import { Skeleton, useTheme } from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";

export function OverviewSkeleton() {
  return (
    <>
      <FacilityMixSkeleton />
      <ChartTileSkeleton />
      <ChartTileSkeleton />
      <ChartTileSkeleton />
    </>
  );
}

export function FacilityMixSkeleton() {
  const theme = useTheme();
  return (
    <DashboardTile>
      <DashboardTile.ContentArea xs={12}>
        <Skeleton variant="rectangular" height={theme.spacing(4)} />
      </DashboardTile.ContentArea>
      <DashboardTile.ContentArea xs={12} md={6}>
        <Skeleton variant="rectangular" height={theme.spacing(40)} />
      </DashboardTile.ContentArea>
      <DashboardTile.ContentArea xs={12} md={6}>
        <Skeleton variant="rectangular" height={theme.spacing(40)} />
      </DashboardTile.ContentArea>
      <DashboardTile.ContentArea xs={12}>
        <Skeleton variant="rectangular" height={theme.spacing(6)} />
      </DashboardTile.ContentArea>
    </DashboardTile>
  );
}

export function ChartTileSkeleton() {
  const theme = useTheme();
  return (
    <DashboardTile>
      <DashboardTile.ContentArea xs={12}>
        <Skeleton variant="rectangular" height={theme.spacing(4)} />
      </DashboardTile.ContentArea>
      <DashboardTile.ContentArea xs={3}>
        <Skeleton variant="rectangular" height={theme.spacing(38)} />
      </DashboardTile.ContentArea>
      <DashboardTile.ContentArea xs={9}>
        <Skeleton variant="rectangular" height={theme.spacing(38)} />
      </DashboardTile.ContentArea>
    </DashboardTile>
  );
}
