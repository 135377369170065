import { ChemicalsWithMeasurementsQuery } from "generated-graphql/graphql";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DeleteChemicalMessage } from "./FacilityChemicalsTable";
import { MeasurementsTable } from "./ReportingFacilityChemical/MeasurementsTable";
import { LoadingButton } from "@mui/lab";

type MeasurementType =
  ChemicalsWithMeasurementsQuery["facilityChemicalMeasurementsForReport"]["items"][number];
interface Props {
  measurementToDelete?: MeasurementType;
  setDeletingChemicalMeasurements: (value: MeasurementType | undefined) => void;
  handleDelete: (id: string, addZeroMeasurement?: boolean) => void;
  isDeleting: boolean;
  reportingYear: number;
}

export const DeleteChemicalMeasurementsConfirmDialog: React.FC<Props> = ({
  measurementToDelete,
  setDeletingChemicalMeasurements,
  handleDelete,
  isDeleting,
  reportingYear,
}) => {
  if (!measurementToDelete) return null;

  if (measurementToDelete.hasCarryOver) {
    return (
      <ConfirmDialog
        open={!!measurementToDelete}
        onClose={() => setDeletingChemicalMeasurements(undefined)}
        title="Remove Chemical Measurements?"
        msg={`You are deleting all measurements of this chemical for reporting year ${reportingYear}. Choose to either retain the carryover amount from the previous year or reset the chemical amount to 0 on January 1, ${reportingYear}.`}
        actions={
          <>
            <LoadingButton
              disabled={isDeleting}
              loading={isDeleting}
              onClick={() => handleDelete(measurementToDelete.id, true)}
              variant="contained"
            >
              {`Reset to 0 on January 1, ${reportingYear}`}
            </LoadingButton>
            <LoadingButton
              loading={isDeleting}
              disabled={isDeleting}
              onClick={() => handleDelete(measurementToDelete.id, false)}
              variant="contained"
              data-testid="confirm"
            >
              {`Retain Carryover Amount`}
            </LoadingButton>
          </>
        }
        loading={isDeleting}
      />
    );
  } else {
    return (
      <ConfirmDialog
        open={!!measurementToDelete}
        onClose={() => setDeletingChemicalMeasurements(undefined)}
        onConfirm={() => handleDelete(measurementToDelete.id)}
        title="Remove Chemical Measurements?"
        confirmText="delete"
        msg={
          <DeleteChemicalMessage
            chemicalName={measurementToDelete.name}
            reportingYear={reportingYear}
            forMeasurements={true}
          />
        }
        loading={isDeleting}
      />
    );
  }
};
