import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { FacilityProductMeasurementInput } from "generated-graphql/graphql";
import { omit } from "lodash";
import { useCallback } from "react";

const VALIDATE_FACILITY_PRODUCT_MEASUREMENT_INPUT = gql(`
  query ValidateFacilityProductMeasurementInput($input: FacilityProductMeasurementInput!) {
    facilityProductMeasurementInputValidator(input: $input) {
      ...issue
    }
  }
`);

const transformInput = (input: FacilityProductMeasurementInput) => {
  return {
    ...omit(input, "id", "issues", "product"),
  };
};
export function useFacilityProductMeasurementInputValidation() {
  const [query] = useLazyQuery(VALIDATE_FACILITY_PRODUCT_MEASUREMENT_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: FacilityProductMeasurementInput) => {
      const transformedInput = transformInput(input);
      const { data } = await query({
        variables: { input: transformedInput },
      });
      return data?.facilityProductMeasurementInputValidator ?? [];
    },
    [query]
  );
}
