import { Frequency, Permission } from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useCurrentUser } from "hooks/useCurrentUser";
import * as yup from "yup";

export const useTaskSchema = () => {
  const { hasPermissions } = useAuthorization();
  const { user } = useCurrentUser();

  let facilityIds: string[] = [];
  if (!hasPermissions([Permission.WriteAllFacility])) {
    facilityIds = user?.facilities.map((facility) => facility.id) ?? [];
  }

  const facilityIdsSchema = yup.array().of(
    yup
      .string()
      .required("At least one facility is required")
      .test(
        "is-valid-facility-id",
        "Facilities are not valid",
        function (value) {
          return facilityIds.length === 0 || facilityIds.includes(value!);
        }
      )
  );

  // If facilityIds is provided, ensure at least one entry is required
  const finalFacilityIdsSchema =
    facilityIds.length > 0
      ? facilityIdsSchema
          .min(1, "At least one facility is required")
          .required("At least one facility is required")
      : facilityIdsSchema;

  return yup.object({
    title: yup.string().nullable().required("Title is required"),
    description: yup.string().nullable(),
    assignee: yup
      .object()
      .nullable()
      .required("Assignee is required")
      .shape({
        id: yup.string().required("Assignee ID is required"),
      }),
    deadline: yup.date().nullable().required("Deadline is required"),
    frequency: yup
      .mixed<Frequency>()
      .oneOf(Object.values(Frequency), "Invalid frequency")
      .required("Frequency is required"),
    interval: yup.number().nullable().required("Interval is required").min(1),
    shouldNotify: yup.boolean().required("Notification status is required"),
    subtasks: yup.array().of(
      yup.object({
        title: yup.string().nullable().required("Subtask title is required"),
        completed: yup
          .boolean()
          .required("Subtask completion status is required"),
      })
    ),
    facilityIds: finalFacilityIdsSchema,
    documents: yup.array(),
    links: yup.array().of(
      yup.object({
        url: yup
          .string()
          .url("Invalid URL")
          .nullable()
          .required("URL is required"),
      })
    ),
    watchers: yup.array().of(yup.string().required("Watcher ID is required")),
  });
};
