import { useQuery } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { gql } from "../../generated-graphql";
import { StaffUsersQuery } from "../../generated-graphql/graphql";
import { useDebounce } from "../../hooks/useDebounce";
import { SpinnerOverlay } from "../SpinnerOverlay";

const STAFF_USERS_QUERY = gql(`
  query StaffUsers($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    staffUsers(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        email
        person {
          id
          first
          last
        }
      }
    }
  } 
`);

type Props = {
  title?: string;
  open: boolean;
  onSelect: (userId: string) => void | Promise<void>;
  onClose: () => void;
};

export function UserSelectDialog(props: Props) {
  const [search, setSearch] = useState("");
  const [saving, setSaving] = useState(false);
  const [selectedUser, setSelectedUser] = useState<
    StaffUsersQuery["staffUsers"]["items"][0] | null
  >(null);
  const [debouncedSearch] = useDebounce(search, 1000);
  const theme = useTheme();

  const { data, loading, previousData } = useQuery(STAFF_USERS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      search: debouncedSearch,
    },
  });

  const handleClose = useCallback(() => {
    props.onClose?.();
  }, [props]);

  const handleSelect = useCallback(async () => {
    setSaving(true);
    selectedUser && (await props.onSelect(selectedUser.id));
  }, [selectedUser, props]);

  useEffect(() => {
    if (!props.open) {
      setSelectedUser(null);
      setSaving(false);
      setSearch("");
    }
  }, [props.open]);

  const d = loading ? previousData : data;

  return (
    <Dialog scroll="paper" open={props.open} onClose={handleClose} fullWidth>
      <DialogTitle>{props.title ?? "Select a user"}</DialogTitle>

      <TextField
        placeholder="Search"
        size="small"
        sx={{ width: "100%", paddingX: "1rem", marginBottom: "1rem" }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <DialogContent dividers sx={{ position: "relative" }}>
        <List sx={{ height: "24rem", overflowY: "auto" }}>
          {d?.staffUsers.items.map((user) => (
            <ListItem
              sx={{
                cursor: "pointer",
                overflowY: "auto",
                borderTop: theme.palette.divider,
                ":hover": {
                  background: theme.palette.action.hover,
                },
              }}
              onClick={() => setSelectedUser(user)}
              key={user.id}
            >
              {user.id === selectedUser?.id ? (
                <Check sx={{ color: theme.palette.success.main }} />
              ) : (
                ""
              )}
              <Box sx={{ marginLeft: "1rem" }}>
                {user.person?.first} {user.person?.last}{" "}
                {"<" + user.email + ">"}
              </Box>
            </ListItem>
          ))}
        </List>
        <SpinnerOverlay loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={!selectedUser || saving}
          onClick={handleSelect}
        >
          {saving ? (
            <CircularProgress size="1rem" />
          ) : (
            `Select ${
              selectedUser?.person
                ? ` ${selectedUser.person.first} ${selectedUser.person.last}`
                : ""
            }`
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
