import { Box, Tooltip, Typography, styled, useTheme } from "@mui/material";
import { CopyableText } from "components/CopyableText";
import { GetCredentialQuery } from "generated-graphql/graphql";

const TruncatedText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CredentialPortalUrl = ({
  facility,
}: {
  facility?: NonNullable<
    GetCredentialQuery["credential"]["facilities"]
  >[number];
}) => {
  const theme = useTheme();

  const portalUrl = facility?.portalCredential?.url;
  return (
    <Box
      paddingTop={theme.spacing(1)}
      paddingBottom={theme.spacing(2)}
      display="flex"
      flexWrap="nowrap"
    >
      <Typography fontWeight="bold" paddingRight={theme.spacing(0.5)}>
        URL:
      </Typography>
      {portalUrl ? (
        <>
          <Tooltip title={portalUrl}>
            <TruncatedText>{portalUrl}</TruncatedText>
          </Tooltip>
          <CopyableText hideText>{portalUrl}</CopyableText>
        </>
      ) : (
        "N/A"
      )}
    </Box>
  );
};
