import React, { useCallback } from "react";
import { CheckboxField } from "components/Forms/CheckboxField";
import { v4 } from "uuid";
import { IconButton, Button, Stack, Typography, useTheme } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import { Control, useFieldArray } from "react-hook-form";
import { TaskFormType } from "./TaskDialog";
import { FormTextField } from "components/Forms/FormTextField";

type SubtasksControlProps = {
  control: Control<TaskFormType>;
};

const SubtasksControl: React.FC<SubtasksControlProps> = ({ control }) => {
  const theme = useTheme();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "subtasks",
    keyName: "_id",
  });

  const handleAddSubTask = useCallback(() => {
    append({ title: "", completed: false, id: v4() });
  }, [append]);

  const handleDeleteSubTask = useCallback(
    (id: string) => {
      const index = fields.findIndex((field) => field.id === id);
      if (index === -1) return;
      remove(index);
    },
    [fields, remove]
  );

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Subtasks</Typography>
        <div>
          <Button onClick={handleAddSubTask} startIcon={<Add />}>
            Add Subtask
          </Button>
        </div>
      </Stack>
      <Stack direction="column" gap={theme.spacing(0.5)}>
        {fields?.length === 0 && (
          <Typography variant="body2">
            No subtasks have been added to this task yet
          </Typography>
        )}
        {fields.map((field, index) => (
          <Stack direction="row" key={field.id} alignItems="center">
            <CheckboxField
              name={`subtasks.${index}.completed`}
              control={control}
              label={""}
              fullWidth={false}
            />
            <FormTextField
              name={`subtasks.${index}.title`}
              control={control}
              label="Subtask"
              fullWidth
            />
            <IconButton
              edge="end"
              onClick={() => handleDeleteSubTask(field.id)}
              sx={{
                marginTop: theme.spacing(1),
                alignSelf: "flex-start",
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default SubtasksControl;
