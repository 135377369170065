import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import { Link, Tooltip } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import { FieldValues } from "react-hook-form";

/**
 * Transforms raw html string to replace <a> tags with MUI Link components
 * that open in a new tab with rel="noopener noreferrer"
 * @param inputString Raw html string
 * @returns Transformed html string with <a> tags replaced with MUI Link components
 * with target="_blank" and rel="noopener noreferrer"
 */
function transformLinks(inputString: string) {
  // Regex to match <a href="url">text</a>
  const regex = /<a href="(?<url>[^"]+)">(?<text>[^<]+)<\/a>/gi;
  const matches = inputString.matchAll(regex);
  for (const match of matches) {
    const url = match.groups?.url;
    const text = match.groups?.text;
    inputString = inputString.replace(
      match[0],
      renderToStaticMarkup(
        <Link href={url} target="_blank" rel="noopener noreferrer">
          {text}
        </Link>
      )
    );
  }
  return inputString;
}

type InputTooltipProps<T extends FieldValues> = {
  title: string;
  placement?:
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left"
    | "left-end"
    | "left-start"
    | "right"
    | "right-end"
    | "right-start"
    | "top"
    | "top-end"
    | "top-start";
};

export const InputTooltip = <T extends FieldValues>({
  title,
  placement = "bottom",
}: InputTooltipProps<T>) => {
  return (
    <Tooltip
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: transformLinks(title),
          }}
        />
      }
      className="input-tooltip"
      arrow
      leaveDelay={250}
      style={{ marginLeft: "4px", padding: "0" }}
      placement={placement}
    >
      <HelpOutlineOutlined fontSize="small" />
    </Tooltip>
  );
};
