import CopyAll from "@mui/icons-material/CopyAll";
import Delete from "@mui/icons-material/Delete";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCurrentUser } from "hooks/useCurrentUser";
import { isEmpty } from "lodash";
import { FormEventHandler, useMemo, useState } from "react";
import { useLocalStorageState } from "../../hooks/useLocalStorageState";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useAlerts } from "../Alerts/AlertProvider";
import { Filter } from "./Omnisearch";

type FavoritesPopoverProps = {
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  popoverAnchor: HTMLButtonElement | null;
  setPopoverAnchor: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
};

export function FavoritesPopover({
  isPopoverOpen,
  setIsPopoverOpen,
  popoverAnchor,
  setPopoverAnchor,
}: FavoritesPopoverProps) {
  const alerts = useAlerts();
  const currentUser = useCurrentUser();
  const { filters, setFilters } = useQueryParams<Filter>();
  const [favorites, setFavorites] = useLocalStorageState<{
    [favorite: string]: string;
  }>(`filter-favorites|${currentUser.user?.email}`, {});

  const handleFavoritesPopoverClose = () => {
    setIsPopoverOpen(false);
    setPopoverAnchor(null);
  };

  const handleCopyFavoriteClick = (favorite: string) => {
    const url = new URL(window.location.pathname, window.location.origin);
    const params = new URLSearchParams(window.location.search);
    params.set("omnisearch", favorites[favorite]);
    params.forEach((value, key) => url.searchParams.set(key, value));

    navigator.clipboard.writeText(url.href);
    alerts.success("Copied!");
  };

  const handleDeleteFavoriteClick = (favorite: string) => {
    const { [favorite]: _, ...newState } = favorites;
    setFavorites(newState);
  };

  const [favoriteName, setFavoriteName] = useState<string>("");
  const [searchFavoritesText, setSearchFavoritesText] = useState<string>("");

  const saveFavorite: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const newState = {
      ...favorites,
      [favoriteName.trim()]: filters.omnisearch ?? "",
    };
    setFavorites(newState);

    setFavoriteName("");
  };

  const handleFavoriteSelection = (favorite: string) => {
    setFilters((state) => {
      state.omnisearch = favorites[favorite];
      return state;
    });
    setIsPopoverOpen(false);
    setSearchFavoritesText("");
    setFavoriteName("");
  };

  const compareFavorites = (a: string, b: string) =>
    a.toLowerCase() > b.toLowerCase() ? 1 : -1;

  const favoriteNames = useMemo(() => Object.keys(favorites), [favorites]);

  return (
    <Popover
      id="omnisearch-favorites-popover"
      data-testid="omnisearch-favorites-popover"
      open={isPopoverOpen}
      onClose={handleFavoritesPopoverClose}
      anchorEl={popoverAnchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack spacing={2} sx={{ p: 2, bgcolor: "background.paper" }}>
        <form onSubmit={saveFavorite}>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              id="favorite-name"
              label="Name"
              autoFocus
              value={favoriteName}
              inputProps={{ "data-1p-ignore": true }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFavoriteName(event.target.value);
              }}
            />
            <Button type="submit" disabled={isEmpty(favoriteName.trim())}>
              Save
            </Button>
          </Stack>
        </form>
        <Divider />
        <Stack direction="column" spacing={2} justifyItems="start">
          <Typography variant="h6">Favorites</Typography>
          {favoriteNames.length === 0 && (
            <Typography variant="body2">No favorites saved yet!</Typography>
          )}
          {favoriteNames.length > 0 && (
            <TextField
              id="search-favorites-input"
              label="Search"
              value={searchFavoritesText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchFavoritesText(event.target.value);
              }}
            />
          )}
          <List
            sx={{
              bgcolor: "background.paper",
              maxHeight: "300px",
              overflow: "auto",
            }}
            dense={true}
          >
            {favoriteNames.length > 0 &&
              favoriteNames
                .filter((favorite) => {
                  return (
                    isEmpty(searchFavoritesText.trim()) ||
                    favorite
                      .toLowerCase()
                      .includes(searchFavoritesText.toLowerCase())
                  );
                })
                .sort(compareFavorites)
                .map((favorite) => (
                  <ListItem key={favorite}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <Button
                          onClick={() => handleFavoriteSelection(favorite)}
                        >
                          {favorite}
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip title="Copy shareable link">
                          <IconButton
                            aria-label={`Copy ${favorite}`}
                            onClick={() => handleCopyFavoriteClick(favorite)}
                          >
                            <CopyAll />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label={`Delete ${favorite}`}
                            onClick={() => handleDeleteFavoriteClick(favorite)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
          </List>
        </Stack>
      </Stack>
    </Popover>
  );
}
