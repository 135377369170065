import { Box, useMediaQuery, useTheme } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";
import {
  ChartsItemContentProps,
  ChartsTooltip,
  DefaultizedPieValueType,
  PieValueType,
  ResponsiveChartContainer,
  ResponsiveChartContainerProps,
} from "@mui/x-charts";
import { PiePlot, PiePlotProps } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { useMemo } from "react";
import { PriorityChartColors } from "util/insights";
import ChartLegend, { LegendConfig } from "./ChartLegend";
import ChartTooltip from "./ChartTooltip";

// TODO: Remove this after Insights V2 is live
const LEGACY_DONUT_CHART_COLORS: string[] = [
  "rgb(2, 178, 175)",
  "rgb(46, 150, 255)",
  "rgb(184, 0, 216)",
  "rgb(96, 0, 155)",
  "rgb(39, 49, 200)",
  "rgb(3, 0, 141)",
];

const SERIES_ID = "seriesId";

const StyledText = styled("text")(({ theme, fontSize }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize,
}));

function PieCenterLabel({
  label,
  subLabel,
}: {
  label?: string;
  subLabel?: string;
}) {
  const { width, height, left, top } = useDrawingArea();

  const offsetX = 0;
  const offsetY = 12;
  const centerX = left + width / 2;
  const centerY = top + height / 2;

  return (
    <>
      {label && (
        <StyledText
          fontSize={24}
          x={centerX + offsetX}
          y={centerY - (subLabel ? offsetY : 0)}
        >
          {label}
        </StyledText>
      )}
      {subLabel && (
        <StyledText fontSize={12} x={centerX + offsetX} y={centerY + offsetY}>
          {subLabel}
        </StyledText>
      )}
    </>
  );
}

function ItemTooltip(params: ChartsItemContentProps<any>) {
  const label = params.series.data?.[params.itemData.dataIndex].label;

  const title = typeof label === "function" ? label("legend") : label;
  const value = typeof label === "function" ? label("tooltip") : label;

  return (
    <ChartTooltip
      title={title ?? ""}
      labels={[
        {
          color: params.getColor(params.itemData.dataIndex),
          value: value ?? "",
        },
      ]}
    />
  );
}

export type LabelLocation = "tooltip" | "legend" | "arc";

type DonutChartProps = {
  seriesData: PieValueType[];
  selectedIndex?: number | null;
  label?: string;
  subLabel?: string;
  containerHeight: string;
  onItemClick?: PiePlotProps["onItemClick"];
  onLegendItemClick?: (item: DefaultizedPieValueType) => void;
  legend?: LegendConfig;
  legendSx?: SxProps;
  responsiveContainerProps?: Partial<ResponsiveChartContainerProps>;
  // TODO: Remove this after Insights V2 is live
  useLegacyColors?: boolean;
};

export default function DonutChart({
  seriesData,
  selectedIndex,
  label,
  subLabel,
  containerHeight,
  responsiveContainerProps,
  legend,
  onItemClick,
  onLegendItemClick,
  legendSx,
  useLegacyColors = false,
}: DonutChartProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const highlightedItem = useMemo(() => {
    if (selectedIndex === null) {
      return null;
    }

    return {
      dataIndex: selectedIndex,
      seriesId: SERIES_ID,
    };
  }, [selectedIndex]);

  return (
    <Box
      display="flex"
      flexDirection={
        isSmallScreen
          ? "column"
          : legend?.position === "above"
          ? "column-reverse"
          : legend?.position === "right"
          ? "row"
          : legend?.position === "below"
          ? "column"
          : legend?.position === "left"
          ? "row-reverse"
          : "row"
      }
    >
      <Box
        height={containerHeight}
        width={containerHeight}
        minWidth={containerHeight}
      >
        <ResponsiveChartContainer
          series={[
            {
              id: SERIES_ID,
              type: "pie",
              data: seriesData,
              innerRadius: 65,
              outerRadius: 120,
              highlightScope: { faded: "global", highlighted: "item" },
            },
          ]}
          colors={PriorityChartColors}
          highlightedItem={highlightedItem}
          {...responsiveContainerProps}
        >
          <PiePlot onItemClick={onItemClick} />
          <ChartsTooltip
            trigger="item"
            slots={{
              itemContent: (params) => ItemTooltip(params),
            }}
          />
          <PieCenterLabel label={label} subLabel={subLabel} />
        </ResponsiveChartContainer>
      </Box>
      <ChartLegend
        config={legend}
        items={seriesData.map((d, index) => ({
          color:
            d.color ??
            (useLegacyColors
              ? LEGACY_DONUT_CHART_COLORS[index]
              : PriorityChartColors[index]),
          value:
            (typeof d.label === "function" ? d.label("legend") : d.label) ?? "",
        }))}
        onItemClick={(index) =>
          onLegendItemClick &&
          onLegendItemClick(seriesData[index] as DefaultizedPieValueType)
        }
        selectedIndex={selectedIndex}
        sx={legendSx}
      />
    </Box>
  );
}
