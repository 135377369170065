import { Grid, useTheme } from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { StatePicker } from "components/StatePicker";
import { EncampMailingAddress } from "encamp-shared/src/constants/encampAddress";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CountryPicker } from "./CountryPicker";

export function MailingAddress({ readOnly }: { readOnly?: boolean }) {
  const theme = useTheme();
  const { control, watch, setValue, getFieldState } = useFormContext();

  const isMailingAddressSameAsFacility = watch(
    "isMailingAddressSameAsFacility"
  );
  const streetAddress1 = watch("streetAddress1");
  const streetAddress2 = watch("streetAddress2");
  const city = watch("city");
  const state = watch("state");
  const zip = watch("zip");
  const country = watch("country");

  const disabled =
    isMailingAddressSameAsFacility === "encamp" ||
    isMailingAddressSameAsFacility ||
    readOnly;

  useEffect(() => {
    if (isMailingAddressSameAsFacility === "encamp") {
      setValue(
        "mailingStreetAddress1",
        EncampMailingAddress.mailingStreetAddress1
      );
      setValue(
        "mailingStreetAddress2",
        EncampMailingAddress.mailingStreetAddress2
      );
      setValue("mailingCity", EncampMailingAddress.mailingCity);
      setValue("mailingState", EncampMailingAddress.mailingState);
      setValue("mailingZip", EncampMailingAddress.mailingZip);
      setValue("mailingCountry", EncampMailingAddress.mailingCountry);
    } else if (isMailingAddressSameAsFacility) {
      setValue("mailingStreetAddress1", streetAddress1);
      setValue("mailingStreetAddress2", streetAddress2);
      setValue("mailingCity", city);
      setValue("mailingState", state);
      setValue("mailingZip", zip);
      setValue("mailingCountry", country);
    } else if (
      getFieldState("isMailingAddressSameAsFacility").isTouched &&
      !isMailingAddressSameAsFacility
    ) {
      setValue("mailingStreetAddress1", null);
      setValue("mailingStreetAddress2", null);
      setValue("mailingCity", null);
      setValue("mailingState", null);
      setValue("mailingZip", null);
      setValue("mailingCountry", null);
    }
  }, [
    isMailingAddressSameAsFacility,
    setValue,
    getFieldState,
    streetAddress1,
    streetAddress2,
    city,
    state,
    zip,
    country,
  ]);

  return (
    <Grid
      container
      columnSpacing={theme.spacing(3)}
      rowSpacing={theme.spacing(1)}
      marginTop={theme.spacing(2)}
    >
      <Grid item xs={12} mb={1}>
        <RadioGroupField
          label="Is the mailing address the same as the facility address?"
          name="isMailingAddressSameAsFacility"
          control={control}
          disabled={readOnly}
          radioOptions={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
            {
              label: "Use Encamp's mailing address",
              value: "encamp",
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <FormTextField
          disabled={disabled}
          name="mailingStreetAddress1"
          label="Street Address"
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          disabled={disabled}
          name="mailingStreetAddress2"
          label="Street Address 2"
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          disabled={disabled}
          name="mailingCity"
          label="City"
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <StatePicker
          name="mailingState"
          countryFieldName="mailingCountry"
          control={control}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextField
          name="mailingZip"
          label="Postal Code"
          disabled={disabled}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <CountryPicker
          name="mailingCountry"
          control={control}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}
