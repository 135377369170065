import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { useCallback, useEffect, useState } from "react";
import { Filter } from "./Omnisearch";
import { TimeFilter } from "./TimeFilterInput";
import { FilterKey } from "hooks/useOmnisearchDatagridSettings";

function parseOmnisearch(omnisearch: string): {
  [key: string]: string;
} {
  const parsed: { [key: string]: string } = {};
  const regex = /([^:\s]+):("[^"]*"|\S+)/g;
  let match;
  while ((match = regex.exec(omnisearch)) != null) {
    let value = match[2];
    // only remove quotes if they are present
    if (value.startsWith('"') && value.endsWith('"')) {
      value = value.slice(1, -1);
    }

    parsed[match[1]] = value;
  }
  return parsed;
}

function stringifyOmnisearch(obj: { [key: string]: string }): string {
  return Object.entries(obj)
    .filter(([, v]) => v.trim().length > 0)
    .map(([k, v]) => {
      // only add quotes if the value includes spaces
      return v.includes(" ") ? `${k}:"${v}"` : `${k}:${v}`;
    })
    .join(" ");
}

type FilterPopoverProps = {
  isOpen: boolean;
  filterButtonAnchor: HTMLButtonElement | null;
  handleCloseFilter: () => void;
  columnFilterKeys: FilterKey[];
  onFilterKeyClick: (key: string) => void;
  filters: Filter;
};

export function FilterPopover({
  isOpen,
  filterButtonAnchor,
  handleCloseFilter,
  columnFilterKeys,
  onFilterKeyClick,
  filters,
}: FilterPopoverProps) {
  const theme = useTheme();
  const [inputValues, setInputValues] = useState<{
    [key: string]: string;
  }>(parseOmnisearch(filters.omnisearch ?? ""));

  useEffect(() => {
    setInputValues(parseOmnisearch(filters.omnisearch ?? ""));
  }, [filters.omnisearch]);

  const handleApply = useCallback(() => {
    const newOmnisearch = stringifyOmnisearch(inputValues);
    onFilterKeyClick(newOmnisearch);
    handleCloseFilter();
  }, [handleCloseFilter, inputValues, onFilterKeyClick]);

  return (
    <Popover
      open={isOpen}
      anchorEl={filterButtonAnchor}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ p: 2, width: 640 }}>
        <Typography variant="h6" component="h2">
          Filters
        </Typography>

        {columnFilterKeys?.map(
          ({ key, header, filterKeyType, HelpTooltip, enumValues }) => {
            let input;

            switch (filterKeyType) {
              case "time":
                input = (
                  <TimeFilter
                    value={inputValues[key] ?? ""}
                    id={key}
                    key={key}
                    header={header}
                    onChange={(value) =>
                      setInputValues((iv) => ({ ...iv, [key]: value }))
                    }
                    handleApply={handleApply}
                  />
                );
                break;

              case "enum":
                input = (
                  <FormControl fullWidth>
                    <InputLabel id={`${header}-select`} size="small">
                      {header}
                    </InputLabel>
                    <Select
                      labelId={`${header}-select`}
                      aria-labelledby={`${header}-select`}
                      label={header}
                      size="small"
                      value={inputValues[key] ?? ""}
                      onChange={(e) =>
                        setInputValues((iv) => ({
                          ...iv,
                          [key]: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={""}>Unselect</MenuItem>
                      {enumValues?.map((val) => (
                        <MenuItem key={val} value={val}>
                          {prettyPrintEnumValue(val)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
                break;

              case "boolean":
                input = (
                  <FormControl fullWidth>
                    <InputLabel id={`${header}-select`} size="small">
                      {header}
                    </InputLabel>
                    <Select
                      labelId={`${header}-select`}
                      aria-labelledby={`${header}-select`}
                      label={header}
                      size="small"
                      value={inputValues[key] ?? ""}
                      onChange={(e) =>
                        setInputValues((iv) => ({
                          ...iv,
                          [key]: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={""}>Unselect</MenuItem>
                      <MenuItem value={"true"}>true</MenuItem>
                      <MenuItem value={"false"}>false</MenuItem>
                    </Select>
                  </FormControl>
                );
                break;

              default:
                input = (
                  <TextField
                    fullWidth
                    label={header}
                    value={(inputValues[key] as string) ?? ""}
                    size="small"
                    onChange={(e) =>
                      setInputValues((iv) => ({
                        ...iv,
                        [key]: e.target.value,
                      }))
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleApply();
                      }
                    }}
                  />
                );
            }

            return (
              <Grid
                key={key}
                container
                sx={{
                  mb: theme.spacing(2),
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={11}>
                  {input}
                </Grid>
                {HelpTooltip && <HelpTooltip />}
              </Grid>
            );
          }
        )}
        <Grid
          container
          justifyContent="flex-end"
          columnSpacing={2}
          sx={{ mt: 2 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseFilter}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleApply}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}
