import { useLazyQuery } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Filter, Omnisearch } from "components/Omnisearch/Omnisearch";
import { FilterKey, FilterKeyType } from "hooks/useOmnisearchDatagridSettings";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { encampStatusToLabel, orgStatusToLabel } from "util/constants";
import { DataGrid } from "../../../../../components/DataGrid";
import { gql } from "../../../../../generated-graphql";
import {
  ActivityStatus,
  ActivityType,
  ExecutionPlanType,
  TierIiReportEncampStatus,
  TierIiReportOrgStatus,
  WaitForAllCohortQuery,
} from "../../../../../generated-graphql/graphql";
import { useDebounce } from "../../../../../hooks/useDebounce";

export type Row = WaitForAllCohortQuery["tierIIReportActivities"]["items"][0];

const WAIT_FOR_ALL_COHORT_QUERY = gql(`
  query WaitForAllCohort($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReportActivities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        tenantId
        tierIIReportId
        reportingYear
        productCount
        organizationStatus
        encampStatus
        state
        facilityName
        facilityId
        streetAddress1
        streetAddress2
        city
        state
        zip
        customerFacilityId
        organizationName
        workflowId
        activityId
        activityType
        activityDescription
        activityAssigneeFirst
        activityAssigneeLast
        activityAssigneeEmail
        activityExecutionPlan
        activityStatus
        hasIncompleteReviews
        tenantId
        isChampionReport
      }
      count
    }
  }
`);

export function WaitForAllCohortTable({
  cohortFacilityIds,
  cohortType,
}: {
  cohortFacilityIds: string[];
  cohortType: string;
}) {
  const theme = useTheme();
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [paginationModel, setPaginationModel] = usePaginationModel();

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const defaultSearch = `facilityId:${cohortFacilityIds.join("|")}`;

  const [filters, setFilters] = useState<Filter>();

  // scroll the data grid into view when the omnisearch filters change
  const dataGridRef = useRef<HTMLDivElement>(null);

  const [fetchPage, { data, error, loading, previousData }] = useLazyQuery(
    WAIT_FOR_ALL_COHORT_QUERY,
    {
      variables: {
        search: `${defaultSearch} ${useDebounce(
          filters?.omnisearch ?? "",
          1000
        )}`,
        pageSize: paginationModel.pageSize,
        sort: [...sortModel],
      },
    }
  );

  // Initially fetch data
  useEffect(() => {
    fetchPage({ variables: { page: paginationModel.page } });
  }, [fetchPage, paginationModel.page]);

  const viewReport = useCallback(
    (id?: string) => {
      if (id) {
        const newTab = window.open(
          `${window.location.origin}/staff/fulfillment/${id}`,
          "_blank"
        );
        if (newTab) {
          newTab.focus();
        } else {
          alerts.error(
            "Unable to open the URL in a new tab. Please allow pop-ups for this website."
          );
        }
      }
    },
    [alerts]
  );

  const columns = useMemo((): GridColDef<Row>[] => {
    return [
      {
        field: "facilityName",
        headerName: "Facility",
        flex: 1,
        valueGetter: ({ row }) =>
          row.customerFacilityId
            ? `${row.facilityName} - ${row.customerFacilityId}`
            : row.facilityName,
      },
      {
        field: "streetAddress1",
        headerName: "Address",
        flex: 1,
        valueGetter: ({ row }) =>
          `${row.streetAddress1} ${row.streetAddress2} ${row.city}, ${row.state} ${row.zip}`,
      },
      {
        field: "hasReport",
        headerName: "Report?",
        flex: 1,
        renderCell: ({ row }) => (row.tierIIReportId ? <Check /> : <></>),
      },
      {
        field: "organizationStatus",
        headerName: "Organization Status",
        flex: 1,
        valueGetter: ({ row }) => {
          return orgStatusToLabel(
            row.organizationStatus as TierIiReportOrgStatus
          );
        },
      },
      {
        field: "encampStatus",
        headerName: "Encamp Status",
        flex: 1,
        valueGetter: ({ row }) => {
          return encampStatusToLabel(
            row.encampStatus as TierIiReportEncampStatus
          );
        },
      },
      {
        field: "activityDescription",
        valueGetter: (params) => {
          if (!params.row.activityType) return "N/A";
          return params.row.activityDescription
            ? `${params.row.activityType}: ${params.row.activityDescription}`
            : `${params.row.activityType}`;
        },
        headerName: "Activities",
        flex: 1,
      },
      {
        field: "moreInfo",
        type: "actions",
        getActions: (params) => {
          const actions = [];
          if (params.row.tierIIReportId) {
            actions.push(
              <Tooltip title="View Submission" key={1}>
                <GridActionsCellItem
                  onClick={() => viewReport(params.row.tierIIReportId ?? "")}
                  label="View Submission"
                  icon={<OpenInNew />}
                />
              </Tooltip>
            );
          }
          return actions;
        },
      },
    ];
  }, [viewReport]);

  const columnFilterKeys: FilterKey[] = columns
    .filter((c) => c.type !== "actions")
    .concat([
      {
        field: "isChampionReport",
        headerName: "Champ",
      },
    ])
    .map((c) => {
      let enumValues;
      let type: FilterKeyType | undefined = undefined;
      if (c.field === "organizationStatus") {
        enumValues = Object.values(TierIiReportOrgStatus);
        type = "enum";
      }
      if (c.field === "encampStatus") {
        enumValues = Object.values(TierIiReportEncampStatus);
        type = "enum";
      }
      if (c.field === "executionPlan") {
        enumValues = Object.values(ExecutionPlanType);
        type = "enum";
      }
      if (c.field === "activityType") {
        enumValues = Object.values(ActivityType);
        type = "enum";
      }
      if (c.field === "activityStatus") {
        enumValues = Object.values(ActivityStatus);
        type = "enum";
      }
      if (c.field === "hasReport" || c.field === "isChampionReport") {
        type = "boolean";
      }
      return {
        key: c.field,
        header: c.headerName ?? c.field,
        filterKeyType: type,
        enumValues,
      };
    });

  if (error) throw error;

  return (
    <Grid
      container
      gap={0.5}
      component={Paper}
      sx={{
        flexDirection: "column",
        padding: theme.spacing(3),
        border: `${theme.palette.divider} solid 1px`,
      }}
    >
      <Typography variant="h6">Facilities in Cohort: {cohortType}</Typography>
      <Omnisearch
        sx={{
          width: "100%",
          marginBottom: theme.spacing(3),
        }}
        columnFilterKeys={columnFilterKeys}
        showFavorites={false}
        useQueryString={false}
        onSearchChanged={(search) => {
          setFilters({ omnisearch: search });
        }}
      />
      <DataGrid
        innerRef={dataGridRef}
        sx={{
          width: "100%",
          opacity:
            loading && previousData ? theme.palette.action.disabledOpacity : 1,
        }}
        columnBuffer={10}
        loading={loading}
        rowCount={data?.tierIIReportActivities.count ?? 0}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        onRowClick={({ row }) => {
          viewReport(row.tierIIReportId);
        }}
        onRowSelectionModelChange={(newModel) => setRowSelectionModel(newModel)}
        columns={columns}
        initialState={{
          pinnedColumns: {
            right: ["actions"],
          },
        }}
        rows={
          data?.tierIIReportActivities.items ??
          previousData?.tierIIReportActivities.items ??
          []
        }
      />
    </Grid>
  );
}
