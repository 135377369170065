import { Typography, useTheme, Stack, Box, styled } from "@mui/material";
import CopyableTypography from "../../../../../components/Typography/CopyableTypography";

const Label = styled("span")`
  font-weight: 500;
`;
const Field = styled("p")`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.25rem;
`;

type Props = {
  reportDetailsExtended: { [key: string]: any };
};
export const ReportDetailsExtended = ({ reportDetailsExtended }: Props) => {
  const theme = useTheme();

  if (
    !reportDetailsExtended ||
    Object.keys(reportDetailsExtended).length === 0
  ) {
    return null;
  }

  return (
    <Box sx={{ paddingTop: theme.spacing(2) }}>
      <Typography variant="h6">Report Details Extended</Typography>
      <Typography variant="body1">
        The data below should help you complete this activity
      </Typography>
      <Stack
        direction={"column"}
        sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1) }}
      >
        {Object.keys(reportDetailsExtended).map((key) => {
          const isPrimitive =
            typeof reportDetailsExtended[key] === "string" ||
            typeof reportDetailsExtended[key] === "number" ||
            typeof reportDetailsExtended[key] === "boolean";
          const stringifiedValue = isPrimitive
            ? String(reportDetailsExtended[key])
            : JSON.stringify(reportDetailsExtended[key]);
          return (
            <Field key={key}>
              <Label>{key}:</Label>
              <CopyableTypography
                // use stringify because it *should* be a primitive, but we don't have guarantees
                text={stringifiedValue}
              />
            </Field>
          );
        })}
      </Stack>
    </Box>
  );
};
