import { Box, Typography, useTheme } from "@mui/material";

type HeaderType<T> = T extends undefined ? string : keyof T;
export function StepperPageHeader<T extends { [stepName: string]: string }>({
  header,
  description,
  headerButton,
  mb = 3,
}: {
  header: HeaderType<T>;
  description: React.ReactNode;
  headerButton?: React.ReactNode;
  mb?: number;
}) {
  const theme = useTheme();
  return (
    <Box mb={theme.spacing(mb)} sx={{ width: "80%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        <Typography variant="h5">{header}</Typography>
        {headerButton}
      </Box>
      {description && (
        <Typography
          variant="caption"
          sx={{
            fontSize: theme.typography.htmlFontSize - 2,
            mb: 3,
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
}
