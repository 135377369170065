import ReactRouterPrompt from 'react-router-prompt';
import { ConfirmDialog } from "./ConfirmDialog";

type UnfinishedChangesPrompt = {
  when: boolean;
  msg?: string;
};

export function UnfinishedChangesPrompt({
  when,
  msg,
}: UnfinishedChangesPrompt) {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) => (
        <ConfirmDialog 
          open={isActive} 
          onConfirm={onConfirm} 
          onClose={onCancel}
          title="Are you sure you want to leave?"  
          msg={msg ?? "You have unsaved changes."} 
        />
      )}
    </ReactRouterPrompt>
  )
}
