import { getNumberWithExtension } from "encamp-shared/src/utils/phoneNumber";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { PhoneType } from "generated-graphql/graphql";

export const formatPhoneType = (phoneType: PhoneType) => {
  switch (phoneType) {
    case PhoneType.Hrs_24:
      return "24-Hour";
    default:
      return prettyPrintEnumValue(phoneType);
  }
};

export const formatPhoneNumber = (phone: {
  number?: string | null;
  type: PhoneType;
}) => {
  if (!phone.number || phone.number === "") {
    return "";
  }
  const { phoneNumber, extension } = getNumberWithExtension(phone.number);
  return `${phoneNumber}${extension ? "x" + extension : ""}`;
};

export const formatPhoneNumberAndType = (phone: {
  number?: string | null;
  type: PhoneType;
}) => {
  if (!phone.number || phone.number === "") {
    return "";
  }
  return `${formatPhoneNumber(phone)} (${formatPhoneType(phone.type)})`;
};
