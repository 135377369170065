import { Skeleton } from "@mui/material";
import { ChemicalSummaryTable } from "../../ChemicalSummaryTable";
import { ReportFloatingBar } from "../ReportFloatingBar";
import { useReport, useReportingFacilityChemicalIssues } from "../useReport";
import { HasFeature } from "components/HasFeature";
import { TierIiReportStep } from "generated-graphql/graphql";
import { useTouchReportMutation } from "../useTouchReportMutation";

export function Summary() {
  const { data } = useReport();
  const facilityId = data?.tierIIReport.facility.id ?? "";
  const reportId = data?.tierIIReport.id ?? "";
  const { handleSave, loading } = useTouchReportMutation(
    reportId,
    data?.tierIIReport.touchedSteps ?? [],
    TierIiReportStep.ChemicalSummary
  );

  const issues = useReportingFacilityChemicalIssues();

  if (!data?.tierIIReport) {
    return <Skeleton />;
  }

  return (
    <HasFeature
      products
      or
      measurements
      fallback="../chemicals"
      facilityId={facilityId}
    >
      <ChemicalSummaryTable facilityId={facilityId} reportId={reportId} />
      <ReportFloatingBar
        onSaveClick={handleSave}
        loading={loading}
        disabledWithAnyIssues
        issues={issues}
        saveText="Mark as Complete"
      />
    </HasFeature>
  );
}
