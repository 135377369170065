import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import {
  FacilityProductInput,
  FacilityProductStorageLocationInput,
} from "generated-graphql/graphql";
import React, { useMemo } from "react";
import { FieldArrayWithId } from "react-hook-form";
import { NoRowsOverlay } from "./NoRowsOverlay";
import { IssueCount } from "components/IssueCount";

type FacilityProductStorageLocationsProps = {
  storageLocations: FieldArrayWithId<
    FacilityProductInput,
    "storageLocations",
    "id"
  >[];
  handleEditStorageLocation: (
    storageLocation: FacilityProductStorageLocationInput
  ) => void;
  handleDeleteStorageLocation: (
    storageLocation: FacilityProductStorageLocationInput
  ) => void;
  showDialog: () => void;
};

export const FacilityProductStorageLocations: React.FC<
  FacilityProductStorageLocationsProps
> = ({
  storageLocations,
  handleEditStorageLocation,
  showDialog,
  handleDeleteStorageLocation,
}) => {
  const theme = useTheme();
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        headerName: "Location",
        field: "description",
        flex: 0.5,
        valueGetter: ({ row }) => row.storageLocation.description,
      },
      {
        headerName: "Max Quantity",
        field: "maxQuantity",
        valueGetter: ({ row }) => row.maxQuantity,
        flex: 0.5,
      },
      {
        field: "issues",
        headerName: "Issues",
        flex: 1,
        renderCell: ({ row }) => {
          return <IssueCount issueCount={row.issueCount} />;
        },
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.25,
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => handleEditStorageLocation(params.row)}
            key={1}
            label="Edit"
            icon={<Edit />}
          />,
          <GridActionsCellItem
            onClick={() => handleDeleteStorageLocation(params.row)}
            key={2}
            label="Delete"
            icon={<Delete />}
          />,
        ],
      },
    ],
    [handleEditStorageLocation, handleDeleteStorageLocation]
  );

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h6">Storage Locations</Typography>
        </Box>
        <Box>
          <Button variant="contained" size="small" onClick={() => showDialog()}>
            Add Storage Location
          </Button>
        </Box>
      </Stack>
      <Box position="relative" paddingTop={theme.spacing(2)}>
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: {
              sortModel: [
                { field: "description", sort: "asc" },
                { field: "maxQuantity", sort: "asc" },
              ],
            },
          }}
          pagination
          pageSizeOptions={[5]}
          rows={storageLocations ?? []}
          columns={columns}
          sx={{
            height: "301px", // Minimum height to not get a scrollbar with 5 rows
            width: "100%",
          }}
          onRowClick={(params) => {
            handleEditStorageLocation(params.row);
          }}
          slots={{
            noRowsOverlay: (props) => (
              <NoRowsOverlay
                {...props}
                onButtonClick={() => showDialog()}
                message="No storage locations for this product."
                buttonText="Add storage location"
              />
            ),
          }}
        />
      </Box>
    </Stack>
  );
};
