import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useState } from "react";
import Upload from "@mui/icons-material/Upload";
import { Row } from ".";
import { ChemicalFromSdsDialog } from "./ChemicalFromSdsDialog";
import { GridApiRef } from "components/OmnisearchDataGrid";

export const DocumentsBulkActionsMenu = function ({
  documents,
  apiRef,
}: {
  documents: Row[];
  apiRef: GridApiRef;
}) {
  const buttonId = "documents-bulk-actions-button";
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openDialog, setDialogOpen] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        Bulk Actions
      </Button>
      <Menu
        id="add-chemical-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        <MenuList dense>
          <MenuItem
            key="sds-upload"
            onClick={() => {
              setDialogOpen(true);
              handleClose();
            }}
            disabled={documents.length === 0}
          >
            <ListItemIcon>
              <Upload />
            </ListItemIcon>
            <ListItemText>Create Chemicals from Documents</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      {openDialog && (
        <ChemicalFromSdsDialog
          open={openDialog}
          onClose={() => setDialogOpen(false)}
          documents={documents}
          apiRef={apiRef}
        />
      )}
    </>
  );
};
