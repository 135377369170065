import useOmnisearchFilters from "../../../../components/Omnisearch/useOmnisearch";

export enum AssignmentManagementTableFilterKeys {
  Organization = "organization",
  Facility = "facility",
  State = "state",
  Activities = "activities",
  Assignee = "assignee",
  IsError = "iserror",
  ActivityType = "activityType",
  ExecutionPlan = "executionPlan",
  EncampStatus = "encampStatus",
  OrganizationStatus = "organizationStatus",
  ActivityStatus = "activityStatus",
  Unverified = "unverified",
  // This one need not be used, it's the exact opposite of unverified
  Verified = "verified",
}

export function useAssignmentManagerOmnisearch() {
  const {
    omnisearch,
    updateOmnisearch: update,
    removeOmnisearch: remove,
    getFilter: get,
    getOmnisearchUrl: getUrl,
  } = useOmnisearchFilters();
  const updateOmnisearch = (
    filters: [AssignmentManagementTableFilterKeys, string][],
    clearFirst?: boolean
  ) => {
    update(filters, clearFirst);
  };
  const removeOmnisearch = (filter: AssignmentManagementTableFilterKeys) => {
    remove(filter);
  };
  const getFilter = (filter: AssignmentManagementTableFilterKeys) => {
    return get(filter);
  };
  const getOmnisearchUrl = (
    filters: [AssignmentManagementTableFilterKeys, string][]
  ) => {
    return getUrl(`${window.location.origin}/staff/fulfillment`, filters);
  };
  return {
    omnisearch,
    updateOmnisearch,
    removeOmnisearch,
    getFilter,
    getOmnisearchUrl,
  };
}
