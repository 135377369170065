import { useProducts } from "hooks/useProducts";
import { Navigate } from "react-router-dom";

export function InventoryDefaultNavigation() {
  const { hasProducts } = useProducts();

  return hasProducts ? (
    <Navigate to="products" replace />
  ) : (
    <Navigate to="chemicals" replace />
  );
}
