import { MenuItem, Select, SelectProps, useTheme } from "@mui/material";
import { useQueryParams } from "hooks/useQueryParams";
import { useEffect } from "react";
import { useReport } from "routes/Customer/Chemicals/Report/useReport";
import useLocalStorageState from "use-local-storage-state";

export type TierIIYearPickerProps = {
  selectProps?: SelectProps;
};

export function TierIIYearPicker(props: TierIIYearPickerProps) {
  const reportingYear = useSelectedReportingYear();
  const {
    filters: { ry },
    setFilters,
  } = useQueryParams<{ ry: string }>();

  const [persistedRy, setPersistedRy] = useLocalStorageState<
    string | undefined
  >("ry", {
    defaultValue: ry,
  });

  useEffect(() => {
    if (persistedRy && !ry) {
      setFilters((f) => {
        f.ry = persistedRy;
      });
    }
  }, [persistedRy, ry, setFilters, setPersistedRy]);

  useEffect(() => {
    if (ry !== persistedRy) {
      setPersistedRy(ry);
    }
  }, [persistedRy, ry, setPersistedRy]);

  const theme = useTheme();
  const numberOfOptions = 10;
  const currentYear = new Date().getFullYear();
  const { sx: selectSx, ...selectProps } = props.selectProps || {};

  return (
    <Select
      variant="outlined"
      value={reportingYear}
      onChange={(e) => {
        const val = String(e.target.value);
        setFilters((f) => {
          f.ry = val;
        });
      }}
      sx={{
        color: theme.palette.primary.main,
        ...selectSx,
      }}
      {...selectProps}
    >
      {new Array(numberOfOptions).fill(0).map((_, i) => (
        <MenuItem key={i} value={currentYear - i}>
          {currentYear - i}
        </MenuItem>
      ))}
    </Select>
  );
}

/**
 * This hook will return the currently viewed reporting year.
 * If we're in a report, it will know to return the report's reporting year.
 * If we're not in a report, it will look for a reporting year query param, and return that.
 * Otherwise, it will return the current year.
 * @returns number
 */
export function useSelectedReportingYear(): number {
  const { data } = useReport();

  const {
    filters: { ry },
  } = useQueryParams<{ ry: string }>();

  // First, check if we have a valid reporting year from the report data
  if (data?.tierIIReport.reportingYear !== undefined) {
    return data.tierIIReport.reportingYear;
  }

  // If not, check if we have a valid ry query parameter
  if (ry !== undefined && ry !== "") {
    const parsedRy = Number(ry);
    if (!isNaN(parsedRy)) {
      return parsedRy;
    }
  }

  // If all else fails, return the current year
  return new Date().getFullYear();
}
