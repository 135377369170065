import { useApolloClient } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Upload from "@mui/icons-material/Upload";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useTenant } from "hooks/useTenant";
import { useToken } from "providers/token";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { uploadSDS } from "util/uploadFile";
import { SDSUploadDialog } from "./SDSUploadDialog";
import { TENANT_CATALOG_CHEMICALS } from "./schema";

export const AddChemicalButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { getIdTokenRefreshIfExpired } = useToken();
  const sdsUploadRef = useRef<null | HTMLInputElement>(null);

  const [sdsJobId, setSdsJobId] = useState<string | null>(null);

  const { tenantId } = useTenant();
  const alerts = useAlerts();

  const buttonId = "add-chemical-actions-button";

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        Add Chemical
      </Button>
      <Menu
        id="add-chemical-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        <MenuList dense>
          <MenuItem key="add" onClick={() => navigate("new")}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText>Add Chemical</ListItemText>
          </MenuItem>

          <MenuItem
            key="sds-upload"
            onClick={() => sdsUploadRef.current?.click()}
          >
            <ListItemIcon>
              <Upload />
            </ListItemIcon>
            <ListItemText>Add Chemical from SDS</ListItemText>
            <input
              ref={sdsUploadRef}
              type="file"
              hidden
              multiple
              accept="application/pdf"
              onChange={async (event) => {
                const files = event.target.files;
                if (files && files.length) {
                  try {
                    const idToken = await getIdTokenRefreshIfExpired();
                    const { jobId } = await uploadSDS(
                      files,
                      tenantId ?? "",
                      idToken
                    );
                    setSdsJobId(jobId);
                  } catch (error) {
                    alerts.error(
                      "There was an error uploading the file(s)",
                      error
                    );
                  }
                }
              }}
            />
          </MenuItem>
        </MenuList>
      </Menu>

      {sdsJobId && (
        <SDSUploadDialog
          jobId={sdsJobId}
          onClose={async () => {
            setSdsJobId(null);
            await apolloClient.refetchQueries({
              include: [TENANT_CATALOG_CHEMICALS],
            });
          }}
        />
      )}
    </>
  );
};
