import { useMutation } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";

const VERIFY_CREDENTIAL_MUTATION = gql(`
    mutation VerifyCredential($input: VerifyCredentialInput!) {
        verifyCredential(input: $input) {
            facilityId
            queued
        }
    }`);

export const useVerifyCredential = () => {
  const alerts = useAlerts();
  return useMutation(VERIFY_CREDENTIAL_MUTATION, {
    onCompleted() {
      alerts.success(
        "Successfully initiated the credential verification process"
      );
    },
    onError(err) {
      alerts.error(
        "There was an error initiating the credential verification process",
        err
      );
    },
  });
};

const VERIFY_CREDENTIALS_MUTATION = gql(`
mutation VerifyCredentials($input: [VerifyCredentialInput!]!) {
  verifyCredentials(input: $input) {
    count
    results {
      facilityId
      queued
    }
  }
}`);

export const useVerifyCredentials = () => {
  return useMutation(VERIFY_CREDENTIALS_MUTATION);
};
