import { Box } from "@mui/material";
import { HasFeature } from "components/HasFeature";
import { useParams } from "react-router-dom";
import { useReport } from "../../Report/useReport";
import { FacilityChemicalMeasurementDetailsTable } from "./FacilityChemicalMeasurementDetailsTable";

export function FacilityChemicalMeasurementDetails() {
  const { facilityId, chemicalId } = useParams();

  const { data } = useReport();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <HasFeature
        measurements
        fallback="../chemicals"
        facilityId={facilityId ?? data?.tierIIReport?.facility.id ?? ""}
      >
        <FacilityChemicalMeasurementDetailsTable
          facilityId={facilityId ?? data?.tierIIReport?.facility.id ?? ""}
          chemicalId={chemicalId ?? ""}
          reportId={data?.tierIIReport.id}
        />
      </HasFeature>
    </Box>
  );
}
