import { FacilityProductStorageLocationInput } from "generated-graphql/graphql";
import { v4 as uuid } from "uuid";

export function transform(
  storageLocation: FacilityProductStorageLocationInput
) {
  return {
    id: storageLocation.id === "NEW" ? uuid() : storageLocation.id,
    storageLocation: storageLocation.storageLocation ?? {
      description: "",
      facilityId: "",
      id: "",
    },
    maxQuantity: storageLocation.maxQuantity,
  };
}
