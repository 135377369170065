import { Button, useMediaQuery, useTheme } from "@mui/material";
import { IssueListTooltip } from "components/IssueListTooltip";
import { Issue } from "generated-graphql/graphql";
import pluralize from "pluralize";
import { useMemo, useState } from "react";

export const IssueListButton = ({ issues }: { issues: Issue[] }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen((open) => !open);
  };

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const label = useMemo(() => {
    const numErrors = issues.length;
    return isLargeScreen
      ? `${numErrors > 0 ? numErrors : "No"} Compliance ${pluralize(
          "Issue",
          numErrors
        )}`
      : "Issues";
  }, [isLargeScreen, issues.length]);

  return (
    <IssueListTooltip
      issues={issues}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Button
        type="button"
        color="error"
        onClick={toggleTooltip}
        disabled={!issues?.length}
        variant="outlined"
      >
        {label}
      </Button>
    </IssueListTooltip>
  );
};
