import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { ImportButton } from "components/ImportButton";
import { ReactNode, useState } from "react";

interface ImportDialogProps {
  importers: { name: string; importer: ReactNode }[];
  sx?: SxProps;
}

export function ImportDialog({
  importers,
  open,
  onClose,
}: ImportDialogProps & Pick<DialogProps, "open" | "onClose">) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Choose Import Template</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {importers.map((importer) => (
            <Stack direction="row" sx={{ minWidth: 400 }} key={importer.name}>
              <Typography flexGrow={1}>{importer.name}</Typography>
              {importer.importer}
            </Stack>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export function ImportButtonWithDialog(props: ImportDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ImportButton onClick={() => setIsOpen(true)} />
      <ImportDialog
        importers={props.importers}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}
