import Check from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useTheme,
} from "@mui/material";
import { ActivityStatus } from "generated-graphql/graphql";
import { useState } from "react";
import { ActivityButtonProps } from ".";
import { useUpdateActivity } from "../../../../../../hooks/activities";
import { useReportDetails } from "../../useReportDetails";

export const CompleteActivity = ({
  activity,
  showWarningPanel = false,
}: ActivityButtonProps & {
  showWarningPanel?: boolean;
}) => {
  const [isCompletionDialogOpen, setIsCompletionDialogOpen] = useState(false);

  const { data } = useReportDetails();
  const [updateActivity, { loading: isActivityUpdating }] = useUpdateActivity();

  const submitCompletion = async () => {
    await updateActivity({
      variables: {
        id: activity.id,
        input: {
          status: ActivityStatus.StaffCompleted,
          message: `Manually completed activity from report details page. Clearing report tags: ${data?.tierIIReport.tags}.`,
        },
      },
    });
  };

  const onClickComplete = () => {
    if (showWarningPanel) {
      setIsCompletionDialogOpen(true);
    } else {
      submitCompletion();
    }
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        color="primary"
        loading={isActivityUpdating}
        loadingPosition="start"
        startIcon={<Check />}
        onClick={() => onClickComplete()}
      >
        Complete activity
      </LoadingButton>
      <CompletionDialog
        activityId={activity.id}
        onSubmit={submitCompletion}
        isSubmitting={isActivityUpdating}
        isOpen={isCompletionDialogOpen}
        onCloseDialog={() => setIsCompletionDialogOpen(false)}
      />
    </>
  );
};
interface Props {
  activityId: string;
  isOpen: boolean;
  onCloseDialog: () => void;
  onSubmit: (message?: string | undefined) => void;
  isSubmitting: boolean;
}

const CompletionDialog = ({
  isOpen,
  onCloseDialog,
  onSubmit,
  isSubmitting,
}: Props) => {
  const theme = useTheme();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const disableSubmit = name === "" || description === "";

  const onClose = () => {
    setName("");
    setDescription("");
    onCloseDialog();
  };

  const handleSubmit = () => {
    onSubmit(`Completed by ${name} - ${description}`);
    onClose();
  };

  return (
    <Dialog open={isOpen} sx={{ padding: theme.spacing(2) }}>
      <DialogTitle>Manually Complete Activity</DialogTitle>
      <DialogContent sx={{ minWidth: "300px" }}>
        <DialogContentText>
          Please describe why it is safe to manually complete this activity.
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          multiline
          rows={4}
          id="description"
          label="Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <LoadingButton
          disabled={disableSubmit}
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Complete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
