import { Grid, GridProps, Typography } from "@mui/material";

type FilterSelectionsProps = {
  facilityType?: string | null;
  programArea?: string | null;
  gridProps?: GridProps;
};

export function FilterSelections({
  facilityType,
  programArea,
  gridProps,
}: FilterSelectionsProps) {
  return (
    <Grid item {...gridProps}>
      {facilityType && (
        <Typography variant="body2" textAlign="right">
          Facility type:{" "}
          <Typography variant="body2" component="span" fontWeight={200}>
            {facilityType}
          </Typography>
        </Typography>
      )}
      {programArea && (
        <Typography variant="body2" textAlign="right">
          Facility program area:{" "}
          <Typography variant="body2" component="span" fontWeight={200}>
            {programArea}
          </Typography>
        </Typography>
      )}
    </Grid>
  );
}
