import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  LinearProgress,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import { OmnisearchAutocomplete } from "components/OmnisearchAutocomplete";
import { Facility, T2SFacility, T2S_FACILITIES_QUERY } from "./queries";

type FacilityMapperProps = {
  t2sFacilities: T2SFacility[];
  defaultSearch: string;
  onProgressChange: (progress: number) => void;
  onComplete: (mappedFacilities: (Facility | null)[]) => void;
};

export const T2sImportFacilityMapper: React.FC<FacilityMapperProps> = ({
  t2sFacilities,
  defaultSearch,
  onProgressChange,
  onComplete,
}) => {
  const [mappedFacilities, setMappedFacilities] = useState<
    (Facility | null | undefined)[]
  >(new Array(t2sFacilities.length).fill(undefined));

  const progress = useMemo(() => {
    return (
      (mappedFacilities.filter((f) => f !== undefined).length /
        t2sFacilities.length) *
      100
    );
  }, [mappedFacilities, t2sFacilities.length]);

  useEffect(() => {
    onProgressChange(progress);
    if (progress === 100) {
      onComplete(mappedFacilities as (Facility | null)[]);
    }
  }, [progress, onProgressChange, onComplete, mappedFacilities]);

  const handleFacilityMap = (
    index: number,
    facility: Facility | null | undefined
  ) => {
    setMappedFacilities((prev) => {
      const updated = [...prev];
      updated[index] = facility;
      return updated;
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Map T2S Facilities to Existing Facilities
      </Typography>
      <LinearProgress variant="determinate" value={progress} sx={{ mb: 2 }} />
      {t2sFacilities.map((facility, index) => (
        <Paper
          key={`${facility.name}.${index}`}
          elevation={2}
          sx={{ p: 2, mb: 2, position: "relative" }}
        >
          {mappedFacilities[index] === undefined && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleFacilityMap(index, null)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >
              Skip
            </Button>
          )}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>{facility.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {facility.city}, {facility.state}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Chemicals: {facility.chemicals} | Contacts: {facility.contacts}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle1">
                Match to Existing Facility
              </Typography>
              {mappedFacilities[index] === undefined ? (
                <OmnisearchAutocomplete
                  runInitialSearch
                  dataQuery={T2S_FACILITIES_QUERY}
                  defaultSearch={`${defaultSearch}`}
                  dataQueryVariables={{
                    sort: [{ field: "name", sort: "asc" }],
                  }}
                  getItems={(data) => data.facilities.items}
                  onSelectionChange={(selected: Facility | null) => {
                    handleFacilityMap(index, selected);
                  }}
                  renderOption={(props, item) => (
                    <li {...props}>
                      <Typography>
                        {item.name} - {item.city}, {item.state}
                      </Typography>
                    </li>
                  )}
                />
              ) : (
                <Box>
                  {mappedFacilities[index] ? (
                    <>
                      <Typography variant="body1">
                        Mapped to: {mappedFacilities[index]?.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {mappedFacilities[index]?.city},{" "}
                        {mappedFacilities[index]?.state}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      Skipped
                    </Typography>
                  )}
                  <Button
                    startIcon={<UndoIcon />}
                    onClick={() => handleFacilityMap(index, undefined)}
                    sx={{ mt: 1 }}
                  >
                    Change
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};
