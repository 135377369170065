import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { TierIiReportVerifier } from "generated-graphql/graphql";
import { useCallback } from "react";
import { useReportDetails } from "routes/Staff/Fulfillment/ReportDetails/useReportDetails";

const VALIDATE_VERIFY_INPUT = gql(`
  query ValidateVerifyInput($id: ID!, $facilityId: ID!, $data: TierIIReportVerifier!) {
    tierIIReportVerifyStepValidator(id: $id, facilityId: $facilityId, data: $data) {
      ...issue
    }
  }
`);

export function useVerifyInputValidation() {
  const { data: reportDetailsData } = useReportDetails();
  const [query] = useLazyQuery(VALIDATE_VERIFY_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: TierIiReportVerifier) => {
      const { data } = await query({
        variables: {
          id: reportDetailsData?.tierIIReport.id ?? "",
          facilityId: reportDetailsData?.tierIIReport.facility.id ?? "",
          data: input,
        },
      });

      return data?.tierIIReportVerifyStepValidator ?? [];
    },
    [
      query,
      reportDetailsData?.tierIIReport.facility.id,
      reportDetailsData?.tierIIReport.id,
    ]
  );
}
