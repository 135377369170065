import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { PoliceDepartmentsQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useMemo, useState } from "react";
import { ConfirmDeleteNJPoliceDepartments } from "./ConfirmDeleteNJPoliceDepartments";
import { PoliceDepartmentDialog } from "./PoliceDepartmentDialog";
import { GET_ALL_NJ_POLICE_DEPARTMENTS } from "./schema";

type Row = PoliceDepartmentsQuery["policeDepartments"]["items"][number];

type NJPoliceDepartmentsProps = {
  initialPageSize?: number;
};

export const NJPoliceDepartments: React.FC<NJPoliceDepartmentsProps> = ({
  initialPageSize,
}) => {
  const [openAgencyDialog, setOpenAgencyDialog] = useState<{
    mode: "add" | "edit";
    agency?: Row;
  } | null>(null);

  const [confirmDeletePoliceDepartment, setConfirmDeletePoliceDepartment] =
    useState<Row | null>(null);

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 50 },
      { field: "name", headerName: "Name", flex: 1 },
      { field: "address", headerName: "Address", flex: 1 },
      { field: "city", headerName: "City", flex: 1 },
      { field: "state", headerName: "State", width: 75 },
      { field: "zip", headerName: "Zip", width: 75 },
      { field: "email", headerName: "Email", flex: 1 },
      { field: "contactName", headerName: "Contact Name", flex: 1 },
      {
        field: "actions",
        type: "actions",
        align: "left",
        getActions: ({ row }) => {
          return [
            <Tooltip title="Edit Agency" key={1}>
              <GridActionsCellItem
                label="Edit Agency"
                onClick={() =>
                  setOpenAgencyDialog({ mode: "edit", agency: row })
                }
                icon={<Edit />}
              />
            </Tooltip>,
            <Tooltip title="Delete Agency" key={2}>
              <GridActionsCellItem
                label="Delete Agency"
                onClick={() => setConfirmDeletePoliceDepartment(row)}
                icon={<Delete />}
              />
            </Tooltip>,
          ];
        },
      },
    ],
    []
  );

  return (
    <Box sx={{ pt: 3, padding: 2 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={GET_ALL_NJ_POLICE_DEPARTMENTS}
        getItems={(data: any) => data.policeDepartments.items ?? []}
        getCount={(data: any) => data.policeDepartments.count ?? []}
        noDataMessage="No Agencies found."
        initialPageSize={initialPageSize ?? 25}
        onRowClick={({ row }) => {
          setOpenAgencyDialog({ mode: "edit", agency: row });
        }}
        commandButtons={[
          <Button
            key="add-agency-button"
            variant="contained"
            onClick={() => setOpenAgencyDialog({ mode: "add" })}
          >
            Add NJ Police Department
          </Button>,
        ]}
      />
      {!!openAgencyDialog && (
        <PoliceDepartmentDialog
          onClose={() => setOpenAgencyDialog(null)}
          agencyType="NJ Police Department"
          mode={openAgencyDialog.mode}
          agencyId={openAgencyDialog?.agency?.id}
        />
      )}

      {confirmDeletePoliceDepartment && (
        <ConfirmDeleteNJPoliceDepartments
          agencyId={confirmDeletePoliceDepartment.id}
          onClose={() => setConfirmDeletePoliceDepartment(null)}
        />
      )}
    </Box>
  );
};
