import AsyncAutocomplete from "components/Forms/AsyncAutocomplete";
import { AssigneePickerFragment } from "generated-graphql/graphql";
import { GET_USERS_QUERY } from "./userQueries";
import { ControllerFieldState } from "react-hook-form";

export const AssigneePicker = ({
  tenantId,
  value,
  onChange,
  error,
}: {
  tenantId: string;
  value: AssigneePickerFragment | null;
  onChange: (item: AssigneePickerFragment | null) => void;
} & ControllerFieldState) => {
  return (
    <AsyncAutocomplete
      query={GET_USERS_QUERY}
      getItems={(data) => data.users.items}
      placeholderLabel="Assignee *"
      getOptionLabel={(item) => {
        const fullName = item?.person
          ? `${item.person.first ?? ""} ${item.person.last ?? ""}`.trim() ||
            null
          : null;
        return fullName ? `${fullName} (${item.email})` : item.email;
      }}
      onChange={onChange}
      value={value}
      additionalSearchString={`tenantId:${tenantId}`}
      error={error}
    />
  );
};
