import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { ReactNode } from "react";

type SaveButtonProps = {
  loading: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  saveText?: string;
  startIcon?: ReactNode;
  formId?: string;
};

export const SaveButton: React.FC<SaveButtonProps> = ({
  loading: isLoading,
  disabled: isDisabled,
  onClick,
  formId,
  saveText = "Save",
  startIcon = <SaveOutlined />,
}) => {
  return (
    <LoadingButton
      type="submit"
      variant="contained"
      loading={isLoading}
      loadingPosition="start"
      disabled={isDisabled}
      startIcon={startIcon}
      onClick={onClick}
      form={formId}
    >
      {saveText}
    </LoadingButton>
  );
};
