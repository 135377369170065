import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { FireDepartmentInput } from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import {
  GET_FIRE_DEPARTMENT,
  UPSERT_FIRE_DEPARTMENT,
  FIRE_DEPARTMENT_SEARCH,
  VALIDATE_FIRE_DEPARTMENT_INPUT,
} from "./api";
import { CheckboxField } from "components/Forms/CheckboxField";
import { useCallback } from "react";
import { transform } from "hooks/transform/transformFireDepartment";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { STATES } from "encamp-shared/src/constants/states";
import { hasCriticalIssues } from "util/forms";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";

export function useFireDepartmentInputValidation(isCreate: boolean) {
  const [query] = useLazyQuery(VALIDATE_FIRE_DEPARTMENT_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: FireDepartmentInput) => {
      const { data } = await query({
        variables: {
          data: transform(input),
          id: !isCreate ? input.id : undefined,
        },
      });

      return data?.fireDepartmentInputValidator ?? [];
    },
    [query, isCreate]
  );
}

type FireDepartmentDialogProps = {
  agencyType: string;
  onClose: () => void;
  mode?: "add" | "edit";
  fireDepartmentId?: string;
};

export const CreateEditFireDepartmentDialog: React.FC<
  FireDepartmentDialogProps
> = ({ agencyType, onClose, mode, fireDepartmentId }) => {
  const theme = useTheme();
  const alerts = useAlerts();
  const [additionalFieldsOpen, setAdditionalFieldsOpen] = useState(false);

  const { data: agency, loading: isLoadingAgency } = useQuery(
    GET_FIRE_DEPARTMENT,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: fireDepartmentId ?? "",
      },
      skip: !fireDepartmentId,
    }
  );

  const [upsertFireDepartment, { loading: isUpserting }] = useMutation(
    UPSERT_FIRE_DEPARTMENT,
    { refetchQueries: ["FireDepartment"] }
  );

  const defaultValues: FireDepartmentInput = useMemo(
    () => ({
      id: agency?.fireDepartment.id ?? undefined,
      name: agency?.fireDepartment?.name ?? "",
      mailingAddress: agency?.fireDepartment?.mailingAddress ?? "",
      mailingAddressLine2: agency?.fireDepartment?.mailingAddressLine2 ?? "",
      city: agency?.fireDepartment?.city ?? "",
      state: agency?.fireDepartment?.state ?? "",
      zip: agency?.fireDepartment?.zip ?? "",
      county: agency?.fireDepartment?.county ?? "",
      phone: agency?.fireDepartment?.phone ?? "",
      email: agency?.fireDepartment?.email ?? "",
      firstName: agency?.fireDepartment?.firstName ?? "",
      lastName: agency?.fireDepartment?.lastName ?? "",
      title: agency?.fireDepartment?.title ?? "",
      latitude: agency?.fireDepartment?.latitude ?? null,
      longitude: agency?.fireDepartment?.longitude ?? null,
      additionalComments: agency?.fireDepartment?.additionalComments ?? "",
      stateSubmitsToFireDept:
        agency?.fireDepartment?.stateSubmitsToFireDept ?? false,
      needsT2sFile: agency?.fireDepartment?.needsT2sFile ?? false,
      isMailed: agency?.fireDepartment?.isMailed ?? false,
      isEmailed: agency?.fireDepartment?.isEmailed ?? false,
      isSubmittedViaPortal:
        agency?.fireDepartment?.isSubmittedViaPortal ?? false,
      portalId: agency?.fireDepartment?.portalId ?? "",
      portalName: agency?.fireDepartment?.portalName ?? "",
    }),
    [agency]
  );

  const form = useValidatingForm<FireDepartmentInput>(
    defaultValues,
    agency?.fireDepartment.issues ?? [],
    useFireDepartmentInputValidation(mode === "add")
  );

  const {
    handleSubmit,
    control,
    getValues,
    issues,
    formState: { errors },
  } = form;

  const onSubmit = async () => {
    const input = {
      ...getValues(),
    };

    await upsertFireDepartment({
      variables: {
        id: fireDepartmentId,
        input: transform(input),
      },
      onCompleted: (data) => {
        onClose();
        alerts.success("Agency saved successfully");
      },
      onError: (error) => {
        alerts.error(
          error.message ?? "An error occurred while saving the fire department"
        );
      },
      refetchQueries: [FIRE_DEPARTMENT_SEARCH],
    });
  };

  const savingOrLoading = isLoadingAgency || isUpserting;
  return (
    <>
      <Dialog open onClose={onClose} maxWidth="lg">
        <DialogTitle>
          {mode === "add" ? "Add" : "Edit"} {agencyType}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 500 }}>
          <FormProvider {...form}>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                width: 500,
              }}
            >
              <Grid container spacing={4} justifyContent="center" pt={1}>
                <Grid item xs={12}>
                  <FormTextField
                    name="name"
                    label="Name"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField
                    name="mailingAddress"
                    label="Mailing Address"
                    control={control}
                  />
                  <FormTextField
                    name="mailingAddressLine2"
                    label="Mailing Address Line 2"
                    control={control}
                  />
                  <FormTextField
                    name="city"
                    label="City"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField
                    name="county"
                    label="County"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={6}>
                      <FormSelect
                        name="state"
                        label="State"
                        control={control}
                        selectItems={STATES.map((state) => ({
                          value: state,
                          display: state,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextField
                        name="zip"
                        label="Zip"
                        control={control}
                        textFieldProps={{ required: true }}
                      />
                    </Grid>
                  </Grid>

                  <FormTextField
                    name="phone"
                    label="Phone"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField
                    name="phoneExt"
                    label="Phone Ext"
                    control={control}
                  />
                  <FormTextField name="email" label="Email" control={control} />
                  <FormTextField
                    name="additionalComments"
                    label="Additional Comments"
                    control={control}
                    textFieldProps={{ multiline: true }}
                  />
                  <Grid item xs={12}>
                    <ErrorDisplay
                      errors={errors}
                      fields={[
                        "isEmailed",
                        // These are commented out because they all share
                        // essentially the same error message, and it's more
                        // clear if the message is only listed once
                        // -  "isMailed",
                        // -  "isSubmittedViaPortal",
                        // -  "isStateSubmits",
                      ]}
                    />
                  </Grid>
                  <CheckboxField
                    name="needsT2sFile"
                    label="Needs T2s File"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="isEmailed"
                    label="Is Emailed"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="isMailed"
                    label="Is Mailed"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="isSubmittedViaPortal"
                    label="Is submitted via portal"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="stateSubmitsToFireDept"
                    label="Is submitted by the state"
                    control={control}
                    noErrorDisplay
                  />

                  <Typography
                    onClick={() => setAdditionalFieldsOpen((prev) => !prev)}
                    sx={{ cursor: "pointer" }}
                    my={3}
                    color="primary"
                    gutterBottom
                  >
                    Show Additional Fields
                  </Typography>
                  <Collapse
                    in={additionalFieldsOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <FormTextField
                      name="firstName"
                      label="First Name"
                      control={control}
                    />
                    <FormTextField
                      name="lastName"
                      label="Last Name"
                      control={control}
                    />
                    <FormTextField
                      name="title"
                      label="Title"
                      control={control}
                    />
                    <FormTextField
                      name="latitude"
                      label="Latitude"
                      control={control}
                    />
                    <FormTextField
                      name="longitude"
                      label="Longitude"
                      control={control}
                    />
                    <FormTextField
                      name="portalId"
                      label="Portal Id"
                      control={control}
                    />
                    <FormTextField
                      name="portalName"
                      label="Portal Name"
                      control={control}
                    />
                  </Collapse>
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
          <Button onClick={onClose}>Cancel</Button>
          <SaveButton
            loading={savingOrLoading}
            saveText="Save"
            onClick={onSubmit}
            disabled={
              hasCriticalIssues(issues) ||
              (mode === "add" && !form.formState.isDirty)
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
