import { Grid, Skeleton, SxProps, useTheme } from "@mui/material";
import { useMemo } from "react";

/*
 * Skeleton components to match existing components
 */

/**
 * Creates a skeleton similar in size to a `FormTextField` to display while loading.
 */
export const FormTextFieldSkeleton = ({
  kind = "short",
}: {
  kind?: "short" | "long";
}) => {
  const theme = useTheme();
  return (
    <Skeleton
      variant="rounded"
      sx={{ mb: theme.spacing(1) }}
      height={kind === "short" ? 50 : 125}
    />
  );
};

/**
 * Creates a list of form field like skeletons to display while loading.
 * @example
 * ```tsx
 * <SkeletonFormGroup count={4} />
 * ```
 * @param {Object} params
 * @param {number} params.count Number of form fields
 * @param {SxProps} [params.gridSx] Additional sx for the outer grid
 */
export const SkeletonFormGroup = ({
  count,
  gridSx,
}: {
  count: number;
  gridSx?: SxProps;
}) => {
  const theme = useTheme();
  const items = useMemo(() => new Array(count).fill(1), [count]);

  return (
    <Grid container spacing={theme.spacing(3)} sx={gridSx}>
      {items.map((_, i) => (
        <Grid item xs={12} key={i}>
          <FormTextFieldSkeleton />
        </Grid>
      ))}
    </Grid>
  );
};
