import { Alert, useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useReport } from "routes/Customer/Chemicals/Report/useReport";
import { REPORTING_FACILITY_CHEMICALS_QUERY } from "routes/Customer/Chemicals/ReportingFacilityChemical/ReportingFacilityChemicalTable";
import {
  GetReportDetailsQuery,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { useMemo } from "react";

export function useReportChemicals(report?: GetReportDetailsQuery) {
  const { data, loading } = useQuery(REPORTING_FACILITY_CHEMICALS_QUERY, {
    variables: {
      search: `reportingYear:${report?.tierIIReport?.reportingYear} facilityId:${report?.tierIIReport?.facility.id}`,
    },
  });

  return { data, loading };
}

export function checkChemicalSteps(report?: GetReportDetailsQuery) {
  const chemicalSteps: TierIiReportStep[] = [
    TierIiReportStep.Chemicals,
    TierIiReportStep.ChemicalSummary,
    TierIiReportStep.OtherChemicals,
    TierIiReportStep.Products,
  ];
  return report?.tierIIReport?.touchedSteps?.some((s) =>
    chemicalSteps.includes(s)
  );
}

export function useHasChemicalsToReport() {
  const { data: report } = useReport();
  const { data, loading } = useReportChemicals(report);
  const hasChemicalsToReport = useMemo(() => {
    return (
      !!data?.reportingFacilityChemicals.items.length &&
      data.reportingFacilityChemicals.items.some(
        (c) => c.isOverThreshold || c.isAlwaysReported
      )
    );
  }, [data]);
  return { hasChemicalsToReport, loading };
}

export function ReportHasNoChemicalsAlert() {
  const theme = useTheme();
  const { data: report } = useReport();
  const hasStepsTouched = checkChemicalSteps(report);
  const { hasChemicalsToReport, loading } = useHasChemicalsToReport();

  if (loading || hasChemicalsToReport === true || !hasStepsTouched) {
    return <></>;
  }

  return (
    <Alert sx={{ mb: theme.spacing(2) }} severity="info">
      This facility has no chemicals over threshold and may not need to file a
      Tier II report.
    </Alert>
  );
}
