import { TableRow, TableCell, useTheme, SxProps } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useState } from "react";

type StyledTableCellProps = {
  isFirst?: boolean;
  isSelected?: boolean;
  isBold?: boolean;
  isLeftMost?: boolean;
  isBottomMost?: boolean;
  isTopMost?: boolean;
  isCentered?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
  style?: React.CSSProperties;
  onClick?: () => void;
  ["data-testid"]?: string;
};

export const StyledTableRow: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => <TableRow sx={{ height: "1px" /* HACK!!! */ }}>{children}</TableRow>;

export const StyledTableCell: React.FC<StyledTableCellProps> = ({
  isTopMost,
  isBottomMost,
  isLeftMost,
  children,
  sx,
  onClick,
  "data-testid": testId,
}) => {
  const theme = useTheme();
  return (
    <TableCell
      sx={{
        height: "inherit",
        paddingTop: isTopMost ? theme.spacing(1) : theme.spacing(0.5),
        paddingBottom: isBottomMost ? theme.spacing(1) : theme.spacing(0.5),
        paddingLeft: isLeftMost ? theme.spacing(1) : theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        whiteSpace: "pre-wrap",
        ...sx,
      }}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </TableCell>
  );
};

export const EmptyCell: React.FC = () => (
  <StyledTableCell sx={{ border: "none", padding: 0 }} />
);

export const FacilityCountCell: React.FC<StyledTableCellProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <StyledTableCell
      sx={{
        border: "none",
        fontStyle: "italic",
        color: theme.palette.text.secondary,
        justifySelf: "center",
      }}
      {...props}
    >
      {children}
    </StyledTableCell>
  );
};

export const SelectAllCell: React.FC<StyledTableCellProps> = ({
  children,
  isFirst = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <StyledTableCell
      sx={{
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderLeft: isFirst ? `1px solid ${theme.palette.grey[300]}` : "none",
        padding: "6px 8px",
        minWidth: "15rem",
        position: "sticky",
        right: 0,
        zIndex: 2,
      }}
      {...props}
    >
      {children}
    </StyledTableCell>
  );
};

export const FieldLabelCell: React.FC<StyledTableCellProps> = ({
  children,
  "data-testid": testId,
}) => {
  const theme = useTheme();
  return (
    <StyledTableCell
      sx={{
        border: "none",
        textAlign: "right",
        verticalAlign: "top",
        minWidth: "12rem",
        padding: theme.spacing(1.5, 2),
      }}
      data-testid={testId}
    >
      {children}
    </StyledTableCell>
  );
};

export const CellContent: React.FC<StyledTableCellProps> = ({
  children,
  isCentered,
  "data-testid": testId,
  style,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "top",
        justifyContent: isCentered ? "center" : "start",
        height: "100%",
        minHeight: theme.spacing(4),
        padding: theme.spacing(1),
        whiteSpace: "pre-wrap",
        ...style,
      }}
      data-testid={testId}
    >
      {children}
    </div>
  );
};

export const SelectableCellContent: React.FC<
  StyledTableCellProps & { isSelected?: boolean }
> = ({ children, isSelected, "data-testid": testId, ...props }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: props.isCentered ? "center" : "start",
        height: "100%",
        padding: theme.spacing(1),
        backgroundColor: isSelected
          ? blue[50]
          : isHovered
          ? blue[100]
          : theme?.palette.action.hover,
        cursor: "pointer",
        whiteSpace: "pre-wrap",
        ...props.style,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={props.onClick}
      data-testid={testId}
    >
      {children}
    </div>
  );
};

export const CandidateDataCell: React.FC<StyledTableCellProps> = (props) => {
  const theme = useTheme();
  return (
    <StyledTableCell
      sx={{
        borderTop: props.isBold ? `1px solid ${theme.palette.divider}` : "none",
        borderLeft: props.isLeftMost
          ? `1px solid ${theme.palette.divider}`
          : "none",
        borderBottom: props.isBottomMost
          ? `1px solid ${theme.palette.divider}`
          : "none",
        borderRight: "none",
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        fontWeight: props.isBold ? "bold" : "normal",
      }}
      {...props}
    >
      {props.children}
    </StyledTableCell>
  );
};

export const MergedHeaderCell: React.FC<StyledTableCellProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <StyledTableCell
      sx={{
        position: "sticky",
        right: 0,
        zIndex: 2,
        backgroundColor: theme.palette.grey[300],
        border: `1px solid ${theme.palette.grey[300]}`,
        minWidth: "15rem",
      }}
      {...props}
    >
      {children}
    </StyledTableCell>
  );
};

export const MergedDataCell: React.FC<StyledTableCellProps> = ({
  children,
  isBold,
  isTopMost,
  ...props
}) => {
  const theme = useTheme();
  return (
    <StyledTableCell
      sx={{
        position: "sticky",
        right: 0,
        zIndex: isBold ? 3 : 2,
        minWidth: "15rem",
        backgroundColor: theme.palette.background.paper,
        borderTop: isTopMost ? `1px solid ${theme.palette.grey[300]}` : "none",
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        fontWeight: isBold ? "bold" : "normal",
        whiteSpace: "pre-wrap",
        verticalAlign: "top",
      }}
      {...props}
    >
      {children}
    </StyledTableCell>
  );
};

export const StickyFacilityCountCell: React.FC<StyledTableCellProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <FacilityCountCell
      sx={{
        position: "sticky",
        right: 0,
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
      }}
      {...props}
    >
      {children}
    </FacilityCountCell>
  );
};
