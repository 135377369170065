import { useAuthenticator } from "@aws-amplify/ui-react";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useAlerts } from "./Alerts/AlertProvider";
import { Auth } from "aws-amplify";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import EncampWideLogo from "../assets/EncampWideLogo.svg";
import { FormTextField } from "./Forms/FormTextField";
import { PasswordAction, PasswordDialog } from "./PasswordDialog";
import { ConfirmDialog } from "./ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { SpinnerOverlay } from "./SpinnerOverlay";

type SignInFormProps = PropsWithChildren<any>;

const USER_DISABLED_MESSAGE =
  "Invalid SAML response received: User is disabled. ";

export function SignInForm({ children }: SignInFormProps) {
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showConfirmResetPasswordDialog, setShowConfirmResetPasswordDialog] =
    useState(false);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues: { email: "", password: "" },
  });
  const { authStatus } = useAuthenticator();
  const alerts = useAlerts();
  const theme = useTheme();
  const [passwordAction, setPasswordAction] = useState<PasswordAction | null>(
    null
  );
  const [user, setUser] = useState<CognitoUser | null>(null);

  const searchParams = useMemo(() => new URLSearchParams(location.search), []);

  const { email, password } = watch();

  const emailInvalid = useMemo(() => {
    if (!email?.length) return true;
    if (!/^([^\s@.]+\.)*[^\s@.]+@[^\s@.]+(\.[^\s@.]+)+$/.test(email))
      return true;

    return false;
  }, [email]);

  // render cognito error if we received one on redirect from SSO provider
  useEffect(() => {
    let cognitoErrorDescription = localStorage.getItem("error_description");
    if (cognitoErrorDescription) {
      console.log({ cognitoErrorDescription });
      // if the error cognito gave us is because the user is disabled
      // render a more user friendly message
      if (cognitoErrorDescription === USER_DISABLED_MESSAGE) {
        cognitoErrorDescription = "User is disabled.";
      }
      setError("email", { message: cognitoErrorDescription });
      localStorage.removeItem("error_description");
    }
  }, [alerts, searchParams, setError]);

  const handlePasswordLogin = useCallback(async () => {
    try {
      const user = await Auth.signIn(email.toLowerCase(), password);
      setUser(user as CognitoUser);
      const challengeName = user?.challengeName;
      if (challengeName === "NEW_PASSWORD_REQUIRED") {
        setPasswordAction(PasswordAction.NewPassword);
      }
      reset();
      setShowPasswordField(false);
    } catch (err) {
      alerts.error("Error signing in: ", err);
      if (err instanceof Error) {
        setError("password", { message: err.message });
      }
    }
  }, [email, password, alerts, reset, setError]);

  const onSubmit = useCallback<(args: { email: string }) => Promise<void>>(
    async ({ email }) => {
      try {
        const [emailLocalPart, emailDomain] = email.split("@");
        let customProvider: string;

        if (emailDomain === "encamp.com" && !emailLocalPart.includes("+")) {
          customProvider = "Google";
        } else {
          const apiUrl = `${
            import.meta.env.MODE === "development"
              ? "http://localhost:4000"
              : `${import.meta.env.VITE_API_URL}`
          }`;

          const response = await fetch(
            `${apiUrl}/tenantSso?domain=${emailDomain}`
          );

          const json = await response.json();
          customProvider = json.customProvider;

          if (customProvider == null) {
            setShowPasswordField(true);
            return;
          }

          if (typeof customProvider !== "string") return;
        }

        const redirectUri = searchParams.get("redirectUri") ?? undefined;
        const customState = {
          pathname: location.pathname,
          redirectUri,
        };

        try {
          await Auth.federatedSignIn({
            customProvider,
            customState: JSON.stringify(customState),
          });
          reset();
        } catch (err) {
          alerts.error("Error redirecting to SSO provider: ", err);
        }
      } catch (err) {
        console.error(err);
        const message =
          "There was an error retrieving sign-in information for your organization. If you are unable to sign in please contact support.";
        alerts.error(message);

        setError("password", {
          message,
        });
        // Fallback to password login
        setShowPasswordField(true);
      }
    },
    [searchParams, alerts, setError, reset]
  );

  const goBack = useCallback(() => {
    setShowPasswordField(false);
    setValue("password", "");
  }, [setValue]);

  useEffect(() => {
    if (authStatus !== "unauthenticated") return;

    const preLoginPath = `${window.location.pathname}${window.location.search}`;
    if (preLoginPath !== "/" && localStorage.getItem("logout") !== "true") {
      localStorage.setItem(
        "preLoginPath",
        `${window.location.pathname}${window.location.search}`
      );
    }

    localStorage.removeItem("logout");
  }, [authStatus]);

  if (authStatus === "configuring") return <SpinnerOverlay loading />;
  if (authStatus === "authenticated") return children;

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(/sign-in-background.webp)`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        />
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "xs",
            }}
          >
            <img
              src={EncampWideLogo}
              alt="Encamp logo"
              style={{ width: "250px" }}
            />
            <Typography
              paddingTop={theme.spacing(3)}
              component="h1"
              variant="h5"
            >
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              display={"block"}
              sx={{ mt: 1, width: "100%", maxWidth: "500px" }}
            >
              {showPasswordField ? (
                <FormTextField
                  fullWidth
                  label="Email"
                  readOnly
                  defaultValue={email}
                  helperText=""
                  textFieldProps={{
                    margin: "normal",
                  }}
                  show1Pass
                />
              ) : (
                <FormTextField
                  fullWidth
                  control={control}
                  label="Email"
                  name="email"
                  helperText=""
                  textFieldProps={{
                    required: true,
                    autoFocus: true,
                    margin: "normal",
                  }}
                  show1Pass
                  type="email"
                />
              )}

              {showPasswordField && (
                <FormTextField
                  fullWidth
                  control={control}
                  label="Password"
                  name="password"
                  type="password"
                  textFieldProps={{
                    autoFocus: true,
                    required: true,
                    margin: "normal",
                  }}
                  show1Pass
                />
              )}

              {!showPasswordField ? (
                <Grid container>
                  <Grid item xs>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      sx={{ mt: 1 }}
                      variant="contained"
                      disabled={emailInvalid}
                      loading={isSubmitting}
                    >
                      Next
                    </LoadingButton>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <LoadingButton
                    type="submit"
                    fullWidth
                    sx={{ mt: 1, mb: 2 }}
                    variant="contained"
                    disabled={!email?.length || !password?.length}
                    loading={isSubmitting}
                    onClick={handleSubmit(handlePasswordLogin)}
                  >
                    Sign In
                  </LoadingButton>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Button
                      onClick={() => setShowConfirmResetPasswordDialog(true)}
                    >
                      Forgot Password?
                    </Button>
                    <Box padding={theme.spacing(1)}>
                      <Button onClick={() => goBack()}>Back</Button>
                    </Box>
                  </Stack>
                </>
              )}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <PasswordDialog
        action={passwordAction}
        open={passwordAction != null}
        onClose={() => setPasswordAction(null)}
        email={email}
        user={user}
      />
      <ConfirmDialog
        open={showConfirmResetPasswordDialog}
        onConfirm={async () => {
          try {
            await Auth.forgotPassword(email);
          } catch (err) {
            alerts.error("Error generating reset code: ", err);
          }
          setShowConfirmResetPasswordDialog(false);
          setShowPasswordField(false);
          setPasswordAction(PasswordAction.Reset);
        }}
        onClose={() => setShowConfirmResetPasswordDialog(false)}
        title="Reset Password"
        confirmText="Reset Password"
        msg={
          'If you\'ve lost your password, click "Reset Password" to receive a one-time code to reset your password.'
        }
      />
    </>
  );
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {`© ${new Date().getFullYear()} `}
      <Link color="inherit" href="https://encamp.com/" target="_blank">
        Encamp, Inc.
      </Link>{" "}
      All Rights Reserved.
    </Typography>
  );
}
