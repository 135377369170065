import { Box } from "@mui/material";
import { OmnisearchDataGrid } from "./OmnisearchDataGrid";
import { gql } from "generated-graphql";
import { ListOrganizationsQuery } from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useMemo } from "react";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import CopyableTypography from "./Typography/CopyableTypography";

const LIST_ORGANIZATIONS = gql(/* GraphQL */ `
  query ListOrganizations(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    tenants(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...tenant
      }
      count
    }
  }
`);

export type Tenant = NonNullable<
  NonNullable<ListOrganizationsQuery["tenants"]["items"]>[number]
>;

type TenantPickerProps = {
  onTenantSelected: (tenant: Tenant) => void;
};

export function TenantPicker({ onTenantSelected }: TenantPickerProps) {
  const { isOriginalUserStaff } = useCurrentUser();
  const columns = useMemo(() => {
    const defaultColumns: OmnisearchGridColDef[] = [
      {
        field: "name",
        headerName: "Organization Name",
        flex: 1,
      },
    ];
    if (isOriginalUserStaff) {
      const additionalColumns: OmnisearchGridColDef[] = [
        {
          field: "id",
          headerName: "ID",
          flex: 1.25,
          renderCell: ({ row }) => (
            <CopyableTypography
              text={row.id}
              sx={{ fontFamily: "monospace", fontSize: "1em" }}
            />
          ),
        },
        {
          field: "isDemo",
          headerName: "Demo",
          filterKeyType: "boolean",
          flex: 0.25,
        },
        {
          field: "isDisabled",
          headerName: "Disabled",
          filterKeyType: "boolean",
          flex: 0.25,
        },
      ];
      defaultColumns.push(...additionalColumns);
    }
    return defaultColumns;
  }, [isOriginalUserStaff]);
  return (
    <Box sx={{ pt: 1 }}>
      <OmnisearchDataGrid
        autoFocus
        sx={{ height: 510 }}
        withPadding={false}
        dataQuery={LIST_ORGANIZATIONS}
        columns={columns}
        initialSortModel={[{ field: "name", sort: "asc" }]}
        getCount={(data) => data.tenants.count}
        getItems={(data) => data.tenants.items}
        isURLDriven={false}
        onRowClick={({ row }) => {
          onTenantSelected(row as Tenant);
        }}
      />
    </Box>
  );
}
