import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

type StatDisplayProps = {
  label: string;
  value: ReactNode;
  subtext?: string;
};

export const StatDisplay = ({ label, value, subtext }: StatDisplayProps) => {
  return (
    <Box py={1}>
      <Typography variant="caption" color="text.secondary" noWrap>
        {label}
      </Typography>
      <Box display="flex" alignItems="baseline">
        <Typography variant="h4" color="text.primary" component="span">
          {value}
        </Typography>
        {subtext && (
          <Typography
            variant="caption"
            color="text.secondary"
            component="span"
            sx={{ ml: 1, fontWeight: "500" }}
          >
            {subtext}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
