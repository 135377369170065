import {
  Box,
  Grid,
  Skeleton,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  TablePagination,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useState, useEffect } from "react";
import { DiffTable } from "./DiffTable";
import { NpdesDetailResult } from "generated-graphql/graphql";

const GET_NPDES_INFO = gql(`
    query GetNpdesDetail($npdesId: String!, $tenantId: String!) {
      npdesDetail(npdesId: $npdesId, tenantId: $tenantId) {
        facility {
          icisFacilityInterestId
          npdesId
          facilityUin
          facilityTypeCode
          facilityName
          locationAddress
          supplementalAddressText
          city
          countyCode
          stateCode
          zip
          geocodeLatitude
          geocodeLongitude
          impairedWaters
        }
        permits {
          activityId
          externalPermitNmbr
          versionNmbr
          facilityTypeIndicator
          permitTypeCode
          majorMinorStatusFlag
          permitStatusCode
          totalDesignFlowNmbr
          actualAverageFlowNmbr
          stateWaterBody
          stateWaterBodyName
          permitName
          agencyTypeCode
          originalIssueDate
          issueDate
          issuingAgency
          effectiveDate
          expirationDate
          retirementDate
          terminationDate
          permitCompStatusFlag
          dmrNonReceiptFlag
          rncTrackingFlag
          masterExternalPermitNmbr
          tmdlInterfaceFlag
          edmrAuthorizationFlag
          pretreatmentIndicatorCode
          radWbdHuc12s
        }
      }
    }
  `);

interface NpdesInfoDetailProps {
  id?: string;
}

const SectionHeader = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <Box marginBottom={theme.spacing(2.5)} marginLeft={theme.spacing(1)}>
      <Typography variant="body1" fontWeight={"bold"} height={theme.spacing(3)}>
        {title}
      </Typography>
    </Box>
  );
};

export const NpdesDetail: React.FC<NpdesInfoDetailProps> = ({ id }) => {
  const { npdesId: npdesIdFromUrl, tenantId } = useParams<{
    npdesId: string;
    tenantId: string;
  }>();
  const npdesId = id || npdesIdFromUrl;
  const theme = useTheme();
  const [activePermit, setActivePermit] = useState<
    NpdesDetailResult["permits"][0] | null
  >(null);
  const [previousPermit, setPreviousPermit] = useState<
    NpdesDetailResult["permits"][0] | null
  >(null);
  const [diffMode, setDiffMode] = useState<"updated" | "nodiff">("nodiff");
  const [page, setPage] = useState(0);
  const rowsPerPage = 4;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const { loading, data } = useQuery(GET_NPDES_INFO, {
    variables: { npdesId: npdesId || "", tenantId: tenantId || "" },
    skip: !npdesId || !tenantId,
  });

  useEffect(() => {
    if (data?.npdesDetail?.permits) {
      const latestPermit = data.npdesDetail.permits[0];
      setActivePermit(latestPermit);
    }
  }, [data]);

  useEffect(() => {
    if (data?.npdesDetail?.permits && activePermit) {
      const activePermitIndex = data.npdesDetail.permits.findIndex(
        (permit: any) => permit === activePermit
      );
      const previousIndex = activePermitIndex + 1;
      setPreviousPermit(
        previousIndex < data.npdesDetail.permits.length
          ? data.npdesDetail.permits[previousIndex]
          : null
      );
    }
  }, [data, activePermit]);

  // Diff Table constructors

  const facilityDetailsConstructors = [
    {
      fieldLabel: "Facility Name",
      constructor: (facility: NpdesDetailResult["facility"]) =>
        facility?.facilityName || "",
    },
    {
      fieldLabel: "Facility Type",
      constructor: (facility: NpdesDetailResult["facility"]) =>
        facility?.facilityTypeCode || "",
    },
    {
      fieldLabel: "Facility UIN",
      constructor: (facility: NpdesDetailResult["facility"]) =>
        facility?.facilityUin || "",
    },
    {
      fieldLabel: "Address",
      constructor: (facility: NpdesDetailResult["facility"]) =>
        `${[
          facility?.locationAddress,
          facility?.supplementalAddressText,
          facility?.city,
          facility?.stateCode,
        ]} ${facility?.zip || ""}`,
    },
    {
      fieldLabel: "County",
      constructor: (facility: NpdesDetailResult["facility"]) =>
        facility?.countyCode || "",
    },
    {
      fieldLabel: "Lat / Long",
      constructor: (facility: NpdesDetailResult["facility"]) =>
        `${facility?.geocodeLatitude || ""}, ${
          facility?.geocodeLongitude || ""
        }`,
    },
    {
      fieldLabel: "Impaired Waters",
      constructor: (facility: NpdesDetailResult["facility"]) =>
        facility?.impairedWaters || "",
    },
  ];

  const permitDetailsConstructors = [
    {
      fieldLabel: "Permit Name",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.permitName || "",
    },
    {
      fieldLabel: "Permit Type",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.permitTypeCode || "",
    },
    {
      fieldLabel: "Facility Type",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.facilityTypeIndicator || "",
    },
    {
      fieldLabel: "Major / Minor Status",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.majorMinorStatusFlag || "",
    },
    {
      fieldLabel: "Total Design Flow",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        `${permit?.totalDesignFlowNmbr || ""}`,
    },
    {
      fieldLabel: "Actual Average Flow",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        `${permit?.actualAverageFlowNmbr || ""}`,
    },
    {
      fieldLabel: "State Water Body",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        `${permit?.stateWaterBody || ""} - ${permit?.stateWaterBodyName || ""}`,
    },
    {
      fieldLabel: "Issuing Agency",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        `${permit?.issuingAgency || ""} (${permit?.agencyTypeCode || ""})`,
    },
    {
      fieldLabel: "Original Issue Date",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.originalIssueDate
          ? new Date(permit.originalIssueDate).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })
          : "",
    },
    {
      fieldLabel: "Issue Date",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.issueDate
          ? new Date(permit.issueDate).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })
          : "",
    },
    {
      fieldLabel: "Retirement Date",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.retirementDate
          ? new Date(permit.retirementDate).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })
          : "",
    },
    {
      fieldLabel: "Termination Date",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.terminationDate
          ? new Date(permit.terminationDate).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })
          : "",
    },
  ];

  const complianceConstructors = [
    {
      fieldLabel: "Permit Compliance Status Tracking?",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.permitCompStatusFlag ? "Yes" : "No",
    },
    {
      fieldLabel: "DMR Non-Receipt?",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.dmrNonReceiptFlag ? "Yes" : "No",
    },
    {
      fieldLabel: "RNC Tracking?",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.rncTrackingFlag ? "Yes" : "No",
    },
    {
      fieldLabel: "TMDL Interface?",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.tmdlInterfaceFlag ? "Yes" : "No",
    },
    {
      fieldLabel: "EDMR Authorization?",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.edmrAuthorizationFlag ? "Yes" : "No",
    },
    {
      fieldLabel: "Pretreatment Indicator",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.pretreatmentIndicatorCode || "",
    },
    {
      fieldLabel: "Rad WBD HUC12s",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.radWbdHuc12s || "",
    },
    {
      fieldLabel: "Master External Permit Number",
      constructor: (permit: NpdesDetailResult["permits"][0]) =>
        permit?.masterExternalPermitNmbr || "",
    },
  ];

  return (
    <Box
      marginTop={theme.spacing(3)}
      paddingBottom={theme.spacing(3)}
      marginLeft={theme.spacing(3)}
      marginRight={theme.spacing(3)}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={theme.spacing(20)} />
      ) : (
        <Box>
          <TableContainer sx={{ border: `${theme.palette.divider} solid 1px` }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Permit Number</TableCell>
                  <TableCell>Version Number</TableCell>
                  <TableCell>Effective Date</TableCell>
                  <TableCell>Expiration Date</TableCell>
                  <TableCell>Permit Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.npdesDetail?.permits
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((permit: any) => (
                    <TableRow
                      key={permit.activityId}
                      selected={permit === activePermit}
                      onClick={() => setActivePermit(permit)}
                    >
                      <TableCell>{permit.externalPermitNmbr}</TableCell>
                      <TableCell>{permit.versionNmbr}</TableCell>
                      <TableCell>
                        {new Date(permit.effectiveDate).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        {new Date(permit.expirationDate).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>{permit.permitStatusCode}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={data?.npdesDetail.permits?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
          <Box
            marginTop={theme.spacing(4)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h6" align="right">
                {activePermit?.externalPermitNmbr} - Version{" "}
                {activePermit?.versionNmbr}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" marginRight={theme.spacing(1)}>
                Compare with previous version
              </Typography>
              <Switch
                checked={diffMode === "updated"}
                onChange={(_, checked) =>
                  setDiffMode(checked ? "updated" : "nodiff")
                }
              />
            </Box>
          </Box>
        </Box>
      )}

      {loading ? (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          <Grid item xs={6}>
            <SectionHeader title="Facility Details" />
            <Skeleton variant="rectangular" height={theme.spacing(20)} />
          </Grid>
          <Grid item xs={6}>
            <SectionHeader title="Permit Details" />
            <Skeleton variant="rectangular" height={theme.spacing(20)} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          <Grid item xs={6}>
            <SectionHeader title="Facility Details" />
            <DiffTable
              baseRecord={data?.npdesDetail.facility}
              comparisonRecord={data?.npdesDetail.facility}
              fieldConstructors={facilityDetailsConstructors}
              diffMode={diffMode}
            />
          </Grid>
          <Grid item xs={6}>
            <SectionHeader title="Permit Details" />
            <DiffTable
              baseRecord={activePermit}
              comparisonRecord={previousPermit}
              fieldConstructors={permitDetailsConstructors}
              diffMode={diffMode}
            />
          </Grid>
        </Grid>
      )}

      {loading ? (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          <Grid item xs={12}>
            <SectionHeader title="Permit Compliance" />
            <Skeleton variant="rectangular" height={theme.spacing(20)} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          <Grid item xs={12}>
            <SectionHeader title="Permit Compliance" />
            <DiffTable
              baseRecord={activePermit}
              comparisonRecord={previousPermit}
              fieldConstructors={complianceConstructors}
              diffMode={diffMode}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
