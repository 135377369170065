import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";

type Props<T extends FieldValues> = {
  label: string;
  tooltip?: string;
  subtitle?: string;
  radioOptions: { value: any; label: string }[];
  disabled?: boolean;
  fullWidth?: boolean;
  /** Use row layout for the radio buttons (default: `true`) */
  row?: boolean;
  sx?: SxProps;
  /** direction of radio buttons and CE errors. Defaults to row */
  direction?: "row" | "column";
} & Partial<Pick<ControllerRenderProps, "onChange">> &
  UseControllerProps<T>;

export function RadioGroupField<T extends FieldValues>(props: Props<T>) {
  const theme = useTheme();
  // Ok, technically theme.spacing(2) is 2px short of being exactly 83px which
  // is the normal size of a text input in our forms.  The reason we add this
  // spacing is because in text fields we reserve space below the input for
  // helper text and errors, but in radio buttons we display this extra text
  // next to the options.  ENG-7591 calls out this cramped space and here is the
  // solution.
  const marginToMakeRadioGroupSameAsText = theme.spacing(2);
  const inputLabelId = useMemo(() => `${props.name}label`, [props.name]);
  const labelWithTooltip = useMemo(
    () =>
      props.tooltip ? (
        <TextWithTooltip text={props.label} tooltip={props.tooltip} />
      ) : (
        props.label
      ),
    [props.label, props.tooltip]
  );
  return (
    <FormControl
      fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
      sx={{ ...props.sx }}
    >
      <FormLabel id={inputLabelId}>{labelWithTooltip}</FormLabel>
      {props.subtitle && (
        <FormHelperText sx={{ ml: 0 }}>{props.subtitle}</FormHelperText>
      )}
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field, fieldState: { error } }) => {
          return (
            <Stack
              direction={props.direction ?? "row"}
              alignItems={props.direction === "row" ? "center" : "flex-start"}
            >
              <RadioGroup
                sx={{ mb: marginToMakeRadioGroupSameAsText }}
                aria-labelledby={inputLabelId}
                {...field}
                value={field.value ?? ""}
                onChange={(e) => {
                  const newVal = props.radioOptions.find(
                    // The radio button input event provides a string
                    (ro) => ro.value.toString() === e.target.value
                  );
                  field.onChange(newVal?.value);
                  if (props.onChange) {
                    props.onChange(newVal);
                  }
                }}
                row={props.row ?? true}
              >
                {props.radioOptions.map(({ label, value }, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Radio />}
                    label={label}
                    value={value}
                    disabled={props.disabled}
                  />
                ))}
              </RadioGroup>
              <ErrorDisplay error={error} />
            </Stack>
          );
        }}
      />
    </FormControl>
  );
}
