import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import {
  DynamicField,
  ProgramArea,
  ReportingFacilityChemicalQuery,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformStateField";
import { useCallback } from "react";

const VALIDATE_REPORTING_FACILITY_CHEMICAL_INPUT = gql(`
  query ValidateReportingFacilityChemicalInput($id: ID!, $input: [FacilityChemicalStateFieldInput!]!) {
    reportingFacilityChemicalInputValidator(id: $id, input: $input) {
      ...issue
    }
  }
`);

export function useReportingFacilityChemicalInputValidation(
  rfc: ReportingFacilityChemicalQuery["reportingFacilityChemical"] | undefined
) {
  const [query] = useLazyQuery(VALIDATE_REPORTING_FACILITY_CHEMICAL_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: {
      stateFields: (DynamicField & { type: ProgramArea })[];
    }) => {
      if (!rfc) return [];

      const transformedInput = input.stateFields?.map((sf) => ({
        ...transform(sf),
        facilityId: rfc.facilityId,
        chemicalId: rfc.chemicalId,
        reportingYear: rfc.reportingYear,
      }));

      const { data: result } = await query({
        variables: {
          id: rfc.id,
          input: transformedInput,
        },
      });
      return result?.reportingFacilityChemicalInputValidator ?? [];
    },
    [query, rfc]
  );
}
