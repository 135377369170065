import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, Menu, MenuItem, MenuList } from "@mui/material";
import { Importer } from "components/Importer";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { useState } from "react";
import { ProcessingFunction } from "generated-graphql/graphql";
export const StaffManifestActionsMenu = function () {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonId = "staff-bulk-actions-button";

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        Staff
      </Button>
      <Menu
        id="staff-bulk-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        <MenuList dense>
          <MenuItem key="import-clean-harbors">
            <Importer
              templateType={OneSchemaTemplateType.CleanHarbors}
              label="Import Clean Harbors"
              refetchQueries={["WasteManifests"]}
              style={{ width: "100%" }}
              importType="file-upload"
              processingFunction={ProcessingFunction.CleanHarbors}
            />
          </MenuItem>
          <MenuItem key="import-clean-earth">
            <Importer
              templateType={OneSchemaTemplateType.CleanEarthWaste}
              label="Import Clean Earth"
              refetchQueries={["WasteManifests"]}
              style={{ width: "100%" }}
              importType="file-upload"
              processingFunction={ProcessingFunction.CleanEarth}
            />
          </MenuItem>
          <MenuItem key="import-us-ecology">
            <Importer
              templateType={OneSchemaTemplateType.UsEcologyManifest}
              label="Import US Ecology"
              refetchQueries={["WasteManifests"]}
              style={{ width: "100%" }}
              importType="file-upload"
              processingFunction={ProcessingFunction.UsEcology}
            />
          </MenuItem>
          <MenuItem key="import-republic">
            <Importer
              templateType={OneSchemaTemplateType.RepublicManifest}
              label="Import Republic"
              refetchQueries={["WasteManifests"]}
              style={{ width: "100%" }}
              importType="file-upload"
              processingFunction={ProcessingFunction.Republic}
            />
          </MenuItem>
          <MenuItem key="import">
            <Importer
              templateType={OneSchemaTemplateType.OtherWasteManifest}
              label="Import Other"
              refetchQueries={["WasteManifests"]}
              style={{ width: "100%" }}
              importType="file-upload"
              processingFunction={ProcessingFunction.WasteManifest}
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
