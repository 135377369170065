import { Box } from "@mui/material";
import { TabLink, TabLinks } from "components/TabLinks";
import InsightsUpsell from "components/UpsellLandingPages/InsightsUpsell";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useMemo } from "react";
import {
  Navigate,
  Outlet,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";
import { LoadingState } from "./Overview";

export function Insights() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { pathname } = useLocation();
  const { handlerId, registryId } = useParams<{
    handlerId: string;
    registryId: string;
  }>();
  const { featureFlags: flags, loading: featureFlagsLoading } =
    useFeatureFlags();

  const hasInsights = flags?.["insights-ui"];

  const tabs: TabLink[] = useMemo(
    () => [
      {
        title: "Overview",
        to: `/o/${tenantId}/insights/overview`,
      },
      {
        title: "ECHO",
        to: `/o/${tenantId}/insights/echo`,
      },
      {
        title: "RCRA Info",
        to: `/o/${tenantId}/insights/rcra-info`,
      },
      {
        title: "NPDES",
        to: `/o/${tenantId}/insights/npdes`,
      },
    ],
    [tenantId]
  );

  const currentTab = tabs.find(({ to }) => matchPath(`${to}/*`, pathname));

  const breadcrumbs = useMemo(() => {
    const baseCrumbs = [
      { label: "Insights" },
      { label: currentTab?.title ?? "" },
    ];
    if (handlerId) {
      return [...baseCrumbs, { label: handlerId }];
    } else if (registryId) {
      return [...baseCrumbs, { label: registryId }];
    } else {
      return baseCrumbs;
    }
  }, [currentTab, handlerId, registryId]);

  useBreadcrumb(breadcrumbs);

  if (featureFlagsLoading) {
    return <LoadingState />;
  }

  // Redirect to first tab if we don't have a match
  if (!currentTab) {
    return <Navigate to={tabs[0].to} />;
  }

  if (!hasInsights) {
    return <InsightsUpsell />;
  }

  return (
    <Box>
      <TabLinks
        value={currentTab.to}
        tabs={tabs}
        aria-label="insights tabs nav"
        role="navigation"
      />
      <Outlet />
    </Box>
  );
}
