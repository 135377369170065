import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";

import {
  DELETE_FIRE_DEPARTMENT,
  GET_FIRE_DEPARTMENT,
  FIRE_DEPARTMENT_SEARCH,
} from "./api";

export function ConfirmDeleteFireDepartments({
  fireDepartmentId,
  onClose,
}: {
  fireDepartmentId: string;
  onClose: () => void;
}) {
  const alerts = useAlerts();

  const { data, previousData } = useQuery(GET_FIRE_DEPARTMENT, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: fireDepartmentId,
    },
  });

  const [deleteFireDepartment, { loading: isDeleting }] = useMutation(
    DELETE_FIRE_DEPARTMENT
  );

  const fireDepartment = data?.fireDepartment ?? previousData?.fireDepartment;

  const modalContent = (
    <Stack gap={2}>
      <Typography>
        Are you sure you want to delete this fire department?
      </Typography>
    </Stack>
  );

  return fireDepartment ? (
    <ConfirmDialog
      open={true}
      msg={modalContent}
      title="Confirm Delete"
      confirmText="Delete"
      loading={isDeleting}
      onClose={onClose}
      onConfirm={() =>
        deleteFireDepartment({
          variables: { id: fireDepartmentId },
          onCompleted() {
            onClose();
            alerts.success("Fire department deleted successfully!");
          },
          onError(e) {
            alerts.error("There was an error deleting the fire department:", e);
          },
          refetchQueries: [FIRE_DEPARTMENT_SEARCH],
        })
      }
    />
  ) : null;
}
