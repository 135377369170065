import Clear from "@mui/icons-material/Clear";
import StarOutline from "@mui/icons-material/StarOutline";
import Tune from "@mui/icons-material/Tune";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  TextField,
  Tooltip,
} from "@mui/material";
import { FilterKey } from "hooks/useOmnisearchDatagridSettings";
import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { FavoritesPopover } from "./FavoritesPopover";
import { FilterPopover } from "./FilterPopoverV2";

export type Filter = {
  omnisearch?: string;
};

export type OmnisearchProps = {
  autoFocus?: boolean;
  columnFilterKeys?: FilterKey[];
  showFavorites?: boolean;
  sx?: SxProps;
  omnisearch: string;
  setOmnisearch: (newVal: string) => void;
  inputRef?: React.RefObject<HTMLElement>;
  resetRef?: React.RefObject<HTMLButtonElement>;
};

export function Omnisearch({
  autoFocus = false,
  columnFilterKeys,
  showFavorites = true,
  sx = {},
  omnisearch,
  setOmnisearch,
  inputRef,
  resetRef,
}: OmnisearchProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  const [filterButtonAnchor, setFilterButtonAnchor] =
    useState<HTMLButtonElement | null>(null);

  const handleFavoritesIconClick =
    () => (event: React.MouseEvent<HTMLButtonElement>) => {
      setPopoverAnchor(event.currentTarget);
      setIsPopoverOpen(true);
    };

  const onFilterKeyClick = useCallback(
    (newOmnisearch: string) => {
      setOmnisearch(newOmnisearch);
    },
    [setOmnisearch]
  );

  const toggleFilterKeysPopover = useCallback(
    (event: MouseEvent<HTMLButtonElement>) =>
      setFilterButtonAnchor(event.currentTarget),
    []
  );

  const handleCloseFilter = useCallback(() => {
    setFilterButtonAnchor(null);
  }, []);

  const openFilter = useMemo(
    () => Boolean(filterButtonAnchor),
    [filterButtonAnchor]
  );

  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{
        width: {
          sm: "80%",
          xs: "100%",
        },
        ...sx,
      }}
    >
      <TextField
        autoFocus={autoFocus}
        inputRef={inputRef}
        sx={{ flexGrow: 1 }}
        name="search"
        value={omnisearch ?? ""}
        onChange={(e) => setOmnisearch(e.target.value)}
        label="Search"
        InputProps={{
          endAdornment: (
            <Box sx={{ display: "flex" }}>
              <IconButton
                ref={resetRef}
                sx={{
                  display: omnisearch?.length ? "default" : "none",
                }}
                onClick={() => {
                  setOmnisearch("");
                }}
                aria-label="clear input"
              >
                <Clear fontSize="small" />
              </IconButton>
              {columnFilterKeys && (
                <Tooltip title="Filters">
                  <IconButton
                    onClick={toggleFilterKeysPopover}
                    aria-label="show filters"
                  >
                    <Tune fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ),
        }}
      />

      {columnFilterKeys && (
        <FilterPopover
          isOpen={openFilter}
          filterButtonAnchor={filterButtonAnchor}
          handleCloseFilter={handleCloseFilter}
          columnFilterKeys={columnFilterKeys}
          onFilterKeyClick={onFilterKeyClick}
          omnisearch={omnisearch}
        />
      )}

      {showFavorites && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Favorites">
            <IconButton
              sx={{ p: 0 }}
              onClick={handleFavoritesIconClick()}
              aria-label="open favorites"
            >
              <StarOutline />
            </IconButton>
          </Tooltip>

          <FavoritesPopover
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            popoverAnchor={popoverAnchor}
            setPopoverAnchor={setPopoverAnchor}
          />
        </Box>
      )}
    </Stack>
  );
}
