import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";

const REQUEUE_ACTIVITIES = gql(`
  mutation RequeueActivities($activityIds: [ID!]!) {
    requeueActivities(activityIds: $activityIds) {
      activitiesUpdated
      updatedCount
    }
  }
`);

export const useRequeueActivities = () => {
  return useMutation(REQUEUE_ACTIVITIES);
};
