import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { GridColDef } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import CheckIcon from "@mui/icons-material/Check";
import { FacilitiesWithCredentialsQuery } from "generated-graphql/graphql";
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FACILITIES } from "./schema";

type Row = FacilitiesWithCredentialsQuery["facilities"]["items"][number];

interface SelectFacilitiesDialogProps {
  initialSelectedIds: string[];
  open: boolean;
  onSave: (selectedFacilityIds: string[]) => void;
  onCancel: () => void;
}

export const SelectFacilitiesDialog: React.FC<SelectFacilitiesDialogProps> = ({
  initialSelectedIds,
  open,
  onSave,
  onCancel,
}) => {
  const theme = useTheme();
  const { tenantId } = useParams();
  // The rows the user has selected
  const [selectedFacilityIds, setSelectedFacilityIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedFacilityIds(initialSelectedIds);
  }, [initialSelectedIds]);

  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "facility",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 2,
        valueGetter: (params) => {
          const { name, customerFacilityId } = params.row;

          return `${name}${
            customerFacilityId ? ` (${customerFacilityId})` : ""
          }`;
        },
      },
      {
        field: "streetAddress1",
        headerName: "Street Address",
        flex: 2,
        sortable: true,
        valueGetter(params) {
          if (!params.row.streetAddress1) return "";

          const { streetAddress1, city, state, zip } = params.row;

          return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
        },
      },
      {
        renderHeader: (params) => (
          <div
            style={{
              whiteSpace: "normal",
              lineHeight: "normal",
              fontWeight: "500",
            }}
          >
            {params.colDef.headerName}
          </div>
        ),
        field: "hasCredential",
        headerName: "Facility Has a Credential",
        flex: 1,
        renderCell: (params) => (
          <div style={{ textAlign: "center", width: "60%" }}>
            {(params.row.facilityCredentials || []).length > 0 ? "✓" : ""}
          </div>
        ),
      },
    ];
  }, []);

  const handleSave = () => {
    onSave(selectedFacilityIds);
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="lg">
      <DialogTitle>Select Facilities</DialogTitle>
      <DialogContent sx={{ pt: 3, px: 0 }}>
        <Box sx={{ pb: 3, px: 3 }}>
          Select one or more facilities to associate with this credential.
        </Box>
        <OmnisearchDataGrid
          isURLDriven={false}
          columns={columns}
          dataQuery={FACILITIES}
          initialSortModel={[{ field: "facility", sort: "asc" }]}
          defaultSearch={`tenantId:${tenantId}`}
          getItems={(data) => data.facilities.items}
          getCount={(data) => data.facilities.count}
          noDataMessage="No facilities have been associated to this credential yet."
          initialPageSize={50}
          additionalFilterColumns={[
            { header: "Facility ID", key: "customerFacilityId" },
            { header: "City", key: "city" },
            { header: "Zip", key: "zip" },
            { header: "State", key: "state" },
          ]}
          onSelectedIdsChanged={(ids) =>
            setSelectedFacilityIds(ids.map((i) => i.toString()))
          }
          selectedIds={selectedFacilityIds}
          gridSx={{
            minHeight: "300px",
            "& .MuiDataGrid-virtualScroller": {
              minHeight: "200px",
              maxHeight: "350px",
              overflowY: "auto",
            },
          }}
          sx={{
            minWidth: 1000,
            p: 0,
          }}
        />
      </DialogContent>
      <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={handleSave}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
