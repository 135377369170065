import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { FacilityProductInput } from "generated-graphql/graphql";
import { omit } from "lodash";
import { useCallback } from "react";

const VALIDATE_FACILITY_PRODUCT_INPUT = gql(`
  query ValidateFacilityProductInput($input: FacilityProductInput!) {
    facilityProductInputValidator(input: $input) {
      ...issue
    }
  }
`);

export function useFacilityProductInputValidation() {
  const [query] = useLazyQuery(VALIDATE_FACILITY_PRODUCT_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: FacilityProductInput) => {
      const transformedInput = omit(input, "id");
      const { data } = await query({
        variables: { input: transformedInput },
      });
      return data?.facilityProductInputValidator ?? [];
    },
    [query]
  );
}
