import { useSuspenseQuery } from "@apollo/client";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { StatDisplay } from "components/StatDisplay";
import { gql } from "generated-graphql";
import { useTenant } from "hooks/useTenant";
import formatCurrency from "util/formatCurrency";
import { FilterSelections } from "../FilterSelections";
import { useOverviewState } from "../OverviewContext";
import {
  allowedPenaltiesBarChartTypes,
  PenaltiesBarChart,
  PenaltiesBarChartType,
} from "./PenaltiesBarChart";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useQueryParams } from "hooks/useQueryParams";

const PENALTIES_QUERY = gql(`
  query InsightPenalties($tenantId: String!, $filter: InsightsFilter) {
    insightsPenalties(tenantId: $tenantId, filter: $filter) {
      totalPenalties
      averagePenalties
      averageCostPerViolation
      byQuarter {
        cost
        label
      }
      byFacility {
        frsId
        cost
        label
      }
      byProgramArea {
        cost
        label
      }
      byQuarterByProgramArea {
        quarter
        programAreas {
          cost
          label
        }
      }
    }
  }
`);
const graphVariations: Record<
  PenaltiesBarChartType,
  { label: string; selectLabel: string }
> = {
  byProgramArea: {
    label: "Penalties by program area",
    selectLabel: "Program area totals",
  },
  byQuarter: {
    label: "Penalties by quarter",
    selectLabel: "Quarterly totals",
  },
  byQuarterProgramArea: {
    label: "Penalties by quarter and program area",
    selectLabel: "Quarterly by program area",
  },
  byCostFacility: {
    label: "Costliest facilities",
    selectLabel: "Costliest facilities",
  },
};

type QueryParamFilters = {
  penaltyGraphType: PenaltiesBarChartType;
};

export function PenaltiesTile() {
  const theme = useTheme();
  const { filters, setFilters } = useQueryParams<QueryParamFilters>();
  const { tenantId } = useTenant();
  const {
    overviewState: { naicsCode, programArea, naicsType },
  } = useOverviewState();

  const penaltyGraphType = allowedPenaltiesBarChartTypes.includes(
    filters.penaltyGraphType as any
  )
    ? (filters.penaltyGraphType as PenaltiesBarChartType)
    : "byProgramArea";

  const { data } = useSuspenseQuery(PENALTIES_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      filter: { naicsCode: naicsCode?.toString(), programArea },
    },
    skip: !tenantId,
  });
  const penalties = data?.insightsPenalties;
  return (
    <DashboardTile>
      <DashboardTile.Header
        title="Penalties"
        infoTooltip={<PenaltiesTooltip timePeriod="3 years" />}
      >
        <Grid container direction="row" justifyContent="right" gap={2}>
          <FilterSelections
            facilityType={naicsType}
            programArea={programArea}
          />
          <Grid item>
            <FormControl sx={{ width: theme.spacing(32.5) }}>
              <InputLabel id="view-by">View by</InputLabel>
              <Select
                sx={{ height: theme.spacing(5) }}
                fullWidth
                labelId="view-by"
                label="View by"
                value={penaltyGraphType}
                onChange={(event) => {
                  setFilters((f) => {
                    f.penaltyGraphType = event.target
                      .value as PenaltiesBarChartType;
                  });
                }}
              >
                {Object.entries(graphVariations).map(
                  ([key, { selectLabel }]) => (
                    <MenuItem key={key} value={key}>
                      {selectLabel}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DashboardTile.Header>
      <DashboardTile.ContentArea xs={12} lg={2} display="flex" gap={5}>
        <Stack
          pl={{ xs: 0, lg: 2 }}
          gap={2}
          direction={{ xs: "row", lg: "column" }}
        >
          <StatDisplay
            label="Total penalties"
            value={formatCurrency(penalties?.totalPenalties)}
          />
          <StatDisplay
            label="Avg. penalties per facility"
            value={formatCurrency(penalties?.averagePenalties)}
          />
          <StatDisplay
            label="Avg. cost per violation"
            value={formatCurrency(penalties?.averageCostPerViolation)}
          />
        </Stack>
      </DashboardTile.ContentArea>
      <DashboardTile.ContentArea container xs={12} lg={10}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography>{graphVariations[penaltyGraphType].label}</Typography>
            <Tooltip
              title={
                <PenaltiesGraphTooltip
                  label={graphVariations[penaltyGraphType].label}
                  timePeriod="3 years"
                />
              }
            >
              <IconButton size="small">
                <InfoOutlined color="disabled" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>

        <Grid item xs={12} height={theme.spacing(34)}>
          <PenaltiesBarChart graphType={penaltyGraphType} data={penalties} />
        </Grid>
      </DashboardTile.ContentArea>
    </DashboardTile>
  );
}

function PenaltiesTooltip({ timePeriod }: { timePeriod: string }) {
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Program area totals
        </Typography>{" "}
        represents the total penalties you've paid over the last {timePeriod},
        broken down by the program areas found in EPA cases.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Quarterly totals
        </Typography>{" "}
        represents the total penalties you've paid during each quarter over the
        last {timePeriod}.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Quarterly by program area
        </Typography>{" "}
        represents the penalties you've paid during each quarter over the last{" "}
        {timePeriod}, broken down by the program areas found in EPA cases.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Costliest facilities
        </Typography>{" "}
        represents the facilities who have received the highest total penalties
        over the last {timePeriod}.
      </Typography>
      <Typography variant="caption">
        *You can view the facility details that correspond to each chart by
        clicking on it.
      </Typography>
    </Stack>
  );
}

function PenaltiesGraphTooltip({
  label,
  timePeriod,
}: {
  label: string;
  timePeriod: string;
}) {
  switch (label) {
    case "Penalties by program area":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Program area totals
            </Typography>{" "}
            represents the total penalties you've paid over the last{" "}
            {timePeriod}, broken down by the program areas found in EPA cases.
          </Typography>
          <Typography variant="caption">
            If there is a penalty against multiple program areas, you'll see
            that penalty represented{" "}
            <Typography variant="caption" component="span" fontStyle="italic">
              in full
            </Typography>{" "}
            for each corresponding program area total (e.g. a $10k penalty would
            be added to{" "}
            <Typography variant="caption" component="span" fontStyle="italic">
              both
            </Typography>{" "}
            air and water totals). The penalty is only assessed once when
            calculating the 'total penalties' amount.
          </Typography>
        </Stack>
      );
      break;
    case "Penalties by quarter":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Quarterly totals
            </Typography>{" "}
            represents the total penalties you've paid during each quarter over
            the last {timePeriod}, regardless of their program area or facility.
          </Typography>
        </Stack>
      );
      break;
    case "Penalties by quarter and program area":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Quarterly by program area
            </Typography>{" "}
            represents the penalties you've paid during each quarter over the
            last {timePeriod}, broken down by the program areas found in EPA
            cases.
          </Typography>
          <Typography variant="caption">
            If there is a penalty against multiple program areas, it will be
            added to the 'multiple programs' section of the chart.
          </Typography>
        </Stack>
      );
      break;
    case "Costliest facilities":
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="caption">
            <Typography component="span" variant="caption" fontWeight={800}>
              Costliest facilities
            </Typography>{" "}
            represents the facilities that have received the highest total
            penalties over the last {timePeriod}.
          </Typography>
          <Typography variant="caption">
            If there is a penalty against multiple facilities, you'll see that
            penalty represented{" "}
            <Typography variant="caption" component="span" fontStyle="italic">
              in full
            </Typography>{" "}
            on each corresponding facility (e.g. a single $10k penalty would be
            added to both facility X and Y).
          </Typography>
        </Stack>
      );
      break;
    default:
      return null;
  }
}
