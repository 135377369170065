import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Grid,
  GridProps,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BigTooltip } from "components/BigTooltip";
import { PropsWithChildren, ReactNode } from "react";

export function DashboardTile(props: PropsWithChildren<GridProps>) {
  const theme = useTheme();
  return (
    <Grid item container {...props}>
      <Paper
        sx={{ width: "100%" }}
        elevation={0}
        component={Grid}
        paddingX={theme.spacing(3)}
        paddingY={theme.spacing(2)}
      >
        <Grid container spacing={2}>
          {props.children}
        </Grid>
      </Paper>
    </Grid>
  );
}

const Header = ({
  title,
  infoTooltip,
  children,
}: PropsWithChildren<{
  title: string;
  infoTooltip?: ReactNode;
}>) => {
  return (
    <Grid item xs={12}>
      <Box display="flex">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="h5">{title}</Typography>
            {infoTooltip && (
              <BigTooltip title={infoTooltip}>
                <IconButton size="small">
                  <InfoOutlined color="disabled" />
                </IconButton>
              </BigTooltip>
            )}
          </Stack>
          {children}
        </Stack>
      </Box>
    </Grid>
  );
};

DashboardTile.Header = Header;

const ContentArea = (props: PropsWithChildren<GridProps>) => {
  return (
    <Grid item {...props}>
      {props.children}
    </Grid>
  );
};

DashboardTile.ContentArea = ContentArea;
