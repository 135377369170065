import { useQuery } from "@apollo/client";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import { prettyPrintDate } from "util/dates";
import { SubmissionRecords } from "./Submitted/SubmissionRecords";
import { useReport } from "./useReport";

const FACILITY_NOT_REPORTING_STATUS = gql(`
  query Facility($id: ID!) {
    facility(id: $id) {
      isInactive
      isClosed
      colocationReportingFacility {
        id
      }
      isNotReporting
      isNotReportingThroughEncamp
      dateClosed
      dateInactive
    }
  }
`);

export const NotReporting = () => {
  const theme = useTheme();
  const { data: report } = useReport();

  const { data } = useQuery(FACILITY_NOT_REPORTING_STATUS, {
    variables: { id: report?.tierIIReport.facility.id ?? "" },
    skip: !report,
    fetchPolicy: "cache-and-network",
  });

  const isClosed: boolean = data?.facility?.isClosed ?? false;
  const isInactive: boolean = data?.facility?.isInactive ?? false;
  const isColocated: boolean = data?.facility?.colocationReportingFacility
    ? true
    : false;
  const isNotReportingThroughEncamp: boolean =
    data?.facility?.isNotReportingThroughEncamp ?? false;
  const isUnderThreshold: boolean = data?.facility?.isNotReporting ?? false;

  let message = "";
  if (isClosed) {
    message = "This facility was closed for the entire reporting year.";
  } else if (isInactive) {
    message = "This facility was inactive for the entire reporting year.";
  } else if (isColocated) {
    message =
      "This facility is co-located on the same site with another facility.";
  } else if (isUnderThreshold) {
    message =
      "This facility's products were under the threshold for the entire reporting year.";
  } else if (isNotReportingThroughEncamp) {
    message = "This facility is not reporting through Encamp.";
  }

  return (
    <Stack
      gap={theme.spacing(6)}
      sx={{ p: theme.spacing(2), mt: theme.spacing(1) }}
    >
      <Box>
        <Typography variant="h5">Facility Not Reporting</Typography>
        <Typography variant="caption">
          No {prettyPrintEnumValue(report?.tierIIReport.reportKind)} report will
          be filed for this facility.
        </Typography>
      </Box>

      <Box>
        <Typography variant="body1" sx={{ mb: theme.spacing(1) }}>
          <i>Reason for not reporting:</i>
        </Typography>
        <Typography variant="body1">{message}</Typography>
        {data?.facility?.dateInactive && (
          <Typography>
            Date inactive: {prettyPrintDate(data.facility.dateInactive)}
          </Typography>
        )}
        {data?.facility?.dateClosed && (
          <Typography>
            Date closed: {prettyPrintDate(data.facility.dateClosed)}
          </Typography>
        )}
      </Box>
      {report?.tierIIReport && (
        <SubmissionRecords report={report?.tierIIReport} />
      )}
    </Stack>
  );
};
