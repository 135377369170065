import { gql } from "generated-graphql";

gql(` 
fragment AssigneePicker on User {
    id
    email
    person {
        first
        last
        email
    }
}
`);

export const GET_USERS_QUERY = gql(`
    query GetUsers($search: String!, $page: Int!, $pageSize: Int!, $sort: [SortModel!]) {
        users(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
            items {
                ...AssigneePicker
            }
            count
        }
    }
`);
