import React, { useCallback, useState } from "react";
import { v4 } from "uuid";
import {
  IconButton,
  Button,
  TextField,
  Stack,
  Typography,
  Link as MuiLink,
  useTheme,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Check from "@mui/icons-material/Check";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Control, useController, useFieldArray } from "react-hook-form";
import { TaskFormType } from "./TaskDialog";

type LinksFormControlProps = {
  control: Control<TaskFormType>;
};

const LinksFormControl: React.FC<LinksFormControlProps> = ({ control }) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "links",
    keyName: "_id",
  });

  const [editingId, setEditingId] = useState<string | null>(null);

  const handleAddLink = useCallback(() => {
    const newId = v4();
    append({ url: "", id: newId }, { shouldFocus: true });
    setEditingId(newId);
  }, [append]);

  const onChange = useCallback(
    (value: string) => {
      if (editingId == null) return;
      const idx = fields.findIndex((field) => field.id === editingId);
      if (idx === -1) return;
      update(idx, { url: value, id: editingId });
    },
    [editingId, fields, update]
  );

  const handleDeleteLink = useCallback(
    (id: string) => {
      const idx = fields.findIndex((field) => field.id === id);
      if (editingId === id) {
        setEditingId(null);
      }
      remove(idx);
    },
    [editingId, fields, remove]
  );

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Links</Typography>
        <div>
          <Button
            onClick={handleAddLink}
            disabled={editingId !== null}
            startIcon={<Add />}
          >
            Add Link
          </Button>
        </div>
      </Stack>
      <Stack direction="column">
        {fields?.length === 0 && (
          <Typography variant="body2">
            No links have been added to this task yet
          </Typography>
        )}
        {fields.map((field, index) => (
          <LinkItem
            control={control}
            index={index}
            key={field.id}
            isEditing={editingId === field.id}
            handleDelete={() => handleDeleteLink(field.id)}
            handleEdit={() => setEditingId(field.id)}
            handleSave={() => setEditingId(null)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

// Like your browser, after you hit enter, we will prepend the protocol to the URL
// Explicitly ignoring other protocols (don't give me your FTP URL)
const ensureHttps = (url: string) =>
  url.startsWith("http") ? url : `https://${url}`;

const LinkItem: React.FC<{
  control: Control<TaskFormType>;
  index: number;
  isEditing: boolean;
  handleDelete: () => void;
  handleEdit: () => void;
  handleSave: () => void;
}> = ({ index, control, isEditing, handleDelete, handleEdit, handleSave }) => {
  const theme = useTheme();
  const { field, fieldState } = useController({
    control,
    name: `links.${index}.url`,
  });
  return (
    <Stack direction="row" justifyContent={"space-between"}>
      {isEditing ? (
        <TextField
          value={field.value}
          error={fieldState.error != null}
          helperText={fieldState.error?.message}
          onChange={(e) => {
            const urlWithProtocol = ensureHttps(e.target.value);
            field.onChange(urlWithProtocol);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const urlWithProtocol = ensureHttps(field.value);
              field.onChange(urlWithProtocol);
              handleSave();
            }
          }}
          fullWidth
        />
      ) : (
        <Typography variant="body2" width={"100%"}>
          <MuiLink
            href={field.value}
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            color={fieldState.error ? "error" : "primary"}
          >
            {field.value}
          </MuiLink>
          <IconButton
            edge="end"
            href={field.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            <OpenInNew />
          </IconButton>
          <Typography sx={{ paddingLeft: 1 }} variant="caption" color="error">
            {fieldState.error?.message}
          </Typography>
        </Typography>
      )}
      <Stack
        direction="row"
        alignItems={"center"}
        spacing={theme.spacing(0.5)}
        marginLeft={theme.spacing(1)}
      >
        {isEditing ? (
          <IconButton edge="end" size="small" onClick={handleSave}>
            <Check />
          </IconButton>
        ) : (
          <IconButton edge="end" size="small" onClick={handleEdit}>
            <Edit />
          </IconButton>
        )}
        <IconButton edge="end" size="small" onClick={handleDelete}>
          <Delete />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default LinksFormControl;
