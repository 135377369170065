import * as Sentry from "@sentry/react";
const { VITE_ENV = "", VITE_API_URL = "" } = import.meta.env;

const apiRegex = new RegExp(`^${VITE_API_URL.replaceAll(".", "\\.")}`);
const whitelistedEnvs = ["release", "dev"];

export default function configureSentry() {
  if (!whitelistedEnvs.includes(VITE_ENV)) {
    // Don't initialize Sentry in sandboxes or dev
    return;
  }
  Sentry.init({
    dsn: "https://5fe24cc356020a1eac95b574b369cde3@o4505909205729280.ingest.sentry.io/4505909471084544",
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        networkDetailAllowUrls: [apiRegex],
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Browser Tracing
    tracesSampleRate: 0.2,
    tracePropagationTargets: ["localhost", apiRegex],

    // Session Replay
    replaysSessionSampleRate: VITE_ENV === "release" ? 1.0 : 0.1, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    environment: VITE_ENV,
  });
}

export function configureSentryUser(user: Sentry.User | null) {
  if (!whitelistedEnvs.includes(VITE_ENV)) {
    // Don't initialize Sentry in sandboxes or dev
    return;
  }
  Sentry.setUser({ ...user });
}
