import ExpandMore from "@mui/icons-material/ExpandMore";
import Merge from "@mui/icons-material/Merge";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import Upload from "@mui/icons-material/Upload";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { Importer } from "components/Importer";
import { ImportDialog } from "components/Importer/ImportDialog";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { useState } from "react";
import { ContactMergePicker } from "../../routes/Customer/Contacts/ContactMergePicker";
import { ContactReplaceDialog } from "../../routes/Customer/Contacts/ContactReplaceDialog";

export const StaffBulkActionsMenu = function ({
  contactIds,
}: {
  contactIds: string[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [importOpen, setImportOpen] = useState(false);
  const [replaceOpen, setReplaceOpen] = useState(false);
  const [mergeOpen, setMergeOpen] = useState(false);

  const buttonId = "staff-bulk-actions-button";

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        Staff
      </Button>
      <Menu
        id="staff-bulk-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        <MenuList dense>
          <MenuItem key="import" onClick={() => setImportOpen(true)}>
            <ListItemIcon>
              <Upload />
            </ListItemIcon>
            <ListItemText>Import</ListItemText>
          </MenuItem>

          <MenuItem key="replace" onClick={() => setReplaceOpen(true)}>
            <ListItemIcon>
              <SwapHoriz />
            </ListItemIcon>
            <ListItemText>Replace</ListItemText>
          </MenuItem>

          <MenuItem
            key="merge"
            disabled={contactIds.length < 2}
            onClick={() => setMergeOpen(true)}
          >
            <ListItemIcon>
              <Merge />
            </ListItemIcon>
            <ListItemText>Merge</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>

      {importOpen && (
        <ImportDialog
          importers={[
            {
              name: "Contacts",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.Contact}
                  refetchQueries={["People", "OnboardingContacts"]}
                />
              ),
            },
            {
              name: "Facility Contacts",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.FacilityContact}
                  refetchQueries={["People", "OnboardingContacts"]}
                />
              ),
            },
          ]}
          open={true}
          onClose={() => setImportOpen(false)}
        />
      )}

      {replaceOpen && (
        <ContactReplaceDialog open onClose={() => setReplaceOpen(false)} />
      )}

      {mergeOpen && (
        <ContactMergePicker
          open
          onClose={() => setMergeOpen(false)}
          contactIds={contactIds}
        />
      )}
    </>
  );
};
