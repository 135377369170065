import { useCallback } from "react";
import { useQueryParams } from "../../hooks/useQueryParams";
import { stringifyQueryParams } from "providers/queryParams";

export type Filter = {
  omnisearch: string;
  updateOmnisearch: (filters: [string, string][], clearFirst?: boolean) => void;
  removeOmnisearch: (filter: string) => void;
  getFilter: (filter: string) => string | undefined;
  getOmnisearchUrl: (baseUrl: string, filters: [string, string][]) => string;
};

export default function useOmnisearchFilters(): Filter {
  const { filters, setFilters } = useQueryParams<Filter>();

  const updateOmnisearch = useCallback(
    (filters: [string, string][], clearFirst?: boolean) => {
      setFilters((state) => {
        state.omnisearch = getOmnisearchValueFromFilters(
          filters,
          !clearFirst ? state.omnisearch ?? "" : ""
        );
      });
    },
    [setFilters]
  );

  const getOmnisearchUrl = useCallback(
    (baseUrl: string, filters: [string, string][]) => {
      const omnisearch = getOmnisearchValueFromFilters(filters);

      return `${baseUrl}?${stringifyQueryParams({
        omnisearch,
      })}`;
    },
    []
  );

  const removeOmnisearch = useCallback(
    (filter: string) => {
      setFilters((state) => {
        state.omnisearch = removeKeyInOmnisearch(
          state.omnisearch ?? "",
          filter
        );
      });
    },
    [setFilters]
  );

  const getFilter = useCallback(
    (filter: string) => {
      const { kvpValues } = breakApartOmnisearch(filters.omnisearch ?? "");
      const kvp = kvpValues.find(([k]) => k === filter);
      return kvp?.[1];
    },
    [filters]
  );

  return {
    omnisearch: filters.omnisearch ?? "",
    updateOmnisearch,
    removeOmnisearch,
    getFilter,
    getOmnisearchUrl,
  };
}

function getOmnisearchValueFromFilters(
  filters: [string, string][],
  initialOmnisearch = ""
) {
  let omnisearch = initialOmnisearch;
  for (const [key, value] of filters) {
    omnisearch = addOrUpdateKeyInOmnisearch(omnisearch, key, value);
  }
  return omnisearch;
}

function addOrUpdateKeyInOmnisearch(
  omnisearch: string,
  key: string,
  value: string
) {
  const { nonKvpValues, kvpValues } = breakApartOmnisearch(omnisearch);

  const filteredKvpValues = kvpValues.filter(([k]) => k !== key); // remove the old value if it exists, we're updating

  filteredKvpValues.push([key, value]); // add the new value
  return `${nonKvpValues} ${filteredKvpValues
    .map(([k, v]) => `${k}:${v}`)
    .join(" ")}`.trim();
}

function removeKeyInOmnisearch(omnisearch: string, key: string) {
  const { nonKvpValues, kvpValues } = breakApartOmnisearch(omnisearch);
  const filteredKvpValues = kvpValues.filter(([k]) => k !== key); // remove the old value if it exists
  return `${nonKvpValues} ${filteredKvpValues
    .map(([k, v]) => `${k}:${v}`)
    .join(" ")}`.trim();
}

function breakApartOmnisearch(omnisearch: string) {
  const values = omnisearch.split(" ").filter((v) => v.length > 0);
  const nonKvpValues = values.filter((v) => !v.includes(":")).join(" "); // get the non kvp values, just stuff we're searching for

  const kvpValues = values // this is all of the keyvalue pairs except the one we're updating / adding
    .filter((v) => v.includes(":"))
    .map((v) => v.split(":"));

  return { nonKvpValues, kvpValues };
}
