import {
  Alert,
  AlertTitle,
  Box,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { FileRejection, useDropzone } from "react-dropzone";
import { useState } from "react";

export type DropzoneProps = {
  label: string;
  dragActiveText: string;
  onDrop: (acceptedFiles: File[]) => void;
  /**
   * file types including the period
   * e.g. [".csv", ".xlsx"]
   */
  accept?: string | string[];
  sx?: SxProps;
};

export function Dropzone(props: DropzoneProps) {
  const [showDropRejected, setShowDropRejected] = useState(false);
  const [dropRejectedFiles, setDropRejectedFiles] = useState<FileRejection[]>(
    []
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onDrop,
    accept: props.accept,
    onDropRejected: (rejections: FileRejection[]) =>
      setDropRejectedFiles(rejections),
    onDropAccepted: () => setShowDropRejected(false),
  });
  const theme = useTheme();

  return (
    <>
      {dropRejectedFiles.length > 0 && (
        <Alert severity="error" sx={{ mb: theme.spacing(2) }}>
          {dropRejectedFiles.map((fileRejection, index) => (
            <div key={index}>
              <AlertTitle>Error: {fileRejection.file.name}</AlertTitle>
              {fileRejection.errors.map((error) => error.message)}
            </div>
          ))}
        </Alert>
      )}
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed",
          borderColor: theme.palette.grey[300],
          padding: 2,
          borderRadius: 1,
          bgcolor: theme.palette.grey[200],
          color: theme.palette.grey[500],
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "background.default",
          },
          height: theme.spacing(12),
          ...props.sx,
        }}
      >
        <input {...getInputProps()} />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <AttachmentIcon sx={{ transform: "rotate(90deg)" }} />
          <Typography variant="body2">
            {isDragActive ? props.dragActiveText : props.label}
          </Typography>
        </Stack>
      </Box>
    </>
  );
}
