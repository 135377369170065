import { FacilityInput } from "generated-graphql/graphql";
import { FacilityDetailsInput } from "routes/Customer/Facility/FacilityProfile";
import { transform as transformStateField } from "./transformStateField";

export function transform(input: FacilityDetailsInput): FacilityInput {
  return {
    ...input,
    latitude: input.latitude ? Number(input.latitude) : null,
    longitude: input.longitude ? Number(input.longitude) : null,
    facilityAlternateIds: input.facilityAlternateIds?.map((altId) => ({
      ...altId,
      value: altId.hasNoId ? "" : altId.value ?? "",
      expiresAt: altId.hasNoId ? null : altId.expiresAt ?? null,
    })),
    colocationReportingFacilityId: !input.isColocated
      ? null
      : input.colocationReportingFacilityId,
    stateFields: input.stateFields?.map(transformStateField),
    fireDepartmentId: input.fireDepartmentId,
    lepcId: input.lepcId,
  };
}
