export function getChemicalsBreadcrumb(tenantId: string) {
  return `/o/${tenantId}/chemicals`;
}

export function getChemicalsReportsBreadcrumb(tenantId: string) {
  return `/o/${tenantId}/chemicals/reports`;
}

export function getChemicalsInventoryBreadcrumb(tenantId: string) {
  return `/o/${tenantId}/chemicals/inventory`;
}

export function getChemicalsInventoryFacilityBreadcrumb(
  tenantId: string,
  facilityid: string
) {
  return `/o/${tenantId}/chemicals/inventory/${facilityid}`;
}

export function getChemicalsCatalogBreadcrumb(tenantId: string) {
  return `/o/${tenantId}/chemicals/catalog`;
}

export function getChemicalsContactsBreadcrumb(tenantId: string) {
  return `/o/${tenantId}/chemicals/contacts`;
}

export function getChemicalsDocumentsBreadcrumb(tenantId: string) {
  return `/o/${tenantId}/chemicals/documents`;
}
