import { FacilityAlternateId } from "@prisma/client";

type FindAltIdFields = Pick<FacilityAlternateId, "value" | "type"> & {
  // Changed this so it will work with the Front end
  hasNoId?: boolean;
  expiresAt?: Date | null;
};

function sortIds<T extends FindAltIdFields>(ids: T[]) {
  return [...ids].sort((a, b) => {
    if (a.expiresAt == null && b.expiresAt !== null) return -1;
    if (a.expiresAt != null && b.expiresAt === null) return 1;
    if (a.expiresAt && b.expiresAt) {
      return new Date(b.expiresAt).getTime() - new Date(a.expiresAt).getTime(); // Descending order
    }
    return 0;
  });
}

/**
 * Returns the first match of the provided `idTypes`.
 */
export function findAlternateId<T extends FindAltIdFields>(
  ids: T[],
  idTypes: string[],
  includeHasNoId: boolean = false
): T | undefined {
  const sortedIds = sortIds<T>(ids);

  const match = sortedIds.find(
    (x) => idTypes.includes(x.type) && (includeHasNoId || !x.hasNoId)
  );

  // Commented this out because it's not clear if there will be unintended side
  // effects if we exclude expired IDs, but I'm leaving it here in case we need
  // it in the future
  /**
    if (match?.expiresAt && match.expiresAt < new Date()) {
      if (idTypes.length > 1) {
        return findAlternateId(ids, idTypes.slice(1));
      }
      return undefined;
    }
   */
  return match;
}

/**
 * Returns all matches of the provided `idTypes`.
 */
export function findAlternateIds<T extends FindAltIdFields>(
  ids: T[],
  idTypes: string[],
  includeHasNoId: boolean = false
): T[] {
  const sortedIds = sortIds<T>(ids);

  const matches = sortedIds.filter(
    (x) => idTypes.includes(x.type) && (includeHasNoId || !x.hasNoId)
  );

  return matches;
}
