import { Box, Grid, Typography } from "@mui/material";

export function Home({ name }: { name: string }) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Typography variant="h4" color="primary" sx={{ marginTop: -40 }}>
        Welcome, {name}!
      </Typography>
      <Box mt={2} sx={{ width: "35%" }}>
        <Typography color="GrayText" textAlign="center">
          Encamp helps EHS professionals understand, manage, and comply with
          applicable EHS regulations.
        </Typography>
      </Box>
    </Grid>
  );
}
