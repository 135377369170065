import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { EncampTokenClaims } from "encamp-shared/src/types/lambda";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const tokenClaimContext = createContext<{
  getIdTokenRefreshIfExpired: () => Promise<string>;
  tokenClaims: Partial<EncampTokenClaims>;
  isAuthenticated: boolean;
}>({
  getIdTokenRefreshIfExpired: async () => "",
  tokenClaims: {},
  isAuthenticated: false,
});

export function useToken() {
  return useContext(tokenClaimContext);
}

export function TokenProviderEnabler(props: PropsWithChildren<unknown>) {
  const [tokenClaims, setTokenClaims] = useState<{ [key: string]: string }>({});

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  // Auth.currentSession gets the idToken, but _automagically refreshes it if it's expired_!!
  //   (sidebar: they did not bother to document this in Amplify's docs, but I found this GH issue and independently verified: https://github.com/aws-amplify/amplify-js/issues/2560)
  //   (removing Amplify note: we would want to implement similar behavior, which is not difficult to do by checking the token's expiration and making an API call to refresh the token if it's expired)
  // Due to this behavior, we want to call it every time we make an API call that needs authentication
  //   (because there is always a chance that auth has expired)
  // Therefore this provider is going to provide an asynchronous function that can be used to
  //   GET the token, not the token itself
  // This is fine, because in every scenario where you actually need the token, you're already in an async
  //   context (because you're about to make a network call)
  // However, we also want to know what claims are on the token in the frontend sometimes,
  //   so we set up a tokenClaims state object, and also provide that in the context
  // We are accepting the tradeoff that we will _perhaps_ be relying on stale claims, but we don't have to worry about dealing with
  //   async nonsense every time we want to check one
  // We are also accepting the performance cost of base64-decoding our token on every API call, because Auth.currentSession doesn't
  //   expose a way to tell whether it needed to get a new token or not
  const getIdTokenRefreshIfExpired = useMemo(
    () => async (): Promise<string> => {
      try {
        const session = await Auth.currentSession();
        const newIdTokenObject = session.getIdToken();
        const newIdToken = newIdTokenObject.getJwtToken();
        setTokenClaims(newIdTokenObject.decodePayload());
        return newIdToken;
      } catch (error) {
        console.warn("No authenticated user", error);
        setTokenClaims({});
        return "";
      }
    },
    []
  );

  useEffect(() => {
    getIdTokenRefreshIfExpired();
  }, [getIdTokenRefreshIfExpired]);

  const isAuthenticated = useMemo(
    () => authStatus === "authenticated",
    [authStatus]
  );

  return (
    <tokenClaimContext.Provider
      value={{
        getIdTokenRefreshIfExpired,
        tokenClaims,
        isAuthenticated,
      }}
    >
      {props.children}
    </tokenClaimContext.Provider>
  );
}
