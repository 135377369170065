import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import BasicMap from "components/BasicMap";
import { FormTextField } from "components/Forms/FormTextField";
import { StatePicker } from "components/StatePicker";
import { Controller, useFormContext } from "react-hook-form";
import { CountryPicker } from "./CountryPicker";
import { useEffect } from "react";
import { CountyPicker } from "components/CountyPicker";
import { MunicipalityPicker } from "components/MunicipalityPicker";

const showMunicipalityStates = ["PA", "WI"];
export const PhysicalAddress = ({
  showMap,
  readOnly,
}: {
  showMap?: boolean;
  readOnly?: boolean;
}) => {
  const theme = useTheme();
  const { control, watch, setValue } = useFormContext();
  const lat = watch("latitude");
  const long = watch("longitude");
  const state = watch("state") ?? "";
  const county = watch("county");
  const inStateWithMunicipality =
    state && showMunicipalityStates.includes(state);

  useEffect(() => {
    if (state && !showMunicipalityStates.includes(state)) {
      setValue("municipality", null);
    }
  }, [state, setValue]);

  return (
    <Grid
      container
      columnSpacing={theme.spacing(3)}
      rowSpacing={theme.spacing(1)}
      marginTop={theme.spacing(2)}
    >
      <Grid item xs={12}>
        <FormTextField
          name="streetAddress1"
          label="Street Address"
          control={control}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          name="streetAddress2"
          label="Street Address 2"
          control={control}
          disabled={readOnly}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="city"
          label="City"
          control={control}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StatePicker
          name="state"
          countryFieldName="country"
          control={control}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <FormTextField
          name="zip"
          label="Postal Code"
          control={control}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="county"
          control={control}
          render={(props) => (
            <CountyPicker
              {...props}
              facilityState={state}
              disabled={readOnly}
            />
          )}
        />
      </Grid>

      {inStateWithMunicipality && (
        <Grid item xs={12} md={6}>
          <Controller
            name="municipality"
            control={control}
            render={({ field, fieldState }) => (
              <MunicipalityPicker
                {...field}
                {...fieldState}
                disabled={readOnly}
                facilityState={state}
                facilityCounty={county}
              />
            )}
          />
        </Grid>
      )}

      <Grid item xs={12} md={inStateWithMunicipality ? 4 : 6}>
        <CountryPicker name="country" control={control} disabled={readOnly} />
      </Grid>

      <Grid item xs={6} md={inStateWithMunicipality ? 4 : 6}>
        <FormTextField
          name="latitude"
          label="Latitude"
          control={control}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={6} md={inStateWithMunicipality ? 4 : 6}>
        <FormTextField
          name="longitude"
          label="Longitude"
          control={control}
          disabled={readOnly}
        />
      </Grid>

      {showMap && (
        <Grid item xs={12}>
          <Box width="full" height="269px">
            {!Number.isNaN(parseFloat(lat)) &&
              !Number.isNaN(parseFloat(long)) && (
                <BasicMap latitude={Number(lat)} longitude={Number(long)} />
              )}
            {(Number.isNaN(parseFloat(lat)) ||
              Number.isNaN(parseFloat(long))) && (
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Map will display here once latitude and longitude are
                    entered.
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
