import { useApolloClient, useLazyQuery } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";
import { Box, Tooltip } from "@mui/material";
import { ExportButton, ExportFileType } from "components/ExportButton";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { TruncateTypography } from "components/TruncateTypography";
import { DeepPartial } from "encamp-shared/src/utils/types";
import { gql } from "generated-graphql";
import {
  WasteLine,
  WasteLinesQuery,
  WasteManifestSource,
  WasteManifestsQuery,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useCurrentUser } from "hooks/useCurrentUser";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { prettyPrintDateMed } from "util/dates";
import { ExpandableCell } from "./ExpandableCell";
import { StaffManifestActionsMenu } from "./StaffManifestActionsMenu";
import { wasteManifestSourceToLabel } from "./utils";
import { useLongPollingForFile } from "hooks/useLongPollingForFile";
import invariant from "invariant";

// Using this fragment to ensure we get cache replacement for the waste lines
gql(/* GraphQL */ `
  fragment WasteLineFragment on WasteLine {
    quantity
    manifestId
    qtyUnitOfMeasureDesc
    dotHazardous
    dotPrintedInformation
    nonHazWasteDescription
    vendorProfileDescription
    vendorProfileNumber
    dotIdNumberDescription
    federalWasteCodes
    quantity
    qtyUnitOfMeasureCode
    qtyUnitOfMeasureDesc
    quantityTons
    quantityAcuteTons
    quantityNonAcuteTons
    quantityKg
    quantityAcuteKg
    quantityNonAcuteKg
  }
`);

const MANIFESTS_QUERY = gql(/* GraphQL */ `
  query WasteManifests(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    wasteManifests(
      search: $search
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      items {
        id
        displayedManifestId
        generatorId
        manifestTrackingNumber
        vendor
        shippedDate
        facilityName
        hasEManifest
        hasVendorManifest
        dataDiscrepancy
        wasteLines {
          count
          items {
            ...WasteLineFragment
          }
        }
      }
      count
    }
  }
`);

const WASTE_LINES_QUERY = gql(/* GraphQL */ `
  query WasteLines($wasteManifestId: String!) {
    wasteManifestLineItems(wasteManifestId: $wasteManifestId) {
      count
      items {
        ...WasteLineFragment
      }
    }
  }
`);

export type Row = WasteManifestsQuery["wasteManifests"]["items"][0];

const replaceWasteLinesInCache = (
  cache: ReturnType<typeof useApolloClient>["cache"],
  wasteLinesResult: WasteLinesQuery["wasteManifestLineItems"],
  processedManifestId: string | null | undefined
) => {
  const cacheId = cache.identify({
    __typename: "ProcessedManifestSummary",
    id: processedManifestId,
  });
  cache.modify({
    broadcast: true,
    optimistic: true,
    id: cacheId,
    fields: {
      wasteLines() {
        return wasteLinesResult;
      },
    },
  });
};

export function Manifests() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { isStaff } = useCurrentUser();
  const [search, setSearch] = useState<undefined | string>();

  invariant(tenantId, "tenantId not found");

  const generateFilename = useCallback(() => {
    return `wasteManifests-${new Date().toISOString()}.xlsx`;
  }, []);

  const filename = useMemo(
    () => generateFilename(),
    // Make sure to generate a new filename when the inputs change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [generateFilename, search, tenantId]
  );

  const defaultSearch = `tenantId:${tenantId}`;
  const client = useApolloClient();
  const [fetchWasteLines, { loading: loadingWasteLines }] =
    useLazyQuery(WASTE_LINES_QUERY);

  const { polling, setPolling, signedUrl } = useLongPollingForFile({
    tenantId,
    filename,
  });

  useEffect(() => {
    if (!signedUrl) return;

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = signedUrl;
    document.body.appendChild(a);
    a.click();
  });

  const handleFetchWasteLines = useCallback(
    (
      wasteManifestId: string | null | undefined,
      processedManifestId: string | null | undefined
    ) => {
      if (!wasteManifestId) {
        return;
      }

      fetchWasteLines({
        variables: {
          wasteManifestId,
        },
        onCompleted: (data) => {
          if (data?.wasteManifestLineItems) {
            replaceWasteLinesInCache(
              client.cache,
              data.wasteManifestLineItems,
              processedManifestId
            );
          }
        },
      });
    },
    [fetchWasteLines]
  );
  const btns = [
    <ExportButton
      key="export"
      fileType={ExportFileType.EXCEL}
      endpoint={`/download/waste?tenantId=${tenantId}&search=${search}&filename=${filename}`}
      onClick={() => setPolling(true)}
      loading={polling}
    />,
  ];

  useBreadcrumb([
    {
      label: "Waste",
      to: tenantId ? `/o/${tenantId}/waste` : undefined,
    },
    {
      label: "Manifests",
    },
  ]);

  if (isStaff) {
    btns.push(<StaffManifestActionsMenu key="staff" />);
  }
  const columns: OmnisearchGridColDef<Row>[] = [
    {
      field: "epaId",
      headerName: "EPA ID",
      flex: 1,
      topAligned: true,
      valueGetter: ({ row }) => {
        return row?.generatorId ?? "";
      },
    },
    {
      field: "facility",
      headerName: "Facility",
      filterKeyType: "facility",
      flex: 2,
      topAligned: true,
      valueGetter: ({ row }) => row?.facilityName ?? "",
    },
    {
      field: "manifestTrackingNumber",
      headerName: "Manifest #",
      flex: 1,
      topAligned: true,
      valueGetter: ({ row }) => row.manifestTrackingNumber,
    },
    {
      field: "shippedDate",
      headerName: "Ship Date",
      width: 120,
      topAligned: true,
      valueGetter: ({ row }) => {
        return prettyPrintDateMed(row?.shippedDate);
      },
    },
    {
      field: "wasteLines",
      headerName: "Line Item",
      flex: 1.5,
      sortable: false,
      topAligned: true,
      renderCell: (cell) => {
        return (
          <Box height={"100%"}>
            <ExpandableCell
              items={cell.row.wasteLines?.items ?? []}
              totalCount={cell.row.wasteLines?.count}
              rowId={cell.id}
              clickDisabled={loadingWasteLines}
              onClick={() => {
                handleFetchWasteLines(
                  cell.row.displayedManifestId,
                  cell.row.id
                );
              }}
              collapsedLines={5}
              render={(lines) => {
                return (
                  <>
                    {lines?.map((line, idx) => (
                      <TruncateTypography key={idx} showTooltip variant="body">
                        {determineLineDisplay(line)}
                      </TruncateTypography>
                    ))}
                  </>
                );
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.7,
      sortable: false,
      topAligned: true,
      renderCell: (cell) => {
        return (
          <Box height={"100%"}>
            <ExpandableCell
              items={cell.row.wasteLines?.items ?? []}
              totalCount={cell.row.wasteLines?.count}
              rowId={cell.id}
              clickDisabled={loadingWasteLines}
              onClick={() => {
                handleFetchWasteLines(
                  cell.row.displayedManifestId,
                  cell.row.id
                );
              }}
              collapsedLines={5}
              render={(lines) => {
                return (
                  <>
                    {lines?.map((line, idx) => (
                      <TruncateTypography key={idx} showTooltip variant="body">
                        {determineAmount(line)}
                      </TruncateTypography>
                    ))}
                  </>
                );
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "wasteProfileNumber",
      headerName: "Waste Profile #",
      flex: 1.5,
      sortable: false,
      topAligned: true,
      renderCell: (cell) => {
        return (
          <Box height={"100%"}>
            <ExpandableCell
              items={cell.row.wasteLines?.items ?? []}
              totalCount={cell.row.wasteLines?.count}
              rowId={cell.id}
              clickDisabled={loadingWasteLines}
              onClick={() => {
                handleFetchWasteLines(
                  cell.row.displayedManifestId,
                  cell.row.id
                );
              }}
              collapsedLines={5}
              render={(lines) => {
                return (
                  <>
                    {lines?.map((line, idx) => (
                      <TruncateTypography
                        key={`wasteProfileNumber${idx}`}
                        showTooltip
                        variant="body"
                      >
                        {determineWasteProfileNumber(line)}
                      </TruncateTypography>
                    ))}
                  </>
                );
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 0.7,
      topAligned: true,
      filterKeyType: "enum",
      enumValues: Object.values(WasteManifestSource),
      enumPresentationFunction: (enumValue) =>
        wasteManifestSourceToLabel(enumValue as WasteManifestSource),
      valueGetter: ({ row }) => {
        return wasteManifestSourceToLabel(row.vendor);
      },
    },
    {
      field: "eManifest",
      headerName: "e-Manifest",
      flex: 0.7,
      sortable: false,
      align: "center",
      renderCell: ({ row }) => {
        if (!row?.hasEManifest) {
          return <></>;
        }

        return <Check />;
      },
    },
    {
      field: "vendorManifest",
      headerName: "Vendor Manifest",
      flex: 0.8,
      sortable: false,
      align: "center",
      renderCell: ({ row }) => {
        if (!row?.hasVendorManifest) {
          return <></>;
        }

        return <Check />;
      },
    },
    {
      field: "issues",
      headerName: "Issues",
      flex: 0.7,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return row.dataDiscrepancy ? (
          <Tooltip title="Data discrepancy between manifests" arrow>
            <Warning color="error" />
          </Tooltip>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <Box sx={{ py: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={MANIFESTS_QUERY}
        getItems={(data) => data.wasteManifests.items}
        getCount={(data) => data.wasteManifests.count}
        noDataMessage={`No manifests have been added to this organization.`}
        isRowSelectable={() => false}
        defaultSearch={defaultSearch}
        rowHeight={"auto"}
        commandButtons={btns}
        excludeFilterColumns={[
          "wasteLines",
          "wasteProfileNumber",
          "amount",
          "eManifest",
          "vendorManifest",
          "issues",
        ]}
        onSearchChanged={(search) => setSearch(search)}
      />
    </Box>
  );
}

function determineLineDisplay(line: DeepPartial<WasteLine>) {
  if (line?.vendorProfileDescription) {
    return line.vendorProfileDescription;
  }

  return line?.dotHazardous
    ? line.dotPrintedInformation
    : line?.nonHazWasteDescription;
}

function determineAmount(line: DeepPartial<WasteLine>) {
  if (line?.quantity && line.qtyUnitOfMeasureCode) {
    return `${line.quantity} ${getQtyUnits(line.qtyUnitOfMeasureCode)}`;
  }

  if (line?.quantityTons) {
    return `${line.quantityTons} tons`;
  }

  if (line?.quantityKg) {
    return `${line.quantityKg} kg`;
  }
}

function determineWasteProfileNumber(line: DeepPartial<WasteLine>) {
  if (line?.vendorProfileNumber) {
    return line.vendorProfileNumber ?? "";
  }

  return `${line?.dotIdNumberDescription ?? ""} ${
    line?.federalWasteCodes?.join(" ") ?? ""
  }`;
}

function getQtyUnits(code: string) {
  switch (code) {
    case "G":
      return "gals";
    case "K":
      return "kg";
    case "L":
      return "L";
    case "M":
      return "MT";
    case "N":
      return "cm";
    case "P":
      return "lbs";
    case "T":
      return "T";
    case "Y":
      return "cy";
    default:
      return "";
  }
}
