import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { FacilityPicker } from "components/FacilityPicker";
import { DateField } from "components/Forms/DateField";
import { SaveButton } from "components/SaveButton";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { gql } from "generated-graphql";
import {
  FacilityPickerFragment,
  NotReportingReason,
} from "generated-graphql/graphql";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { REPORTS_QUERY } from ".";
import { GET_REPORT } from "../Report/useReport";

/**
 * This component was originally created for the fulfillment and other report
 * tables.  This interface makes it a bit more generic in that we don't have a
 * specific type that is required. The data just needs to match the following
 * interface
 */
export interface NotReportingItem {
  reason?: NotReportingReason;
  facilityId: string;
  reportingYear: number;
  tenantId: string;
  tierIIReportId?: string | null;
  facilityDateClosed?: string | null;
  facilityDateInactive?: string | null;
  colocationReportingFacilityId?: string | null;
  isClosed?: boolean | null;
  isInactive?: boolean | null;
  isNotReporting?: boolean | null;
  isNotReportingThroughEncamp?: boolean | null;
}

interface NotReportingModalProps<T extends NotReportingItem> {
  reports: T[];
  open: boolean;
  onClose: () => void;
}

interface FormInput {
  selectedOption?: NotReportingReason;
  dateClosed?: string;
  dateInactive?: string;
  coLocatedId?: string;
}

const COLOCATION_FACILITY = gql(`
  query CoLocatedFacility($id: ID!) {
    facility(id: $id) {
      ...FacilityPicker
    }
  }
`);

const BULK_UPDATE_NOT_REPORTING_REASON_MUTATION = gql(`
  mutation BulkUpdateNotReportingReason($input: NotReportingReasonInput!) {
    bulkUpdateNotReportingReason(input: $input) {
      succeeded
    }
  }
`);

const currentYear = new Date().getFullYear();
const prevYearEnd = new Date(currentYear - 1, 11, 31); // Dec 31 of previous year, 0 index months FOR SOME REASON
const validationSchema = yup.object().shape({
  selectedOption: yup.string().required("This field is required"),
  dateClosed: yup.date().when("selectedOption", {
    is: "CLOSED",
    then: yup
      .date()
      .required("This field is required")
      .max(
        prevYearEnd,
        `Date closed cannot be after ${prevYearEnd.toLocaleDateString("en-us")}`
      ),
  }),
  dateInactive: yup.date().when("selectedOption", {
    is: "INACTIVE",
    then: yup
      .date()
      .required("This field is required")
      .max(
        prevYearEnd,
        `Date inactive cannot be after ${prevYearEnd.toLocaleDateString(
          "en-us"
        )}`
      ),
  }),
  coLocatedId: yup.string().when("selectedOption", {
    is: "CO_LOCATED",
    then: yup.string().nullable().required("This field is required"),
  }),
});

export function NotReportingModal<T extends NotReportingItem>(
  props: NotReportingModalProps<T>
) {
  const { open, onClose, reports } = props;
  const prevRefOpen = useRef(open);
  const [coLocatedFacility, setCoLocatedFacility] =
    useState<FacilityPickerFragment | null>(null);

  const singleMode = reports.length === 1;

  const { loading } = useQuery(COLOCATION_FACILITY, {
    variables: { id: reports[0].colocationReportingFacilityId ?? "" },
    skip: !singleMode || !reports[0].colocationReportingFacilityId,
    onCompleted(data) {
      if (data.facility) {
        setCoLocatedFacility(data.facility);
      }
    },
  });

  const defaultValues = useMemo<FormInput>(() => {
    let defaultOption: NotReportingReason | undefined = undefined;
    if (singleMode) {
      const reportData = reports[0];
      if (reportData?.reason) {
        defaultOption = reportData.reason;
      } else {
        if (reportData?.isClosed) {
          defaultOption = NotReportingReason.Closed;
        } else if (reportData?.isInactive) {
          defaultOption = NotReportingReason.Inactive;
        } else if (reportData?.colocationReportingFacilityId) {
          defaultOption = NotReportingReason.CoLocated;
        } else if (reportData?.isNotReporting) {
          defaultOption = NotReportingReason.UnderThreshold;
        } else if (reportData?.isNotReportingThroughEncamp) {
          defaultOption = NotReportingReason.NotWithEncamp;
        } else {
          defaultOption = NotReportingReason.Closed;
        }
      }
    }
    return {
      selectedOption: defaultOption,
      dateClosed:
        singleMode && reports[0].facilityDateClosed
          ? reports[0].facilityDateClosed
          : undefined,
      dateInactive:
        singleMode && reports[0].facilityDateInactive
          ? reports[0].facilityDateInactive
          : undefined,
      coLocatedId:
        singleMode && reports[0]
          ? reports[0].colocationReportingFacilityId ?? undefined
          : "",
    };
  }, [reports, singleMode]);

  const { handleSubmit, control, watch, reset } = useForm<FormInput>({
    resolver: validateData,
    defaultValues,
  });
  const selectedOption = watch("selectedOption");
  const [bulkUpdateNotReportingReason, { loading: saving }] = useMutation(
    BULK_UPDATE_NOT_REPORTING_REASON_MUTATION,
    {
      awaitRefetchQueries: true,
      onCompleted: () => {
        alerts.success(
          `Saved report${props.reports.length > 1 ? "s" : ""} successfully`
        );
      },
      onError: (error) => {
        alerts.error("An error occurred while saving the reports", error);
      },
      refetchQueries: [REPORTS_QUERY, GET_REPORT],
    }
  );
  const alerts = useAlerts();

  useEffect(() => {
    if (prevRefOpen.current === false && open === true) {
      reset(defaultValues);
    }
    prevRefOpen.current = open;
  }, [open, reset, defaultValues]);

  const onSubmit = useCallback(
    async (input: FormInput) => {
      if (input.selectedOption) {
        const result = await bulkUpdateNotReportingReason({
          variables: {
            input: {
              facilityIds: props.reports.map((report) => report.facilityId),
              reason: input.selectedOption,
              dateClosed: input.dateClosed,
              dateInactive: input.dateInactive,
              coLocatedId: input.coLocatedId,
            },
          },
        });
        if (!result.data?.bulkUpdateNotReportingReason?.succeeded) {
          alerts.error("Error saving reports.");
        }
        onClose();
      }
    },
    [bulkUpdateNotReportingReason, props.reports, alerts, onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        {"Set Facility as Not Reporting"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ height: "360px" }}>
          <FormLabel component="legend">
            Select the reason this facility is not reporting:
          </FormLabel>
          <Controller
            name="selectedOption"
            control={control}
            defaultValue={selectedOption}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value={NotReportingReason.Closed}
                  control={<Radio />}
                  label={`This facility was closed for the entire reporting year (Jan 1, ${currentTierIIReportingYear} through Dec 31, ${currentTierIIReportingYear})`}
                />
                {selectedOption === NotReportingReason.Closed && (
                  <Controller
                    name="dateClosed"
                    control={control}
                    render={({ field }) => (
                      <DateField
                        defaultValue={field.value}
                        name={field.name}
                        label="Date Closed"
                        control={control}
                      />
                    )}
                  />
                )}

                <FormControlLabel
                  value={NotReportingReason.Inactive}
                  control={<Radio />}
                  label={`This facility was inactive for the entire reporting year (Jan 1, ${currentTierIIReportingYear} through Dec 31, ${currentTierIIReportingYear})`}
                />
                {selectedOption === NotReportingReason.Inactive && (
                  <Controller
                    name="dateInactive"
                    control={control}
                    render={({ field }) => (
                      <DateField
                        defaultValue={field.value}
                        name={field.name}
                        label="Date Inactive"
                        control={control}
                      />
                    )}
                  />
                )}

                <FormControlLabel
                  value={NotReportingReason.CoLocated}
                  control={<Radio />}
                  label="This facility is co-located on the same site with another facility"
                />
                {selectedOption === NotReportingReason.CoLocated && (
                  <Controller
                    name="coLocatedId"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FacilityPicker
                        {...field}
                        {...fieldState}
                        disabled={!!defaultValues.coLocatedId && loading}
                        value={coLocatedFacility}
                        defaultSearchTerm={`tenantId:${reports[0].tenantId}`}
                        label="Co-located Facility"
                        disabledFacilitiesById={props.reports.map(
                          (report) => report.facilityId
                        )}
                        onChange={(fac) => {
                          setCoLocatedFacility(fac);
                          field.onChange(fac?.id ?? null);
                        }}
                      />
                    )}
                  />
                )}

                <FormControlLabel
                  value={NotReportingReason.UnderThreshold}
                  control={<Radio />}
                  label={`This facility's products were under the threshold for the entire reporting year (Jan 1, ${currentTierIIReportingYear} through Dec 31, ${currentTierIIReportingYear})`}
                />

                <FormControlLabel
                  value={NotReportingReason.NotWithEncamp}
                  control={<Radio />}
                  label="This facility is not reporting through Encamp"
                />
              </RadioGroup>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <SaveButton loading={saving} />
        </DialogActions>
      </form>
    </Dialog>
  );
}

type YupErrors = {
  inner: {
    path: string;
    message: string;
  }[];
};

const validateData = async (data: FormInput) => {
  try {
    await validationSchema.validate(data, { abortEarly: false });
    return { values: data, errors: {} };
  } catch (e) {
    return { values: {}, errors: convertYupErrorsToRHFErrors(e as YupErrors) };
  }
};

export const convertYupErrorsToRHFErrors = (
  yupErrors: YupErrors
): Record<string, { type: string; message: string }> => {
  const errors: Record<string, { type: string; message: string }> = {};

  for (const error of yupErrors.inner) {
    if (error.path) {
      errors[error.path] = {
        type: "validation",
        message: error.message,
      };
    }
  }

  return errors;
};
