import { useMutation } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CopyableText } from "components/CopyableText";
import { gql } from "generated-graphql";
import { TierIiReportOrgStatus } from "generated-graphql/graphql";
import { useCallback } from "react";
import { useDownloadReportPreview } from "routes/Customer/Chemicals/Report/useDownloadReportPreview";
import { launchPortalWithEncampy } from "../../../../util";
import { CredentialVerification } from "./CredentialVerification";
import { useReportDetails } from "./useReportDetails";

const mutation = gql(`
  mutation GenerateT2S($reportId: ID!) {
    generateT2S(reportId: $reportId)
  }
`);

const Label = styled("span")`
  font-weight: bold;
`;

const Field = styled("p")`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.25rem;
`;

export function StatePortal() {
  const theme = useTheme();
  const alerts = useAlerts();
  const { data } = useReportDetails();

  const portalCredential = data?.tierIIReport.facility.portalCredential;

  const [mutate, { loading: isGeneratingT2S }] = useMutation(mutation, {
    onCompleted({ generateT2S }) {
      window.open(generateT2S, "_blank");
    },
    onError(error) {
      alerts.error("We encountered an error generating T2S", error);
    },
  });

  const { downloadReportPreview, loadingPreview } = useDownloadReportPreview();

  const generateT2S = useCallback(
    async () =>
      await mutate({ variables: { reportId: data?.tierIIReport.id ?? "" } }),
    [data?.tierIIReport.id, mutate]
  );

  const shouldDisableT2SDownload =
    data?.tierIIReport.organizationStatus !== TierIiReportOrgStatus.Verified;

  const shouldDisableEncampy = !portalCredential?.url;

  function launchEncampy() {
    if (portalCredential) {
      launchPortalWithEncampy(portalCredential);
    }
  }

  return (
    <Stack
      direction="column"
      component={Paper}
      sx={{ border: `1px solid ${theme.palette.divider}` }}
      spacing={2}
      padding={theme.spacing(1)}
    >
      <Typography variant="h6">State Portal</Typography>
      <Box sx={{ padding: theme.spacing(1) }}>
        <Field>
          <Label>Username:</Label>{" "}
          {portalCredential?.credential.username ? (
            <CopyableText>{portalCredential?.credential.username}</CopyableText>
          ) : (
            "N/A"
          )}
        </Field>
        <Field>
          <Label>Password:</Label>{" "}
          {portalCredential?.credential.password ? (
            <CopyableText obscured>
              {portalCredential?.credential.password}
            </CopyableText>
          ) : (
            "N/A"
          )}
        </Field>
        <Field>
          <Label>URL:</Label>{" "}
          {portalCredential?.url ? (
            <CopyableText>{portalCredential.url}</CopyableText>
          ) : (
            "N/A"
          )}
        </Field>
      </Box>
      {portalCredential && (
        <>
          <Divider />
          <CredentialVerification
            credential={portalCredential.credential}
            facilityId={data.tierIIReport.facility.id ?? ""}
          />
        </>
      )}
      <Divider />
      <Stack direction={"row"}>
        <Button disabled={shouldDisableEncampy} onClick={launchEncampy}>
          <OpenInNew sx={{ marginRight: theme.spacing(1) }} />
          Open With Encampy
        </Button>
        <LoadingButton
          disabled={shouldDisableT2SDownload}
          onClick={generateT2S}
          loading={isGeneratingT2S}
        >
          <Download sx={{ marginRight: theme.spacing(1) }} />
          Tier2Submit
        </LoadingButton>
        <LoadingButton
          disabled={!data?.tierIIReport.id.length}
          onClick={async () =>
            await downloadReportPreview(data?.tierIIReport.id ?? "")
          }
          loading={loadingPreview}
        >
          <Download sx={{ marginRight: theme.spacing(1) }} />
          Generate PDF
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
