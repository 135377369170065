import FileDownload from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { useToken } from "providers/token";
import { useState } from "react";
import { useAlerts } from "./Alerts/AlertProvider";

export type ExportButtonProps = {
  endpoint: string;
  fileType: ExportFileType;
  disabled?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export enum ExportFileType {
  EXCEL,
}

const EXPORT_FN_MAP: {
  [fileType: string]: (
    authorization: string,
    endpoint: string
  ) => Promise<void>;
} = {
  [ExportFileType.EXCEL]: exportExcelFile,
};

async function exportExcelFile(authorization: string, endpoint: string) {
  const apiUrl =
    import.meta.env.MODE === "development"
      ? "http://localhost:4000"
      : import.meta.env.VITE_API_URL;

  const response = await fetch(`${apiUrl}${endpoint}`, {
    headers: {
      authorization,
    },
  });

  if (response.status >= 400) {
    throw new Error("Error generating download");
  }

  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    const { signedUrl } = await response.json();
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = signedUrl;
    document.body.appendChild(a);
    a.click();
  }
}

export const ExportButton: React.FC<ExportButtonProps> = (props) => {
  const { getIdTokenRefreshIfExpired } = useToken();
  const alerts = useAlerts();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingButton
      variant="outlined"
      size="small"
      startIcon={<FileDownload />}
      loading={props.loading ?? isLoading}
      onClick={async (event) => {
        props.onClick && props.onClick(event);

        if (!EXPORT_FN_MAP[props.fileType ?? ""]) {
          console.error(`File type ${props.fileType} not supported`);
          return;
        }

        const idToken = await getIdTokenRefreshIfExpired();
        const authorization = `Bearer ${idToken || ""}`;
        const callEndpoint = EXPORT_FN_MAP[props.fileType ?? ""];
        try {
          setIsLoading(true);
          return await callEndpoint(authorization, props.endpoint ?? "");
        } catch (err) {
          console.error(err);
          alerts.error("Error generating export file");
        } finally {
          setIsLoading(false);
        }
      }}
      disabled={props.disabled}
    >
      Export
    </LoadingButton>
  );
};
