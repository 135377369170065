import { useMutation } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import Cancel from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Close from "@mui/icons-material/Close";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonAdd from "@mui/icons-material/PersonAdd";
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { gql } from "generated-graphql";
import {
  AssignmentType,
  Permission,
  TierIiReportKind,
  TierIiReportOrgStatus,
} from "generated-graphql/graphql";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { REPORTS_QUERY, ReportRow } from ".";
import { AssigneesEditorDialog, AssignmentAction } from "./AssigneesEditor";
import { MarkReportingModal } from "./MarkReportingModal";
import { NotReportingModal } from "./NotReportingModal";
import { useFacilityReportEditable } from "hooks/useFacilityReportEditable";
import { useAuthorization } from "hooks/useAuthorization";
import React from "react";

export const BULK_OPEN_ANNUAL_REPORT = gql(`
  mutation OpenAnnualReports($reportingYear: Int!, $reportKind: TierIIReportKind!, $facilityIds: [ID!]!) {
    createTierIIReports(reportingYear: $reportingYear, reportKind: $reportKind, facilityIds: $facilityIds) {
      id
    }
  }
`);

type ReportBulkActionsMenuProps = {
  reports: ReportRow[];
};

const ReportBulkActionsMenu: React.FC<ReportBulkActionsMenuProps> = function ({
  reports,
}) {
  const { tenantId } = useParams<{ tenantId: string }>();
  const [showNotReportingModal, setShowNotReportingModal] = useState(false);
  const [markReportingModalOpen, setMarkReportingModalOpen] = useState(false);
  const [showAssigneeModal, setShowAssigneeModal] = useState<
    [AssignmentType, AssignmentAction] | undefined
  >(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { hasPermissionForFacility } = useAuthorization();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const alerts = useAlerts();
  const [bulkCreateReport] = useMutation(BULK_OPEN_ANNUAL_REPORT, {
    refetchQueries: [REPORTS_QUERY],
  });

  const bulkCreateAnnualReports = useCallback(async () => {
    try {
      handleClose();
      alerts.info("Starting bulk report initialization");
      await bulkCreateReport({
        variables: {
          // Currently there's no way to send this reports that have different reporting years
          // so we take the first report's reportingYear
          reportingYear: reports[0].reportingYear,
          reportKind: TierIiReportKind.Annual,
          facilityIds: reports
            .filter((report) => !report.tierIIReportId)
            .map((report) => report.facilityId),
        },
      });
    } catch (err) {
      alerts.error("There was an error during bulk report initialization");
      console.error(err);
    }
  }, [alerts, bulkCreateReport, reports]);

  const {
    canAddAssigneesOrReviewers,
    canMarkAsNotReporting,
    canMarkAsReporting,
  } = useFacilityReportEditable();

  invariant(tenantId);

  const {
    canAddAnyAssigneesOrReviewers,
    canMarkAnyAsNotReporting,
    canMarkAnyAsReporting,
    isAnnualEnabled,
    markNotReportingText,
  } = useMemo(() => {
    const canVerifyAndSubmit = reports.every((r) =>
      hasPermissionForFacility(r.facilityId, [Permission.VerifyTierIiReport])
    );
    const isAnnualEnabled =
      canVerifyAndSubmit &&
      reports.some((r) => r.organizationStatusAnnual === null);
    const canAddAnyAssigneesOrReviewers = reports.some((r) =>
      canAddAssigneesOrReviewers(r)
    );
    const canMarkAnyAsReporting = reports.some((r) => canMarkAsReporting(r));
    const reportsThatCanBeMarkedAsNotReporting = reports.filter((r) =>
      canMarkAsNotReporting(r)
    );
    const markNotReportingText = reportsThatCanBeMarkedAsNotReporting.some(
      (r) => r.organizationStatus !== TierIiReportOrgStatus.NotReporting
    )
      ? "Mark as Not Reporting"
      : "Edit Not Reporting Reason";
    const canMarkAnyAsNotReporting =
      reportsThatCanBeMarkedAsNotReporting.length > 0;
    return {
      canAddAnyAssigneesOrReviewers,
      canMarkAnyAsNotReporting,
      canMarkAnyAsReporting,
      isAnnualEnabled,
      markNotReportingText,
    };
  }, [
    reports,
    canMarkAsReporting,
    canAddAssigneesOrReviewers,
    canMarkAsNotReporting,
    hasPermissionForFacility,
  ]);

  const menuItems = [
    {
      items: [
        isAnnualEnabled && (
          <MenuItem
            key="createAnnualReport"
            disabled={reports.every((r) => hasValue(r.tierIIReportId))}
            onClick={bulkCreateAnnualReports}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText>Create Annual Report</ListItemText>
          </MenuItem>
        ),
      ].filter(Boolean),
    },
    {
      items: canAddAnyAssigneesOrReviewers
        ? [
            <MenuItem
              key="addAssignees"
              onClick={() => {
                setShowAssigneeModal([
                  AssignmentType.Assignee,
                  AssignmentAction.Assign,
                ]);
              }}
            >
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add Assignees</ListItemText>
            </MenuItem>,
            <MenuItem
              key="addReviewers"
              onClick={() => {
                setShowAssigneeModal([
                  AssignmentType.Reviewer,
                  AssignmentAction.Assign,
                ]);
              }}
            >
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add Reviewers</ListItemText>
            </MenuItem>,
            <Divider key="divider-1" />,
            <MenuItem
              key="removeAssignees"
              disabled={reports.flatMap((r) => r.assignees).length === 0}
              onClick={() => {
                setShowAssigneeModal([
                  AssignmentType.Assignee,
                  AssignmentAction.Remove,
                ]);
              }}
            >
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              <ListItemText>Remove Assignees</ListItemText>
            </MenuItem>,
            <MenuItem
              key="removeReviewers"
              disabled={reports.flatMap((r) => r.reviewers).length === 0}
              onClick={() => {
                setShowAssigneeModal([
                  AssignmentType.Reviewer,
                  AssignmentAction.Remove,
                ]);
              }}
            >
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              <ListItemText>Remove Reviewers</ListItemText>
            </MenuItem>,
          ]
        : [],
    },
    {
      items: [
        canMarkAnyAsNotReporting && (
          <MenuItem
            key="markNotReporting"
            onClick={() => setShowNotReportingModal(true)}
          >
            <ListItemIcon>
              <Cancel fontSize="small" />
            </ListItemIcon>
            <ListItemText>{markNotReportingText}</ListItemText>
          </MenuItem>
        ),
        canMarkAnyAsReporting && (
          <MenuItem
            key="markReporting"
            onClick={() => setMarkReportingModalOpen(true)}
          >
            <ListItemIcon>
              <CheckCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as Reporting</ListItemText>
          </MenuItem>
        ),
      ].filter(Boolean),
    },
  ];

  const shouldShowDivider = (index: number) => {
    // Check if the current group has items and if any of the remaining groups have items
    return (
      menuItems[index].items.length > 0 &&
      menuItems.slice(index + 1).some((group) => group.items.length > 0)
    );
  };

  const showMenu = menuItems.some((group) => group.items.length > 0);

  const disabled = reports.length === 0 || !showMenu;

  return (
    <>
      <Button
        id="report-bulk-actions-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disabled={disabled}
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        Bulk Actions
      </Button>
      <Menu
        id="report-bulk-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "report-bulk-actions-button",
        }}
      >
        <MenuList dense>
          {menuItems.map((group, index) => (
            <React.Fragment key={index}>
              {group.items}
              {shouldShowDivider(index) && <Divider />}
            </React.Fragment>
          ))}
        </MenuList>
      </Menu>
      {!!showAssigneeModal && (
        <AssigneesEditorDialog
          open={true}
          assignmentType={showAssigneeModal[0]}
          assignmentAction={showAssigneeModal[1]}
          onClose={() => setShowAssigneeModal(undefined)}
          tierIIReports={reports.filter((r) => canAddAssigneesOrReviewers(r))}
          tenantId={tenantId}
        />
      )}
      {showNotReportingModal && (
        <NotReportingModal
          open={showNotReportingModal}
          onClose={() => setShowNotReportingModal(false)}
          reports={reports}
        />
      )}
      {markReportingModalOpen && (
        <MarkReportingModal
          onClose={() => setMarkReportingModalOpen(false)}
          reports={reports}
        />
      )}
    </>
  );
};

export { ReportBulkActionsMenu };
