import { FormSelect } from "components/Forms/FormSelect";
import {
  getAmountCode,
  getAmountCodes,
} from "encamp-shared/src/constants/storageAmounts";
import { isNil } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

interface AmountCodeSelectProps {
  name: string;
  label: string;
  state?: string | null;
  amount?: number | null;
  disabled?: boolean;
}

export function AmountCodeSelect({
  name,
  label,
  state,
  amount,
  disabled = false,
}: AmountCodeSelectProps) {
  const { setValue } = useFormContext();

  // If max or average amount is set, then use the calculated value
  useEffect(() => {
    if (amount) {
      const calculatedCode = Number(getAmountCode(amount, state ?? ""));
      setValue(name, calculatedCode);
    }
  }, [amount, name, setValue, state]);

  const selectItems = useMemo(
    () =>
      Object.entries(getAmountCodes(state ?? "")).map(([value, display]) => ({
        value,
        display,
      })),
    [state]
  );

  return (
    <FormSelect
      label={label}
      selectItems={selectItems}
      name={name}
      disabled={!isNil(amount) || disabled}
    />
  );
}
