import QuestionMark from "@mui/icons-material/QuestionMark";
import { Box, Skeleton, Tooltip, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

type Props = {
  title?: string;
  subText?: string;
  subTextInTooltip?: boolean;
  number?: number;
  loading: boolean;
  href?: string;
};

export function StatCard({
  title,
  subText,
  subTextInTooltip,
  number,
  loading,
  href,
}: Props) {
  const formatter = Intl.NumberFormat("en", { notation: "standard" });
  const theme = useTheme();
  return (
    <Card
      sx={{
        minHeight: "180px",
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        ":hover": {
          boxShadow: href
            ? `0 0 0 1px ${theme.palette.primary.main}`
            : undefined,
        },
        border: `${theme.palette.divider} solid 1px`,
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: href ? "pointer" : "default",
        position: "relative",
        textDecoration: "none",
      }}
      component={href ? Link : "div"}
      to={href ?? ""}
    >
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "1.25rem",
              md: "1.25rem",
              lg: "0.8rem",
            },
          }}
        >
          {title}
        </Typography>
      </CardContent>

      <CardContent>
        {loading ? (
          <Skeleton
            variant="circular"
            width={70}
            height={70}
            sx={{ marginLeft: "auto", marginRight: "auto", marginBottom: 2 }}
          />
        ) : (
          <CardText
            number={number}
            formatter={formatter}
            subText={subText}
            subTextInTooltip={subTextInTooltip}
          />
        )}
      </CardContent>
    </Card>
  );
}

function CardText(props: {
  number?: number;
  formatter?: Intl.NumberFormat;
  subText?: string;
  subTextInTooltip?: boolean;
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: "center",
        position: "absolute",
        top: {
          lg: "58%",
          xs: "65%",
        },
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Typography
        variant="h4"
        component="div"
        sx={{
          flex: 1,
          fontSize: {
            xs: "1.7rem",
            md: "1.7rem",
            lg: "1.7rem",
          },
          width: "100%",
        }}
      >
        {props.number && props.formatter?.format(props.number)}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "0.75rem",
          color: theme.palette.grey[600],
          whiteSpace: "nowrap",
          visibility: props.subText ? "normal" : "hidden", // hack to get the numbers without subtext to align vertically with those that have subtext
        }}
      >
        {props.subTextInTooltip ? (
          <Tooltip title={props.subText}>
            <QuestionMark
              sx={{
                fontSize: ".9rem",
                paddingTop: "0.25rem",
                ursor: "pointer",
                color: theme.palette.grey[500],
              }}
            />
          </Tooltip>
        ) : (
          props.subText ?? "none"
        )}
      </Typography>
    </Box>
  );
}
