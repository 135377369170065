import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { TabLink, TabLinks } from "components/TabLinks";
import ChemicalUpsell from "components/UpsellLandingPages/ChemicalUpsell";
import { Permission } from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useProducts } from "hooks/useProducts";
import { isNil } from "lodash";
import { useMemo } from "react";
import {
  Navigate,
  Outlet,
  matchPath,
  useLocation,
  useMatch,
  useParams,
} from "react-router-dom";
import {
  getChemicalsCatalogBreadcrumb,
  getChemicalsContactsBreadcrumb,
  getChemicalsDocumentsBreadcrumb,
  getChemicalsInventoryBreadcrumb,
  getChemicalsReportsBreadcrumb,
} from "util/breadcrumb";

export function Chemicals() {
  const { featureFlags: flags, loading } = useFeatureFlags();

  const hasTierIIModule = flags?.["module-tierii"];

  if (loading) {
    return <ChemicalsSkeleton />;
  } else if (hasTierIIModule) {
    return <ChemicalsOutlet />;
  } else {
    return <ChemicalUpsell />;
  }
}

export function ChemicalsOutlet() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { pathname } = useLocation();
  const sectionMatch = useMatch({
    path: "/:tenantId/chemicals/:tab/:section",
    end: false,
  });
  const { hasPermissions } = useAuthorization();
  const { hasProducts } = useProducts();

  const tabs: (TabLink & { shouldRender: boolean })[] = useMemo(
    () => [
      {
        title: "Reports",
        to: tenantId ? getChemicalsReportsBreadcrumb(tenantId) : "",
        shouldRender: hasPermissions([Permission.RouteEpcraReports]),
      },
      {
        title: "Inventory",
        to: tenantId ? getChemicalsInventoryBreadcrumb(tenantId) : "",
        shouldRender: true,
      },
      {
        title: "Chemical Catalog",
        to: tenantId
          ? getChemicalsCatalogBreadcrumb(tenantId) +
            (isNil(sectionMatch?.params.section) ||
            sectionMatch?.params.tab !== "catalog"
              ? ""
              : `/${sectionMatch?.params.section}`)
          : "",
        shouldRender: hasPermissions([Permission.RouteEpcraProducts]),
      },
      {
        title: "Product Catalog",
        to: `/o/${tenantId}/chemicals/products`,
        shouldRender:
          !!hasProducts && hasPermissions([Permission.RouteEpcraProducts]),
      },
      {
        title: "Contacts",
        to: tenantId ? getChemicalsContactsBreadcrumb(tenantId) : "",
        shouldRender: hasPermissions([Permission.RouteContacts]),
      },
      {
        title: "Documents",
        to: tenantId ? getChemicalsDocumentsBreadcrumb(tenantId) : "",
        shouldRender: hasPermissions([Permission.RouteDocuments]),
      },
    ],
    [
      tenantId,
      hasPermissions,
      sectionMatch?.params.section,
      sectionMatch?.params.tab,
      hasProducts,
    ]
  );
  const currentTab = tabs.find(({ to }) => matchPath(`${to}/*`, pathname));

  // Redirect to first tab if we don't have a match
  if (!currentTab) {
    return <Navigate to={tabs[0].to} replace={true} />;
  }
  const tabIndex = tabs.indexOf(currentTab);

  return (
    <Box>
      <TabLinks
        value={tabs[tabIndex].to}
        tabs={tabs.filter((t) => t.shouldRender)}
        aria-label="chemicals tabs nav"
        role="navigation"
      />
      <Outlet />
    </Box>
  );
}

const ChemicalsSkeleton = () => {
  return (
    <Box>
      {/* Skeletons for the tabs */}
      <Stack direction="row" gap={2} mb={2}>
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
      </Stack>

      {/* Skeleton for the search bar */}
      <Stack direction="row">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={40}
          sx={{ mb: 2 }}
        />

        {/* Skeleton for the button */}
        <Skeleton
          variant="rectangular"
          width={120}
          height={40}
          sx={{ mb: 2 }}
        />
      </Stack>

      {/* Skeletons for the table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(new Array(5)).map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
