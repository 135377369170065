// components/CohortSelector.tsx
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { gql } from "generated-graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { InsightsCohort } from "generated-graphql/graphql";

const INSIGHTS_COHORTS = gql(`
  query InsightsCohorts($tenantId: String!) {
    insightsCohorts(tenantId: $tenantId) {
      tenantId
      id
      name
      nameSearches
      domainSearches
    }
  }
`);

export const CREATE_INSIGHTS_COHORT = gql(`
  mutation CreateInsightsCohort(
    $tenantId: String!
    $name: String!
    $nameSearches: [String!]!
    $domainSearches: [String!]!
  ) {
    createInsightsCohort(
      tenantId: $tenantId
      name: $name
      nameSearches: $nameSearches
      domainSearches: $domainSearches
    ) {
      id
      name
      nameSearches
      domainSearches
    }
  }
`);

export const UPDATE_INSIGHTS_COHORT = gql(`
  mutation UpdateInsightsCohort(
    $id: String!
    $name: String!
    $nameSearches: [String!]!
    $domainSearches: [String!]!
  ) {
    updateInsightsCohort(
      id: $id
      name: $name
      nameSearches: $nameSearches
      domainSearches: $domainSearches
    ) {
      id
      name
      nameSearches
      domainSearches
    }
  }
`);

export const DELETE_INSIGHTS_COHORT = gql(`
  mutation DeleteInsightsCohort($id: String!) {
    deleteInsightsCohort(id: $id) {
      id
    }
  }
`);

export default function CohortSelector({
  tenantId,
  onComparisonSubmit,
  loading,
  selectedCohortIds,
}: {
  tenantId: string;
  onComparisonSubmit: (selectedCohortIds: string[]) => void;
  loading: boolean;
  selectedCohortIds: string[];
}) {
  const [selectedCohort, setSelectedCohort] = useState<InsightsCohort[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteInsightsCohort] = useMutation(DELETE_INSIGHTS_COHORT);
  const [selectedCohortForEdit, setSelectedCohortForEdit] =
    useState<InsightsCohort | null>(null);

  const {
    data: insightsCohortsData,
    loading: insightsCohortsLoading,
    error: insightsCohortsError,
    refetch: refetchInsightsCohorts,
  } = useQuery(INSIGHTS_COHORTS, {
    variables: {
      tenantId,
    },
  });

  useEffect(() => {
    if (insightsCohortsData && selectedCohortIds) {
      const selectedCohorts = insightsCohortsData.insightsCohorts.filter(
        (cohort) => selectedCohortIds.includes(cohort.id)
      );
      setSelectedCohort(selectedCohorts);
    }
  }, [insightsCohortsData, selectedCohortIds]);

  const [createInsightsCohort] = useMutation(CREATE_INSIGHTS_COHORT);
  const [updateInsightsCohort] = useMutation(UPDATE_INSIGHTS_COHORT);

  const handleOpenModal = (cohort: InsightsCohort | null) => {
    setSelectedCohortForEdit(cohort);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCohortForEdit(null);
    setModalOpen(false);
  };

  const handleSaveCohort = async (cohortData: InsightsCohort) => {
    if (selectedCohortForEdit) {
      await updateInsightsCohort({
        variables: { ...cohortData, id: selectedCohortForEdit.id },
      });
    } else {
      await createInsightsCohort({
        variables: { ...cohortData, tenantId },
      });
    }
    refetchInsightsCohorts();
  };

  const handleDeleteCohort = async (cohortId: string) => {
    await deleteInsightsCohort({
      variables: { id: cohortId },
    });
    refetchInsightsCohorts();
    setSelectedCohort(selectedCohort.filter((c) => c.id !== cohortId));
  };

  const handleComparisonSubmit = () => {
    const selectedCohortIds = selectedCohort.map((cohort) => cohort.id);
    onComparisonSubmit(selectedCohortIds);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Autocomplete
        multiple
        options={insightsCohortsData?.insightsCohorts || []}
        getOptionLabel={(option) => option.name}
        value={selectedCohort}
        onChange={(event, newValue) => {
          const createNewOption = newValue.find(
            (option) => option.id === "create-new"
          );
          if (createNewOption) {
            newValue = newValue.filter((option) => option.id !== "create-new");
            handleOpenModal(null);
          }
          setSelectedCohort(newValue as InsightsCohort[]);
        }}
        filterOptions={(options, state) => {
          const filtered = options.filter((option) =>
            option.name.toLowerCase().includes(state.inputValue.toLowerCase())
          );
          return [
            ...filtered,
            {
              id: "create-new",
              name: "Create New",
              tenantId: "",
              nameSearches: [],
              domainSearches: [],
            },
          ];
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {option.name}
            <Box sx={{ marginLeft: "auto" }}>
              {option.id === "create-new" ? (
                <AddIcon
                  fontSize="small"
                  sx={{ ml: 1, cursor: "pointer" }}
                  onClick={() => handleOpenModal(null)}
                />
              ) : (
                <EditIcon
                  fontSize="small"
                  sx={{ ml: 1, cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenModal(option);
                  }}
                />
              )}
              <DeleteIcon
                fontSize="small"
                sx={{ ml: 1, cursor: "pointer" }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteCohort(option.id);
                }}
              />
            </Box>
          </Box>
        )}
        renderInput={(params) => <TextField {...params} label="Cohort" />}
        sx={{ flexGrow: 1, mr: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleComparisonSubmit}
        startIcon={loading ? <CircularProgress size={24} /> : null}
        disabled={loading}
      >
        Compare
      </Button>
      <CohortModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveCohort}
        cohort={selectedCohortForEdit}
        selectedCohort={selectedCohort}
        setSelectedCohort={setSelectedCohort}
      />
    </Box>
  );
}

function CohortModal({
  open,
  onClose,
  onSave,
  cohort,
  selectedCohort,
  setSelectedCohort,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (cohortData: InsightsCohort) => void;
  cohort: InsightsCohort | null;
  selectedCohort: InsightsCohort[];
  setSelectedCohort: (cohorts: InsightsCohort[]) => void;
}) {
  const [name, setName] = useState(cohort?.name || "");
  const [nameSearches, setNameSearches] = useState(cohort?.nameSearches || []);
  const [domainSearches, setDomainSearches] = useState(
    cohort?.domainSearches || []
  );

  useEffect(() => {
    setName(cohort?.name || "");
    setNameSearches(cohort?.nameSearches || []);
    setDomainSearches(cohort?.domainSearches || []);
  }, [cohort]);

  const handleSave = () => {
    const cohortData: InsightsCohort = {
      ...cohort,
      name,
      nameSearches,
      domainSearches,
      id: cohort?.id || "",
      tenantId: cohort?.tenantId || "",
    };
    onSave(cohortData);
    if (selectedCohort.some((c) => c.id === cohortData.id)) {
      setSelectedCohort(
        selectedCohort.map((c) => (c.id === cohortData.id ? cohortData : c))
      );
    } else {
      setSelectedCohort([...selectedCohort, cohortData]);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{cohort ? "Edit Cohort" : "Create Cohort"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Cohort Title"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Typography variant="h6">Search Terms</Typography>
        <TextField
          label="Name Searches"
          value={nameSearches.join(",")}
          onChange={(e) => setNameSearches(e.target.value.split(","))}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Domain Searches"
          value={domainSearches.join(",")}
          onChange={(e) => setDomainSearches(e.target.value.split(","))}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
