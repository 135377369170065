import { TypedDocumentNode, useQuery } from "@apollo/client";
import {
  AutocompleteFreeSoloValueMapping,
  AutocompleteValue,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { SyntheticEvent, useCallback, useState } from "react";
import { FieldError } from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";

/**
 * Props for the EncampAutocomplete component.
 */
interface EncampAutocompleteProps<
  TData,
  TOption extends { id: string },
  TAdditionalVars extends Record<string, unknown>,
  TMultiple extends boolean | undefined = false,
  TFreeSolo extends boolean | undefined = false
> {
  /** GraphQL query document */
  query: TypedDocumentNode<TData, TAdditionalVars>;
  /** Function to extract items from the query result */
  getItems: (data: TData) => TOption[];
  /** Function to extract the label from an option */
  getOptionLabel: (
    option: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>
  ) => string;
  /** Additional variables for the query */
  variables?: Partial<TAdditionalVars>;
  /** Error to display */
  error?: FieldError;
  /** Display in a disabled state */
  disabled?: boolean;
  /** Input Label */
  label?: string;
  /** Select multiple options */
  multiple?: TMultiple;
  /** Allow arbitrary input  */
  freeSolo?: TFreeSolo;
  /** Callback for when an option is selected */
  onChange?: (
    value: AutocompleteValue<TOption, TMultiple, false, TFreeSolo>
  ) => void;
  getOptionDisabled?: (option: TOption) => boolean;
  /** The value of the autocomplete */
  value?: AutocompleteValue<TOption, TMultiple, false, TFreeSolo>;
  /** The maximum number of tags that will be visible when not focused. Set -1 to disable the limit. */
  limitTags?: number;
}

/**
 * EncampAutocomplete Component
 * A generic autocomplete component that supports pagination and GraphQL integration.
 *
 * @param query - The GraphQL query document to fetch data.
 * @param getItems - Function to extract items from the query result.
 * @param getOptionLabel - Function to extract the label from an option.
 * @param variables - Variables for the query.
 * @param onChange - Callback for when an option is selected.
 * @param value - The value of the autocomplete.
 * @param error - Error to display.
 * @returns A JSX element representing the paginated autocomplete component.
 *
 * @example
 * ```tsx
 * <EncampAutocomplete
 *   query={FIRE_DEPARTMENTS_QUERY}
 *   variables={{
 *     state: facilityState,
 *     county: facilityCounty,
 *   }}
 *   getItems={(data: FireDepartmentsQuery) =>
 *     data.fireDepartments?.items ?? []
 *   }
 *   onChange={onChange}
 *   getOptionLabel={(fireDepartment) =>
 *     `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.state}`
 *   }
 *   value={value}
 *   error={error}
 * />
 * ```
 */
const EncampAutocomplete = <
  TData,
  TOption extends { id: string },
  TAdditionalVars extends Record<string, unknown>,
  TMultiple extends boolean | undefined = false,
  TFreeSolo extends boolean | undefined = false
>({
  query,
  getItems,
  getOptionLabel,
  variables = {},
  onChange,
  getOptionDisabled,
  value,
  error,
  disabled,
  label,
  multiple,
  freeSolo,
  limitTags,
}: EncampAutocompleteProps<
  TData,
  TOption,
  TAdditionalVars,
  TMultiple,
  TFreeSolo
>) => {
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<TOption[]>([]);
  type VariablesType = Partial<TAdditionalVars>;

  const {
    data,
    loading,
    error: dataLoadingError,
  } = useQuery<TData, VariablesType>(query, {
    variables: {
      ...variables,
    } as VariablesType,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const items = data ? getItems(data) : [];
      setOptions((prevOptions) => items);
    },
    skip: disabled,
  });

  // Handler for input changes in the autocomplete
  const handleInputChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newInputValue: string) => {
      setSearch(newInputValue);
    },
    []
  );

  return (
    <>
      {dataLoadingError && (
        <Typography variant="body2" color="error">
          An error occurred while searching.
        </Typography>
      )}
      <Autocomplete
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        // Disable the built-in filtering since it is handled server-side
        filterOptions={(options) => {
          return options.filter((option) => {
            const optionString =
              // @ts-ignore
              `${option.name} ${option.city}, ${option.state}`.toLowerCase();
            if (optionString.includes(search.toLowerCase())) {
              // @ts-ignore
              return true;
            }
            return false;
          });
        }}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_, value) => {
          onChange?.(value);
        }}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            label={label ? label : "Search"}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        loading={loading}
        loadingText="Loading..."
        noOptionsText="No options found."
        multiple={multiple}
        freeSolo={freeSolo}
        limitTags={disabled ? undefined : limitTags}
      />
      <ErrorDisplay error={error} />
    </>
  );
};

export default EncampAutocomplete;
