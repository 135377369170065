import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";
import {
  FacilityInput,
  ProgramArea,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { reportStepMetadata } from "util/constants";
import { ReportFloatingBar } from "./ReportFloatingBar";
import { StepperPageHeader } from "./StepperPageHeader";
import { useNavigateReportSaveCancel } from "./useNavigateReportSaveCancel";
import {
  useReport,
  useStateInfoIssues,
  useUpdateFacilityReport,
} from "./useReport";
import { useFacilityInputValidation } from "./validationHooks/useFacilityInputValidation";
import { useTouchReportMutation } from "./useTouchReportMutation";
import { FacilityDetailsInput } from "routes/Customer/Facility/FacilityProfile";
import { omit } from "lodash";
import { transform as transformStateField } from "hooks/transform/transformStateField";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";
import { StateFieldsForm } from "components/Forms/StateFieldsForm";

gql(`
  fragment stateInfo on Facility {
    stateFields {
      key
      value
      type
      jurisdiction
      inputType
      label
      tooltip
      selectOptions {
        label
        value
      }
    }
  }
`);

export const StateInformation: React.FC = () => {
  const alerts = useAlerts();
  const navigateToOverview = useNavigateReportSaveCancel();
  const { data, loading } = useReport();
  const { handleSave: handleTouch, loading: touchLoading } =
    useTouchReportMutation(
      data?.tierIIReport.id ?? "",
      data?.tierIIReport.touchedSteps ?? [],
      TierIiReportStep.StateInformation
    );

  const dynamicFields = useMemo(
    () => data?.tierIIReport.facility.stateFields ?? [],
    [data?.tierIIReport.facility.stateFields]
  );

  const defaultValues: FacilityDetailsInput = useMemo(
    () => ({
      state: data?.tierIIReport.facility.state,
      lepcId: data?.tierIIReport.facility.lepcId,
      fireDepartmentId: data?.tierIIReport.facility.fireDepartmentId,
      stateFields: dynamicFields.map((sf) => ({
        ...omit(sf, "selectOptions", "tooltip"),
        value: sf.value ?? null,
        type: ProgramArea.Epcra,
      })),
    }),
    [data, dynamicFields]
  );

  const form = useValidatingForm(
    defaultValues,
    useStateInfoIssues(),
    useFacilityInputValidation({
      facilityId: data?.tierIIReport.facility.id ?? "",
      reportId: data?.tierIIReport.id,
      pick: ["stateFields"],
    })
  );

  const [updateFacility, { loading: submitting }] = useUpdateFacilityReport();

  const {
    formState: { isDirty, isSubmitSuccessful, isSubmitted },
  } = form;

  const hasPendingChanges = isDirty && !isSubmitSuccessful && !isSubmitted;

  const onSubmit = useCallback(
    async (input: FacilityDetailsInput) => {
      if (!data) return;
      const saveData = {
        ...input,
        stateFields: input.stateFields?.map(transformStateField),
      };

      try {
        await updateFacility({
          variables: {
            id: data.tierIIReport.facility.id,
            facility: saveData,
            reportId: data.tierIIReport.id,
            programArea: ProgramArea.Epcra,
          },
          awaitRefetchQueries: true,
        });
        alerts.success("State information saved successfully.");
        await handleTouch();
      } catch (err) {
        alerts.error("Error saving state information.");
        console.info("Error saving state information.", err);
      }
    },
    [alerts, data, handleTouch, updateFacility]
  );

  return (
    <FormProvider {...form}>
      <form style={{ flexGrow: 1 }}>
        <Stack
          sx={{
            height: "100%",
            flexDirection: "column",
          }}
        >
          <StepperPageHeader
            header="State Information"
            description={
              reportStepMetadata.STATE_INFORMATION.overviewDescription
            }
          />

          {loading ? (
            <Skelly />
          ) : dynamicFields.length === 0 ? (
            <Typography variant="body1">
              There are no additional state fields for this facility
            </Typography>
          ) : (
            <StateFieldsForm
              context="facility"
              fields={data?.tierIIReport?.facility.stateFields ?? []}
              namePrefix="stateFields"
            />
          )}
          <ReportFloatingBar
            saving={submitting}
            onSaveClick={form.handleSubmit(onSubmit)}
            loading={loading || touchLoading}
            onCancel={navigateToOverview}
            issues={form.issues}
          />
          <UnfinishedChangesPrompt when={hasPendingChanges} />
        </Stack>
      </form>
    </FormProvider>
  );
};

function Skelly() {
  const theme = useTheme();
  return (
    <Stack gap={theme.spacing(2)}>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>
    </Stack>
  );
}
