import { useQuery } from "@apollo/client";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { gql } from "generated-graphql";
import {
  ProductPickerFragment,
  ProductsQueryQuery,
} from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import React, { forwardRef, useState } from "react";
import { ControllerFieldState } from "react-hook-form";
import { ErrorDisplay } from "./Forms/ErrorDisplay";
import { FormTextField } from "./Forms/FormTextField";

gql(/* GraphQL */ `
  fragment ProductPicker on Product {
    id
    name
  }
`);

const PRODUCTS_QUERY = gql(/* GraphQL */ `
  query ProductsQuery(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
    $facilityId: ID
    $reportingYear: Int
  ) {
    products(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...ProductPicker
        facilityProducts(
          facilityId: $facilityId
          reportingYear: $reportingYear
        ) {
          id
        }
      }
      count
    }
  }
`);

type Product = ProductsQueryQuery["products"]["items"][number];

type ProductPickerProps = ControllerFieldState & {
  onChange: (product: Product | null) => void;
  defaultSearchTerm?: string;
  value?: ProductPickerFragment | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isOptionDisabled?: (product: Product) => boolean;
  facilityId?: string;
  reportingYear?: number;
};

export const ProductPicker: React.FC<ProductPickerProps> = forwardRef(
  function ProductPicker(
    {
      onChange,
      defaultSearchTerm = "",
      value: initialValue,
      error,
      required,
      disabled,
      readOnly,
      isOptionDisabled,
      facilityId,
      reportingYear,
    },
    ref
  ) {
    const [value, setValue] = useState(initialValue ?? null);
    const [search, setSearch] = useState(value?.name ?? "");
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [debouncedSearch] = useDebounce(
      `${defaultSearchTerm} ${search}`,
      150
    );

    const { data, loading, previousData } = useQuery(PRODUCTS_QUERY, {
      variables: {
        search: debouncedSearch,
        page: 0,
        pageSize: 5,
        facilityId,
        reportingYear,
      },
      skip: !searchIsOpen,
      fetchPolicy: "network-only",
    });

    const componentLabel = "Product Name";

    if (readOnly) {
      return (
        <>
          <FormTextField
            label={componentLabel}
            readOnly
            defaultValue={value?.name}
          />
          <ErrorDisplay error={error} />
        </>
      );
    }

    return (
      <>
        <Autocomplete<Product>
          ref={ref}
          options={data?.products.items ?? previousData?.products.items ?? []}
          isOptionEqualToValue={(o, v) => o.name === v.name}
          noOptionsText="Search for a products in your inventory"
          loading={loading}
          loadingText="Loading products..."
          onOpen={() => setSearchIsOpen(true)}
          onClose={() => setSearchIsOpen(false)}
          inputValue={search}
          value={value}
          disabled={disabled}
          onInputChange={(event, value) => setSearch(value)}
          onChange={(event, value) => {
            setValue(value);
            onChange(value);
          }}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={isOptionDisabled}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              label={componentLabel}
              variant="outlined"
              error={!!error}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <ErrorDisplay error={error} />
      </>
    );
  }
);
