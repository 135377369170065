import { Box, Button, Tooltip, styled, useTheme } from "@mui/material";
import { Credential } from "generated-graphql/graphql";
import { CredentialVerificationStatus } from "generated-graphql/graphql";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { relativeLogTime } from "util/dates";
import { useVerifyCredential } from "hooks/useVerifyCredentials";

const RELATIVE_TIME = 12;
const NEVER_CHECKED = "never checked";

const Label = styled("span")`
  font-weight: bold;
`;

const Field = styled("p")`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.25rem;
`;

export const CredentialVerification = ({
  credential,
  facilityId,
  disabled = false,
}: {
  credential: Credential | undefined | null;
  facilityId: string;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const [verifyCredential, { loading: sendingCredentialVerification }] =
    useVerifyCredential();
  const handleVerifyCredential = async () => {
    await verifyCredential({
      variables: {
        input: {
          facilityId: facilityId,
          credentialId: credential?.id,
        },
      },
      refetchQueries: ["GetTierIIReport", "GetCredential"],
    });
  };

  const verificationTime = credential?.lastVerificationCheckAt;

  return (
    <Box>
      <Field>
        <Label>Verification Status:</Label>{" "}
        {prettyPrintEnumValue(credential?.verificationStatus)}
      </Field>
      <Field>
        <Label>Last Check:</Label>{" "}
        <Tooltip title={verificationTime}>
          <span>
            {verificationTime
              ? relativeLogTime(verificationTime, RELATIVE_TIME)
              : NEVER_CHECKED}
          </span>
        </Tooltip>
      </Field>
      {credential?.verificationStatus !== CredentialVerificationStatus.Good &&
      !!credential?.lastVerificationSuccessAt ? (
        <Field>
          <Label>Last Successful Verification Check:</Label>{" "}
          <Tooltip title={credential?.lastVerificationSuccessAt}>
            <span>
              {credential?.lastVerificationCheckAt
                ? relativeLogTime(
                    credential?.lastVerificationSuccessAt,
                    RELATIVE_TIME
                  )
                : NEVER_CHECKED}
            </span>
          </Tooltip>
        </Field>
      ) : null}
      <Box paddingTop={theme.spacing(1)}>
        <Button
          disabled={
            disabled ||
            credential?.verificationStatus ===
              CredentialVerificationStatus.CurrentlyVerifying ||
            !!sendingCredentialVerification
          }
          variant="outlined"
          onClick={handleVerifyCredential}
        >
          Verify Credential
        </Button>
      </Box>
    </Box>
  );
};
