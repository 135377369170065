import CheckCircleOutlineRounded from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";
import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import pluralize from "pluralize";
import { useMatch, useNavigate } from "react-router-dom";
import { ReportStepState } from "util/constants";
import { StepState } from "./useReport";

type OverviewCardButtonProps = {
  status: ReportStepState | undefined;
  issueCount: number;
};

const OverviewCardButton: React.FC<OverviewCardButtonProps> = ({
  status,
  issueCount,
}) => {
  const theme = useTheme();

  const statusToButtonText: { [key in ReportStepState]: string } = {
    [ReportStepState.NotStarted]: "Not Started",
    [ReportStepState.Done]: "Complete",
    [ReportStepState.Invalid]: `Fix ${issueCount} ${pluralize(
      "Issue",
      issueCount
    )}`,
  };

  const statusToColor: {
    [key in ReportStepState]: string;
  } = {
    [ReportStepState.NotStarted]: theme.palette.action.active,
    [ReportStepState.Done]: theme.palette.success.main,
    [ReportStepState.Invalid]: theme.palette.error.main,
  };

  const statusToButtonIcon: {
    [key in ReportStepState]: React.ReactNode;
  } = {
    [ReportStepState.NotStarted]: (
      <PlayCircleOutline
        sx={{ color: statusToColor[ReportStepState.NotStarted] }}
      />
    ),
    [ReportStepState.Done]: (
      <CheckCircleOutlineRounded
        sx={{ color: statusToColor[ReportStepState.Done] }}
      />
    ),
    [ReportStepState.Invalid]: (
      <ErrorOutlineRounded
        sx={{ color: statusToColor[ReportStepState.Invalid] }}
      />
    ),
  };

  const buttonText = status ? statusToButtonText[status] : "";
  const buttonColor = status ? statusToColor[status] : "primary";
  const icon = status ? statusToButtonIcon[status] : <></>;

  return !status ? (
    <Skeleton
      variant="rounded"
      sx={{ width: theme.spacing(8), height: theme.spacing(4) }}
    />
  ) : (
    <Button size="small" sx={{ color: buttonColor }} startIcon={icon}>
      <Box lineHeight={1}>{buttonText}</Box>
    </Button>
  );
};

export const OverviewCard: React.FC<{
  title: string;
  route: string;
  step: StepState;
  showIssueDescriptions?: boolean;
}> = ({ title, step, route, showIssueDescriptions = false }) => {
  const { status, issues, metaData } = step;
  const theme = useTheme();
  const navigate = useNavigate();
  const displayIssues = issues?.slice(0, 10);
  const moreIssuesCount = issues ? issues.length - 10 : 0;

  const match = useMatch({
    path: "/o/:tenantId/chemicals/reports/:reportId/:step",
    end: false,
  });

  const isViewingStep = !!match?.params.step;
  const isViewingReview = match?.params.step === "review";

  return (
    <Paper
      sx={{
        p: theme.spacing(2),
        mb: 1,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
          cursor: "pointer",
        },
      }}
      onClick={() => {
        const to = isViewingStep ? `../${route}` : route;
        navigate(to, { state: { fromReview: isViewingReview } });
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap={theme.spacing(8)}
      >
        <Stack spacing={theme.spacing(2.5)}>
          <Typography
            variant="body1"
            fontWeight="medium"
            marginTop={theme.spacing(0.5)}
          >
            {title}
          </Typography>
          {showIssueDescriptions && (
            <>
              <Typography variant="body2" marginTop={theme.spacing(2.5)}>
                {metaData?.overviewDescription}
              </Typography>
              {!!issues?.length && (
                <Box>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color={theme.palette.error.main}
                    sx={{ mb: 0.5 }}
                  >
                    Issues:
                  </Typography>
                  <List
                    sx={{
                      listStyleType: "disc",
                      pt: 0,
                      pl: theme.spacing(2),
                      color: theme.palette.error.main,
                    }}
                  >
                    {issues &&
                      displayIssues?.map((issue, index) => (
                        <ListItem
                          key={index}
                          sx={{ mt: 0, mb: 0, p: 0, display: "list-item" }}
                        >
                          <ListItemText
                            secondary={issue.message}
                            secondaryTypographyProps={{
                              color: theme.palette.error.main,
                            }}
                          />
                        </ListItem>
                      ))}

                    {issues.length > 10 && (
                      <ListItem
                        sx={{ mt: 0, mb: 0, p: 0, display: "list-item" }}
                      >
                        <ListItemText
                          secondary={`${moreIssuesCount} more ${pluralize(
                            "issue",
                            moreIssuesCount
                          )}`}
                          secondaryTypographyProps={{
                            color: theme.palette.error.main,
                          }}
                        />
                      </ListItem>
                    )}
                  </List>
                </Box>
              )}
            </>
          )}
        </Stack>
        <Box>
          <OverviewCardButton
            status={status}
            issueCount={issues?.length ?? 0}
          />
        </Box>
      </Box>
    </Paper>
  );
};
