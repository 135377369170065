import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { PropsWithChildren } from "react";
import { SignInForm } from "components/SignInForm";

const isLocal = location.hostname.includes("localhost");

// We need to scope the cookie to a single domain to prevent bloated headers
// being sent to our support site resulting in a 400 error.
// If in the future we need to share cognito sessions across multiple applications
// the other applications will need to be added as subdomains of the main application,
// e.g. otherapp.ehs.encamp.com, and the cookie domain will need to be set to ".ehs.encamp.com".
const cookieStorageDomain = location.hostname;

const baseCognitoUrl = new URL(import.meta.env.VITE_COGNITO_BASE_URL);

const amplifyConfiguration = {
  aws_user_pools_id: import.meta.env.VITE_USER_POOL_ID,
  aws_user_pools_web_client_id: import.meta.env.VITE_CLIENT_ID,
  Auth: {
    userPoolWebClientId: import.meta.env.VITE_CLIENT_ID,
    cookieStorage: {
      domain: cookieStorageDomain,
      path: "/",
      expires: 1,
      secure: !isLocal,
    },
  },
  oauth: {
    domain: baseCognitoUrl.hostname,
    responseType: "code",
    scope: ["email", "openid", "profile"],
    redirectSignOut: location.origin,
    redirectSignIn: location.origin,
  },
};

Amplify.configure(amplifyConfiguration);

export function AuthProvider(props: PropsWithChildren<unknown>) {
  return <SignInForm>{props.children}</SignInForm>;
}
