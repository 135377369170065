import Close from "@mui/icons-material/Close";
import { Box, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import _ from "lodash";
import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import EncampLogo from "../assets/EncampLogo.svg";
import { useAlerts } from "./Alerts/AlertProvider";

type StandardNavItem = {
  kind: "Standard";
  text: string;
  path?: string;
  isDisabled?: boolean;
  textColor?: string;
  Icon: React.ElementType;
};

type CustomNavItem = {
  kind: "CustomElement";
  customElement: JSX.Element;
};

export type NavItem = StandardNavItem | CustomNavItem;

interface NavDrawerProps {
  navItems: NavItem[];
  open?: boolean;
  drawerWidth: string;
  onClose?: () => void;
}

function isPathActive(path: string | undefined, text: string) {
  const currentPath = window.location.pathname;
  const pathToMatch = `/${
    path ? path : text.split("/").map(_.kebabCase).join("/")
  }`;
  return currentPath.includes(pathToMatch);
}

export function LeftNavDrawer({
  navItems,
  open = true,
  drawerWidth,
  onClose = () => {},
}: NavDrawerProps) {
  const location = useLocation();
  const alerts = useAlerts();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const selected = pathnames[pathnames.length - 1];
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Drawer
      variant={"persistent"}
      open={open}
      sx={{
        display: open ? "block" : "none",
        width: {
          md: open ? drawerWidth : 0,
          xs: open ? "100vw" : 0,
        },
      }}
    >
      <Box
        sx={{
          width: isLargeScreen ? drawerWidth : "100vw",
          position: "relative",
          flex: 1,
          backgroundColor:
            theme.palette.mode === "light"
              ? "#051E3C"
              : theme.palette.background.default,
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{
            zIndex: 99999,
            position: "absolute",
            color: "white",
            top: 0,
            right: 0,
            display: isLargeScreen ? "none" : "box",
          }}
          onClick={onClose}
        >
          <Close sx={{ fontSize: "3rem" }} />
        </IconButton>
        <Stack
          sx={{
            height: {
              md: "150px",
              xs: "50px",
            },
            marginTop: {
              md: 0,
              xs: "20px",
            },
            marginBottom: {
              md: 0,
              xs: "20px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <img
              src={EncampLogo}
              alt="Encamp logo"
              style={{
                width: isLargeScreen ? "40%" : "25%",
              }}
            />
          </Box>
        </Stack>
        <List
          sx={{
            paddingTop: {
              md: 0,
              xs: 0,
            },
          }}
        >
          {navItems.map((navItem, idx) => {
            if (navItem.kind === "CustomElement") {
              return <Fragment key={idx}>{navItem.customElement}</Fragment>;
            } else {
              const { Icon, path, text, textColor } = navItem;

              const isActive = isPathActive(path, text);
              const isDisabled = navItem.isDisabled === true;

              let iconColor = "";

              if (textColor) {
                iconColor = textColor;
              } else {
                if (isActive) {
                  iconColor = "white";
                } else {
                  iconColor = "#8D98A6";
                }
              }

              return (
                <ListItem key={`${text}-${path}`} disablePadding>
                  <NavLink
                    onClick={(e) => {
                      if (isDisabled) {
                        e.preventDefault();
                        alerts.info("This section is not yet available.");
                      } else if (!isLargeScreen) {
                        onClose();
                      }
                    }}
                    to={`/${
                      path ? path : text.split("/").map(_.kebabCase).join("/")
                    }`}
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: "inherit",

                      width: "100%",
                      background: isActive ? "#4A88D0" : "none",
                    })}
                  >
                    <ListItemButton
                      sx={{
                        "&:hover": {
                          ".MuiSvgIcon-root": {
                            color: isActive ? "white" : "#4A88D0",
                          },
                          ".MuiTypography-root": {
                            color: isActive ? "white" : "#4A88D0",
                          },
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          paddingRight: {
                            md: "5px",
                            xs: "100px",
                          },
                          paddingLeft: {
                            md: "10px",
                            xs: "30px",
                          },
                        }}
                      >
                        <Icon
                          sx={{
                            fontSize: { xs: "60px", md: "24px" },
                            color: iconColor,
                            opacity: textColor && !isActive ? 0.5 : 1,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        primaryTypographyProps={{
                          fontSize: { xs: "40px", md: "16px" },
                          color: textColor ? textColor : "white",
                          marginLeft: {
                            md: "-10px",
                            xs: "-60px",
                          },
                        }}
                      />
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              );
            }
          })}
        </List>
      </Box>
    </Drawer>
  );
}
