import CopyAllOutlined from "@mui/icons-material/CopyAllOutlined";
import { IconButton } from "@mui/material";
import { PropsWithChildren } from "react";
import { useAlerts } from "./Alerts/AlertProvider";

type CopyableTextProps = {
  obscured?: boolean;
  hideText?: boolean;
};

export function CopyableText(props: PropsWithChildren<CopyableTextProps>) {
  const alerts = useAlerts();
  const obscured = props.obscured ?? false;
  const hideText = props.hideText ?? false;

  function handleCopy() {
    navigator.clipboard.writeText(props.children?.toString() ?? "");
    alerts.info("Copied to clipboard");
  }

  if (!props.children) return <></>;

  return (
    <span>
      {!hideText && (obscured ? "********" : props.children)}{" "}
      <IconButton onClick={handleCopy} sx={{ padding: 0 }}>
        <CopyAllOutlined />
      </IconButton>
    </span>
  );
}
