import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DataGrid } from "components/DataGrid";
import { Dialog } from "components/Dialog";
import { getFullName } from "encamp-shared/src/utils/name";
import { gql } from "generated-graphql";
import { GetFacilitiesByPersonQuery } from "generated-graphql/graphql";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import invariant from "invariant";
import pluralize from "pluralize";

type DeleteContactDialogProps = {
  open: boolean;
  onClose: () => void;
  personId: string;
};

type Row = GetFacilitiesByPersonQuery["facilities"]["items"][0];

const GET_PERSON_FOR_DELETION = gql(/* GraphQL */ `
  query GetPersonForDeletion($personId: ID!) {
    person(id: $personId) {
      id
      first
      last
      user {
        id
      }
    }
  }
`);

const GET_FACILITIES_BY_PERSON = gql(/* GraphQL */ `
  query GetFacilitiesByPerson(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        streetAddress1
        streetAddress2
        city
        state
        zip
        country
      }
      count
    }
  }
`);

const DELETE_PERSON_MUTATION = gql(`
mutation DeletePerson($id: ID!) {
  deletePerson(id: $id) {
    id
  }
}
`);

export function DeleteContactDialog({
  open,
  onClose,
  personId,
}: DeleteContactDialogProps) {
  const theme = useTheme();
  const alerts = useAlerts();
  const { tenantId } = useTenant();
  invariant(tenantId, "tenantId not found");

  const { paginationModel, sortModel, columns, setPaginationModel } =
    useOmnisearchDatagrid<Row>({
      isURLDriven: false,
      initialPageSize: 10,
      initialSortModel: [{ field: "name", sort: "asc" }],
      columns: [
        {
          headerName: "Facility",
          field: "name",
          flex: 1,
          filterKeyType: "facility",
        },
        {
          headerName: "Location",
          field: "streetAddress",
          flex: 1,
          valueGetter({ row }) {
            return `${row.city}, ${row.state}`;
          },
        },
      ],
    });

  const {
    data: personData,
    loading: personLoading,
    error: personError,
  } = useQuery(GET_PERSON_FOR_DELETION, {
    variables: { personId },
    skip: !personId,
  });

  const {
    data: facilitiesData,
    previousData: previousFacilitiesData,
    loading: facilitiesLoading,
    error: facilitiesError,
  } = useQuery(GET_FACILITIES_BY_PERSON, {
    variables: {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
      search: `personId:${personId} tenantId:${tenantId}`,
    },

    skip: !personId,
  });

  const [deletePerson, { loading: deleting }] = useMutation(
    DELETE_PERSON_MUTATION,
    {
      refetchQueries: ["Contacts"],
    }
  );

  const handleDelete = async () => {
    try {
      await deletePerson({ variables: { id: personId } });
      alerts.success("Successfully deleted contact");
      onClose();
    } catch (err) {
      alerts.error("There was an error deleting this contact", err);
    }
  };

  const error = personError || facilitiesError;
  const loading =
    personLoading ||
    (!previousFacilitiesData?.facilities.items.length && facilitiesLoading);

  if (error) {
    throw error;
  }

  const associatedFacilities =
    facilitiesData?.facilities?.items ??
    previousFacilitiesData?.facilities?.items ??
    [];
  const associatedFacilityCount =
    facilitiesData?.facilities?.count ??
    previousFacilitiesData?.facilities?.count ??
    0;

  const canDelete = !loading && !personData?.person?.user;

  const skeleton = (
    <Stack>
      <Skeleton variant="text" sx={{ width: "30rem" }} />
      <Skeleton variant="text" sx={{ width: "25rem" }} />
      <Skeleton variant="text" sx={{ width: "30rem" }} />
    </Stack>
  );

  const personName = getFullName(personData?.person);

  const facilitiesPlural = pluralize("facilities", associatedFacilityCount);

  const canDeleteMessage = (
    <Typography>
      '{personName}' is currently associated with {associatedFacilityCount}{" "}
      {facilitiesPlural}. This contact will be removed from all current and
      future reports. Previously submitted reports <i>will not be affected.</i>
    </Typography>
  );

  const cannotDeleteMessage = (
    <Typography>
      '{personName}' is associated with a user account, and cannot be deleted.
    </Typography>
  );

  const message = loading
    ? skeleton
    : canDelete
    ? canDeleteMessage
    : cannotDeleteMessage;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      {(loading || !canDelete || associatedFacilityCount > 0) && (
        <DialogContent>{message}</DialogContent>
      )}
      {canDelete && [
        associatedFacilityCount > 0 && (
          <DialogContent key="facilities">
            <Stack sx={{ height: "15rem" }} spacing={theme.spacing(1)}>
              <Typography sx={{ color: theme.palette.grey[500] }}>
                The following {associatedFacilityCount} {facilitiesPlural} will
                be affected:
              </Typography>
              <DataGrid
                columns={columns}
                rows={associatedFacilities}
                pagination
                sortModel={sortModel}
                sortingMode="server"
                paginationMode="server"
                initialState={{
                  pagination: {
                    paginationModel,
                  },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) =>
                  setPaginationModel(newModel)
                }
                rowCount={associatedFacilityCount}
              />
            </Stack>
          </DialogContent>
        ),
        <DialogContent key="are you sure">
          <Typography>Are you sure you want to remove this contact?</Typography>
        </DialogContent>,
      ]}
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {canDelete && (
          <LoadingButton
            loading={deleting}
            variant="contained"
            onClick={handleDelete}
          >
            Yes, I'm sure
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
