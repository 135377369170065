import { gql } from "generated-graphql";
import {
  FireDepartmentPickerFragment,
  FireDepartmentsQuery,
} from "generated-graphql/graphql";
import React, { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import EncampAutocomplete from "./Forms/EncampAutocomplete";

type Props = {
  facilityState?: string;
  facilityCounty?: string;
  onChange?: (value: FireDepartmentPickerFragment | null) => void;
  value?: FireDepartmentPickerFragment | null;
  disabled?: boolean;
} & ControllerFieldState;

gql(`
  fragment FireDepartmentPicker on FireDepartment {
    id
    name
    city
    state
    mailingAddress
    zip
    county
    phone
    email
  }
`);

const FIRE_DEPARTMENTS_QUERY = gql(`
  query FireDepartments($state: String, $county: String) {
    fireDepartments(state: $state, county: $county) {
      items {
        ...FireDepartmentPicker
      }
      count
    }
  }
`);

export const FireDepartmentPicker: React.FC<Props> = forwardRef(
  function FireDepartmentPicker(
    { onChange, value, facilityState, facilityCounty, error, disabled },
    ref
  ) {
    return (
      <>
        <EncampAutocomplete
          query={FIRE_DEPARTMENTS_QUERY}
          variables={{
            state: facilityState,
            county: facilityCounty,
          }}
          getItems={(data: FireDepartmentsQuery) =>
            data.fireDepartments?.items ?? []
          }
          onChange={onChange}
          getOptionLabel={(fireDepartment) => {
            let label = `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.state}`;
            if (fireDepartment.county) {
              label = `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.county}, ${fireDepartment.state}`;
            }
            return label;
          }}
          value={value}
          error={error}
          disabled={disabled}
        />
      </>
    );
  }
);
