import { StorageType } from "encamp-shared/src/constants/tierii";
import { FacilityChemicalMeasurementInput } from "generated-graphql/graphql";

export const transform = (input: FacilityChemicalMeasurementInput) => {
  return {
    ...input,
    amount: input.amount != null ? Number(input.amount) : null,
    storageLocationId: input.storageLocation?.id ?? "",
    storageTypeDescription:
      input.storageType === StorageType.Other
        ? input.storageTypeDescription
        : null,
  };
};
