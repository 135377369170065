import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export function Main({
  open,
  drawerWidth,
  children,
}: {
  open: boolean;
  drawerWidth: string;
  children?: React.ReactNode;
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        width: open ? `calc(100% - ${drawerWidth})` : "100%",
        marginLeft: open ? `${drawerWidth}` : 0,
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      {children}
    </Box>
  );
}
