type confirmMessageProps = {
  roleEditPermissionChange: string;
  roleName: string;
  userName: string;
  isPartner: boolean;
  action: "edit" | "add";
};

export function getConfirmMessage({
  roleEditPermissionChange,
  roleName,
  userName,
  isPartner,
  action,
}: confirmMessageProps) {
  let confirmMessage = `You have selected the "${roleName}" role for ${userName}, `;

  // Determine the access change message based on the role change.
  const accessChangeMessage =
    roleEditPermissionChange === "increase"
      ? "which will grant access to every facility in your organization"
      : roleEditPermissionChange === "decrease"
      ? "which will restrict access to only the facilities that you associate them to"
      : "which will grant access to your organization";

  confirmMessage += accessChangeMessage;

  if (isPartner) {
    confirmMessage += " and all partner organizations";
  }

  const actionMessage = `Are you sure you'd like to ${
    action === "edit" ? "update" : "invite"
  } this user?`;

  confirmMessage += `. ${actionMessage}`;

  return confirmMessage;
}
