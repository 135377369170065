import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CheckboxField } from "components/Forms/CheckboxField";
import { FormAutocomplete } from "components/Forms/FormAutocomplete";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { gql } from "generated-graphql";
import { AccountType, TenantInput } from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDebounce } from "hooks/useDebounce";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { hasCriticalIssues } from "util/forms";
import { TENANTS_QUERY } from ".";
import { useTenantInputValidation } from "../validationHooks/useTenantInputValidation";

const UPSERT_TENANT_MUTATION = gql(`
  mutation UpsertTenant($input: TenantInput!) {
    upsertTenant(input: $input) {
      ...tenant
    }
  }
`);

function CreateTenant() {
  const user = useCurrentUser();

  // State for keeping track of whether the create tenant dialog is open
  const [createTenantDialogOpen, setCreateTenantDialogOpen] = useState(false);

  // Only staff users can create tenants
  if (!user.isStaff) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setCreateTenantDialogOpen(true)}
      >
        Create Organization
      </Button>
      <CreateTenantDialog
        open={createTenantDialogOpen}
        onClose={() => setCreateTenantDialogOpen(false)}
      />
    </>
  );
}

type CreateTenantDialogProps = {
  open: boolean;
  onClose: () => void;
};

function CreateTenantDialog({ open, onClose }: CreateTenantDialogProps) {
  const theme = useTheme();
  const alerts = useAlerts();

  // Form state/logic
  const defaultValues: TenantInput = useMemo(
    () => ({
      name: "",
      accountType: AccountType.Customer,
      isDisabled: false,
      isDemo: false,
      isPartner: false,
    }),
    []
  );

  const form = useValidatingForm<TenantInput>(
    defaultValues,
    [],
    useTenantInputValidation()
  );

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    issues,
    reset,
    setValue,
    watch,
  } = form;

  const isPartner = watch("isPartner");
  // If creating a partner organization, clear the partnerTenantId
  useEffect(() => {
    if (isPartner) {
      setValue("partnerTenantId", null);
    }
  }, [isPartner, setValue]);

  // Tenants query and related state
  const {
    omnisearch,
    setOmnisearch,
    paginationModel: { page, pageSize },
    sortModel,
  } = useOmnisearchDatagrid({
    isURLDriven: false,
    initialPageSize: 20,
    initialSortModel: [{ field: "name", sort: "asc" }],
  });
  const [search] = useDebounce(`isPartner:true ${omnisearch}`, 150);
  const {
    data: tenantsData,
    loading: loadingTenants,
    previousData: previousTenants,
  } = useQuery(TENANTS_QUERY, {
    variables: {
      search,
      page,
      pageSize,
      sort: sortModel,
    },
  });

  const tenantsOptions = useMemo(() => {
    return loadingTenants
      ? previousTenants?.tenants.items.map((t) => ({
          value: t.id,
          label: t.name,
        })) ?? []
      : tenantsData?.tenants.items.map((t) => ({
          value: t.id,
          label: t.name,
        })) ?? [];
  }, [loadingTenants, previousTenants, tenantsData]);

  // Mutation
  const [upsertTenant, { loading: saving }] = useMutation(
    UPSERT_TENANT_MUTATION,
    {
      refetchQueries: ["Tenants"],
    }
  );

  // Dialog handlers
  async function handleSave(input: TenantInput) {
    await upsertTenant({
      variables: { input },
      onCompleted: () => {
        alerts.success("Organization created successfully");
        handleClose();
      },
      onError: () => {
        alerts.error("Failed to create the organization");
      },
    });
  }

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle>Create Organization</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <FormTextField
              label="Organization Name"
              name={"name"}
              sx={{ marginTop: theme.spacing(1) }}
              control={control}
            />
            <CheckboxField
              label="Make this a Partner Organization"
              name={"isPartner"}
              control={control}
            />
            <FormAutocomplete
              label="Associate this Organization with a Partner Organization"
              name={"partnerTenantId"}
              autocompleteProps={{
                onInputChange: (_, newInputValue) => {
                  setOmnisearch(newInputValue);
                },
                // This is required by the type but will be overwritten in the component
                renderInput: () => null,
                // This is required by the type but will be overwritten in the component
                options: tenantsOptions,
                loading: loadingTenants,
                loadingText: "Loading organizations...",
                disabled: isPartner,
              }}
              autocompleteItems={tenantsOptions}
              disabled={isPartner}
              control={control}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <SaveButton
            saveText="Create"
            loading={saving}
            disabled={!isDirty || hasCriticalIssues(issues)}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export { CreateTenant };
