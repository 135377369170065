import { useLocation, useNavigate, useParams } from "react-router-dom";

type UseNavigateToReportOverviewParams = {
  tenantId: string;
  reportId: string;
};

export const useNavigateReportSaveCancel = () => {
  const { tenantId, reportId } = useParams<UseNavigateToReportOverviewParams>();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateReportSaveCancel = () => {
    if (location.state?.fromReview) {
      navigate(`/o/${tenantId}/chemicals/reports/${reportId}/review`);
    } else {
      navigate(`/o/${tenantId}/chemicals/reports/${reportId}`);
    }
  };

  return navigateReportSaveCancel;
};
