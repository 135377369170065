import { Stack, useTheme } from "@mui/material";
import { reportStepMetadata } from "util/constants";
import { StepperPageHeader } from "./StepperPageHeader";
import { OverviewCards } from "./OverviewCards";
import { useMatch } from "react-router-dom";
import { Permission, TierIiReportStep } from "generated-graphql/graphql";
import { useReport } from "./useReport";
import { ReviewForm } from "./Review/ReviewForm";
import { useAuthorization } from "hooks/useAuthorization";
import { ReportHasNoChemicalsAlert } from "components/Alerts/ReportHasNoChemicalsAlert";

export const Overview: React.FC = () => {
  const theme = useTheme();
  const match = useMatch({
    path: "/o/:tenantId/chemicals/reports/:reportId/:step",
    end: false,
  });
  const { hasPermissionForFacility } = useAuthorization();
  const { data } = useReport();

  const canVerifyAndSubmit = hasPermissionForFacility(
    data?.tierIIReport?.facility.id ?? "",
    [Permission.VerifyTierIiReport]
  );

  const isViewingReview = match?.params.step === "review";

  const title = isViewingReview ? "Review" : "Report Overview";
  const overviewDescription = isViewingReview
    ? reportStepMetadata.REVIEW.overviewDescription
    : reportStepMetadata.REPORT_OVERVIEW.overviewDescription;

  const hiddenSteps = isViewingReview
    ? [TierIiReportStep.Review, TierIiReportStep.VerifyAndSubmit]
    : [];

  if (!canVerifyAndSubmit) {
    hiddenSteps.push(TierIiReportStep.VerifyAndSubmit);
  }

  return (
    <Stack
      gap={theme.spacing(0.5)}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <StepperPageHeader header={title} description={overviewDescription} />

      <ReportHasNoChemicalsAlert />

      <OverviewCards
        showIssueDescriptions={!isViewingReview}
        hideSteps={hiddenSteps}
      />

      {isViewingReview && <ReviewForm />}
    </Stack>
  );
};
