import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import CopyableTypography from "components/Typography/CopyableTypography";
import { useReportDetails } from "../useReportDetails";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { useMemo } from "react";
import { ActivityType } from "generated-graphql/graphql";
import { useParams } from "react-router-dom";

const GeppettoCommand = () => {
  const theme = useTheme();
  const { data } = useReportDetails();
  const { activityId } = useParams();

  const viewingActivity = data?.tierIIReport.currentWorkflow?.activities.find(
    (a) => a.id === activityId
  );

  const isViewingGeppettoActivity =
    viewingActivity?.type === ActivityType.Geppetto ||
    viewingActivity?.type === ActivityType.CollectEvidence;

  const action =
    viewingActivity?.type === ActivityType.Geppetto
      ? "inputData"
      : viewingActivity?.type === ActivityType.CollectEvidence
      ? "collectEvidenceOfCompletion"
      : "";

  const facilityId = data?.tierIIReport.facility.id;

  const jobId = viewingActivity?.job?.id;

  const credentialId =
    data?.tierIIReport.facility?.portalCredential?.credential?.id;

  const geppettoCommand = useMemo(
    () =>
      `encamp geppetto --action ${action} --reporting-year ${currentTierIIReportingYear} --facility-id ${facilityId} --job-id ${
        jobId ?? ""
      } --credential-id ${credentialId ?? ""}`,
    [action, facilityId, jobId, credentialId]
  );

  if (!isViewingGeppettoActivity) return <></>;

  return (
    <Stack
      direction={"column"}
      component={Paper}
      sx={{ border: `1px solid ${theme.palette.divider}`, padding: 1 }}
    >
      <Typography variant="h6">Geppetto Command</Typography>
      <Box padding={theme.spacing(2)}>
        <CopyableTypography text={geppettoCommand} />
      </Box>
    </Stack>
  );
};

export { GeppettoCommand };
