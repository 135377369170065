import {
  Breadcrumbs,
  Link as MUILink,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";

export default function NavBreadcrumbs() {
  const { breadcrumbs } = useBreadcrumbs();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [fontSize, setFontSize] = useState("1.25rem");
  const breadcrumbsRef = useRef(null);

  useEffect(() => {
    const adjustFontSize = () => {
      if (breadcrumbsRef.current) {
        const maxHeight = 60;

        if ((breadcrumbsRef.current as any).offsetHeight > maxHeight) {
          setFontSize("1.25rem");
        } else {
          setFontSize(isLargeScreen ? "1.5rem" : "1.25rem");
        }
      }
    };
    adjustFontSize();
  }, [breadcrumbs, isLargeScreen]);

  const displayBreadcrumbs = useMemo(() => {
    return isLargeScreen ? breadcrumbs : breadcrumbs.slice(-1);
  }, [isLargeScreen, breadcrumbs]);

  return (
    <Breadcrumbs
      ref={breadcrumbsRef}
      aria-label="breadcrumb"
      separator={"/"}
      sx={{
        maxHeight: "100%",
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      {displayBreadcrumbs.map((breadcrumb, idx) =>
        !breadcrumb.label ? (
          <Skeleton key={`skeleton-${idx}`} width={200} />
        ) : breadcrumb.to && idx < breadcrumbs.length - 1 ? (
          <MUILink
            key={breadcrumb.label}
            underline="hover"
            color="primary"
            sx={{
              fontSize,
              textDecoration: "none",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "block",
            }}
            to={`${breadcrumb.to}`}
            component={Link}
          >
            {breadcrumb.label}
          </MUILink>
        ) : (
          <Typography
            sx={{
              fontSize,
              textDecoration: "none",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "block",
            }}
            key={breadcrumb.label}
          >
            {breadcrumb.label}
            {breadcrumb.addendum && (
              <span style={{ color: theme.palette.grey[500] }}>
                {" "}
                {`- ${breadcrumb.addendum}`}
              </span>
            )}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
}
