import { useLazyQuery, useQuery } from "@apollo/client";
import Cancel from "@mui/icons-material/Cancel";
import Download from "@mui/icons-material/Download";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { saveAs } from "file-saver";
import { ElementType, useCallback, useState } from "react";
import { useAlerts } from "./Alerts/AlertProvider";
import { Dialog } from "./Dialog";
import { gql } from "generated-graphql";
import { Document } from "generated-graphql/graphql";

const GET_DOCUMENT_WITH_DOWNLOAD_LINK = gql(`
  query GetDocument($id: ID!) {
    document(id: $id) {
      id
      title
      fileExtension
      storageLink
      downloadLink
    }
  }
`);
type DocumentViewerOptions = {
  // Note: Viewing inline works well for PDFs, images, and other file types that display
  // nicely when viewed as the src of an iframe. More work would need to be done to create
  // a good experience for file types that will end up being downloaded in that scenario.
  viewInline: boolean;
};

type DocumentViewerProps = {
  documentId: string;
  title: string;
  fileExtension?: string | null | undefined;
  options?: DocumentViewerOptions;
  IconComponent?: ElementType;
};

const downloadFile = async (url: string, filename: string) => {
  const response = await fetch(url);
  const download = await response.blob();

  saveAs(download, filename);
};

const getExtensionFromString = (candidate: string): string | null => {
  const parts = candidate.split(".");
  return parts.length > 1 ? parts[parts.length - 1] : null;
};

const getFileNameForDownload = ({
  title,
  fileExtension,
  storageLink,
}: Pick<Document, "title" | "fileExtension" | "storageLink">) => {
  const extensionFromLink = getExtensionFromString(storageLink);
  const extensionFromTitle = getExtensionFromString(title);

  if (extensionFromTitle) {
    return title;
  } else if (fileExtension) {
    return `${title}.${fileExtension}`;
  } else if (extensionFromLink) {
    return `${title}.${extensionFromLink}`;
  } else {
    return title;
  }
};

export const useDocumentViewer = (viewInline?: boolean) => {
  const [downloadLink, setDownloadLink] = useState<string | undefined>();
  const [getDocument] = useLazyQuery(GET_DOCUMENT_WITH_DOWNLOAD_LINK, {
    fetchPolicy: "no-cache", // This ensures the download link is always fetched
  });
  const alerts = useAlerts();

  const fetchAndHandleDownload = useCallback(
    async ({ documentId }: { documentId: string }) => {
      const { data, error } = await getDocument({
        variables: { id: documentId },
      });

      if (!data?.document || !data.document.downloadLink || error) {
        alerts.error("An error occurred while viewing the record");
        return;
      }
      setDownloadLink(data.document.downloadLink);
      const filename = getFileNameForDownload(data.document);

      if (!viewInline && data.document.downloadLink)
        downloadFile(data.document.downloadLink, filename);
    },
    [getDocument, alerts, viewInline]
  );

  const clearDownloadLink = () => {
    setDownloadLink(undefined);
  };

  return {
    downloadLink,
    fetchAndHandleDownload,
    clearDownloadLink,
  };
};

export function DocumentViewer({
  documentId,
  title,
  options,
  IconComponent = Download,
}: DocumentViewerProps) {
  const theme = useTheme();
  const viewInline = options?.viewInline ?? false;
  const { downloadLink, fetchAndHandleDownload, clearDownloadLink } =
    useDocumentViewer(options?.viewInline);

  return (
    <>
      <IconComponent
        color="primary"
        sx={{ cursor: "pointer" }}
        onClick={async () => await fetchAndHandleDownload({ documentId })}
      />
      {viewInline && downloadLink && (
        <Dialog
          open={typeof downloadLink === "string"}
          fullScreen
          onClose={clearDownloadLink}
          sx={{ p: theme.spacing(2) }}
        >
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" color="action">
                {title}
              </Typography>
              <Tooltip title="Close">
                <IconButton onClick={clearDownloadLink}>
                  <Cancel color="action" />
                </IconButton>
              </Tooltip>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ overflow: "unset" }}>
            <iframe
              src={downloadLink}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
