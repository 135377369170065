import CheckCircle from "@mui/icons-material/CheckCircle";
import Upload from "@mui/icons-material/Upload";
import { Button, CircularProgress } from "@mui/material";
import { Document } from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useToken } from "providers/token";
import React, { useRef, useState } from "react";
import invariant from "tiny-invariant";
import { useAlerts } from "../../../../components/Alerts/AlertProvider";
import { uploadFile } from "../../../../util/uploadFile";

type FileUploadProps = {
  tenantId: string;
  label: string;
  documentType?: string;
  startIcon?: React.ReactNode;
  facilityId?: string;
  reportId?: string;
  activityId?: string;
  description?: string;
  state?: string | null;
  onSuccess?: (document: Document) => unknown;
  onError?: (error: unknown) => unknown;
  sx?: React.CSSProperties;
  acceptedFileTypes?: string;
};

const DocumentUpload = ({
  tenantId,
  facilityId,
  reportId,
  activityId,
  state,
  description,
  label,
  startIcon,
  documentType = "Other",
  onSuccess,
  onError,
  sx = {},
  acceptedFileTypes = "",
}: FileUploadProps): JSX.Element => {
  const alerts = useAlerts();
  const [loading, setLoading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const { user, loading: userLoading } = useCurrentUser();

  const { getIdTokenRefreshIfExpired } = useToken();

  const fileInput = useRef<HTMLInputElement>(null);

  if (userLoading) {
    return <></>;
  }

  invariant(user);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const idToken = await getIdTokenRefreshIfExpired();
      try {
        setLoading(true);
        const document = await uploadFile(
          file,
          {
            tenantId,
            userId: user.id,
            facilities: [{ id: facilityId || "" }],
            reportId,
            activityId,
            state,
            description,
            documentType,
          },
          idToken
        );
        alerts.success("Successfully uploaded the document!");
        setUploadComplete(true);
        if (onSuccess) {
          onSuccess(document);
        }
        setTimeout(() => setUploadComplete(false), 1500);
      } catch (err) {
        alerts.error(`${err}`);
        if (onError) {
          onError(err);
        }
      } finally {
        setLoading(false);

        // Clear the file input value - this is so we can upload the same file
        // again if we need to, since it won't let you normally
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      }
    }
  };

  return (
    <Button
      variant="contained"
      size="small"
      sx={sx}
      startIcon={
        loading ? (
          <CircularProgress size={16} />
        ) : uploadComplete ? (
          <CheckCircle />
        ) : (
          startIcon || <Upload />
        )
      }
      component="label"
      disabled={loading || uploadComplete}
    >
      {uploadComplete ? "Upload Complete" : label}
      <input
        ref={fileInput}
        accept={acceptedFileTypes}
        type="file"
        onChange={handleFileChange}
        hidden
      />
    </Button>
  );
};

export default DocumentUpload;
