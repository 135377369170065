import { useMutation } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";
import {
  T2sImportDialogFacilitiesQuery,
  T2sReadT2sFileQuery,
} from "generated-graphql/graphql";

export type Facility =
  T2sImportDialogFacilitiesQuery["facilities"]["items"][number];

export type T2SFacility =
  T2sReadT2sFileQuery["readT2sFile"]["facilities"][number];

export const useImportT2sFile = () => {
  const alerts = useAlerts();
  const mutation = useMutation(IMPORT_T2S_FILE_MUTATION, {
    onCompleted() {
      alerts.success(`T2S imported successfully`);
    },
    onError(error) {
      console.error(error);
      alerts.error(`An error occurred while importing the T2S file`);
    },
  });

  return mutation;
};

export const IMPORT_T2S_FILE_MUTATION = gql(`
    mutation ImportT2sFile($input: T2sImportInput!) {
      importT2sFile(input: $input) {
        id
      }
    }
  `);

export const T2S_FACILITIES_QUERY = gql(`
  query T2sImportDialogFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        streetAddress1
        streetAddress2
        city
        state
        zip
      }
      count
    }
  }
`);
export const T2S_READ_DOCUMENT_QUERY = gql(`
  query T2sReadT2sFile($documentId: ID!) {
    readT2sFile(documentId: $documentId) {
      reportingYear
      facilities {
        name
        city
        state
        chemicals
        contacts
      }
    }
  }
`);
