import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import { useAlerts } from "../components/Alerts/AlertProvider";
import { GET_TIERII_REPORT } from "../routes/Staff/Fulfillment/ReportDetails/useReportDetails";
import { useCurrentUser } from "./useCurrentUser";

export const UPSERT_COMMENT = gql(`
  mutation upsertComment($input: CommentInput!) {
    upsertComment(input: $input) {
      id
      userId
      reportId
      workflowId
      activityId
      comment
      updatedAt
      user {
        id
        email
      }
    }
  }
`);

export const useCreateComment = () => {
  const alerts = useAlerts();
  const { user } = useCurrentUser();
  const mutation = useMutation(UPSERT_COMMENT, {
    update(cache, { data }) {
      if (!data?.upsertComment) {
        return;
      }
      const upsertComment = data.upsertComment;
      const reportId = upsertComment.reportId;

      const report = cache.readQuery({
        query: GET_TIERII_REPORT,
        variables: { reportId: reportId ?? "" },
      });

      if (!report) {
        return;
      }

      cache.writeQuery({
        query: GET_TIERII_REPORT,
        variables: { reportId: reportId ?? "" },
        data: {
          __typename: "Query",
          tierIIReport: {
            ...report.tierIIReport,
            // check to see if this is a new comment or can be updated
            comments: [upsertComment, ...(report.tierIIReport.comments ?? [])],
          },
        },
      });
    },
    onCompleted() {
      alerts.success(`Comment added successfully`);
    },
    onError(error) {
      console.error(error);
      alerts.error(`An error occurred while updating the comment`);
    },
    optimisticResponse: ({ input }) => {
      return {
        upsertComment: {
          __typename: "Comment" as const,
          id: input.id ?? "new",
          comment: input.comment,
          reportId: input.reportId ?? null,
          workflowId: input.workflowId ?? null,
          activityId: input.activityId ?? null,
          updatedAt: new Date(),
          userId: user?.id,
          user,
        },
      };
    },
  });

  return mutation;
};

export const useUpdateComment = () => {
  const alerts = useAlerts();
  const mutation = useMutation(UPSERT_COMMENT, {
    // apollo should(tm) update the cache automatically
    onCompleted() {
      alerts.success(`Comment added successfully`);
    },
    onError(error) {
      console.error(error);
      alerts.error(`An error occurred while updating the comment`);
    },
  });

  return mutation;
};
