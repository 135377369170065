import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { gql } from "generated-graphql";
import {
  ImplementationsCreateWebAutomationJobsMutation,
  Exact,
  CreateWebAutomationJobInput,
} from "generated-graphql/graphql";

const CREATE_JOB_MUTATION = gql(`
  mutation ImplementationsCreateWebAutomationJobs($input: [CreateWebAutomationJobInput!]!) {
    createWebAutomationJobs(input: $input) {
      count
    }
  }
`);

export const useCreateWebAutomationJobs = (
  options?:
    | MutationHookOptions<
        ImplementationsCreateWebAutomationJobsMutation,
        Exact<{
          input: CreateWebAutomationJobInput | CreateWebAutomationJobInput[];
        }>,
        DefaultContext,
        ApolloCache<any>
      >
    | undefined
) => {
  return useMutation(CREATE_JOB_MUTATION, options);
};
