import { useQuery } from "@apollo/client";
import { gql } from "generated-graphql";

const FACILITY_OVERVIEW = gql(`
  query InventoryOverview($facilityId: ID!) {
    facility(id: $facilityId) {
      id
      name
      ...collectionMode
    }
  }
`);

const useFacilityOverview = (facilityId: string | undefined) => {
  return useQuery(FACILITY_OVERVIEW, {
    variables: {
      facilityId: facilityId ?? "",
    },
    skip: !facilityId,
  });
};

export default useFacilityOverview;
