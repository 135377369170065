import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { SaveButton } from "components/SaveButton";
import { gql } from "generated-graphql";
import { FacilitiesQuery, UserDetailQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";

const formatFacilityChangeMessage = (
  numAdding: number,
  numRemoving: number
) => {
  const adding = pluralize("facility", numAdding, true);
  const removing = pluralize("facility", numRemoving, true);
  return `${numAdding > 0 ? `Granting access to ${adding}.` : ""}${
    numAdding > 0 && numRemoving > 0 ? " " : ""
  }${numRemoving > 0 ? `Removing access to ${removing}.` : ""}`;
};

const FACILITIES_QUERY = gql(`
  query TenantFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        streetAddress1
        streetAddress2
        city
        state
        zip
        customerFacilityId
      }
      count
    }
  }
`);

const FACILITY_ASSOCIATION_MUTATION = gql(`
  mutation UpsertUserFacilitiesForTenant($userId: ID!, $facilityIds: [ID!]!, $tenantId: ID!) {
    upsertUserFacilitiesForTenant(userId: $userId, facilityIds: $facilityIds, tenantId: $tenantId) {
      count
      userFacilities {
        facilityId
      }
    }
  }
`);

type FacilityRow = FacilitiesQuery["facilities"]["items"][number];

type AssociateFacilitiesProps = {
  open: boolean;
  onClose: () => void;
  userId: string;
  userEmail: string;
  userFacilities: NonNullable<UserDetailQuery["user"]>["facilities"];
  tenantId: string;
  onSave: () => void;
  cancelBtnText?: string;
};

const AssociateFacilitiesDialog = ({
  open,
  onClose,
  userId,
  userEmail,
  userFacilities,
  tenantId,
  onSave,
  cancelBtnText,
}: AssociateFacilitiesProps) => {
  const theme = useTheme();
  const userFacilityIds = useMemo(
    () => userFacilities.map((f) => f.id) ?? [],
    [userFacilities]
  );

  const [selectedFacilityIds, setSelectedFacilityIds] = useState<string[]>([]);
  useEffect(() => {
    if (open) {
      setSelectedFacilityIds(userFacilityIds);
    }
  }, [userFacilityIds, open]); // Dependency on 'open' and 'userFacilityIds'

  const facilityIdsBeingAdded = selectedFacilityIds.filter(
    (id) => !userFacilityIds.some((f) => f === id)
  );
  const facilityIdsBeingRemoved = userFacilityIds.filter(
    (id) => !selectedFacilityIds.includes(id)
  );

  const anyAssociationsModified = !!(
    facilityIdsBeingAdded.length || facilityIdsBeingRemoved.length
  );

  const [
    associateFacilitiesMutation,
    { loading: mutatingAssociatedFacilities },
  ] = useMutation(FACILITY_ASSOCIATION_MUTATION);

  const handleClose = useCallback(() => {
    setSelectedFacilityIds([]);
    onClose();
  }, [onClose]);

  const handleSave = useCallback(async () => {
    // if we have not added or removed any facilities, do not do anything
    if (!anyAssociationsModified) {
      handleClose();
      return;
    }

    if (mutatingAssociatedFacilities) return;

    await associateFacilitiesMutation({
      variables: {
        userId,
        facilityIds: selectedFacilityIds,
        tenantId,
      },
      refetchQueries: ["Facilities", "UserDetail"],
    });
    handleClose();
    onSave();
  }, [
    mutatingAssociatedFacilities,
    userId,
    selectedFacilityIds,
    tenantId,
    associateFacilitiesMutation,
    handleClose,
    onSave,
    anyAssociationsModified,
  ]);

  const columns: OmnisearchGridColDef<FacilityRow>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      filterKeyType: "facility",
      valueGetter: ({ row: { customerFacilityId, name } }) => {
        return `${name}${customerFacilityId ? ` (${customerFacilityId})` : ""}`;
      },
    },
    {
      field: "streetAddress",
      flex: 1,
      headerName: "Address",
      width: 200,
      valueGetter: ({ row }) => {
        if (!row.streetAddress1) return "";
        const { streetAddress1, city, state, zip } = row;
        return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
      },
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="associate-facilities-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="associate-facilities-dialog-title">
        Facility Access
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={theme.spacing(1)}>
          <Typography variant="body1">
            Grant or revoke access to facilities for {userEmail}.
          </Typography>
          <OmnisearchDataGrid
            withPadding={false}
            gridSx={{
              minHeight: "300px",
              "& .MuiDataGrid-virtualScroller": {
                minHeight: "200px",
                maxHeight: "380px",
                overflowY: "auto",
              },
            }}
            onSelectedIdsChanged={(rows) =>
              setSelectedFacilityIds(rows as string[])
            }
            selectedIds={selectedFacilityIds}
            dataQuery={FACILITIES_QUERY}
            columns={columns}
            defaultSearch={`tenantId:${tenantId} facilities:all`}
            getItems={(data) => data.facilities.items}
            getCount={(data) => data.facilities.count}
            noDataMessage="No facilities have been added to this tenant."
            initialPageSize={10}
            isURLDriven={false}
            otherLoading={mutatingAssociatedFacilities}
            initialSortModel={[{ field: "name", sort: "asc" }]}
            additionalFilterColumns={[
              { header: "State", key: "state" },
              { header: "Facility ID", key: "customerFacilityId" },
            ]}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" paddingRight={theme.spacing(1)}>
            {formatFacilityChangeMessage(
              facilityIdsBeingAdded.length,
              facilityIdsBeingRemoved.length
            )}
          </Typography>
          <Box sx={{ marginRight: theme.spacing(1.5) }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ marginRight: theme.spacing(1.5) }}
            >
              {cancelBtnText ?? "Cancel"}
            </Button>
            <SaveButton
              loading={mutatingAssociatedFacilities}
              onClick={handleSave}
              disabled={!anyAssociationsModified}
            />
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AssociateFacilitiesDialog;
