import { gql } from "generated-graphql";
import {
  TagPickerFragment,
  TagType,
  TagsQuery,
} from "generated-graphql/graphql";
import React, { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import EncampAutocomplete from "./Forms/EncampAutocomplete";
import { useTenant } from "hooks/useTenant";
import { uniqBy } from "lodash";
import { v4 as uuidv4 } from "uuid";

type Props = {
  onChange: (tags: (TagPickerFragment | string)[]) => void;
  disabled?: boolean;
  value?: TagPickerFragment[];
  label?: string;
  tagType: TagType;
} & ControllerFieldState;

gql(`
  fragment TagPicker on Tag {
    id
    name
  }
`);

const TAGS_QUERY = gql(`
  query Tags($search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]) {
    tags(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...TagPicker
      }
      count
    }
  }
`);

export const TagPicker: React.FC<Props> = forwardRef(function TagPicker(
  { onChange, disabled, value, label, tagType },
  ref
) {
  const { tenantId } = useTenant();
  return (
    <>
      <EncampAutocomplete
        query={TAGS_QUERY}
        variables={{
          search: `tenantId:${tenantId} type:${tagType}`,
        }}
        getItems={(data: TagsQuery) => data.tags?.items ?? []}
        value={value}
        getOptionLabel={(tag) => (typeof tag === "string" ? tag : tag.name)}
        onChange={(value) => {
          // new tags will only be a string, existing tags will be of type `TagPickerFragment`
          const tags = value.map((tag) =>
            typeof tag === "string"
              ? ({
                  __typename: "Tag",
                  id: uuidv4(),
                  name: tag,
                } satisfies TagPickerFragment)
              : tag
          );

          // remove duplicates with case insensitivity
          const uniqueTags = uniqBy(tags, (tag) => tag.name.toLowerCase());

          onChange(uniqueTags);
        }}
        label={label}
        multiple
        disabled={disabled}
        freeSolo
        limitTags={3}
        getOptionDisabled={(tag) =>
          !!value?.map((tag) => tag.id).includes(tag.id)
        }
      />
    </>
  );
});
