import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";

const deleteTask = gql(`
  mutation DeleteTask($input: EventMutationInput!) {
    deleteTask(input: $input)
  }
`);

const useDeleteTaskMutation = () => {
  return useMutation(deleteTask);
};

export default useDeleteTaskMutation;
