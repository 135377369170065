import { gql } from "../generated-graphql";

const GET_ROLES_QUERY = gql(`
  query Roles($tenantId: ID!) {
    roles(tenantId: $tenantId) {
        id
        name
        description
        permissions
    }
  }
`);

export default GET_ROLES_QUERY;
