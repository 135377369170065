import CachedIcon from "@mui/icons-material/Cached";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import SwapHorizOutlined from "@mui/icons-material/SwapHorizOutlined";
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { range } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Row } from ".";
import { SetCollectionModeModal } from "../Collection/SetCollectionModeModal";
import { QueueExtractionsModal } from "../Extractions/QueueExtractionsModal";
import { CopyFacilityInventoriesModal } from "./CopyFacilityInventoriesModal";

const buttonId = "facilities-bulk-actions-button";
const menuId = "facilities-bulk-actions-menu";

type BulkActionMenuProps = {
  selectedRows: Row[];
};

type DialogState =
  | { action: "setCollectionMode" }
  | { action: "copyInventory" }
  | { action: "queueExtractions"; year: number }
  | null;

const bulkActionContext = createContext<{
  selectedRows: Row[];
  dialogState: DialogState;
  setDialogState: Dispatch<SetStateAction<DialogState>>;
  onClose: () => void;
}>({
  selectedRows: [],
  dialogState: null,
  setDialogState: () => {},
  onClose: () => {},
});

export function useBulkActionContext() {
  return useContext(bulkActionContext);
}

const FacilitiesBulkActionsMenu = ({ selectedRows }: BulkActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogState, setDialogState] = useState<DialogState>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
    setDialogState(null);
  };

  return (
    <bulkActionContext.Provider
      value={{ selectedRows, onClose, dialogState, setDialogState }}
    >
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disabled={!selectedRows.length}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
        sx={{ whiteSpace: "nowrap" }}
      >
        Bulk Actions
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{ "aria-labelledby": buttonId }}
      >
        <MenuList>
          <SetCollectionModeMenuItem />
          <CopyInventoryMenuItem />
          <Divider />
          <QueueExtractionsMenuItems />
        </MenuList>
      </Menu>
      <SetCollectionModeModal />
      <CopyFacilityInventoriesModal />
      <QueueExtractionsModal />
    </bulkActionContext.Provider>
  );
};

export { FacilitiesBulkActionsMenu };

function SetCollectionModeMenuItem() {
  const { selectedRows, setDialogState } = useBulkActionContext();
  return (
    <MenuItem
      disabled={selectedRows.length === 0}
      onClick={() => setDialogState({ action: "setCollectionMode" })}
    >
      <ListItemIcon>
        <SwapHorizOutlined />
      </ListItemIcon>
      <ListItemText>Set Collection Mode</ListItemText>
    </MenuItem>
  );
}

function CopyInventoryMenuItem() {
  const currentYear = new Date().getFullYear();
  const { selectedRows, setDialogState } = useBulkActionContext();

  return (
    <MenuItem
      disabled={selectedRows.length === 0}
      onClick={() => setDialogState({ action: "copyInventory" })}
    >
      <ListItemIcon>
        <CopyAllIcon />
      </ListItemIcon>
      <ListItemText>Copy Last Year's Inventory to {currentYear}</ListItemText>
    </MenuItem>
  );
}

export function QueueExtractionsMenuItems() {
  const currentYear = new Date().getFullYear();
  const { selectedRows, setDialogState } = useBulkActionContext();
  return (
    <>
      {range(currentYear, currentYear - 4).map((reportingYear) => (
        <MenuItem
          key={reportingYear}
          disabled={selectedRows.length === 0}
          onClick={() =>
            setDialogState({ action: "queueExtractions", year: reportingYear })
          }
        >
          <ListItemIcon>
            <CachedIcon />
          </ListItemIcon>
          <ListItemText>Queue Extractions for {reportingYear}</ListItemText>
        </MenuItem>
      ))}
    </>
  );
}
