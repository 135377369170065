import { gql } from "generated-graphql";

gql(`
  fragment TenantCatalogChemical on Chemical {
    id
    ...ChemicalPicker
    name
    casNumber
    isEhs
    pureOrMixture
    alternateId
    needsReview
    issueCount
    documents {
      id
      document {
        id
        documentType
      }
    }
  }
`);

export const TENANT_CATALOG_CHEMICALS =
  gql(`query TenantCatalogChemicals($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    chemicals(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        count
        items {
            ...TenantCatalogChemical
        }
    }
}`);

gql(`
  fragment TenantCatalogProduct on Product {
    id
    name
    alternateId
    reference
    issues {
      ...issue
    }
  }
`);

export const TENANT_CATALOG_PRODUCTS =
  gql(`query TenantCatalogProducts($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    products(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      items {
          ...TenantCatalogProduct
      }
    }
}`);

export const CREATE_NEW_PRODUCT = gql(`
    mutation CreateNewProduct($productInput: ProductInput!) {
        upsertProduct(input: $productInput) {
            id
        }
    }
`);

export const CREATE_NEW_CHEMICAL = gql(`
    mutation CreateNewChemical($chemicalInput: ChemicalInput!) {
        upsertChemical(input: $chemicalInput) {
            id
        }
    }
`);

export const DELETE_PRODUCT = gql(`
  mutation DeleteTenantCatalogProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`);

export const DELETE_CHEMICAL = gql(`
  mutation DeleteTenantCatalogChemical($id: ID!) {
    deleteChemical(id: $id) {
      id
    }
  }
`);

export const JOB_STATUS = gql(`
  query Job($id: ID!) {
    job(id: $id) {
      id
      status
    }
  }
`);
