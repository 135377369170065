import { useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DateField } from "components/Forms/DateField";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { gql } from "generated-graphql";
import {
  GetReportDetailsQuery,
  NotReportingReason,
  Permission,
  TierIiReportStep,
  TierIiReportVerifier,
  VerifiersQuery,
} from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useValidatingForm } from "hooks/useValidatingForm";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { reportStepMetadata } from "util/constants";
import { OverviewCards } from "./OverviewCards";
import { ReportFloatingBar } from "./ReportFloatingBar";
import { StepperPageHeader } from "./StepperPageHeader";
import {
  useInvalidSteps,
  useNotStartedSteps,
  useReport,
  useVerifyAndSubmitIssues,
} from "./useReport";
import { useVerifyInputValidation } from "./validationHooks/useVerifyInputValidation";
import { useAuthorization } from "hooks/useAuthorization";
import {
  ReportHasNoChemicalsAlert,
  useHasChemicalsToReport,
} from "components/Alerts/ReportHasNoChemicalsAlert";
import { ConfirmDialog } from "components/ConfirmDialog";
import {
  NotReportingItem,
  NotReportingModal,
} from "../Reports/NotReportingModal";
import { OmnisearchAutocomplete } from "components/OmnisearchAutocomplete";
import { PERSON_QUERY } from "components/VerifierTable";

const SUBMIT_REPORT = gql(`
  mutation SubmitReport($reportId: ID!, $verifier: TierIIReportVerifier!) {
    updateTierIIReportVerifier(reportId: $reportId, verifier: $verifier) {
      id
      organizationStatus
      encampStatus
    }
  }
`);

const LoadingSkeleton = () => {
  const theme = useTheme();

  return (
    <Stack gap={theme.spacing(2)}>
      <Box height={theme.spacing(12)} padding={theme.spacing(2)}>
        <Skeleton
          variant="rectangular"
          style={{
            height: "100%",
          }}
        />
      </Box>

      <Stack
        paddingBottom={theme.spacing(2)}
        gap={theme.spacing(2)}
        paddingX={theme.spacing(2)}
      >
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </Stack>

      <Stack
        paddingBottom={theme.spacing(2)}
        gap={theme.spacing(2)}
        paddingX={theme.spacing(2)}
      >
        <Skeleton variant="rectangular" />
      </Stack>

      <Grid
        container
        paddingLeft={theme.spacing(2)}
        paddingBottom={theme.spacing(4)}
      >
        <Grid item md={6} lg={6} height={theme.spacing(6)}>
          <Skeleton
            variant="rectangular"
            style={{
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        paddingLeft={theme.spacing(2)}
        paddingBottom={theme.spacing(4)}
      >
        <Grid item md={6} lg={6} height={theme.spacing(6)}>
          <Skeleton
            variant="rectangular"
            style={{
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
      <Grid container paddingLeft={theme.spacing(2)}>
        <Grid item md={3} lg={3} height={theme.spacing(6)}>
          <Skeleton
            variant="rectangular"
            style={{
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export type ReportDetails = GetReportDetailsQuery["tierIIReport"];
export type VerifierRow = VerifiersQuery["people"]["items"][number];

export const VerifyAndSubmit: React.FC = () => {
  const { data: reportData } = useReport();
  const { user, isStaff } = useCurrentUser();
  const { hasPermissionForFacility } = useAuthorization();
  const navigate = useNavigate();
  if (
    !hasPermissionForFacility(reportData?.tierIIReport?.facility.id ?? "", [
      Permission.VerifyTierIiReport,
    ])
  ) {
    navigate(`..`);
  }
  const theme = useTheme();
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const [showNotReportingDialog, setShowNotReportingDialog] = useState(false);
  const invalidSteps = useInvalidSteps();
  const incompleteSteps = useNotStartedSteps();
  const alerts = useAlerts();
  const { reportId } = useParams<{ reportId: string }>();
  invariant(reportId);

  const defaultSearch = `tenantId:${reportData?.tierIIReport.tenantId}`;
  const [staffSelectedVerifier, setStaffSelectedVerifier] =
    useState<VerifierRow | null>();
  const handleRemoveStaffVerifier = () => {
    setStaffSelectedVerifier(null);
    setValue("personId", null);
  };

  const hasInvalidSteps =
    invalidSteps.filter((s) => s !== TierIiReportStep.VerifyAndSubmit).length >
    0;

  const hasIncompleteSteps =
    incompleteSteps.filter((s) => s !== TierIiReportStep.VerifyAndSubmit)
      .length > 0;

  const todaysDate = useMemo(() => DateTime.now(), []);

  const formData = useMemo(
    () => ({
      personId: user?.person?.id,
      isVerified: null,
      verifiedAt: todaysDate,
      verifierFullName: null,
      verifierTitle: null,
    }),
    [todaysDate, user?.person?.id]
  );

  const { handleSubmit, control, watch, issues, setValue, trigger } =
    useValidatingForm<TierIiReportVerifier>(
      formData,
      useVerifyAndSubmitIssues(),
      useVerifyInputValidation()
    );

  const [submitReport, { loading }] = useMutation(SUBMIT_REPORT);

  const onSubmit = useCallback(
    async (formState: TierIiReportVerifier) => {
      try {
        await submitReport({
          variables: {
            reportId,
            verifier: formState,
          },
        });
        alerts.success("Successfully submitted report.");
      } catch (err) {
        alerts.error("Error submitting report: ", err);
      }
    },
    [submitReport, alerts, reportId]
  );

  const isVerified = watch("isVerified");
  const verifierFullName = watch("verifierFullName");
  const verifierTitle = watch("verifierTitle");
  const disabled = hasIncompleteSteps || hasInvalidSteps;

  const { hasChemicalsToReport, loading: loadingReportingChemicals } =
    useHasChemicalsToReport();

  const handlePreSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const needsConfirmation = !hasChemicalsToReport;
      if (needsConfirmation) {
        setShowConfirmUpdate(true);
      } else {
        handleSubmit(onSubmit)();
      }
    },
    [handleSubmit, hasChemicalsToReport, onSubmit]
  );

  if (!user?.id) return <LoadingSkeleton />;

  function convertReportForNotReportingModal(
    report: ReportDetails
  ): NotReportingItem {
    const item: NotReportingItem = {
      reason: NotReportingReason.UnderThreshold,
      facilityId: report.facility.id,
      reportingYear: report.reportingYear,
      tenantId: report.tenantId,
      tierIIReportId: report.id,
      facilityDateClosed: report.facility.dateClosed,
      facilityDateInactive: report.facility.dateInactive,
      colocationReportingFacilityId:
        report.facility.colocationReportingFacilityId,
      isClosed: report.facility.isClosed,
      isInactive: report.facility.isInactive,
      isNotReporting: report.facility.isNotReporting,
      isNotReportingThroughEncamp: report.facility.isNotReportingThroughEncamp,
    };
    return item;
  }

  // Other-chemicals will fallback to chemicals if that step is not applicable
  const chemicalInventoryPage = `/o/${reportData?.tierIIReport.tenantId}/chemicals/reports/${reportData?.tierIIReport.id}/other-chemicals`;
  return (
    <Stack sx={{ height: "100%" }}>
      <StepperPageHeader
        header="Verify & Submit"
        description={reportStepMetadata.VERIFY_AND_SUBMIT.overviewDescription}
      />

      <ReportHasNoChemicalsAlert />

      <form
        onSubmit={handlePreSubmit}
        style={{ display: "flex", flex: 1, flexDirection: "column" }}
      >
        <Stack gap={theme.spacing(1)} sx={{ flex: 1 }}>
          {(hasInvalidSteps || hasIncompleteSteps) && (
            <>
              <Stack direction="row" sx={{ marginBottom: theme.spacing(3) }}>
                <ErrorOutline color="error" />
                <Typography
                  paddingLeft={theme.spacing(1)}
                  color={theme.palette.error.main}
                >
                  The following sections must be completed without any
                  compliance issues before this report can be submitted.
                </Typography>
              </Stack>
              <OverviewCards
                hideSteps={[TierIiReportStep.VerifyAndSubmit]}
                hideCompleted={true}
              />
            </>
          )}

          <Stack sx={{ flex: 1 }}>
            <RadioGroupField
              sx={{ marginY: theme.spacing(2) }}
              disabled={disabled}
              control={control}
              name="isVerified"
              label="I have reviewed this report for accuracy and completion and it is ready to be submitted."
              radioOptions={[
                {
                  label: "Yes",
                  value: true,
                },
                {
                  label: "No",
                  value: false,
                },
              ]}
            />
            <Typography paddingBottom={theme.spacing(4)}>
              By adding my digital signature below, I acknowledge that my name
              will appear on the report as the &quot;submitter&quot; in the
              state reporting system. In addition, Encamp, Inc. may act as an
              authorized representative on my behalf with regards to submitting
              this report.
            </Typography>

            <Stack spacing={theme.spacing(2)}>
              <Grid
                container
                columnSpacing={theme.spacing(3)}
                rowSpacing={theme.spacing(3)}
              >
                {isStaff && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="staff-verifier-selection"
                        style={{
                          position: "static",
                          transform: "none",
                          marginBottom: "8px",
                        }}
                      >
                        (Staff Only) Selected Verifier:
                      </InputLabel>
                      <Box>
                        {staffSelectedVerifier ? (
                          <Paper
                            variant="outlined"
                            sx={{
                              padding: theme.spacing(1.5),
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Typography variant="subtitle1" component="div">
                                {`${staffSelectedVerifier.first} ${
                                  staffSelectedVerifier.last
                                }${
                                  staffSelectedVerifier.email
                                    ? ` (${staffSelectedVerifier.email})`
                                    : ""
                                }`}
                              </Typography>
                            </Box>
                            <IconButton
                              onClick={handleRemoveStaffVerifier}
                              size="small"
                              aria-label="remove verifier"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Paper>
                        ) : (
                          <OmnisearchAutocomplete
                            disabled={disabled}
                            dataQuery={PERSON_QUERY}
                            defaultSearch={defaultSearch}
                            dataQueryVariables={{
                              sort: [{ field: "last", sort: "asc" }],
                            }}
                            getItems={(data) => data.people.items}
                            onSelectionChange={(
                              selected: VerifierRow | null
                            ) => {
                              setStaffSelectedVerifier(selected);
                              if (selected !== null) {
                                setValue(
                                  "verifierFullName",
                                  `${selected.first} ${selected.last}`
                                );
                                setValue("personId", selected.id);
                                setValue("verifierTitle", selected.title);
                              }
                              trigger([
                                "personId",
                                "verifierFullName",
                                "verifierTitle",
                              ]);
                            }}
                            renderOption={(props, item: VerifierRow) => (
                              <ListItem key={item.id} {...props} disablePadding>
                                <ListItemText
                                  primary={`${item.first} ${item.last}`}
                                  secondary={`${item.email}`}
                                />
                              </ListItem>
                            )}
                            placeholder="Search for a person"
                            runInitialSearch
                          />
                        )}
                      </Box>
                    </FormControl>
                  </Grid>
                )}
                <Grid item md={6} lg={6}>
                  <FormTextField
                    disabled={disabled}
                    textFieldProps={{ required: true }}
                    name="verifierFullName"
                    label="Full Name"
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6} lg={6}>
                  <FormTextField
                    disabled={disabled}
                    textFieldProps={{ required: true }}
                    name="verifierTitle"
                    label="Title"
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} lg={3}>
                  <DateField
                    datePickerProps={{ disabled: true, value: todaysDate }}
                    label="Today's Date"
                    name="verifiedAt"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
        <ConfirmDialog
          title={"Report contains no chemicals"}
          loading={loading}
          open={showConfirmUpdate}
          confirmText=""
          msg={
            <Box>
              Are you sure you want to submit this report? There are no
              chemicals included in this report. You may not need to file a Tier
              II report.
            </Box>
          }
          actions={
            <>
              <Button
                disabled={loading}
                onClick={() => setShowConfirmUpdate(false)}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={loading}
                onClick={() => navigate(chemicalInventoryPage)}
                variant="outlined"
              >
                Return to Inventory
              </Button>
              <Button
                disabled={loading}
                onClick={() => {
                  setShowConfirmUpdate(false);
                  setShowNotReportingDialog(true);
                }}
                variant="contained"
              >
                Mark Facility as Not Reporting
              </Button>
            </>
          }
          onConfirm={handleSubmit(onSubmit)}
        />
        <ReportFloatingBar
          saveText="Submit Report"
          noIcon
          hideCancel
          issues={issues}
          loading={loading}
          saveDisabled={
            disabled ||
            !verifierFullName ||
            !verifierTitle ||
            !isVerified ||
            loadingReportingChemicals
          }
          saving={loading}
        />
      </form>
      {reportData?.tierIIReport && !hasChemicalsToReport && (
        <NotReportingModal
          reports={[convertReportForNotReportingModal(reportData.tierIIReport)]}
          open={showNotReportingDialog}
          onClose={() => {
            setShowNotReportingDialog(false);
          }}
        />
      )}
    </Stack>
  );
};
