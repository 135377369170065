import {
  PROVINCE_TERRITORY_NAMES,
  STATE_NAMES,
} from "encamp-shared/src/constants/states";
import { sortBy } from "lodash";
import { useEffect, useMemo } from "react";
import { UseControllerProps, useFormContext } from "react-hook-form";
import { FormSelect, SelectItem } from "./Forms/FormSelect";

type Props = {
  disabled?: boolean;
  countryFieldName?: string;
} & UseControllerProps;

export function StatePicker(props: Props) {
  const { watch, setValue, getFieldState } = useFormContext();
  const country = watch(props.countryFieldName || "country");

  useEffect(() => {
    // when the country changes, reset the state
    if (
      props.countryFieldName &&
      getFieldState(props.countryFieldName).isDirty
    ) {
      setValue(props.name, null);
    }
  }, [country, setValue, getFieldState, props.name, props.countryFieldName]);

  const selectItems: SelectItem[] = useMemo(
    () =>
      sortBy(
        Object.entries(
          country === "Canada" ? PROVINCE_TERRITORY_NAMES : STATE_NAMES
        ),
        1
      ).map(([abbr, name]) => ({
        display: name,
        value: abbr,
      })),
    [country]
  );

  return <FormSelect {...props} label="State" selectItems={selectItems} />;
}
