import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  Box,
  useTheme,
  DialogActions,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { Row } from ".";
import { DocumentType } from "generated-graphql/graphql";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { DataGrid } from "components/DataGrid";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import invariant from "tiny-invariant";
import { GridApiRef } from "components/OmnisearchDataGrid";

interface ChemicalFromSdsDialogProps {
  documents: Row[];
  apiRef: GridApiRef;
  onClose: () => void;
}

const CREATE_CHEMICALS_FROM_SDS = gql(`
  mutation CreateChemicalsFromSDS($input: CreateChemicalsFromSDSInput!) {
    queueSdsParsingJobs(input: $input) {
      id
      task
      status
      createdAt
      updatedAt
    }
  }
`);

export function ChemicalFromSdsDialog({
  open,
  onClose,
  documents,
  apiRef,
}: ChemicalFromSdsDialogProps & Pick<DialogProps, "open">) {
  const theme = useTheme();
  const { tenantId } = useParams();
  invariant(tenantId);
  const [tabValue, setTabValue] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [isExtracting, setIsExtracting] = useState(false);
  const [hasExtractionFailed, setHasExtractionFailed] = useState(false);

  const documentsToProcess = documents.filter(
    (document) => document.documentType === DocumentType.SafetyDataSheet
  );
  const documentsNotToProcess = documents.filter(
    (document) => document.documentType !== DocumentType.SafetyDataSheet
  );

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "title", headerName: "Document Title", flex: 1 },
      { field: "documentType", headerName: "Document Type", flex: 1 },
    ],
    []
  );

  const [queueSdsParsingJobs, { loading }] = useMutation(
    CREATE_CHEMICALS_FROM_SDS,
    {
      refetchQueries: ["Documents"],
    }
  );

  const handleCreateChemicals = async () => {
    try {
      await queueSdsParsingJobs({
        variables: {
          input: {
            tenantId: tenantId,
            documents: documentsToProcess.map((document) => ({
              id: document.id,
              tenantId: document.tenantId ?? "",
            })),
          },
        },
      });
      setIsExtracting(true);
      apiRef.current.setRowSelectionModel([]);
    } catch (error) {
      setIsExtracting(true);
      setHasExtractionFailed(true);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Create Chemicals from Documents</DialogTitle>
      {isExtracting ? (
        <>
          <DialogContent>
            {hasExtractionFailed ? "Extraction failed" : "Extraction has begun"}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={onClose}>
              CLOSE
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
              This will begin automatically extracting Chemical information from
              SDS documents. This process may take some time. Only SDS documents
              will be processed.
            </Typography>
            <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
              <Tab
                label={`Documents to Process (${documentsToProcess.length})`}
              />
              <Tab
                label={`Excluded Documents (${documentsNotToProcess.length})`}
              />
            </Tabs>
            <Box sx={{ height: 500 }}>
              {tabValue === 0 && (
                <DataGrid
                  columns={columns}
                  rows={documentsToProcess}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={[10]}
                  pagination
                />
              )}
              {tabValue === 1 && (
                <DataGrid
                  columns={columns}
                  rows={documentsNotToProcess}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={[10]}
                  pagination
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{ marginLeft: theme.spacing(1) }}
              onClick={onClose}
            >
              CANCEL
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleCreateChemicals}
              variant="contained"
            >
              BEGIN EXTRACTION
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
