import AdjustOutlined from "@mui/icons-material/AdjustOutlined";
import CheckCircleOutlineRounded from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";
import Home from "@mui/icons-material/Home";
import {
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { IssueCount } from "components/IssueCount";
import { TruncateTypography } from "components/TruncateTypography";
import {
  Facility,
  TierIiReport,
  TierIiReportOrgStatus,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { kebabCase } from "lodash";
import { ReactNode } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  getChemicalsBreadcrumb,
  getChemicalsReportsBreadcrumb,
} from "util/breadcrumb";
import {
  ReportStepState,
  reportKindToLabel,
  reportStepMetadata,
} from "util/constants";
import { NotReporting } from "./NotReporting";
import { Submitted } from "./Submitted/Submitted";
import { Report, useReport, useReportSteps } from "./useReport";

interface TierIIReportProps {
  reportId?: string;
}

type FacilityInfoProps = {
  loading: boolean;
  facility?: Pick<
    Facility,
    "name" | "streetAddress1" | "city" | "state" | "zip"
  >;
};

const FacilityInfo: React.FC<FacilityInfoProps> = ({ loading, facility }) => {
  const theme = useTheme();

  let content;
  if (loading || !facility) {
    content = (
      <>
        <Skeleton variant="text" height={theme.spacing(3)} />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </>
    );
  } else {
    content = (
      <>
        <TruncateTypography lines={3} fontWeight="medium">
          {facility?.name}
        </TruncateTypography>
        <TruncateTypography variant="caption" lines={2}>
          {facility?.streetAddress1}
        </TruncateTypography>
        <Typography variant="caption">
          {facility?.city}, {facility?.state} {facility?.zip}
        </Typography>
      </>
    );
  }
  return (
    <Stack
      sx={{
        px: 3,
        height: theme.spacing(17),
        maxHeight: theme.spacing(17),
        justifyContent: "center",
      }}
    >
      {content}
    </Stack>
  );
};

type ReportInfoProps = {
  loading: boolean;
  report?: Pick<
    TierIiReport,
    "reportingYear" | "reportKind" | "organizationStatus"
  >;
};

const ReportInfo: React.FC<ReportInfoProps> = ({ loading, report }) => {
  const theme = useTheme();

  if (loading || !report) {
    return (
      <Box sx={{ px: 3, pt: 2 }}>
        <Skeleton variant="text" height={theme.spacing(4)} />
        <Skeleton variant="text" height={theme.spacing(4)} />
        <Skeleton variant="text" height={theme.spacing(6)} />
      </Box>
    );
  }

  return (
    <Box sx={{ px: 3, pt: 2, pb: 3 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ color: "gray" }}>
          {report?.reportingYear}
        </Typography>
        <Typography variant="h6">
          {reportKindToLabel(report.reportKind)}
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <StatusChip status={report.organizationStatus} />
      </Box>
    </Box>
  );
};

// ML 5.1.24. It might be worth just making the route and step key match
// instead of having to translate it here.
const translateStepToRoute = (step: TierIiReportStep) => {
  switch (step) {
    case TierIiReportStep.VerifyAndSubmit:
      return "verify-submit";
    default:
      return kebabCase(step);
  }
};

const SidebarSections: React.FC<{ loading: boolean; report?: Report }> = ({
  loading,
  report,
}) => {
  const params = useParams();
  const selected = params["*"] ?? null;
  const navigate = useNavigate();
  const sections = useReportSteps();

  const renderSections = Object.entries(sections).map(
    ([section, { status, issues }]) => {
      const sectionState = status;
      const stepMetadata = reportStepMetadata[section as TierIiReportStep];
      return (
        <ReportSectionListItem
          key={section}
          text={stepMetadata.title}
          issueCount={issues.length}
          icon={(() => {
            switch (sectionState) {
              case ReportStepState.NotStarted:
                return <AdjustOutlined color="action" />;
              case ReportStepState.Done:
                return <CheckCircleOutlineRounded color="success" />;
              case ReportStepState.Invalid:
                return <ErrorOutlineRounded color="error" />;
              case undefined:
                return <Skeleton variant="circular" width={20} height={20} />;
              default:
                ((_: never) => {
                  throw new Error(`Unexpected value: ${_}`);
                })(sectionState);
            }
          })()}
          selected={
            selected === translateStepToRoute(section as TierIiReportStep)
          }
          onClick={() =>
            navigate(
              `/o/${params.tenantId}/chemicals/reports/${report?.id}/${stepMetadata.route}`
            )
          }
        />
      );
    }
  );

  if (loading || !Object.keys(sections).length) return null;

  return (
    <List sx={{ p: 0 }}>
      <ReportSectionListItem
        text="Overview"
        icon={<Home />}
        selected={selected === ""}
        onClick={() =>
          navigate(`/o/${params.tenantId}/chemicals/reports/${report?.id}`)
        }
      />
      {renderSections}
    </List>
  );
};

const ReportSectionListItem = ({
  text,
  icon,
  selected,
  onClick,
  issueCount,
}: {
  text: string;
  icon: ReactNode;
  selected: boolean;
  onClick: () => void;
  issueCount?: number;
}) => {
  const theme = useTheme();
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick} selected={selected} sx={{ pl: 3 }}>
        <ListItemIcon sx={{ minWidth: 0, mr: theme.spacing(2) }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ mr: theme.spacing(1) }} />
        <IssueCount issueCount={issueCount} />
      </ListItemButton>
    </ListItem>
  );
};

const TierIIReport: React.FC<TierIIReportProps> = () => {
  const params = useParams();

  const { data, loading, error } = useReport();

  if (error) {
    throw error;
  }

  const report = data?.tierIIReport;

  if (report && report.tenantId !== params.tenantId) {
    throw new Error("Report does not match tenant");
  }

  useBreadcrumb([
    {
      label: "Chemicals",
      to: params.tenantId ? getChemicalsBreadcrumb(params.tenantId) : undefined,
    },
    {
      label: "Reports",
      to: params.tenantId
        ? getChemicalsReportsBreadcrumb(params.tenantId)
        : undefined,
    },
    {
      label: report
        ? `${report?.facility?.name} - ${reportKindToLabel(
            report?.reportKind
          )} ${report?.reportingYear}`
        : "",
    },
  ]);

  const theme = useTheme();
  const sideBarWidth = theme.spacing(32);
  const topNavHeight = theme.spacing(15.25);

  return (
    <Grid container sx={{ height: `calc(100% - ${topNavHeight})` }}>
      <Grid
        item
        sx={{
          width: sideBarWidth,
          flexShrink: 0,
        }}
      >
        <Stack>
          <FacilityInfo loading={loading} facility={report?.facility} />

          <Divider />

          <ReportInfo loading={loading} report={report} />

          <SidebarSections loading={loading} report={report} />
        </Stack>
      </Grid>
      <Grid
        item
        xs
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          width: `calc(100% - ${sideBarWidth})`,
          maxWidth: "1200px",
          display: "flex",
          flexDirection: "column",
          px: 3,
          pt: 4,
        }}
      >
        {!data ? (
          <>
            <Skeleton variant="text" height={theme.spacing(3)} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : data.tierIIReport.organizationStatus ===
          TierIiReportOrgStatus.NotReporting ? (
          <NotReporting />
        ) : data.tierIIReport.organizationStatus ===
          TierIiReportOrgStatus.Verified ? (
          <Submitted />
        ) : (
          <Outlet />
        )}
      </Grid>
    </Grid>
  );
};

export default TierIIReport;

interface StatusChipProps {
  status: TierIiReportOrgStatus;
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const statusToColor: Record<
    TierIiReportOrgStatus,
    "default" | "primary" | "secondary" | "info" | "success"
  > = {
    [TierIiReportOrgStatus.NotStarted]: "default",
    [TierIiReportOrgStatus.NotReporting]: "default",
    [TierIiReportOrgStatus.Assigned]: "secondary",
    [TierIiReportOrgStatus.InProgress]: "primary",
    [TierIiReportOrgStatus.InReview]: "info",
    [TierIiReportOrgStatus.Verified]: "success",
  };

  const titleCaseStatus = status
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return <Chip label={titleCaseStatus} color={statusToColor[status]} />;
};
