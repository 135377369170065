import { Typography } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { InputTooltip } from "./InputTooltip";

type InputTooltipProps<T extends FieldValues> = {
  text: string | React.ReactNode;
  tooltip: string;
};

export const TextWithTooltip = <T extends FieldValues>({
  text,
  tooltip,
}: InputTooltipProps<T>) => {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      {text}
      <InputTooltip title={tooltip} />
    </span>
  );
};
