import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from "@apollo/client";
import { gql } from "generated-graphql";
import {
  CreateTierIiReportMutation,
  CreateTierIiReportMutationVariables,
  TierIiReportKind,
} from "generated-graphql/graphql";

const CREATE_TIER_II_REPORT = gql(`
  mutation CreateTierIIReport($facilityId: ID!, $reportingYear: Int!, $reportKind: TierIIReportKind!) {
    createTierIIReport(facilityId: $facilityId, reportingYear: $reportingYear, reportKind: $reportKind) {
      id
    }
  }
`);

type UseCreateReportWithoutReportKindReturn = [
  (
    options: Omit<
      MutationFunctionOptions<
        CreateTierIiReportMutation,
        CreateTierIiReportMutationVariables
      >,
      "variables"
    > & {
      variables: Omit<CreateTierIiReportMutationVariables, "reportKind">;
    }
  ) => Promise<FetchResult<CreateTierIiReportMutation>>,
  MutationResult<CreateTierIiReportMutation>
];

/**
 * This will supply the CreateTierIIReportMutation with the specified
 * reportKind variable so it doesn't need to be passed in with
 * the other mutation variables. The returned mutation can be
 * called normally with any additional options.
 */
const augmentMutationWithReportKind: (
  mutation: (
    options: MutationFunctionOptions<
      CreateTierIiReportMutation,
      CreateTierIiReportMutationVariables
    >
  ) => Promise<FetchResult<CreateTierIiReportMutation>>,
  reportKind: TierIiReportKind
) => UseCreateReportWithoutReportKindReturn[0] =
  (mutation, reportKind) => (options) =>
    mutation({
      ...options,
      variables: {
        ...options.variables,
        reportKind,
      },
    });

const useCreateAnnual = (): UseCreateReportWithoutReportKindReturn => {
  const [mutation, mutationResult] = useMutation(CREATE_TIER_II_REPORT);
  return [
    augmentMutationWithReportKind(mutation, TierIiReportKind.Annual),
    mutationResult,
  ];
};

const useCreateInitial = (): UseCreateReportWithoutReportKindReturn => {
  const [mutation, mutationResult] = useMutation(CREATE_TIER_II_REPORT);
  return [
    augmentMutationWithReportKind(mutation, TierIiReportKind.Initial),
    mutationResult,
  ];
};

const useCreateRevision = (): UseCreateReportWithoutReportKindReturn => {
  const [mutation, mutationResult] = useMutation(CREATE_TIER_II_REPORT);
  return [
    augmentMutationWithReportKind(mutation, TierIiReportKind.Revision),
    mutationResult,
  ];
};

export { useCreateAnnual, useCreateInitial, useCreateRevision };
