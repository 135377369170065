import { DialogProps, Dialog as MUIDialog } from "@mui/material";

export function Dialog(props: DialogProps) {
  return props.open ? (
    <MUIDialog {...props} open={true}>
      {props.children}
    </MUIDialog>
  ) : (
    <></>
  );
}
