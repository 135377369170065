import ExpandMore from "@mui/icons-material/ExpandMore";
import Merge from "@mui/icons-material/Merge";
import Upload from "@mui/icons-material/Upload";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { Importer } from "components/Importer";
import { ImportDialog } from "components/Importer/ImportDialog";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { TenantCatalogChemicalFragment } from "generated-graphql/graphql";
import { useState } from "react";
import { ChemicalMergePicker } from "./ChemicalMergePicker";

export const StaffBulkActionsMenu = function ({
  chemicalIds,
}: {
  chemicalIds: TenantCatalogChemicalFragment["id"][];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [importOpen, setImportOpen] = useState(false);
  const [mergeOpen, setMergeOpen] = useState(false);

  const buttonId = "staff-bulk-actions-button";

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        Staff
      </Button>
      <Menu
        id="staff-bulk-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        <MenuList dense>
          <MenuItem key="import" onClick={() => setImportOpen(true)}>
            <ListItemIcon>
              <Upload />
            </ListItemIcon>
            <ListItemText>Import</ListItemText>
          </MenuItem>

          <MenuItem
            key="merge"
            disabled={chemicalIds.length < 2}
            onClick={() => setMergeOpen(true)}
          >
            <ListItemIcon>
              <Merge />
            </ListItemIcon>
            <ListItemText>Merge</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>

      {importOpen && (
        <ImportDialog
          importers={[
            {
              name: "Chemical",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.Chemical}
                  refetchQueries={[
                    "TenantCatalogChemicals",
                    "ChemicalDetailChemicalQuery",
                  ]}
                />
              ),
            },
            {
              name: "Mixture Components",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.ChemicalComponent}
                  refetchQueries={["ChemicalDetailChemicalQuery"]}
                />
              ),
            },
            {
              name: "State Fields",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.ChemicalStateField}
                  refetchQueries={["ChemicalDetailChemicalQuery"]}
                />
              ),
            },
          ]}
          open={true}
          onClose={() => setImportOpen(false)}
        />
      )}

      {mergeOpen && (
        <ChemicalMergePicker
          open
          onClose={() => setMergeOpen(false)}
          chemicalIds={chemicalIds}
        />
      )}
    </>
  );
};
