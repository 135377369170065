import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import {
  VirtualEvent,
  Frequency,
  EventMutationScope,
  UpdateVirtualEventInput,
} from "generated-graphql/graphql";
import { useCallback } from "react";
import { TaskFormType } from "./TaskDialog";
import { DateTime } from "luxon";
import { TASKS_QUERY } from ".";

const UPDATE_SINGLE = gql(`
  mutation UpdateTaskSingle($input: UpdateTaskSingleInput!) {
    updateTaskSingle(input: $input) {
      id
    }
  }
`);

const UPDATE_RECURRING = gql(`
  mutation UpdateTaskRecurring($input: UpdateTaskRecurringInput!) {
    updateTaskRecurring(input: $input) {
      id
    }
  }
`);

type MutationFuncArgs = {
  variables: { taskData: TaskFormType; scope: EventMutationScope };
  onCompleted?: (data: any) => void;
  onError?: (error: any) => void;
};
type MutationFunc = (opts: MutationFuncArgs) => Promise<any>;

type Result = {
  loading: boolean;
  error: any;
};
type UseUpdateTaskMutationReturn = [MutationFunc, Result];
type TaskPick = Pick<VirtualEvent, "id" | "frequency" | "seriesId">;

export const useUpdateTaskMutation = (
  taskToUpdate: TaskPick | null,
  tenantId: string
): UseUpdateTaskMutationReturn => {
  const [
    updateTaskSingle,
    { loading: updateTaskSingleLoading, error: updateTaskSingleError },
  ] = useMutation(UPDATE_SINGLE, {
    refetchQueries: [TASKS_QUERY],
  });
  const [
    updateTaskRecurring,
    { loading: updateTaskRecurringLoading, error: updateTaskRecurringError },
  ] = useMutation(UPDATE_RECURRING, {
    refetchQueries: [TASKS_QUERY],
  });

  const updateFunction: MutationFunc = useCallback(
    async (options: MutationFuncArgs) => {
      const {
        variables: { taskData, scope },
        ...mutationOptions
      } = options;

      if (!taskToUpdate || !taskData.deadline) {
        return null;
      }
      if (
        taskToUpdate.frequency === Frequency.Once ||
        scope === EventMutationScope.This
      ) {
        return await updateTaskSingle({
          ...mutationOptions,
          variables: {
            input: {
              tenantId,
              eventInput: createEventInput(taskData, taskToUpdate.id),
            },
          },
        });
      }
      return await updateTaskRecurring({
        ...mutationOptions,
        variables: {
          input: {
            tenantId,
            seriesId: taskToUpdate.seriesId,
            from:
              scope === EventMutationScope.All
                ? undefined
                : DateTime.fromJSDate(taskData.deadline!).toUTC().toISODate(),
            eventInput: createEventInput(taskData, taskToUpdate.id),
          },
        },
      });
    },
    [taskToUpdate, updateTaskRecurring, updateTaskSingle]
  );

  return [
    updateFunction,
    {
      loading: updateTaskSingleLoading || updateTaskRecurringLoading,
      error: updateTaskSingleError || updateTaskRecurringError,
    },
  ];
};

const createEventInput = (
  data: TaskFormType,
  virtualEventId: string
): UpdateVirtualEventInput => {
  const completedAt = data.isCompleted
    ? data.completedOn ?? DateTime.now()
    : null;
  return {
    id: virtualEventId,
    title: data.title,
    frequency: data.frequency,
    interval: data.interval,
    description: data.description,
    shouldNotify: data.shouldNotify,
    assigneeId: data.assignee!.id,
    deadline: DateTime.fromJSDate(data.deadline!).toUTC().toISODate(), // todo  fix this
    facilityIds: data.facilityIds,
    watcherIds: data.watchers,
    documentIds: data.documents.map((document) => document.id),
    links: data.links.map((link) => link.url),
    subtasks: data.subtasks
      .filter((subTask) => subTask.title?.trim() !== "")
      .map((subTask) => ({
        id: subTask.id,
        title: subTask.title,
        completedAt: subTask.completed ? DateTime.now() : null,
      })),
    // If not previously completed and isCompleted is set, use now
    completedAt: completedAt,
    tags: data.tags,
  };
};
