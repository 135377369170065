import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider as Provider,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { PropsWithChildren, useEffect, useLayoutEffect } from "react";
import { useToken } from "./token";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

const removeTypenameLink = removeTypenameFromVariables();

const httpLink = new HttpLink({
  uri:
    import.meta.env.MODE === "development"
      ? "http://localhost:4000/graphql"
      : `${import.meta.env.VITE_API_URL}/graphql`,
});

const createErrorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions?.code === "UNAUTHENTICATED") {
          // Redirect to login page
          window.location.href = "/";
        }
      });
    }

    if (
      networkError &&
      "statusCode" in networkError &&
      networkError.statusCode === 401
    ) {
      // Redirect to login page
      window.location.href = "/";
    }
  });

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Payment: {
        fields: {
          facilities: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      TierIIReport: {
        fields: {
          assignees: {
            merge(_, incoming) {
              return incoming;
            },
          },
          reviewers: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  connectToDevTools: import.meta.env.MODE === "development",
});

export function ApolloProvider(props: PropsWithChildren<object>) {
  const { getIdTokenRefreshIfExpired, isAuthenticated } = useToken();

  // useLayoutEffect (as opposed to useEffect) forces this to be synchronous so it will finish
  // running before the component is rendered. That is necessary because we need to make the
  // client.setLink call before any child components attempt to actually use the client
  useLayoutEffect(() => {
    const authLink = setContext(async (_, { headers }) => {
      const idToken = await getIdTokenRefreshIfExpired();
      return {
        headers: {
          ...headers,
          "Content-Type": "application/json",
          authorization: idToken ? `Bearer ${idToken}` : "",
        },
      };
    });

    client.setLink(
      from([removeTypenameLink, createErrorLink(), authLink, httpLink])
    );
  }, [getIdTokenRefreshIfExpired]);

  useEffect(() => {
    if (!isAuthenticated) {
      client.clearStore();
    }
  }, [isAuthenticated]);

  return <Provider client={client}>{props.children}</Provider>;
}
