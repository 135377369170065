import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Menu, MenuItem, Tab, Tabs, TabsProps } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SubNavAppBar } from "./AppBar";

export type TabLink = {
  title: string;
  to: string;
};

export type MenuLink = {
  title: string;
  items: TabLink[];
};

type TabLinksProps = {
  value: string;
  tabs: (TabLink | MenuLink)[];
} & TabsProps;

export const tabsOffset = 6;

export function TabLinks(props: TabLinksProps) {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const { tabs, value, ...tabsProps } = props;

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };

  const isTabLink = (tab: TabLink | MenuLink): tab is TabLink => {
    return (tab as TabLink).to !== undefined;
  };

  const isMenuItemSelected = (menuItem: TabLink) => {
    return location.pathname.startsWith(menuItem.to);
  };

  const isMenuSelected = (menu: MenuLink) => {
    return menu.items.some(isMenuItemSelected);
  };

  const tabIndex = tabs.findIndex((tab) =>
    isTabLink(tab) ? tab.to === value : isMenuSelected(tab)
  );

  const tabsValue = tabIndex >= 0 ? tabIndex : false;

  const getTabStyle = (isSelected: boolean) => ({
    px: 3,
    color: isSelected ? "primary.main" : "inherit",
    "&:hover": {
      color: isSelected ? "primary.main" : "primary.light",
      backgroundColor: "transparent",
    },
  });

  const openMenuItem = openMenuIndex
    ? (tabs[openMenuIndex] as MenuLink)
    : undefined;

  return (
    <SubNavAppBar>
      <Tabs
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        {...tabsProps}
        value={tabsValue}
      >
        {tabs.map((tab, index) =>
          isTabLink(tab) ? (
            <Tab
              key={index}
              label={tab.title}
              value={index}
              to={tab.to}
              component={Link}
              sx={getTabStyle(tab.to === value)}
            />
          ) : (
            <Tab
              key={index}
              label={
                <Box display="flex" alignItems="center">
                  {tab.title}
                  <ArrowDropDownIcon fontSize="small" sx={{ ml: 1 }} />
                </Box>
              }
              value={index}
              onClick={(e) => handleMenuOpen(e, index)}
              sx={getTabStyle(isMenuSelected(tab))}
            />
          )
        )}
      </Tabs>
      {openMenuItem && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": openMenuItem.title,
          }}
        >
          {openMenuItem.items.map((item, itemIndex) => (
            <MenuItem
              key={itemIndex}
              onClick={handleMenuClose}
              component={Link}
              to={item.to}
              selected={isMenuItemSelected(item)}
              sx={getTabStyle(isMenuItemSelected(item))}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </SubNavAppBar>
  );
}
