import { useMutation } from "@apollo/client";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import { gql } from "generated-graphql";
import {
  ProductsWithMeasurementsQuery,
  ProductsWithMeasurementsQueryVariables,
} from "generated-graphql/graphql";
import { useQueryParams } from "hooks/useQueryParams";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatChemicalName } from "util/chemicalName";
import { prettyPrintQuantity } from "util/unit";
import { FacilityProductMeasurementForm } from "./FacilityProductMeasurementForm";
import { DeleteProductMessage } from "./FacilityProductsTable";
import { ProductsTableProps } from "./ProductsTable";

export const PRODUCTS_WITH_MEASUREMENTS = gql(`
  query ProductsWithMeasurements($facilityId: ID!, $reportingYear: Int!, $search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    productMeasurementsForReport(facilityId: $facilityId, reportingYear: $reportingYear, search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        totalMeasurements
        productChemicals {
          chemical {
            name
            ...ChemicalPicker
          }
          maxAmount {
            amount
            unit
          }
       }
       issues {
         ...issue
       }
      }
      count
    }
  }
`);

const DELETE_FACILITY_PRODUCT_MEASUREMENTS_BY_PRODUCT_AND_YEAR = gql(`
  mutation DeleteFacilityProductMeasurementsByProductAndYear($facilityId: ID!, $productId: ID!, $reportingYear: Int!) {
    deleteFacilityProductMeasurementsByProductAndYear(facilityId: $facilityId, productId: $productId, reportingYear: $reportingYear) {
      facilityId
      productId
      reportingYear
    }
  }
`);

type ProductsWithMeasurementsTableProps = ProductsTableProps & {
  usesSearchParams?: boolean;
};

export function ProductsWithMeasurementsTable({
  facilityId,
  reportId,
}: ProductsWithMeasurementsTableProps) {
  const alerts = useAlerts();
  const navigate = useNavigate();
  const reportingYear = useSelectedReportingYear();
  const { navigateWithFilters } = useQueryParams<{
    ry: string;
    facilityId: string;
  }>();

  const [showAddMeasurementForm, setShowAddMeasurementForm] = useState(false);
  const [deletingProductMeasurements, setDeletingProductMeasurements] =
    useState<
      | ProductsWithMeasurementsQuery["productMeasurementsForReport"]["items"][number]
      | undefined
    >(undefined);

  const location = useLocation();
  const usedInInventory = location.pathname.includes("inventory");

  const navigateToProductMeasurements = useCallback(
    (productId: string) => {
      usedInInventory
        ? navigateWithFilters(productId, (filters) => {
            filters.facilityId = facilityId;
            filters.ry = String(reportingYear);
          })
        : navigate(productId);
    },
    [navigate, navigateWithFilters, usedInInventory, facilityId, reportingYear]
  );

  const [
    deleteFacilityProductMeasurementsByProductAndYear,
    { loading: isDeleting },
  ] = useMutation(DELETE_FACILITY_PRODUCT_MEASUREMENTS_BY_PRODUCT_AND_YEAR, {
    onCompleted() {
      alerts.success("Successfully deleted the measurements");
      setDeletingProductMeasurements(undefined);
    },
    onError(err) {
      alerts.error("An error occurred deleting the measurements", err);
      setDeletingProductMeasurements(undefined);
    },
    refetchQueries: [PRODUCTS_WITH_MEASUREMENTS],
  });

  const handleDelete = useCallback(() => {
    if (!deletingProductMeasurements) return;
    deleteFacilityProductMeasurementsByProductAndYear({
      variables: {
        facilityId,
        productId: deletingProductMeasurements.id,
        reportingYear,
      },
    });
  }, [
    deleteFacilityProductMeasurementsByProductAndYear,
    deletingProductMeasurements,
    facilityId,
    reportingYear,
  ]);

  return (
    <>
      <OmnisearchDataGrid<
        ProductsWithMeasurementsQuery,
        ProductsWithMeasurementsQueryVariables,
        ProductsWithMeasurementsQuery["productMeasurementsForReport"]["items"][number]
      >
        columns={[
          {
            headerName: "Product",
            field: "name",
            flex: 2,
            topAligned: true,
            valueGetter: ({ row }) => row.name,
          },
          {
            headerName: "Measurements",
            field: "facilityProductMeasurementCount",
            flex: 1,
            topAligned: true,
            valueGetter: ({ row }) => row.totalMeasurements,
          },
          {
            headerName: "Chemicals in Product",
            field: "chemicalsInProduct",
            flex: 2,
            sortable: false,
            topAligned: true,
            renderCell({ row }) {
              if (row.productChemicals?.length) {
                return (
                  <Box>
                    {row.productChemicals.map(({ chemical }) => (
                      <Typography
                        key={chemical.id}
                        variant="body2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "inherit",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip title={formatChemicalName(chemical)}>
                          <span>{chemical.name}</span>
                        </Tooltip>
                      </Typography>
                    ))}
                  </Box>
                );
              }
            },
          },
          {
            headerName: "Max Amount",
            field: "maxAmount",
            flex: 1,
            sortable: false,
            topAligned: true,
            renderCell({ row }) {
              if (row.productChemicals?.length) {
                return (
                  <Box>
                    {row.productChemicals.map(({ chemical, maxAmount }) => (
                      <Typography
                        key={chemical.id}
                        variant="body2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "inherit",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prettyPrintQuantity(
                          maxAmount?.amount,
                          maxAmount?.unit,
                          true
                        )}
                      </Typography>
                    ))}
                  </Box>
                );
              }
            },
          },
          {
            headerName: "Issues",
            field: "issueCount",
            align: "center",
            headerAlign: "center",
            renderCell: ({ row }) => (
              <IssueCount issueCount={row.issues.length} />
            ),
            sortable: false,
            flex: 0.8,
          },
          {
            field: "actions",
            type: "actions",
            getActions({ row }) {
              return [
                <GridActionsCellItem
                  key="edit"
                  label="Edit"
                  icon={<Edit />}
                  onClick={() => navigateToProductMeasurements(row.id)}
                />,
                <GridActionsCellItem
                  key="delete"
                  label="Delete"
                  icon={<Delete />}
                  onClick={() => setDeletingProductMeasurements(row)}
                />,
              ];
            },
          },
        ]}
        dataQuery={PRODUCTS_WITH_MEASUREMENTS}
        dataQueryVariables={{
          reportingYear,
          facilityId,
        }}
        defaultSearch={`facilityId:${facilityId} reportingYear:${reportingYear}`}
        getItems={(data) => data.productMeasurementsForReport.items}
        getCount={(data) => data.productMeasurementsForReport.count}
        initialSortModel={[{ field: "name", sort: "asc" }]}
        noDataMessage="No products found"
        noDataButtonText="Add Product"
        rowHeight={"auto"}
        noDataOnButtonClick={() => setShowAddMeasurementForm(true)}
        excludeFilterColumns={[
          "issueCount",
          "maxAmount",
          "chemicalsInProduct",
          "facilityProductMeasurementCount",
        ]}
        additionalFilterColumns={[{ key: "chemical", header: "Chemical" }]}
        commandButtons={[
          !reportId ? (
            <TierIIYearPicker
              key="yearPicker"
              selectProps={{
                size: "small",
              }}
            />
          ) : undefined,
          <Button
            key="add"
            variant="contained"
            size="small"
            onClick={() => setShowAddMeasurementForm(true)}
          >
            Add Product
          </Button>,
        ]}
        withPadding={false}
        onRowClick={({ row }) => navigateToProductMeasurements(row.id)}
      />
      {!!deletingProductMeasurements && (
        <ConfirmDialog
          open={!!deletingProductMeasurements}
          onClose={() => setDeletingProductMeasurements(undefined)}
          onConfirm={handleDelete}
          title="Remove Product Measurements?"
          confirmText="delete"
          msg={DeleteProductMessage({
            productName: deletingProductMeasurements.name,
            reportingYear,
            forMeasurements: true,
          })}
          loading={isDeleting}
        />
      )}
      {showAddMeasurementForm && (
        <FacilityProductMeasurementForm
          facilityId={facilityId}
          open={showAddMeasurementForm}
          onClose={() => setShowAddMeasurementForm(false)}
        />
      )}
    </>
  );
}
