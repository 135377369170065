import { getFullName } from "encamp-shared/src/utils/name";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useInterval } from "hooks/useInterval";
import { useTenant } from "hooks/useTenant";
import { useToken } from "providers/token";
import { useCallback, useEffect, useMemo, useState } from "react";

// this chatId is for our support team, if they wanted to create a new channel, we could parameterize this component
const chatId = "7c6180ab4a679fabc9a84a196bc2d056";
const scriptId = "front-chat-script";
const iframeId = "front-chat-iframe";

interface UserInfo {
  name: string;
  userHash: string;
  email: string;
  customFields: {
    [key: string]: string | boolean | number | undefined | null;
  };
}
function FrontWrapped({ userInfo }: { userInfo: UserInfo }) {
  const handleScriptLoad = useCallback(() => {
    if (window.FrontChat) {
      window.FrontChat("init", {
        chatId: chatId,
        useDefaultLauncher: true,
        ...userInfo,
      });
    }
  }, [userInfo]);

  /**
   * Once the iframe is found, the interval is cleared
   * and the z-index of the iframe is set to 1201.
   * This is done every 1000 milliseconds (1 second)
   *  until the iframe is found or the counter reaches 30 seconds.
   */
  const [counter, setCounter] = useState(0);
  const [foundIframe, setFoundIframe] = useState(false);

  useInterval(
    () => {
      setCounter((prev) => prev + 1);
      const iframe = document.getElementById(iframeId);
      if (iframe) {
        setFoundIframe(true);
        iframe.style.zIndex = "1201";
      }
    },
    !foundIframe && counter <= 10 ? 1000 : null
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
    script.async = true;
    script.onload = handleScriptLoad;
    script.onerror = () => console.error("Could not load FrontChat");
    document.body.appendChild(script);

    return () => {
      if (window.FrontChat) {
        window.FrontChat("shutdown");
      }
      const script = document.getElementById(scriptId);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);
  return null;
}

export function FrontChat() {
  const { user, isStaff } = useCurrentUser();
  const { tokenClaims } = useToken();
  const { tenant } = useTenant();
  const isLocalhost = useMemo(
    () => window.location.href.includes("localhost"),
    []
  );
  const hash = tokenClaims
    ? tokenClaims["custom:front_verification_hash"]
    : undefined;
  const userInfo =
    user && tenant && hash
      ? {
          name: getFullName(user.person),
          userHash: hash,
          email: user.email,
          customFields: {
            firstName: user.person?.first,
            lastName: user.person?.last,
            organization: tenant.name,
            tenantId: tenant.id,
            isStaff,
          },
        }
      : undefined;

  return userInfo && !isLocalhost ? <FrontWrapped userInfo={userInfo} /> : null;
}
