import { useQuery } from "@apollo/client";
import { PropsWithChildren, createContext, useMemo } from "react";
import { configureSentryUser } from "sentry";
import { gql } from "../generated-graphql";
import {
  GetUserQuery,
  GetUserQueryVariables,
} from "../generated-graphql/graphql";

export type CurrentUser = GetUserQuery["getCurrentUser"];

type CurrentUserProviderContext = {
  user: CurrentUser | undefined;
  loading: boolean;
  refetchUser: () => void;
};

export const currentUserContext = createContext<CurrentUserProviderContext>({
  user: undefined,
  loading: true,
  refetchUser: () => {},
});

const GET_USER_QUERY = gql(`
  query GetUser {
    getCurrentUser {
      id
      email
      type
      isPartner
      person {
        id
        first
        last
      }
      UserTenant {
        tenantId
        role {
          permissions
        }
        tenant {
          id
          partnersTenants {
            id
          }
        }
      }
      facilities {
        id
      }
      isEmulating
      originalUserType
    }
  }
`);

export function CurrentUserProvider(props: PropsWithChildren<unknown>) {
  const { data, loading, error, refetch } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_QUERY);

  const contextValue = useMemo(
    () => ({
      user: data?.getCurrentUser,
      loading,
      refetchUser: refetch,
      originalUserType: data?.getCurrentUser?.originalUserType,
    }),
    [data?.getCurrentUser, loading, refetch]
  );

  if (error) {
    console.error(error);
    throw error;
  }

  configureSentryUser(data?.getCurrentUser ? { ...data.getCurrentUser } : null);

  return (
    <currentUserContext.Provider value={contextValue}>
      {props.children}
    </currentUserContext.Provider>
  );
}
