import { Button, Collapse, Grid, Stack } from "@mui/material";
import { usePrevNextNavigationContext } from "components/PrevNextNavigation";
import { useCallback, useEffect } from "react";
import { Outlet, useMatch } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/useBreadcrumbs";
import { AssignmentManagementTable } from "./AssignmentManagerTable";
import { Summary } from "./Summary";
import { useLocalStorageState } from "hooks/useLocalStorageState";

export function FulfillmentDashboard() {
  const [statsOpen, setStatsOpen] = useLocalStorageState<boolean>(
    "statsOpen",
    false
  );

  const { setNavigationKey } = usePrevNextNavigationContext();
  useEffect(() => {
    // Set the navigation key when the component mounts
    setNavigationKey("/staff/fulfillment");

    // Reset the navigation key when the component unmounts
    return () => {
      setNavigationKey("");
    };
  }, [setNavigationKey]);
  const match = useMatch("/staff/fulfillment");

  const handleToggle = useCallback(() => {
    setStatsOpen((prevState) => !prevState);
  }, [setStatsOpen]);

  useBreadcrumb({
    label: "Fulfillment",
    to: "/staff/fulfillment",
  });

  if (!match) return <Outlet />;

  return (
    <Stack p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button onClick={handleToggle}>
            {statsOpen ? "Hide Stats" : "Expand Stats"}
          </Button>
          <Collapse in={statsOpen}>
            <Grid item mt={1}>
              <Summary />
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <AssignmentManagementTable />
        </Grid>
      </Grid>
    </Stack>
  );
}
