import { TextField } from "@mui/material";

export function LabeledText(props: {
  label: string;
  value?: string | undefined | null;
}) {
  const { label, value } = props;
  return (
    <TextField
      fullWidth
      label={label}
      value={value ?? " "}
      variant="standard"
      size="small"
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.87)",
          WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
          "&::before, &::after": {
            borderBottom: "none !important",
          },
          "&:hover::before": {
            borderBottom: "none !important",
          },
        },
        "& .MuiInputLabel-root.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.6)",
          WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
        },
        "& .MuiInput-underline:before, & .MuiInput-underline:after": {
          borderBottom: "none !important",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "none !important",
        },
      }}
      disabled
    />
  );
}
