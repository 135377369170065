import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";

import {
  DELETE_POLICE_DEPARTMENT,
  GET_NJ_POLICE_DEPARTMENT,
  GET_ALL_NJ_POLICE_DEPARTMENTS,
} from "./schema";

export function ConfirmDeleteNJPoliceDepartments({
  agencyId,
  onClose,
}: {
  agencyId: number;
  onClose: () => void;
}) {
  const alerts = useAlerts();

  const { data, previousData } = useQuery(GET_NJ_POLICE_DEPARTMENT, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: agencyId,
    },
  });

  const [deletePoliceDepartment, { loading: isDeleting }] = useMutation(
    DELETE_POLICE_DEPARTMENT
  );

  const policeDepartment =
    data?.policeDepartment ?? previousData?.policeDepartment;

  const modalContent = (
    <Stack gap={2}>
      <Typography>
        Are you sure you want to delete this police department?
      </Typography>
    </Stack>
  );

  return policeDepartment ? (
    <ConfirmDialog
      open={true}
      msg={modalContent}
      title="Confirm Delete"
      confirmText="Delete"
      loading={isDeleting}
      onClose={onClose}
      onConfirm={() =>
        deletePoliceDepartment({
          variables: { id: agencyId },
          onCompleted() {
            onClose();
            alerts.success("Police department deleted successfully!");
          },
          onError(e) {
            alerts.error(
              "There was an error deleting the police department:",
              e
            );
          },
          refetchQueries: [GET_ALL_NJ_POLICE_DEPARTMENTS],
        })
      }
    />
  ) : null;
}
