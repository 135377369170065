import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useState } from "react";
import { TenantCatalogChemicalFragment } from "generated-graphql/graphql";
import Close from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import { ConfirmDialog } from "components/ConfirmDialog";
import pluralize from "pluralize";
import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import { useAlerts } from "components/Alerts/AlertProvider";

const BULK_VERIFY_CHEMICALS_MUTATION = gql(`
    mutation BulkVerifyChemicals($chemicalIds: [ID!]!) {
        verifyChemicals(chemicalIds: $chemicalIds)
    }
`);

const BULK_UNVERIFY_CHEMICALS_MUTATION = gql(`
     mutation BulkUnverifyChemicals($chemicalIds: [ID!]!) {
        unverifyChemicals(chemicalIds: $chemicalIds)
    }`);

export const ChemicalCatalogBulkActions = function ({
  chemicals,
}: {
  chemicals: TenantCatalogChemicalFragment[];
}) {
  const buttonId = "chemical-catalog-bulk-actions";
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setDialogOpen] = useState<
    undefined | "verify" | "unverify"
  >(undefined);
  const alerts = useAlerts();

  const [bulkVerifyChemicals, { loading: bulkVerifyingChemicals }] =
    useMutation(BULK_VERIFY_CHEMICALS_MUTATION);

  const [bulkUnverifyChemicals, { loading: bulkUnverifyingChemicals }] =
    useMutation(BULK_UNVERIFY_CHEMICALS_MUTATION);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        Bulk Actions
      </Button>
      <Menu
        id="add-chemical-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        <MenuList dense>
          <MenuItem
            key="bulk-verify"
            onClick={() => {
              setDialogOpen("verify");
              handleClose();
            }}
            disabled={chemicals.length === 0}
          >
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText>Verify Chemicals</ListItemText>
          </MenuItem>
          <MenuItem
            key="bulk-unverify"
            onClick={() => {
              setDialogOpen("unverify");
              handleClose();
            }}
            disabled={chemicals.length === 0}
          >
            <ListItemIcon>
              <Close />
            </ListItemIcon>
            <ListItemText>Unverify Chemicals</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <ConfirmDialog
        open={!!openDialog}
        onClose={() => setDialogOpen(undefined)}
        onCancel={() => setDialogOpen(undefined)}
        loading={bulkVerifyingChemicals || bulkUnverifyingChemicals}
        onConfirm={async () => {
          try {
            if (openDialog === "verify")
              await bulkVerifyChemicals({
                variables: {
                  chemicalIds: chemicals.map((c) => c.id),
                },
              });
            else
              await bulkUnverifyChemicals({
                variables: {
                  chemicalIds: chemicals.map((c) => c.id),
                },
              });
            alerts.success(
              `Successfully ${
                openDialog === "verify" ? "verified" : "unverified"
              } chemicals.`
            );
          } catch (err) {
            alerts.error(
              `There was an error ${
                openDialog === "verify" ? "verified" : "unverified"
              } chemicals`,
              { err }
            );
          }
          setDialogOpen(undefined);
        }}
        msg={`Are you sure you want to ${openDialog} the selected ${
          chemicals.length
        } ${pluralize(
          "chemical",
          chemicals.length
        )}? This indicates that these chemicals in Encamp ${
          openDialog === "unverify" && `do not`
        } match their SDS documents. Other users of your organization will see these chemicals as ${
          openDialog === "verify" ? "verified" : "unverified"
        }.`}
      />
    </>
  );
};
