import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  useTheme,
} from "@mui/material";
import { Issue } from "generated-graphql/graphql";
import { groupBy } from "lodash";

export const ValidationSummary = ({ issues }: { issues: Issue[] }) => {
  const theme = useTheme();
  const groupedIssues = groupBy(issues, "modelName");
  if (issues.length === 0) {
    return <></>;
  }

  return (
    <Box sx={{ mt: theme.spacing(1) }}>
      <Typography>
        The following compliance issues will have to be resolved before
        reporting. Would you like to save anyway?
      </Typography>
      <List>
        {/* TODO: Make this scrollable */}
        {/* TODO: Better format errors */}
        {/* i.e. "CAS Number is required" */}
        {Object.entries(groupedIssues).map(([model, issues]) => (
          <div key={model}>
            <ListSubheader
              disableGutters
              key={model}
              sx={{
                height: "auto",
                bgcolor: "transparent",
                fontSize: theme.typography.subtitle1.fontSize,
              }}
            >
              {model}
            </ListSubheader>
            {issues.map((issue, i) => (
              <ListItem key={i} disablePadding>
                <ListItemIcon sx={{ mr: theme.spacing(1), minWidth: 5 }}>
                  <ErrorOutlineRounded color="error" />
                </ListItemIcon>
                <ListItemText primary={issue.message} />
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </Box>
  );
};
