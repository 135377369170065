import { MutationHookOptions, useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import {
  DeleteDocumentMutation,
  DocumentInput,
  RemoveDocumentFromActivityMutation,
  UpdateDocumentMutation,
} from "generated-graphql/graphql";

const DELETE_DOCUMENT_MUTATION = gql(`
  mutation DeleteDocument($id: ID!, $retainAsset: Boolean) {
    deleteDocument(id: $id, retainAsset: $retainAsset) {
      id
      reportId
    }
  }
`);

type DeleteDocumentVariables = {
  id: string;
};

export function useDeleteDocumentMutation(
  options?: MutationHookOptions<DeleteDocumentMutation, DeleteDocumentVariables>
) {
  return useMutation(DELETE_DOCUMENT_MUTATION, options);
}

export const UPDATE_DOCUMENT_MUTATION = gql(`
  mutation UpdateDocument($id: ID!, $input: DocumentInput!) {
    updateDocument(id: $id, input: $input) {
      id
      title
      description
      storageLink
      documentType
      updatedAt
      createdAt
      reportId
      documentTags {
        id
        name
        tenantId
        isDisabled
        type
      }
    }
  }
`);

type UpdateDocumentVariables = {
  id: string;
  input: DocumentInput;
};

export function useUpdateDocumentMutation(
  options?: MutationHookOptions<UpdateDocumentMutation, UpdateDocumentVariables>
) {
  return useMutation(UPDATE_DOCUMENT_MUTATION, options);
}

export const REMOVE_DOCUMENT_FROM_ACTIVITY_MUTATION = gql(`
  mutation RemoveDocumentFromActivity($activityId: ID!, $documentId: ID!) {
    removeDocumentFromActivity(activityId: $activityId, documentId: $documentId) {
      activityId
      documentId
    }
  }`);

type RemoveDocumentFromActivityVariables = {
  activityId: string;
  documentId: string;
};
export function useRemoveDocumentFromActivityMutation(
  options?: MutationHookOptions<
    RemoveDocumentFromActivityMutation,
    RemoveDocumentFromActivityVariables
  >
) {
  return useMutation(REMOVE_DOCUMENT_FROM_ACTIVITY_MUTATION, options);
}
