import { Stack, useTheme } from "@mui/material";
import { useReportSteps } from "./useReport";
import { OverviewCard } from "./OverviewCard";
import { ReportStepState } from "util/constants";
import { useMemo } from "react";
import { TierIiReportStep } from "generated-graphql/graphql";

type OverviewCardsProps = {
  showIssueDescriptions?: boolean;
  hideCompleted?: boolean;
  hideSteps?: TierIiReportStep[];
};

export const OverviewCards = ({
  showIssueDescriptions,
  hideCompleted,
  hideSteps = [],
}: OverviewCardsProps) => {
  const theme = useTheme();
  const steps = useReportSteps();

  const overviewSteps = useMemo(
    () =>
      Object.values(steps).filter((step) => {
        if (hideSteps.includes(step.metaData?.key)) {
          return false;
        }
        if (hideCompleted && step.status === ReportStepState.Done) {
          return false;
        }
        return true;
      }),
    [hideCompleted, hideSteps, steps]
  );

  return (
    <Stack
      gap={theme.spacing(1.5)}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      {overviewSteps.map((step) => (
        <OverviewCard
          key={step.metaData?.key}
          title={step.metaData?.title ?? ""}
          route={step.metaData.route}
          step={step}
          showIssueDescriptions={showIssueDescriptions}
        />
      ))}
    </Stack>
  );
};
