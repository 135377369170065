import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  ContactReportRole,
  CredentialKind,
  CredentialVerificationStatus,
  DocumentType,
  RcraInfoFederalWasteGeneratorStatus,
  TierIiReportEncampStatus,
  TierIiReportKind,
  TierIiReportOrgStatus,
  TierIiReportStep,
} from "generated-graphql/graphql";

export function statusToLabel(
  orgStatus: TierIiReportOrgStatus,
  encampStatus: TierIiReportEncampStatus
): string {
  switch (orgStatus) {
    case TierIiReportOrgStatus.NotStarted:
    case TierIiReportOrgStatus.NotReporting:
    case TierIiReportOrgStatus.Assigned:
    case TierIiReportOrgStatus.InProgress:
    case TierIiReportOrgStatus.InReview:
      return orgStatusToLabel(orgStatus);
    case TierIiReportOrgStatus.Verified: // once we get to verified we're in encamp status territory
      return encampStatusToLabel(encampStatus);
  }
}

export function orgStatusToLabel(orgStatus: TierIiReportOrgStatus): string {
  switch (orgStatus) {
    case TierIiReportOrgStatus.NotStarted:
      return "Not Started";
    case TierIiReportOrgStatus.NotReporting:
      return "Not Reporting";
    case TierIiReportOrgStatus.Assigned:
      return "Assigned";
    case TierIiReportOrgStatus.InProgress:
      return "In Progress";
    case TierIiReportOrgStatus.InReview:
      return "In Review";
    case TierIiReportOrgStatus.Verified:
      return "Verified";
  }
}

export function orgStatusIsOpen(orgStatus: TierIiReportOrgStatus): boolean {
  switch (orgStatus) {
    case TierIiReportOrgStatus.NotStarted:
    case TierIiReportOrgStatus.Assigned:
    case TierIiReportOrgStatus.InProgress:
    case TierIiReportOrgStatus.InReview:
      return true;
    case TierIiReportOrgStatus.NotReporting:
    case TierIiReportOrgStatus.Verified:
      return false;
  }
}

export const encampStatusToLabel = (
  encampStatus: TierIiReportEncampStatus
): string => {
  switch (encampStatus) {
    case TierIiReportEncampStatus.AwaitingVerification:
      return "Awaiting Verification";
    case TierIiReportEncampStatus.Processing:
      return "Processing";
    case TierIiReportEncampStatus.FilingComplete:
      return "Filing Complete";
    case TierIiReportEncampStatus.NotReporting:
      return "Not Reporting";
  }
};

export const reportKindToLabel = (reportKind: TierIiReportKind): string => {
  switch (reportKind) {
    case TierIiReportKind.Annual:
      return "Annual";

    case TierIiReportKind.Initial:
      return "Initial Notification";

    case TierIiReportKind.Revision:
      return "Revision";

    case TierIiReportKind.Update:
      return "Update";
    default:
      return "Annual";
  }
};

export const documentTypeToLabel = (documentType: DocumentType): string => {
  switch (documentType) {
    case DocumentType.AstDocumentation:
      return "AST Documentation";
    case DocumentType.Certification:
      return "Certification";
    case DocumentType.Check:
      return "Check";
    case DocumentType.CoverLetter:
      return "Cover Letter";
    case DocumentType.DikeDescription:
      return "Dike Description";
    case DocumentType.EmergencyResponsePlan:
      return "Emergency Response Plan";
    case DocumentType.Epcra302:
      return "EPCRA 302";
    case DocumentType.Epcra304:
      return "EPCRA 304";
    case DocumentType.Epcra311:
      return "EPCRA 311";
    case DocumentType.Epcra312:
      return "EPCRA 312";
    case DocumentType.Epcra313:
      return "EPCRA 313";
    case DocumentType.FeeCalculation:
      return "Fee Calculation";
    case DocumentType.Fees:
      return "Fees";
    case DocumentType.Form:
      return "Form";
    case DocumentType.HazardousWasteContingencyPlan:
      return "Hazardous Waste Contingency Plan";
    case DocumentType.HazardousMaterialsContingencyPlanOrQuickGuide:
      return "Hazardous Materials Contingency Plan or Quick Guide";
    case DocumentType.HazardousMaterialsInventoryStatement:
      return "Hazardous Materials Inventory Statement";
    case DocumentType.HazardousMaterialsManagementPlan:
      return "Hazardous Materials Management Plan";
    case DocumentType.Invoice:
      return "Invoice";
    case DocumentType.Other:
      return "Other";
    case DocumentType.P2Plan:
      return "P2 Plan";
    case DocumentType.P2Update:
      return "P2 Update";
    case DocumentType.Permit:
      return "Permit";
    case DocumentType.Proof:
      return "Proof";
    case DocumentType.RcraAnnual:
      return "RCRA Annual";
    case DocumentType.RcraFeePaymentOrInvoice:
      return "RCRA Fee Payment/Invoice";
    case DocumentType.RcraManifest:
      return "RCRA Manifest";
    case DocumentType.RcraUpdateNotification:
      return "RCRA Update Notification";
    case DocumentType.Receipt:
      return "Receipt";
    case DocumentType.ReferenceImage:
      return "Reference Image";
    case DocumentType.Report:
      return "Report";
    case DocumentType.RevisionOrUpdate:
      return "Revision/Update";
    case DocumentType.SafeguardMeasures:
      return "Safeguard Measures";
    case DocumentType.SafetyDataSheet:
      return "Safety Data Sheet";
    case DocumentType.Screenshot:
      return "Screenshot";
    case DocumentType.SiteCoordinateAbbreviation:
      return "Site Coordinate Abbreviation";
    case DocumentType.SitePlan:
      return "Site Plan";
    case DocumentType.Submission:
      return "Submission";
    case DocumentType.TrainingPlan:
      return "Training Plan";
    case DocumentType.UstDocumentation:
      return "UST Documentation";
  }
};

export const credentialKindToLabel = (kind: CredentialKind): string => {
  switch (kind) {
    case CredentialKind.RcraCounty:
      return "RCRA County";
    case CredentialKind.RcraState:
      return "RCRA State";
    case CredentialKind.RcraFederal:
      return "RCRA Federal";
    case CredentialKind.RcraPayment:
      return "RCRA Payment";
    case CredentialKind.Tierii:
      return "Tier II";
    case CredentialKind.Lepc:
      return "LEPC";
  }
};

export const credentialVerificationStatusToLabel = (
  status: CredentialVerificationStatus
): string => {
  switch (status) {
    case CredentialVerificationStatus.InvalidAccessidOrPassword:
      return "Invalid Access ID or Password";
    case CredentialVerificationStatus.UnknownNonVerifiable:
      return "Unknown Non-Verifiable";
    default:
      return prettyPrintEnumValue(status);
  }
};

export const contactRoleToLabel = (role: ContactReportRole) => {
  switch (role) {
    case ContactReportRole.EpcraOwnerOperator:
      return "Owner / Operator";
    case ContactReportRole.EpcraEmergencyContact:
      return "Emergency Contact";
    case ContactReportRole.EpcraEmergencyCoordinator:
      return "Emergency Coordinator";
    case ContactReportRole.EpcraTierIiContact:
      return "Tier II Contact";
    case ContactReportRole.EpcraBilling:
      return "Billing Contact";
    case ContactReportRole.EpcraChemicalCarrier:
      return "Chemical Carrier";
    default:
      return prettyPrintEnumValue(role);
  }
};

export enum ReportStepState {
  NotStarted = "not started",
  Done = "done",
  Invalid = "invalid",
}

export type ReportStepMetadata = {
  title: string;
  route: string;
  hideOnReviewPage?: boolean;
  /** `touchedSteps` name */
  key: TierIiReportStep;
  /** Description used in overview cards and editor subheaders */
  overviewDescription: string;
};

export const reportStepMetadata: {
  [step in TierIiReportStep]: ReportStepMetadata;
} = {
  REPORT_OVERVIEW: {
    title: "Overview",
    route: "",
    key: TierIiReportStep.ReportOverview,
    overviewDescription: "Review and validate information on your report.",
  },
  FACILITY: {
    title: "Facility",
    route: "facility",
    key: TierIiReportStep.Facility,
    overviewDescription:
      "Add or confirm the information about your facility and where it's located.",
  },
  REGULATORY: {
    title: "Regulatory",
    route: "regulatory",
    key: TierIiReportStep.Regulatory,
    overviewDescription:
      "Confirm if your facility is subject to any of the listed regulations. Depending on your selection, you may need to add an RMP or TRI ID.",
  },
  CHEMICALS: {
    title: "Chemicals",
    route: "chemicals",
    key: TierIiReportStep.Chemicals,
    overviewDescription:
      "Confirm or update the chemical inventory at your facility. Include all reportable chemicals present at or above the threshold amounts at your facility in the last calendar year. Encamp will alert you if any product does not cross the reporting threshold based on the indicated quantities at your facility.",
  },
  PRODUCTS: {
    title: "Products",
    route: "products",
    key: TierIiReportStep.Products,
    overviewDescription:
      "Confirm or update the number of products and equipment at your facility that contain reportable chemicals. We will calculate whether the total chemical quantity is at or above reportable thresholds.",
  },
  OTHER_CHEMICALS: {
    title: "Other Chemicals",
    route: "other-chemicals",
    key: TierIiReportStep.OtherChemicals,
    overviewDescription:
      "Confirm or update the chemical inventory at your facility. Include all reportable chemicals present at or above the threshold amounts at your facility in the last calendar year. Check all locations and quantities and make sure they are up to date.",
  },
  CHEMICAL_SUMMARY: {
    title: "Chemical Summary",
    route: "chemical-summary",
    key: TierIiReportStep.ChemicalSummary,
    overviewDescription:
      "Confirm the total chemical amounts that will be reported by your facility from both products and other chemicals. You may also answer state-specific questions or voluntarily report under-threshold chemicals.",
  },
  CONTACTS: {
    title: "Contacts",
    route: "contacts",
    key: TierIiReportStep.Contacts,
    overviewDescription: "Add or confirm the contact list for your facility.",
  },
  DOCUMENTS: {
    title: "Documents",
    route: "documents",
    key: TierIiReportStep.Documents,
    overviewDescription:
      "Some states require site maps or various plans to accompany the reports. Confirm the attached documents for your facility and upload a site map as needed.",
  },
  STATE_INFORMATION: {
    title: "State Info",
    route: "state-information",
    key: TierIiReportStep.StateInformation,
    overviewDescription:
      "Some states require additional information to be included on the reports. Confirm or update the information for your facility. Many custom state fields are optional or can be calculated based on other data you have already entered, so Encamp does not display them here.",
  },
  REVIEW: {
    title: "Review",
    route: "review",
    hideOnReviewPage: true,
    key: TierIiReportStep.Review,
    overviewDescription: "Review and validate information on your report.",
  },
  VERIFY_AND_SUBMIT: {
    title: "Verify & Submit",
    route: "verify-submit",
    hideOnReviewPage: true,
    key: TierIiReportStep.VerifyAndSubmit,
    overviewDescription:
      "Verify and submit. Our team will review, process, calculate fees, and submit to state portals on your behalf.",
  },
};

export const wasteGeneratorStatusToLabel = (
  status: RcraInfoFederalWasteGeneratorStatus
) => {
  switch (status) {
    case RcraInfoFederalWasteGeneratorStatus.VerySmallQuantityGenerator:
      return "VSQG";
    case RcraInfoFederalWasteGeneratorStatus.SmallQuantityGenerator:
      return "SQG";
    case RcraInfoFederalWasteGeneratorStatus.LargeQuantityGenerator:
      return "LQG";
    default:
      return prettyPrintEnumValue(status);
  }
};
