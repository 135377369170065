// usePaginationModel.ts
import { useState } from "react";

export type PaginationModel = {
  page: number;
  pageSize: number;
};

export const usePaginationModel = (initialPageSize = 25) => {
  return useState<PaginationModel>({
    page: 0,
    pageSize: initialPageSize,
  });
};
