import { Reference } from "@apollo/client";
import { gql } from "generated-graphql";
import {
  Document,
  FacilityDocumentInput,
  JobStatus,
  TagInput,
} from "generated-graphql/graphql";
import { isNil } from "lodash";
import { client } from "../providers/apollo";

type UploadFileMetadata = {
  tenantId: string;
  userId: string;
  facilities?: FacilityDocumentInput[];
  reportId?: string;
  state?: string | null;
  activityId?: string;
  description?: string;
  documentType?: string;
  documentId?: string;
  documentTags?: TagInput[];
};

const GENERATE_PRESIGNED_URL = gql(`
  mutation GeneratePresignedUrl(
    $fileName: String!
    $tenantId: ID!
    $facilityId: ID
  ) {
    generatePresignedUrl(
      fileName: $fileName
      tenantId: $tenantId
      facilityId: $facilityId
    ) {
      key
      presignedUrl
  }
  }
`);

/**
 *
 * @param file {File} file to upload
 * @param metadata {UploadFileMetadata} metadata on who is uploading file, for which facility, etc.
 * @returns {string} S3 key of file
 */
async function uploadFileDirectly(
  file: File,
  metadata: UploadFileMetadata
): Promise<string> {
  const { data } = await client.mutate({
    mutation: GENERATE_PRESIGNED_URL,
    variables: {
      fileName: file.name,
      tenantId: metadata.tenantId,
    },
  });

  if (!data) {
    throw new Error(
      "Unable to upload document: Cannot obtain presigned S3 URL."
    );
  }

  const { presignedUrl, key } = data.generatePresignedUrl;

  const response = await fetch(presignedUrl, { method: "PUT", body: file });

  if (!response.ok) {
    throw new Error(
      `Unable to upload document: Error uploading document to S3: ${response.status} ${response.statusText}`
    );
  }

  return key;
}

export type DocumentWithFileSize = Document & { fileSize: number };

/**
 * uploads a File object to
 * our /upload REST endpoint
 * @param file {File} file object to upload
 * @param idToken {string} Bearer token to provide for
 * Authorization HTTP header (from openid connect context).
 * @param metadata {UploadFileMetadata} additional information
 * such as report, activity, type, etc
 */
export async function uploadFile(
  file: File,
  metadata: UploadFileMetadata,
  idToken?: string
): Promise<Document> {
  const authorization = `Bearer ${idToken || ""}`;
  const apiUrl = `${
    import.meta.env.MODE === "development"
      ? "http://localhost:4000/upload"
      : `${import.meta.env.VITE_API_URL}/upload`
  }`;
  const formData = new FormData();
  const headers = new Headers();

  headers.set("authorization", authorization);

  if (metadata) {
    Object.entries(metadata).forEach(([k, v]) => {
      if (v) {
        if (Array.isArray(v) && k === "facilities") {
          formData.append(
            "facilityIds",
            (v as FacilityDocumentInput[]).map((f) => f.id).join(",")
          );
        } else if (Array.isArray(v) && k === "documentTags") {
          formData.append("documentTags", JSON.stringify(v) ?? "");
        } else if (typeof v === "string") {
          formData.append(k, v);
        }
      }
    });
  }

  // if file is above 10MB we need to upload it
  // directly to S3 due to API Gateway preventing
  // HTTP messages being larger than that.
  // We set a couple headers to let the API server
  // know that the file is already in S3.
  // Check if size is greater than 9.7MB, since
  // the HTTP message include headers
  if (file.size / (1024 * 1024) > 9.7) {
    const documentKey = await uploadFileDirectly(file, metadata);

    headers.set("x-document-key", documentKey);
    // provide dummy file to API server so that we can still
    // run busboy file handler
    formData.append("file", new File([new Blob([])], file.name));
  } else {
    // this needs to come last, because we use a streaming parser and want
    // to stream the file only after we've received its metadata above
    // check out apps/api/src/routes/upload.ts for more details
    formData.append("file", file);
  }

  const response = await fetch(apiUrl, {
    headers,
    body: formData,
    method: "POST",
  });

  if (!response.ok) {
    return Promise.reject(
      new Error(`file upload failed for ${file.name}: ${response.text()}`)
    );
  }

  const { document }: { document: Document } = await response.json();

  const documentRef = client.writeFragment({
    id: client.cache.identify(document),
    fragment: gql(`fragment DocumentFragment on Document {
          id
          title
          description
          storageLink
          documentType
          updatedAt
          createdAt
          fileExtension
          facilities {
            id
            name
          }
        }`),
    data: {
      ...document,
      facilities:
        metadata.facilities?.map((f) => {
          return { id: f.id ?? "", name: f.name ?? "" };
        }) ?? [],
    },
  });
  if (metadata && metadata?.activityId && documentRef) {
    updateDocumentCache("Activity", metadata?.activityId, documentRef);
  }
  if (metadata && metadata?.reportId && documentRef) {
    updateDocumentCache("TierIIReport", metadata?.reportId, documentRef);
  }

  return document;
}

function updateDocumentCache(
  forType: string,
  cacheEntityId: string,
  documentRef: Reference
) {
  client.cache.modify({
    optimistic: true,
    id: client.cache.identify({
      __typename: forType,
      id: cacheEntityId,
    }),
    fields: {
      documents(documentRefs = [], { readField }) {
        if (isNil(documentRefs)) {
          return [documentRef];
        } else {
          const targetRefId = readField("id", documentRef);
          return [
            ...documentRefs.filter(
              (d: Reference) => readField("id", d) !== targetRefId // we're filtering out the previous document, in the case of an edit
            ),
            documentRef,
          ];
        }
      },
    },
  });
}

export async function uploadSDS(
  files: FileList,
  tenantId: string,
  idToken: string
): Promise<{ jobId: string; status: JobStatus }> {
  const authorization = `Bearer ${idToken || ""}`;
  const formData = new FormData();
  formData.append("tenantId", tenantId ?? "");

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append("file", file);
  }

  const response = await fetch(
    `${
      import.meta.env.MODE === "development"
        ? "http://localhost:4000/upload-sds"
        : `${import.meta.env.VITE_API_URL}/upload-sds`
    }`,
    {
      headers: {
        authorization,
      },
      method: "POST",
      body: formData,
    }
  );

  if (!response.ok) {
    return Promise.reject(
      new Error(`file upload failed: ${await response.text()}`)
    );
  }

  const data: { jobId: string; status: JobStatus } = await response.json();
  return data;
}
