import {
  ClickAwayListener,
  Tooltip,
  TooltipProps,
  styled,
} from "@mui/material";
import { useState, PropsWithChildren } from "react";

export const BigTooltip = styled(
  ({ className, children, ...props }: PropsWithChildren<TooltipProps>) => {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClick={handleTooltipOpen}
          {...props}
          classes={{ popper: className }}
        >
          {children}
        </Tooltip>
      </ClickAwayListener>
    );
  }
)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[5],
  },
}));
