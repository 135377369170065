import { gql } from "generated-graphql";

gql(`
  fragment LepcFragment on Lepc {
    id,
    name,
    state,
    city,
    county,
    zip,
    mailingAddress,
    mailingAddressLine2,
    contactName,
    email,
    phone,
    feeStructure,
    latitude,
    longitude,
    leadAgency,
    paymentMethod,
    t2sValue,
    submissionNotes,
    requirementDetails,
    additionalComments,
    additionaRequirements,
    additionalFees,
    isEmailed,
    isCurrent1AsOf2021,
    isMailed,
    deactivated,
    isStateSubmits,
    needsT2sFile,
    isSubmittedViaPortal,
    needsXmlZipFile,
    portalId,
    portalName,
    issues {
      ...issue
    }
  }
`);

export const LEPC_SEARCH = gql(`
  query LepcSearch($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String) {
    lepcSearch(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        ...LepcFragment
      }
      count
    }
  }
`);

export const GET_LEPC = gql(`
  query Lepc($id: String!) {
    lepc(id: $id) {
        ...LepcFragment
        issues {
          ...issue
        }
    }
  }
`);

export const UPSERT_LEPC = gql(`
  mutation UpsertLepc($id: String, $input: LepcInput!) {
    upsertLepc(id: $id, input: $input) {
        ...LepcFragment
    }
  }
`);

export const DELETE_LEPC = gql(`
  mutation DeleteLepc($id: String!) {
    deleteLepc(id: $id) {
      id
    }
  }
`);

export const VALIDATE_LEPC_INPUT = gql(`
  query ValidateLepcInput($id: String, $data: LepcInput!) {
    lepcInputValidator(id: $id, data: $data) {
      ...issue
    }
  }
`);
