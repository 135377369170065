import { Stack, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { convertEnumToHumanReadable, getActivityTitle } from "util/activity";
import { useReportDetails } from "../useReportDetails";
import { DataGrid } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid-premium";
import { ActivityLog } from "generated-graphql/graphql";
import { prettyPrintDateTime, relativeLogTime } from "util/dates";

export function ActivityLogs() {
  const { data } = useReportDetails();
  const { activityId } = useParams<{ activityId: string }>();
  const activity = useMemo(
    () =>
      data?.tierIIReport.currentWorkflow?.activities.find(
        (act) => act.id === activityId
      ),
    [activityId, data?.tierIIReport.currentWorkflow?.activities]
  );

  const columns: GridColDef<ActivityLog>[] = [
    {
      field: "status",
      headerName: "Status",
      valueGetter(params) {
        return convertEnumToHumanReadable(params.row.status);
      },
    },
    {
      field: "time",
      headerName: "Logged At",
      flex: 0.5,
      renderCell: ({ row }) => (
        <Tooltip title={prettyPrintDateTime(row.time)}>
          <span>{relativeLogTime(row.time)}</span>
        </Tooltip>
      ),
    },
    {
      field: "message",
      headerName: "Message/Reason",
      flex: 1,
      cellClassName: "wordWrapCell",
    },
  ];

  return (
    <Stack
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        {activity && getActivityTitle(activity)}
      </Typography>
      <DataGrid
        sortModel={[{ field: "time", sort: "asc" }]}
        columns={columns}
        rows={activity?.logs ?? []}
        getRowHeight={() => "auto"}
      />
      <style>
        {`
        .wordWrapCell {
          white-space: normal !important;
          word-wrap: break-word !important;
        }
      `}
      </style>
    </Stack>
  );
}
