export const STATE_NAMES = <const>{
  AK: "Alaska",
  AL: "Alabama",
  AR: "Arkansas",
  AS: "American Samoa",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MP: "Northern Mariana Islands",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VI: "Virgin Islands",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
};

export const stateNamesByAbbreviation = new Map<string, string>(
  Object.entries(STATE_NAMES).map(([abbreviation, name]) => [
    abbreviation,
    name,
  ])
);

export type StateAbbreviation = keyof typeof STATE_NAMES;

export const STATES: StateAbbreviation[] = Object.keys(
  STATE_NAMES
) as StateAbbreviation[];

export const PROVINCE_TERRITORY_NAMES = <const>{
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NS: "Nova Scotia",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  NT: "Northwest Territories",
  NU: "Nunavut",
  YT: "Yukon",
};

export const EPLAN_STATES: StateAbbreviation[] = [
  "AL",
  "AK",
  "FL",
  "GA",
  "IA",
  "MS",
  "MT",
  "NY",
  "NC",
  "SC",
  "TN",
];

export const TIER_II_MANAGER_STATES = [
  "DE",
  "IL",
  "IN",
  "KY",
  "MA",
  "MD",
  "MI",
  "MN",
  "ND",
  "NV",
  "OR",
  "PA",
  "WI",
  "WV",
] as const;

export const TIER_II_SUBMIT_STATES: StateAbbreviation[] = [
  "AK",
  "AL",
  "AR",
  "CO",
  "CT",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "ME",
  "MS",
  "MT",
  "NC",
  "NH",
  "NM",
  "NY",
  "OH",
  "OK",
  "RI",
  "SC",
  "TN",
  "UT",
  "VA",
  "VI",
  "VT",
  "WY",
];

export const IDIOSYNCRATIC_STATES: StateAbbreviation[] = [
  "AZ",
  "CA",
  "KS",
  "LA",
  "MO",
  "NE",
  "NJ",
  "SD",
  "TX",
  "WA",
];

export type Tier2ManagerSearch = {
  // Normalized CAS number, no dashes
  casNumber: string;
  // A commonly used name for this chemical, like an alias;
  // I could see a case for this being an array of strings,
  // Like ["Ammonia (anhydrous)", "Ammonia (anhudrous)"]
  chemicalName: string;
  // Is this chemical an EHS?
  ehs?: boolean;
} & (
  | {
      // The old system calls this 'productId'
      chemicalId: string;
      componentPercent?: never;
    }
  | {
      chemicalId?: never;
      componentPercent: number;
    }
  | {
      chemicalId?: undefined;
      componentPercent?: undefined;
    }
);

type Tier2ManagerSearchMappings = {
  casNumber: string;
  encampChemicalNames: string[];
  ehs?: boolean;
  chemicalId?: string;
  componentPercentageLowerBoundInclusive?: number | undefined;
  componentPercentageUpperBoundInclusive?: number | undefined;

  /**
   * What we are searching for
   */
  t2mChemicalName: string;
};

/**
 * Known search strings from T2M portals, we can use this
 * to make sure our searches succeed in their portal.
 *
 * If this list grows, we should just make 2 dictionaries
 * from this; one for the CAS number and one for the chemicalName.
 */
export const TIER_II_MANAGER_SEARCH_STRINGS: Tier2ManagerSearchMappings[] = [
  {
    casNumber: "7664417",
    encampChemicalNames: [
      "Ammonia",
      "Ammonia (Conc >20%)",
      "**Ammonia",
      "Prologue", // Nutrien Ag Solutions uses this name
    ],
    t2mChemicalName: "Ammonia",
    ehs: true,
    componentPercentageLowerBoundInclusive: 0,
    componentPercentageUpperBoundInclusive: 100,
  },
  {
    casNumber: "7664417",
    encampChemicalNames: [
      "Anhydrous Ammonia",
      "Anhudrous Ammonia",
      "Pro-Iron 5",
    ],
    t2mChemicalName: "Ammonia (anhydrous)",
    ehs: true,
  },
  {
    casNumber: "1910425",
    encampChemicalNames: ["Paraquat"],
    t2mChemicalName: "Paraquat dichloride",
    ehs: true,
  },
  {
    casNumber: "2074502",
    encampChemicalNames: ["Paraquat"],
    t2mChemicalName: "Paraquat methosulfate",
    ehs: true,
  },
  {
    casNumber: "7647010",
    encampChemicalNames: [
      "Hydrochloric Acid",
      "Hydrochloric Acid Solution",
      "HCL 15%",
      "Hydrochloric Acid 15%",
      "Hydrochloric Acide",
      "HCL 10.1-15%",
    ],
    t2mChemicalName: "Hydrochloric acid",
    ehs: false,
  },
  {
    casNumber: "7647010",
    encampChemicalNames: ["Hydrogen chloride"],
    t2mChemicalName: "Hydrogen chloride (anhydrous)",
    ehs: true,
  },
  {
    casNumber: "7722841",
    encampChemicalNames: ["Hydrogen Peroxide", "Hydrogen Peroxide (h2o2)"],
    t2mChemicalName: "Hydrogen Peroxide (Conc.< 52%)",
    componentPercentageLowerBoundInclusive: 0,
    componentPercentageUpperBoundInclusive: 52,
    ehs: false,
  },
  {
    casNumber: "7722841",
    encampChemicalNames: ["Hydrogen Peroxide", "Hydrogen Peroxide (h2o2)"],
    t2mChemicalName: "Hydrogen Peroxide (Conc.> 52%)",
    componentPercentageLowerBoundInclusive: 52,
    componentPercentageUpperBoundInclusive: 100,
    ehs: true,
  },
];
