import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { ActivityStatus, ExecutionPlanType } from "generated-graphql/graphql";
import { useUpdateActivity } from "hooks/activities";
import { useCallback, useState } from "react";
import { ActivityButtonProps } from ".";

export const SetToManual = ({ activity }: ActivityButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const canSetToManual =
    activity.executionPlan !== ExecutionPlanType.Human &&
    activity?.status !== ActivityStatus.Completed;
  if (canSetToManual) {
    return (
      <>
        <LoadingButton
          variant="text"
          size="small"
          color="primary"
          loadingPosition="start"
          onClick={() => setIsDialogOpen(true)}
          startIcon={<PersonIcon />}
        >
          Set to manual
        </LoadingButton>
        <SetToManualDialog
          activityId={activity.id}
          status={activity.status}
          isOpen={isDialogOpen}
          closeDialog={() => setIsDialogOpen(false)}
        />
      </>
    );
  } else {
    return null;
  }
};

interface Props {
  activityId: string;
  status: ActivityStatus;
  closeDialog: () => void;
  isOpen: boolean;
}

const SetToManualDialog = ({
  activityId,
  status,
  isOpen,
  closeDialog,
}: Props) => {
  const theme = useTheme();
  const [updateActivity, { loading: isActivityUpdating }] = useUpdateActivity();
  const handleSetToManualButtonClick = useCallback(async () => {
    updateActivity({
      variables: {
        id: activityId,
        input: {
          executionPlan: ExecutionPlanType.Human,
        },
      },
    });
    closeDialog();
  }, [activityId, closeDialog, updateActivity]);

  return (
    <Dialog open={isOpen} sx={{ padding: theme.spacing(2) }}>
      <DialogTitle>Set Activity to Manual</DialogTitle>
      <DialogContent sx={{ minWidth: "300px" }}>
        <DialogContentText>
          Are you sure you want to set this activity to manual?
          {status === ActivityStatus.InProgress && (
            <Typography>
              In Progress portal automation will be canceled. Logout could take
              up to 10 minutes.
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <LoadingButton
          loading={isActivityUpdating}
          onClick={handleSetToManualButtonClick}
        >
          Set to manual
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
