import { PropsWithChildren, useEffect } from "react";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useNavigate } from "react-router-dom";

export function StaffOnly(props: PropsWithChildren<unknown>) {
  const { isCustomer, isStaff, user } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isCustomer) {
      navigate(`/o/${user?.UserTenant[0]?.tenantId}/facilities`);
    }
  }, [isCustomer, navigate, user?.UserTenant]);

  if (!isStaff) {
    return <></>;
  }

  return <>{props.children}</>;
}
