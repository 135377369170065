import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from "@mui/material";
import { cloneDeep } from "lodash";
import { Dialog } from "components/Dialog";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import {
  isNonReactiveSolid,
  normalizeCas,
} from "encamp-shared/src/utils/casAndEhs";
import { WeightOrVolume } from "generated-graphql/graphql";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useWatch } from "react-hook-form";
import { EhsFormLabels, EhsFormOrder } from "util/ehsForm";
import { EhsFormTooltip } from "./EhsFormTooltip";
import { ChemicalDetailInputs, ComponentInputType } from "./index";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useChemicalInputValidation } from "../../Report/validationHooks/useChemicalInputValidation";
import { hasCriticalIssues } from "util/forms";
import { v4 } from "uuid";
interface ChemicalComponentFormProps {
  chemical: ChemicalDetailInputs;
  index: number;
  onSave: (component: ComponentInputType) => void;
  onClose: () => void;
  open: boolean;
  mode: "add" | "edit";
}

const ChemicalComponentFormDialog: React.FC<ChemicalComponentFormProps> = ({
  index,
  chemical,
  onClose,
  open,
  onSave,
  mode,
}) => {
  const theme = useTheme();

  const id = useMemo(
    () => (mode === "edit" ? chemical.components[index].id : v4()),
    [mode, chemical, index]
  );

  const initialValues = useMemo(() => {
    const existingValues = cloneDeep(chemical);
    if (mode === "edit") {
      return existingValues;
    }
    return {
      ...existingValues,
      components: [
        ...(existingValues.components || []),
        {
          id,
          name: "",
          componentPercentage: "0",
          weightOrVolume: WeightOrVolume.Weight,
          casNumber: "",
          isEhs: false,
          ehsForm: EhsFormOrder[0],
        },
      ],
    };
  }, [id, mode, chemical]);

  const {
    control,
    getValues,
    issues,
    resetField,
    formState: { isDirty },
  } = useValidatingForm(
    initialValues,
    [],
    useChemicalInputValidation(mode === "add")
  );

  const watchCasNumber = useWatch({
    control: control,
    name: `components.${index}.casNumber`,
  });
  const watchIsEhs = useWatch({
    control: control,
    name: `components.${index}.isEhs`,
  });

  const handleSave = useCallback(() => {
    const component = getValues(`components.${index}`);
    onSave(component);
  }, [index, onSave, getValues]);

  const saveDisabled = useMemo(() => {
    const critIssues = hasCriticalIssues(
      issues.filter((i) => i.modelId === id)
    );
    if (mode === "add") {
      return critIssues || !isDirty;
    }
    return critIssues;
  }, [isDirty, mode, issues, id]);
  const isEhsFormDisabled = useMemo(() => {
    if (!watchCasNumber) return true;
    if (!watchIsEhs) return true;
    return !isNonReactiveSolid(normalizeCas(watchCasNumber));
  }, [watchCasNumber, watchIsEhs]);

  useEffect(() => {
    if (isEhsFormDisabled) {
      resetField(`components.${index}.ehsForm`);
    }
  }, [index, isEhsFormDisabled, resetField]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{mode == "add" ? "Add" : "Edit"} Component</DialogTitle>
      <DialogContent>
        <Box marginTop={theme.spacing(2)}>
          <FormTextField
            name={`components.${index}.name`}
            control={control}
            label="Mixture Component Name"
            textFieldProps={{ required: true }}
            sx={{ marginBottom: theme.spacing(1) }}
          />
          <FormTextField
            name={`components.${index}.componentPercentage`}
            control={control}
            label="Component Percentage"
            textFieldProps={{ required: true }}
            sx={{ marginBottom: theme.spacing(1) }}
          />
          <FormSelect
            name={`components.${index}.weightOrVolume`}
            control={control}
            label="Weight or Volume"
            selectProps={{ required: true }}
            selectItems={[
              { value: WeightOrVolume.Weight, display: "by Weight" },
              { value: WeightOrVolume.Volume, display: "by Volume" },
            ]}
            sx={{ marginBottom: theme.spacing(1) }}
          />
          <FormTextField
            name={`components.${index}.casNumber`}
            control={control}
            label="CAS Number"
            sx={{ marginBottom: theme.spacing(1) }}
          />
          <Stack flexDirection="row" sx={{ marginBottom: theme.spacing(1) }}>
            <FormSelect
              name={`components.${index}.ehsForm`}
              control={control}
              label="EHS Form"
              disabled={isEhsFormDisabled}
              selectItems={EhsFormOrder.map((ehsForm) => ({
                display: EhsFormLabels[ehsForm],
                value: ehsForm,
              }))}
            />
            {!isEhsFormDisabled && <EhsFormTooltip />}
          </Stack>
          <FormControl component="fieldset">
            <FormLabel required component="legend">
              Is this component an EHS?
            </FormLabel>
            <Controller
              name={`components.${index}.isEhs`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <RadioGroup
                    {...field}
                    onChange={(e) => field.onChange(e.target.value === "true")} // The radio button provides a string "true", so convert it to a boolean
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <ErrorDisplay error={error} />
                </>
              )}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: theme.spacing(1.5), pr: theme.spacing(1.5) }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={theme.spacing(1.5)}
        >
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <SaveButton
            loading={false}
            saveText="Save Component"
            disabled={saveDisabled}
            onClick={handleSave}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ChemicalComponentFormDialog;
