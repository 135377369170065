import { useForm, Controller, Control } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { QueryConfiguration } from "generated-graphql/graphql";
import { gql } from "generated-graphql";
import { useMutation } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useCallback } from "react";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { FloatingSaveBar } from "components/FloatingSaveBar";

const GENERATE_CANDIDATES_MUTATION = gql(`
  mutation GenerateCandidates($input: GenerateInsightsCandidatesInput!) {
    generateInsightsCandidates(input: $input)
  }
`);

const ControlledSliderField = ({
  controller,
  name,
  label,
  tooltip,
  min = 0,
  max = 10,
  defaultValue = (max + min) / 2,
  step,
}: {
  controller: Control<QueryConfiguration>;
  name: keyof QueryConfiguration;
  label: string;
  tooltip?: string;
  min?: number;
  max?: number;
  defaultValue?: number;
  step?: number;
}) => {
  return (
    <Controller
      control={controller}
      name={name}
      render={({ field }) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            display="flex"
            alignItems="center"
            variant="body2"
            style={{ width: "150px" }}
          >
            {label}{" "}
            {tooltip && (
              <Tooltip title={tooltip}>
                <HelpOutline
                  fontSize="small"
                  sx={{ ml: 1, color: "text.secondary" }}
                />
              </Tooltip>
            )}
          </Typography>
          <Slider
            {...field}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            step={step}
            value={field.value ?? defaultValue}
            style={{ flexGrow: 1 }}
          />
        </Stack>
      )}
    />
  );
};

const defaultValues: QueryConfiguration = {
  matchLimit: 10,
  minMatchScore: 0.6,
  naicsCodeWeight: 3,
  streetAddressWeight: 6,
  cityWeight: 3,
  facilityNameWeight: 3,
  tenantNameWeight: 3,
  zipWeight: 3,
  distanceWeight: 2,
};

export const GenerateCandidatesForm = ({
  tenantId,
  onClose,
}: {
  tenantId: string;
  onClose: () => void;
}) => {
  const form = useForm<QueryConfiguration>({
    defaultValues,
  });
  const alerts = useAlerts();

  const [mutate, { loading: mutationLoading }] = useMutation(
    GENERATE_CANDIDATES_MUTATION
  );

  const onSubmit = useCallback(
    (data: QueryConfiguration) => {
      mutate({
        variables: {
          input: {
            tenantId,
            queryConfiguration: {
              ...data,
              zipPrefixWeight: data.zipWeight ? data.zipWeight / 2 : 0,
            },
          },
        },
        onCompleted: () => {
          alerts.success(
            "Candidates generating, this will take a little while"
          );
          onClose();
        },
        onError: (error) => {
          alerts.error("Failed to generate candidates");
          onClose();
        },
      });
    },
    [mutate, alerts, onClose, tenantId]
  );

  return (
    <Stack direction="column" spacing={2} alignItems="stretch">
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Weighting</Typography>
        <Typography variant="body1">
          Relative to the one another, how important is each of these factors?
        </Typography>
        <ControlledSliderField
          controller={form.control}
          name="streetAddressWeight"
          label="Street Address"
          tooltip="How important is it that street addresses match?"
        />
        <ControlledSliderField
          controller={form.control}
          name="cityWeight"
          label="City"
          tooltip="How important is it that city names match?"
        />
        <ControlledSliderField
          controller={form.control}
          tooltip="How important is it that zip codes match?"
          name="zipWeight"
          label="Zip"
        />
        <ControlledSliderField
          controller={form.control}
          tooltip="How important is the distance by latitude and longitude?"
          name="distanceWeight"
          label="Distance"
        />
        <ControlledSliderField
          controller={form.control}
          tooltip="How important is the facility name?"
          name="facilityNameWeight"
          label="Facility Name"
        />
        <ControlledSliderField
          controller={form.control}
          tooltip="How important is the tenant name?"
          name="tenantNameWeight"
          label="Tenant Name"
        />
        <ControlledSliderField
          controller={form.control}
          tooltip="How important is the NAICS code?"
          name="naicsCodeWeight"
          label="NAICS Code"
        />
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Matching</Typography>
        <ControlledSliderField
          controller={form.control}
          name="matchLimit"
          label="Match Limit"
          tooltip="How many matches do we return at most?"
          min={0}
          max={25}
          defaultValue={10}
        />
        <ControlledSliderField
          controller={form.control}
          name="minMatchScore"
          label="Min Match Score"
          tooltip="What is the minimum score for a match?"
          min={0}
          max={1}
          step={0.05}
        />
      </Box>
      <FloatingSaveBar
        saveTooltipTitle="Generate Candidates"
        onCancel={onClose}
        onSaveClick={form.handleSubmit(onSubmit)}
        saving={mutationLoading}
      />
    </Stack>
  );
};
