import { Paper, Stack, Skeleton, Grid, useTheme } from "@mui/material";

export const ChemicalFormSkeleton = () => {
  const theme = useTheme();
  return (
    <Paper sx={{ paddingTop: theme.spacing(2), paddingX: theme.spacing(2) }}>
      <Stack spacing={theme.spacing(3)}>
        <Skeleton
          variant="text"
          sx={{ width: "100px", fontSize: theme.typography.h6.fontSize }}
        />
        <Skeleton
          variant="text"
          sx={{ width: "300px", fontSize: theme.typography.body1.fontSize }}
        />
        <SkeletonFormGroup />

        <Grid container spacing={theme.spacing(1)} mt={theme.spacing(2)}>
          <Grid item xs={12} sm={6}>
            <Skeleton
              variant="text"
              sx={{ width: "200px", fontSize: theme.typography.h6.fontSize }}
            />
            <Stack
              flexDirection={"column"}
              justifyContent={"flex-start"}
              sx={{ mt: theme.spacing(2) }}
            >
              <Skeleton
                variant="rectangular"
                sx={{ mb: theme.spacing(4), height: 50, width: "30%" }}
              />

              <SkeletonFormGroup count={2} itemWidth={"80%"} />
              <SkeletonFormGroup count={2} itemWidth={"80%"} />
            </Stack>
          </Grid>

          <Grid item sm={6} xs={12}>
            <SkeletonFormGroup count={2} itemWidth={"80%"} />

            <Grid container item sx={{ mt: theme.spacing(2) }}>
              <Skeleton
                variant="text"
                sx={{
                  width: "200px",
                  fontSize: theme.typography.h6.fontSize,
                  mb: theme.spacing(2),
                }}
              />
              <Skeleton variant="rectangular" height={200} width={"90%"} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Skeleton
            variant="text"
            sx={{
              width: "200px",
              fontSize: theme.typography.h6.fontSize,
              mb: theme.spacing(2),
            }}
          />
          <Skeleton variant="rectangular" height={200} width={"90%"} />
        </Grid>

        <SkeletonFormGroup />
      </Stack>
    </Paper>
  );
};

const SkeletonFormGroup = ({
  count,
  itemWidth: width,
}: {
  count?: number;
  itemWidth?: string | number;
}) => {
  const theme = useTheme();
  const items = new Array(count ?? 4).fill(1);

  return (
    <Grid container spacing={theme.spacing(1)}>
      {items.map((_, i) => (
        <Grid item xs={12} sm={6} key={i}>
          <Skeleton
            variant="rectangular"
            height={50}
            width={width ?? "90%"}
            sx={{ mb: theme.spacing(3) }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
