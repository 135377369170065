import { Skeleton, Stack, useTheme } from "@mui/material";
import { FormTextFieldSkeleton, SkeletonFormGroup } from "components/Skeleton";

// This component can change but it's a good starting point for a skeleton
export const TaskDialogSkeleton: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack
      gap={theme.spacing(3)}
      sx={{
        minWidth: "550px",
        minHeight: "600px",
        paddingTop: theme.spacing(2),
      }}
    >
      <SkeletonFormGroup count={1} />
      <FormTextFieldSkeleton kind="long" />
      <FormTextFieldSkeleton kind="short" />
      <Stack direction="row" gap={theme.spacing(1)}>
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </Stack>
      <Skeleton variant="text" width="100%" height={50} />
      <Stack direction="row" gap={theme.spacing(1)}>
        <Skeleton variant="rounded" width="100%" height={50} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </Stack>
      <SkeletonFormGroup count={3} />
    </Stack>
  );
};
