import { gql } from "generated-graphql";

export const GET_ALL_NJ_LEAD_AGENECIES = gql(/* GraphQL */ `
  query leadAgencies(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
    $tenantId: String
  ) {
    leadAgencies(
      search: $search
      page: $page
      pageSize: $pageSize
      sort: $sort
      tenantId: $tenantId
    ) {
      items {
        id
        address
        name
        attn
        city
        consolidated
        phone
        state
        zip
        email
      }
      count
    }
  }
`);

export const GET_NJ_LEAD_AGENECY = gql(/* GraphQL */ `
  query leadAgency($id: Int!) {
    leadAgency(id: $id) {
      id
      address
      name
      attn
      city
      consolidated
      phone
      state
      zip
      email
      issues {
        ...issue
      }
    }
  }
`);

export const UPSERT_LEAD_AGENCY = gql(/* GraphQL */ `
  mutation upsertLeadAgency($id: Int, $input: LeadAgencyInput!) {
    upsertLeadAgency(id: $id, input: $input) {
      id
      address
      name
      attn
      city
      consolidated
      phone
      state
      zip
      email
    }
  }
`);

export const DELETE_LEAD_AGENCY = gql(/* GraphQL */ `
  mutation deleteLeadAgency($id: Int!) {
    deleteLeadAgency(id: $id) {
      id
    }
  }
`);
