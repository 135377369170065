import { useQuery } from "@apollo/client";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { FacilityProductInput } from "generated-graphql/graphql";
import { useTenant } from "hooks/useTenant";
import { useFormContext } from "react-hook-form";
import { FACILITY_PRODUCT_PREVIOUS_YEAR_VALUES } from "routes/Customer/Chemicals/Inventory/Facility/schema";
import invariant from "tiny-invariant";

type OverallProductStorageFormProps = {
  facilityId: string;
  reportingYear: number;
};

export const OverallProductStorageForm: React.FC<
  OverallProductStorageFormProps
> = ({ facilityId }) => {
  const { tenant, loading } = useTenant();
  const theme = useTheme();
  const { control, watch } = useFormContext<FacilityProductInput>();

  const productId = watch("productId");
  const reportingYear = watch("reportingYear");

  const { data: previousYearData } = useQuery(
    FACILITY_PRODUCT_PREVIOUS_YEAR_VALUES,
    {
      variables: {
        facilityId,
        productId: productId || "",
        reportingYear: (reportingYear ?? 1) - 1,
      },
      skip: !productId || !reportingYear,
    }
  );
  const previousYearValues = previousYearData?.facilityProductByYear;

  if (loading) return <></>;

  invariant(tenant);

  return (
    <Grid item xs={6}>
      <Stack spacing={theme.spacing(2)}>
        <Typography variant="h6">Reporting Year: {reportingYear}</Typography>
        <FormTextField
          intOnly
          label="Max Quantity"
          control={control}
          name="maxQuantity"
          textFieldProps={{ required: true }}
          helperText={
            previousYearValues?.maxQuantity
              ? `Previous Year: ${previousYearValues.maxQuantity}`
              : "Chemical not present at this facility last year"
          }
        />
        <FormTextField
          intOnly
          label="Average Quantity"
          control={control}
          name="averageQuantity"
          textFieldProps={{ required: true }}
          helperText={
            previousYearValues?.averageQuantity
              ? `Previous Year: ${previousYearValues.averageQuantity}`
              : "Chemical not present at this facility last year"
          }
        />
        <FormTextField
          intOnly
          label="Days on Site"
          control={control}
          name="daysOnSite"
          textFieldProps={{ required: true }}
          helperText={
            previousYearValues?.daysOnSite
              ? `Previous Year: ${previousYearValues.daysOnSite}`
              : "Chemical not present at this facility last year"
          }
        />
      </Stack>
    </Grid>
  );
};
