import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, Divider, Menu, MenuList } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Row } from "..";
import { AssignMenuItem, BulkAssignDialog } from "./Assign";
import { ManageTags, ManageTagsDialog } from "./ManageTags";
import { RequeueMenuItem } from "./Requeue";
import { SetDefaultExecutionPlanAuto } from "./SetDefaultExecutionPlanAuto";
import { SetDefaultExecutionPlanManual } from "./SetDefaultExecutionPlanManual";
import { SetToAuto } from "./SetToAuto";
import { SetToManual } from "./SetToManual";
import { UnassignMenuItem } from "./Unassign";
import {
  VerifyAndSubmitDialog,
  VerifyAndSubmitMenuItem,
} from "./VerifyAndSubmit";
import { VerifyCredentialsMenuItem } from "./VerifyCredentials";

const buttonId = "bulk-actions-button";
const menuId = "bulk-actions-menu";

type BulkActionMenuProps = {
  selectedRows: Row[];
};

type DialogState = "assign" | "manageTags" | "verify&submit" | null;

const bulkActionContext = createContext<{
  selectedRows: Row[];
  dialogState: DialogState;
  setDialogState: Dispatch<SetStateAction<DialogState>>;
  onClose: () => void;
}>({
  selectedRows: [],
  dialogState: null,
  setDialogState: () => {},
  onClose: () => {},
});

export function useBulkActionContext() {
  return useContext(bulkActionContext);
}

const BulkActionsMenu = ({ selectedRows }: BulkActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogState, setDialogState] = useState<DialogState>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
    setDialogState(null);
  };

  return (
    <bulkActionContext.Provider
      value={{ selectedRows, onClose, dialogState, setDialogState }}
    >
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disabled={!selectedRows.length}
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMore />}
        sx={{ whiteSpace: "nowrap" }}
      >
        Bulk Actions
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{ "aria-labelledby": buttonId }}
      >
        <MenuList>
          <AssignMenuItem />
          <UnassignMenuItem />
          <Divider />
          <VerifyAndSubmitMenuItem />
          <VerifyCredentialsMenuItem />
          <Divider />
          <SetToManual />
          <SetToAuto />
          <Divider />
          <SetDefaultExecutionPlanManual />
          <SetDefaultExecutionPlanAuto />
          <RequeueMenuItem />
          <Divider />
          <ManageTags />
        </MenuList>
      </Menu>
      <BulkAssignDialog />
      <ManageTagsDialog />
      <VerifyAndSubmitDialog />
    </bulkActionContext.Provider>
  );
};

export { BulkActionsMenu };
