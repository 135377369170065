import { Box } from "@mui/material";
import { TabLink, TabLinks } from "components/TabLinks";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { Navigate, Outlet, matchPath, useLocation } from "react-router-dom";

const tabs: TabLink[] = [
  {
    title: "LEPCs",
    to: `/staff/agencies/lepcs`,
  },
  {
    title: "Fire Departments",
    to: `/staff/agencies/fireDepartments`,
  },
  {
    title: "NJ Police Departments",
    to: `/staff/agencies/njPoliceDepartments`,
  },
  {
    title: "NJ Lead Agencies",
    to: `/staff/agencies/njLeadAgencies`,
  },
];

export function Agencies() {
  const { pathname } = useLocation();
  const currentTab = tabs.find(({ to }) => matchPath(`${to}/*`, pathname));
  useBreadcrumb([{ label: "Agencies" }, { label: currentTab?.title ?? "" }]);

  // Redirect to first tab if we don't have a match
  if (!currentTab) {
    return <Navigate to={tabs[0].to} />;
  }

  return (
    <Box>
      <TabLinks
        value={currentTab.to}
        tabs={tabs}
        aria-label="Agencies tabs nav"
        role="navigation"
      />
      <Outlet />
    </Box>
  );
}
