import { Box, CircularProgress, useTheme } from "@mui/material";

type Props = {
  loading: boolean;
};

export function SpinnerOverlay(props: Props) {
  const theme = useTheme();
  return (
    <>
      {props.loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor={
            theme.palette.mode === "dark"
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(255, 255, 255, 0.3)"
          }
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
