import { useEffect } from "react";
import { useLongPollingForFile } from "../hooks/useLongPollingForFile";
import { ExportButton, ExportButtonProps } from "./ExportButton";

interface LongPollingExportButtonProps
  extends Omit<ExportButtonProps, "onClick" | "loading"> {
  fileName: string;
  tenantId: string;
}

export function LongPollingExportButton(props: LongPollingExportButtonProps) {
  const { polling, setPolling, signedUrl } = useLongPollingForFile({
    tenantId: props.tenantId,
    filename: props.fileName,
  });

  useEffect(() => {
    if (!signedUrl) return;

    // Download the file
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = signedUrl;
    document.body.appendChild(a);
    a.click();
  });

  return (
    <ExportButton
      {...props}
      onClick={() => setPolling(true)}
      loading={polling}
    />
  );
}

export function generateSpreadsheetFilename(
  prefix: string,
  type: string
): string {
  return `${prefix.replace(
    /[&]+/g,
    ""
  )}-${type}-${new Date().toISOString()}.xlsx`;
}
