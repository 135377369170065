import { useMutation, useQuery } from "@apollo/client";
import { Candidate, FieldConfig, MergePicker } from "components/MergePicker";
import { getFullName } from "encamp-shared/src/utils/name";
import { gql } from "generated-graphql";
import {
  GetCandidateContactsQuery,
  PersonInput,
  PhoneType,
} from "generated-graphql/graphql";
import { useCallback, useMemo } from "react";
import { usePersonFacilityContactsInputValidation } from "../Chemicals/Report/validationHooks/usePersonFacilityContactsInputValidation";
import { omit, uniqBy } from "lodash";
import { useTenant } from "hooks/useTenant";
import { useAlerts } from "components/Alerts/AlertProvider";

const GET_CANDIDATE_CONTACTS = gql(/* GraphQL */ `
  query GetCandidateContacts($contactIds: [ID!]!) {
    peopleByIds(ids: $contactIds) {
      id
      first
      last
      email
      title
      company
      tenantId
      streetAddress1
      streetAddress2
      city
      country
      state
      zip
      phones {
        type
        number
      }
      facilityContacts {
        id
        reportingRoles
        facility {
          id
          name
        }
      }
      issues {
        ...issue
      }
    }
  }
`);

const MERGE_CONTACTS = gql(/* GraphQL */ `
  mutation MergeContacts($input: PersonInput!, $mergedPeopleIds: [ID!]!) {
    mergePeople(input: $input, mergedPeopleIds: $mergedPeopleIds) {
      id
      first
      last
    }
  }
`);

type Contact = GetCandidateContactsQuery["peopleByIds"][0];

type ContactMergePickerProps = {
  open: boolean;
  onClose: () => void;
  contactIds: string[];
};

export function ContactMergePicker(props: ContactMergePickerProps) {
  const { tenant } = useTenant();
  const alerts = useAlerts();
  const { data, loading: loadingCandidates } = useQuery(
    GET_CANDIDATE_CONTACTS,
    {
      variables: { contactIds: props.contactIds },
    }
  );

  const [mergeContacts, { loading: isMerging }] = useMutation(MERGE_CONTACTS, {
    refetchQueries: ["Contacts"],
    onCompleted() {
      alerts.success("Contacts merged successfully");
    },
    onError(error) {
      console.error(error);
      alerts.error("An error occurred while merging the contacts");
    },
  });

  const validator = usePersonFacilityContactsInputValidation();

  const candidates = useMemo<Candidate<Contact>[]>(
    () =>
      data?.peopleByIds.map<Candidate<Contact>>((p) => ({
        id: p.id,
        associatedFacilityIds: Array.from(
          new Set(p.facilityContacts?.map((fc) => fc.facility.id))
        ),
        data: p,
        issues: p.issues ?? [],
        name: getFullName(p),
      })) ?? [],
    [data?.peopleByIds]
  );

  const fields = useMemo<FieldConfig<Contact>[]>(() => {
    const relevantPhoneTypes: { type: PhoneType; label: string }[] = [
      { type: PhoneType.Cell, label: "Phone (Cell)" },
      { type: PhoneType.Emergency, label: "Phone (Emergency)" },
      { type: PhoneType.Fax, label: "Phone (Fax)" },
      { type: PhoneType.Home, label: "Phone (Home)" },
      { type: PhoneType.Hrs_24, label: "Phone (24 Hour)" },
      { type: PhoneType.Work, label: "Phone (Work)" },
    ].filter((pt) =>
      candidates.some((c) =>
        c.data.phones?.map((p) => p.type).includes(pt.type)
      )
    );

    const phoneFields = relevantPhoneTypes.map<FieldConfig<Contact>>((pt) => ({
      name: pt.label,
      label: pt.label,
      strategy: "inclusive",
      render(data) {
        return (
          data.phones
            ?.filter((p) => p.type === pt.type)
            .map((p) => p.number)
            .join("\n") ?? ""
        );
      },
      merge(selections, merged) {
        return {
          ...merged,
          phones: uniqBy(
            [
              ...(merged.phones?.filter((p) => p.type !== pt.type) ?? []),
              ...selections.flatMap(
                (s) => s.phones?.filter((p) => p.type === pt.type) ?? []
              ),
            ],
            "number"
          ),
        };
      },
    }));

    return [
      {
        name: "name",
        label: "Name",
        strategy: "exclusive",
        primary: true,
        render(data) {
          return getFullName(data);
        },
        merge(selection, merged) {
          return {
            ...merged,
            first: selection.first,
            last: selection.last,
          };
        },
      },
      {
        name: "email",
        label: "Email",
        strategy: "exclusive",
        render(data) {
          return data.email ?? "";
        },
        merge(selection, merged) {
          return {
            ...merged,
            email: selection.email,
          };
        },
      },
      {
        name: "company",
        label: "Company",
        strategy: "exclusive",
        render(data) {
          return data.company ?? "";
        },
        merge(selection, merged) {
          return {
            ...merged,
            company: selection.company,
          };
        },
      },
      {
        name: "title",
        label: "Title",
        strategy: "exclusive",
        render(data) {
          return data.title ?? "";
        },
        merge(selection, merged) {
          return {
            ...merged,
            title: selection.title,
          };
        },
      },
      ...phoneFields,
      {
        name: "address",
        label: "Address",
        strategy: "exclusive",
        render(data) {
          if (!data.streetAddress1) return "";

          return `${data.streetAddress1},\n${
            data.streetAddress2 ? `${data.streetAddress2}\n` : ""
          }${data.city}, ${data.state}\n${data.zip}`;
        },
        merge(selection, merged) {
          return {
            ...merged,
            streetAddress1: selection.streetAddress1,
            streetAddress2: selection.streetAddress2,
            city: selection.city,
            state: selection.state,
            zip: selection.zip,
            country: selection.country,
          };
        },
      },
      {
        name: "facilityContacts",
        hidden: true,
        strategy: "all",
        merge(selections, merged) {
          return {
            ...merged,
            facilityContacts: selections.flatMap(
              (s) => s.facilityContacts ?? []
            ),
          };
        },
      },
      {
        name: "tenantId",
        hidden: true,
        strategy: "all",
        merge(_, merged) {
          return {
            ...merged,
            tenantId: tenant?.id ?? "",
          };
        },
      },
    ];
  }, [candidates, tenant?.id]);

  const handleMerge = useCallback(
    (input: PersonInput, mergedPeopleIds: string[]) => {
      return mergeContacts({
        variables: { input: omit(input, "facilityContacts"), mergedPeopleIds },
      });
    },
    [mergeContacts]
  );

  return (
    <MergePicker
      open={props.open}
      onClose={props.onClose}
      kind={"contact"}
      fields={fields}
      candidates={candidates}
      validator={async (person) =>
        validator({
          person,
        })
      }
      onMerge={handleMerge}
      loadingCandidates={loadingCandidates}
      isMerging={false}
    />
  );
}
