import { useMutation } from "@apollo/client";
import FileDownload from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";
import { SortModel } from "generated-graphql/graphql";
import { useEffect } from "react";

const EXPORT_GRID_TO_CSV = gql(`
  mutation ExportGridToCsv($search: String, $sort: [SortModel!]) {
    exportFulfillmentGridToCsv(search: $search, sort: $sort) {
      success
      errorMsg
      downloadUrl
    }
  }
`);

type ExportGridToCsvButtonProps = {
  search?: string;
  sort?: Array<SortModel>;
};

export function ExportGridToCsvButton(props: ExportGridToCsvButtonProps) {
  const alert = useAlerts();
  const [mutate, { data, loading }] = useMutation(EXPORT_GRID_TO_CSV);

  function handleClick() {
    mutate({
      variables: {
        search: props.search,
        sort: props.sort,
      },
    });
  }

  useEffect(() => {
    if (data?.exportFulfillmentGridToCsv.success) {
      alert.success("Successfully generated the export");
    } else if (data?.exportFulfillmentGridToCsv.errorMsg) {
      alert.error(
        `There was a problem generated the export: ${data.exportFulfillmentGridToCsv.errorMsg}`
      );
    }
  }, [
    alert,
    data?.exportFulfillmentGridToCsv.errorMsg,
    data?.exportFulfillmentGridToCsv.success,
  ]);

  useEffect(() => {
    if (data?.exportFulfillmentGridToCsv.downloadUrl) {
      window.open(data.exportFulfillmentGridToCsv.downloadUrl, "_blank");
    }
  }, [data?.exportFulfillmentGridToCsv.downloadUrl]);

  return (
    <LoadingButton
      variant="outlined"
      loading={loading}
      sx={{ minWidth: 0 }}
      onClick={handleClick}
    >
      <FileDownload />
    </LoadingButton>
  );
}
