import { Paper } from "@mui/material";
import { GridColDef, GridRowModel } from "@mui/x-data-grid-premium";
import { ClickableGridCell } from "../../../../components/ClickableGridCell";
import { DataGrid } from "../../../../components/DataGrid";

interface ErrorTableByTypeProps {
  errorType: string;
  data?: { name?: string | null | undefined; val: number }[];
  loading: boolean;
  onRowClick?: (params: any) => void;
}

export const ErrorTableByType: React.FC<ErrorTableByTypeProps> = ({
  errorType,
  data = [],
  loading = false,
  onRowClick = () => {},
}) => {
  const columns: GridColDef[] = [
    { field: "name", headerName: errorType, flex: 1 },
    {
      field: "val",
      headerName: "Errors",
      flex: 1,
      type: "number",
      sortable: true,
      renderCell: (params) => {
        return (
          <ClickableGridCell onClick={() => onRowClick(params.row)}>
            {params.value}
          </ClickableGridCell>
        );
      },
    },
  ];

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        loading={loading}
        rows={data}
        getRowId={(row) => row.name ?? ""}
        columns={columns}
        autoPageSize
        pagination
      />
    </Paper>
  );
};
