import { Tooltip, Typography, TypographyTypeMap } from "@mui/material";
import React from "react";

type TruncateTypographyProps = {
  lines?: number;
  showTooltip?: boolean;
} & React.PropsWithChildren<any> &
  TypographyTypeMap;

export const TruncateTypography = ({
  lines = 1,
  children,
  showTooltip = false,
  ...typographyProps
}: TruncateTypographyProps) => {
  return (
    <Typography
      {...typographyProps}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: lines.toString(),
        WebkitBoxOrient: "vertical",
      }}
    >
      {showTooltip ? (
        <Tooltip title={children}>
          <span>{children}</span>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </Typography>
  );
};
