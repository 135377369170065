import Grid from "@mui/material/Grid";
import { useMemo } from "react";
import {
  ActivityStatus,
  ActivityType,
  ExecutionPlanType,
  ReportingSeasonSummaryQuery,
  TierIiReportEncampStatus,
} from "../../../../../generated-graphql/graphql";
import { AssignmentManagementTableFilterKeys } from "../../AssignmentManagerTable/useAssignmentManagerOmnisearch";
import { StatCard } from "../StatCard";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";

function daysUntilMarchFirst(): number {
  const now = new Date();
  let nextMarch = new Date(now.getFullYear(), 2, 1);

  // If the current date is after March 1, calculate days until March 1 next year
  if (now > nextMarch) {
    nextMarch = new Date(now.getFullYear() + 1, 2, 1);
  }

  const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
  const daysLeft = Math.ceil((nextMarch.getTime() - now.getTime()) / oneDay);

  return daysLeft;
}

type Props = {
  data?: ReportingSeasonSummaryQuery["reportingSeasonSummary"];
  loading: boolean;
};

export function StatsGrid({
  data = {
    noReportsTotal: 0,
    organizationSummaries: [],
    staffSummaries: [],
    year: 2023,
    facilitiesTotal: 0,
    reportsInFilingComplete: 0,
    reportsInAwaitingVerification: 0,
    reportsInNotReporting: 0,
    reportsInProcessing: 0,
    reportsUnverified: 0,
    reportsTotal: 0,
    workflowsCountHuman: 0,
    workflowsCountError: 0,
    workflowsCountWaitForAll: 0,
    stateSummaries: [],
  },
  loading,
}: Props) {
  const { createUrlFromOmnisearchKeyValues } = useOmnisearchDatagrid();

  const reportsRemaining = data.reportsTotal - data.reportsInFilingComplete;

  const stats = useMemo(
    () => [
      {
        title: "March to March",
        subText: "days remaining",
        number: daysUntilMarchFirst(),
      },
      {
        title: "Reports Remaining",
        number: reportsRemaining,
        subTextInTooltip: true,
        subText: `Reports remaining = (reporting facilities) - (filing complete)`,
      },
      { title: "Total Facilities", number: data.facilitiesTotal },
      { title: "Reporting Facilities", number: data.reportsTotal },
      {
        title: "Unverified Reports",
        number: data.reportsUnverified,
        filter: [AssignmentManagementTableFilterKeys.Unverified, "true"],
        subTextInTooltip: true,
        subText: `Unverified = (awaiting verification) + (no report)`,
      },
      {
        title: "Processing (Verified)",
        number: data.reportsInProcessing,
        subTextInTooltip: true,
        subText:
          "encampStatus=Processing always overlaps with organizationStatus=Verified",
        filter: [
          AssignmentManagementTableFilterKeys.EncampStatus,
          TierIiReportEncampStatus.Processing,
        ],
      },
      {
        title: "In Manual",
        number: data.workflowsCountHuman,
        filter: [
          AssignmentManagementTableFilterKeys.ExecutionPlan,
          ExecutionPlanType.Human,
        ],
        subTextInTooltip: true,
        subText:
          "Workfow Status=Manual. Count excludes encampStatus=FILING_COMPLETE",
      },
      {
        title: "In Error",
        number: data.workflowsCountError,
        filter: [
          AssignmentManagementTableFilterKeys.ActivityStatus,
          ActivityStatus.Error,
        ],
      },
      {
        title: "Wait for All",
        number: data.workflowsCountWaitForAll,
        filter: [
          AssignmentManagementTableFilterKeys.ActivityType,
          `${ActivityType.WaitForAllStateReports}|${ActivityType.WaitForAllCredentialReports}|${ActivityType.WaitForAllCnReports}|${ActivityType.WaitForAllAltIdReports}|${ActivityType.WaitForAllLepc}|${ActivityType.WaitForAllFd}`,
        ],
        subTextInTooltip: true,
        subText:
          "Activity.type=WaitForAll*. Count excludes encampStatus=FILING_COMPLETE",
      },
      {
        title: "Filing Complete",
        number: data.reportsInFilingComplete,
        filter: [
          AssignmentManagementTableFilterKeys.EncampStatus,
          TierIiReportEncampStatus.FilingComplete,
        ],
      },
    ],
    [
      reportsRemaining,
      data.facilitiesTotal,
      data.reportsTotal,
      data.reportsUnverified,
      data.reportsInProcessing,
      data.workflowsCountHuman,
      data.workflowsCountError,
      data.workflowsCountWaitForAll,
      data.reportsInFilingComplete,
    ]
  );

  return (
    <Grid container spacing={2}>
      {stats.map((stat, i) => (
        <Grid item key={i} xs={12} md={3} lg={2}>
          <StatCard
            title={stat.title}
            subText={stat.subText}
            subTextInTooltip={stat.subTextInTooltip}
            number={stat.number}
            loading={loading}
            href={
              stat.filter
                ? createUrlFromOmnisearchKeyValues([
                    stat.filter as [string, string],
                  ])
                : undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}
