import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { ProductChemicalInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformProductChemical";
import { useCallback } from "react";

const VALIDATE_PRODUCT_CHEMICAL_INPUT = gql(`
  query ValidateProductChemicalInput($input: ProductChemicalInput!) {
    productChemicalInputValidator(input: $input) {
      ...issue
    }
  }
`);

export function useProductChemicalInputValidation() {
  const [query] = useLazyQuery(VALIDATE_PRODUCT_CHEMICAL_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: ProductChemicalInput) => {
      const { data } = await query({
        variables: { input: transform(input) },
      });
      return data?.productChemicalInputValidator ?? [];
    },
    [query]
  );
}
