const formatCurrency = (amount?: number): string => {
  if (!amount) {
    return "$0";
  }
  const abbreviations = [
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
    { value: 1, symbol: "" },
  ];

  for (const { value, symbol } of abbreviations) {
    if (amount >= value) {
      let formattedAmount = (amount / value).toFixed(2);

      // Ensure we have no more than 3 numeric characters
      if (parseFloat(formattedAmount) >= 100) {
        formattedAmount = Math.round(parseFloat(formattedAmount)).toString();
      } else if (parseFloat(formattedAmount) >= 10) {
        formattedAmount = parseFloat(formattedAmount).toFixed(1);
      }

      // Remove trailing zeros and decimal point if necessary

      // This handles cases like 1.00
      formattedAmount = formattedAmount.replace(/\.0+$/, "");
      // This handles cases like 1.20
      if (formattedAmount.includes(".")) {
        formattedAmount = formattedAmount.replace(/0+$/, "");
      }

      return `$${formattedAmount}${symbol ? " " + symbol : ""}`;
    }
  }
  // Fallback
  return "$0";
};

export default formatCurrency;
