import { Box, Button, FormControl, Stack, useTheme } from "@mui/material";
import {
  FacilityProductStorageLocationInput,
  InsideOutside,
  StorageQuadrant,
} from "generated-graphql/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { isNilOrEmpty } from "../../../../../util";
import { FormTextField } from "../../../../../components/Forms/FormTextField";
import { StorageLocationPicker } from "../../../../../components/StorageLocationPicker";
import { SaveButton } from "../../../../../components/SaveButton";
import { FormSelect } from "../../../../../components/Forms/FormSelect";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { useReport } from "routes/Customer/Chemicals/Report/useReport";
import { useFacility } from "routes/Customer/Facility/useFacility";
import { IssueListButton } from "../../../../../components/Forms/IssueListButton";
import { v4 as uuidv4 } from "uuid";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useFacilityProductStorageLocationInputValidation } from "routes/Customer/Chemicals/Report/validationHooks/useFacilityProductStorageLocationInputValidation";

type FacilityProductStorageLocationFormProps = {
  storageLocation: FacilityProductStorageLocationInput | null;
  onSave: (storageLocation: FacilityProductStorageLocationInput) => void;
  handleClose: () => void;
  facilityId: string;
  isEditMode: boolean;
};

export const FacilityProductStorageLocationForm: React.FC<
  FacilityProductStorageLocationFormProps
> = ({ onSave, storageLocation, handleClose, facilityId, isEditMode }) => {
  const theme = useTheme();
  const { data: reportData } = useReport();
  const { data: facilityData } = useFacility();

  const facilityState =
    facilityData?.facility?.state ?? reportData?.tierIIReport.facility.state;

  const isOregonFacility = facilityState === "OR";
  const isCaliforniaFacility = facilityState === "CA";

  const defaultValues: FacilityProductStorageLocationInput = useMemo(
    () => ({
      id: storageLocation?.id ?? uuidv4(),
      maxQuantity: storageLocation?.maxQuantity ?? 0,
      storageLocation: storageLocation?.storageLocation ?? {
        description: "",
        facilityId,
        id: "",
        OR_insideOutside: null,
        OR_storageArea: "",
        OR_storageBuilding: "",
        OR_storageQuadrant: null,
        OR_storageFloor: "",
        OR_storageRoom: "",
      },
    }),
    [storageLocation, facilityId]
  );

  const form = useValidatingForm<FacilityProductStorageLocationInput>(
    defaultValues,
    [],
    useFacilityProductStorageLocationInputValidation(facilityId)
  );

  const {
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { isSubmitting },
    trigger,
    issues,
  } = form;

  useEffect(() => {
    // Since we're not in the context of a real react-hook-form, we need to manually trigger the validation when the form opens
    if (isEditMode) {
      trigger();
    }
  }, [trigger, isEditMode]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const description = watch("storageLocation.description");
  const maxQuantity = watch("maxQuantity");

  const onSubmit = useCallback(
    (input: FacilityProductStorageLocationInput) => onSave(input),
    [onSave]
  );

  return (
    <Box>
      <Stack direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box paddingX={3} paddingTop={2}>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="storageLocation.description"
                render={({ field, fieldState }) => (
                  <StorageLocationPicker
                    {...fieldState}
                    facilityId={facilityId}
                    description={field.value}
                    required
                    onSelectLocation={(location) => {
                      if (location == null) return;
                      setValue(
                        "storageLocation",
                        {
                          ...location,
                          description: location.description ?? "",
                        },
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          {isOregonFacility && (
            <>
              <Box paddingX={3} paddingTop={2}>
                <FormSelect
                  name="storageLocation.OR_insideOutside"
                  label="Inside/Outside *"
                  selectItems={Object.values(InsideOutside).map(
                    (insideOutside) => ({
                      display: prettyPrintEnumValue(insideOutside),
                      value: insideOutside,
                    })
                  )}
                  control={control}
                  // disabled={disabled}
                />
              </Box>
              <Box paddingX={3} paddingTop={2}>
                <FormTextField
                  label="Storage Building *"
                  name="storageLocation.OR_storageBuilding"
                  control={control}
                  // disabled={disabled}
                />
              </Box>
              <Box paddingX={3} paddingTop={2}>
                <FormTextField
                  label="Storage Floor"
                  name="storageLocation.OR_storageFloor"
                  control={control}
                  // disabled={disabled}
                />
              </Box>
              <Box paddingX={3} paddingTop={2}>
                <FormSelect
                  name="storageLocation.OR_storageQuadrant"
                  label="Storage Quadrant *"
                  selectItems={Object.values(StorageQuadrant).map(
                    (storageQuadrant) => ({
                      display: prettyPrintEnumValue(storageQuadrant),
                      value: storageQuadrant,
                    })
                  )}
                  control={control}
                  // disabled={disabled}
                />
              </Box>
              <Box paddingX={3} paddingTop={2}>
                <FormTextField
                  label="Storage Room"
                  name="storageLocation.OR_storageRoom"
                  control={control}
                  // disabled={disabled}
                />
              </Box>
              <Box paddingX={3} paddingTop={2}>
                <FormTextField
                  label="Storage Area *"
                  name="storageLocation.OR_storageArea"
                  control={control}
                  // disabled={disabled}
                />
              </Box>
            </>
          )}
          {isCaliforniaFacility && (
            <>
              <Box paddingX={3} paddingTop={2}>
                <FormTextField
                  label="Grid Number"
                  name="storageLocation.CA_gridNumber"
                  control={control}
                  // disabled={disabled}
                />
              </Box>
              <Box paddingX={3} paddingTop={2}>
                <FormTextField
                  label="Map Number"
                  name="storageLocation.CA_mapNumber"
                  control={control}
                  // disabled={disabled}
                />
              </Box>
            </>
          )}
          <Box paddingX={3} paddingTop={2}>
            <FormTextField
              intOnly
              label="Max Quantity"
              control={control}
              name="maxQuantity"
            />
          </Box>
          <Stack padding={1.5} direction="row" justifyContent="flex-end">
            <IssueListButton issues={issues} />
            <Box paddingRight={theme.spacing(1)}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
            <Box>
              <SaveButton
                loading={isSubmitting}
                disabled={
                  isNilOrEmpty(description) ||
                  Number.isNaN(maxQuantity) ||
                  maxQuantity === 0
                  // false
                }
              />
            </Box>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
