import { useQuery } from "@apollo/client";
import { Button, Collapse, Grid, Stack } from "@mui/material";
import { usePrevNextNavigationContext } from "components/PrevNextNavigation";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { useCallback, useEffect } from "react";
import { Outlet, useMatch } from "react-router-dom";
import { gql } from "../../../generated-graphql";
import { useBreadcrumb } from "../../../hooks/useBreadcrumbs";
import { ErrorTableByType } from "./ErrorTableByType";
import { ReportErrorTable } from "./ReportErrorTable";
import {
  ReportErrorTableFilterKeys,
  useReportErrorTableOmnisearch,
} from "./ReportErrorTable/useReportErrorTableOmnisearch";
import { useLocalStorageState } from "hooks/useLocalStorageState";

const statsQuery = gql(`
  query SubmissionErrorStats($year: Int!) {
    submissionErrorStats(year: $year) {
      errorsByAssignee {
        name
        val
      }
      errorsByCustomer {
        name
        val
      }
      errorsByState {
        name
        val
      }
      reportsCurrentlyBlockedByErrors
    }
  }
`);

export function SubmissionErrorsDashboard() {
  const match = useMatch("/staff/submission-errors");
  const { updateOmnisearch } = useReportErrorTableOmnisearch();
  const { setNavigationKey } = usePrevNextNavigationContext();
  useEffect(() => {
    // Set the navigation key when the component mounts
    setNavigationKey("/staff/submission-errors");

    // Reset the navigation key when the component unmounts
    return () => {
      setNavigationKey("");
    };
  }, [setNavigationKey]);

  const [statsOpen, setStatsOpen] = useLocalStorageState<boolean>(
    "errorStatsOpen",
    false
  );

  const handleToggle = useCallback(() => {
    setStatsOpen((prevState) => !prevState);
  }, [setStatsOpen]);

  useBreadcrumb({
    label: "Submission Errors",
    to: `/staff/submission-errors`,
  });

  const { data, error, loading } = useQuery(statsQuery, {
    fetchPolicy: "network-only",
    variables: {
      year: currentTierIIReportingYear,
    },
  });

  if (!match) {
    return <Outlet />;
  }

  if (error) {
    throw error;
  }

  return (
    <Stack p={3}>
      <Grid container>
        <Grid item xs={3}>
          <Button onClick={handleToggle}>
            {statsOpen ? "Hide Stats" : "Expand Stats"}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={statsOpen}>
            <Grid container spacing={2} sx={{ marginTop: 0 }}>
              <Grid item xs={12} md={6} lg={3} sx={{ height: "400px" }}>
                <ErrorTableByType
                  errorType="Assignee"
                  data={data?.submissionErrorStats.errorsByAssignee}
                  loading={loading}
                  onRowClick={(row) => {
                    updateOmnisearch(
                      [[ReportErrorTableFilterKeys.Assignee, row.name]],
                      true
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} sx={{ height: "400px" }}>
                <ErrorTableByType
                  errorType="Customer"
                  data={data?.submissionErrorStats.errorsByCustomer}
                  loading={loading}
                  onRowClick={(row) => {
                    updateOmnisearch(
                      [[ReportErrorTableFilterKeys.Organization, row.name]],
                      true
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} sx={{ height: "400px" }}>
                <ErrorTableByType
                  errorType="State"
                  data={data?.submissionErrorStats.errorsByState}
                  loading={loading}
                  onRowClick={(row) => {
                    updateOmnisearch(
                      [[ReportErrorTableFilterKeys.State, row.name]],
                      true
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <ReportErrorTable />
        </Grid>
      </Grid>
    </Stack>
  );
}
