import { Skeleton } from "@mui/material";
import { useQueryParams } from "hooks/useQueryParams";
import { useParams } from "react-router-dom";
import { FacilityProductMeasurementTable } from "../../FacilityProductMeasurementTable";
import { HasFeature } from "components/HasFeature";

export function FacilityProductMeasurements() {
  const { facilityId, productId, reportId } = useParams<{
    facilityId: string;
    productId: string;
    reportId: string;
  }>();
  const {
    filters: { ry },
  } = useQueryParams<{ ry: string }>();

  if (!facilityId || !productId || !ry) {
    return <Skeleton />;
  }

  return (
    <HasFeature
      products
      and
      measurements
      fallback="../chemicals"
      facilityId={facilityId}
    >
      <FacilityProductMeasurementTable
        facilityId={facilityId}
        productId={productId}
        reportingYear={Number(ry)}
        reportId={reportId}
      />
    </HasFeature>
  );
}
