import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import { Box } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid } from "components/DataGrid";
import {
  GetReportDetailsQuery,
  PersonPickerFragment,
  PhoneType,
} from "generated-graphql/graphql";
import { useReviewerRemove } from "hooks/useAssignees";
import { useCallback, useMemo, useState } from "react";
import { formatPhoneNumber } from "util/phoneNumbers";
import { NoRowsOverlay } from "../../Inventory/Facility/NoRowsOverlay";
import { Reviewer } from "./ReviewForm";

export function ReviewersList({
  report = undefined,
  canDelete,
}: {
  report?: GetReportDetailsQuery["tierIIReport"];
  canDelete: boolean;
}) {
  const alerts = useAlerts();
  const [reviewerToDelete, setReviewerToDelete] =
    useState<PersonPickerFragment | null>(null);
  const reviewers = report?.reviewers ?? [];

  const [removeTierIIReportReviewers, { loading: removingReviewer }] =
    useReviewerRemove();

  const handleRemoveReviewer = useCallback(
    async (person: PersonPickerFragment | null) => {
      try {
        if (!report || !person) {
          setReviewerToDelete(null);
          return;
        }
        await removeTierIIReportReviewers({
          variables: { tierIIReportIds: [report.id], personIds: [person.id] },
        });
        alerts.success("Successfully removed reviewer");
      } catch (error) {
        alerts.error("There was a problem removing the reviewer");
      }
      setReviewerToDelete(null);
    },
    [report, removeTierIIReportReviewers, setReviewerToDelete, alerts]
  );

  const columns: GridColDef<Reviewer>[] = useMemo(
    () => [
      {
        field: "isComplete",
        headerName: "Review complete?",
        maxWidth: 180,
        headerAlign: "center",
        flex: 1,
        align: "center",
        renderCell: ({ row }) => {
          return row.isComplete && <Check color="success" fontSize="small" />;
        },
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        valueGetter: ({ row }) =>
          `${row.person.first ?? ""} ${row.person.last ?? ""}`.trim(),
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1.25,
        valueGetter: ({ row }) => {
          const phone =
            row.person.phones?.find((p) => p.type === PhoneType.Work) ??
            row.person.phones?.[0];
          return phone ? formatPhoneNumber(phone) : "";
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1.5,
        valueGetter: ({ row }) => row.person.email,
      },
      {
        field: "actions",
        type: "actions",
        cellClassName: "actions",
        flex: 0.5,
        renderCell: ({ row }) => (
          <Box display="flex" height="100%" alignItems="center">
            {canDelete && (
              <GridActionsCellItem
                key="delete"
                label="Delete"
                icon={<Clear />}
                onClick={() => setReviewerToDelete(row.person)}
              />
            )}
          </Box>
        ),
      },
    ],
    [canDelete]
  );

  return (
    <>
      <DataGrid
        disableRowSelectionOnClick={true}
        columns={columns}
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "desc" }] },
        }}
        rows={reviewers}
        hideFooter
        slots={{
          noRowsOverlay: (props) => (
            <NoRowsOverlay
              {...props}
              sx={{ maxWidth: 600 }}
              message="If your organization requires other individuals to review this report, you can add them here. They'll be emailed when you mark the report as ready for review."
            />
          ),
        }}
        autoHeight
        getRowHeight={() => "auto"}
        sx={{
          "& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within":
            { outline: "none" },
          "& .MuiDataGrid-row:hover": { cursor: "pointer" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:not(.actions)":
            { py: "9px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "4px",
            alignItems: "start",
          },
        }}
      />
      <ConfirmDialog
        open={!!reviewerToDelete}
        title="Remove Reviewer"
        msg={`Remove ${reviewerToDelete?.first} ${reviewerToDelete?.last} as a reviewer?`}
        onClose={() => setReviewerToDelete(null)}
        onConfirm={() => handleRemoveReviewer(reviewerToDelete)}
        loading={removingReviewer}
      />
    </>
  );
}
