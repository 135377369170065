import { useQuery } from "@apollo/client";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { gql } from "generated-graphql";
import React, { forwardRef, useMemo, useState } from "react";
import { ControllerFieldState } from "react-hook-form";
import { ErrorDisplay } from "./Forms/ErrorDisplay";

type Props = {
  onChange: (municipality: string | null) => void;
  value?: string | null;
  facilityState?: string | null;
  facilityCounty?: string | null;
  disabled?: boolean;
} & ControllerFieldState;

const MUNICIPALITIES_QUERY = gql(`
  query Municipalities($state: String, $county: String) {
    municipalities(state: $state, county: $county) {
      items {
        id
        name
      }
      count
    }
  }
`);

export const MunicipalityPicker: React.FC<Props> = forwardRef(
  function MunicipalityPicker(
    { onChange, value, facilityState, facilityCounty, error, disabled },
    ref
  ) {
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const theme = useTheme();

    const { data, loading, previousData } = useQuery(MUNICIPALITIES_QUERY, {
      variables: {
        state: facilityState,
        county: facilityCounty,
      },
      skip: !searchIsOpen,
      fetchPolicy: "cache-and-network",
    });

    const options = useMemo(
      () =>
        data?.municipalities?.items ??
        previousData?.municipalities?.items ??
        [],
      [data?.municipalities?.items, previousData?.municipalities?.items]
    );

    // Determine the object that corresponds to the value string
    const autocompleteValue =
      options.find((option) => option.name === value) ?? value
        ? {
            id: "",
            name: value ?? "",
          }
        : null;

    return (
      <>
        <Autocomplete
          ref={ref}
          options={options}
          isOptionEqualToValue={(o, v) => o.name === v.name}
          noOptionsText="No Municipalities found"
          onOpen={() => setSearchIsOpen(true)}
          onClose={() => setSearchIsOpen(false)}
          value={autocompleteValue}
          loading={loading}
          onChange={(_, val) => {
            onChange(val?.name ?? null);
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          disabled={disabled}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label="Municipality"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
              <ErrorDisplay error={error} />
            </>
          )}
        />
      </>
    );
  }
);
