import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { gql } from "generated-graphql";
import pluralize from "pluralize";
import { useCallback } from "react";
import { useBulkActionContext } from "../Facilities/FacilitiesBulkActionMenu";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "components/DataGrid";

const COPY_CHEMICAL_INVENTORIES = gql(/* GraphQL */ `
  mutation CopyChemicalInventoryForAllFacilitiesInTenant(
    $facilityIds: [ID!]!
    $toYear: Int!
  ) {
    copyFacilityInventoryForFacilities(
      facilityIds: $facilityIds
      toYear: $toYear
    ) {
      succeeded {
        facilityId
      }
      skipped {
        facilityId
        reason
      }
      failed {
        facilityId
        reason
      }
    }
  }
`);

export function CopyFacilityInventoriesModal() {
  const {
    selectedRows: selectedFacilities,
    dialogState,
    onClose: handleClose,
  } = useBulkActionContext();

  const alerts = useAlerts();
  const theme = useTheme();

  const closeDialog = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const [copyInventories, { loading }] = useMutation(COPY_CHEMICAL_INVENTORIES);

  const currentYear = new Date().getFullYear();
  const onSubmit = useCallback(async () => {
    try {
      const mutationResult = await copyInventories({
        variables: {
          facilityIds: selectedFacilities.map((f) => f.id),
          toYear: currentYear,
        },
      });

      const results = mutationResult.data?.copyFacilityInventoryForFacilities;
      const succeeded = results?.succeeded ?? [];
      const skipped = results?.skipped ?? [];
      const failed = results?.failed ?? [];

      if (succeeded.length > 0 || skipped.length > 0) {
        alerts.success(
          `Successfully copied ${succeeded.length} facility ${pluralize(
            "inventory",
            succeeded.length
          )}. ${skipped.length} skipped.`
        );

        handleClose();
        return;
      }

      alerts.error(
        `Error copying facility inventories. ${failed
          .map((f) => f.reason)
          .join(", ")}`
      );
    } catch (err) {
      alerts.error(`Error saving facilities:`, err);
    }
  }, [alerts, selectedFacilities, copyInventories, handleClose, currentYear]);

  return (
    <Dialog
      open={dialogState?.action === "copyInventory"}
      onClose={closeDialog}
    >
      <Box>
        <DialogTitle>Copy Chemical Inventories</DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle1"
            mb={2}
            sx={{ color: theme.palette.text.secondary }}
          >
            The most recent chemical and product{" "}
            {pluralize("inventory", selectedFacilities.length)} will be copied
            to {currentYear} for the following {selectedFacilities.length}{" "}
            {pluralize("facility", selectedFacilities.length)}:
          </Typography>
          <DataGrid
            rows={selectedFacilities}
            columns={[{ field: "name", headerName: "Facility Name", flex: 1 }]}
          />
          <Typography mt={2} fontWeight={theme.typography.fontWeightMedium}>
            Are you sure that you'd like to make this change?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={onSubmit}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
