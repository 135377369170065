import { Phone, PhoneType, ReportType } from "../generated-graphql";

export type PhoneNumber = {
  phoneNumber: string;
  extension?: string;
};

/*
 * regex adapted from https://stackoverflow.com/a/16702965
 * Breaks up variously formatted phone numbers into its components
 * optional country code (ignored), area code, exchange #, subscriber #, optional extention #
 * (i.e. 18005551234, 1-800-555-1234, 800 555 1234 ext5678, +1(800) 555-1234 ext. 5678, etc...)
 * returns the phone number formatted with dashes and the extension
 */
export function getNumberWithExtension(phoneNumber: string): PhoneNumber {
  let match = phoneNumber.match(
    /^\s*(?:\+?(?<country>\d{1,3}))?[-. (]*(?<area>\d{3})[-. )]*(?<exchange>\d{3})[-. ]*(?<subscriber>\d{4})(?: *(?:(([e|E]xt[.]?)|x)[ ]?)?(?<extension>\d+))?\s*$/
  );

  if (!match || !match.groups) {
    return {
      phoneNumber,
    };
  }

  const num = match.groups.country
    ? `+${match.groups.country} ${match.groups.area}-${match.groups.exchange}-${match.groups.subscriber}`
    : `${match.groups.area}-${match.groups.exchange}-${match.groups.subscriber}`;

  return {
    phoneNumber: num,
    extension: match.groups?.extension,
  };
}

export function getRcraPhoneData(phones: Phone[]): {
  phoneNumber: string | undefined;
  extension: string | undefined;
  fax: string | undefined;
} {
  let phoneNumber: string | undefined;
  let extension: string | undefined;
  let fax: string | undefined;

  for (var phone of phones) {
    if (!phone.reportTypes?.includes(ReportType.RCRA)) continue;

    if (!phone.number) continue;

    if (phone.type?.valueOf() === PhoneType.FAX.valueOf()) {
      fax = phone.number;
      continue;
    }

    if (!phoneNumber) {
      const number = getNumberWithExtension(phone.number);
      phoneNumber = number.phoneNumber;
      extension = number.extension;
    }
  }

  return { phoneNumber, extension, fax };
}
