import RestartAlt from "@mui/icons-material/RestartAlt";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useRequeueActivities } from "hooks/activities/requeue";
import { compact } from "lodash";
import { useCallback } from "react";
import { useBulkActionContext } from ".";

export function RequeueMenuItem() {
  const { selectedRows, onClose } = useBulkActionContext();
  const [requeueMutation] = useRequeueActivities();
  const alerts = useAlerts();

  const requeue = useCallback(async () => {
    const activityIds = compact(selectedRows.map((row) => row.activityId));
    const res = await requeueMutation({ variables: { activityIds } });
    if (activityIds.length === res.data?.requeueActivities?.updatedCount) {
      alerts.success("Successfully requeued activities!");
    } else if (res.data?.requeueActivities?.updatedCount === 0) {
      alerts.error("No activities were requeued.");
    } else {
      alerts.info(
        "Some activities were requeued, but some failed. Refresh to investigate"
      );
    }
    onClose();
  }, [onClose, requeueMutation, selectedRows, alerts]);

  return (
    <MenuItem onClick={requeue}>
      <ListItemIcon>
        <RestartAlt />
      </ListItemIcon>
      <ListItemText>Requeue</ListItemText>
    </MenuItem>
  );
}
