import { useMutation } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { ExportFileType } from "components/ExportButton";
import { IssueCount } from "components/IssueCount";
import {
  generateSpreadsheetFilename,
  LongPollingExportButton,
} from "components/LongPollingExportButton";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  DocumentType,
  PureOrMixture,
  TenantCatalogChemicalFragment,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useCurrentUser } from "hooks/useCurrentUser";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getChemicalsBreadcrumb,
  getChemicalsCatalogBreadcrumb,
} from "util/breadcrumb";
import { formatChemicalName } from "util/chemicalName";
import { AddChemicalButton } from "./AddChemicalButton";
import { ChemicalCatalogBulkActions } from "./ChemicalCatalogBulkActions";
import { StaffBulkActionsMenu } from "./StaffBulkActionsMenu";
import { DELETE_CHEMICAL, TENANT_CATALOG_CHEMICALS } from "./schema";

type Row = TenantCatalogChemicalFragment;

export const TenantChemicalsTable: React.FC = () => {
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { tenant } = useTenant();

  const alerts = useAlerts();
  const { isStaff } = useCurrentUser();
  const [selectedChemicalIds, setSelectedChemicalIds] = useState<Row["id"][]>(
    []
  );
  const [selectedChemical, setSelectedChemical] = useState<Row | undefined>(
    undefined
  );
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Row[]>([]);

  useBreadcrumb([
    {
      label: "Chemicals",
      to: tenantId ? getChemicalsBreadcrumb(tenantId) : undefined,
    },
    {
      label: "Chemical Catalog",
      to: tenantId ? getChemicalsCatalogBreadcrumb(tenantId) : undefined,
    },
  ]);

  const [deleteChemical, { loading: deleting }] = useMutation(DELETE_CHEMICAL);

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteConfirmOpen(false);
    setSelectedChemical(undefined);
  }, []);

  const handleConfirmDeletion = useCallback(async () => {
    try {
      if (selectedChemical) {
        await deleteChemical({
          variables: {
            id: selectedChemical.id,
          },
          refetchQueries: ["TenantCatalogChemicals", "CatalogOverview"],
        });
        alerts.success("Successfully deleted chemical");
        handleCloseDeleteDialog();
      }
    } catch (err) {
      alerts.error("An error occurred while deleting the measurement", err);
    }
  }, [alerts, deleteChemical, selectedChemical, handleCloseDeleteDialog]);

  const handleOpenDeleteDialog = useCallback((chemical?: Row) => {
    setSelectedChemical(chemical);
    setDeleteConfirmOpen(true);
  }, []);

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Chemical Name",
        flex: 0.5,
        renderCell: ({ row }) => (
          <Tooltip title={formatChemicalName(row)}>
            <span>{row.name}</span>
          </Tooltip>
        ),
      },
      {
        field: "casNumber",
        headerName: "CAS Number",
        flex: 0.25,
      },
      {
        field: "alternateId",
        headerName: "Alternate ID",
        flex: 0.25,
      },
      {
        field: "EHS",
        headerName: "EHS",
        renderCell(params) {
          const isEhs = params.row.isEhs;
          if (!isEhs) return <></>;
          return <Check />;
        },
        filterKeyType: "boolean",
      },
      {
        field: "mixture",
        headerName: "Mixture",
        renderCell(params) {
          const pureOrMixture = params.row.pureOrMixture;
          if (pureOrMixture === PureOrMixture.Mixture) return <Check />;
          return <></>;
        },
        filterKeyType: "boolean",
      },
      {
        field: "SDS",
        headerName: "SDS",
        sortable: false,
        renderCell(params) {
          const hasSDS = params.row.documents
            ?.map((d) => d.document.documentType)
            .some((t) => t === DocumentType.SafetyDataSheet);
          return hasSDS ? <Check /> : <></>;
        },
        filterKeyType: "boolean",
      },
      {
        field: "needsReview",
        headerName: "Needs Review?",
        width: 110,
        renderCell: (params) => (params.row.needsReview ? <Check /> : <></>),
        filterKeyType: "boolean",
      },
      {
        field: "issueCount",
        headerName: "Issues",
        align: "center",
        headerAlign: "center",
        renderCell: ({ row: { issueCount } }) => (
          <IssueCount issueCount={issueCount} />
        ),
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        getActions: ({ row }) => [
          <Tooltip title="Edit" key={1}>
            <GridActionsCellItem
              onClick={() => navigate(`./${row.id}`)}
              label="Edit Chemical"
              icon={<Edit />}
            />
          </Tooltip>,
          <Tooltip title="Delete" key={2}>
            <GridActionsCellItem
              onClick={() => handleOpenDeleteDialog(row)}
              label="Delete Chemical"
              icon={<Delete />}
            />
          </Tooltip>,
        ],
      },
    ],
    [navigate, handleOpenDeleteDialog]
  );

  const chemicalCatalogSpreadsheetFilename = useMemo(
    () =>
      generateSpreadsheetFilename(
        tenant?.name ?? tenantId ?? "",
        "chemical-catalog"
      ),
    [tenant?.name, tenantId]
  );

  const commandButtons = useMemo(() => {
    const buttons = [
      <LongPollingExportButton
        key="export"
        fileType={ExportFileType.EXCEL}
        endpoint={`/download/chemical?tenantId=${tenantId}&filename=${chemicalCatalogSpreadsheetFilename}`}
        disabled={!tenantId}
        tenantId={tenantId ?? ""}
        fileName={chemicalCatalogSpreadsheetFilename}
      />,
      <AddChemicalButton key="add" />,
      <ChemicalCatalogBulkActions
        key="bulk-actions"
        chemicals={selectedRows}
      />,
    ];
    if (isStaff) {
      buttons.push(
        <StaffBulkActionsMenu key="staff" chemicalIds={selectedChemicalIds} />
      );
    }
    return buttons;
  }, [
    isStaff,
    selectedChemicalIds,
    tenantId,
    selectedRows,
    chemicalCatalogSpreadsheetFilename,
  ]);

  return (
    <Box sx={{ py: 3 }}>
      <OmnisearchDataGrid
        persistenceKey="chemical-catalog"
        additionalFilterColumns={[
          { header: "Manufacturer/Supplier", key: "manufacturerSupplier" },
        ]}
        excludeFilterColumns={["issueCount"]}
        columns={columns}
        initialSortModel={[
          { field: "needsReview", sort: "desc" },
          { field: "name", sort: "asc" },
        ]}
        dataQuery={TENANT_CATALOG_CHEMICALS}
        defaultSearch={`tenantId:${tenantId}`}
        getItems={(data) => data.chemicals.items}
        getCount={(data) => data.chemicals.count}
        noDataOnButtonClick={() => navigate("new")}
        noDataMessage="No chemicals have been added to the catalog yet."
        noDataButtonText="Add Chemical"
        onRowClick={(params) => navigate(`./${params.row.id}`)}
        onSelectedIdsChanged={
          isStaff ? (ids) => setSelectedChemicalIds(ids as string[]) : undefined
        }
        onSelectedRowsChanged={setSelectedRows}
        commandButtons={commandButtons}
      />
      <ConfirmDialog
        loading={deleting}
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDeletion}
        msg={`Are you sure you want to delete chemical "${selectedChemical?.name}?"`}
      />
    </Box>
  );
};
