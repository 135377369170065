import {
  Box,
  Grid,
  Skeleton,
  Typography,
  useTheme,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Scroller } from "routes/Customer/Facility/Scroller";
import { useQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import invariant from "invariant";
import { LinkedInsightsNotFound } from "./LinkedInsightsNotFound";
import { GetEchoExporterQuery } from "generated-graphql/graphql";
import { useFacility } from "../Facility/useFacility";
import { HasNoInsightsId } from "./HasNoInsightsId";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";

type EchoExporterRow = GetEchoExporterQuery["echoExporter"];

const GET_ECHO_EXPORTER = gql(`
  query GetEchoExporter($registryId: String!, $tenantId: String!) {
    echoExporter(tenantId: $tenantId, registryId: $registryId) {
      registryId
      facName
      facStreet
      facCity
      facState
      facZip
      facCounty
      facFipsCode
      facActiveFlag
      airFlag
      npdesFlag
      rcraFlag
      facLat
      facLong
      facMajorFlag
      facInspectionCount
      facDaysLastInspection
      facInformalCount
      facFormalActionCount
      facDateLastFormalAction
      caaEvaluationCount
      caaDaysLastEvaluation
      caaInformalCount
      caaFormalActionCount
      caaDateLastFormalAction
      cwaInspectionCount
      cwaDaysLastInspection
      cwaInformalCount
      cwaFormalActionCount
      cwaDateLastFormalAction
      rcraInspectionCount
      rcraDaysLastEvaluation
      rcraInformalCount
      rcraFormalActionCount
      rcraDateLastFormalAction
      facTotalPenalties
      facLastPenaltyAmt
      facDateLastPenalty
      caaPenalties
      caaLastPenaltyAmt
      caaLastPenaltyDate
      cwaPenalties
      cwaLastPenaltyAmt
      cwaLastPenaltyDate
      rcraPenalties
      rcraLastPenaltyAmt
      rcraLastPenaltyDate
      facProgramsWithSnc
      airIds
      caaPermitTypes
      npdesIds
      cwaPermitTypes
      rcraIds
      rcraPermitTypes
      triIds
      triReporterInPast
      triReporter
      caaComplianceStatus
      rcraComplianceStatus
      facComplianceStatus
      cwaComplianceStatus
      caaHpvFlag
      cwaSncFlag
      rcraSncFlag
      facSncFlg
      caaQtrsWithNc
      cwaQtrsWithNc
      rcraQtrsWithNc
      facQtrsWithNc
      caa3yrComplQtrsHistory {
        quarter
        status
      }
      cwa13qtrsComplHistory {
        quarter
        status
      }
      rcra3yrComplQtrsHistory {
        quarter
        status
      }
      fac3yrComplianceHistory {
        quarter
        status
      }
    }
  }
`);

interface programDataProps {
  data: any;
  program: string;
  getComplianceColor: (status: string) => string;
}

const formatDate = (date: string) =>
  date
    ? new Date(date).toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      })
    : "";

const formatCurrency = (value: number) =>
  `$${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;

const getOverallData = (data: EchoExporterRow) => ({
  ids: data.registryId,
  permitTypes: [
    data.airFlag ? "Air" : null,
    data.npdesFlag ? "Water" : null,
    data.rcraFlag ? "Waste" : null,
  ]
    .filter(Boolean)
    .join(", "),
  status: data.facActiveFlag ? "Active" : "Inactive",
  complianceStatus: data.facComplianceStatus,
  significantViolations: `${data.facProgramsWithSnc} programs with Significant Violations`,
  quartersInNonCompliance: data.facQtrsWithNc,
  inspections: data.facInspectionCount,
  daysSinceLastInspection: data.facDaysLastInspection,
  informalActions: data.facInformalCount,
  formalActions: data.facFormalActionCount,
  lastFormalAction: formatDate(data.facDateLastFormalAction),
  totalPenalties: formatCurrency(data.facTotalPenalties ?? 0),
  lastPenaltyAmount: formatCurrency(data.facLastPenaltyAmt ?? 0),
  lastPenaltyDate: formatDate(data.facDateLastPenalty),
  complianceHistory: data.fac3yrComplianceHistory,
});

const getAirData = (data: EchoExporterRow) => ({
  ids: data.airIds,
  permitTypes: data.caaPermitTypes,
  status: data.airFlag ? "Active" : "Inactive",
  complianceStatus: data.caaComplianceStatus,
  significantViolations: data.caaHpvFlag
    ? "High Priority Violator"
    : "Not a High Priority Violator",
  quartersInNonCompliance: data.caaQtrsWithNc,
  inspections: data.caaEvaluationCount,
  daysSinceLastInspection: data.caaDaysLastEvaluation,
  informalActions: data.caaInformalCount,
  formalActions: data.caaFormalActionCount,
  lastFormalAction: formatDate(data.caaDateLastFormalAction),
  totalPenalties: formatCurrency(data.caaPenalties ?? 0),
  lastPenaltyAmount: formatCurrency(data.caaLastPenaltyAmt ?? 0),
  lastPenaltyDate: formatDate(data.caaLastPenaltyDate),
  complianceHistory: data.caa3yrComplQtrsHistory,
});

const getWaterData = (data: EchoExporterRow) => ({
  ids: data.npdesIds,
  permitTypes: data.cwaPermitTypes,
  status: data.npdesFlag ? "Active" : "Inactive",
  complianceStatus: data.cwaComplianceStatus,
  significantViolations: data.cwaSncFlag
    ? "Significant Violator"
    : "Not a Significant Violator",
  quartersInNonCompliance: data.cwaQtrsWithNc,
  inspections: data.cwaInspectionCount,
  daysSinceLastInspection: data.cwaDaysLastInspection,
  informalActions: data.cwaInformalCount,
  formalActions: data.cwaFormalActionCount,
  lastFormalAction: formatDate(data.cwaDateLastFormalAction),
  totalPenalties: formatCurrency(data.cwaPenalties ?? 0),
  lastPenaltyAmount: formatCurrency(data.cwaLastPenaltyAmt ?? 0),
  lastPenaltyDate: formatDate(data.cwaLastPenaltyDate),
  complianceHistory: data.cwa13qtrsComplHistory,
});

const getWasteData = (data: EchoExporterRow) => ({
  ids: data.rcraIds,
  permitTypes: data.rcraPermitTypes,
  status: data.rcraFlag ? "Active" : "Inactive",
  complianceStatus: data.rcraComplianceStatus,
  significantViolations: data.rcraSncFlag
    ? "Significant Violator"
    : "Not a Significant Violator",
  quartersInNonCompliance: data.rcraQtrsWithNc,
  inspections: data.rcraInspectionCount,
  daysSinceLastInspection: data.rcraDaysLastEvaluation,
  informalActions: data.rcraInformalCount,
  formalActions: data.rcraFormalActionCount,
  lastFormalAction: formatDate(data.rcraDateLastFormalAction),
  totalPenalties: formatCurrency(data.rcraPenalties ?? 0),
  lastPenaltyAmount: formatCurrency(data.rcraLastPenaltyAmt ?? 0),
  lastPenaltyDate: formatDate(data.rcraLastPenaltyDate),
  complianceHistory: data.rcra3yrComplQtrsHistory,
});

const ProgramData: React.FC<programDataProps> = ({
  data,
  program,
  getComplianceColor,
}) => {
  const theme = useTheme();

  const programData = {
    Overall: getOverallData(data),
    Air: getAirData(data),
    Water: getWaterData(data),
    Waste: getWasteData(data),
  }[program];

  const history = programData?.complianceHistory ?? [];

  const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
  const currentYear = new Date().getFullYear();
  const startYear =
    currentYear - Math.floor((history.length - 1 + currentQuarter) / 4);
  const startQuarter = (4 - ((history.length - 1 + currentQuarter) % 4)) % 4;
  const years = Array.from(
    { length: Math.ceil((history.length - 1 + currentQuarter) / 4) },
    (_, i) => startYear + i
  );

  const getQuarterStatus = (year: number, quarter: number) => {
    const index = `${year}Q${quarter}`;
    return history.find((q) => q.quarter === index)?.status ?? "";
  };

  const complianceFields = [
    { key: "Status", value: programData?.status },
    { key: "Program IDs", value: programData?.ids },
    { key: "Permit Types", value: programData?.permitTypes },
    { key: "Compliance Status", value: programData?.complianceStatus },
    {
      key: "Significant Violations",
      value: programData?.significantViolations,
    },
    {
      key: "Quarters in Non-Compliance",
      value: programData?.quartersInNonCompliance,
    },
  ];

  const enforcementFields = [
    { key: "Inspections/Evaluations", value: programData?.inspections },
    {
      key: "Days Since Last Inspection/Evaluation",
      value: programData?.daysSinceLastInspection,
    },
    { key: "Informal Actions", value: programData?.informalActions },
    { key: "Formal Actions", value: programData?.formalActions },
    { key: "Date of Last Formal Action", value: programData?.lastFormalAction },
    { key: "Total Penalties", value: programData?.totalPenalties },
    { key: "Last Penalty Amount", value: programData?.lastPenaltyAmount },
    { key: "Last Penalty Date", value: programData?.lastPenaltyDate },
  ];

  return programData?.status === "Inactive" ? (
    <Typography
      variant="body1"
      fontWeight={"bold"}
      height={theme.spacing(3)}
      marginBottom={theme.spacing(2.5)}
      textAlign="center"
    >
      This facility does not have an active {program} program
    </Typography>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography
          variant="body1"
          fontWeight={"bold"}
          height={theme.spacing(3)}
          marginBottom={theme.spacing(2.5)}
        >
          Compliance
        </Typography>
        <TableContainer>
          <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <TableBody>
              {complianceFields.map(({ key, value }) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      borderRight: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {key}
                  </TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant="body1"
          fontWeight={"bold"}
          height={theme.spacing(3)}
          marginBottom={theme.spacing(2.5)}
        >
          Enforcement
        </Typography>
        <TableContainer>
          <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <TableBody>
              {enforcementFields.map(({ key, value }) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      borderRight: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {key}
                  </TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          fontWeight={"bold"}
          height={theme.spacing(3)}
          marginBottom={theme.spacing(2.5)}
        >
          Quarterly History
        </Typography>
        <TableContainer>
          <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderRight: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  Year
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderRight: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  Q1
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderRight: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  Q2
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderRight: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  Q3
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderRight: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  Q4
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {years.map((year) => (
                <TableRow key={year}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      borderRight: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {year}
                  </TableCell>
                  {[1, 2, 3, 4].map((quarter) => {
                    const status = getQuarterStatus(year, quarter);
                    return (
                      <TableCell
                        key={`${year}-${quarter}`}
                        sx={{
                          backgroundColor: status
                            ? getComplianceColor(status)
                            : theme.palette.grey[200],
                        }}
                      >
                        {quarter >= startQuarter || year > startYear
                          ? status
                          : ""}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export const EchoDetail: React.FC<{ id?: string }> = ({ id }) => {
  const { registryId: registryIdFromUrl, tenantId } = useParams<{
    registryId: string;
    tenantId: string;
  }>();
  const registryId = id || registryIdFromUrl;
  const theme = useTheme();
  const { data: facility } = useFacility();

  const { loading, error, data } = useQuery(GET_ECHO_EXPORTER, {
    variables: { registryId: registryId ?? "", tenantId: tenantId ?? "" },
    skip: !registryId,
  });

  const getComplianceColor = useCallback(
    (status: any) => {
      switch (status) {
        case "NO_VIOLATION":
          return `${theme.palette.success.light}40`;
        case "VIOLATION":
          return `${theme.palette.warning.light}40`;
        case "SIGNIFICANT_NONCOMPLIANCE":
          return `${theme.palette.error.light}40`;
        default:
          return theme.palette.grey[200];
      }
    },
    [theme]
  );

  const frsId = findAlternateId(
    facility?.facility?.facilityAlternateIds ?? [],
    ["FRS"],
    true
  );

  if (frsId?.hasNoId === true) {
    return <HasNoInsightsId type="FRS ID" />;
  }

  if (error) {
    return (
      <LinkedInsightsNotFound
        alternateId={registryId ?? "[No ID set]"}
        type="ECHO"
      />
    );
  }

  const scrollerSections = [
    {
      id: "site-details",
      title: "Site Details",
      content: loading ? (
        <Skeleton variant="rectangular" height={theme.spacing(20)} />
      ) : (
        <Box
          marginTop={theme.spacing(3)}
          paddingBottom={theme.spacing(3)}
          marginLeft={theme.spacing(3)}
          marginRight={theme.spacing(3)}
        >
          <TableContainer>
            <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderRight: `1px solid ${theme.palette.divider}`,
                      fontWeight: "bold",
                    }}
                  >
                    Registry ID
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {data?.echoExporter.registryId}
                    <Tooltip title="View EPA Detail Page in new tab">
                      <IconButton
                        component="a"
                        href={`https://echo.epa.gov/detailed-facility-report?fid=${data?.echoExporter.registryId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ ml: 1 }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderRight: `1px solid ${theme.palette.divider}`,
                      fontWeight: "bold",
                    }}
                  >
                    Facility Name
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {data?.echoExporter.facName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderRight: `1px solid ${theme.palette.divider}`,
                      fontWeight: "bold",
                    }}
                  >
                    Facility Address
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {data?.echoExporter.facStreet}, {data?.echoExporter.facCity}
                    , {data?.echoExporter.facState} {data?.echoExporter.facZip}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderRight: `1px solid ${theme.palette.divider}`,
                      fontWeight: "bold",
                    }}
                  >
                    County
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {data?.echoExporter.facCounty}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      borderRight: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    Lat/Long
                  </TableCell>
                  <TableCell>
                    {data?.echoExporter.facLat}, {data?.echoExporter.facLong}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ),
    },
    {
      id: "overall",
      title: "Overall",
      content: loading ? (
        <Skeleton variant="rectangular" height={theme.spacing(20)} />
      ) : (
        <Box
          marginTop={theme.spacing(3)}
          paddingBottom={theme.spacing(3)}
          marginLeft={theme.spacing(3)}
          marginRight={theme.spacing(3)}
        >
          <ProgramData
            data={data?.echoExporter}
            program="Overall"
            getComplianceColor={getComplianceColor}
          />
        </Box>
      ),
    },
    {
      id: "air",
      title: "Air",
      content: loading ? (
        <Skeleton variant="rectangular" height={theme.spacing(20)} />
      ) : (
        <Box
          marginTop={theme.spacing(3)}
          paddingBottom={theme.spacing(3)}
          marginLeft={theme.spacing(3)}
          marginRight={theme.spacing(3)}
        >
          <ProgramData
            data={data?.echoExporter}
            program="Air"
            getComplianceColor={getComplianceColor}
          />
        </Box>
      ),
    },
    {
      id: "water",
      title: "Water",
      content: loading ? (
        <Skeleton variant="rectangular" height={theme.spacing(20)} />
      ) : (
        <Box
          marginTop={theme.spacing(3)}
          paddingBottom={theme.spacing(3)}
          marginLeft={theme.spacing(3)}
          marginRight={theme.spacing(3)}
        >
          <ProgramData
            data={data?.echoExporter}
            program="Water"
            getComplianceColor={getComplianceColor}
          />
        </Box>
      ),
    },
    {
      id: "waste",
      title: "Waste",
      content: loading ? (
        <Skeleton variant="rectangular" height={theme.spacing(20)} />
      ) : (
        <Box
          marginTop={theme.spacing(3)}
          paddingBottom={theme.spacing(3)}
          marginLeft={theme.spacing(3)}
          marginRight={theme.spacing(3)}
        >
          <ProgramData
            data={data?.echoExporter}
            program="Waste"
            getComplianceColor={getComplianceColor}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        p: theme.spacing(2),
        mt: theme.spacing(1),
        paddingLeft: 0,
        marginLeft: 0,
      }}
    >
      <Scroller sections={scrollerSections} />
    </Box>
  );
};
