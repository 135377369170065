import { ApolloError } from "@apollo/client";
import CopyAll from "@mui/icons-material/CopyAll";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Layout } from "components/Layout";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useNavigate, useRouteError } from "react-router-dom";
import { useAlerts } from "../components/Alerts/AlertProvider";
import { useCustomerNavItems } from "./Customer";

export function Oops() {
  const { navItems } = useCustomerNavItems();
  const error = useRouteError();
  const apolloError = error as ApolloError;
  const theme = useTheme();
  const navigate = useNavigate();
  const { info } = useAlerts();
  // Show traces only if user isStaff or non-prod environments
  const { isStaff } = useCurrentUser();
  const showTrace = isStaff || !import.meta.env.PROD;

  if (!(error instanceof Error)) throw error; // really uncaught because it's not even an error

  let msg = error.stack;
  if (!error.stack) msg = error.message;

  const handleCopy = () => {
    const fullError = generateFullError(error);
    navigator.clipboard.writeText(fullError);
    info("Message copied to clipboard");
  };

  const goBack = () => {
    localStorage.removeItem("preLoginPath");
    navigate("/");
  };

  return (
    <Layout navItems={navItems}>
      <Box
        sx={{
          m: 4,
        }}
      >
        <Typography
          variant="h3"
          align="center"
          sx={{
            mt: 10,
            mb: 5,
          }}
        >
          Oops! Something weird happened!
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" type="button" onClick={goBack}>
            Head Back
          </Button>
        </Box>

        {showTrace && (
          <Box
            sx={{
              m: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              See the detailed error message below.
              <Button type="button" onClick={handleCopy}>
                Copy Error To Clipboard <CopyAll />
              </Button>
            </Typography>

            <Box
              component="code"
              sx={{
                display: "flex",
                textAlign: "left",
                border: "solid 1px",
                borderRadius: "1rem",
                my: theme.spacing(2),
                padding: theme.spacing(2),
                overflowX: "auto",
                color: theme.palette.error.main,
              }}
            >
              <pre>
                {msg}
                {apolloError && (
                  <>
                    <h2>GraphQL Error</h2>
                    <p>{error.message}</p>
                    {apolloError.graphQLErrors &&
                      apolloError.graphQLErrors.length > 0 && (
                        <>
                          {apolloError.graphQLErrors.map(
                            (graphQLError, index) => (
                              <div key={index}>
                                <h3>Error {index + 1}</h3>
                                <p>Message: {graphQLError.message}</p>
                                {graphQLError.locations && (
                                  <p>
                                    Locations:{" "}
                                    {graphQLError.locations
                                      .map(
                                        (loc) =>
                                          `Line: ${loc.line}, Column: ${loc.column}`
                                      )
                                      .join(", ")}
                                  </p>
                                )}
                                {graphQLError.path && (
                                  <p>Path: {graphQLError.path.join(" -> ")}</p>
                                )}
                                {graphQLError.extensions && (
                                  <pre>
                                    Extensions:{" "}
                                    {JSON.stringify(
                                      graphQLError.extensions,
                                      null,
                                      2
                                    )}
                                  </pre>
                                )}
                              </div>
                            )
                          )}
                        </>
                      )}
                    {apolloError.networkError && (
                      <div>
                        <h3>Network Error</h3>
                        <p>{apolloError.networkError?.message}</p>
                      </div>
                    )}
                    {apolloError.extraInfo && (
                      <div>
                        <h3>Extra Info</h3>
                        <pre>
                          {JSON.stringify(apolloError.extraInfo, null, 2)}
                        </pre>
                      </div>
                    )}
                  </>
                )}
              </pre>
            </Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

function generateFullError(error: Error) {
  const apolloError = error as ApolloError;

  const msg = error.stack || error.message;

  let fullError = `
\`\`\`
URL: ${window.location.href}
  
${msg}
`;
  if (apolloError) {
    fullError += `
GraphQL Error:
  
${error.message}`;
    if (apolloError.graphQLErrors && apolloError.graphQLErrors.length > 0) {
      apolloError.graphQLErrors.forEach((graphQLError, index) => {
        fullError += `
  
GraphQL Error ${index + 1}:
`;
        if (graphQLError.locations) {
          fullError += `
Locations:
${graphQLError.locations
  .map((loc) => `Line: ${loc.line}, Column: ${loc.column}`)
  .join("\n")}`;
        }

        if (graphQLError.path) {
          fullError += `
Path: ${graphQLError.path.join(" -> ")}
`;
        }

        if (graphQLError.extensions) {
          fullError += `
Extra Info
Extensions: ${JSON.stringify(graphQLError.extensions, null, 2)}
`;
        }
      });
    }

    if (apolloError.networkError) {
      fullError += `
Network Error:
${apolloError.networkError.message}
`;
    }

    if (apolloError.extraInfo) {
      fullError += `
Extra Info:
${JSON.stringify(apolloError.extraInfo, null, 2)}
`;
    }
  }

  fullError += `
\`\`\``;

  return fullError;
}
