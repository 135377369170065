import Check from "@mui/icons-material/Check";
import { isEqual } from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import { FacilitiesQuery, Permission } from "generated-graphql/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTenant } from "hooks/useTenant";
import { useAuthorization } from "hooks/useAuthorization";
import { useCurrentUser } from "hooks/useCurrentUser";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";

export const FACILITIES_QUERY = gql(`
  query TaskFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        streetAddress1
        streetAddress2
        city
        state
        zip
      }
      count
    }
  }
`);

export type FacilityRow = FacilitiesQuery["facilities"]["items"][number];

type AssociateFacilitiesDialogProps = {
  open: boolean;
  onClose: () => void;
  existingFacilityIds?: string[];
  onSave: (facilityIds: string[]) => void;
};

export const AssociateFacilitiesDialog = ({
  open,
  onClose,
  existingFacilityIds,
  onSave,
}: AssociateFacilitiesDialogProps) => {
  const theme = useTheme();
  const { tenantId } = useTenant();
  const { hasPermissions } = useAuthorization();
  const { user } = useCurrentUser();

  // if the user has WriteAllFacility it can view assign to anything, otherwise we filter to what they have access to
  const search = useMemo(() => {
    return `tenantId:${tenantId}${
      hasPermissions([Permission.WriteAllFacility])
        ? ""
        : ` id:${user?.facilities.map((f) => f.id).join("|")}`
    }`;
  }, [hasPermissions, tenantId, user?.facilities]);

  const [selectedFacilityIds, setSelectedFacilityIds] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setSelectedFacilityIds(existingFacilityIds ?? []);
    }
  }, [existingFacilityIds, open]);

  const anyAssociationsModified = !isEqual(
    existingFacilityIds ?? [],
    selectedFacilityIds
  );

  const handleClose = useCallback(() => {
    setSelectedFacilityIds([]);
    onClose();
  }, [onClose]);

  const handleSave = useCallback(async () => {
    if (!anyAssociationsModified) {
      handleClose();
      return;
    }

    onSave(selectedFacilityIds);
    handleClose();
  }, [selectedFacilityIds, handleClose, onSave, anyAssociationsModified]);

  const columns: OmnisearchGridColDef<FacilityRow>[] = [
    {
      field: "name",
      headerName: "Name",
      filterKeyType: "facility",
      flex: 1,
    },
    {
      field: "streetAddress",
      flex: 1,
      headerName: "Address",
      width: 200,
      renderCell: ({ row }) => {
        if (!row.streetAddress1) return "";
        const { streetAddress1, city, state, zip } = row;
        return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
      },
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="associate-facilities-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="associate-facilities-dialog-title">
        Associate Facilities
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={theme.spacing(1)}>
          <Typography variant="body1">
            Select facilities to associate with this task.
          </Typography>
          <OmnisearchDataGrid
            withPadding={false}
            gridSx={{
              minHeight: "300px",
              "& .MuiDataGrid-virtualScroller": {
                minHeight: "200px",
                maxHeight: "380px",
                overflowY: "auto",
              },
            }}
            onSelectedIdsChanged={(rows) =>
              setSelectedFacilityIds(rows as string[])
            }
            selectedIds={selectedFacilityIds}
            dataQuery={FACILITIES_QUERY}
            columns={columns}
            defaultSearch={search}
            getItems={(data) => data.facilities.items}
            getCount={(data) => data.facilities.count}
            noDataMessage="No facilities available."
            initialPageSize={10}
            isURLDriven={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" startIcon={<Check />} onClick={handleSave}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
