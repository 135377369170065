import { SxProps, useTheme } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import React from "react";
import { FieldError, FieldErrors } from "react-hook-form";

export type FormErrors<T> = {
  [K in keyof T]?: FieldError;
};

/**
 * This is a helper function to convert a FieldErrors object to an array of
 * fields with their error messages. It loops over all of the fields in the
 * error object and recursively calls itself to handle any nested objects.
 */
const convertErrorsToArray = (
  errors: FieldErrors,
  path = ""
): Array<{ field: string; message: string }> => {
  return Object.entries(errors).reduce((acc, [field, error]) => {
    const currentPath = path ? `${path}.${field}` : field;
    if (error?.type) {
      acc.push({ field: currentPath, message: error.message as string });
      if (typeof error === "object") {
        acc = acc.concat(
          convertErrorsToArray(error as FieldErrors, currentPath)
        );
      }
    } else if (typeof error === "object") {
      acc = acc.concat(convertErrorsToArray(error as FieldErrors, currentPath));
    }
    return acc;
  }, [] as Array<{ field: string; message: string }>);
};

export const useFormHelperTextSx = (staticSpacing?: boolean, sx?: SxProps) => {
  const theme = useTheme();
  return {
    minHeight: staticSpacing ? theme.spacing(3) : undefined,
    marginLeft: theme.spacing(1.75), // 14px to match the other form helper text
    fontSize: theme.typography.body2.fontSize,
    ...sx,
  };
};

export const ErrorDisplay: React.FC<{
  error?: FieldError;
  errors?: FieldErrors;
  hideErrors?: boolean;
  helperText?: string;
  sx?: SxProps;
  staticSpacing?: false;
  fields?: string[];
}> = ({
  error,
  errors,
  hideErrors,
  helperText,
  fields,
  sx,
  staticSpacing = true,
}) => {
  const formHelperTextSx = useFormHelperTextSx(staticSpacing, sx);

  // Returning null indicates that we do not want to display extra space
  // below an input. We want to hide errors but display helper text if it is
  // provided
  if (hideErrors === true && (error || errors || !helperText)) {
    return null;
  }

  let message: JSX.Element[] = [];

  // display errors as long as we haven't been asked to hide them
  if (!hideErrors && (error || errors)) {
    const errorMessages = error?.message?.split("|") ?? [];
    if (errors) {
      const fieldErrors = convertErrorsToArray(errors ?? {});
      fieldErrors
        .filter((e) => fields?.includes(e.field))
        .forEach((e) => {
          errorMessages.push(...(e.message?.split("|") ?? []));
        });
    }
    message = errorMessages.map((m) => (
      <Typography key={m} variant="body2">
        {m}
      </Typography>
    ));
  }
  // Only show helper text if there are no errors
  else if (!error && !errors) {
    message.push(
      <Typography key="helperText" variant="body2">
        {helperText}
      </Typography>
    );
  }

  return (
    <FormHelperText
      component={"div"}
      error={!!error || !!errors}
      sx={formHelperTextSx}
    >
      {message}
    </FormHelperText>
  );
};
