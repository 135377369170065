import { useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import Download from "@mui/icons-material/Download";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { SaveButton } from "components/SaveButton";
import {
  DocumentType,
  Payee,
  PaymentMethod,
  PaymentStatus,
} from "generated-graphql/graphql";
import {
  useMakeLobPayment,
  usePayment,
  useUpsertPayment,
} from "hooks/usePayment";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "../../../../../queries/getDocumentDownloadLink";
import DocumentUpload from "../DocumentUpload";
import { AssociatedEntities } from "./AssociatedEntities";
import { Association } from "./types";
import { calculateAmountPerAssociation } from "./utils";
import { PaymentType, validationSchema } from "./validation";

export interface PayeeFormInputs {
  type: PaymentType;
  payeeType: Payee;
  recipientName: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  memo: string;
  invoiceNumber: string;
  paymentMethod: PaymentMethod;
  amount: number;
  paidAt?: string;
  invoiceDocument?: any;
  receiptDocument?: any;
  associations: Association[];
  activityId?: string;
  status?: PaymentStatus;
}

const PaymentDialog = ({
  open,
  onClose,
  tenantId,
  onCompleted,
  facilityId,
  reportId,
  activityId,
  paymentId,
  associatedReportIds,
}: {
  open: boolean;
  onClose: () => void;
  tenantId: string;
  onCompleted?: () => void;
  facilityId?: string;
  reportId?: string;
  activityId?: string;
  paymentId?: string;
  associatedReportIds?: string[];
}) => {
  const theme = useTheme();

  const { payment: existingPayment, loading: loadingPayment } =
    usePayment(paymentId);
  const [upsert, { loading: recordingPayment }] = useUpsertPayment(reportId);

  const [sendLobCheck, { loading: sendingLobCheck }] =
    useMakeLobPayment(reportId);

  const onSubmit = useCallback(
    async (data: PayeeFormInputs) => {
      if (data.type === PaymentType.LOB_CHECK) {
        await sendLobCheck({
          variables: {
            input: {
              associations: data.associations.map((r) => ({
                reportId: r.reportId,
                facilityId: r.facilityId,
                amount: r.amount,
              })),
              activityId: data.activityId,
              tenantId: tenantId,
              invoiceDocumentId: data.invoiceDocument?.id ?? "",
              amount: Number(data.amount),
              payeeType: data.payeeType,
              recipient: data.recipientName,
              invoiceNumber: data.invoiceNumber,
              memo: data.memo,
              company: data.companyName,
              addressLine1: data.addressLine1,
              addressLine2: data.addressLine2,
              city: data.city,
              state: data.state,
              zip: data.zip,
            },
          },
        });
      } else if (data.type === PaymentType.RECORD_PAYMENT) {
        await upsert({
          variables: {
            input: {
              id: paymentId,
              associations: data.associations.map((r) => ({
                reportId: r.reportId,
                facilityId: r.facilityId,
                amount: r.amount,
              })),
              activityId: data.activityId,
              tenantId: tenantId,
              invoiceDocumentId: data.invoiceDocument?.id ?? "",
              documentId: data.receiptDocument?.id ?? "",
              amount: Number(data.amount),
              payeeType: data.payeeType,
              payee: data.recipientName,
              invoiceNumber: data.invoiceNumber,
              paymentMethod: data.paymentMethod,
              memo: data.memo,
              company: data.companyName,
              addressLine1: data.addressLine1,
              addressLine2: data.addressLine2,
              city: data.city,
              state: data.state,
              zip: data.zip,
              paidAt: data.paidAt ?? new Date().toISOString(),
            },
          },
        });
      }

      onCompleted?.();

      onClose();
    },
    [onCompleted, onClose, sendLobCheck, tenantId, upsert, paymentId]
  );

  const { handleSubmit, control, watch, setValue, reset } =
    useForm<PayeeFormInputs>({
      resolver: yupResolver(validationSchema) as any,
    });

  const defaultValues = useMemo(() => {
    const invoice = existingPayment?.paymentInvoices?.[0];
    return {
      type: existingPayment ? PaymentType.RECORD_PAYMENT : undefined,
      recipientName: existingPayment?.payee ?? undefined,
      paymentMethod: existingPayment?.paymentMethod ?? undefined,
      payeeType: existingPayment?.payeeType ?? undefined,
      companyName: existingPayment?.company ?? undefined,
      addressLine1: existingPayment?.addressLine1 ?? undefined,
      addressLine2: existingPayment?.addressLine2 ?? undefined,
      city: existingPayment?.city ?? undefined,
      state: existingPayment?.state ?? undefined,
      zip: existingPayment?.zip ?? undefined,
      amount: existingPayment?.amount ?? undefined,
      memo: existingPayment?.memo ?? undefined,
      paidAt: existingPayment?.paidAt ?? undefined,
      associations: existingPayment
        ? [
            ...(existingPayment.tierIIReports?.map((report) => ({
              amount: report.amount ?? 0,
              reportId: report.tierIIReport?.id,
            })) ?? []),
            ...(existingPayment.facilities?.map((facility) => ({
              amount: facility.amount ?? 0,
              facilityId: facility.facility?.id,
            })) ?? []),
          ]
        : [reportId, ...(associatedReportIds ?? [])]
            .filter((x) => x?.length)
            .map((id) => ({
              amount: 0,
              reportId: id,
            })),
      invoiceDocument: invoice?.invoiceDocument ?? undefined,
      invoiceNumber: invoice?.invoiceNumber ?? undefined,
      receiptDocument: existingPayment?.document ?? undefined,
      activityId: existingPayment?.activityId ?? activityId,
      status: existingPayment?.status ?? undefined,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingPayment]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isLob = watch("type") === PaymentType.LOB_CHECK;

  useEffect(() => {
    if (isLob) {
      setValue("paymentMethod", PaymentMethod.Check);
    }
  }, [isLob, setValue]);

  const amount = watch("amount");
  const associations = watch("associations");
  const { replace: replaceAssociations } = useFieldArray({
    control,
    name: "associations",
  });

  const setReportAmounts = useCallback(() => {
    const associationsSum = associations.reduce(
      (partialSum, a) => partialSum + a.amount,
      0
    );

    if (
      associations.length &&
      (!associationsSum || associations.length === 1)
    ) {
      const amountsPerAssociation = calculateAmountPerAssociation(
        amount,
        associations.length
      );
      replaceAssociations(
        associations.map((r, idx) => ({
          ...r,
          amount: amountsPerAssociation[idx],
        }))
      );
    }
  }, [amount, associations, replaceAssociations]);

  const alerts = useAlerts();
  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          alerts.error("Error fetching download link");
          return;
        }
        window.open(data.getDocumentDownloadLink, "_blank");
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  const isExistingLobPayment =
    !!paymentId && !!existingPayment?.document?.lobId;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Payments</DialogTitle>
        <DialogContent>
          {!!paymentId && loadingPayment ? (
            <Stack gap={2}>
              <Skeleton variant="rectangular" />
              <Skeleton variant="rectangular" />
              <Skeleton variant="rectangular" />

              <Skeleton variant="rectangular" />
              <Skeleton variant="rectangular" />
            </Stack>
          ) : (
            <Stack gap={1}>
              <Box sx={{ mb: theme.spacing(2) }}>
                <a
                  href="https://docs.google.com/spreadsheets/d/1rh_889u-RRv7BHQN_suWX4zW6lshXigvB_J2PmzoiVw/edit#gid=857027531"
                  target="_blank"
                  rel="noreferrer"
                >
                  LEPC Fee Information
                </a>
              </Box>
              {!paymentId && (
                <Box>
                  <RadioGroupField
                    name="type"
                    control={control}
                    label="Do you need to make or record a payment"
                    radioOptions={[
                      {
                        value: PaymentType.LOB_CHECK,
                        label: "Send a check with Lob",
                      },
                      {
                        value: PaymentType.RECORD_PAYMENT,
                        label: "Record a payment made elsewhere",
                      },
                    ]}
                  />
                </Box>
              )}
              {isExistingLobPayment && (
                <Typography variant="subtitle2">
                  This payment was sent to Lob. Editing the payment will NOT
                  send another check to Lob. Take caution editing this payment.
                </Typography>
              )}
              <Grid container spacing={theme.spacing(2)}>
                <Grid item xs={6}>
                  <Typography sx={{ mb: theme.spacing(1) }} variant="subtitle2">
                    Payee
                  </Typography>
                  <FormSelect
                    label="Payee Type"
                    selectItems={[
                      Payee.Lepc,
                      Payee.Fd,
                      Payee.Serc,
                      Payee.Rcra,
                    ].map((val) => ({
                      value: val,
                      display: val,
                    }))}
                    name="payeeType"
                    control={control}
                  />
                  <FormTextField
                    name="recipientName"
                    label="Recipient/Payee"
                    maxLength={isLob ? 40 : undefined}
                    control={control}
                    disabled={isExistingLobPayment}
                  />
                  <FormTextField
                    name="companyName"
                    label="Company (Optional)"
                    maxLength={isLob ? 40 : undefined}
                    control={control}
                    disabled={isExistingLobPayment}
                  />
                  <FormTextField
                    name="addressLine1"
                    label="Address Line 1"
                    maxLength={isLob ? 64 : undefined}
                    control={control}
                    disabled={isExistingLobPayment}
                  />
                  <FormTextField
                    name="addressLine2"
                    label="Address Line 2"
                    maxLength={isLob ? 64 : undefined}
                    control={control}
                    disabled={isExistingLobPayment}
                  />
                  <Grid container spacing={theme.spacing(1)}>
                    <Grid item xs={5}>
                      <FormTextField
                        name="city"
                        label="City"
                        maxLength={isLob ? 200 : undefined}
                        control={control}
                        disabled={isExistingLobPayment}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormTextField
                        name="state"
                        label="State"
                        control={control}
                        disabled={isExistingLobPayment}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FormTextField
                        name="zip"
                        label="Zip"
                        control={control}
                        disabled={isExistingLobPayment}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ mb: theme.spacing(1) }} variant="subtitle2">
                    Payment
                  </Typography>
                  <FormTextField
                    name="memo"
                    label="Memo"
                    maxLength={isLob ? 40 : undefined}
                    control={control}
                    disabled={isExistingLobPayment}
                  />
                  <FormTextField
                    name="invoiceNumber"
                    label="Invoice #"
                    control={control}
                  />
                  <FormSelect
                    name="paymentMethod"
                    label="Payment Method"
                    control={control}
                    selectItems={[
                      { value: PaymentMethod.Check, display: "Check" },
                      {
                        value: PaymentMethod.CreditCard,
                        display: "Credit Card",
                      },
                      { value: PaymentMethod.Ach, display: "ACH" },
                    ]}
                    disabled={isLob || isExistingLobPayment}
                  />
                  <FormTextField
                    name="amount"
                    label="Amount"
                    control={control}
                    onBlur={setReportAmounts}
                    disabled={isExistingLobPayment}
                  />
                  <Stack spacing={1}>
                    <Controller
                      name="invoiceDocument"
                      control={control}
                      render={({ field: { value }, fieldState: { error } }) => (
                        <>
                          {value ? (
                            <>
                              <Typography>Invoice</Typography>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography
                                  color={theme.palette.action.active}
                                  fontStyle="italic"
                                >
                                  {`${value.title}.${value.fileExtension}`}
                                </Typography>
                                <IconButton
                                  aria-label="download"
                                  onClick={() =>
                                    getDocumentDownloadLink({
                                      variables: { id: value.id },
                                    })
                                  }
                                >
                                  <Download />
                                </IconButton>
                              </Stack>
                            </>
                          ) : (
                            <>
                              <DocumentUpload
                                tenantId={tenantId}
                                facilityId={facilityId}
                                reportId={reportId}
                                activityId={activityId}
                                documentType={DocumentType.Invoice}
                                onSuccess={(document) => {
                                  setValue("invoiceDocument", document);
                                }}
                                label={"Attach Invoice"}
                              />
                              <ErrorDisplay error={error} />
                            </>
                          )}
                        </>
                      )}
                    />
                    {(!isLob || !!existingPayment) && (
                      <Controller
                        name="receiptDocument"
                        control={control}
                        render={({
                          field: { value },
                          fieldState: { error },
                        }) => (
                          <>
                            {value ? (
                              <>
                                <Typography>Receipt</Typography>
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    color={theme.palette.action.active}
                                    fontStyle="italic"
                                  >
                                    {`${value.title}.${value.fileExtension}`}
                                  </Typography>
                                  <IconButton
                                    aria-label="download"
                                    onClick={() =>
                                      getDocumentDownloadLink({
                                        variables: { id: value.id },
                                      })
                                    }
                                  >
                                    <Download />
                                  </IconButton>
                                </Stack>
                              </>
                            ) : (
                              existingPayment?.status !==
                                PaymentStatus.Pending && (
                                <>
                                  <DocumentUpload
                                    tenantId={tenantId}
                                    facilityId={facilityId}
                                    reportId={reportId}
                                    activityId={activityId}
                                    documentType={DocumentType.Receipt}
                                    onSuccess={(document) => {
                                      setValue("receiptDocument", document);
                                    }}
                                    label={"Attach Receipt"}
                                  />
                                  <ErrorDisplay error={error} />
                                </>
                              )
                            )}
                          </>
                        )}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <AssociatedEntities
                control={control}
                tenantId={tenantId}
                type="Report"
              />
              <AssociatedEntities
                control={control}
                tenantId={tenantId}
                type="Facility"
              />
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <SaveButton
            disabled={
              loadingPayment ||
              existingPayment?.status === PaymentStatus.Pending
            }
            loading={recordingPayment || sendingLobCheck}
            saveText="Submit Payment"
            startIcon={<MonetizationOn />}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { PaymentDialog };
