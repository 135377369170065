import { useLazyQuery } from "@apollo/client";
import { EditContactFormData } from "components/Contacts/EditContactDialog";
import { gql } from "generated-graphql";
import { transform } from "hooks/transform/transformFacilityContact";
import { useTenant } from "hooks/useTenant";
import { useCallback } from "react";

const VALIDATE_PERSON_FACILITY_CONTACTS_INPUT = gql(`
  query ValidatePersonFacilityContactsInput($data: PersonFacilityContactsInput!) {
    personFacilityContactsInputValidator(data: $data) {
      ...issue
    }
  }
`);

export function usePersonFacilityContactsInputValidation() {
  const [query] = useLazyQuery(VALIDATE_PERSON_FACILITY_CONTACTS_INPUT, {
    fetchPolicy: "no-cache",
  });
  return useCallback(
    async (data: EditContactFormData) => {
      const input = transform(data);
      const { data: result, previousData } = await query({
        variables: { data: input },
      });
      return result
        ? result?.personFacilityContactsInputValidator
        : previousData?.personFacilityContactsInputValidator ?? [];
    },
    [query]
  );
}
