import { Box, Card, Stack, SxProps, Tooltip, Typography } from "@mui/material";

export type LegendConfig = {
  direction: "column" | "row";
  position: "above" | "right" | "below" | "left";
};

export type ChartLegendProps = {
  config?: LegendConfig;
  items: {
    color: string;
    value: string;
  }[];
  onItemClick?: (index: number) => void;
  selectedIndex?: number | null;
  sx?: SxProps;
};

export default function ChartLegend({
  config,
  items,
  onItemClick,
  selectedIndex,
  sx,
}: ChartLegendProps) {
  return (
    <Stack
      direction={config?.direction ?? "column"}
      justifyContent={config?.direction === "row" ? "left" : "center"}
      gap={1}
      sx={{ p: 1, ...sx }}
    >
      {items.map((i, index) => (
        <Tooltip title={i.value} key={`${i.color}-${i.value}`}>
          <Card
            sx={{
              border: "none",
              boxShadow: "none",
              cursor: onItemClick ? "pointer" : "default",
            }}
            onClick={() => onItemClick && onItemClick(index)}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  minWidth: "16px",
                  borderRadius: "2px",
                  backgroundColor: i.color,
                  opacity:
                    Number.isInteger(selectedIndex) && index === selectedIndex
                      ? 1
                      : Number.isInteger(selectedIndex) &&
                        index !== selectedIndex
                      ? 0.3
                      : 1,
                }}
              />
              <Typography variant="body2" noWrap={true}>
                {i.value}
              </Typography>
            </Stack>
          </Card>
        </Tooltip>
      ))}
    </Stack>
  );
}
