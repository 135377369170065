import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";
import {
  CancelPaymentMutation,
  Exact,
  PaymentStatus,
} from "generated-graphql/graphql";
import { client } from "providers/apollo";
import { useEffect } from "react";

export const PAYMENT_ROW_FRAGMENT = gql(`
  fragment PaymentRow on Payment {
    id
    payee
    paidAt
    paymentMethod
    amount
    payeeType
    activityId
    status
    tenantId
    lobMail {
      id
      lobId
      status
      type
    }
  }
`);

export const SEND_LOB_CHECK = gql(`
  mutation SendLobCheck($input: SendLobCheckInput!) {
    sendLobCheck(input: $input) {
     ...PaymentRow
    }
  }
`);

export const UPSERT_PAYMENT = gql(`
  mutation UpsertPayment($input: PaymentInput!) {
    upsertPayment(input: $input) {
     ...PaymentRow
    }
  }
`);

const CANCEL_PAYMENT = gql(`
  mutation CancelPayment($id: ID!) {
    cancelPayment(id: $id) {
      id
      lobMail {
        lobId
        type
      }
    }
  }
`);

export const GET_PAYMENT = gql(`
  query Payment($id: ID!) {
    payment(id: $id) {
      id
      payee
      paidAt
      paymentMethod
      amount
      description
      status
      payeeType
      company
      addressLine1
      addressLine2
      city
      state
      zip
      memo
      activityId
      document {
        ...DocumentFragment
        fileExtension
        lobId
      }
      paymentInvoices {
        invoiceNumber
        invoiceDocument {
          ...DocumentFragment
          fileExtension
        }
      }
      tierIIReports {
        amount
        tierIIReport {
          id
        }
      }
      facilities {
        amount
        facility {
          id
        }
      }
    }
  }
`);

export const GET_PAYMENTS = gql(`
  query Payments($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    payments(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        tenantId
        tenant {
          name
        }
        status
        paymentMethod
        amount
        description
        memo
        payee
        paidAt
        payeeType
        recordedByEmail
        lobMail {
          lobId
          type
        }
        facilities {
          facility {
            id
          }
        }
        tierIIReports {
          tierIIReport {
            id
          }
        }
        job {
          id
          status
        }
      }
      count
    }
  }
`);

export const useUpsertPayment = (reportId?: string) => {
  const alerts = useAlerts();
  return useMutation(UPSERT_PAYMENT, {
    onCompleted() {
      alerts.success("Payment record submitted successfully!");
    },
    onError(error) {
      console.error(error);
      alerts.error(
        "An error occurred while recording the payment: " + error.message
      );
    },
    update(cache, { data }) {
      if (reportId) {
        const payment = data?.upsertPayment;
        cache.modify({
          id: client.cache.identify({
            __typename: "TierIIReport",
            id: reportId,
          }),
          fields: {
            payments(existingPayments = []) {
              const newPaymentRef = cache.writeFragment({
                data: payment,
                fragment: PAYMENT_ROW_FRAGMENT,
              });

              // Check if the new payment is already in the existing payments
              if (
                existingPayments.some(
                  (ref: { __ref: string }) => ref.__ref === newPaymentRef?.__ref
                )
              ) {
                return existingPayments.map((ref: { __ref: string }) =>
                  ref.__ref === newPaymentRef?.__ref ? newPaymentRef : ref
                ); // Return the existing payments if the new payment is already there
              }

              return [...existingPayments, newPaymentRef]; // Append the new payment if it's not already there
            },
          },
        });
      }
    },
  });
};

export const useMakeLobPayment = (reportId?: string) => {
  const alerts = useAlerts();

  return useMutation(SEND_LOB_CHECK, {
    onCompleted() {
      alerts.success("Payment submitted successfully to Lob!");
    },
    onError(error) {
      console.error(error);
      alerts.error(
        "An error occurred while submitting the payment to Lob: " +
          error.message
      );
    },
    update(cache, { data }) {
      if (reportId) {
        const payment = data?.sendLobCheck;
        cache.modify({
          id: client.cache.identify({
            __typename: "TierIIReport",
            id: reportId,
          }),
          fields: {
            payments(existingPayments = []) {
              const newPaymentRef = cache.writeFragment({
                data: payment,
                fragment: PAYMENT_ROW_FRAGMENT,
              });
              return [...existingPayments, newPaymentRef];
            },
          },
        });
      }
    },
  });
};

export const usePayment = (paymentId: string | undefined) => {
  const result = useQuery(GET_PAYMENT, {
    variables: { id: paymentId ?? "" },
    skip: !paymentId,
    fetchPolicy: "cache-and-network",
  });

  const payment = result.data?.payment;

  const { startPolling, stopPolling } = result;

  useEffect(() => {
    // Poll pending payments every 5 seconds
    if (payment?.status === PaymentStatus.Pending) {
      startPolling(5000);
    } else {
      stopPolling();
    }
    return () => stopPolling();
  }, [payment?.status, startPolling, stopPolling]);

  return { ...result, payment };
};

export const useCancelPayment = (
  options?: MutationHookOptions<
    CancelPaymentMutation,
    Exact<{ id: string }>,
    DefaultContext,
    ApolloCache<any>
  >
) => {
  return useMutation(CANCEL_PAYMENT, options);
};
