import { Stack, useTheme } from "@mui/material";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { Pressure } from "generated-graphql/graphql";
import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormSelect } from "./Forms/FormSelect";
import { FormTextField } from "./Forms/FormTextField";

type PressurePickerProps = {
  facilityState?: string | null;
  disabled?: boolean;
  required?: boolean;
};

export function PressurePicker({
  facilityState,
  disabled,
  required,
}: PressurePickerProps) {
  const theme = useTheme();
  const { control, setValue } = useFormContext();
  const pressure = useWatch({ name: "pressure", control });
  const otherPressureValue = useWatch({ name: "otherPressureValue", control });

  useEffect(() => {
    if (pressure !== Pressure.Other && otherPressureValue) {
      setValue("otherPressureValue", null);
    }
  }, [otherPressureValue, pressure, setValue]);

  const options = useMemo(
    () =>
      // Only LA facilities should have "Other" as an option
      Object.values(Pressure).filter((val) =>
        facilityState === "LA" || val === pressure ? true : val !== "OTHER"
      ),
    [pressure, facilityState]
  );

  return (
    <Stack direction="row" spacing={theme.spacing(3)}>
      <FormSelect
        name="pressure"
        label="Pressure"
        selectItems={options.map((pressure) => ({
          display: prettyPrintEnumValue(pressure),
          value: pressure,
        }))}
        control={control}
        disabled={disabled}
        rules={{ required: required }}
      />

      {pressure === Pressure.Other && (
        <FormTextField
          label="Other Pressure"
          name="otherPressureValue"
          control={control}
        />
      )}
    </Stack>
  );
}
