import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useAlerts } from "./Alerts/AlertProvider";
import { Dialog } from "./Dialog";
import { FormTextField } from "./Forms/FormTextField";

export enum PasswordAction {
  Reset,
  NewPassword,
}

type PasswordDialogProps = {
  action: PasswordAction | null;
  email: string;
  open: boolean;
  onClose: () => void;
  user: CognitoUser | null;
};

export function PasswordDialog({
  action,
  email,
  open,
  onClose,
  user,
}: PasswordDialogProps) {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<{
    code: string | null;
    password: string | null;
    passwordConfirmation: string | null;
  }>();

  const { code, password, passwordConfirmation } = watch();

  const alerts = useAlerts();
  const theme = useTheme();

  const passwordsMatch =
    password?.length &&
    passwordConfirmation?.length &&
    password === passwordConfirmation;
  const passwordHasLength = (password?.length ?? 0) >= 12;
  const passwordHasLowercase = /[a-z]/.test(password ?? "");
  const passwordHasUppercase = /[A-Z]/.test(password ?? "");
  const passwordHasNumber = /\d/.test(password ?? "");
  const passwordHasSymbol = /(_|[^\w\d ])/.test(password ?? "");

  const resettingPassword = action === PasswordAction.Reset;

  const disabled = useMemo(() => {
    if (
      resettingPassword &&
      !(code?.length && password?.length && passwordConfirmation?.length)
    )
      return true;

    if (
      ![
        passwordsMatch,
        passwordHasLength,
        passwordHasLowercase,
        passwordHasUppercase,
        passwordHasSymbol,
        passwordHasNumber,
      ].every((v) => !!v)
    )
      return true;

    return false;
  }, [
    code,
    password,
    passwordConfirmation,
    passwordsMatch,
    passwordHasLength,
    passwordHasLowercase,
    passwordHasUppercase,
    passwordHasSymbol,
    passwordHasNumber,
    resettingPassword,
  ]);

  if (action == null) return <></>;

  return (
    <Dialog open={open}>
      <form
        onSubmit={handleSubmit(
          async ({ code, password, passwordConfirmation }) => {
            if (!(password?.length && passwordConfirmation?.length))
              return false;

            if (resettingPassword) {
              if (code == null) return false;

              try {
                await Auth.forgotPasswordSubmit(email, code, password);
                await Auth.signIn(email, password);
                reset();
                onClose();
              } catch (err) {
                alerts.error("Error resetting password", err);
              }
              return;
            } else {
              if (user == null) {
                alerts.error("User not provided.");
                return;
              }
              try {
                const cognitoUser = await Auth.completeNewPassword(
                  user,
                  password
                );
                const tenantId =
                  cognitoUser?.signInUserSession?.idToken?.payload[
                    "custom:tenant_id"
                  ];

                if (tenantId == null) return;

                localStorage.setItem(
                  "preLoginPath",
                  `/o/${tenantId}/facilities`
                );
                reset();
                onClose();
              } catch (err) {
                alerts.error("Error creating new password", err);
              }
            }
          }
        )}
      >
        <DialogTitle>
          {resettingPassword ? "Reset Password" : "Create New Password"}
        </DialogTitle>
        <DialogContent>
          <Stack>
            {resettingPassword && (
              <>
                <Typography paddingBottom={theme.spacing(1)}>
                  Enter the one-time code sent to your email to reset your
                  password.
                </Typography>
                <FormTextField
                  control={control}
                  name="code"
                  label="One-time code"
                />
              </>
            )}
            <Typography>
              Passwords must meet the following requirements:
            </Typography>
            <List>
              <ListItem>
                {passwordHasLength ? (
                  <Check color="success" />
                ) : (
                  <Close color="error" />
                )}
                <Typography>Password must be at least 12 characters</Typography>
              </ListItem>
              <ListItem>
                {passwordHasLowercase ? (
                  <Check color="success" />
                ) : (
                  <Close color="error" />
                )}
                <Typography>
                  Password must have at least one lowercase letter
                </Typography>
              </ListItem>
              <ListItem>
                {passwordHasUppercase ? (
                  <Check color="success" />
                ) : (
                  <Close color="error" />
                )}
                <Typography>
                  Password must have at least one uppercase letter
                </Typography>
              </ListItem>
              <ListItem>
                {passwordHasSymbol ? (
                  <Check color="success" />
                ) : (
                  <Close color="error" />
                )}
                <Typography>Password must have at least one symbol</Typography>
              </ListItem>
              <ListItem>
                {passwordHasNumber ? (
                  <Check color="success" />
                ) : (
                  <Close color="error" />
                )}
                <Typography>Password must have at least one number</Typography>
              </ListItem>
              <ListItem>
                {passwordsMatch ? (
                  <Check color="success" />
                ) : (
                  <Close color="error" />
                )}
                <Typography>Passwords must match</Typography>
              </ListItem>
            </List>
            <FormTextField
              type="password"
              control={control}
              name="password"
              label="Password"
            />
            <FormTextField
              type="password"
              control={control}
              name="passwordConfirmation"
              label="Confirm Password"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {resettingPassword && (
            <Button
              onClick={() => {
                onClose();
                reset();
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            disabled={disabled}
            variant="contained"
          >
            {resettingPassword
              ? "Confirm Password Reset"
              : "Confirm New Password"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
