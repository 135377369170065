import { InsightsLinker, ComparisonFields } from "./InsightsLinker";
import {
  RcraInfo,
  Row as RcraInfoRow,
} from "routes/Customer/Insights/RcraInfo";
import { RcraInfoDetail } from "routes/Customer/Insights/RcraInfoDetail";
import { useParams } from "react-router-dom";

export function RcraProfile() {
  const { facilityId } = useParams<{ facilityId: string }>();

  const mapper = (rowData: RcraInfoRow): ComparisonFields => ({
    name: rowData["handlerName"] ?? "",
    streetAddress: `${rowData["locationStreetNo"]} ${rowData["locationStreet1"]}`,
    streetAddress2: "",
    city: rowData["locationCity"] ?? "",
    state: rowData["locationState"] ?? "",
    zip: rowData["locationZip"] ?? "",
    id: rowData["id"] ?? "",
  });

  return (
    <InsightsLinker
      componentIfLinked={RcraInfoDetail}
      componentIfUnlinked={RcraInfo}
      comparisonMapper={mapper}
      facilityId={facilityId || ""}
      type="EPA"
    />
  );
}
