import { getOakNotReportingReason } from "encamp-shared/src/tierii/oakNotReportingReason";

export function getNotReportingReason(facility: {
  isClosed?: boolean | null;
  isInactive?: boolean | null;
  isNotReporting?: boolean | null;
  isNotReportingThroughEncamp?: boolean | null;
  colocationReportingFacilityId?: string | null;
}): string | null {
  return getOakNotReportingReason(facility);
}
