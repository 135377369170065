import { useQuery } from "@apollo/client";
import { TabContext, TabPanel } from "@mui/lab";
import { TabLinks } from "components/TabLinks";
import { gql } from "generated-graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { OnboardingContacts } from "./Contacts";
import { ExtractionsTabPanel } from "./Extractions/ExtractionsTabPanel";
import { ImplementationFacilities } from "./Facilities";
import { ImportExport } from "./ImportExportSpreadsheets";
import { InsightsCandidates } from "./InsightsCandidates";
import { StaffReports } from "./StaffReports";

const GET_TENANT_QUERY = gql(`
  query ImplementationsOrganizationDetail($tenantId: ID!) {
    tenant(id: $tenantId) {
      id
      name
      featureFlags
      facilityCount
      contactCount
    }
  }
`);

export function ImplementationTabs() {
  const { tenantId } = useParams();
  const [searchParams] = useSearchParams();

  const { data: tenantData } = useQuery(GET_TENANT_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
    },
  });

  useBreadcrumb({
    label: tenantData?.tenant.name ?? "...",
  });

  if (!tenantId) {
    return <Outlet />;
  }

  const tabs = [
    {
      title: `Facilities (${tenantData?.tenant.facilityCount ?? "..."})`,
      to: ".?tab=facilities",
      component: <ImplementationFacilities tenantId={tenantId} />,
    },
    {
      title: "Extractions",
      to: ".?tab=extractions",
      component: <ExtractionsTabPanel tenantId={tenantId} />,
    },
    {
      title: `Contacts (${tenantData?.tenant.contactCount ?? "..."})`,
      to: ".?tab=contacts",
      component: <OnboardingContacts tenantId={tenantId} />,
    },
    {
      title: "Import/export spreadsheets",
      to: ".?tab=import-export",
      component: <ImportExport tenantId={tenantId} />,
    },
    {
      title: "Connect Insights",
      to: ".?tab=insights",
      component: <InsightsCandidates tenantId={tenantId} />,
    },
    {
      title: "Staff Reports",
      to: ".?tab=staffReports",
      component: <StaffReports />,
    },
  ];

  const selectedTabVal =
    tabs.find((t) => t.to === `.?tab=${searchParams.get("tab")}`)?.to ??
    tabs[0].to;

  return (
    <TabContext value={selectedTabVal}>
      <TabLinks tabs={tabs} value={selectedTabVal} />
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={tab.to}>
          {tab.component}
        </TabPanel>
      ))}
    </TabContext>
  );
}
