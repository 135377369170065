import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { DateField } from "components/Forms/DateField";
import { FormTextField } from "components/Forms/FormTextField";
import { VerifierFragmentFragment } from "generated-graphql/graphql";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { VerifierTable } from "./VerifierTable";

export type VerifyAndSubmitFormState = {
  personId?: string;
  firstName: string;
  lastName: string;
  title: string;
  date: string;
};

const validationSchema = yup.object().shape({
  personId: yup.string().nullable(),
  firstName: yup.string().required("First name is required.").nullable(),
  lastName: yup.string().required("Last name is required.").nullable(),
  title: yup.string().required("Title is required.").nullable(),
  date: yup.string().required("Date is required.").nullable(),
});

type Props = {
  onClose: () => void;
  open: boolean;
  loading: boolean;
  defaultVerifier?: {
    person?: VerifierFragmentFragment;
    fullName?: string;
    title?: string;
  };
} & (
  | {
      onSubmit: (formState: VerifyAndSubmitFormState) => Promise<void>;
      withConfirmation?: false;
    }
  | {
      onSubmit: (
        formState: VerifyAndSubmitFormState,
        overrideOpenReviews: boolean
      ) => Promise<void>;
      withConfirmation: true;
      skippedDueToOpenReviewsCount: number;
      skippedDueToVerificationCount: number;
      verifiableRowsWithoutOpenReviewsCount: number;
    }
);

export const VerifyAndSubmitFormDialog = (props: Props) => {
  const defaultDate = useMemo(() => {
    const currentDate = DateTime.now();
    const comparisonDate = DateTime.fromISO("2024-01-01");

    if (currentDate < comparisonDate) {
      return comparisonDate.toISODate();
    } else {
      return currentDate.toISODate();
    }
  }, []);

  const [person, setPerson] = useState<VerifierFragmentFragment | null>(
    props.defaultVerifier?.person ?? null
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  const fieldsAreDisabled = person !== null;
  // We have to do this because our data model for verifier doesn't support first and last (in the old land)
  const nameParts = props.defaultVerifier?.fullName?.split(" ") ?? [];
  const defaultFirstName = nameParts[0] ?? "";
  const defaultLastName = nameParts.slice(1).join(" ") ?? "";

  const form = useForm<VerifyAndSubmitFormState>({
    resolver: yupResolver(validationSchema) as any,
    defaultValues: {
      personId: person?.id ?? undefined,
      firstName: person?.first ?? defaultFirstName ?? "",
      lastName: person?.last ?? defaultLastName ?? "",
      title: person?.title ?? props.defaultVerifier?.title ?? "",
      date: defaultDate,
    },
  });

  const handleClose = () => {
    if (props.withConfirmation) {
      setShowConfirmation(false);
    }
    props.onClose();
    setPerson(null);
    form.reset();
  };

  const onVerify = async (formState: VerifyAndSubmitFormState) => {
    if (props.withConfirmation) {
      setShowConfirmation(true);
    } else {
      await props.onSubmit(formState);
      handleClose();
    }
  };
  const onClearPerson = () => {
    setPerson(null);
    form.reset();
  };

  const onSelectPerson = (person: VerifierFragmentFragment | null) => {
    setPerson(person);
    form.setValue("personId", person?.id);
    form.setValue("firstName", person?.first ?? "");
    form.setValue("lastName", person?.last ?? "");
    form.setValue("title", person?.title ?? "");
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle>
        Verify & Submit
        <Tooltip title="Use the verifier table to find a verifier, OR enter one manually">
          <IconButton size="small" style={{ marginLeft: 16 }}>
            <InfoOutlined fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} marginTop={1}>
          <Grid item xs={12} md={6} lg={3}>
            <FormTextField
              name="firstName"
              label="First Name"
              disabled={fieldsAreDisabled}
              control={form.control}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormTextField
              name="lastName"
              label="Last Name"
              disabled={fieldsAreDisabled}
              control={form.control}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormTextField
              name="title"
              label="Title"
              disabled={fieldsAreDisabled}
              control={form.control}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DateField
              name="date"
              label="Date"
              datePickerProps={{
                disabled: fieldsAreDisabled,
              }}
              control={form.control}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <VerifierTable
              onSelectPerson={onSelectPerson}
              disabled={fieldsAreDisabled}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClearPerson} variant="outlined">
          Clear Person
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={form.handleSubmit(onVerify)}
          loading={props.loading}
        >
          Verify & Submit
        </LoadingButton>
      </DialogActions>
      {props.withConfirmation && showConfirmation && (
        <ConfirmationDialog
          loading={props.loading}
          onClose={() => setShowConfirmation(false)}
          onSubmit={(overrideOpenReviews) =>
            form.handleSubmit(async (formState) => {
              await props.onSubmit(formState, overrideOpenReviews);
              handleClose();
            })()
          }
          skippedDueToOpenReviewsCount={props.skippedDueToOpenReviewsCount}
          skippedDueToVerificationCount={props.skippedDueToVerificationCount}
          verifiableRowsWithoutOpenReviewsCount={
            props.verifiableRowsWithoutOpenReviewsCount
          }
        />
      )}
    </Dialog>
  );
};

type ConfirmationProps = {
  loading: boolean;
  onClose: () => void;
  onSubmit: (overrideOpenReviews: boolean) => Promise<void>;
  skippedDueToOpenReviewsCount: number;
  skippedDueToVerificationCount: number;
  verifiableRowsWithoutOpenReviewsCount: number;
};

const ConfirmationDialog = (props: ConfirmationProps) => {
  const [overrideOpenReviews, setOverrideOpenReviews] = useState(false);

  const aboutToVerifyCount =
    props.verifiableRowsWithoutOpenReviewsCount +
    (overrideOpenReviews ? props.skippedDueToOpenReviewsCount : 0);

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <p>
          You are about to verify <b>{aboutToVerifyCount}</b>{" "}
          {pluralize("report", aboutToVerifyCount)}.
        </p>
        {props.skippedDueToVerificationCount ? (
          <p>
            <b>{props.skippedDueToVerificationCount}</b>{" "}
            {pluralize("report", props.skippedDueToVerificationCount)}{" "}
            {pluralize("was", props.skippedDueToVerificationCount)} skipped
            because {props.skippedDueToVerificationCount > 1 ? "they" : "it"}{" "}
            {props.skippedDueToVerificationCount > 1 ? "are" : "is"} not
            awaiting verification.
          </p>
        ) : (
          <></>
        )}
        {props.skippedDueToOpenReviewsCount ? (
          <>
            {!overrideOpenReviews && (
              <p>
                <b>{props.skippedDueToOpenReviewsCount}</b>{" "}
                {pluralize("report", props.skippedDueToOpenReviewsCount)}{" "}
                {pluralize("was", props.skippedDueToOpenReviewsCount)} skipped
                because {props.skippedDueToOpenReviewsCount > 1 ? "they" : "it"}{" "}
                {props.skippedDueToOpenReviewsCount > 1 ? "have" : "has"}{" "}
                pending reviews.
              </p>
            )}
            <FormControlLabel
              sx={{ marginLeft: "0px" }}
              control={
                <Checkbox
                  sx={{ padding: "9px 4px 9px 0px" }}
                  checked={overrideOpenReviews}
                  onChange={() => setOverrideOpenReviews(!overrideOpenReviews)}
                />
              }
              label={
                <Typography fontSize={14} fontWeight={500}>
                  I want to override this safety check and include{" "}
                  {props.skippedDueToOpenReviewsCount}{" "}
                  {pluralize("report", props.skippedDueToOpenReviewsCount)} with
                  pending reviews.
                </Typography>
              }
            />
          </>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={props.loading}
          disabled={aboutToVerifyCount === 0}
          onClick={() => props.onSubmit(overrideOpenReviews)}
          variant="contained"
        >
          Yes, I'm sure
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
