import { Box, Paper, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

export type ChartTooltipProps = PropsWithChildren & {
  title: string;
  labels: {
    color: string;
    value: string;
  }[];
};

export default function ChartTooltip({
  title,
  labels,
  children,
}: ChartTooltipProps) {
  return (
    <Paper sx={{ p: 1.5 }}>
      <Typography variant="body2" fontWeight="800" marginBottom={1}>
        {title}
      </Typography>
      {labels.map((l) => (
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          key={`${l.color}-${l.value}`}
        >
          <Box
            sx={{
              width: "16px",
              height: "16px",
              borderRadius: "2px",
              backgroundColor: l.color,
            }}
          />
          <Typography variant="body2">{l.value}</Typography>
        </Stack>
      ))}
      {children}
    </Paper>
  );
}
