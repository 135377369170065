import {
  ActivityType,
  GetTierIiReportQuery,
} from "../generated-graphql/graphql";

type Activity = NonNullable<
  GetTierIiReportQuery["tierIIReport"]["currentWorkflow"]
>["activities"][0];

export function getActivityTitle(
  activity: Pick<Activity, "description" | "type">
): string {
  const activityDescription = activity.description
    ? ` - ${activity.description}`
    : "";
  return `${convertEnumToHumanReadable(activity.type)}${activityDescription}`;
}

export function convertEnumToHumanReadable(value: string): string {
  return value
    .replace(/([A-Z])/g, " $1") // Insert space before each capital letter
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
}

export const WaitForAllActivityTypes: ActivityType[] = [
  ActivityType.WaitForAllCnReports,
  ActivityType.WaitForAllCredentialReports,
  ActivityType.WaitForAllAltIdReports,
  ActivityType.WaitForAllStateReports,
  ActivityType.WaitForAllFd,
  ActivityType.WaitForAllLepc,
];
