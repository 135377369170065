import { List, ListItem, ListItemText, Stack } from "@mui/material";
import { ExportButton, ExportFileType } from "components/ExportButton";
import { FacilitiesExportButton } from "components/FacilitiesExportButton";
import { Importer } from "components/Importer";
import { ImportButtonWithDialog } from "components/Importer/ImportDialog";
import {
  generateSpreadsheetFilename,
  LongPollingExportButton,
} from "components/LongPollingExportButton";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";

export function ImportExport({ tenantId }: { tenantId: string }) {
  const { tenant } = useTenant();
  const tenantPrefix = tenant?.name ?? tenantId;

  const chemicalInventorySpreadsheetFilename = useMemo(
    () => generateSpreadsheetFilename(tenantPrefix, "chemical-inventory"),
    [tenantPrefix]
  );

  const chemicalMeasurementsSpreadsheetFilename = useMemo(
    () => generateSpreadsheetFilename(tenantPrefix, "chemical-measurements"),
    [tenantPrefix]
  );

  const chemicalCatalogSpreadsheetFilename = useMemo(
    () => generateSpreadsheetFilename(tenantPrefix, "chemical-catalog"),
    [tenantPrefix]
  );

  return (
    <List>
      <ListItem>
        <ListItemText
          primary={`Chemical Catalog`}
          secondary="Chemical, ChemicalComponent, ChemicalStateField"
        />
        <Stack direction="row" spacing={1}>
          <LongPollingExportButton
            key="export"
            disabled={!tenantId}
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/chemical?tenantId=${tenantId}&filename=${chemicalCatalogSpreadsheetFilename}`}
            fileName={chemicalCatalogSpreadsheetFilename}
            tenantId={tenantId}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Chemicals",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Chemical} />
                ),
              },
              {
                name: "Chemical Components",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.ChemicalComponent}
                  />
                ),
              },
              {
                name: "Chemical State Fields",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.ChemicalStateField}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Chemical Inventory - MEASUREMENT mode`}
          secondary=" StorageLocation, FacilityChemicalMeasurement"
        />
        <Stack direction="row" spacing={1}>
          <LongPollingExportButton
            key="export"
            disabled={!tenantId}
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/facility-chemical-measurement?tenantId=${tenantId}&filename=${chemicalMeasurementsSpreadsheetFilename}`}
            fileName={chemicalMeasurementsSpreadsheetFilename}
            tenantId={tenantId}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "Measurements",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityChemicalMeasurement
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Chemical Inventory - AGGREGATE mode`}
          secondary="FacilityChemical, StorageLocation, FacilityChemicalStorageLocation, FacilityChemicalStateField"
        />
        <Stack direction="row" spacing={1}>
          <LongPollingExportButton
            key="export"
            disabled={!tenantId}
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/facility-chemical?tenantId=${tenantId}&filename=${chemicalInventorySpreadsheetFilename}`}
            fileName={chemicalInventorySpreadsheetFilename}
            tenantId={tenantId}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "FacilityChemicals",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityChemical}
                  />
                ),
              },
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "FacilityChemicalStorageLocations",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityChemicalStorageLocation
                    }
                  />
                ),
              },
              {
                name: "Facility Chemical State Fields",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityChemicalStateField
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Product Catalog`}
          secondary="Product, ProductChemical"
        />
        <Stack direction="row" spacing={1}>
          <ExportButton
            key="export"
            disabled={!tenantId}
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/product-catalog?tenantId=${tenantId}`}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Products",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Product} />
                ),
              },
              {
                name: "ProductChemicals",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.ProductChemical}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Product Inventory - MEASUREMENT mode`}
          secondary="StorageLocation, FacilityProductMeasurement"
        />
        <Stack direction="row" spacing={1}>
          <ExportButton
            key="export"
            disabled={!tenantId}
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/facility-product-measurement?tenantId=${tenantId}`}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "FacilityProductMeasurements",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityProductMeasurement
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Product Inventory - AGGREGATE mode`}
          secondary="FacilityProduct, StorageLocation, FacilityProductStorageLocation"
        />
        <Stack direction="row" spacing={1}>
          <ExportButton
            key="export"
            disabled={!tenantId}
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/facility-product?tenantId=${tenantId}`}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "FacilityProducts",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityProduct}
                  />
                ),
              },
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "FacilityProductStorageLocations",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityProductStorageLocation
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Contact Catalog`}
          secondary="Person, FacilityContact"
        />
        <Stack direction="row" spacing={1}>
          <ExportButton
            key="export"
            disabled={!tenantId}
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/contact?tenantId=${tenantId}`}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "People",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Contact} />
                ),
              },
              {
                name: "FacilityContacts",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityContact}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Facilities`}
          secondary="Facility, FacilityStateField, FacilityAlternateId"
        />
        <Stack direction="row" spacing={1}>
          <FacilitiesExportButton key="export" tenantId={tenantId} />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Facilities",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Facility} />
                ),
              },
              {
                name: "Facility State Fields",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityStateField}
                  />
                ),
              },
              {
                name: "Facility Alternate IDs",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityAlternateId}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
    </List>
  );
}
