import { Paper, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { startCase } from "lodash";
import { useMemo } from "react";
import { DataGrid } from "../../../../components/DataGrid";
import ShowAllCopyTooltip from "../../../../components/Typography/ShowAllCopyTooltip";
import { useReportDetails } from "./useReportDetails";

function toTitleCase(input: string): string {
  if (input.startsWith("is")) {
    return startCase(input.slice(2)) + "?";
  }

  return startCase(input);
}

export function Extensions() {
  const { data } = useReportDetails();
  const theme = useTheme();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 1,
        field: "key",
        valueGetter: (params) => toTitleCase(params.row.key),
        headerName: "Question",
      },
      {
        flex: 1,
        field: "value",
        headerName: "Answer",
        renderCell: (params) => {
          if (params.row.value === null) {
            return "";
          } else {
            return (
              <ShowAllCopyTooltip
                text={String(params.row.value)}
              ></ShowAllCopyTooltip>
            );
          }
        },
      },
    ],
    []
  );
  const rows = data?.tierIIReport.facility.stateFields ?? [];

  return (
    <Paper
      sx={{
        padding: 1,
      }}
    >
      <Typography variant="h6">State Fields</Typography>
      {rows.length === 0 ? (
        <Typography
          variant="body1"
          sx={{
            color: "gray",
            padding: theme.spacing(1),
          }}
        >
          None
        </Typography>
      ) : (
        <DataGrid
          columns={columns}
          rows={rows}
          getRowId={(row) => row.key}
          pagination
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "value",
                  sort: "desc",
                },
                { field: "label", sort: "asc" },
              ],
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 15, 50]}
        />
      )}
    </Paper>
  );
}
