import { RefetchQueriesInclude, useQuery } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { DocumentPreview } from "components/DocumentPreview";
import { useDocumentViewer } from "components/DocumentViewer";
import { Dropzone } from "components/Dropzone";
import { FormAutocomplete } from "components/Forms/FormAutocomplete";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { TagPicker } from "components/TagPicker";
import {
  DocumentInput,
  DocumentType,
  FacilityDocumentInput,
  Document as GqlDocument,
  TagInput,
  TagPickerFragment,
  TagType,
} from "generated-graphql/graphql";
import { useUpdateDocumentMutation } from "hooks/documents";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useToken } from "providers/token";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDocumentIssuesForModel } from "routes/Customer/Chemicals/Report/useReport";
import { useDocumentInputValidation } from "routes/Customer/Chemicals/Report/validationHooks/useDocumentInputValidation";
import { documentTypeToLabel } from "util/constants";
import { uploadFile } from "util/uploadFile";
import { MultipleFacilityPicker } from "./MultipleFacilityPicker";

type AddEditDocumentDialogProps = {
  onSubmit?: (
    document: Pick<
      GqlDocument,
      "id" | "fileExtension" | "title" | "documentType"
    >,
    dialogState: AddEditDocumentDialogState
  ) => void;
  dialogState: AddEditDocumentDialogState;
  setDialogState: Dispatch<SetStateAction<AddEditDocumentDialogState>>;
  documentTypeOptions?: DocumentType[];
  activityPickerOptions?: {
    id: string;
    title: string;
  }[];
  tenantId: string;
  reportId?: string;
  showFacilityPicker?: boolean;
  showTagPicker?: boolean;
  refetchQueries: RefetchQueriesInclude;
};

export const defaultAddEditDocumentDialogState: AddEditDocumentDialogState = {
  open: false,
  saving: false,
  step: "Upload",
};

export type AddEditDocumentDialogState = {
  reportId?: string;
  facilities?: FacilityDocumentInput[];
  documentId?: string;
  open: boolean;
  mode?: "Add" | "Edit";
  step?: "Upload" | "Form";
  title?: string;
  documentType?: DocumentType;
  activityId?: string;
  fileExtension?: string | null | undefined;
  selectedFile?: File;
  documentTags?: TagPickerFragment[];
  saving: boolean;
};

export type DocumentFormData = Omit<DocumentInput, "documentTags"> & {
  fileSize?: number;

  // gql-gen generates this attribute with type `InputMaybe<T>` which means it could be null
  // but it can only be undefined or an array of tag inputs
  documentTags?: TagInput[];
};

export function AddEditDocumentDialog({
  onSubmit,
  dialogState,
  setDialogState,
  documentTypeOptions,
  activityPickerOptions,
  tenantId,
  reportId,
  showFacilityPicker,
  showTagPicker,
  refetchQueries,
}: AddEditDocumentDialogProps) {
  const theme = useTheme();
  const alerts = useAlerts();
  const { user } = useCurrentUser();
  const { getIdTokenRefreshIfExpired } = useToken();
  const { fetchAndHandleDownload, clearDownloadLink } = useDocumentViewer();
  const navigate = useNavigate();

  const initialIssues = useDocumentIssuesForModel(dialogState.documentId ?? "");
  const defaultValues: DocumentFormData = useMemo(
    () => ({
      title: dialogState.title ?? "",
      documentType: dialogState.documentType,
      activityId: dialogState.activityId,
      fileExtension: dialogState.fileExtension,
      reportId: dialogState.reportId,
      facilities: dialogState.facilities || [],
      documentTags: dialogState.documentTags ?? [],
    }),
    [
      dialogState.title,
      dialogState.documentType,
      dialogState.activityId,
      dialogState.fileExtension,
      dialogState.reportId,
      dialogState.facilities,
      dialogState.documentTags,
    ]
  );

  const { data } = useQuery(GET_DOCUMENT_DOWNLOAD_LINK_QUERY, {
    variables: { id: dialogState.documentId ?? "" },
    skip: !dialogState.documentId,
  });

  const [updateDocument] = useUpdateDocumentMutation({
    refetchQueries,
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    issues,
    trigger,
    setError,
    clearErrors,
  } = useValidatingForm<DocumentFormData>(
    defaultValues,
    initialIssues,
    useDocumentInputValidation()
  );

  const watchedActivityId = watch("activityId");
  const watchTitle = watch("title");
  const watchDocumentType = watch("documentType");
  const watchFileExtension = watch("fileExtension");

  const isInvalidSds =
    watchDocumentType === DocumentType.SafetyDataSheet &&
    watchFileExtension?.toLowerCase() !== ".pdf";

  const saveDisabled = useMemo(() => {
    return !!issues?.length || isInvalidSds;
  }, [issues, isInvalidSds]);

  useEffect(() => {
    if (watchTitle) {
      const match = /\.[0-9a-z]+$/i.exec(watchTitle);
      const fileExtension = match ? match[0] : "";
      setValue("fileExtension", fileExtension);
    }
  }, [watchTitle, setValue]);

  const activitySelectItems = useMemo(() => {
    if (activityPickerOptions === undefined) {
      return undefined;
    }
    const options = activityPickerOptions.map((v) => ({
      display: v.title,
      value: v.id as string | null,
    }));
    if (watchedActivityId) {
      options.unshift({ display: "None", value: null });
    }
    return options;
  }, [watchedActivityId, activityPickerOptions]);

  useEffect(() => {
    let msg = "";
    const titleAndExtensionIssues = issues?.filter(
      (issue) => issue.key === "title" || issue.key === "fileExtension"
    );

    if (!titleAndExtensionIssues?.length) {
      clearErrors("title");
      return;
    }

    for (const issue of titleAndExtensionIssues ?? []) {
      if (msg === "") {
        msg += issue.message;
      } else {
        msg += "|" + issue.message;
      }
    }

    setError("title", { message: msg });
  }, [issues, setError, clearErrors]);

  const showPdfPreview = useMemo(() => {
    // We show a pdf preview if we have a file AND the file extension is pdf
    // dialogState.selectedFile is set when we are adding a document
    // data?.getDocumentDownloadLink is set when we are editing a document
    // There are both b/c one is a File and the other is a string link, and it's not worth the
    // pain to unify them
    return !!(
      (dialogState.selectedFile || data?.getDocumentDownloadLink) &&
      (watchFileExtension?.toLowerCase() === ".pdf" ||
        dialogState?.fileExtension?.toLowerCase() === "pdf") &&
      dialogState.step === "Form"
    );
  }, [
    dialogState.selectedFile,
    data?.getDocumentDownloadLink,
    watchFileExtension,
    dialogState?.fileExtension,
    dialogState.step,
  ]);

  // Memoize the URL so that the document preview will not unnecessarily reload which causes it to flash
  const fileUrl = useMemo(() => {
    return dialogState.selectedFile
      ? URL.createObjectURL(dialogState.selectedFile ?? new Blob())
      : data?.getDocumentDownloadLink ?? "";
  }, [dialogState.selectedFile, data?.getDocumentDownloadLink]);

  // Wait until we have a user before rendering
  if (!user) {
    return null;
  }

  const removeDocumentIdFromUrl = () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    // Only do this if we are on the documents list page and there is a document id in the url
    if (pathSegments.length === 4 && pathSegments[2] === "documents") {
      pathSegments.pop(); // Remove the last segment

      const newPath = `/${pathSegments.join("/")}${location.search}`;
      navigate(newPath);
    }
  };

  const onClose = () => {
    setDialogState(defaultAddEditDocumentDialogState);
    reset();
    removeDocumentIdFromUrl();
  };

  const documentPickerOptions = (
    documentTypeOptions ?? Object.values(DocumentType)
  )?.map((v) => ({
    label: documentTypeToLabel(v),
    value: v,
  }));

  const handleFileSelect = async (files: File[]) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    const match = /\.[0-9a-z]+$/i.exec(file.name);
    const fileExtension = match ? match[0] : "";

    setDialogState((state) => ({ ...state, selectedFile: file, step: "Form" }));
    setValue("fileExtension", fileExtension);
    setValue("title", file.name);
    setValue("fileSize", file.size);
    await trigger(["title", "fileExtension"]);
  };

  const onAddSubmit: SubmitHandler<DocumentFormData> = async (data) => {
    if (!dialogState.selectedFile) {
      alerts.error("Please select a file");
      return;
    }

    const idToken = await getIdTokenRefreshIfExpired();

    // Since the File type is immutable, create a new file
    // with the name from the form submission
    const file = new File(
      [dialogState.selectedFile],
      data.title ?? dialogState.selectedFile.name,
      { type: dialogState.selectedFile.type }
    );

    try {
      setDialogState((state) => ({ ...state, saving: true }));
      const document = await uploadFile(
        file,
        {
          tenantId: tenantId ?? "",
          userId: user.id,
          facilities: data.facilities || [],
          reportId,
          documentType: data.documentType ?? DocumentType.Other,
          description: file.name,
          activityId: data.activityId ?? undefined,
          documentTags: data.documentTags,
        },
        idToken
      );
      onClose();
      if (onSubmit) {
        onSubmit(document, dialogState);
      }
      reset();
      // refetch(); refetch was here for refetching the report, might not need?
      alerts.success("File successfully uploaded");
    } catch (err) {
      console.error(`${err}`);
      alerts.error("An error occurred while adding the document");
      setDialogState((state) => ({ ...state, saving: false }));
    }
  };

  const onEditSubmit: SubmitHandler<DocumentFormData> = async (formData) => {
    if (!dialogState.documentId) {
      return;
    }

    let document: GqlDocument | undefined;
    try {
      if (dialogState?.selectedFile) {
        const idToken = await getIdTokenRefreshIfExpired();
        // Since the File type is immutable, create a new file
        // with the name from the form submission
        const file = new File(
          [dialogState.selectedFile],
          formData.title ?? dialogState.selectedFile.name,
          { type: dialogState.selectedFile.type }
        );

        setDialogState((state) => ({ ...state, saving: true }));
        document = await uploadFile(
          file,
          {
            tenantId: tenantId ?? "",
            userId: user.id,
            facilities: formData.facilities || [],
            reportId,
            documentType: formData.documentType ?? DocumentType.Other,
            description: file.name,
            activityId: formData.activityId ?? undefined,
            documentId: dialogState.documentId ?? undefined,
            documentTags: formData.documentTags,
          },
          idToken
        );
      } else {
        setDialogState((state) => ({ ...state, saving: true }));
        const updateResult = await updateDocument({
          variables: {
            id: dialogState.documentId,
            input: {
              tenantId: tenantId ?? "",
              reportId,
              documentType: formData.documentType,
              title: formData.title,
              activityId: formData.activityId,
              facilities: formData.facilities || [],
              documentTags: formData.documentTags,
            },
          },
        });
        document = updateResult.data?.updateDocument;
      }

      onClose();
      if (onSubmit) {
        onSubmit(
          document ?? {
            id: dialogState.documentId,
            title: formData.title ?? dialogState?.selectedFile?.name ?? "",
            documentType: formData.documentType ?? DocumentType.Other,
            fileExtension: formData.fileExtension ?? "",
          },
          dialogState
        );
      }
      reset();
      alerts.success("File successfully edited");
    } catch (err) {
      console.error(`${err}`);
      alerts.error("An error occurred while editing the document");
      setDialogState((state) => ({ ...state, saving: false }));
    }
  };

  return (
    <Dialog
      open={dialogState.open}
      onClose={onClose}
      fullWidth
      maxWidth={showPdfPreview ? "lg" : "xs"}
      sx={{ minHeight: theme.spacing(59) }}
    >
      <form
        onSubmit={handleSubmit(
          dialogState.mode === "Add" ? onAddSubmit : onEditSubmit
        )}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {dialogState.mode && `${dialogState.mode} Document`}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {dialogState.step === "Upload" ? (
            <Dropzone
              label={"Drag and drop your document or click to select file"}
              dragActiveText="Drop the file here"
              onDrop={(acceptedFiles) => {
                handleFileSelect(acceptedFiles);
              }}
              sx={{ minWidth: theme.spacing(48), minHeight: theme.spacing(29) }}
            />
          ) : (
            <Stack
              spacing={theme.spacing(3)}
              direction="row"
              justifyContent="space-between"
            >
              <Stack gap={theme.spacing(1)} sx={{ flex: 1 }}>
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={11}>
                    <FormTextField
                      name="title"
                      label="Filename *"
                      control={control}
                      sx={{ marginTop: theme.spacing(1) }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: theme.spacing(2.5),
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setDialogState((state) => ({
                          ...state,
                          step: "Upload",
                          selectedFile: undefined,
                          fileExtension: undefined,
                        }));
                      }}
                    >
                      <FindReplaceIcon color="primary" fontSize="medium" />
                    </IconButton>
                  </Grid>
                </Grid>
                <FormTextField
                  name="fileExtension"
                  sx={{ display: "none" }}
                  label="File Extension"
                  control={control}
                />
                {issues?.find((issue) => issue.key === "fileSize") && (
                  <Typography
                    color="error"
                    sx={{ mt: 0, pt: 0, mb: theme.spacing(1) }}
                  >
                    {issues?.find((issue) => issue.key === "fileSize")?.message}
                  </Typography>
                )}
                <FormAutocomplete
                  label="Type *"
                  control={control}
                  name="documentType"
                  autocompleteItems={documentPickerOptions}
                />
                {isInvalidSds && (
                  <Typography
                    color="error"
                    sx={{ mt: 0, pt: 0, mb: theme.spacing(2) }}
                  >
                    Safety Data Sheets must be in PDF format
                  </Typography>
                )}
                {activitySelectItems && (
                  <FormSelect
                    name="activityId"
                    label="Activity"
                    control={control}
                    selectItems={activitySelectItems}
                  />
                )}
                {showTagPicker && (
                  <Controller
                    name="documentTags"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TagPicker
                        {...field}
                        {...fieldState}
                        tagType={TagType.Document}
                        label="Tags"
                      />
                    )}
                  />
                )}
                <Controller
                  name="facilities"
                  control={control}
                  render={({ field, fieldState }) => (
                    <MultipleFacilityPicker
                      {...field}
                      {...fieldState}
                      sx={{
                        display: showFacilityPicker ? "block" : "none",
                        minHeight: theme.spacing(16.5),
                      }}
                      value={
                        defaultValues.facilities?.map((f) => {
                          return { id: f.id ?? "", name: f.name ?? "" };
                        }) ?? []
                      }
                      defaultSearchTerm={`tenantId:${tenantId}`}
                      lazyLoad={false}
                      label="Facilities"
                      onChange={(facilities) => {
                        field.onChange(facilities ?? []);
                      }}
                    />
                  )}
                />
              </Stack>
              {showPdfPreview && (
                <Box
                  sx={{
                    flex: 2,
                    position: "sticky",
                    height: "48vh",
                  }}
                >
                  <DocumentPreview fileUrl={fileUrl} />
                </Box>
              )}
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={{ p: theme.spacing(2) }}>
          <Stack
            direction="row"
            justifyContent={
              dialogState.documentId ? "space-between" : "flex-end"
            }
            flex={1}
          >
            {dialogState.documentId && (
              <Button
                onClick={async () => {
                  await fetchAndHandleDownload({
                    documentId: dialogState.documentId ?? "",
                  });
                  clearDownloadLink();
                }}
                endIcon={<Download />}
              >
                Download
              </Button>
            )}
            <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
              <SaveButton
                loading={dialogState.saving}
                disabled={saveDisabled}
              />
            </Box>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
}
