export interface Subsector {
  label: string;
  code: string;
  industries?: Industry[];
}

export interface Industry {
  label: string;
  code: string;
}

export const subsectors: Array<{
  label: string;
  code: string;
  industries?: Array<{ label: string; code: string }>;
}> = [
  {
    label: "Crop Production",
    code: "111",
    industries: [
      {
        label: "Soybean Farming",
        code: "11111",
      },
      {
        label: "Oilseed (except Soybean) Farming",
        code: "11112",
      },
      {
        label: "Dry Pea and Bean Farming",
        code: "11113",
      },
      {
        label: "Wheat Farming",
        code: "11114",
      },
      {
        label: "Corn Farming",
        code: "11115",
      },
      {
        label: "Rice Farming",
        code: "11116",
      },
      {
        label: "Other Grain Farming",
        code: "11119",
      },
      {
        label: "Vegetable and Melon Farming",
        code: "11121",
      },
      {
        label: "Orange Groves",
        code: "11131",
      },
      {
        label: "Citrus (except Orange) Groves",
        code: "11132",
      },
      {
        label: "Noncitrus Fruit and Tree Nut Farming",
        code: "11133",
      },
      {
        label: "Food Crops Grown Under Cover",
        code: "11141",
      },
      {
        label: "Nursery and Floriculture Production",
        code: "11142",
      },
      {
        label: "Tobacco Farming",
        code: "11191",
      },
      {
        label: "Cotton Farming",
        code: "11192",
      },
      {
        label: "Sugarcane Farming",
        code: "11193",
      },
      {
        label: "Hay Farming",
        code: "11194",
      },
      {
        label: "All Other Crop Farming",
        code: "11199",
      },
    ],
  },
  {
    label: "Animal Production and Aquaculture",
    code: "112",
    industries: [
      {
        label: "Beef Cattle Ranching and Farming, including Feedlots",
        code: "11211",
      },
      {
        label: "Dairy Cattle and Milk Production",
        code: "11212",
      },
      {
        label: "Dual-Purpose Cattle Ranching and Farming",
        code: "11213",
      },
      {
        label: "Hog and Pig Farming",
        code: "11221",
      },
      {
        label: "Chicken Egg Production",
        code: "11231",
      },
      {
        label: "Broilers and Other Meat Type Chicken Production",
        code: "11232",
      },
      {
        label: "Turkey Production",
        code: "11233",
      },
      {
        label: "Poultry Hatcheries",
        code: "11234",
      },
      {
        label: "Other Poultry Production",
        code: "11239",
      },
      {
        label: "Sheep Farming",
        code: "11241",
      },
      {
        label: "Goat Farming",
        code: "11242",
      },
      {
        label: "Aquaculture",
        code: "11251",
      },
      {
        label: "Apiculture",
        code: "11291",
      },
      {
        label: "Horses and Other Equine Production",
        code: "11292",
      },
      {
        label: "Fur-Bearing Animal and Rabbit Production",
        code: "11293",
      },
      {
        label: "All Other Animal Production",
        code: "11299",
      },
    ],
  },
  {
    label: "Forestry and Logging",
    code: "113",
    industries: [
      {
        label: "Timber Tract Operations",
        code: "11311",
      },
      {
        label: "Forest Nurseries and Gathering of Forest Products",
        code: "11321",
      },
      {
        label: "Logging",
        code: "11331",
      },
    ],
  },
  {
    label: "Fishing, Hunting and Trapping",
    code: "114",
    industries: [
      {
        label: "Fishing",
        code: "11411",
      },
      {
        label: "Hunting and Trapping",
        code: "11421",
      },
    ],
  },
  {
    label: "Support Activities for Agriculture and Forestry",
    code: "115",
    industries: [
      {
        label: "Support Activities for Crop Production",
        code: "11511",
      },
      {
        label: "Support Activities for Animal Production",
        code: "11521",
      },
      {
        label: "Support Activities for Forestry",
        code: "11531",
      },
    ],
  },
  {
    label: "Oil and Gas Extraction",
    code: "211",
    industries: [
      {
        label: "Crude Petroleum Extraction",
        code: "21112",
      },
      {
        label: "Natural Gas Extraction",
        code: "21113",
      },
    ],
  },
  {
    label: "Mining (except Oil and Gas)",
    code: "212",
    industries: [
      {
        label: "Coal Mining",
        code: "21211",
      },
      {
        label: "Iron Ore Mining",
        code: "21221",
      },
      {
        label: "Gold Ore and Silver Ore Mining",
        code: "21222",
      },
      {
        label: "Copper, Nickel, Lead, and Zinc Mining",
        code: "21223",
      },
      {
        label: "Other Metal Ore Mining",
        code: "21229",
      },
      {
        label: "Stone Mining and Quarrying",
        code: "21231",
      },
      {
        label:
          "Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying",
        code: "21232",
      },
      {
        label: "Other Nonmetallic Mineral Mining and Quarrying",
        code: "21239",
      },
    ],
  },
  {
    label: "Support Activities for Mining",
    code: "213",
    industries: [
      {
        label: "Support Activities for Mining",
        code: "21311",
      },
    ],
  },
  {
    label: "Utilities ",
    code: "221",
    industries: [
      {
        label: "Electric Power Generation ",
        code: "22111",
      },
      {
        label: "Electric Power Transmission, Control, and Distribution ",
        code: "22112",
      },
      {
        label: "Natural Gas Distribution ",
        code: "22121",
      },
      {
        label: "Water Supply and Irrigation Systems ",
        code: "22131",
      },
      {
        label: "Sewage Treatment Facilities ",
        code: "22132",
      },
      {
        label: "Steam and Air-Conditioning Supply ",
        code: "22133",
      },
    ],
  },
  {
    label: "Construction of Buildings",
    code: "236",
    industries: [
      {
        label: "Residential Building Construction",
        code: "23611",
      },
      {
        label: "Industrial Building Construction",
        code: "23621",
      },
      {
        label: "Commercial and Institutional Building Construction",
        code: "23622",
      },
    ],
  },
  {
    label: "Heavy and Civil Engineering Construction",
    code: "237",
    industries: [
      {
        label: "Water and Sewer Line and Related Structures Construction",
        code: "23711",
      },
      {
        label: "Oil and Gas Pipeline and Related Structures Construction",
        code: "23712",
      },
      {
        label:
          "Power and Communication Line and Related Structures Construction",
        code: "23713",
      },
      {
        label: "Land Subdivision",
        code: "23721",
      },
      {
        label: "Highway, Street, and Bridge Construction",
        code: "23731",
      },
      {
        label: "Other Heavy and Civil Engineering Construction",
        code: "23799",
      },
    ],
  },
  {
    label: "Specialty Trade Contractors",
    code: "238",
    industries: [
      {
        label: "Poured Concrete Foundation and Structure Contractors ",
        code: "23811",
      },
      {
        label: "Structural Steel and Precast Concrete Contractors ",
        code: "23812",
      },
      {
        label: "Framing Contractors ",
        code: "23813",
      },
      {
        label: "Masonry Contractors ",
        code: "23814",
      },
      {
        label: "Glass and Glazing Contractors ",
        code: "23815",
      },
      {
        label: "Roofing Contractors ",
        code: "23816",
      },
      {
        label: "Siding Contractors ",
        code: "23817",
      },
      {
        label:
          "Other Foundation, Structure, and Building Exterior Contractors ",
        code: "23819",
      },
      {
        label:
          "Electrical Contractors and Other Wiring Installation Contractors",
        code: "23821",
      },
      {
        label: "Plumbing, Heating, and Air-Conditioning Contractors",
        code: "23822",
      },
      {
        label: "Other Building Equipment Contractors",
        code: "23829",
      },
      {
        label: "Drywall and Insulation Contractors",
        code: "23831",
      },
      {
        label: "Painting and Wall Covering Contractors",
        code: "23832",
      },
      {
        label: "Flooring Contractors",
        code: "23833",
      },
      {
        label: "Tile and Terrazzo Contractors",
        code: "23834",
      },
      {
        label: "Finish Carpentry Contractors",
        code: "23835",
      },
      {
        label: "Other Building Finishing Contractors",
        code: "23839",
      },
      {
        label: "Site Preparation Contractors",
        code: "23891",
      },
      {
        label: "All Other Specialty Trade Contractors",
        code: "23899",
      },
      {
        label: "Manufacturing",
        code: "31-33",
      },
    ],
  },
  {
    label: "Food Manufacturing",
    code: "311",
    industries: [
      {
        label: "Animal Food Manufacturing",
        code: "31111",
      },
      {
        label: "Flour Milling and Malt Manufacturing",
        code: "31121",
      },
      {
        label: "Starch and Vegetable Fats and Oils Manufacturing",
        code: "31122",
      },
      {
        label: "Breakfast Cereal Manufacturing",
        code: "31123",
      },
      {
        label: "Sugar Manufacturing",
        code: "31131",
      },
      {
        label: "Nonchocolate Confectionery Manufacturing",
        code: "31134",
      },
      {
        label: "Chocolate and Confectionery Manufacturing",
        code: "31135",
      },
      {
        label: "Frozen Food Manufacturing",
        code: "31141",
      },
      {
        label: "Fruit and Vegetable Canning, Pickling, and Drying",
        code: "31142",
      },
      {
        label: "Dairy Product (except Frozen) Manufacturing",
        code: "31151",
      },
      {
        label: "Ice Cream and Frozen Dessert Manufacturing",
        code: "31152",
      },
      {
        label: "Animal Slaughtering and Processing",
        code: "31161",
      },
      {
        label: "Seafood Product Preparation and Packaging",
        code: "31171",
      },
      {
        label: "Bread and Bakery Product Manufacturing",
        code: "31181",
      },
      {
        label: "Cookie, Cracker, and Pasta Manufacturing",
        code: "31182",
      },
      {
        label: "Tortilla Manufacturing",
        code: "31183",
      },
      {
        label: "Snack Food Manufacturing",
        code: "31191",
      },
      {
        label: "Coffee and Tea Manufacturing",
        code: "31192",
      },
      {
        label: "Flavoring Syrup and Concentrate Manufacturing",
        code: "31193",
      },
      {
        label: "Seasoning and Dressing Manufacturing",
        code: "31194",
      },
      {
        label: "All Other Food Manufacturing",
        code: "31199",
      },
    ],
  },
  {
    label: "Beverage and Tobacco Product Manufacturing",
    code: "312",
    industries: [
      {
        label: "Soft Drink and Ice Manufacturing",
        code: "31211",
      },
      {
        label: "Breweries",
        code: "31212",
      },
      {
        label: "Wineries",
        code: "31213",
      },
      {
        label: "Distilleries",
        code: "31214",
      },
      {
        label: "Tobacco Manufacturing",
        code: "31223",
      },
    ],
  },
  {
    label: "Textile Mills",
    code: "313",
    industries: [
      {
        label: "Fiber, Yarn, and Thread Mills",
        code: "31311",
      },
      {
        label: "Broadwoven Fabric Mills",
        code: "31321",
      },
      {
        label: "Narrow Fabric Mills and Schiffli Machine Embroidery",
        code: "31322",
      },
      {
        label: "Nonwoven Fabric Mills",
        code: "31323",
      },
      {
        label: "Knit Fabric Mills",
        code: "31324",
      },
      {
        label: "Textile and Fabric Finishing Mills",
        code: "31331",
      },
      {
        label: "Fabric Coating Mills",
        code: "31332",
      },
    ],
  },
  {
    label: "Textile Product Mills",
    code: "314",
    industries: [
      {
        label: "Carpet and Rug Mills",
        code: "31411",
      },
      {
        label: "Curtain and Linen Mills",
        code: "31412",
      },
      {
        label: "Textile Bag and Canvas Mills",
        code: "31491",
      },
      {
        label: "All Other Textile Product Mills",
        code: "31499",
      },
    ],
  },
  {
    label: "Apparel Manufacturing",
    code: "315",
    industries: [
      {
        label: "Apparel Knitting Mills",
        code: "31512",
      },
      {
        label: "Cut and Sew Apparel Contractors ",
        code: "31521",
      },
      {
        label: "Cut and Sew Apparel Manufacturing (except Contractors) ",
        code: "31525",
      },
      {
        label: "Apparel Accessories and Other Apparel Manufacturing",
        code: "31599",
      },
    ],
  },
  {
    label: "Leather and Allied Product Manufacturing",
    code: "316",
    industries: [
      {
        label: "Leather and Hide Tanning and Finishing",
        code: "31611",
      },
      {
        label: "Footwear Manufacturing",
        code: "31621",
      },
      {
        label: "Other Leather and Allied Product Manufacturing",
        code: "31699",
      },
    ],
  },
  {
    label: "Wood Product Manufacturing",
    code: "321",
    industries: [
      {
        label: "Sawmills and Wood Preservation",
        code: "32111",
      },
      {
        label: "Veneer, Plywood, and Engineered Wood Product Manufacturing",
        code: "32121",
      },
      {
        label: "Millwork",
        code: "32191",
      },
      {
        label: "Wood Container and Pallet Manufacturing",
        code: "32192",
      },
      {
        label: "All Other Wood Product Manufacturing",
        code: "32199",
      },
    ],
  },
  {
    label: "Paper Manufacturing",
    code: "322",
    industries: [
      {
        label: "Pulp Mills",
        code: "32211",
      },
      {
        label: "Paper Mills",
        code: "32212",
      },
      {
        label: "Paperboard Mills",
        code: "32213",
      },
      {
        label: "Paperboard Container Manufacturing",
        code: "32221",
      },
      {
        label: "Paper Bag and Coated and Treated Paper Manufacturing",
        code: "32222",
      },
      {
        label: "Stationery Product Manufacturing",
        code: "32223",
      },
      {
        label: "Other Converted Paper Product Manufacturing",
        code: "32229",
      },
    ],
  },
  {
    label: "Printing and Related Support Activities",
    code: "323",
    industries: [
      {
        label: "Printing",
        code: "32311",
      },
      {
        label: "Support Activities for Printing",
        code: "32312",
      },
    ],
  },
  {
    label: "Petroleum and Coal Products Manufacturing",
    code: "324",
    industries: [
      {
        label: "Petroleum Refineries",
        code: "32411",
      },
      {
        label: "Asphalt Paving, Roofing, and Saturated Materials Manufacturing",
        code: "32412",
      },
      {
        label: "Other Petroleum and Coal Products Manufacturing",
        code: "32419",
      },
    ],
  },
  {
    label: "Chemical Manufacturing",
    code: "325",
    industries: [
      {
        label: "Petrochemical Manufacturing",
        code: "32511",
      },
      {
        label: "Industrial Gas Manufacturing",
        code: "32512",
      },
      {
        label: "Synthetic Dye and Pigment Manufacturing",
        code: "32513",
      },
      {
        label: "Other Basic Inorganic Chemical Manufacturing",
        code: "32518",
      },
      {
        label: "Other Basic Organic Chemical Manufacturing",
        code: "32519",
      },
      {
        label: "Resin and Synthetic Rubber Manufacturing",
        code: "32521",
      },
      {
        label: "Artificial and Synthetic Fibers and Filaments Manufacturing",
        code: "32522",
      },
      {
        label: "Fertilizer and Compost Manufacturing",
        code: "32531",
      },
      {
        label: "Pesticide and Other Agricultural Chemical Manufacturing",
        code: "32532",
      },
      {
        label: "Pharmaceutical and Medicine Manufacturing",
        code: "32541",
      },
      {
        label: "Paint and Coating Manufacturing",
        code: "32551",
      },
      {
        label: "Adhesive Manufacturing",
        code: "32552",
      },
      {
        label: "Soap and Cleaning Compound Manufacturing",
        code: "32561",
      },
      {
        label: "Toilet Preparation Manufacturing",
        code: "32562",
      },
      {
        label: "Printing Ink Manufacturing",
        code: "32591",
      },
      {
        label: "Explosives Manufacturing",
        code: "32592",
      },
      {
        label: "All Other Chemical Product and Preparation Manufacturing",
        code: "32599",
      },
    ],
  },
  {
    label: "Plastics and Rubber Products Manufacturing",
    code: "326",
    industries: [
      {
        label:
          "Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing",
        code: "32611",
      },
      {
        label:
          "Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing",
        code: "32612",
      },
      {
        label:
          "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing",
        code: "32613",
      },
      {
        label: "Polystyrene Foam Product Manufacturing",
        code: "32614",
      },
      {
        label:
          "Urethane and Other Foam Product (except Polystyrene) Manufacturing",
        code: "32615",
      },
      {
        label: "Plastics Bottle Manufacturing",
        code: "32616",
      },
      {
        label: "Other Plastics Product Manufacturing",
        code: "32619",
      },
      {
        label: "Tire Manufacturing",
        code: "32621",
      },
      {
        label: "Rubber and Plastics Hoses and Belting Manufacturing",
        code: "32622",
      },
      {
        label: "Other Rubber Product Manufacturing",
        code: "32629",
      },
    ],
  },
  {
    label: "Nonmetallic Mineral Product Manufacturing",
    code: "327",
    industries: [
      {
        label: "Pottery, Ceramics, and Plumbing Fixture Manufacturing",
        code: "32711",
      },
      {
        label: "Clay Building Material and Refractories Manufacturing",
        code: "32712",
      },
      {
        label: "Glass and Glass Product Manufacturing",
        code: "32721",
      },
      {
        label: "Cement Manufacturing",
        code: "32731",
      },
      {
        label: "Ready-Mix Concrete Manufacturing",
        code: "32732",
      },
      {
        label: "Concrete Pipe, Brick, and Block Manufacturing",
        code: "32733",
      },
      {
        label: "Other Concrete Product Manufacturing",
        code: "32739",
      },
      {
        label: "Lime Manufacturing",
        code: "32741",
      },
      {
        label: "Gypsum Product Manufacturing",
        code: "32742",
      },
      {
        label: "Abrasive Product Manufacturing",
        code: "32791",
      },
      {
        label: "All Other Nonmetallic Mineral Product Manufacturing",
        code: "32799",
      },
    ],
  },
  {
    label: "Primary Metal Manufacturing",
    code: "331",
    industries: [
      {
        label: "Iron and Steel Mills and Ferroalloy Manufacturing",
        code: "33111",
      },
      {
        label:
          "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel",
        code: "33121",
      },
      {
        label: "Rolling and Drawing of Purchased Steel",
        code: "33122",
      },
      {
        label: "Alumina and Aluminum Production and Processing",
        code: "33131",
      },
      {
        label: "Nonferrous Metal (except Aluminum) Smelting and Refining",
        code: "33141",
      },
      {
        label: "Copper Rolling, Drawing, Extruding, and Alloying",
        code: "33142",
      },
      {
        label:
          "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying",
        code: "33149",
      },
      {
        label: "Ferrous Metal Foundries",
        code: "33151",
      },
      {
        label: "Nonferrous Metal Foundries",
        code: "33152",
      },
    ],
  },
  {
    label: "Fabricated Metal Product Manufacturing",
    code: "332",
    industries: [
      {
        label: "Forging and Stamping",
        code: "33211",
      },
      {
        label: "Cutlery and Handtool Manufacturing",
        code: "33221",
      },
      {
        label: "Plate Work and Fabricated Structural Product Manufacturing",
        code: "33231",
      },
      {
        label: "Ornamental and Architectural Metal Products Manufacturing",
        code: "33232",
      },
      {
        label: "Power Boiler and Heat Exchanger Manufacturing",
        code: "33241",
      },
      {
        label: "Metal Tank (Heavy Gauge) Manufacturing",
        code: "33242",
      },
      {
        label:
          "Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing",
        code: "33243",
      },
      {
        label: "Hardware Manufacturing",
        code: "33251",
      },
      {
        label: "Spring and Wire Product Manufacturing",
        code: "33261",
      },
      {
        label: "Machine Shops",
        code: "33271",
      },
      {
        label: "Turned Product and Screw, Nut, and Bolt Manufacturing",
        code: "33272",
      },
      {
        label: "Coating, Engraving, Heat Treating, and Allied Activities",
        code: "33281",
      },
      {
        label: "Metal Valve Manufacturing",
        code: "33291",
      },
      {
        label: "All Other Fabricated Metal Product Manufacturing",
        code: "33299",
      },
    ],
  },
  {
    label: "Machinery Manufacturing",
    code: "333",
    industries: [
      {
        label: "Agricultural Implement Manufacturing",
        code: "33311",
      },
      {
        label: "Construction Machinery Manufacturing",
        code: "33312",
      },
      {
        label: "Mining and Oil and Gas Field Machinery Manufacturing",
        code: "33313",
      },
      {
        label: "Industrial Machinery Manufacturing",
        code: "33324",
      },
      {
        label: "Commercial and Service Industry Machinery Manufacturing",
        code: "33331",
      },
      {
        label:
          "Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing",
        code: "33341",
      },
      {
        label: "Metalworking Machinery Manufacturing",
        code: "33351",
      },
      {
        label:
          "Engine, Turbine, and Power Transmission Equipment Manufacturing",
        code: "33361",
      },
      {
        label: "Pump and Compressor Manufacturing",
        code: "33391",
      },
      {
        label: "Material Handling Equipment Manufacturing",
        code: "33392",
      },
      {
        label: "All Other General Purpose Machinery Manufacturing",
        code: "33399",
      },
    ],
  },
  {
    label: "Computer and Electronic Product Manufacturing",
    code: "334",
    industries: [
      {
        label: "Computer and Peripheral Equipment Manufacturing",
        code: "33411",
      },
      {
        label: "Telephone Apparatus Manufacturing",
        code: "33421",
      },
      {
        label:
          "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",
        code: "33422",
      },
      {
        label: "Other Communications Equipment Manufacturing",
        code: "33429",
      },
      {
        label: "Audio and Video Equipment Manufacturing",
        code: "33431",
      },
      {
        label: "Semiconductor and Other Electronic Component Manufacturing",
        code: "33441",
      },
      {
        label:
          "Navigational, Measuring, Electromedical, and Control Instruments Manufacturing",
        code: "33451",
      },
      {
        label: "Manufacturing and Reproducing Magnetic and Optical Media",
        code: "33461",
      },
    ],
  },
  {
    label: "Electrical Equipment, Appliance, and Component Manufacturing",
    code: "335",
    industries: [
      {
        label: "Electric Lighting Equipment Manufacturing",
        code: "33513",
      },
      {
        label: "Small Electrical Appliance Manufacturing",
        code: "33521",
      },
      {
        label: "Major Household Appliance Manufacturing ",
        code: "33522",
      },
      {
        label: "Electrical Equipment Manufacturing",
        code: "33531",
      },
      {
        label: "Battery Manufacturing",
        code: "33591",
      },
      {
        label: "Communication and Energy Wire and Cable Manufacturing",
        code: "33592",
      },
      {
        label: "Wiring Device Manufacturing",
        code: "33593",
      },
      {
        label: "All Other Electrical Equipment and Component Manufacturing",
        code: "33599",
      },
    ],
  },
  {
    label: "Transportation Equipment Manufacturing",
    code: "336",
    industries: [
      {
        label: "Automobile and Light Duty Motor Vehicle Manufacturing",
        code: "33611",
      },
      {
        label: "Heavy Duty Truck Manufacturing",
        code: "33612",
      },
      {
        label: "Motor Vehicle Body and Trailer Manufacturing",
        code: "33621",
      },
      {
        label: "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",
        code: "33631",
      },
      {
        label:
          "Motor Vehicle Electrical and Electronic Equipment Manufacturing",
        code: "33632",
      },
      {
        label:
          "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",
        code: "33633",
      },
      {
        label: "Motor Vehicle Brake System Manufacturing",
        code: "33634",
      },
      {
        label: "Motor Vehicle Transmission and Power Train Parts Manufacturing",
        code: "33635",
      },
      {
        label: "Motor Vehicle Seating and Interior Trim Manufacturing",
        code: "33636",
      },
      {
        label: "Motor Vehicle Metal Stamping",
        code: "33637",
      },
      {
        label: "Other Motor Vehicle Parts Manufacturing",
        code: "33639",
      },
      {
        label: "Aerospace Product and Parts Manufacturing",
        code: "33641",
      },
      {
        label: "Railroad Rolling Stock Manufacturing",
        code: "33651",
      },
      {
        label: "Ship and Boat Building",
        code: "33661",
      },
      {
        label: "Other Transportation Equipment Manufacturing",
        code: "33699",
      },
    ],
  },
  {
    label: "Furniture and Related Product Manufacturing",
    code: "337",
    industries: [
      {
        label: "Wood Kitchen Cabinet and Countertop Manufacturing",
        code: "33711",
      },
      {
        label: "Household and Institutional Furniture Manufacturing",
        code: "33712",
      },
      {
        label: "Office Furniture (including Fixtures) Manufacturing",
        code: "33721",
      },
      {
        label: "Mattress Manufacturing",
        code: "33791",
      },
      {
        label: "Blind and Shade Manufacturing",
        code: "33792",
      },
    ],
  },
  {
    label: "Miscellaneous Manufacturing",
    code: "339",
    industries: [
      {
        label: "Medical Equipment and Supplies Manufacturing",
        code: "33911",
      },
      {
        label: "Jewelry and Silverware Manufacturing",
        code: "33991",
      },
      {
        label: "Sporting and Athletic Goods Manufacturing",
        code: "33992",
      },
      {
        label: "Doll, Toy, and Game Manufacturing",
        code: "33993",
      },
      {
        label: "Office Supplies (except Paper) Manufacturing",
        code: "33994",
      },
      {
        label: "Sign Manufacturing",
        code: "33995",
      },
      {
        label: "All Other Miscellaneous Manufacturing",
        code: "33999",
      },
    ],
  },
  {
    label: "Merchant Wholesalers, Durable Goods ",
    code: "423",
    industries: [
      {
        label: "Automobile and Other Motor Vehicle Merchant Wholesalers ",
        code: "42311",
      },
      {
        label: "Motor Vehicle Supplies and New Parts Merchant Wholesalers ",
        code: "42312",
      },
      {
        label: "Tire and Tube Merchant Wholesalers ",
        code: "42313",
      },
      {
        label: "Motor Vehicle Parts (Used) Merchant Wholesalers ",
        code: "42314",
      },
      {
        label: "Furniture Merchant Wholesalers ",
        code: "42321",
      },
      {
        label: "Home Furnishing Merchant Wholesalers ",
        code: "42322",
      },
      {
        label:
          "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers ",
        code: "42331",
      },
      {
        label:
          "Brick, Stone, and Related Construction Material Merchant Wholesalers ",
        code: "42332",
      },
      {
        label: "Roofing, Siding, and Insulation Material Merchant Wholesalers ",
        code: "42333",
      },
      {
        label: "Other Construction Material Merchant Wholesalers ",
        code: "42339",
      },
      {
        label: "Photographic Equipment and Supplies Merchant Wholesalers ",
        code: "42341",
      },
      {
        label: "Office Equipment Merchant Wholesalers ",
        code: "42342",
      },
      {
        label:
          "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ",
        code: "42343",
      },
      {
        label: "Other Commercial Equipment Merchant Wholesalers ",
        code: "42344",
      },
      {
        label:
          "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers ",
        code: "42345",
      },
      {
        label: "Ophthalmic Goods Merchant Wholesalers ",
        code: "42346",
      },
      {
        label:
          "Other Professional Equipment and Supplies Merchant Wholesalers ",
        code: "42349",
      },
      {
        label: "Metal Service Centers and Other Metal Merchant Wholesalers ",
        code: "42351",
      },
      {
        label: "Coal and Other Mineral and Ore Merchant Wholesalers ",
        code: "42352",
      },
      {
        label:
          "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers ",
        code: "42361",
      },
      {
        label:
          "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers ",
        code: "42362",
      },
      {
        label: "Other Electronic Parts and Equipment Merchant Wholesalers ",
        code: "42369",
      },
      {
        label: "Hardware Merchant Wholesalers ",
        code: "42371",
      },
      {
        label:
          "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ",
        code: "42372",
      },
      {
        label:
          "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ",
        code: "42373",
      },
      {
        label: "Refrigeration Equipment and Supplies Merchant Wholesalers ",
        code: "42374",
      },
      {
        label:
          "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ",
        code: "42381",
      },
      {
        label: "Farm and Garden Machinery and Equipment Merchant Wholesalers ",
        code: "42382",
      },
      {
        label: "Industrial Machinery and Equipment Merchant Wholesalers ",
        code: "42383",
      },
      {
        label: "Industrial Supplies Merchant Wholesalers ",
        code: "42384",
      },
      {
        label:
          "Service Establishment Equipment and Supplies Merchant Wholesalers ",
        code: "42385",
      },
      {
        label:
          "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ",
        code: "42386",
      },
      {
        label:
          "Sporting and Recreational Goods and Supplies Merchant Wholesalers",
        code: "42391",
      },
      {
        label: "Toy and Hobby Goods and Supplies Merchant Wholesalers ",
        code: "42392",
      },
      {
        label: "Recyclable Material Merchant Wholesalers ",
        code: "42393",
      },
      {
        label:
          "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers ",
        code: "42394",
      },
      {
        label: "Other Miscellaneous Durable Goods Merchant Wholesalers ",
        code: "42399",
      },
    ],
  },
  {
    label: "Merchant Wholesalers, Nondurable Goods ",
    code: "424",
    industries: [
      {
        label: "Printing and Writing Paper Merchant Wholesalers ",
        code: "42411",
      },
      {
        label: "Stationery and Office Supplies Merchant Wholesalers ",
        code: "42412",
      },
      {
        label: "Industrial and Personal Service Paper Merchant Wholesalers ",
        code: "42413",
      },
      {
        label: "Drugs and Druggists' Sundries Merchant Wholesalers ",
        code: "42421",
      },
      {
        label:
          "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers ",
        code: "42431",
      },
      {
        label: "Footwear Merchant Wholesalers ",
        code: "42434",
      },
      {
        label: "Clothing and Clothing Accessories Merchant Wholesalers",
        code: "42435",
      },
      {
        label: "General Line Grocery Merchant Wholesalers ",
        code: "42441",
      },
      {
        label: "Packaged Frozen Food Merchant Wholesalers ",
        code: "42442",
      },
      {
        label: "Dairy Product (except Dried or Canned) Merchant Wholesalers ",
        code: "42443",
      },
      {
        label: "Poultry and Poultry Product Merchant Wholesalers ",
        code: "42444",
      },
      {
        label: "Confectionery Merchant Wholesalers ",
        code: "42445",
      },
      {
        label: "Fish and Seafood Merchant Wholesalers ",
        code: "42446",
      },
      {
        label: "Meat and Meat Product Merchant Wholesalers ",
        code: "42447",
      },
      {
        label: "Fresh Fruit and Vegetable Merchant Wholesalers ",
        code: "42448",
      },
      {
        label: "Other Grocery and Related Products Merchant Wholesalers ",
        code: "42449",
      },
      {
        label: "Grain and Field Bean Merchant Wholesalers ",
        code: "42451",
      },
      {
        label: "Livestock Merchant Wholesalers ",
        code: "42452",
      },
      {
        label: "Other Farm Product Raw Material Merchant Wholesalers ",
        code: "42459",
      },
      {
        label:
          "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers ",
        code: "42461",
      },
      {
        label: "Other Chemical and Allied Products Merchant Wholesalers ",
        code: "42469",
      },
      {
        label: "Petroleum Bulk Stations and Terminals ",
        code: "42471",
      },
      {
        label:
          "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ",
        code: "42472",
      },
      {
        label: "Beer and Ale Merchant Wholesalers ",
        code: "42481",
      },
      {
        label: "Wine and Distilled Alcoholic Beverage Merchant Wholesalers ",
        code: "42482",
      },
      {
        label: "Farm Supplies Merchant Wholesalers ",
        code: "42491",
      },
      {
        label: "Book, Periodical, and Newspaper Merchant Wholesalers ",
        code: "42492",
      },
      {
        label:
          "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers ",
        code: "42493",
      },
      {
        label: "Tobacco Product and Electronic Cigarette Merchant Wholesalers ",
        code: "42494",
      },
      {
        label: "Paint, Varnish, and Supplies Merchant Wholesalers ",
        code: "42495",
      },
      {
        label: "Other Miscellaneous Nondurable Goods Merchant Wholesalers ",
        code: "42499",
      },
    ],
  },
  {
    label: "Wholesale Trade Agents and Brokers ",
    code: "425",
    industries: [
      {
        label: "Wholesale Trade Agents and Brokers ",
        code: "42512",
      },
      {
        label: "Retail Trade",
        code: "44-45",
      },
    ],
  },
  {
    label: "Motor Vehicle and Parts Dealers ",
    code: "441",
    industries: [
      {
        label: "New Car Dealers ",
        code: "44111",
      },
      {
        label: "Used Car Dealers ",
        code: "44112",
      },
      {
        label: "Recreational Vehicle Dealers ",
        code: "44121",
      },
      {
        label: "Motorcycle, Boat, and Other Motor Vehicle Dealers ",
        code: "44122",
      },
      {
        label: "Automotive Parts and Accessories Retailers ",
        code: "44133",
      },
      {
        label: "Tire Dealers ",
        code: "44134",
      },
    ],
  },
  {
    label: "Building Material and Garden Equipment and Supplies Dealers ",
    code: "444",
    industries: [
      {
        label: "Home Centers ",
        code: "44411",
      },
      {
        label: "Paint and Wallpaper Retailers ",
        code: "44412",
      },
      {
        label: "Hardware Retailers ",
        code: "44414",
      },
      {
        label: "Other Building Material Dealers ",
        code: "44418",
      },
      {
        label: "Outdoor Power Equipment Retailers ",
        code: "44423",
      },
      {
        label: "Nursery, Garden Center, and Farm Supply Retailers ",
        code: "44424",
      },
    ],
  },
  {
    label: "Food and Beverage Retailers ",
    code: "445",
    industries: [
      {
        label:
          "Supermarkets and Other Grocery Retailers (except Convenience Retailers) ",
        code: "44511",
      },
      {
        label: "Convenience Retailers and Vending Machine Operators ",
        code: "44513",
      },
      {
        label: "Fruit and Vegetable Retailers ",
        code: "44523",
      },
      {
        label: "Meat Retailers ",
        code: "44524",
      },
      {
        label: "Fish and Seafood Retailers ",
        code: "44525",
      },
      {
        label: "Other Specialty Food Retailers ",
        code: "44529",
      },
      {
        label: "Beer, Wine, and Liquor Retailers ",
        code: "44532",
      },
    ],
  },
  {
    label: "Furniture, Home Furnishings, Electronics, and Appliance Retailers ",
    code: "449",
    industries: [
      {
        label: "Furniture Retailers ",
        code: "44911",
      },
      {
        label: "Home Furnishings Retailers ",
        code: "44912",
      },
      {
        label: "Electronics and Appliance Retailers ",
        code: "44921",
      },
    ],
  },
  {
    label: "General Merchandise Retailers ",
    code: "455",
    industries: [
      {
        label: "Department Stores ",
        code: "45511",
      },
      {
        label:
          "Warehouse Clubs, Supercenters, and Other General Merchandise Retailers ",
        code: "45521",
      },
    ],
  },
  {
    label: "Health and Personal Care Retailers ",
    code: "456",
    industries: [
      {
        label: "Pharmacies and Drug Retailers ",
        code: "45611",
      },
      {
        label: "Cosmetics, Beauty Supplies, and Perfume Retailers ",
        code: "45612",
      },
      {
        label: "Optical Goods Retailers ",
        code: "45613",
      },
      {
        label: "Other Health and Personal Care Retailers ",
        code: "45619",
      },
    ],
  },
  {
    label: "Gasoline Stations and Fuel Dealers ",
    code: "457",
    industries: [
      {
        label: "Gasoline Stations with Convenience Stores ",
        code: "45711",
      },
      {
        label: "Other Gasoline Stations ",
        code: "45712",
      },
      {
        label: "Fuel Dealers",
        code: "45721",
      },
    ],
  },
  {
    label: "Clothing, Clothing Accessories, Shoe, and Jewelry Retailers ",
    code: "458",
    industries: [
      {
        label: "Clothing and Clothing Accessories Retailers ",
        code: "45811",
      },
      {
        label: "Shoe Retailers ",
        code: "45821",
      },
      {
        label: "Jewelry Retailers ",
        code: "45831",
      },
      {
        label: "Luggage and Leather Goods Retailers ",
        code: "45832",
      },
    ],
  },
  {
    label:
      "Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers ",
    code: "459",
    industries: [
      {
        label: "Sporting Goods Retailers ",
        code: "45911",
      },
      {
        label: "Hobby, Toy, and Game Retailers ",
        code: "45912",
      },
      {
        label: "Sewing, Needlework, and Piece Goods Retailers ",
        code: "45913",
      },
      {
        label: "Musical Instrument and Supplies Retailers ",
        code: "45914",
      },
      {
        label: "Book Retailers and News Dealers ",
        code: "45921",
      },
      {
        label: "Florists ",
        code: "45931",
      },
      {
        label: "Office Supplies and Stationery Retailers ",
        code: "45941",
      },
      {
        label: "Gift, Novelty, and Souvenir Retailers ",
        code: "45942",
      },
      {
        label: "Used Merchandise Retailers ",
        code: "45951",
      },
      {
        label: "Pet and Pet Supplies Retailers ",
        code: "45991",
      },
      {
        label: "Art Dealers ",
        code: "45992",
      },
      {
        label: "Manufactured (Mobile) Home Dealers ",
        code: "45993",
      },
      {
        label: "All Other Miscellaneous Retailers ",
        code: "45999",
      },
      {
        label: "Transportation and Warehousing",
        code: "48-49",
      },
    ],
  },
  {
    label: "Air Transportation",
    code: "481",
    industries: [
      {
        label: "Scheduled Air Transportation",
        code: "48111",
      },
      {
        label: "Nonscheduled Air Transportation",
        code: "48121",
      },
    ],
  },
  {
    label: "Rail Transportation",
    code: "482",
    industries: [
      {
        label: "Rail Transportation",
        code: "48211",
      },
    ],
  },
  {
    label: "Water Transportation",
    code: "483",
    industries: [
      {
        label: "Deep Sea, Coastal, and Great Lakes Water Transportation",
        code: "48311",
      },
      {
        label: "Inland Water Transportation",
        code: "48321",
      },
    ],
  },
  {
    label: "Truck Transportation",
    code: "484",
    industries: [
      {
        label: "General Freight Trucking, Local",
        code: "48411",
      },
      {
        label: "General Freight Trucking, Long-Distance",
        code: "48412",
      },
      {
        label: "Used Household and Office Goods Moving",
        code: "48421",
      },
      {
        label: "Specialized Freight (except Used Goods) Trucking, Local",
        code: "48422",
      },
      {
        label:
          "Specialized Freight (except Used Goods) Trucking, Long-Distance",
        code: "48423",
      },
    ],
  },
  {
    label: "Transit and Ground Passenger Transportation",
    code: "485",
    industries: [
      {
        label: "Urban Transit Systems",
        code: "48511",
      },
      {
        label: "Interurban and Rural Bus Transportation",
        code: "48521",
      },
      {
        label: "Taxi and Ridesharing Services",
        code: "48531",
      },
      {
        label: "Limousine Service",
        code: "48532",
      },
      {
        label: "School and Employee Bus Transportation",
        code: "48541",
      },
      {
        label: "Charter Bus Industry",
        code: "48551",
      },
      {
        label: "Other Transit and Ground Passenger Transportation",
        code: "48599",
      },
    ],
  },
  {
    label: "Pipeline Transportation",
    code: "486",
    industries: [
      {
        label: "Pipeline Transportation of Crude Oil",
        code: "48611",
      },
      {
        label: "Pipeline Transportation of Natural Gas",
        code: "48621",
      },
      {
        label: "Pipeline Transportation of Refined Petroleum Products",
        code: "48691",
      },
      {
        label: "All Other Pipeline Transportation",
        code: "48699",
      },
    ],
  },
  {
    label: "Scenic and Sightseeing Transportation",
    code: "487",
    industries: [
      {
        label: "Scenic and Sightseeing Transportation, Land",
        code: "48711",
      },
      {
        label: "Scenic and Sightseeing Transportation, Water",
        code: "48721",
      },
      {
        label: "Scenic and Sightseeing Transportation, Other",
        code: "48799",
      },
    ],
  },
  {
    label: "Support Activities for Transportation",
    code: "488",
    industries: [
      {
        label: "Airport Operations",
        code: "48811",
      },
      {
        label: "Other Support Activities for Air Transportation",
        code: "48819",
      },
      {
        label: "Support Activities for Rail Transportation",
        code: "48821",
      },
      {
        label: "Port and Harbor Operations",
        code: "48831",
      },
      {
        label: "Marine Cargo Handling",
        code: "48832",
      },
      {
        label: "Navigational Services to Shipping",
        code: "48833",
      },
      {
        label: "Other Support Activities for Water Transportation",
        code: "48839",
      },
      {
        label: "Motor Vehicle Towing",
        code: "48841",
      },
      {
        label: "Other Support Activities for Road Transportation",
        code: "48849",
      },
      {
        label: "Freight Transportation Arrangement",
        code: "48851",
      },
      {
        label: "Other Support Activities for Transportation",
        code: "48899",
      },
    ],
  },
  {
    label: "Postal Service",
    code: "491",
    industries: [
      {
        label: "Postal Service",
        code: "49111",
      },
    ],
  },
  {
    label: "Couriers and Messengers",
    code: "492",
    industries: [
      {
        label: "Couriers and Express Delivery Services",
        code: "49211",
      },
      {
        label: "Local Messengers and Local Delivery",
        code: "49221",
      },
    ],
  },
  {
    label: "Warehousing and Storage",
    code: "493",
    industries: [
      {
        label: "General Warehousing and Storage",
        code: "49311",
      },
      {
        label: "Refrigerated Warehousing and Storage",
        code: "49312",
      },
      {
        label: "Farm Product Warehousing and Storage",
        code: "49313",
      },
      {
        label: "Other Warehousing and Storage",
        code: "49319",
      },
    ],
  },
  {
    label: "Motion Picture and Sound Recording Industries",
    code: "512",
    industries: [
      {
        label: "Motion Picture and Video Production",
        code: "51211",
      },
      {
        label: "Motion Picture and Video Distribution",
        code: "51212",
      },
      {
        label: "Motion Picture and Video Exhibition",
        code: "51213",
      },
      {
        label:
          "Postproduction Services and Other Motion Picture and Video Industries",
        code: "51219",
      },
      {
        label: "Music Publishers",
        code: "51223",
      },
      {
        label: "Sound Recording Studios",
        code: "51224",
      },
      {
        label: "Record Production and Distribution",
        code: "51225",
      },
      {
        label: "Other Sound Recording Industries",
        code: "51229",
      },
    ],
  },
  {
    label: "Publishing Industries",
    code: "513",
    industries: [
      {
        label: "Newspaper Publishers",
        code: "51311",
      },
      {
        label: "Periodical Publishers",
        code: "51312",
      },
      {
        label: "Book Publishers",
        code: "51313",
      },
      {
        label: "Directory and Mailing List Publishers",
        code: "51314",
      },
      {
        label: "Other Publishers",
        code: "51319",
      },
      {
        label: "Software Publishers",
        code: "51321",
      },
    ],
  },
  {
    label: "Broadcasting and Content Providers",
    code: "516",
    industries: [
      {
        label: "Radio Broadcasting Stations",
        code: "51611",
      },
      {
        label: "Television Broadcasting Stations",
        code: "51612",
      },
      {
        label:
          "Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers",
        code: "51621",
      },
    ],
  },
  {
    label: "Telecommunications",
    code: "517",
    industries: [
      {
        label:
          "Wired and Wireless Telecommunications Carriers (except Satellite)",
        code: "51711",
      },
      {
        label:
          "Telecommunications Resellers and Agents for Wireless Telecommunication Services",
        code: "51712",
      },
      {
        label: "Satellite Telecommunications",
        code: "51741",
      },
      {
        label: "All Other Telecommunications",
        code: "51781",
      },
    ],
  },
  {
    label:
      "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
    code: "518",
    industries: [
      {
        label:
          "Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services",
        code: "51821",
      },
    ],
  },
  {
    label:
      "Web Search Portals, Libraries, Archives, and Other Information Services",
    code: "519",
    industries: [
      {
        label: "Libraries and Archives",
        code: "51921",
      },
      {
        label: "Web Search Portals and All Other Information Services",
        code: "51929",
      },
    ],
  },
  {
    label: "Monetary Authorities-Central Bank",
    code: "521",
    industries: [
      {
        label: "Monetary Authorities-Central Bank",
        code: "52111",
      },
    ],
  },
  {
    label: "Credit Intermediation and Related Activities",
    code: "522",
    industries: [
      {
        label: "Commercial Banking ",
        code: "52211",
      },
      {
        label: "Credit Unions ",
        code: "52213",
      },
      {
        label:
          "Savings Institutions and Other Depository Credit Intermediation ",
        code: "52218",
      },
      {
        label: "Credit Card Issuing ",
        code: "52221",
      },
      {
        label: "Sales Financing ",
        code: "52222",
      },
      {
        label: "Other Nondepository Credit Intermediation ",
        code: "52229",
      },
      {
        label: "Mortgage and Nonmortgage Loan Brokers ",
        code: "52231",
      },
      {
        label:
          "Financial Transactions Processing, Reserve, and Clearinghouse Activities ",
        code: "52232",
      },
      {
        label: "Other Activities Related to Credit Intermediation ",
        code: "52239",
      },
    ],
  },
  {
    label:
      "Securities, Commodity Contracts, and Other Financial Investments and Related Activities",
    code: "523",
    industries: [
      {
        label: "Investment Banking and Securities Intermediation ",
        code: "52315",
      },
      {
        label: "Commodity Contracts Intermediation ",
        code: "52316",
      },
      {
        label: "Securities and Commodity Exchanges",
        code: "52321",
      },
      {
        label: "Miscellaneous Intermediation ",
        code: "52391",
      },
      {
        label: "Portfolio Management and Investment Advice ",
        code: "52394",
      },
      {
        label: "All Other Financial Investment Activities ",
        code: "52399",
      },
    ],
  },
  {
    label: "Insurance Carriers and Related Activities",
    code: "524",
    industries: [
      {
        label: "Direct Life, Health, and Medical Insurance Carriers ",
        code: "52411",
      },
      {
        label: "Direct Insurance (except Life, Health, and Medical) Carriers ",
        code: "52412",
      },
      {
        label: "Reinsurance Carriers ",
        code: "52413",
      },
      {
        label: "Insurance Agencies and Brokerages ",
        code: "52421",
      },
      {
        label: "Other Insurance Related Activities ",
        code: "52429",
      },
    ],
  },
  {
    label: "Funds, Trusts, and Other Financial Vehicles ",
    code: "525",
    industries: [
      {
        label: "Pension Funds ",
        code: "52511",
      },
      {
        label: "Health and Welfare Funds ",
        code: "52512",
      },
      {
        label: "Other Insurance Funds ",
        code: "52519",
      },
      {
        label: "Open-End Investment Funds ",
        code: "52591",
      },
      {
        label: "Trusts, Estates, and Agency Accounts ",
        code: "52592",
      },
      {
        label: "Other Financial Vehicles ",
        code: "52599",
      },
    ],
  },
  {
    label: "Real Estate",
    code: "531",
    industries: [
      {
        label: "Lessors of Residential Buildings and Dwellings ",
        code: "53111",
      },
      {
        label: "Lessors of Nonresidential Buildings (except Miniwarehouses) ",
        code: "53112",
      },
      {
        label: "Lessors of Miniwarehouses and Self-Storage Units ",
        code: "53113",
      },
      {
        label: "Lessors of Other Real Estate Property ",
        code: "53119",
      },
      {
        label: "Offices of Real Estate Agents and Brokers",
        code: "53121",
      },
      {
        label: "Real Estate Property Managers ",
        code: "53131",
      },
      {
        label: "Offices of Real Estate Appraisers ",
        code: "53132",
      },
      {
        label: "Other Activities Related to Real Estate ",
        code: "53139",
      },
    ],
  },
  {
    label: "Rental and Leasing Services",
    code: "532",
    industries: [
      {
        label: "Passenger Car Rental and Leasing",
        code: "53211",
      },
      {
        label:
          "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing",
        code: "53212",
      },
      {
        label: "Consumer Electronics and Appliances Rental",
        code: "53221",
      },
      {
        label: "Other Consumer Goods Rental ",
        code: "53228",
      },
      {
        label: "General Rental Centers",
        code: "53231",
      },
      {
        label:
          "Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing",
        code: "53241",
      },
      {
        label: "Office Machinery and Equipment Rental and Leasing",
        code: "53242",
      },
      {
        label:
          "Other Commercial and Industrial Machinery and Equipment Rental and Leasing",
        code: "53249",
      },
    ],
  },
  {
    label:
      "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
    code: "533",
    industries: [
      {
        label:
          "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
        code: "53311",
      },
    ],
  },
  {
    label: "Professional, Scientific, and Technical Services",
    code: "541",
    industries: [
      {
        label: "Offices of Lawyers",
        code: "54111",
      },
      {
        label: "Offices of Notaries",
        code: "54112",
      },
      {
        label: "Other Legal Services",
        code: "54119",
      },
      {
        label: "Accounting, Tax Preparation, Bookkeeping, and Payroll Services",
        code: "54121",
      },
      {
        label: "Architectural Services",
        code: "54131",
      },
      {
        label: "Landscape Architectural Services",
        code: "54132",
      },
      {
        label: "Engineering Services",
        code: "54133",
      },
      {
        label: "Drafting Services",
        code: "54134",
      },
      {
        label: "Building Inspection Services",
        code: "54135",
      },
      {
        label: "Geophysical Surveying and Mapping Services",
        code: "54136",
      },
      {
        label: "Surveying and Mapping (except Geophysical) Services",
        code: "54137",
      },
      {
        label: "Testing Laboratories and Services",
        code: "54138",
      },
      {
        label: "Interior Design Services",
        code: "54141",
      },
      {
        label: "Industrial Design Services",
        code: "54142",
      },
      {
        label: "Graphic Design Services",
        code: "54143",
      },
      {
        label: "Other Specialized Design Services",
        code: "54149",
      },
      {
        label: "Computer Systems Design and Related Services",
        code: "54151",
      },
      {
        label: "Management Consulting Services",
        code: "54161",
      },
      {
        label: "Environmental Consulting Services",
        code: "54162",
      },
      {
        label: "Other Scientific and Technical Consulting Services",
        code: "54169",
      },
      {
        label:
          "Research and Development in the Physical, Engineering, and Life Sciences",
        code: "54171",
      },
      {
        label: "Research and Development in the Social Sciences and Humanities",
        code: "54172",
      },
      {
        label: "Advertising Agencies",
        code: "54181",
      },
      {
        label: "Public Relations Agencies",
        code: "54182",
      },
      {
        label: "Media Buying Agencies",
        code: "54183",
      },
      {
        label: "Media Representatives",
        code: "54184",
      },
      {
        label: "Indoor and Outdoor Display Advertising",
        code: "54185",
      },
      {
        label: "Direct Mail Advertising",
        code: "54186",
      },
      {
        label: "Advertising Material Distribution Services",
        code: "54187",
      },
      {
        label: "Other Services Related to Advertising",
        code: "54189",
      },
      {
        label: "Marketing Research and Public Opinion Polling",
        code: "54191",
      },
      {
        label: "Photographic Services",
        code: "54192",
      },
      {
        label: "Translation and Interpretation Services",
        code: "54193",
      },
      {
        label: "Veterinary Services",
        code: "54194",
      },
      {
        label: "All Other Professional, Scientific, and Technical Services",
        code: "54199",
      },
    ],
  },
  {
    label: "Management of Companies and Enterprises",
    code: "551",
    industries: [
      {
        label: "Management of Companies and Enterprises",
        code: "55111",
      },
    ],
  },
  {
    label: "Administrative and Support Services",
    code: "561",
    industries: [
      {
        label: "Office Administrative Services",
        code: "56111",
      },
      {
        label: "Facilities Support Services",
        code: "56121",
      },
      {
        label: "Employment Placement Agencies and Executive Search Services",
        code: "56131",
      },
      {
        label: "Temporary Help Services",
        code: "56132",
      },
      {
        label: "Professional Employer Organizations",
        code: "56133",
      },
      {
        label: "Document Preparation Services",
        code: "56141",
      },
      {
        label: "Telephone Call Centers",
        code: "56142",
      },
      {
        label: "Business Service Centers",
        code: "56143",
      },
      {
        label: "Collection Agencies",
        code: "56144",
      },
      {
        label: "Credit Bureaus",
        code: "56145",
      },
      {
        label: "Other Business Support Services",
        code: "56149",
      },
      {
        label: "Travel Agencies",
        code: "56151",
      },
      {
        label: "Tour Operators",
        code: "56152",
      },
      {
        label: "Other Travel Arrangement and Reservation Services",
        code: "56159",
      },
      {
        label: "Investigation, Guard, and Armored Car Services",
        code: "56161",
      },
      {
        label: "Security Systems Services",
        code: "56162",
      },
      {
        label: "Exterminating and Pest Control Services",
        code: "56171",
      },
      {
        label: "Janitorial Services",
        code: "56172",
      },
      {
        label: "Landscaping Services",
        code: "56173",
      },
      {
        label: "Carpet and Upholstery Cleaning Services",
        code: "56174",
      },
      {
        label: "Other Services to Buildings and Dwellings",
        code: "56179",
      },
      {
        label: "Packaging and Labeling Services",
        code: "56191",
      },
      {
        label: "Convention and Trade Show Organizers",
        code: "56192",
      },
      {
        label: "All Other Support Services",
        code: "56199",
      },
    ],
  },
  {
    label: "Waste Management and Remediation Services",
    code: "562",
    industries: [
      {
        label: "Waste Collection ",
        code: "56211",
      },
      {
        label: "Waste Treatment and Disposal ",
        code: "56221",
      },
      {
        label: "Remediation Services ",
        code: "56291",
      },
      {
        label: "Materials Recovery Facilities ",
        code: "56292",
      },
      {
        label: "All Other Waste Management Services ",
        code: "56299",
      },
    ],
  },
  {
    label: "Educational Services",
    code: "611",
    industries: [
      {
        label: "Elementary and Secondary Schools ",
        code: "61111",
      },
      {
        label: "Junior Colleges",
        code: "61121",
      },
      {
        label: "Colleges, Universities, and Professional Schools",
        code: "61131",
      },
      {
        label: "Business and Secretarial Schools",
        code: "61141",
      },
      {
        label: "Computer Training",
        code: "61142",
      },
      {
        label: "Professional and Management Development Training",
        code: "61143",
      },
      {
        label: "Technical and Trade Schools",
        code: "61151",
      },
      {
        label: "Fine Arts Schools",
        code: "61161",
      },
      {
        label: "Sports and Recreation Instruction",
        code: "61162",
      },
      {
        label: "Language Schools",
        code: "61163",
      },
      {
        label: "All Other Schools and Instruction",
        code: "61169",
      },
      {
        label: "Educational Support Services",
        code: "61171",
      },
    ],
  },
  {
    label: "Ambulatory Health Care Services",
    code: "621",
    industries: [
      {
        label: "Offices of Physicians",
        code: "62111",
      },
      {
        label: "Offices of Dentists",
        code: "62121",
      },
      {
        label: "Offices of Chiropractors",
        code: "62131",
      },
      {
        label: "Offices of Optometrists",
        code: "62132",
      },
      {
        label: "Offices of Mental Health Practitioners (except Physicians)",
        code: "62133",
      },
      {
        label:
          "Offices of Physical, Occupational and Speech Therapists, and Audiologists",
        code: "62134",
      },
      {
        label: "Offices of All Other Health Practitioners",
        code: "62139",
      },
      {
        label: "Family Planning Centers",
        code: "62141",
      },
      {
        label: "Outpatient Mental Health and Substance Abuse Centers",
        code: "62142",
      },
      {
        label: "Other Outpatient Care Centers",
        code: "62149",
      },
      {
        label: "Medical and Diagnostic Laboratories",
        code: "62151",
      },
      {
        label: "Home Health Care Services",
        code: "62161",
      },
      {
        label: "Ambulance Services",
        code: "62191",
      },
      {
        label: "All Other Ambulatory Health Care Services",
        code: "62199",
      },
    ],
  },
  {
    label: "Hospitals",
    code: "622",
    industries: [
      {
        label: "General Medical and Surgical Hospitals",
        code: "62211",
      },
      {
        label: "Psychiatric and Substance Abuse Hospitals",
        code: "62221",
      },
      {
        label: "Specialty (except Psychiatric and Substance Abuse) Hospitals",
        code: "62231",
      },
    ],
  },
  {
    label: "Nursing and Residential Care Facilities",
    code: "623",
    industries: [
      {
        label: "Nursing Care Facilities (Skilled Nursing Facilities)",
        code: "62311",
      },
      {
        label:
          "Residential Intellectual and Developmental Disability Facilities",
        code: "62321",
      },
      {
        label: "Residential Mental Health and Substance Abuse Facilities",
        code: "62322",
      },
      {
        label:
          "Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly",
        code: "62331",
      },
      {
        label: "Other Residential Care Facilities",
        code: "62399",
      },
    ],
  },
  {
    label: "Social Assistance",
    code: "624",
    industries: [
      {
        label: "Child and Youth Services",
        code: "62411",
      },
      {
        label: "Services for the Elderly and Persons with Disabilities",
        code: "62412",
      },
      {
        label: "Other Individual and Family Services",
        code: "62419",
      },
      {
        label: "Community Food Services",
        code: "62421",
      },
      {
        label: "Community Housing Services",
        code: "62422",
      },
      {
        label: "Emergency and Other Relief Services",
        code: "62423",
      },
      {
        label: "Vocational Rehabilitation Services",
        code: "62431",
      },
      {
        label: "Child Care Services",
        code: "62441",
      },
    ],
  },
  {
    label: "Performing Arts, Spectator Sports, and Related Industries",
    code: "711",
    industries: [
      {
        label: "Theater Companies and Dinner Theaters",
        code: "71111",
      },
      {
        label: "Dance Companies",
        code: "71112",
      },
      {
        label: "Musical Groups and Artists",
        code: "71113",
      },
      {
        label: "Other Performing Arts Companies",
        code: "71119",
      },
      {
        label: "Spectator Sports",
        code: "71121",
      },
      {
        label:
          "Promoters of Performing Arts, Sports, and Similar Events with Facilities",
        code: "71131",
      },
      {
        label:
          "Promoters of Performing Arts, Sports, and Similar Events without Facilities",
        code: "71132",
      },
      {
        label:
          "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",
        code: "71141",
      },
      {
        label: "Independent Artists, Writers, and Performers",
        code: "71151",
      },
    ],
  },
  {
    label: "Museums, Historical Sites, and Similar Institutions",
    code: "712",
    industries: [
      {
        label: "Museums",
        code: "71211",
      },
      {
        label: "Historical Sites",
        code: "71212",
      },
      {
        label: "Zoos and Botanical Gardens",
        code: "71213",
      },
      {
        label: "Nature Parks and Other Similar Institutions",
        code: "71219",
      },
    ],
  },
  {
    label: "Amusement, Gambling, and Recreation Industries",
    code: "713",
    industries: [
      {
        label: "Amusement and Theme Parks",
        code: "71311",
      },
      {
        label: "Amusement Arcades",
        code: "71312",
      },
      {
        label: "Casinos (except Casino Hotels)",
        code: "71321",
      },
      {
        label: "Other Gambling Industries",
        code: "71329",
      },
      {
        label: "Golf Courses and Country Clubs",
        code: "71391",
      },
      {
        label: "Skiing Facilities",
        code: "71392",
      },
      {
        label: "Marinas",
        code: "71393",
      },
      {
        label: "Fitness and Recreational Sports Centers",
        code: "71394",
      },
      {
        label: "Bowling Centers",
        code: "71395",
      },
      {
        label: "All Other Amusement and Recreation Industries",
        code: "71399",
      },
    ],
  },
  {
    label: "Accommodation",
    code: "721",
    industries: [
      {
        label: "Hotels (except Casino Hotels) and Motels",
        code: "72111",
      },
      {
        label: "Casino Hotels",
        code: "72112",
      },
      {
        label: "Other Traveler Accommodation",
        code: "72119",
      },
      {
        label: "RV (Recreational Vehicle) Parks and Recreational Camps",
        code: "72121",
      },
      {
        label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
        code: "72131",
      },
    ],
  },
  {
    label: "Food Services and Drinking Places",
    code: "722",
    industries: [
      {
        label: "Food Service Contractors",
        code: "72231",
      },
      {
        label: "Caterers",
        code: "72232",
      },
      {
        label: "Mobile Food Services",
        code: "72233",
      },
      {
        label: "Drinking Places (Alcoholic Beverages)",
        code: "72241",
      },
      {
        label: "Restaurants and Other Eating Places",
        code: "72251",
      },
    ],
  },
  {
    label: "Repair and Maintenance",
    code: "811",
    industries: [
      {
        label: "Automotive Mechanical and Electrical Repair and Maintenance",
        code: "81111",
      },
      {
        label: "Automotive Body, Paint, Interior, and Glass Repair",
        code: "81112",
      },
      {
        label: "Other Automotive Repair and Maintenance",
        code: "81119",
      },
      {
        label: "Electronic and Precision Equipment Repair and Maintenance",
        code: "81121",
      },
      {
        label:
          "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
        code: "81131",
      },
      {
        label: "Home and Garden Equipment and Appliance Repair and Maintenance",
        code: "81141",
      },
      {
        label: "Reupholstery and Furniture Repair",
        code: "81142",
      },
      {
        label: "Footwear and Leather Goods Repair",
        code: "81143",
      },
      {
        label: "Other Personal and Household Goods Repair and Maintenance",
        code: "81149",
      },
    ],
  },
  {
    label: "Personal and Laundry Services",
    code: "812",
    industries: [
      {
        label: "Hair, Nail, and Skin Care Services ",
        code: "81211",
      },
      {
        label: "Other Personal Care Services ",
        code: "81219",
      },
      {
        label: "Funeral Homes and Funeral Services ",
        code: "81221",
      },
      {
        label: "Cemeteries and Crematories ",
        code: "81222",
      },
      {
        label: "Coin-Operated Laundries and Drycleaners ",
        code: "81231",
      },
      {
        label: "Drycleaning and Laundry Services (except Coin-Operated) ",
        code: "81232",
      },
      {
        label: "Linen and Uniform Supply ",
        code: "81233",
      },
      {
        label: "Pet Care (except Veterinary) Services ",
        code: "81291",
      },
      {
        label: "Photofinishing ",
        code: "81292",
      },
      {
        label: "Parking Lots and Garages ",
        code: "81293",
      },
      {
        label: "All Other Personal Services ",
        code: "81299",
      },
    ],
  },
  {
    label:
      "Religious, Grantmaking, Civic, Professional, and Similar Organizations",
    code: "813",
    industries: [
      {
        label: "Religious Organizations ",
        code: "81311",
      },
      {
        label: "Grantmaking and Giving Services ",
        code: "81321",
      },
      {
        label: "Social Advocacy Organizations ",
        code: "81331",
      },
      {
        label: "Civic and Social Organizations ",
        code: "81341",
      },
      {
        label: "Business Associations ",
        code: "81391",
      },
      {
        label: "Professional Organizations ",
        code: "81392",
      },
      {
        label: "Labor Unions and Similar Labor Organizations ",
        code: "81393",
      },
      {
        label: "Political Organizations ",
        code: "81394",
      },
      {
        label:
          "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations) ",
        code: "81399",
      },
    ],
  },
  {
    label: "Private Households",
    code: "814",
    industries: [
      {
        label: "Private Households",
        code: "81411",
      },
    ],
  },
  {
    label: "Executive, Legislative, and Other General Government Support ",
    code: "921",
    industries: [
      {
        label: "Executive Offices ",
        code: "92111",
      },
      {
        label: "Legislative Bodies ",
        code: "92112",
      },
      {
        label: "Public Finance Activities ",
        code: "92113",
      },
      {
        label: "Executive and Legislative Offices, Combined ",
        code: "92114",
      },
      {
        label: "American Indian and Alaska Native Tribal Governments ",
        code: "92115",
      },
      {
        label: "Other General Government Support ",
        code: "92119",
      },
    ],
  },
  {
    label: "Justice, Public Order, and Safety Activities ",
    code: "922",
    industries: [
      {
        label: "Courts ",
        code: "92211",
      },
      {
        label: "Police Protection ",
        code: "92212",
      },
      {
        label: "Legal Counsel and Prosecution ",
        code: "92213",
      },
      {
        label: "Correctional Institutions ",
        code: "92214",
      },
      {
        label: "Parole Offices and Probation Offices ",
        code: "92215",
      },
      {
        label: "Fire Protection ",
        code: "92216",
      },
      {
        label: "Other Justice, Public Order, and Safety Activities ",
        code: "92219",
      },
    ],
  },
  {
    label: "Administration of Human Resource Programs ",
    code: "923",
    industries: [
      {
        label: "Administration of Education Programs ",
        code: "92311",
      },
      {
        label: "Administration of Public Health Programs ",
        code: "92312",
      },
      {
        label:
          "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) ",
        code: "92313",
      },
      {
        label: "Administration of Veterans' Affairs ",
        code: "92314",
      },
    ],
  },
  {
    label: "Administration of Environmental Quality Programs ",
    code: "924",
    industries: [
      {
        label:
          "Administration of Air and Water Resource and Solid Waste Management Programs ",
        code: "92411",
      },
      {
        label: "Administration of Conservation Programs ",
        code: "92412",
      },
    ],
  },
  {
    label:
      "Administration of Housing Programs, Urban Planning, and Community Development ",
    code: "925",
    industries: [
      {
        label: "Administration of Housing Programs ",
        code: "92511",
      },
      {
        label:
          "Administration of Urban Planning and Community and Rural Development ",
        code: "92512",
      },
    ],
  },
  {
    label: "Administration of Economic Programs ",
    code: "926",
    industries: [
      {
        label: "Administration of General Economic Programs ",
        code: "92611",
      },
      {
        label: "Regulation and Administration of Transportation Programs ",
        code: "92612",
      },
      {
        label:
          "Regulation and Administration of Communications, Electric, Gas, and Other Utilities ",
        code: "92613",
      },
      {
        label: "Regulation of Agricultural Marketing and Commodities ",
        code: "92614",
      },
      {
        label:
          "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors ",
        code: "92615",
      },
    ],
  },
  {
    label: "Space Research and Technology ",
    code: "927",
    industries: [
      {
        label: "Space Research and Technology ",
        code: "92711",
      },
    ],
  },
  {
    label: "National Security and International Affairs ",
    code: "928",
    industries: [
      {
        label: "National Security ",
        code: "92811",
      },
      {
        label: "International Affairs ",
        code: "92812",
      },
    ],
  },
];
