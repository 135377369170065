import { Box, Button, Grid, Typography } from "@mui/material";
import Facility from "../../assets/UpsellIcons/Facility.png";
import Binoculars from "../../assets/UpsellIcons/Binoculars.png";
import Calculate from "../../assets/UpsellIcons/Calculate.png";
import Chemicals from "../../assets/UpsellIcons/Chemicals.png";
import Compass from "../../assets/UpsellIcons/Compass.png";
import DirectionalSign from "../../assets/UpsellIcons/DirectionalSign.png";
import Forms from "../../assets/UpsellIcons/Forms.png";
import Map from "../../assets/UpsellIcons/Map.png";
import Tech from "../../assets/UpsellIcons/Tech.png";
import { Link } from "react-router-dom";

const data = [
  {
    icon: Facility,
    title: "Waste Vendor Integration",
    description: "Track waste shipment data from all your vendors",
  },
  {
    icon: Calculate,
    title: "Generator Status Tracking",
    description: "Calculate each facility generator status in real time",
  },
  {
    icon: Forms,
    title: "Compliance Reporting",
    description: "Encamp submits reports to every required agency",
  },
  {
    icon: Compass,
    title: "RCRA Training",
    description: "Train your team with compliance experts",
  },
  {
    icon: Binoculars,
    title: "Mail and Payments",
    description: "Automate all RCRA fee payments and mailings",
  },
  {
    icon: DirectionalSign,
    title: "Audit Documentation",
    description: "Bulletproof documentation for all filings and payments",
  },
  {
    icon: Chemicals,
    title: "E-manifest auditing",
    description: "Compare E-manifest data to waste vendor data",
  },
  {
    icon: Map,
    title: "Update Notifications",
    description: "Complete site updates to federal and state agencies",
  },
  {
    icon: Tech,
    title: "Year Round Compliance",
    description: "Manage waste compliance proactively throughout the year",
  },
];

export function WasteUpsell() {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        fontFamily={"Georgia"}
        fontSize={"2em"}
      >
        Waste and RCRA Reporting Features
      </Typography>
      <Grid container padding={4} paddingBottom={0}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Grid container marginBottom={4} padding={1}>
              <Grid
                container
                item
                xs={3}
                minWidth={55}
                maxHeight={55}
                justifyContent={"center"}
              >
                <Box sx={{ marginBottom: 2 }}>
                  <img src={item.icon} width={40} height={"auto"} />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  align="left"
                  variant="h6"
                  gutterBottom
                  color={"#26428b"}
                  fontFamily={"sans-serif"}
                  fontSize={"1em"}
                  fontWeight={"550"}
                >
                  {item.title}
                </Typography>
                <Typography align="left" variant="body1" fontSize={"1em"}>
                  {item.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        paddingLeft={4}
        paddingRight={4}
        paddingBottom={2}
        justifyContent={"center"}
      >
        <Link to="https://encamp.com/demo/" target="_blank">
          <Button variant="contained">Simplify your RCRA Reporting</Button>
        </Link>
      </Grid>
    </Box>
  );
}

export default WasteUpsell;
