import { Box, Button, SxProps, Typography, useTheme } from "@mui/material";

export const NoRowsOverlay = ({
  onButtonClick = () => {},
  message,
  buttonText,
  sx,
}: {
  onButtonClick?: () => void;
  message: string | string[];
  buttonText?: string;
  sx?: SxProps;
}) => {
  const theme = useTheme();

  const messageLines = Array.isArray(message) ? message : Array(message);

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        textAlign: "center",
        zIndex: 99999,
        p: 2,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: theme.spacing(2),
          marginBottom: theme.spacing(1),
        }}
      >
        {messageLines.map((message, index) => (
          <Typography key={index} variant="body2">
            {message}
          </Typography>
        ))}
      </Box>
      {buttonText && (
        <Button variant="outlined" size="small" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
