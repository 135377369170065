import { Reference, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";
import {
  Payment,
  PaymentMethod,
  PaymentStatus,
} from "generated-graphql/graphql";
import { useState } from "react";

const RECORD_LOB_FEE = gql(`
  mutation RecordLobFee($input: RecordLobFeeInput!) {
    recordLobFee(input: $input) {
      id
      payee
      paidAt
      paymentMethod
      amount
      status
      activityId
    }
  }
`);

export const LobCheckFeeInput = ({
  payment,
  reportId,
  activityId,
  tenantId,
}: {
  payment?: Payment;
  reportId: string;
  activityId: string;
  tenantId: string;
}) => {
  const [fee, setFee] = useState<string>(payment?.amount?.toString() ?? "");

  const alerts = useAlerts();
  const [mutate, { loading }] = useMutation(RECORD_LOB_FEE);

  const recordLobFee = async () => {
    const numberFee = Number(fee);
    if (!isNaN(numberFee)) {
      await mutate({
        variables: {
          input: {
            amount: numberFee,
            reportId,
            activityId,
            tenantId,
            paymentId: payment?.id,
          },
        },
        optimisticResponse({ input }) {
          return {
            recordLobFee: {
              __typename: "Payment",
              id: input.paymentId ?? "new",
              status: PaymentStatus.QueuedForAutomation,
              paidAt: new Date().toISOString(),
              activityId,
              amount: input.amount,
              paymentMethod: PaymentMethod.Check,
              payee: "",
            },
          };
        },
        update(cache, { data }) {
          const newPayment = data?.recordLobFee;
          if (!newPayment) {
            return;
          }

          cache.modify({
            id: cache.identify({
              __typename: "TierIIReport",
              id: reportId,
            }),
            fields: {
              currentWorkflow(currentWorkflowRef) {
                cache.modify({
                  id: currentWorkflowRef.__ref,
                  fields: {
                    activities(existingActivityRefs = [], { readField }) {
                      return existingActivityRefs.map(
                        (activityRef: Reference) => {
                          if (readField("id", activityRef) === activityId) {
                            cache.modify({
                              id: activityRef.__ref,
                              fields: {
                                payments(existingPayments = []) {
                                  return [...existingPayments, newPayment];
                                },
                              },
                            });
                          }
                          return activityRef;
                        }
                      );
                    },
                  },
                });
                return currentWorkflowRef;
              },
            },
          });
        },
        onCompleted() {
          alerts.success("Fee recorded!");
        },
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <TextField
        label="Lob Check Fee Amount"
        value={fee}
        onChange={(event) => {
          const inputValue = event.target.value;
          setFee(inputValue);
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <LoadingButton loading={loading} onClick={recordLobFee}>
        Save
      </LoadingButton>
    </Box>
  );
};
