import { ApolloError } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { useCreateInitial } from "hooks/useCreateReport";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const InitialReportDialog = ({
  facilityId,
  onClose,
}: {
  facilityId: string;
  onClose: () => void;
}) => {
  const [createInitial, { loading }] = useCreateInitial();
  const navigate = useNavigate();
  const alerts = useAlerts();

  const [reportingYear, setReportingYear] = useState(
    currentTierIIReportingYear
  );

  const onSelect = () => {
    createInitial({
      variables: {
        facilityId,
        reportingYear,
      },
      onCompleted(data) {
        navigate(data.createTierIIReport.id);
        onClose();
      },
      onError(err: ApolloError) {
        const reportAlreadyExistsError = err.graphQLErrors.find(
          (e) => e.extensions?.code === "REPORT_FOR_YEAR_ALREADY_EXISTS"
        );
        if (reportAlreadyExistsError) {
          alerts.error(err.message);
        } else {
          alerts.error("Error creating initial notification report");
        }
        console.error("Error creating initial notification report", err);
        onClose();
      },
    });
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Select Reporting Year</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Typography>
            Select the reporting year for the initial report.
          </Typography>
          <Select
            value={reportingYear}
            onChange={(e) => setReportingYear(Number(e.target.value))}
          >
            {[currentTierIIReportingYear, currentTierIIReportingYear + 1].map(
              (year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              )
            )}
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={onSelect} loading={loading}>
          Select
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
