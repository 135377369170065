import OpenInNew from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Collapse,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { FacilityUsageQueryQuery } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { useFacilityUsage } from "./useFacilityUsage";

type Always<T> = T extends null | undefined ? never : T;

type Facility = Always<FacilityUsageQueryQuery["facilityUsage"]["items"]>[0];

type FacilityUsageProps = {
  tenantCount: number;
  facilityCount: number;
  chemicalId: string;
};

export const FacilityUsage: React.FC<FacilityUsageProps> = ({
  chemicalId,
  tenantCount,
  facilityCount,
}) => {
  const columns: GridColDef<Facility>[] = [
    {
      field: "name",
      headerName: "Facility",
      flex: 0.75,
      renderCell: ({ id, row }) => (
        <Link
          href={`/o/${row.tenant?.id}/chemicals/inventory/${id}/chemicals`}
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          <Box display="flex" alignItems="center">
            {row.name}{" "}
            <OpenInNew
              style={{ fontSize: "inherit", marginLeft: theme.spacing(0.5) }}
            />
          </Box>
        </Link>
      ),
    },
    ...(tenantCount > 1
      ? [
          {
            field: "tenantName",
            headerName: "Organization",
            flex: 0.25,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.tenant?.name,
          },
        ]
      : []),
  ];
  const { tenantId } = useParams();
  invariant(tenantId);

  // Stores whether we should show the datagrid of facilities or not
  const [showDataGrid, setShowDataGrid] = useState(false);

  const theme = useTheme();
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const { loading, data, previousData } = useFacilityUsage({
    // only executes the query if the chemicalId is truthy
    chemicalId: showDataGrid ? chemicalId : "",
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    includeItems: true,
  });
  const facilities = data?.facilityUsage.items ?? [];

  const handleCollapse = useCallback(() => {
    setShowDataGrid((show) => !show);
  }, [setShowDataGrid]);

  if (facilityCount === 0) return <></>;

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Typography>
          {facilityCount === 1 &&
            `Changing this information will affect what is reported at 1 facility ${
              tenantCount > 1 ? `across ${tenantCount} organizations` : ""
            } `}
          {facilityCount !== 1 &&
            `Changing this information will affect what is reported at ${facilityCount} facilities ${
              tenantCount > 1 ? `across ${tenantCount} organizations` : ""
            }`}
        </Typography>
        <Button
          size="small"
          onClick={handleCollapse}
          sx={{ marginLeft: theme.spacing(2) }}
        >
          {showDataGrid ? "Hide Facilities" : "Show Facilities"}
        </Button>
      </Stack>
      <Box
        paddingTop={theme.spacing(2)}
        paddingBottom={showDataGrid ? theme.spacing(4) : undefined}
      >
        <Collapse in={showDataGrid}>
          <DataGrid
            sx={{
              minHeight: "250px",
              opacity:
                loading && previousData
                  ? theme.palette.action.disabledOpacity
                  : 1,
            }}
            isCellEditable={() => false}
            isRowSelectable={() => false}
            loading={loading}
            rows={facilities}
            columns={columns}
            rowCount={facilityCount ?? 0}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Collapse>
      </Box>
    </Stack>
  );
};
