import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { CredentialInputForValidation } from "generated-graphql/graphql";
import { useCallback } from "react";

const VALIDATE_CREDENTIAL_INPUT = gql(`
  query ValidateCredentialInput($id: ID, $data: CredentialInputForValidation!) {
    credentialInputValidator(id: $id, data: $data) {
      ...issue
    }
  }
`);

export function useCredentialInputValidation(isCreate: boolean) {
  const [query] = useLazyQuery(VALIDATE_CREDENTIAL_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: CredentialInputForValidation) => {
      const inputToValidate: CredentialInputForValidation = input;

      const { data } = await query({
        variables: {
          data: inputToValidate,
          id: !isCreate ? input.id : undefined,
        },
      });

      return data?.credentialInputValidator ?? [];
    },
    [query, isCreate]
  );
}
