import { useMutation, useQuery } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Candidate, FieldConfig, MergePicker } from "components/MergePicker";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  ChemicalInput,
  DocumentType,
  ProgramArea,
  PureOrMixture,
  WeightOrVolume,
} from "generated-graphql/graphql";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { EhsFormLabels } from "util/ehsForm";
import {
  HealthHazardLabels,
  HealthHazardOrder,
  PhysicalHazardLabels,
  PhysicalHazardOrder,
} from "util/hazards";
import { useChemicalInputValidation } from "../../Report/validationHooks/useChemicalInputValidation";
import { ChemicalDetailInputs } from "../Chemical";
import { ApolloError } from "@apollo/client";

const GET_CANDIDATE_CHEMICALS = gql(/* GraphQL */ `
  query GetCandidateChemicals($chemicalIds: [ID!]!) {
    chemicalsByIds(ids: $chemicalIds) {
      id
      name
      casNumber
      alternateId
      ehsForm
      stateOfMatter
      density
      densityUnits
      isEhs
      noHazardsNotReporting
      isTradeSecret
      manufacturerSupplier
      recommendedUse
      version
      revisionDate
      documents {
        id
        key
        name
        documentId
        document {
          id
          documentType
          title
          description
          storageLink
          documentType
        }
      }
      pureOrMixture
      components {
        id
        name
        componentPercentage
        weightOrVolume
        casNumber
        isEhs
        noCasNumber
      }
      physicalHazards
      healthHazards
      stateFields {
        key
        value
        label
        jurisdiction
        tooltip
      }
      associatedFacilities {
        id
      }
      issues {
        ...issue
      }
    }
  }
`);

const MERGE_CHEMICALS = gql(/* GraphQL */ `
  mutation MergeChemicals($ids: [ID!]!, $input: ChemicalInput!) {
    mergeChemicals(ids: $ids, input: $input) {
      id
      name
    }
  }
`);

type ChemicalMergePickerProps = {
  open: boolean;
  onClose: () => void;
  chemicalIds: string[];
};

export function ChemicalMergePicker(props: ChemicalMergePickerProps) {
  const alerts = useAlerts();

  const { data, loading: loadingChemicals } = useQuery(
    GET_CANDIDATE_CHEMICALS,
    {
      variables: {
        chemicalIds: props.chemicalIds,
      },
    }
  );

  const [mergeChemicals, { loading: isMerging }] = useMutation(
    MERGE_CHEMICALS,
    {
      refetchQueries: ["TenantCatalogChemicals"],
    }
  );

  const stateFieldFields = useMemo<FieldConfig<ChemicalInput>[]>(() => {
    return uniqBy(
      data?.chemicalsByIds.flatMap((chemical) =>
        chemical.stateFields?.filter((field) => hasValue(field.value))
      ) ?? [],
      (stateField) => stateField?.key
    )
      .sort((a, b) => {
        const jurisdictionA = a?.jurisdiction ?? "";
        const jurisdictionB = b?.jurisdiction ?? "";
        return jurisdictionA.localeCompare(jurisdictionB);
      })
      .map<FieldConfig<ChemicalInput>>((stateField) => ({
        name: stateField?.key ?? "",
        label: stateField?.label
          ? `State Field (${stateField.jurisdiction}):\n${stateField.label}`
          : "",
        strategy: "exclusive",
        merge: (selection, mergedRecord) => {
          const selectedStateField = selection.stateFields?.find(
            (sf) => sf.key === stateField?.key && hasValue(sf.value)
          );
          if (!selectedStateField) return mergedRecord;

          return {
            ...mergedRecord,
            stateFields: (
              mergedRecord.stateFields?.filter(
                (sf) => sf.key !== stateField?.key
              ) ?? []
            ).concat([{ ...selectedStateField }]),
          };
        },
        render(data) {
          const field = data.stateFields?.find(
            (sf) => sf.key === stateField?.key
          );
          return typeof field?.value === "string"
            ? field.value
            : typeof field?.value === "boolean"
            ? field?.value
              ? "Yes"
              : "No"
            : field?.value?.toString() ?? "";
        },
      }));
  }, [data?.chemicalsByIds]);

  const mergeFields = useMemo<FieldConfig<ChemicalInput>[]>(() => {
    return [
      {
        name: "chemName",
        label: "Chemical Name",
        strategy: "exclusive",
        sticky: true,
        primary: true,
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            name: selection.name,
          };
        },
        render(data) {
          return data.name ?? "";
        },
      },
      {
        name: "casNumber",
        label: "CAS Number",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            casNumber: selection.casNumber,
          };
        },
        render(data) {
          return data.casNumber ?? "";
        },
      },
      {
        name: "altID",
        label: "Alt ID",
        strategy: "inclusive",
        merge(selections, mergedRecord) {
          return {
            ...mergedRecord,
            alternateId: Array.from(
              new Set(selections.map((s) => s.alternateId).filter((v) => !!v))
            ).join(", "),
          };
        },
        render(data) {
          return data.alternateId ?? "";
        },
      },
      {
        name: "ehsForm",
        label: "EHS Form",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            ehsForm: selection.ehsForm,
          };
        },
        render(data) {
          return data.ehsForm ? EhsFormLabels[data.ehsForm] : "";
        },
      },
      {
        name: "stateOfMatter",
        label: "State of Matter",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            stateOfMatter: selection.stateOfMatter,
          };
        },
        render(data) {
          return data.stateOfMatter
            ? prettyPrintEnumValue(data.stateOfMatter)
            : "";
        },
      },
      {
        name: "density",
        label: "Density",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            density: selection.density,
          };
        },
        render(data) {
          return data.density ? data.density.toString() : "";
        },
      },
      {
        name: "densityUnits",
        label: "Density Units",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            densityUnits: selection.densityUnits,
          };
        },
        render(data) {
          return data.densityUnits
            ? prettyPrintEnumValue(data.densityUnits)
            : "";
        },
      },
      {
        name: "isEhs",
        label: "Extremely Hazardous",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            isEhs: selection.isEhs,
          };
        },
        render(data) {
          return hasValue(data.isEhs) ? (data.isEhs ? "Yes" : "No") : "";
        },
      },
      {
        name: "excludeFromTierII",
        label: "Exclude from Tier II",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            noHazardsNotReporting: selection.noHazardsNotReporting,
          };
        },
        render(data) {
          return hasValue(data.noHazardsNotReporting)
            ? data.noHazardsNotReporting
              ? "Yes"
              : "No"
            : "";
        },
      },
      {
        name: "isTradeSecret",
        label: "Trade Secret",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            isTradeSecret: selection.isTradeSecret,
          };
        },
        render(data) {
          return hasValue(data.isTradeSecret)
            ? data.isTradeSecret
              ? "Yes"
              : "No"
            : "";
        },
      },
      {
        name: "sds",
        label: "SDS",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            documents: selection.documents,
          };
        },
        render(data) {
          const sds = data.documents?.find(
            (d) => d.document.documentType === DocumentType.SafetyDataSheet
          );
          return sds ? sds.name : "";
        },
      },
      {
        name: "pureOrMix",
        label: "Pure/Mix",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            components: selection.components,
            pureOrMixture: selection.pureOrMixture,
          };
        },
        render(data) {
          let str =
            data.pureOrMixture === PureOrMixture.Mixture ? "Mix" : "Pure";
          data.components?.forEach((component, i) => {
            str += `\n\nComponent ${i + 1}:\n${component.name}\n${
              component.componentPercentage
            }% by ${
              component.weightOrVolume === WeightOrVolume.Weight
                ? "weight"
                : "volume"
            }\n${component.casNumber}${component.isEhs ? "\nEHS" : ""}`;
          });
          return str;
        },
      },
      {
        name: "physicalHazards",
        label: "Physical Hazards",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            physicalHazards: selection.physicalHazards,
          };
        },
        render(data) {
          return (
            PhysicalHazardOrder.filter((ph) =>
              data.physicalHazards?.includes(ph)
            )
              ?.map((hazard) => `- ${PhysicalHazardLabels[hazard]}`)
              .join("\n") ?? ""
          );
        },
      },
      {
        name: "healthHazards",
        label: "Health Hazards",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            healthHazards: selection.healthHazards,
          };
        },
        render(data) {
          return (
            HealthHazardOrder.filter((hh) => data.healthHazards?.includes(hh))
              ?.map((hazard) => `- ${HealthHazardLabels[hazard]}`)
              .join("\n") ?? ""
          );
        },
      },
      {
        name: "manufacturerSupplier",
        label: "Manufacturer/Supplier",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            manufacturerSupplier: selection.manufacturerSupplier,
          };
        },
        render(data) {
          return data.manufacturerSupplier ?? "";
        },
      },
      {
        name: "recommendedUse",
        label: "Recommended Use",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            recommendedUse: selection.recommendedUse,
          };
        },
        render(data) {
          return data.recommendedUse ?? "";
        },
      },
      {
        name: "version",
        label: "Version",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            version: selection.version,
          };
        },
        render(data) {
          return data.version ?? "";
        },
      },
      {
        name: "revisionDate",
        label: "Revision Date",
        strategy: "exclusive",
        merge(selection, mergedRecord) {
          return {
            ...mergedRecord,
            revisionDate: selection.revisionDate,
          };
        },
        render(data) {
          return data.revisionDate ?? "";
        },
      },
      ...stateFieldFields,
    ];
  }, [stateFieldFields]);

  const candidates = useMemo<Candidate<ChemicalInput>[]>(
    () =>
      data?.chemicalsByIds.map<Candidate<ChemicalInput>>((c) => ({
        id: c.id,
        associatedFacilityIds: c.associatedFacilities.map((f) => f.id),
        issues: c.issues,
        data: {
          name: c.name,
          casNumber: c.casNumber,
          alternateId: c.alternateId,
          ehsForm: c.ehsForm,
          stateOfMatter: c.stateOfMatter,
          density: c.density,
          densityUnits: c.densityUnits,
          isEhs: c.isEhs,
          noHazardsNotReporting: c.noHazardsNotReporting,
          isTradeSecret: c.isTradeSecret,
          pureOrMixture: c.pureOrMixture,
          components: c.components,
          physicalHazards: c.physicalHazards,
          healthHazards: c.healthHazards,
          manufacturerSupplier: c.manufacturerSupplier,
          recommendedUse: c.recommendedUse,
          version: c.version,
          revisionDate: c.revisionDate,
          documents: c.documents?.map((d) => ({
            chemicalId: c.id,
            documentId: d.documentId,
            id: d.id,
            key: d.key,
            name: d.name,
            document: {
              id: d.document.id,
              description: d.document.description,
              title: d.document.title,
              storageLink: d.document.storageLink,
              documentType: d.document.documentType,
            },
          })),

          stateFields: c.stateFields?.map((sf) => ({
            jurisdiction: sf.jurisdiction,
            key: sf.key,
            type: ProgramArea.Epcra,
            value: sf.value,
          })),
        },
        name: c.name,
      })) ?? [],
    [data?.chemicalsByIds]
  );

  const validator = useChemicalInputValidation(true);

  const handleMerge = async (input: ChemicalInput, mergedIds: string[]) => {
    return await mergeChemicals({
      variables: { ids: mergedIds, input },
      onCompleted: () => {
        alerts.success("Chemicals merged successfully");
      },
      onError: (error: ApolloError) => {
        const chemicalsInProductError = error.graphQLErrors.find(
          (e) => e.extensions?.code === "MULTIPLE_CHEMICALS_IN_PRODUCTS"
        );
        const chemicalsAtFacilityInYear = error.graphQLErrors.find(
          (e) =>
            e.extensions?.code ===
            "MULTIPLE_CHEMICALS_AT_FACILITY_IN_REPORTING_YEAR"
        );

        if (chemicalsInProductError) {
          alerts.error(
            `The following products cannot be merged. Please update the products such that only one of the merge chemicals is defined on the products and then retry the merge.
            ${(chemicalsInProductError.extensions?.extraInfo as string[]).join(
              "\n"
            )}`
          );
          return;
        } else if (chemicalsAtFacilityInYear) {
          alerts.error(
            `The following facility reporting years cannot be merged. Please update the facility inventory for the reporting year such that only one of the merge chemicals is at the facility and then retry the merge.
            ${(
              chemicalsAtFacilityInYear.extensions?.extraInfo as string[]
            ).join("\n")}`
          );
          return;
        }

        console.error(error);
        alerts.error("An error occurred while merging the chemicals");
      },
    });
  };

  return (
    <MergePicker
      open={props.open}
      onClose={props.onClose}
      kind={"chemical"}
      fields={mergeFields}
      candidates={candidates}
      // TODO: I don't like that we have to cast the type here, but this is because we want to share the chemical input validator. I think this is a code smell, and should be addressed in the validator directly.
      validator={(result) => validator(result as ChemicalDetailInputs)}
      onMerge={handleMerge}
      loadingCandidates={loadingChemicals}
      isMerging={isMerging}
    />
  );
}
