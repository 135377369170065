import { useContext, useMemo } from "react";
import { UserType } from "../generated-graphql/graphql";
import { currentUserContext } from "../providers/user";

export function useCurrentUser() {
  const { user, loading, refetchUser } = useContext(currentUserContext);

  return useMemo(() => {
    const isStaff = !!user && user.type !== UserType.Customer;
    const isCustomer = !!user && user.type === UserType.Customer;
    const isOriginalUserStaff =
      !!user && (user.originalUserType ?? user.type) !== UserType.Customer;
    return {
      user,
      loading,
      isStaff,
      isOriginalUserStaff,
      isCustomer,
      isEmulating: !!user?.isEmulating,
      isPartner: !!user?.isPartner,
      refetchUser,
    };
  }, [loading, user, refetchUser]);
}
