import { Stack } from "@mui/material";
import { teal } from "@mui/material/colors";
import { BarChart } from "components/Dashboard/BarChart";
import ChartLegend from "components/Dashboard/ChartLegend";
import {
  InsightPenaltiesQuery,
  InsightsPenalty,
} from "generated-graphql/graphql";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import formatCurrency from "util/formatCurrency";
import {
  PriorityChartColors,
  ProgramAreaColors,
  generateQuarters,
  getPriorityChartColorByIndex,
} from "util/insights";
import { useOverviewState } from "../OverviewContext";

export const allowedPenaltiesBarChartTypes = [
  "byProgramArea",
  "byCostFacility",
  "byQuarter",
  "byQuarterProgramArea",
] as const;

export type PenaltiesBarChartType =
  (typeof allowedPenaltiesBarChartTypes)[number];

export type PenaltiesBarChartProps = {
  graphType: PenaltiesBarChartType;
  data: InsightPenaltiesQuery["insightsPenalties"] | undefined;
};

export const PenaltiesBarChart = ({
  graphType,
  data,
}: PenaltiesBarChartProps) => {
  const navigate = useNavigate();
  const {
    overviewState: { programArea },
  } = useOverviewState();
  const onBarChartItemClick = useCallback(
    (item: any | undefined, source: string) => {
      const omnisearchParams: string[] = [];

      if (item) {
        switch (source) {
          case "programArea":
            omnisearchParams.push(
              `programAreaPenalty:${encodeURIComponent(item.label)}`
            );
            break;
          case "byFacility":
            if (!item.frsId) break;
            omnisearchParams.push(
              `registryId:${encodeURIComponent(item.frsId)}`
            );
            break;
          case "byQuarterByProgramArea": {
            if (item.programArea) {
              omnisearchParams.push(
                `programAreaPenalty:${encodeURIComponent(item.programArea)}`
              );
            } else {
              omnisearchParams.push("hasPenalties:true");
            }
            break;
          }
          case "byQuarter": {
            omnisearchParams.push(`hasPenalties:true`);
            break;
          }
        }
      }

      if (source !== "byFacility" && programArea) {
        omnisearchParams.push(`programArea:${encodeURIComponent(programArea)}`);
      }

      const omnisearchValue = omnisearchParams.join(" ");
      const newSearchParams = new URLSearchParams();
      newSearchParams.set("omnisearch", omnisearchValue);

      navigate(`../echo?${newSearchParams.toString()}`);
    },
    [navigate, programArea]
  );

  if (!data) return null;

  switch (graphType) {
    case "byProgramArea":
      return (
        <ByProgramArea
          onBarChartItemClick={(fine) => {
            onBarChartItemClick(fine, "programArea");
          }}
          programAreas={data?.byProgramArea}
        />
      );
    case "byCostFacility":
      return (
        <ByCostFacility
          onBarChartItemClick={(fine) => {
            onBarChartItemClick(fine, "byFacility");
          }}
          facilities={data?.byFacility}
        />
      );
    case "byQuarter":
      return (
        <ByQuarter
          onBarChartItemClick={(fine) => {
            onBarChartItemClick(fine, "byQuarter");
          }}
          quarters={data?.byQuarter}
        />
      );
    case "byQuarterProgramArea":
      return (
        <ByQuarterProgramArea
          onBarChartItemClick={(fine) => {
            onBarChartItemClick(fine, "byQuarterByProgramArea");
          }}
          quarters={data?.byQuarterByProgramArea}
        />
      );
    default:
      return null;
  }
};

const ByProgramArea = ({
  programAreas = [],
  onBarChartItemClick,
}: {
  programAreas: InsightPenaltiesQuery["insightsPenalties"]["byProgramArea"];
  onBarChartItemClick: (fine: InsightsPenalty | undefined) => void;
}) => {
  return (
    <BarChart
      onItemClick={(event, item) => {
        const fine = programAreas[item.dataIndex];
        onBarChartItemClick(fine);
      }}
      dataset={
        programAreas.length ? programAreas : [{ cost: 0, label: "No data" }]
      }
      series={[
        {
          type: "bar",
          dataKey: "cost",
          valueFormatter: (val) => (val ? `$${val.toLocaleString()}` : "$0"),
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "label",
          colorMap: {
            type: "ordinal",
            values: Object.keys(ProgramAreaColors),
            colors: Object.values(ProgramAreaColors),
          },
          categoryGapRatio: 0.65,
        },
      ]}
      yAxis={[
        { scaleType: "sqrt", valueFormatter: (val) => formatCurrency(val) },
      ]}
      barLabel={({ value }) => (value ? formatCurrency(value) : "$0")}
      barLabelColor="white"
    />
  );
};

const ByCostFacility = ({
  facilities = [],
  limit = 5,
  onBarChartItemClick,
}: {
  facilities: InsightPenaltiesQuery["insightsPenalties"]["byFacility"];
  limit?: number;
  onBarChartItemClick: (item: InsightsPenalty | undefined) => void;
}) => {
  const dataset = useMemo(
    () =>
      facilities
        // Do not show facilities with a cost of 0
        .filter((f) => f.cost > 0)
        .slice(0, limit),
    [facilities, limit]
  );
  return (
    <Stack display="flex" direction="row" height="100%">
      <BarChart
        onItemClick={(event, item) => {
          const fine = facilities[item.dataIndex];
          onBarChartItemClick(fine);
        }}
        dataset={dataset.length ? dataset : [{ cost: 0, label: "No data" }]}
        series={[
          {
            type: "bar",
            dataKey: "cost",
            valueFormatter: (val) => (val ? `$${val.toLocaleString()}` : "$0"),
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "name",
            categoryGapRatio: 0.4,
            colorMap: {
              type: "ordinal",
              colors: PriorityChartColors,
            },
          },
        ]}
        yAxis={[
          {
            scaleType: "sqrt",
            valueFormatter: (val) => formatCurrency(val),
          },
        ]}
        barLabel={({ value }) => (value ? formatCurrency(value) : "$0")}
        barLabelColor="white"
        indexAxisLabelProperty={dataset.length ? "name" : undefined}
        sx={{ flex: 1 }}
      />
      <ChartLegend
        items={dataset.map((d, index) => ({
          color: getPriorityChartColorByIndex(index),
          value: `${index + 1}. ${d.label}`,
        }))}
        sx={{ flex: 1, maxWidth: "35%" }}
      />
    </Stack>
  );
};

const ByQuarter = ({
  quarters,
  onBarChartItemClick,
}: {
  quarters: InsightPenaltiesQuery["insightsPenalties"]["byQuarter"];
  onBarChartItemClick: (item: InsightsPenalty | undefined) => void;
}) => {
  const processedData = generateQuarters().map((label) => {
    const data = quarters.find((q) => q.label === label);
    return data || { label, cost: 0 };
  });

  return (
    <BarChart
      onItemClick={(event, barItemIdentifier) => {
        const clickedQuarter = processedData[barItemIdentifier.dataIndex];
        const originalQuarter = quarters.find(
          (q) => q.label === clickedQuarter.label
        );
        onBarChartItemClick(originalQuarter);
      }}
      dataset={processedData}
      series={[
        {
          type: "bar",
          dataKey: "cost",
          color: teal[200],
          valueFormatter: (val) => formatCurrency(val ?? undefined),
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "label",
          categoryGapRatio: 0.2,
          valueFormatter(value, context) {
            if (context.location === "tick") {
              return value.split(" ")[0] === "Q1"
                ? value.split(" ").join("\n")
                : value.split(" ")[0];
            }
            return value;
          },
        },
      ]}
      yAxis={[
        {
          scaleType: "sqrt",
          valueFormatter: formatCurrency,
        },
      ]}
    />
  );
};

const ByQuarterProgramArea = ({
  quarters,
  onBarChartItemClick,
}: {
  quarters: InsightPenaltiesQuery["insightsPenalties"]["byQuarterByProgramArea"];
  onBarChartItemClick: (item: any | undefined) => void;
}) => {
  const programAreas = Array.from(
    new Set(quarters.flatMap((q) => q.programAreas.flatMap((pa) => pa.label)))
  ).sort();

  const processedData = generateQuarters().map((quarter) => {
    const programAreas = Object.keys(ProgramAreaColors).reduce((acc, pa) => {
      const thisQuarter = quarters
        .find((q) => q.quarter === quarter)
        ?.programAreas.find((p) => p.label === pa);
      acc[pa.toLowerCase()] = thisQuarter?.cost ?? null;
      return acc;
    }, {} as Record<string, number | null>);

    return {
      ...programAreas,
      quarter: quarter,
    };
  });

  return (
    <>
      <ChartLegend
        config={{ direction: "row", position: "above" }}
        items={programAreas.map((pa) => ({
          value: pa,
          color: ProgramAreaColors[pa],
        }))}
        sx={{ paddingLeft: 6, position: "absolute" }}
      />
      <BarChart
        onItemClick={(event, barItemIdentifier) => {
          const clickedQuarter = processedData[barItemIdentifier.dataIndex];
          if (clickedQuarter) {
            onBarChartItemClick({
              programArea: barItemIdentifier.seriesId,
              label: clickedQuarter.quarter,
              cost: 0,
            });
          }
        }}
        dataset={processedData}
        series={Object.keys(ProgramAreaColors).map((programArea) => ({
          type: "bar",
          stack: "total",
          id: programArea,
          dataKey: programArea.toLowerCase(),
          color: ProgramAreaColors[programArea],
          valueFormatter: (value) =>
            programArea === "Multiple Programs"
              ? `Multi-program facilities (${formatCurrency(
                  value ?? undefined
                )})`
              : `${programArea} facilities (${formatCurrency(
                  value ?? undefined
                )})`,
        }))}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "quarter",
            categoryGapRatio: 0.2,
            valueFormatter(value, context) {
              if (context.location === "tick") {
                return value.split(" ")[0] === "Q1"
                  ? value.split(" ").join("\n")
                  : value.split(" ")[0];
              }
              return value;
            },
          },
        ]}
        yAxis={[
          {
            scaleType: "sqrt",
            valueFormatter: formatCurrency,
          },
        ]}
        stackedTooltipTotalFormatter={(total) =>
          `Total fines: ${formatCurrency(total)}`
        }
      />
    </>
  );
};
