import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  CredentialKind,
  CredentialsQuery,
  CredentialVerificationStatus,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { useMemo, useState } from "react";
import {
  credentialKindToLabel,
  credentialVerificationStatusToLabel,
} from "util/constants";
import { ConfirmDeleteCredential } from "./ConfirmDeleteCredential";
import { CredentialDialog } from "./CredentialDialog";
import { CREDENTIALS } from "./schema";
import { useCurrentUser } from "hooks/useCurrentUser";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useFacilityOverview from "hooks/useFacilityOverview";

export type Row = CredentialsQuery["credentials"]["items"][number];

export function Credentials() {
  const { tenantId } = useTenant();
  const { isStaff } = useCurrentUser();
  const { facilityId, virtualEventId } = useParams();
  const { data: facilityData } = useFacilityOverview(facilityId);
  const navigate = useNavigate();
  const location = useLocation();

  const [confirmDeleteCredential, setConfirmDeleteCredential] = useState<
    Row | undefined
  >(undefined);

  const pushVirtualEventIdToUrl = (id: string) => {
    navigate(`${id}${location.search}`);
  };

  const removeVirtualEventIdFromUrl = () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    pathSegments.pop(); // Remove the last segment
    const newPath = `/${pathSegments.join("/")}${location.search}`;
    navigate(newPath);
  };

  useBreadcrumb(
    facilityId
      ? [
          {
            label: "Facilities",
            to: `/o/${tenantId}/facilities`,
          },
          {
            label: facilityData?.facility?.name ?? "",
            to: `/o/${tenantId}/facilities/${facilityId}/profile`,
          },
          {
            label: "Credentials",
          },
        ]
      : [{ label: "Credentials" }]
  );

  // Grid Column Definitions
  const columns: OmnisearchGridColDef<Row>[] = useMemo(() => {
    const cols: OmnisearchGridColDef<Row>[] = [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        sortable: true,
      },
      {
        field: "username",
        headerName: "Username",
        flex: 1,
        sortable: true,
      },
      {
        field: "kind",
        headerName: "Type",
        flex: 1,
        sortable: true,
        valueGetter(params) {
          return credentialKindToLabel(params.row.kind);
        },
        filterKeyType: "enum",
        enumValues: Object.values(CredentialKind),
        enumPresentationFunction: (enumValue) =>
          credentialKindToLabel(enumValue as CredentialKind),
      },
      {
        field: "facilities",
        headerName: "Facilities",
        flex: 1,
        renderCell(params) {
          const facilities = params.row?.facilityCount
            ? params.row.facilityCount
            : "";

          return (
            <Stack>
              <Typography variant="body2">{facilities}</Typography>
            </Stack>
          );
        },
      },
      {
        field: "state",
        headerName: "State",
        sortable: false,
        flex: 1,
        renderCell({ row }) {
          const states = row.states ?? [];
          const display =
            states.slice(0, 10).join(", ") + (states.length > 10 ? " ..." : "");

          return (
            <Tooltip title={states?.join(", ")}>
              <Typography variant="body2">{display}</Typography>
            </Tooltip>
          );
        },
      },
    ];

    if (isStaff) {
      cols.push({
        field: "verificationStatus",
        headerName: "Verification Status",
        flex: 1,
        valueGetter({ row }) {
          return prettyPrintEnumValue(row.verificationStatus);
        },
        filterKeyType: "enum",
        enumValues: Object.values(CredentialVerificationStatus),
        enumPresentationFunction: (enumValue) =>
          credentialVerificationStatusToLabel(
            enumValue as CredentialVerificationStatus
          ),
      });
    }

    cols.push({
      field: "actions",
      type: "actions",
      align: "left",
      getActions: ({ row }) => {
        const actions = [
          <Tooltip title="Edit Credential" key={1}>
            <GridActionsCellItem
              onClick={() => pushVirtualEventIdToUrl(row.id)}
              label="Edit Credential"
              icon={<Edit />}
            />
          </Tooltip>,
          <Tooltip title="Delete Credential" key="delete">
            <GridActionsCellItem
              onClick={() => setConfirmDeleteCredential(row)}
              label="Delete Credential"
              icon={<Delete />}
            />
          </Tooltip>,
        ];

        return actions;
      },
    });

    return cols;
  }, [isStaff, pushVirtualEventIdToUrl]);

  return (
    <Box sx={{ pt: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        initialSortModel={[{ field: "name", sort: "asc" }]}
        dataQuery={CREDENTIALS}
        defaultSearch={`tenantId:${tenantId}${
          facilityId ? ` facilityId:${facilityId}` : ""
        }`}
        getItems={(data) => data.credentials.items}
        getCount={(data) => data.credentials.count ?? 0}
        showFavorites={true}
        noDataMessage={
          facilityId
            ? "No credentials have been added to this facility yet."
            : "No credentials have been added to this organization yet."
        }
        isRowSelectable={() => false}
        onRowClick={({ row }) => pushVirtualEventIdToUrl(row.id)}
        commandButtons={[
          <Button
            key="add-credential-button"
            variant="contained"
            onClick={() => pushVirtualEventIdToUrl("add")}
          >
            Add Credential
          </Button>,
        ]}
        rowHeight="auto"
        initialPageSize={50}
        additionalFilterColumns={
          facilityId
            ? []
            : [
                {
                  key: "facility",
                  header: "Facility",
                },
              ]
        }
        excludeFilterColumns={
          facilityId ? ["facility", "facilities"] : ["facilities"]
        }
      />
      {virtualEventId &&
        virtualEventId !== "add" &&
        !virtualEventId.startsWith("delete/") && (
          <CredentialDialog
            onClose={removeVirtualEventIdFromUrl}
            mode="edit"
            credentialId={virtualEventId}
            currentFacility={facilityData?.facility}
          />
        )}
      {virtualEventId === "add" && (
        <CredentialDialog
          onClose={removeVirtualEventIdFromUrl}
          mode="add"
          currentFacility={facilityData?.facility}
        />
      )}
      {confirmDeleteCredential && (
        <ConfirmDeleteCredential
          credentialId={confirmDeleteCredential.id}
          onClose={() => setConfirmDeleteCredential(undefined)}
        />
      )}
    </Box>
  );
}
