import { useLazyQuery } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DocumentType, GetReportDetailsQuery } from "generated-graphql/graphql";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { prettyPrintDateMed } from "util/dates";
import { Report } from "../useReport";

type SubmissionRecordsProps = { report: Report };
type ReportDocument = NonNullable<
  GetReportDetailsQuery["tierIIReport"]["documents"]
>[0];

export const SubmissionRecords: React.FC<SubmissionRecordsProps> = ({
  report,
}) => {
  const theme = useTheme();

  if (!report?.documents?.length) {
    return <></>;
  }

  return (
    <Box mt={theme.spacing(2)}>
      <Typography variant="h6">Submission Records</Typography>
      <Paper elevation={1} sx={{ borderRadius: "5px", overflow: "hidden" }}>
        <List>
          {report.documents.map((document, index) => (
            <>{document && <RecordRow record={document} key={index} />}</>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

const RecordRow: React.FC<{ record: ReportDocument }> = ({ record }) => {
  const alerts = useAlerts();
  const [getDownloadLink] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_LINK_QUERY);
  const handleDownloadClick = async (documentId: string) => {
    const { data, error } = await getDownloadLink({
      variables: { id: documentId },
    });

    if (!data?.getDocumentDownloadLink || error) {
      alerts.error("An error occurred while viewing the record");
      return;
    }

    window.open(data.getDocumentDownloadLink, "_blank");
  };
  return (
    <ListItem
      sx={{
        padding: "10px",
        "&:not(:last-child)": {
          borderBottom: "1px solid #dcdcdc",
        },
      }}
    >
      {document && (
        <ListItemIcon>
          <IconButton onClick={() => handleDownloadClick(record.id)} edge="end">
            <Download color="primary" />
          </IconButton>
        </ListItemIcon>
      )}
      <ListItemText
        primary={record.title}
        secondary={recordToDescriptionLabel(record)}
      />
    </ListItem>
  );
};

const recordToDescriptionLabel = (record: ReportDocument) => {
  let timestampPrefix = "";
  switch (record.documentType) {
    case DocumentType.AstDocumentation:
    case DocumentType.DikeDescription:
    case DocumentType.EmergencyResponsePlan:
    case DocumentType.HazardousMaterialsInventoryStatement:
    case DocumentType.HazardousMaterialsManagementPlan:
    case DocumentType.Other:
    case DocumentType.ReferenceImage:
    case DocumentType.SafeguardMeasures:
    case DocumentType.Screenshot:
    case DocumentType.SafetyDataSheet:
    case DocumentType.SiteCoordinateAbbreviation:
    case DocumentType.SitePlan:
    case DocumentType.TrainingPlan:
    case DocumentType.UstDocumentation:
      timestampPrefix = "Generated ";
      break;
    case DocumentType.Receipt:
      timestampPrefix = "Paid on ";
      break;
    case DocumentType.Invoice:
      timestampPrefix = "Received ";
      break;
    case DocumentType.Report:
      timestampPrefix = "Submitted ";
      break;
  }

  return `${timestampPrefix} ${prettyPrintDateMed(record.createdAt)}`;
};
