import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useTenant } from "hooks/useTenant";
import { OrganizationTable } from "routes/Staff/Implementation/OrganizationTable";

export function Organizations() {
  const { tenant } = useTenant();

  useBreadcrumb({
    label: "Organizations",
    to: `/o/${tenant?.id}/organizations`,
  });

  return <OrganizationTable />;
}
