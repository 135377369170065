import { useMutation } from "@apollo/client";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Dialog } from "components/Dialog";
import { FormTextField } from "components/Forms/FormTextField";
import { RolePicker } from "components/RolePicker";
import { SaveButton } from "components/SaveButton";
import { gql } from "generated-graphql";
import {
  CreateUserInput,
  Permission,
  Role,
  UpdateUserInput,
} from "generated-graphql/graphql";
import { useTenant } from "hooks/useTenant";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import AssociateFacilitiesDialog from "./AssociateFacilitiesDialog";
import { useUserInputValidation } from "./validationHooks/useUserInputValidation";
import { hasCriticalIssues } from "util/forms";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useAuthorization } from "hooks/useAuthorization";
import { useCurrentUser } from "hooks/useCurrentUser";
import { RoleNamesEnum } from "encamp-shared/src/constants/permissionsTypes";
import { getConfirmMessage } from "./util";

const _roleGivesAccessToAllFacilities = (
  role: Pick<Role, "permissions"> | null | undefined
) => {
  if (role?.permissions == null) return false;

  return (
    role.permissions.includes(Permission.ReadAllFacility) ||
    role.permissions.includes(Permission.WriteAllFacility)
  );
};

type UserFormData = CreateUserInput & Partial<UpdateUserInput>;

type AddUserDialogProps = {
  onClose: () => void;
  open: boolean;
  tenantId: string;
};

const CREATE_USER_MUTATION = gql(`
mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
  }
}
`);

export const AddUserDialog = ({
  onClose,
  open,
  tenantId,
}: AddUserDialogProps) => {
  const theme = useTheme();
  const { hasPermissions } = useAuthorization();
  const { tenant } = useTenant();

  const [associateFacilitiesOpen, setAssociateFacilitiesOpen] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const { isStaff } = useCurrentUser();
  const roleAccessesAllFacilities = useMemo(
    () => _roleGivesAccessToAllFacilities(role),
    [role]
  );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const alerts = useAlerts();

  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);

  const [createUser, { loading: creatingUser }] = useMutation(
    CREATE_USER_MUTATION,
    { refetchQueries: ["Users"] }
  );

  const defaultValues = useMemo(() => {
    return {
      id: userId,
      tenantId,
      email: userEmail ?? "",
      first: "",
      last: "",
      roleId: "",
      status: null,
    };
  }, [userId, userEmail, tenantId]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isDirty },
    issues,
  } = useValidatingForm<UserFormData>(
    defaultValues,
    [],
    useUserInputValidation()
  );

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const { first, last, email, roleId } = watch();

  const roleEditPermissionChange: "increase" | "decrease" | "noChange" =
    useMemo(() => {
      const defaultRoleGivesAccessToAllFacilities = false;
      if (roleAccessesAllFacilities && !defaultRoleGivesAccessToAllFacilities)
        return "increase";
      if (!roleAccessesAllFacilities && defaultRoleGivesAccessToAllFacilities)
        return "decrease";
      return "noChange";
    }, [roleAccessesAllFacilities]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const userName = useMemo(() => {
    if (first && last && email) return `${first} ${last} (${email})`;
    if (email) return email;
    return "";
  }, [first, last, email]);

  const confirmMessage = getConfirmMessage({
    roleEditPermissionChange,
    roleName: role?.name ?? "",
    userName,
    isPartner: tenant?.isPartner ?? false,
    action: "add",
  });

  const saveUser = useCallback(
    async (formData: UserFormData) => {
      const { first, last, roleId, email } = formData;
      const { data: newUser } = await createUser({
        variables: {
          input: {
            tenantId,
            email,
            roleId,
            first,
            last,
          },
        },
      });

      if (newUser?.createUser?.id) {
        setUserId(newUser?.createUser?.id);
        setUserEmail(email);
      }
    },
    [createUser, tenantId]
  );

  const submitAndClose = useCallback(
    async (formData: UserFormData) => {
      try {
        await saveUser(formData);
        if (!roleAccessesAllFacilities) {
          setAssociateFacilitiesOpen(true);
        } else {
          handleClose();
        }
        alerts.success(`Successfully created user ${userName}`);
      } catch (err) {
        alerts.error(`Error creating user.`, err);
      }
    },
    [saveUser, handleClose, userName, alerts, roleAccessesAllFacilities]
  );

  const onSubmit = useCallback(
    async (formData: UserFormData) => {
      if (tenant?.isPartner || roleEditPermissionChange !== "noChange") {
        setShowConfirmationDialog(true);
        return;
      }

      await submitAndClose(formData);
    },
    [tenant?.isPartner, submitAndClose, roleEditPermissionChange]
  );

  const allowWrite = useMemo(() => {
    // if we don't have the create permission it is read only
    if (hasPermissions([Permission.CreateUserTenant])) return true;

    return false;
  }, [hasPermissions]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box>Add User</Box>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box>
              <Stack direction="column">
                <Stack direction="row">
                  <Grid container spacing={theme.spacing(3)}>
                    <Grid item md={12}>
                      {
                        <Stack>
                          <FormTextField
                            name="email"
                            label="Email"
                            type="email"
                            control={control}
                            disabled={!allowWrite}
                            textFieldProps={{ required: true }}
                            sx={{ mb: theme.spacing(0.5) }}
                          />

                          <FormTextField
                            name="first"
                            label="First Name"
                            control={control}
                            disabled={!allowWrite}
                          />

                          <FormTextField
                            name="last"
                            label="Last Name"
                            control={control}
                            disabled={!allowWrite}
                          />
                          <Controller
                            name="roleId"
                            control={control}
                            render={({ field, fieldState }) => (
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: theme.spacing(3) }}
                              >
                                <RolePicker
                                  {...field}
                                  {...fieldState}
                                  value={roleId ?? ""}
                                  disabled={!allowWrite}
                                  roleDisabled={(role) =>
                                    !isStaff &&
                                    role.name === RoleNamesEnum.superAdmin
                                  }
                                  onChange={(role) => {
                                    setRole(role);
                                    setValue("roleId", role?.id ?? null, {
                                      shouldDirty: true,
                                    });
                                  }}
                                  tenantId={tenantId}
                                />
                              </FormControl>
                            )}
                          />
                        </Stack>
                      }
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            {hasPermissions([Permission.CreateUserTenant]) && (
              <SaveButton
                loading={creatingUser}
                disabled={hasCriticalIssues(issues) || !isDirty}
                saveText={roleAccessesAllFacilities ? "Add" : "Save & Next"}
                startIcon={
                  roleAccessesAllFacilities ? (
                    <SaveIcon />
                  ) : (
                    <ArrowForwardIcon />
                  )
                }
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
      {userId && userEmail && (
        <AssociateFacilitiesDialog
          open={associateFacilitiesOpen}
          onClose={() => {
            setAssociateFacilitiesOpen(false);
            handleClose();
          }}
          userId={userId}
          userEmail={userEmail}
          userFacilities={[]}
          tenantId={tenantId ?? ""}
          cancelBtnText="Skip"
          onSave={() => {
            alerts.success(`Successfully created user ${userName}`);
          }}
        />
      )}
      <ConfirmDialog
        loading={creatingUser}
        open={showConfirmationDialog}
        msg={<Box>{confirmMessage}</Box>}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={async () => {
          try {
            await submitAndClose(getValues());
          } finally {
            setShowConfirmationDialog(false);
          }
        }}
      />
    </>
  );
};
