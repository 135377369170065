import { Box, List, ListItem, Typography, useTheme } from "@mui/material";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import useFacilityOverview from "hooks/useFacilityOverview";
import { NavLink, Outlet, useParams } from "react-router-dom";
import {
  getChemicalsBreadcrumb,
  getChemicalsInventoryBreadcrumb,
  getChemicalsInventoryFacilityBreadcrumb,
} from "util/breadcrumb";
import {
  stepToDisplayName,
  stepToRoute,
  useChemicalSteps,
} from "../../Report/useReport";

const menuWidth = 24;

export function FacilityInventoryDetail() {
  const theme = useTheme();
  const { facilityId, tenantId } = useParams();
  const { data: facilityData } = useFacilityOverview(facilityId);
  const chemicalSteps = useChemicalSteps(facilityId);
  const showSidebar = chemicalSteps.length > 1;

  useBreadcrumb([
    {
      label: "Chemicals",
      to: tenantId ? getChemicalsBreadcrumb(tenantId) : undefined,
    },
    {
      label: "Inventory",
      to: tenantId ? getChemicalsInventoryBreadcrumb(tenantId) : undefined,
    },
    {
      label: facilityData?.facility?.name ?? "",
      to:
        tenantId && facilityId
          ? getChemicalsInventoryFacilityBreadcrumb(tenantId, facilityId)
          : undefined,
    },
  ]);

  if (!showSidebar) {
    return (
      <Box
        sx={{
          paddingTop: theme.spacing(3),
          paddingX: theme.spacing(3),
        }}
      >
        <Outlet />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          paddingTop: theme.spacing(3),
          width: theme.spacing(menuWidth),
          position: "fixed",
          height: "100%",
          overflow: "auto",
          backgroundColor: theme.palette.background.default,
          borderRight: `1px solid ${theme.palette.divider}`,
        }}
      >
        <List sx={{ padding: 0 }}>
          {chemicalSteps.map((step, i) => (
            <NavLink
              key={i}
              to={stepToRoute(step)}
              style={{ textDecoration: "none" }}
            >
              {({ isActive }) => (
                <ListItem
                  key={i}
                  sx={{
                    height: theme.spacing(6),
                    color: theme.palette.text.primary,
                    padding: 0,
                    margin: 0,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: theme.palette.action.hover,
                    },
                    textDecoration: "none",
                    backgroundColor: isActive
                      ? theme.palette.action.selected
                      : theme.palette.background.paper,
                    fontWeight: isActive ? "bold" : "normal",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="primary"
                    paddingLeft={theme.spacing(3)}
                  >
                    {stepToDisplayName[step]}
                  </Typography>
                </ListItem>
              )}
            </NavLink>
          ))}
        </List>
      </Box>

      <Box
        style={{
          marginLeft: theme.spacing(menuWidth),
          paddingTop: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}
