import {
  gridDetailPanelExpandedRowIdsSelector,
  GridRowId,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";

export const useExpandableRow = (id: GridRowId) => {
  const apiRef = useGridApiContext();

  const expandedRowIds = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowIdsSelector
  );

  const isExpanded = expandedRowIds.includes(id);

  const toggleRow = () => {
    const expandedRows = expandedRowIds.includes(id)
      ? expandedRowIds.filter((rowId) => rowId !== id)
      : [...expandedRowIds, id];

    apiRef.current.setExpandedDetailPanels(expandedRows);
  };

  return { isExpanded, toggleRow };
};
