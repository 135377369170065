import {
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import CopyableTypography from "components/Typography/CopyableTypography";
import { JobLog, JobStepsDone } from "generated-graphql/graphql";
import { compact, orderBy, sortBy } from "lodash";
import { useCallback, useState } from "react";
import {
  prettyPrintDateTime,
  prettyPrintDateTimeWithSeconds,
  relativeLogTime,
} from "util/dates";
import { useReportDetails } from "./useReportDetails";
import { DataGridWithDefaultSort } from "components/DataGridWithDefaultSort";

export function WebAutomationJobInfo() {
  const theme = useTheme();
  const { data } = useReportDetails();
  const jobs = orderBy(
    data?.tierIIReport.facility.jobs ?? [],
    ["createdAt"],
    ["desc"]
  );

  const [selectedJob, setSelectedJob] = useState(jobs?.[0]);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([selectedJob?.id]);

  const logs = sortBy(compact(selectedJob?.logs), (log) => log.createdAt);
  const stepsDone = compact(selectedJob?.stepsDone);
  const screenshotSrcs = compact(
    logs.map(
      (log) =>
        log.screenshotUrl && {
          src: log.screenshotUrl,
          createdAt: log.createdAt,
        }
    )
  );

  const handleImageClick = useCallback(
    (src: string) => window.open(src, "_blank"),
    []
  );
  const handleRowClick = (params: GridRowParams) => {
    setSelectedJob(params.row);
    setRowSelectionModel([params.row.id]);
  };

  return (
    <Paper sx={{ paddingTop: theme.spacing(2) }}>
      <Grid
        container
        spacing={theme.spacing(2)}
        padding={theme.spacing(2)}
        paddingTop={0}
        // TODO: This is a bad hack to deal with large pages of logs. Ideally we
        // would pull these into a paginated list of logs
        minHeight={"500px"}
      >
        <Grid item xs={12}>
          <Typography paddingLeft={theme.spacing(1)} variant="h6">
            Portal Interaction Records
          </Typography>
          <DataGridWithDefaultSort
            autoHeight
            hideFooter
            rows={jobs}
            sortModel={[{ field: "createdAt", sort: "desc" }]}
            rowSelectionModel={rowSelectionModel}
            onRowClick={handleRowClick}
            columns={[
              {
                field: "id",
                headerName: "ID",
                width: 250,
                renderCell: ({ row }) => (
                  <CopyableTypography variant="body2" text={row.id} />
                ),
              },
              { field: "action", headerName: "Action", width: 160 },
              { field: "status", headerName: "Status" },
              {
                field: "createdAt",
                headerName: "Created At",
                width: 160,
                renderCell: ({ row }) => (
                  <Tooltip title={prettyPrintDateTime(row.createdAt)}>
                    <span>{relativeLogTime(row.createdAt)}</span>
                  </Tooltip>
                ),
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} mb={theme.spacing(3)}>
          <Typography paddingLeft={theme.spacing(1)} variant="h6">
            Logs for {selectedJob && selectedJob.id}
          </Typography>
          <DataGridWithDefaultSort
            sx={{ maxHeight: "800px" }}
            hideFooter
            sortModel={[{ field: "createdAt", sort: "desc" }]}
            columns={logColumns}
            rows={logs}
          />
        </Grid>

        {screenshotSrcs.length > 0 && (
          <Grid item xs={12}>
            <Typography paddingLeft={theme.spacing(1)} variant="h6">
              Error Screenshots
            </Typography>

            <Grid item container spacing={1}>
              {screenshotSrcs.map(({ createdAt, src }) => (
                <Grid
                  item
                  key={src}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <img
                    style={{
                      width: 200,
                      height: 200,
                      borderRadius: 4,
                      marginTop: theme.spacing(2),
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(2),
                    }}
                    src={src}
                    alt={`Geppetto error screenshot ${src}`}
                    onClick={() => handleImageClick(src)}
                  />
                  <Typography
                    variant="caption"
                    style={{ marginTop: theme.spacing(1), textAlign: "center" }}
                  >
                    {prettyPrintDateTime(createdAt)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography paddingLeft={theme.spacing(1)} variant="h6">
            Steps Done
          </Typography>
          <DataGridWithDefaultSort
            autoHeight
            hideFooter
            sortModel={[{ field: "createdAt", sort: "desc" }]}
            columns={stepsDoneColumns}
            rows={stepsDone}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

const logColumns: GridColDef<JobLog>[] = [
  {
    field: "status",
    headerName: "Status",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Logged At",
    width: 150,
    renderCell: ({ row }) => (
      <span>{prettyPrintDateTimeWithSeconds(row.createdAt)}</span>
    ),
  },
  {
    field: "errorMessage",
    headerName: "Message",
    flex: 0.5,
    renderCell: ({ row }) => <MessageCell message={row.errorMessage} />,
  },
  // TODO: maybe this can be a direct link to the Cloudwatch console
  // {
  //   field: "cloudWatchLogStream",
  //   headerName: "CloudWatch Log Stream",
  //   width: 260,
  //   renderCell: ({ row }) => (
  //     <CopyableTypography variant="body2" text={row.cloudWatchLogStream} />
  //   ),
  // },
];

const stepsDoneColumns: GridColDef<JobStepsDone>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 0.75,
  },
];

const MessageCell = ({ message }: { message?: string | null }) => {
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const openDialog = () => setMessageDialogOpen(true);
  const closeDialog = () => setMessageDialogOpen(false);
  return (
    <>
      <Typography onClick={openDialog} variant="body2">
        {message}
      </Typography>

      <Dialog open={messageDialogOpen} onClose={closeDialog}>
        <DialogContent>
          <CopyableTypography variant="body2" text={message} />
        </DialogContent>
      </Dialog>
    </>
  );
};
