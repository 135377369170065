import { TypedDocumentNode, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { FieldError } from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { useDebounce } from "use-debounce";
import { GridSortModel } from "@mui/x-data-grid-premium";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";

type OmnisearchPaginationModel = {
  search: string;
  page: number;
  pageSize: number;
  sort: GridSortModel;
};

/**
 * Props for the OmniAutocompleteProps component.
 */
interface AsyncAutocompleteProps<
  TData,
  TOption extends { id: string },
  TAdditionalVars extends Record<string, unknown>
> {
  /** GraphQL query document */
  query: TypedDocumentNode<TData, TAdditionalVars>;
  /** Function to extract items from the query result */
  getItems: (data: TData) => TOption[];
  /** Function to extract the label from an option */
  getOptionLabel: (option: TOption) => string;
  placeholderLabel?: string;
  /** Additional variables for the query */
  variables?: Partial<TAdditionalVars>;
  /** Initial pagination model */
  initialPaginationModel?: Partial<OmnisearchPaginationModel>;
  /** Callback for when an option is selected */
  onChange?: (value: TOption | null) => void;
  /** The value of the autocomplete */
  value?: TOption | null;
  /** Error to display */
  error?: FieldError;
  /** Display in a disabled state */
  disabled?: boolean;
  /** Additional search string to append to the search query */
  additionalSearchString?: string;
}

/**
 * OmniAutocompleteProps Component
 * A generic autocomplete component that supports pagination and GraphQL integration.
 *
 * @param query - The GraphQL query document to fetch data.
 * @param getItems - Function to extract items from the query result.
 * @param getOptionLabel - Function to extract the label from an option.
 * @param variables - Variables for the query.
 * @param onChange - Callback for when an option is selected.
 * @param value - The value of the autocomplete.
 * @param error - Error to display.
 * @returns A JSX element representing the paginated autocomplete component.
 *
 * @example
 * ```tsx
 * <AsyncAutocomplete
 *   query={FIRE_DEPARTMENTS_QUERY}
 *   variables={{
 *     state: facilityState,
 *     county: facilityCounty,
 *   }}
 *   getItems={(data: FireDepartmentsQuery) =>
 *     data.fireDepartments?.items ?? []
 *   }
 *   onChange={onChange}
 *   getOptionLabel={(fireDepartment) =>
 *     `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.state}`
 *   }
 *   value={value}
 *   error={error}
 * />
 * ```
 */
const AsyncAutocomplete = <
  TData,
  TOption extends { id: string },
  TAdditionalVars extends Record<string, unknown>
>({
  query,
  getItems,
  getOptionLabel,
  placeholderLabel = "Search",
  variables = {},
  onChange,
  value,
  error,
  disabled,
  additionalSearchString = "",
}: AsyncAutocompleteProps<TData, TOption, TAdditionalVars>) => {
  type VariablesType = OmnisearchPaginationModel & Partial<TAdditionalVars>;
  const {
    omnisearch,
    setOmnisearch,
    paginationModel: { page, pageSize },
    setPaginationModel,
    sortModel,
  } = useOmnisearchDatagrid({
    isURLDriven: false,
    initialPageSize: 20,
    initialSortModel: [{ field: "name", sort: "asc" }],
  });
  const [options, setOptions] = useState<TOption[]>([]);
  const [hasMoreOptions, setHasMoreOptions] = useState<boolean>(true);
  const search = useMemo(
    () => `${additionalSearchString} ${omnisearch}`.trim(),
    [additionalSearchString, omnisearch]
  );
  const [debouncedSearch] = useDebounce(search, 150);

  const variablesWithPagination: VariablesType = useMemo(
    () => ({
      ...variables,
      search,
      page,
      pageSize,
      sort: sortModel,
    }),
    [page, pageSize, search, sortModel, variables]
  );

  const {
    data,
    fetchMore,
    loading,
    error: dataLoadingError,
  } = useQuery<TData, VariablesType>(query, {
    variables: variablesWithPagination,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const items = data ? getItems(data) : [];
      setOptions((prevOptions) => [...prevOptions, ...items]);
      setHasMoreOptions(items.length >= pageSize);
    },
    skip: disabled,
  });

  // Handler for input changes in the autocomplete
  const handleInputChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newInputValue: string) => {
      setOmnisearch(newInputValue);
      setPaginationModel({
        page: 0, // Reset the page when we get a new input
        pageSize,
      });
      setOptions([]); // Clear the previous options
    },
    [pageSize, setOmnisearch, setPaginationModel, setOptions]
  );
  // Handler for scrolling in the autocomplete listbox
  const handleScroll = useCallback(
    async (event: React.UIEvent<HTMLUListElement>) => {
      const bottom =
        event.currentTarget.scrollHeight <=
        event.currentTarget.scrollTop + event.currentTarget.clientHeight;

      if (bottom && hasMoreOptions && !loading) {
        await fetchMore({
          variables: {
            ...variablesWithPagination,
            search: `${debouncedSearch} ${additionalSearchString}`.trim(),
            page: page + 1,
            pageSize,
          },
        });
        setPaginationModel({
          page: page + 1,
          pageSize,
        });
      }
    },
    [
      additionalSearchString,
      debouncedSearch,
      fetchMore,
      hasMoreOptions,
      loading,
      page,
      pageSize,
      setPaginationModel,
      variablesWithPagination,
    ]
  );
  return (
    <>
      {dataLoadingError && (
        <Typography variant="body2" color="error">
          An error occurred while searching.
        </Typography>
      )}
      <Autocomplete
        options={options}
        getOptionLabel={getOptionLabel}
        // Disable the built-in filtering since it is handled server-side
        filterOptions={(x) => x}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_, value) => {
          onChange?.(value);
        }}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            label={placeholderLabel}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
        loading={loading}
        loadingText="Loading..."
        noOptionsText="No options found."
      />
      <ErrorDisplay error={error} />
    </>
  );
};

export default AsyncAutocomplete;
