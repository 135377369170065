import { useMediaQuery, useTheme } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { LeftNavDrawer, NavItem } from "../components/LeftNavDrawer";
import { Main } from "../components/Main";
import { TopNavBar } from "../components/TopNavBar";
import { useThemeMode } from "../hooks/useThemeMode";

export function Layout(props: PropsWithChildren<{ navItems: NavItem[] }>) {
  const { toggleThemeMode } = useThemeMode();
  const theme = useTheme();
  const drawerWidth = theme.spacing(25);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(isLargeScreen);

  useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <>
      <LeftNavDrawer
        navItems={props.navItems}
        open={open}
        drawerWidth={drawerWidth}
        onClose={handleDrawerToggle}
      />
      <Main open={open} drawerWidth={drawerWidth}>
        <TopNavBar
          handleThemeToggle={toggleThemeMode}
          onMenuClick={handleDrawerToggle}
        />
        {props.children}
      </Main>
    </>
  );
}
