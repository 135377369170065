import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { PoliceDepartmentForValidation } from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";

import {
  GET_NJ_POLICE_DEPARTMENT,
  UPSERT_POLICE_DEPARTMENT,
  GET_ALL_NJ_POLICE_DEPARTMENTS,
} from "./schema";
import { hasCriticalIssues } from "util/forms";
import { useCallback } from "react";

const VALIDATE_POLICE_DEPARTMENT_INPUT = gql(`
  query ValidatePoliceDepartmentInput($id: Int, $data: PoliceDepartmentForValidation!) {
    policeDepartmentInputValidator(id: $id, data: $data) {
      ...issue
    }
  }
`);

export function usePoliceDepartmentInputValidation(isCreate: boolean) {
  const [query] = useLazyQuery(VALIDATE_POLICE_DEPARTMENT_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: PoliceDepartmentForValidation) => {
      const { data } = await query({
        variables: {
          data: input,
          id: !isCreate ? input.id : undefined,
        },
      });

      return data?.policeDepartmentInputValidator ?? [];
    },
    [query, isCreate]
  );
}

type PoliceDepartmentDialogProps = {
  agencyType: string;
  onClose: () => void;
  mode?: "add" | "edit";
  agencyId?: number;
};

export const PoliceDepartmentDialog: React.FC<PoliceDepartmentDialogProps> = ({
  agencyType,
  onClose,
  mode,
  agencyId,
}) => {
  const theme = useTheme();
  const alerts = useAlerts();

  const { data: agency, loading: isLoadingAgency } = useQuery(
    GET_NJ_POLICE_DEPARTMENT,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: agencyId ?? -1,
      },
      skip: !agencyId,
    }
  );

  const [upsertPoliceDepartment, { loading: isUpserting }] = useMutation(
    UPSERT_POLICE_DEPARTMENT,
    { refetchQueries: ["PoliceDepartment"] }
  );

  const defaultValues: PoliceDepartmentForValidation = useMemo(
    () => ({
      id: agency?.policeDepartment.id ?? undefined,
      name: agency?.policeDepartment?.name ?? "",
      address: agency?.policeDepartment?.address ?? "",
      attn: agency?.policeDepartment?.attn ?? "",
      city: agency?.policeDepartment?.city ?? "",
      state: agency?.policeDepartment?.state ?? "NJ",
      zip: agency?.policeDepartment?.zip ?? "",
      email: agency?.policeDepartment?.email ?? "",
      contactName: agency?.policeDepartment?.contactName ?? "",
      additionalComments: agency?.policeDepartment?.additionalComments ?? "",
    }),
    [agency]
  );

  const form = useValidatingForm<PoliceDepartmentForValidation>(
    defaultValues,
    agency?.policeDepartment.issues,
    usePoliceDepartmentInputValidation(mode === "add")
  );

  const { handleSubmit, control, getValues, issues } = form;

  const onSubmit = async () => {
    const input = {
      ...getValues(),
    };

    await upsertPoliceDepartment({
      variables: { id: agencyId, input },
      onCompleted: () => {
        onClose();
        alerts.success("Agency saved successfully");
      },
      onError: (error) => {
        console.error(error);
        alerts.error("An error occurred while saving the Agency");
      },
      refetchQueries: [GET_ALL_NJ_POLICE_DEPARTMENTS],
    });
  };

  const savingOrLoading = isLoadingAgency || isUpserting;
  return (
    <>
      <Dialog open onClose={onClose} maxWidth="lg">
        <DialogTitle>
          {mode === "add" ? "Add" : "Edit"} {agencyType}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 500 }}>
          <FormProvider {...form}>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                padding: 0.5,
                width: 500,
              }}
            >
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={3} minWidth="100%">
                  <FormTextField
                    name="name"
                    label="Name"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField
                    name="address"
                    label="Address"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField name="attn" label="Attn" control={control} />
                  <FormTextField
                    name="city"
                    label="City"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField
                    name="state"
                    label="State"
                    control={control}
                    disabled
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField name="zip" label="Zip" control={control} />
                  <FormTextField name="email" label="Email" control={control} />
                  <FormTextField
                    name="contactName"
                    label="Contact Name"
                    control={control}
                  />
                  <FormTextField
                    name="additionalComments"
                    label="Additional Comments"
                    control={control}
                    textFieldProps={{ multiline: true }}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
          <Button onClick={onClose}>Cancel</Button>
          <SaveButton
            loading={savingOrLoading}
            saveText="Save"
            onClick={onSubmit}
            disabled={
              hasCriticalIssues(issues) ||
              (mode === "add" && !form.formState.isDirty)
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
