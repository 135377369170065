import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { ExportButton, ExportFileType } from "components/ExportButton";
import { Importer } from "components/Importer";
import { ImportButtonWithDialog } from "components/Importer/ImportDialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { LepcSearchQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import compact from "lodash/compact";
import { useMemo, useState } from "react";
import { ConfirmDeleteLEPC } from "./ConfirmDeleteLEPC";
import { CreateEditLepcDialog } from "./CreateEditDialog";
import { LEPC_SEARCH } from "./api";
import { IssueCount } from "components/IssueCount";

type Row = LepcSearchQuery["lepcSearch"]["items"][number];

type LepcsProps = {
  initialPageSize?: number;
};

export const Lepcs: React.FC<LepcsProps> = ({ initialPageSize }) => {
  const [openLepcDialog, setOpenLepcDialog] = useState<{
    mode: "add" | "edit";
    agency?: Row;
  } | null>(null);

  const [confirmDeleteLepc, setConfirmDeleteLepc] = useState<Row | null>(null);

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 100 },
      { field: "name", headerName: "Name", flex: 3 },
      {
        field: "mailingAddress",
        headerName: "Mailing Address",
        flex: 2,
        valueGetter: (params) => {
          return compact([
            params.row.mailingAddress,
            params.row.mailingAddressLine2,
          ]).join(", ");
        },
      },
      { field: "city", headerName: "City", flex: 1 },
      { field: "county", headerName: "County", flex: 1 },
      { field: "state", headerName: "State", width: 75 },
      { field: "zip", headerName: "Zip", width: 75 },
      { field: "email", headerName: "Email", flex: 3 },
      {
        field: "issues",
        headerName: "Issues",
        flex: 0.3,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row: { issues } }) => {
          return <IssueCount issueCount={issues?.length} />;
        },
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        align: "right",
        getActions: ({ row }) => {
          return [
            <Tooltip title="Edit LEPC" key={1}>
              <GridActionsCellItem
                label="Edit LEPC"
                onClick={() => setOpenLepcDialog({ mode: "edit", agency: row })}
                icon={<Edit />}
              />
            </Tooltip>,
            <Tooltip title="Delete LEPC" key={2}>
              <GridActionsCellItem
                label="Delete LEPC"
                onClick={() => setConfirmDeleteLepc(row)}
                icon={<Delete />}
              />
            </Tooltip>,
          ];
        },
      },
    ],
    []
  );

  return (
    <Box sx={{ pt: 3, padding: 2 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={LEPC_SEARCH}
        getItems={(data: any) => data.lepcSearch.items ?? []}
        getCount={(data: any) => data.lepcSearch.count ?? []}
        noDataMessage="No LEPCs found."
        initialPageSize={initialPageSize ?? 25}
        onRowClick={({ row }) => {
          setOpenLepcDialog({ mode: "edit", agency: row });
        }}
        commandButtons={[
          <ExportButton
            key="export"
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/lepcs`}
          />,
          <ImportButtonWithDialog
            key="import"
            importers={[
              {
                name: "LEPCs",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.LEPC}
                    refetchQueries={["LepcSearch"]}
                  />
                ),
              },
            ]}
          />,
          <Button
            key="add-lepc-button"
            variant="contained"
            onClick={() => setOpenLepcDialog({ mode: "add" })}
          >
            Add LEPC
          </Button>,
        ]}
      />
      {!!openLepcDialog && (
        <CreateEditLepcDialog
          onClose={() => setOpenLepcDialog(null)}
          mode={openLepcDialog.mode}
          lepcId={openLepcDialog?.agency?.id}
        />
      )}

      {confirmDeleteLepc && (
        <ConfirmDeleteLEPC
          lepcId={confirmDeleteLepc.id}
          onClose={() => setConfirmDeleteLepc(null)}
        />
      )}
    </Box>
  );
};
