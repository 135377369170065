import Cancel from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Drafts from "@mui/icons-material/Drafts";
import Edit from "@mui/icons-material/Edit";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import {
  AssignmentType,
  Permission,
  TierIiReportEncampStatus,
  TierIiReportOrgStatus,
} from "generated-graphql/graphql";
import { useCreateRevision } from "hooks/useCreateReport";
import { forwardRef, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReportRow } from ".";
import { MarkReportingModal } from "./MarkReportingModal";
import { NotReportingModal } from "./NotReportingModal";

import { useAlerts } from "components/Alerts/AlertProvider";
import { useCurrentUser } from "hooks/useCurrentUser";
import { CloseReportDialog } from "./CloseReportDialog";
import Delete from "@mui/icons-material/Delete";
import { useFacilityReportEditable } from "hooks/useFacilityReportEditable";
import React from "react";
import { useAuthorization } from "hooks/useAuthorization";
import { InitialReportDialog } from "./InitialReportDialog";

export default forwardRef(function ReportActionsMenu(
  props: {
    facilityTierIiReport: ReportRow;
    onEditAssignees: (
      facilityTierIiReport: ReportRow | undefined | null,
      assignmentType: AssignmentType
    ) => void;
    createNewReport: (row: ReportRow) => Promise<void>;
  },
  ref: React.Ref<HTMLButtonElement>
) {
  const { facilityTierIiReport, onEditAssignees, createNewReport } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [createRevision] = useCreateRevision();
  const [notReportingModalOpen, setNotReportingModalOpen] = useState(false);
  const [markReportingModalOpen, setMarkReportingModalOpen] = useState(false);
  const [closeReportDialogOpen, setCloseReportDialogOpen] = useState(false);
  const [initialReportDialogOpen, setInitialReportDialogOpen] = useState(false);
  const {
    canAddAssigneesOrReviewers,
    canCloseReport,
    canMarkAsNotReporting,
    canMarkAsReporting,
  } = useFacilityReportEditable();
  const { isStaff } = useCurrentUser();
  const { hasPermissionForFacility } = useAuthorization();
  const canVerifyAndSubmit = hasPermissionForFacility(
    facilityTierIiReport.facilityId,
    [Permission.VerifyTierIiReport]
  );

  const {
    isAnnualEnabled,
    isRevisionEnabled,
    isInitialEnabled,
    isAssigneesEnabled,
    isReportClosable,
    isMarkNotReportingEnabled,
    isMarkReportingEnabled,
  } = useMemo(() => {
    // Show only if there isn't already an annual report
    const isAnnualEnabled =
      canVerifyAndSubmit &&
      facilityTierIiReport.organizationStatusAnnual === null;

    // Show only IF there is a verified annual report AND there is no revision in an open status
    const isRevisionEnabled =
      facilityTierIiReport.organizationStatusAnnual ===
        TierIiReportOrgStatus.Verified &&
      facilityTierIiReport.encampStatusAnnual ===
        TierIiReportEncampStatus.FilingComplete &&
      !facilityTierIiReport.hasOpenRevision &&
      isStaff;

    // Show only IF there is no initial report in an open status and it's currentRY
    const isInitialEnabled =
      canVerifyAndSubmit &&
      !facilityTierIiReport.hasOpenInitial &&
      facilityTierIiReport.reportingYear === currentTierIIReportingYear;

    const isAssigneesEnabled = canAddAssigneesOrReviewers(facilityTierIiReport);
    const isMarkNotReportingEnabled =
      canMarkAsNotReporting(facilityTierIiReport);
    const isMarkReportingEnabled = canMarkAsReporting(facilityTierIiReport);
    const isReportClosable = canCloseReport(facilityTierIiReport);
    return {
      isAnnualEnabled,
      isRevisionEnabled,
      isInitialEnabled,
      isAssigneesEnabled,
      isMarkNotReportingEnabled,
      isMarkReportingEnabled,
      isReportClosable,
    };
  }, [
    canAddAssigneesOrReviewers,
    canMarkAsNotReporting,
    canMarkAsReporting,
    canVerifyAndSubmit,
    canCloseReport,
    facilityTierIiReport,
    isStaff,
  ]);

  const handleNotReportingModalOpen = () => {
    setNotReportingModalOpen(true);
  };

  const handleNotReportingModalClose = () => {
    setNotReportingModalOpen(false);
    handleClose();
  };

  const handleInitialReportDialogOpen = () => {
    setInitialReportDialogOpen(true);
  };

  const menuItems = [
    {
      items: [
        isAnnualEnabled && (
          <MenuItem
            key="annual"
            onClick={() => createNewReport(facilityTierIiReport)}
          >
            <ListItemIcon>
              <Drafts fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              Open Annual ({facilityTierIiReport.reportingYear})
            </ListItemText>
          </MenuItem>
        ),
        isInitialEnabled && (
          <MenuItem key="initial" onClick={handleInitialReportDialogOpen}>
            <ListItemIcon>
              <Drafts fontSize="small" />
            </ListItemIcon>
            <ListItemText>Open Initial Notification</ListItemText>
          </MenuItem>
        ),
        isRevisionEnabled && (
          <MenuItem
            key="revision"
            onClick={() =>
              createRevision({
                variables: {
                  facilityId: facilityTierIiReport.facilityId,
                  reportingYear: facilityTierIiReport.reportingYear,
                },
                onCompleted(data) {
                  navigate(data.createTierIIReport.id);
                },
                onError(err) {
                  alerts.error("Error creating revision report");
                  console.error("Error creating revision report", err);
                },
              })
            }
          >
            <ListItemIcon>
              <Drafts fontSize="small" />
            </ListItemIcon>
            <ListItemText>Open Revision</ListItemText>
          </MenuItem>
        ),
      ].filter(Boolean),
    },
    {
      items: [
        isMarkNotReportingEnabled && (
          <MenuItem
            key="markNotReporting"
            onClick={handleNotReportingModalOpen}
          >
            <ListItemIcon>
              <Cancel fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {facilityTierIiReport.organizationStatus ===
              TierIiReportOrgStatus.NotReporting
                ? "Edit Not Reporting Reason"
                : "Mark as Not Reporting"}
            </ListItemText>
          </MenuItem>
        ),
        isMarkReportingEnabled && (
          <MenuItem
            key="markReporting"
            onClick={() => setMarkReportingModalOpen(true)}
          >
            <ListItemIcon>
              <CheckCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as Reporting</ListItemText>
          </MenuItem>
        ),
        isReportClosable && (
          <MenuItem
            key="closeReport"
            onClick={() => setCloseReportDialogOpen(true)}
          >
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            <ListItemText>Close Report</ListItemText>
          </MenuItem>
        ),
      ].filter(Boolean),
    },
    {
      items: isAssigneesEnabled
        ? [
            <MenuItem
              key="editAssignees"
              onClick={() => {
                onEditAssignees(facilityTierIiReport, AssignmentType.Assignee);
                handleClose();
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit Assignees</ListItemText>
            </MenuItem>,
            <MenuItem
              key="editReviewers"
              onClick={() => {
                onEditAssignees(facilityTierIiReport, AssignmentType.Reviewer);
                handleClose();
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit Reviewers</ListItemText>
            </MenuItem>,
          ]
        : [],
    },
  ];
  const showMenu = menuItems.some((group) => group.items.length > 0);

  if (!showMenu) {
    return null;
  }

  const shouldShowDivider = (index: number) => {
    // Check if the current group has items and if any of the remaining groups have items
    return (
      menuItems[index].items.length > 0 &&
      menuItems.slice(index + 1).some((group) => group.items.length > 0)
    );
  };

  return (
    <>
      <Tooltip title="More actions">
        <GridActionsCellItem
          ref={ref}
          id="report-actions-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          label="More actions"
          icon={<MoreVert />}
        />
      </Tooltip>
      <Menu
        id="report-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "report-actions-button",
        }}
      >
        <MenuList dense>
          {menuItems.map((group, index) => (
            <React.Fragment key={index}>
              {group.items}
              {shouldShowDivider(index) && <Divider />}
            </React.Fragment>
          ))}
        </MenuList>
      </Menu>
      {notReportingModalOpen && (
        <NotReportingModal
          open={notReportingModalOpen}
          onClose={handleNotReportingModalClose}
          reports={[props.facilityTierIiReport]}
        />
      )}
      {markReportingModalOpen && (
        <MarkReportingModal
          onClose={() => {
            setMarkReportingModalOpen(false);
            handleClose();
          }}
          reports={[facilityTierIiReport]}
        />
      )}
      {closeReportDialogOpen && (
        <CloseReportDialog
          onClose={() => {
            setCloseReportDialogOpen(false);
            handleClose();
          }}
          report={facilityTierIiReport}
        />
      )}
      {initialReportDialogOpen && (
        <InitialReportDialog
          onClose={() => {
            setInitialReportDialogOpen(false);
            handleClose();
          }}
          facilityId={facilityTierIiReport.facilityId}
        />
      )}
    </>
  );
});
