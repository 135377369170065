import Error from "@mui/icons-material/Error";
import { Stack, Typography } from "@mui/material";
import { DataGrid } from "components/DataGrid";
import { ReportingFacilityChemicalQuery } from "generated-graphql/graphql";
import { prettyPrintQuantity } from "util/unit";
import { NoRowsOverlay } from "../Inventory/Facility/NoRowsOverlay";
import { IssueCount } from "components/IssueCount";

export const StorageLocationsTable = ({
  rfc,
}: {
  rfc: ReportingFacilityChemicalQuery["reportingFacilityChemical"];
}) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h6">Storage Locations</Typography>
      <DataGrid
        pagination
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay message="No storage locations found." />
          ),
        }}
        rows={rfc.storageLocations ?? []}
        columns={[
          {
            field: "location",
            headerName: "Location",
            flex: 1,
            valueGetter(params) {
              return params.row.storageLocation?.description;
            },
          },
          {
            field: "storageType",
            headerName: "Storage Type",
            flex: 1,
            valueGetter(params) {
              return params.row.storageType;
            },
          },
          {
            field: "maxAmount",
            headerName: "Max Amount",
            flex: 1,
            valueGetter(params) {
              return prettyPrintQuantity(
                params.row.maxAmount,
                params.row.unit,
                true
              );
            },
          },
          {
            field: "issues",
            headerName: "Issues",
            flex: 1,
            renderCell({ row }) {
              const issues = rfc.issues?.filter(
                (issue) =>
                  issue.modelName ===
                    "ReportingFacilityChemicalStorageLocation" &&
                  issue.modelId === row.id
              );
              return <IssueCount issueCount={issues.length} />;
            },
          },
        ]}
      />
      {rfc.hasConfidentialStorageLocations && (
        <Typography sx={{ display: "flex", gap: 1 }}>
          <Error color="warning" />
          This chemical has confidential storage locations
        </Typography>
      )}
    </Stack>
  );
};
