import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  EventMutationScope,
  Frequency,
  Permission,
  TasksQuery,
  VirtualEventStatus,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { prettyPrintDateMed } from "util/dates";
import { AddTaskDialog } from "./AddTaskDialog";
import { EditTaskDialog } from "./EditTaskDialog";
import { ConfirmDialog } from "components/ConfirmDialog";
import RecurrenceScopeRadioDialog from "./RecurrenceScopeRadioDialog";
import useDeleteTaskMutation from "./useDeleteTask";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useAuthorization } from "hooks/useAuthorization";
import { useCurrentUser } from "hooks/useCurrentUser";

export const TASKS_QUERY = gql(`
  query Tasks($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    virtualEvents(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      items {
        id
        seriesId
        taskId
        frequency
        interval
        title
        facilities {
          id
          name
        }
        status
        deadline
        assignee {
          ...AssigneePicker
        }
        tags {
          name
        }
      }
    }
  }
`);

export type Row = TasksQuery["virtualEvents"]["items"][0];

const CellText = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  alignItems: "start",
  height: "100%",
}));

const defaultDeleteTaskState = {
  open: false,
  recurring: false,
  virtualEvent: null,
};
export const Tasks = () => {
  const { tenantId, virtualEventId } = useParams();
  const theme = useTheme();
  const alerts = useAlerts();

  const [openAddModal, setOpenAddModal] = useState(false);

  const [deleteTaskState, setDeleteTaskState] = useState<{
    open: boolean;
    recurring: boolean;
    virtualEvent: Pick<Row, "taskId" | "deadline"> | null;
  }>(defaultDeleteTaskState);
  const resetDeleteTaskState = () => setDeleteTaskState(defaultDeleteTaskState);
  const { hasTaskWritePermissionForFacilities, hasPermissions } =
    useAuthorization();

  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();

  const canAddTask = useMemo(() => {
    return (
      hasPermissions([Permission.WriteAllFacility]) ||
      (user?.facilities.length ?? 0) > 0
    );
  }, [hasPermissions, user?.facilities.length]);

  const [deleteTask, { loading: deleteTaskLoading }] = useDeleteTaskMutation();

  const pushVirtualEventIdToUrl = (virtualEventId: string) => {
    navigate(`${virtualEventId}${location.search}`);
  };

  const removeVirtualEventIdFromUrl = () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    pathSegments.pop(); // Remove the last segment
    const newPath = `/${pathSegments.join("/")}${location.search}`;
    navigate(newPath);
  };

  const onRowEdit = (row: Row) => {
    if (
      !hasTaskWritePermissionForFacilities(
        row.facilities?.map((f) => f.id) ?? []
      )
    ) {
      return; // no op because the user doesn't have permissions
    }

    pushVirtualEventIdToUrl(row.id);
  };

  const onDeleteTask = useCallback(
    (scope: EventMutationScope) => {
      if (deleteTaskState.virtualEvent) {
        deleteTask({
          variables: {
            input: {
              taskId: deleteTaskState.virtualEvent.taskId,
              date: deleteTaskState.virtualEvent.deadline,
              eventMutationScope: scope,
            },
          },
          onCompleted: () => {
            resetDeleteTaskState();
            alerts.success(`Event deleted`);
          },
          onError: (error) => {
            alerts.error(`Error deleting event: ${error.message}`);
          },
          refetchQueries: [TASKS_QUERY],
        });
      }
    },
    [deleteTask, deleteTaskState.virtualEvent, alerts]
  );

  useBreadcrumb([{ label: "Tasks" }]);

  const columns: OmnisearchGridColDef<Row>[] = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      sortable: true,
      renderCell: ({ row: { title } }) => {
        return <CellText variant="body2">{title}</CellText>;
      },
    },
    {
      field: "facilities",
      headerName: "Facilities",
      flex: 1,
      sortable: true,
      renderCell({ row }) {
        return (
          <Stack
            spacing={0}
            style={{
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              height: "100%",
              width: "100%",
              alignItems: "start",
              overflow: "hidden",
            }}
          >
            {(row.facilities ?? []).map((facility) => (
              <Typography
                key={facility.id}
                variant="body2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                  whiteSpace: "nowrap",
                }}
              >
                <Tooltip title={facility.name}>
                  <span>{facility.name}</span>
                </Tooltip>
              </Typography>
            ))}
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      sortable: true,
      renderCell({ row: { status } }) {
        if (status === VirtualEventStatus.Active) {
          return "";
        } else if (status === VirtualEventStatus.Overdue) {
          return (
            <CellText variant="body2" color={theme.palette.error.main}>
              Overdue
            </CellText>
          );
        }

        return (
          <CellText variant="body2">{prettyPrintEnumValue(status)}</CellText>
        );
      },
      filterKeyType: "enum",
      enumValues: Object.values(VirtualEventStatus),
      enumPresentationFunction: (enumValue) => prettyPrintEnumValue(enumValue),
    },

    {
      field: "deadline",
      headerName: "Deadline",
      sortable: true,
      flex: 0.8,
      valueGetter({ row: { deadline } }) {
        return prettyPrintDateMed(deadline);
      },
      renderCell: ({ value }) => {
        return <CellText variant="body2">{value}</CellText>;
      },
    },

    {
      field: "assignee",
      headerName: "Assignee",
      sortable: true,
      flex: 1,
      valueGetter({ row: { assignee } }) {
        const fullName = assignee?.person
          ? `${assignee.person.first ?? ""} ${
              assignee.person.last ?? ""
            }`.trim() || null
          : null;

        return fullName ?? assignee?.email;
      },
      renderCell: ({ value }) => {
        return <CellText variant="body2">{value}</CellText>;
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 1,
      valueGetter({ row: { tags } }) {
        return tags?.map((tag) => tag.name).join(", ") || "";
      },
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      align: "right",
      getActions: ({ row }) => {
        const actions = [
          <Tooltip title="Edit Task" key={1}>
            <GridActionsCellItem
              onClick={() => onRowEdit(row)}
              label="Edit Task"
              icon={<Edit />}
            />
          </Tooltip>,
          <Tooltip title="Delete Task" key="delete">
            <GridActionsCellItem
              onClick={() =>
                setDeleteTaskState({
                  open: true,
                  recurring: row.frequency !== Frequency.Once,
                  virtualEvent: row,
                })
              }
              label="Delete Task"
              icon={<Delete />}
            />
          </Tooltip>,
        ];

        return hasTaskWritePermissionForFacilities(
          row.facilities?.map((f) => f.id) ?? []
        )
          ? actions
          : [];
      },
    },
  ];

  return (
    <>
      <Box sx={{ pt: 3 }}>
        <OmnisearchDataGrid
          columns={columns}
          initialSortModel={[{ field: "deadline", sort: "asc" }]}
          dataQuery={TASKS_QUERY}
          defaultSearch={`tenantId:${tenantId} status:ACTIVE|OVERDUE`}
          getItems={(data) => data.virtualEvents.items}
          getCount={(data) => data.virtualEvents.count ?? 0}
          noDataMessage="No tasks have been added to this organization yet."
          excludeFilterColumns={["deadline"]}
          additionalFilterColumns={[
            { key: "after", header: "Due After", filterKeyType: "text" },
            { key: "before", header: "Due Before", filterKeyType: "text" },
          ]}
          showFavorites={true}
          isRowSelectable={() => false}
          onRowClick={({ row }) => onRowEdit(row)}
          commandButtons={
            canAddTask
              ? [
                  <Button
                    key="add-task-button"
                    variant="contained"
                    onClick={() => setOpenAddModal(true)}
                  >
                    Add Task
                  </Button>,
                ]
              : []
          }
          initialPageSize={10}
          rowHeight={"auto"}
        />
      </Box>
      {openAddModal && (
        <AddTaskDialog
          open={openAddModal}
          onClose={() => setOpenAddModal(false)}
        />
      )}
      {virtualEventId && (
        <EditTaskDialog
          virtualEventId={virtualEventId}
          onClose={removeVirtualEventIdFromUrl}
        />
      )}
      {deleteTaskState.recurring && (
        <RecurrenceScopeRadioDialog
          action="Delete"
          open={deleteTaskState.open}
          onClose={resetDeleteTaskState}
          onSubmit={onDeleteTask}
          loading={deleteTaskLoading}
        />
      )}
      {!deleteTaskState.recurring && (
        // if it's not recurring, we can just delete it
        <ConfirmDialog
          open={deleteTaskState.open}
          onClose={resetDeleteTaskState}
          onConfirm={() => onDeleteTask(EventMutationScope.This)}
          loading={deleteTaskLoading}
          msg={`Are you sure you want to delete this task?`}
        />
      )}
    </>
  );
};
