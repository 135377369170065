import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid } from "components/DataGrid";

import { GetCredentialQuery } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import pluralize from "pluralize";
import { CREDENTIALS, DELETE_CREDENTIAL, GET_CREDENTIAL } from "./schema";

export function ConfirmDeleteCredential({
  credentialId,
  onClose,
}: {
  credentialId: string;
  onClose: () => void;
}) {
  const alerts = useAlerts();
  const [paginationModel, setPaginationModel] = usePaginationModel();

  const { data, previousData, loading } = useQuery(GET_CREDENTIAL, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: credentialId,
      facilitiesPage: paginationModel.page,
      facilitiesPageSize: paginationModel.pageSize,
    },
  });

  const [deleteCredential, { loading: isDeleting }] =
    useMutation(DELETE_CREDENTIAL);

  const credential = data?.credential ?? previousData?.credential;

  const modalContent = (
    <Stack gap={2}>
      {(credential?.facilityCount ?? 0) > 0 && (
        <>
          <Typography>
            Deleting the credential "{credential?.name}" will affect{" "}
            {credential?.facilityCount}{" "}
            {pluralize("facility", credential?.facilityCount ?? 0)}.
          </Typography>
          <DataGrid<
            NonNullable<GetCredentialQuery["credential"]["facilities"]>[number]
          >
            loading={loading}
            rowCount={credential?.facilityCount ?? 0}
            rows={credential?.facilities ?? []}
            columns={[
              { field: "name", headerName: "Name", flex: 1, sortable: false },
              {
                field: "location",
                headerName: "Location",
                flex: 1,
                sortable: false,
                valueGetter({ row }) {
                  return [row.city, row.state].join(", ");
                },
              },
            ]}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </>
      )}
      <Typography>Are you sure you want to delete this credential?</Typography>
    </Stack>
  );

  return credential ? (
    <ConfirmDialog
      open={true}
      msg={modalContent}
      title="Confirm Delete"
      confirmText="Delete"
      loading={isDeleting}
      onClose={onClose}
      onConfirm={() =>
        deleteCredential({
          variables: { id: credentialId },
          onCompleted() {
            onClose();
            alerts.success("Credential deleted successfully!");
          },
          onError(e) {
            alerts.error("There was an error deleting the credential:", e);
          },
          refetchQueries: [CREDENTIALS],
        })
      }
    />
  ) : null;
}
