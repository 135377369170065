import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { LoadingButton } from "@mui/lab";
import {
  ActivityStatus,
  ActivityType,
  ExecutionPlanType,
  JobStatus,
} from "generated-graphql/graphql";
import { useState } from "react";
import { ActivityButtonProps } from ".";
import { ActivityErrorDialog } from "./ActivityErrorDialog";

export function SendToErrorTriage({ activity }: ActivityButtonProps) {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const canSendGeppettoError =
    activity.type === ActivityType.Geppetto &&
    (activity.job?.status === JobStatus.Cancelled ||
      activity.executionPlan === ExecutionPlanType.Human);

  const canSendNonGeppettoError =
    activity.type !== ActivityType.Geppetto &&
    activity.status === ActivityStatus.InProgress;

  if (canSendNonGeppettoError || canSendGeppettoError) {
    return (
      <>
        <LoadingButton
          size="small"
          variant="text"
          color="error"
          startIcon={<ErrorOutline />}
          onClick={() => setIsErrorDialogOpen(true)}
          sx={{ mr: 1 }}
        >
          Send to Error
        </LoadingButton>
        <ActivityErrorDialog
          isOpen={isErrorDialogOpen}
          closeDialog={() => setIsErrorDialogOpen(false)}
          activityId={activity?.id}
        />
      </>
    );
  } else {
    return null;
  }
}
