import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { useLocalStorageState } from "hooks/useLocalStorageState";

type ScreenWidth = "full" | "fixed";
type ScreenWidthContext = {
  screenWidth: ScreenWidth;
  setScreenWidth: Dispatch<SetStateAction<ScreenWidth>>;
  toggleScreenWidth: () => void;
};

export const screenWidthContext = createContext<ScreenWidthContext>({
  screenWidth: "fixed",
  setScreenWidth: () => {},
  toggleScreenWidth: () => {},
});

export function ScreenWidthProvider(props: PropsWithChildren<unknown>) {
  const [screenWidth, setScreenWidth] = useLocalStorageState<ScreenWidth>(
    "screenWidth",
    "fixed"
  );

  const toggleScreenWidth = useCallback(() => {
    setScreenWidth(screenWidth === "fixed" ? "full" : "fixed");
  }, [screenWidth, setScreenWidth]);

  const value: ScreenWidthContext = useMemo(
    () => ({
      screenWidth,
      setScreenWidth,
      toggleScreenWidth,
    }),
    [screenWidth, setScreenWidth, toggleScreenWidth]
  );
  return (
    <screenWidthContext.Provider value={value}>
      {props.children}
    </screenWidthContext.Provider>
  );
}
