import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { ActivityStatus } from "generated-graphql/graphql";
import { useUpdateActivity } from "hooks/activities";
import { useCallback, useState } from "react";

interface Props {
  activityId: string;
  closeDialog: () => void;
  isOpen: boolean;
}

export const ActivityErrorDialog = ({
  isOpen,
  closeDialog,
  activityId,
}: Props) => {
  const [errorTriageMessage, setErrorTriageMessage] = useState("");
  const theme = useTheme();
  const [updateActivity, { loading: isActivityUpdating }] = useUpdateActivity();
  const handleErrorButtonClick = useCallback(async () => {
    await updateActivity({
      variables: {
        id: activityId,
        input: {
          status: ActivityStatus.Error, // Setting the status directly to "Error"
          message: errorTriageMessage,
        },
      },
    });
    closeDialog();
  }, [updateActivity, activityId, errorTriageMessage]);
  const handleChangeTriageMessage = (event: SelectChangeEvent<string>) => {
    setErrorTriageMessage(event.target.value);
  };
  return (
    <Dialog open={isOpen} sx={{ padding: theme.spacing(2) }}>
      <DialogTitle>Select an Error Reason</DialogTitle>
      <DialogContent sx={{ minWidth: "300px" }}>
        <Select
          autoWidth
          displayEmpty
          size="small"
          style={{ marginRight: "1rem", minWidth: "200px" }}
          labelId="dropdown-label"
          id="dropdown"
          value={errorTriageMessage}
          onChange={handleChangeTriageMessage}
        >
          <MenuItem disabled value="">
            <em>Select an error type</em>
          </MenuItem>
          <MenuItem value="badMissingData">Bad/Missing Data</MenuItem>
          <MenuItem value="incorrectCredentials">
            Incorrect/No Credentials
          </MenuItem>
          <MenuItem value="needsReran">Needs Reran</MenuItem>
          <MenuItem value="other">Other (see comments)</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleErrorButtonClick}
          loading={isActivityUpdating}
          variant="contained"
          color="primary"
        >
          Ok
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
