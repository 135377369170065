import AccessTime from "@mui/icons-material/AccessTime";
import Done from "@mui/icons-material/Done";
import LanguageIcon from "@mui/icons-material/Language";
import PlayArrow from "@mui/icons-material/PlayArrow";
import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useCreateWebAutomationJobs } from "hooks/useCreateWebAutomationJobs";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "../../../../../components/Alerts/AlertProvider";
import {
  CreateWebAutomationJobInput,
  ImplementationFacilitiesQuery,
  JobTask,
  JobStatus,
  WebAutomationAction,
} from "../../../../../generated-graphql/graphql";
import { useBulkActionContext } from "../Facilities/FacilitiesBulkActionMenu";

type Row = ImplementationFacilitiesQuery["facilities"]["items"][0];

export function QueueExtractionsModal() {
  const {
    selectedRows,
    dialogState,
    onClose: handleCloseQueueExtractionsModal,
  } = useBulkActionContext();

  const { tenantId } = useParams();
  const alerts = useAlerts();

  const showQueueExtractionsModal = dialogState?.action === "queueExtractions";
  const selectedReportingYear =
    dialogState?.action === "queueExtractions"
      ? dialogState.year
      : new Date().getFullYear();

  const queueableExtractionCount = useMemo(() => {
    return selectedRows.filter((item) =>
      canQueueExtraction(selectedReportingYear, item)
    ).length;
  }, [selectedRows, selectedReportingYear]);

  const [createWebAutomationJobs, { error: mutationError }] =
    useCreateWebAutomationJobs({
      refetchQueries: [
        "ImplementationFacilities",
        "ExtractionsWebAutomationJobs",
      ],
      variables: {
        input: selectedRows
          .filter((item) => canQueueExtraction(selectedReportingYear, item))
          .map((selectedRow): CreateWebAutomationJobInput => {
            const stateOrTerritory = selectedRow?.state;

            return {
              tenantId: tenantId ?? "",
              facilityId: selectedRow.id,
              status: JobStatus.Queued,
              action: WebAutomationAction.ExtractData,
              reportingYear: Number.isNaN(selectedReportingYear)
                ? undefined
                : Number(selectedReportingYear),
              evidenceOfCompletionActions: [],
              stepError: "",
              credentialUsername:
                selectedRow.portalCredential?.credential?.username ?? "",
              stateOrTerritory: stateOrTerritory ?? "",
              forceClassicSubmissionStrategy: false,
              task: JobTask.WebAutomation,
              parameters: {}, // todo: Jason, what is this supposed to be?
            };
          }),
      },
    });

  const handleConfirmCreateWebAutomationJobs = useCallback(async () => {
    await createWebAutomationJobs();

    if (mutationError) {
      alerts.error("There was an error initiating extractions");
    } else {
      alerts.success(`Successfully queued extractions`);
    }

    handleCloseQueueExtractionsModal();
  }, [
    createWebAutomationJobs,
    alerts,
    mutationError,
    handleCloseQueueExtractionsModal,
  ]);

  return (
    <Modal
      open={showQueueExtractionsModal}
      onClose={handleCloseQueueExtractionsModal}
    >
      <Box
        sx={{
          padding: 6,
          bgcolor: "background.paper",
          margin: "auto",
          maxWidth: "60%",
          maxHeight: "80%",
          overflow: "auto",
          mt: "10%",
          outline: "none",
        }}
      >
        <Typography variant="h5">Confirm Queue Extractions</Typography>

        <TableContainer
          component={Paper}
          sx={{
            marginTop: "2rem",
          }}
        >
          <Box
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Organization</TableCell>
                  <TableCell>Facility</TableCell>
                  <TableCell>Customer Facility ID</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Reporting Year</TableCell>
                  <TableCell>Extraction Status</TableCell>
                  <TableCell>Will Queue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>{row.tenant?.name}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.customerFacilityId}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{selectedReportingYear}</TableCell>
                    <TableCell>
                      <ExtractionStatus
                        selectedReportingYear={selectedReportingYear}
                        facilityRow={row}
                      />
                    </TableCell>
                    <TableCell>
                      <ExtractionWillStart
                        selectedReportingYear={selectedReportingYear}
                        facilityRow={row}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>

        <Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "flex-end",
              marginTop: "2rem",
            }}
          >
            <Button
              color="success"
              variant="contained"
              onClick={handleConfirmCreateWebAutomationJobs}
              endIcon={<PlayArrow />}
              disabled={queueableExtractionCount === 0}
            >
              Queue
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

function ExtractionStatus(props: {
  selectedReportingYear: number;
  facilityRow?: Row;
}) {
  const match = props.facilityRow?.jobs?.find(
    (item) => item.reportingYear === props.selectedReportingYear
  );

  if (match) {
    if (match.status === JobStatus.Succeeded) {
      return (
        <Stack direction={"row"}>
          <Typography
            sx={{
              marginRight: "0.5rem",
            }}
          >
            SUCCEEDED
          </Typography>
          <Done color="success" />
        </Stack>
      );
    }

    if (match.status === JobStatus.Running) {
      return (
        <Stack direction={"row"}>
          <Typography
            sx={{
              marginRight: "0.5rem",
            }}
          >
            RUNNING
          </Typography>
          <LanguageIcon />
        </Stack>
      );
    }

    if (match.status === JobStatus.Queued) {
      return (
        <Stack direction={"row"}>
          <Typography
            sx={{
              marginRight: "0.5rem",
            }}
          >
            QUEUED
          </Typography>
          <AccessTime />
        </Stack>
      );
    }

    return (
      <Stack direction={"row"}>
        <Typography
          sx={{
            marginRight: "0.5rem",
          }}
        >
          {match.status}
        </Typography>
      </Stack>
    );
  }

  return <></>;
}

function ExtractionWillStart(props: {
  selectedReportingYear: number;
  facilityRow?: Row;
}) {
  if (canQueueExtraction(props.selectedReportingYear, props.facilityRow)) {
    return (
      <Typography
        sx={{
          marginRight: "0.5rem",
        }}
      >
        YES
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        marginRight: "0.5rem",
      }}
    >
      NO
    </Typography>
  );
}

function canQueueExtraction(
  selectedReportingYear: number,
  facilityRow?: Row
): boolean {
  const match = facilityRow?.jobs?.find(
    (item) => item.reportingYear === selectedReportingYear
  );
  if (!match) {
    return true;
  }

  if (
    match.status === JobStatus.Succeeded ||
    match.status === JobStatus.Cancelled ||
    match.status?.includes("FAILED")
  ) {
    return true;
  }

  return false;
}
