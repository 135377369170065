import { Box, Button, Grid, Typography } from "@mui/material";
import Facility from "../../assets/UpsellIcons/Facility.png";
import Binoculars from "../../assets/UpsellIcons/Binoculars.png";
import Lamp from "../../assets/UpsellIcons/Lamp.png";
import Compass from "../../assets/UpsellIcons/Compass.png";
import DirectionalSign from "../../assets/UpsellIcons/DirectionalSign.png";
import Circle from "../../assets/UpsellIcons/Circle.png";
import Leaf from "../../assets/UpsellIcons/Leaf.png";
import TierII from "../../assets/UpsellIcons/TierII.png";
import Tech from "../../assets/UpsellIcons/Tech.png";
import { Link } from "react-router-dom";

const data = [
  {
    icon: Binoculars,
    title: "Regulator Eye View",
    description: "See your company compliance program like a regulator",
  },
  {
    icon: Compass,
    title: "Industry Benchmarking",
    description: "Compare your compliance posture to your industry",
  },
  {
    icon: Circle,
    title: "Competitor Comparisons",
    description: "Compare your compliance posture to specific competitors",
  },
  {
    icon: Lamp,
    title: "Compliance History",
    description: "Your compliance performance per program over time",
  },
  {
    icon: Facility,
    title: "Facility Insights",
    description: "Regulator eye view profile of each of your facilities",
  },
  {
    icon: DirectionalSign,
    title: "Auditing",
    description:
      "Go deep on your own facilities, vendors, and M&A opportunities",
  },
  {
    icon: Leaf,
    title: "ECHO Search",
    description:
      "Search the entire EPA ECHO database directly within Encamp to understand your overall compliance",
  },
  {
    icon: TierII,
    title: "RCRAInfo Search",
    description:
      "Search the entire EPA RCRAInfo database directly within Encamp to understand your waste compliance",
  },
  {
    icon: Tech,
    title: "NPDES Search",
    description:
      "Search the entire EPA NPDES database directly within Encamp to understand your water compliance",
  },
];

export function InsightsUpsell() {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        fontFamily={"Georgia"}
        fontSize={"2em"}
      >
        Compliance Insights
      </Typography>
      <Grid container padding={4} paddingBottom={0}>
        {data.map((item, index) => (
          <Grid xs={12} sm={6} md={4} key={index}>
            <Grid container marginBottom={4} padding={1}>
              <Grid
                container
                xs={3}
                minWidth={55}
                maxHeight={55}
                justifyContent={"center"}
              >
                <Box sx={{ marginBottom: 2 }}>
                  <img src={item.icon} width={40} height={"auto"} />
                </Box>
              </Grid>
              <Grid xs={9}>
                <Typography
                  align="left"
                  variant="h6"
                  gutterBottom
                  color={"#26428b"}
                  fontFamily={"sans-serif"}
                  fontSize={"1em"}
                  fontWeight={"550"}
                >
                  {item.title}
                </Typography>
                <Typography align="left" variant="body1" fontSize={"1em"}>
                  {item.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        paddingLeft={4}
        paddingRight={4}
        paddingBottom={2}
        justifyContent={"center"}
      >
        <Link to="https://encamp.com/demo/" target="_blank">
          <Button variant="contained">
            See your compliance program like a regulator
          </Button>
        </Link>
      </Grid>
    </Box>
  );
}

export default InsightsUpsell;
