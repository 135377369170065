import Close from "@mui/icons-material/Close";
import CopyAllOutlined from "@mui/icons-material/CopyAllOutlined";
import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type AlertContext = {
  success: (msg: string) => void;
  error: (msg: string, error?: any) => void;
  info: (msg: string) => void;
};

export const ctx = createContext<AlertContext>({
  success: () => {},
  error: () => {},
  info: () => {},
});

export function AlertProvider(props: PropsWithChildren<Record<string, any>>) {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState<string | undefined>();
  const [err, setError] = useState<Error | undefined>();
  const [severity, setSeverity] = useState<AlertColor | undefined>();

  const success = useCallback((msg: string) => {
    setSeverity("success");
    setMsg(msg);
    setError(undefined);
    setOpen(true);
  }, []);

  const error = useCallback((msg: string, error?: any) => {
    setSeverity("error");
    setMsg(msg);
    setError(error);
    setOpen(true);
  }, []);

  const info = useCallback((msg: string) => {
    setSeverity("info");
    setMsg(msg);
    setError(undefined);
    setOpen(true);
  }, []);

  const state = useMemo(
    () => ({
      success,
      error,
      info,
    }),
    [error, info, success]
  );

  return (
    <ctx.Provider value={state}>
      {props.children}
      <Snackbar
        open={open}
        autoHideDuration={10000}
        onClose={(e, reason) => {
          if (reason === "clickaway") return;
          setOpen(false);
        }}
      >
        <Alert
          severity={severity}
          onClose={() => setOpen(false)}
          action={
            <>
              {err && (
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(err.stack ?? "");
                  }}
                >
                  <CopyAllOutlined fontSize="inherit" />
                </IconButton>
              )}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </>
          }
          sx={{
            whiteSpace: "pre-line",
          }}
        >
          {msg} {err && err.message}
        </Alert>
      </Snackbar>
    </ctx.Provider>
  );
}

export function useAlerts() {
  return useContext(ctx);
}
