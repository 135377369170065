export const EncampAddress = {
  streetAddress1: "8520 Allison Pointe Blvd Ste 223",
  streetAddress2: "PMB 45239",
  city: "Indianapolis",
  state: "IN",
  zip: "46250-4299",
  country: "USA",
};

export const EncampMailingAddress = {
  mailingStreetAddress1: EncampAddress.streetAddress1,
  mailingStreetAddress2: EncampAddress.streetAddress2,
  mailingCity: EncampAddress.city,
  mailingState: EncampAddress.state,
  mailingZip: EncampAddress.zip,
  mailingCountry: EncampAddress.country,
};
