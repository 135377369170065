import {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";

export type Crumb = {
  label: string;
  to?: string;
  addendum?: string;
};

type BreadcrumbContextData = {
  breadcrumbs: Crumb[];
  addBreadcrumb: (breadcrumb: Crumb) => void;
  removeBreadcrumb: (breadcrumb: Crumb) => void;
};

export const BreadcrumbContext = createContext<BreadcrumbContextData>({
  addBreadcrumb: () => {},
  removeBreadcrumb: () => {},
  breadcrumbs: [],
});

export function BreadcrumbProvider({ children }: PropsWithChildren<unknown>) {
  const [breadcrumbs, setBreadcrumbs] = useState<Crumb[]>([]);

  function setTitle(crumbs: Crumb[]) {
    const labels = ["Encamp", ...crumbs.map((b) => b.label)];
    document.title = labels.join(" / ");
  }

  const addBreadcrumb = useCallback((breadcrumb: Crumb) => {
    setBreadcrumbs((prevBreadcrumbs) => {
      const newBreadcrumbs = [...prevBreadcrumbs, breadcrumb];

      // useEffect will execute on child components before parent components, meaning
      // if all data is cached we will see child crumbs added before parent crumbs
      // and we'll end up with incorrectly ordered breadcrumbs. As a workaround
      // we sort the breadcrumbs every time a new one is added
      newBreadcrumbs.sort((a, b) => {
        if (a.to === undefined) return 1; // Sort undefined to the end
        if (b.to === undefined) return -1; // Sort undefined to the end
        return a.to.localeCompare(b.to); // Sort by path hierarchy
      });

      setTitle(newBreadcrumbs);
      return newBreadcrumbs;
    });
  }, []);

  const removeBreadcrumb = useCallback((breadcrumb: Crumb) => {
    setBreadcrumbs((prevBreadcrumbs) => {
      const newBreadcrumbs = prevBreadcrumbs.filter(
        (bc) => bc.label !== breadcrumb.label
      );
      setTitle(newBreadcrumbs);
      return newBreadcrumbs;
    });
  }, []);

  const value = useMemo(
    () => ({
      breadcrumbs,
      addBreadcrumb,
      removeBreadcrumb,
    }),
    [addBreadcrumb, breadcrumbs, removeBreadcrumb]
  );

  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  );
}
