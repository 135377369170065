import { gql } from "generated-graphql";

import {
  Card,
  CardContent,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import {
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
  useGridSelector,
  gridRowsLookupSelector,
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
} from "@mui/x-data-grid-premium";
import { compact } from "lodash";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { GenerateCandidatesForm } from "./GenerateCandidatesForm";
import { FacilitiesWithInsightsCandidatesQuery } from "generated-graphql/graphql";
import { Dialog } from "components/Dialog";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Search from "@mui/icons-material/Search";
import Lightbulb from "@mui/icons-material/LightbulbOutlined";
import { DataGrid } from "components/DataGrid";
import Close from "@mui/icons-material/Close";
import { useAlerts } from "components/Alerts/AlertProvider";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import React from "react";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";

const FACILITIES_WITH_CANDIDATES = gql(`
    query FacilitiesWithInsightsCandidates($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
        facilitiesWithInsightsCandidates(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
            count
            items {
              id
              name
              state
              latitude
              longitude
              streetAddress1
              city
              zip
              naicsCode
              tenant {
                  id
                  name
              }
              facilityAlternateIds {
                type
                value
              }
              echoFacilityMatchCandidates {
                matchScore
                id
                echoExporter {
                    registryId
                    facName
                    facCity
                    facStreet
                    facNaicsCodes
                    facActiveFlag
                    facState
                    facZip
                    facLat
                    facLong
                    dfrUrl
                    npdesIds
                    rcraIds
                }
              }
            }
        }
    }
`);

const LINK_MATCHES_MUTATION = gql(`
    mutation LinkMatches($input: LinkFacilitiesInput!) {
        linkFacilities(input: $input)
    }
`);

const GENERATE_CANDIDATES_FROM_ALTERNATE_IDS_MUTATION = gql(`
    mutation GenerateCandidatesFromAlternateIds($tenantId: ID!) {
        generateInsightsCandidatesFromAlternateIds(tenantId: $tenantId) {
            count
            items {
                id
            }
        }
    }
`);

const DELETE_CANDIDATES_MUTATION = gql(`
    mutation DeleteCandidates($input: DeleteCandidatesInput!) {
        deleteCandidates(input: $input)
    }
`);

type Row =
  FacilitiesWithInsightsCandidatesQuery["facilitiesWithInsightsCandidates"]["items"][number];
type CandidateRows = NonNullable<Row["echoFacilityMatchCandidates"]>[number];

export const InsightsCandidates = ({ tenantId }: { tenantId: string }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [selectedIdMap, setSelectedIds] = useState<{
    [facilityId: string]: string[];
  }>({});
  const updateFacilitySelectedIds = useCallback(
    (facilityId: string, ids: string[]) => {
      setSelectedIds((prev) => {
        return {
          ...prev,
          [facilityId]: ids,
        };
      });
    },
    []
  );
  const removeIdsAcrossMap = useCallback((idsToRemove: string[]) => {
    setSelectedIds((prev) =>
      Object.fromEntries(
        Object.entries(prev).map(([facilityId, ids]) => [
          facilityId,
          ids.filter((id) => !idsToRemove.includes(id)),
        ])
      )
    );
  }, []);
  const alerts = useAlerts();
  const [generateFromAltIdsOpen, setGenerateFromAltIdsOpen] = useState(false);

  const [linkMutation, { loading: linkMutationLoading }] = useMutation(
    LINK_MATCHES_MUTATION,
    {
      refetchQueries: [FACILITIES_WITH_CANDIDATES],
    }
  );
  const [
    generateFromAltIds,
    { loading: generateFromAltIdsLoading, data: generateFromAltIdsData, reset },
  ] = useMutation(GENERATE_CANDIDATES_FROM_ALTERNATE_IDS_MUTATION, {
    refetchQueries: [FACILITIES_WITH_CANDIDATES],
  });
  const onSave = useCallback(
    (candidateIds: string[]) => {
      linkMutation({
        variables: {
          input: { candidateIds },
        },
        onCompleted: () => {
          removeIdsAcrossMap(candidateIds);
        },
      });
    },
    [linkMutation, removeIdsAcrossMap]
  );
  const onGenerateFromAltIds = useCallback(() => {
    generateFromAltIds({
      variables: { tenantId },
      onCompleted: (data) => {
        alerts.success(
          `${data.generateInsightsCandidatesFromAlternateIds.count} candidates generated`
        );
      },
    });
  }, [generateFromAltIds, tenantId, alerts]);

  const [deleteMutation, { loading: deleteMutationLoading }] = useMutation(
    DELETE_CANDIDATES_MUTATION
  );

  const onDelete = useCallback(
    (ids: string[]) => {
      deleteMutation({
        variables: {
          input: {
            candidateIds: ids,
          },
        },
        onCompleted: () => {
          removeIdsAcrossMap(ids);
        },
        update: (cache) => {
          ids.forEach((id) => {
            const cacheId = cache.identify({
              __typename: "InsightsCandidate",
              id,
            });
            cache.evict({ id: cacheId });
          });
          cache.gc();
        },
      });
    },
    [deleteMutation, removeIdsAcrossMap]
  );

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderHeader: () => <ExpandCollapseAllHeader />,
      },
      {
        field: "name",
        headerName: "Facility",
        flex: 1,
        filterKeyType: "facility",
      },
      {
        field: "address",
        headerName: "Address",
        flex: 2,
        valueGetter: (params) => {
          const { streetAddress1, city, state, zip } = params.row;
          return compact([streetAddress1, city, state, zip]).join(", ");
        },
      },
      {
        field: "naicsCode",
        headerName: "NAICS",
        flex: 0.5,
        valueGetter: (params) => params.row.naicsCode,
      },
      {
        field: "latitude",
        headerName: "Latitude",
        flex: 0.5,
        valueGetter: (params) => params.row.latitude,
      },
      {
        field: "longitude",
        headerName: "Longitude",
        flex: 0.5,
        valueGetter: (params) => params.row.longitude,
      },
      {
        field: "echoLinked",
        headerName: "Echo",
        flex: 0.5,
        sortable: false,
        valueGetter: (params) =>
          findAlternateId(params?.row?.facilityAlternateIds ?? [], ["FRS"])
            ?.value,
        renderCell: (params) => {
          return params?.value ?? <Close />;
        },
      },
      {
        field: "rcraLinked",
        headerName: "RCRA",
        flex: 0.5,
        sortable: false,
        valueGetter: (params) =>
          findAlternateId(params?.row?.facilityAlternateIds ?? [], ["EPA"])
            ?.value,
        renderCell: (params) => {
          return params?.value ?? <Close />;
        },
      },
      {
        field: "npdesLinked",
        headerName: "NPDES",
        flex: 0.5,
        sortable: false,
        valueGetter: (params) =>
          findAlternateId(params?.row?.facilityAlternateIds ?? [], ["NPDES"])
            ?.value,
        renderCell: (params) => {
          return params?.value ?? <Close />;
        },
      },
      {
        field: "numCandidates",
        headerName: "Candidates",
        flex: 0.5,
        valueGetter: (params) =>
          params?.row?.echoFacilityMatchCandidates?.length ?? 0,
      },
      {
        field: "actions",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <Tooltip title="Open facility profile in new tab" key="open">
            <GridActionsCellItem
              key="open"
              label="Open"
              icon={<OpenInNew fontSize="small" />}
              onClick={() =>
                window.open(
                  `/o/${params.row.tenant?.id}/facilities/${params.row.id}/profile`,
                  "_blank"
                )
              }
            />
          </Tooltip>,
        ],
      },
    ],
    []
  );

  const commandButtons = useMemo(() => {
    return [
      <Stack direction="row" spacing={2} key="commandButtons">
        <Button
          variant="outlined"
          onClick={() => setFormOpen(true)}
          color="primary"
        >
          <Search fontSize="small" sx={{ mr: 1 }} />
          Find More
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onGenerateFromAltIds();
            setGenerateFromAltIdsOpen(true);
          }}
          color="primary"
        >
          <Lightbulb fontSize="small" sx={{ mr: 1 }} />
          Generate From Alternate IDs
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          variant="outlined"
          disabled={
            deleteMutationLoading ||
            linkMutationLoading ||
            Object.values(selectedIdMap).flat().length === 0
          }
          onClick={() => {
            onSave(Object.values(selectedIdMap).flat());
          }}
        >
          <Check fontSize="small" sx={{ mr: 1 }} />
          Save
        </Button>
        <Button
          variant="outlined"
          disabled={
            deleteMutationLoading ||
            linkMutationLoading ||
            Object.values(selectedIdMap).flat().length === 0
          }
          onClick={() => onDelete(Object.values(selectedIdMap).flat())}
        >
          <Delete fontSize="small" sx={{ mr: 1 }} />
          Delete
        </Button>
      </Stack>,
    ];
  }, [
    selectedIdMap,
    deleteMutationLoading,
    onGenerateFromAltIds,
    onSave,
    onDelete,
    linkMutationLoading,
  ]);
  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps["getDetailPanelContent"]>
  >(
    ({ row }) => {
      if (row.echoFacilityMatchCandidates?.length === 0) {
        return null;
      }
      return (
        <DetailPanelContent
          row={row}
          onSave={onSave}
          onDelete={onDelete}
          onSelectionChanged={(ids) => updateFacilitySelectedIds(row.id, ids)}
          selectedIds={selectedIdMap[row.id] ?? []}
        />
      );
    },
    [selectedIdMap, onSave, onDelete, updateFacilitySelectedIds]
  );
  return (
    <>
      <OmnisearchDataGrid
        dataQuery={FACILITIES_WITH_CANDIDATES}
        defaultSearch={`tenantId:${tenantId}`}
        initialFilters={[
          { field: "hasEcho", value: false },
          { field: "onlyEchoActive", value: true },
        ]}
        skip={!tenantId}
        columns={columns}
        otherLoading={deleteMutationLoading || linkMutationLoading}
        initialSortModel={[{ field: "numCandidates", sort: "desc" }]}
        withPadding={false}
        initialPageSize={10}
        getItems={(data) => data.facilitiesWithInsightsCandidates.items}
        getCount={(data) => data.facilitiesWithInsightsCandidates.count}
        commandButtons={commandButtons}
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={getDetailPanelContent}
        excludeFilterColumns={[
          GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
          "naicsCode",
          "latitude",
          "longitude",
          "echoLinked",
          "rcraLinked",
          "npdesLinked",
          "candidates",
        ]}
        additionalFilterColumns={[
          {
            key: "state",
            header: "State Abbreviation",
          },
          {
            key: "hasEcho",
            header: "Has Echo",
            filterKeyType: "boolean",
          },
          {
            key: "hasRCRA",
            header: "Has RCRA",
            filterKeyType: "boolean",
          },
          {
            key: "hasNPDES",
            header: "Has NPDES",
            filterKeyType: "boolean",
          },
          {
            key: "onlyEchoActive",
            header: "Only Include Active ECHO Sites",
            filterKeyType: "boolean",
          },
        ]}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={formOpen}
        onClose={() => setFormOpen(false)}
      >
        <DialogTitle>Generate Candidates</DialogTitle>
        <DialogContent>
          <GenerateCandidatesForm
            tenantId={tenantId}
            onClose={() => setFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={generateFromAltIdsOpen}
        onClose={() => {
          setGenerateFromAltIdsOpen(false);
          reset();
        }}
      >
        <DialogTitle>Generate Candidates</DialogTitle>
        <DialogContent>
          <Stack gap={2} direction="column">
            {generateFromAltIdsLoading && (
              <Stack gap={2} direction="row">
                <CircularProgress size={24} />
                <Typography variant="body1">
                  Using the EPA, NPDES, and FRS IDs to match facilities in Echo.
                </Typography>
              </Stack>
            )}
            {generateFromAltIdsData && (
              <Typography variant="body1">
                {generateFromAltIdsData
                  ?.generateInsightsCandidatesFromAlternateIds.count ?? 0}{" "}
                candidates generated, either press "Link Candidates" to link
                them, or close this dialog to review them.
              </Typography>
            )}
            <FloatingSaveBar
              saving={linkMutationLoading}
              sx={{ marginTop: 0, paddingY: 0 }}
              onCancel={() => {
                setGenerateFromAltIdsOpen(false);
                reset();
              }}
              saveText="Link Candidates"
              saveStartIcon={null}
              saveDisabled={
                generateFromAltIdsLoading ||
                generateFromAltIdsData
                  ?.generateInsightsCandidatesFromAlternateIds.items.length ===
                  0
              }
              onSaveClick={() => {
                onSave(
                  generateFromAltIdsData?.generateInsightsCandidatesFromAlternateIds.items.map(
                    (i) => i.id
                  ) ?? []
                );
                setGenerateFromAltIdsOpen(false);
                reset();
              }}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

const DetailPanelContent = React.memo(function DetailPanelContent({
  row,
  onSave,
  onDelete,
  selectedIds,
  onSelectionChanged,
}: {
  row: Row;
  onSave: (ids: string[]) => void;
  onDelete: (ids: string[]) => void;
  selectedIds: string[];
  onSelectionChanged: (ids: string[]) => void;
}) {
  const columns: GridColDef<CandidateRows>[] = useMemo(
    () => [
      {
        field: "matchScore",
        headerName: "Score",
        flex: 0.4,
        valueGetter: (params) => params?.row?.matchScore ?? "",
        renderCell: (params) => {
          const matchScore = params?.value;
          return matchScore !== undefined ? matchScore.toFixed(2) : "";
        },
      },
      {
        field: "echoName",
        headerName: "Name",
        flex: 1,
        valueGetter: (params) => params?.row?.echoExporter?.facName ?? "",
      },
      {
        field: "echoAddress",
        headerName: "Echo Address",
        flex: 1.5,
        valueGetter: (params) => {
          const streetAddress = params?.row?.echoExporter?.facStreet ?? "";
          const state = params?.row?.echoExporter?.facState ?? "";
          const city = params?.row?.echoExporter?.facCity ?? "";
          const zip = params?.row?.echoExporter?.facZip ?? "";
          return compact([streetAddress, city, state, zip]).join(", ");
        },
      },
      {
        field: "naicsCode",
        headerName: "NAICS",
        flex: 0.5,
        valueGetter: (params) =>
          params?.row?.echoExporter?.facNaicsCodes?.join(", "),
      },
      {
        field: "active",
        headerName: "Active",
        flex: 0.5,
        valueGetter: (params) =>
          params?.row?.echoExporter?.facActiveFlag ? "Yes" : "No",
      },
      {
        field: "dfrUrl",
        headerName: "Detailed Report",
        flex: 0.5,
        valueGetter: (params) => params?.row?.echoExporter?.dfrUrl ?? "",
        renderCell: (params) => {
          const dfrUrl = params?.value;
          return dfrUrl ? (
            <Tooltip title="Opens EPA site in new tab">
              <Link href={dfrUrl} target="_blank">
                <OpenInNew fontSize="small" />
              </Link>
            </Tooltip>
          ) : null;
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <Tooltip title="Save" key="save">
            <GridActionsCellItem
              label="Save"
              icon={<Check fontSize="small" />}
              onClick={() => onSave([params.row.id])}
            />
          </Tooltip>,
          <Tooltip title="Delete candidate" key="delete">
            <GridActionsCellItem
              label="Delete"
              icon={<Delete fontSize="small" />}
              onClick={() => onDelete([params.row.id])}
            />
          </Tooltip>,
        ],
      },
    ],
    [onSave, onDelete]
  );
  return (
    <Card variant="outlined">
      <CardContent>
        <DataGrid
          columns={columns}
          rows={row.echoFacilityMatchCandidates ?? []}
          checkboxSelection
          isRowSelectable={() => true}
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={(ids) => {
            onSelectionChanged(ids as string[]);
          }}
          getDetailPanelHeight={() => "auto"}
          sortModel={[{ field: "matchScore", sort: "desc" }]}
        />
      </CardContent>
    </Card>
  );
});

const ExpandCollapseAllHeader = React.memo(function ExpandCollapseAllHeader() {
  const apiRef = useGridApiContext();

  const expandedRowIds = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowIdsSelector
  );
  const rowsWithDetailPanels = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  const noDetailPanelsOpen = expandedRowIds.length === 0;

  const expandOrCollapseAll = () => {
    const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
    const allRowIdsWithDetailPanels: GridRowId[] = Object.keys(
      rowsWithDetailPanels
    ).map((key) => apiRef.current.getRowId(dataRowIdToModelLookup[key]));

    apiRef.current.setExpandedDetailPanels(
      noDetailPanelsOpen ? allRowIdsWithDetailPanels : []
    );
  };

  const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      onClick={expandOrCollapseAll}
      aria-label={noDetailPanelsOpen ? "Expand All" : "Collapse All"}
    >
      <Icon fontSize="inherit" />
    </IconButton>
  );
});
