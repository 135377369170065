import {
  TypedDocumentNode,
  WatchQueryFetchPolicy,
  useQuery,
} from "@apollo/client";
import { Box, Stack, SxProps, Theme, useTheme } from "@mui/material";
import {
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowHeightParams,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  GridValidRowModel,
  MuiEvent,
  UncapitalizedGridPremiumSlotsComponent,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { DataGrid } from "components/DataGrid";
import { Omnisearch } from "components/Omnisearch/OmnisearchV2";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDebounce } from "hooks/useDebounce";
import {
  FilterKey,
  OmnisearchGridColDef,
  useOmnisearchDatagrid,
} from "hooks/useOmnisearchDatagridSettings";
import { compact, debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NoRowsOverlay } from "routes/Customer/Chemicals/Inventory/Facility/NoRowsOverlay";
import { DateSearchHelpTooltip } from "./Omnisearch/helpComponents/DateSearchHelpTooltip";

export const helpTooltipByField: { [field: string]: React.ComponentType } = {
  createdAt: DateSearchHelpTooltip,
  updatedAt: DateSearchHelpTooltip,
  measuredAt: DateSearchHelpTooltip,
};
export type GridApiRef = React.MutableRefObject<GridApiPremium>;

export function OmnisearchDataGrid<
  TData,
  TVariables,
  TRow extends GridValidRowModel
>({
  apiRef,
  autoFocus,
  persistenceKey,
  columns: columnsInput,
  rowHeight,
  getRowHeight,
  dataQuery,
  dataQueryVariables,
  defaultSearch = "",
  commandButtons,
  sx,
  gridSx,
  slots,
  excludeFilterColumns,
  additionalFilterColumns,
  initialFilters = [],
  columnVisibilityModel,
  noDataMessage = "No results found.",
  noDataButtonText,
  selectedIds,
  noDataOnButtonClick,
  getItems,
  getCount,
  onSelectedRowsChanged,
  onSelectedIdsChanged,
  isRowSelectable,
  onRowClick,
  onLoadingStateChange,
  onColumnVisibilityModelChange,
  onColumnWidthChange,
  onSearchChanged,
  initialSortModel = [],
  fetchPolicy = "cache-and-network",
  initialState,
  initialPageSize,
  isURLDriven = true,
  otherLoading = false,
  showFavorites = false,
  withPadding = true,
  skip = false,
  getDetailPanelContent,
  getDetailPanelHeight,
  getRowId,
  shouldPoll,
  disableRowSelectionOnClick,
}: {
  apiRef?: GridApiRef;
  autoFocus?: boolean;
  /** key to be used as an identifier for persisted state in localStorage */
  persistenceKey?: string;
  columns: OmnisearchGridColDef<TRow>[];
  rowHeight?: number | "auto";
  getRowHeight?: (params: GridRowHeightParams) => number | "auto";
  dataQuery: TypedDocumentNode<TData, TVariables>;
  dataQueryVariables?: TVariables;
  defaultSearch?: string;
  commandButtons?: React.ReactNode[];
  /** sx for the Box wrapping the component */
  sx?: SxProps<Theme>;
  gridSx?: SxProps<Theme>;
  slots?: Partial<UncapitalizedGridPremiumSlotsComponent>;
  /** Additional columns to exclude from the Omnisearch filter popover. ("actions" is excluded by default) */
  excludeFilterColumns?: GridColDef["field"][];
  additionalFilterColumns?: FilterKey[];
  initialFilters?: { field: string; value: string | boolean }[];
  columnVisibilityModel?: GridColumnVisibilityModel;
  /** Message to display in the grid when there is no data for the default search (default "No results found.") */
  noDataMessage?: string | string[];
  /** Button text to display in the grid when there is no data for the default search (must be defined to display the button) */
  noDataButtonText?: string;
  /** Set grid's selected IDs. This can include IDs across pages. */
  selectedIds?: GridRowId[];
  /** onClick handler for the button displayed in the grid when there is no data for the default search */
  noDataOnButtonClick?: () => void;
  getItems: (data: TData) => TRow[];
  getCount: (data: TData) => number;
  /** Handler called when selection changed with row data. This will not include
   * rows selected across pages */
  onSelectedRowsChanged?: (rows: TRow[]) => void;
  /** Handler called when selection changed. This can include IDs across pages
   * */
  onSelectedIdsChanged?: (ids: GridRowId[]) => void;
  isRowSelectable?: (params: GridRowParams<TRow>) => boolean;
  initialPageSize?: number;
  /**
   * Function to handle the datagrid's onRowClick event.
   * i.e.
   * ```
   * const handleRowClick = (params: GridRowParams) => {
   *  history.push(`/facility/${params.id}`);
   * };
   */
  onRowClick?: (
    params: GridRowParams<TRow>,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>,
    details: GridCallbackDetails
  ) => void;
  /**
   * Function to handle the datagrid's onLoadingStateChange event.
   */
  onLoadingStateChange?: (loading: boolean) => void;
  onColumnVisibilityModelChange?: (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails<any>
  ) => void;
  onColumnWidthChange?: GridEventListener<"columnWidthChange">;
  initialSortModel?: GridSortModel;
  onSearchChanged?: (search: string) => void;
  /**
   * Apollo client fetch policy. Defaults to "cache-and-network"
   */
  fetchPolicy?: WatchQueryFetchPolicy;
  initialState?: GridInitialStatePremium;
  /**
   * Indicates if Omnisearch should use the url querystring. Set to false to use
   * an internal search variable (i.e. for a modal).
   */
  isURLDriven?: boolean;
  otherLoading?: boolean;
  /** When true, the omnisearch max width will always be 100%. `commandButtons`
   * may still be used */
  showFavorites?: boolean;
  /** when true, provide extra padding around grid */
  withPadding?: boolean;
  /** (optional) skip running query if true. Defaults to false */
  skip?: boolean;
  getDetailPanelContent?: (params: GridRowParams<TRow>) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams<TRow>) => "auto" | number;
  getRowId?: (row: TRow) => GridRowId;
  shouldPoll?: (data: TData) => number | undefined;
  disableRowSelectionOnClick?: boolean;
}) {
  const theme = useTheme();
  const { user } = useCurrentUser();
  const dataGridRef = useRef<HTMLDivElement>(null);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const {
    columns,
    omnisearch,
    paginationModel,
    sortModel,
    setOmnisearch,
    setPaginationModel,
    setSortModel,
    columnFilterKeys,
  } = useOmnisearchDatagrid({
    persistenceKey,
    columns: columnsInput,
    additionalFilters: additionalFilterColumns,
    excludeFilters: excludeFilterColumns,
    isURLDriven,
    initialSortModel,
    initialPageSize,
    initialFilters,
  });

  const search = useMemo(() => {
    return [defaultSearch, omnisearch].join(" ").trim();
  }, [omnisearch, defaultSearch]);

  const [debouncedSearch, isWaiting] = useDebounce(search, 1000);

  useEffect(
    () => onSearchChanged && onSearchChanged(debouncedSearch),
    [debouncedSearch, onSearchChanged]
  );

  const {
    data,
    error,
    loading: dataLoading,
    previousData,
    startPolling,
    stopPolling,
  } = useQuery(dataQuery, {
    variables: {
      search: debouncedSearch,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
      ...dataQueryVariables,
    },
    fetchPolicy,
    skip,
  });

  useEffect(() => {
    const pollInterval = data ? shouldPoll?.(data) : undefined;
    if (pollInterval) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling, shouldPoll, data]);

  const currentData = useMemo(() => data ?? previousData, [data, previousData]);

  const items = useMemo(
    () => (currentData ? getItems(currentData) : []),
    [currentData, getItems]
  );
  const count = useMemo(
    () => (currentData ? getCount(currentData) : 0),
    [currentData, getCount]
  );

  const loading = dataLoading || otherLoading || isWaiting;

  useEffect(() => {
    if (onLoadingStateChange) onLoadingStateChange(loading);
  }, [loading, onLoadingStateChange]);

  useEffect(() => {
    if (selectedIds) {
      setRowSelectionModel(selectedIds);
    }
  }, [selectedIds]);

  const setSelectedIds = useCallback(
    (ids: GridRowSelectionModel) => {
      if (onSelectedIdsChanged) {
        onSelectedIdsChanged(ids);
      }
    },
    [onSelectedIdsChanged]
  );

  useEffect(() => {
    onSelectedRowsChanged &&
      items.length &&
      onSelectedRowsChanged(
        compact(
          rowSelectionModel.map((id) => {
            return items.find((item) => item.id === id);
          })
        )
      );
  }, [rowSelectionModel, onSelectedRowsChanged, items]);

  if (error) throw error;

  const clearOmnisearch = useCallback(() => {
    setOmnisearch("");
  }, [setOmnisearch]);

  // When there are no query results, but the default query has a count, show a
  // "no results found" message and a button to clear the search. When the
  // default query's count is 0, show the noDataMessage.
  const noRowsMessage = useMemo(
    () => (omnisearch ? "No results found." : noDataMessage),
    [noDataMessage, omnisearch]
  );

  const noRowsButtonText = useMemo(
    () => (omnisearch ? "Clear search" : noDataButtonText),
    [noDataButtonText, omnisearch]
  );

  const noRowsOnButtonClick = useMemo(
    () => (omnisearch ? clearOmnisearch : noDataOnButtonClick),
    [clearOmnisearch, noDataOnButtonClick, omnisearch]
  );

  const memoizedSlots:
    | Partial<UncapitalizedGridPremiumSlotsComponent>
    | undefined = useMemo(
    () => ({
      noRowsOverlay: (props) => (
        <NoRowsOverlay
          {...props}
          onButtonClick={noRowsOnButtonClick}
          message={noRowsMessage}
          buttonText={noRowsButtonText}
        />
      ),
      ...slots,
    }),
    [slots, noRowsOnButtonClick, noRowsMessage, noRowsButtonText]
  );

  if (!user) return <></>;
  return (
    <Box sx={{ px: withPadding ? 3 : 0 }}>
      <Stack
        sx={{ marginBottom: theme.spacing(1.5) }}
        spacing={theme.spacing(1.5)}
        direction={{ xs: "column", lg: "row" }}
        alignItems="flex-end"
      >
        <Omnisearch
          autoFocus={autoFocus}
          omnisearch={omnisearch}
          setOmnisearch={setOmnisearch}
          columnFilterKeys={columnFilterKeys}
          showFavorites={showFavorites}
          sx={{
            width: {
              xs: "100%",
              lg: "inherit",
            },
            flexGrow: 1,
          }}
        />
        {(commandButtons?.length ?? 0) > 0 && (
          <Stack
            spacing={1.5}
            direction={{ xs: "column", sm: "row" }}
            sx={{ width: { xs: "100%", sm: "inherit" } }}
          >
            {commandButtons}
          </Stack>
        )}
      </Stack>
      <Box position="relative" sx={sx}>
        <DataGrid
          apiRef={apiRef}
          columns={columns}
          rows={items}
          // Defaulted to match our current designs but allows it to be overridden
          getRowHeight={getRowHeight ?? (() => rowHeight ?? 38)}
          autoHeight={rowHeight === "auto"}
          innerRef={dataGridRef}
          getRowId={getRowId}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              minHeight: items.length === 0 ? "100px" : "0",
            },
            opacity:
              loading && previousData
                ? theme.palette.action.disabledOpacity
                : 1,
            ...gridSx,
          }}
          columnBuffer={3} // Consider for optimization if horizontal scrolling has issues
          checkboxSelection={!!onSelectedRowsChanged || !!onSelectedIdsChanged}
          disableRowSelectionOnClick={
            typeof disableRowSelectionOnClick === "boolean"
              ? disableRowSelectionOnClick
              : !(!!onSelectedRowsChanged || !!onSelectedIdsChanged)
          }
          loading={loading}
          rowCount={count}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newModel) => {
            setRowSelectionModel(newModel);
            setSelectedIds(newModel);
          }}
          isRowSelectable={isRowSelectable}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          onColumnWidthChange={onColumnWidthChange}
          keepNonExistentRowsSelected
          slots={memoizedSlots}
          onRowClick={onRowClick}
          columnVisibilityModel={columnVisibilityModel}
          initialState={initialState}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
        />
      </Box>
    </Box>
  );
}
