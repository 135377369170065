import { useTheme } from "@mui/material";
import { AppBarProps } from "@mui/material/AppBar";
import { AppBar as MuiAppBar } from "@mui/material";

export const xsHeight = 10;
export const mdHeight = 9;

export const AppBar = (props: AppBarProps) => {
  const theme = useTheme();
  return (
    <MuiAppBar
      position="sticky"
      elevation={0}
      {...props}
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        height: {
          xs: theme.spacing(xsHeight),
          md: theme.spacing(mdHeight),
        },
        borderBottom: "1px solid",
        borderColor: theme.palette.divider,
        ...props.sx,
      }}
    >
      {props.children}
    </MuiAppBar>
  );
};

/**
 * Designed to sit under the TopNavBar. They are both sticky by default.
 * @param props
 * @returns
 */
export const SubNavAppBar = (props: AppBarProps) => {
  const theme = useTheme();
  return (
    <MuiAppBar
      position="sticky"
      elevation={0}
      {...props}
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        top: {
          xs: theme.spacing(xsHeight),
          md: theme.spacing(mdHeight),
        },
        ...props.sx,
      }}
    >
      {props.children}
    </MuiAppBar>
  );
};
