import { Reference, useLazyQuery, useQuery } from "@apollo/client";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Computer from "@mui/icons-material/Computer";
import Delete from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";
import Upload from "@mui/icons-material/Upload";
import WarningOutlined from "@mui/icons-material/WarningOutlined";
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import {
  useRemoveDocumentFromActivityMutation,
  useUpdateDocumentMutation,
} from "hooks/documents";
import { useCurrentUser } from "hooks/useCurrentUser";
import { isNil } from "lodash";
import { useToken } from "providers/token";
import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { v4 } from "uuid";
import { useAlerts } from "../../../../../components/Alerts/AlertProvider";
import {
  ActivityStatus,
  ActivityType,
  GetTierIiReportQuery,
  PaymentStatus,
  StaffInput,
  StaffInputType,
} from "../../../../../generated-graphql/graphql";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "../../../../../queries/getDocumentDownloadLink";
import GET_STAFF_INPUTS from "../../../../../queries/getStaffInputs";
import { uploadFile } from "../../../../../util/uploadFile";
import { PaymentDialog } from "../Payments/PaymentDialog";
import { useReportDetails } from "../useReportDetails";
import { LobCheckFeeInput } from "./LobCheckFeeInput";
import { WaitFor } from "./WaitFor";

type DocType = NonNullable<
  GetTierIiReportQuery["tierIIReport"]["documents"]
>[0];

interface DocumentTableRow {
  id: string;
  isRequired: boolean;
  documentType: string;
  document: DocType | null;
}

export const StaffInputs: React.FC = () => {
  const theme = useTheme();
  const { activityId } = useParams<{ activityId: string }>();
  const alerts = useAlerts();
  const { data: reportData, loading } = useReportDetails();
  const { user } = useCurrentUser();

  invariant(user);

  const [removeDocumentFromActivity, { loading: isLoading }] =
    useRemoveDocumentFromActivityMutation({
      update(cache, { data }) {
        if (!data?.removeDocumentFromActivity) return;

        const id = cache.identify({
          __typename: "Document",
          id: data.removeDocumentFromActivity.documentId,
        });

        cache.modify({
          optimistic: true,
          id: cache.identify({
            __typename: "Activity",
            id: data.removeDocumentFromActivity.activityId,
          }),
          fields: {
            documents(existingActivityDocuments = []) {
              return existingActivityDocuments.filter(
                (activityDocument: { document: Reference }) =>
                  activityDocument.document.__ref !== id
              );
            },
          },
        });
        cache.modify({
          optimistic: true,
          id: cache.identify({
            __typename: "TierIIReport",
            id: reportData?.tierIIReport.id,
          }),
          fields: {
            documents(documents = []) {
              return documents.filter(
                (document: Reference) => document.__ref !== id
              );
            },
          },
        });
      },
    });

  const [updateDocument] = useUpdateDocumentMutation({
    update(cache, { data }) {
      if (!data?.updateDocument) return;

      const id = cache.identify(data.updateDocument);

      cache.modify({
        optimistic: true,
        id: cache.identify({
          __typename: "Activity",
          id: activity?.id,
        }),
        fields: {
          documents(existingDocuments = []) {
            return existingDocuments.map((document: Reference) =>
              document.__ref === id
                ? { ...data.updateDocument, __ref: id }
                : document
            );
          },
        },
      });
    },
  });

  const processRowUpdate = async (newRow: GridRowModel) => {
    await updateDocument({
      variables: {
        id: newRow.document.id,
        input: {
          title: newRow.title,
          description: newRow.description,
        },
      },
    });
    return newRow;
  };
  const activity = reportData?.tierIIReport.currentWorkflow?.activities.find(
    (a) => a.id === activityId
  );
  const activityDocs = activity?.documents?.map((ad) => ad.document) ?? [];
  const inStaffAction = activity?.type === ActivityType.Staff;
  const fileInputs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  const onClickDelete = async (documentId: string) => {
    if (!activity?.id) return;
    await removeDocumentFromActivity({
      variables: { documentId, activityId: activity?.id },
    });
  };

  const { data: staffInputData, loading: loadingRequiredStaffInputs } =
    useQuery(GET_STAFF_INPUTS, {
      variables: {
        activityDescription: activity?.description ?? "",
      },
      skip: !inStaffAction,
    });

  const hasFeesInput =
    staffInputData?.getRequiredStaffInputs?.requiredStaffInputs.some(
      (staffInput) => staffInput.name === StaffInput.Fees
    );

  const links = staffInputData?.getRequiredStaffInputs?.links;
  const requiredDocumentInputs =
    staffInputData?.getRequiredStaffInputs?.requiredStaffInputs
      .filter((staffInput) => staffInput.inputType === StaffInputType.File)
      .map((staffInput) => staffInput.name) ?? [];

  const { getIdTokenRefreshIfExpired } = useToken();

  const handleStaffInputClick = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, requiredInput: string) => {
      const file = e.target.files?.[0];
      if (isNil(file) || isNil(reportData)) return;

      const { tierIIReport } = reportData;

      const idToken = await getIdTokenRefreshIfExpired();
      try {
        await uploadFile(
          file,
          {
            tenantId: tierIIReport.tenant.id,
            userId: user.id,
            facilities: [{ id: tierIIReport.facility.id }],
            reportId: tierIIReport.id,
            documentType: requiredInput,
            description: requiredInput,
            activityId: activity?.id,
          },
          idToken
        );
        alerts.success(`Successfully uploaded ${requiredInput}`);
      } catch (err) {
        alerts.error(`${err}`);
      }

      const ref = fileInputs.current[requiredInput];

      if (!isNil(ref)) {
        ref.value = "";
        fileInputs.current[requiredInput] = ref;
      }
    },
    [reportData, getIdTokenRefreshIfExpired, activity?.id, alerts]
  );

  let documentRows: DocumentTableRow[] = requiredDocumentInputs?.map(
    (inputType) => ({
      id: v4(),
      isRequired: true,
      documentType: inputType as string,
      document: null,
    })
  );

  documentRows = activityDocs?.reduce((acc: DocumentTableRow[], doc) => {
    const docTypeIndex = acc.findIndex(
      (x) => x.documentType === doc.documentType
    );
    if (docTypeIndex >= 0 && acc[docTypeIndex].document === null) {
      acc[docTypeIndex].document = doc;
      acc[docTypeIndex].id = doc.id;
    } else {
      acc.push({
        id: doc.id,
        isRequired: false,
        documentType: doc.documentType,
        document: doc,
      });
    }

    return acc;
  }, documentRows);

  const columns: GridColDef[] = [
    {
      field: "status",
      flex: 0.1,
      headerName: "",
      valueGetter: (params) => {
        // This powers the default sort, and sorting overall on this icon column.
        if (params.row.isRequired && !params.row.document) {
          return 0;
        } else if (params.row.isRequired && params.row.document) {
          return 1;
        } else {
          return 2;
        }
      },
      renderCell: (params) => {
        {
          if (!params.row.isRequired) {
            return (
              <Tooltip
                title="Extra document uploaded by Encamp Platform"
                placement="top"
                arrow
              >
                <Computer style={{ color: "gray", opacity: 0.7 }} />
              </Tooltip>
            );
          } else {
            if (params.row.document) {
              return (
                <Tooltip title="Document Uploaded" placement="top" arrow>
                  <CheckCircleOutline style={{ color: "gray", opacity: 0.7 }} />
                </Tooltip>
              );
            } else {
              return (
                <Tooltip
                  title="Document Required: Upload to complete activity"
                  placement="top"
                  arrow
                >
                  <WarningOutlined
                    style={{ color: "goldenrod", opacity: 0.7 }}
                  />
                </Tooltip>
              );
            }
          }
        }
      },
    },
    {
      field: "documentType",
      headerName: "Type",
      flex: 0.9,
      valueGetter: (params) => params.row.documentType,
      renderCell: (params) => {
        return (
          <>
            {params.row.documentType}
            <input
              name={params.row.documentType}
              type="file"
              onChange={async (e) =>
                await handleStaffInputClick(e, params.row.documentType)
              }
              style={{ display: "none" }} // This hides the input
              ref={(ref) => (fileInputs.current[params.row.documentType] = ref)}
            />
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
      editable: !isLoading,
      renderCell: (params) => {
        return (
          params.row.document?.title ?? (
            <Typography
              color="text.secondary"
              variant="body2"
              style={{ fontStyle: "italic" }}
            >
              Upload a document to complete activity
            </Typography>
          )
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 150,
      align: "left",
      getActions: (params) => {
        if (params.row.document !== null && params.row.document !== undefined) {
          return [
            <Tooltip title="Download Document" key={2}>
              <GridActionsCellItem
                onClick={() => {
                  if (!params.row.document) return;
                  getDocumentDownloadLink({
                    variables: { id: params.row.document.id },
                  });
                }}
                key={2}
                label="Download Document"
                icon={<Download />}
                disabled={isLoading}
              />
            </Tooltip>,
            <Tooltip title="Delete Document" key={1}>
              <GridActionsCellItem
                icon={<Delete />}
                key={1}
                label="Delete Document"
                disabled={isLoading}
                onClick={() => {
                  if (!params.row.document) return;
                  onClickDelete(params.row.document.id);
                }}
              />
            </Tooltip>,
          ];
        } else {
          return [
            <Tooltip title="Upload Document" key={1}>
              <GridActionsCellItem
                icon={<Upload />}
                key={1}
                label="Upload Document"
                disabled={isLoading}
                onClick={() =>
                  fileInputs.current[params.row.documentType]?.click()
                }
              />
            </Tooltip>,
          ];
        }
      },
    },
  ];

  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          alerts.error("Error fetching download link");
          return;
        }
        window.open(data.getDocumentDownloadLink, "_blank");
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );
  const hasLobCheckActivity =
    reportData?.tierIIReport.currentWorkflow?.activities.some(
      (activity) =>
        activity.type === ActivityType.Lob &&
        activity.description === "Check" &&
        activity.status === ActivityStatus.NotStarted
    ) ?? false;

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const closePaymentForm = () => setShowPaymentForm(false);

  const canMakePayment =
    hasFeesInput &&
    (!activity?.championId ||
      activity?.championId === reportData?.tierIIReport.id) &&
    !hasLobCheckActivity;

  return (
    <Stack direction="column" spacing={1}>
      <Typography>
        {documentRows && documentRows.some((x) => !x.document && x.isRequired)
          ? "The following documents must be uploaded before this activity can be completed:"
          : `No documents necessary to complete this activity.`}
      </Typography>
      {documentRows && documentRows.length > 0 && (
        <DataGrid
          rows={documentRows}
          sortModel={[
            {
              field: "status",
              sort: "asc",
            },
          ]}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onRowClick={(params, event) => {
            if (!params?.row?.document) {
              fileInputs.current[params.row.documentType]?.click();
            }
          }}
          hideFooter
        />
      )}
      <WaitFor />
      {hasFeesInput && hasLobCheckActivity && reportData && activityId && (
        <Box sx={{ paddingY: 2 }}>
          <LobCheckFeeInput
            payment={activity?.payments?.find(
              (p) => p.status === PaymentStatus.QueuedForAutomation
            )}
            reportId={reportData.tierIIReport.id}
            activityId={activityId}
            tenantId={reportData.tierIIReport.tenantId}
          />
        </Box>
      )}
      {canMakePayment && (
        <>
          <Box>
            <Button
              variant="contained"
              onClick={() => setShowPaymentForm(true)}
            >
              Make or record a payment
            </Button>
          </Box>
          {showPaymentForm && reportData && (
            <PaymentDialog
              open={true}
              onClose={closePaymentForm}
              tenantId={reportData.tierIIReport.tenant.id}
              facilityId={reportData.tierIIReport.facility.id}
              reportId={reportData.tierIIReport.id}
              activityId={activityId}
              associatedReportIds={activity?.nonChampionIds ?? []}
            />
          )}
        </>
      )}
      {links?.length && (
        <Box sx={{ my: theme.spacing(2) }}>
          <Typography variant="h6" sx={{ mb: 0 }}>
            {activity?.description} Links
          </Typography>
          <List sx={{ p: 0 }}>
            {links.map(({ name, link }, index) => (
              <ListItem key={index} sx={{ px: 0 }}>
                <Stack>
                  {name && <Typography variant="caption">{name}</Typography>}
                  <Link
                    href={link}
                    sx={{ wordBreak: "break-word" }}
                    target="_blank"
                  >
                    {link}
                  </Link>
                </Stack>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Stack>
  );
};
