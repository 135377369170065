import { FormSelect } from "components/Forms/FormSelect";
import { ComponentProps } from "react";

type Props = Omit<ComponentProps<typeof FormSelect>, "label" | "selectItems">;

export const CountryPicker = (props: Props) => {
  return (
    <FormSelect
      {...props}
      label="Country"
      selectItems={[
        { value: "USA", display: "United States" },
        { value: "Canada", display: "Canada" },
      ]}
    />
  );
};
