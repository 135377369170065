import { useQuery } from "@apollo/client";
import { CircularProgress, SxProps, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { gql } from "generated-graphql";
import {
  Facility,
  MultipleFacilityPickerFragment,
} from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import React, { forwardRef, useState } from "react";
import { ControllerFieldState } from "react-hook-form";

gql(`
  fragment MultipleFacilityPicker on Facility {
    id
    name
  }
`);

const FACILITIES_QUERY = gql(`
  query PickerFacilitiesQuery($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...MultipleFacilityPicker
      }
      count
    }
  }
`);

interface MultipleFacilityPickerProps extends ControllerFieldState {
  onChange: (chemical: MultipleFacilityPickerFragment[] | null) => void;
  defaultSearchTerm?: string;
  disabledFacilitiesById?: string[];
  disabledOptionLabel?: string;
  label?: string;
  value?: MultipleFacilityPickerFragment[] | null;
  lazyLoad?: boolean;
  sx?: SxProps;
}

export const MultipleFacilityPicker: React.FC<MultipleFacilityPickerProps> =
  forwardRef(function MultipleFacilityPicker(
    {
      onChange,
      defaultSearchTerm = "",
      disabledFacilitiesById = [],
      disabledOptionLabel = "",
      label,
      value: initialValue,
      error,
      lazyLoad = true,
      sx,
    },
    ref
  ) {
    const [value, setValue] = useState(initialValue ?? null);
    const [search, setSearch] = useState("");
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [debouncedSearch] = useDebounce(
      `${defaultSearchTerm} ${search ?? ""}`,
      150
    );

    const { data, loading, previousData } = useQuery(FACILITIES_QUERY, {
      variables: {
        search: debouncedSearch,
        page: 0,
        pageSize: 5,
        sort: [{ field: "name", sort: "asc" }],
      },
      skip: lazyLoad && !searchIsOpen,
      fetchPolicy: "cache-and-network",
    });

    return (
      <>
        <Autocomplete
          multiple
          filterSelectedOptions
          sx={sx}
          ref={ref}
          options={
            data?.facilities.items ?? previousData?.facilities.items ?? []
          }
          isOptionEqualToValue={(o, v) => o.id === v.id}
          noOptionsText="Search for a facility"
          onOpen={() => setSearchIsOpen(true)}
          onClose={() => setSearchIsOpen(false)}
          inputValue={search}
          value={determineValue(value, [
            ...(data?.facilities?.items ?? []),
            ...(previousData?.facilities?.items ?? []),
          ])}
          loading={loading}
          onInputChange={(_, val) => {
            setSearch(val);
          }}
          onChange={(_, val) => {
            setValue(val);
            onChange(val);
          }}
          getOptionLabel={(option: MultipleFacilityPickerFragment) =>
            option.name ?? ""
          }
          getOptionDisabled={(option: MultipleFacilityPickerFragment) =>
            disabledFacilitiesById.includes(option?.id ?? "")
          }
          renderOption={(props, option) => (
            <li {...props}>
              {option.name}
              {disabledFacilitiesById.includes(option?.id ?? "") && (
                <span>&nbsp;({disabledOptionLabel})</span>
              )}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              label={label ?? "Facility"}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <ErrorDisplay error={error} />
      </>
    );
  });

function determineValue(
  values: MultipleFacilityPickerFragment[] | null | undefined,
  data: Partial<Facility>[]
): MultipleFacilityPickerFragment[] | undefined {
  if (values?.every((v) => v.name && v.id)) {
    return values;
  }

  const newVals = [];

  for (const value of values ?? []) {
    if (value?.name && value?.id) {
      newVals.push(value);
    }
    const dataValue = data.find((f) => f.id === value?.id);
    if (dataValue) {
      newVals.push({ id: dataValue.id ?? "", name: dataValue.name ?? "" });
    }
  }

  return newVals;
}
