import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import { TierIiReportEncampStatus } from "generated-graphql/graphql";
import { useTheme } from "@mui/material";

interface StatusBarProps {
  status: TierIiReportEncampStatus;
}

const steps = [
  TierIiReportEncampStatus.AwaitingVerification,
  TierIiReportEncampStatus.Processing,
  TierIiReportEncampStatus.FilingComplete,
];
export const EncampStatusStepper: React.FC<StatusBarProps> = ({ status }) => {
  const theme = useTheme();
  const activeStep = steps.indexOf(status);

  const StepIcon = ({
    active,
    completed,
  }: {
    active: boolean;
    completed: boolean;
  }) => {
    return completed || active ? (
      <CheckCircle style={{ color: theme.palette.success.main }} />
    ) : (
      <RadioButtonUnchecked color="disabled" />
    );
  };

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((currentStatus, index) => {
        const stepCompleted = index <= activeStep;

        return (
          <Step key={currentStatus} completed={stepCompleted}>
            <StepLabel
              StepIconComponent={() => (
                <StepIcon
                  active={index === activeStep}
                  completed={stepCompleted}
                />
              )}
            >
              {statusToLabel(currentStatus)}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

const statusToLabel = (status: TierIiReportEncampStatus) => {
  switch (status) {
    case TierIiReportEncampStatus.AwaitingVerification:
      return "Awaiting Verification";
    case TierIiReportEncampStatus.Processing:
      return "Processing";
    case TierIiReportEncampStatus.FilingComplete:
      return "Filing Complete";
    default:
      throw new Error(`Unexpected value: ${status}`);
  }
};
