import { useQuery } from "@apollo/client";
import { UseAutocompleteProps } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { gql } from "generated-graphql";
import * as React from "react";

const GET_ALL_TIERII_REPORT_TAGS = gql(`
  query GetTierIIReportTags {
    getAllTierIIReportTags
}`);

type TagOption = {
  inputValue?: string;
  tag: string;
};

const filter = createFilterOptions<TagOption>();

type Props = {
  onAddTag: (tag: string) => void;
};

export default function TierIIReportTagPicker(props: Props) {
  const [value, setValue] = React.useState<TagOption | null>(null);
  const { data } = useQuery(GET_ALL_TIERII_REPORT_TAGS);

  const tags: TagOption[] =
    data?.getAllTierIIReportTags.map((tag) => ({ tag })) ?? [];

  const onChange = React.useCallback(
    (
      evnet: React.SyntheticEvent<Element, Event>,
      newTag: string | TagOption | null
    ) => {
      if (typeof newTag === "string") {
        setValue({
          tag: newTag,
        });
        props.onAddTag(newTag);
      } else if (newTag && newTag.inputValue) {
        // Create a new value from the user input
        setValue({
          tag: newTag.inputValue,
        });
        props.onAddTag(newTag.inputValue);
      } else {
        setValue(newTag);
        newTag && props.onAddTag(newTag.tag);
      }
    },
    [props.onAddTag]
  );

  const filterOptions: UseAutocompleteProps<
    TagOption,
    false,
    false,
    true
  >["filterOptions"] = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.tag);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        tag: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = React.useCallback((option: string | TagOption) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.tag;
  }, []);

  return (
    <Autocomplete
      freeSolo
      value={value}
      onChange={onChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="tag-picker"
      sx={{ width: "100%" }}
      options={tags}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => <li {...props}>{option.tag}</li>}
      renderInput={(params) => <TextField {...params} label="Add tag" />}
    />
  );
}
