import { useContext } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { BreadcrumbContext, Crumb } from "../providers/breadcrumbs";

export function useBreadcrumbs() {
  const context = useContext(BreadcrumbContext);

  if (context === undefined) {
    throw new Error("useBreadcrumbs must be used within a BreadcrumbProvider");
  }

  return context;
}

export function useBreadcrumb(crumb: Crumb): void;
export function useBreadcrumb(crumb: Crumb[]): void;
export function useBreadcrumb(crumb: Crumb | Crumb[]) {
  const { addBreadcrumb, removeBreadcrumb } = useContext(BreadcrumbContext);

  useDeepCompareEffect(() => {
    const crumbs = Array.isArray(crumb) ? crumb : [crumb];
    crumbs.forEach((crumb) => addBreadcrumb(crumb));
    return () => {
      crumbs.forEach((crumb) => removeBreadcrumb(crumb));
    };
  }, [addBreadcrumb, crumb, removeBreadcrumb]);
}
