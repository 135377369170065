import { InsightsLinker, ComparisonFields } from "./InsightsLinker";
import { Echo, Row as EchoRow } from "routes/Customer/Insights/Echo";
import { EchoDetail } from "routes/Customer/Insights/EchoDetail";
import { useParams } from "react-router-dom";

export function EchoProfile() {
  const { facilityId } = useParams<{ facilityId: string }>();

  const mapper = (rowData: EchoRow): ComparisonFields => ({
    name: rowData["facName"] ?? "",
    streetAddress: rowData["facStreet"] ?? "",
    streetAddress2: "",
    city: rowData["facCity"] ?? "",
    state: rowData["facState"] ?? "",
    zip: rowData["facZip"] ?? "",
    id: rowData["id"] ?? "",
  });

  return (
    <>
      <InsightsLinker
        componentIfLinked={EchoDetail}
        componentIfUnlinked={Echo}
        facilityId={facilityId || ""}
        type="FRS"
        comparisonMapper={mapper}
      />
    </>
  );
}
