import { Box, Paper, Skeleton, Stack, useTheme } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ReportingSeasonSummaryQuery } from "../../../../../generated-graphql/graphql";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  data?: ReportingSeasonSummaryQuery["reportingSeasonSummary"];
  loading: boolean;
};

export function SummaryDoughnutChart({ data, loading }: Props) {
  const theme = useTheme();

  const doughnutChartData = {
    labels: [
      "Not Reporting",
      "No Report",
      "Awaiting Verification",
      "Processing",
      "Filing Complete",
    ],
    datasets: [
      {
        data: [
          data?.reportsInNotReporting,
          data?.noReportsTotal,
          data?.reportsInAwaitingVerification,
          data?.reportsInProcessing,
          data?.reportsInFilingComplete,
        ],
        backgroundColor: [
          "#B07D9D", // purple (not reporting)
          "#E3797B", // red (no report)
          "#F9DB79", // yellow (awaiting verification)
          "#72B3E3", // blue (processing)
          "#85D7A7", // green (filing complete)
        ],
      },
    ],
  };

  return (
    <Paper
      sx={{
        height: "100%",
        padding: 2,
        border: `${theme.palette.divider} solid 1px`,
      }}
    >
      {loading ? (
        <Stack sx={{ height: "100%", padding: 2 }} spacing={2}>
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="rounded" sx={{ flex: 1 }} />
        </Stack>
      ) : (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Doughnut
            data={doughnutChartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              color: theme.palette.text.primary,
              plugins: {
                legend: {
                  position: "left",
                },
              },
            }}
          />
        </Box>
      )}
    </Paper>
  );
}
