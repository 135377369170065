import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback } from "react";
import { FacilityChemicalStorageLocationInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacilityChemicalStorageLocation";

const VALIDATE_FACILITY_CHEMICAL_STORAGE_LOCATION_INPUT = gql(`
  query ValidateFacilityChemicalStorageLocationInput($input: FacilityChemicalStorageLocationInput!, $facilityId: String!, $facilityChemicalId: String!) {
    facilityChemicalStorageLocationInputValidator(input: $input, facilityId: $facilityId, facilityChemicalId: $facilityChemicalId) {
      ...issue
    }
  }
`);

export function useFacilityChemicalStorageLocationInputValidation(
  facilityId: string,
  facilityChemicalId: string
) {
  const [query] = useLazyQuery(
    VALIDATE_FACILITY_CHEMICAL_STORAGE_LOCATION_INPUT,
    {
      fetchPolicy: "no-cache",
    }
  );

  return useCallback(
    async (input: FacilityChemicalStorageLocationInput) => {
      const { data: result } = await query({
        variables: {
          input: transform(input),
          facilityId: facilityId,
          facilityChemicalId: facilityChemicalId,
        },
      });
      return result?.facilityChemicalStorageLocationInputValidator ?? [];
    },
    [query, facilityId, facilityChemicalId]
  );
}
