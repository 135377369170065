import { useQuery } from "@apollo/client";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import CopyableTypography from "components/Typography/CopyableTypography";
import { gql } from "generated-graphql";
import { JobLog } from "generated-graphql/graphql";
import { compact } from "lodash";
import { useEffect } from "react";
import { prettyPrintDateTimeWithSeconds } from "util/dates";

const JOB_LOG_QUERY = gql(`
  query JobLogs($id: ID!) {
    webAutomationJob(id: $id) {
      id
      status
      logs {
        id
        jobId
        status
        createdAt
        updatedAt
        errorMessage
      }
      createdAt
      updatedAt
    }
  }
`);

type JobLogsProps = {
  id: string;
};

export function JobLogs({ id }: JobLogsProps) {
  const theme = useTheme();

  const { data, loading, error, startPolling, stopPolling } = useQuery(
    JOB_LOG_QUERY,
    {
      variables: { id },
    }
  );

  useEffect(() => {
    if (data?.webAutomationJob.status === "RUNNING") {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling, data?.webAutomationJob.status]);

  if (loading) {
    return (
      <Stack display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return <Typography>There was an error retrieving the logs.</Typography>;
  }

  const logs: JobLog[] = compact(data?.webAutomationJob?.logs ?? []);

  const columns: GridColDef<JobLog>[] = [
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 200,
      flex: 0.25,
    },
    {
      field: "createdAt",
      headerName: "Logged At",
      sortable: false,
      width: 150,
      flex: 0.25,
      renderCell: ({ row }) => (
        <span>{prettyPrintDateTimeWithSeconds(row.createdAt)}</span>
      ),
    },
    {
      field: "errorMessage",
      headerName: "Message",
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <CopyableTypography
          variant="body2"
          text={row.errorMessage}
          sx={{
            paddingY: theme.spacing(1),
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        />
      ),
    },
  ];

  if (!logs) {
    return <Typography>No logs found.</Typography>;
  }

  return (
    <DataGrid
      sortModel={[{ field: "createdAt", sort: "desc" }]}
      columns={columns}
      rows={logs}
      hideFooter
      getRowHeight={() => "auto"}
    />
  );
}
