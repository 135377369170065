import { BarChart } from "components/Dashboard/BarChart";
import { WasteGenerationQuery } from "generated-graphql/graphql";
import { PriorityChartColors } from "util/insights";

export type WasteGenerationGraphsProps = {
  graphType:
    | "hazardousWaste"
    | "hazardousWasteByMonth"
    | "highestGeneratingWasteStreams";
  data: WasteGenerationQuery | undefined;
};

export const WasteGenerationGraphs = ({
  graphType,
  data,
}: WasteGenerationGraphsProps) => {
  if (!data) return null;

  switch (graphType) {
    case "hazardousWaste":
      return <HazardousWaste data={data} />;
    case "hazardousWasteByMonth":
      return <HazardousWasteByMonth data={data} />;
    case "highestGeneratingWasteStreams":
      return <HighestGeneratingWasteStreams data={data} />;
    default:
      return null;
  }
};

const HazardousWaste = ({ data }: { data: WasteGenerationQuery }) => {
  return (
    <BarChart
      dataset={[{ name: "No data", total: 0 }]}
      series={[
        {
          type: "bar",
          dataKey: "total",
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "name",
          categoryGapRatio: 0.65,
          colorMap: {
            type: "ordinal",
            colors: PriorityChartColors,
          },
        },
      ]}
      yAxis={[{ scaleType: "linear" }]}
    />
  );
};

const HazardousWasteByMonth = ({ data }: { data: WasteGenerationQuery }) => {
  return (
    <BarChart
      dataset={[{ name: "No data", total: 0 }]}
      series={[
        {
          type: "bar",
          dataKey: "total",
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "name",
          categoryGapRatio: 0.65,
          colorMap: {
            type: "ordinal",
            colors: PriorityChartColors,
          },
        },
      ]}
      yAxis={[{ scaleType: "linear" }]}
    />
  );
};

const HighestGeneratingWasteStreams = ({
  data,
}: {
  data: WasteGenerationQuery;
}) => {
  return (
    <BarChart
      dataset={[{ name: "No data", total: 0 }]}
      series={[
        {
          type: "bar",
          dataKey: "total",
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "name",
          categoryGapRatio: 0.65,
          colorMap: {
            type: "ordinal",
            colors: PriorityChartColors,
          },
        },
      ]}
      yAxis={[{ scaleType: "linear" }]}
    />
  );
};
