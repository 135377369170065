import { useSuspenseQuery } from "@apollo/client";
import { gql } from "generated-graphql";

const WASTE_FACILITY_DETAILS = gql(`
  query WasteFacilityDetails(
    $id: ID!
  ) {
    facility(id: $id) {
      id
      name
      streetAddress1
      streetAddress2
      city
      state
      zip
      latitude
      longitude
      county
      facilityAlternateIds {
        id
        type
        value
        hasNoId
      }
      regulatoryStatus {
        rcraInfo
        rcraInfoHighestStatusThisYear
        eManifest
        vendor
      }
    }
  }
`);

export function useWasteFacility(facilityId?: string) {
  return useSuspenseQuery(WASTE_FACILITY_DETAILS, {
    variables: { id: facilityId ?? "" },
  });
}
