import { gql } from "generated-graphql";
import { LepcPickerFragment, LepcsQuery } from "generated-graphql/graphql";
import React, { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import EncampAutocomplete from "./Forms/EncampAutocomplete";

type Props = {
  facilityState?: string;
  facilityCounty?: string;
  onChange?: (value: LepcPickerFragment | null) => void;
  value?: LepcPickerFragment | null;
  disabled?: boolean;
} & ControllerFieldState;

gql(`
  fragment LepcPicker on Lepc {
    id
    name
    mailingAddress
    city
    state
    zip
    county
    phone
    email
    isEmailed
  }
`);

const LEPCS_QUERY = gql(`
  query Lepcs($state: String, $county: String) {
    lepcs(state: $state, county: $county) {
      items {
        ...LepcPicker
      }
      count
    }
  }
`);

export const LepcPicker: React.FC<Props> = forwardRef(function LepcPicker(
  { facilityState, facilityCounty, onChange, value, error, disabled },
  ref
) {
  return (
    <>
      <EncampAutocomplete
        query={LEPCS_QUERY}
        variables={{
          state: facilityState,
          county: facilityCounty,
        }}
        getItems={(data: LepcsQuery) => data.lepcs?.items ?? []}
        getOptionLabel={(fireDepartment) => {
          let label = `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.state}`;
          if (fireDepartment.county) {
            label = `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.county}, ${fireDepartment.state}`;
          }
          return label;
        }}
        onChange={onChange}
        value={value}
        error={error}
        disabled={disabled}
      />
    </>
  );
});
