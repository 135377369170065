import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { FacilityInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacility";
import { useCallback } from "react";
import { FacilityDetailsInput } from "routes/Customer/Facility/FacilityProfile";

const VALIDATE_REGULATORY_INPUT = gql(`
  query ValidateRegulatoryInput($id: ID!, $data: FacilityInput!, $reportId: ID, $omit: [String!], $pick: [String!]) {
    regulatoryInputValidator(id: $id, data: $data, reportId: $reportId, omit: $omit, pick: $pick) {
      ...issue
    }
  }
`);

export function useRegulatoryInputValidation({
  facilityId,
  reportId,
  omit,
  pick,
}: {
  facilityId: string;
  reportId: string | undefined;
  omit?: string[];
  pick?: string[];
}) {
  const [query] = useLazyQuery(VALIDATE_REGULATORY_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: FacilityDetailsInput) => {
      const inputToValidate: FacilityInput = transform(input);
      const { data, previousData } = await query({
        variables: {
          data: inputToValidate,
          id: facilityId,
          reportId,
          omit,
          pick,
        },
      });
      return data
        ? data?.regulatoryInputValidator
        : previousData?.regulatoryInputValidator ?? [];
    },
    [facilityId, omit, pick, query, reportId]
  );
}
