import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from "@mui/material";

interface DiffTableProps {
  baseRecord: any;
  comparisonRecord: any;
  fieldConstructors: Array<{
    fieldLabel: string;
    constructor: (record: any) => string;
  }>;
  diffMode: "updated" | "nodiff";
  firstCellWidth?: string;
}

export const DiffTable: React.FC<DiffTableProps> = ({
  baseRecord,
  comparisonRecord,
  fieldConstructors,
  diffMode,
  firstCellWidth = "33%",
}) => {
  const theme = useTheme();

  if (!baseRecord) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {fieldConstructors.map(({ fieldLabel, constructor }, index) => {
            const baseValue = constructor(baseRecord);
            const prevValue = comparisonRecord
              ? constructor(comparisonRecord)
              : "";
            const isDiff = diffMode === "updated" && baseValue !== prevValue;

            return (
              <TableRow key={fieldLabel}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontWeight: "bold",
                    border: `1px solid ${theme.palette.divider}`,
                    width: firstCellWidth,
                    ...(!isDiff && { borderRight: "none" }),
                  }}
                >
                  {fieldLabel}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    ...(isDiff && {
                      color: theme.palette.success.light,
                    }),
                  }}
                >
                  {isDiff ? (
                    <>
                      <span>Update: {baseValue}</span>
                      <div
                        style={{
                          color: theme.palette.error.light,
                          fontStyle: "italic",
                        }}
                      >
                        Previous: {prevValue}
                      </div>
                    </>
                  ) : (
                    baseValue
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
