import { Box, Grid, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import { mdHeight, xsHeight } from "../AppBar";
import { tabsOffset } from "../TabLinks";
import { blueGrey } from "@mui/material/colors";
import { useThemeMode } from "hooks/useThemeMode";

export function DashboardLayout(props: PropsWithChildren<unknown>) {
  const theme = useTheme();
  const { themeMode } = useThemeMode();
  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        background: blueGrey[themeMode === "light" ? 50 : 900],
        minHeight: {
          xs: `calc(100vh - ${theme.spacing(xsHeight + tabsOffset)})`,
          md: `calc(100vh - ${theme.spacing(mdHeight + tabsOffset)})`,
        },
      }}
    >
      <Grid container spacing={2}>
        {props.children}
      </Grid>
    </Box>
  );
}
