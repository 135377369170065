import { ProductInput } from "generated-graphql/graphql";
import { omit } from "lodash";
import { transform as transformProductChemical } from "./transformProductChemical";

export const transform = (input: ProductInput) => {
  return {
    ...omit(input, "issues"),
    productChemicals: input.productChemicals?.map((pc) =>
      transformProductChemical(pc)
    ),
  };
};
