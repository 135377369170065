import { useMeasurements } from "hooks/useMeasurements";
import { useProducts } from "hooks/useProducts";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

type HasFeatureProps = PropsWithChildren<{
  products?: boolean;
  measurements?: boolean;
  or?: boolean;
  and?: boolean;
  not?: boolean;
  facilityId?: string;
  fallback: string;
}>;

export function HasFeature({
  products,
  measurements,
  facilityId,
  fallback,
  or,
  and,
  not,
  children,
}: HasFeatureProps) {
  const { loading: productsLoading, hasProducts } = useProducts();
  const { loading: measurementsLoading, hasMeasurements } = useMeasurements(
    facilityId || ""
  );

  const checks = [];

  if (products !== undefined) {
    checks.push({ condition: hasProducts, loading: productsLoading });
  }

  if (measurements !== undefined) {
    checks.push({ condition: hasMeasurements, loading: measurementsLoading });
  }

  if (checks.some((check) => check.loading)) return <></>;

  const allConditionsMet = checks.every((check) => check.condition);
  const anyConditionMet = checks.some((check) => check.condition);

  let shouldRedirect = false;

  if (not) {
    if (and) {
      shouldRedirect = allConditionsMet;
    } else if (or) {
      shouldRedirect = anyConditionMet;
    } else {
      shouldRedirect = anyConditionMet;
    }
  } else {
    if (and) {
      shouldRedirect = !allConditionsMet;
    } else if (or) {
      shouldRedirect = !anyConditionMet;
    } else {
      shouldRedirect = !allConditionsMet;
    }
  }

  if (shouldRedirect) {
    return <Navigate to={fallback} replace />;
  }

  return <>{children}</>;
}
