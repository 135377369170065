import { useQuery } from "@apollo/client";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DataGrid } from "components/DataGrid";
import { gql } from "generated-graphql/gql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useState } from "react";

const WORKFLOW_MANAGER_CONFIG_QUERY = gql(`
  query WorkflowManagerConfigQuery {
    workflowManagerConfigs {
      items {
        id
        type
        value
        updatedAt
        updatedBy
      }
    }
  }
`);

export const WorkflowConfig = () => {
  useBreadcrumb({
    label: "Workflow Manager Configuration",
  });
  const { data, loading, error } = useQuery(WORKFLOW_MANAGER_CONFIG_QUERY);
  const alerts = useAlerts();
  const [sortModel, setSortModel] = useState<GridSortModel>();
  if (error) return <p>Error: ${error.message} </p>;

  const columns = [
    { field: "type", headerName: "Type", flex: 0.75 },
    { field: "value", headerName: "Value", flex: 0.5 },
    { field: "updatedAt", headerName: "Updated At", flex: 1 },
    { field: "updatedBy", headerName: "Updated By", flex: 1 },
  ];
  const onClickCopy = () => {
    navigator.clipboard.writeText(
      JSON.stringify(data?.workflowManagerConfigs.items, null, 2)
    );
    alerts.success("Copied workflow manager configuration to clipboard");
  };

  return (
    <Stack gap={2} direction={"column"} p={3}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Typography variant="body1">
          To modify or add configuration here, submit a ticket to{" "}
          <a
            href="https://encamp.slack.com/archives/C02EUTUBC1H"
            style={{ textDecoration: "none" }}
          >
            #engineering-support
          </a>
          {"."}
        </Typography>
        <Button variant="outlined" onClick={onClickCopy} disabled={loading}>
          Copy all as JSON
        </Button>
      </Stack>
      <Box component={Paper} sx={{ height: "90vh" }}>
        <DataGrid
          loading={loading}
          rows={data?.workflowManagerConfigs.items ?? []}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </Box>
    </Stack>
  );
};
