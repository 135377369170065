import { StorageType } from "encamp-shared/src/constants/tierii";
import { FacilityChemicalStorageLocationInput } from "generated-graphql/graphql";
import { v4 as uuid } from "uuid";

export function transform(
  storageLocation: FacilityChemicalStorageLocationInput
) {
  return {
    id: storageLocation.id === "NEW" ? uuid() : storageLocation.id,
    storageLocation: storageLocation.storageLocation ?? {
      description: "",
      facilityId: "",
      id: "",
    },
    pressure: storageLocation.pressure,
    otherPressureValue: storageLocation.otherPressureValue,
    storageType: storageLocation.storageType,
    storageTypeDescription:
      storageLocation.storageType === StorageType.Other
        ? storageLocation.storageTypeDescription
        : null,
    temperature: storageLocation.temperature,
    unit: storageLocation.unit,
    maxAmount: storageLocation.maxAmount
      ? Number(storageLocation.maxAmount)
      : null,
  };
}
