import Edit from "@mui/icons-material/Edit";
import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { FacilitiesExportButton } from "components/FacilitiesExportButton";
import { Importer } from "components/Importer";
import { ImportButtonWithDialog } from "components/Importer/ImportDialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { gql } from "generated-graphql";
import { FacilitiesQuery } from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

type Row = FacilitiesQuery["facilities"]["items"][number];

const FACILITIES = gql(`
  query Facilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        streetAddress1
        city
        state
        zip
        tenantId
      }
      count
    }
  }
`);

export const FacilitiesTable: React.FC = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const { isStaff } = useCurrentUser();

  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "facility",
        headerName: "Facility",
        flex: 0.5,
        filterKeyType: "facility",
        valueGetter: (params) => {
          const { name, customerFacilityId } = params.row;

          return `${name}${
            customerFacilityId ? ` (${customerFacilityId})` : ""
          }`;
        },
      },
      {
        field: "state",
        headerName: "State",
        flex: 0.125,
      },
      {
        field: "streetAddress1",
        headerName: "Street Address",
        flex: 0.5,
        sortable: true,
        valueGetter(params) {
          if (!params.row.streetAddress1) return "";

          const { streetAddress1, city, state, zip } = params.row;

          return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
        },
      },
      {
        field: "actions",
        type: "actions",
        resizable: false,
        sortable: false,
        getActions: (params) => [
          <Tooltip title="Edit Facility" key={1}>
            <GridActionsCellItem
              onClick={() => navigate(params.row.id)}
              label="Edit Facility"
              icon={<Edit />}
            />
          </Tooltip>,
        ],
      },
    ];
  }, [navigate]);

  useBreadcrumb([
    {
      label: "Facilities",
      to: `/o/${tenantId}/facilities`,
    },
  ]);

  const importExportButtons = useMemo(() => {
    const buttons = [
      <FacilitiesExportButton key="export" tenantId={tenantId} />,
    ];
    if (isStaff) {
      buttons.unshift(
        <ImportButtonWithDialog
          key="import"
          importers={[
            {
              name: "Facilities",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.Facility}
                  refetchQueries={["Facilities"]}
                />
              ),
            },
            {
              name: "Facility Alternate IDs",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.FacilityAlternateId}
                  refetchQueries={["Facilities"]}
                />
              ),
            },
          ]}
        />
      );
    }
    return buttons;
  }, [isStaff, tenantId]);

  return (
    <Box sx={{ pt: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={FACILITIES}
        initialSortModel={[
          { field: "state", sort: "asc" },
          { field: "facility", sort: "asc" },
        ]}
        defaultSearch={`tenantId:${tenantId}`}
        getItems={(data) => data.facilities.items}
        getCount={(data) => data.facilities.count}
        noDataMessage="No facilities have been added to this organization yet."
        onRowClick={({ row }) => navigate(row.id)}
        commandButtons={importExportButtons}
        initialPageSize={50}
        showFavorites
        additionalFilterColumns={[
          { header: "Facility ID", key: "customerFacilityId" },
          { header: "City", key: "city" },
          { header: "Zip", key: "zip" },
          { header: "Tag", key: "tags" },
        ]}
      />
    </Box>
  );
};
