import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { gql } from "generated-graphql/gql";
import { REPORTS_QUERY, ReportRow } from ".";
import { useAlerts } from "components/Alerts/AlertProvider";

const CLOSE_REPORT_MUTATION = gql(`
  mutation CloseTierIIReport($tierIIReportId: ID!) {
    closeTierIIReport(tierIIReportId: $tierIIReportId)
  }
`);

export const CloseReportDialog = ({
  onClose,
  report,
}: {
  onClose: () => void;
  report: ReportRow;
}) => {
  const alerts = useAlerts();
  const [mutate, { loading }] = useMutation(CLOSE_REPORT_MUTATION, {
    variables: { tierIIReportId: report.tierIIReportId ?? "" },
    refetchQueries: [REPORTS_QUERY],
    onCompleted(data) {
      if (data.closeTierIIReport) {
        alerts.success("Report closed successfully.");
      } else {
        alerts.error("This report could not be closed.");
      }
      onClose();
    },
    onError(err) {
      alerts.error("An error occurred while closing the report.");
    },
  });
  if (report.tierIIReportId) {
    return (
      <Dialog open={true}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography>
              Are you sure you want to close this report? This action cannot be
              undone. We will not delete any facility or chemical data, but we
              will delete Assignee/Reviewer information from this report.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => mutate()}
            loading={loading}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
};
