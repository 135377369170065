import {
  Box,
  List,
  ListItem,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import React, { ReactNode, useState } from "react";

const menuWidth = 21;

interface SectionProps {
  title: string;
  content: ReactNode;
  id: string;
}

const Section: React.FC<SectionProps> = ({ title, content, id }) => {
  const theme = useTheme();
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[900];
  return (
    <Box
      id={id}
      style={{
        scrollSnapAlign: "start",
        marginLeft: theme.spacing(menuWidth),
        borderRight: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box height={theme.spacing(6)} sx={{ backgroundColor: backgroundColor }}>
        <Typography
          variant="h5"
          marginLeft={theme.spacing(3)}
          height="100%"
          paddingTop={theme.spacing(1)}
          color={theme.palette.text.primary}
        >
          {title}
        </Typography>
      </Box>
      {content}
    </Box>
  );
};

const Menu: React.FC<{
  sections: SectionProps[];
  activeSection: string;
  onMenuItemClick: (id: string) => void;
  sx?: SxProps;
}> = ({ sections, activeSection, onMenuItemClick, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: theme.spacing(menuWidth),
        position: "fixed",
        height: "100%",
        overflow: "auto",
        backgroundColor: theme.palette.background.default,
        borderRight: `1px solid ${theme.palette.divider}`,
        ...sx,
      }}
    >
      <List sx={{ padding: 0 }}>
        {sections.map((section) => (
          <ListItem
            key={section.id}
            onClick={() => onMenuItemClick(section.id)}
            sx={{
              height: theme.spacing(6),
              fontWeight: activeSection === section.id ? "bold" : "normal",
              backgroundColor:
                activeSection === section.id
                  ? theme.palette.action.selected
                  : theme.palette.background.paper,
              color: theme.palette.text.primary,
              padding: 0,
              margin: 0,
              "&:hover": {
                cursor: "pointer",
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <Typography
              variant="body1"
              color="primary"
              paddingLeft={theme.spacing(3)}
            >
              {section.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export function Scroller(props: { sections: SectionProps[]; sx?: SxProps }) {
  const { sections } = props;
  const [activeSection, setActiveSection] = useState<string>(sections[0].id);

  const handleMenuItemClick = (id: string) => {
    setActiveSection(id); // Update active section immediately on click
    const offset = 145;
    const section = document.getElementById(id);
    const topPosition = section
      ? section.getBoundingClientRect().top + window.pageYOffset - offset
      : 0;
    window.scrollTo({ top: topPosition, behavior: "smooth" });
  };

  return (
    <>
      <Menu
        sections={sections}
        onMenuItemClick={handleMenuItemClick}
        activeSection={activeSection}
        sx={props.sx}
      />
      <Box sx={props.sx}>
        {sections.map((section) => (
          <Section key={section.id} {...section} />
        ))}
      </Box>
    </>
  );
}
