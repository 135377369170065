import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  TextField,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import { gql } from "generated-graphql";
import { StorageLocationPickerFragment } from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { v4 } from "uuid";
import { ErrorDisplay } from "./Forms/ErrorDisplay";

gql(`
  fragment StorageLocationPicker on StorageLocation {
    id
    description
    facilityId
    CA_mapNumber
    CA_gridNumber
    OR_insideOutside
    OR_storageArea
    OR_storageBuilding
    OR_storageFloor
    OR_storageQuadrant
    OR_storageRoom
  }
`);

const objectIsValidStorageLocation = (
  obj: any
): obj is { id: string; facilityId: string; description: string } =>
  typeof obj === "object" &&
  "id" in obj &&
  "facilityId" in obj &&
  "description" in obj;

const STORAGE_LOCATIONS_QUERY = gql(`
  query StorageLocations($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    storageLocations(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...StorageLocationPicker
      }
      count
    }
  }
`);

type OptionType = StorageLocationPickerFragment & {
  inputValue?: string;
};

interface StorageLocationPickerProps {
  facilityId: string;
  onSelectLocation: (location: StorageLocationPickerFragment) => void;
  defaultSearchTerm?: string;
  disabledLocationsById?: string[];
  disabledOptionLabel?: string;
  description?: StorageLocationPickerFragment["description"];
  error?: FieldError;
  disabled?: boolean;
  disableClearable?: boolean;
  required?: boolean;
  tooltip?: React.ReactNode;
}

const initNewStorageLocation = (
  facilityId: string,
  description: string,
  id: string
) => ({
  id,
  facilityId,
  description,
  OR_insideOutside: null,
  OR_storageArea: "",
  OR_storageBuilding: "",
  OR_storageFloor: "",
  OR_storageQuadrant: null,
  OR_storageRoom: "",
});

export const StorageLocationPicker: React.FC<StorageLocationPickerProps> = ({
  facilityId,
  onSelectLocation,
  defaultSearchTerm = "",
  description,
  error,
  disabled,
  required,
  disableClearable,
  tooltip,
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState(description ?? "");
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [options, setOptions] = useState<StorageLocationPickerFragment[]>([]);
  const [debouncedSearch] = useDebounce(
    `facilityId:${facilityId} ${defaultSearchTerm} ${search}`,
    200
  );
  const [newStorageLocation, setNetStorageLocation] =
    useState<StorageLocationPickerFragment | null>(null);

  const { data, loading, previousData } = useQuery(STORAGE_LOCATIONS_QUERY, {
    variables: {
      search: debouncedSearch,
      page: 0,
      pageSize: 5,
    },
    skip: !searchIsOpen,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setSearch(description ?? "");
  }, [description]);

  useEffect(() => {
    if (data?.storageLocations.items) setOptions(data?.storageLocations.items);
    else if (previousData?.storageLocations.items)
      setOptions(previousData?.storageLocations.items);
  }, [
    setOptions,
    data?.storageLocations.items,
    previousData?.storageLocations.items,
  ]);

  return (
    <>
      <Autocomplete<OptionType, false, typeof disableClearable, true>
        loading={loading}
        disableClearable={disableClearable}
        disabled={disabled}
        value={description}
        isOptionEqualToValue={(o, v) => o.description === v.description}
        onBlur={(e) => {
          const description = (e.target as HTMLInputElement).value;
          if (!description.length) {
            onSelectLocation(initNewStorageLocation(facilityId, "", ""));
            return;
          }

          let existingStorageLocation = options.find(
            (o) => o.description === description
          );

          if (!existingStorageLocation) {
            existingStorageLocation = options.find(
              (o) => o.description?.toLowerCase() === description.toLowerCase()
            );
          }

          const selectedNewStorageLocation =
            description === newStorageLocation?.description;

          if (!(selectedNewStorageLocation || existingStorageLocation)) {
            setSearch("");
            return;
          }

          if (existingStorageLocation) {
            onSelectLocation(existingStorageLocation);
            setSearch(existingStorageLocation.description ?? "");
            return;
          }

          if (selectedNewStorageLocation) {
            onSelectLocation(newStorageLocation);
            setSearch(newStorageLocation.description ?? "");
          }
        }}
        onChange={(_, newValue, reason) => {
          if (reason === "clear") {
            onSelectLocation(initNewStorageLocation(facilityId, "", ""));
            return;
          }

          if (
            reason === "selectOption" &&
            objectIsValidStorageLocation(newValue)
          ) {
            const existingStorageLocation = options.find(
              (o) => o.description === newValue?.description
            );

            onSelectLocation(
              existingStorageLocation ?? {
                description: newValue.description,
                id: newValue.id,
                facilityId: newValue.facilityId,
              }
            );

            if (!existingStorageLocation) {
              setNetStorageLocation(
                initNewStorageLocation(
                  newValue.facilityId,
                  newValue.description,
                  newValue.id
                )
              );
            }
          }
        }}
        inputValue={search}
        onInputChange={(_, newInputValue, reason) => {
          if (reason !== "reset") {
            setSearch(newInputValue);
          }
        }}
        options={options}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          const filtered = options.filter(
            (o) =>
              (o.description ?? "")
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0
          );
          const isExisting = filtered.some(
            (option) =>
              inputValue.toLowerCase() === option.description?.toLowerCase()
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              facilityId,
              id: v4(),
              description: inputValue,
              inputValue: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        onOpen={() => setSearchIsOpen(true)}
        onClose={() => setSearchIsOpen(false)}
        noOptionsText="Search for a storage location"
        getOptionLabel={(option: OptionType | string) => {
          if (typeof option === "string") {
            return option;
          }

          if (option.inputValue) {
            return option.inputValue;
          }

          return option.description ?? "";
        }}
        renderOption={(props, option) => (
          <li {...props} key={`${option.id}`}>
            {option.inputValue ?? option.description}
          </li>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={!!error}
              label="Storage Location"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {tooltip && (
                      <Tooltip title={tooltip}>
                        <IconButton
                          style={{
                            color: theme.palette.info.dark,
                            opacity: 0.8,
                          }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              required={required}
            />
          );
        }}
      />
      <ErrorDisplay error={error} />
    </>
  );
};
