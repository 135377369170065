/**
 * Maps Encamp's standard Program Areas to Statute Codes used in ECHO. Note TRI
 * will have statute code:EPCRA with lawSectionCode:313. Use the `statuteCodeToProgramArea(...)`
 * function to get the program area accurately. DON'T change order of the array; TRI must be before Chemicals.
 */
export const programAreaToStatuteCodes = new Map<string, string[]>([
  ["Air", ["CAA"]],
  ["TRI", ["EPCRA"]],
  ["Chemicals", ["FIFRA", "TSCA", "EPCRA"]],
  ["Waste", ["CERCLA", "MWTA", "RCRA", "MCRBMA", "MRBMA"]],
  ["Water", ["CWA", "MPRSA", "SDWA", "REFA", "OPA"]],
]);

const statuteCodeToProgramAreaMap = Array.from(
  programAreaToStatuteCodes.entries()
).reduce(
  (acc, [programArea, statuteCodes]) =>
    statuteCodes.reduce(
      (innerAcc, statuteCode) => innerAcc.set(statuteCode, programArea),
      acc
    ),
  new Map<string, string>()
);

/**
 * Maps ECHO statueCodes/lawSectionCodes to encamp programAreas
 * @param statuteCode string
 * @param @optional lawSectionCode string
 * @returns string encamp program area
 */
export function statuteCodeToProgramArea(
  statuteCode: string,
  lawSectionCode?: string
) {
  if (statuteCode === "EPCRA" && lawSectionCode === "313") {
    return "TRI";
  }

  const programArea = statuteCodeToProgramAreaMap.get(statuteCode) || "Unknown";
  return programArea;
}
