import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CopyableTypography from "components/Typography/CopyableTypography";
import { useReportDetails } from "./useReportDetails";

export function Agencies() {
  const theme = useTheme();
  const { data } = useReportDetails();

  const lepc = data?.tierIIReport.facility.lepc;
  const fireDepartment = data?.tierIIReport.facility.fireDepartment;

  return (
    <Stack
      direction="column"
      component={Paper}
      sx={{ border: `1px solid ${theme.palette.divider}` }}
      spacing={2}
      padding={theme.spacing(1)}
    >
      <Typography variant="h6">LEPC</Typography>
      <Stack direction="row">
        <List sx={{ flex: 1 }}>
          <ListItem>
            <ListItemText secondary="LEPC ID">
              <CopyableTypography text={renderString(lepc?.id)} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Name">
              <CopyableTypography text={renderString(lepc?.name)} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Is mailed">
              {renderBoolean(lepc?.isMailed)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Is emailed">
              {renderBoolean(lepc?.isEmailed)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Needs T2S file">
              {renderBoolean(lepc?.needsT2sFile)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="State submits to LEPC">
              {renderBoolean(lepc?.isStateSubmits)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Additional comments">
              <CopyableTypography
                text={renderString(lepc?.additionalComments)}
              />
            </ListItemText>
          </ListItem>
        </List>
        <List sx={{ flex: 1 }}>
          <ListItem>
            <ListItemText secondary="Email">
              <CopyableTypography text={renderString(lepc?.email)} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Phone">
              <CopyableTypography text={renderString(lepc?.phone)} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="City">
              <CopyableTypography text={renderString(lepc?.city)} />
            </ListItemText>
            <ListItemText secondary="State">
              <CopyableTypography text={renderString(lepc?.state)} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Mailing Address">
              <CopyableTypography text={renderString(lepc?.mailingAddress)} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Mailing Address Line 2">
              <CopyableTypography
                text={renderString(lepc?.mailingAddressLine2)}
              />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Zip Code">
              <CopyableTypography text={renderString(lepc?.zip)} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="County">
              <CopyableTypography text={renderString(lepc?.county)} />
            </ListItemText>
          </ListItem>
        </List>
      </Stack>

      <Typography variant="h6">Fire Department</Typography>
      <Stack direction="row">
        <List sx={{ flex: 1 }}>
          <ListItem>
            <ListItemText secondary="Fire Dept ID">
              <CopyableTypography text={renderString(fireDepartment?.id)} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Name">
              <CopyableTypography text={renderString(fireDepartment?.name)} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Is mailed">
              {renderBoolean(fireDepartment?.isMailed)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Is emailed">
              {renderBoolean(fireDepartment?.isEmailed)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Needs T2S file">
              {renderBoolean(fireDepartment?.needsT2sFile)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="State submits to fire dept">
              {renderBoolean(fireDepartment?.stateSubmitsToFireDept)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Additional comments">
              <CopyableTypography
                text={renderString(fireDepartment?.additionalComments)}
              />
            </ListItemText>
          </ListItem>
        </List>
        <List sx={{ flex: 1 }}>
          <ListItem>
            <ListItemText secondary="Email">
              <CopyableTypography text={renderString(fireDepartment?.email)} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Phone">
              <CopyableTypography text={renderString(fireDepartment?.phone)} />
            </ListItemText>
            <ListItemText secondary="Ext">
              <CopyableTypography
                text={renderString(fireDepartment?.phoneExt)}
              />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="City">
              <CopyableTypography text={renderString(fireDepartment?.city)} />
            </ListItemText>
            <ListItemText secondary="State">
              <CopyableTypography text={renderString(fireDepartment?.state)} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Mailing Address">
              <CopyableTypography
                text={renderString(fireDepartment?.mailingAddress)}
              />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Mailing Address Line 2">
              <CopyableTypography
                text={renderString(fireDepartment?.mailingAddressLine2)}
              />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Zip Code">
              <CopyableTypography text={renderString(fireDepartment?.zip)} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="County">
              <CopyableTypography text={renderString(fireDepartment?.county)} />
            </ListItemText>
          </ListItem>
        </List>
      </Stack>
    </Stack>
  );
}

function renderString(v: string | undefined | null) {
  return v ? v : "N/A";
}

function renderBoolean(v: boolean | undefined | null) {
  return v ? "Yes" : "No";
}
