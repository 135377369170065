import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";

import { DELETE_LEPC, GET_LEPC, LEPC_SEARCH } from "./api";

export function ConfirmDeleteLEPC({
  lepcId,
  onClose,
}: {
  lepcId: string;
  onClose: () => void;
}) {
  const alerts = useAlerts();

  const { data, previousData } = useQuery(GET_LEPC, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: lepcId,
    },
  });

  const [deleteLepc, { loading: isDeleting }] = useMutation(DELETE_LEPC);

  const lepc = data?.lepc ?? previousData?.lepc;

  const modalContent = (
    <Stack gap={2}>
      <Typography>Are you sure you want to delete this LEPC?</Typography>
    </Stack>
  );

  return lepc ? (
    <ConfirmDialog
      open={true}
      msg={modalContent}
      title="Confirm Delete"
      confirmText="Delete"
      loading={isDeleting}
      onClose={onClose}
      onConfirm={() =>
        deleteLepc({
          variables: { id: lepcId },
          onCompleted() {
            onClose();
            alerts.success("LEPC deleted successfully!");
          },
          onError(e) {
            alerts.error("There was an error deleting the LEPC:", e);
          },
          refetchQueries: [LEPC_SEARCH],
        })
      }
    />
  ) : null;
}
