import Download from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import { Stack, useTheme } from "@mui/material";
import { reportKindToLabel } from "util/constants";
import { StepperPageHeader } from "../StepperPageHeader";
import { useDownloadReportPreview } from "../useDownloadReportPreview";
import { useReport } from "../useReport";
import { EncampStatusStepper } from "./EncampStatusStepper";
import { SubmissionRecords } from "./SubmissionRecords";

export const Submitted: React.FC = () => {
  const theme = useTheme();
  const { data: report } = useReport();
  const { downloadReportPreview, loadingPreview } = useDownloadReportPreview();

  const description = `Your ${report?.tierIIReport.reportingYear} 
  ${
    report?.tierIIReport.reportKind
      ? reportKindToLabel(report?.tierIIReport.reportKind).toLowerCase()
      : ""
  } 
  report has been submitted to Encamp and is being verified and processed.`;

  return (
    <Stack sx={{ p: theme.spacing(2), mt: theme.spacing(1) }}>
      <StepperPageHeader
        header="Congratulations!"
        description={description}
        headerButton={
          <LoadingButton
            startIcon={<Download />}
            color="primary"
            size="small"
            onClick={() => downloadReportPreview(report?.tierIIReport.id ?? "")}
            loading={loadingPreview}
          >
            Download Preview
          </LoadingButton>
        }
      />
      {report && (
        <>
          <EncampStatusStepper status={report?.tierIIReport.encampStatus} />
          <SubmissionRecords report={report.tierIIReport} />
        </>
      )}
    </Stack>
  );
};
