import { useFeatureFlags } from "./useFeatureFlags";

/**
 * @returns loading and boolean indicating enabled feature flag for countable items
 */
export function useProducts(): {
  loading: boolean;
  hasProducts: boolean | undefined;
} {
  const { featureFlags, loading } = useFeatureFlags();
  return { loading, hasProducts: featureFlags?.["countable-items-ui"] };
}
