import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { UserInput } from "generated-graphql/graphql";
import { useCallback } from "react";

const VALIDATE_USER_INPUT = gql(`
  query ValidateUserInput($input: UserInput!) {
    userInputValidator(input: $input) {
      ...issue
    }
  }
`);

export function useUserInputValidation() {
  const [query] = useLazyQuery(VALIDATE_USER_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: UserInput) => {
      const transformedInput = input;
      const { data: result, previousData } = await query({
        variables: { input: transformedInput },
      });

      return result
        ? result?.userInputValidator
        : previousData?.userInputValidator ?? [];
    },
    [query]
  );
}
