import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getChemicalsBreadcrumb,
  getChemicalsContactsBreadcrumb,
} from "util/breadcrumb";
import { gql } from "../../../../generated-graphql";
import { ContactsFacilitiesQuery } from "../../../../generated-graphql/graphql";
import { useBreadcrumb } from "../../../../hooks/useBreadcrumbs";

type Row = ContactsFacilitiesQuery["facilities"]["items"][0];

const CONTACTS_FACILITIES = gql(`
  query ContactsFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        collectionMode
        streetAddress1
        city
        state
        issues {
          key
          modelName
        }
      }
      count
    }
  }
`);

export function ContactsFacilitiesTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();

  useBreadcrumb([
    {
      label: "Chemicals",
      to: tenantId ? getChemicalsBreadcrumb(tenantId) : undefined,
    },
    {
      label: "Contacts",
      to: tenantId ? getChemicalsContactsBreadcrumb(tenantId) : undefined,
    },
  ]);

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: "facility",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 0.5,
        valueGetter(params) {
          return params.row.name;
        },
      },
      {
        field: "alternateId",
        headerName: "Alternate ID",
        flex: 0.25,
        valueGetter(params) {
          return params.row.customerFacilityId;
        },
      },
      {
        field: "streetAddress1",
        headerName: "Street Address",
        flex: 0.5,
        sortable: false,
      },
      {
        field: "city",
        headerName: "City",
        flex: 0.3,
      },
      {
        field: "state",
        headerName: "State",
        flex: 0.25,
      },
      {
        field: "issues",
        headerName: "Contact Issues",
        flex: 0.3,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row: { issues } }) => {
          const contactIssues = issues.filter(
            (i) => i.key === "contacts" || i.modelName === "Person"
          );
          return <IssueCount issueCount={contactIssues.length} />;
        },
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.2,
        getActions: (params) => [
          <Tooltip title="View facility" key={1}>
            <GridActionsCellItem
              onClick={() => navigate(params.row.id)}
              label="Show Contacts"
              icon={<ArrowCircleRightIcon />}
            />
          </Tooltip>,
        ],
      },
    ],
    [navigate]
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ pt: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={CONTACTS_FACILITIES}
        defaultSearch={`tenantId:${tenantId}`}
        getItems={(data) => data.facilities.items}
        getCount={(data) => data.facilities.count}
        columnVisibilityModel={{
          alternateId: !isSmallScreen,
          streetAddress: !isSmallScreen,
          city: !isSmallScreen,
        }}
        initialSortModel={[{ field: "facility", sort: "asc" }]}
        noDataMessage="No facilities have been added to this organization yet."
        onRowClick={({ row }) => navigate(row.id)}
      />
    </Box>
  );
}
