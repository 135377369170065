import { useSuspenseQuery } from "@apollo/client";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { StatDisplay } from "components/StatDisplay";
import { gql } from "generated-graphql";
import { UnitType } from "generated-graphql/graphql";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { prettyPrintQuantity } from "util/unit";
import {
  WasteGenerationGraphs,
  WasteGenerationGraphsProps,
} from "./WasteGenerationGraphs";

//  TODO change this to actually fetch waste generation data when available
const WASTE_GENERATION = gql(`
  query WasteGeneration($facilityId: ID!) {
    facility(id: $facilityId) {
      id
      name
    }
  }
`);

const graphVariations: Record<
  WasteGenerationGraphsProps["graphType"],
  { label: string; selectLabel: string }
> = {
  hazardousWaste: {
    label: "Hazardous waste generation in lbs",
    selectLabel: "Hazardous waste generation",
  },
  hazardousWasteByMonth: {
    label: "Waste generation by month in lbs",
    selectLabel: "Waste generation by month",
  },
  highestGeneratingWasteStreams: {
    label: "Highest generating waste streams in lbs",
    selectLabel: "Highest generating waste streams",
  },
};

export const WasteGenerationTile = () => {
  const theme = useTheme();

  const { facilityId } = useParams<{ facilityId: string }>();
  const [graphType, setGraphType] =
    useState<WasteGenerationGraphsProps["graphType"]>("hazardousWaste");

  const { data } = useSuspenseQuery(WASTE_GENERATION, {
    variables: { facilityId: facilityId ?? "" },
  });

  return (
    <DashboardTile>
      <DashboardTile.Header title="Waste Generation, last 12 months">
        <FormControl sx={{ width: theme.spacing(32.5) }}>
          <InputLabel id="view-by">View by</InputLabel>
          <Select
            sx={{ height: theme.spacing(5) }}
            fullWidth
            labelId="view-by"
            label="View by"
            value={graphType}
            onChange={(event) =>
              setGraphType(
                event.target.value as WasteGenerationGraphsProps["graphType"]
              )
            }
          >
            {Object.entries(graphVariations).map(([key, { selectLabel }]) => (
              <MenuItem key={key} value={key}>
                {selectLabel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DashboardTile.Header>

      {graphType === "hazardousWaste" && (
        <DashboardTile.ContentArea xs={12} lg={2} display="flex" gap={5}>
          <Stack
            pl={{ xs: 0, lg: 2 }}
            gap={2}
            direction={{ xs: "row", lg: "column" }}
          >
            <StatDisplay
              label="Total waste"
              value={prettyPrintQuantity(453, UnitType.Pounds, true)}
            />
            <StatDisplay label="Highest month" value={"Jan 2022"} />
          </Stack>
        </DashboardTile.ContentArea>
      )}

      {graphType === "hazardousWasteByMonth" && (
        <DashboardTile.ContentArea xs={12} lg={2} display="flex" gap={5}>
          <Stack
            pl={{ xs: 0, lg: 2 }}
            gap={2}
            direction={{ xs: "row", lg: "column" }}
          >
            <StatDisplay
              label="Total non-hazardous waste"
              value={prettyPrintQuantity(152, UnitType.Pounds, true)}
            />
            <StatDisplay
              label="Total hazardous waste"
              value={prettyPrintQuantity(453, UnitType.Pounds, true)}
            />
            <StatDisplay
              label="Total universal waste"
              value={prettyPrintQuantity(146, UnitType.Pounds, true)}
            />
          </Stack>
        </DashboardTile.ContentArea>
      )}

      <DashboardTile.ContentArea
        container
        xs={12}
        lg={graphType === "highestGeneratingWasteStreams" ? 12 : 10}
      >
        <Grid item xs={12}>
          <Typography>{graphVariations[graphType].label}</Typography>
        </Grid>
        <Grid item xs={12} height={theme.spacing(34)}>
          <WasteGenerationGraphs graphType={graphType} data={data} />
        </Grid>
      </DashboardTile.ContentArea>
    </DashboardTile>
  );
};
