import { gql } from "generated-graphql";

export const GET_ALL_NJ_POLICE_DEPARTMENTS = gql(`
  query policeDepartments($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String) {
    policeDepartments(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        id,              
        address,           
        name,              
        additionalComments,              
        attn,              
        city,             
        state,             
        zip,             
        email,             
        contactName,             
      }
      count
    }
  }
`);

export const GET_NJ_POLICE_DEPARTMENT = gql(`
  query policeDepartment($id: Int!) {
    policeDepartment(id: $id) {
        id,              
        address,           
        name,              
        additionalComments,              
        attn,              
        city,             
        state,             
        zip,             
        email,             
        contactName,     
        issues {
          ...issue
        }             
    }
  }
`);

export const UPSERT_POLICE_DEPARTMENT = gql(`
  mutation upsertPoliceDepartment($id: Int, $input: PoliceDepartmentInput!) {
    upsertPoliceDepartment(id: $id, input: $input) {
        id,              
        address,           
        name,              
        additionalComments,              
        attn,              
        city,             
        state,             
        zip,             
        email,             
        contactName,           
    }
  }
`);

export const DELETE_POLICE_DEPARTMENT = gql(`
  mutation deletePoliceDepartment($id: Int!) {
    deletePoliceDepartment(id: $id) {
      id
    }
  }
`);
