import { Box, Button, Typography } from "@mui/material";
import { Layout } from "components/Layout";
import { useNavigate } from "react-router-dom";
import { useCustomerNavItems } from "./Customer";

export function NotFound() {
  const { navItems } = useCustomerNavItems();
  const navigate = useNavigate();

  const goBack = () => {
    // There's a chance the preLoginPath got them here, so we'll clear it before
    // it tries it, again.
    localStorage.removeItem("preLoginPath");
    navigate("/");
  };

  return (
    <Layout navItems={navItems}>
      <Typography
        variant="h3"
        align="center"
        sx={{
          mt: 10,
          mb: 5,
        }}
      >
        Oops! It seems like we've gotten lost.
      </Typography>
      <Typography variant="h4" align="center" sx={{ mb: 2 }}>
        Don't worry. We'll get back on the trail.{" "}
      </Typography>

      <Typography variant="body1" align="center" sx={{ mb: 2 }}>
        Somewhere along the way, we got a little turned around. Ready to head
        back?
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" type="button" onClick={goBack}>
          Head Back
        </Button>
      </Box>
    </Layout>
  );
}
