import QuestionMark from "@mui/icons-material/QuestionMark";
import { Box, Stack, Tooltip, useTheme } from "@mui/material";

export function EhsFormTooltip() {
  const theme = useTheme();
  return (
    <Tooltip
      sx={{
        marginTop: 2,
        marginLeft: 2,
        color: theme.palette.info.main,
      }}
      title={
        <Stack spacing={2} padding={2}>
          <Box>
            NONE: Most common scenario. Select this when none of the below
            scenarios apply.
          </Box>
          <Box>
            SOLUTION: The EHS would be a solid under standard conditions, but is
            stored dissolved, suspended or emulsified in a liquid.
          </Box>
          <Box>
            MOLTEN: The EHS would be a solid under standard conditions, but is
            stored as a liquid at an elevated temperature.
          </Box>
          <Box>
            PARTICLE SIZE {"<"}100 MICRONS: The EHS is a solid under standard
            conditions and stored in powder form, with a particle size less than
            100 microns.
          </Box>
        </Stack>
      }
    >
      <QuestionMark />
    </Tooltip>
  );
}
