// routes/Customer/Insights/Overview.tsx
import { useQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DefaultizedPieValueType, axisClasses } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
import DonutChart from "components/Dashboard/DonutChart";
import { gql } from "generated-graphql";
import { Counts } from "generated-graphql/graphql";
import invariant from "invariant";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CohortSelector from "./CohortSelector";
import IndustrySelector from "./IndustrySelector";

const ECHO_OVERVIEW = gql(`
  query EchoOverview($tenantId: String!, $filterTenantId: String, $naicsCode: String, $cohortIds: [String!]) {
    echoOverview(tenantId: $tenantId, filterTenantId: $filterTenantId, naicsCode: $naicsCode, cohortIds: $cohortIds) {
      stats {
        totalFacilityCount
        activeFacilityCount
        airFacilityCount
        airSignificantViolatorCount
        airViolatorCount
        airComplianceStatusCounts {
          countKey
          count
        }
        airPermitTypeCounts {
          countKey
          count
        }
        waterFacilityCount
        waterViolatorCount
        waterSignificantViolatorCount
        waterComplianceStatusCounts {
          countKey
          count
        }
        waterPermitTypeCounts {
          countKey
          count
        }
        wasteFacilityCount
        wasteViolatorCount
        wasteSignificantViolatorCount
        wasteComplianceStatusCounts {
          countKey
          count
        }
        wastePermitTypeCounts {
          countKey
          count
        }
      }
      history {
        quarter
        program
        significantNoncompliance
        violation
        noViolation
        unknown
      }
    }
  }
`);

const FACILITIES = gql(`
  query FacilityCount($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
    }
  }
`);

type HistoryAccumulatorItem = {
  quarter: string;
  [key: string]: string | number;
};

function formatPieChartLabel(label: string, maxLength: number) {
  let formattedLabel = "";
  let remainingLabel = label;

  while (remainingLabel.length > maxLength) {
    let lastSpaceIndex = remainingLabel.slice(0, maxLength).lastIndexOf(" ");
    if (lastSpaceIndex === -1) lastSpaceIndex = maxLength;
    formattedLabel += remainingLabel.slice(0, lastSpaceIndex) + "\n";
    remainingLabel = remainingLabel.slice(lastSpaceIndex).trim();
  }

  formattedLabel += remainingLabel;
  return formattedLabel;
}

export function LoadingState() {
  return (
    <Stack p={3} spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {[...Array(3)].map((_, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={400} />
        </Grid>
      </Grid>
    </Stack>
  );
}

function ErrorState({ message }: { message: string }) {
  return <Typography>Error: {message}</Typography>;
}

function EmptyState() {
  return <Typography>No data available</Typography>;
}

export function Overview() {
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId, "tenantId is required");
  const [comparisonType, setComparisonType] = useState<"industry" | "cohort">(
    "industry"
  );
  const [naicsCode, setNaicsCode] = useState("");
  const [selectedCohortIds, setSelectedCohortIds] = useState<string[]>([]);
  const theme = useTheme();

  const chartSetting = {
    yAxis: [
      {
        label: "Count",
      },
    ],

    height: 400,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  const valueFormatter = (value: number | null) => `${value}`;

  const { data: facilitiesData } = useQuery(FACILITIES, {
    variables: {
      search: `tenantId:${tenantId}`,
      page: 1,
      pageSize: 1, // We only need the count
    },
  });

  const tenantFacilitiesCount = facilitiesData?.facilities?.count ?? 0;

  const { data, loading, error } = useQuery(ECHO_OVERVIEW, {
    variables: {
      tenantId: tenantId || "",
      filterTenantId: tenantId || "",
    },
  });

  const {
    data: sectorData,
    loading: sectorLoading,
    refetch: refetchSectorOverview,
  } = useQuery(ECHO_OVERVIEW, {
    variables: {
      naicsCode: comparisonType === "industry" ? naicsCode || "" : "",
      cohortIds: comparisonType === "cohort" ? selectedCohortIds || [] : [],
      tenantId: tenantId || "",
    },
  });

  const handleComparisonSubmit = (value: string | string[]) => {
    if (comparisonType === "industry") {
      setNaicsCode(value as string);
    } else {
      setSelectedCohortIds(value as string[]);
    }
    refetchSectorOverview();
  };

  const handlePieChartClick = (
    program: string,
    data: { label: string; type: "compliance" | "permit" }
  ) => {
    let searchString = "";
    if (data.type === "compliance") {
      const complianceField = {
        air: "caaComplianceStatus",
        water: "cwaComplianceStatus",
        waste: "rcraComplianceStatus",
      }[program];
      searchString = ` ${complianceField}:"${data.label}"`;
    } else if (data.type === "permit") {
      const permitField = {
        air: "caaPermitTypes",
        water: "cwaPermitTypes",
        waste: "rcraPermitTypes",
      }[program];
      searchString = `${permitField}:"${data.label}"`;
    }

    const params = new URLSearchParams({
      omnisearch: searchString,
    });
    navigate(`/o/${tenantId}/insights/echo?${params.toString()}`);
  };

  const mainOverviewStats = data?.echoOverview?.stats ?? {};
  const {
    airFacilityCount: mainAirFacilityCount,
    airViolatorCount: mainAirViolatorCount,
    airSignificantViolatorCount: mainAirSignificantViolatorCount,
    waterFacilityCount: mainWaterFacilityCount,
    waterViolatorCount: mainWaterViolatorCount,
    waterSignificantViolatorCount: mainWaterSignificantViolatorCount,
    wasteFacilityCount: mainWasteFacilityCount,
    wasteViolatorCount: mainWasteViolatorCount,
    wasteSignificantViolatorCount: mainWasteSignificantViolatorCount,
    airComplianceStatusCounts: mainAirComplianceStatusCounts = [],
    airPermitTypeCounts: mainAirPermitTypeCounts = [],
    waterComplianceStatusCounts: mainWaterComplianceStatusCounts = [],
    waterPermitTypeCounts: mainWaterPermitTypeCounts = [],
    wasteComplianceStatusCounts: mainWasteComplianceStatusCounts = [],
    wastePermitTypeCounts: mainWastePermitTypeCounts = [],
  } = mainOverviewStats;

  const sectorOverviewStats = sectorData?.echoOverview?.stats ?? {};
  const {
    totalFacilityCount: sectorTotalFacilityCount,
    airFacilityCount: sectorAirFacilityCount,
    airViolatorCount: sectorAirViolatorCount,
    airSignificantViolatorCount: sectorAirSignificantViolatorCount,
    waterFacilityCount: sectorWaterFacilityCount,
    waterViolatorCount: sectorWaterViolatorCount,
    waterSignificantViolatorCount: sectorWaterSignificantViolatorCount,
    wasteFacilityCount: sectorWasteFacilityCount,
    wasteViolatorCount: sectorWasteViolatorCount,
    wasteSignificantViolatorCount: sectorWasteSignificantViolatorCount,
  } = sectorOverviewStats;

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState message={error.message} />;
  }

  if (!data) {
    return <EmptyState />;
  }

  const programOverviewPermitHeight = `${
    25 +
    Math.max(
      mainAirPermitTypeCounts?.length ?? 0,
      mainWaterPermitTypeCounts?.length ?? 0,
      mainWastePermitTypeCounts?.length ?? 0
    )
  }rem`;

  const programOverviewComplianceStatusHeight = `${
    25 +
    Math.max(
      mainAirComplianceStatusCounts?.length ?? 0,
      mainWaterComplianceStatusCounts?.length ?? 0,
      mainWasteComplianceStatusCounts?.length ?? 0
    )
  }rem`;

  return (
    <Stack p={3}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography variant="h6">
                Total Tenant Facilities: {tenantFacilitiesCount}
              </Typography>
              <Typography variant="h6">
                Active ECHO Facilities: {mainOverviewStats.activeFacilityCount}
              </Typography>
            </Box>
            {sectorOverviewStats.totalFacilityCount &&
            sectorOverviewStats.totalFacilityCount > 0 ? (
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="h6">
                  {comparisonType === "cohort"
                    ? "Active Cohort Facilities"
                    : "Active Industry Facilities"}
                  : {sectorOverviewStats.activeFacilityCount}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <ToggleButtonGroup
              value={comparisonType}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setComparisonType(newValue);
                }
              }}
              sx={{ mr: 2 }}
            >
              <ToggleButton value="industry">Industry</ToggleButton>
              <ToggleButton value="cohort">Cohort</ToggleButton>
            </ToggleButtonGroup>
            {comparisonType === "industry" ? (
              <IndustrySelector
                onComparisonSubmit={handleComparisonSubmit}
                loading={sectorLoading}
                selectedNaicsCode={naicsCode}
              />
            ) : (
              <CohortSelector
                tenantId={tenantId}
                onComparisonSubmit={handleComparisonSubmit}
                loading={sectorLoading}
                selectedCohortIds={selectedCohortIds}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ border: `${theme.palette.divider} solid 1px` }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Facility Type</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>Percent</TableCell>
                  {comparisonType === "cohort" ? (
                    <TableCell>Cohort %</TableCell>
                  ) : (
                    <TableCell>Industry %</TableCell>
                  )}
                  <TableCell>Total Violations</TableCell>
                  <TableCell>Violation %</TableCell>
                  {comparisonType === "cohort" ? (
                    <TableCell>Cohort Violation %</TableCell>
                  ) : (
                    <TableCell>Industry Violation %</TableCell>
                  )}
                  <TableCell>Significant Violations</TableCell>
                  <TableCell>Significant Violation %</TableCell>
                  {comparisonType === "cohort" ? (
                    <TableCell>Cohort Significant Violation %</TableCell>
                  ) : (
                    <TableCell>Industry Significant Violation %</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Air Facilities</TableCell>
                  <TableCell>{mainAirFacilityCount}</TableCell>
                  <PercentageCell
                    numerator={mainAirFacilityCount ?? 0}
                    denominator={tenantFacilitiesCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorAirFacilityCount ?? 0}
                    denominator={sectorTotalFacilityCount ?? 0}
                  />
                  <TableCell>{mainAirViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainAirViolatorCount ?? 0}
                    denominator={mainAirFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorAirViolatorCount ?? 0}
                    denominator={sectorAirFacilityCount ?? 0}
                  />
                  <TableCell>{mainAirSignificantViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainAirSignificantViolatorCount ?? 0}
                    denominator={mainAirFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorAirSignificantViolatorCount ?? 0}
                    denominator={sectorAirFacilityCount ?? 0}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Water Facilities</TableCell>
                  <TableCell>{mainWaterFacilityCount}</TableCell>
                  <PercentageCell
                    numerator={mainWaterFacilityCount ?? 0}
                    denominator={tenantFacilitiesCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWaterFacilityCount ?? 0}
                    denominator={sectorTotalFacilityCount ?? 0}
                  />

                  <TableCell>{mainWaterViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWaterViolatorCount ?? 0}
                    denominator={mainWaterFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWaterViolatorCount ?? 0}
                    denominator={sectorWaterFacilityCount ?? 0}
                  />
                  <TableCell>{mainWaterSignificantViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWaterSignificantViolatorCount ?? 0}
                    denominator={mainWaterFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWaterSignificantViolatorCount ?? 0}
                    denominator={sectorWaterFacilityCount ?? 0}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Waste Facilities</TableCell>
                  <TableCell>{mainWasteFacilityCount}</TableCell>
                  <PercentageCell
                    numerator={mainWasteFacilityCount ?? 0}
                    denominator={tenantFacilitiesCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWasteFacilityCount ?? 0}
                    denominator={sectorTotalFacilityCount ?? 0}
                  />
                  <TableCell>{mainWasteViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWasteViolatorCount ?? 0}
                    denominator={mainWasteFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWasteViolatorCount ?? 0}
                    denominator={sectorWasteFacilityCount ?? 0}
                  />
                  <TableCell>{mainWasteSignificantViolatorCount}</TableCell>
                  <PercentageCell
                    numerator={mainWasteSignificantViolatorCount ?? 0}
                    denominator={mainWasteFacilityCount ?? 0}
                  />
                  <PercentageCell
                    numerator={sectorWasteSignificantViolatorCount ?? 0}
                    denominator={sectorWasteFacilityCount ?? 0}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <ProgramOverview
              title="Air"
              complianceStatusCounts={mainAirComplianceStatusCounts ?? []}
              permitTypeCounts={mainAirPermitTypeCounts ?? []}
              onChartClick={(data) => handlePieChartClick("air", data)}
              permitHeight={programOverviewPermitHeight}
              complianceStatusHeight={programOverviewComplianceStatusHeight}
            />
            <ProgramOverview
              title="Water"
              complianceStatusCounts={mainWaterComplianceStatusCounts ?? []}
              permitTypeCounts={mainWaterPermitTypeCounts ?? []}
              onChartClick={(data) => handlePieChartClick("water", data)}
              permitHeight={programOverviewPermitHeight}
              complianceStatusHeight={programOverviewComplianceStatusHeight}
            />
            <ProgramOverview
              title="Waste"
              complianceStatusCounts={mainWasteComplianceStatusCounts ?? []}
              permitTypeCounts={mainWastePermitTypeCounts ?? []}
              onChartClick={(data) => handlePieChartClick("waste", data)}
              permitHeight={programOverviewPermitHeight}
              complianceStatusHeight={programOverviewComplianceStatusHeight}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Compliance History
          </Typography>
          <BarChart
            dataset={data?.echoOverview?.history.reduce(
              (acc: HistoryAccumulatorItem[], curr) => {
                const existingQuarter = acc.find(
                  (item) => item.quarter === curr.quarter
                );
                if (existingQuarter) {
                  existingQuarter[`${curr.program}Violation`] =
                    curr.violation ?? 0;
                  existingQuarter[`${curr.program}SignificantNoncompliance`] =
                    curr.significantNoncompliance ?? 0;
                } else {
                  acc.push({
                    quarter: curr.quarter ?? "Unknown Quarter",
                    [`${curr.program}Violation`]: curr.violation ?? 0,
                    [`${curr.program}SignificantNoncompliance`]:
                      curr.significantNoncompliance ?? 0,
                  });
                }
                return acc;
              },
              []
            )}
            xAxis={[{ scaleType: "band", dataKey: "quarter" }]}
            series={[
              {
                dataKey: "airViolation",
                label: "Air Violations",
                valueFormatter,
                stack: "air",
              },
              {
                dataKey: "airSignificantNoncompliance",
                label: "Air Significant Noncompliance",
                valueFormatter,
                stack: "air",
              },
              {
                dataKey: "waterViolation",
                label: "Water Violations",
                valueFormatter,
                stack: "water",
              },
              {
                dataKey: "waterSignificantNoncompliance",
                label: "Water Significant Noncompliance",
                valueFormatter,
                stack: "water",
              },
              {
                dataKey: "wasteViolation",
                label: "Waste Violations",
                valueFormatter,
                stack: "waste",
              },
              {
                dataKey: "wasteSignificantNoncompliance",
                label: "Waste Significant Noncompliance",
                valueFormatter,
                stack: "waste",
              },
            ]}
            {...chartSetting}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

interface ProgramOverviewProps {
  title: string;
  complianceStatusCounts: Counts[];
  permitTypeCounts: Counts[];
  onChartClick: (data: {
    label: string;
    type: "compliance" | "permit";
  }) => void;
  permitHeight: string;
  complianceStatusHeight: string;
}

function ProgramOverview({
  title,
  complianceStatusCounts = [],
  permitTypeCounts = [],
  onChartClick,
  permitHeight,
  complianceStatusHeight,
}: ProgramOverviewProps) {
  const theme = useTheme();
  const isSmallerThanXLScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const formatCounts = (counts: Counts[]) => {
    return (
      counts?.map(({ countKey, count }) => ({
        countKey: countKey || "Unknown Key",
        count: count || 0,
      })) ?? []
    );
  };

  const formattedComplianceStatusCounts = formatCounts(complianceStatusCounts);
  const formattedPermitTypeCounts = formatCounts(permitTypeCounts);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            sx={{
              border: `${theme.palette.divider} solid 1px`,
            }}
          >
            <Grid
              item
              p={2}
              sx={{
                [theme.breakpoints.up("xl")]: {
                  height: "20rem",
                },
                [theme.breakpoints.not("xl")]: {
                  height: permitHeight,
                },
                [theme.breakpoints.down("md")]: {
                  height: "unset",
                },
              }}
            >
              <Typography variant="h6" gutterBottom>
                {title} Permit Types
              </Typography>
              <DonutChart
                seriesData={formattedPermitTypeCounts.map(
                  ({ countKey, count }, id) => ({
                    id,
                    value: Number(count),
                    label: formatPieChartLabel(countKey, 18),
                  })
                )}
                containerHeight="16rem"
                legend={{
                  direction: "column",
                  position: isSmallerThanXLScreen ? "below" : "right",
                }}
                onItemClick={(event, pieItemIdentifier, item) => {
                  if (item && item.index !== undefined) {
                    const clickedItem = formattedPermitTypeCounts[item.index];
                    if (clickedItem) {
                      onChartClick({
                        label: clickedItem.countKey,
                        type: "permit",
                      });
                    }
                  }
                }}
                onLegendItemClick={(item: DefaultizedPieValueType) => {
                  if (item.label) {
                    onChartClick({
                      label: (typeof item.label === "function"
                        ? item.label("legend")
                        : item.label
                      ).replaceAll("\n", " "),
                      type: "permit",
                    });
                  }
                }}
                selectedIndex={null}
                legendSx={{ minWidth: "150px" }}
                responsiveContainerProps={{
                  // Override the colors for insights V1 so that the default colors are used
                  colors: undefined,
                }}
                useLegacyColors={true}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ border: `${theme.palette.divider} solid 1px` }}>
            <Box
              p={2}
              sx={{
                [theme.breakpoints.up("xl")]: {
                  height: "20rem",
                },
                [theme.breakpoints.not("xl")]: {
                  height: complianceStatusHeight,
                },
                [theme.breakpoints.down("md")]: {
                  height: "unset",
                },
              }}
            >
              <Typography variant="h6" gutterBottom>
                {title} Compliance Status
              </Typography>
              <DonutChart
                seriesData={formattedComplianceStatusCounts.map(
                  ({ countKey, count }, id) => ({
                    id,
                    value: Number(count),
                    label: formatPieChartLabel(countKey, 18),
                  })
                )}
                containerHeight="16rem"
                legend={{
                  direction: "column",
                  position: isSmallerThanXLScreen ? "below" : "right",
                }}
                onItemClick={(event, pieItemIdentifier, item) => {
                  if (item && item.index !== undefined) {
                    const clickedItem =
                      formattedComplianceStatusCounts[item.index];
                    if (clickedItem) {
                      onChartClick({
                        label: clickedItem.countKey,
                        type: "compliance",
                      });
                    }
                  }
                }}
                onLegendItemClick={(item: DefaultizedPieValueType) => {
                  if (item.label) {
                    onChartClick({
                      label: (typeof item.label === "function"
                        ? item.label("legend")
                        : item.label
                      ).replaceAll("\n", " "),
                      type: "compliance",
                    });
                  }
                }}
                selectedIndex={null}
                legendSx={{ minWidth: "150px" }}
                responsiveContainerProps={{
                  // Override the colors for insights V1 so that the default colors are used
                  colors: undefined,
                }}
                useLegacyColors={true}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

function PercentageCell({
  numerator,
  denominator,
}: {
  numerator: number;
  denominator: number;
}) {
  const percentage =
    denominator > 0 ? ((numerator / denominator) * 100).toFixed(2) + "%" : "-";
  return <TableCell>{percentage}</TableCell>;
}
