import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { gql } from "../../../../generated-graphql";
import { SummaryDoughnutChart } from "./DoughnutChart";
import { StaffTable } from "./StaffTable";
import { StatsGrid } from "./StatGrid";
import { UnverifiedTable } from "./UnverifiedTable";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";

const REPORTING_SEASON_SUMMARY = gql(`
  query ReportingSeasonSummary($year: Int!) {
    reportingSeasonSummary(year: $year) {
      year
      noReportsTotal
      facilitiesTotal
      reportsInFilingComplete
      reportsInAwaitingVerification
      reportsInNotReporting
      reportsInProcessing
      reportsUnverified
      reportsTotal
      workflowsCountHuman
      workflowsCountError
      workflowsCountWaitForAll
      stateSummaries {
        id
        name
        unverifiedCount
        reportCount
      }
      organizationSummaries {
        id
        name
        unverifiedCount
        reportCount
      }
      staffSummaries {
        id
        name
        reportCount
        completedCount
      }
    }
  }
`);

export function Summary() {
  const { data, loading } = useQuery(REPORTING_SEASON_SUMMARY, {
    fetchPolicy: "network-only",
    variables: {
      year: currentTierIIReportingYear,
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4} xs={12}>
          <SummaryDoughnutChart
            data={data?.reportingSeasonSummary}
            loading={loading}
          />
        </Grid>
        <Grid item lg={8}>
          <StatsGrid data={data?.reportingSeasonSummary} loading={loading} />
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12} sx={{ height: "400px" }}>
            <UnverifiedTable
              type="State"
              data={data?.reportingSeasonSummary.stateSummaries}
              loading={loading}
            />
          </Grid>
          <Grid item lg={4} xs={12} sx={{ height: "400px" }}>
            <UnverifiedTable
              type="Organization"
              data={data?.reportingSeasonSummary.organizationSummaries}
              loading={loading}
            />
          </Grid>
          <Grid item lg={4} xs={12} sx={{ height: "400px" }}>
            <StaffTable
              data={data?.reportingSeasonSummary.staffSummaries}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
