import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback, useEffect, useState } from "react";

const CHECK_FOR_FILE = gql(`query CheckForFile($key: String!) {
    checkForFile(key: $key) {
      exists
      signedUrl
    }
  }`);

export const useLongPollingForFile = ({
  tenantId,
  filename,
}: {
  tenantId: string;
  filename: string;
}) => {
  const [polling, setPolling] = useState(false);
  const [signedUrl, setSignedUrl] = useState<undefined | string>(undefined);

  const [checkForFile, { loading: checkingForFile }] = useLazyQuery(
    CHECK_FOR_FILE,
    {
      fetchPolicy: "network-only",
      variables: { key: `spreadsheet-exports/${tenantId}/${filename}` },
    }
  );

  const checkForS3File = useCallback(async () => {
    const { data } = await checkForFile();

    if (data?.checkForFile?.signedUrl == null) return;

    setPolling(false);
    setSignedUrl(data.checkForFile.signedUrl);
  }, [checkForFile]);

  useEffect(() => {
    if (!polling || checkingForFile) return;

    const intervalId = setInterval(() => checkForS3File(), 1000);

    return () => intervalId && clearInterval(intervalId);
  }, [polling, checkForS3File, checkingForFile]);

  return { polling, setPolling, signedUrl };
};
