import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export function ClickableGridCell(
  props: PropsWithChildren<{ onClick?: () => void; href?: string }>
) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        textAlign: "right",
        display: "flex",
        paddingRight: "8px",
        alignItems: "center",
        justifyContent: "flex-end",
        textDecoration: "unset",
        color: "unset",
        ":hover": {
          textDecoration: "underline",
          textUnderlineOffset: "7px",
        },
        position: "relative",
        cursor: "pointer",
      }}
      onClick={props.onClick}
      component={props.href ? Link : "div"}
      to={props.href ?? ""}
    >
      {props.children}
    </Box>
  );
}
