import { gql } from "generated-graphql/gql";

export const TIER_II_REPORT_ASSOCIATIONS_QUERY = gql(`
  query AssociatedTierIIReports($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReports(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      items {
        id
        reportingYear
        reportKind
        facility {
          id
          name
          streetAddress1
          city
          state
          zip
        }
        tenant {
          id
          name
        }
      }
    }
  }
`);

export const FACILITY_ASSOCIATIONS_QUERY = gql(`
  query AssociatedFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...FacilityPicker
        streetAddress1
        streetAddress2
        city
        state
        zip
        tenant {
          id
          name
        }
      }
      count
    }
  }
`);
