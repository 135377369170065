import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";
import { Dialog } from "./Dialog";
import { LoadingButton } from "@mui/lab";

type ConfirmDialogProps = {
  open: boolean;
  loading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  msg: string | ReactNode;
  title?: string;
  confirmText?: string | ReactNode;
  cancelText?: string | ReactNode;
  actions?: ReactNode;
};

export function ConfirmDialog(props: ConfirmDialogProps) {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      props.onConfirm?.();
    }
  };

  return (
    <Dialog open={props.open} onKeyDown={handleKeyDown} onClose={props.onClose}>
      <DialogTitle>{props.title ?? "Are you sure?"}</DialogTitle>
      <DialogContent>{props.msg}</DialogContent>
      <DialogActions>
        {props.actions ? (
          props.actions
        ) : (
          <>
            <Button
              disabled={props.loading}
              onClick={props.onCancel ?? props.onClose}
              variant="outlined"
            >
              {props.cancelText ?? "Cancel"}
            </Button>
            <LoadingButton
              loading={props.loading}
              disabled={props.loading}
              onClick={props.onConfirm}
              variant="contained"
              data-testid="confirm"
            >
              {props.confirmText ?? "Yes, I'm sure"}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
