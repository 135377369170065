import { useMutation } from "@apollo/client";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { CheckboxField } from "components/Forms/CheckboxField";
import { IssueListButton } from "components/Forms/IssueListButton";
import { StateFieldsForm } from "components/Forms/StateFieldsForm";
import { SaveButton } from "components/SaveButton";
import { SkeletonFormGroup } from "components/Skeleton";
import { ThresholdChip } from "components/ThresholdChip";
import { gql } from "generated-graphql";
import {
  DynamicField,
  IssueLevel,
  ProgramArea,
  ReportingFacilityChemicalQuery,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformStateField";
import { useValidatingForm } from "hooks/useValidatingForm";
import { omit } from "lodash";
import { useCallback, useMemo } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useReportingFacilityChemicalInputValidation } from "../Report/validationHooks/useReportingFacilityChemicalInputValidation";
import { MeasurementsTable } from "./MeasurementsTable";
import { ProductsTable } from "./ProductsTable";
import { REPORTING_FACILITY_CHEMICAL_QUERY } from "./ReportingFacilityChemicalEditor";
import { REPORTING_FACILITY_CHEMICALS_QUERY } from "./ReportingFacilityChemicalTable";
import { StorageLocationsTable } from "./StorageLocationsTable";
import { SummaryCard } from "./SummaryCard";
import { CatalogLink } from "components/CatalogLink";

type FormValues = {
  isAlwaysReported?: boolean | null;
  stateFields: (DynamicField & { type: ProgramArea })[];
};

const UPSERT_FACILITY_CHEMICAL_STATE_FIELDS = gql(`
  mutation UpsertFacilityChemicalStateFields($input: [FacilityChemicalStateFieldInput!]!, $facilityId: ID!, $chemicalId: ID!, $reportingYear: Int!) {
    upsertFacilityChemicalStateFields(input: $input, facilityId: $facilityId, chemicalId: $chemicalId, reportingYear: $reportingYear, type: EPCRA) {
      id
    }
  }
`);

const SET_IS_ALWAYS_REPORTED = gql(`
  mutation SetIsAlwaysReportedOnRfc($id: ID!, $isAlwaysReported: Boolean) {
    setIsAlwaysReportedOnReportingFacilityChemical(id: $id, isAlwaysReported: $isAlwaysReported) {
      id
      isAlwaysReported
    }
  }
`);

export const ReportingFacilityChemicalDialog = ({
  rfc,
  loading: loadingRfc,
  onClose,
}: {
  rfc: ReportingFacilityChemicalQuery["reportingFacilityChemical"] | undefined;
  loading: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const [upsertStateFields, { loading: savingStateFields }] = useMutation(
    UPSERT_FACILITY_CHEMICAL_STATE_FIELDS
  );
  const [setIsAlwaysReported, { loading: savingIsAlwaysReported }] =
    useMutation(SET_IS_ALWAYS_REPORTED);

  const saving = savingStateFields || savingIsAlwaysReported;

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      if (!rfc) return;

      await setIsAlwaysReported({
        variables: { id: rfc.id, isAlwaysReported: data.isAlwaysReported },
      });

      await upsertStateFields({
        variables: {
          input: data.stateFields.map((stateField) => {
            return {
              ...transform(stateField),
              facilityId: rfc.facilityId,
              chemicalId: rfc.chemicalId,
              reportingYear: rfc.reportingYear,
            };
          }),
          facilityId: rfc.facilityId,
          chemicalId: rfc.chemicalId,
          reportingYear: rfc.reportingYear,
        },
        onCompleted() {
          onClose();
        },
        refetchQueries: [
          REPORTING_FACILITY_CHEMICAL_QUERY,
          REPORTING_FACILITY_CHEMICALS_QUERY,
        ],
      });
    },
    [onClose, rfc, setIsAlwaysReported, upsertStateFields]
  );

  const defaultValues = useMemo<FormValues>(
    () => ({
      isAlwaysReported: rfc?.isAlwaysReported ?? false,
      stateFields:
        rfc?.stateFields.map((sf) => ({
          ...omit(sf, "selectOptions", "tooltip"),
          value: sf.value ?? null,
          type: ProgramArea.Epcra,
        })) ?? [],
    }),
    [rfc]
  );

  // Use the map to dedup productIds
  const productIdsToNames: Record<string, string> = {};
  if (rfc) {
    rfc.origin?.facilityProducts?.forEach((fp) => {
      productIdsToNames[fp.product?.id ?? ""] = fp.product?.name ?? "";
    });
    rfc.origin?.facilityProductMeasurements?.forEach((fpm) => {
      productIdsToNames[fpm.product?.id ?? ""] = fpm.product?.name ?? "";
    });
  }

  const form = useValidatingForm<FormValues>(
    defaultValues,
    rfc?.issues,
    useReportingFacilityChemicalInputValidation(rfc)
  );

  const renderIssueListButton = () => {
    if (rfc?.aggregationErrorMessage) {
      return (
        <IssueListButton
          issues={[
            {
              message: rfc.aggregationErrorMessage,
              level: IssueLevel.Critical,
              modelName: "ReportingFacilityChemical",
            },
          ]}
        />
      );
    }

    if (form.issues) {
      return <IssueListButton issues={form.issues} />;
    }

    return null;
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>
          {rfc ? (
            <>
              {`${rfc.reportingYear} Reporting Summary for ${rfc.chemical?.name}`}
              <ThresholdChip
                isOverThreshold={rfc.isOverThreshold ?? false}
                sx={{ ml: 1 }}
              />
            </>
          ) : (
            "Loading..."
          )}
        </DialogTitle>
        <DialogContent>
          {rfc ? (
            <Stack spacing={4}>
              {!rfc.isOverThreshold && (
                <CheckboxField
                  label="Voluntarily report this chemical"
                  name="isAlwaysReported"
                  control={form.control}
                  noErrorDisplay
                />
              )}

              {rfc.aggregationErrorMessage && (
                <Alert severity="error">{rfc.aggregationErrorMessage}</Alert>
              )}
              <SummaryCard rfc={rfc} />

              {rfc.stateFields.length > 0 && (
                <FormProvider {...form}>
                  <StateFieldsForm
                    namePrefix="stateFields"
                    fields={rfc.stateFields}
                    context="facilityChemical"
                    sx={{ p: theme.spacing(3) }}
                  />
                </FormProvider>
              )}

              <MeasurementsTable rfc={rfc} />

              <ProductsTable rfc={rfc} />

              <StorageLocationsTable rfc={rfc} />
              <CatalogLink
                id={rfc.chemical?.id ?? ""}
                name={rfc.chemical?.name ?? ""}
                type="Chemical"
                issues={rfc.issues}
              />
              {Object.entries(productIdsToNames).map(([id, name], idx) => (
                <CatalogLink
                  key={idx}
                  id={id ?? ""}
                  name={name ?? ""}
                  type="Product"
                  issues={rfc.issues ?? []}
                />
              ))}
            </Stack>
          ) : (
            <SkeletonFormGroup count={8} />
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap={1}>
            {renderIssueListButton()}
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <SaveButton loading={saving} disabled={loadingRfc} />
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};
