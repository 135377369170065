import { useQuery } from "@apollo/client";
import Cancel from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { JobStatus } from "generated-graphql/graphql";
import React, { useEffect } from "react";
import { JOB_STATUS } from "./schema";

export const SDSUploadDialog: React.FC<{
  jobId: string;
  onClose: () => void;
}> = ({ jobId, onClose }) => {
  const { data, stopPolling } = useQuery(JOB_STATUS, {
    variables: { id: jobId },
    pollInterval: 1000, // poll every second until we stop below
  });

  useEffect(() => {
    if (
      data?.job.status === JobStatus.Failed ||
      data?.job.status === JobStatus.Succeeded
    ) {
      stopPolling();
    }
  }, [data?.job.status, stopPolling]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Job Status</DialogTitle>
      <DialogContent>
        <List sx={{ minWidth: 400 }}>
          {data?.job ? (
            <ListItem key={data.job.id} sx={{ ml: 2 }} alignItems="flex-start">
              <ListItemText primary={data.job.id} secondary={data.job.status} />

              <ListItemIcon sx={{ mr: 2 }}>
                {data.job.status === JobStatus.Queued && (
                  <CircularProgress size={24} />
                )}
                {data.job.status === JobStatus.Succeeded && (
                  <CheckCircle color="success" />
                )}
                {data.job.status === JobStatus.Failed && (
                  <Cancel color="error" />
                )}
              </ListItemIcon>
            </ListItem>
          ) : (
            <Skeleton variant="rectangular" animation="wave" />
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};
