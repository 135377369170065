import { useLazyQuery, useMutation } from "@apollo/client";
import Delete from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadFile from "@mui/icons-material/UploadFile";
import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid } from "components/DataGrid";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { DocumentInput, DocumentType } from "generated-graphql/graphql";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { useCallback, useEffect, useState } from "react";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import DocumentUpload from "routes/Staff/Fulfillment/ReportDetails/DocumentUpload";
import { v4 } from "uuid";
import { ChemicalDetailInputs } from ".";
import {
  CHEMICAL_DETAIL_CHEMICAL_QUERY,
  DELETE_CHEMICAL_DOCUMENT_MUTATION,
} from "./schema";

interface SDSTableProps {
  chemicalId: string;
  control: Control<ChemicalDetailInputs, any>;
  parentFile?: string;
  onDocumentReview: (fileUrl?: string) => void;
}

export const SDSTable: React.FC<SDSTableProps> = ({
  chemicalId,
  control,
  onDocumentReview,
  parentFile,
}) => {
  const theme = useTheme();
  const alerts = useAlerts();
  const { tenantId } = useParams();

  const {
    fields: documentsFieldArray,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "documents",
  });

  useEffect(() => setFileUrl(parentFile), [parentFile]);

  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [downloadDocument, setDownloadDocument] = useState(false);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Title", flex: 1 },
    {
      field: "actions",
      type: "actions",
      resizable: false,
      sortable: false,
      getActions: (params) => {
        const actions = [
          <Tooltip title="Delete Document" key={1}>
            <GridActionsCellItem
              onClick={() => {
                setDeleteConfirmData({
                  chemicalId,
                  documentId: params.row.documentId,
                  title: params.row.document.title,
                });
              }}
              label="Delete Document"
              icon={<Delete />}
              disabled={deleteDocumentLoading}
            />
          </Tooltip>,
          <Tooltip title="Download Document" key={2}>
            <GridActionsCellItem
              onClick={async () => {
                setDownloadDocument(true);
                getDocumentDownloadLink({
                  variables: { id: params.row.documentId },
                });
              }}
              label="Download Document"
              icon={<Download />}
              disabled={!params.row.documentId}
            />
          </Tooltip>,
        ];

        if (fileUrl == null) {
          actions.push(
            <div style={{ paddingRight: theme.spacing(1) }} key={3}>
              <Tooltip title="Review Document">
                <GridActionsCellItem
                  onClick={async () => {
                    setDownloadDocument(false);
                    const { data } = await getDocumentDownloadLink({
                      variables: { id: params.row.documentId },
                    });

                    if (data?.getDocumentDownloadLink == null) {
                      alerts.error("Error fetching file url");
                      return;
                    }

                    onDocumentReview(data.getDocumentDownloadLink);
                    setFileUrl(data.getDocumentDownloadLink);
                  }}
                  label="Review Document"
                  icon={<VisibilityIcon />}
                  disabled={!params.row.documentId}
                />
              </Tooltip>
            </div>
          );
        }

        return actions;
      },
    },
  ];

  // Stores relevant data for deleting a document, determines whether delete modal shows
  const [deleteConfirmData, setDeleteConfirmData] = useState<{
    chemicalId?: string;
    documentId?: string;
    title?: string;
  }>({});

  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          return alerts.error("Error fetching download link");
        }

        if (downloadDocument) {
          alerts.success("Success fetching download link");
          window.open(data.getDocumentDownloadLink, "_blank");
        }
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  const [deleteDocument, { loading: deleteDocumentLoading }] = useMutation(
    DELETE_CHEMICAL_DOCUMENT_MUTATION,
    {
      onCompleted: () => {
        alerts.success("Successfully deleted the document");
      },
      onError: (e) =>
        alerts.error("An error occurred while deleting the document", e),
      update: (cache, { data }) => {
        const chemicalDocumentId =
          data?.deleteChemicalDocument.chemicalDocumentId;
        if (!chemicalId) {
          return;
        }

        // Get the existing data from cache
        const existingData = cache.readQuery({
          query: CHEMICAL_DETAIL_CHEMICAL_QUERY,
          variables: { chemicalId },
        });

        if (!existingData) {
          return;
        }

        // Filter out the deleted document
        const updatedDocuments = (
          existingData.chemicalWithAssociatedData.chemical.documents ?? []
        ).filter((document) => document.id !== chemicalDocumentId);

        // Write the updated data back to the cache
        cache.writeQuery({
          query: CHEMICAL_DETAIL_CHEMICAL_QUERY,
          variables: { chemicalId },
          data: {
            ...existingData,
            chemicalWithAssociatedData: {
              ...existingData.chemicalWithAssociatedData,
              chemical: {
                ...existingData.chemicalWithAssociatedData.chemical,
                documents: updatedDocuments,
              },
            },
          },
        });
      },
    }
  );

  const handleCloseConfirmDialog = useCallback(() => {
    setDeleteConfirmData({});
  }, []);

  return (
    <>
      <Controller
        name="documents"
        control={control}
        render={({ fieldState: { error } }) => (
          <FormControl>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <FormLabel>Safety Data Sheet</FormLabel>
              </Box>
              <DocumentUpload
                tenantId={tenantId ?? ""}
                label="Upload"
                description="Safety Data Sheet"
                documentType={DocumentType.SafetyDataSheet}
                startIcon={<UploadFile />}
                sx={{ marginLeft: theme.spacing(2) }}
                onSuccess={(document) => {
                  if (!chemicalId) {
                    return;
                  }
                  append({
                    id: v4(),
                    key: document.storageLink,
                    name: document.title,
                    chemicalId: chemicalId,
                    documentId: document.id,
                    document: document as DocumentInput,
                  });
                }}
                acceptedFileTypes=".pdf"
              />
            </Stack>
            <ErrorDisplay error={error} />
          </FormControl>
        )}
      />
      <DataGrid
        sx={{ height: 200 }}
        rows={documentsFieldArray}
        columns={columns}
        sortModel={[{ field: "name", sort: "asc" }]}
      />
      <ConfirmDialog
        loading={deleteDocumentLoading}
        open={!!deleteConfirmData.documentId}
        onClose={handleCloseConfirmDialog}
        onConfirm={async () => {
          const documentIndex = documentsFieldArray.findIndex(
            (document) => document.documentId === deleteConfirmData.documentId
          );
          remove(documentIndex);
          await deleteDocument({
            variables: {
              input: {
                chemicalId: chemicalId,
                documentId: deleteConfirmData.documentId ?? "",
              },
            },
          });
          handleCloseConfirmDialog();
        }}
        msg={`Are you sure you want to delete document "${deleteConfirmData.title}?"`}
      />
    </>
  );
};
