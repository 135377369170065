import { useQuery } from "@apollo/client";
import { gql } from "generated-graphql";

const FACILITY_USAGE_QUERY = gql(`
  query FacilityUsageQuery($chemicalId: ID!, $page: Int, $pageSize: Int, $includeItems: Boolean = false) {
      facilityUsage(chemicalId: $chemicalId, page: $page, pageSize: $pageSize) {
        facilityCount
        tenantCount
        items @include(if: $includeItems) {
          id
          name
          tenant {
            id
            name
          }
        }
      }
  }
`);

export function useFacilityUsage({
  chemicalId,
  page,
  pageSize,
  includeItems = false,
}: {
  chemicalId: string;
  page?: number;
  pageSize?: number;
  includeItems?: boolean;
}) {
  return useQuery(FACILITY_USAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: !chemicalId,
    variables: {
      chemicalId,
      page,
      pageSize,
      includeItems,
    },
  });
}
