import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { ActivityStatus } from "generated-graphql/graphql";
import { useUpdateActivity } from "hooks/activities";
import { useState } from "react";
import { ActivityButtonProps } from ".";
import { useReportDetails } from "../../useReportDetails";

export const Requeue = ({
  activity,
  showWarningPanel = false,
}: ActivityButtonProps & {
  showWarningPanel?: boolean;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data } = useReportDetails();
  const [updateActivity, { loading }] = useUpdateActivity();

  const requeueAndUpdateCache = () => {
    updateActivity({
      variables: {
        id: activity.id,
        input: {
          status: ActivityStatus.Queued,
          message: `Requeued activity from report details page. Clearing report tags: ${data?.tierIIReport.tags}.`,
        },
      },
      update(cache, { data }) {
        if (data?.updateActivity.status) {
          cache.modify({
            id: cache.identify({
              __typename: "Activity",
              id: activity.id,
            }),
            fields: {
              status() {
                return data?.updateActivity?.status;
              },
            },
          });
        }
      },
    });
  };

  const onClick = () => {
    if (showWarningPanel) {
      setIsDialogOpen(true);
    } else {
      requeueAndUpdateCache();
    }
  };

  return (
    <>
      <LoadingButton
        variant="text"
        size="small"
        color="primary"
        loadingPosition="start"
        onClick={onClick}
        startIcon={<PlayArrowIcon />}
      >
        Requeue
      </LoadingButton>
      <RequeueDialog
        activityId={activity.id}
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        loading={loading}
        onSubmit={() => {
          requeueAndUpdateCache();
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};

interface Props {
  activityId: string;
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
  loading: boolean;
}

const RequeueDialog = ({ isOpen, onCancel, onSubmit, loading }: Props) => {
  const theme = useTheme();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const handleRequeueButtonClick = () => {
    onSubmit();
  };

  const handleClose = () => {
    setIsConfirmed(false);
    onCancel();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ padding: theme.spacing(2) }}
    >
      <DialogTitle>Requeue Activity</DialogTitle>
      <DialogContent sx={{ minWidth: "300px" }}>
        <DialogContentText>
          Making changes now may affect the report submission, which has already
          started. Some steps cannot be recalled, such as submitting a payment.
        </DialogContentText>
        <DialogContentText>
          Submit a ticket to{" "}
          <a
            href="https://encamp.slack.com/archives/C02EUTUBC1H"
            style={{ textDecoration: "none" }}
          >
            #engineering-support
          </a>{" "}
          for manual review by an engineer with these{" "}
          <a href={window.location.href} style={{ textDecoration: "none" }}>
            report details
          </a>
          .
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
              name="confirmed"
              color="primary"
            />
          }
          label="I've reviewed this with an engineer and want to override the safety check"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          disabled={!isConfirmed}
          loading={loading}
          onClick={handleRequeueButtonClick}
        >
          Requeue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
