import { useSuspenseQuery } from "@apollo/client";
import Insights from "@mui/icons-material/Insights";
import {
  Alert,
  Fade,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DefaultizedPieValueType } from "@mui/x-charts";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import DonutChart, { LabelLocation } from "components/Dashboard/DonutChart";
import { HorizontalBarChart } from "components/Dashboard/HorizontalBarChart";
import { gql } from "generated-graphql/gql";
import { useQueryParams } from "hooks/useQueryParams";
import { useTenant } from "hooks/useTenant";
import React, { useCallback, useMemo } from "react";
import { ProgramAreaColors, ProgramAreaLabelMap } from "util/insights";
import { useOverviewState } from "../OverviewContext";

const INSIGHTS_FACILITY_MIX_QUERY = gql(`
  query InsightsFacilityMix($tenantId: String!, $programArea: String, $naicsCode: String) {
    insightsFacilityMix(tenantId: $tenantId, programArea: $programArea, naicsCode: $naicsCode) {
      facilitiesCount
      connectedFacilitiesCount
      hasNoGovtIdCount
      filteredFacilitiesCount
      facilityNaicsTypes {
        name
        naicsCode
        count
      }
      programAreas {
        key
        count
      }
      facilitiesWithMultiplePrograms
    }
  }
`);

type FacilityMix = {
  id: number;
  label: (location: LabelLocation) => string;
  code: string;
  value: number;
};

export function FacilityMixTile() {
  const { tenantId } = useTenant();
  const theme = useTheme();

  const {
    overviewState: { programArea, naicsCode, isPending },
    setOverviewState,
  } = useOverviewState();

  const { filters: localFilter } = useQueryParams<{
    programArea: string;
    naicsCode: string;
    naicsType: string;
  }>();

  const { data } = useSuspenseQuery(INSIGHTS_FACILITY_MIX_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      naicsCode: naicsCode,
      programArea,
    },
  });

  const connectedFacilitiesText = `${(
    (data.insightsFacilityMix.connectedFacilitiesCount /
      (data.insightsFacilityMix.facilitiesCount || 1)) *
    100
  ).toFixed()}% of facilities represented`;

  const facilityMix: FacilityMix[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.insightsFacilityMix.facilityNaicsTypes.map((f) => {
      return {
        id: f.naicsCode === "Other" ? -1 : parseInt(f.naicsCode),
        label: (location: LabelLocation) => {
          return location === "tooltip"
            ? `${f.count} facilities, ${Math.floor(
                (f.count / (data.insightsFacilityMix.facilitiesCount || 1)) *
                  100
              )}%`
            : f.name;
        },
        code: f.naicsCode,
        value: f.count,
      };
    });
  }, [data]);

  const programAreaPercentageData = useMemo(() => {
    if (!data) {
      return [];
    }

    const totalFacilities = data.insightsFacilityMix.facilitiesCount;

    return data.insightsFacilityMix.programAreas.map((p) => {
      const percentage = Math.floor((p.count / totalFacilities) * 100);

      const percentData = {
        label: ProgramAreaLabelMap[p.key] ?? ProgramAreaLabelMap.Default,
        value: ProgramAreaLabelMap[p.key] ?? ProgramAreaLabelMap.Default,
        total: p.count,
        percentage,
        color:
          ProgramAreaColors[
            ProgramAreaLabelMap[p.key] ?? ProgramAreaLabelMap.Default
          ],
      };
      return percentData;
    });
  }, [data]);

  const selectedIndex = useMemo(() => {
    const foundIndex = facilityMix.findIndex(
      (f) => f.id === Number(localFilter.naicsCode) || f.code === "Other"
    );
    return localFilter.naicsCode !== undefined ? foundIndex : null;
  }, [localFilter.naicsCode, facilityMix]);

  const onFacilityMixClick = useCallback(
    (item: DefaultizedPieValueType) => {
      if (!item.code || naicsCode === item.id || naicsCode === item.code) {
        setOverviewState((state) => {
          state.naicsCode = undefined;
          state.naicsType = undefined;
        });
      } else if (item) {
        const naicsType = data?.insightsFacilityMix.facilityNaicsTypes.find(
          (t) => t.naicsCode === item.code || t.naicsCode === "Other"
        );

        setOverviewState((state) => {
          state.naicsCode = item.code;
          state.naicsType = naicsType?.name;
        });
      }
    },
    [data?.insightsFacilityMix.facilityNaicsTypes, naicsCode, setOverviewState]
  );

  return (
    <DashboardTile>
      <DashboardTile.Header
        title="My facilities"
        infoTooltip={<MyFacilitiesTooltip />}
      >
        {isPending && (
          <Fade in={isPending} timeout={750}>
            <Skeleton
              variant="rounded"
              animation="wave"
              height={theme.spacing(2)}
              sx={{
                alignSelf: "center",
                borderRadius: theme.shape.borderRadius,
                flex: {
                  xs: 0.9,
                  sm: 0.5,
                  md: 0.6,
                },
              }}
            />
          </Fade>
        )}
        <Tooltip
          title={
            <React.Fragment>
              Total open facilities in Encamp:{" "}
              {data.insightsFacilityMix.facilitiesCount}
              <br />
              Matched in Insights:{" "}
              {data.insightsFacilityMix.connectedFacilitiesCount}
              {data.insightsFacilityMix.hasNoGovtIdCount ? (
                <>
                  <br />
                  No Government ID: {data.insightsFacilityMix.hasNoGovtIdCount}
                </>
              ) : null}
              <hr />
              <em>
                Facilities are matched in Insights based on FRS ID. To see more
                facilities represented here, add an FRS ID on the facility
                profile or contact Encamp Support for assistance.
              </em>
            </React.Fragment>
          }
        >
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              flexShrink: 0,
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
          >
            {connectedFacilitiesText}
          </Typography>
        </Tooltip>
      </DashboardTile.Header>
      <DashboardTile.ContentArea xs={12} lg={6}>
        <Typography>Facility mix</Typography>
        <DonutChart
          seriesData={facilityMix}
          label={data.insightsFacilityMix.filteredFacilitiesCount.toLocaleString()}
          subLabel="Total"
          containerHeight="18rem"
          legend={{ direction: "column", position: "right" }}
          selectedIndex={selectedIndex}
          onItemClick={(event, pieItemIdentifier, item) =>
            onFacilityMixClick(item)
          }
          onLegendItemClick={(item: DefaultizedPieValueType) =>
            onFacilityMixClick(item)
          }
          legendSx={{ minWidth: "150px" }}
        />
      </DashboardTile.ContentArea>
      <DashboardTile.ContentArea xs={12} lg={6}>
        <HorizontalBarChart
          title="Program areas"
          value={localFilter.programArea}
          onClick={(programArea) => {
            if (programArea === localFilter.programArea) {
              setOverviewState((state) => {
                state.programArea = undefined;
              });
            } else {
              setOverviewState((state) => {
                state.programArea = programArea;
              });
            }
          }}
          data={programAreaPercentageData}
        />
      </DashboardTile.ContentArea>

      {data && data.insightsFacilityMix.facilitiesWithMultiplePrograms > 0 && (
        <DashboardTile.ContentArea xs={12}>
          <Alert severity="info" icon={<Insights />}>
            <Typography>
              <Typography component="span" fontWeight="800">
                {Math.floor(
                  (data.insightsFacilityMix.facilitiesWithMultiplePrograms /
                    data.insightsFacilityMix.filteredFacilitiesCount) *
                    100
                )}
                %
              </Typography>{" "}
              of your facilities have two or more programs
            </Typography>
          </Alert>
        </DashboardTile.ContentArea>
      )}
    </DashboardTile>
  );
}

function MyFacilitiesTooltip() {
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Facility mix
        </Typography>{" "}
        represents your facility breakdown by industry. We've grouped your
        facilities based on the first 3 digits of a facility's reported primary
        NAICS code, and display the top 10 NAICS codes present in your
        organization.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          Program areas
        </Typography>{" "}
        represents your facility breakdown by program type. We've grouped your
        facilities into 4 high-level program areas (waste, water, air, and TRI)
        based on EPA statutes and data available in ECHO.
      </Typography>
      <Typography variant="caption">
        <Typography component="span" variant="caption" fontWeight={800}>
          % of facilities represented
        </Typography>{" "}
        is the number of facilities we can match between your facilities in
        Encamp and the government datasets, based on FRS ID and EPA ID.
      </Typography>
      <Typography variant="caption">
        *Interacting with both the Facility mix and Program areas charts will
        filter the penalties, violations, and non-compliance charts below.
      </Typography>
    </Stack>
  );
}
