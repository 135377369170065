import { gql } from "../generated-graphql";

const GET_STAFF_INPUTS = gql(`
query GetRequiredStaffInputs($activityDescription: String!) {
  getRequiredStaffInputs(activityDescription: $activityDescription) {
    requiredStaffInputs {
      name
      inputType
    }
    links {
      name
      link
    }
    activityType
    jurisdictionType
    consolidated
  }
}
`);

export default GET_STAFF_INPUTS;
