import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

const ConfirmDeleteDocuments = ({
  deleteDocument,
  onClose,
  deleting,
}: {
  deleteDocument: () => Promise<void>;
  onClose: () => void;
  deleting: boolean;
}) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <Dialog open={true}>
      <DialogTitle>Confirm Document Delete</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            Deleting a document is a destructive action and can affect report
            submission. Please confirm with a Tier II captain or an engineer via
            #tier-2-basecamp or #engineering-support that this is safe.
          </Typography>
          <Typography>
            Please note, customer submitted documents will also appear in this
            list (like SDSs, site plans, and so on)!
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirm}
                onChange={() => setConfirm(!confirm)}
                disabled={deleting}
              />
            }
            label={
              <Typography>
                I understand the risks and would like to delete this document
              </Typography>
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={deleting}
          variant="contained"
          onClick={async () => {
            await deleteDocument();
            onClose();
          }}
          disabled={!confirm}
        >
          Delete Document
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDeleteDocuments };
