import {
  Permission,
  TierIiReportKind,
  TierIiReportOrgStatus,
} from "generated-graphql/graphql";
import { ReportRow } from "routes/Customer/Chemicals/Reports";
import { useAuthorization } from "./useAuthorization";
import { orgStatusIsOpen } from "util/constants";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { useCallback, useMemo } from "react";

type FacilityReport = Partial<ReportRow> &
  Pick<
    ReportRow,
    "organizationStatus" | "facilityId" | "reportingYear" | "reportKind"
  >;

export const reportCanBeMarkedAsNotReporting = (
  report: FacilityReport
): boolean => {
  const noReport = !report.tierIIReportId;
  // If the report is already in NotReporting we can mark it as NotReporting again to update the reason
  const openOrNotReporting =
    !!report.organizationStatus &&
    (orgStatusIsOpen(report.organizationStatus) ||
      report.organizationStatus === TierIiReportOrgStatus.NotReporting);
  return (
    report.reportingYear === currentTierIIReportingYear &&
    // If there is no report we can still mark the facility as not reporting
    (noReport ||
      (report.reportKind === TierIiReportKind.Annual && openOrNotReporting))
  );
};

export const reportCanBeMarkedAsReporting = (
  report: FacilityReport
): boolean => {
  return (
    report.reportingYear === currentTierIIReportingYear &&
    report.reportKind === TierIiReportKind.Annual &&
    report.organizationStatus === TierIiReportOrgStatus.NotReporting
  );
};

export const reportCanAddAssigneesOrReviewers = (
  report: FacilityReport
): boolean => {
  return (
    !!report.organizationStatus && orgStatusIsOpen(report.organizationStatus)
    // ML 8.2.24 - Making this more permissive while the current reporting year is still 2023
    // && report.reportingYear === currentTierIIReportingYear
  );
};

export const reportCanBeClosed = (report: FacilityReport): boolean => {
  return (
    report.reportKind === TierIiReportKind.Annual &&
    !!report.organizationStatus &&
    orgStatusIsOpen(report.organizationStatus)
  );
};

export const useFacilityReportEditable = () => {
  const { hasPermissionForFacility } = useAuthorization();

  const canMarkAsNotReporting = useCallback(
    (facilityTierIIReport: FacilityReport): boolean =>
      reportCanBeMarkedAsNotReporting(facilityTierIIReport) &&
      hasPermissionForFacility(facilityTierIIReport.facilityId, [
        Permission.VerifyTierIiReport,
      ]),
    [hasPermissionForFacility]
  );

  const canMarkAsReporting = useCallback(
    (facilityTierIIReport: FacilityReport): boolean =>
      reportCanBeMarkedAsReporting(facilityTierIIReport) &&
      hasPermissionForFacility(facilityTierIIReport.facilityId, [
        Permission.VerifyTierIiReport,
      ]),
    [hasPermissionForFacility]
  );

  const canAddAssigneesOrReviewers = useCallback(
    (facilityTierIIReport: FacilityReport): boolean =>
      reportCanAddAssigneesOrReviewers(facilityTierIIReport) &&
      hasPermissionForFacility(facilityTierIIReport.facilityId, [
        Permission.VerifyTierIiReport,
      ]),
    [hasPermissionForFacility]
  );

  const canCloseReport = useCallback(
    (facilityTierIIReport: FacilityReport): boolean =>
      reportCanBeClosed(facilityTierIIReport) &&
      hasPermissionForFacility(facilityTierIIReport.facilityId, [
        Permission.VerifyTierIiReport,
      ]),
    [hasPermissionForFacility]
  );

  return useMemo(
    () => ({
      canMarkAsNotReporting,
      canMarkAsReporting,
      canAddAssigneesOrReviewers,
      canCloseReport,
    }),
    [
      canMarkAsNotReporting,
      canMarkAsReporting,
      canAddAssigneesOrReviewers,
      canCloseReport,
    ]
  );
};
