import Check from "@mui/icons-material/Check";
import { GET_USERS_QUERY } from "./userQueries";
import { isEqual } from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { useCallback, useEffect, useState } from "react";
import { useTenant } from "hooks/useTenant";
import { GetUsersQuery } from "generated-graphql/graphql";

type WatcherRow = GetUsersQuery["users"]["items"][number];

type AssociateWatchersDialogProps = {
  open: boolean;
  onClose: () => void;
  existingWatcherIds?: string[];
  onSave: (watcherIds: string[]) => void;
};

export const AssociateWatchersDialog = ({
  open,
  onClose,
  existingWatcherIds,
  onSave,
}: AssociateWatchersDialogProps) => {
  const theme = useTheme();
  const { tenantId } = useTenant();

  const [selectedWatcherIds, setSelectedWatcherIds] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setSelectedWatcherIds(existingWatcherIds ?? []);
    }
  }, [existingWatcherIds, open]);

  const anyAssociationsModified = !isEqual(
    existingWatcherIds ?? [],
    selectedWatcherIds
  );

  const handleClose = useCallback(() => {
    setSelectedWatcherIds([]);
    onClose();
  }, [onClose]);

  const handleSave = useCallback(async () => {
    if (!anyAssociationsModified) {
      handleClose();
      return;
    }

    onSave(selectedWatcherIds);
    handleClose();
  }, [selectedWatcherIds, handleClose, onSave, anyAssociationsModified]);

  const columns: GridColDef<WatcherRow>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        `${params.row?.person?.first ?? ""} ${params.row?.person?.last ?? ""}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) => params.row.email,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="associate-watchers-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="associate-watchers-dialog-title">
        Associate Watchers
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={theme.spacing(1)}>
          <Typography variant="body1">
            Select watchers to associate with this task.
          </Typography>
          <OmnisearchDataGrid
            onSelectedIdsChanged={(ids) =>
              setSelectedWatcherIds(ids as string[])
            }
            selectedIds={selectedWatcherIds}
            dataQuery={GET_USERS_QUERY}
            columns={columns}
            defaultSearch={`tenantId:${tenantId}`}
            getItems={(data) => data.users.items}
            getCount={(data) => data.users.count}
            noDataMessage="No watchers available."
            initialPageSize={10}
            isURLDriven={false}
            // the minimum height where the grid won't have scrollbars with 10
            sx={{ height: 491 }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" startIcon={<Check />} onClick={handleSave}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
