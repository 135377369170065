import { useTheme } from "@mui/material";
import { Divider } from "@mui/material";

export function LeftNavDivider() {
  const theme = useTheme();

  return (
    <Divider
      variant="middle"
      color="#8D98A6"
      sx={{ my: theme.spacing(2), mx: theme.spacing(4) }}
      flexItem
    />
  );
}
