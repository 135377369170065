import { PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import _ from "lodash";
import { PortalCredential } from "../generated-graphql/graphql";

export function kebabToTitleCase(str?: string) {
  return _.chain(str)
    .split("-")
    .map((word) => _.upperFirst(_.lowerCase(word)))
    .join(" ")
    .value();
}

export function launchPortalWithEncampy(portalCredential: PortalCredential) {
  chrome.runtime.sendMessage(import.meta.env.VITE_ENCAMPY_EXTENSION_ID, {
    command: "LOGIN_TO_PORTAL",
    payload: {
      ...portalCredential.credential,
      portalType: portalCredential.type,
      portalUrl: portalCredential.url,
    },
  });
}

export function topLevelRoute(): string {
  return location.pathname.split("/")[1] ?? "";
}

export function convertURLParamsToGridFilterModel(
  params: URLSearchParams
): GridFilterModel {
  const filterModel: GridFilterModel = { items: [] };

  params.forEach((value, key) => {
    const [field, operator] = key.split("_");

    if (!operator) return;

    filterModel.items.push({
      field,
      operator,
      value,
    });
  });

  return filterModel;
}

export function convertGridFilterModelToURLParams(
  filter: GridFilterModel
): URLSearchParams {
  const params = new URLSearchParams();

  filter.items.forEach((item) => {
    params.append(`${item.field}_${item.operator}`, item.value ?? "");
  });

  return params;
}

export function isNilOrEmpty(str: string): boolean {
  if (str == null) return true;
  if (str === "") return true;
  if (/^\s*$/.test(str)) return true;

  return false;
}

export const brandColors = {
  "blue-jeans": {
    900: "#63ADF5",
    800: "#71B4F5",
    700: "#7FBBF6",
    600: "#8DC3F7",
    500: "#9BCAF8",
    400: "#A9D2F9",
    300: "#B8D9FA",
    200: "#C6E1FB",
    100: "#D4E8FC",
  },
  "dark-imperial-blue": {
    900: "#073D65",
    800: "#1D4E73",
    700: "#346081",
    600: "#4A718F",
    500: "#61839D",
    400: "#7795AB",
    300: "#8EA6B9",
    200: "#A4B8C7",
    100: "#BBCAD5",
  },
  "encamp-blue": {
    50: "#EFF6FF",
    100: "#CAE4FD",
    200: "#A8D2FA",
    300: "#93C5FD",
    400: "#85BFF8",
    500: "#4790D2",
    600: "#2574B7",
    700: "#13588B",
    800: "#073D65",
    900: "#051E3C",
  },
  "encamp-gray": {
    50: "#F9F9F9",
    100: "#F5F4F4",
    200: "#E7E7E7",
    300: "#D8D8D8",
    400: "#B4B4B4",
    500: "#808080",
    600: "#717171",
    700: "#545454",
    800: "#414141",
    900: "#1C1C1C",
  },
  "encamp-orange": {
    50: "#FFF7ED",
    100: "#FFE8CC",
    200: "#FED39D",
    300: "#FFB459",
    400: "#FFA73A",
    500: "#F08A0D",
    600: "#DB6A01",
    700: "#C35202",
    800: "#9F4B13",
    900: "#7C3200",
  },
  "encamp-purple": {
    50: "#F5F3FF",
    100: "#E2DEF7",
    200: "#D0C9EE",
    300: "#BDB4E6",
    400: "#9889D6",
    500: "#8674CD",
    600: "#6F5FBF",
    700: "#5944AB",
    800: "#422D96",
    900: "#240D80",
  },
  "encamp-red": {
    50: "#FEF2F2",
    100: "#FEE2E2",
    200: "#FECACA",
    300: "#FCA5A5",
    400: "#F87171",
    500: "#EF4444",
    600: "#DC2626",
    700: "#B91C1C",
    800: "#991B1B",
    900: "#7F1D1D",
  },
  "encamp-green": {
    50: "#f0fdfa",
    100: "#dffff8",
    200: "#bfece7",
    300: "#99e1d9",
    400: "#4dcbbd",
    500: "#00b4a0",
    600: "#00a290",
    700: "#277a6e",
    800: "#006c60",
    900: "#00584e",
  },
  fuschia: {
    50: "#FDF4FF",
    100: "#FAE8FF",
    200: "#F5D0FE",
    300: "#F0ABFC",
    400: "#E879F9",
    500: "#D946EF",
    600: "#C026D3",
    700: "#A21CAF",
    800: "#86198F",
    900: "#701A75",
  },
  gray: {
    50: "#f9fafb",
    100: "#f4f5f7",
    200: "#e5e7eb",
    300: "#d2d6dc",
    400: "#9fa6b2",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#252f3f",
    900: "#161e2e",
  },
  green: {
    50: "#F0FDF3",
    100: "#DCFCE3",
    200: "#BBF7C9",
    300: "#86EF9F",
    400: "#4ADE6D",
    500: "#22C548",
    600: "#16A337",
    700: "#15802E",
    800: "#166528",
    900: "#145323",
  },
  "maastricht-blue": {
    900: "#051E3C",
    800: "#1B324D",
    700: "#32465F",
    600: "#495B71",
    500: "#5F6F82",
    400: "#768494",
    300: "#8D98A6",
    200: "#A4ADB8",
    100: "#BAC1C9",
  },
  "neutral-gray": {
    50: "#FAFAFA",
    100: "#F4F4F5",
    200: "#E4E4E7",
    300: "#D4D4D8",
    400: "#A1A1AA",
    500: "#71717A",
    600: "#52525B",
    700: "#3F3F46",
    800: "#27272A",
    900: "#18181B",
  },
  orange: {
    100: "#FDDFB9",
    200: "#FDD4A2",
    300: "#FDC98B",
    400: "#FCBF73",
    500: "#FCB45C",
    600: "#FCA945",
    700: "#FB9F2E",
    800: "#FB9417",
    900: "#FB8A00",
  },
  purple: {
    900: "#6F5FBF",
    800: "#7C6DC4",
    700: "#897CCA",
    600: "#968AD0",
    500: "#A399D6",
    400: "#B0A7DC",
    300: "#BDB6E1",
    200: "#CAC4E7",
    100: "#D7D3ED",
    // : "#E4E1F3",
    // : "#F1F0F9",
  },
  "selective-yellow": {
    900: "#EDBA1D",
    800: "#EEC031",
    700: "#F0C646",
    600: "#F1CC5A",
    500: "#F3D36F",
    400: "#F5D983",
    300: "#F6DF98",
    200: "#F8E5AC",
    100: "#FAECC1",
  },
  "stone-gray": {
    50: "#FAFAF9",
    100: "#F5F5F4",
    200: "#E7E5E4",
    300: "#D6D3D1",
    400: "#A8A29E",
    500: "#78716C",
    600: "#57534E",
    700: "#44403C",
    800: "#292524",
    900: "#1C1917",
  },
  "medium-sea-green": {
    50: "#F0FDF3",
    100: "#DCFCDC",
    200: "#BBF7BB",
    300: "#86EF86",
    400: "#4ADE4A",
    500: "#22C522",
    600: "#16A316",
    700: "#158015",
    800: "#166516",
    900: "#145314",
  },
  "seafoam-green": {
    50: "#F0FDFA",
    100: "#DFFFF8",
    200: "#B7FBEE",
    300: "#83EFDB",
    400: "#52DBC2",
    500: "#00B4A0",
    600: "#1B9B88",
    700: "#277A6E",
    800: "#115E52",
    900: "#134E45",
  },
  "engine-red": {
    DEFAULT: "#F22A18",
    50: "#FEF2F1",
    100: "#FDDCD9",
    200: "#FAB0A9",
    300: "#F78379",
    400: "#F55748",
    500: "#F22A18",
    600: "#CC1B0B",
    700: "#9B1509",
    800: "#6B0E06",
    900: "#3B0803",
  },
};

export const useEncampTheme = (mode: PaletteMode) => {
  return createTheme({
    palette: {
      mode: mode,
      common: {
        black: brandColors["encamp-gray"][900],
        white: brandColors["neutral-gray"][50],
      },
      action: {
        active: brandColors["encamp-blue"][500],
        hover:
          mode === "dark"
            ? brandColors["encamp-blue"][700]
            : brandColors["encamp-blue"][200],
        selected:
          mode === "dark"
            ? brandColors["encamp-blue"][700]
            : brandColors["encamp-blue"][200],
        disabled: brandColors["encamp-gray"][300],
        disabledBackground: brandColors["encamp-gray"][100],
      },
      divider:
        mode === "dark"
          ? brandColors["encamp-gray"][800]
          : brandColors["encamp-gray"][300],
      tonalOffset: 0.2,
      primary: {
        main: brandColors["encamp-blue"][500],
        light: brandColors["encamp-blue"][200],
        dark: brandColors["encamp-blue"][700],
        contrastText: brandColors["neutral-gray"][50],
      },
      secondary: {
        main: brandColors["encamp-orange"][500],
        light: brandColors["encamp-orange"][200],
        dark: brandColors["encamp-orange"][700],
        contrastText: brandColors["neutral-gray"][50],
      },
      error: {
        main:
          mode === "dark"
            ? brandColors["encamp-red"][700]
            : brandColors["encamp-red"][500],
      },
      warning: {
        main:
          mode === "dark"
            ? brandColors["selective-yellow"][800]
            : brandColors["selective-yellow"][700],
      },
      info: {
        main:
          mode === "dark"
            ? brandColors["encamp-blue"][700]
            : brandColors["encamp-blue"][500],
      },
      success: {
        main:
          mode === "dark"
            ? brandColors["encamp-green"][700]
            : brandColors["encamp-green"][500],
      },
      ...(mode === "dark"
        ? {
            background: {
              default: brandColors["maastricht-blue"][900],
              paper: brandColors["maastricht-blue"][800],
            },
            text: {
              primary: brandColors["neutral-gray"][50],
              secondary: brandColors["neutral-gray"][200],
            },
          }
        : {
            background: {
              default: brandColors["encamp-gray"][50],
              paper: brandColors["encamp-gray"][100],
            },
            text: {
              primary: brandColors["dark-imperial-blue"][900],
              secondary: brandColors["dark-imperial-blue"][800],
            },
          }),
    },
  });
};
