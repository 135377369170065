import { Box, FormControlLabel, Switch } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import { EchoExportersQuery } from "generated-graphql/graphql";
import invariant from "invariant";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface EchoProps {
  handleRowClick?: (params: GridRowParams) => void;
  initialPageSize?: number;
  filterTenant?: boolean;
}

export type Row = EchoExportersQuery["echoExporters"]["items"][number];

const ECHO_EXPORTERS = gql(`
  query EchoExporters($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String!, $filterTenantId: String) {
    echoExporters(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId, filterTenantId: $filterTenantId) {
      items {
        id: registryId
        facName
        facStreet
        facCity
        facState
        facZip
        facActiveFlag
        facQtrsWithNc
        airFlag
        caaQtrsWithNc
        npdesFlag
        cwaQtrsWithNc
        rcraFlag
        rcraQtrsWithNc
      }
      count
    }
  }
`);

export const Echo: React.FC<EchoProps> = ({
  handleRowClick,
  initialPageSize = 25,
  filterTenant = true,
}) => {
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId, "tenantId is required");

  const [showMyFacilities, setShowMyFacilities] = useState(filterTenant);

  const onRowClick = (params: GridRowParams) => {
    if (handleRowClick) {
      handleRowClick(params);
    } else {
      const row: Row = params.row;
      navigate(`/o/${tenantId}/insights/echo/${row.id}`);
    }
  };

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      { field: "id", headerName: "Registry ID", flex: 0.6 },
      {
        field: "facName",
        headerName: "Facility Name",
        filterKeyType: "facility",
        flex: 1.2,
      },
      {
        field: "facilityAddress",
        headerName: "Address",
        flex: 1.8,
        valueGetter: (params) => {
          const { facStreet, facCity, facState, facZip } = params.row;
          return [facStreet, facCity, facState, facZip]
            .filter(Boolean)
            .join(", ");
        },
      },
      {
        field: "facActiveFlag",
        headerName: "Active",
        filterKeyType: "boolean",
        type: "boolean",
        flex: 0.5,
      },
      {
        field: "facQtrsWithNc",
        headerName: "Qtrs With NC",
        filterKeyType: "number",
        type: "number",
        flex: 0.5,
      },
      {
        field: "airFlag",
        headerName: "Air",
        filterKeyType: "boolean",
        type: "boolean",
        flex: 0.5,
      },
      {
        field: "caaQtrsWithNc",
        headerName: "CAA Qtrs With NC",
        filterKeyType: "number",
        type: "number",
        flex: 0.5,
      },
      {
        field: "npdesFlag",
        headerName: "Water",
        filterKeyType: "boolean",
        type: "boolean",
        flex: 0.5,
      },
      {
        field: "cwaQtrsWithNc",
        headerName: "CWA Qtrs With NC",
        filterKeyType: "number",
        type: "number",
        flex: 0.5,
      },
      {
        field: "rcraFlag",
        headerName: "Waste",
        filterKeyType: "boolean",
        type: "boolean",
        flex: 0.5,
      },
      {
        field: "rcraQtrsWithNc",
        headerName: "RCRA Qtrs With NC",
        filterKeyType: "number",
        type: "number",
        flex: 0.5,
      },
    ],
    []
  );

  return (
    <Box sx={{ pt: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showMyFacilities}
              onChange={() => setShowMyFacilities(!showMyFacilities)}
              name="showMyFacilities"
              color="primary"
            />
          }
          label="Only show my facilities"
        />
      </Box>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={ECHO_EXPORTERS}
        getItems={(data: any) => data?.echoExporters?.items ?? []}
        getCount={(data: any) => data?.echoExporters?.count ?? 0}
        noDataMessage="No ECHO Exporters found."
        initialPageSize={initialPageSize}
        onRowClick={onRowClick}
        dataQueryVariables={{
          filterTenantId: showMyFacilities ? tenantId : null,
          tenantId,
        }}
      />
    </Box>
  );
};
