import { gql } from "generated-graphql";
import { FacilityProductsQuery } from "generated-graphql/graphql";

export type FacilityProductRow =
  FacilityProductsQuery["facilityProducts"]["items"][0];

export const FACILITY_PRODUCTS_QUERY = gql(`
  query FacilityProducts($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilityProducts(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        product {
          id
          name
        }
        maxQuantity
        averageQuantity
        chemicalAmounts {
          chemicalId
          chemical {
            ...ChemicalPicker
          }
          maxAmount
          averageAmount
          unit
        }
        updatedAt
        issues {
          ...issue
        }
      }
      count
    }
  }
`);

export const DELETE_FACILITY_PRODUCT = gql(`
mutation DeleteFacilityProduct($id: ID!) {
  deleteFacilityProduct(id: $id) {
    id
  }
}
`);
