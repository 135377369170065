import { useLazyQuery } from "@apollo/client";
import { StorageType } from "encamp-shared/src/constants/tierii";
import { gql } from "generated-graphql";
import { FacilityChemicalMeasurementInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacilityChemicalMeasurement";
import { useCallback } from "react";

const VALIDATE_FACILITY_CHEMICAL_MEASUREMENT_INPUT = gql(`
  query ValidateFacilityChemicalMeasurementInput($input: FacilityChemicalMeasurementInput!) {
    facilityChemicalMeasurementInputValidator(input: $input) {
      ...issue
    }
  }
`);

export function useFacilityChemicalMeasurementInputValidation() {
  const [query] = useLazyQuery(VALIDATE_FACILITY_CHEMICAL_MEASUREMENT_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: FacilityChemicalMeasurementInput) => {
      const transformedInput = transform(input);
      const { data } = await query({
        variables: { input: transformedInput },
      });
      return data?.facilityChemicalMeasurementInputValidator ?? [];
    },
    [query]
  );
}
