import { useApolloClient, useQuery, useMutation } from "@apollo/client";
import OneSchemaImporter from "@oneschema/react";
import { ImportButton } from "components/ImportButton";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { gql } from "generated-graphql";
import { startCase } from "lodash";
import { useToken } from "providers/token";
import { useEffect, useRef, useState } from "react";
import { useAlerts } from "../Alerts/AlertProvider";
import { useParams } from "react-router-dom";
import { ProcessingFunction } from "generated-graphql/graphql";
import invariant from "tiny-invariant";

interface ImporterProps {
  templateType: OneSchemaTemplateType;
  refetchQueries?: string[];
  label?: string;
  style?: React.CSSProperties;
  importType?: "webhook" | "file-upload";
  processingFunction?: ProcessingFunction;
}

interface WebhookImportConfig {
  type: "webhook";
  key: string;
}
interface LocalImportConfig {
  type: "local";
  metadataOnly?: boolean;
}
interface FileUploadImportConfig {
  type: "file-upload";
  url: string;
  format?: "json" | "csv";
  headers?: {
    [headerName: string]: string;
  };
}
type ImportConfig =
  | WebhookImportConfig
  | LocalImportConfig
  | FileUploadImportConfig;

const GET_ONESCHEMA_JWT = gql(`
  query GetOneSchemaJwt($tenantId: String) {
    getOneSchemaJwt(tenantId: $tenantId)
  }
`);

const GENERATE_IMPORTER_PRESIGNED_URL = gql(`
  mutation GenerateImporterPresignedUrl($fileName: String!, $tenantId: ID!, $facilityId: ID, $directory: String, $contentType: String!) {
    generatePresignedUrl(fileName: $fileName, tenantId: $tenantId, facilityId: $facilityId, directory: $directory, contentType: $contentType) {
      key
      presignedUrl
    }
  }
`);

const PROCESS_ONESCHEMA_FILE_UPLOAD = gql(`
  mutation ProcessOneschemaFileUpload($input: ProcessOneschemaFileUploadInput!) {
    processOneschemaFileUpload(input: $input) {
      result
    }
  }
`);

export function Importer({
  templateType,
  refetchQueries = [],
  label,
  style,
  importType = "webhook",
  processingFunction,
}: ImporterProps) {
  const initialized = useRef(false);
  const alerts = useAlerts();
  const apolloClient = useApolloClient();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const { getIdTokenRefreshIfExpired } = useToken();
  const [isSuccess, setIsSuccess] = useState(false);
  const { tenantId } = useParams();
  invariant(tenantId, "tenantId is required");
  const [importConfig, setImportConfig] = useState<ImportConfig | null>(null);

  const { data: oneschemaJwtQueryData } = useQuery(GET_ONESCHEMA_JWT, {
    variables: { tenantId },
  });

  const [generatePresignedUrl] = useMutation(GENERATE_IMPORTER_PRESIGNED_URL);
  const [processFileUpload] = useMutation(PROCESS_ONESCHEMA_FILE_UPLOAD);

  const getImportConfig = async (): Promise<ImportConfig | null> => {
    if (importType === "webhook") {
      return {
        type: "webhook",
        key: `${import.meta.env.VITE_ENV}-import`,
      };
    } else if (importType === "file-upload") {
      try {
        const { data } = await generatePresignedUrl({
          variables: {
            fileName: `${templateType}_${new Date().toISOString()}.csv`,
            tenantId: tenantId,
            facilityId: undefined,
            directory: "one-schema-uploads",
            contentType: "text/csv",
          },
        });
        return {
          type: "file-upload",
          url: data?.generatePresignedUrl?.presignedUrl || "",
          format: "csv",
        };
      } catch (error) {
        console.error("Error generating presigned URL:", error);
        alerts.error("Failed to generate upload URL. Please try again.");
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    async function initializeImporter() {
      if (!initialized.current) {
        initialized.current = true;
        setIsLoading(true);

        const idToken = await getIdTokenRefreshIfExpired();
        try {
          const response = await fetch(
            import.meta.env.MODE === "development"
              ? `http://localhost:4000/oneschema/templates/${templateType}`
              : `${
                  import.meta.env.VITE_API_URL
                }/oneschema/templates/${templateType}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${idToken}`,
              },
            }
          );
          const template = (await response.json()) as { template_key: string };
          setTemplateName(template.template_key);
        } catch (error) {
          console.error(error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    }

    initializeImporter();
  }, [templateType, getIdTokenRefreshIfExpired]);

  const handleOpenImporter = async () => {
    setIsLoading(true);
    try {
      const config = await getImportConfig();
      setImportConfig(config);
      setIsOpen(true);
    } catch (error) {
      console.error("Error opening importer:", error);
      alerts.error("Failed to open importer. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccess = async () => {
    const handleSuccessfulImport = async (message: any) => {
      alerts.success(message);
      if (refetchQueries.length) {
        await apolloClient.refetchQueries({
          include: refetchQueries,
        });
      }
      setIsSuccess(true);
      setIsOpen(false);
    };

    if (
      processingFunction &&
      importConfig &&
      importConfig.type === "file-upload"
    ) {
      try {
        const { data } = await processFileUpload({
          variables: {
            input: {
              tenantId: tenantId,
              url: importConfig.url,
              processingFunction,
            },
          },
        });

        await handleSuccessfulImport(
          `Successfully created job: ${data?.processOneschemaFileUpload.result}`
        );
      } catch (error) {
        console.error("Error processing file upload:", error);
        alerts.error("An error occurred during file processing");
      }
    } else {
      await handleSuccessfulImport("Successfully imported");
    }
  };

  const onError = (message: any) => {
    console.error(message);
    alerts.error("An error occurred during the import");
  };

  const importer = templateName &&
    oneschemaJwtQueryData?.getOneSchemaJwt &&
    importConfig && (
      <OneSchemaImporter
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        clientId={import.meta.env.VITE_ONE_SCHEMA_CLIENT_ID}
        userJwt={oneschemaJwtQueryData?.getOneSchemaJwt}
        templateKey={templateName}
        importConfig={importConfig}
        devMode={import.meta.env.MODE === "development"}
        className="oneschema-importer"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1300,
        }}
        customizationOverrides={{
          hideLogo: true,
          uploadPaneHeaderText: `Import ${startCase(templateType)}`,
          includeExcelTemplate: true,
          importExperience: "blockIfErrors",
          mappingStrategy: ["exact", "historical"],
          skipMapping: ["exact", "historical"],
          autofixAfterMapping: true,
        }}
        onSuccess={onSuccess}
        onError={onError}
      />
    );

  return (
    <>
      {importer}
      <ImportButton
        loading={isLoading}
        disabled={isError || !templateName}
        success={isSuccess}
        onClick={handleOpenImporter}
        label={label}
        style={{ ...style }}
      />
    </>
  );
}
