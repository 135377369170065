import {
  Box,
  Grid,
  Skeleton,
  Stack,
  useTheme,
  Typography,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { MailingAddress } from "components/Facility/MailingAddress";
import { PhysicalAddress } from "components/Facility/PhysicalAddress";
import { FireDepartmentPicker } from "components/FireDepartmentPicker";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { LepcPicker } from "components/LepcPicker";
import { NaicsPicker } from "components/NaicsPicker";
import { LabeledText } from "components/Typography/LabeledText";
import { gql } from "generated-graphql";
import {
  FireDepartmentPickerFragment,
  LepcPickerFragment,
  ProgramArea,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacility";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { FacilityDetailsInput } from "routes/Customer/Facility/FacilityProfile";
import { reportStepMetadata } from "util/constants";
import { ReportFloatingBar } from "./ReportFloatingBar";
import { StepperPageHeader } from "./StepperPageHeader";
import { useNavigateReportSaveCancel } from "./useNavigateReportSaveCancel";
import {
  regulatoryFields,
  useFacilityInfoIssues,
  useReport,
  useUpdateFacilityReport,
} from "./useReport";
import { useFacilityInputValidation } from "./validationHooks/useFacilityInputValidation";
import { SubSection } from "components/SubSection";
import { useTouchReportMutation } from "./useTouchReportMutation";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";

gql(`
  fragment facilityInfo on Facility {
    id
    name
    companyName
    department
    isMailingAddressSameAsFacility
    isMailingAddressEncamp
    isUnmanned
    maxNumOccupants
    fireDepartmentId
    fireDepartment {
      ...FireDepartmentPicker
    }
    lepcId
    lepc {
      ...LepcPicker
    }
    dbNumber
    naicsCode
    longitude
    latitude
    state
    county
    municipality
    phone
    phone24Hour
    streetAddress1
    streetAddress2
    city
    state
    zip
    mailingStreetAddress1
    mailingStreetAddress2
    mailingCity
    mailingState
    mailingZip
    dateClosed
    dateOpened
    dateInactive
    status
  }
`);

export const Facility: React.FC = () => {
  const alerts = useAlerts();
  const { data, loading } = useReport();
  const navigateToOverview = useNavigateReportSaveCancel();

  const defaultValues = useMemo(
    () => ({
      name: data?.tierIIReport.facility.name ?? "",
      companyName: data?.tierIIReport.facility.companyName ?? null,
      department: data?.tierIIReport.facility.department ?? null,
      isMailingAddressSameAsFacility: data?.tierIIReport.facility
        .isMailingAddressEncamp
        ? "encamp"
        : data?.tierIIReport.facility.isMailingAddressSameAsFacility ?? null,
      isUnmanned: data?.tierIIReport.facility.isUnmanned ?? null,
      maxNumOccupants: data?.tierIIReport.facility.maxNumOccupants ?? null,
      fireDepartmentId: data?.tierIIReport.facility.fireDepartment?.id ?? null,
      lepcId: data?.tierIIReport.facility.lepc?.id ?? null,
      dbNumber: data?.tierIIReport.facility.dbNumber ?? null,
      naicsCode: data?.tierIIReport.facility.naicsCode ?? null,
      longitude: data?.tierIIReport.facility.longitude ?? null,
      latitude: data?.tierIIReport.facility.latitude ?? null,
      phone: data?.tierIIReport.facility.phone ?? null,
      phone24Hour: data?.tierIIReport.facility.phone24Hour ?? null,
      county: data?.tierIIReport.facility.county ?? null,
      municipality: data?.tierIIReport.facility.municipality ?? null,

      streetAddress1: data?.tierIIReport.facility.streetAddress1 ?? null,
      streetAddress2: data?.tierIIReport.facility.streetAddress2 ?? null,
      city: data?.tierIIReport.facility.city ?? null,
      state: data?.tierIIReport.facility.state ?? null,
      zip: data?.tierIIReport.facility.zip ?? null,

      mailingStreetAddress1:
        data?.tierIIReport.facility.mailingStreetAddress1 ?? null,
      mailingStreetAddress2:
        data?.tierIIReport.facility.mailingStreetAddress2 ?? null,
      mailingCity: data?.tierIIReport.facility.mailingCity ?? null,
      mailingState: data?.tierIIReport.facility.mailingState ?? null,
      mailingZip: data?.tierIIReport.facility.mailingZip ?? null,
    }),
    [data?.tierIIReport]
  );

  const form = useValidatingForm<FacilityDetailsInput>(
    defaultValues,
    useFacilityInfoIssues(),
    useFacilityInputValidation({
      facilityId: data?.tierIIReport.facility.id ?? "",
      reportId: data?.tierIIReport.id,
      omit: [
        "facilityDocuments",
        "contacts",
        "stateFields",
        ...regulatoryFields,
      ],
    })
  );

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitted },
  } = form;
  const hasPendingChanges = isDirty && !isSubmitting && !isSubmitted;

  const [updateFacility, { loading: submitting }] = useUpdateFacilityReport();
  const { handleSave: handleTouch } = useTouchReportMutation(
    data?.tierIIReport.id ?? "",
    data?.tierIIReport.touchedSteps ?? [],
    TierIiReportStep.Facility
  );

  const onSubmit = useCallback(
    async (saveData: FacilityDetailsInput) => {
      if (!data) return;

      const input = transform(saveData);

      try {
        await updateFacility({
          variables: {
            id: data.tierIIReport.facility.id,
            facility: input,
            reportId: data.tierIIReport.id,
            programArea: ProgramArea.Epcra,
          },
          awaitRefetchQueries: true,
        });
        await handleTouch();
      } catch (err) {
        alerts.error("Error saving facility information.");
        return;
      }
      alerts.success("Facility information saved successfully.");
    },
    [alerts, data, updateFacility, handleTouch]
  );

  return (
    <FormProvider {...form}>
      <form>
        <Stack>
          <StepperPageHeader
            header="Facility"
            description={reportStepMetadata.FACILITY.overviewDescription}
          />

          {loading ? (
            <Skelly />
          ) : (
            <>
              <BasicInfo />
              <FacilityAddress />
              <FacilityMailingAddress />
              <AdditionalInfo />
              <LocalAgencies />
            </>
          )}

          <ReportFloatingBar
            saving={submitting}
            onSaveClick={handleSubmit(onSubmit)}
            loading={loading}
            onCancel={navigateToOverview}
            issues={form.issues}
          />
          <UnfinishedChangesPrompt when={hasPendingChanges} />
        </Stack>
      </form>
    </FormProvider>
  );
};

function Skelly() {
  const theme = useTheme();
  return (
    <Stack gap={theme.spacing(2)}>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>
    </Stack>
  );
}

function BasicInfo() {
  const theme = useTheme();
  const { control } = useFormContext();
  return (
    <Box marginTop={theme.spacing(1)}>
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
      >
        <Grid item xs={12}>
          <FormTextField name="name" label="Facility Name" control={control} />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="companyName"
            label="Company Name"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="department"
            label="Department"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField name="phone" label="Phone Number" control={control} />
        </Grid>

        <Grid item xs={6}>
          <FormTextField
            name="phone24Hour"
            label="24-hour Phone Number"
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function FacilityAddress() {
  const theme = useTheme();
  return (
    <Box marginTop={theme.spacing(3)}>
      <Typography variant="h5">Facility Address</Typography>
      <PhysicalAddress />
    </Box>
  );
}

function FacilityMailingAddress() {
  const theme = useTheme();

  return (
    <Box marginTop={theme.spacing(3)}>
      <Typography variant="h5">Mailing Address</Typography>
      <MailingAddress />
    </Box>
  );
}

function AdditionalInfo() {
  const theme = useTheme();
  const { control, watch, setValue } = useFormContext();

  const isUnmanned = watch("isUnmanned");

  useEffect(() => {
    if (isUnmanned) {
      setValue("maxNumOccupants", 0);
    }
  }, [isUnmanned, setValue]);

  return (
    <Box marginTop={theme.spacing(3)}>
      <Typography variant="h5">Additional Info</Typography>
      <Grid
        container
        columnSpacing={theme.spacing(3)}
        rowSpacing={theme.spacing(1)}
        marginTop={theme.spacing(2)}
      >
        <Grid item xs={3}>
          <RadioGroupField
            label="Is this an unmanned facility?"
            name="isUnmanned"
            control={control}
            radioOptions={[
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            name="maxNumOccupants"
            label="Max Occupancy"
            intOnly
            disabled={isUnmanned}
            control={control}
          />
        </Grid>

        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormTextField
            name="dbNumber"
            label="Dun & Bradstreet Number"
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="naicsCode"
            control={control}
            render={({ field, fieldState }) => (
              <NaicsPicker {...field} {...fieldState} />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function LocalAgencies() {
  const theme = useTheme();
  const { data } = useReport();
  const { control } = useFormContext();

  // State for keeping track of the fire department and lepc selections
  // that the user makes so that we can display them in the form.
  const [selectedFireDepartment, setSelectedFireDepartment] =
    useState<FireDepartmentPickerFragment | null>(
      data?.tierIIReport.facility.fireDepartment ?? null
    );
  const [selectedLepc, setSelectedLepc] = useState<LepcPickerFragment | null>(
    data?.tierIIReport.facility.lepc ?? null
  );

  const [state, county] = useWatch({
    control,
    name: ["state", "county"],
  });

  return (
    <Box marginTop={theme.spacing(3)} marginLeft={0}>
      <Typography variant="h5">Local Agencies</Typography>
      <SubSection
        title="Local Emergency Planning Committee (LEPC)"
        style={{
          marginLeft: 0,
        }}
      >
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)}
        >
          <Grid item xs={12}>
            <Controller
              name="lepcId"
              control={control}
              render={({ field, fieldState }) => (
                <LepcPicker
                  {...field}
                  {...fieldState}
                  facilityState={state}
                  facilityCounty={county}
                  onChange={(lepc) => {
                    setSelectedLepc(lepc);
                    field.onChange(lepc?.id ?? null);
                  }}
                  value={selectedLepc ? selectedLepc : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LabeledText
              label="Street Address"
              value={selectedLepc?.mailingAddress}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="City" value={selectedLepc?.city} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="State" value={selectedLepc?.state} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="Zip" value={selectedLepc?.zip} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText
              label="County"
              value={selectedLepc?.county ? selectedLepc?.county : " "}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Phone" value={selectedLepc?.phone} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Email" value={selectedLepc?.email} />
          </Grid>
        </Grid>
      </SubSection>
      <SubSection
        title="Fire District"
        style={{
          marginLeft: 0,
        }}
      >
        <Grid
          container
          columnSpacing={theme.spacing(3)}
          rowSpacing={theme.spacing(1)}
        >
          <Grid item xs={12}>
            <Controller
              name="fireDepartmentId"
              control={control}
              render={({ field, fieldState }) => (
                <FireDepartmentPicker
                  {...field}
                  {...fieldState}
                  facilityState={state}
                  facilityCounty={county}
                  onChange={(fireDepartment) => {
                    setSelectedFireDepartment(fireDepartment);
                    field.onChange(fireDepartment?.id ?? null);
                  }}
                  value={selectedFireDepartment ? selectedFireDepartment : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LabeledText
              label="Street Address"
              value={selectedFireDepartment?.mailingAddress}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="City" value={selectedFireDepartment?.city} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="State" value={selectedFireDepartment?.state} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText label="Zip" value={selectedFireDepartment?.zip} />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabeledText
              label="County"
              value={
                selectedFireDepartment?.county
                  ? selectedFireDepartment?.county
                  : " "
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Phone" value={selectedFireDepartment?.phone} />
          </Grid>
          <Grid item sm={12} md={6}>
            <LabeledText label="Email" value={selectedFireDepartment?.email} />
          </Grid>
        </Grid>
      </SubSection>
    </Box>
  );
}
