import { useQuery } from "@apollo/client";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { gql } from "generated-graphql";
import React, { forwardRef, useMemo, useState } from "react";
import { UseControllerReturn } from "react-hook-form";
import { ErrorDisplay } from "./Forms/ErrorDisplay";

type Props = {
  facilityState: string;
  disabled?: boolean;
} & UseControllerReturn<{ county?: string | null }, "county">;

const COUNTIES_QUERY = gql(`
  query Counties($state: String!) {
    counties(state: $state) {
      items
      count
    }
  }
`);

export const CountyPicker: React.FC<Props> = forwardRef(function CountyPicker(
  {
    field: { value, onChange },
    facilityState,
    fieldState: { error },
    disabled,
  },
  ref
) {
  const [inputValue, setInputValue] = useState("");
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const { data, loading, previousData } = useQuery(COUNTIES_QUERY, {
    variables: { state: facilityState },
    skip: !searchIsOpen,
    fetchPolicy: "cache-and-network",
  });

  const options = useMemo(
    () => data?.counties.items ?? previousData?.counties.items ?? [],
    [data?.counties.items, previousData?.counties.items]
  );

  return (
    <>
      <Autocomplete
        ref={ref}
        freeSolo
        options={options}
        disabled={disabled}
        noOptionsText="Search for a county"
        onOpen={() => setSearchIsOpen(true)}
        onClose={() => setSearchIsOpen(false)}
        loading={loading}
        inputValue={inputValue}
        onInputChange={(_, val) => setInputValue(val)}
        onBlur={(e) => onChange((e.target as HTMLInputElement).value)}
        value={value ?? null}
        onChange={(_, val) => onChange(val)}
        getOptionLabel={(option: string) => option}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label="County"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
            <ErrorDisplay error={error} />
          </>
        )}
      />
    </>
  );
});
