import { useEffect, useState } from "react";

/**
 * @deprecated This hook uses old custom implementation of local storage.
 * Recommend using "use-local-storage-state" package instead.
 */
export function useLocalStorageState<T = any>(key?: string, initialValue?: T) {
  const val = useState<T>(() => {
    if (key) {
      const persistedValue = localStorage.getItem(key);
      try {
        return persistedValue !== null
          ? JSON.parse(persistedValue)
          : initialValue;
      } catch {
        return initialValue;
      }
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    if (key) {
      localStorage.setItem(key, JSON.stringify(val[0]));
    }
  }, [key, val]);

  return val;
}
