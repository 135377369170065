import { Facility } from "@prisma/client";

export function getOakNotReportingReason(facility?: Partial<Facility>) {
  if (!facility) return null;

  if (facility.isClosed) {
    return "Closed";
  }

  if (facility.colocationReportingFacilityId) {
    return "Co-located";
  }

  if (facility.isNotReporting) {
    return "Under Thresholds";
  }

  if (facility.isInactive) {
    return "Is Inactive";
  }

  if (facility.isNotReportingThroughEncamp) {
    return "Without Encamp";
  }

  return null;
}
