import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid } from "components/DataGrid";
import { Dialog } from "components/Dialog";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { RolePicker } from "components/RolePicker";
import { SaveButton } from "components/SaveButton";
import { gql } from "generated-graphql";
import {
  CreateUserInput,
  Permission,
  Role,
  UpdateUserInput,
  UserDetailQuery,
  UserStatus,
  UserType,
} from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { prettyPrintUserStatus } from ".";
import { NoRowsOverlay } from "../Chemicals/Inventory/Facility/NoRowsOverlay";
import { useUserInputValidation } from "./validationHooks/useUserInputValidation";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useAuthorization } from "hooks/useAuthorization";
import { useCurrentUser } from "hooks/useCurrentUser";
import { RoleNamesEnum } from "encamp-shared/src/constants/permissionsTypes";
import AssociateFacilitiesDialog from "./AssociateFacilitiesDialog";
import { getConfirmMessage } from "./util";
import { useFeatureFlags } from "hooks/useFeatureFlags";

const _roleGivesAccessToAllFacilities = (
  role: Pick<Role, "permissions"> | null | undefined
) => {
  if (role?.permissions == null) return false;

  return (
    role.permissions.includes(Permission.ReadAllFacility) ||
    role.permissions.includes(Permission.WriteAllFacility)
  );
};

type UserFormData = CreateUserInput & Partial<UpdateUserInput>;

type EditUserDialogProps = {
  onClose: () => void;
  initialUserId?: string;
  open: boolean;
  introText?: string;
  tenantId: string;
};

const USER_DETAIL_QUERY = gql(`
query UserDetail($userId: ID!) {
    user(id: $userId) {
      status
      id
      email
      type
      facilities {
        id
        name
        streetAddress1
        streetAddress2
        city
        state
        zip
        customerFacilityId
      }
      UserTenant {
        id
        tenantId
        role {
          id
          name
          permissions
        }
      }
      person {
        id
        first
        last
      }
    }
}
`);

const EDIT_USER_MUTATION = gql(`
mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
  }
}
`);

type Row = NonNullable<UserDetailQuery["user"]>["facilities"][number];

const UserDetailFormSkeleton = () => {
  const theme = useTheme();
  return (
    <Stack spacing={theme.spacing(2)}>
      <Box paddingBottom={theme.spacing(1)}>
        <Skeleton
          variant="rounded"
          height={56}
          sx={{
            height: theme.spacing(2.5),
            marginTop: theme.spacing(0.5),
          }}
        />
      </Box>
      <Box paddingBottom={theme.spacing(1)}>
        <Skeleton variant="rounded" height={56} />
      </Box>
      <Box paddingBottom={theme.spacing(1)}>
        <Skeleton variant="rounded" height={56} />
      </Box>
      <Box paddingBottom={theme.spacing(1)}>
        <Skeleton variant="rounded" height={56} />
      </Box>
      <Box paddingBottom={theme.spacing(1)}>
        <Skeleton variant="rounded" height={56} />
      </Box>
    </Stack>
  );
};

export const EditUserDialog = ({
  onClose,
  open,
  initialUserId,
  introText,
  tenantId,
}: EditUserDialogProps) => {
  const theme = useTheme();
  const { hasPermissions } = useAuthorization();
  const { tenant } = useTenant();
  const { featureFlags } = useFeatureFlags();
  const isFacilitySelfAssignmentEnabled =
    featureFlags?.["facility-self-assignment"] ?? false;
  const [enableSave, setEnableSave] = useState<boolean>(false);

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        field: "facility",
        headerName: "Associated Facility",
        flex: 0.5,
        valueGetter: ({ row: { customerFacilityId, name } }) => {
          return `${name}${
            customerFacilityId ? ` (${customerFacilityId})` : ""
          }`;
        },
      },
      {
        field: "address",
        headerName: "Street Address",
        flex: 0.5,
        renderCell({ row }) {
          if (!row.streetAddress1) return "";
          const { streetAddress1, city, state, zip } = row;
          return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
        },
      },
    ],
    []
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [associateFacilitiesOpen, setAssociateFacilitiesOpen] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const { isStaff } = useCurrentUser();
  const roleAccessesAllFacilities = useMemo(
    () => _roleGivesAccessToAllFacilities(role),
    [role]
  );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [userId, setUserId] = useState(initialUserId);
  const alerts = useAlerts();

  const { data: userDetail, loading } = useQuery(USER_DETAIL_QUERY, {
    variables: {
      userId: initialUserId ?? "",
    },
    skip: initialUserId == null,
    nextFetchPolicy: "cache-first",
  });

  const [userEmail, setUserEmail] = useState(userDetail?.user?.email);
  const editedUserIsStaff =
    !!userDetail?.user && userDetail.user.type !== UserType.Customer;

  const [updateUser, { loading: updatingUser }] = useMutation(
    EDIT_USER_MUTATION,
    {
      refetchQueries: [
        "UserDetail",
        "Users",
        "GetUser",
        "FacilityTierIIReports",
        "InventoryFacilities",
      ],
    }
  );

  const userFacilities = useMemo(
    () => userDetail?.user?.facilities ?? [],
    [userDetail?.user?.facilities]
  );

  const userTenant = useMemo(
    () =>
      userDetail?.user?.UserTenant?.find(
        (userTenant) => userTenant.tenantId === tenantId
      ),
    [userDetail?.user?.UserTenant, tenantId]
  );

  const defaultValues = useMemo(() => {
    return {
      id: userId,
      tenantId,
      email: userEmail ?? "",
      first: userDetail?.user?.person?.first ?? "",
      last: userDetail?.user?.person?.last ?? "",
      roleId: userTenant?.role?.id ?? "",
      status: userDetail?.user?.status ?? null,
    };
  }, [
    userId,
    userEmail,
    userDetail?.user?.person?.first,
    userDetail?.user?.person?.last,
    userDetail?.user?.status,
    tenantId,
    userTenant?.role?.id,
  ]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isDirty },
  } = useValidatingForm<UserFormData>(
    defaultValues,
    [],
    useUserInputValidation()
  );

  const handleClose = useCallback(() => {
    onClose();
    reset();
    setEnableSave(false);
  }, [onClose, reset]);

  const { first, last, email, roleId } = watch();

  const roleEditPermissionChange: "increase" | "decrease" | "noChange" =
    useMemo(() => {
      const defaultRoleGivesAccessToAllFacilities =
        _roleGivesAccessToAllFacilities(
          userTenant?.role ?? { permissions: [] }
        );
      if (roleAccessesAllFacilities && !defaultRoleGivesAccessToAllFacilities)
        return "increase";
      if (!roleAccessesAllFacilities && defaultRoleGivesAccessToAllFacilities)
        return "decrease";
      return "noChange";
    }, [roleAccessesAllFacilities, userTenant?.role]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    setUserId(initialUserId);
    setUserEmail(userDetail?.user?.email);
    setRole(userDetail?.user?.UserTenant?.[0]?.role ?? null);
  }, [initialUserId, userDetail?.user?.email, userDetail?.user?.UserTenant]);

  const noRowsMessage = useMemo(() => {
    if (roleAccessesAllFacilities || editedUserIsStaff)
      return `This user has access to all facilities ${
        tenant?.isPartner ? "in this tenant and all child tenants" : ""
      } due to being a "${editedUserIsStaff ? "Staff user" : role?.name}"`;

    return "This user does not have access to any facilities";
  }, [
    roleAccessesAllFacilities,
    tenant?.isPartner,
    role?.name,
    editedUserIsStaff,
  ]);

  const userName = useMemo(() => {
    if (first && last && email) return `${first} ${last} (${email})`;
    if (email) return email;
    return "";
  }, [first, last, email]);

  const confirmMessage = getConfirmMessage({
    roleEditPermissionChange,
    roleName: role?.name ?? "",
    userName,
    isPartner: tenant?.isPartner ?? false,
    action: "edit",
  });

  const saveUser = useCallback(
    async (formData: UserFormData) => {
      const { first, last, roleId, status } = formData;
      if (initialUserId == null) return;

      await updateUser({
        variables: {
          input: {
            id: initialUserId,
            first,
            last,
            roleId: roleId === "" ? null : roleId,
            status,
            tenantId,
          },
        },
      });
    },
    [updateUser, initialUserId, tenantId]
  );

  const submitAndClose = useCallback(
    async (formData: UserFormData) => {
      try {
        await saveUser(formData);
        handleClose();
        alerts.success(`Successfully updated user ${userName}`);
      } catch (err) {
        alerts.error(`Error updating user.`, err);
      }
    },
    [saveUser, handleClose, userName, alerts]
  );

  const onSubmit = useCallback(
    async (formData: UserFormData) => {
      if (loading) return; // can we just disable the button

      if (roleEditPermissionChange !== "noChange") {
        setShowConfirmationDialog(true);
        return;
      }

      await submitAndClose(formData);
    },
    [loading, submitAndClose, roleEditPermissionChange]
  );

  const allowWrite = useMemo(() => {
    // Don't allow write for staff users
    if (editedUserIsStaff) return "no";

    // if we have the write permission we can write
    if (hasPermissions([Permission.WriteAllUserTenant])) return "yes";

    // Third case for self assignment users, only enables name editing
    if (
      !hasPermissions([Permission.WriteAllUserTenant]) &&
      isFacilitySelfAssignmentEnabled
    )
      return "limited";

    return "no";
  }, [hasPermissions, editedUserIsStaff, isFacilitySelfAssignmentEnabled]);

  const dialogTitle = useMemo(() => {
    if (allowWrite == "no") return "View User";

    return "Edit User";
  }, [allowWrite]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Box>{dialogTitle}</Box>
            {(hasPermissions([Permission.WriteAllUserTenant]) ||
              isFacilitySelfAssignmentEnabled) && (
              <Box>
                <Button
                  variant="contained"
                  disabled={roleAccessesAllFacilities || editedUserIsStaff}
                  onClick={() => !loading && setAssociateFacilitiesOpen(true)}
                >
                  Associate Facilities
                </Button>
              </Box>
            )}
          </Stack>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {introText && (
              <Alert severity="info" sx={{ mb: theme.spacing(3) }}>
                {introText}
              </Alert>
            )}
            <Box>
              <Stack direction="column">
                <Stack direction="row">
                  <Grid container spacing={theme.spacing(3)}>
                    <Grid item md={4}>
                      {loading ? (
                        <UserDetailFormSkeleton />
                      ) : (
                        <Stack>
                          <FormTextField
                            name="email"
                            label="Email"
                            type="email"
                            control={control}
                            disabled={true}
                            sx={{ mb: theme.spacing(0.5) }}
                          />

                          <FormTextField
                            name="first"
                            label="First Name"
                            control={control}
                            disabled={allowWrite == "no"}
                          />

                          <FormTextField
                            name="last"
                            label="Last Name"
                            control={control}
                            disabled={allowWrite == "no"}
                          />
                          <Controller
                            name="roleId"
                            control={control}
                            render={({ field, fieldState }) => (
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: theme.spacing(3) }}
                              >
                                <RolePicker
                                  {...field}
                                  {...fieldState}
                                  value={roleId ?? ""}
                                  disabled={allowWrite !== "yes"}
                                  roleDisabled={(role) =>
                                    !isStaff &&
                                    role.name === RoleNamesEnum.superAdmin
                                  }
                                  onChange={(role) => {
                                    setRole(role);
                                    setValue("roleId", role?.id ?? null, {
                                      shouldDirty: true,
                                    });
                                  }}
                                  tenantId={tenantId}
                                />
                              </FormControl>
                            )}
                          />
                          <FormSelect
                            name="status"
                            control={control}
                            label="Status"
                            disabled={allowWrite !== "yes"}
                            selectItems={Object.values(UserStatus)
                              .filter(
                                (userStatus) =>
                                  userStatus !== UserStatus.NotInvited
                              )
                              .map((userStatus) => ({
                                display: prettyPrintUserStatus(userStatus),
                                value: userStatus,
                                disabled: userStatus === UserStatus.Pending,
                              }))}
                          />
                        </Stack>
                      )}
                    </Grid>

                    <Grid item md={8}>
                      <DataGrid
                        columns={columns}
                        rows={roleAccessesAllFacilities ? [] : userFacilities}
                        rowCount={
                          roleAccessesAllFacilities ? 0 : userFacilities.length
                        }
                        pagination
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        loading={loading}
                        slots={{
                          noRowsOverlay: (props) => (
                            <NoRowsOverlay {...props} message={noRowsMessage} />
                          ),
                        }}
                        sx={{
                          "& .MuiDataGrid-virtualScroller": {
                            minHeight: "380px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              {allowWrite == "no" ? "Close" : "Cancel"}
            </Button>

            {allowWrite !== "no" && (
              <SaveButton
                loading={updatingUser && !showConfirmationDialog}
                disabled={!enableSave && !isDirty}
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
      {userId && userEmail && (
        <AssociateFacilitiesDialog
          open={associateFacilitiesOpen}
          onClose={() => {
            setAssociateFacilitiesOpen(false);
          }}
          userId={userId}
          userEmail={userEmail}
          userFacilities={userFacilities}
          tenantId={tenantId ?? ""}
          cancelBtnText="Cancel"
          onSave={() => {
            setEnableSave(true);
          }}
        />
      )}
      <ConfirmDialog
        loading={updatingUser}
        open={showConfirmationDialog}
        msg={<Box>{confirmMessage}</Box>}
        onClose={() => setShowConfirmationDialog(false)}
        onConfirm={async () => {
          try {
            await submitAndClose(getValues());
          } finally {
            setEnableSave(false);
            setShowConfirmationDialog(false);
          }
        }}
      />
    </>
  );
};
