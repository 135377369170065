import { Permission } from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { PropsWithChildren } from "react";
import { Unauthorized } from "./Unauthorized";

type HasPermissionProps = PropsWithChildren<{ permissions: Permission[] }>;

export const HasPermission = ({
  permissions,
  children,
}: HasPermissionProps) => {
  const { hasPermissions, loading } = useAuthorization();

  if (loading) {
    return <></>;
  } else if (hasPermissions(permissions)) {
    return <>{children}</>;
  } else {
    return <Unauthorized />;
  }
};
