import { Typography, Box, Card, CardContent } from "@mui/material";

export const LinkedInsightsNotFound = ({
  alternateId,
  type,
}: {
  alternateId: string;
  type: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: (theme) => theme.spacing(3),
      }}
    >
      <Card sx={{ maxWidth: 400, textAlign: "center", p: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {type} ID: {alternateId} does not exist in the Government Database
          </Typography>
          <Typography variant="h6" gutterBottom>
            Double check that this is set correctly for the facility.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
