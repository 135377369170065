import { Chip, PaletteColor, Stack, useTheme } from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import {
  JobStatus,
  JobTask,
  StaffJobsTableQuery,
  StaffJobsTableQueryVariables,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useCallback, useState } from "react";
import { prettyPrintDateTime } from "util/dates";
import { useEffect } from "react";
import { getJobStatusColor } from "./utils";
import { JobDialog } from "./JobDialog";

import { STAFF_JOBS_TABLE_QUERY } from "./api";

type JobRow = StaffJobsTableQuery["jobs"]["items"][number];

export const Jobs = () => {
  const theme = useTheme();
  useBreadcrumb({ label: "Jobs" });

  const [selectedJobId, setSelectedJobId] = useState<string | undefined>(
    undefined
  );
  const params = new URLSearchParams(window.location.search);
  const jobId = params.get("jobId");

  useEffect(() => {
    if (jobId) {
      setSelectedJobId(jobId);
    }
  }, [jobId]);

  const getJobStatusColorCallback = useCallback(
    (jobStatus: JobStatus | undefined | null) => {
      return getJobStatusColor({
        jobStatus,
        theme,
      });
    },
    [theme.palette]
  );

  return (
    <Stack py={3}>
      <OmnisearchDataGrid<
        StaffJobsTableQuery,
        StaffJobsTableQueryVariables,
        JobRow
      >
        persistenceKey="staffJobs"
        rowHeight="auto"
        initialSortModel={[{ field: "createdAt", sort: "desc" }]}
        onRowClick={(params) => {
          setSelectedJobId(params.row.id);
        }}
        columns={[
          {
            headerName: "Job ID",
            field: "id",
            flex: 1,
          },
          {
            headerName: "Tenant",
            field: "tenantName",
            flex: 1.2,
            sortable: false,
            valueGetter(params) {
              return params.row.tenant?.name ?? "";
            },
          },
          {
            headerName: "Task",
            field: "task",
            filterKeyType: "enum",
            enumValues: Object.values(JobTask),
            flex: 1,
          },
          {
            headerName: "Status",
            field: "status",
            filterKeyType: "enum",
            enumValues: Object.values(JobStatus),
            flex: 0.7,
            renderCell({ value }: { value?: JobStatus }) {
              const color: PaletteColor = getJobStatusColorCallback(value);
              return (
                <Chip
                  size="small"
                  label={value}
                  sx={{
                    backgroundColor: color.main,
                    color: color.contrastText,
                  }}
                />
              );
            },
          },
          {
            headerName: "Created At",
            field: "createdAt",
            flex: 1.2,
            renderCell({ value }) {
              return prettyPrintDateTime(value);
            },
          },
          {
            headerName: "Created By",
            field: "createdBy",
            flex: 1.2,
          },
          {
            headerName: "Updated At",
            field: "updatedAt",
            flex: 1.2,
            renderCell({ value }) {
              return prettyPrintDateTime(value);
            },
          },
        ]}
        dataQuery={STAFF_JOBS_TABLE_QUERY}
        getItems={(data) => data.jobs.items}
        getCount={(data) => data.jobs.count}
      />
      {selectedJobId && (
        <JobDialog
          jobId={selectedJobId}
          onClose={() => {
            setSelectedJobId(undefined);
          }}
        />
      )}
    </Stack>
  );
};
