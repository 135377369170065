import { useState } from "react";
import invariant from "tiny-invariant";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  ListItem,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dropzone } from "components/Dropzone";
import { DocumentType, Document } from "generated-graphql/graphql";
import { useTenant } from "hooks/useTenant";
import { OmnisearchAutocomplete } from "components/OmnisearchAutocomplete";
import { useToken } from "providers/token";
import { uploadFile } from "util/uploadFile";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useMutation, useQuery } from "@apollo/client";
import { DOCUMENTS, Row as DocumentRow } from "routes/Customer/Documents";
import { prettyPrintDate } from "util/dates";
import {
  T2S_READ_DOCUMENT_QUERY,
  Facility,
  IMPORT_T2S_FILE_MUTATION,
} from "./queries";
import { T2sImportFacilityMapper } from "./T2sImportFacilityMapper";
import LoadingButton from "@mui/lab/LoadingButton";

type T2sImportDialogProps = {
  open: boolean;
  onClose: (event: object, reason: string) => void;
};

export function T2sImportDialog({ open, onClose }: T2sImportDialogProps) {
  const { tenantId } = useTenant();
  // New documents and t2s lambda needs the tenantId
  invariant(tenantId);

  const { user } = useCurrentUser();
  // New documents need the user
  invariant(user);

  const alerts = useAlerts();

  const defaultSearch = `tenantId:${tenantId}`;

  const [uploadingFile, setUploadingFile] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<
    Document | DocumentRow | null
  >();

  const [mappedFacilities, setMappedFacilities] = useState<
    (Facility | null | undefined)[]
  >([]);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Select T2S File", "Map Facilities"];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const { getIdTokenRefreshIfExpired } = useToken();
  const handleFileSelect = async (files: File[]) => {
    const file = files?.[0];
    if (!file) {
      return;
    }
    try {
      setUploadingFile(true);
      const idToken = await getIdTokenRefreshIfExpired();
      const document = await uploadFile(
        file,
        {
          tenantId,
          userId: user.id,
          description: "T2S file uploaded for import",
          documentType: DocumentType.Report,
        },
        idToken
      );
      setSelectedDocument(document);
    } catch (err) {
      alerts.error(`${err}`);
    } finally {
      setUploadingFile(false);
    }
  };

  const { data: t2sFileData } = useQuery(T2S_READ_DOCUMENT_QUERY, {
    variables: {
      documentId: selectedDocument?.id ?? "",
    },
    skip: !selectedDocument?.id,
    onCompleted: () => {
      handleNext();
    },
    onError() {
      alerts.error(`An error occurred while importing the T2S file`);
    },
  });

  const [importT2sFile, { loading: importingT2sFile }] = useMutation(
    IMPORT_T2S_FILE_MUTATION,
    {
      onCompleted() {
        alerts.success(`T2S imported successfully`);
      },
      onError() {
        alerts.error(`An error occurred while importing the T2S file`);
      },
      refetchQueries: ["ExtractionsWebAutomationJobs"],
    }
  );

  const handleImport = async () => {
    if (!selectedDocument) {
      alerts.error("No document selected");
      return;
    }
    if (!t2sFileData?.readT2sFile?.reportingYear) {
      alerts.error("No reporting year found in t2s file");
      return;
    }
    await importT2sFile({
      variables: {
        input: {
          documentId: selectedDocument?.id ?? "",
          reportingYear: t2sFileData?.readT2sFile?.reportingYear,
          facilityIds: mappedFacilities.map((f) => f?.id ?? null),
        },
      },
    });
    onClose({}, "success");
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {uploadingFile && (
              <>
                <Typography>Uploading t2s file...</Typography>
                <CircularProgress size={24} />
              </>
            )}
            {selectedDocument && !t2sFileData?.readT2sFile && (
              <>
                <Typography>Reading T2S file...</Typography>
                <CircularProgress size={24} />
              </>
            )}
            {!selectedDocument && !uploadingFile && (
              <>
                <Dropzone
                  label="Drag and drop your document or click to select file"
                  dragActiveText="Drop the file here"
                  accept=".t2s"
                  onDrop={(acceptedFiles) => handleFileSelect(acceptedFiles)}
                />
                <Typography variant="h6">Or select a .t2s file</Typography>
                <OmnisearchAutocomplete
                  runInitialSearch
                  dataQuery={DOCUMENTS}
                  defaultSearch={`${defaultSearch} fileExtension:T2S`}
                  dataQueryVariables={{
                    sort: [{ field: "title", sort: "asc" }],
                  }}
                  getItems={(data) => data.documents.items}
                  onSelectionChange={(selected: DocumentRow | null) => {
                    setSelectedDocument(selected);
                  }}
                  renderOption={(props, item) => (
                    <ListItem key={item.id} {...props} disablePadding>
                      <ListItemText
                        primary={item.title}
                        secondary={`Uploaded: ${prettyPrintDate(
                          item.createdAt
                        )}`}
                      />
                    </ListItem>
                  )}
                />
              </>
            )}
          </Box>
        );
      case 1:
        return (
          <T2sImportFacilityMapper
            t2sFacilities={t2sFileData?.readT2sFile?.facilities || []}
            onComplete={(mappedFacilities) =>
              setMappedFacilities(mappedFacilities)
            }
            defaultSearch={defaultSearch}
            onProgressChange={(progress) =>
              progress === 100
                ? setDisableSubmit(false)
                : setDisableSubmit(true)
            }
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Import .t2s file</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={(e) => onClose(e, "closeButtonClick")}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2 }}>{renderStepContent(activeStep)}</Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            loading={importingT2sFile}
            onClick={handleImport}
            disabled={
              disableSubmit || mappedFacilities.every((f) => f === null)
            }
          >
            Import
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
