import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { gql } from "generated-graphql";
import {
  UpdateFacilitiesMutation,
  UpdateFacilitiesMutationVariables,
} from "generated-graphql/graphql";

const UPDATE_FACILITIES = gql(`
    mutation UpdateFacilities($ids: [ID!]!,$facility: FacilityInput!) {
        updateFacilities(ids: $ids, facility: $facility) {
            id
            ...facilityInfo 
            ...regulatoryInfo
            ...stateInfo
        }
    }`);

type UpdateFacilitiesOptions = MutationHookOptions<
  UpdateFacilitiesMutation,
  UpdateFacilitiesMutationVariables,
  DefaultContext,
  ApolloCache<any>
>;

export function useUpdateFacilities(options?: UpdateFacilitiesOptions) {
  return useMutation(UPDATE_FACILITIES, options);
}
