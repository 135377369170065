import { Autocomplete, TextField } from "@mui/material";
import React, { forwardRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { ErrorDisplay } from "./Forms/ErrorDisplay";

type BaseSelectProps = {
  getOptionLabel: (option: any) => string;
  onChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
  defaultSearchTerm?: string;
  label: string;
  value: any;
  noOptionsText?: string;
  error?: FieldError;
  disabled?: boolean;
  required?: boolean;
};

type SearchSelectProps = BaseSelectProps &
  (
    | { query: any; loadingText?: string; options?: never }
    | { options: any[]; query?: never; loadingText?: never }
  );

type QuerySearchSelectProps = BaseSelectProps & {
  query: any;
};

type DirectOptionsSelectProps = BaseSelectProps & {
  options: any[];
};

export const DirectOptionsSelect = forwardRef(function DirectOptionsSelect(
  {
    options,
    getOptionLabel,
    onChange,
    onInputChange,
    defaultSearchTerm = "",
    label,
    value,
    noOptionsText,
    error,
    disabled,
    required,
  }: DirectOptionsSelectProps,
  ref
) {
  const [search, setSearch] = useState(defaultSearchTerm);

  return (
    <>
      <Autocomplete
        ref={ref}
        freeSolo
        forcePopupIcon
        options={options}
        value={value ?? null}
        inputValue={search}
        noOptionsText={noOptionsText}
        disabled={disabled}
        onInputChange={(_, val) => {
          setSearch(val);
          onInputChange ? onInputChange(val) : null;
        }}
        onChange={(_, val) => (onChange ? onChange(val) : null)}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            label={label}
            variant="outlined"
            required={required}
          />
        )}
      />
      <ErrorDisplay error={error} />
    </>
  );
});

export const SearchSelect: React.FC<SearchSelectProps> = forwardRef(
  function SearchSelect(props, ref) {
    if (!props.query) {
      return (
        <DirectOptionsSelect
          {...(props as DirectOptionsSelectProps)}
          ref={ref}
        />
      );
    } else {
      throw new Error("Not implemented");
    }
  }
);
