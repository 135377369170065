import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { TabLink, TabLinks, MenuLink } from "components/TabLinks";
import { Permission } from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import {
  Navigate,
  Outlet,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";

export function Facility() {
  const { tenantId, facilityId } = useParams<{
    tenantId: string;
    facilityId: string;
  }>();
  const { pathname } = useLocation();
  const { hasPermissions } = useAuthorization();

  const tabs: (TabLink | (MenuLink & { shouldRender: boolean }))[] = useMemo(
    () => [
      {
        title: "Facility Profile",
        to: `/o/${tenantId}/facilities/${facilityId}/profile`,
        shouldRender: true,
      },
      {
        title: "Insights",
        items: [
          {
            title: "ECHO Insights",
            to: `/o/${tenantId}/facilities/${facilityId}/echoInsights`,
          },
          {
            title: "RCRA Insights",
            to: `/o/${tenantId}/facilities/${facilityId}/rcraInsights`,
          },
          {
            title: "NPDES Insights",
            to: `/o/${tenantId}/facilities/${facilityId}/npdesInsights`,
          },
        ],
        shouldRender: true,
      },
      {
        title: "Contacts",
        to: `/o/${tenantId}/facilities/${facilityId}/contacts`,
        shouldRender: hasPermissions([Permission.RouteContacts]),
      },
      {
        title: "Documents",
        to: `/o/${tenantId}/facilities/${facilityId}/documents`,
        shouldRender: hasPermissions([Permission.RouteDocuments]),
      },
      {
        title: "Credentials",
        to: `/o/${tenantId}/facilities/${facilityId}/credentials`,
        shouldRender: hasPermissions([Permission.RouteEpcraCredentials]),
      },
    ],
    [tenantId, facilityId, hasPermissions]
  );

  const flattenedTabs = tabs.flatMap((tab) =>
    "items" in tab ? tab.items : tab
  );

  const currentTab = flattenedTabs.find(({ to }) =>
    matchPath(`${to}/*`, pathname)
  );

  // Redirect to first tab if we don't have a match
  if (!currentTab) {
    return <Navigate to={flattenedTabs[0].to} replace={true} />;
  }

  return (
    <Box>
      <TabLinks
        value={currentTab.to}
        tabs={tabs.filter((t) => "shouldRender" in t && t.shouldRender)}
        aria-label="facility tabs nav"
        role="navigation"
      />
      <Outlet />
    </Box>
  );
}
