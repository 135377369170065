// components/IndustrySelector.tsx
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import {
  Industry,
  Subsector,
  subsectors,
} from "encamp-shared/src/constants/naicsLookups"; // Ensure you have these lookups available
import { useEffect, useState } from "react";

export default function IndustrySelector({
  onComparisonSubmit,
  loading,
  selectedNaicsCode,
}: {
  onComparisonSubmit: (selectedNaicsCode: string) => void;
  loading: boolean;
  selectedNaicsCode: string;
}) {
  const [selectedSubsector, setSelectedSubsector] = useState<Subsector | null>(
    null
  );
  const [selectedIndustry, setSelectedIndustry] = useState<Industry | null>(
    null
  );

  const industryOptions = selectedSubsector?.industries || [];
  const industryDisabled = !selectedSubsector;

  useEffect(() => {
    const subsector = subsectors.find(
      (subsector) =>
        subsector.code.substring(0, 3) === selectedNaicsCode.substring(0, 3)
    );
    setSelectedSubsector(subsector || null);
    setSelectedIndustry(
      subsector?.industries?.find(
        (industry) => industry.code === selectedNaicsCode
      ) || null
    );
  }, [selectedNaicsCode]);

  const handleComparisonSubmit = () => {
    const selectedCode = selectedIndustry
      ? selectedIndustry.code
      : selectedSubsector?.code || "";
    onComparisonSubmit(selectedCode);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Autocomplete
        options={subsectors}
        getOptionLabel={(option) => `${option.label} (${option.code})`}
        value={selectedSubsector}
        onChange={(event, newValue) => {
          setSelectedSubsector(newValue);
          setSelectedIndustry(null);
        }}
        renderInput={(params) => <TextField {...params} label="Subsector" />}
        sx={{ flexGrow: 1, mr: 2 }}
      />
      <Autocomplete
        options={industryOptions}
        getOptionLabel={(option) => `${option.label} (${option.code})`}
        value={selectedIndustry}
        onChange={(event, newValue) => setSelectedIndustry(newValue)}
        renderInput={(params) => <TextField {...params} label="Industry" />}
        sx={{ flexGrow: 1, mr: 2 }}
        disabled={industryDisabled}
      />
      <Button
        variant="contained"
        onClick={handleComparisonSubmit}
        startIcon={loading ? <CircularProgress size={24} /> : null}
        disabled={loading}
      >
        Compare
      </Button>
    </Box>
  );
}
