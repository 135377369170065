import { ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { gql } from "../../generated-graphql";
import { CommentListFragment } from "../../generated-graphql/graphql";

gql(`
  fragment CommentList on Comment {
    id
    reportId
    userId
    workflowId
    activityId
    comment
    updatedAt
    user {
      id
      email
    }
  }
`);

interface CommentsListProps {
  comments: CommentListFragment[];
  showNewestOnTop?: boolean;
}

export function CommentList({
  comments,
  showNewestOnTop = true,
}: CommentsListProps) {
  return (
    <>
      <Stack
        direction={showNewestOnTop ? "column" : "column-reverse"}
        sx={{
          maxHeight: "15rem",
          overflowY: "auto",
          wordWrap: "break-word",
        }}
      >
        {comments.map((comment) => (
          <ListItem
            key={comment.id}
            divider={true}
            sx={{
              paddingTop: "0.0625rem",
              paddingBottom: "0.0625rem",
            }}
          >
            <ListItemText>
              <Typography variant="subtitle2">
                <strong>{comment?.user?.email ?? "Someone"} </strong>
                {formatDistanceToNow(new Date(comment.updatedAt)) + " ago"}
              </Typography>
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                {comment.comment}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </Stack>
    </>
  );
}
