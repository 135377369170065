import { Chip, useTheme } from "@mui/material";
import { brandColors } from "../util";

type IssueCountProps = {
  issueCount?: number | null;
  onClick?: () => void;
};

export const IssueCount = ({ issueCount, onClick }: IssueCountProps) => {
  const theme = useTheme();
  if (!issueCount) return <></>;

  return (
    <Chip
      label={issueCount}
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      sx={{
        color: theme.palette.error.main,
        backgroundColor: brandColors["encamp-red"][200],
        "&:hover": {
          backgroundColor: onClick
            ? brandColors["encamp-red"][300]
            : brandColors["encamp-red"][200],
        },
      }}
    />
  );
};
