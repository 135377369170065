import { Autocomplete, TextField, Typography } from "@mui/material";
import naicsCodes from "encamp-shared/src/constants/naics.json";
import React, { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import { ErrorDisplay } from "./Forms/ErrorDisplay";

type Props = {
  onChange: (naicsCode: number | null) => void;
  value: number | null;
  disabled?: boolean;
} & ControllerFieldState;

export const NaicsPicker: React.FC<Props> = forwardRef(function NaicsPicker(
  { onChange, value, error, disabled },
  ref
) {
  const autocompleteValue =
    naicsCodes.find((naicsCode) => naicsCode.code === value) ?? null;

  return (
    <>
      <Autocomplete
        ref={ref}
        options={naicsCodes}
        noOptionsText="Search for a NAICS Code"
        value={autocompleteValue}
        onChange={(_, val) => onChange(val?.code ?? null)}
        getOptionLabel={(naicsCode) =>
          `${naicsCode.description} (${naicsCode.code})`
        }
        renderOption={(props, naicsCode) => (
          <li {...props}>
            {naicsCode.description}&nbsp;
            <Typography color="gray">({naicsCode.code})</Typography>
          </li>
        )}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            label="NAICS Code"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
      <ErrorDisplay error={error} />
    </>
  );
});
