import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { FacilityProductStorageLocationInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacilityProductStorageLocation";
import { useCallback } from "react";

const VALIDATE_FACILITY_PRODUCT_STORAGE_INPUT = gql(`
  query ValidateFacilityProductStorageLocationInput($input: FacilityProductStorageLocationInput!, $facilityId: String!) {
    facilityProductStorageLocationInputValidator(input: $input, facilityId: $facilityId) {
      ...issue
    }
  }
`);

export function useFacilityProductStorageLocationInputValidation(
  facilityId: string
) {
  const [query] = useLazyQuery(VALIDATE_FACILITY_PRODUCT_STORAGE_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: FacilityProductStorageLocationInput) => {
      const transformedInput = transform(input);

      const { data: result } = await query({
        variables: {
          input: transformedInput,
          facilityId,
        },
      });
      return result?.facilityProductStorageLocationInputValidator ?? [];
    },
    [query, facilityId]
  );
}
