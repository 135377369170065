import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function Unauthorized() {
  const navigate = useNavigate();

  const goBack = () => {
    localStorage.removeItem("preLoginPath");
    navigate("/");
  };

  return (
    <Box p={3}>
      <Typography
        variant="h3"
        align="center"
        sx={{
          mt: 10,
          mb: 5,
        }}
      >
        Uh-oh! It seems like you don't have permission to view this.
      </Typography>
      <Typography variant="h4" align="center" sx={{ mb: 2 }}>
        Don't worry. We'll get back on the trail.{" "}
      </Typography>

      <Typography variant="body1" align="center" sx={{ mb: 2 }}>
        Somewhere along the way, we got a little turned around. Ready to head
        back?
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" type="button" onClick={goBack}>
          Head Back
        </Button>
      </Box>
    </Box>
  );
}
