import { GridValidRowModel } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { ComponentProps, useState } from "react";

type DataGridProps<Row extends GridValidRowModel> = ComponentProps<
  typeof DataGrid<Row>
>;

export const DataGridWithDefaultSort = <T extends GridValidRowModel>(
  props: DataGridProps<T>
) => {
  const [sortModel, setSortModel] = useState(props.sortModel);

  return (
    <DataGrid
      {...props}
      sortModel={sortModel}
      onSortModelChange={(newModel) => setSortModel(newModel)}
    />
  );
};
