import { useMutation } from "@apollo/client";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { Box, CircularProgress, Grid, useTheme } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { FormTextField } from "components/Forms/FormTextField";
import { gql } from "generated-graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useReport } from "../useReport";

export const UPDATE_NOTES_TO_REGULATOR = gql(`
  mutation UpdateNotesToRegulator($reportId: ID!, $notes: String) {
    updateNotesToRegulators(id: $reportId, notes: $notes) {
      id
      organizationStatus
      notesToRegulators
      touchedSteps
      assignees {
        id
        isComplete
      }
    }
  }
`);

type FormState = {
  notesToRegulators?: string | null;
};

export function NotesToRegulatorsForm() {
  const theme = useTheme();
  const alerts = useAlerts();
  const { reportId = "" } = useParams<{ reportId: string }>();
  const { data } = useReport();
  const [saveSuccessful, setSaveSuccessful] = useState(false);

  const [updateNotesToRegulators, { loading: updatingNotes }] = useMutation(
    UPDATE_NOTES_TO_REGULATOR
  );

  const originalNotes: FormState = useMemo(
    () => ({
      notesToRegulators: data?.tierIIReport.notesToRegulators,
    }),
    [data?.tierIIReport.notesToRegulators]
  );

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<FormState>({
    defaultValues: originalNotes,
  });

  const notesFieldWatch = watch("notesToRegulators");

  useEffect(() => {
    reset(originalNotes);
  }, [originalNotes, reset]);

  const onNotesUpdated = handleSubmit(async (data) => {
    await updateNotesToRegulators({
      variables: {
        reportId,
        notes: data.notesToRegulators,
      },
    });
    setSaveSuccessful(true);
    setTimeout(() => setSaveSuccessful(false), 3000); // hide the success icon
  });

  const updateNotesIfChanged = useCallback(async () => {
    if (notesFieldWatch !== originalNotes.notesToRegulators) {
      await onNotesUpdated();
    }
  }, [notesFieldWatch, originalNotes.notesToRegulators, onNotesUpdated]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      await updateNotesIfChanged();
    }, 5000); // Auto-save after 5 seconds of inactivity

    return () => clearTimeout(timeoutId);
  }, [notesFieldWatch, originalNotes.notesToRegulators, updateNotesIfChanged]);

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    alerts.success("Successfully updated Notes to Regulators");
  }, [alerts, isSubmitSuccessful]);

  return (
    <>
      <Grid
        container
        spacing={1}
        marginTop={theme.spacing(3)}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid item xs={12}>
          <FormTextField
            name="notesToRegulators"
            fullWidth
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Notes to Regulators
                {updatingNotes ? (
                  <CircularProgress size={16} style={{ marginLeft: 10 }} />
                ) : null}
                {saveSuccessful && !updatingNotes ? (
                  <CheckCircleOutline
                    color="success"
                    fontSize="small"
                    style={{ marginLeft: 10 }}
                  />
                ) : null}
              </Box>
            }
            control={control}
            onBlur={updateNotesIfChanged}
            textFieldProps={{
              minRows: 6,
              multiline: true,
              variant: "outlined",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
