import { Box, FormControlLabel, Switch } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { gql } from "generated-graphql";
import { NpdesFacilitiesQuery } from "generated-graphql/graphql";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import invariant from "invariant";
import { useState } from "react";

interface NpdesProps {
  handleRowClick?: (params: GridRowParams) => void;
  initialPageSize?: number;
  filterTenant?: boolean;
}

export type Row = NpdesFacilitiesQuery["npdesFacilities"]["items"][number];

const NPDES_FACILITIES = gql(`
  query NpdesFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String!, $filterTenantId: String) {
    npdesFacilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId, filterTenantId: $filterTenantId) {
      items {
        id: npdesId
        facilityUin
        facilityName
        locationAddress
        city
        stateCode
        zip
      }
      count
    }
  }
`);

export const Npdes: React.FC<NpdesProps> = ({
  handleRowClick,
  initialPageSize = 25,
  filterTenant = true,
}) => {
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId, "tenantId is required");
  const [showMyFacilities, setShowMyFacilities] = useState(filterTenant);

  const onRowClick = (params: GridRowParams) => {
    if (handleRowClick) {
      handleRowClick(params);
    } else {
      const row: Row = params.row;
      navigate(`/o/${tenantId}/insights/npdes/${row.id}`);
    }
  };

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      { field: "facilityUin", headerName: "Registry ID", flex: 0.6 },
      { field: "id", headerName: "NPDES ID", flex: 0.6 },
      {
        field: "facilityName",
        headerName: "Facility Name",
        filterKeyType: "facility",
        flex: 1.2,
      },
      {
        field: "locationAddress",
        headerName: "Address",
        flex: 1.8,
        valueGetter: (params) => {
          const { locationAddress, city, stateCode, zip } = params.row;
          return `${locationAddress}, ${city}, ${stateCode} ${zip}`;
        },
      },
    ],
    []
  );

  return (
    <Box sx={{ pt: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showMyFacilities}
              onChange={() => setShowMyFacilities(!showMyFacilities)}
              name="showMyFacilities"
              color="primary"
            />
          }
          label="Only show my facilities"
        />
      </Box>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={NPDES_FACILITIES}
        getItems={(data: any) => data?.npdesFacilities?.items ?? []}
        getCount={(data: any) => data?.npdesFacilities?.count ?? 0}
        noDataMessage="No NPDES facilities found."
        initialPageSize={initialPageSize}
        onRowClick={onRowClick}
        dataQueryVariables={{
          filterTenantId: showMyFacilities ? tenantId : null,
          tenantId,
        }}
      />
    </Box>
  );
};
