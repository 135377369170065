import { gql } from "../../../../../generated-graphql";

gql(`
  fragment ChemicalDetail on Chemical {
    id
    name
    alternateId
    casNumber
    density
    densityUnits
    ehsForm
    healthHazards
    ifcHazards
    isEhs
    isTradeSecret
    noCasNumber
    noHazardsNotReporting
    oregonHazards
    physicalHazards
    pureOrMixture
    stateOfMatter
    manufacturerSupplier
    version
    recommendedUse
    revisionDate
    components {
      id
      casNumber
      componentPercentage
      name
      weightOrVolume
      isEhs
      ehsForm
    }
    stateFields {
      key
      value
      type
      jurisdiction
      inputType
      label
      tooltip
      selectOptions {
        label
        value
      }
    }
    documents {
      id
      chemicalId
      documentId
      document {
        id
        title
        storageLink
        fileExtension
        documentType # Needed for validation of SDS documents
      }
    }
    issues {
      ...issue
    }
    verifiedAt
    verifiedBy
    verifier {
      id
      email
      person {
        first
        last
      }
    }
  }
`);

export const CHEMICAL_DETAIL_CHEMICAL_QUERY = gql(`
  query ChemicalDetailChemicalQuery($chemicalId: ID!) {
    chemicalWithAssociatedData(chemicalId: $chemicalId) {
      chemical {
        ...ChemicalDetail
      }
      jurisdictions
    }
  }
`);

export const CHEMICAL_DETAIL_FOR_FACILITY_QUERY = gql(`
  query ChemicalDetailForFacilityQuery($chemicalId: ID!, $facilityId: ID!) {
    chemicalWithAssociatedDataForFacility(chemicalId: $chemicalId, facilityId: $facilityId) {
      chemical {
        ...ChemicalDetail
      }
      jurisdictions
    }
  }
`);

export const CHEMICAL_DETAIL_SAVE_CHEMICAL_MUTATION = gql(`
  mutation ChemicalDetailSaveChemical($id: ID!, $input: ChemicalInput!) {
    upsertChemical(id: $id, input: $input) {
      ...ChemicalDetail
    }
  }
`);

export const VERIFY_CHEMICAL_MUTATION = gql(`
  mutation VerifyChemical($id: ID!) { 
    verifyChemical(chemicalId: $id) {
      verifiedAt
      verifiedBy
      verifier {
        id
        email
        person {
          first
          last
        }
      }
    } 
  }
`);

export const UNVERIFY_CHEMICAL_MUTATION = gql(`
  mutation UnverifyChemical($id: ID!) { 
    unverifyChemical(chemicalId: $id) 
  }
`);

export const DELETE_CHEMICAL_DOCUMENT_MUTATION = gql(`
  mutation DeleteChemicalDocument($input: DeleteChemicalDocumentInput!) {
    deleteChemicalDocument(input: $input) {
      chemicalDocumentId
      document {
        id
      }
    }
  }
`);
