import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql } from "../../../../generated-graphql";
import { TenantsQuery } from "../../../../generated-graphql/graphql";
import { CreateTenant } from "./CreateTenant";
import DoNotDisturb from "@mui/icons-material/DoNotDisturb";
import Check from "@mui/icons-material/Check";
import { ConfirmDialog } from "components/ConfirmDialog";
import { useMutation } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { capitalize, omit } from "lodash";

type Row = TenantsQuery["tenants"]["items"][0];

const UPDATE_TENANT = gql(`
  mutation UpdateTenant($input: TenantInput!) {
    upsertTenant(input: $input) {
      id
      isDisabled
    }
  }
`);

export const TENANTS_QUERY = gql(`
  query Tenants($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tenants(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        isDisabled
        isDemo
        isPartner
        accountType
        partnerTenant {
          id
          name
        }
      }
      count
    }
  }
`);

export function OrganizationTable() {
  const { isStaff, isPartner } = useCurrentUser();
  const navigate = useNavigate();
  const alerts = useAlerts();
  const [showDialog, setShowDialog] = useState<
    "disable" | "enable" | undefined
  >(undefined);
  const [selectedTenant, setSelectedTenant] = useState<Row | undefined>(
    undefined
  );

  const [updateTenant, { loading: updatingTenant }] =
    useMutation(UPDATE_TENANT);

  const viewOrganization = useCallback(
    (tenantId: string) => {
      if (isStaff) {
        navigate(`/staff/implementation/${tenantId}`);
      } else if (isPartner) {
        navigate(`/o/${tenantId}/facilities`);
      }
    },
    [isPartner, isStaff, navigate]
  );

  const columns: GridColDef<Row>[] = [
    {
      field: "name",
      headerName: "Organization",
      flex: 0.75,
    },
    {
      field: "isPartner",
      headerName: "Partner",
      flex: 0.25,
    },
    {
      field: "isDisabled",
      headerName: "Disabled",
      flex: 0.25,
    },
    {
      field: "accountType",
      headerName: "Account Type",
      flex: 0.25,
    },
    {
      field: "partnerTenant",
      headerName: "Partner Organization",
      flex: 0.75,
      valueGetter: (params) => {
        return params.row.partnerTenant?.name ?? "";
      },
    },
    {
      field: "actions",
      type: "actions",
      resizable: false,
      sortable: false,
      getActions: (params) => {
        const actions = [];
        if (params.row.isDisabled) {
          actions.push(
            <Tooltip title="Enable Tenant" key={1}>
              <GridActionsCellItem
                onClick={() => {
                  setShowDialog("enable");
                  setSelectedTenant(params.row);
                }}
                label="Enable Tenant"
                icon={<Check />}
              />
            </Tooltip>
          );
        } else {
          actions.push(
            <Tooltip title="Disable Tenant" key={1}>
              <GridActionsCellItem
                onClick={() => {
                  setShowDialog("disable");
                  setSelectedTenant(params.row);
                }}
                label="Disable Tenant"
                icon={<DoNotDisturb />}
              />
            </Tooltip>
          );
        }
        return actions;
      },
    },
  ];

  const title = useMemo(() => {
    return `${capitalize(showDialog)} ${selectedTenant?.name}`;
  }, [selectedTenant?.name, showDialog]);

  const msg = useMemo(() => {
    return `Are you sure you want to ${showDialog} ${selectedTenant?.name}?`;
  }, [selectedTenant?.name, showDialog]);

  const onClose = useCallback(() => {
    setShowDialog(undefined);
    setSelectedTenant(undefined);
  }, []);

  return (
    <Box py={3}>
      <>
        <OmnisearchDataGrid
          sx={{ height: "80vh" }}
          dataQuery={TENANTS_QUERY}
          columns={columns}
          getCount={(data) => data.tenants.count}
          getItems={(data) => data.tenants.items}
          onRowClick={(params) => viewOrganization(params.row.id)}
          commandButtons={[<CreateTenant key="create-tenant" />]}
        />
        <ConfirmDialog
          open={!!showDialog}
          title={title}
          msg={msg}
          loading={updatingTenant}
          onClose={onClose}
          onConfirm={async () => {
            if (selectedTenant == null) return;
            try {
              await updateTenant({
                variables: {
                  input: {
                    ...omit(selectedTenant, "__typename", "partnerTenant"),
                    isDisabled: showDialog === "disable",
                  },
                },
              });
              alerts.success(`Successfully updated ${selectedTenant.name}`);
              onClose();
            } catch (err) {
              alerts.error("Error updating tenant", { error: err });
            }
          }}
        />
      </>
    </Box>
  );
}
