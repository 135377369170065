import EditIcon from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import {
  FormTextField,
  FormTextFieldProps,
} from "components/Forms/FormTextField";
import { useCallback, useState } from "react";
import { FieldValues } from "react-hook-form";

type Props<T extends FieldValues> = {
  mode?: "add" | "edit";
  required?: boolean;
  onEditClick?: () => void;
} & FormTextFieldProps<T>;

export const EditablePasswordField = <T extends FieldValues>({
  mode,
  required,
  ...props
}: Props<T>) => {
  const { onEditClick } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);

  const handleEditClick = useCallback(() => {
    if (onEditClick) {
      onEditClick();
    }
    setIsEditing(true);
  }, [onEditClick]);

  const toggleVisibility = useCallback(() => {
    setRevealPassword(!revealPassword);
  }, [revealPassword]);

  return (
    <FormTextField
      {...props}
      type={revealPassword ? "text" : "password"}
      disabled={(mode === "edit" && !isEditing) || props.disabled}
      textFieldProps={{
        required,
        onFocus: handleEditClick,
      }}
      InputLabelProps={{
        shrink: mode === "edit" || (mode === "add" && isEditing),
      }}
      InputProps={{
        placeholder: mode === "edit" && !isEditing ? "*********" : undefined,
        endAdornment:
          mode === "edit" && !isEditing ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password edit"
                onClick={handleEditClick}
                edge="end"
                disabled={props.disabled}
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label={revealPassword ? "hide password" : "show password"}
                onClick={toggleVisibility}
                edge="end"
                disabled={props.disabled}
              >
                {revealPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
      }}
    />
  );
};
