import FileCopy from "@mui/icons-material/FileCopy";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import CopyableTypography from "../../../../components/Typography/CopyableTypography";
import { useReportDetails } from "./useReportDetails";

export function ImportantIdentifiers() {
  const { data } = useReportDetails();
  const theme = useTheme();
  const alerts = useAlerts();

  const onClickCopy = () => {
    const identifiers = {
      facilityId: data?.tierIIReport.facility.id,
      reportId: data?.tierIIReport.id,
      currentActivityId: data?.tierIIReport.currentWorkflow?.currentActivityId,
      workflowId: data?.tierIIReport.currentWorkflow?.id,
      jobId:
        data?.tierIIReport.currentWorkflow?.currentActivity?.job?.id ?? "N/A",
      jobTaskArn:
        data?.tierIIReport.currentWorkflow?.currentActivity?.job?.taskArn ??
        "N/A",
      jobTaskUrl:
        data?.tierIIReport.currentWorkflow?.currentActivity?.job?.taskUrl ??
        "N/A",
    };
    navigator.clipboard.writeText(JSON.stringify(identifiers, null, 2));
    alerts.info("Copied all identifiers");
  };

  return (
    <Stack
      direction={"column"}
      component={Paper}
      sx={{ border: `1px solid ${theme.palette.divider}`, padding: 1 }}
    >
      <Typography variant="h6">
        Important Identifiers
        <Tooltip title="Copy all identifiers">
          <IconButton edge="end" aria-label="copy" onClick={onClickCopy}>
            <FileCopy />
          </IconButton>
        </Tooltip>
      </Typography>
      <List>
        <ListItem>
          <ListItemText secondary="Facility ID">
            <CopyableTypography text={data?.tierIIReport.facility.id} />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Report ID">
            <CopyableTypography text={data?.tierIIReport.id} />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Current Activity ID">
            <CopyableTypography
              text={data?.tierIIReport.currentWorkflow?.currentActivityId}
            />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Workflow ID">
            <CopyableTypography text={data?.tierIIReport.currentWorkflow?.id} />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Job ID">
            <CopyableTypography
              text={
                data?.tierIIReport.currentWorkflow?.currentActivity?.jobId ??
                "N/A"
              }
            />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Portal Interaction Record Task ARN">
            <CopyableTypography
              text={
                data?.tierIIReport.currentWorkflow?.currentActivity?.job
                  ?.taskArn ?? "N/A"
              }
            />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Portal Interaction Record Task URL">
            {data?.tierIIReport.currentWorkflow?.currentActivity?.job
              ?.taskUrl ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  data?.tierIIReport.currentWorkflow?.currentActivity?.job
                    ?.taskUrl ?? undefined
                }
              >
                {
                  data?.tierIIReport.currentWorkflow?.currentActivity?.job
                    ?.taskUrl
                }
              </a>
            ) : (
              "N/A"
            )}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Credential ID">
            <CopyableTypography
              text={
                data?.tierIIReport.facility?.portalCredential?.credential?.id ??
                "N/A"
              }
            />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Tenant ID">
            <CopyableTypography text={data?.tierIIReport.tenant?.id ?? "N/A"} />
          </ListItemText>
        </ListItem>
      </List>
    </Stack>
  );
}
