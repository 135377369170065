import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Checkbox,
  FormControlLabel,
  useTheme,
  Typography,
} from "@mui/material";
import { gql } from "generated-graphql";
import { Dialog } from "./Dialog";
import { useAlerts } from "./Alerts/AlertProvider";
import { useCurrentUser } from "../hooks/useCurrentUser";
import {
  Permission,
  Role,
  Tenant as TenantType,
} from "generated-graphql/graphql";
import { RolePicker } from "./RolePicker";
import { SaveButton } from "./SaveButton";
import { LoadingButton } from "@mui/lab";
import Cancel from "@mui/icons-material/Cancel";
import Person from "@mui/icons-material/Person";
import { TenantPicker, Tenant as TenantPickerType } from "./TenantPicker";
import { useNavigate } from "react-router-dom";

const SET_EMULATED_ROLE = gql(`
  mutation SetEmulatedRole($tenantId: String!, $permissions: [Permission!]!) {
    setEmulatedRole(tenantId: $tenantId, permissions: $permissions)
  }
`);

const RESET_EMULATED_ROLE = gql(`
  mutation ResetEmulatedRole {
    resetEmulatedRole
  }
`);

const PERMISSIONS_GROUPS: { [group: string]: Permission[] } = {
  Routes: [
    Permission.RouteEpcra,
    Permission.RouteRcra,
    Permission.RouteFacilities,
    Permission.RouteCalendar,
    Permission.RouteContacts,
    Permission.RouteDocuments,
    Permission.RouteEpcraOverview,
    Permission.RouteEpcraTierIi,
    Permission.RouteEpcraReports,
    Permission.RouteEpcraProducts,
    Permission.RouteEpcraCredentials,
    Permission.RouteInsights,
  ],
  Facilities: [
    Permission.ReadAllFacility,
    Permission.ReadAllFacilityMinimal,
    Permission.WriteAllFacility,
    Permission.CreateFacility,
    Permission.EditOwnFacilityPermissions,
    Permission.EditOwnFacilityPermissionsUnlimited,
    Permission.ReadFacilityContact,
    Permission.WriteFacilityContact,
    Permission.VerifyTierIiReport,
  ],
  Users: [
    Permission.ReadAllUserTenant,
    Permission.WriteAllUserTenant,
    Permission.CreateUserTenant,
    Permission.ReadUserTenantRole,
    Permission.WriteUserTenantRole,
    Permission.ReadRole,
    Permission.WriteRole,
    Permission.ReadPerson,
    Permission.WritePerson,
  ],
  Tasks: [
    Permission.ReadOrgEventSequences,
    Permission.WriteOrgEventSequences,
    Permission.CreateDataCollectionTask,
    Permission.WriteDataCollectionTask,
    Permission.FulfillDataCollectionTask,
    Permission.WriteAllCalendarEvent,
    // Deprecated
    // Permission.WriteDataCollectionTaskTemplate,
  ],
  Logs: [Permission.ReadSystemAuditLog, Permission.ReadUserActivityLog],
};

type EmulatePermissionsDialogProps = {
  open: boolean;
  tenant?: TenantType | TenantPickerType;
  onClose: () => void;
};

export function EmulatePermissionsDialog({
  open,
  tenant: initialTenant,
  onClose,
}: EmulatePermissionsDialogProps) {
  const theme = useTheme();
  const { user, refetchUser } = useCurrentUser();
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [setEmulatedRole, { loading: emulateLoading }] =
    useMutation(SET_EMULATED_ROLE);
  const [resetEmulatedRole, { loading: resetLoading }] =
    useMutation(RESET_EMULATED_ROLE);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [tenant, setTenant] = useState<
    TenantType | TenantPickerType | undefined
  >(initialTenant);
  const [redirectAfterUserUpdate, setRedirectAfterUserUpdate] = useState<
    string | undefined
  >(undefined);
  const [tenantPickerOpen, setTenantPickerOpen] = useState(false);

  useEffect(() => {
    if (redirectAfterUserUpdate) {
      navigate(redirectAfterUserUpdate);
      setRedirectAfterUserUpdate(undefined);
    }
  }, [user]);

  useEffect(() => {
    if (selectedRole) {
      setSelectedPermissions(selectedRole.permissions as Permission[]);
    }
  }, [selectedRole, user?.isEmulating]);

  useEffect(() => {
    if (initialTenant) {
      setTenant(initialTenant);
    }
  }, [initialTenant]);

  const handleEmulate = async () => {
    try {
      if (!tenant) {
        alerts.error("Please select a tenant");
        return;
      }
      await setEmulatedRole({
        variables: {
          tenantId: tenant?.id ?? "",
          permissions: selectedPermissions,
        },
      });
      alerts.success("Successfully emulated user");
      setRedirectAfterUserUpdate(`/`);
      refetchUser();
      onClose();
    } catch (error) {
      alerts.error("Failed to emulate user");
    }
  };

  const handleResetEmulation = async () => {
    try {
      await resetEmulatedRole();
      alerts.success("Successfully reset emulation");
      if (tenant) {
        setRedirectAfterUserUpdate(`/o/${tenant.id}/facilities`);
      }
      refetchUser();
      onClose();
    } catch (error) {
      alerts.error("Failed to reset emulation");
    }
  };

  const handlePermissionsChange = (permission: Permission) => {
    setSelectedPermissions((prev) =>
      prev.includes(permission)
        ? prev.filter((p) => p !== permission)
        : [...prev, permission]
    );
  };

  const handleGroupClick = (group: string) => {
    const permissions = PERMISSIONS_GROUPS[group];
    const allSelected = permissions.every((permission) =>
      selectedPermissions.includes(permission)
    );

    setSelectedPermissions((prev) =>
      allSelected
        ? prev.filter((p) => !permissions.includes(p))
        : [...prev, ...permissions.filter((p) => !prev.includes(p))]
    );
  };

  const handleTenantSelected = (selectedTenant: TenantPickerType) => {
    setTenant(selectedTenant);
    setTenantPickerOpen(false);
  };

  const handleOnClose = () => {
    setTenantPickerOpen(false);
    setTenant(initialTenant);
    setSelectedRole(null);
    setSelectedPermissions([]);
    onClose();
  };

  if (user?.isEmulating)
    return (
      <Dialog open={open} onClose={handleOnClose} maxWidth="lg">
        <DialogTitle>Emulate Permissions</DialogTitle>
        <DialogContent>
          <Typography>
            You are currently emulating a role in {tenant?.name}. Please reset
            to emulate a different role or to go back to being a staff user.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={emulateLoading || resetLoading}
            variant="contained"
            onClick={handleResetEmulation}
            startIcon={<Cancel />}
            disabled={!tenant}
          >
            Reset
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="lg">
      <DialogTitle>Emulate Permissions</DialogTitle>
      <DialogContent>
        <Typography>
          Select a tenant and role to view that tenant as a specific user role,
          or select a combination of permissions to see what a user with those
          permissions would see.
        </Typography>
        {!!tenant && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography>Current Tenant: {tenant.name}</Typography>
            <Button onClick={() => setTenantPickerOpen(!tenantPickerOpen)}>
              Change
            </Button>
          </Box>
        )}
        {(tenantPickerOpen || !tenant) && (
          <TenantPicker onTenantSelected={handleTenantSelected} />
        )}
        {!!tenant && (
          <>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <RolePicker
                tenantId={tenant?.id ?? ""}
                value={selectedRole?.id ?? ""}
                onChange={setSelectedRole}
                roleDisabled={(role) =>
                  !(
                    role.permissions?.includes(Permission.ReadAllFacility) ||
                    role.permissions?.includes(Permission.WriteAllFacility)
                  )
                }
              />
            </FormControl>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {Object.entries(PERMISSIONS_GROUPS).map(
                ([group, permissions]) => (
                  <Grid item xs={6} key={group}>
                    <FormControl component="fieldset" variant="standard">
                      <Box
                        fontWeight="fontWeightBold"
                        mb={1}
                        onClick={() => handleGroupClick(group)}
                        sx={{ cursor: "pointer" }}
                      >
                        {group}
                      </Box>
                      {permissions.map((permission) => (
                        <FormControlLabel
                          key={permission}
                          control={
                            <Checkbox
                              checked={selectedPermissions.includes(permission)}
                              onChange={() =>
                                handlePermissionsChange(permission)
                              }
                            />
                          }
                          label={permission}
                        />
                      ))}
                    </FormControl>
                  </Grid>
                )
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
        <Button onClick={handleOnClose}>Cancel</Button>
        <SaveButton
          loading={emulateLoading || resetLoading}
          onClick={handleEmulate}
          startIcon={<Person />}
          disabled={!tenant || selectedPermissions.length === 0}
          saveText={"Emulate"}
        />
      </DialogActions>
    </Dialog>
  );
}
