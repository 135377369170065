import Mode from "@mui/icons-material/Mode";
import { Box, Button, Paper, Stack, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid } from "components/DataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { useCancelPayment } from "hooks/usePayment";
import { useCallback, useMemo, useState } from "react";
import { getActivityTitle } from "util/activity";
import { prettyPrintDateTime } from "util/dates";
import { useReportDetails } from "../useReportDetails";
import { PaymentDialog } from "./PaymentDialog";
import { LobMailType, Payment, PaymentStatus } from "generated-graphql/graphql";
import Clear from "@mui/icons-material/Clear";

export function Payments() {
  const theme = useTheme();
  const alerts = useAlerts();
  const { data, loading } = useReportDetails();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [cancelPayment, setCancelPayment] = useState<Payment | undefined>(
    undefined
  );
  const [editPaymentId, setEditPaymentId] = useState<string | undefined>(
    undefined
  );

  const closeModal = () => {
    setShowPaymentForm(false);
    setShowCancelConfirmation(false);
    setEditPaymentId(undefined);
    setCancelPayment(undefined);
  };

  const payments =
    data?.tierIIReport.payments?.map((p) => ({
      ...p,
      activity: data.tierIIReport.currentWorkflow?.activities.find(
        (a) => a.id === p.activityId
      ),
    })) ?? [];

  const [cancelPaymentMutation, { loading: deleting }] = useCancelPayment({
    refetchQueries: ["GetTierIIReport"],
  });

  const openPaymentsForm = (paymentId?: string) => {
    paymentId && setEditPaymentId(paymentId);
    setShowPaymentForm(true);
  };

  const openCancelConfirmation = (payment: Payment) => {
    setCancelPayment(payment);
    setShowCancelConfirmation(true);
  };

  const handleCancelPayment = useCallback(async () => {
    if (!cancelPayment) return;
    try {
      await cancelPaymentMutation({ variables: { id: cancelPayment.id } });
      alerts.success("Successfully cancelled the payment");
    } catch (err) {
      alerts.error("There was an error cancelling this payment", err);
    }
    closeModal();
  }, [alerts, cancelPayment, cancelPaymentMutation]);

  const lobType = useMemo(
    () =>
      cancelPayment?.lobMail?.type === LobMailType.Check ? "checks" : "letters",
    [cancelPayment?.lobMail?.type]
  );

  return (
    <Paper
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Stack spacing={theme.spacing(2)}>
        <Typography variant="h5">Payments</Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={() => openPaymentsForm()}>
            Make or record a payment
          </Button>
        </Box>
        <DataGrid
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              minHeight: "50px",
            },
          }}
          hideFooter
          loading={loading}
          rows={payments}
          disableRowSelectionOnClick
          onRowClick={({ row }) => openPaymentsForm(row.id)}
          initialState={{
            sorting: {
              sortModel: [{ field: "paidAt", sort: "desc" }],
            },
          }}
          columns={[
            { headerName: "Payee", field: "payee", flex: 1 },
            { headerName: "Type", field: "payeeType", flex: 1 },
            {
              headerName: "Activity",
              field: "activity",
              valueGetter(params) {
                return params.value
                  ? getActivityTitle(params.value)
                  : undefined;
              },
              flex: 1,
            },
            {
              headerName: "Payment Date",
              field: "paidAt",
              flex: 1,
              renderCell(params) {
                return prettyPrintDateTime(params.value);
              },
            },
            {
              headerName: "Payment Method",
              field: "paymentMethod",
              flex: 1,
              renderCell(params) {
                return prettyPrintEnumValue(params.value);
              },
            },
            {
              headerName: "Amount",
              field: "amount",
              valueFormatter(params) {
                return new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(params.value);
              },
              flex: 1,
            },
            {
              type: "actions",
              field: "actions",
              align: "left",
              getActions({ row }) {
                const actions = [
                  <GridActionsCellItem
                    key="edit"
                    label="Edit"
                    icon={<Mode />}
                    onClick={() => openPaymentsForm(row.id)}
                  />,
                ];

                if (row.status !== PaymentStatus.Cancelled) {
                  actions.push(
                    <GridActionsCellItem
                      key="cancel"
                      label="Cancel"
                      icon={<Clear />}
                      onClick={() => openCancelConfirmation(row)}
                    />
                  );
                }

                return actions;
              },
            },
          ]}
        />
      </Stack>
      {data && showPaymentForm && (
        <PaymentDialog
          open={showPaymentForm}
          onClose={closeModal}
          tenantId={data.tierIIReport.tenant.id}
          facilityId={data.tierIIReport.facility.id}
          reportId={data.tierIIReport.id}
          paymentId={editPaymentId}
        />
      )}
      {showCancelConfirmation && (
        <ConfirmDialog
          msg={`This will cancel the payment.
          This will not cancel any in-flight lob checks.
          If you need to cancel a check,
          please do so in Lob or contact #engineering-support in Slack. 
          ${
            cancelPayment?.lobMail
              ? `Lob URL: https://dashboard.lob.com/${lobType}/${cancelPayment.lobMail.lobId}`
              : ""
          }`}
          open={showCancelConfirmation}
          onClose={closeModal}
          onConfirm={handleCancelPayment}
          loading={deleting}
        />
      )}
    </Paper>
  );
}
