import { gql } from "generated-graphql";
import { CollectionMode } from "generated-graphql/graphql";
import { useDebug } from "./useDebug";
import { useFragment } from "@apollo/client";

const COLLECTION_MODE_FRAGMENT = gql(/* GraphQL */ `
  fragment collectionMode on Facility {
    id
    collectionMode
  }
`);

/**
 * Determine whether a facility is in measurement mode
 * @param facilityId
 * @returns boolean indicating whether the facility is in measurement mode
 */
export function useMeasurements(facilityId: string | undefined): {
  hasMeasurements: boolean;
  loading: boolean;
} {
  const { data, complete } = useFragment({
    fragment: COLLECTION_MODE_FRAGMENT,
    from: `Facility:${facilityId}`,
  });

  return {
    hasMeasurements:
      data?.collectionMode === CollectionMode.Measurement ||
      data?.collectionMode === CollectionMode.MeasurementDefaulted,
    loading: !complete,
  };
}
