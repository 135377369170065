import { Grid, Paper, Stack, useTheme } from "@mui/material";
import { useReportDetails } from "../useReportDetails";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ActivityStepper } from "../Activities/ActivityStepper";

export function WorkflowLogs() {
  const theme = useTheme();
  const { data } = useReportDetails();
  const currentActivityId =
    data?.tierIIReport.currentWorkflow?.currentActivityId;

  const shouldAutoNavigateToCurrentActivity = useMatch(
    "/staff/fulfillment/:reportId/workflow-logs"
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldAutoNavigateToCurrentActivity && currentActivityId) {
      navigate(currentActivityId, { replace: true });
    }
  }, [currentActivityId, navigate, shouldAutoNavigateToCurrentActivity]);

  return (
    <Paper sx={{ paddingTop: theme.spacing(2) }}>
      <Grid container spacing={theme.spacing(2)} minHeight={"500px"}>
        <Grid
          item
          xs={4}
          sx={{ borderRight: `solid 1px ${theme.palette.divider}` }}
        >
          <Stack direction="column" spacing={2}>
            <ActivityStepper />
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Outlet />
        </Grid>
      </Grid>
    </Paper>
  );
}
