import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { ExportButton, ExportFileType } from "components/ExportButton";
import { Importer } from "components/Importer";
import { ImportButtonWithDialog } from "components/Importer/ImportDialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { FireDepartmentSearchQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import compact from "lodash/compact";
import { useMemo, useState } from "react";
import { ConfirmDeleteFireDepartments } from "./ConfirmDeleteFireDepartments";
import { CreateEditFireDepartmentDialog } from "./CreateEditDialog";
import { FIRE_DEPARTMENT_SEARCH } from "./api";
import { IssueCount } from "components/IssueCount";

type Row = FireDepartmentSearchQuery["fireDepartmentSearch"]["items"][number];

type FireDepartmentsProps = {
  initialPageSize?: number;
};

export const FireDepartments: React.FC<FireDepartmentsProps> = ({
  initialPageSize,
}) => {
  const [openFireDepartmentDialog, setOpenFireDepartmentDialog] = useState<{
    mode: "add" | "edit";
    agency?: Row;
  } | null>(null);

  const [confirmDeleteFireDepartment, setConfirmDeleteFireDepartment] =
    useState<Row | null>(null);

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 100 },
      { field: "name", headerName: "Name", flex: 3 },
      {
        field: "mailingAddress",
        headerName: "Mailing Address",
        flex: 2,
        valueGetter: (params) => {
          return compact([
            params.row.mailingAddress,
            params.row.mailingAddressLine2,
          ]).join(", ");
        },
      },
      { field: "city", headerName: "City", flex: 1 },
      { field: "county", headerName: "County", flex: 1 },
      { field: "state", headerName: "State", width: 75 },
      { field: "zip", headerName: "Zip", width: 75 },
      { field: "email", headerName: "Email", flex: 3 },
      {
        field: "issues",
        headerName: "Issues",
        flex: 0.3,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row: { issues } }) => {
          return <IssueCount issueCount={issues?.length} />;
        },
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        align: "left",
        getActions: ({ row }) => {
          return [
            <Tooltip title="Edit Agency" key={1}>
              <GridActionsCellItem
                label="Edit Agency"
                onClick={() =>
                  setOpenFireDepartmentDialog({ mode: "edit", agency: row })
                }
                icon={<Edit />}
              />
            </Tooltip>,
            <Tooltip title="Delete Agency" key={2}>
              <GridActionsCellItem
                label="Delete Agency"
                onClick={() => setConfirmDeleteFireDepartment(row)}
                icon={<Delete />}
              />
            </Tooltip>,
          ];
        },
      },
    ],
    []
  );

  return (
    <Box sx={{ pt: 3, padding: 2 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={FIRE_DEPARTMENT_SEARCH}
        getItems={(data: any) => data.fireDepartmentSearch.items ?? []}
        getCount={(data: any) => data.fireDepartmentSearch.count ?? []}
        noDataMessage="No fire departments found."
        initialPageSize={initialPageSize ?? 25}
        onRowClick={({ row }) => {
          setOpenFireDepartmentDialog({ mode: "edit", agency: row });
        }}
        commandButtons={[
          <ExportButton
            key="export"
            fileType={ExportFileType.EXCEL}
            endpoint={`/download/fire-departments`}
          />,
          <ImportButtonWithDialog
            key="import"
            importers={[
              {
                name: "Fire Departments",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FireDepartment}
                    refetchQueries={["FireDepartmentSearch"]}
                  />
                ),
              },
            ]}
          />,
          <Button
            key="add-fire-department-button"
            variant="contained"
            onClick={() => setOpenFireDepartmentDialog({ mode: "add" })}
          >
            Add Fire Department
          </Button>,
        ]}
      />
      {!!openFireDepartmentDialog && (
        <CreateEditFireDepartmentDialog
          onClose={() => setOpenFireDepartmentDialog(null)}
          agencyType="Fire Department"
          mode={openFireDepartmentDialog.mode}
          fireDepartmentId={openFireDepartmentDialog?.agency?.id}
        />
      )}

      {confirmDeleteFireDepartment && (
        <ConfirmDeleteFireDepartments
          fireDepartmentId={confirmDeleteFireDepartment.id}
          onClose={() => setConfirmDeleteFireDepartment(null)}
        />
      )}
    </Box>
  );
};
