import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { LeadAgencyInput } from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";

import {
  GET_NJ_LEAD_AGENECY,
  UPSERT_LEAD_AGENCY,
  GET_ALL_NJ_LEAD_AGENECIES,
} from "./schema";
import { hasCriticalIssues } from "util/forms";
import { useCallback } from "react";

const VALIDATE_LEAD_AGENCY_INPUT = gql(`
  query ValidateLeadAgencylInput($id: Int, $data: LeadAgencyInput!) {
    leadAgencyInputValidator(id: $id, data: $data) {
      ...issue
    }
  }
`);

export function useLeadAgencyInputValidation(isCreate: boolean) {
  const [query] = useLazyQuery(VALIDATE_LEAD_AGENCY_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: LeadAgencyInput) => {
      console.log("running validation");
      const { data } = await query({
        variables: {
          data: input,
          id: !isCreate ? input.id : undefined,
        },
      });

      return data?.leadAgencyInputValidator ?? [];
    },
    [query, isCreate]
  );
}

type LeadAngencyDialogProps = {
  agencyType: string;
  onClose: () => void;
  mode?: "add" | "edit";
  agencyId?: number;
};

export const LeadAgencyDialog: React.FC<LeadAngencyDialogProps> = ({
  agencyType,
  onClose,
  mode,
  agencyId,
}) => {
  const theme = useTheme();
  const alerts = useAlerts();

  const { data: agency, loading: isLoadingAgency } = useQuery(
    GET_NJ_LEAD_AGENECY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: agencyId ?? -1,
      },
      skip: !agencyId,
    }
  );

  const [upsertLeadAgency, { loading: isUpserting }] = useMutation(
    UPSERT_LEAD_AGENCY,
    { refetchQueries: ["LeadAgency"] }
  );

  const defaultValues: LeadAgencyInput = useMemo(
    () => ({
      id: agency?.leadAgency.id ?? undefined,
      name: agency?.leadAgency?.name ?? "",
      address: agency?.leadAgency?.address ?? "",
      attn: agency?.leadAgency?.attn ?? "",
      city: agency?.leadAgency?.city ?? "",
      consolidated: agency?.leadAgency?.consolidated ?? "",
      phone: agency?.leadAgency?.phone ?? "",
      state: agency?.leadAgency?.state ?? "NJ",
      zip: agency?.leadAgency?.zip ?? "",
      email: agency?.leadAgency?.email ?? "",
    }),
    [agency]
  );

  const form = useValidatingForm<LeadAgencyInput>(
    defaultValues,
    agency?.leadAgency.issues,
    useLeadAgencyInputValidation(mode === "add")
  );

  const { handleSubmit, control, getValues, issues } = form;

  const onSubmit = async () => {
    const input = {
      ...getValues(),
    };

    await upsertLeadAgency({
      variables: { id: agencyId, input },
      onCompleted: () => {
        onClose();
        alerts.success("Agency saved successfully");
      },
      onError: (error) => {
        console.error(error);
        alerts.error("An error occurred while saving the Agency");
      },
      refetchQueries: [GET_ALL_NJ_LEAD_AGENECIES],
    });
  };

  const savingOrLoading = isLoadingAgency || isUpserting;
  return (
    <Dialog open onClose={onClose} maxWidth="lg">
      <DialogTitle>
        {mode === "add" ? "Add" : "Edit"} {agencyType}
      </DialogTitle>
      <DialogContent sx={{ minHeight: 500 }}>
        <FormProvider {...form}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              padding: 0.5,
              width: 500,
            }}
          >
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={3} minWidth="100%">
                <FormTextField
                  name="name"
                  label="Name"
                  control={control}
                  textFieldProps={{ required: true }}
                />
                <FormTextField
                  name="address"
                  label="Address"
                  control={control}
                  textFieldProps={{ required: true }}
                />
                <FormTextField
                  name="attn"
                  label="Attn"
                  control={control}
                  textFieldProps={{ required: true }}
                />
                <FormTextField
                  name="city"
                  label="City"
                  control={control}
                  textFieldProps={{ required: true }}
                />
                <FormTextField
                  name="state"
                  label="State"
                  control={control}
                  disabled
                  textFieldProps={{ required: true }}
                />
                <FormTextField
                  name="zip"
                  label="Zip"
                  control={control}
                  textFieldProps={{ required: true }}
                />
                <FormTextField
                  name="consolidated"
                  label="Consolidated"
                  control={control}
                  textFieldProps={{ required: true }}
                />
                <FormTextField
                  name="phone"
                  label="Phone"
                  control={control}
                  textFieldProps={{ required: true }}
                />
                <FormTextField name="email" label="Email" control={control} />
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
        <Button onClick={onClose}>Cancel</Button>
        <SaveButton
          loading={savingOrLoading}
          saveText="Save"
          onClick={onSubmit}
          disabled={
            hasCriticalIssues(issues) ||
            (mode === "add" && !form.formState.isDirty)
          }
        />
      </DialogActions>
    </Dialog>
  );
};
