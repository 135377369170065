import { DataGrid } from "components/DataGrid";
import { useQuery } from "@apollo/client";

import { GridColDef } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { FACILITIES_QUERY } from "./AssociateFacilitiesDialog";
import { useTenant } from "hooks/useTenant";
import { useMemo, useState } from "react";
import Delete from "@mui/icons-material/Delete";

type Props = {
  facilityIds: string[];
  onDelete: (id: string) => void;
};

export const AssociatedFacilityGrid = ({ facilityIds, onDelete }: Props) => {
  const { tenantId, loading: isTenantLoading } = useTenant();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const { data, previousData, loading } = useQuery(FACILITIES_QUERY, {
    variables: {
      search: `tenantId:${tenantId} id:${facilityIds.join("|")}`,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: [{ field: "name", sort: "asc" }],
    },
    skip: isTenantLoading,
  });

  const items = useMemo(
    () => data?.facilities.items ?? previousData?.facilities.items ?? [],
    [data?.facilities.items, previousData?.facilities.items]
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Facility Name",
      flex: 1,
      valueGetter: (params) => params.row.name,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value}>
            <Typography
              variant="body2"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "streetAddress1",
      headerName: "Location",
      flex: 1,
      valueGetter(params) {
        if (!params.row.streetAddress1) return "";

        const { streetAddress, city, state, zip } = params.row.streetAddress1;

        return [streetAddress, city, state, zip].filter((s) => s).join(", ");
      },
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value}>
            <Typography
              variant="body2"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.1,
      getActions: (params) => {
        return [
          <Tooltip title="Delete facility association" key={1}>
            <GridActionsCellItem
              icon={<Delete />}
              label="Delete"
              onClick={() => {
                onDelete(params.row.id);
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <div>
      <DataGrid
        noRowsMessage="No facilities associated with this task"
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        paginationMode="server"
        pagination
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 25, 50]}
        onPaginationModelChange={(model) => {
          setPaginationModel(model);
        }}
        rowCount={data?.facilities.count ?? 0}
        loading={loading}
        sx={{
          maxHeight: "300px",
          "& .MuiDataGrid-virtualScroller": {
            minHeight: items.length === 0 ? "100px" : "0",
          },
        }}
      />
    </div>
  );
};
