import { useQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { ReportingFacilityChemicalDialog } from "./ReportingFacilityChemicalDialog";

export const REPORTING_FACILITY_CHEMICAL_QUERY = gql(`
  query ReportingFacilityChemical($id: ID!) {
    reportingFacilityChemical(id: $id) {
      id
      facilityId
      aggregationErrorMessage
      maxAmount
      unit
      averageAmount
      isOverThreshold
      chemicalId
      reportingYear
      hasConfidentialStorageLocations
      isAlwaysReported
      ...ReportingFacilityChemicalAggregate
      manuallyEdited
      chemical {
        ...FacilityChemicalChemical
        noHazardsNotReporting
      }
      storageLocations {
        ...ReportingFacilityChemicalStorageLocationForm
      }
      issues {
        ...issue
      }
      previousYearMaxAmount {
        amount
        unit
      }
      jurisdictions
      stateFields {
          key
          value
          type
          jurisdiction
          inputType
          label
          tooltip
          group
          selectOptions {
            label
            value
          }
      }
      maxAmountProductChemicalBreakdown {
        productMaxAmount {
          amount
          unit
        }
        chemicalMaxAmount {
          amount
          unit
        }
      }
      origin {
        facilityChemicalId
        facilityProducts {
          id
          daysOnSite
          maxQuantity
          averageQuantity
          product {
            id
            name
            productChemicals {
              amount
              chemicalId
            }
          }
        }
        facilityProductMeasurements {
          id
          quantity
          product {
            id
            name
            productChemicals {
              id
              chemicalId
              amount
              unit
            }
          }
        }
        facilityChemicalMeasurements {
          id
          measuredAtUtc
          amount
          unit
          storageType
          storageLocation {
            description
          }
        }
      }
    }
  }
`);

export const ReportingFacilityChemicalEditor = ({
  id,
  onClose,
}: {
  id: string;
  onClose: () => void;
}) => {
  const { data, loading } = useQuery(REPORTING_FACILITY_CHEMICAL_QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  return (
    <ReportingFacilityChemicalDialog
      rfc={data?.reportingFacilityChemical}
      loading={loading}
      onClose={onClose}
    />
  );
};
