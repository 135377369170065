import { LoadingButton } from "@mui/lab";
import { Grid, Paper, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useCreateComment } from "../../hooks/comments";
import { useReportDetails } from "../../routes/Staff/Fulfillment/ReportDetails/useReportDetails";
import { CommentList } from "./CommentList";

export function Comments() {
  const theme = useTheme();
  const [createComment, { loading: isUpsertCommentLoading }] =
    useCreateComment();
  const { data: report } = useReportDetails();
  const comments = report?.tierIIReport.comments;

  const [newComment, setNewComment] = useState("");

  const handleAddComment = async () => {
    await createComment({
      variables: {
        input: {
          reportId: report?.tierIIReport.id,
          workflowId: report?.tierIIReport.currentWorkflow?.id,
          comment: newComment,
        },
      },
      onCompleted() {
        setNewComment("");
      },
    });
  };

  return (
    <Grid
      container
      gap={0.5}
      component={Paper}
      sx={{
        padding: theme.spacing(3),
        border: `${theme.palette.divider} solid 1px`,
      }}
    >
      <Typography>Workflow Comments</Typography>
      <TextField
        multiline
        variant="outlined"
        fullWidth={true}
        value={newComment}
        disabled={isUpsertCommentLoading}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Leave a comment"
        InputProps={{
          endAdornment: (
            <LoadingButton
              onClick={handleAddComment}
              loading={isUpsertCommentLoading}
              variant="contained"
              color="primary"
              size="small"
            >
              Comment
            </LoadingButton>
          ),
        }}
      />
      {comments && comments.length > 0 && <CommentList comments={comments} />}
    </Grid>
  );
}
