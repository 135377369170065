import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";
import { ExportFileType } from "./ExportButton";
import {
  LongPollingExportButton,
  generateSpreadsheetFilename,
} from "./LongPollingExportButton";

export function FacilitiesExportButton({ tenantId }: { tenantId?: string }) {
  const { tenant } = useTenant();

  const filename = useMemo(
    () =>
      generateSpreadsheetFilename(tenant?.name ?? tenantId ?? "", "facilities"),
    [tenantId, tenant?.name]
  );

  return (
    <LongPollingExportButton
      fileType={ExportFileType.EXCEL}
      endpoint={`/download/facilities?tenantId=${tenantId}&filename=${filename}`}
      disabled={!tenantId}
      tenantId={tenantId ?? ""}
      fileName={filename}
    />
  );
}
