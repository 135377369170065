import { useQuery } from "@apollo/client";
import { InputLabel, MenuItem, Select } from "@mui/material";
import React, { forwardRef, useMemo } from "react";
import { ControllerFieldState } from "react-hook-form";
import { Role } from "generated-graphql/graphql";
import GET_ROLES_QUERY from "queries/getRoles";

type Props = {
  onChange: (role: Role | null) => void;
  roleDisabled?: (role: Role) => boolean;
  value: string | null;
  tenantId: string;
  disabled?: boolean;
} & Partial<ControllerFieldState>;

export const RolePicker: React.FC<Props> = forwardRef(function RolePicker(
  { value, tenantId, onChange, disabled, roleDisabled },
  ref
) {
  const { data, loading } = useQuery(GET_ROLES_QUERY, {
    variables: {
      tenantId,
    },
    fetchPolicy: "cache-and-network",
  });

  const roles = useMemo(() => {
    const roles = data?.roles ?? [];

    return [...roles].sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }, [data?.roles]);

  return (
    <>
      <InputLabel id="role-id" disabled={disabled}>
        Role
      </InputLabel>
      <Select
        label="Role"
        labelId="role-id"
        ref={ref}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          const roleId = e.target.value;
          const role = roles.find((role) => role.id === roleId);
          onChange(role ?? null);
        }}
      >
        {loading ? (
          <MenuItem>Loading...</MenuItem>
        ) : (
          roles.map((role) => (
            <MenuItem
              key={role.id}
              value={role.id}
              disabled={roleDisabled && roleDisabled(role)}
            >
              {role.name}
            </MenuItem>
          ))
        )}
      </Select>
    </>
  );
});
