import { FilterObj, QueryParamContext } from "providers/queryParams";
import { useContext } from "react";

/**
 * Number parsing is disabled for query params, so we are explicit in what we
 * are able to return by this function
 */
type UseQueryParamsNonNumericTypes = string | boolean | undefined | object;

export function useQueryParams<
  T extends Record<string, UseQueryParamsNonNumericTypes>
>(): FilterObj<T> {
  const context = useContext(QueryParamContext);
  if (!context) {
    throw new Error("useQueryParams must be used within a QueryParamProvider");
  }
  return context;
}
