import { SxProps } from "@mui/material";
import { gql } from "generated-graphql";
import {
  DynamicFieldFragment,
  DynamicFieldType,
} from "generated-graphql/graphql";
import { Control, FieldValues, Path } from "react-hook-form";
import { DateField } from "./DateField";
import { FormSelect } from "./FormSelect";
import { FormTextField } from "./FormTextField";
import { RadioGroupField } from "./RadioGroupField";
import { FormAutocomplete } from "./FormAutocomplete";
import { sicCodes } from "encamp-shared/src/constants/sic";

gql(`
  fragment dynamicField on DynamicField {
    key
    value
    jurisdiction
    label
    tooltip
    inputType
    selectOptions {
      label
      value
    }
  }
`);

type DynamicFieldProps<TFieldValues extends FieldValues> = {
  namePrefix: string;
  control: Control<TFieldValues>;
  dynamicField: DynamicFieldFragment;
  sx?: SxProps;
  disabled?: boolean;
};

export function DynamicField<TFieldValues extends FieldValues>(
  props: DynamicFieldProps<TFieldValues>
) {
  const {
    namePrefix,
    control,
    dynamicField: { inputType, label, selectOptions },
    sx,
    disabled,
  } = props;

  const tooltip = props.dynamicField.tooltip || undefined;

  /**
   * `.value` here is the field/property name of the DynamicField object
   * ```
   *   chemicalStateField.0.key => epaId
   *   chemicalStateField.0.value => 123345
   * ```
   */
  const name = `${namePrefix}.value` as Path<TFieldValues>;

  switch (inputType) {
    case DynamicFieldType.Text:
    case DynamicFieldType.Numeric:
      return (
        <FormTextField
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          disabled={disabled}
        />
      );
    case DynamicFieldType.Textarea:
      return (
        <FormTextField
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          textFieldProps={{ multiline: true }}
          disabled={disabled}
        />
      );

    case DynamicFieldType.Boolean:
      return (
        <RadioGroupField
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          disabled={disabled}
          radioOptions={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
        />
      );

    case DynamicFieldType.Date:
      return (
        <DateField
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          disabled={disabled}
        />
      );

    case DynamicFieldType.Radiogroup:
      return (
        <RadioGroupField
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          radioOptions={selectOptions ?? []}
          disabled={disabled}
        />
      );

    // Currently just rendering this as a regular select field
    // but in future we can make a new form field that is
    // responsible for fetching it's own data
    case DynamicFieldType.Select:
    case DynamicFieldType.SelectNjPoliceDepartment:
      return (
        <FormSelect
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          disabled={disabled}
          selectItems={
            selectOptions?.map((opt) => ({
              display: opt.label,
              value: opt.value,
            })) ?? []
          }
        />
      );

    case DynamicFieldType.Autocomplete:
      return (
        <FormAutocomplete
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          disabled={disabled}
          autocompleteItems={selectOptions ?? []}
        />
      );

    case DynamicFieldType.SicCodeSelect: {
      return (
        <FormAutocomplete
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          disabled={disabled}
          autocompleteItems={sicCodes ?? []}
        />
      );
    }

    case DynamicFieldType.Multiselect:
      return (
        <FormSelect
          sx={sx}
          control={control}
          name={name}
          label={label}
          tooltip={tooltip}
          disabled={disabled}
          selectItems={
            selectOptions?.map((opt) => ({
              display: opt.label,
              value: opt.value,
            })) ?? []
          }
          selectProps={{
            multiple: true,
          }}
        />
      );
  }
}
