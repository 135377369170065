import CheckCircle from "@mui/icons-material/CheckCircle";
import CopyAll from "@mui/icons-material/CopyAll";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import copy from "clipboard-copy";
import { useState } from "react";

interface ShowAllCopyTooltipProps {
  text?: string | null;
  variant?: "body1" | "body2" | "caption";
}

function ShowAllCopyTooltip({
  text,
  variant = "body1",
}: ShowAllCopyTooltipProps) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    await copy(text ?? "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <Tooltip placement="bottom-start" title={text}>
      <Typography
        variant={variant}
        onClick={handleCopy}
        style={{ cursor: "pointer" }}
      >
        <Box component="span" display="flex" alignItems="center">
          {isCopied ? (
            <CheckCircle
              style={{ fontSize: 20, marginRight: 8, opacity: 0.3 }}
            />
          ) : (
            <CopyAll style={{ fontSize: 20, marginRight: 8, opacity: 0.3 }} />
          )}
          {text}
        </Box>
      </Typography>
    </Tooltip>
  );
}

export default ShowAllCopyTooltip;
