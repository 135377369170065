import { Box, Typography, useTheme } from "@mui/material";
import OpenInNew from "@mui/icons-material/OpenInNew";
import MenuBook from "@mui/icons-material/MenuBook";
import { Issue } from "generated-graphql/graphql";
import pluralize from "pluralize";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { IssueCount } from "components/IssueCount";
import { brandColors } from "../util";

interface CatalogLinkProps {
  type: keyof typeof modelToSchemas;
  id: string;
  name: string;
  issues: Issue[];
}

// This maps the model type to the issue schemas that are relevant to the model
const modelToSchemas = {
  Chemical: ["Chemical", "ChemicalComponent", "ChemicalStateField"],
  Product: ["Product", "ProductChemical"],
};

export const CatalogLink: React.FC<CatalogLinkProps> = ({
  type,
  id,
  name,
  issues,
}) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const theme = useTheme();
  if (!name) {
    return null;
  }

  let link = "";
  if (type === "Chemical") {
    link = `/o/${tenantId}/chemicals/catalog/chemicals/${id}`;
  } else {
    link = `/o/${tenantId}/chemicals/products/${id}`;
  }
  const issueCount = issues.filter(
    (issue) =>
      modelToSchemas[type].includes(issue.modelName) &&
      (issue.modelId === id || issue.schemaPath?.includes(id))
  ).length;

  const color =
    issueCount === 0 ? theme.palette.text.secondary : theme.palette.error.main;
  const backgroundColor =
    issueCount === 0
      ? brandColors["encamp-blue"][50]
      : brandColors["encamp-red"][50];

  const linkContent =
    issueCount === 0 ? (
      <>
        <MenuBook fontSize="small" sx={{ mr: 1 }} />
        View the {type.toLowerCase()} catalog entry for{" "}
        <Link
          to={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit", overflow: "hidden" }}
        >
          <Typography noWrap>{name}</Typography>
        </Link>{" "}
      </>
    ) : (
      <>
        Fix&nbsp;
        <IssueCount issueCount={issueCount} />
        &nbsp;
        {pluralize("issue", issueCount)} in the {type.toLowerCase()} catalog for{" "}
        <Link
          to={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color, overflow: "hidden" }}
        >
          <Typography noWrap>{name}</Typography>
        </Link>{" "}
      </>
    );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        px: 2,
        py: 1,
        mt: 2,
        whiteSpace: "pre",
        backgroundColor,
        color,
      }}
    >
      {linkContent}
      <Link
        to={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color }}
      >
        <OpenInNew
          fontSize="small"
          sx={{
            verticalAlign: "middle",
            fontSize: "inherit",
          }}
        />
      </Link>
    </Box>
  );
};
