import { gql } from "generated-graphql";

gql(` 
fragment JobFragment on Job {
    id
    task
    status
    parameters
    createdAt
    createdBy
    updatedAt
    tenant {
    name
    }
    updatedBy
}
`);

export const STAFF_JOB_DIALOG_QUERY = gql(`
    query StaffJobDialog($id: ID!) {
      job(id: $id) {
        ...JobFragment
      }
    }
  `);

export const STAFF_JOBS_TABLE_QUERY = gql(`
    query StaffJobsTable($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
      jobs(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        items {
          ...JobFragment
        }
        count
      }
    }
  `);

export const STAFF_JOB_STEPS_DONE_TABLE_QUERY = gql(`
  query StaffJobStepsDoneTable($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
      jobStepsDone(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
          items {
            id
            jobId
            name
            updatedAt
            createdAt
          }
          count
      }
      }
  `);

export const STAFF_JOB_LOGS_TABLE_QUERY = gql(`
  query StaffJobLogsTable($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
      jobLogs(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
          items {
            id
            status
            createdAt
            errorMessage
            updatedAt
          }
          count
        }
      }
  `);
