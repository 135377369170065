import React, { useState, useMemo } from "react";
import { Autocomplete, TextField, SxProps, Theme } from "@mui/material";
import { useQuery, TypedDocumentNode } from "@apollo/client";
import { useDebounce } from "hooks/useDebounce";

interface OmnisearchAutocompleteProps<
  TData,
  TVariables,
  TItem extends { id: string }
> {
  disabled?: boolean;
  dataQuery: TypedDocumentNode<TData, TVariables>;
  dataQueryVariables?: Omit<TVariables, "search">;
  getItems: (data: TData) => TItem[];
  placeholder?: string;
  onSelectionChange?: (item: TItem | null) => void;
  defaultSearch?: string;
  runInitialSearch?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    item: TItem
  ) => React.ReactNode;
  sx?: SxProps<Theme>;
  /**
   * Initial search query to populate the search input.
   * This allows setting a starting search term that can be overwritten by the user.
   * Useful for pre-filling the search based on external factors or user preferences.
   */
  initialSearchQuery?: string;
}

export function OmnisearchAutocomplete<
  TData,
  TVariables,
  TItem extends { id: string }
>({
  disabled = false,
  dataQuery,
  dataQueryVariables,
  getItems,
  placeholder = "Search...",
  onSelectionChange,
  defaultSearch = "",
  renderOption,
  runInitialSearch = false,
  sx,
  initialSearchQuery = "",
}: OmnisearchAutocompleteProps<TData, TVariables, TItem>) {
  const [search, setSearch] = useState(initialSearchQuery);
  const [debouncedSearch] = useDebounce(search, 300);

  const { data, loading } = useQuery(dataQuery, {
    variables: {
      search: [defaultSearch, debouncedSearch].filter(Boolean).join(" "),
      ...dataQueryVariables,
    },
    skip: !runInitialSearch && debouncedSearch.length < 2,
  });

  const items = useMemo(() => (data ? getItems(data) : []), [data, getItems]);

  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    setSearch(newInputValue);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: TItem | null
  ) => {
    if (onSelectionChange) {
      onSelectionChange(newValue);
    }
    setSearch(""); // Clear the input after selection
  };

  return (
    <Autocomplete<TItem>
      disabled={disabled}
      sx={sx}
      options={items}
      getOptionLabel={(item) => item.id}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} fullWidth />
      )}
      inputValue={search}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={null}
      loading={loading}
      loadingText="Searching..."
      noOptionsText="No results found"
      clearOnBlur={false}
      clearOnEscape={false}
      handleHomeEndKeys
      filterOptions={(x) => x}
    />
  );
}
