import { Dialog } from "components/Dialog";
import { Row } from "./index";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useDeleteDocumentMutation } from "hooks/documents";
import { LoadingButton } from "@mui/lab";

type ConfirmDeleteDialogProps = {
  open: boolean;
  selectedDocument?: Row;
  onClose: () => void;
};

export const ConfirmDeleteDialog = ({
  open,
  selectedDocument,
  onClose,
}: ConfirmDeleteDialogProps) => {
  const theme = useTheme();

  const [deleteDocument, { loading }] = useDeleteDocumentMutation({
    refetchQueries: ["Documents"],
  });

  if (!selectedDocument) return <></>;

  return (
    <Dialog open={open} onClose={onClose}>
      <form>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          This action is permanent. "{selectedDocument.title}" may be associated
          with other facilities, reports and chemicals. Are you sure you want to
          permanently delete this document?
        </DialogContent>
        <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            variant="contained"
            onClick={async () => {
              await deleteDocument({
                variables: {
                  id: selectedDocument.id,
                  retainAsset: true,
                },
              });
              onClose();
            }}
            disabled={loading}
            loading={loading}
          >
            Remove
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
