import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { gql } from "generated-graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FacilityContacts } from "../Contacts/FacilityContacts";
import { ProgramArea } from "generated-graphql/graphql";

const GET_FACILITY_CONTACTS = gql(`
  query GetFacilityContacts($facilityId: ID!, $programArea: ProgramArea) {
    facility(id: $facilityId) {
      id
      name
      ...facilityContacts
      issues {
        ...issue
      }
    }
  }
`);

export const Contacts = () => {
  const { tenantId, facilityId } = useParams();
  const { data, previousData } = useQuery(GET_FACILITY_CONTACTS, {
    variables: {
      facilityId: facilityId ?? "",
      programArea: ProgramArea.Epcra,
    },
    skip: !facilityId,
    fetchPolicy: "cache-and-network",
  });
  const issues = useMemo(
    () => data?.facility?.issues ?? [],
    [data?.facility?.issues]
  );

  useBreadcrumb([
    {
      label: "Facilities",
      to: `/o/${tenantId}/facilities`,
    },
    {
      label: data?.facility?.name ?? "",
      to: `/o/${tenantId}/facilities/${facilityId}/profile`,
    },
    {
      label: "Contacts",
    },
  ]);

  return (
    <Stack sx={{ px: 3, pt: 4 }}>
      <FacilityContacts
        loading={!data && !previousData}
        facilityId={data?.facility?.id ?? ""}
        facilityContacts={data?.facility?.contacts ?? []}
        getRowIssues={(row) =>
          issues.filter(
            (issue) =>
              (issue.modelId === row.person.id &&
                issue.modelName === "Person") ||
              (issue.modelName === "FacilityContact" &&
                issue.modelId === row.id)
          )
        }
        facilityContactIssues={issues.filter(
          (issue) => issue.key === "contacts"
        )}
        refetchQueries={[GET_FACILITY_CONTACTS]}
      />
    </Stack>
  );
};
