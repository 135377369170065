import useOmnisearchFilters from "../../../../components/Omnisearch/useOmnisearch";

export enum ReportErrorTableFilterKeys {
  Organization = "organizationName",
  Facility = "facilityName",
  State = "state",
  ExecutionPlan = "executionPlan",
  ErrorType = "errorType",
  ErrorMessage = "errorMessage",
  ProductCount = "productCount",
  ActivityDescription = "activityDescription",
  Assignee = "activityAssigneeEmail",
  ActivityType = "activityType",
}

export function useReportErrorTableOmnisearch() {
  const {
    omnisearch,
    updateOmnisearch: update,
    getOmnisearchUrl,
  } = useOmnisearchFilters();
  const updateOmnisearch = (
    filters: [ReportErrorTableFilterKeys, string][],
    clearFirst?: boolean
  ) => {
    update(filters, clearFirst);
  };

  const getUrl = (filters: [ReportErrorTableFilterKeys, string][]) => {
    return getOmnisearchUrl(window.location.origin + "/staff/errors", filters);
  };

  return {
    omnisearch,
    updateOmnisearch,
    getOmnisearchUrl: getUrl,
  };
}
