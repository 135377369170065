import CheckCircle from "@mui/icons-material/CheckCircle";
import FileUpload from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";

type ImportButtonProps = {
  loading?: boolean;
  disabled?: boolean;
  success?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  style?: React.CSSProperties;
};

export const ImportButton: React.FC<ImportButtonProps> = ({
  loading,
  disabled,
  success,
  onClick,
  label,
  style,
}) => {
  return (
    <LoadingButton
      variant="outlined"
      size="small"
      startIcon={success ? <CheckCircle color="success" /> : <FileUpload />}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      style={{ ...style }}
    >
      {label ?? "Import"}
    </LoadingButton>
  );
};
