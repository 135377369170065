import { useMeasurements } from "hooks/useMeasurements";
import { useProducts } from "hooks/useProducts";
import { reportStepMetadata } from "util/constants";
import { ChemicalsWithMeasurementsTable } from "./ChemicalsWithMeasurementsTable";
import { FacilityChemicalsTable } from "./FacilityChemicalsTable";
import { StepperPageHeader } from "./Report/StepperPageHeader";

export type ChemicalsTableProps = {
  facilityId: string;
  reportId?: string;
};

export function ChemicalsTable(props: ChemicalsTableProps) {
  const { hasMeasurements } = useMeasurements(props.facilityId);
  const { hasProducts: isOtherChemicals } = useProducts();

  const header = isOtherChemicals
    ? reportStepMetadata.OTHER_CHEMICALS.title
    : reportStepMetadata.CHEMICALS.title;
  const headerDescription = isOtherChemicals
    ? reportStepMetadata.OTHER_CHEMICALS.overviewDescription
    : reportStepMetadata.CHEMICALS.overviewDescription;

  let table;
  if (hasMeasurements) {
    table = <ChemicalsWithMeasurementsTable {...props} />;
  } else {
    table = <FacilityChemicalsTable {...props} />;
  }

  return (
    <>
      <StepperPageHeader header={header} description={headerDescription} />
      {table}
    </>
  );
}
