import { UnitType } from "generated-graphql/graphql";
import pluralize from "pluralize";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { getAmountCodes } from "encamp-shared/src/constants/storageAmounts";

pluralize.addSingularRule(/feet$/i, "foot");
pluralize.addPluralRule(/feet$/i, "feet");

export function prettyPrintQuantity(
  amount?: number | null,
  unit?: UnitType | null,
  abbreviateUnit = false,
  includeLabel = true
): string {
  if (!amount && amount !== 0) return "Not set";

  if (!includeLabel) {
    return `${amount.toLocaleString()}`;
  }

  const unitLabel = getUnitLabel({ unit, amount, abbreviateUnit });

  return `${amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })} ${unitLabel}`;
}
export function prettyPrintAmountCode(
  state?: string | null,
  rawAmountCode?: number | null,
  unit?: UnitType | null,
  abbreviateUnit = false,
  includeLabel = true
) {
  if (!rawAmountCode) return "Not set";

  const codes = getAmountCodes(state ?? "");
  const amountCode = codes[rawAmountCode.toString()] ?? "";

  if (!includeLabel) {
    return `${amountCode}`;
  }

  const unitLabel = getUnitLabel({ unit, abbreviateUnit });

  return `${amountCode} ${unitLabel}`;
}

function getUnitLabel({
  unit,
  amount,
  abbreviateUnit = false,
}: {
  unit?: UnitType | null;
  amount?: number | null;
  abbreviateUnit?: boolean;
}) {
  if (abbreviateUnit) {
    return unit ? translateUnitToLabel(unit) : "(No unit)";
  }

  return unit
    ? pluralize(prettyPrintEnumValue(unit), amount ?? undefined)
    : "(No unit)";
}

export function translateUnitToLabel(unit: UnitType): string {
  switch (unit) {
    case UnitType.Barrels:
      return "bbls";
    case UnitType.CubicFeet:
      return "cu. ft";
    case UnitType.Gallons:
      return "gals";
    case UnitType.Kilograms:
      return "kg";
    case UnitType.Liters:
      return "L";
    case UnitType.MetricTons:
      return "MT";
    case UnitType.Pounds:
      return "lbs";
    case UnitType.Tons:
      return "T";
    default:
      throw new Error(`Unknown unit type: ${unit}`);
  }
}
