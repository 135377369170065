import { getFullName } from "encamp-shared/src/utils/name";
import { useCallback, useEffect, useMemo } from "react";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useFeatureFlags } from "../hooks/useFeatureFlags";
import { useTenant } from "../hooks/useTenant";

type AppcuesUserInformation = {
  firstName?: string | null;
  lastName?: string | null;
  displayName: string;
  email: string;
  organization: string;
  tenantId: string;
  isStaff: boolean;
};

export function Appcues() {
  const { featureFlags } = useFeatureFlags();
  const isAppcuesEnabled = featureFlags?.["appcues-widget"] ?? false;
  const { user, isStaff } = useCurrentUser();
  const { tenant } = useTenant();

  const userInfo = useMemo(() => {
    if (user && tenant) {
      const info: AppcuesUserInformation = {
        firstName: user.person?.first,
        lastName: user.person?.last,
        displayName: getFullName(user.person),
        email: user.email,
        organization: tenant.name,
        tenantId: tenant.id,
        isStaff,
      };

      return info;
    }
    return undefined;
  }, [user, tenant, isStaff]);

  const handleScriptLoad = useCallback(() => {
    if (user && userInfo && window.Appcues) {
      window.Appcues.identify(user.id, userInfo);
    }
  }, [user, userInfo]);

  const handleScriptError = () => {
    console.error("Could not load Appcues");
  };

  useEffect(() => {
    if (isAppcuesEnabled) {
      const script = document.createElement("script");
      script.src = "//fast.appcues.com/117798.js";
      script.async = true;
      script.onload = handleScriptLoad;
      script.onerror = handleScriptError;
      document.body.appendChild(script);
    }
  }, [handleScriptLoad, isAppcuesEnabled]);

  return null;
}
