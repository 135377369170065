import { EhsForm } from "generated-graphql/graphql";

export const EhsFormLabels: Record<EhsForm, string> = {
  MOLTEN: "Molten",
  NONE: "None (most common)",
  SOLID_PARTICLE_SIZE_LESS_THAN_100_MICRON:
    "Solid particle size less than 100 micron",
  SOLUTION: "Solution",
};

export const EhsFormOrder: EhsForm[] = [
  EhsForm.None,
  EhsForm.Solution,
  EhsForm.Molten,
  EhsForm.SolidParticleSizeLessThan_100Micron,
];
