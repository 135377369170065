import { RefetchQueriesInclude, useApolloClient } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Button, Stack, Tooltip, useTheme } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
} from "components/AddEditDocumentDialog";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid, IssueMessageCell } from "components/DataGrid";
import { DocumentViewer } from "components/DocumentViewer";
import { gql } from "generated-graphql";
import {
  DocumentGridRowFragment,
  DocumentType,
  FacilityDocumentInput,
  Issue,
  TagInput,
} from "generated-graphql/graphql";
import { useDeleteDocumentMutation } from "hooks/documents";
import { PaginationModel } from "hooks/usePaginationModel";
import { useMemo, useState } from "react";
import { documentTypeToLabel } from "util/constants";
import { prettyPrintDateMed } from "util/dates";

gql(`
  fragment DocumentGridRow on Document {
    id
    title
    fileExtension
    documentType
    createdAt
  }
`);

type DocumentIssueRow = Issue & GridValidRowModel;

type DataGridRow = DocumentGridRowFragment | DocumentIssueRow;

const isDocumentIssueRow = (row: DataGridRow): row is DocumentIssueRow => {
  return (row as DocumentIssueRow).key !== undefined;
};

type DeleteDialogState = {
  open: boolean;
  message: string;
  idToDelete: string;
};

const defaultDeleteDialogState: DeleteDialogState = {
  open: false,
  message: "Are you sure you want to delete this record?",
  idToDelete: "",
};
const selectableDocumentTypes = [
  DocumentType.AstDocumentation,
  DocumentType.DikeDescription,
  DocumentType.EmergencyResponsePlan,
  DocumentType.HazardousMaterialsContingencyPlanOrQuickGuide,
  DocumentType.HazardousMaterialsInventoryStatement,
  DocumentType.HazardousMaterialsManagementPlan,
  DocumentType.Other,
  DocumentType.Permit,
  DocumentType.ReferenceImage,
  DocumentType.SafetyDataSheet,
  DocumentType.SiteCoordinateAbbreviation,
  DocumentType.SitePlan,
  DocumentType.TrainingPlan,
  DocumentType.UstDocumentation,
];

type DocumentsGridProps = {
  loading: boolean;
  documents: DocumentGridRowFragment[];
  /** These are issues that will display their message as a row in the grid.
   * Used primarily when a facility is missing a specific document type. */
  facilityDocumentIssues: Issue[];
  tenantId: string | undefined;
  facility: FacilityDocumentInput | undefined;
  reportId?: string;
  refetchQueries: RefetchQueriesInclude;
  tags?: TagInput[];
  paginationModel?: PaginationModel;
  setPaginationModel?: (pagination: PaginationModel) => void;
  sortModel?: GridSortModel;
  setSortModel?: (sort: GridSortModel) => void;
  count?: number;
};

export const DocumentsGrid: React.FC<DocumentsGridProps> = ({
  loading,
  documents,
  facilityDocumentIssues,
  tenantId,
  facility,
  reportId,
  refetchQueries,
  tags,
  paginationModel,
  setPaginationModel,
  sortModel,
  setSortModel,
  count,
}) => {
  const theme = useTheme();
  const apolloClient = useApolloClient();
  const alerts = useAlerts();

  const rows: DataGridRow[] = useMemo(
    () => [
      ...documents,
      ...facilityDocumentIssues.map((i) => ({ ...i, id: i.message })),
    ],
    [facilityDocumentIssues, documents]
  );

  const additionalAddEditState: Partial<AddEditDocumentDialogState> =
    useMemo(() => {
      return {
        facilities: facility ? [facility] : undefined,
        reportId,
        documentTags: tags,
      };
    }, [facility, reportId, tags]);

  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>({
      ...defaultAddEditDocumentDialogState,
    });
  const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>(
    defaultDeleteDialogState
  );

  const [deleteDocument, { loading: deletingDocument }] =
    useDeleteDocumentMutation({ refetchQueries });

  const columns: GridColDef<DataGridRow>[] = useMemo(
    () => [
      {
        field: "title",
        headerName: "Name",
        flex: 1.5,
        colSpan: ({ row }) => {
          if (isDocumentIssueRow(row)) {
            return 3;
          }

          return undefined;
        },
        renderCell: ({ row }) => {
          if (isDocumentIssueRow(row)) {
            return <IssueMessageCell issue={row} />;
          }

          return row.title;
        },
      },
      {
        field: "fileExtension",
        headerName: "File Extension",
        flex: 0.5,
      },
      {
        field: "documentType",
        headerName: "Type",
        flex: 0.75,
        renderCell: (params) => {
          return params.row.documentType
            ? documentTypeToLabel(params.row.documentType)
            : "";
        },
      },
      {
        field: "createdAt",
        headerName: "Uploaded",
        flex: 0.5,
        renderCell: ({ value }) =>
          value ? prettyPrintDateMed(value) : undefined,
      },
      {
        field: "actions",
        type: "actions",
        align: "right",
        flex: 0.4,
        getActions: ({ row }) => {
          if (isDocumentIssueRow(row)) {
            return [
              <Button
                key="add"
                color="primary"
                size="small"
                sx={{ my: 1 }}
                startIcon={<Add fontSize="small" />}
                onClick={() => {
                  setAddEditDialogState((state) => ({
                    ...state,
                    open: true,
                    mode: "Add",
                    ...additionalAddEditState,
                  }));
                }}
              >
                Add Document
              </Button>,
            ];
          }

          return [
            <Tooltip title="Edit" key="editRecord">
              <GridActionsCellItem
                onClick={() => {
                  setAddEditDialogState((state) => ({
                    ...state,
                    open: true,
                    mode: "Edit",
                    step: "Form",
                    documentId: row.id,
                    title: row.title,
                    documentType: row.documentType,
                    ...additionalAddEditState,
                  }));
                }}
                label="Edit"
                icon={<Edit />}
              />
            </Tooltip>,
            <Tooltip title="Download" key="downloadRecord">
              <GridActionsCellItem
                label="View"
                icon={
                  <DocumentViewer
                    documentId={row.id}
                    title={row.title}
                    fileExtension={row.fileExtension}
                  />
                }
              />
            </Tooltip>,
            <Tooltip title="Delete" key="deleteRecord">
              <GridActionsCellItem
                onClick={() =>
                  setDeleteDialogState((state) => ({
                    ...state,
                    open: true,
                    message: `Are you sure you want to delete ${row.title}?`,
                    idToDelete: row.id,
                  }))
                }
                label="Delete"
                icon={<Delete />}
              />
            </Tooltip>,
          ];
        },
      },
    ],
    [additionalAddEditState]
  );

  return (
    <>
      <Stack display="flex" flexDirection="column" flexGrow={1}>
        <Stack>
          <Stack direction="row" justifyContent="end" mb={theme.spacing(1)}>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                setAddEditDialogState((state) => ({
                  ...state,
                  open: true,
                  mode: "Add",
                  ...additionalAddEditState,
                }))
              }
            >
              Add Document
            </Button>
          </Stack>

          <DataGrid<DataGridRow>
            columns={columns}
            rows={rows}
            rowCount={count}
            autoHeight={true}
            getRowHeight={() => "auto"}
            loading={loading}
            rowSelection={false}
            noRowsMessage="No documents have been added yet."
            hideFooter={!paginationModel}
            pagination={!!paginationModel}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            onRowClick={(params: GridRowParams<DataGridRow>) => {
              if (isDocumentIssueRow(params.row)) {
                setAddEditDialogState((state) => ({
                  ...state,
                  open: true,
                  mode: "Add",
                  ...additionalAddEditState,
                }));
              } else {
                setAddEditDialogState((state) => ({
                  ...state,
                  open: true,
                  mode: "Edit",
                  step: "Form",
                  documentId: params.row.id,
                  title: params.row.title,
                  documentType: params.row.documentType,
                  ...additionalAddEditState,
                }));
              }
            }}
          />
        </Stack>
      </Stack>

      {addEditDialogState.open && tenantId && (
        <AddEditDocumentDialog
          dialogState={addEditDialogState}
          setDialogState={setAddEditDialogState}
          tenantId={tenantId}
          reportId={reportId}
          documentTypeOptions={selectableDocumentTypes}
          onSubmit={() =>
            apolloClient.refetchQueries({ include: [...refetchQueries] })
          }
          refetchQueries={refetchQueries}
        />
      )}

      {deleteDialogState.open && (
        <ConfirmDialog
          open={deleteDialogState.open}
          msg={deleteDialogState.message}
          loading={deletingDocument}
          onClose={() => setDeleteDialogState(defaultDeleteDialogState)}
          onConfirm={async () => {
            await deleteDocument({
              variables: { id: deleteDialogState.idToDelete },
              refetchQueries,
            });
            setDeleteDialogState(defaultDeleteDialogState);
            alerts.success("File successfully deleted");
          }}
        />
      )}
    </>
  );
};
