import { Box, Typography, useTheme } from "@mui/material";

export function SubSection(props: {
  title?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  const theme = useTheme();
  return (
    <Box
      marginTop={theme.spacing(3)}
      paddingBottom={theme.spacing(3)}
      marginLeft={theme.spacing(3)}
      marginRight={theme.spacing(3)}
      style={props.style}
    >
      {props.title && <SubSectionHeader title={props.title} />}
      {props.children}
    </Box>
  );
}

export function SubSectionHeader(props: { title: string }) {
  const theme = useTheme();
  return (
    <Typography
      variant="body1"
      fontWeight={"bold"}
      height={theme.spacing(3)}
      marginBottom={theme.spacing(2.5)}
    >
      {props.title}
    </Typography>
  );
}
