import { Typography } from "@mui/material";
import { TierIiReportStep } from "generated-graphql/graphql";
import { FacilityContacts } from "routes/Customer/Contacts/FacilityContacts";
import { ReportFloatingBar } from "../ReportFloatingBar";
import { StepperPageHeader } from "../StepperPageHeader";
import {
  GET_REPORT,
  useContactIssues,
  useFacilityContactIssues,
  useReport,
} from "../useReport";
import { useTouchReportMutation } from "../useTouchReportMutation";

export const ReportFacilityContacts = () => {
  const { data: report, loading: reportLoading } = useReport();
  const allContactIssues = useContactIssues();
  const facilityContactIssues = useFacilityContactIssues();
  const { handleSave, loading } = useTouchReportMutation(
    report?.tierIIReport.id ?? "",
    report?.tierIIReport.touchedSteps ?? [],
    TierIiReportStep.Contacts
  );

  return (
    <>
      <StepperPageHeader
        header="Contacts"
        mb={0}
        description={
          <>
            <Typography variant="body2">
              Wherever possible, Encamp has pre-populated your contact
              information based on what was reported in past years.
            </Typography>
            <Typography variant="body2">
              The agencies associated with this facility's location require
              contact information for specific roles associated with EPCRA.
            </Typography>
          </>
        }
      />
      <FacilityContacts
        loading={reportLoading}
        facilityId={report?.tierIIReport.facility.id ?? ""}
        facilityContacts={report?.tierIIReport.facility.contacts ?? []}
        getRowIssues={(row) =>
          allContactIssues.filter(
            (issue) =>
              issue.modelId === row.id || issue.modelId === row.person.id
          )
        }
        facilityContactIssues={facilityContactIssues}
        refetchQueries={[GET_REPORT]}
      />
      <ReportFloatingBar
        loading={loading}
        onSaveClick={handleSave}
        issues={allContactIssues}
        disabledWithAnyIssues
        saveText="Mark as Complete"
      />
    </>
  );
};
