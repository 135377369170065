import { useLazyQuery } from "@apollo/client";
import { DocumentFormData } from "components/AddEditDocumentDialog";
import { gql } from "generated-graphql";
import { transform } from "hooks/transform/transformDocument";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

const VALIDATE_DOCUMENT_INPUT = gql(`
  query ValidateDocumentInput($input: DocumentInput!, $fileSize: Int) {
    documentInputValidator(input: $input, fileSize: $fileSize) {
      ...issue
    }
  }
`);

export function useDocumentInputValidation() {
  const [query] = useLazyQuery(VALIDATE_DOCUMENT_INPUT, {
    fetchPolicy: "no-cache",
  });

  const { tenantId = "" } = useParams();
  return useCallback(
    async (input: DocumentFormData) => {
      const { data } = await query({
        variables: {
          input: {
            ...transform(input),
            tenantId,
          },
          fileSize: input.fileSize,
        },
      });
      return data?.documentInputValidator ?? [];
    },
    [query, tenantId]
  );
}
