import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { ProductInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformProduct";
import { useCallback } from "react";

const VALIDATE_PRODUCT_INPUT = gql(`
  query ValidateProductInput($input: ProductInput!) {
    productInputValidator(input: $input) {
      ...issue
    }
  }
`);

export function useProductInputValidation() {
  const [query] = useLazyQuery(VALIDATE_PRODUCT_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: ProductInput) => {
      const { data } = await query({
        variables: { input: transform(input) },
      });
      return data?.productInputValidator ?? [];
    },
    [query]
  );
}
