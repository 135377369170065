import { Box, DialogContent, DialogTitle } from "@mui/material";
import { Dialog } from "./Dialog";
import { TenantPicker, Tenant } from "./TenantPicker";

type TenantPickerDialogProps = {
  open: boolean;
  onClose: () => void;
  onTenantSelected: (tenant: Tenant) => void;
};

export function TenantPickerDialog({
  open,
  onClose,
  onTenantSelected,
}: TenantPickerDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Change Organizations</DialogTitle>
      <DialogContent sx={{ minWidth: 900 }}>
        <TenantPicker onTenantSelected={onTenantSelected} />
      </DialogContent>
    </Dialog>
  );
}
