import { Skeleton } from "@mui/material";
import { FacilityProductMeasurementTable } from "../../FacilityProductMeasurementTable";
import { useReport } from "../useReport";
import { useParams } from "react-router-dom";
import { HasFeature } from "components/HasFeature";

export function ProductMeasurements() {
  const { data } = useReport();
  const { productId } = useParams<{ productId: string }>();

  const facilityId = data?.tierIIReport.facility.id;
  const reportingYear = data?.tierIIReport.reportingYear;

  if (!facilityId || !reportingYear || !productId) {
    return <Skeleton />;
  }

  return (
    <HasFeature
      products
      and
      measurements
      fallback="../chemicals"
      facilityId={facilityId}
    >
      <FacilityProductMeasurementTable
        facilityId={facilityId}
        reportingYear={reportingYear}
        productId={productId}
      />
    </HasFeature>
  );
}
