import Add from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ManageTagsAction } from "generated-graphql/graphql";
import pluralize from "pluralize";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "./Dialog";
import { FormTextField } from "./Forms/FormTextField";

type ManageTagsFormDialogProps = {
  tierIIReportIds: string[];
  onClose: () => void;
  onCloseConfirmation: () => void;
  confirm: () => void;
  open: boolean;
  withConfirmation?: boolean;
  showConfirmation: boolean;
  onSubmit: (
    action: ManageTagsAction,
    formState: ManageTagsFormState
  ) => Promise<void>;
  loading: boolean;
};

export type ManageTagsFormState = {
  tagInput: string;
  tagsToAdd: string[];
};

export function ManageTagsFormDialog({
  tierIIReportIds,
  onClose,
  onCloseConfirmation,
  confirm,
  open,
  withConfirmation,
  showConfirmation,
  onSubmit,
  loading,
}: ManageTagsFormDialogProps) {
  const theme = useTheme();
  const [currentAction, setCurrentAction] = useState<ManageTagsAction>();

  const form = useForm<ManageTagsFormState>({
    defaultValues: {
      tagInput: "",
      tagsToAdd: [],
    },
  });

  const tagsToAdd = form.watch("tagsToAdd");

  const addTag = () => {
    const tagInput = form.getValues("tagInput").trim();
    if (tagInput === "") {
      return;
    }

    form.setValue(
      "tagsToAdd",
      [...new Set(form.getValues("tagsToAdd").concat([tagInput]))].sort(
        (tag1, tag2) => tag1.localeCompare(tag2)
      )
    );
    form.resetField("tagInput");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      addTag();
    }
  };

  const handleDelete = (tag: string) => {
    form.setValue(
      "tagsToAdd",
      tagsToAdd.filter((t) => t !== tag)
    );
  };

  const handleClose = () => {
    if (withConfirmation) {
      onCloseConfirmation();
    }
    onClose();
    form.reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Manage Tags for {tierIIReportIds.length} Report
        {tierIIReportIds.length > 1 ? "s" : ""}
      </DialogTitle>
      <DialogContent>
        <Stack>
          <FormTextField
            name="tagInput"
            label="Add Tag"
            control={form.control}
            textFieldProps={{
              onKeyPress: handleKeyPress,
              InputProps: {
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      addTag();
                    }}
                  >
                    <Add />
                  </IconButton>
                ),
              },
            }}
            sx={{ marginTop: theme.spacing(1) }}
          />
          <Paper
            sx={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: theme.spacing(3),
            }}
          >
            {form.getValues("tagsToAdd").length === 0 && (
              <Typography
                sx={{
                  paddingX: theme.spacing(2),
                  paddingY: theme.spacing(1),
                }}
              >
                No tags to add
              </Typography>
            )}
            {form.getValues("tagsToAdd").map((tag, index) => {
              return (
                <ListItem
                  key={`${tag}-${index}`}
                  sx={{
                    display: "inline",
                    width: "unset",
                    padding: theme.spacing(1),
                  }}
                >
                  <Chip label={tag} onDelete={() => handleDelete(tag)} />
                </ListItem>
              );
            })}
          </Paper>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={theme.spacing(1)}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "10rem" }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => {
                setCurrentAction(ManageTagsAction.Remove);
                if (withConfirmation) {
                  confirm();
                }
              }}
              disabled={!tierIIReportIds.length}
              loading={currentAction === ManageTagsAction.Remove && loading}
              sx={{ width: "10rem" }}
            >
              Remove All Tags
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={() => {
                setCurrentAction(ManageTagsAction.Add);
                if (withConfirmation) {
                  confirm();
                }
              }}
              disabled={!tierIIReportIds.length || !tagsToAdd.length}
              loading={currentAction === ManageTagsAction.Add && loading}
              sx={{ width: "10rem" }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
      {withConfirmation && (
        <Dialog open={showConfirmation} onClose={onCloseConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            {currentAction === ManageTagsAction.Remove ? (
              <p>
                This will remove all tags from {tierIIReportIds.length}{" "}
                {pluralize("report", tierIIReportIds.length)}.
              </p>
            ) : (
              <p>
                This will add the{" "}
                {pluralize("tag", form.getValues("tagsToAdd").length)} to{" "}
                {tierIIReportIds.length}{" "}
                {pluralize("report", tierIIReportIds.length)}.
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() =>
                currentAction === ManageTagsAction.Add
                  ? onSubmit(ManageTagsAction.Add, form.getValues())
                  : onSubmit(ManageTagsAction.Remove, form.getValues())
              }
              variant="contained"
            >
              Yes, I'm sure
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
