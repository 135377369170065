import { useMutation, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";

import {
  DELETE_LEAD_AGENCY,
  GET_NJ_LEAD_AGENECY,
  GET_ALL_NJ_LEAD_AGENECIES,
} from "./schema";

export function ConfirmDeleteNJLeadAgencies({
  agencyId,
  onClose,
}: {
  agencyId: number;
  onClose: () => void;
}) {
  const alerts = useAlerts();

  const { data, previousData } = useQuery(GET_NJ_LEAD_AGENECY, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: agencyId,
    },
  });

  const [deleteLeadAgency, { loading: isDeleting }] =
    useMutation(DELETE_LEAD_AGENCY);

  const leadAgency = data?.leadAgency ?? previousData?.leadAgency;

  const modalContent = (
    <Stack gap={2}>
      <Typography>Are you sure you want to delete this LEAD AGENCY?</Typography>
    </Stack>
  );

  return leadAgency ? (
    <ConfirmDialog
      open={true}
      msg={modalContent}
      title="Confirm Delete"
      confirmText="Delete"
      loading={isDeleting}
      onClose={onClose}
      onConfirm={() =>
        deleteLeadAgency({
          variables: { id: agencyId },
          onCompleted() {
            onClose();
            alerts.success("LEAD AGENCY deleted successfully!");
          },
          onError(e) {
            alerts.error("There was an error deleting the lead agency:", e);
          },
          refetchQueries: [GET_ALL_NJ_LEAD_AGENECIES],
        })
      }
    />
  ) : null;
}
