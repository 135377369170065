import { produce } from "immer";
import { isEqual } from "lodash";
import queryString from "query-string";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

type QueryParamContext = FilterObj<Record<string, any>>;

export const QueryParamContext = createContext<FilterObj<any> | undefined>(
  undefined
);

export type FilterUpdateFunction<T> = (state: Partial<T>) => void | Partial<T>;

export type FilterObj<T> = {
  filters: Partial<T>;
  setFilters: (updateFn: FilterUpdateFunction<T>) => void;
  navigateWithFilters: (to: string, updateFn?: FilterUpdateFunction<T>) => void;
};

export function stringifyQueryParams(params: any) {
  return queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: false,
    arrayFormat: "bracket",
  });
}

function parseQueryString(search: string) {
  return queryString.parse(search, {
    /**
     * Do not change parseNumbers to true. Instead convert strings to numbers as necessary in your component
     */
    parseNumbers: false, // Again, do not change this!
    parseBooleans: true,
    arrayFormat: "bracket",
  });
}

export function QueryParamProvider<T = Record<string, any>>(
  props: PropsWithChildren<unknown>
) {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo<Partial<T>>(
    () => parseQueryString(location.search) as unknown as Partial<T>,
    [location.search]
  );

  const filters = useMemo(
    () => parseQueryString(location.search) as unknown as Partial<T>,
    [location.search]
  );

  // Use a ref to store the latest dependencies of setFiltes, and update on each render.
  // This breaks up the infinite dependency loop that occurs if you try to call setFilters
  // inside of a useEffect. This is not a pattern to copy and use liberally, as it is a rather
  // confusing workaround. Here, I'm deeming this OK, as it is kept internal, and doesn't leak
  // out into the rest of the code that uses this hook
  //
  // TODO: When we eventually move onto react-compiler, we won't have to do this anymore, setFilters will be properly memoized by default!
  const latestFiltersRef = useRef(filters);
  const latestLocationRef = useRef(location);
  latestFiltersRef.current = filters;
  latestLocationRef.current = location;

  const setFilters = useCallback(
    (updateFn: FilterUpdateFunction<T>) => {
      const newState = produce(latestFiltersRef.current, updateFn);
      if (!isEqual(newState, latestFiltersRef.current)) {
        navigate(
          {
            ...latestLocationRef.current,
            search: stringifyQueryParams(newState),
          },
          { replace: true, preventScrollReset: true }
        );
      }
    },
    [navigate]
  );

  const navigateWithFilters = useCallback(
    (to: string, updateFn?: FilterUpdateFunction<T>) => {
      const newState = updateFn ? produce(queryParams, updateFn) : queryParams;
      navigate({
        pathname: `${location.pathname}/${to}`,
        search: stringifyQueryParams(newState),
      });
    },
    [queryParams, navigate, location.pathname]
  );

  const value = useMemo(
    () => ({
      filters,
      navigateWithFilters,
      setFilters,
    }),
    [filters, navigateWithFilters, setFilters]
  );

  return (
    <QueryParamContext.Provider value={value as FilterObj<T>}>
      {props.children}
    </QueryParamContext.Provider>
  );
}
