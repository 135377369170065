import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { ChemicalInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformChemical";
import { useCallback } from "react";
import { ChemicalDetailInputs } from "../../Catalog/Chemical";

const VALIDATE_CHEMICAL_INPUT = gql(`
  query ValidateChemicalInput($id: ID, $data: ChemicalInput!) {
    chemicalInputValidator(id: $id, data: $data) {
      ...issue
    }
  }
`);

export function useChemicalInputValidation(isCreate: boolean) {
  const [query] = useLazyQuery(VALIDATE_CHEMICAL_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: ChemicalDetailInputs) => {
      const inputToValidate: ChemicalInput = transform(input);

      const { data, previousData } = await query({
        variables: {
          data: inputToValidate,
          id: !isCreate ? input.id : undefined,
        },
      });

      return data
        ? data?.chemicalInputValidator
        : previousData?.chemicalInputValidator ?? [];
    },
    [query, isCreate]
  );
}
