import { gql } from "generated-graphql";
import { Tooltip } from "@mui/material";
import { VerifierFragmentFragment } from "generated-graphql/graphql";
import { OmnisearchDataGrid } from "./OmnisearchDataGrid";

gql(`
    fragment VerifierFragment on Person {
        id
        first
        last
        email
        state
        title
        tenant {
          id
          name
        }
        company
        phones {
            number
            type
        }
        streetAddress1
        streetAddress2
        city
        state
        zip
    }
    `);

export const PERSON_QUERY = gql(`
  query Verifiers($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    people(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...VerifierFragment
        }
      count
  }
  }
`);

type Props = {
  onSelectPerson: (person: VerifierFragmentFragment) => void;
  disabled?: boolean;
};
export const VerifierTable = ({ onSelectPerson, disabled }: Props) => {
  const columns = [
    {
      field: "first",
      headerName: "First",
      flex: 0.8,
    },
    {
      field: "last",
      headerName: "Last",
      flex: 0.8,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: (params: any) => (
        <Tooltip title={params.row.email}>
          <span>{params.row.email}</span>
        </Tooltip>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.row.title}>
          <span>{params.row.title}</span>
        </Tooltip>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1.3,
      renderCell: (params: any) => {
        const phones = params.row.phones.map((phone: any, index: number) => (
          <span key={index}>
            {`${phone.type}:${phone.number}`}
            <br />
          </span>
        ));

        return (
          <Tooltip title={phones}>
            <span>{phones}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "state",
      headerName: "State",
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.row.tenant.name}>
          <span>{params.row.tenant.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      flex: 2,
      renderCell: (params: any) => {
        if (
          !params.row.streetAddress1 ||
          !params.row.city ||
          !params.row.state
        ) {
          return null;
        }
        const address = `${params.row.streetAddress1 ?? ""}, ${
          params.row.city ?? ""
        }, ${params.row.state ?? ""}`;
        return (
          <Tooltip title={address}>
            <span>{address}</span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <OmnisearchDataGrid
      columns={columns}
      isURLDriven={false}
      dataQuery={PERSON_QUERY}
      onRowClick={(params) => {
        if (!disabled) {
          onSelectPerson(params.row as VerifierFragmentFragment);
        }
      }}
      excludeFilterColumns={[
        "first",
        "last",
        "state",
        "email",
        "title",
        "phone",
        "company",
        "address",
      ]}
      initialPageSize={8}
      gridSx={{
        minHeight: "300px",
        "& .MuiDataGrid-virtualScroller": {
          minHeight: "200px",
        },
      }}
      getItems={(data) => data.people.items}
      getCount={(data) => data.people.count}
    />
  );
};
