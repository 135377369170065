import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { TenantInput } from "generated-graphql/graphql";
import { useCallback } from "react";

const VALIDATE_TENANT_INPUT = gql(`
  query ValidateTenantInput($input: TenantInput!) {
    tenantInputValidator(input: $input) {
      ...issue
    }
  }
`);

export function transform(input: TenantInput): TenantInput {
  return {
    ...input,
    name: input.name ?? "",
  };
}

export function useTenantInputValidation() {
  const [query] = useLazyQuery(VALIDATE_TENANT_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: TenantInput) => {
      const transformedInput = transform(input);

      const { data: result, previousData } = await query({
        variables: { input: transformedInput },
      });

      return result
        ? result?.tenantInputValidator
        : previousData?.tenantInputValidator ?? [];
    },
    [query]
  );
}
