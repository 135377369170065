import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { Box, Checkbox, Divider, Stack, Typography } from "@mui/material";
import {
  ActivityInput,
  ActivityUserInput,
  ActivityUserInputType,
  GetTierIiReportQuery,
} from "generated-graphql/graphql";

type Props = {
  activity: NonNullable<
    GetTierIiReportQuery["tierIIReport"]["currentWorkflow"]
  >["activities"][0];
};

const UPDATE_ACTIVITY_INPUT_MUTATION = gql(`
  mutation SetValueOnActivityUserInput($id: ID!, $value: String!) {
    setValueOnActivityUserInput(id: $id, value: $value) {
      id
      value
}}`);

export const ActivityInputs = ({ activity }: Props) => {
  const inputs = activity.inputs;
  if (!inputs || inputs?.length === 0) {
    return null;
  }

  return (
    <Box sx={{ paddingTop: 1 }}>
      <Divider />
      <Typography variant="h6">User Inputs</Typography>
      {inputs.map((input, index) => {
        if (!input) {
          return null;
        }
        return <ActivityUserInput key={index} input={input} />;
      })}
      <Divider />
    </Box>
  );
};

const ActivityUserInput = ({ input }: { input: ActivityUserInput }) => {
  const [setInput, { loading }] = useMutation(UPDATE_ACTIVITY_INPUT_MUTATION);
  if (!input) {
    return null;
  }
  switch (input.type) {
    case ActivityUserInputType.Boolean:
      return (
        <Stack direction="row" alignItems={"center"}>
          <Checkbox
            required={input.required}
            disabled={loading}
            checked={input.value === "true"}
            onChange={(e) => {
              setInput({
                variables: {
                  id: input.id,
                  value: e.target.checked ? "true" : "false",
                },
              });
            }}
            inputProps={{ "aria-label": input.label }}
          />
          <Typography variant="body1">{input.label}</Typography>
        </Stack>
      );
    default:
      return null;
  }
};
