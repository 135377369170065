/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateEventSeriesInput = {
  event: CreateSingleEventInput,
  watcherIds?: Array< string > | null,
  documentIds?: Array< string > | null,
};

export type CreateSingleEventInput = {
  facilityId: string,
  responsiblePersonId?: string | null,
  eventResponsiblePersonId?: string | null,
  requirementId: string,
  requirement?: EventUpdateRequirementInput | null,
  description?: string | null,
  status: EventStatus,
  statusSearchable: string,
  tasks?: Array< TaskInput | null > | null,
  hyperlinks?: Array< HyperLinkInput | null > | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
  frequency?: RepeatRuleInput | null,
};

export type EventUpdateRequirementInput = {
  area?: string | null,
  description?: string | null,
  name?: string | null,
  agencyId?: string | null,
  userAgencyTitle?: string | null,
};

export enum EventStatus {
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
}


export type TaskInput = {
  isCompleted: boolean,
  text: string,
};

export type HyperLinkInput = {
  name?: string | null,
  link?: string | null,
};

export type TimespanInput = {
  value: number,
  unit: TimeIncrement,
};

export enum TimeIncrement {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}


export type RepeatRuleInput = {
  frequency: Frequency,
  interval: number,
  start?: string | null,
};

export enum Frequency {
  ONCE = "ONCE",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
}


export type UpdateEventsInput = {
  eventId: string,
  newData: UpdateEventNewDataInput,
};

export type UpdateEventNewDataInput = {
  event?: EventUpdateInput | null,
  watchers?: Array< string > | null,
};

export type EventUpdateInput = {
  facilityId?: string | null,
  eventResponsiblePersonId?: string | null,
  requirement?: EventUpdateRequirementInput | null,
};

export type MarkEventsAsCompletedInput = {
  events: Array< MarkEventAsCompletedInput >,
};

export type MarkEventAsCompletedInput = {
  id: string,
  deadline: string,
};

export type NewUpdateFutureEventsInput = {
  updateAt: string,
  seriesId: string,
  sequenceInput: UpdateSequenceInput,
  watcherIds?: Array< string > | null,
};

export type UpdateSequenceInput = {
  id?: string | null,
  parentId?: string | null,
  owner?: string | null,
  seriesId?: string | null,
  deadline?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  requirement?: NestedRequirementInput | null,
  exceptions?: Array< string > | null,
  facilityId?: string | null,
  responsiblePersonId?: string | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
  completedAt?: string | null,
  status?: EventSequenceStatus | null,
  description?: string | null,
  citations?: Array< LinkInput | null > | null,
};

export type NestedRequirementInput = {
  id?: string | null,
  status?: RequirementStatus | null,
  name?: string | null,
  title?: string | null,
  description?: string | null,
  frequency?: RepeatRuleInput | null,
  agencyId?: string | null,
  citations?: Array< LinkInput | null > | null,
  area?: string | null,
  agency?: NestedAgencyInput | null,
  userAgencyTitle?: string | null,
  tasks?: Array< TaskInput | null > | null,
  hyperlinks?: Array< HyperLinkInput | null > | null,
  documents?: Array< NestedDocumentInput | null > | null,
};

export enum RequirementStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type LinkInput = {
  name?: string | null,
  link?: string | null,
};

export type NestedAgencyInput = {
  id: string,
  owner?: string | null,
  name: string,
  level: AgencyLevel,
  link?: LinkInput | null,
  jurisdiction: string,
  state?: StateOrTerritory | null,
  locale?: string | null,
};

export enum AgencyLevel {
  FEDERAL = "FEDERAL",
  STATE = "STATE",
  LOCAL = "LOCAL",
}


export enum StateOrTerritory {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
  DC = "DC",
  VI = "VI",
  PR = "PR",
  MP = "MP",
  GU = "GU",
  AS = "AS",
}


export type NestedDocumentInput = {
  id: string,
  title: string,
  owner?: string | null,
  storageLink: string,
};

export enum EventSequenceStatus {
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
}


export type NewUpdateFutureEventsOutput = {
  __typename: "NewUpdateFutureEventsOutput",
  newSequenceId?: string | null,
};

export type NewUpdateEventInput = {
  deadline: string,
  sequenceId: string,
  sequenceInput: UpdateSequenceInput,
  watcherIds: Array< string >,
  documentIds: Array< string >,
  type?: EventSequenceType | null,
};

export enum EventSequenceType {
  CALENDAR = "CALENDAR",
  TASK = "TASK",
}


export type NewMarkEventsAsCompletedInput = {
  events: Array< NewMarkEventAsCompletedInput >,
};

export type NewMarkEventAsCompletedInput = {
  sequenceId: string,
  deadline: string,
};

export type NewCreateEventSeriesInput = {
  eventSequence: CreateSequenceInput,
  watcherIds?: Array< string > | null,
  documentIds?: Array< string > | null,
  type?: EventSequenceType | null,
};

export type CreateSequenceInput = {
  parentId?: string | null,
  owner: string,
  seriesId: string,
  startDate: string,
  type: EventSequenceType,
  endDate: string,
  requirement: NestedRequirementInput,
  exceptions?: Array< string > | null,
  facilityId: string,
  responsiblePersonId?: string | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
  completedAt?: string | null,
  status: EventSequenceStatus,
  description?: string | null,
  citations?: Array< LinkInput | null > | null,
};

export type NewEndEventSeriesInput = {
  endAt: string,
  sequenceId: string,
};

export type NewDeleteEventInput = {
  deleteAt: string,
  sequenceId: string,
};

export type CreateNewManagedTaskInput = {
  startDate: string,
  endDate: string,
  requirement: NestedRequirementInput,
  watcherId?: string | null,
  assigneeId?: string | null,
  isOrganizationLevel: boolean,
  programArea?: string | null,
};

export type EditManagedTaskInput = {
  id: string,
  startDate: string,
  endDate: string,
  requirement: NestedRequirementInput,
  watcherId?: string | null,
  assigneeId?: string | null,
  isOrganizationLevel?: boolean | null,
  programArea?: string | null,
};

export type BulkAssignFacilitiesToTaskInput = {
  taskId: string,
  facilityIds?: Array< string > | null,
  assigneeId?: string | null,
  isOrganizationLevel?: boolean | null,
};

export type BulkUnassignFacilitiesFromTaskInput = {
  taskId: string,
  facilityIds?: Array< string > | null,
  isOrganizationLevel?: boolean | null,
};

export type CreateTaskInput = {
  facilityId: string,
  startDate: string,
  endDate?: string | null,
  name: string,
  description?: string | null,
  frequency: Frequency,
  interval: number,
  subTasks?: Array< TaskInput > | null,
  hyperlinks?: Array< HyperLinkInput > | null,
  programArea?: string | null,
  isOrganizationLevel?: boolean | null,
  watcherIds?: Array< string > | null,
  assigneeId?: string | null,
  documentIds?: Array< string > | null,
  state?: string | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
};

export type UpdateTaskInput = {
  updateSeriesAt: string,
  seriesId: string,
  sequenceInput: UpdateTaskSequenceInput,
  watcherIds?: Array< string > | null,
  documentIds?: Array< string > | null,
};

export type UpdateTaskSequenceInput = {
  deadline: string,
  facilityId: string,
  name: string,
  description?: string | null,
  frequency: Frequency,
  subTasks?: Array< TaskInput > | null,
  hyperlinks?: Array< HyperLinkInput > | null,
  programArea: string,
  assigneeId: string,
  notificationLeadTimes?: Array< TimespanInput > | null,
  status?: EventSequenceStatus | null,
  completedAt?: string | null,
};

export type EditTaskOccurrenceInput = {
  updateSeriesAt: string,
  seriesId: string,
  sequenceInput: EditTaskOccurrenceSequenceInput,
  watcherIds?: Array< string > | null,
  documentIds?: Array< string > | null,
};

export type EditTaskOccurrenceSequenceInput = {
  completedAt?: string | null,
  subTasks?: Array< TaskInput > | null,
  hyperlinks?: Array< HyperLinkInput > | null,
  status?: EventSequenceStatus | null,
};

export type CompleteTaskInput = {
  updateSeriesAt: string,
  sequenceId: string,
  status?: EventSequenceStatus | null,
  subtasks?: Array< TaskInput > | null,
};

export type DeleteTaskInput = {
  seriesId: string,
  deleteTaskAt: string,
  shouldDeleteFutureOccurrences?: boolean | null,
};

export type UpdateNotificationsInput = {
  items?: Array< UpdateNotificationParameters > | null,
};

export type UpdateNotificationParameters = {
  id: string,
};

export type PushNotificationInput = {
  owner: string,
  facilityId?: string | null,
  notificationType: NotificationType,
  personId: string,
  name: string,
  description?: string | null,
  emailParams?: SendEmailInput | null,
  rcraStatusChangeContent?: RcraStatusChangeContentInput | null,
  sdsExtractionContent?: SdsExtractionContentInput | null,
};

export enum NotificationType {
  EVENT = "EVENT",
  EVENTSEQUENCE = "EVENTSEQUENCE",
  EVENTSEQUENCEWATCHER = "EVENTSEQUENCEWATCHER",
  TIERII = "TIERII",
  RCRA_STATUS_CHANGE = "RCRA_STATUS_CHANGE",
  SDS_EXTRACTION = "SDS_EXTRACTION",
}


export type SendEmailInput = {
  from: string,
  to: string,
  replyTo?: string | null,
  subject: string,
  text: string,
  useTemplate?: boolean | null,
  linkText?: string | null,
  hyperlink?: string | null,
  attachments?: Array< SendEmailAttachmentInput > | null,
};

export type SendEmailAttachmentInput = {
  key: string,
  mimeType: string,
  filename: string,
};

export type RcraStatusChangeContentInput = {
  generatorStatus?: RcraGeneratorStatus | null,
  changedFromManifest?: boolean | null,
};

export enum RcraGeneratorStatus {
  VSQG = "VSQG",
  SQG = "SQG",
  LQG = "LQG",
  CA_SQG = "CA_SQG",
  CA_LQG = "CA_LQG",
  KS_CESQG = "KS_CESQG",
  KS_KSQG = "KS_KSQG",
  KS_SQG = "KS_SQG",
  KS_LQG = "KS_LQG",
  ME_SQG = "ME_SQG",
  ME_SQGPlus = "ME_SQGPlus",
  ME_LQG = "ME_LQG",
  MD_SQG = "MD_SQG",
  MD_Fully_Regulated = "MD_Fully_Regulated",
  MA_VSQG = "MA_VSQG",
  MA_SQG = "MA_SQG",
  MA_LQG = "MA_LQG",
  MN_MiniQG = "MN_MiniQG",
  MN_VSQG = "MN_VSQG",
  MN_SQG = "MN_SQG",
  MN_LQG = "MN_LQG",
  NH_SQG = "NH_SQG",
  NH_FQG = "NH_FQG",
  RI_CESQG = "RI_CESQG",
  RI_SQG = "RI_SQG",
  RI_LQG = "RI_LQG",
  TX_Class_1 = "TX_Class_1",
  TX_VSQG = "TX_VSQG",
  TX_SQG = "TX_SQG",
  TX_LQG = "TX_LQG",
  WA_SQG = "WA_SQG",
  WA_MQG = "WA_MQG",
  WA_LQG = "WA_LQG",
}


export type SdsExtractionContentInput = {
  productId?: string | null,
  chemName?: string | null,
};

export type Notification = {
  __typename: "Notification",
  id?: string,
  owner?: string | null,
  notificationType?: NotificationType,
  parentId?: string | null,
  personId?: string | null,
  readAt?: string | null,
  lifecycleStatus?: NotificationLifecycleStatus,
  scheduledFor?: string,
  activeAt?: string | null,
  archivedAt?: string | null,
  name?: string,
  description?: string | null,
  emailParams?: SendEmailParams,
  eventContent?: NotificationEventContent,
  rcraStatusChangeContent?: RcraStatusChangeContent,
  sdsExtractionContent?: SdsExtractionContent,
  facilityId?: string | null,
  notificationTypeSearchable?: string | null,
  lifecycleStatusSearchable?: string | null,
  areaSearchable?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facility?: Facility,
  facilities?: ModelNotificationFacilityLinkConnection,
  requirements?: ModelNotificationRequirementLinkConnection,
};

export enum NotificationLifecycleStatus {
  SCHEDULED = "SCHEDULED",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export type SendEmailParams = {
  __typename: "SendEmailParams",
  from?: string | null,
  to?: string | null,
  subject?: string | null,
  replyTo?: string | null,
  text?: string | null,
  linkText?: string | null,
  hyperlink?: string | null,
};

export type NotificationEventContent = {
  __typename: "NotificationEventContent",
  area?: string | null,
  deadline?: string | null,
  seriesId?: string | null,
  type?: EventSequenceType | null,
};

export type RcraStatusChangeContent = {
  __typename: "RcraStatusChangeContent",
  generatorStatus?: RcraGeneratorStatus | null,
  changedFromManifest?: boolean | null,
};

export type SdsExtractionContent = {
  __typename: "SdsExtractionContent",
  productId?: string | null,
  chemName?: string | null,
};

export type Facility = {
  __typename: "Facility",
  id?: string,
  owner?: string | null,
  name?: string,
  status?: FacilityStatus | null,
  statusReason?: string | null,
  epaGeneratorStatus?: RcraGeneratorStatus | null,
  epaGeneratorStatusUpdatedAt?: string | null,
  epaGeneratorStatusUpdatedBy?: string | null,
  stateGeneratorStatus?: RcraGeneratorStatus | null,
  stateGeneratorStatusUpdatedAt?: string | null,
  stateGeneratorStatusUpdatedBy?: string | null,
  businessUnit?: string | null,
  customerFacilityId?: string | null,
  dbNumber?: string | null,
  facilityType?: string | null,
  naicsCode?: number | null,
  maxNumOccupants?: number | null,
  isUnmanned?: boolean | null,
  phone?: string | null,
  phone24Hour?: string | null,
  streetAddress?: Address,
  mailingAddress?: Address,
  state?: string | null,
  county?: string | null,
  municipality?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  isAutoGeocoded?: boolean | null,
  lepc?: string | null,
  lepcId?: string | null,
  fireDistrict?: string | null,
  fireDistrictId?: string | null,
  programAreas?: Array< string | null > | null,
  alternateIds?:  Array<FacilityAlternateId > | null,
  userPermissions?:  Array<UserPermission | null > | null,
  dateOpened?: string | null,
  dateClosed?: string | null,
  dateInactive?: string | null,
  regulatoryInformation?: FacilityRegulatoryInformation,
  federalFields?: string | null,
  stateFields?: string | null,
  searchableProgramAreas?: string | null,
  searchableStatus?: string | null,
  searchableCity?: string | null,
  lastProductMeasuredAt?: string | null,
  customerProductComments?:  Array<TierIIComment > | null,
  streetAddressVerifiedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  contacts?: ModelFacilityContactConnection,
  credentials?: ModelFacilityCredentialConnection,
  requirements?: ModelFacilityRequirementConnection,
};

export type MinimalFacility = {
  __typename: "MinimalFacility",
  id?: string,
  owner?: string | null,
  name?: string,
  state?: string | null,
  county?: string | null,
  municipality?: string | null,
  streetAddress?: Address,
};

export enum FacilityStatus {
  PLANNED = "PLANNED",
  OPEN = "OPEN",
  INACTIVE = "INACTIVE",
  CLOSED = "CLOSED",
}


export type Address = {
  __typename: "Address",
  streetAddress?: string | null,
  streetAddressLine2?: string | null,
  city?: string | null,
  zip?: string | null,
  state?: string | null,
  country?: string | null,
};

export type FacilityAlternateId = {
  __typename: "FacilityAlternateId",
  kind?: string,
  id?: string,
  expiresAt?: string | null,
  recordKeys?: Array< string | null > | null,
};

export type UserPermission = {
  __typename: "UserPermission",
  userTenantId?: string,
  level?: UserPermissionLevel,
};

export enum UserPermissionLevel {
  READ = "READ",
  WRITE = "WRITE",
}


export type FacilityRegulatoryInformation = {
  __typename: "FacilityRegulatoryInformation",
  isSubjectToChemicalAccidentPrevention?: boolean | null,
  isSubjectToEmergencyPlanning?: boolean | null,
  isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
  reconciledAt?: string | null,
  rmpId?: string | null,
  triId?: string | null,
};

export type TierIIComment = {
  __typename: "TierIIComment",
  at?: string,
  comment?: string,
  commentedBy?: string,
};

export type ModelFacilityContactConnection = {
  __typename: "ModelFacilityContactConnection",
  items?:  Array<FacilityContact | null > | null,
  nextToken?: string | null,
};

export type FacilityContact = {
  __typename: "FacilityContact",
  id?: string,
  owner?: string | null,
  facilityId?: string,
  personId?: string,
  person?: Person,
  areas?: Array< string > | null,
  reportingRoles?: Array< ContactReportRole > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facility?: Facility,
};

export type Person = {
  __typename: "Person",
  id?: string,
  owner?: string | null,
  first?: string | null,
  last?: string | null,
  email?: string | null,
  title?: string | null,
  company?: string | null,
  address?: Address,
  state?: string | null,
  phones?:  Array<Phone >,
  contactType?: ContactType | null,
  searchableProgramAreas?: string | null,
  searchableFacilities?: string | null,
  searchableContactTypes?: string | null,
  userTenantId?: string | null,
  paymentMethodId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facilities?: ModelFacilityContactConnection,
  userTenant?: UserTenant,
};

export type Phone = {
  __typename: "Phone",
  type?: PhoneType,
  reportTypes?: Array< ReportType > | null,
  number?: string,
};

export enum PhoneType {
  CELL = "CELL",
  HOME = "HOME",
  WORK = "WORK",
  HRS_24 = "HRS_24",
  EMERGENCY = "EMERGENCY",
  FAX = "FAX",
}


export enum ReportType {
  EPCRA = "EPCRA",
  RCRA = "RCRA",
}


export enum ContactType {
  BILLING = "BILLING",
  ORGANIZATION = "ORGANIZATION",
  REGULATORY = "REGULATORY",
  THIRD_PARTY = "THIRD_PARTY",
}


export type UserTenant = {
  __typename: "UserTenant",
  id?: string,
  tenantId?: string,
  email?: string,
  groups?: Array< string > | null,
  invitedAt?: string | null,
  invitationExpiresAt?: string | null,
  status?: UserTenantStatus,
  personId?: string,
  isDisabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  person?: Person,
  tenant?: Tenant,
  roles?: ModelUserTenantRoleConnection,
};

export enum UserTenantStatus {
  NOT_INVITED = "NOT_INVITED",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type Tenant = {
  __typename: "Tenant",
  id?: string,
  name?: string,
  partnerTenantId?: string | null,
  salesforceId?: string | null,
  accountType?: AccountType | null,
  isDemo?: boolean | null,
  isPartner?: boolean | null,
  isDisabled?: boolean | null,
  externalId?: string | null,
  ssoConfig?: SsoConfig,
  roleIdToDefaultForNewUsers?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  users?: ModelUserTenantConnection,
};

export enum AccountType {
  CUSTOMER = "CUSTOMER",
  DEMO = "DEMO",
  PROSPECT = "PROSPECT",
}


export type SsoConfig = {
  __typename: "SsoConfig",
  mappingName?: string,
  emailDomains?: Array< string >,
};

export type ModelUserTenantConnection = {
  __typename: "ModelUserTenantConnection",
  items?:  Array<UserTenant | null > | null,
  nextToken?: string | null,
};

export type ModelUserTenantRoleConnection = {
  __typename: "ModelUserTenantRoleConnection",
  items?:  Array<UserTenantRole | null > | null,
  nextToken?: string | null,
};

export type UserTenantRole = {
  __typename: "UserTenantRole",
  id?: string,
  owner?: string,
  userTenantId?: string,
  roleId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  role?: Role,
  userTenant?: UserTenant,
};

export type Role = {
  __typename: "Role",
  id?: string,
  owner?: string,
  name?: string,
  rolePermissions?: Array< string > | null,
  roleType?: RoleTypeEnum,
  createdAt?: string,
  updatedAt?: string,
  updatedBy?: string,
};

export enum RoleTypeEnum {
  ENCAMP_ASSIGNABLE_BY_STAFF = "ENCAMP_ASSIGNABLE_BY_STAFF",
  ENCAMP_ASSIGNABLE_BY_USER = "ENCAMP_ASSIGNABLE_BY_USER",
}


export enum ContactReportRole {
  EPCRA_CHEMICAL_CARRIER = "EPCRA_CHEMICAL_CARRIER",
  EPCRA_EMERGENCY_CONTACT = "EPCRA_EMERGENCY_CONTACT",
  EPCRA_EMERGENCY_COORDINATOR = "EPCRA_EMERGENCY_COORDINATOR",
  EPCRA_OWNER_OPERATOR = "EPCRA_OWNER_OPERATOR",
  EPCRA_TIER_II_CONTACT = "EPCRA_TIER_II_CONTACT",
  EPCRA_BILLING = "EPCRA_BILLING",
  GENERAL = "GENERAL",
  RCRA_OWNER = "RCRA_OWNER",
  RCRA_OPERATOR = "RCRA_OPERATOR",
  RCRA_SITE_CONTACT = "RCRA_SITE_CONTACT",
}


export type ModelFacilityCredentialConnection = {
  __typename: "ModelFacilityCredentialConnection",
  items?:  Array<FacilityCredential | null > | null,
  nextToken?: string | null,
};

export type FacilityCredential = {
  __typename: "FacilityCredential",
  id?: string,
  owner?: string | null,
  facilityId?: string,
  credentialId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  credential?: Credential,
  facility?: Facility,
};

export type Credential = {
  __typename: "Credential",
  id?: string,
  owner?: string | null,
  name?: string,
  username?: string,
  password?: string,
  kind?: CredentialKind,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  securityQuestions?:  Array<SecurityQuestion > | null,
  verificationStatus?: CredentialVerificationStatus | null,
  lastVerificationSuccessAt?: string | null,
  lastVerificationCheckAt?: string | null,
  recentUsage?:  Array<CredentialUsageLogEntry > | null,
  pools?: ModelCredentialPoolCredentialConnection,
  facilities?: ModelFacilityCredentialConnection,
};

export enum CredentialKind {
  RCRA_COUNTY = "RCRA_COUNTY",
  RCRA_STATE = "RCRA_STATE",
  RCRA_FEDERAL = "RCRA_FEDERAL",
  RCRA_PAYMENT = "RCRA_PAYMENT",
  TIERII = "TIERII",
  LEPC = "LEPC",
}


export type SecurityQuestion = {
  __typename: "SecurityQuestion",
  question?: string,
  answer?: string,
};

export enum CredentialVerificationStatus {
  NOT_ATTEMPTED = "NOT_ATTEMPTED",
  GOOD = "GOOD",
  INVALID_ACCESSID_OR_PASSWORD = "INVALID_ACCESSID_OR_PASSWORD",
  BAD_USERNAME = "BAD_USERNAME",
  BAD_PASSWORD = "BAD_PASSWORD",
  BAD_SECURITY_QUESTION_ANSWER = "BAD_SECURITY_QUESTION_ANSWER",
  EXPIRED_PASSWORD = "EXPIRED_PASSWORD",
  IN_USE = "IN_USE",
  REQUIRES_NEW_PASSWORD = "REQUIRES_NEW_PASSWORD",
  LOCKED = "LOCKED",
  UNKNOWN_NON_VERIFIABLE = "UNKNOWN_NON_VERIFIABLE",
  UNKNOWN_ERROR_NEEDS_HUMAN = "UNKNOWN_ERROR_NEEDS_HUMAN",
}


export type CredentialUsageLogEntry = {
  __typename: "CredentialUsageLogEntry",
  timestamp?: string,
  verificationStatus?: CredentialVerificationStatus,
  errorMessage?: string | null,
};

export type ModelCredentialPoolCredentialConnection = {
  __typename: "ModelCredentialPoolCredentialConnection",
  items?:  Array<CredentialPoolCredential | null > | null,
  nextToken?: string | null,
};

export type CredentialPoolCredential = {
  __typename: "CredentialPoolCredential",
  id?: string,
  owner?: string,
  credentialId?: string,
  credentialPoolId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  credential?: Credential,
  credentialPool?: CredentialPool,
};

export type CredentialPool = {
  __typename: "CredentialPool",
  id?: string,
  owner?: string | null,
  description?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  credentials?: ModelCredentialPoolCredentialConnection,
};

export type ModelFacilityRequirementConnection = {
  __typename: "ModelFacilityRequirementConnection",
  items?:  Array<FacilityRequirement | null > | null,
  nextToken?: string | null,
};

export type FacilityRequirement = {
  __typename: "FacilityRequirement",
  id?: string,
  owner?: string | null,
  facilityId?: string,
  requirementId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facility?: Facility,
  requirement?: Requirement,
};

export type Requirement = {
  __typename: "Requirement",
  id?: string,
  owner?: string | null,
  status?: RequirementStatus | null,
  name?: string,
  description?: string | null,
  frequency?: RepeatRule,
  agencyId?: string,
  citations?:  Array<Link | null > | null,
  area?: string,
  areaSearchable?: string,
  jurisdictionalAgency?: string | null,
  intervalInWords?: string | null,
  parentStatute?: ParentStatute | null,
  jurisdictionalState?: string | null,
  jurisdictionalCounties?: Array< string | null > | null,
  title?: string | null,
  portals?:  Array<Link | null > | null,
  jurisdictionalTownship?: string | null,
  jurisdictionalTribalArea?: string | null,
  jurisdictionalCity?: string | null,
  formExpires?: string | null,
  forms?:  Array<Link | null > | null,
  fillableLinks?: Array< string | null > | null,
  otherLinks?:  Array<Link | null > | null,
  instructionFileName?: string | null,
  instruction?: string | null,
  extraDocumentations?:  Array<Link | null > | null,
  department?: string | null,
  subDepartment?: string | null,
  contactEmail?: string | null,
  contactPhone?: string | null,
  contactPhoneExtension?: number | null,
  isExtensionAvailable?: boolean | null,
  extensionNotes?: string | null,
  penalty?: string | null,
  encampInstructions?: string | null,
  isAlwaysApplicable?: boolean | null,
  agencyLevelSearchable?: string | null,
  agencyStateSearchable?: string | null,
  userAgencyTitle?: string | null,
  tasks?:  Array<Task | null > | null,
  hyperlinks?:  Array<HyperLink | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  agency?: Agency,
  documents?: ModelDocumentRequirementConnection,
  facilities?: ModelFacilityRequirementConnection,
};

export type RepeatRule = {
  __typename: "RepeatRule",
  frequency?: Frequency,
  interval?: number,
  start?: string | null,
};

export type Link = {
  __typename: "Link",
  name?: string | null,
  link?: string | null,
};

export enum ParentStatute {
  NONE = "NONE",
  CWA = "CWA",
  RCRA = "RCRA",
  TSCA = "TSCA",
  EP313 = "EP313",
  CAA = "CAA",
  UST = "UST",
  OSHA = "OSHA",
}


export type Task = {
  __typename: "Task",
  isCompleted?: boolean,
  text?: string,
};

export type HyperLink = {
  __typename: "HyperLink",
  name?: string | null,
  link?: string | null,
};

export type Agency = {
  __typename: "Agency",
  id?: string,
  owner?: string | null,
  name?: string,
  level?: AgencyLevel,
  link?: Link,
  jurisdiction?: string,
  state?: StateOrTerritory | null,
  locale?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelDocumentRequirementConnection = {
  __typename: "ModelDocumentRequirementConnection",
  items?:  Array<DocumentRequirement | null > | null,
  nextToken?: string | null,
};

export type DocumentRequirement = {
  __typename: "DocumentRequirement",
  id?: string,
  owner?: string | null,
  documentId?: string,
  requirementId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  document?: Document,
  events?: ModelEventConnection,
  requirement?: Requirement,
};

export type Document = {
  __typename: "Document",
  id?: string,
  owner?: string | null,
  title?: string,
  description?: string | null,
  fileExtension?: string,
  state?: string | null,
  area?: string | null,
  programAreas?: Array< string | null > | null,
  documentType?: string,
  sourceLink?: string | null,
  storageLink?: string,
  agencyId?: string | null,
  facilityId?: string | null,
  lobId?: string | null,
  createdBy?: string | null,
  stateSearchable?: string | null,
  areaSearchable?: string | null,
  searchableProgramAreas?: string | null,
  relatedEntityIds?:  Array<DocumentRelatedEntityId > | null,
  tags?: string | null,
  isArchived?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  requirements?: ModelDocumentRequirementConnection,
};

export type DocumentRelatedEntityId = {
  __typename: "DocumentRelatedEntityId",
  kind?: DocumentRelatedEntityIdKind,
  id?: string,
};

export enum DocumentRelatedEntityIdKind {
  TIER2 = "TIER2",
  RCRA_WASTESTREAM = "RCRA_WASTESTREAM",
  ACTIVITY = "ACTIVITY",
}


export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
};

export type Event = {
  __typename: "Event",
  id?: string,
  seriesId?: string,
  requirementId?: string,
  requirement?: NestedRequirement,
  _requirementName?: string,
  _requirementArea?: string,
  facilityId?: string,
  eventResponsiblePersonId?: string | null,
  notificationLeadTimes?:  Array<Timespan > | null,
  completedAt?: string | null,
  status?: EventStatus,
  statusSearchable?: string,
  owner?: string | null,
  deadline?: string,
  description?: string | null,
  citations?:  Array<Link | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  documents?: ModelEventDocumentConnection,
  watchers?: ModelEventWatcherConnection,
  facility?: Facility,
  responsiblePerson?: Person,
};

export type NestedRequirement = {
  __typename: "NestedRequirement",
  id?: string | null,
  status?: RequirementStatus | null,
  name?: string | null,
  title?: string | null,
  description?: string | null,
  frequency?: RepeatRule,
  agencyId?: string | null,
  citations?:  Array<Link | null > | null,
  area?: string | null,
  agency?: NestedAgency,
  userAgencyTitle?: string | null,
  tasks?:  Array<Task | null > | null,
  hyperlinks?:  Array<HyperLink | null > | null,
  documents?:  Array<NestedDocument | null > | null,
};

export type NestedAgency = {
  __typename: "NestedAgency",
  id?: string,
  owner?: string | null,
  name?: string,
  level?: AgencyLevel,
  link?: Link,
  jurisdiction?: string,
  state?: StateOrTerritory | null,
  locale?: string | null,
};

export type NestedDocument = {
  __typename: "NestedDocument",
  id?: string,
  owner?: string | null,
  title?: string,
  storageLink?: string,
};

export type Timespan = {
  __typename: "Timespan",
  value?: number,
  unit?: TimeIncrement,
};

export type ModelEventDocumentConnection = {
  __typename: "ModelEventDocumentConnection",
  items?:  Array<EventDocument | null > | null,
  nextToken?: string | null,
};

export type EventDocument = {
  __typename: "EventDocument",
  id?: string,
  owner?: string | null,
  documentId?: string,
  eventId?: string,
  seriesId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  document?: Document,
  event?: Event,
};

export type ModelEventWatcherConnection = {
  __typename: "ModelEventWatcherConnection",
  items?:  Array<EventWatcher | null > | null,
  nextToken?: string | null,
};

export type EventWatcher = {
  __typename: "EventWatcher",
  id?: string,
  owner?: string | null,
  personId?: string,
  eventId?: string,
  deadline?: string,
  seriesId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  event?: Event,
  person?: Person,
};

export type ModelNotificationFacilityLinkConnection = {
  __typename: "ModelNotificationFacilityLinkConnection",
  items?:  Array<NotificationFacilityLink | null > | null,
  nextToken?: string | null,
};

export type NotificationFacilityLink = {
  __typename: "NotificationFacilityLink",
  id?: string,
  owner?: string | null,
  notificationType?: NotificationType,
  notificationId?: string,
  facilityId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facility?: Facility,
  notification?: Notification,
};

export type ModelNotificationRequirementLinkConnection = {
  __typename: "ModelNotificationRequirementLinkConnection",
  items?:  Array<NotificationRequirementLink | null > | null,
  nextToken?: string | null,
};

export type NotificationRequirementLink = {
  __typename: "NotificationRequirementLink",
  id?: string,
  owner?: string | null,
  notificationType?: NotificationType,
  notificationId?: string,
  requirementId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  notification?: Notification,
  requirement?: Requirement,
};

export type SilentCreateUserInput = {
  email: string,
  personId: string,
  tenantId: string,
};

export type InviteExistingUserInput = {
  userTenantId: string,
};

export type CreateOrganizationInput = {
  organizationName: string,
  isDemo?: boolean | null,
  isPartner?: boolean | null,
  partnerTenantId?: string | null,
};

export type ChangeTenantInput = {
  tenantId: string,
};

export type PurgeFacilityRequirementInput = {
  seriesId: string,
};

export type CreateUserInput = {
  email: string,
  first: string,
  last: string,
  title?: string | null,
  roleId: string,
  personId?: string | null,
  company?: string | null,
  address?: CreateUserAddressInput | null,
  phones?: Array< CreateUserPhoneInput | null > | null,
  contactType?: ContactType | null,
};

export type CreateUserAddressInput = {
  streetAddress?: string | null,
  streetAddressLine2?: string | null,
  city?: string | null,
  zip?: string | null,
  state?: string | null,
  country?: string | null,
};

export type CreateUserPhoneInput = {
  type: PhoneType,
  reportTypes?: Array< ReportType > | null,
  number: string,
};

export type GenerateTierIIProductSheetInput = {
  processId: string,
};

export type GenerateTierIIFacilityProductSheetInput = {
  processId: string,
  reportId: string,
};

export type GenerateTierIIFacilitiesProductsSheetInput = {
  processId: string,
  facilityIds?: Array< string | null > | null,
};

export type GeneratePersonsSheetInput = {
  processId: string,
};

export type GenerateSpreadsheetInput = {
  processId: string,
};

export type GenerateTierIIReportPdfInput = {
  processId: string,
  reportId: string,
  kind: string,
};

export type PublishDocumentInput = {
  processId: string,
  url?: string | null,
  errorMsg?: string | null,
};

export type PublishedDocument = {
  __typename: "PublishedDocument",
  processId?: string,
  url?: string | null,
  errorMsg?: string | null,
};

export type PublishExceptionReportInput = {
  processId: string,
  key?: string | null,
  errorMsg?: string | null,
};

export type PublishedExceptionReport = {
  __typename: "PublishedExceptionReport",
  processId?: string,
  key?: string | null,
  errorMsg?: string | null,
};

export type GenerateReportInput = {
  reportKind: string,
  tenantId: string,
  processId?: string | null,
};

export type BulkUpdateTierIIProductsInput = {
  filename: string,
};

export type BulkUpdateTierIIProducts = {
  __typename: "BulkUpdateTierIIProducts",
  updatedRowCount?: number,
  errors?: Array< string >,
};

export type BulkUpdateTierIIFacilityProductsInput = {
  filename: string,
  reportId: string,
  state: string,
};

export type BulkUpdateTierIIFacilityProducts = {
  __typename: "BulkUpdateTierIIFacilityProducts",
  updatedRowCount?: number,
  errors?: Array< string >,
};

export type BulkUpdateTierIIFacilitiesProductsInput = {
  filename: string,
};

export type BulkUpdateTierIIFacilitiesProducts = {
  __typename: "BulkUpdateTierIIFacilitiesProducts",
  updatedRowCount?: number,
  errors?: Array< string >,
};

export type BulkUpdateTierIIFacilityProductsValidationResults = {
  __typename: "BulkUpdateTierIIFacilityProductsValidationResults",
  updatedRows?:  Array<MultiFacilitySpreadsheetFacilityProducts >,
  validationErrors?:  Array<ValidationError >,
};

export type MultiFacilitySpreadsheetFacilityProducts = {
  __typename: "MultiFacilitySpreadsheetFacilityProducts",
  id?: string,
  facilityId?: string,
  facilityName?: string,
  customerFacilityId?: string,
  productId?: string,
  alternateId?: string,
  isOverThresholds?: boolean | null,
  isAlwaysReported?: boolean | null,
  maxAmount?: number | null,
  maxAmountCode?: number | null,
  averageAmount?: number | null,
  averageAmountCode?: number | null,
  maxAmountLargestContainer?: number | null,
  daysOnSite?: number | null,
  hasConfidentialStorageLocations?: boolean | null,
  unit?: string | null,
  locations?:  Array<TierIIStorageLocation | null > | null,
};

export type TierIIStorageLocation = {
  __typename: "TierIIStorageLocation",
  id?: string,
  description?: string | null,
  storageType?: string | null,
  pressure?: string | null,
  temperature?: string | null,
  amount?: number | null,
  unit?: string | null,
  mapNumber?: string | null,
  gridNumber?: string | null,
  insideOutside?: string | null,
  storageBuilding?: string | null,
  storageFloor?: string | null,
  storageArea?: string | null,
  storageRoom?: string | null,
  storageQuadrant?: string | null,
};

export type ValidationError = {
  __typename: "ValidationError",
  type?: string,
  message?: string,
  path?: string,
};

export type TriggerCustomerDataPipelineInput = {
  filenames: Array< string >,
};

export type TriggeredCustomerDataPipeline = {
  __typename: "TriggeredCustomerDataPipeline",
  triggered?: boolean,
  errors?: Array< string >,
};

export type GenerateTierIIFacilitiesBasicInformationSheetInput = {
  processId: string,
  facilityIds?: Array< string | null > | null,
};

export type BulkUpdateInput = {
  filename: string,
};

export type BulkUpdateTierIIBasicInformationValidationResults = {
  __typename: "BulkUpdateTierIIBasicInformationValidationResults",
  updatedRows?:  Array<MultiFacilitySpreadsheetBasicInformationResults > | null,
  validationErrors?:  Array<ValidationError >,
};

export type MultiFacilitySpreadsheetBasicInformationResults = {
  __typename: "MultiFacilitySpreadsheetBasicInformationResults",
  id?: string | null,
  companyName?: string | null,
  county?: string | null,
  dbNumber?: string | null,
  department?: string | null,
  name?: string | null,
  facilityPhone?: string | null,
  facilityPhone24Hour?: string | null,
  fireDistrict?: string | null,
  fireDistrictId?: string | null,
  isClosed?: boolean | null,
  isColocated?: boolean | null,
  isInactive?: boolean | null,
  colocationReportingFacilityId?: string | null,
  isMailingSameAsFacility?: boolean | null,
  isUnmanned?: boolean | null,
  latitude?: number | null,
  longitude?: number | null,
  lepc?: string | null,
  lepcId?: string | null,
  mailingAddress?: Address,
  maxNumOccupants?: number | null,
  municipality?: string | null,
  naicsCode?: number | null,
  reconciledAt?: string | null,
  streetAddress?: Address,
};

export type BulkUpdateTierIIBasicInformation = {
  __typename: "BulkUpdateTierIIBasicInformation",
  updatedRowCount?: number,
  errors?: Array< string >,
};

export type BulkUpdateTierIIContactsInput = {
  filename: string,
};

export type BulkUpdateTierIIContactsValidationResults = {
  __typename: "BulkUpdateTierIIContactsValidationResults",
  updatedRows?:  Array<MultiFacilitySpreadsheetContact >,
  validationErrors?:  Array<ValidationError | null >,
};

export type MultiFacilitySpreadsheetContact = {
  __typename: "MultiFacilitySpreadsheetContact",
  facilityId?: string,
  facilityName?: string,
  id?: string,
  first?: string,
  last?: string,
  title?: string | null,
  reconciledAt?: string | null,
  contactType?: string | null,
  email?: string | null,
  phone?: string | null,
  phone24Hour?: string | null,
  address?: Address,
  fax?: string | null,
  cell?: string | null,
  phones?:  Array<Phone > | null,
};

export type BulkUpdateTierIIContacts = {
  __typename: "BulkUpdateTierIIContacts",
  updatedRowCount?: number,
  errors?: Array< string >,
};

export type ValidatePersonsSheetInput = {
  filename: string,
};

export type ValidatedPerson = {
  __typename: "ValidatedPerson",
  person?: SpreadsheetPerson,
  validationErrors?:  Array<ValidationError | null >,
};

export type SpreadsheetPerson = {
  __typename: "SpreadsheetPerson",
  id?: string | null,
  owner?: string | null,
  first?: string | null,
  last?: string | null,
  email?: string | null,
  title?: string | null,
  company?: string | null,
  address?: Address,
  contactType?: ContactType | null,
  userTenantId?: string | null,
  cellPhone?: string | null,
  homePhone?: string | null,
  workPhone?: string | null,
  hrs24Phone?: string | null,
  emergencyPhone?: string | null,
  faxPhone?: string | null,
};

export type BulkUpdatePersonsInput = {
  filename: string,
};

export type BulkUpdateResult = {
  __typename: "BulkUpdateResult",
  updatedRowCount?: number,
  errors?: Array< string >,
};

export type ReadTier2SubmitContentsInput = {
  filename: string,
  tenantId?: string | null,
};

export type ReadTier2SubmitContents = {
  __typename: "ReadTier2SubmitContents",
  reportingYear?: string,
  facilities?:  Array<ReadTier2SubmitFacility > | null,
};

export type ReadTier2SubmitFacility = {
  __typename: "ReadTier2SubmitFacility",
  id?: string,
  name?: string,
  streetAddress?: Address,
  mailingAddress?: Address,
  owner?: string | null,
  contactsCount?: number,
  productsCount?: number,
};

export type ImportTier2SubmitContentsInput = {
  filename: string,
  facilityIds?: Array< string | null > | null,
  tenantId?: string | null,
  organizationStatus?: TierIIReportOrganizationStatus | null,
  reportingYear?: number | null,
};

export enum TierIIReportOrganizationStatus {
  NOT_STARTED = "NOT_STARTED",
  NOT_REPORTING = "NOT_REPORTING",
  ASSIGNED = "ASSIGNED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  VERIFIED = "VERIFIED",
}


export type ImportTier2SubmitContentsResults = {
  __typename: "ImportTier2SubmitContentsResults",
  results?:  Array<ImportTier2SubmitContentsResult > | null,
};

export type ImportTier2SubmitContentsResult = {
  __typename: "ImportTier2SubmitContentsResult",
  facilityName?: string,
  reportKind?: string,
};

export enum TierIIReportKind {
  ANNUAL = "ANNUAL",
  INITIAL = "INITIAL",
  REVISION = "REVISION",
  UPDATE = "UPDATE",
}


export type TierIIReportIcebergPatternReportInput = {
  organizationStatus?: TierIIReportOrganizationStatus | null,
  encampStatus?: TierIIReportEncampStatus | null,
  staffAssignee?: string | null,
  assignees?: Array< TierIIReportIcebergPatternTierIIPersonInput > | null,
  customerComments?: Array< TierIIReportIcebergPatternTierIICommentInput > | null,
  review?: TierIIReportIcebergPatternTierIIReviewInput | null,
  records?: TierIIReportIcebergPatternTierIIRecordsInput | null,
  metadata?: TierIIReportIcebergPatternTierIIMetadataInput | null,
};

export enum TierIIReportEncampStatus {
  AWAITING_VERIFICATION = "AWAITING_VERIFICATION",
  NOT_REPORTING = "NOT_REPORTING",
  PROCESSING = "PROCESSING",
  FILING_COMPLETE = "FILING_COMPLETE",
}


export type TierIIReportIcebergPatternTierIIPersonInput = {
  id: string,
  first: string,
  last: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
};

export type TierIIReportIcebergPatternTierIICommentInput = {
  at: string,
  comment: string,
  commentedBy: string,
};

export type TierIIReportIcebergPatternTierIIReviewInput = {
  reviewers?: Array< TierIIReportIcebergPatternTierIIReviewerInput > | null,
  notesToRegulators?: string | null,
  isNotReporting?: boolean | null,
};

export type TierIIReportIcebergPatternTierIIReviewerInput = {
  id: string,
  first: string,
  last: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
  isComplete?: boolean | null,
};

export type TierIIReportIcebergPatternTierIIRecordsInput = {
  facilityRecords?: Array< TierIIReportIcebergPatternTierIIRecordInput > | null,
};

export type TierIIReportIcebergPatternTierIIRecordInput = {
  at: string,
  key: string,
  kind: TierIIRecordKind,
  name: string,
  associatedDocumentId?: string | null,
};

export enum TierIIRecordKind {
  AST_DOCUMENTATION = "AST_DOCUMENTATION",
  DIKE_DESCRIPTION = "DIKE_DESCRIPTION",
  EMERGENCY_RESPONSE_PLAN = "EMERGENCY_RESPONSE_PLAN",
  HAZARDOUS_MATERIALS_INVENTORY_STATEMENT = "HAZARDOUS_MATERIALS_INVENTORY_STATEMENT",
  HAZARDOUS_MATERIALS_MANAGEMENT_PLAN = "HAZARDOUS_MATERIALS_MANAGEMENT_PLAN",
  HAZARDOUS_MATERIALS_CONTINGENCY_PLAN_OR_QUICK_GUIDE = "HAZARDOUS_MATERIALS_CONTINGENCY_PLAN_OR_QUICK_GUIDE",
  INVOICE = "INVOICE",
  OTHER = "OTHER",
  RECEIPT = "RECEIPT",
  REFERENCE_IMAGE = "REFERENCE_IMAGE",
  REPORT = "REPORT",
  SAFEGUARD_MEASURES = "SAFEGUARD_MEASURES",
  SCREENSHOT = "SCREENSHOT",
  SDS = "SDS",
  SITE_COORDINATE_ABBREVIATION = "SITE_COORDINATE_ABBREVIATION",
  SITE_PLAN = "SITE_PLAN",
  TRAINING_PLAN = "TRAINING_PLAN",
  UST_DOCUMENTATION = "UST_DOCUMENTATION",
}


export type TierIIReportIcebergPatternTierIIMetadataInput = {
  comments?: Array< TierIIReportIcebergPatternTierIICommentInput > | null,
  submissionMeasures?: Array< TierIIReportIcebergPatternTierIISubmissionMeasureInput > | null,
  submissionRecords?: Array< TierIIReportIcebergPatternTierIIRecordInput > | null,
  tags?: string | null,
  untouchedSteps?: Array< string > | null,
};

export type TierIIReportIcebergPatternTierIISubmissionMeasureInput = {
  name: string,
  description?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  records?: Array< TierIIReportIcebergPatternTierIIRecordInput > | null,
  notes?: string | null,
};

export type TierIIReport = {
  __typename: "TierIIReport",
  id?: string,
  owner?: string | null,
  facilityId?: string,
  reportingYear?: number,
  reportKind?: TierIIReportKind,
  organizationStatus?: TierIIReportOrganizationStatus,
  encampStatus?: TierIIReportEncampStatus,
  staffAssignee?: string | null,
  metadata?: TierIIMetadata,
  step3Prime?: string | null,
  customerComments?:  Array<TierIIComment > | null,
  assignees?:  Array<TierIIPerson > | null,
  basic?: TierIIBasicInformation,
  regulatory?: TierIIRegulatory,
  facilityProductIds?: Array< string > | null,
  notReportingFacilityProductIds?: Array< string > | null,
  products?:  Array<TierIIFacilityProduct > | null,
  notReportingProducts?:  Array<TierIIFacilityProduct > | null,
  facilityContactIds?: Array< string > | null,
  contacts?:  Array<FacilityContact > | null,
  records?: TierIIRecords,
  state?: TierIIStateInformation,
  review?: TierIIReview,
  verify?: TierIIVerify,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  computed?: ModelComputedConnection,
};

export type TierIIMetadata = {
  __typename: "TierIIMetadata",
  comments?:  Array<TierIIComment > | null,
  submissionMeasures?:  Array<TierIISubmissionMeasure > | null,
  submissionRecords?:  Array<TierIIRecord > | null,
  tags?: string | null,
  untouchedSteps?: Array< string > | null,
};

export type TierIISubmissionMeasure = {
  __typename: "TierIISubmissionMeasure",
  name?: string,
  description?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  records?:  Array<TierIIRecord > | null,
  notes?: string | null,
};

export type TierIIRecord = {
  __typename: "TierIIRecord",
  at?: string,
  key?: string,
  kind?: TierIIRecordKind,
  name?: string,
  associatedDocumentId?: string | null,
};

export type TierIIPerson = {
  __typename: "TierIIPerson",
  id?: string,
  first?: string,
  last?: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
};

export type AbstractTierIIPerson = {
  __typename: "AbstractTierIIPerson",
  id?: string,
  first?: string,
  last?: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
};

export type TierIIReviewer = {
  __typename: "TierIIReviewer",
  id?: string,
  first?: string,
  last?: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
  isComplete?: boolean | null,
};

export type TierIIVerifier = {
  __typename: "TierIIVerifier",
  id?: string,
  first?: string,
  last?: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
  fullName?: string | null,
};

export type TierIIFacilityContact = {
  __typename: "TierIIFacilityContact",
  id?: string,
  first?: string,
  last?: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
  contactType?: string | null,
  email?: string | null,
  phone?: string | null,
  phone24Hour?: string | null,
  address?: Address,
  fax?: string | null,
  cell?: string | null,
  phones?:  Array<Phone > | null,
};

export type TierIIBasicInformation = {
  __typename: "TierIIBasicInformation",
  companyName?: string | null,
  county?: string | null,
  dateClosed?: string | null,
  dbNumber?: string | null,
  department?: string | null,
  facilityName?: string | null,
  facilityPhone?: string | null,
  facilityPhone24Hour?: string | null,
  fireDistrict?: string | null,
  fireDistrictId?: string | null,
  isClosed?: boolean | null,
  isColocated?: boolean | null,
  isInactive?: boolean | null,
  colocationReportingFacilityId?: string | null,
  isMailingSameAsFacility?: boolean | null,
  isUnmanned?: boolean | null,
  latitude?: number | null,
  longitude?: number | null,
  lepc?: string | null,
  lepcId?: string | null,
  mailingAddress?: Address,
  maxNumOccupants?: number | null,
  municipality?: string | null,
  naicsCode?: number | null,
  reconciledAt?: string | null,
  streetAddress?: Address,
};

export type TierIIRegulatory = {
  __typename: "TierIIRegulatory",
  isSubjectToChemicalAccidentPrevention?: boolean | null,
  isSubjectToEmergencyPlanning?: boolean | null,
  isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
  reconciledAt?: string | null,
  rmpId?: string | null,
  triId?: string | null,
};

export type TierIIFacilityProduct = {
  __typename: "TierIIFacilityProduct",
  id?: string,
  facilityId?: string,
  owner?: string | null,
  productId?: string,
  product?: TierIIProduct,
  isOverThresholds?: boolean | null,
  isAlwaysReported?: boolean | null,
  maxAmount?: number | null,
  maxAmountCode?: number | null,
  averageAmount?: number | null,
  averageAmountCode?: number | null,
  maxAmountLargestContainer?: number | null,
  daysOnSite?: number | null,
  hasConfidentialStorageLocations?: boolean | null,
  unit?: string | null,
  locations?:  Array<TierIIStorageLocation | null > | null,
  stateFields?: string | null,
  aggregatedProductIds?: Array< string > | null,
  createdAt?: string | null,
  updatedAt?: string,
  updatedBy?: string | null,
  facility?: Facility,
};

export type TierIIProduct = {
  __typename: "TierIIProduct",
  id?: string,
  owner?: string | null,
  chemName?: string,
  casNumber?: string | null,
  noCasNumber?: boolean | null,
  alternateId?: string | null,
  ehs?: boolean | null,
  pure?: boolean | null,
  mixture?: boolean | null,
  solid?: boolean | null,
  liquid?: boolean | null,
  gas?: boolean | null,
  isTradeSecret?: boolean | null,
  density?: number | null,
  densityUnits?: string | null,
  boilingPointFahr?: number | null,
  flashPointFahr?: number | null,
  vaporPressureMmhg?: number | null,
  solubility?: string | null,
  isBattery?: boolean | null,
  isFuel?: boolean | null,
  physicalHazards?: Array< string | null > | null,
  healthHazards?: Array< string | null > | null,
  oregonHazards?: Array< string | null > | null,
  ifcHazards?: Array< string | null > | null,
  noHazardsNotReporting?: boolean | null,
  components?:  Array<TierIIMixtureComponent | null > | null,
  sdsRecords?:  Array<TierIIRecord > | null,
  stateFields?: string | null,
  ehsForm?: EhsForm | null,
  createdAt?: string | null,
  updatedAt?: string,
  updatedBy?: string | null,
  parentProductGroups?: ModelTierIIProductProductGroupConnection,
};

export type TierIIMixtureComponent = {
  __typename: "TierIIMixtureComponent",
  componentName?: string | null,
  casNumber?: string | null,
  noCasNumber?: boolean | null,
  ehs?: boolean | null,
  componentPercentage?: number | null,
  weightOrVolume?: string | null,
  ehsForm?: EhsForm | null,
};

export enum EhsForm {
  MOLTEN = "MOLTEN",
  SOLUTION = "SOLUTION",
  SOLID_PARTICLE_SIZE_LESS_THAN_100_MICRON = "SOLID_PARTICLE_SIZE_LESS_THAN_100_MICRON",
  NONE = "NONE",
}


export type ModelTierIIProductProductGroupConnection = {
  __typename: "ModelTierIIProductProductGroupConnection",
  items?:  Array<TierIIProductProductGroup | null > | null,
  nextToken?: string | null,
};

export type TierIIProductProductGroup = {
  __typename: "TierIIProductProductGroup",
  id?: string,
  owner?: string | null,
  productGroupId?: string,
  productId?: string,
  amount?: number | null,
  units?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  product?: TierIIProduct,
  productGroup?: TierIIProductGroup,
};

export type TierIIProductGroup = {
  __typename: "TierIIProductGroup",
  id?: string,
  owner?: string | null,
  name?: string,
  groupType?: TierIIProductGroupGroupType | null,
  description?: string,
  measurementUnits?: string,
  records?:  Array<TierIIRecord > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  childProducts?: ModelTierIIProductProductGroupConnection,
  childProductGroups?: ModelTierIIProductGroupProductGroupConnection,
  parentProductGroups?: ModelTierIIProductGroupProductGroupConnection,
};

export enum TierIIProductGroupGroupType {
  EQUIPMENT = "EQUIPMENT",
  STORAGE_METHOD = "STORAGE_METHOD",
  BATTERY = "BATTERY",
}


export type ModelTierIIProductGroupProductGroupConnection = {
  __typename: "ModelTierIIProductGroupProductGroupConnection",
  items?:  Array<TierIIProductGroupProductGroup | null > | null,
  nextToken?: string | null,
};

export type TierIIProductGroupProductGroup = {
  __typename: "TierIIProductGroupProductGroup",
  id?: string,
  owner?: string | null,
  parentProductGroupId?: string,
  childProductGroupId?: string,
  amount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  parentProductGroup?: TierIIProductGroup,
  childProductGroup?: TierIIProductGroup,
};

export type TierIIRecords = {
  __typename: "TierIIRecords",
  facilityRecords?:  Array<TierIIRecord > | null,
};

export type TierIIStateInformation = {
  __typename: "TierIIStateInformation",
  fields?: string | null,
};

export type TierIIReview = {
  __typename: "TierIIReview",
  reviewers?:  Array<TierIIReviewer > | null,
  notesToRegulators?: string | null,
  isNotReporting?: boolean | null,
};

export type TierIIVerify = {
  __typename: "TierIIVerify",
  verifier?: TierIIVerifier,
  verifiedAt?: string | null,
  isVerified?: boolean | null,
};

export type ModelComputedConnection = {
  __typename: "ModelComputedConnection",
  items?:  Array<Computed | null > | null,
  nextToken?: string | null,
};

export type Computed = {
  __typename: "Computed",
  id?: string,
  typeId?: string,
  owner?: string,
  kind?: ComputationKind,
  typeName?: string | null,
  computed?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum ComputationKind {
  EPCRA_DASHBOARD = "EPCRA_DASHBOARD",
  RCRA_DASHBOARD = "RCRA_DASHBOARD",
  TIERII_COUNTS = "TIERII_COUNTS",
}


export type MergeContactsInput = {
  processId: string,
  winningPersonId: string,
  losingPeopleIds: Array< string >,
};

export type PublishMergeContactsInput = {
  processId: string,
  errorMsg?: string | null,
};

export type PublishedMergeContactsResponse = {
  __typename: "PublishedMergeContactsResponse",
  processId?: string,
  errorMsg?: string | null,
};

export type MergeProductsInput = {
  winningProductId: string,
  losingProductIds: Array< string >,
};

export type AggregateFacilityProductsInput = {
  facilityProducts?: Array< FacilityProductListKind > | null,
};

export type FacilityProductListKind = {
  facilityId: string,
  productId: string,
};

export type AggregateFacilityInput = {
  facilityId: string,
};

export type AggregateTenantInput = {
  tenantId: string,
};

export type SdsExtractionInput = {
  action: string,
  tenantId: string,
  s3FileKeys?: Array< string | null > | null,
};

export type CreateMetatronFacilitiyInput = {
  metatronFacilities?: Array< MetatronFacilityInput | null > | null,
  owner?: string | null,
  allowNew?: CreateMetatronFacilitiyAllowNewInput | null,
  earliestSiteIdentificationYear?: number | null,
  earliestBiennialReportingYear?: number | null,
  latestBiennialReportingYear?: number | null,
};

export type MetatronFacilityInput = {
  id?: string | null,
  frsId: string,
  facName?: string | null,
  facStreet?: string | null,
  facCity?: string | null,
  facState?: string | null,
  facZip?: string | null,
  facCounty?: string | null,
  facLat?: string | null,
  facLong?: string | null,
  rcraFlag?: string | null,
  rcraIds?: string | null,
  rcraPermitTypes?: string | null,
  rcraNaics?: string | null,
  airFlag?: string | null,
  airIds?: string | null,
  caaPermitTypes?: string | null,
  caaNaics?: string | null,
  npdesFlag?: string | null,
  npdesIds?: string | null,
  triFlag?: string | null,
  triIds?: string | null,
  ghgFlag?: string | null,
  ghgIds?: string | null,
  organizations?: Array< MetatronFacilityOrganizationInput | null > | null,
  contacts?: Array< MetatronFacilityContactInput | null > | null,
  combinedText?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type MetatronFacilityOrganizationInput = {
  name?: string | null,
  pgmSysAcrnm?: string | null,
  pgmSysId?: string | null,
  email?: string | null,
  affiliationType?: string | null,
};

export type MetatronFacilityContactInput = {
  pgmSysAcrnm?: string | null,
  pgmSysId?: string | null,
  email?: string | null,
  affiliationType?: string | null,
};

export type CreateMetatronFacilitiyAllowNewInput = {
  facilities?: boolean | null,
  siteIdentificationReports?: boolean | null,
};

export type PublishCreateMetatronFacility = {
  processId: string,
  isError?: boolean | null,
};

export type PublishedCreateMetatronFacility = {
  __typename: "PublishedCreateMetatronFacility",
  processId?: string,
  isError?: boolean | null,
};

export type TierIIReportNotReportingReasonInput = {
  notReportingReason?: TierIINotReportingReason | null,
  dateClosed?: string | null,
  dateInactive?: string | null,
  colocationReportingFacilityId?: string | null,
};

export enum TierIINotReportingReason {
  CLOSED = "CLOSED",
  INACTIVE = "INACTIVE",
  NOT_REPORTING = "NOT_REPORTING",
  NOT_REPORTING_THROUGH_ENCAMP = "NOT_REPORTING_THROUGH_ENCAMP",
  COLOCATED = "COLOCATED",
}


export type TierIINotReportingReasonResult = {
  __typename: "TierIINotReportingReasonResult",
  id?: string,
  success?: boolean,
  errorMsg?: string | null,
};

export type ProcessOneSchemaImportInput = {
  processId: string,
  sheet_id: number,
  embed_id: number,
};

export type PublishOneSchemaImportInput = {
  processId: string,
  errorMsg?: string | null,
};

export type PublishedOneSchemaImportResponse = {
  __typename: "PublishedOneSchemaImportResponse",
  processId?: string,
  errorMsg?: string | null,
};

export type MutateWasteDataInput = {
  event: string,
  id?: string | null,
  query?: string | null,
  wastestream?: string | null,
  wasteGenerationEvent?: string | null,
  wasteManifest?: string | null,
  wasteManifestLineItem?: string | null,
  wasteContainer?: string | null,
  wasteContainerGroups?: string | null,
};

export type MutateWasteDataOutput = {
  __typename: "MutateWasteDataOutput",
  success?: boolean,
  data?: string | null,
  count?: number,
};

export type CreateNoteInput = {
  id?: string | null,
  parentId?: string | null,
  noteBy?: string | null,
  at: string,
  key?: string | null,
  note?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelNoteConditionInput = {
  parentId?: ModelIDInput | null,
  noteBy?: ModelStringInput | null,
  at?: ModelStringInput | null,
  key?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNoteConditionInput | null > | null,
  or?: Array< ModelNoteConditionInput | null > | null,
  not?: ModelNoteConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Note = {
  __typename: "Note",
  id?: string,
  parentId?: string | null,
  parent?: Note,
  noteBy?: string | null,
  at?: string,
  key?: string | null,
  note?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type UpdateNoteInput = {
  id: string,
  parentId?: string | null,
  noteBy?: string | null,
  at?: string | null,
  key?: string | null,
  note?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteNoteInput = {
  id: string,
};

export type CreateEventDocumentInput = {
  id?: string | null,
  owner?: string | null,
  documentId: string,
  eventId: string,
  seriesId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelEventDocumentConditionInput = {
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventDocumentConditionInput | null > | null,
  or?: Array< ModelEventDocumentConditionInput | null > | null,
  not?: ModelEventDocumentConditionInput | null,
};

export type UpdateEventDocumentInput = {
  id: string,
  owner?: string | null,
  documentId?: string | null,
  eventId?: string | null,
  seriesId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteEventDocumentInput = {
  id: string,
};

export type CreateEventWatcherInput = {
  id?: string | null,
  owner?: string | null,
  personId: string,
  eventId: string,
  deadline: string,
  seriesId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelEventWatcherConditionInput = {
  owner?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventWatcherConditionInput | null > | null,
  or?: Array< ModelEventWatcherConditionInput | null > | null,
  not?: ModelEventWatcherConditionInput | null,
};

export type UpdateEventWatcherInput = {
  id: string,
  owner?: string | null,
  personId?: string | null,
  eventId?: string | null,
  deadline: string,
  seriesId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteEventWatcherInput = {
  id: string,
  deadline: string,
};

export type CreateChargeInput = {
  id?: string | null,
  owner: string,
  facilityId?: string | null,
  module: ChargeModule,
  reportingYear: number,
  date: string,
  amount?: number | null,
  type: ChargeType,
  memo?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum ChargeModule {
  EPCRA = "EPCRA",
  RCRA = "RCRA",
  General = "General",
}


export enum ChargeType {
  IMPLEMENTATION = "IMPLEMENTATION",
  ON_DEMAND = "ON_DEMAND",
  SERVICE = "SERVICE",
  SUBSCRIPTION = "SUBSCRIPTION",
}


export type ModelChargeConditionInput = {
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  module?: ModelChargeModuleInput | null,
  reportingYear?: ModelIntInput | null,
  date?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  type?: ModelChargeTypeInput | null,
  memo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelChargeConditionInput | null > | null,
  or?: Array< ModelChargeConditionInput | null > | null,
  not?: ModelChargeConditionInput | null,
};

export type ModelChargeModuleInput = {
  eq?: ChargeModule | null,
  ne?: ChargeModule | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelChargeTypeInput = {
  eq?: ChargeType | null,
  ne?: ChargeType | null,
};

export type Charge = {
  __typename: "Charge",
  id?: string,
  owner?: string,
  facilityId?: string | null,
  module?: ChargeModule,
  reportingYear?: number,
  date?: string,
  amount?: number | null,
  type?: ChargeType,
  memo?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type UpdateChargeInput = {
  id: string,
  owner?: string | null,
  facilityId?: string | null,
  module?: ChargeModule | null,
  reportingYear?: number | null,
  date?: string | null,
  amount?: number | null,
  type?: ChargeType | null,
  memo?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteChargeInput = {
  id: string,
};

export type CreateCredentialInput = {
  id?: string | null,
  owner?: string | null,
  name: string,
  username: string,
  password: string,
  kind: CredentialKind,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  securityQuestions?: Array< SecurityQuestionInput > | null,
  verificationStatus?: CredentialVerificationStatus | null,
  lastVerificationSuccessAt?: string | null,
  lastVerificationCheckAt?: string | null,
  recentUsage?: Array< CredentialUsageLogEntryInput > | null,
};

export type SecurityQuestionInput = {
  question: string,
  answer: string,
};

export type CredentialUsageLogEntryInput = {
  timestamp: string,
  verificationStatus: CredentialVerificationStatus,
  errorMessage?: string | null,
};

export type ModelCredentialConditionInput = {
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  password?: ModelStringInput | null,
  kind?: ModelCredentialKindInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  verificationStatus?: ModelCredentialVerificationStatusInput | null,
  lastVerificationSuccessAt?: ModelStringInput | null,
  lastVerificationCheckAt?: ModelStringInput | null,
  and?: Array< ModelCredentialConditionInput | null > | null,
  or?: Array< ModelCredentialConditionInput | null > | null,
  not?: ModelCredentialConditionInput | null,
};

export type ModelCredentialKindInput = {
  eq?: CredentialKind | null,
  ne?: CredentialKind | null,
};

export type ModelCredentialVerificationStatusInput = {
  eq?: CredentialVerificationStatus | null,
  ne?: CredentialVerificationStatus | null,
};

export type UpdateCredentialInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  username?: string | null,
  password?: string | null,
  kind?: CredentialKind | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  securityQuestions?: Array< SecurityQuestionInput > | null,
  verificationStatus?: CredentialVerificationStatus | null,
  lastVerificationSuccessAt?: string | null,
  lastVerificationCheckAt?: string | null,
  recentUsage?: Array< CredentialUsageLogEntryInput > | null,
};

export type DeleteCredentialInput = {
  id: string,
};

export type CreateCredentialPoolInput = {
  id?: string | null,
  owner?: string | null,
  description?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelCredentialPoolConditionInput = {
  owner?: ModelIDInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCredentialPoolConditionInput | null > | null,
  or?: Array< ModelCredentialPoolConditionInput | null > | null,
  not?: ModelCredentialPoolConditionInput | null,
};

export type UpdateCredentialPoolInput = {
  id: string,
  owner?: string | null,
  description?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteCredentialPoolInput = {
  id: string,
};

export type CreateCredentialPoolCredentialInput = {
  id?: string | null,
  owner: string,
  credentialId: string,
  credentialPoolId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelCredentialPoolCredentialConditionInput = {
  owner?: ModelIDInput | null,
  credentialId?: ModelIDInput | null,
  credentialPoolId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCredentialPoolCredentialConditionInput | null > | null,
  or?: Array< ModelCredentialPoolCredentialConditionInput | null > | null,
  not?: ModelCredentialPoolCredentialConditionInput | null,
};

export type UpdateCredentialPoolCredentialInput = {
  id: string,
  owner?: string | null,
  credentialId?: string | null,
  credentialPoolId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type CreateDataCollectionTaskInput = {
  id?: string | null,
  owner: string,
  dataCollectionTaskTemplateId: string,
  userTenantId: string,
  createdByUserTenantId: string,
  facilityId: string,
  title: string,
  savedState?: DataCollectionTaskSavedStateInput | null,
  status: DataCollectionTaskStatusEnum,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DataCollectionTaskSavedStateInput = {
  productGroups?: Array< DataCollectionTaskProductGroupStateInput | null > | null,
};

export type DataCollectionTaskProductGroupStateInput = {
  id: string,
  inputValue: number,
};

export enum DataCollectionTaskStatusEnum {
  PENDNING_ASSIGNMENT = "PENDNING_ASSIGNMENT",
  ASSIGNED = "ASSIGNED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}


export type ModelDataCollectionTaskConditionInput = {
  owner?: ModelIDInput | null,
  dataCollectionTaskTemplateId?: ModelIDInput | null,
  userTenantId?: ModelIDInput | null,
  createdByUserTenantId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  status?: ModelDataCollectionTaskStatusEnumInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDataCollectionTaskConditionInput | null > | null,
  or?: Array< ModelDataCollectionTaskConditionInput | null > | null,
  not?: ModelDataCollectionTaskConditionInput | null,
};

export type ModelDataCollectionTaskStatusEnumInput = {
  eq?: DataCollectionTaskStatusEnum | null,
  ne?: DataCollectionTaskStatusEnum | null,
};

export type DataCollectionTask = {
  __typename: "DataCollectionTask",
  id?: string,
  owner?: string,
  dataCollectionTaskTemplateId?: string,
  userTenantId?: string,
  createdByUserTenantId?: string,
  facilityId?: string,
  title?: string,
  savedState?: DataCollectionTaskSavedState,
  status?: DataCollectionTaskStatusEnum,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  template?: DataCollectionTaskTemplate,
  facility?: Facility,
  userTenant?: UserTenant,
  createdByUserTenant?: UserTenant,
};

export type DataCollectionTaskSavedState = {
  __typename: "DataCollectionTaskSavedState",
  productGroups?:  Array<DataCollectionTaskProductGroupState | null > | null,
};

export type DataCollectionTaskProductGroupState = {
  __typename: "DataCollectionTaskProductGroupState",
  id?: string,
  inputValue?: number,
};

export type DataCollectionTaskTemplate = {
  __typename: "DataCollectionTaskTemplate",
  id?: string,
  owner?: string,
  title?: string,
  description?: string | null,
  productGroupIds?: Array< string >,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type UpdateDataCollectionTaskInput = {
  id: string,
  owner?: string | null,
  dataCollectionTaskTemplateId?: string | null,
  userTenantId?: string | null,
  createdByUserTenantId?: string | null,
  facilityId?: string | null,
  title?: string | null,
  savedState?: DataCollectionTaskSavedStateInput | null,
  status?: DataCollectionTaskStatusEnum | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteDataCollectionTaskInput = {
  id: string,
};

export type CreateDataCollectionTaskTemplateInput = {
  id?: string | null,
  owner: string,
  title: string,
  description?: string | null,
  productGroupIds: Array< string >,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelDataCollectionTaskTemplateConditionInput = {
  owner?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  productGroupIds?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDataCollectionTaskTemplateConditionInput | null > | null,
  or?: Array< ModelDataCollectionTaskTemplateConditionInput | null > | null,
  not?: ModelDataCollectionTaskTemplateConditionInput | null,
};

export type UpdateDataCollectionTaskTemplateInput = {
  id: string,
  owner?: string | null,
  title?: string | null,
  description?: string | null,
  productGroupIds?: Array< string > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteDataCollectionTaskTemplateInput = {
  id: string,
};

export type CreateDocumentRequirementInput = {
  id?: string | null,
  owner?: string | null,
  documentId: string,
  requirementId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelDocumentRequirementConditionInput = {
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentRequirementConditionInput | null > | null,
  or?: Array< ModelDocumentRequirementConditionInput | null > | null,
  not?: ModelDocumentRequirementConditionInput | null,
};

export type UpdateDocumentRequirementInput = {
  id: string,
  owner?: string | null,
  documentId?: string | null,
  requirementId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteDocumentRequirementInput = {
  id: string,
};

export type CreateDocumentInput = {
  id?: string | null,
  owner?: string | null,
  title: string,
  description?: string | null,
  fileExtension: string,
  state?: string | null,
  area?: string | null,
  programAreas?: Array< string | null > | null,
  documentType: string,
  sourceLink?: string | null,
  storageLink: string,
  agencyId?: string | null,
  facilityId?: string | null,
  lobId?: string | null,
  createdBy?: string | null,
  stateSearchable?: string | null,
  areaSearchable?: string | null,
  searchableProgramAreas?: string | null,
  relatedEntityIds?: Array< DocumentRelatedEntityIdInput > | null,
  tags?: string | null,
  isArchived?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DocumentRelatedEntityIdInput = {
  kind: DocumentRelatedEntityIdKind,
  id: string,
};

export type ModelDocumentConditionInput = {
  owner?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  fileExtension?: ModelStringInput | null,
  state?: ModelStringInput | null,
  area?: ModelIDInput | null,
  programAreas?: ModelIDInput | null,
  documentType?: ModelStringInput | null,
  sourceLink?: ModelStringInput | null,
  storageLink?: ModelStringInput | null,
  agencyId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  lobId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  stateSearchable?: ModelStringInput | null,
  areaSearchable?: ModelStringInput | null,
  searchableProgramAreas?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateDocumentInput = {
  id: string,
  owner?: string | null,
  title?: string | null,
  description?: string | null,
  fileExtension?: string | null,
  state?: string | null,
  area?: string | null,
  programAreas?: Array< string | null > | null,
  documentType?: string | null,
  sourceLink?: string | null,
  storageLink?: string | null,
  agencyId?: string | null,
  facilityId?: string | null,
  lobId?: string | null,
  createdBy?: string | null,
  stateSearchable?: string | null,
  areaSearchable?: string | null,
  searchableProgramAreas?: string | null,
  relatedEntityIds?: Array< DocumentRelatedEntityIdInput > | null,
  tags?: string | null,
  isArchived?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteDocumentInput = {
  id: string,
};

export type CreateEpcra311TriggerInput = {
  id?: string | null,
  owner?: string | null,
  description?: string | null,
  triggeredAt: string,
  deadlineAt: string,
  isReported: boolean,
  isRequestingInformation?: boolean | null,
  facilityId: string,
  productId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelEpcra311TriggerConditionInput = {
  owner?: ModelIDInput | null,
  description?: ModelStringInput | null,
  triggeredAt?: ModelStringInput | null,
  deadlineAt?: ModelStringInput | null,
  isReported?: ModelBooleanInput | null,
  isRequestingInformation?: ModelBooleanInput | null,
  facilityId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEpcra311TriggerConditionInput | null > | null,
  or?: Array< ModelEpcra311TriggerConditionInput | null > | null,
  not?: ModelEpcra311TriggerConditionInput | null,
};

export type Epcra311Trigger = {
  __typename: "Epcra311Trigger",
  id?: string,
  owner?: string | null,
  description?: string | null,
  triggeredAt?: string,
  deadlineAt?: string,
  isReported?: boolean,
  isRequestingInformation?: boolean | null,
  facilityId?: string,
  productId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facility?: Facility,
  product?: TierIIProduct,
};

export type UpdateEpcra311TriggerInput = {
  id: string,
  owner?: string | null,
  description?: string | null,
  triggeredAt?: string | null,
  deadlineAt?: string | null,
  isReported?: boolean | null,
  isRequestingInformation?: boolean | null,
  facilityId?: string | null,
  productId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteEpcra311TriggerInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  seriesId: string,
  requirementId: string,
  requirement: NestedRequirementInput,
  _requirementName: string,
  _requirementArea: string,
  facilityId: string,
  eventResponsiblePersonId?: string | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
  completedAt?: string | null,
  status: EventStatus,
  statusSearchable: string,
  owner?: string | null,
  deadline: string,
  description?: string | null,
  citations?: Array< LinkInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelEventConditionInput = {
  seriesId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  _requirementName?: ModelStringInput | null,
  _requirementArea?: ModelStringInput | null,
  facilityId?: ModelIDInput | null,
  eventResponsiblePersonId?: ModelIDInput | null,
  completedAt?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  statusSearchable?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  deadline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelEventStatusInput = {
  eq?: EventStatus | null,
  ne?: EventStatus | null,
};

export type UpdateEventInput = {
  id: string,
  seriesId?: string | null,
  requirementId?: string | null,
  requirement?: NestedRequirementInput | null,
  _requirementName?: string | null,
  _requirementArea?: string | null,
  facilityId?: string | null,
  eventResponsiblePersonId?: string | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
  completedAt?: string | null,
  status?: EventStatus | null,
  statusSearchable?: string | null,
  owner?: string | null,
  deadline?: string | null,
  description?: string | null,
  citations?: Array< LinkInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateEventSequenceInput = {
  id?: string | null,
  parentId?: string | null,
  owner: string,
  seriesId: string,
  taskId?: string | null,
  type: EventSequenceType,
  typeSearchable: string,
  startDate: string,
  endDate: string,
  isRecurring?: boolean | null,
  isOrganizationLevel?: boolean | null,
  exceptions?: Array< string > | null,
  requirement: NestedRequirementInput,
  _requirementName: string,
  _requirementArea: string,
  facilityId?: string | null,
  state?: string | null,
  responsiblePersonId?: string | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
  completedAt?: string | null,
  status: EventSequenceStatus,
  statusSearchable: string,
  description?: string | null,
  citations?: Array< LinkInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelEventSequenceConditionInput = {
  parentId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  type?: ModelEventSequenceTypeInput | null,
  typeSearchable?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  isRecurring?: ModelBooleanInput | null,
  isOrganizationLevel?: ModelBooleanInput | null,
  exceptions?: ModelStringInput | null,
  _requirementName?: ModelStringInput | null,
  _requirementArea?: ModelStringInput | null,
  facilityId?: ModelIDInput | null,
  state?: ModelStringInput | null,
  responsiblePersonId?: ModelIDInput | null,
  completedAt?: ModelStringInput | null,
  status?: ModelEventSequenceStatusInput | null,
  statusSearchable?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventSequenceConditionInput | null > | null,
  or?: Array< ModelEventSequenceConditionInput | null > | null,
  not?: ModelEventSequenceConditionInput | null,
};

export type ModelEventSequenceTypeInput = {
  eq?: EventSequenceType | null,
  ne?: EventSequenceType | null,
};

export type ModelEventSequenceStatusInput = {
  eq?: EventSequenceStatus | null,
  ne?: EventSequenceStatus | null,
};

export type EventSequence = {
  __typename: "EventSequence",
  id?: string,
  parentId?: string | null,
  owner?: string,
  seriesId?: string,
  taskId?: string | null,
  type?: EventSequenceType,
  typeSearchable?: string,
  startDate?: string,
  endDate?: string,
  isRecurring?: boolean | null,
  isOrganizationLevel?: boolean | null,
  exceptions?: Array< string > | null,
  requirement?: NestedRequirement,
  _requirementName?: string,
  _requirementArea?: string,
  facilityId?: string | null,
  state?: string | null,
  responsiblePersonId?: string | null,
  notificationLeadTimes?:  Array<Timespan > | null,
  completedAt?: string | null,
  status?: EventSequenceStatus,
  statusSearchable?: string,
  description?: string | null,
  citations?:  Array<Link | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  documents?: ModelEventSequenceDocumentConnection,
  watchers?: ModelEventSequenceWatcherConnection,
  facility?: Facility,
  responsiblePerson?: Person,
  task?: ManagedTask,
};

export type ModelEventSequenceDocumentConnection = {
  __typename: "ModelEventSequenceDocumentConnection",
  items?:  Array<EventSequenceDocument | null > | null,
  nextToken?: string | null,
};

export type EventSequenceDocument = {
  __typename: "EventSequenceDocument",
  id?: string,
  owner?: string | null,
  documentId?: string,
  eventSequenceId?: string,
  seriesId?: string,
  taskId?: string | null,
  facilityId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  document?: Document,
  eventSequence?: EventSequence,
};

export type ModelEventSequenceWatcherConnection = {
  __typename: "ModelEventSequenceWatcherConnection",
  items?:  Array<EventSequenceWatcher | null > | null,
  nextToken?: string | null,
};

export type EventSequenceWatcher = {
  __typename: "EventSequenceWatcher",
  id?: string,
  owner?: string | null,
  personId?: string,
  eventSequenceId?: string,
  taskId?: string | null,
  seriesId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  eventSequence?: EventSequence,
  person?: Person,
};

export type ManagedTask = {
  __typename: "ManagedTask",
  id?: string,
  owner?: string | null,
  watcherId?: string | null,
  startDate?: string,
  endDate?: string,
  programArea?: string | null,
  requirement?: NestedRequirement,
  _requirementName?: string,
  isOrganizationLevel?: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  sequences?: ModelEventSequenceConnection,
  watcher?: Person,
  facilities?: ModelFacilityTaskConnection,
};

export type ModelEventSequenceConnection = {
  __typename: "ModelEventSequenceConnection",
  items?:  Array<EventSequence | null > | null,
  nextToken?: string | null,
};

export type ModelFacilityTaskConnection = {
  __typename: "ModelFacilityTaskConnection",
  items?:  Array<FacilityTask | null > | null,
  nextToken?: string | null,
};

export type FacilityTask = {
  __typename: "FacilityTask",
  id?: string,
  owner?: string | null,
  taskId?: string,
  facilityId?: string | null,
  assigneeId?: string | null,
  watcherId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  sequences?: ModelEventSequenceConnection,
  facility?: Facility,
  watcher?: Person,
  assignee?: Person,
  task?: ManagedTask,
};

export type UpdateEventSequenceInput = {
  id: string,
  parentId?: string | null,
  owner?: string | null,
  seriesId?: string | null,
  taskId?: string | null,
  type?: EventSequenceType | null,
  typeSearchable?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  isRecurring?: boolean | null,
  isOrganizationLevel?: boolean | null,
  exceptions?: Array< string > | null,
  requirement?: NestedRequirementInput | null,
  _requirementName?: string | null,
  _requirementArea?: string | null,
  facilityId?: string | null,
  state?: string | null,
  responsiblePersonId?: string | null,
  notificationLeadTimes?: Array< TimespanInput > | null,
  completedAt?: string | null,
  status?: EventSequenceStatus | null,
  statusSearchable?: string | null,
  description?: string | null,
  citations?: Array< LinkInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteEventSequenceInput = {
  id: string,
};

export type CreateEventSequenceDocumentInput = {
  id?: string | null,
  owner?: string | null,
  documentId: string,
  eventSequenceId: string,
  seriesId: string,
  taskId?: string | null,
  facilityId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelEventSequenceDocumentConditionInput = {
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  eventSequenceId?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventSequenceDocumentConditionInput | null > | null,
  or?: Array< ModelEventSequenceDocumentConditionInput | null > | null,
  not?: ModelEventSequenceDocumentConditionInput | null,
};

export type UpdateEventSequenceDocumentInput = {
  id: string,
  owner?: string | null,
  documentId?: string | null,
  eventSequenceId?: string | null,
  seriesId?: string | null,
  taskId?: string | null,
  facilityId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteEventSequenceDocumentInput = {
  id: string,
};

export type CreateEventSequenceWatcherInput = {
  id?: string | null,
  owner?: string | null,
  personId: string,
  eventSequenceId: string,
  taskId?: string | null,
  seriesId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelEventSequenceWatcherConditionInput = {
  owner?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  eventSequenceId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventSequenceWatcherConditionInput | null > | null,
  or?: Array< ModelEventSequenceWatcherConditionInput | null > | null,
  not?: ModelEventSequenceWatcherConditionInput | null,
};

export type UpdateEventSequenceWatcherInput = {
  id: string,
  owner?: string | null,
  personId?: string | null,
  eventSequenceId?: string | null,
  taskId?: string | null,
  seriesId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteEventSequenceWatcherInput = {
  id: string,
};

export type CreateFacilityInput = {
  id?: string | null,
  owner?: string | null,
  name: string,
  status?: FacilityStatus | null,
  statusReason?: string | null,
  epaGeneratorStatus?: RcraGeneratorStatus | null,
  epaGeneratorStatusUpdatedAt?: string | null,
  epaGeneratorStatusUpdatedBy?: string | null,
  stateGeneratorStatus?: RcraGeneratorStatus | null,
  stateGeneratorStatusUpdatedAt?: string | null,
  stateGeneratorStatusUpdatedBy?: string | null,
  businessUnit?: string | null,
  customerFacilityId?: string | null,
  dbNumber?: string | null,
  facilityType?: string | null,
  naicsCode?: number | null,
  maxNumOccupants?: number | null,
  isUnmanned?: boolean | null,
  phone?: string | null,
  phone24Hour?: string | null,
  streetAddress: AddressInput,
  mailingAddress?: AddressInput | null,
  state?: string | null,
  county?: string | null,
  municipality?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  isAutoGeocoded?: boolean | null,
  lepc?: string | null,
  lepcId?: string | null,
  fireDistrict?: string | null,
  fireDistrictId?: string | null,
  programAreas?: Array< string | null > | null,
  alternateIds?: Array< FacilityAlternateIdInput > | null,
  userPermissions?: Array< UserPermissionInput | null > | null,
  dateOpened?: string | null,
  dateClosed?: string | null,
  dateInactive?: string | null,
  regulatoryInformation?: FacilityRegulatoryInformationInput | null,
  federalFields?: string | null,
  stateFields?: string | null,
  searchableProgramAreas?: string | null,
  searchableStatus?: string | null,
  searchableCity?: string | null,
  lastProductMeasuredAt?: string | null,
  customerProductComments?: Array< TierIICommentInput > | null,
  streetAddressVerifiedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type AddressInput = {
  streetAddress?: string | null,
  streetAddressLine2?: string | null,
  city?: string | null,
  zip?: string | null,
  state?: string | null,
  country?: string | null,
};

export type FacilityAlternateIdInput = {
  kind: string,
  id: string,
  expiresAt?: string | null,
  recordKeys?: Array< string | null > | null,
};

export type UserPermissionInput = {
  userTenantId: string,
  level: string,
};

export type FacilityRegulatoryInformationInput = {
  isSubjectToChemicalAccidentPrevention?: boolean | null,
  isSubjectToEmergencyPlanning?: boolean | null,
  isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
  reconciledAt?: string | null,
  rmpId?: string | null,
  triId?: string | null,
};

export type TierIICommentInput = {
  at: string,
  comment: string,
  commentedBy: string,
};

export type ModelFacilityConditionInput = {
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelFacilityStatusInput | null,
  statusReason?: ModelStringInput | null,
  epaGeneratorStatus?: ModelRcraGeneratorStatusInput | null,
  epaGeneratorStatusUpdatedAt?: ModelStringInput | null,
  epaGeneratorStatusUpdatedBy?: ModelStringInput | null,
  stateGeneratorStatus?: ModelRcraGeneratorStatusInput | null,
  stateGeneratorStatusUpdatedAt?: ModelStringInput | null,
  stateGeneratorStatusUpdatedBy?: ModelStringInput | null,
  businessUnit?: ModelStringInput | null,
  customerFacilityId?: ModelStringInput | null,
  dbNumber?: ModelStringInput | null,
  facilityType?: ModelStringInput | null,
  naicsCode?: ModelIntInput | null,
  maxNumOccupants?: ModelIntInput | null,
  isUnmanned?: ModelBooleanInput | null,
  phone?: ModelStringInput | null,
  phone24Hour?: ModelStringInput | null,
  state?: ModelStringInput | null,
  county?: ModelStringInput | null,
  municipality?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  isAutoGeocoded?: ModelBooleanInput | null,
  lepc?: ModelStringInput | null,
  lepcId?: ModelStringInput | null,
  fireDistrict?: ModelStringInput | null,
  fireDistrictId?: ModelStringInput | null,
  programAreas?: ModelIDInput | null,
  dateOpened?: ModelStringInput | null,
  dateClosed?: ModelStringInput | null,
  dateInactive?: ModelStringInput | null,
  federalFields?: ModelStringInput | null,
  stateFields?: ModelStringInput | null,
  searchableProgramAreas?: ModelStringInput | null,
  searchableStatus?: ModelStringInput | null,
  searchableCity?: ModelStringInput | null,
  lastProductMeasuredAt?: ModelStringInput | null,
  streetAddressVerifiedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityConditionInput | null > | null,
  or?: Array< ModelFacilityConditionInput | null > | null,
  not?: ModelFacilityConditionInput | null,
};

export type ModelFacilityStatusInput = {
  eq?: FacilityStatus | null,
  ne?: FacilityStatus | null,
};

export type ModelRcraGeneratorStatusInput = {
  eq?: RcraGeneratorStatus | null,
  ne?: RcraGeneratorStatus | null,
};

export type UpdateFacilityInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  status?: FacilityStatus | null,
  statusReason?: string | null,
  epaGeneratorStatus?: RcraGeneratorStatus | null,
  epaGeneratorStatusUpdatedAt?: string | null,
  epaGeneratorStatusUpdatedBy?: string | null,
  stateGeneratorStatus?: RcraGeneratorStatus | null,
  stateGeneratorStatusUpdatedAt?: string | null,
  stateGeneratorStatusUpdatedBy?: string | null,
  businessUnit?: string | null,
  customerFacilityId?: string | null,
  dbNumber?: string | null,
  facilityType?: string | null,
  naicsCode?: number | null,
  maxNumOccupants?: number | null,
  isUnmanned?: boolean | null,
  phone?: string | null,
  phone24Hour?: string | null,
  streetAddress?: AddressInput | null,
  mailingAddress?: AddressInput | null,
  state?: string | null,
  county?: string | null,
  municipality?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  isAutoGeocoded?: boolean | null,
  lepc?: string | null,
  lepcId?: string | null,
  fireDistrict?: string | null,
  fireDistrictId?: string | null,
  programAreas?: Array< string | null > | null,
  alternateIds?: Array< FacilityAlternateIdInput > | null,
  userPermissions?: Array< UserPermissionInput | null > | null,
  dateOpened?: string | null,
  dateClosed?: string | null,
  dateInactive?: string | null,
  regulatoryInformation?: FacilityRegulatoryInformationInput | null,
  federalFields?: string | null,
  stateFields?: string | null,
  searchableProgramAreas?: string | null,
  searchableStatus?: string | null,
  searchableCity?: string | null,
  lastProductMeasuredAt?: string | null,
  customerProductComments?: Array< TierIICommentInput > | null,
  streetAddressVerifiedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type CreateFacilityContactInput = {
  id?: string | null,
  owner?: string | null,
  facilityId: string,
  personId: string,
  areas?: Array< string > | null,
  reportingRoles?: Array< ContactReportRole > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelFacilityContactConditionInput = {
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  areas?: ModelIDInput | null,
  reportingRoles?: ModelContactReportRoleListInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityContactConditionInput | null > | null,
  or?: Array< ModelFacilityContactConditionInput | null > | null,
  not?: ModelFacilityContactConditionInput | null,
};

export type ModelContactReportRoleListInput = {
  eq?: Array< ContactReportRole | null > | null,
  ne?: Array< ContactReportRole | null > | null,
  contains?: ContactReportRole | null,
  notContains?: ContactReportRole | null,
};

export type UpdateFacilityContactInput = {
  id: string,
  owner?: string | null,
  facilityId?: string | null,
  personId?: string | null,
  areas?: Array< string > | null,
  reportingRoles?: Array< ContactReportRole > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteFacilityContactInput = {
  id: string,
};

export type CreateFacilityCredentialInput = {
  id?: string | null,
  owner?: string | null,
  facilityId: string,
  credentialId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelFacilityCredentialConditionInput = {
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  credentialId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityCredentialConditionInput | null > | null,
  or?: Array< ModelFacilityCredentialConditionInput | null > | null,
  not?: ModelFacilityCredentialConditionInput | null,
};

export type DeleteFacilityCredentialInput = {
  id: string,
};

export type CreateFacilityMeasurementInput = {
  id?: string | null,
  facilityId: string,
  owner?: string | null,
  productId: string,
  amount: number,
  unit: string,
  measuredAt: string,
  locations?: Array< TierIIStorageLocationInput | null > | null,
  stateFields?: string | null,
};

export type TierIIStorageLocationInput = {
  id: string,
  description?: string | null,
  storageType?: string | null,
  pressure?: string | null,
  temperature?: string | null,
  amount?: number | null,
  unit?: string | null,
  mapNumber?: string | null,
  gridNumber?: string | null,
  insideOutside?: string | null,
  storageBuilding?: string | null,
  storageFloor?: string | null,
  storageArea?: string | null,
  storageRoom?: string | null,
  storageQuadrant?: string | null,
};

export type ModelFacilityMeasurementConditionInput = {
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  unit?: ModelStringInput | null,
  measuredAt?: ModelStringInput | null,
  stateFields?: ModelStringInput | null,
  and?: Array< ModelFacilityMeasurementConditionInput | null > | null,
  or?: Array< ModelFacilityMeasurementConditionInput | null > | null,
  not?: ModelFacilityMeasurementConditionInput | null,
};

export type FacilityMeasurement = {
  __typename: "FacilityMeasurement",
  id?: string,
  facilityId?: string,
  owner?: string | null,
  productId?: string,
  amount?: number,
  unit?: string,
  measuredAt?: string,
  locations?:  Array<TierIIStorageLocation | null > | null,
  stateFields?: string | null,
  createdAt?: string,
  updatedAt?: string,
  facility?: Facility,
  product?: TierIIProduct,
};

export type UpdateFacilityMeasurementInput = {
  id: string,
  facilityId?: string | null,
  owner?: string | null,
  productId?: string | null,
  amount?: number | null,
  unit?: string | null,
  measuredAt?: string | null,
  locations?: Array< TierIIStorageLocationInput | null > | null,
  stateFields?: string | null,
};

export type DeleteFacilityMeasurementInput = {
  id: string,
};

export type CreateFacilityProductGroupMeasurementInput = {
  id?: string | null,
  facilityId: string,
  owner: string,
  productGroupId: string,
  amount: number,
  measuredAt: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelFacilityProductGroupMeasurementConditionInput = {
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productGroupId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  measuredAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityProductGroupMeasurementConditionInput | null > | null,
  or?: Array< ModelFacilityProductGroupMeasurementConditionInput | null > | null,
  not?: ModelFacilityProductGroupMeasurementConditionInput | null,
};

export type FacilityProductGroupMeasurement = {
  __typename: "FacilityProductGroupMeasurement",
  id?: string,
  facilityId?: string,
  owner?: string,
  productGroupId?: string,
  amount?: number,
  measuredAt?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facility?: Facility,
  productGroup?: TierIIProductGroup,
};

export type UpdateFacilityProductGroupMeasurementInput = {
  id: string,
  facilityId?: string | null,
  owner?: string | null,
  productGroupId?: string | null,
  amount?: number | null,
  measuredAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteFacilityProductGroupMeasurementInput = {
  id: string,
};

export type CreateFacilityProductInput = {
  id?: string | null,
  facilityId: string,
  owner?: string | null,
  productId: string,
  chemName: string,
  casNumber?: string | null,
  alternateId?: string | null,
  isEHS?: boolean | null,
  unit?: string | null,
  density?: number | null,
  densityUnits?: string | null,
  lastAmount?: number | null,
  maxAmount?: number | null,
  averageAmount?: number | null,
  totalMeasurements?: number | null,
  totalLocations?: number | null,
  isAboveReportingThresholds?: boolean | null,
  lastProductMeasuredAt?: string | null,
  daysOnSite?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelFacilityProductConditionInput = {
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  chemName?: ModelStringInput | null,
  casNumber?: ModelStringInput | null,
  alternateId?: ModelStringInput | null,
  isEHS?: ModelBooleanInput | null,
  unit?: ModelStringInput | null,
  density?: ModelFloatInput | null,
  densityUnits?: ModelStringInput | null,
  lastAmount?: ModelFloatInput | null,
  maxAmount?: ModelFloatInput | null,
  averageAmount?: ModelFloatInput | null,
  totalMeasurements?: ModelIntInput | null,
  totalLocations?: ModelIntInput | null,
  isAboveReportingThresholds?: ModelBooleanInput | null,
  lastProductMeasuredAt?: ModelStringInput | null,
  daysOnSite?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityProductConditionInput | null > | null,
  or?: Array< ModelFacilityProductConditionInput | null > | null,
  not?: ModelFacilityProductConditionInput | null,
};

export type FacilityProduct = {
  __typename: "FacilityProduct",
  id?: string,
  facilityId?: string,
  owner?: string | null,
  productId?: string,
  chemName?: string,
  casNumber?: string | null,
  alternateId?: string | null,
  isEHS?: boolean | null,
  unit?: string | null,
  density?: number | null,
  densityUnits?: string | null,
  lastAmount?: number | null,
  maxAmount?: number | null,
  averageAmount?: number | null,
  totalMeasurements?: number | null,
  totalLocations?: number | null,
  isAboveReportingThresholds?: boolean | null,
  lastProductMeasuredAt?: string | null,
  daysOnSite?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  facility?: Facility,
  product?: TierIIProduct,
};

export type UpdateFacilityProductInput = {
  id: string,
  facilityId?: string | null,
  owner?: string | null,
  productId?: string | null,
  chemName?: string | null,
  casNumber?: string | null,
  alternateId?: string | null,
  isEHS?: boolean | null,
  unit?: string | null,
  density?: number | null,
  densityUnits?: string | null,
  lastAmount?: number | null,
  maxAmount?: number | null,
  averageAmount?: number | null,
  totalMeasurements?: number | null,
  totalLocations?: number | null,
  isAboveReportingThresholds?: boolean | null,
  lastProductMeasuredAt?: string | null,
  daysOnSite?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteFacilityProductInput = {
  id: string,
};

export type CreateFacilityRequirementInput = {
  id?: string | null,
  owner?: string | null,
  facilityId: string,
  requirementId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelFacilityRequirementConditionInput = {
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityRequirementConditionInput | null > | null,
  or?: Array< ModelFacilityRequirementConditionInput | null > | null,
  not?: ModelFacilityRequirementConditionInput | null,
};

export type DeleteFacilityRequirementInput = {
  id: string,
};

export type UpdateLockInput = {
  id: string,
  description?: string | null,
  expiresAt?: number | null,
  holder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelLockConditionInput = {
  description?: ModelStringInput | null,
  expiresAt?: ModelIntInput | null,
  holder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelLockConditionInput | null > | null,
  or?: Array< ModelLockConditionInput | null > | null,
  not?: ModelLockConditionInput | null,
};

export type Lock = {
  __typename: "Lock",
  id?: string,
  description?: string | null,
  expiresAt?: number,
  holder?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteLockInput = {
  id: string,
};

export type CreateMailTrackingInput = {
  id: string,
  type: MailTrackingType,
  status?: string | null,
  data?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum MailTrackingType {
  CHECK = "CHECK",
  LETTER = "LETTER",
}


export type ModelMailTrackingConditionInput = {
  type?: ModelMailTrackingTypeInput | null,
  status?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMailTrackingConditionInput | null > | null,
  or?: Array< ModelMailTrackingConditionInput | null > | null,
  not?: ModelMailTrackingConditionInput | null,
};

export type ModelMailTrackingTypeInput = {
  eq?: MailTrackingType | null,
  ne?: MailTrackingType | null,
};

export type MailTracking = {
  __typename: "MailTracking",
  id?: string,
  type?: MailTrackingType,
  status?: string | null,
  data?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type UpdateMailTrackingInput = {
  id: string,
  type?: MailTrackingType | null,
  status?: string | null,
  data?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteMailTrackingInput = {
  id: string,
};

export type CreateMergeRecordInput = {
  id?: string | null,
  owner?: string | null,
  mergedAt: string,
  mergedBy: string,
  typeName: string,
  winningId: string,
  losingId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelMergeRecordConditionInput = {
  owner?: ModelIDInput | null,
  mergedAt?: ModelStringInput | null,
  mergedBy?: ModelStringInput | null,
  typeName?: ModelStringInput | null,
  winningId?: ModelIDInput | null,
  losingId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMergeRecordConditionInput | null > | null,
  or?: Array< ModelMergeRecordConditionInput | null > | null,
  not?: ModelMergeRecordConditionInput | null,
};

export type MergeRecord = {
  __typename: "MergeRecord",
  id?: string,
  owner?: string | null,
  mergedAt?: string,
  mergedBy?: string,
  typeName?: string,
  winningId?: string,
  losingId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type UpdateMergeRecordInput = {
  id: string,
  owner?: string | null,
  mergedAt?: string | null,
  mergedBy?: string | null,
  typeName?: string | null,
  winningId?: string | null,
  losingId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteMergeRecordInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  owner?: string | null,
  notificationType: NotificationType,
  parentId?: string | null,
  personId?: string | null,
  readAt?: string | null,
  lifecycleStatus: NotificationLifecycleStatus,
  scheduledFor: string,
  activeAt?: string | null,
  archivedAt?: string | null,
  name: string,
  description?: string | null,
  emailParams?: SendEmailParamsInput | null,
  eventContent?: NotificationEventContentInput | null,
  rcraStatusChangeContent?: RcraStatusChangeContentInput | null,
  sdsExtractionContent?: SdsExtractionContentInput | null,
  facilityId?: string | null,
  notificationTypeSearchable?: string | null,
  lifecycleStatusSearchable?: string | null,
  areaSearchable?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type SendEmailParamsInput = {
  from?: string | null,
  to?: string | null,
  subject?: string | null,
  replyTo?: string | null,
  text?: string | null,
  linkText?: string | null,
  hyperlink?: string | null,
};

export type NotificationEventContentInput = {
  area?: string | null,
  deadline?: string | null,
  seriesId?: string | null,
  type?: EventSequenceType | null,
};

export type ModelNotificationConditionInput = {
  owner?: ModelIDInput | null,
  notificationType?: ModelNotificationTypeInput | null,
  parentId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  readAt?: ModelStringInput | null,
  lifecycleStatus?: ModelNotificationLifecycleStatusInput | null,
  scheduledFor?: ModelStringInput | null,
  activeAt?: ModelStringInput | null,
  archivedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  facilityId?: ModelIDInput | null,
  notificationTypeSearchable?: ModelStringInput | null,
  lifecycleStatusSearchable?: ModelStringInput | null,
  areaSearchable?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type ModelNotificationLifecycleStatusInput = {
  eq?: NotificationLifecycleStatus | null,
  ne?: NotificationLifecycleStatus | null,
};

export type UpdateNotificationInput = {
  id: string,
  owner?: string | null,
  notificationType?: NotificationType | null,
  parentId?: string | null,
  personId?: string | null,
  readAt?: string | null,
  lifecycleStatus?: NotificationLifecycleStatus | null,
  scheduledFor?: string | null,
  activeAt?: string | null,
  archivedAt?: string | null,
  name?: string | null,
  description?: string | null,
  emailParams?: SendEmailParamsInput | null,
  eventContent?: NotificationEventContentInput | null,
  rcraStatusChangeContent?: RcraStatusChangeContentInput | null,
  sdsExtractionContent?: SdsExtractionContentInput | null,
  facilityId?: string | null,
  notificationTypeSearchable?: string | null,
  lifecycleStatusSearchable?: string | null,
  areaSearchable?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateNotificationFacilityLinkInput = {
  id?: string | null,
  owner?: string | null,
  notificationType: NotificationType,
  notificationId: string,
  facilityId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelNotificationFacilityLinkConditionInput = {
  owner?: ModelIDInput | null,
  notificationType?: ModelNotificationTypeInput | null,
  notificationId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNotificationFacilityLinkConditionInput | null > | null,
  or?: Array< ModelNotificationFacilityLinkConditionInput | null > | null,
  not?: ModelNotificationFacilityLinkConditionInput | null,
};

export type UpdateNotificationFacilityLinkInput = {
  id: string,
  owner?: string | null,
  notificationType?: NotificationType | null,
  notificationId?: string | null,
  facilityId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteNotificationFacilityLinkInput = {
  id: string,
};

export type CreateNotificationRequirementLinkInput = {
  id?: string | null,
  owner?: string | null,
  notificationType: NotificationType,
  notificationId: string,
  requirementId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelNotificationRequirementLinkConditionInput = {
  owner?: ModelIDInput | null,
  notificationType?: ModelNotificationTypeInput | null,
  notificationId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNotificationRequirementLinkConditionInput | null > | null,
  or?: Array< ModelNotificationRequirementLinkConditionInput | null > | null,
  not?: ModelNotificationRequirementLinkConditionInput | null,
};

export type UpdateNotificationRequirementLinkInput = {
  id: string,
  owner?: string | null,
  notificationType?: NotificationType | null,
  notificationId?: string | null,
  requirementId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteNotificationRequirementLinkInput = {
  id: string,
};

export type CreatePaymentInput = {
  id?: string | null,
  owner: string,
  paymentFacilityIds?: Array< string > | null,
  status: PaymentStatus,
  invoices?: Array< PaymentInvoiceInput > | null,
  paymentMethod?: PaymentMethod | null,
  amount?: number | null,
  description?: string | null,
  memo?: string | null,
  payee?: string | null,
  paidAt?: string | null,
  recordedBy?: string | null,
  paymentDocumentID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  activityId?: string | null,
  payeeType?: string | null,
  company?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
};

export enum PaymentStatus {
  AWAITING_INVOICE = "AWAITING_INVOICE",
  PENDING = "PENDING",
  PAID = "PAID",
  NO_PAYMENT = "NO_PAYMENT",
  QUEUED_FOR_AUTOMATION = "QUEUED_FOR_AUTOMATION",
}


export type PaymentInvoiceInput = {
  id?: string | null,
  payee?: string | null,
  invoiceNumber?: string | null,
  paymentInvoiceDocumentId?: string | null,
  dateDue?: string | null,
  amount?: number | null,
  description?: string | null,
};

export enum PaymentMethod {
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
  ACH = "ACH",
  NO_PAYMENT = "NO_PAYMENT",
}


export type ModelPaymentConditionInput = {
  owner?: ModelIDInput | null,
  paymentFacilityIds?: ModelIDInput | null,
  status?: ModelPaymentStatusInput | null,
  paymentMethod?: ModelPaymentMethodInput | null,
  amount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  payee?: ModelStringInput | null,
  paidAt?: ModelStringInput | null,
  recordedBy?: ModelStringInput | null,
  paymentDocumentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  activityId?: ModelIDInput | null,
  payeeType?: ModelStringInput | null,
  company?: ModelStringInput | null,
  addressLine1?: ModelStringInput | null,
  addressLine2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  and?: Array< ModelPaymentConditionInput | null > | null,
  or?: Array< ModelPaymentConditionInput | null > | null,
  not?: ModelPaymentConditionInput | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type ModelPaymentMethodInput = {
  eq?: PaymentMethod | null,
  ne?: PaymentMethod | null,
};

export type Payment = {
  __typename: "Payment",
  id?: string,
  owner?: string,
  paymentFacilityIds?: Array< string > | null,
  paymentReferences?:  Array<PaymentReference > | null,
  status?: PaymentStatus,
  invoices?:  Array<PaymentInvoice > | null,
  paymentMethod?: PaymentMethod | null,
  amount?: number | null,
  description?: string | null,
  memo?: string | null,
  payee?: string | null,
  paidAt?: string | null,
  recordedBy?: string | null,
  paymentDocumentID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  activityId?: string | null,
  payeeType?: string | null,
  company?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
};

export type PaymentReference = {
  __typename: "PaymentReference",
  ReferenceTypename?: string,
  id?: string,
  amount?: number | null,
};

export type TierIIReportPaymentReference = {
  __typename: "TierIIReportPaymentReference",
  ReferenceTypename?: string,
  id?: string,
  reportingYear?: number,
  amount?: number | null,
};

export type FacilityPaymentReference = {
  __typename: "FacilityPaymentReference",
  ReferenceTypename?: string,
  id?: string,
  amount?: number | null,
};

export type PaymentInvoice = {
  __typename: "PaymentInvoice",
  id?: string | null,
  payee?: string | null,
  invoiceNumber?: string | null,
  paymentInvoiceDocumentId?: string | null,
  dateDue?: string | null,
  amount?: number | null,
  description?: string | null,
};

export type UpdatePaymentInput = {
  id: string,
  owner?: string | null,
  paymentFacilityIds?: Array< string > | null,
  status?: PaymentStatus | null,
  invoices?: Array< PaymentInvoiceInput > | null,
  paymentMethod?: PaymentMethod | null,
  amount?: number | null,
  description?: string | null,
  memo?: string | null,
  payee?: string | null,
  paidAt?: string | null,
  recordedBy?: string | null,
  paymentDocumentID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  activityId?: string | null,
  payeeType?: string | null,
  company?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
};

export type DeletePaymentInput = {
  id: string,
};

export type CreatePersonInput = {
  id?: string | null,
  owner?: string | null,
  first?: string | null,
  last?: string | null,
  email?: string | null,
  title?: string | null,
  company?: string | null,
  address?: AddressInput | null,
  state?: string | null,
  phones: Array< PhoneInput >,
  contactType?: ContactType | null,
  searchableProgramAreas?: string | null,
  searchableFacilities?: string | null,
  searchableContactTypes?: string | null,
  userTenantId?: string | null,
  paymentMethodId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type PhoneInput = {
  type: PhoneType,
  reportTypes?: Array< ReportType > | null,
  number: string,
};

export type ModelPersonConditionInput = {
  owner?: ModelIDInput | null,
  first?: ModelStringInput | null,
  last?: ModelStringInput | null,
  email?: ModelStringInput | null,
  title?: ModelStringInput | null,
  company?: ModelStringInput | null,
  state?: ModelStringInput | null,
  contactType?: ModelContactTypeInput | null,
  searchableProgramAreas?: ModelStringInput | null,
  searchableFacilities?: ModelStringInput | null,
  searchableContactTypes?: ModelStringInput | null,
  userTenantId?: ModelIDInput | null,
  paymentMethodId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelPersonConditionInput | null > | null,
  or?: Array< ModelPersonConditionInput | null > | null,
  not?: ModelPersonConditionInput | null,
};

export type ModelContactTypeInput = {
  eq?: ContactType | null,
  ne?: ContactType | null,
};

export type UpdatePersonInput = {
  id: string,
  owner?: string | null,
  first?: string | null,
  last?: string | null,
  email?: string | null,
  title?: string | null,
  company?: string | null,
  address?: AddressInput | null,
  state?: string | null,
  phones?: Array< PhoneInput > | null,
  contactType?: ContactType | null,
  searchableProgramAreas?: string | null,
  searchableFacilities?: string | null,
  searchableContactTypes?: string | null,
  userTenantId?: string | null,
  paymentMethodId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeletePersonInput = {
  id: string,
};

export type CreatePortalInteractionRecordInput = {
  id?: string | null,
  owner?: string | null,
  requestId?: string | null,
  facilityId?: string | null,
  encampRequestId?: string | null,
  credentialId?: string | null,
  credentialUsername?: string | null,
  stateOrTerritory?: string | null,
  status?: PortalInteractionStatus | null,
  log?: Array< PortalInteractionLogInput | null > | null,
  action?: PortalInteractionAction | null,
  forceClassicSubmissionStrategy?: boolean | null,
  evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
  stepsDone?: Array< string > | null,
  stepError?: string | null,
  reportId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum PortalInteractionStatus {
  AWAITING_MANUAL_INTERVENTION = "AWAITING_MANUAL_INTERVENTION",
  FAILED_MAY_AUTO_RETRY = "FAILED_MAY_AUTO_RETRY",
  FAILED_NEEDS_HUMAN = "FAILED_NEEDS_HUMAN",
  FAILED_QUALITY_CONTROL_CHECK = "FAILED_QUALITY_CONTROL_CHECK",
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  SUCCEEDED = "SUCCEEDED",
}


export type PortalInteractionLogInput = {
  status: PortalInteractionStatus,
  at: string,
  errorMessage?: string | null,
  cloudWatchLogStream?: string | null,
  screenshotKey?: string | null,
};

export enum PortalInteractionAction {
  acquireLock = "acquireLock",
  closeDraftReport = "closeDraftReport",
  collectEvidenceOfCompletion = "collectEvidenceOfCompletion",
  downloadReport = "downloadReport",
  deleteLock = "deleteLock",
  extractChemicalDatabase = "extractChemicalDatabase",
  extractData = "extractData",
  extractStateIDs = "extractStateIDs",
  getFacilitiesList = "getFacilitiesList",
  inputData = "inputData",
  inputDataRevision = "inputDataRevision",
  inputDataLepc = "inputDataLepc",
  rcra870012 = "rcra870012",
  rcra870013 = "rcra870013",
  rcraEvq = "rcraEvq",
  scrapeDistrictInfo = "scrapeDistrictInfo",
  verifyCredentials = "verifyCredentials",
  verify870012Submission = "verify870012Submission",
}


export enum CollectEvidenceOfCompletionActions {
  completion = "completion",
  chemicalCount = "chemicalCount",
}


export type ModelPortalInteractionRecordConditionInput = {
  owner?: ModelIDInput | null,
  requestId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  encampRequestId?: ModelIDInput | null,
  credentialId?: ModelIDInput | null,
  credentialUsername?: ModelStringInput | null,
  stateOrTerritory?: ModelStringInput | null,
  status?: ModelPortalInteractionStatusInput | null,
  action?: ModelPortalInteractionActionInput | null,
  forceClassicSubmissionStrategy?: ModelBooleanInput | null,
  evidenceOfCompletionActions?: ModelCollectEvidenceOfCompletionActionsListInput | null,
  stepsDone?: ModelStringInput | null,
  stepError?: ModelStringInput | null,
  reportId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelPortalInteractionRecordConditionInput | null > | null,
  or?: Array< ModelPortalInteractionRecordConditionInput | null > | null,
  not?: ModelPortalInteractionRecordConditionInput | null,
};

export type ModelPortalInteractionStatusInput = {
  eq?: PortalInteractionStatus | null,
  ne?: PortalInteractionStatus | null,
};

export type ModelPortalInteractionActionInput = {
  eq?: PortalInteractionAction | null,
  ne?: PortalInteractionAction | null,
};

export type ModelCollectEvidenceOfCompletionActionsListInput = {
  eq?: Array< CollectEvidenceOfCompletionActions | null > | null,
  ne?: Array< CollectEvidenceOfCompletionActions | null > | null,
  contains?: CollectEvidenceOfCompletionActions | null,
  notContains?: CollectEvidenceOfCompletionActions | null,
};

export type PortalInteractionRecord = {
  __typename: "PortalInteractionRecord",
  id?: string,
  owner?: string | null,
  requestId?: string | null,
  facilityId?: string | null,
  encampRequestId?: string | null,
  credentialId?: string | null,
  credentialUsername?: string | null,
  stateOrTerritory?: string | null,
  status?: PortalInteractionStatus | null,
  log?:  Array<PortalInteractionLog | null > | null,
  action?: PortalInteractionAction | null,
  forceClassicSubmissionStrategy?: boolean | null,
  evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
  stepsDone?: Array< string > | null,
  stepError?: string | null,
  reportId?: string | null,
  createdAt?: string | null,
  updatedAt?: string,
  updatedBy?: string | null,
};

export type PortalInteractionLog = {
  __typename: "PortalInteractionLog",
  status?: PortalInteractionStatus,
  at?: string,
  errorMessage?: string | null,
  cloudWatchLogStream?: string | null,
  screenshotKey?: string | null,
};

export type UpdatePortalInteractionRecordInput = {
  id: string,
  owner?: string | null,
  requestId?: string | null,
  facilityId?: string | null,
  encampRequestId?: string | null,
  credentialId?: string | null,
  credentialUsername?: string | null,
  stateOrTerritory?: string | null,
  status?: PortalInteractionStatus | null,
  log?: Array< PortalInteractionLogInput | null > | null,
  action?: PortalInteractionAction | null,
  forceClassicSubmissionStrategy?: boolean | null,
  evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
  stepsDone?: Array< string > | null,
  stepError?: string | null,
  reportId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeletePortalInteractionRecordInput = {
  id: string,
};

export type CreateProgramAreaInput = {
  id?: string | null,
  owner?: string | null,
  name: string,
  description?: string | null,
  icon?: string | null,
  isDisabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelProgramAreaConditionInput = {
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  isDisabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelProgramAreaConditionInput | null > | null,
  or?: Array< ModelProgramAreaConditionInput | null > | null,
  not?: ModelProgramAreaConditionInput | null,
};

export type ProgramArea = {
  __typename: "ProgramArea",
  id?: string,
  owner?: string | null,
  name?: string,
  description?: string | null,
  icon?: string | null,
  isDisabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type UpdateProgramAreaInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  icon?: string | null,
  isDisabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteProgramAreaInput = {
  id: string,
};

export type CreateRcraForm870012Input = {
  id?: string | null,
  owner?: string | null,
  facilityId: string,
  status: RcraFormStatus,
  submittedAt?: string | null,
  metadata?: RcraFormMetadataInput | null,
  staffAssignee?: string | null,
  submissionType?: RcraSubmissionType | null,
  si?: RcraSiteIdentificationFormInput | null,
  reviewers?: Array< SubmissionAssigneeInput > | null,
  verifier?: SubmissionAssigneeInput | null,
  noteKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum RcraFormStatus {
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  PROCESSING = "PROCESSING",
  FILING_COMPLETE = "FILING_COMPLETE",
}


export type RcraFormMetadataInput = {
  staffComments?: Array< RcraFormStaffCommentInput > | null,
  submissionMeasures?: Array< RcraFormSubmissionMeasureInput > | null,
  tags?: string | null,
};

export type RcraFormStaffCommentInput = {
  at: string,
  comment: string,
  commentedBy: string,
};

export type RcraFormSubmissionMeasureInput = {
  name: string,
  description?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  records?: Array< RcraFormRecordInput > | null,
  notes?: string | null,
};

export type RcraFormRecordInput = {
  at: string,
  key: string,
  name: string,
  associatedDocumentId?: string | null,
};

export enum RcraSubmissionType {
  INITIAL_NOTIFICATION = "INITIAL_NOTIFICATION",
  RENOTIFICATION = "RENOTIFICATION",
  UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION",
  EPISODIC_GENERATION_NOTIFICATION = "EPISODIC_GENERATION_NOTIFICATION",
  BIENNIAL_REPORT = "BIENNIAL_REPORT",
}


export type RcraSiteIdentificationFormInput = {
  reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
  epaId?: string | null,
  siteName?: string | null,
  locationAddress?: AddressInput | null,
  county?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  mailingAddress?: AddressInput | null,
  landType?: RcraSiteIdentificationFormLandType | null,
  naicsCodes?: Array< number > | null,
  siteContact?: RcraSiteIdentificationFormSiteContactInput | null,
  siteOwners?: Array< RcraSiteIdentificationFormOwnerOperatorInput > | null,
  siteOperators?: Array< RcraSiteIdentificationFormOwnerOperatorInput > | null,
  federalGeneratorStatus?: RcraGeneratorStatus | null,
  isShortTermGenerator?: boolean | null,
  shortTermGeneratorComments?: string | null,
  isTreaterStorerDisposer?: boolean | null,
  isOffSiteReceiver?: boolean | null,
  isRecycler?: boolean | null,
  isRecyclerStorer?: boolean | null,
  isExemptBurner?: boolean | null,
  isExemptFurnace?: boolean | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
  isWasteTransporter?: boolean | null,
  wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
  isUndergroundInjectionControl?: boolean | null,
  isUSImporter?: boolean | null,
  isRecognizedTrader?: boolean | null,
  recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
  isSpentLeadAcidBatteryTrader?: boolean | null,
  spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
  isLargeQuantityHandlerUniversalWaste?: boolean | null,
  largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
  isUniversalWasteDestination?: boolean | null,
  isUsedOilTransporter?: boolean | null,
  usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
  isUsedOilProcessor?: boolean | null,
  usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
  isOffSpecificationUsedOilBurner?: boolean | null,
  isUsedOilFuelMarketer?: boolean | null,
  usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
  hasPharmaceuticalActivity?: boolean | null,
  pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
  isPharmaceuticalWithdrawing?: boolean | null,
  isAcademicLab?: boolean | null,
  academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
  isWithdrawingFromAcademicLab?: boolean | null,
  isEpisodicGeneration?: boolean | null,
  isLQGConsolidator?: boolean | null,
  episodicEvent?: RcraSiteIdentificationFormEpisodicEventInput | null,
  episodicEventEmergencyContact?: RcraSiteIdentificationFormEmergencyContactInput | null,
  lqgConsolidatorSites?: Array< RcraSiteIdentificationFormLQGConsolidatorVSQGSiteInput > | null,
  lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
  expectedClosureDate?: string | null,
  requestingNewClosureDate?: string | null,
  actualClosureDate?: string | null,
  isClosedInCompliance?: boolean | null,
  isNotifyingHazardousSecondaryMaterial?: boolean | null,
  isManagingHazardousSecondarymaterial?: boolean | null,
  reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
  effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
  hsmActivities?: Array< RcraSiteIdentificationFormHSMActivityInput > | null,
  isElectronicManifestBroker?: boolean | null,
  comments?: string | null,
  certifiers?: Array< RcraSiteIdentificationFormCertifierInput > | null,
  companyName?: string | null,
  companyMailingAddress?: AddressInput | null,
  companyMailingAttention?: string | null,
};

export enum RcraSiteIdentificationFormReasonForSubmittal {
  BIENNIAL_REPORT = "BIENNIAL_REPORT",
  ONGOING_REGULATED_ACTIVITIES = "ONGOING_REGULATED_ACTIVITIES",
  NO_LONGER_OCCURRING = "NO_LONGER_OCCURRING",
}


export enum RcraSiteIdentificationFormLandType {
  FEDERAL = "FEDERAL",
  STATE = "STATE",
  TRIBAL = "TRIBAL",
  PRIVATE = "PRIVATE",
  COUNTY = "COUNTY",
  MUNICIPAL = "MUNICIPAL",
  DISTRICT = "DISTRICT",
  OTHER = "OTHER",
}


export type RcraSiteIdentificationFormSiteContactInput = {
  first?: string | null,
  middle?: string | null,
  last?: string | null,
  title?: string | null,
  address?: AddressInput | null,
  email?: string | null,
  workPhone?: PhoneInput | null,
  faxPhone?: PhoneInput | null,
  phones?: Array< PhoneInput > | null,
};

export type RcraSiteIdentificationFormOwnerOperatorInput = {
  fullName?: string | null,
  title?: string | null,
  startedAt?: string | null,
  type?: RcraSiteIdentificationFormLandType | null,
  address?: AddressInput | null,
  email?: string | null,
  workPhone?: PhoneInput | null,
  faxPhone?: PhoneInput | null,
  phones?: Array< PhoneInput > | null,
  comments?: string | null,
};

export enum RcraSiteIdentificationFormTransporterType {
  TRANSPORTER = "TRANSPORTER",
  TRANSFER_FACILITY = "TRANSFER_FACILITY",
}


export enum RcraSiteIdentificationFormTraderType {
  IMPORTER = "IMPORTER",
  EXPORTER = "EXPORTER",
}


export enum RcraSiteIdentificationFormUsedOilProcessorType {
  PROCESSOR = "PROCESSOR",
  REREFINER = "REREFINER",
}


export enum RcraSiteIdentificationFormUsedOilMarketerType {
  DIRECTS_SHIPMENT = "DIRECTS_SHIPMENT",
  FIRST_CLAIMS = "FIRST_CLAIMS",
}


export enum RcraSiteIdentificationFormPharmaceuticalActivity {
  HEALTHCARE_FACILITY = "HEALTHCARE_FACILITY",
  REVERSE_DISTRIBUTOR = "REVERSE_DISTRIBUTOR",
}


export enum RcraSiteIdentificationFormAcademicLabType {
  COLLEGE_UNIVERSITY = "COLLEGE_UNIVERSITY",
  TEACHING_HOSPITAL = "TEACHING_HOSPITAL",
  NONPROFIT = "NONPROFIT",
}


export type RcraSiteIdentificationFormEpisodicEventInput = {
  isPlannedEpisodicEvent?: boolean | null,
  projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
  beginningDate?: string | null,
  endDate?: string | null,
  otherDescription?: string | null,
  waste?: Array< RcraSiteIdentificationFormEpisodicGeneratorWasteInput | null > | null,
};

export enum RcraSiteIdentificationFormEpisodicEventProjectType {
  UNPLANNED_ACCIDENTAL_SPILLS = "UNPLANNED_ACCIDENTAL_SPILLS",
  UNPLANNED_PRODUCTION_PROCESS_UPSETS = "UNPLANNED_PRODUCTION_PROCESS_UPSETS",
  UNPLANNED_PRODUCT_RECALLS = "UNPLANNED_PRODUCT_RECALLS",
  UNPLANNED_ACTS_OF_NATURE = "UNPLANNED_ACTS_OF_NATURE",
  UNPLANNED_OTHER = "UNPLANNED_OTHER",
  PLANNED_EXCESS_CHEMICAL_INVENTORY_REMOVAL = "PLANNED_EXCESS_CHEMICAL_INVENTORY_REMOVAL",
  PLANNED_TANK_CLEANOUTS = "PLANNED_TANK_CLEANOUTS",
  PLANNED_SHORT_TERM_CONSTRUCTION_OR_DEMOLITION = "PLANNED_SHORT_TERM_CONSTRUCTION_OR_DEMOLITION",
  PLANNED_EQUIPMENT_MAINTENANCE_DURING_PLANT_SHUTDOWN = "PLANNED_EQUIPMENT_MAINTENANCE_DURING_PLANT_SHUTDOWN",
  PLANNED_OTHER = "PLANNED_OTHER",
}


export type RcraSiteIdentificationFormEpisodicGeneratorWasteInput = {
  wasteDescription?: string | null,
  estimatedQuantity?: number | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
};

export type RcraSiteIdentificationFormEmergencyContactInput = {
  first?: string | null,
  middle?: string | null,
  last?: string | null,
  phones?: Array< PhoneInput > | null,
  email?: string | null,
};

export type RcraSiteIdentificationFormLQGConsolidatorVSQGSiteInput = {
  epaIdNumber?: string | null,
  siteName?: string | null,
  address: AddressInput,
  contactFirst?: string | null,
  contactMiddle?: string | null,
  contactLast?: string | null,
  contactPhone?: PhoneInput | null,
  contactEmail?: string | null,
};

export enum RcraSiteIdentificationFormLQGClosureType {
  CENTRAL_ACCUMULATION_AREA = "CENTRAL_ACCUMULATION_AREA",
  ENTIRE_FACILITY = "ENTIRE_FACILITY",
}


export enum RcraSiteIdentificationFormHSMNotificationReason {
  BEGIN_MANAGING = "BEGIN_MANAGING",
  MANAGING = "MANAGING",
  STOP_MANAGING = "STOP_MANAGING",
}


export type RcraSiteIdentificationFormHSMActivityInput = {
  facilityCode?: string | null,
  wasteCodes?: Array< string > | null,
  estimatedShortTons?: number | null,
  landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
};

export enum RcraSiteIdentificationFormHSMLandBasedUnitType {
  NA = "NA",
  OT = "OT",
  PL = "PL",
  SI = "SI",
}


export type RcraSiteIdentificationFormCertifierInput = {
  certifiedAt?: string | null,
  fullName?: string | null,
  title?: string | null,
  email?: string | null,
};

export type SubmissionAssigneeInput = {
  id: string,
  first: string,
  last: string,
  fullName?: string | null,
  title?: string | null,
  isComplete?: boolean | null,
  completedAt?: string | null,
};

export type ModelRcraForm870012ConditionInput = {
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  status?: ModelRcraFormStatusInput | null,
  submittedAt?: ModelStringInput | null,
  staffAssignee?: ModelStringInput | null,
  submissionType?: ModelRcraSubmissionTypeInput | null,
  noteKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRcraForm870012ConditionInput | null > | null,
  or?: Array< ModelRcraForm870012ConditionInput | null > | null,
  not?: ModelRcraForm870012ConditionInput | null,
};

export type ModelRcraFormStatusInput = {
  eq?: RcraFormStatus | null,
  ne?: RcraFormStatus | null,
};

export type ModelRcraSubmissionTypeInput = {
  eq?: RcraSubmissionType | null,
  ne?: RcraSubmissionType | null,
};

export type RcraForm870012 = {
  __typename: "RcraForm870012",
  id?: string,
  owner?: string | null,
  facilityId?: string,
  status?: RcraFormStatus,
  submittedAt?: string | null,
  metadata?: RcraFormMetadata,
  staffAssignee?: string | null,
  submissionType?: RcraSubmissionType | null,
  si?: RcraSiteIdentificationForm,
  reviewers?:  Array<SubmissionAssignee > | null,
  verifier?: SubmissionAssignee,
  noteKey?: string | null,
  notes?: ModelNoteConnection,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type RcraFormMetadata = {
  __typename: "RcraFormMetadata",
  staffComments?:  Array<RcraFormStaffComment > | null,
  submissionMeasures?:  Array<RcraFormSubmissionMeasure > | null,
  tags?: string | null,
};

export type RcraFormStaffComment = {
  __typename: "RcraFormStaffComment",
  at?: string,
  comment?: string,
  commentedBy?: string,
};

export type RcraFormSubmissionMeasure = {
  __typename: "RcraFormSubmissionMeasure",
  name?: string,
  description?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  records?:  Array<RcraFormRecord > | null,
  notes?: string | null,
};

export type RcraFormRecord = {
  __typename: "RcraFormRecord",
  at?: string,
  key?: string,
  name?: string,
  associatedDocumentId?: string | null,
};

export type RcraSiteIdentificationForm = {
  __typename: "RcraSiteIdentificationForm",
  reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
  epaId?: string | null,
  siteName?: string | null,
  locationAddress?: Address,
  county?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  mailingAddress?: Address,
  landType?: RcraSiteIdentificationFormLandType | null,
  naicsCodes?: Array< number > | null,
  siteContact?: RcraSiteIdentificationFormSiteContact,
  siteOwners?:  Array<RcraSiteIdentificationFormOwnerOperator > | null,
  siteOperators?:  Array<RcraSiteIdentificationFormOwnerOperator > | null,
  federalGeneratorStatus?: RcraGeneratorStatus | null,
  isShortTermGenerator?: boolean | null,
  shortTermGeneratorComments?: string | null,
  isTreaterStorerDisposer?: boolean | null,
  isOffSiteReceiver?: boolean | null,
  isRecycler?: boolean | null,
  isRecyclerStorer?: boolean | null,
  isExemptBurner?: boolean | null,
  isExemptFurnace?: boolean | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
  isWasteTransporter?: boolean | null,
  wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
  isUndergroundInjectionControl?: boolean | null,
  isUSImporter?: boolean | null,
  isRecognizedTrader?: boolean | null,
  recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
  isSpentLeadAcidBatteryTrader?: boolean | null,
  spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
  isLargeQuantityHandlerUniversalWaste?: boolean | null,
  largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
  isUniversalWasteDestination?: boolean | null,
  isUsedOilTransporter?: boolean | null,
  usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
  isUsedOilProcessor?: boolean | null,
  usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
  isOffSpecificationUsedOilBurner?: boolean | null,
  isUsedOilFuelMarketer?: boolean | null,
  usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
  hasPharmaceuticalActivity?: boolean | null,
  pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
  isPharmaceuticalWithdrawing?: boolean | null,
  isAcademicLab?: boolean | null,
  academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
  isWithdrawingFromAcademicLab?: boolean | null,
  isEpisodicGeneration?: boolean | null,
  isLQGConsolidator?: boolean | null,
  episodicEvent?: RcraSiteIdentificationFormEpisodicEvent,
  episodicEventEmergencyContact?: RcraSiteIdentificationFormEmergencyContact,
  lqgConsolidatorSites?:  Array<RcraSiteIdentificationFormLQGConsolidatorVSQGSite > | null,
  lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
  expectedClosureDate?: string | null,
  requestingNewClosureDate?: string | null,
  actualClosureDate?: string | null,
  isClosedInCompliance?: boolean | null,
  isNotifyingHazardousSecondaryMaterial?: boolean | null,
  isManagingHazardousSecondarymaterial?: boolean | null,
  reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
  effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
  hsmActivities?:  Array<RcraSiteIdentificationFormHSMActivity > | null,
  isElectronicManifestBroker?: boolean | null,
  comments?: string | null,
  certifiers?:  Array<RcraSiteIdentificationFormCertifier > | null,
  companyName?: string | null,
  companyMailingAddress?: Address,
  companyMailingAttention?: string | null,
};

export type RcraSiteIdentificationFormSiteContact = {
  __typename: "RcraSiteIdentificationFormSiteContact",
  first?: string | null,
  middle?: string | null,
  last?: string | null,
  title?: string | null,
  address?: Address,
  email?: string | null,
  workPhone?: Phone,
  faxPhone?: Phone,
  phones?:  Array<Phone > | null,
};

export type RcraSiteIdentificationFormOwnerOperator = {
  __typename: "RcraSiteIdentificationFormOwnerOperator",
  fullName?: string | null,
  title?: string | null,
  startedAt?: string | null,
  type?: RcraSiteIdentificationFormLandType | null,
  address?: Address,
  email?: string | null,
  workPhone?: Phone,
  faxPhone?: Phone,
  phones?:  Array<Phone > | null,
  comments?: string | null,
};

export type RcraSiteIdentificationFormEpisodicEvent = {
  __typename: "RcraSiteIdentificationFormEpisodicEvent",
  isPlannedEpisodicEvent?: boolean | null,
  projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
  beginningDate?: string | null,
  endDate?: string | null,
  otherDescription?: string | null,
  waste?:  Array<RcraSiteIdentificationFormEpisodicGeneratorWaste | null > | null,
};

export type RcraSiteIdentificationFormEpisodicGeneratorWaste = {
  __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
  wasteDescription?: string | null,
  estimatedQuantity?: number | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
};

export type RcraSiteIdentificationFormEmergencyContact = {
  __typename: "RcraSiteIdentificationFormEmergencyContact",
  first?: string | null,
  middle?: string | null,
  last?: string | null,
  phones?:  Array<Phone > | null,
  email?: string | null,
};

export type RcraSiteIdentificationFormLQGConsolidatorVSQGSite = {
  __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
  epaIdNumber?: string | null,
  siteName?: string | null,
  address?: Address,
  contactFirst?: string | null,
  contactMiddle?: string | null,
  contactLast?: string | null,
  contactPhone?: Phone,
  contactEmail?: string | null,
};

export type RcraSiteIdentificationFormHSMActivity = {
  __typename: "RcraSiteIdentificationFormHSMActivity",
  facilityCode?: string | null,
  wasteCodes?: Array< string > | null,
  estimatedShortTons?: number | null,
  landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
};

export type RcraSiteIdentificationFormCertifier = {
  __typename: "RcraSiteIdentificationFormCertifier",
  certifiedAt?: string | null,
  fullName?: string | null,
  title?: string | null,
  email?: string | null,
};

export type SubmissionAssignee = {
  __typename: "SubmissionAssignee",
  id?: string,
  first?: string,
  last?: string,
  fullName?: string | null,
  title?: string | null,
  isComplete?: boolean | null,
  completedAt?: string | null,
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection",
  items?:  Array<Note | null > | null,
  nextToken?: string | null,
};

export type UpdateRcraForm870012Input = {
  id: string,
  owner?: string | null,
  facilityId?: string | null,
  status?: RcraFormStatus | null,
  submittedAt?: string | null,
  metadata?: RcraFormMetadataInput | null,
  staffAssignee?: string | null,
  submissionType?: RcraSubmissionType | null,
  si?: RcraSiteIdentificationFormInput | null,
  reviewers?: Array< SubmissionAssigneeInput > | null,
  verifier?: SubmissionAssigneeInput | null,
  noteKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteRcraForm870012Input = {
  id: string,
};

export type CreateRcraForm870013Input = {
  id?: string | null,
  owner?: string | null,
  facilityId: string,
  status: RcraFormStatus,
  reportingPeriod?: string | null,
  submittedAt?: string | null,
  metadata?: RcraFormMetadataInput | null,
  staffAssignee?: string | null,
  si?: RcraSiteIdentificationFormInput | null,
  gm?: RcraGenerationManagementFormInput | null,
  oi?: RcraOffSiteIdentificationFormInput | null,
  wr?: RcraWasteReceivedFormInput | null,
  reviewers?: Array< SubmissionAssigneeInput > | null,
  verifier?: SubmissionAssigneeInput | null,
  noteKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type RcraGenerationManagementFormInput = {
  forms?: Array< RcraGenerationManagementWasteStreamInput > | null,
};

export type RcraGenerationManagementWasteStreamInput = {
  id?: string | null,
  description?: string | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
  sourceCode?: string | null,
  managementMethod?: string | null,
  countryCode?: string | null,
  formCode?: string | null,
  wasteMinimizationCode?: string | null,
  radioactiveMixed?: boolean | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
  density?: number | null,
  densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
  wasteWasProcessedOnSite?: boolean | null,
  onSiteProcesses?: Array< RcraGenerationManagementOnSiteProcessInput > | null,
  wasteWasShippedOffSite?: boolean | null,
  offSiteProcesses?: Array< RcraGenerationManagementOffSiteProcessInput > | null,
  comments?: string | null,
  status?: RcraFormStatus | null,
};

export enum RcraDensityUnitOfMeasure {
  POUNDS_PER_GALLON = "POUNDS_PER_GALLON",
  SPECIFIC_GRAVITY = "SPECIFIC_GRAVITY",
}


export type RcraGenerationManagementOnSiteProcessInput = {
  managementMethod?: string | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
};

export type RcraGenerationManagementOffSiteProcessInput = {
  shippedEpaId?: string | null,
  managementMethod?: string | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
};

export type RcraOffSiteIdentificationFormInput = {
  sites?: Array< RcraOffSiteInstallationOrTransporterInput | null > | null,
  comments?: string | null,
};

export type RcraOffSiteInstallationOrTransporterInput = {
  epaId?: string | null,
  name?: string | null,
  handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
  installationAddress?: AddressInput | null,
  county?: string | null,
};

export enum RcraOffSiteIdentificationHandlerType {
  GENERATOR = "GENERATOR",
  TRANSPORTER = "TRANSPORTER",
  RECEIVING_FACILITY = "RECEIVING_FACILITY",
}


export type RcraWasteReceivedFormInput = {
  wastes?: Array< RcraWasteReceivedWasteStreamInput > | null,
  comments?: string | null,
};

export type RcraWasteReceivedWasteStreamInput = {
  description?: string | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
  epaIdNumber?: string | null,
  formCode?: string | null,
  managementMethod?: string | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
  density?: number | null,
  densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
};

export type ModelRcraForm870013ConditionInput = {
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  status?: ModelRcraFormStatusInput | null,
  reportingPeriod?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  staffAssignee?: ModelStringInput | null,
  noteKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRcraForm870013ConditionInput | null > | null,
  or?: Array< ModelRcraForm870013ConditionInput | null > | null,
  not?: ModelRcraForm870013ConditionInput | null,
};

export type RcraForm870013 = {
  __typename: "RcraForm870013",
  id?: string,
  owner?: string | null,
  facilityId?: string,
  status?: RcraFormStatus,
  reportingPeriod?: string | null,
  submittedAt?: string | null,
  metadata?: RcraFormMetadata,
  staffAssignee?: string | null,
  si?: RcraSiteIdentificationForm,
  gm?: RcraGenerationManagementForm,
  oi?: RcraOffSiteIdentificationForm,
  wr?: RcraWasteReceivedForm,
  reviewers?:  Array<SubmissionAssignee > | null,
  verifier?: SubmissionAssignee,
  noteKey?: string | null,
  notes?: ModelNoteConnection,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type RcraGenerationManagementForm = {
  __typename: "RcraGenerationManagementForm",
  forms?:  Array<RcraGenerationManagementWasteStream > | null,
};

export type RcraGenerationManagementWasteStream = {
  __typename: "RcraGenerationManagementWasteStream",
  id?: string | null,
  description?: string | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
  sourceCode?: string | null,
  managementMethod?: string | null,
  countryCode?: string | null,
  formCode?: string | null,
  wasteMinimizationCode?: string | null,
  radioactiveMixed?: boolean | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
  density?: number | null,
  densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
  wasteWasProcessedOnSite?: boolean | null,
  onSiteProcesses?:  Array<RcraGenerationManagementOnSiteProcess > | null,
  wasteWasShippedOffSite?: boolean | null,
  offSiteProcesses?:  Array<RcraGenerationManagementOffSiteProcess > | null,
  comments?: string | null,
  status?: RcraFormStatus | null,
};

export type RcraGenerationManagementOnSiteProcess = {
  __typename: "RcraGenerationManagementOnSiteProcess",
  managementMethod?: string | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
};

export type RcraGenerationManagementOffSiteProcess = {
  __typename: "RcraGenerationManagementOffSiteProcess",
  shippedEpaId?: string | null,
  managementMethod?: string | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
};

export type RcraOffSiteIdentificationForm = {
  __typename: "RcraOffSiteIdentificationForm",
  sites?:  Array<RcraOffSiteInstallationOrTransporter | null > | null,
  comments?: string | null,
};

export type RcraOffSiteInstallationOrTransporter = {
  __typename: "RcraOffSiteInstallationOrTransporter",
  epaId?: string | null,
  name?: string | null,
  handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
  installationAddress?: Address,
  county?: string | null,
};

export type RcraWasteReceivedForm = {
  __typename: "RcraWasteReceivedForm",
  wastes?:  Array<RcraWasteReceivedWasteStream > | null,
  comments?: string | null,
};

export type RcraWasteReceivedWasteStream = {
  __typename: "RcraWasteReceivedWasteStream",
  description?: string | null,
  federalWasteCodes?: Array< string > | null,
  stateWasteCodes?: Array< string > | null,
  epaIdNumber?: string | null,
  formCode?: string | null,
  managementMethod?: string | null,
  quantity?: number | null,
  quantityUnitOfMeasure?: string | null,
  density?: number | null,
  densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
};

export type UpdateRcraForm870013Input = {
  id: string,
  owner?: string | null,
  facilityId?: string | null,
  status?: RcraFormStatus | null,
  reportingPeriod?: string | null,
  submittedAt?: string | null,
  metadata?: RcraFormMetadataInput | null,
  staffAssignee?: string | null,
  si?: RcraSiteIdentificationFormInput | null,
  gm?: RcraGenerationManagementFormInput | null,
  oi?: RcraOffSiteIdentificationFormInput | null,
  wr?: RcraWasteReceivedFormInput | null,
  reviewers?: Array< SubmissionAssigneeInput > | null,
  verifier?: SubmissionAssigneeInput | null,
  noteKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteRcraForm870013Input = {
  id: string,
};

export type CreateRequirementInput = {
  id?: string | null,
  owner?: string | null,
  status?: RequirementStatus | null,
  name: string,
  description?: string | null,
  frequency?: RepeatRuleInput | null,
  agencyId: string,
  citations?: Array< LinkInput | null > | null,
  area: string,
  areaSearchable: string,
  jurisdictionalAgency?: string | null,
  intervalInWords?: string | null,
  parentStatute?: ParentStatute | null,
  jurisdictionalState?: string | null,
  jurisdictionalCounties?: Array< string | null > | null,
  title?: string | null,
  portals?: Array< LinkInput | null > | null,
  jurisdictionalTownship?: string | null,
  jurisdictionalTribalArea?: string | null,
  jurisdictionalCity?: string | null,
  formExpires?: string | null,
  forms?: Array< LinkInput | null > | null,
  fillableLinks?: Array< string | null > | null,
  otherLinks?: Array< LinkInput | null > | null,
  instructionFileName?: string | null,
  instruction?: string | null,
  extraDocumentations?: Array< LinkInput | null > | null,
  department?: string | null,
  subDepartment?: string | null,
  contactEmail?: string | null,
  contactPhone?: string | null,
  contactPhoneExtension?: number | null,
  isExtensionAvailable?: boolean | null,
  extensionNotes?: string | null,
  penalty?: string | null,
  encampInstructions?: string | null,
  isAlwaysApplicable?: boolean | null,
  agencyLevelSearchable?: string | null,
  agencyStateSearchable?: string | null,
  userAgencyTitle?: string | null,
  tasks?: Array< TaskInput | null > | null,
  hyperlinks?: Array< HyperLinkInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelRequirementConditionInput = {
  owner?: ModelIDInput | null,
  status?: ModelRequirementStatusInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  agencyId?: ModelIDInput | null,
  area?: ModelIDInput | null,
  areaSearchable?: ModelStringInput | null,
  jurisdictionalAgency?: ModelStringInput | null,
  intervalInWords?: ModelStringInput | null,
  parentStatute?: ModelParentStatuteInput | null,
  jurisdictionalState?: ModelStringInput | null,
  jurisdictionalCounties?: ModelStringInput | null,
  title?: ModelStringInput | null,
  jurisdictionalTownship?: ModelStringInput | null,
  jurisdictionalTribalArea?: ModelStringInput | null,
  jurisdictionalCity?: ModelStringInput | null,
  formExpires?: ModelStringInput | null,
  fillableLinks?: ModelStringInput | null,
  instructionFileName?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  department?: ModelStringInput | null,
  subDepartment?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactPhone?: ModelStringInput | null,
  contactPhoneExtension?: ModelIntInput | null,
  isExtensionAvailable?: ModelBooleanInput | null,
  extensionNotes?: ModelStringInput | null,
  penalty?: ModelStringInput | null,
  encampInstructions?: ModelStringInput | null,
  isAlwaysApplicable?: ModelBooleanInput | null,
  agencyLevelSearchable?: ModelStringInput | null,
  agencyStateSearchable?: ModelStringInput | null,
  userAgencyTitle?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRequirementConditionInput | null > | null,
  or?: Array< ModelRequirementConditionInput | null > | null,
  not?: ModelRequirementConditionInput | null,
};

export type ModelRequirementStatusInput = {
  eq?: RequirementStatus | null,
  ne?: RequirementStatus | null,
};

export type ModelParentStatuteInput = {
  eq?: ParentStatute | null,
  ne?: ParentStatute | null,
};

export type UpdateRequirementInput = {
  id: string,
  owner?: string | null,
  status?: RequirementStatus | null,
  name?: string | null,
  description?: string | null,
  frequency?: RepeatRuleInput | null,
  agencyId?: string | null,
  citations?: Array< LinkInput | null > | null,
  area?: string | null,
  areaSearchable?: string | null,
  jurisdictionalAgency?: string | null,
  intervalInWords?: string | null,
  parentStatute?: ParentStatute | null,
  jurisdictionalState?: string | null,
  jurisdictionalCounties?: Array< string | null > | null,
  title?: string | null,
  portals?: Array< LinkInput | null > | null,
  jurisdictionalTownship?: string | null,
  jurisdictionalTribalArea?: string | null,
  jurisdictionalCity?: string | null,
  formExpires?: string | null,
  forms?: Array< LinkInput | null > | null,
  fillableLinks?: Array< string | null > | null,
  otherLinks?: Array< LinkInput | null > | null,
  instructionFileName?: string | null,
  instruction?: string | null,
  extraDocumentations?: Array< LinkInput | null > | null,
  department?: string | null,
  subDepartment?: string | null,
  contactEmail?: string | null,
  contactPhone?: string | null,
  contactPhoneExtension?: number | null,
  isExtensionAvailable?: boolean | null,
  extensionNotes?: string | null,
  penalty?: string | null,
  encampInstructions?: string | null,
  isAlwaysApplicable?: boolean | null,
  agencyLevelSearchable?: string | null,
  agencyStateSearchable?: string | null,
  userAgencyTitle?: string | null,
  tasks?: Array< TaskInput | null > | null,
  hyperlinks?: Array< HyperLinkInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteRequirementInput = {
  id: string,
};

export type CreateProductReviewInput = {
  id?: string | null,
  owner?: string | null,
  productId?: string | null,
  alternateId: string,
  sdsExtractionJobId?: string | null,
  isExempt?: boolean | null,
  recommendedUse?: string | null,
  reviewedAt?: string | null,
  reviewerRetoolUsername?: string | null,
  status?: ReviewStatus | null,
  needsReview?: boolean | null,
  isUpdate?: boolean | null,
  sdsFilename?: string | null,
  sdsBoxFileId?: string | null,
  sdsBoxPath?: string | null,
  sdsS3Key?: string | null,
  extractionResultsS3Key?: string | null,
  extractionMessages?: Array< ExtractionMessageInput > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum ReviewStatus {
  REVIEWED = "REVIEWED",
  NOT_STARTED = "NOT_STARTED",
}


export type ExtractionMessageInput = {
  type: ExtractionMessageType,
  message: string,
};

export enum ExtractionMessageType {
  GLOBAL = "GLOBAL",
  HEALTH_HAZARD = "HEALTH_HAZARD",
  PHYSICAL_HAZARD = "PHYSICAL_HAZARD",
}


export type ModelProductReviewConditionInput = {
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  alternateId?: ModelStringInput | null,
  sdsExtractionJobId?: ModelIDInput | null,
  isExempt?: ModelBooleanInput | null,
  recommendedUse?: ModelStringInput | null,
  reviewedAt?: ModelStringInput | null,
  reviewerRetoolUsername?: ModelStringInput | null,
  status?: ModelReviewStatusInput | null,
  needsReview?: ModelBooleanInput | null,
  isUpdate?: ModelBooleanInput | null,
  sdsFilename?: ModelStringInput | null,
  sdsBoxFileId?: ModelStringInput | null,
  sdsBoxPath?: ModelStringInput | null,
  sdsS3Key?: ModelStringInput | null,
  extractionResultsS3Key?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelProductReviewConditionInput | null > | null,
  or?: Array< ModelProductReviewConditionInput | null > | null,
  not?: ModelProductReviewConditionInput | null,
};

export type ModelReviewStatusInput = {
  eq?: ReviewStatus | null,
  ne?: ReviewStatus | null,
};

export type ProductReview = {
  __typename: "ProductReview",
  id?: string,
  owner?: string | null,
  productId?: string | null,
  alternateId?: string,
  sdsExtractionJobId?: string | null,
  isExempt?: boolean | null,
  recommendedUse?: string | null,
  reviewedAt?: string | null,
  reviewerRetoolUsername?: string | null,
  status?: ReviewStatus | null,
  needsReview?: boolean | null,
  isUpdate?: boolean | null,
  fields?:  Array<FieldProposal > | null,
  sdsFilename?: string | null,
  sdsBoxFileId?: string | null,
  sdsBoxPath?: string | null,
  sdsS3Key?: string | null,
  extractionResultsS3Key?: string | null,
  extractionMessages?:  Array<ExtractionMessage > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  sdsExtractionJob?: SdsExtractionJob,
};

export type FieldProposal = TierIIMixtureComponentsFieldProposal | StringFieldProposal | IntFieldProposal | FloatFieldProposal | BooleanFieldProposal


export type TierIIMixtureComponentsFieldProposal = {
  __typename: "TierIIMixtureComponentsFieldProposal",
  type?: FieldType,
  name?: string,
  doNotUpdate?: boolean | null,
  messages?: Array< string > | null,
  oldComponents?:  Array<TierIIMixtureComponent > | null,
  newComponents?:  Array<TierIIMixtureComponent > | null,
  acceptedComponents?:  Array<TierIIMixtureComponent > | null,
};

export type FieldProposalInterface = {
  __typename: "FieldProposalInterface",
  type?: FieldType,
  name?: string,
  doNotUpdate?: boolean | null,
  messages?: Array< string > | null,
};

export type StringFieldProposal = {
  __typename: "StringFieldProposal",
  type?: FieldType,
  name?: string,
  doNotUpdate?: boolean | null,
  messages?: Array< string > | null,
  oldString?: string | null,
  newString?: string | null,
  acceptedString?: string | null,
};

export enum FieldType {
  STRING = "STRING",
  INT = "INT",
  FLOAT = "FLOAT",
  BOOLEAN = "BOOLEAN",
  TIERII_MIXTURE_COMPONENTS = "TIERII_MIXTURE_COMPONENTS",
  PHYSICAL_HAZARD = "PHYSICAL_HAZARD",
  HEALTH_HAZARD = "HEALTH_HAZARD",
}


export type IntFieldProposal = {
  __typename: "IntFieldProposal",
  type?: FieldType,
  name?: string,
  doNotUpdate?: boolean | null,
  messages?: Array< string > | null,
  oldInt?: number | null,
  newInt?: number | null,
  acceptedInt?: number | null,
};

export type FloatFieldProposal = {
  __typename: "FloatFieldProposal",
  type?: FieldType,
  name?: string,
  doNotUpdate?: boolean | null,
  messages?: Array< string > | null,
  oldFloat?: number | null,
  newFloat?: number | null,
  acceptedFloat?: number | null,
};

export type BooleanFieldProposal = {
  __typename: "BooleanFieldProposal",
  type?: FieldType,
  name?: string,
  doNotUpdate?: boolean | null,
  messages?: Array< string > | null,
  oldBoolean?: boolean | null,
  newBoolean?: boolean | null,
  acceptedBoolean?: boolean | null,
};

export type ExtractionMessage = {
  __typename: "ExtractionMessage",
  type?: ExtractionMessageType,
  message?: string,
};

export type SdsExtractionJob = {
  __typename: "SdsExtractionJob",
  id?: string,
  owner?: string | null,
  boxFolderId?: string | null,
  boxFolderPath?: string | null,
  fileCount?: number | null,
  processedFileCount?: number | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateProductReviewInput = {
  id: string,
  owner?: string | null,
  productId?: string | null,
  alternateId?: string | null,
  sdsExtractionJobId?: string | null,
  isExempt?: boolean | null,
  recommendedUse?: string | null,
  reviewedAt?: string | null,
  reviewerRetoolUsername?: string | null,
  status?: ReviewStatus | null,
  needsReview?: boolean | null,
  isUpdate?: boolean | null,
  sdsFilename?: string | null,
  sdsBoxFileId?: string | null,
  sdsBoxPath?: string | null,
  sdsS3Key?: string | null,
  extractionResultsS3Key?: string | null,
  extractionMessages?: Array< ExtractionMessageInput > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteProductReviewInput = {
  id: string,
};

export type CreateSdsExtractionJobInput = {
  id?: string | null,
  owner?: string | null,
  boxFolderId?: string | null,
  boxFolderPath?: string | null,
  fileCount?: number | null,
  processedFileCount?: number | null,
};

export type ModelSdsExtractionJobConditionInput = {
  owner?: ModelIDInput | null,
  boxFolderId?: ModelStringInput | null,
  boxFolderPath?: ModelStringInput | null,
  fileCount?: ModelIntInput | null,
  processedFileCount?: ModelIntInput | null,
  and?: Array< ModelSdsExtractionJobConditionInput | null > | null,
  or?: Array< ModelSdsExtractionJobConditionInput | null > | null,
  not?: ModelSdsExtractionJobConditionInput | null,
};

export type UpdateSdsExtractionJobInput = {
  id: string,
  owner?: string | null,
  boxFolderId?: string | null,
  boxFolderPath?: string | null,
  fileCount?: number | null,
  processedFileCount?: number | null,
};

export type DeleteSdsExtractionJobInput = {
  id: string,
};

export type CreateManagedTaskInput = {
  id?: string | null,
  owner?: string | null,
  watcherId?: string | null,
  startDate: string,
  endDate: string,
  programArea?: string | null,
  requirement: NestedRequirementInput,
  _requirementName: string,
  isOrganizationLevel: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelManagedTaskConditionInput = {
  owner?: ModelIDInput | null,
  watcherId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  programArea?: ModelIDInput | null,
  _requirementName?: ModelStringInput | null,
  isOrganizationLevel?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelManagedTaskConditionInput | null > | null,
  or?: Array< ModelManagedTaskConditionInput | null > | null,
  not?: ModelManagedTaskConditionInput | null,
};

export type UpdateManagedTaskInput = {
  id: string,
  owner?: string | null,
  watcherId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  programArea?: string | null,
  requirement?: NestedRequirementInput | null,
  _requirementName?: string | null,
  isOrganizationLevel?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteManagedTaskInput = {
  id: string,
};

export type CreateFacilityTaskInput = {
  id?: string | null,
  owner?: string | null,
  taskId: string,
  facilityId?: string | null,
  assigneeId?: string | null,
  watcherId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelFacilityTaskConditionInput = {
  owner?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  assigneeId?: ModelIDInput | null,
  watcherId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityTaskConditionInput | null > | null,
  or?: Array< ModelFacilityTaskConditionInput | null > | null,
  not?: ModelFacilityTaskConditionInput | null,
};

export type UpdateFacilityTaskInput = {
  id: string,
  owner?: string | null,
  taskId?: string | null,
  facilityId?: string | null,
  assigneeId?: string | null,
  watcherId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteFacilityTaskInput = {
  id: string,
};

export type CreateTaskDocumentInput = {
  id?: string | null,
  owner?: string | null,
  documentId: string,
  taskId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelTaskDocumentConditionInput = {
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTaskDocumentConditionInput | null > | null,
  or?: Array< ModelTaskDocumentConditionInput | null > | null,
  not?: ModelTaskDocumentConditionInput | null,
};

export type TaskDocument = {
  __typename: "TaskDocument",
  id?: string,
  owner?: string | null,
  documentId?: string,
  taskId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  document?: Document,
  task?: ManagedTask,
};

export type UpdateTaskDocumentInput = {
  id: string,
  owner?: string | null,
  documentId?: string | null,
  taskId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTaskDocumentInput = {
  id: string,
};

export type CreateTenantInput = {
  id?: string | null,
  name: string,
  partnerTenantId?: string | null,
  salesforceId?: string | null,
  accountType?: AccountType | null,
  isDemo?: boolean | null,
  isPartner?: boolean | null,
  isDisabled?: boolean | null,
  externalId?: string | null,
  ssoConfig?: SsoConfigInput | null,
  roleIdToDefaultForNewUsers?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type SsoConfigInput = {
  mappingName: string,
  emailDomains: Array< string >,
};

export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  salesforceId?: ModelStringInput | null,
  accountType?: ModelAccountTypeInput | null,
  isDemo?: ModelBooleanInput | null,
  isPartner?: ModelBooleanInput | null,
  isDisabled?: ModelBooleanInput | null,
  externalId?: ModelStringInput | null,
  roleIdToDefaultForNewUsers?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type ModelAccountTypeInput = {
  eq?: AccountType | null,
  ne?: AccountType | null,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  partnerTenantId?: string | null,
  salesforceId?: string | null,
  accountType?: AccountType | null,
  isDemo?: boolean | null,
  isPartner?: boolean | null,
  isDisabled?: boolean | null,
  externalId?: string | null,
  ssoConfig?: SsoConfigInput | null,
  roleIdToDefaultForNewUsers?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTenantInput = {
  id: string,
};

export type CreateTierIIFacilityProductInput = {
  id?: string | null,
  facilityId: string,
  owner?: string | null,
  productId: string,
  isOverThresholds?: boolean | null,
  isAlwaysReported?: boolean | null,
  maxAmount?: number | null,
  maxAmountCode?: number | null,
  averageAmount?: number | null,
  averageAmountCode?: number | null,
  maxAmountLargestContainer?: number | null,
  daysOnSite?: number | null,
  hasConfidentialStorageLocations?: boolean | null,
  unit?: string | null,
  locations?: Array< TierIIStorageLocationInput | null > | null,
  stateFields?: string | null,
  aggregatedProductIds?: Array< string > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelTierIIFacilityProductConditionInput = {
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  isOverThresholds?: ModelBooleanInput | null,
  isAlwaysReported?: ModelBooleanInput | null,
  maxAmount?: ModelFloatInput | null,
  maxAmountCode?: ModelIntInput | null,
  averageAmount?: ModelFloatInput | null,
  averageAmountCode?: ModelIntInput | null,
  maxAmountLargestContainer?: ModelFloatInput | null,
  daysOnSite?: ModelIntInput | null,
  hasConfidentialStorageLocations?: ModelBooleanInput | null,
  unit?: ModelStringInput | null,
  stateFields?: ModelStringInput | null,
  aggregatedProductIds?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIFacilityProductConditionInput | null > | null,
  or?: Array< ModelTierIIFacilityProductConditionInput | null > | null,
  not?: ModelTierIIFacilityProductConditionInput | null,
};

export type UpdateTierIIFacilityProductInput = {
  id: string,
  facilityId?: string | null,
  owner?: string | null,
  productId?: string | null,
  isOverThresholds?: boolean | null,
  isAlwaysReported?: boolean | null,
  maxAmount?: number | null,
  maxAmountCode?: number | null,
  averageAmount?: number | null,
  averageAmountCode?: number | null,
  maxAmountLargestContainer?: number | null,
  daysOnSite?: number | null,
  hasConfidentialStorageLocations?: boolean | null,
  unit?: string | null,
  locations?: Array< TierIIStorageLocationInput | null > | null,
  stateFields?: string | null,
  aggregatedProductIds?: Array< string > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTierIIFacilityProductInput = {
  id: string,
};

export type CreateTierIIProductInput = {
  id?: string | null,
  owner?: string | null,
  chemName: string,
  casNumber?: string | null,
  noCasNumber?: boolean | null,
  alternateId?: string | null,
  ehs?: boolean | null,
  pure?: boolean | null,
  mixture?: boolean | null,
  solid?: boolean | null,
  liquid?: boolean | null,
  gas?: boolean | null,
  isTradeSecret?: boolean | null,
  density?: number | null,
  densityUnits?: string | null,
  boilingPointFahr?: number | null,
  flashPointFahr?: number | null,
  vaporPressureMmhg?: number | null,
  solubility?: string | null,
  isBattery?: boolean | null,
  isFuel?: boolean | null,
  physicalHazards?: Array< string | null > | null,
  healthHazards?: Array< string | null > | null,
  oregonHazards?: Array< string | null > | null,
  ifcHazards?: Array< string | null > | null,
  noHazardsNotReporting?: boolean | null,
  components?: Array< TierIIMixtureComponentInput | null > | null,
  sdsRecords?: Array< TierIIRecordInput > | null,
  stateFields?: string | null,
  ehsForm?: EhsForm | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type TierIIMixtureComponentInput = {
  componentName?: string | null,
  casNumber?: string | null,
  noCasNumber?: boolean | null,
  ehs?: boolean | null,
  componentPercentage?: number | null,
  weightOrVolume?: string | null,
  ehsForm?: EhsForm | null,
};

export type TierIIRecordInput = {
  at: string,
  key: string,
  kind: TierIIRecordKind,
  name: string,
  associatedDocumentId?: string | null,
};

export type ModelTierIIProductConditionInput = {
  owner?: ModelIDInput | null,
  chemName?: ModelStringInput | null,
  casNumber?: ModelStringInput | null,
  noCasNumber?: ModelBooleanInput | null,
  alternateId?: ModelStringInput | null,
  ehs?: ModelBooleanInput | null,
  pure?: ModelBooleanInput | null,
  mixture?: ModelBooleanInput | null,
  solid?: ModelBooleanInput | null,
  liquid?: ModelBooleanInput | null,
  gas?: ModelBooleanInput | null,
  isTradeSecret?: ModelBooleanInput | null,
  density?: ModelFloatInput | null,
  densityUnits?: ModelStringInput | null,
  boilingPointFahr?: ModelFloatInput | null,
  flashPointFahr?: ModelFloatInput | null,
  vaporPressureMmhg?: ModelFloatInput | null,
  solubility?: ModelStringInput | null,
  isBattery?: ModelBooleanInput | null,
  isFuel?: ModelBooleanInput | null,
  physicalHazards?: ModelStringInput | null,
  healthHazards?: ModelStringInput | null,
  oregonHazards?: ModelStringInput | null,
  ifcHazards?: ModelStringInput | null,
  noHazardsNotReporting?: ModelBooleanInput | null,
  stateFields?: ModelStringInput | null,
  ehsForm?: ModelEhsFormInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductConditionInput | null > | null,
  or?: Array< ModelTierIIProductConditionInput | null > | null,
  not?: ModelTierIIProductConditionInput | null,
};

export type ModelEhsFormInput = {
  eq?: EhsForm | null,
  ne?: EhsForm | null,
};

export type UpdateTierIIProductInput = {
  id: string,
  owner?: string | null,
  chemName?: string | null,
  casNumber?: string | null,
  noCasNumber?: boolean | null,
  alternateId?: string | null,
  ehs?: boolean | null,
  pure?: boolean | null,
  mixture?: boolean | null,
  solid?: boolean | null,
  liquid?: boolean | null,
  gas?: boolean | null,
  isTradeSecret?: boolean | null,
  density?: number | null,
  densityUnits?: string | null,
  boilingPointFahr?: number | null,
  flashPointFahr?: number | null,
  vaporPressureMmhg?: number | null,
  solubility?: string | null,
  isBattery?: boolean | null,
  isFuel?: boolean | null,
  physicalHazards?: Array< string | null > | null,
  healthHazards?: Array< string | null > | null,
  oregonHazards?: Array< string | null > | null,
  ifcHazards?: Array< string | null > | null,
  noHazardsNotReporting?: boolean | null,
  components?: Array< TierIIMixtureComponentInput | null > | null,
  sdsRecords?: Array< TierIIRecordInput > | null,
  stateFields?: string | null,
  ehsForm?: EhsForm | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTierIIProductInput = {
  id: string,
};

export type CreateTierIIProductGroupInput = {
  id?: string | null,
  owner?: string | null,
  name: string,
  groupType?: TierIIProductGroupGroupType | null,
  description: string,
  measurementUnits: string,
  records?: Array< TierIIRecordInput > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelTierIIProductGroupConditionInput = {
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  groupType?: ModelTierIIProductGroupGroupTypeInput | null,
  description?: ModelStringInput | null,
  measurementUnits?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductGroupConditionInput | null > | null,
  or?: Array< ModelTierIIProductGroupConditionInput | null > | null,
  not?: ModelTierIIProductGroupConditionInput | null,
};

export type ModelTierIIProductGroupGroupTypeInput = {
  eq?: TierIIProductGroupGroupType | null,
  ne?: TierIIProductGroupGroupType | null,
};

export type UpdateTierIIProductGroupInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  groupType?: TierIIProductGroupGroupType | null,
  description?: string | null,
  measurementUnits?: string | null,
  records?: Array< TierIIRecordInput > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTierIIProductGroupInput = {
  id: string,
};

export type CreateTierIIProductProductGroupInput = {
  id?: string | null,
  owner?: string | null,
  productGroupId: string,
  productId: string,
  amount?: number | null,
  units?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelTierIIProductProductGroupConditionInput = {
  owner?: ModelIDInput | null,
  productGroupId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  units?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductProductGroupConditionInput | null > | null,
  or?: Array< ModelTierIIProductProductGroupConditionInput | null > | null,
  not?: ModelTierIIProductProductGroupConditionInput | null,
};

export type UpdateTierIIProductProductGroupInput = {
  id: string,
  owner?: string | null,
  productGroupId?: string | null,
  productId?: string | null,
  amount?: number | null,
  units?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTierIIProductProductGroupInput = {
  id: string,
};

export type CreateTierIIProductGroupProductGroupInput = {
  id?: string | null,
  owner?: string | null,
  parentProductGroupId: string,
  childProductGroupId: string,
  amount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelTierIIProductGroupProductGroupConditionInput = {
  owner?: ModelIDInput | null,
  parentProductGroupId?: ModelIDInput | null,
  childProductGroupId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductGroupProductGroupConditionInput | null > | null,
  or?: Array< ModelTierIIProductGroupProductGroupConditionInput | null > | null,
  not?: ModelTierIIProductGroupProductGroupConditionInput | null,
};

export type UpdateTierIIProductGroupProductGroupInput = {
  id: string,
  owner?: string | null,
  parentProductGroupId?: string | null,
  childProductGroupId?: string | null,
  amount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTierIIProductGroupProductGroupInput = {
  id: string,
};

export type CreateTierIIReportInput = {
  id?: string | null,
  owner?: string | null,
  facilityId: string,
  reportingYear: number,
  reportKind: TierIIReportKind,
  organizationStatus: TierIIReportOrganizationStatus,
  encampStatus: TierIIReportEncampStatus,
  staffAssignee?: string | null,
  metadata?: TierIIMetadataInput | null,
  step3Prime?: string | null,
  customerComments?: Array< TierIICommentInput > | null,
  assignees?: Array< TierIIPersonInput > | null,
  basic?: TierIIBasicInformationInput | null,
  regulatory?: TierIIRegulatoryInput | null,
  facilityProductIds?: Array< string > | null,
  notReportingFacilityProductIds?: Array< string > | null,
  facilityContactIds?: Array< string > | null,
  records?: TierIIRecordsInput | null,
  state?: TierIIStateInformationInput | null,
  review?: TierIIReviewInput | null,
  verify?: TierIIVerifyInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type TierIIMetadataInput = {
  comments?: Array< TierIICommentInput > | null,
  submissionMeasures?: Array< TierIISubmissionMeasureInput > | null,
  submissionRecords?: Array< TierIIRecordInput > | null,
  tags?: string | null,
  untouchedSteps?: Array< string > | null,
};

export type TierIISubmissionMeasureInput = {
  name: string,
  description?: string | null,
  startedAt?: string | null,
  completedAt?: string | null,
  records?: Array< TierIIRecordInput > | null,
  notes?: string | null,
};

export type TierIIPersonInput = {
  id: string,
  first: string,
  last: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
};

export type TierIIBasicInformationInput = {
  companyName?: string | null,
  county?: string | null,
  dateClosed?: string | null,
  dbNumber?: string | null,
  department?: string | null,
  facilityName?: string | null,
  facilityPhone?: string | null,
  facilityPhone24Hour?: string | null,
  fireDistrict?: string | null,
  fireDistrictId?: string | null,
  isClosed?: boolean | null,
  isColocated?: boolean | null,
  isInactive?: boolean | null,
  colocationReportingFacilityId?: string | null,
  isMailingSameAsFacility?: boolean | null,
  isUnmanned?: boolean | null,
  latitude?: number | null,
  longitude?: number | null,
  lepc?: string | null,
  lepcId?: string | null,
  mailingAddress?: AddressInput | null,
  maxNumOccupants?: number | null,
  municipality?: string | null,
  naicsCode?: number | null,
  reconciledAt?: string | null,
  streetAddress?: AddressInput | null,
};

export type TierIIRegulatoryInput = {
  isSubjectToChemicalAccidentPrevention?: boolean | null,
  isSubjectToEmergencyPlanning?: boolean | null,
  isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
  reconciledAt?: string | null,
  rmpId?: string | null,
  triId?: string | null,
};

export type TierIIRecordsInput = {
  facilityRecords?: Array< TierIIRecordInput > | null,
};

export type TierIIStateInformationInput = {
  fields?: string | null,
};

export type TierIIReviewInput = {
  reviewers?: Array< TierIIReviewerInput > | null,
  notesToRegulators?: string | null,
  isNotReporting?: boolean | null,
};

export type TierIIReviewerInput = {
  id: string,
  first: string,
  last: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
  isComplete?: boolean | null,
};

export type TierIIVerifyInput = {
  verifier?: TierIIVerifierInput | null,
  verifiedAt?: string | null,
  isVerified?: boolean | null,
};

export type TierIIVerifierInput = {
  id: string,
  first: string,
  last: string,
  title?: string | null,
  areas?: Array< string > | null,
  reconciledAt?: string | null,
  fullName?: string | null,
};

export type ModelTierIIReportConditionInput = {
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  reportingYear?: ModelIntInput | null,
  reportKind?: ModelTierIIReportKindInput | null,
  organizationStatus?: ModelTierIIReportOrganizationStatusInput | null,
  encampStatus?: ModelTierIIReportEncampStatusInput | null,
  staffAssignee?: ModelStringInput | null,
  step3Prime?: ModelStringInput | null,
  facilityProductIds?: ModelIDInput | null,
  notReportingFacilityProductIds?: ModelIDInput | null,
  facilityContactIds?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIReportConditionInput | null > | null,
  or?: Array< ModelTierIIReportConditionInput | null > | null,
  not?: ModelTierIIReportConditionInput | null,
};

export type ModelTierIIReportKindInput = {
  eq?: TierIIReportKind | null,
  ne?: TierIIReportKind | null,
};

export type ModelTierIIReportOrganizationStatusInput = {
  eq?: TierIIReportOrganizationStatus | null,
  ne?: TierIIReportOrganizationStatus | null,
};

export type ModelTierIIReportEncampStatusInput = {
  eq?: TierIIReportEncampStatus | null,
  ne?: TierIIReportEncampStatus | null,
};

export type UpdateTierIIReportInput = {
  id: string,
  owner?: string | null,
  facilityId?: string | null,
  reportingYear?: number | null,
  reportKind?: TierIIReportKind | null,
  organizationStatus?: TierIIReportOrganizationStatus | null,
  encampStatus?: TierIIReportEncampStatus | null,
  staffAssignee?: string | null,
  metadata?: TierIIMetadataInput | null,
  step3Prime?: string | null,
  customerComments?: Array< TierIICommentInput > | null,
  assignees?: Array< TierIIPersonInput > | null,
  basic?: TierIIBasicInformationInput | null,
  regulatory?: TierIIRegulatoryInput | null,
  facilityProductIds?: Array< string > | null,
  notReportingFacilityProductIds?: Array< string > | null,
  facilityContactIds?: Array< string > | null,
  records?: TierIIRecordsInput | null,
  state?: TierIIStateInformationInput | null,
  review?: TierIIReviewInput | null,
  verify?: TierIIVerifyInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteTierIIReportInput = {
  id: string,
};

export type CreateUserTenantInput = {
  id?: string | null,
  tenantId: string,
  email: string,
  groups?: Array< string > | null,
  invitedAt?: string | null,
  invitationExpiresAt?: string | null,
  status: UserTenantStatus,
  personId: string,
  isDisabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelUserTenantConditionInput = {
  email?: ModelStringInput | null,
  invitedAt?: ModelStringInput | null,
  invitationExpiresAt?: ModelStringInput | null,
  status?: ModelUserTenantStatusInput | null,
  personId?: ModelIDInput | null,
  isDisabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserTenantConditionInput | null > | null,
  or?: Array< ModelUserTenantConditionInput | null > | null,
  not?: ModelUserTenantConditionInput | null,
};

export type ModelUserTenantStatusInput = {
  eq?: UserTenantStatus | null,
  ne?: UserTenantStatus | null,
};

export type UpdateUserTenantInput = {
  id: string,
  tenantId?: string | null,
  email?: string | null,
  groups?: Array< string > | null,
  invitedAt?: string | null,
  invitationExpiresAt?: string | null,
  status?: UserTenantStatus | null,
  personId?: string | null,
  isDisabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type CreateUserTenantRoleInput = {
  id?: string | null,
  owner: string,
  userTenantId: string,
  roleId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelUserTenantRoleConditionInput = {
  owner?: ModelIDInput | null,
  userTenantId?: ModelIDInput | null,
  roleId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserTenantRoleConditionInput | null > | null,
  or?: Array< ModelUserTenantRoleConditionInput | null > | null,
  not?: ModelUserTenantRoleConditionInput | null,
};

export type UpdateUserTenantRoleInput = {
  id: string,
  owner?: string | null,
  userTenantId?: string | null,
  roleId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DocumentDownloadLinkResponse = {
  __typename: "DocumentDownloadLinkResponse",
  headResponse?: string | null,
  signedUrl?: string | null,
};

export type DocumentImportInput = {
  title?: string | null,
  description?: string | null,
  fileExtension?: string | null,
  documentType?: string | null,
  sourceLink?: string | null,
  storageLink?: string | null,
  area?: string | null,
  programAreas?: Array< string | null > | null,
  agencyId?: string | null,
  state?: string | null,
  owner?: string | null,
  createdBy?: string | null,
  facilityId?: string | null,
  relatedEntityIds?: Array< DocumentRelatedEntityIdInput | null > | null,
  tags?: string | null,
};

export type DocumentImportResponse = {
  __typename: "DocumentImportResponse",
  document?: Document,
};

export type OnboardFacilityImportResponse = {
  __typename: "OnboardFacilityImportResponse",
  facilities?:  Array<OnboardFacilityImportResponseFacility | null > | null,
  conversionErrors?: Array< string | null > | null,
  duplicateFacilities?:  Array<OnboardFacilityImportResponseFacility | null > | null,
  csvError?: string | null,
};

export type OnboardFacilityImportResponseFacility = {
  __typename: "OnboardFacilityImportResponseFacility",
  id?: string | null,
  name?: string | null,
  businessUnit?: string | null,
  customerFacilityId?: string | null,
  streetAddress?: Address,
  county?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  alternateIds?:  Array<FacilityAlternateId > | null,
  status?: string | null,
  searchableStatus?: string | null,
};

export type GetDistrictInformationInput = {
  state: string,
  county?: string | null,
  municipality?: string | null,
};

export type GetDistrictInformationResponse = {
  __typename: "GetDistrictInformationResponse",
  counties?: Array< string > | null,
  lepcs?:  Array<LepcOption > | null,
  fire?:  Array<FireDepartmentOption > | null,
  municipalities?:  Array<MunicipalityOption > | null,
};

export type LepcOption = {
  __typename: "LepcOption",
  id?: string | null,
  deactivated?: boolean | null,
  label?: string,
  value?: string,
  mailingAddress?: string | null,
  mailingAddressLine2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  phone?: string | null,
  email?: string | null,
};

export type FireDepartmentOption = {
  __typename: "FireDepartmentOption",
  id?: string | null,
  label?: string,
  value?: string,
  mailingAddress?: string | null,
  mailingAddressLine2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  phone?: string | null,
  email?: string | null,
};

export type MunicipalityOption = {
  __typename: "MunicipalityOption",
  label?: string,
  value?: string,
  state?: string | null,
};

export type ListEventActivityLogsInput = {
  sequenceId: string,
  deadline: string,
};

export type ListEventActivityLogsOutput = {
  __typename: "ListEventActivityLogsOutput",
  directLogs?:  Array<SystemAuditLog | null > | null,
  relatedLogs?:  Array<SystemAuditLog | null > | null,
};

export type SystemAuditLog = {
  __typename: "SystemAuditLog",
  id?: string,
  owner?: string,
  typeId?: string,
  typeName?: string,
  mutatedAt?: string,
  mutatedBy?: string | null,
  oldValue?: string | null,
  newValue?: string | null,
  summary?: string | null,
  oldArchiveId?: string | null,
  newArchiveId?: string | null,
  eventId?: string | null,
  eventSequenceId?: string | null,
  facilityId?: string | null,
  personId?: string | null,
  seriesId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ComplianceEngineInput = {
  event: string,
  includeDraftedDefinitions?: boolean | null,
  targetType: string,
  validateByItem?: ValidateByItemInput | null,
  getComplianceDefinition?: KeyInput | null,
  getComplianceDefinitionByItem?: ValidateByItemInput | null,
  isSubjectToEmergencyPlanning?: SubjectToEmergencyPlanningInput | null,
};

export type ValidateByItemInput = {
  itemId: string,
  itemType: string,
};

export type KeyInput = {
  key: string,
};

export type SubjectToEmergencyPlanningInput = {
  ownerId: string,
  facilityId: string,
  reportingYear?: number | null,
  reportingKind?: string | null,
};

export type ComplianceEngineOutput = {
  __typename: "ComplianceEngineOutput",
  validation?: string | null,
  complianceDefinitions?:  Array<ComplianceDefinitionOutput | null > | null,
  isSubjectToEmergencyPlanning?: boolean | null,
};

export type ComplianceDefinitionOutput = {
  __typename: "ComplianceDefinitionOutput",
  id?: string | null,
  targetType?: string | null,
  key?: string | null,
  steps?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type PublicTenant = {
  __typename: "PublicTenant",
  id?: string,
  ssoConfig?: SsoConfig,
};

export type GetDocumentsInput = {
  docIds?: Array< string | null > | null,
};

export type GetDocumentsResponse = {
  __typename: "GetDocumentsResponse",
  documents?:  Array<Document | null > | null,
};

export type SearchableMinimalFacilityFilterInput = {
  id?: CopySearchableIDFilterInput | null,
  name?: CopySearchableStringFilterInput | null,
  state?: CopySearchableStringFilterInput | null,
  county?: CopySearchableStringFilterInput | null,
  municipality?: CopySearchableStringFilterInput | null,
  and?: Array< SearchableMinimalFacilityFilterInput | null > | null,
  or?: Array< SearchableMinimalFacilityFilterInput | null > | null,
  not?: SearchableMinimalFacilityFilterInput | null,
};

export type CopySearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type CopySearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableMinimalFacilitySortInput = {
  field?: SearchableMinimalFacilitySortableFields | null,
  direction?: CopySearchableSortDirection | null,
};

export enum SearchableMinimalFacilitySortableFields {
  name = "name",
  state = "state",
  county = "county",
  municipality = "municipality",
}


export enum CopySearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type MinimalFacilityPaginator = {
  __typename: "MinimalFacilityPaginator",
  items?:  Array<MinimalFacility | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type WasteDataInput = {
  event: string,
  id?: string | null,
  csvFileKey?: string | null,
  owner?: string | null,
  user?: string | null,
  query?: string | null,
  wastestream?: string | null,
  wasteGenerationEvent?: string | null,
  wasteManifest?: string | null,
  wasteManifestLineItem?: string | null,
  wasteContainer?: string | null,
  wasteContainerGroups?: string | null,
  typeFilter?: string | null,
  rcraCode?: string | null,
  targetMonth?: string | null,
  pagination?: string | null,
  sort?: string | null,
  searchText?: string | null,
  facilities?: Array< string > | null,
};

export type WasteDataOutput = {
  __typename: "WasteDataOutput",
  id?: string | null,
  success?: boolean,
  data?: string | null,
  count?: number,
};

export type listTierIIFacilityProductsByFacilityReportingYearInput = {
  productId?: string | null,
  facilityId: string,
  reportingYear: number,
};

export type listTierIIFacilityProductsByFacilityReportingYearOutput = {
  __typename: "listTierIIFacilityProductsByFacilityReportingYearOutput",
  facilityProducts?:  Array<TierIIFacilityProduct >,
};

export type GetOneSchemaTemplateInput = {
  templateType: string,
};

export type ModelNoteFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  noteBy?: ModelStringInput | null,
  at?: ModelStringInput | null,
  key?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNoteFilterInput | null > | null,
  or?: Array< ModelNoteFilterInput | null > | null,
  not?: ModelNoteFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelDraftFilterInput = {
  id?: ModelIDInput | null,
  draftSource?: ModelStringInput | null,
  draftProcessGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDraftFilterInput | null > | null,
  or?: Array< ModelDraftFilterInput | null > | null,
  not?: ModelDraftFilterInput | null,
};

export type ModelDraftConnection = {
  __typename: "ModelDraftConnection",
  items?:  Array<Draft | null > | null,
  nextToken?: string | null,
};

export type Draft = {
  __typename: "Draft",
  id?: string,
  draftMetadata?: DraftMetadata,
  draftSource?: string,
  draftProcessGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DraftMetadata = {
  __typename: "DraftMetadata",
  approved?: boolean,
  approvedAt?: string | null,
  autoApproved?: boolean,
  rejectedAt?: string | null,
  reviewer?: string | null,
  automationAccuracy?: number | null,
  confidenceRating?: number | null,
  relatedS3Paths?:  Array<DraftS3Path | null > | null,
  additionalMetadata?: string | null,
};

export type DraftS3Path = {
  __typename: "DraftS3Path",
  path?: string | null,
  type?: string | null,
};

export type ModelRequestFilterInput = {
  id?: ModelIDInput | null,
  batchId?: ModelStringInput | null,
  message?: ModelStringInput | null,
  status?: ModelRequestStatusInput | null,
  source?: ModelStringInput | null,
  destination?: ModelStringInput | null,
  queueUrl?: ModelStringInput | null,
  response?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRequestFilterInput | null > | null,
  or?: Array< ModelRequestFilterInput | null > | null,
  not?: ModelRequestFilterInput | null,
};

export type ModelRequestStatusInput = {
  eq?: RequestStatus | null,
  ne?: RequestStatus | null,
};

export enum RequestStatus {
  INITIALIZED = "INITIALIZED",
  QUEUED = "QUEUED",
  RECEIVED = "RECEIVED",
  UPDATED = "UPDATED",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}


export type ModelRequestConnection = {
  __typename: "ModelRequestConnection",
  items?:  Array<Request | null > | null,
  nextToken?: string | null,
};

export type Request = {
  __typename: "Request",
  id?: string,
  batchId?: string,
  message?: string,
  status?: RequestStatus,
  source?: string,
  destination?: string,
  queueUrl?: string,
  history?:  Array<StatusHistory | null > | null,
  response?: string | null,
  createdAt?: string,
  updatedAt?: string,
  updatedBy?: string | null,
};

export type StatusHistory = {
  __typename: "StatusHistory",
  time?: string,
  status?: RequestStatus,
};

export type MetatronFacility = {
  __typename: "MetatronFacility",
  id?: string,
  frsId?: string,
  facName?: string | null,
  facStreet?: string | null,
  facCity?: string | null,
  facState?: string | null,
  facZip?: string | null,
  facCounty?: string | null,
  facLat?: string | null,
  facLong?: string | null,
  rcraFlag?: string | null,
  rcraIds?: string | null,
  rcraPermitTypes?: string | null,
  rcraNaics?: string | null,
  airFlag?: string | null,
  airIds?: string | null,
  caaPermitTypes?: string | null,
  caaNaics?: string | null,
  npdesFlag?: string | null,
  npdesIds?: string | null,
  triFlag?: string | null,
  triIds?: string | null,
  ghgFlag?: string | null,
  ghgIds?: string | null,
  organizations?:  Array<MetatronFacilityOrganization | null > | null,
  contacts?:  Array<MetatronFacilityContact | null > | null,
  combinedText?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type MetatronFacilityOrganization = {
  __typename: "MetatronFacilityOrganization",
  name?: string,
  pgmSysAcrnm?: string,
  pgmSysId?: string,
  email?: string | null,
  affiliationType?: string | null,
};

export type MetatronFacilityContact = {
  __typename: "MetatronFacilityContact",
  pgmSysAcrnm?: string,
  pgmSysId?: string,
  email?: string | null,
  affiliationType?: string | null,
};

export type ModelMetatronFacilityFilterInput = {
  id?: ModelIDInput | null,
  frsId?: ModelStringInput | null,
  facName?: ModelStringInput | null,
  facStreet?: ModelStringInput | null,
  facCity?: ModelStringInput | null,
  facState?: ModelStringInput | null,
  facZip?: ModelStringInput | null,
  facCounty?: ModelStringInput | null,
  facLat?: ModelStringInput | null,
  facLong?: ModelStringInput | null,
  rcraFlag?: ModelStringInput | null,
  rcraIds?: ModelStringInput | null,
  rcraPermitTypes?: ModelStringInput | null,
  rcraNaics?: ModelStringInput | null,
  airFlag?: ModelStringInput | null,
  airIds?: ModelStringInput | null,
  caaPermitTypes?: ModelStringInput | null,
  caaNaics?: ModelStringInput | null,
  npdesFlag?: ModelStringInput | null,
  npdesIds?: ModelStringInput | null,
  triFlag?: ModelStringInput | null,
  triIds?: ModelStringInput | null,
  ghgFlag?: ModelStringInput | null,
  ghgIds?: ModelStringInput | null,
  combinedText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMetatronFacilityFilterInput | null > | null,
  or?: Array< ModelMetatronFacilityFilterInput | null > | null,
  not?: ModelMetatronFacilityFilterInput | null,
};

export type ModelMetatronFacilityConnection = {
  __typename: "ModelMetatronFacilityConnection",
  items?:  Array<MetatronFacility | null > | null,
  nextToken?: string | null,
};

export type SearchableMetatronFacilityFilterInput = {
  id?: SearchableIDFilterInput | null,
  frsId?: SearchableStringFilterInput | null,
  facName?: SearchableStringFilterInput | null,
  facStreet?: SearchableStringFilterInput | null,
  facCity?: SearchableStringFilterInput | null,
  facState?: SearchableStringFilterInput | null,
  facZip?: SearchableStringFilterInput | null,
  facCounty?: SearchableStringFilterInput | null,
  facLat?: SearchableStringFilterInput | null,
  facLong?: SearchableStringFilterInput | null,
  rcraFlag?: SearchableStringFilterInput | null,
  rcraIds?: SearchableStringFilterInput | null,
  rcraPermitTypes?: SearchableStringFilterInput | null,
  rcraNaics?: SearchableStringFilterInput | null,
  airFlag?: SearchableStringFilterInput | null,
  airIds?: SearchableStringFilterInput | null,
  caaPermitTypes?: SearchableStringFilterInput | null,
  caaNaics?: SearchableStringFilterInput | null,
  npdesFlag?: SearchableStringFilterInput | null,
  npdesIds?: SearchableStringFilterInput | null,
  triFlag?: SearchableStringFilterInput | null,
  triIds?: SearchableStringFilterInput | null,
  ghgFlag?: SearchableStringFilterInput | null,
  ghgIds?: SearchableStringFilterInput | null,
  combinedText?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableMetatronFacilityFilterInput | null > | null,
  or?: Array< SearchableMetatronFacilityFilterInput | null > | null,
  not?: SearchableMetatronFacilityFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableMetatronFacilitySortInput = {
  field?: SearchableMetatronFacilitySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMetatronFacilitySortableFields {
  id = "id",
  frsId = "frsId",
  facName = "facName",
  facStreet = "facStreet",
  facCity = "facCity",
  facState = "facState",
  facZip = "facZip",
  facCounty = "facCounty",
  facLat = "facLat",
  facLong = "facLong",
  rcraFlag = "rcraFlag",
  rcraIds = "rcraIds",
  rcraPermitTypes = "rcraPermitTypes",
  rcraNaics = "rcraNaics",
  airFlag = "airFlag",
  airIds = "airIds",
  caaPermitTypes = "caaPermitTypes",
  caaNaics = "caaNaics",
  npdesFlag = "npdesFlag",
  npdesIds = "npdesIds",
  triFlag = "triFlag",
  triIds = "triIds",
  ghgFlag = "ghgFlag",
  ghgIds = "ghgIds",
  combinedText = "combinedText",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableMetatronFacilityConnection = {
  __typename: "SearchableMetatronFacilityConnection",
  items?:  Array<MetatronFacility | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelAgencyFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  level?: ModelAgencyLevelInput | null,
  jurisdiction?: ModelStringInput | null,
  state?: ModelStateOrTerritoryInput | null,
  locale?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelAgencyFilterInput | null > | null,
  or?: Array< ModelAgencyFilterInput | null > | null,
  not?: ModelAgencyFilterInput | null,
};

export type ModelAgencyLevelInput = {
  eq?: AgencyLevel | null,
  ne?: AgencyLevel | null,
};

export type ModelStateOrTerritoryInput = {
  eq?: StateOrTerritory | null,
  ne?: StateOrTerritory | null,
};

export type ModelAgencyConnection = {
  __typename: "ModelAgencyConnection",
  items?:  Array<Agency | null > | null,
  nextToken?: string | null,
};

export type ModelSystemAuditLogFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  typeId?: ModelIDInput | null,
  typeName?: ModelStringInput | null,
  mutatedAt?: ModelStringInput | null,
  mutatedBy?: ModelStringInput | null,
  oldValue?: ModelStringInput | null,
  newValue?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  oldArchiveId?: ModelStringInput | null,
  newArchiveId?: ModelStringInput | null,
  eventId?: ModelStringInput | null,
  eventSequenceId?: ModelStringInput | null,
  facilityId?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  seriesId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelSystemAuditLogFilterInput | null > | null,
  or?: Array< ModelSystemAuditLogFilterInput | null > | null,
  not?: ModelSystemAuditLogFilterInput | null,
};

export type ModelSystemAuditLogConnection = {
  __typename: "ModelSystemAuditLogConnection",
  items?:  Array<SystemAuditLog | null > | null,
  nextToken?: string | null,
};

export type ModelSystemAuditLogByTypeNameCompositeKeyConditionInput = {
  eq?: ModelSystemAuditLogByTypeNameCompositeKeyInput | null,
  le?: ModelSystemAuditLogByTypeNameCompositeKeyInput | null,
  lt?: ModelSystemAuditLogByTypeNameCompositeKeyInput | null,
  ge?: ModelSystemAuditLogByTypeNameCompositeKeyInput | null,
  gt?: ModelSystemAuditLogByTypeNameCompositeKeyInput | null,
  between?: Array< ModelSystemAuditLogByTypeNameCompositeKeyInput | null > | null,
  beginsWith?: ModelSystemAuditLogByTypeNameCompositeKeyInput | null,
};

export type ModelSystemAuditLogByTypeNameCompositeKeyInput = {
  typeName?: string | null,
  mutatedAt?: string | null,
};

export type ModelEventDocumentFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventDocumentFilterInput | null > | null,
  or?: Array< ModelEventDocumentFilterInput | null > | null,
  not?: ModelEventDocumentFilterInput | null,
};

export type ModelEventWatcherFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  deadline?: ModelStringInput | null,
  seriesId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventWatcherFilterInput | null > | null,
  or?: Array< ModelEventWatcherFilterInput | null > | null,
  not?: ModelEventWatcherFilterInput | null,
};

export type ModelChargeFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  module?: ModelChargeModuleInput | null,
  reportingYear?: ModelIntInput | null,
  date?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  type?: ModelChargeTypeInput | null,
  memo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelChargeFilterInput | null > | null,
  or?: Array< ModelChargeFilterInput | null > | null,
  not?: ModelChargeFilterInput | null,
};

export type ModelChargeConnection = {
  __typename: "ModelChargeConnection",
  items?:  Array<Charge | null > | null,
  nextToken?: string | null,
};

export type SearchableChargeFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  facilityId?: SearchableIDFilterInput | null,
  reportingYear?: SearchableIntFilterInput | null,
  date?: SearchableStringFilterInput | null,
  amount?: SearchableFloatFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableChargeFilterInput | null > | null,
  or?: Array< SearchableChargeFilterInput | null > | null,
  not?: SearchableChargeFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableChargeSortInput = {
  field?: SearchableChargeSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableChargeSortableFields {
  id = "id",
  owner = "owner",
  facilityId = "facilityId",
  reportingYear = "reportingYear",
  date = "date",
  amount = "amount",
  memo = "memo",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableChargeConnection = {
  __typename: "SearchableChargeConnection",
  items?:  Array<Charge | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelComputedFilterInput = {
  id?: ModelIDInput | null,
  typeId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  kind?: ModelComputationKindInput | null,
  typeName?: ModelStringInput | null,
  computed?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelComputedFilterInput | null > | null,
  or?: Array< ModelComputedFilterInput | null > | null,
  not?: ModelComputedFilterInput | null,
};

export type ModelComputationKindInput = {
  eq?: ComputationKind | null,
  ne?: ComputationKind | null,
};

export type ModelCredentialFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  password?: ModelStringInput | null,
  kind?: ModelCredentialKindInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  verificationStatus?: ModelCredentialVerificationStatusInput | null,
  lastVerificationSuccessAt?: ModelStringInput | null,
  lastVerificationCheckAt?: ModelStringInput | null,
  and?: Array< ModelCredentialFilterInput | null > | null,
  or?: Array< ModelCredentialFilterInput | null > | null,
  not?: ModelCredentialFilterInput | null,
};

export type ModelCredentialConnection = {
  __typename: "ModelCredentialConnection",
  items?:  Array<Credential | null > | null,
  nextToken?: string | null,
};

export type ModelCredentialPoolFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCredentialPoolFilterInput | null > | null,
  or?: Array< ModelCredentialPoolFilterInput | null > | null,
  not?: ModelCredentialPoolFilterInput | null,
};

export type ModelCredentialPoolConnection = {
  __typename: "ModelCredentialPoolConnection",
  items?:  Array<CredentialPool | null > | null,
  nextToken?: string | null,
};

export type ModelCredentialPoolCredentialFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  credentialId?: ModelIDInput | null,
  credentialPoolId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCredentialPoolCredentialFilterInput | null > | null,
  or?: Array< ModelCredentialPoolCredentialFilterInput | null > | null,
  not?: ModelCredentialPoolCredentialFilterInput | null,
};

export type ModelDataCollectionTaskFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  dataCollectionTaskTemplateId?: ModelIDInput | null,
  userTenantId?: ModelIDInput | null,
  createdByUserTenantId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  status?: ModelDataCollectionTaskStatusEnumInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDataCollectionTaskFilterInput | null > | null,
  or?: Array< ModelDataCollectionTaskFilterInput | null > | null,
  not?: ModelDataCollectionTaskFilterInput | null,
};

export type ModelDataCollectionTaskConnection = {
  __typename: "ModelDataCollectionTaskConnection",
  items?:  Array<DataCollectionTask | null > | null,
  nextToken?: string | null,
};

export type ModelDataCollectionTaskTemplateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  productGroupIds?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDataCollectionTaskTemplateFilterInput | null > | null,
  or?: Array< ModelDataCollectionTaskTemplateFilterInput | null > | null,
  not?: ModelDataCollectionTaskTemplateFilterInput | null,
};

export type ModelDataCollectionTaskTemplateConnection = {
  __typename: "ModelDataCollectionTaskTemplateConnection",
  items?:  Array<DataCollectionTaskTemplate | null > | null,
  nextToken?: string | null,
};

export type ModelDocumentRequirementFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentRequirementFilterInput | null > | null,
  or?: Array< ModelDocumentRequirementFilterInput | null > | null,
  not?: ModelDocumentRequirementFilterInput | null,
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  fileExtension?: ModelStringInput | null,
  state?: ModelStringInput | null,
  area?: ModelIDInput | null,
  programAreas?: ModelIDInput | null,
  documentType?: ModelStringInput | null,
  sourceLink?: ModelStringInput | null,
  storageLink?: ModelStringInput | null,
  agencyId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  lobId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  stateSearchable?: ModelStringInput | null,
  areaSearchable?: ModelStringInput | null,
  searchableProgramAreas?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items?:  Array<Document | null > | null,
  nextToken?: string | null,
};

export type SearchableDocumentFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  fileExtension?: SearchableStringFilterInput | null,
  state?: SearchableStringFilterInput | null,
  area?: SearchableIDFilterInput | null,
  programAreas?: SearchableIDFilterInput | null,
  documentType?: SearchableStringFilterInput | null,
  sourceLink?: SearchableStringFilterInput | null,
  storageLink?: SearchableStringFilterInput | null,
  agencyId?: SearchableIDFilterInput | null,
  facilityId?: SearchableIDFilterInput | null,
  lobId?: SearchableStringFilterInput | null,
  createdBy?: SearchableStringFilterInput | null,
  stateSearchable?: SearchableStringFilterInput | null,
  areaSearchable?: SearchableStringFilterInput | null,
  searchableProgramAreas?: SearchableStringFilterInput | null,
  tags?: SearchableStringFilterInput | null,
  isArchived?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableDocumentFilterInput | null > | null,
  or?: Array< SearchableDocumentFilterInput | null > | null,
  not?: SearchableDocumentFilterInput | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableDocumentSortInput = {
  field?: SearchableDocumentSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDocumentSortableFields {
  id = "id",
  owner = "owner",
  title = "title",
  description = "description",
  fileExtension = "fileExtension",
  state = "state",
  area = "area",
  programAreas = "programAreas",
  documentType = "documentType",
  sourceLink = "sourceLink",
  storageLink = "storageLink",
  agencyId = "agencyId",
  facilityId = "facilityId",
  lobId = "lobId",
  createdBy = "createdBy",
  stateSearchable = "stateSearchable",
  areaSearchable = "areaSearchable",
  searchableProgramAreas = "searchableProgramAreas",
  tags = "tags",
  isArchived = "isArchived",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableDocumentConnection = {
  __typename: "SearchableDocumentConnection",
  items?:  Array<Document | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelEpcra311TriggerFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  description?: ModelStringInput | null,
  triggeredAt?: ModelStringInput | null,
  deadlineAt?: ModelStringInput | null,
  isReported?: ModelBooleanInput | null,
  isRequestingInformation?: ModelBooleanInput | null,
  facilityId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEpcra311TriggerFilterInput | null > | null,
  or?: Array< ModelEpcra311TriggerFilterInput | null > | null,
  not?: ModelEpcra311TriggerFilterInput | null,
};

export type ModelEpcra311TriggerConnection = {
  __typename: "ModelEpcra311TriggerConnection",
  items?:  Array<Epcra311Trigger | null > | null,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  _requirementName?: ModelStringInput | null,
  _requirementArea?: ModelStringInput | null,
  facilityId?: ModelIDInput | null,
  eventResponsiblePersonId?: ModelIDInput | null,
  completedAt?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  statusSearchable?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  deadline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventByStatusCompositeKeyConditionInput = {
  eq?: ModelEventByStatusCompositeKeyInput | null,
  le?: ModelEventByStatusCompositeKeyInput | null,
  lt?: ModelEventByStatusCompositeKeyInput | null,
  ge?: ModelEventByStatusCompositeKeyInput | null,
  gt?: ModelEventByStatusCompositeKeyInput | null,
  between?: Array< ModelEventByStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelEventByStatusCompositeKeyInput | null,
};

export type ModelEventByStatusCompositeKeyInput = {
  status?: EventStatus | null,
  deadline?: string | null,
};

export type SearchableEventFilterInput = {
  id?: SearchableIDFilterInput | null,
  seriesId?: SearchableIDFilterInput | null,
  requirementId?: SearchableIDFilterInput | null,
  _requirementName?: SearchableStringFilterInput | null,
  _requirementArea?: SearchableStringFilterInput | null,
  facilityId?: SearchableIDFilterInput | null,
  eventResponsiblePersonId?: SearchableIDFilterInput | null,
  completedAt?: SearchableStringFilterInput | null,
  statusSearchable?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  deadline?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableEventFilterInput | null > | null,
  or?: Array< SearchableEventFilterInput | null > | null,
  not?: SearchableEventFilterInput | null,
};

export type SearchableEventSortInput = {
  field?: SearchableEventSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEventSortableFields {
  id = "id",
  seriesId = "seriesId",
  requirementId = "requirementId",
  _requirementName = "_requirementName",
  _requirementArea = "_requirementArea",
  facilityId = "facilityId",
  eventResponsiblePersonId = "eventResponsiblePersonId",
  completedAt = "completedAt",
  statusSearchable = "statusSearchable",
  owner = "owner",
  deadline = "deadline",
  description = "description",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableEventConnection = {
  __typename: "SearchableEventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelEventSequenceFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  type?: ModelEventSequenceTypeInput | null,
  typeSearchable?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  isRecurring?: ModelBooleanInput | null,
  isOrganizationLevel?: ModelBooleanInput | null,
  exceptions?: ModelStringInput | null,
  _requirementName?: ModelStringInput | null,
  _requirementArea?: ModelStringInput | null,
  facilityId?: ModelIDInput | null,
  state?: ModelStringInput | null,
  responsiblePersonId?: ModelIDInput | null,
  completedAt?: ModelStringInput | null,
  status?: ModelEventSequenceStatusInput | null,
  statusSearchable?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventSequenceFilterInput | null > | null,
  or?: Array< ModelEventSequenceFilterInput | null > | null,
  not?: ModelEventSequenceFilterInput | null,
};

export type SearchableEventSequenceFilterInput = {
  id?: SearchableIDFilterInput | null,
  parentId?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  seriesId?: SearchableIDFilterInput | null,
  taskId?: SearchableIDFilterInput | null,
  typeSearchable?: SearchableStringFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  isRecurring?: SearchableBooleanFilterInput | null,
  isOrganizationLevel?: SearchableBooleanFilterInput | null,
  exceptions?: SearchableStringFilterInput | null,
  _requirementName?: SearchableStringFilterInput | null,
  _requirementArea?: SearchableStringFilterInput | null,
  facilityId?: SearchableIDFilterInput | null,
  state?: SearchableStringFilterInput | null,
  responsiblePersonId?: SearchableIDFilterInput | null,
  completedAt?: SearchableStringFilterInput | null,
  statusSearchable?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableEventSequenceFilterInput | null > | null,
  or?: Array< SearchableEventSequenceFilterInput | null > | null,
  not?: SearchableEventSequenceFilterInput | null,
};

export type SearchableEventSequenceSortInput = {
  field?: SearchableEventSequenceSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEventSequenceSortableFields {
  id = "id",
  parentId = "parentId",
  owner = "owner",
  seriesId = "seriesId",
  taskId = "taskId",
  typeSearchable = "typeSearchable",
  startDate = "startDate",
  endDate = "endDate",
  isRecurring = "isRecurring",
  isOrganizationLevel = "isOrganizationLevel",
  exceptions = "exceptions",
  _requirementName = "_requirementName",
  _requirementArea = "_requirementArea",
  facilityId = "facilityId",
  state = "state",
  responsiblePersonId = "responsiblePersonId",
  completedAt = "completedAt",
  statusSearchable = "statusSearchable",
  description = "description",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableEventSequenceConnection = {
  __typename: "SearchableEventSequenceConnection",
  items?:  Array<EventSequence | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelEventSequenceDocumentFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  eventSequenceId?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventSequenceDocumentFilterInput | null > | null,
  or?: Array< ModelEventSequenceDocumentFilterInput | null > | null,
  not?: ModelEventSequenceDocumentFilterInput | null,
};

export type ModelEventSequenceWatcherFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  eventSequenceId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  seriesId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelEventSequenceWatcherFilterInput | null > | null,
  or?: Array< ModelEventSequenceWatcherFilterInput | null > | null,
  not?: ModelEventSequenceWatcherFilterInput | null,
};

export type ModelFacilityFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelFacilityStatusInput | null,
  statusReason?: ModelStringInput | null,
  epaGeneratorStatus?: ModelRcraGeneratorStatusInput | null,
  epaGeneratorStatusUpdatedAt?: ModelStringInput | null,
  epaGeneratorStatusUpdatedBy?: ModelStringInput | null,
  stateGeneratorStatus?: ModelRcraGeneratorStatusInput | null,
  stateGeneratorStatusUpdatedAt?: ModelStringInput | null,
  stateGeneratorStatusUpdatedBy?: ModelStringInput | null,
  businessUnit?: ModelStringInput | null,
  customerFacilityId?: ModelStringInput | null,
  dbNumber?: ModelStringInput | null,
  facilityType?: ModelStringInput | null,
  naicsCode?: ModelIntInput | null,
  maxNumOccupants?: ModelIntInput | null,
  isUnmanned?: ModelBooleanInput | null,
  phone?: ModelStringInput | null,
  phone24Hour?: ModelStringInput | null,
  state?: ModelStringInput | null,
  county?: ModelStringInput | null,
  municipality?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  isAutoGeocoded?: ModelBooleanInput | null,
  lepc?: ModelStringInput | null,
  lepcId?: ModelStringInput | null,
  fireDistrict?: ModelStringInput | null,
  fireDistrictId?: ModelStringInput | null,
  programAreas?: ModelIDInput | null,
  dateOpened?: ModelStringInput | null,
  dateClosed?: ModelStringInput | null,
  dateInactive?: ModelStringInput | null,
  federalFields?: ModelStringInput | null,
  stateFields?: ModelStringInput | null,
  searchableProgramAreas?: ModelStringInput | null,
  searchableStatus?: ModelStringInput | null,
  searchableCity?: ModelStringInput | null,
  lastProductMeasuredAt?: ModelStringInput | null,
  streetAddressVerifiedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityFilterInput | null > | null,
  or?: Array< ModelFacilityFilterInput | null > | null,
  not?: ModelFacilityFilterInput | null,
};

export type ModelFacilityConnection = {
  __typename: "ModelFacilityConnection",
  items?:  Array<Facility | null > | null,
  nextToken?: string | null,
};

export type SearchableFacilityFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  statusReason?: SearchableStringFilterInput | null,
  epaGeneratorStatusUpdatedAt?: SearchableStringFilterInput | null,
  epaGeneratorStatusUpdatedBy?: SearchableStringFilterInput | null,
  stateGeneratorStatusUpdatedAt?: SearchableStringFilterInput | null,
  stateGeneratorStatusUpdatedBy?: SearchableStringFilterInput | null,
  businessUnit?: SearchableStringFilterInput | null,
  customerFacilityId?: SearchableStringFilterInput | null,
  dbNumber?: SearchableStringFilterInput | null,
  facilityType?: SearchableStringFilterInput | null,
  naicsCode?: SearchableIntFilterInput | null,
  maxNumOccupants?: SearchableIntFilterInput | null,
  isUnmanned?: SearchableBooleanFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  phone24Hour?: SearchableStringFilterInput | null,
  state?: SearchableStringFilterInput | null,
  county?: SearchableStringFilterInput | null,
  municipality?: SearchableStringFilterInput | null,
  latitude?: SearchableFloatFilterInput | null,
  longitude?: SearchableFloatFilterInput | null,
  isAutoGeocoded?: SearchableBooleanFilterInput | null,
  lepc?: SearchableStringFilterInput | null,
  lepcId?: SearchableStringFilterInput | null,
  fireDistrict?: SearchableStringFilterInput | null,
  fireDistrictId?: SearchableStringFilterInput | null,
  programAreas?: SearchableIDFilterInput | null,
  dateOpened?: SearchableStringFilterInput | null,
  dateClosed?: SearchableStringFilterInput | null,
  dateInactive?: SearchableStringFilterInput | null,
  federalFields?: SearchableStringFilterInput | null,
  stateFields?: SearchableStringFilterInput | null,
  searchableProgramAreas?: SearchableStringFilterInput | null,
  searchableStatus?: SearchableStringFilterInput | null,
  searchableCity?: SearchableStringFilterInput | null,
  lastProductMeasuredAt?: SearchableStringFilterInput | null,
  streetAddressVerifiedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableFacilityFilterInput | null > | null,
  or?: Array< SearchableFacilityFilterInput | null > | null,
  not?: SearchableFacilityFilterInput | null,
};

export type SearchableFacilitySortInput = {
  field?: SearchableFacilitySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFacilitySortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  statusReason = "statusReason",
  epaGeneratorStatusUpdatedAt = "epaGeneratorStatusUpdatedAt",
  epaGeneratorStatusUpdatedBy = "epaGeneratorStatusUpdatedBy",
  stateGeneratorStatusUpdatedAt = "stateGeneratorStatusUpdatedAt",
  stateGeneratorStatusUpdatedBy = "stateGeneratorStatusUpdatedBy",
  businessUnit = "businessUnit",
  customerFacilityId = "customerFacilityId",
  dbNumber = "dbNumber",
  facilityType = "facilityType",
  naicsCode = "naicsCode",
  maxNumOccupants = "maxNumOccupants",
  isUnmanned = "isUnmanned",
  phone = "phone",
  phone24Hour = "phone24Hour",
  state = "state",
  county = "county",
  municipality = "municipality",
  latitude = "latitude",
  longitude = "longitude",
  isAutoGeocoded = "isAutoGeocoded",
  lepc = "lepc",
  lepcId = "lepcId",
  fireDistrict = "fireDistrict",
  fireDistrictId = "fireDistrictId",
  programAreas = "programAreas",
  dateOpened = "dateOpened",
  dateClosed = "dateClosed",
  dateInactive = "dateInactive",
  federalFields = "federalFields",
  stateFields = "stateFields",
  searchableProgramAreas = "searchableProgramAreas",
  searchableStatus = "searchableStatus",
  searchableCity = "searchableCity",
  lastProductMeasuredAt = "lastProductMeasuredAt",
  streetAddressVerifiedAt = "streetAddressVerifiedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableFacilityConnection = {
  __typename: "SearchableFacilityConnection",
  items?:  Array<Facility | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFacilityContactFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  areas?: ModelIDInput | null,
  reportingRoles?: ModelContactReportRoleListInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityContactFilterInput | null > | null,
  or?: Array< ModelFacilityContactFilterInput | null > | null,
  not?: ModelFacilityContactFilterInput | null,
};

export type ModelFacilityCredentialFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  credentialId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityCredentialFilterInput | null > | null,
  or?: Array< ModelFacilityCredentialFilterInput | null > | null,
  not?: ModelFacilityCredentialFilterInput | null,
};

export type ModelFacilityMeasurementFilterInput = {
  id?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  unit?: ModelStringInput | null,
  measuredAt?: ModelStringInput | null,
  stateFields?: ModelStringInput | null,
  and?: Array< ModelFacilityMeasurementFilterInput | null > | null,
  or?: Array< ModelFacilityMeasurementFilterInput | null > | null,
  not?: ModelFacilityMeasurementFilterInput | null,
};

export type ModelFacilityMeasurementConnection = {
  __typename: "ModelFacilityMeasurementConnection",
  items?:  Array<FacilityMeasurement | null > | null,
  nextToken?: string | null,
};

export type ModelFacilityMeasurementByFacilityCompositeKeyConditionInput = {
  eq?: ModelFacilityMeasurementByFacilityCompositeKeyInput | null,
  le?: ModelFacilityMeasurementByFacilityCompositeKeyInput | null,
  lt?: ModelFacilityMeasurementByFacilityCompositeKeyInput | null,
  ge?: ModelFacilityMeasurementByFacilityCompositeKeyInput | null,
  gt?: ModelFacilityMeasurementByFacilityCompositeKeyInput | null,
  between?: Array< ModelFacilityMeasurementByFacilityCompositeKeyInput | null > | null,
  beginsWith?: ModelFacilityMeasurementByFacilityCompositeKeyInput | null,
};

export type ModelFacilityMeasurementByFacilityCompositeKeyInput = {
  productId?: string | null,
  measuredAt?: string | null,
};

export type ModelFacilityMeasurementByProductCompositeKeyConditionInput = {
  eq?: ModelFacilityMeasurementByProductCompositeKeyInput | null,
  le?: ModelFacilityMeasurementByProductCompositeKeyInput | null,
  lt?: ModelFacilityMeasurementByProductCompositeKeyInput | null,
  ge?: ModelFacilityMeasurementByProductCompositeKeyInput | null,
  gt?: ModelFacilityMeasurementByProductCompositeKeyInput | null,
  between?: Array< ModelFacilityMeasurementByProductCompositeKeyInput | null > | null,
  beginsWith?: ModelFacilityMeasurementByProductCompositeKeyInput | null,
};

export type ModelFacilityMeasurementByProductCompositeKeyInput = {
  facilityId?: string | null,
  measuredAt?: string | null,
};

export type ModelFacilityProductGroupMeasurementFilterInput = {
  id?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productGroupId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  measuredAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityProductGroupMeasurementFilterInput | null > | null,
  or?: Array< ModelFacilityProductGroupMeasurementFilterInput | null > | null,
  not?: ModelFacilityProductGroupMeasurementFilterInput | null,
};

export type ModelFacilityProductGroupMeasurementConnection = {
  __typename: "ModelFacilityProductGroupMeasurementConnection",
  items?:  Array<FacilityProductGroupMeasurement | null > | null,
  nextToken?: string | null,
};

export type ModelFacilityProductGroupMeasurementByFacilityCompositeKeyConditionInput = {
  eq?: ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput | null,
  le?: ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput | null,
  lt?: ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput | null,
  ge?: ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput | null,
  gt?: ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput | null,
  between?: Array< ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput | null > | null,
  beginsWith?: ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput | null,
};

export type ModelFacilityProductGroupMeasurementByFacilityCompositeKeyInput = {
  productGroupId?: string | null,
  measuredAt?: string | null,
};

export type ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyConditionInput = {
  eq?: ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput | null,
  le?: ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput | null,
  lt?: ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput | null,
  ge?: ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput | null,
  gt?: ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput | null,
  between?: Array< ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput | null > | null,
  beginsWith?: ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput | null,
};

export type ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyInput = {
  facilityId?: string | null,
  measuredAt?: string | null,
};

export type ModelFacilityProductFilterInput = {
  id?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  chemName?: ModelStringInput | null,
  casNumber?: ModelStringInput | null,
  alternateId?: ModelStringInput | null,
  isEHS?: ModelBooleanInput | null,
  unit?: ModelStringInput | null,
  density?: ModelFloatInput | null,
  densityUnits?: ModelStringInput | null,
  lastAmount?: ModelFloatInput | null,
  maxAmount?: ModelFloatInput | null,
  averageAmount?: ModelFloatInput | null,
  totalMeasurements?: ModelIntInput | null,
  totalLocations?: ModelIntInput | null,
  isAboveReportingThresholds?: ModelBooleanInput | null,
  lastProductMeasuredAt?: ModelStringInput | null,
  daysOnSite?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityProductFilterInput | null > | null,
  or?: Array< ModelFacilityProductFilterInput | null > | null,
  not?: ModelFacilityProductFilterInput | null,
};

export type ModelFacilityProductConnection = {
  __typename: "ModelFacilityProductConnection",
  items?:  Array<FacilityProduct | null > | null,
  nextToken?: string | null,
};

export type SearchableFacilityProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  facilityId?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  productId?: SearchableIDFilterInput | null,
  chemName?: SearchableStringFilterInput | null,
  casNumber?: SearchableStringFilterInput | null,
  alternateId?: SearchableStringFilterInput | null,
  isEHS?: SearchableBooleanFilterInput | null,
  unit?: SearchableStringFilterInput | null,
  density?: SearchableFloatFilterInput | null,
  densityUnits?: SearchableStringFilterInput | null,
  lastAmount?: SearchableFloatFilterInput | null,
  maxAmount?: SearchableFloatFilterInput | null,
  averageAmount?: SearchableFloatFilterInput | null,
  totalMeasurements?: SearchableIntFilterInput | null,
  totalLocations?: SearchableIntFilterInput | null,
  isAboveReportingThresholds?: SearchableBooleanFilterInput | null,
  lastProductMeasuredAt?: SearchableStringFilterInput | null,
  daysOnSite?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableFacilityProductFilterInput | null > | null,
  or?: Array< SearchableFacilityProductFilterInput | null > | null,
  not?: SearchableFacilityProductFilterInput | null,
};

export type SearchableFacilityProductSortInput = {
  field?: SearchableFacilityProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFacilityProductSortableFields {
  id = "id",
  facilityId = "facilityId",
  owner = "owner",
  productId = "productId",
  chemName = "chemName",
  casNumber = "casNumber",
  alternateId = "alternateId",
  isEHS = "isEHS",
  unit = "unit",
  density = "density",
  densityUnits = "densityUnits",
  lastAmount = "lastAmount",
  maxAmount = "maxAmount",
  averageAmount = "averageAmount",
  totalMeasurements = "totalMeasurements",
  totalLocations = "totalLocations",
  isAboveReportingThresholds = "isAboveReportingThresholds",
  lastProductMeasuredAt = "lastProductMeasuredAt",
  daysOnSite = "daysOnSite",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableFacilityProductConnection = {
  __typename: "SearchableFacilityProductConnection",
  items?:  Array<FacilityProduct | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFacilityRequirementFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityRequirementFilterInput | null > | null,
  or?: Array< ModelFacilityRequirementFilterInput | null > | null,
  not?: ModelFacilityRequirementFilterInput | null,
};

export type ModelLockFilterInput = {
  id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  expiresAt?: ModelIntInput | null,
  holder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelLockFilterInput | null > | null,
  or?: Array< ModelLockFilterInput | null > | null,
  not?: ModelLockFilterInput | null,
};

export type ModelLockConnection = {
  __typename: "ModelLockConnection",
  items?:  Array<Lock | null > | null,
  nextToken?: string | null,
};

export type ModelMailTrackingFilterInput = {
  id?: ModelStringInput | null,
  type?: ModelMailTrackingTypeInput | null,
  status?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMailTrackingFilterInput | null > | null,
  or?: Array< ModelMailTrackingFilterInput | null > | null,
  not?: ModelMailTrackingFilterInput | null,
};

export type ModelMailTrackingConnection = {
  __typename: "ModelMailTrackingConnection",
  items?:  Array<MailTracking | null > | null,
  nextToken?: string | null,
};

export type ModelMergeRecordFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  mergedAt?: ModelStringInput | null,
  mergedBy?: ModelStringInput | null,
  typeName?: ModelStringInput | null,
  winningId?: ModelIDInput | null,
  losingId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMergeRecordFilterInput | null > | null,
  or?: Array< ModelMergeRecordFilterInput | null > | null,
  not?: ModelMergeRecordFilterInput | null,
};

export type ModelMergeRecordConnection = {
  __typename: "ModelMergeRecordConnection",
  items?:  Array<MergeRecord | null > | null,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  notificationType?: ModelNotificationTypeInput | null,
  parentId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  readAt?: ModelStringInput | null,
  lifecycleStatus?: ModelNotificationLifecycleStatusInput | null,
  scheduledFor?: ModelStringInput | null,
  activeAt?: ModelStringInput | null,
  archivedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  facilityId?: ModelIDInput | null,
  notificationTypeSearchable?: ModelStringInput | null,
  lifecycleStatusSearchable?: ModelStringInput | null,
  areaSearchable?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items?:  Array<Notification | null > | null,
  nextToken?: string | null,
};

export type ModelNotificationByStatusAndScheduleCompositeKeyConditionInput = {
  eq?: ModelNotificationByStatusAndScheduleCompositeKeyInput | null,
  le?: ModelNotificationByStatusAndScheduleCompositeKeyInput | null,
  lt?: ModelNotificationByStatusAndScheduleCompositeKeyInput | null,
  ge?: ModelNotificationByStatusAndScheduleCompositeKeyInput | null,
  gt?: ModelNotificationByStatusAndScheduleCompositeKeyInput | null,
  between?: Array< ModelNotificationByStatusAndScheduleCompositeKeyInput | null > | null,
  beginsWith?: ModelNotificationByStatusAndScheduleCompositeKeyInput | null,
};

export type ModelNotificationByStatusAndScheduleCompositeKeyInput = {
  lifecycleStatus?: NotificationLifecycleStatus | null,
  scheduledFor?: string | null,
};

export type SearchableNotificationFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  parentId?: SearchableIDFilterInput | null,
  personId?: SearchableIDFilterInput | null,
  readAt?: SearchableStringFilterInput | null,
  scheduledFor?: SearchableStringFilterInput | null,
  activeAt?: SearchableStringFilterInput | null,
  archivedAt?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  facilityId?: SearchableIDFilterInput | null,
  notificationTypeSearchable?: SearchableStringFilterInput | null,
  lifecycleStatusSearchable?: SearchableStringFilterInput | null,
  areaSearchable?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableNotificationFilterInput | null > | null,
  or?: Array< SearchableNotificationFilterInput | null > | null,
  not?: SearchableNotificationFilterInput | null,
};

export type SearchableNotificationSortInput = {
  field?: SearchableNotificationSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableNotificationSortableFields {
  id = "id",
  owner = "owner",
  parentId = "parentId",
  personId = "personId",
  readAt = "readAt",
  scheduledFor = "scheduledFor",
  activeAt = "activeAt",
  archivedAt = "archivedAt",
  name = "name",
  description = "description",
  facilityId = "facilityId",
  notificationTypeSearchable = "notificationTypeSearchable",
  lifecycleStatusSearchable = "lifecycleStatusSearchable",
  areaSearchable = "areaSearchable",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableNotificationConnection = {
  __typename: "SearchableNotificationConnection",
  items?:  Array<Notification | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelNotificationFacilityLinkFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  notificationType?: ModelNotificationTypeInput | null,
  notificationId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNotificationFacilityLinkFilterInput | null > | null,
  or?: Array< ModelNotificationFacilityLinkFilterInput | null > | null,
  not?: ModelNotificationFacilityLinkFilterInput | null,
};

export type ModelNotificationRequirementLinkFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  notificationType?: ModelNotificationTypeInput | null,
  notificationId?: ModelIDInput | null,
  requirementId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelNotificationRequirementLinkFilterInput | null > | null,
  or?: Array< ModelNotificationRequirementLinkFilterInput | null > | null,
  not?: ModelNotificationRequirementLinkFilterInput | null,
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  paymentFacilityIds?: ModelIDInput | null,
  status?: ModelPaymentStatusInput | null,
  paymentMethod?: ModelPaymentMethodInput | null,
  amount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  payee?: ModelStringInput | null,
  paidAt?: ModelStringInput | null,
  recordedBy?: ModelStringInput | null,
  paymentDocumentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  activityId?: ModelIDInput | null,
  payeeType?: ModelStringInput | null,
  company?: ModelStringInput | null,
  addressLine1?: ModelStringInput | null,
  addressLine2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  and?: Array< ModelPaymentFilterInput | null > | null,
  or?: Array< ModelPaymentFilterInput | null > | null,
  not?: ModelPaymentFilterInput | null,
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection",
  items?:  Array<Payment | null > | null,
  nextToken?: string | null,
};

export type SearchablePaymentFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  paymentFacilityIds?: SearchableIDFilterInput | null,
  amount?: SearchableFloatFilterInput | null,
  description?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  payee?: SearchableStringFilterInput | null,
  paidAt?: SearchableStringFilterInput | null,
  recordedBy?: SearchableStringFilterInput | null,
  paymentDocumentID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  activityId?: SearchableIDFilterInput | null,
  payeeType?: SearchableStringFilterInput | null,
  company?: SearchableStringFilterInput | null,
  addressLine1?: SearchableStringFilterInput | null,
  addressLine2?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  state?: SearchableStringFilterInput | null,
  zip?: SearchableStringFilterInput | null,
  and?: Array< SearchablePaymentFilterInput | null > | null,
  or?: Array< SearchablePaymentFilterInput | null > | null,
  not?: SearchablePaymentFilterInput | null,
};

export type SearchablePaymentSortInput = {
  field?: SearchablePaymentSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePaymentSortableFields {
  id = "id",
  owner = "owner",
  paymentFacilityIds = "paymentFacilityIds",
  amount = "amount",
  description = "description",
  memo = "memo",
  payee = "payee",
  paidAt = "paidAt",
  recordedBy = "recordedBy",
  paymentDocumentID = "paymentDocumentID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  activityId = "activityId",
  payeeType = "payeeType",
  company = "company",
  addressLine1 = "addressLine1",
  addressLine2 = "addressLine2",
  city = "city",
  state = "state",
  zip = "zip",
}


export type SearchablePaymentConnection = {
  __typename: "SearchablePaymentConnection",
  items?:  Array<Payment | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPersonFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  first?: ModelStringInput | null,
  last?: ModelStringInput | null,
  email?: ModelStringInput | null,
  title?: ModelStringInput | null,
  company?: ModelStringInput | null,
  state?: ModelStringInput | null,
  contactType?: ModelContactTypeInput | null,
  searchableProgramAreas?: ModelStringInput | null,
  searchableFacilities?: ModelStringInput | null,
  searchableContactTypes?: ModelStringInput | null,
  userTenantId?: ModelIDInput | null,
  paymentMethodId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelPersonFilterInput | null > | null,
  or?: Array< ModelPersonFilterInput | null > | null,
  not?: ModelPersonFilterInput | null,
};

export type ModelPersonConnection = {
  __typename: "ModelPersonConnection",
  items?:  Array<Person | null > | null,
  nextToken?: string | null,
};

export type SearchablePersonFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  first?: SearchableStringFilterInput | null,
  last?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  company?: SearchableStringFilterInput | null,
  state?: SearchableStringFilterInput | null,
  searchableProgramAreas?: SearchableStringFilterInput | null,
  searchableFacilities?: SearchableStringFilterInput | null,
  searchableContactTypes?: SearchableStringFilterInput | null,
  userTenantId?: SearchableIDFilterInput | null,
  paymentMethodId?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchablePersonFilterInput | null > | null,
  or?: Array< SearchablePersonFilterInput | null > | null,
  not?: SearchablePersonFilterInput | null,
};

export type SearchablePersonSortInput = {
  field?: SearchablePersonSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePersonSortableFields {
  id = "id",
  owner = "owner",
  first = "first",
  last = "last",
  email = "email",
  title = "title",
  company = "company",
  state = "state",
  searchableProgramAreas = "searchableProgramAreas",
  searchableFacilities = "searchableFacilities",
  searchableContactTypes = "searchableContactTypes",
  userTenantId = "userTenantId",
  paymentMethodId = "paymentMethodId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchablePersonConnection = {
  __typename: "SearchablePersonConnection",
  items?:  Array<Person | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPortalInteractionRecordFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  requestId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  encampRequestId?: ModelIDInput | null,
  credentialId?: ModelIDInput | null,
  credentialUsername?: ModelStringInput | null,
  stateOrTerritory?: ModelStringInput | null,
  status?: ModelPortalInteractionStatusInput | null,
  action?: ModelPortalInteractionActionInput | null,
  forceClassicSubmissionStrategy?: ModelBooleanInput | null,
  evidenceOfCompletionActions?: ModelCollectEvidenceOfCompletionActionsListInput | null,
  stepsDone?: ModelStringInput | null,
  stepError?: ModelStringInput | null,
  reportId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelPortalInteractionRecordFilterInput | null > | null,
  or?: Array< ModelPortalInteractionRecordFilterInput | null > | null,
  not?: ModelPortalInteractionRecordFilterInput | null,
};

export type ModelPortalInteractionRecordConnection = {
  __typename: "ModelPortalInteractionRecordConnection",
  items?:  Array<PortalInteractionRecord | null > | null,
  nextToken?: string | null,
};

export type ModelProgramAreaFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  isDisabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelProgramAreaFilterInput | null > | null,
  or?: Array< ModelProgramAreaFilterInput | null > | null,
  not?: ModelProgramAreaFilterInput | null,
};

export type ModelProgramAreaConnection = {
  __typename: "ModelProgramAreaConnection",
  items?:  Array<ProgramArea | null > | null,
  nextToken?: string | null,
};

export type SearchableProgramAreaFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  icon?: SearchableStringFilterInput | null,
  isDisabled?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableProgramAreaFilterInput | null > | null,
  or?: Array< SearchableProgramAreaFilterInput | null > | null,
  not?: SearchableProgramAreaFilterInput | null,
};

export type SearchableProgramAreaSortInput = {
  field?: SearchableProgramAreaSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProgramAreaSortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  description = "description",
  icon = "icon",
  isDisabled = "isDisabled",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableProgramAreaConnection = {
  __typename: "SearchableProgramAreaConnection",
  items?:  Array<ProgramArea | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelRcraForm870012FilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  status?: ModelRcraFormStatusInput | null,
  submittedAt?: ModelStringInput | null,
  staffAssignee?: ModelStringInput | null,
  submissionType?: ModelRcraSubmissionTypeInput | null,
  noteKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRcraForm870012FilterInput | null > | null,
  or?: Array< ModelRcraForm870012FilterInput | null > | null,
  not?: ModelRcraForm870012FilterInput | null,
};

export type ModelRcraForm870012Connection = {
  __typename: "ModelRcraForm870012Connection",
  items?:  Array<RcraForm870012 | null > | null,
  nextToken?: string | null,
};

export type ModelRcraForm870013FilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  status?: ModelRcraFormStatusInput | null,
  reportingPeriod?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  staffAssignee?: ModelStringInput | null,
  noteKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRcraForm870013FilterInput | null > | null,
  or?: Array< ModelRcraForm870013FilterInput | null > | null,
  not?: ModelRcraForm870013FilterInput | null,
};

export type ModelRcraForm870013Connection = {
  __typename: "ModelRcraForm870013Connection",
  items?:  Array<RcraForm870013 | null > | null,
  nextToken?: string | null,
};

export type ModelRequirementFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  status?: ModelRequirementStatusInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  agencyId?: ModelIDInput | null,
  area?: ModelIDInput | null,
  areaSearchable?: ModelStringInput | null,
  jurisdictionalAgency?: ModelStringInput | null,
  intervalInWords?: ModelStringInput | null,
  parentStatute?: ModelParentStatuteInput | null,
  jurisdictionalState?: ModelStringInput | null,
  jurisdictionalCounties?: ModelStringInput | null,
  title?: ModelStringInput | null,
  jurisdictionalTownship?: ModelStringInput | null,
  jurisdictionalTribalArea?: ModelStringInput | null,
  jurisdictionalCity?: ModelStringInput | null,
  formExpires?: ModelStringInput | null,
  fillableLinks?: ModelStringInput | null,
  instructionFileName?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  department?: ModelStringInput | null,
  subDepartment?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactPhone?: ModelStringInput | null,
  contactPhoneExtension?: ModelIntInput | null,
  isExtensionAvailable?: ModelBooleanInput | null,
  extensionNotes?: ModelStringInput | null,
  penalty?: ModelStringInput | null,
  encampInstructions?: ModelStringInput | null,
  isAlwaysApplicable?: ModelBooleanInput | null,
  agencyLevelSearchable?: ModelStringInput | null,
  agencyStateSearchable?: ModelStringInput | null,
  userAgencyTitle?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRequirementFilterInput | null > | null,
  or?: Array< ModelRequirementFilterInput | null > | null,
  not?: ModelRequirementFilterInput | null,
};

export type ModelRequirementConnection = {
  __typename: "ModelRequirementConnection",
  items?:  Array<Requirement | null > | null,
  nextToken?: string | null,
};

export type SearchableRequirementFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  agencyId?: SearchableIDFilterInput | null,
  area?: SearchableIDFilterInput | null,
  areaSearchable?: SearchableStringFilterInput | null,
  jurisdictionalAgency?: SearchableStringFilterInput | null,
  intervalInWords?: SearchableStringFilterInput | null,
  jurisdictionalState?: SearchableStringFilterInput | null,
  jurisdictionalCounties?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  jurisdictionalTownship?: SearchableStringFilterInput | null,
  jurisdictionalTribalArea?: SearchableStringFilterInput | null,
  jurisdictionalCity?: SearchableStringFilterInput | null,
  formExpires?: SearchableStringFilterInput | null,
  fillableLinks?: SearchableStringFilterInput | null,
  instructionFileName?: SearchableStringFilterInput | null,
  instruction?: SearchableStringFilterInput | null,
  department?: SearchableStringFilterInput | null,
  subDepartment?: SearchableStringFilterInput | null,
  contactEmail?: SearchableStringFilterInput | null,
  contactPhone?: SearchableStringFilterInput | null,
  contactPhoneExtension?: SearchableIntFilterInput | null,
  isExtensionAvailable?: SearchableBooleanFilterInput | null,
  extensionNotes?: SearchableStringFilterInput | null,
  penalty?: SearchableStringFilterInput | null,
  encampInstructions?: SearchableStringFilterInput | null,
  isAlwaysApplicable?: SearchableBooleanFilterInput | null,
  agencyLevelSearchable?: SearchableStringFilterInput | null,
  agencyStateSearchable?: SearchableStringFilterInput | null,
  userAgencyTitle?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableRequirementFilterInput | null > | null,
  or?: Array< SearchableRequirementFilterInput | null > | null,
  not?: SearchableRequirementFilterInput | null,
};

export type SearchableRequirementSortInput = {
  field?: SearchableRequirementSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableRequirementSortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  description = "description",
  agencyId = "agencyId",
  area = "area",
  areaSearchable = "areaSearchable",
  jurisdictionalAgency = "jurisdictionalAgency",
  intervalInWords = "intervalInWords",
  jurisdictionalState = "jurisdictionalState",
  jurisdictionalCounties = "jurisdictionalCounties",
  title = "title",
  jurisdictionalTownship = "jurisdictionalTownship",
  jurisdictionalTribalArea = "jurisdictionalTribalArea",
  jurisdictionalCity = "jurisdictionalCity",
  formExpires = "formExpires",
  fillableLinks = "fillableLinks",
  instructionFileName = "instructionFileName",
  instruction = "instruction",
  department = "department",
  subDepartment = "subDepartment",
  contactEmail = "contactEmail",
  contactPhone = "contactPhone",
  contactPhoneExtension = "contactPhoneExtension",
  isExtensionAvailable = "isExtensionAvailable",
  extensionNotes = "extensionNotes",
  penalty = "penalty",
  encampInstructions = "encampInstructions",
  isAlwaysApplicable = "isAlwaysApplicable",
  agencyLevelSearchable = "agencyLevelSearchable",
  agencyStateSearchable = "agencyStateSearchable",
  userAgencyTitle = "userAgencyTitle",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableRequirementConnection = {
  __typename: "SearchableRequirementConnection",
  items?:  Array<Requirement | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelRoleFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  rolePermissions?: ModelStringInput | null,
  roleType?: ModelRoleTypeEnumInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelRoleFilterInput | null > | null,
  or?: Array< ModelRoleFilterInput | null > | null,
  not?: ModelRoleFilterInput | null,
};

export type ModelRoleTypeEnumInput = {
  eq?: RoleTypeEnum | null,
  ne?: RoleTypeEnum | null,
};

export type ModelRoleConnection = {
  __typename: "ModelRoleConnection",
  items?:  Array<Role | null > | null,
  nextToken?: string | null,
};

export type ModelProductReviewFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  alternateId?: ModelStringInput | null,
  sdsExtractionJobId?: ModelIDInput | null,
  isExempt?: ModelBooleanInput | null,
  recommendedUse?: ModelStringInput | null,
  reviewedAt?: ModelStringInput | null,
  reviewerRetoolUsername?: ModelStringInput | null,
  status?: ModelReviewStatusInput | null,
  needsReview?: ModelBooleanInput | null,
  isUpdate?: ModelBooleanInput | null,
  sdsFilename?: ModelStringInput | null,
  sdsBoxFileId?: ModelStringInput | null,
  sdsBoxPath?: ModelStringInput | null,
  sdsS3Key?: ModelStringInput | null,
  extractionResultsS3Key?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelProductReviewFilterInput | null > | null,
  or?: Array< ModelProductReviewFilterInput | null > | null,
  not?: ModelProductReviewFilterInput | null,
};

export type ModelProductReviewConnection = {
  __typename: "ModelProductReviewConnection",
  items?:  Array<ProductReview | null > | null,
  nextToken?: string | null,
};

export type ModelSdsExtractionJobFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  boxFolderId?: ModelStringInput | null,
  boxFolderPath?: ModelStringInput | null,
  fileCount?: ModelIntInput | null,
  processedFileCount?: ModelIntInput | null,
  and?: Array< ModelSdsExtractionJobFilterInput | null > | null,
  or?: Array< ModelSdsExtractionJobFilterInput | null > | null,
  not?: ModelSdsExtractionJobFilterInput | null,
};

export type ModelSdsExtractionJobConnection = {
  __typename: "ModelSdsExtractionJobConnection",
  items?:  Array<SdsExtractionJob | null > | null,
  nextToken?: string | null,
};

export type ModelManagedTaskFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  watcherId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  programArea?: ModelIDInput | null,
  _requirementName?: ModelStringInput | null,
  isOrganizationLevel?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelManagedTaskFilterInput | null > | null,
  or?: Array< ModelManagedTaskFilterInput | null > | null,
  not?: ModelManagedTaskFilterInput | null,
};

export type ModelManagedTaskConnection = {
  __typename: "ModelManagedTaskConnection",
  items?:  Array<ManagedTask | null > | null,
  nextToken?: string | null,
};

export type SearchableManagedTaskFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  watcherId?: SearchableIDFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  programArea?: SearchableIDFilterInput | null,
  _requirementName?: SearchableStringFilterInput | null,
  isOrganizationLevel?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableManagedTaskFilterInput | null > | null,
  or?: Array< SearchableManagedTaskFilterInput | null > | null,
  not?: SearchableManagedTaskFilterInput | null,
};

export type SearchableManagedTaskSortInput = {
  field?: SearchableManagedTaskSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableManagedTaskSortableFields {
  id = "id",
  owner = "owner",
  watcherId = "watcherId",
  startDate = "startDate",
  endDate = "endDate",
  programArea = "programArea",
  _requirementName = "_requirementName",
  isOrganizationLevel = "isOrganizationLevel",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableManagedTaskConnection = {
  __typename: "SearchableManagedTaskConnection",
  items?:  Array<ManagedTask | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFacilityTaskFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  assigneeId?: ModelIDInput | null,
  watcherId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelFacilityTaskFilterInput | null > | null,
  or?: Array< ModelFacilityTaskFilterInput | null > | null,
  not?: ModelFacilityTaskFilterInput | null,
};

export type SearchableFacilityTaskFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  taskId?: SearchableIDFilterInput | null,
  facilityId?: SearchableIDFilterInput | null,
  assigneeId?: SearchableIDFilterInput | null,
  watcherId?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableFacilityTaskFilterInput | null > | null,
  or?: Array< SearchableFacilityTaskFilterInput | null > | null,
  not?: SearchableFacilityTaskFilterInput | null,
};

export type SearchableFacilityTaskSortInput = {
  field?: SearchableFacilityTaskSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFacilityTaskSortableFields {
  id = "id",
  owner = "owner",
  taskId = "taskId",
  facilityId = "facilityId",
  assigneeId = "assigneeId",
  watcherId = "watcherId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableFacilityTaskConnection = {
  __typename: "SearchableFacilityTaskConnection",
  items?:  Array<FacilityTask | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTaskDocumentFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTaskDocumentFilterInput | null > | null,
  or?: Array< ModelTaskDocumentFilterInput | null > | null,
  not?: ModelTaskDocumentFilterInput | null,
};

export type ModelTaskDocumentConnection = {
  __typename: "ModelTaskDocumentConnection",
  items?:  Array<TaskDocument | null > | null,
  nextToken?: string | null,
};

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  partnerTenantId?: ModelIDInput | null,
  salesforceId?: ModelStringInput | null,
  accountType?: ModelAccountTypeInput | null,
  isDemo?: ModelBooleanInput | null,
  isPartner?: ModelBooleanInput | null,
  isDisabled?: ModelBooleanInput | null,
  externalId?: ModelStringInput | null,
  roleIdToDefaultForNewUsers?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items?:  Array<Tenant | null > | null,
  nextToken?: string | null,
};

export type ModelTierIIFacilityProductFilterInput = {
  id?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  isOverThresholds?: ModelBooleanInput | null,
  isAlwaysReported?: ModelBooleanInput | null,
  maxAmount?: ModelFloatInput | null,
  maxAmountCode?: ModelIntInput | null,
  averageAmount?: ModelFloatInput | null,
  averageAmountCode?: ModelIntInput | null,
  maxAmountLargestContainer?: ModelFloatInput | null,
  daysOnSite?: ModelIntInput | null,
  hasConfidentialStorageLocations?: ModelBooleanInput | null,
  unit?: ModelStringInput | null,
  stateFields?: ModelStringInput | null,
  aggregatedProductIds?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIFacilityProductFilterInput | null > | null,
  or?: Array< ModelTierIIFacilityProductFilterInput | null > | null,
  not?: ModelTierIIFacilityProductFilterInput | null,
};

export type ModelTierIIFacilityProductConnection = {
  __typename: "ModelTierIIFacilityProductConnection",
  items?:  Array<TierIIFacilityProduct | null > | null,
  nextToken?: string | null,
};

export type ModelTierIIProductFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  chemName?: ModelStringInput | null,
  casNumber?: ModelStringInput | null,
  noCasNumber?: ModelBooleanInput | null,
  alternateId?: ModelStringInput | null,
  ehs?: ModelBooleanInput | null,
  pure?: ModelBooleanInput | null,
  mixture?: ModelBooleanInput | null,
  solid?: ModelBooleanInput | null,
  liquid?: ModelBooleanInput | null,
  gas?: ModelBooleanInput | null,
  isTradeSecret?: ModelBooleanInput | null,
  density?: ModelFloatInput | null,
  densityUnits?: ModelStringInput | null,
  boilingPointFahr?: ModelFloatInput | null,
  flashPointFahr?: ModelFloatInput | null,
  vaporPressureMmhg?: ModelFloatInput | null,
  solubility?: ModelStringInput | null,
  isBattery?: ModelBooleanInput | null,
  isFuel?: ModelBooleanInput | null,
  physicalHazards?: ModelStringInput | null,
  healthHazards?: ModelStringInput | null,
  oregonHazards?: ModelStringInput | null,
  ifcHazards?: ModelStringInput | null,
  noHazardsNotReporting?: ModelBooleanInput | null,
  stateFields?: ModelStringInput | null,
  ehsForm?: ModelEhsFormInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductFilterInput | null > | null,
  or?: Array< ModelTierIIProductFilterInput | null > | null,
  not?: ModelTierIIProductFilterInput | null,
};

export type ModelTierIIProductConnection = {
  __typename: "ModelTierIIProductConnection",
  items?:  Array<TierIIProduct | null > | null,
  nextToken?: string | null,
};

export type SearchableTierIIProductFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  chemName?: SearchableStringFilterInput | null,
  casNumber?: SearchableStringFilterInput | null,
  noCasNumber?: SearchableBooleanFilterInput | null,
  alternateId?: SearchableStringFilterInput | null,
  ehs?: SearchableBooleanFilterInput | null,
  pure?: SearchableBooleanFilterInput | null,
  mixture?: SearchableBooleanFilterInput | null,
  solid?: SearchableBooleanFilterInput | null,
  liquid?: SearchableBooleanFilterInput | null,
  gas?: SearchableBooleanFilterInput | null,
  isTradeSecret?: SearchableBooleanFilterInput | null,
  density?: SearchableFloatFilterInput | null,
  densityUnits?: SearchableStringFilterInput | null,
  boilingPointFahr?: SearchableFloatFilterInput | null,
  flashPointFahr?: SearchableFloatFilterInput | null,
  vaporPressureMmhg?: SearchableFloatFilterInput | null,
  solubility?: SearchableStringFilterInput | null,
  isBattery?: SearchableBooleanFilterInput | null,
  isFuel?: SearchableBooleanFilterInput | null,
  physicalHazards?: SearchableStringFilterInput | null,
  healthHazards?: SearchableStringFilterInput | null,
  oregonHazards?: SearchableStringFilterInput | null,
  ifcHazards?: SearchableStringFilterInput | null,
  noHazardsNotReporting?: SearchableBooleanFilterInput | null,
  stateFields?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  updatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableTierIIProductFilterInput | null > | null,
  or?: Array< SearchableTierIIProductFilterInput | null > | null,
  not?: SearchableTierIIProductFilterInput | null,
};

export type SearchableTierIIProductSortInput = {
  field?: SearchableTierIIProductSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTierIIProductSortableFields {
  id = "id",
  owner = "owner",
  chemName = "chemName",
  casNumber = "casNumber",
  noCasNumber = "noCasNumber",
  alternateId = "alternateId",
  ehs = "ehs",
  pure = "pure",
  mixture = "mixture",
  solid = "solid",
  liquid = "liquid",
  gas = "gas",
  isTradeSecret = "isTradeSecret",
  density = "density",
  densityUnits = "densityUnits",
  boilingPointFahr = "boilingPointFahr",
  flashPointFahr = "flashPointFahr",
  vaporPressureMmhg = "vaporPressureMmhg",
  solubility = "solubility",
  isBattery = "isBattery",
  isFuel = "isFuel",
  physicalHazards = "physicalHazards",
  healthHazards = "healthHazards",
  oregonHazards = "oregonHazards",
  ifcHazards = "ifcHazards",
  noHazardsNotReporting = "noHazardsNotReporting",
  stateFields = "stateFields",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}


export type SearchableTierIIProductConnection = {
  __typename: "SearchableTierIIProductConnection",
  items?:  Array<TierIIProduct | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTierIIProductGroupFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  name?: ModelStringInput | null,
  groupType?: ModelTierIIProductGroupGroupTypeInput | null,
  description?: ModelStringInput | null,
  measurementUnits?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductGroupFilterInput | null > | null,
  or?: Array< ModelTierIIProductGroupFilterInput | null > | null,
  not?: ModelTierIIProductGroupFilterInput | null,
};

export type ModelTierIIProductGroupConnection = {
  __typename: "ModelTierIIProductGroupConnection",
  items?:  Array<TierIIProductGroup | null > | null,
  nextToken?: string | null,
};

export type ModelTierIIProductProductGroupFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  productGroupId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  units?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductProductGroupFilterInput | null > | null,
  or?: Array< ModelTierIIProductProductGroupFilterInput | null > | null,
  not?: ModelTierIIProductProductGroupFilterInput | null,
};

export type ModelTierIIProductGroupProductGroupFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  parentProductGroupId?: ModelIDInput | null,
  childProductGroupId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIProductGroupProductGroupFilterInput | null > | null,
  or?: Array< ModelTierIIProductGroupProductGroupFilterInput | null > | null,
  not?: ModelTierIIProductGroupProductGroupFilterInput | null,
};

export type ModelTierIIReportFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  facilityId?: ModelIDInput | null,
  reportingYear?: ModelIntInput | null,
  reportKind?: ModelTierIIReportKindInput | null,
  organizationStatus?: ModelTierIIReportOrganizationStatusInput | null,
  encampStatus?: ModelTierIIReportEncampStatusInput | null,
  staffAssignee?: ModelStringInput | null,
  step3Prime?: ModelStringInput | null,
  facilityProductIds?: ModelIDInput | null,
  notReportingFacilityProductIds?: ModelIDInput | null,
  facilityContactIds?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelTierIIReportFilterInput | null > | null,
  or?: Array< ModelTierIIReportFilterInput | null > | null,
  not?: ModelTierIIReportFilterInput | null,
};

export type ModelTierIIReportConnection = {
  __typename: "ModelTierIIReportConnection",
  items?:  Array<TierIIReport | null > | null,
  nextToken?: string | null,
};

export type ModelTierIIReportByFacilityCompositeKeyConditionInput = {
  eq?: ModelTierIIReportByFacilityCompositeKeyInput | null,
  le?: ModelTierIIReportByFacilityCompositeKeyInput | null,
  lt?: ModelTierIIReportByFacilityCompositeKeyInput | null,
  ge?: ModelTierIIReportByFacilityCompositeKeyInput | null,
  gt?: ModelTierIIReportByFacilityCompositeKeyInput | null,
  between?: Array< ModelTierIIReportByFacilityCompositeKeyInput | null > | null,
  beginsWith?: ModelTierIIReportByFacilityCompositeKeyInput | null,
};

export type ModelTierIIReportByFacilityCompositeKeyInput = {
  facilityId?: string | null,
  reportingYear?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelTierIIReportByYearCompositeKeyConditionInput = {
  eq?: ModelTierIIReportByYearCompositeKeyInput | null,
  le?: ModelTierIIReportByYearCompositeKeyInput | null,
  lt?: ModelTierIIReportByYearCompositeKeyInput | null,
  ge?: ModelTierIIReportByYearCompositeKeyInput | null,
  gt?: ModelTierIIReportByYearCompositeKeyInput | null,
  between?: Array< ModelTierIIReportByYearCompositeKeyInput | null > | null,
  beginsWith?: ModelTierIIReportByYearCompositeKeyInput | null,
};

export type ModelTierIIReportByYearCompositeKeyInput = {
  reportingYear?: number | null,
  facilityId?: string | null,
};

export type UserActivityLog = {
  __typename: "UserActivityLog",
  id?: string,
  owner?: string,
  cognitoUserId?: string,
  userTenantId?: string,
  type?: string,
  name?: string,
  description?: string,
  createdAt?: string,
  updatedAt?: string,
  updatedBy?: string,
};

export type ModelUserActivityLogFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  cognitoUserId?: ModelIDInput | null,
  userTenantId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserActivityLogFilterInput | null > | null,
  or?: Array< ModelUserActivityLogFilterInput | null > | null,
  not?: ModelUserActivityLogFilterInput | null,
};

export type ModelUserActivityLogConnection = {
  __typename: "ModelUserActivityLogConnection",
  items?:  Array<UserActivityLog | null > | null,
  nextToken?: string | null,
};

export type UserAuth = {
  __typename: "UserAuth",
  id?: string,
  owner?: string,
  userId?: string,
  userTenantId?: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  userTenant?: UserTenant,
};

export type ModelUserAuthFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  userTenantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserAuthFilterInput | null > | null,
  or?: Array< ModelUserAuthFilterInput | null > | null,
  not?: ModelUserAuthFilterInput | null,
};

export type ModelUserAuthConnection = {
  __typename: "ModelUserAuthConnection",
  items?:  Array<UserAuth | null > | null,
  nextToken?: string | null,
};

export type ModelUserTenantFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  invitedAt?: ModelStringInput | null,
  invitationExpiresAt?: ModelStringInput | null,
  status?: ModelUserTenantStatusInput | null,
  personId?: ModelIDInput | null,
  isDisabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserTenantFilterInput | null > | null,
  or?: Array< ModelUserTenantFilterInput | null > | null,
  not?: ModelUserTenantFilterInput | null,
};

export type ModelUserTenantRoleFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  userTenantId?: ModelIDInput | null,
  roleId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelUserTenantRoleFilterInput | null > | null,
  or?: Array< ModelUserTenantRoleFilterInput | null > | null,
  not?: ModelUserTenantRoleFilterInput | null,
};

export type CreateEventSeriesMutationVariables = {
  input?: CreateEventSeriesInput | null,
};

export type CreateEventSeriesMutation = {
  createEventSeries?: boolean | null,
};

export type UpdateSingleEventMutationVariables = {
  input?: UpdateEventsInput,
};

export type UpdateSingleEventMutation = {
  updateSingleEvent?: boolean | null,
};

export type MarkEventsAsCompletedMutationVariables = {
  input?: MarkEventsAsCompletedInput,
};

export type MarkEventsAsCompletedMutation = {
  markEventsAsCompleted?: boolean | null,
};

export type NewUpdateFutureEventsMutationVariables = {
  input?: NewUpdateFutureEventsInput,
};

export type NewUpdateFutureEventsMutation = {
  newUpdateFutureEvents?:  {
    __typename: "NewUpdateFutureEventsOutput",
    newSequenceId?: string | null,
  } | null,
};

export type NewUpdateSingleEventMutationVariables = {
  input?: NewUpdateEventInput,
};

export type NewUpdateSingleEventMutation = {
  newUpdateSingleEvent?: boolean | null,
};

export type NewMarkEventsAsCompletedMutationVariables = {
  input?: NewMarkEventsAsCompletedInput,
};

export type NewMarkEventsAsCompletedMutation = {
  newMarkEventsAsCompleted?: boolean | null,
};

export type NewCreateEventSeriesMutationVariables = {
  input?: NewCreateEventSeriesInput,
};

export type NewCreateEventSeriesMutation = {
  newCreateEventSeries?: boolean | null,
};

export type NewEndEventSeriesMutationVariables = {
  input?: NewEndEventSeriesInput,
};

export type NewEndEventSeriesMutation = {
  newEndEventSeries?: boolean | null,
};

export type NewDeleteEventMutationVariables = {
  input?: NewDeleteEventInput,
};

export type NewDeleteEventMutation = {
  newDeleteEvent?: boolean | null,
};

export type CreateNewManagedTaskMutationVariables = {
  input?: CreateNewManagedTaskInput,
};

export type CreateNewManagedTaskMutation = {
  createNewManagedTask?: boolean | null,
};

export type EditManagedTaskMutationVariables = {
  input?: EditManagedTaskInput,
};

export type EditManagedTaskMutation = {
  editManagedTask?: boolean | null,
};

export type BulkAssignFacilitiesToTaskMutationVariables = {
  input?: BulkAssignFacilitiesToTaskInput,
};

export type BulkAssignFacilitiesToTaskMutation = {
  bulkAssignFacilitiesToTask?: boolean | null,
};

export type BulkUnassignFacilitiesFromTaskMutationVariables = {
  input?: BulkUnassignFacilitiesFromTaskInput,
};

export type BulkUnassignFacilitiesFromTaskMutation = {
  bulkUnassignFacilitiesFromTask?: boolean | null,
};

export type CreateTaskMutationVariables = {
  input?: CreateTaskInput,
};

export type CreateTaskMutation = {
  createTask?: boolean | null,
};

export type UpdateTaskMutationVariables = {
  input?: UpdateTaskInput,
};

export type UpdateTaskMutation = {
  updateTask?: string | null,
};

export type EditTaskOccurrenceMutationVariables = {
  input?: EditTaskOccurrenceInput,
};

export type EditTaskOccurrenceMutation = {
  editTaskOccurrence?: boolean | null,
};

export type CompleteTaskMutationVariables = {
  input?: CompleteTaskInput,
};

export type CompleteTaskMutation = {
  completeTask?: string | null,
};

export type DeleteTaskMutationVariables = {
  input?: DeleteTaskInput,
};

export type DeleteTaskMutation = {
  deleteTask?: boolean | null,
};

export type MarkNotificationsAsReadMutationVariables = {
  input?: UpdateNotificationsInput,
};

export type MarkNotificationsAsReadMutation = {
  markNotificationsAsRead?: boolean | null,
};

export type ArchiveNotificationsMutationVariables = {
  input?: UpdateNotificationsInput,
};

export type ArchiveNotificationsMutation = {
  archiveNotifications?: boolean | null,
};

export type PushNotificationMutationVariables = {
  input?: PushNotificationInput,
};

export type PushNotificationMutation = {
  pushNotification?:  {
    __typename: "Notification",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    parentId?: string | null,
    personId?: string | null,
    readAt?: string | null,
    lifecycleStatus: NotificationLifecycleStatus,
    scheduledFor: string,
    activeAt?: string | null,
    archivedAt?: string | null,
    name: string,
    description?: string | null,
    emailParams?:  {
      __typename: "SendEmailParams",
      from?: string | null,
      to?: string | null,
      subject?: string | null,
      replyTo?: string | null,
      text?: string | null,
      linkText?: string | null,
      hyperlink?: string | null,
    } | null,
    eventContent?:  {
      __typename: "NotificationEventContent",
      area?: string | null,
      deadline?: string | null,
      seriesId?: string | null,
      type?: EventSequenceType | null,
    } | null,
    rcraStatusChangeContent?:  {
      __typename: "RcraStatusChangeContent",
      generatorStatus?: RcraGeneratorStatus | null,
      changedFromManifest?: boolean | null,
    } | null,
    sdsExtractionContent?:  {
      __typename: "SdsExtractionContent",
      productId?: string | null,
      chemName?: string | null,
    } | null,
    facilityId?: string | null,
    notificationTypeSearchable?: string | null,
    lifecycleStatusSearchable?: string | null,
    areaSearchable?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelNotificationFacilityLinkConnection",
      items?:  Array< {
        __typename: "NotificationFacilityLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        facilityId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelNotificationRequirementLinkConnection",
      items?:  Array< {
        __typename: "NotificationRequirementLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type SendEmailMutationVariables = {
  input?: SendEmailInput,
};

export type SendEmailMutation = {
  sendEmail?: boolean | null,
};

export type StaffSilentCreateUserMutationVariables = {
  input?: SilentCreateUserInput,
};

export type StaffSilentCreateUserMutation = {
  staffSilentCreateUser?: boolean | null,
};

export type StaffInviteExistingUserMutationVariables = {
  input?: InviteExistingUserInput,
};

export type StaffInviteExistingUserMutation = {
  staffInviteExistingUser?: boolean | null,
};

export type CreateOrganizationMutationVariables = {
  input?: CreateOrganizationInput,
};

export type CreateOrganizationMutation = {
  createOrganization?: string | null,
};

export type ChangeTenantMutationVariables = {
  input?: ChangeTenantInput,
};

export type ChangeTenantMutation = {
  changeTenant?: boolean | null,
};

export type StaffPurgeFacilityRequirementMutationVariables = {
  input?: PurgeFacilityRequirementInput,
};

export type StaffPurgeFacilityRequirementMutation = {
  staffPurgeFacilityRequirement?: boolean | null,
};

export type AdminResendInvitationMutationVariables = {
  input?: InviteExistingUserInput,
};

export type AdminResendInvitationMutation = {
  adminResendInvitation?: boolean | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserInput,
};

export type CreateUserMutation = {
  createUser?: boolean | null,
};

export type GenerateTierIiProductSheetMutationVariables = {
  input?: GenerateTierIIProductSheetInput | null,
};

export type GenerateTierIiProductSheetMutation = {
  generateTierIIProductSheet?: boolean | null,
};

export type GenerateTierIiFacilityProductSheetMutationVariables = {
  input?: GenerateTierIIFacilityProductSheetInput | null,
};

export type GenerateTierIiFacilityProductSheetMutation = {
  generateTierIIFacilityProductSheet?: boolean | null,
};

export type GenerateMultiFacilityTierIiProductSheetMutationVariables = {
  input?: GenerateTierIIFacilitiesProductsSheetInput | null,
};

export type GenerateMultiFacilityTierIiProductSheetMutation = {
  generateMultiFacilityTierIIProductSheet?: boolean | null,
};

export type GeneratePersonsSheetMutationVariables = {
  input?: GeneratePersonsSheetInput | null,
};

export type GeneratePersonsSheetMutation = {
  generatePersonsSheet?: boolean | null,
};

export type GenerateFacilitiesSheetMutationVariables = {
  input?: GenerateSpreadsheetInput | null,
};

export type GenerateFacilitiesSheetMutation = {
  generateFacilitiesSheet?: boolean | null,
};

export type GenerateTierIiReportPdfPreviewMutationVariables = {
  input?: GenerateTierIIReportPdfInput | null,
};

export type GenerateTierIiReportPdfPreviewMutation = {
  generateTierIIReportPdfPreview?: boolean | null,
};

export type PublishDocumentMutationVariables = {
  input?: PublishDocumentInput | null,
};

export type PublishDocumentMutation = {
  publishDocument?:  {
    __typename: "PublishedDocument",
    processId: string,
    url?: string | null,
    errorMsg?: string | null,
  } | null,
};

export type PublishExceptionReportMutationVariables = {
  input?: PublishExceptionReportInput | null,
};

export type PublishExceptionReportMutation = {
  publishExceptionReport?:  {
    __typename: "PublishedExceptionReport",
    processId: string,
    key?: string | null,
    errorMsg?: string | null,
  } | null,
};

export type GenerateReportMutationVariables = {
  input?: GenerateReportInput | null,
};

export type GenerateReportMutation = {
  generateReport?: boolean | null,
};

export type UpdateTierIiProductsMutationVariables = {
  input?: BulkUpdateTierIIProductsInput | null,
};

export type UpdateTierIiProductsMutation = {
  updateTierIIProducts?:  {
    __typename: "BulkUpdateTierIIProducts",
    updatedRowCount: number,
    errors: Array< string >,
  } | null,
};

export type UpdateTierIiFacilityProductsMutationVariables = {
  input?: BulkUpdateTierIIFacilityProductsInput | null,
};

export type UpdateTierIiFacilityProductsMutation = {
  updateTierIIFacilityProducts?:  {
    __typename: "BulkUpdateTierIIFacilityProducts",
    updatedRowCount: number,
    errors: Array< string >,
  } | null,
};

export type AddMultiFacilityTierIiProductsMutationVariables = {
  input?: BulkUpdateTierIIFacilitiesProductsInput | null,
};

export type AddMultiFacilityTierIiProductsMutation = {
  addMultiFacilityTierIIProducts?:  {
    __typename: "BulkUpdateTierIIFacilitiesProducts",
    updatedRowCount: number,
    errors: Array< string >,
  } | null,
};

export type ValidateMultiFacilityTierIiProductsMutationVariables = {
  input?: BulkUpdateTierIIFacilitiesProductsInput | null,
};

export type ValidateMultiFacilityTierIiProductsMutation = {
  validateMultiFacilityTierIIProducts?:  {
    __typename: "BulkUpdateTierIIFacilityProductsValidationResults",
    updatedRows:  Array< {
      __typename: "MultiFacilitySpreadsheetFacilityProducts",
      id: string,
      facilityId: string,
      facilityName: string,
      customerFacilityId: string,
      productId: string,
      alternateId: string,
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
    } >,
    validationErrors:  Array< {
      __typename: "ValidationError",
      type: string,
      message: string,
      path: string,
    } >,
  } | null,
};

export type TriggerCustomerDataPipelineMutationVariables = {
  input?: TriggerCustomerDataPipelineInput | null,
};

export type TriggerCustomerDataPipelineMutation = {
  triggerCustomerDataPipeline?:  {
    __typename: "TriggeredCustomerDataPipeline",
    triggered: boolean,
    errors: Array< string >,
  } | null,
};

export type GenerateMultiFacilityTierIiBasicInformationSheetMutationVariables = {
  input?: GenerateTierIIFacilitiesBasicInformationSheetInput | null,
};

export type GenerateMultiFacilityTierIiBasicInformationSheetMutation = {
  generateMultiFacilityTierIIBasicInformationSheet?: boolean | null,
};

export type ValidateMultiFacilityTierIiBasicInformationSheetMutationVariables = {
  input?: BulkUpdateInput | null,
};

export type ValidateMultiFacilityTierIiBasicInformationSheetMutation = {
  validateMultiFacilityTierIIBasicInformationSheet?:  {
    __typename: "BulkUpdateTierIIBasicInformationValidationResults",
    updatedRows?:  Array< {
      __typename: "MultiFacilitySpreadsheetBasicInformationResults",
      id?: string | null,
      companyName?: string | null,
      county?: string | null,
      dbNumber?: string | null,
      department?: string | null,
      name?: string | null,
      facilityPhone?: string | null,
      facilityPhone24Hour?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      isClosed?: boolean | null,
      isColocated?: boolean | null,
      isInactive?: boolean | null,
      colocationReportingFacilityId?: string | null,
      isMailingSameAsFacility?: boolean | null,
      isUnmanned?: boolean | null,
      latitude?: number | null,
      longitude?: number | null,
      lepc?: string | null,
      lepcId?: string | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      maxNumOccupants?: number | null,
      municipality?: string | null,
      naicsCode?: number | null,
      reconciledAt?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
    } > | null,
    validationErrors:  Array< {
      __typename: "ValidationError",
      type: string,
      message: string,
      path: string,
    } >,
  } | null,
};

export type AddMultiFacilityTierIiBasicInformationSheetMutationVariables = {
  input?: BulkUpdateInput | null,
};

export type AddMultiFacilityTierIiBasicInformationSheetMutation = {
  addMultiFacilityTierIIBasicInformationSheet?:  {
    __typename: "BulkUpdateTierIIBasicInformation",
    updatedRowCount: number,
    errors: Array< string >,
  } | null,
};

export type GenerateMultiFacilityTierIiContactSheetMutationVariables = {
  input?: GenerateTierIIFacilitiesProductsSheetInput | null,
};

export type GenerateMultiFacilityTierIiContactSheetMutation = {
  generateMultiFacilityTierIIContactSheet?: boolean | null,
};

export type ValidateMultiFacilityTierIiContactSheetMutationVariables = {
  input?: BulkUpdateTierIIContactsInput | null,
};

export type ValidateMultiFacilityTierIiContactSheetMutation = {
  validateMultiFacilityTierIIContactSheet?:  {
    __typename: "BulkUpdateTierIIContactsValidationResults",
    updatedRows:  Array< {
      __typename: "MultiFacilitySpreadsheetContact",
      facilityId: string,
      facilityName: string,
      id: string,
      first: string,
      last: string,
      title?: string | null,
      reconciledAt?: string | null,
      contactType?: string | null,
      email?: string | null,
      phone?: string | null,
      phone24Hour?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      fax?: string | null,
      cell?: string | null,
      phones?:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } > | null,
    } >,
    validationErrors:  Array< {
      __typename: "ValidationError",
      type: string,
      message: string,
      path: string,
    } | null >,
  } | null,
};

export type AddMultiFacilityTierIiContactsMutationVariables = {
  input?: BulkUpdateTierIIContactsInput | null,
};

export type AddMultiFacilityTierIiContactsMutation = {
  addMultiFacilityTierIIContacts?:  {
    __typename: "BulkUpdateTierIIContacts",
    updatedRowCount: number,
    errors: Array< string >,
  } | null,
};

export type ValidatePersonsSheetMutationVariables = {
  input?: ValidatePersonsSheetInput | null,
};

export type ValidatePersonsSheetMutation = {
  validatePersonsSheet?:  Array< {
    __typename: "ValidatedPerson",
    person:  {
      __typename: "SpreadsheetPerson",
      id?: string | null,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      contactType?: ContactType | null,
      userTenantId?: string | null,
      cellPhone?: string | null,
      homePhone?: string | null,
      workPhone?: string | null,
      hrs24Phone?: string | null,
      emergencyPhone?: string | null,
      faxPhone?: string | null,
    },
    validationErrors:  Array< {
      __typename: "ValidationError",
      type: string,
      message: string,
      path: string,
    } | null >,
  } | null > | null,
};

export type BulkUpdatePersonsMutationVariables = {
  input?: BulkUpdatePersonsInput | null,
};

export type BulkUpdatePersonsMutation = {
  bulkUpdatePersons?:  {
    __typename: "BulkUpdateResult",
    updatedRowCount: number,
    errors: Array< string >,
  } | null,
};

export type UpdateFacilitiesMutationVariables = {
  input?: BulkUpdateInput | null,
};

export type UpdateFacilitiesMutation = {
  updateFacilities?:  {
    __typename: "BulkUpdateResult",
    updatedRowCount: number,
    errors: Array< string >,
  } | null,
};

export type ReadTier2SubmitContentsMutationVariables = {
  input?: ReadTier2SubmitContentsInput | null,
};

export type ReadTier2SubmitContentsMutation = {
  readTier2SubmitContents?:  {
    __typename: "ReadTier2SubmitContents",
    reportingYear: string,
    facilities?:  Array< {
      __typename: "ReadTier2SubmitFacility",
      id: string,
      name: string,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      owner?: string | null,
      contactsCount: number,
      productsCount: number,
    } > | null,
  } | null,
};

export type ImportTier2SubmitContentsMutationVariables = {
  input?: ImportTier2SubmitContentsInput | null,
};

export type ImportTier2SubmitContentsMutation = {
  importTier2SubmitContents?:  {
    __typename: "ImportTier2SubmitContentsResults",
    results?:  Array< {
      __typename: "ImportTier2SubmitContentsResult",
      facilityName: string,
      reportKind: string,
    } > | null,
  } | null,
};

export type UpdateFacilityPermissionForUserMutationVariables = {
  facilityId?: string,
  userTenantId?: string,
  level?: UserPermissionLevel,
};

export type UpdateFacilityPermissionForUserMutation = {
  updateFacilityPermissionForUser?:  {
    __typename: "Facility",
    id: string,
    owner?: string | null,
    name: string,
    status?: FacilityStatus | null,
    statusReason?: string | null,
    epaGeneratorStatus?: RcraGeneratorStatus | null,
    epaGeneratorStatusUpdatedAt?: string | null,
    epaGeneratorStatusUpdatedBy?: string | null,
    stateGeneratorStatus?: RcraGeneratorStatus | null,
    stateGeneratorStatusUpdatedAt?: string | null,
    stateGeneratorStatusUpdatedBy?: string | null,
    businessUnit?: string | null,
    customerFacilityId?: string | null,
    dbNumber?: string | null,
    facilityType?: string | null,
    naicsCode?: number | null,
    maxNumOccupants?: number | null,
    isUnmanned?: boolean | null,
    phone?: string | null,
    phone24Hour?: string | null,
    streetAddress:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    },
    mailingAddress?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    county?: string | null,
    municipality?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    isAutoGeocoded?: boolean | null,
    lepc?: string | null,
    lepcId?: string | null,
    fireDistrict?: string | null,
    fireDistrictId?: string | null,
    programAreas?: Array< string | null > | null,
    alternateIds?:  Array< {
      __typename: "FacilityAlternateId",
      kind: string,
      id: string,
      expiresAt?: string | null,
      recordKeys?: Array< string | null > | null,
    } > | null,
    userPermissions?:  Array< {
      __typename: "UserPermission",
      userTenantId: string,
      level: UserPermissionLevel,
    } | null > | null,
    dateOpened?: string | null,
    dateClosed?: string | null,
    dateInactive?: string | null,
    regulatoryInformation?:  {
      __typename: "FacilityRegulatoryInformation",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    federalFields?: string | null,
    stateFields?: string | null,
    searchableProgramAreas?: string | null,
    searchableStatus?: string | null,
    searchableCity?: string | null,
    lastProductMeasuredAt?: string | null,
    customerProductComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    streetAddressVerifiedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    contacts?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    credentials?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type RemoveFacilityPermissionForUserMutationVariables = {
  facilityId?: string,
  userTenantId?: string,
};

export type RemoveFacilityPermissionForUserMutation = {
  removeFacilityPermissionForUser?:  {
    __typename: "Facility",
    id: string,
    owner?: string | null,
    name: string,
    status?: FacilityStatus | null,
    statusReason?: string | null,
    epaGeneratorStatus?: RcraGeneratorStatus | null,
    epaGeneratorStatusUpdatedAt?: string | null,
    epaGeneratorStatusUpdatedBy?: string | null,
    stateGeneratorStatus?: RcraGeneratorStatus | null,
    stateGeneratorStatusUpdatedAt?: string | null,
    stateGeneratorStatusUpdatedBy?: string | null,
    businessUnit?: string | null,
    customerFacilityId?: string | null,
    dbNumber?: string | null,
    facilityType?: string | null,
    naicsCode?: number | null,
    maxNumOccupants?: number | null,
    isUnmanned?: boolean | null,
    phone?: string | null,
    phone24Hour?: string | null,
    streetAddress:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    },
    mailingAddress?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    county?: string | null,
    municipality?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    isAutoGeocoded?: boolean | null,
    lepc?: string | null,
    lepcId?: string | null,
    fireDistrict?: string | null,
    fireDistrictId?: string | null,
    programAreas?: Array< string | null > | null,
    alternateIds?:  Array< {
      __typename: "FacilityAlternateId",
      kind: string,
      id: string,
      expiresAt?: string | null,
      recordKeys?: Array< string | null > | null,
    } > | null,
    userPermissions?:  Array< {
      __typename: "UserPermission",
      userTenantId: string,
      level: UserPermissionLevel,
    } | null > | null,
    dateOpened?: string | null,
    dateClosed?: string | null,
    dateInactive?: string | null,
    regulatoryInformation?:  {
      __typename: "FacilityRegulatoryInformation",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    federalFields?: string | null,
    stateFields?: string | null,
    searchableProgramAreas?: string | null,
    searchableStatus?: string | null,
    searchableCity?: string | null,
    lastProductMeasuredAt?: string | null,
    customerProductComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    streetAddressVerifiedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    contacts?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    credentials?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpsertIcebergMutationVariables = {
  modelName?: string,
  model?: string,
};

export type UpsertIcebergMutation = {
  upsertIceberg?: boolean | null,
};

export type UpsertIcebergWithAppSyncMutationVariables = {
  modelName?: string,
  model?: string,
  query?: string,
};

export type UpsertIcebergWithAppSyncMutation = {
  upsertIcebergWithAppSync?: boolean | null,
};

export type CreateTierIiReportStubMutationVariables = {
  facilityId?: string,
  reportingYear?: number,
  reportKind?: TierIIReportKind | null,
  reportInput?: TierIIReportIcebergPatternReportInput | null,
  seedReportId?: string | null,
};

export type CreateTierIiReportStubMutation = {
  createTierIIReportStub?:  {
    __typename: "TierIIReport",
    id: string,
    owner?: string | null,
    facilityId: string,
    reportingYear: number,
    reportKind: TierIIReportKind,
    organizationStatus: TierIIReportOrganizationStatus,
    encampStatus: TierIIReportEncampStatus,
    staffAssignee?: string | null,
    metadata?:  {
      __typename: "TierIIMetadata",
      comments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "TierIISubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      submissionRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      tags?: string | null,
      untouchedSteps?: Array< string > | null,
    } | null,
    step3Prime?: string | null,
    customerComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    assignees?:  Array< {
      __typename: "TierIIPerson",
      id: string,
      first: string,
      last: string,
      title?: string | null,
      areas?: Array< string > | null,
      reconciledAt?: string | null,
    } > | null,
    basic?:  {
      __typename: "TierIIBasicInformation",
      companyName?: string | null,
      county?: string | null,
      dateClosed?: string | null,
      dbNumber?: string | null,
      department?: string | null,
      facilityName?: string | null,
      facilityPhone?: string | null,
      facilityPhone24Hour?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      isClosed?: boolean | null,
      isColocated?: boolean | null,
      isInactive?: boolean | null,
      colocationReportingFacilityId?: string | null,
      isMailingSameAsFacility?: boolean | null,
      isUnmanned?: boolean | null,
      latitude?: number | null,
      longitude?: number | null,
      lepc?: string | null,
      lepcId?: string | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      maxNumOccupants?: number | null,
      municipality?: string | null,
      naicsCode?: number | null,
      reconciledAt?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
    } | null,
    regulatory?:  {
      __typename: "TierIIRegulatory",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    facilityProductIds?: Array< string > | null,
    notReportingFacilityProductIds?: Array< string > | null,
    products?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    notReportingProducts?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    facilityContactIds?: Array< string > | null,
    contacts?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    records?:  {
      __typename: "TierIIRecords",
      facilityRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
    } | null,
    state?:  {
      __typename: "TierIIStateInformation",
      fields?: string | null,
    } | null,
    review?:  {
      __typename: "TierIIReview",
      reviewers?:  Array< {
        __typename: "TierIIReviewer",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        isComplete?: boolean | null,
      } > | null,
      notesToRegulators?: string | null,
      isNotReporting?: boolean | null,
    } | null,
    verify?:  {
      __typename: "TierIIVerify",
      verifier?:  {
        __typename: "TierIIVerifier",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        fullName?: string | null,
      } | null,
      verifiedAt?: string | null,
      isVerified?: boolean | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    computed?:  {
      __typename: "ModelComputedConnection",
      items?:  Array< {
        __typename: "Computed",
        id: string,
        typeId: string,
        owner: string,
        kind: ComputationKind,
        typeName?: string | null,
        computed?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTierIiReportIcebergPatternMutationVariables = {
  reportId?: string,
  updates?: TierIIReportIcebergPatternReportInput | null,
};

export type UpdateTierIiReportIcebergPatternMutation = {
  updateTierIIReportIcebergPattern?:  {
    __typename: "TierIIReport",
    id: string,
    owner?: string | null,
    facilityId: string,
    reportingYear: number,
    reportKind: TierIIReportKind,
    organizationStatus: TierIIReportOrganizationStatus,
    encampStatus: TierIIReportEncampStatus,
    staffAssignee?: string | null,
    metadata?:  {
      __typename: "TierIIMetadata",
      comments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "TierIISubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      submissionRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      tags?: string | null,
      untouchedSteps?: Array< string > | null,
    } | null,
    step3Prime?: string | null,
    customerComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    assignees?:  Array< {
      __typename: "TierIIPerson",
      id: string,
      first: string,
      last: string,
      title?: string | null,
      areas?: Array< string > | null,
      reconciledAt?: string | null,
    } > | null,
    basic?:  {
      __typename: "TierIIBasicInformation",
      companyName?: string | null,
      county?: string | null,
      dateClosed?: string | null,
      dbNumber?: string | null,
      department?: string | null,
      facilityName?: string | null,
      facilityPhone?: string | null,
      facilityPhone24Hour?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      isClosed?: boolean | null,
      isColocated?: boolean | null,
      isInactive?: boolean | null,
      colocationReportingFacilityId?: string | null,
      isMailingSameAsFacility?: boolean | null,
      isUnmanned?: boolean | null,
      latitude?: number | null,
      longitude?: number | null,
      lepc?: string | null,
      lepcId?: string | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      maxNumOccupants?: number | null,
      municipality?: string | null,
      naicsCode?: number | null,
      reconciledAt?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
    } | null,
    regulatory?:  {
      __typename: "TierIIRegulatory",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    facilityProductIds?: Array< string > | null,
    notReportingFacilityProductIds?: Array< string > | null,
    products?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    notReportingProducts?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    facilityContactIds?: Array< string > | null,
    contacts?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    records?:  {
      __typename: "TierIIRecords",
      facilityRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
    } | null,
    state?:  {
      __typename: "TierIIStateInformation",
      fields?: string | null,
    } | null,
    review?:  {
      __typename: "TierIIReview",
      reviewers?:  Array< {
        __typename: "TierIIReviewer",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        isComplete?: boolean | null,
      } > | null,
      notesToRegulators?: string | null,
      isNotReporting?: boolean | null,
    } | null,
    verify?:  {
      __typename: "TierIIVerify",
      verifier?:  {
        __typename: "TierIIVerifier",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        fullName?: string | null,
      } | null,
      verifiedAt?: string | null,
      isVerified?: boolean | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    computed?:  {
      __typename: "ModelComputedConnection",
      items?:  Array< {
        __typename: "Computed",
        id: string,
        typeId: string,
        owner: string,
        kind: ComputationKind,
        typeName?: string | null,
        computed?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type MergeContactsMutationVariables = {
  input?: MergeContactsInput | null,
};

export type MergeContactsMutation = {
  mergeContacts?: boolean | null,
};

export type PublishMergeContactsMutationVariables = {
  input?: PublishMergeContactsInput | null,
};

export type PublishMergeContactsMutation = {
  publishMergeContacts?:  {
    __typename: "PublishedMergeContactsResponse",
    processId: string,
    errorMsg?: string | null,
  } | null,
};

export type MergeProductsMutationVariables = {
  input?: MergeProductsInput | null,
};

export type MergeProductsMutation = {
  mergeProducts?: boolean | null,
};

export type AggregateFacilityProductsMutationVariables = {
  fieldName?: string | null,
  input?: AggregateFacilityProductsInput,
};

export type AggregateFacilityProductsMutation = {
  aggregateFacilityProducts?: boolean | null,
};

export type AggregateFacilityMeasurementByFacilityMutationVariables = {
  fieldName?: string | null,
  input?: AggregateFacilityInput,
};

export type AggregateFacilityMeasurementByFacilityMutation = {
  aggregateFacilityMeasurementByFacility?: boolean | null,
};

export type AggregateFacilityMeasurementByTenantMutationVariables = {
  fieldName?: string | null,
  input?: AggregateTenantInput,
};

export type AggregateFacilityMeasurementByTenantMutation = {
  aggregateFacilityMeasurementByTenant?: boolean | null,
};

export type AggregateAllFacilityMeasurementMutationVariables = {
  fieldName?: string | null,
};

export type AggregateAllFacilityMeasurementMutation = {
  aggregateAllFacilityMeasurement?: boolean | null,
};

export type SdsExtractionMutationVariables = {
  input?: SdsExtractionInput | null,
};

export type SdsExtractionMutation = {
  sdsExtraction?: boolean | null,
};

export type CreateMetatronFacilityMutationVariables = {
  processId?: string,
  programName?: string,
  input?: CreateMetatronFacilitiyInput,
};

export type CreateMetatronFacilityMutation = {
  createMetatronFacility?: boolean | null,
};

export type PublishCreateMetatronFacilityMutationVariables = {
  input?: PublishCreateMetatronFacility | null,
};

export type PublishCreateMetatronFacilityMutation = {
  publishCreateMetatronFacility?:  {
    __typename: "PublishedCreateMetatronFacility",
    processId: string,
    isError?: boolean | null,
  } | null,
};

export type SetTierIiNotReportingReasonMutationVariables = {
  reportIds?: Array< string | null > | null,
  reason?: TierIIReportNotReportingReasonInput | null,
};

export type SetTierIiNotReportingReasonMutation = {
  setTierIINotReportingReason?:  Array< {
    __typename: "TierIINotReportingReasonResult",
    id: string,
    success: boolean,
    errorMsg?: string | null,
  } | null > | null,
};

export type ProcessOneSchemaImportMutationVariables = {
  input?: ProcessOneSchemaImportInput | null,
};

export type ProcessOneSchemaImportMutation = {
  processOneSchemaImport?: boolean | null,
};

export type PublishOneSchemaImportMutationVariables = {
  input?: PublishOneSchemaImportInput | null,
};

export type PublishOneSchemaImportMutation = {
  publishOneSchemaImport?:  {
    __typename: "PublishedOneSchemaImportResponse",
    processId: string,
    errorMsg?: string | null,
  } | null,
};

export type MutateWasteDataMutationVariables = {
  input?: MutateWasteDataInput | null,
};

export type MutateWasteDataMutation = {
  mutateWasteData?:  {
    __typename: "MutateWasteDataOutput",
    success: boolean,
    data?: string | null,
    count: number,
  } | null,
};

export type CreateNoteMutationVariables = {
  input?: CreateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type CreateNoteMutation = {
  createNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    parent?:  {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      parent?:  {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      noteBy?: string | null,
      at: string,
      key?: string | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    noteBy?: string | null,
    at: string,
    key?: string | null,
    note?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateNoteMutationVariables = {
  input?: UpdateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type UpdateNoteMutation = {
  updateNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    parent?:  {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      parent?:  {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      noteBy?: string | null,
      at: string,
      key?: string | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    noteBy?: string | null,
    at: string,
    key?: string | null,
    note?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteNoteMutationVariables = {
  input?: DeleteNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type DeleteNoteMutation = {
  deleteNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    parent?:  {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      parent?:  {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      noteBy?: string | null,
      at: string,
      key?: string | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    noteBy?: string | null,
    at: string,
    key?: string | null,
    note?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateEventDocumentMutationVariables = {
  input?: CreateEventDocumentInput,
  condition?: ModelEventDocumentConditionInput | null,
};

export type CreateEventDocumentMutation = {
  createEventDocument?:  {
    __typename: "EventDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventId: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
  } | null,
};

export type UpdateEventDocumentMutationVariables = {
  input?: UpdateEventDocumentInput,
  condition?: ModelEventDocumentConditionInput | null,
};

export type UpdateEventDocumentMutation = {
  updateEventDocument?:  {
    __typename: "EventDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventId: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
  } | null,
};

export type DeleteEventDocumentMutationVariables = {
  input?: DeleteEventDocumentInput,
  condition?: ModelEventDocumentConditionInput | null,
};

export type DeleteEventDocumentMutation = {
  deleteEventDocument?:  {
    __typename: "EventDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventId: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
  } | null,
};

export type CreateEventWatcherMutationVariables = {
  input?: CreateEventWatcherInput,
  condition?: ModelEventWatcherConditionInput | null,
};

export type CreateEventWatcherMutation = {
  createEventWatcher?:  {
    __typename: "EventWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventId: string,
    deadline: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type UpdateEventWatcherMutationVariables = {
  input?: UpdateEventWatcherInput,
  condition?: ModelEventWatcherConditionInput | null,
};

export type UpdateEventWatcherMutation = {
  updateEventWatcher?:  {
    __typename: "EventWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventId: string,
    deadline: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type DeleteEventWatcherMutationVariables = {
  input?: DeleteEventWatcherInput,
  condition?: ModelEventWatcherConditionInput | null,
};

export type DeleteEventWatcherMutation = {
  deleteEventWatcher?:  {
    __typename: "EventWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventId: string,
    deadline: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type CreateChargeMutationVariables = {
  input?: CreateChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type CreateChargeMutation = {
  createCharge?:  {
    __typename: "Charge",
    id: string,
    owner: string,
    facilityId?: string | null,
    module: ChargeModule,
    reportingYear: number,
    date: string,
    amount?: number | null,
    type: ChargeType,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateChargeMutationVariables = {
  input?: UpdateChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type UpdateChargeMutation = {
  updateCharge?:  {
    __typename: "Charge",
    id: string,
    owner: string,
    facilityId?: string | null,
    module: ChargeModule,
    reportingYear: number,
    date: string,
    amount?: number | null,
    type: ChargeType,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteChargeMutationVariables = {
  input?: DeleteChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type DeleteChargeMutation = {
  deleteCharge?:  {
    __typename: "Charge",
    id: string,
    owner: string,
    facilityId?: string | null,
    module: ChargeModule,
    reportingYear: number,
    date: string,
    amount?: number | null,
    type: ChargeType,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateCredentialMutationVariables = {
  input?: CreateCredentialInput,
  condition?: ModelCredentialConditionInput | null,
};

export type CreateCredentialMutation = {
  createCredential?:  {
    __typename: "Credential",
    id: string,
    owner?: string | null,
    name: string,
    username: string,
    password: string,
    kind: CredentialKind,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    securityQuestions?:  Array< {
      __typename: "SecurityQuestion",
      question: string,
      answer: string,
    } > | null,
    verificationStatus?: CredentialVerificationStatus | null,
    lastVerificationSuccessAt?: string | null,
    lastVerificationCheckAt?: string | null,
    recentUsage?:  Array< {
      __typename: "CredentialUsageLogEntry",
      timestamp: string,
      verificationStatus: CredentialVerificationStatus,
      errorMessage?: string | null,
    } > | null,
    pools?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCredentialMutationVariables = {
  input?: UpdateCredentialInput,
  condition?: ModelCredentialConditionInput | null,
};

export type UpdateCredentialMutation = {
  updateCredential?:  {
    __typename: "Credential",
    id: string,
    owner?: string | null,
    name: string,
    username: string,
    password: string,
    kind: CredentialKind,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    securityQuestions?:  Array< {
      __typename: "SecurityQuestion",
      question: string,
      answer: string,
    } > | null,
    verificationStatus?: CredentialVerificationStatus | null,
    lastVerificationSuccessAt?: string | null,
    lastVerificationCheckAt?: string | null,
    recentUsage?:  Array< {
      __typename: "CredentialUsageLogEntry",
      timestamp: string,
      verificationStatus: CredentialVerificationStatus,
      errorMessage?: string | null,
    } > | null,
    pools?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCredentialMutationVariables = {
  input?: DeleteCredentialInput,
  condition?: ModelCredentialConditionInput | null,
};

export type DeleteCredentialMutation = {
  deleteCredential?:  {
    __typename: "Credential",
    id: string,
    owner?: string | null,
    name: string,
    username: string,
    password: string,
    kind: CredentialKind,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    securityQuestions?:  Array< {
      __typename: "SecurityQuestion",
      question: string,
      answer: string,
    } > | null,
    verificationStatus?: CredentialVerificationStatus | null,
    lastVerificationSuccessAt?: string | null,
    lastVerificationCheckAt?: string | null,
    recentUsage?:  Array< {
      __typename: "CredentialUsageLogEntry",
      timestamp: string,
      verificationStatus: CredentialVerificationStatus,
      errorMessage?: string | null,
    } > | null,
    pools?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCredentialPoolMutationVariables = {
  input?: CreateCredentialPoolInput,
  condition?: ModelCredentialPoolConditionInput | null,
};

export type CreateCredentialPoolMutation = {
  createCredentialPool?:  {
    __typename: "CredentialPool",
    id: string,
    owner?: string | null,
    description?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credentials?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCredentialPoolMutationVariables = {
  input?: UpdateCredentialPoolInput,
  condition?: ModelCredentialPoolConditionInput | null,
};

export type UpdateCredentialPoolMutation = {
  updateCredentialPool?:  {
    __typename: "CredentialPool",
    id: string,
    owner?: string | null,
    description?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credentials?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCredentialPoolMutationVariables = {
  input?: DeleteCredentialPoolInput,
  condition?: ModelCredentialPoolConditionInput | null,
};

export type DeleteCredentialPoolMutation = {
  deleteCredentialPool?:  {
    __typename: "CredentialPool",
    id: string,
    owner?: string | null,
    description?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credentials?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCredentialPoolCredentialMutationVariables = {
  input?: CreateCredentialPoolCredentialInput,
  condition?: ModelCredentialPoolCredentialConditionInput | null,
};

export type CreateCredentialPoolCredentialMutation = {
  createCredentialPoolCredential?:  {
    __typename: "CredentialPoolCredential",
    id: string,
    owner: string,
    credentialId: string,
    credentialPoolId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credential:  {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    credentialPool:  {
      __typename: "CredentialPool",
      id: string,
      owner?: string | null,
      description?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      credentials?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCredentialPoolCredentialMutationVariables = {
  input?: UpdateCredentialPoolCredentialInput,
  condition?: ModelCredentialPoolCredentialConditionInput | null,
};

export type UpdateCredentialPoolCredentialMutation = {
  updateCredentialPoolCredential?:  {
    __typename: "CredentialPoolCredential",
    id: string,
    owner: string,
    credentialId: string,
    credentialPoolId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credential:  {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    credentialPool:  {
      __typename: "CredentialPool",
      id: string,
      owner?: string | null,
      description?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      credentials?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateDataCollectionTaskMutationVariables = {
  input?: CreateDataCollectionTaskInput,
  condition?: ModelDataCollectionTaskConditionInput | null,
};

export type CreateDataCollectionTaskMutation = {
  createDataCollectionTask?:  {
    __typename: "DataCollectionTask",
    id: string,
    owner: string,
    dataCollectionTaskTemplateId: string,
    userTenantId: string,
    createdByUserTenantId: string,
    facilityId: string,
    title: string,
    savedState?:  {
      __typename: "DataCollectionTaskSavedState",
      productGroups?:  Array< {
        __typename: "DataCollectionTaskProductGroupState",
        id: string,
        inputValue: number,
      } | null > | null,
    } | null,
    status: DataCollectionTaskStatusEnum,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    template:  {
      __typename: "DataCollectionTaskTemplate",
      id: string,
      owner: string,
      title: string,
      description?: string | null,
      productGroupIds: Array< string >,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    },
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    createdByUserTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateDataCollectionTaskMutationVariables = {
  input?: UpdateDataCollectionTaskInput,
  condition?: ModelDataCollectionTaskConditionInput | null,
};

export type UpdateDataCollectionTaskMutation = {
  updateDataCollectionTask?:  {
    __typename: "DataCollectionTask",
    id: string,
    owner: string,
    dataCollectionTaskTemplateId: string,
    userTenantId: string,
    createdByUserTenantId: string,
    facilityId: string,
    title: string,
    savedState?:  {
      __typename: "DataCollectionTaskSavedState",
      productGroups?:  Array< {
        __typename: "DataCollectionTaskProductGroupState",
        id: string,
        inputValue: number,
      } | null > | null,
    } | null,
    status: DataCollectionTaskStatusEnum,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    template:  {
      __typename: "DataCollectionTaskTemplate",
      id: string,
      owner: string,
      title: string,
      description?: string | null,
      productGroupIds: Array< string >,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    },
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    createdByUserTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteDataCollectionTaskMutationVariables = {
  input?: DeleteDataCollectionTaskInput,
  condition?: ModelDataCollectionTaskConditionInput | null,
};

export type DeleteDataCollectionTaskMutation = {
  deleteDataCollectionTask?:  {
    __typename: "DataCollectionTask",
    id: string,
    owner: string,
    dataCollectionTaskTemplateId: string,
    userTenantId: string,
    createdByUserTenantId: string,
    facilityId: string,
    title: string,
    savedState?:  {
      __typename: "DataCollectionTaskSavedState",
      productGroups?:  Array< {
        __typename: "DataCollectionTaskProductGroupState",
        id: string,
        inputValue: number,
      } | null > | null,
    } | null,
    status: DataCollectionTaskStatusEnum,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    template:  {
      __typename: "DataCollectionTaskTemplate",
      id: string,
      owner: string,
      title: string,
      description?: string | null,
      productGroupIds: Array< string >,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    },
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    createdByUserTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateDataCollectionTaskTemplateMutationVariables = {
  input?: CreateDataCollectionTaskTemplateInput,
  condition?: ModelDataCollectionTaskTemplateConditionInput | null,
};

export type CreateDataCollectionTaskTemplateMutation = {
  createDataCollectionTaskTemplate?:  {
    __typename: "DataCollectionTaskTemplate",
    id: string,
    owner: string,
    title: string,
    description?: string | null,
    productGroupIds: Array< string >,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateDataCollectionTaskTemplateMutationVariables = {
  input?: UpdateDataCollectionTaskTemplateInput,
  condition?: ModelDataCollectionTaskTemplateConditionInput | null,
};

export type UpdateDataCollectionTaskTemplateMutation = {
  updateDataCollectionTaskTemplate?:  {
    __typename: "DataCollectionTaskTemplate",
    id: string,
    owner: string,
    title: string,
    description?: string | null,
    productGroupIds: Array< string >,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteDataCollectionTaskTemplateMutationVariables = {
  input?: DeleteDataCollectionTaskTemplateInput,
  condition?: ModelDataCollectionTaskTemplateConditionInput | null,
};

export type DeleteDataCollectionTaskTemplateMutation = {
  deleteDataCollectionTaskTemplate?:  {
    __typename: "DataCollectionTaskTemplate",
    id: string,
    owner: string,
    title: string,
    description?: string | null,
    productGroupIds: Array< string >,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateDocumentRequirementMutationVariables = {
  input?: CreateDocumentRequirementInput,
  condition?: ModelDocumentRequirementConditionInput | null,
};

export type CreateDocumentRequirementMutation = {
  createDocumentRequirement?:  {
    __typename: "DocumentRequirement",
    id: string,
    owner?: string | null,
    documentId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateDocumentRequirementMutationVariables = {
  input?: UpdateDocumentRequirementInput,
  condition?: ModelDocumentRequirementConditionInput | null,
};

export type UpdateDocumentRequirementMutation = {
  updateDocumentRequirement?:  {
    __typename: "DocumentRequirement",
    id: string,
    owner?: string | null,
    documentId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteDocumentRequirementMutationVariables = {
  input?: DeleteDocumentRequirementInput,
  condition?: ModelDocumentRequirementConditionInput | null,
};

export type DeleteDocumentRequirementMutation = {
  deleteDocumentRequirement?:  {
    __typename: "DocumentRequirement",
    id: string,
    owner?: string | null,
    documentId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateDocumentMutationVariables = {
  input?: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    owner?: string | null,
    title: string,
    description?: string | null,
    fileExtension: string,
    state?: string | null,
    area?: string | null,
    programAreas?: Array< string | null > | null,
    documentType: string,
    sourceLink?: string | null,
    storageLink: string,
    agencyId?: string | null,
    facilityId?: string | null,
    lobId?: string | null,
    createdBy?: string | null,
    stateSearchable?: string | null,
    areaSearchable?: string | null,
    searchableProgramAreas?: string | null,
    relatedEntityIds?:  Array< {
      __typename: "DocumentRelatedEntityId",
      kind: DocumentRelatedEntityIdKind,
      id: string,
    } > | null,
    tags?: string | null,
    isArchived?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    requirements?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input?: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    owner?: string | null,
    title: string,
    description?: string | null,
    fileExtension: string,
    state?: string | null,
    area?: string | null,
    programAreas?: Array< string | null > | null,
    documentType: string,
    sourceLink?: string | null,
    storageLink: string,
    agencyId?: string | null,
    facilityId?: string | null,
    lobId?: string | null,
    createdBy?: string | null,
    stateSearchable?: string | null,
    areaSearchable?: string | null,
    searchableProgramAreas?: string | null,
    relatedEntityIds?:  Array< {
      __typename: "DocumentRelatedEntityId",
      kind: DocumentRelatedEntityIdKind,
      id: string,
    } > | null,
    tags?: string | null,
    isArchived?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    requirements?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input?: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    id: string,
    owner?: string | null,
    title: string,
    description?: string | null,
    fileExtension: string,
    state?: string | null,
    area?: string | null,
    programAreas?: Array< string | null > | null,
    documentType: string,
    sourceLink?: string | null,
    storageLink: string,
    agencyId?: string | null,
    facilityId?: string | null,
    lobId?: string | null,
    createdBy?: string | null,
    stateSearchable?: string | null,
    areaSearchable?: string | null,
    searchableProgramAreas?: string | null,
    relatedEntityIds?:  Array< {
      __typename: "DocumentRelatedEntityId",
      kind: DocumentRelatedEntityIdKind,
      id: string,
    } > | null,
    tags?: string | null,
    isArchived?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    requirements?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateEpcra311TriggerMutationVariables = {
  input?: CreateEpcra311TriggerInput,
  condition?: ModelEpcra311TriggerConditionInput | null,
};

export type CreateEpcra311TriggerMutation = {
  createEpcra311Trigger?:  {
    __typename: "Epcra311Trigger",
    id: string,
    owner?: string | null,
    description?: string | null,
    triggeredAt: string,
    deadlineAt: string,
    isReported: boolean,
    isRequestingInformation?: boolean | null,
    facilityId: string,
    productId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateEpcra311TriggerMutationVariables = {
  input?: UpdateEpcra311TriggerInput,
  condition?: ModelEpcra311TriggerConditionInput | null,
};

export type UpdateEpcra311TriggerMutation = {
  updateEpcra311Trigger?:  {
    __typename: "Epcra311Trigger",
    id: string,
    owner?: string | null,
    description?: string | null,
    triggeredAt: string,
    deadlineAt: string,
    isReported: boolean,
    isRequestingInformation?: boolean | null,
    facilityId: string,
    productId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteEpcra311TriggerMutationVariables = {
  input?: DeleteEpcra311TriggerInput,
  condition?: ModelEpcra311TriggerConditionInput | null,
};

export type DeleteEpcra311TriggerMutation = {
  deleteEpcra311Trigger?:  {
    __typename: "Epcra311Trigger",
    id: string,
    owner?: string | null,
    description?: string | null,
    triggeredAt: string,
    deadlineAt: string,
    isReported: boolean,
    isRequestingInformation?: boolean | null,
    facilityId: string,
    productId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateEventMutationVariables = {
  input?: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    seriesId: string,
    requirementId: string,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId: string,
    eventResponsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventStatus,
    statusSearchable: string,
    owner?: string | null,
    deadline: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventDocumentConnection",
      items?:  Array< {
        __typename: "EventDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventId: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventWatcherConnection",
      items?:  Array< {
        __typename: "EventWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventId: string,
        deadline: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input?: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    seriesId: string,
    requirementId: string,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId: string,
    eventResponsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventStatus,
    statusSearchable: string,
    owner?: string | null,
    deadline: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventDocumentConnection",
      items?:  Array< {
        __typename: "EventDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventId: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventWatcherConnection",
      items?:  Array< {
        __typename: "EventWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventId: string,
        deadline: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input?: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    seriesId: string,
    requirementId: string,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId: string,
    eventResponsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventStatus,
    statusSearchable: string,
    owner?: string | null,
    deadline: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventDocumentConnection",
      items?:  Array< {
        __typename: "EventDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventId: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventWatcherConnection",
      items?:  Array< {
        __typename: "EventWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventId: string,
        deadline: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateEventSequenceMutationVariables = {
  input?: CreateEventSequenceInput,
  condition?: ModelEventSequenceConditionInput | null,
};

export type CreateEventSequenceMutation = {
  createEventSequence?:  {
    __typename: "EventSequence",
    id: string,
    parentId?: string | null,
    owner: string,
    seriesId: string,
    taskId?: string | null,
    type: EventSequenceType,
    typeSearchable: string,
    startDate: string,
    endDate: string,
    isRecurring?: boolean | null,
    isOrganizationLevel?: boolean | null,
    exceptions?: Array< string > | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId?: string | null,
    state?: string | null,
    responsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventSequenceStatus,
    statusSearchable: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventSequenceDocumentConnection",
      items?:  Array< {
        __typename: "EventSequenceDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventSequenceId: string,
        seriesId: string,
        taskId?: string | null,
        facilityId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventSequenceWatcherConnection",
      items?:  Array< {
        __typename: "EventSequenceWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventSequenceId: string,
        taskId?: string | null,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task?:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateEventSequenceMutationVariables = {
  input?: UpdateEventSequenceInput,
  condition?: ModelEventSequenceConditionInput | null,
};

export type UpdateEventSequenceMutation = {
  updateEventSequence?:  {
    __typename: "EventSequence",
    id: string,
    parentId?: string | null,
    owner: string,
    seriesId: string,
    taskId?: string | null,
    type: EventSequenceType,
    typeSearchable: string,
    startDate: string,
    endDate: string,
    isRecurring?: boolean | null,
    isOrganizationLevel?: boolean | null,
    exceptions?: Array< string > | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId?: string | null,
    state?: string | null,
    responsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventSequenceStatus,
    statusSearchable: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventSequenceDocumentConnection",
      items?:  Array< {
        __typename: "EventSequenceDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventSequenceId: string,
        seriesId: string,
        taskId?: string | null,
        facilityId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventSequenceWatcherConnection",
      items?:  Array< {
        __typename: "EventSequenceWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventSequenceId: string,
        taskId?: string | null,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task?:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteEventSequenceMutationVariables = {
  input?: DeleteEventSequenceInput,
  condition?: ModelEventSequenceConditionInput | null,
};

export type DeleteEventSequenceMutation = {
  deleteEventSequence?:  {
    __typename: "EventSequence",
    id: string,
    parentId?: string | null,
    owner: string,
    seriesId: string,
    taskId?: string | null,
    type: EventSequenceType,
    typeSearchable: string,
    startDate: string,
    endDate: string,
    isRecurring?: boolean | null,
    isOrganizationLevel?: boolean | null,
    exceptions?: Array< string > | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId?: string | null,
    state?: string | null,
    responsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventSequenceStatus,
    statusSearchable: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventSequenceDocumentConnection",
      items?:  Array< {
        __typename: "EventSequenceDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventSequenceId: string,
        seriesId: string,
        taskId?: string | null,
        facilityId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventSequenceWatcherConnection",
      items?:  Array< {
        __typename: "EventSequenceWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventSequenceId: string,
        taskId?: string | null,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task?:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateEventSequenceDocumentMutationVariables = {
  input?: CreateEventSequenceDocumentInput,
  condition?: ModelEventSequenceDocumentConditionInput | null,
};

export type CreateEventSequenceDocumentMutation = {
  createEventSequenceDocument?:  {
    __typename: "EventSequenceDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventSequenceId: string,
    seriesId: string,
    taskId?: string | null,
    facilityId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type UpdateEventSequenceDocumentMutationVariables = {
  input?: UpdateEventSequenceDocumentInput,
  condition?: ModelEventSequenceDocumentConditionInput | null,
};

export type UpdateEventSequenceDocumentMutation = {
  updateEventSequenceDocument?:  {
    __typename: "EventSequenceDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventSequenceId: string,
    seriesId: string,
    taskId?: string | null,
    facilityId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type DeleteEventSequenceDocumentMutationVariables = {
  input?: DeleteEventSequenceDocumentInput,
  condition?: ModelEventSequenceDocumentConditionInput | null,
};

export type DeleteEventSequenceDocumentMutation = {
  deleteEventSequenceDocument?:  {
    __typename: "EventSequenceDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventSequenceId: string,
    seriesId: string,
    taskId?: string | null,
    facilityId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type CreateEventSequenceWatcherMutationVariables = {
  input?: CreateEventSequenceWatcherInput,
  condition?: ModelEventSequenceWatcherConditionInput | null,
};

export type CreateEventSequenceWatcherMutation = {
  createEventSequenceWatcher?:  {
    __typename: "EventSequenceWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventSequenceId: string,
    taskId?: string | null,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type UpdateEventSequenceWatcherMutationVariables = {
  input?: UpdateEventSequenceWatcherInput,
  condition?: ModelEventSequenceWatcherConditionInput | null,
};

export type UpdateEventSequenceWatcherMutation = {
  updateEventSequenceWatcher?:  {
    __typename: "EventSequenceWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventSequenceId: string,
    taskId?: string | null,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type DeleteEventSequenceWatcherMutationVariables = {
  input?: DeleteEventSequenceWatcherInput,
  condition?: ModelEventSequenceWatcherConditionInput | null,
};

export type DeleteEventSequenceWatcherMutation = {
  deleteEventSequenceWatcher?:  {
    __typename: "EventSequenceWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventSequenceId: string,
    taskId?: string | null,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type CreateFacilityMutationVariables = {
  input?: CreateFacilityInput,
  condition?: ModelFacilityConditionInput | null,
};

export type CreateFacilityMutation = {
  createFacility?:  {
    __typename: "Facility",
    id: string,
    owner?: string | null,
    name: string,
    status?: FacilityStatus | null,
    statusReason?: string | null,
    epaGeneratorStatus?: RcraGeneratorStatus | null,
    epaGeneratorStatusUpdatedAt?: string | null,
    epaGeneratorStatusUpdatedBy?: string | null,
    stateGeneratorStatus?: RcraGeneratorStatus | null,
    stateGeneratorStatusUpdatedAt?: string | null,
    stateGeneratorStatusUpdatedBy?: string | null,
    businessUnit?: string | null,
    customerFacilityId?: string | null,
    dbNumber?: string | null,
    facilityType?: string | null,
    naicsCode?: number | null,
    maxNumOccupants?: number | null,
    isUnmanned?: boolean | null,
    phone?: string | null,
    phone24Hour?: string | null,
    streetAddress:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    },
    mailingAddress?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    county?: string | null,
    municipality?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    isAutoGeocoded?: boolean | null,
    lepc?: string | null,
    lepcId?: string | null,
    fireDistrict?: string | null,
    fireDistrictId?: string | null,
    programAreas?: Array< string | null > | null,
    alternateIds?:  Array< {
      __typename: "FacilityAlternateId",
      kind: string,
      id: string,
      expiresAt?: string | null,
      recordKeys?: Array< string | null > | null,
    } > | null,
    userPermissions?:  Array< {
      __typename: "UserPermission",
      userTenantId: string,
      level: UserPermissionLevel,
    } | null > | null,
    dateOpened?: string | null,
    dateClosed?: string | null,
    dateInactive?: string | null,
    regulatoryInformation?:  {
      __typename: "FacilityRegulatoryInformation",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    federalFields?: string | null,
    stateFields?: string | null,
    searchableProgramAreas?: string | null,
    searchableStatus?: string | null,
    searchableCity?: string | null,
    lastProductMeasuredAt?: string | null,
    customerProductComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    streetAddressVerifiedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    contacts?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    credentials?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateFacilityMutationVariables = {
  input?: UpdateFacilityInput,
  condition?: ModelFacilityConditionInput | null,
};

export type UpdateFacilityMutation = {
  updateFacility?:  {
    __typename: "Facility",
    id: string,
    owner?: string | null,
    name: string,
    status?: FacilityStatus | null,
    statusReason?: string | null,
    epaGeneratorStatus?: RcraGeneratorStatus | null,
    epaGeneratorStatusUpdatedAt?: string | null,
    epaGeneratorStatusUpdatedBy?: string | null,
    stateGeneratorStatus?: RcraGeneratorStatus | null,
    stateGeneratorStatusUpdatedAt?: string | null,
    stateGeneratorStatusUpdatedBy?: string | null,
    businessUnit?: string | null,
    customerFacilityId?: string | null,
    dbNumber?: string | null,
    facilityType?: string | null,
    naicsCode?: number | null,
    maxNumOccupants?: number | null,
    isUnmanned?: boolean | null,
    phone?: string | null,
    phone24Hour?: string | null,
    streetAddress:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    },
    mailingAddress?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    county?: string | null,
    municipality?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    isAutoGeocoded?: boolean | null,
    lepc?: string | null,
    lepcId?: string | null,
    fireDistrict?: string | null,
    fireDistrictId?: string | null,
    programAreas?: Array< string | null > | null,
    alternateIds?:  Array< {
      __typename: "FacilityAlternateId",
      kind: string,
      id: string,
      expiresAt?: string | null,
      recordKeys?: Array< string | null > | null,
    } > | null,
    userPermissions?:  Array< {
      __typename: "UserPermission",
      userTenantId: string,
      level: UserPermissionLevel,
    } | null > | null,
    dateOpened?: string | null,
    dateClosed?: string | null,
    dateInactive?: string | null,
    regulatoryInformation?:  {
      __typename: "FacilityRegulatoryInformation",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    federalFields?: string | null,
    stateFields?: string | null,
    searchableProgramAreas?: string | null,
    searchableStatus?: string | null,
    searchableCity?: string | null,
    lastProductMeasuredAt?: string | null,
    customerProductComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    streetAddressVerifiedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    contacts?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    credentials?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateFacilityContactMutationVariables = {
  input?: CreateFacilityContactInput,
  condition?: ModelFacilityContactConditionInput | null,
};

export type CreateFacilityContactMutation = {
  createFacilityContact?:  {
    __typename: "FacilityContact",
    id: string,
    owner?: string | null,
    facilityId: string,
    personId: string,
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    areas?: Array< string > | null,
    reportingRoles?: Array< ContactReportRole > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateFacilityContactMutationVariables = {
  input?: UpdateFacilityContactInput,
  condition?: ModelFacilityContactConditionInput | null,
};

export type UpdateFacilityContactMutation = {
  updateFacilityContact?:  {
    __typename: "FacilityContact",
    id: string,
    owner?: string | null,
    facilityId: string,
    personId: string,
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    areas?: Array< string > | null,
    reportingRoles?: Array< ContactReportRole > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteFacilityContactMutationVariables = {
  input?: DeleteFacilityContactInput,
  condition?: ModelFacilityContactConditionInput | null,
};

export type DeleteFacilityContactMutation = {
  deleteFacilityContact?:  {
    __typename: "FacilityContact",
    id: string,
    owner?: string | null,
    facilityId: string,
    personId: string,
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    areas?: Array< string > | null,
    reportingRoles?: Array< ContactReportRole > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateFacilityCredentialMutationVariables = {
  input?: CreateFacilityCredentialInput,
  condition?: ModelFacilityCredentialConditionInput | null,
};

export type CreateFacilityCredentialMutation = {
  createFacilityCredential?:  {
    __typename: "FacilityCredential",
    id: string,
    owner?: string | null,
    facilityId: string,
    credentialId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credential:  {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteFacilityCredentialMutationVariables = {
  input?: DeleteFacilityCredentialInput,
  condition?: ModelFacilityCredentialConditionInput | null,
};

export type DeleteFacilityCredentialMutation = {
  deleteFacilityCredential?:  {
    __typename: "FacilityCredential",
    id: string,
    owner?: string | null,
    facilityId: string,
    credentialId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credential:  {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateFacilityMeasurementMutationVariables = {
  input?: CreateFacilityMeasurementInput,
  condition?: ModelFacilityMeasurementConditionInput | null,
};

export type CreateFacilityMeasurementMutation = {
  createFacilityMeasurement?:  {
    __typename: "FacilityMeasurement",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    amount: number,
    unit: string,
    measuredAt: string,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    createdAt: string,
    updatedAt: string,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateFacilityMeasurementMutationVariables = {
  input?: UpdateFacilityMeasurementInput,
  condition?: ModelFacilityMeasurementConditionInput | null,
};

export type UpdateFacilityMeasurementMutation = {
  updateFacilityMeasurement?:  {
    __typename: "FacilityMeasurement",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    amount: number,
    unit: string,
    measuredAt: string,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    createdAt: string,
    updatedAt: string,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteFacilityMeasurementMutationVariables = {
  input?: DeleteFacilityMeasurementInput,
  condition?: ModelFacilityMeasurementConditionInput | null,
};

export type DeleteFacilityMeasurementMutation = {
  deleteFacilityMeasurement?:  {
    __typename: "FacilityMeasurement",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    amount: number,
    unit: string,
    measuredAt: string,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    createdAt: string,
    updatedAt: string,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateFacilityProductGroupMeasurementMutationVariables = {
  input?: CreateFacilityProductGroupMeasurementInput,
  condition?: ModelFacilityProductGroupMeasurementConditionInput | null,
};

export type CreateFacilityProductGroupMeasurementMutation = {
  createFacilityProductGroupMeasurement?:  {
    __typename: "FacilityProductGroupMeasurement",
    id: string,
    facilityId: string,
    owner: string,
    productGroupId: string,
    amount: number,
    measuredAt: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateFacilityProductGroupMeasurementMutationVariables = {
  input?: UpdateFacilityProductGroupMeasurementInput,
  condition?: ModelFacilityProductGroupMeasurementConditionInput | null,
};

export type UpdateFacilityProductGroupMeasurementMutation = {
  updateFacilityProductGroupMeasurement?:  {
    __typename: "FacilityProductGroupMeasurement",
    id: string,
    facilityId: string,
    owner: string,
    productGroupId: string,
    amount: number,
    measuredAt: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteFacilityProductGroupMeasurementMutationVariables = {
  input?: DeleteFacilityProductGroupMeasurementInput,
  condition?: ModelFacilityProductGroupMeasurementConditionInput | null,
};

export type DeleteFacilityProductGroupMeasurementMutation = {
  deleteFacilityProductGroupMeasurement?:  {
    __typename: "FacilityProductGroupMeasurement",
    id: string,
    facilityId: string,
    owner: string,
    productGroupId: string,
    amount: number,
    measuredAt: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateFacilityProductMutationVariables = {
  input?: CreateFacilityProductInput,
  condition?: ModelFacilityProductConditionInput | null,
};

export type CreateFacilityProductMutation = {
  createFacilityProduct?:  {
    __typename: "FacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    chemName: string,
    casNumber?: string | null,
    alternateId?: string | null,
    isEHS?: boolean | null,
    unit?: string | null,
    density?: number | null,
    densityUnits?: string | null,
    lastAmount?: number | null,
    maxAmount?: number | null,
    averageAmount?: number | null,
    totalMeasurements?: number | null,
    totalLocations?: number | null,
    isAboveReportingThresholds?: boolean | null,
    lastProductMeasuredAt?: string | null,
    daysOnSite?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateFacilityProductMutationVariables = {
  input?: UpdateFacilityProductInput,
  condition?: ModelFacilityProductConditionInput | null,
};

export type UpdateFacilityProductMutation = {
  updateFacilityProduct?:  {
    __typename: "FacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    chemName: string,
    casNumber?: string | null,
    alternateId?: string | null,
    isEHS?: boolean | null,
    unit?: string | null,
    density?: number | null,
    densityUnits?: string | null,
    lastAmount?: number | null,
    maxAmount?: number | null,
    averageAmount?: number | null,
    totalMeasurements?: number | null,
    totalLocations?: number | null,
    isAboveReportingThresholds?: boolean | null,
    lastProductMeasuredAt?: string | null,
    daysOnSite?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteFacilityProductMutationVariables = {
  input?: DeleteFacilityProductInput,
  condition?: ModelFacilityProductConditionInput | null,
};

export type DeleteFacilityProductMutation = {
  deleteFacilityProduct?:  {
    __typename: "FacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    chemName: string,
    casNumber?: string | null,
    alternateId?: string | null,
    isEHS?: boolean | null,
    unit?: string | null,
    density?: number | null,
    densityUnits?: string | null,
    lastAmount?: number | null,
    maxAmount?: number | null,
    averageAmount?: number | null,
    totalMeasurements?: number | null,
    totalLocations?: number | null,
    isAboveReportingThresholds?: boolean | null,
    lastProductMeasuredAt?: string | null,
    daysOnSite?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateFacilityRequirementMutationVariables = {
  input?: CreateFacilityRequirementInput,
  condition?: ModelFacilityRequirementConditionInput | null,
};

export type CreateFacilityRequirementMutation = {
  createFacilityRequirement?:  {
    __typename: "FacilityRequirement",
    id: string,
    owner?: string | null,
    facilityId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteFacilityRequirementMutationVariables = {
  input?: DeleteFacilityRequirementInput,
  condition?: ModelFacilityRequirementConditionInput | null,
};

export type DeleteFacilityRequirementMutation = {
  deleteFacilityRequirement?:  {
    __typename: "FacilityRequirement",
    id: string,
    owner?: string | null,
    facilityId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateLockMutationVariables = {
  input?: UpdateLockInput,
  condition?: ModelLockConditionInput | null,
};

export type UpdateLockMutation = {
  updateLock?:  {
    __typename: "Lock",
    id: string,
    description?: string | null,
    expiresAt: number,
    holder: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteLockMutationVariables = {
  input?: DeleteLockInput,
  condition?: ModelLockConditionInput | null,
};

export type DeleteLockMutation = {
  deleteLock?:  {
    __typename: "Lock",
    id: string,
    description?: string | null,
    expiresAt: number,
    holder: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateMailTrackingMutationVariables = {
  input?: CreateMailTrackingInput,
  condition?: ModelMailTrackingConditionInput | null,
};

export type CreateMailTrackingMutation = {
  createMailTracking?:  {
    __typename: "MailTracking",
    id: string,
    type: MailTrackingType,
    status?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateMailTrackingMutationVariables = {
  input?: UpdateMailTrackingInput,
  condition?: ModelMailTrackingConditionInput | null,
};

export type UpdateMailTrackingMutation = {
  updateMailTracking?:  {
    __typename: "MailTracking",
    id: string,
    type: MailTrackingType,
    status?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteMailTrackingMutationVariables = {
  input?: DeleteMailTrackingInput,
  condition?: ModelMailTrackingConditionInput | null,
};

export type DeleteMailTrackingMutation = {
  deleteMailTracking?:  {
    __typename: "MailTracking",
    id: string,
    type: MailTrackingType,
    status?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateMergeRecordMutationVariables = {
  input?: CreateMergeRecordInput,
  condition?: ModelMergeRecordConditionInput | null,
};

export type CreateMergeRecordMutation = {
  createMergeRecord?:  {
    __typename: "MergeRecord",
    id: string,
    owner?: string | null,
    mergedAt: string,
    mergedBy: string,
    typeName: string,
    winningId: string,
    losingId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateMergeRecordMutationVariables = {
  input?: UpdateMergeRecordInput,
  condition?: ModelMergeRecordConditionInput | null,
};

export type UpdateMergeRecordMutation = {
  updateMergeRecord?:  {
    __typename: "MergeRecord",
    id: string,
    owner?: string | null,
    mergedAt: string,
    mergedBy: string,
    typeName: string,
    winningId: string,
    losingId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteMergeRecordMutationVariables = {
  input?: DeleteMergeRecordInput,
  condition?: ModelMergeRecordConditionInput | null,
};

export type DeleteMergeRecordMutation = {
  deleteMergeRecord?:  {
    __typename: "MergeRecord",
    id: string,
    owner?: string | null,
    mergedAt: string,
    mergedBy: string,
    typeName: string,
    winningId: string,
    losingId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input?: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    parentId?: string | null,
    personId?: string | null,
    readAt?: string | null,
    lifecycleStatus: NotificationLifecycleStatus,
    scheduledFor: string,
    activeAt?: string | null,
    archivedAt?: string | null,
    name: string,
    description?: string | null,
    emailParams?:  {
      __typename: "SendEmailParams",
      from?: string | null,
      to?: string | null,
      subject?: string | null,
      replyTo?: string | null,
      text?: string | null,
      linkText?: string | null,
      hyperlink?: string | null,
    } | null,
    eventContent?:  {
      __typename: "NotificationEventContent",
      area?: string | null,
      deadline?: string | null,
      seriesId?: string | null,
      type?: EventSequenceType | null,
    } | null,
    rcraStatusChangeContent?:  {
      __typename: "RcraStatusChangeContent",
      generatorStatus?: RcraGeneratorStatus | null,
      changedFromManifest?: boolean | null,
    } | null,
    sdsExtractionContent?:  {
      __typename: "SdsExtractionContent",
      productId?: string | null,
      chemName?: string | null,
    } | null,
    facilityId?: string | null,
    notificationTypeSearchable?: string | null,
    lifecycleStatusSearchable?: string | null,
    areaSearchable?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelNotificationFacilityLinkConnection",
      items?:  Array< {
        __typename: "NotificationFacilityLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        facilityId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelNotificationRequirementLinkConnection",
      items?:  Array< {
        __typename: "NotificationRequirementLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input?: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    parentId?: string | null,
    personId?: string | null,
    readAt?: string | null,
    lifecycleStatus: NotificationLifecycleStatus,
    scheduledFor: string,
    activeAt?: string | null,
    archivedAt?: string | null,
    name: string,
    description?: string | null,
    emailParams?:  {
      __typename: "SendEmailParams",
      from?: string | null,
      to?: string | null,
      subject?: string | null,
      replyTo?: string | null,
      text?: string | null,
      linkText?: string | null,
      hyperlink?: string | null,
    } | null,
    eventContent?:  {
      __typename: "NotificationEventContent",
      area?: string | null,
      deadline?: string | null,
      seriesId?: string | null,
      type?: EventSequenceType | null,
    } | null,
    rcraStatusChangeContent?:  {
      __typename: "RcraStatusChangeContent",
      generatorStatus?: RcraGeneratorStatus | null,
      changedFromManifest?: boolean | null,
    } | null,
    sdsExtractionContent?:  {
      __typename: "SdsExtractionContent",
      productId?: string | null,
      chemName?: string | null,
    } | null,
    facilityId?: string | null,
    notificationTypeSearchable?: string | null,
    lifecycleStatusSearchable?: string | null,
    areaSearchable?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelNotificationFacilityLinkConnection",
      items?:  Array< {
        __typename: "NotificationFacilityLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        facilityId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelNotificationRequirementLinkConnection",
      items?:  Array< {
        __typename: "NotificationRequirementLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input?: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    parentId?: string | null,
    personId?: string | null,
    readAt?: string | null,
    lifecycleStatus: NotificationLifecycleStatus,
    scheduledFor: string,
    activeAt?: string | null,
    archivedAt?: string | null,
    name: string,
    description?: string | null,
    emailParams?:  {
      __typename: "SendEmailParams",
      from?: string | null,
      to?: string | null,
      subject?: string | null,
      replyTo?: string | null,
      text?: string | null,
      linkText?: string | null,
      hyperlink?: string | null,
    } | null,
    eventContent?:  {
      __typename: "NotificationEventContent",
      area?: string | null,
      deadline?: string | null,
      seriesId?: string | null,
      type?: EventSequenceType | null,
    } | null,
    rcraStatusChangeContent?:  {
      __typename: "RcraStatusChangeContent",
      generatorStatus?: RcraGeneratorStatus | null,
      changedFromManifest?: boolean | null,
    } | null,
    sdsExtractionContent?:  {
      __typename: "SdsExtractionContent",
      productId?: string | null,
      chemName?: string | null,
    } | null,
    facilityId?: string | null,
    notificationTypeSearchable?: string | null,
    lifecycleStatusSearchable?: string | null,
    areaSearchable?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelNotificationFacilityLinkConnection",
      items?:  Array< {
        __typename: "NotificationFacilityLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        facilityId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelNotificationRequirementLinkConnection",
      items?:  Array< {
        __typename: "NotificationRequirementLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateNotificationFacilityLinkMutationVariables = {
  input?: CreateNotificationFacilityLinkInput,
  condition?: ModelNotificationFacilityLinkConditionInput | null,
};

export type CreateNotificationFacilityLinkMutation = {
  createNotificationFacilityLink?:  {
    __typename: "NotificationFacilityLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    facilityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateNotificationFacilityLinkMutationVariables = {
  input?: UpdateNotificationFacilityLinkInput,
  condition?: ModelNotificationFacilityLinkConditionInput | null,
};

export type UpdateNotificationFacilityLinkMutation = {
  updateNotificationFacilityLink?:  {
    __typename: "NotificationFacilityLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    facilityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteNotificationFacilityLinkMutationVariables = {
  input?: DeleteNotificationFacilityLinkInput,
  condition?: ModelNotificationFacilityLinkConditionInput | null,
};

export type DeleteNotificationFacilityLinkMutation = {
  deleteNotificationFacilityLink?:  {
    __typename: "NotificationFacilityLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    facilityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateNotificationRequirementLinkMutationVariables = {
  input?: CreateNotificationRequirementLinkInput,
  condition?: ModelNotificationRequirementLinkConditionInput | null,
};

export type CreateNotificationRequirementLinkMutation = {
  createNotificationRequirementLink?:  {
    __typename: "NotificationRequirementLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateNotificationRequirementLinkMutationVariables = {
  input?: UpdateNotificationRequirementLinkInput,
  condition?: ModelNotificationRequirementLinkConditionInput | null,
};

export type UpdateNotificationRequirementLinkMutation = {
  updateNotificationRequirementLink?:  {
    __typename: "NotificationRequirementLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteNotificationRequirementLinkMutationVariables = {
  input?: DeleteNotificationRequirementLinkInput,
  condition?: ModelNotificationRequirementLinkConditionInput | null,
};

export type DeleteNotificationRequirementLinkMutation = {
  deleteNotificationRequirementLink?:  {
    __typename: "NotificationRequirementLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreatePaymentMutationVariables = {
  input?: CreatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type CreatePaymentMutation = {
  createPayment?:  {
    __typename: "Payment",
    id: string,
    owner: string,
    paymentFacilityIds?: Array< string > | null,
    paymentReferences:  Array<( {
        __typename: "TierIIReportPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
        reportingYear: number,
      } | {
        __typename: "FacilityPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
      }
    ) > | null,
    status: PaymentStatus,
    invoices?:  Array< {
      __typename: "PaymentInvoice",
      id?: string | null,
      payee?: string | null,
      invoiceNumber?: string | null,
      paymentInvoiceDocumentId?: string | null,
      dateDue?: string | null,
      amount?: number | null,
      description?: string | null,
    } > | null,
    paymentMethod?: PaymentMethod | null,
    amount?: number | null,
    description?: string | null,
    memo?: string | null,
    payee?: string | null,
    paidAt?: string | null,
    recordedBy?: string | null,
    paymentDocumentID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    activityId?: string | null,
    payeeType?: string | null,
    company?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input?: UpdatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment?:  {
    __typename: "Payment",
    id: string,
    owner: string,
    paymentFacilityIds?: Array< string > | null,
    paymentReferences:  Array<( {
        __typename: "TierIIReportPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
        reportingYear: number,
      } | {
        __typename: "FacilityPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
      }
    ) > | null,
    status: PaymentStatus,
    invoices?:  Array< {
      __typename: "PaymentInvoice",
      id?: string | null,
      payee?: string | null,
      invoiceNumber?: string | null,
      paymentInvoiceDocumentId?: string | null,
      dateDue?: string | null,
      amount?: number | null,
      description?: string | null,
    } > | null,
    paymentMethod?: PaymentMethod | null,
    amount?: number | null,
    description?: string | null,
    memo?: string | null,
    payee?: string | null,
    paidAt?: string | null,
    recordedBy?: string | null,
    paymentDocumentID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    activityId?: string | null,
    payeeType?: string | null,
    company?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input?: DeletePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type DeletePaymentMutation = {
  deletePayment?:  {
    __typename: "Payment",
    id: string,
    owner: string,
    paymentFacilityIds?: Array< string > | null,
    paymentReferences:  Array<( {
        __typename: "TierIIReportPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
        reportingYear: number,
      } | {
        __typename: "FacilityPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
      }
    ) > | null,
    status: PaymentStatus,
    invoices?:  Array< {
      __typename: "PaymentInvoice",
      id?: string | null,
      payee?: string | null,
      invoiceNumber?: string | null,
      paymentInvoiceDocumentId?: string | null,
      dateDue?: string | null,
      amount?: number | null,
      description?: string | null,
    } > | null,
    paymentMethod?: PaymentMethod | null,
    amount?: number | null,
    description?: string | null,
    memo?: string | null,
    payee?: string | null,
    paidAt?: string | null,
    recordedBy?: string | null,
    paymentDocumentID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    activityId?: string | null,
    payeeType?: string | null,
    company?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
  } | null,
};

export type CreatePersonMutationVariables = {
  input?: CreatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type CreatePersonMutation = {
  createPerson?:  {
    __typename: "Person",
    id: string,
    owner?: string | null,
    first?: string | null,
    last?: string | null,
    email?: string | null,
    title?: string | null,
    company?: string | null,
    address?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    phones:  Array< {
      __typename: "Phone",
      type: PhoneType,
      reportTypes?: Array< ReportType > | null,
      number: string,
    } >,
    contactType?: ContactType | null,
    searchableProgramAreas?: string | null,
    searchableFacilities?: string | null,
    searchableContactTypes?: string | null,
    userTenantId?: string | null,
    paymentMethodId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facilities?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    userTenant?:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePersonMutationVariables = {
  input?: UpdatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type UpdatePersonMutation = {
  updatePerson?:  {
    __typename: "Person",
    id: string,
    owner?: string | null,
    first?: string | null,
    last?: string | null,
    email?: string | null,
    title?: string | null,
    company?: string | null,
    address?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    phones:  Array< {
      __typename: "Phone",
      type: PhoneType,
      reportTypes?: Array< ReportType > | null,
      number: string,
    } >,
    contactType?: ContactType | null,
    searchableProgramAreas?: string | null,
    searchableFacilities?: string | null,
    searchableContactTypes?: string | null,
    userTenantId?: string | null,
    paymentMethodId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facilities?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    userTenant?:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePersonMutationVariables = {
  input?: DeletePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type DeletePersonMutation = {
  deletePerson?:  {
    __typename: "Person",
    id: string,
    owner?: string | null,
    first?: string | null,
    last?: string | null,
    email?: string | null,
    title?: string | null,
    company?: string | null,
    address?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    phones:  Array< {
      __typename: "Phone",
      type: PhoneType,
      reportTypes?: Array< ReportType > | null,
      number: string,
    } >,
    contactType?: ContactType | null,
    searchableProgramAreas?: string | null,
    searchableFacilities?: string | null,
    searchableContactTypes?: string | null,
    userTenantId?: string | null,
    paymentMethodId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facilities?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    userTenant?:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePortalInteractionRecordMutationVariables = {
  input?: CreatePortalInteractionRecordInput,
  condition?: ModelPortalInteractionRecordConditionInput | null,
};

export type CreatePortalInteractionRecordMutation = {
  createPortalInteractionRecord?:  {
    __typename: "PortalInteractionRecord",
    id: string,
    owner?: string | null,
    requestId?: string | null,
    facilityId?: string | null,
    encampRequestId?: string | null,
    credentialId?: string | null,
    credentialUsername?: string | null,
    stateOrTerritory?: string | null,
    status?: PortalInteractionStatus | null,
    log?:  Array< {
      __typename: "PortalInteractionLog",
      status: PortalInteractionStatus,
      at: string,
      errorMessage?: string | null,
      cloudWatchLogStream?: string | null,
      screenshotKey?: string | null,
    } | null > | null,
    action?: PortalInteractionAction | null,
    forceClassicSubmissionStrategy?: boolean | null,
    evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
    stepsDone?: Array< string > | null,
    stepError?: string | null,
    reportId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
  } | null,
};

export type UpdatePortalInteractionRecordMutationVariables = {
  input?: UpdatePortalInteractionRecordInput,
  condition?: ModelPortalInteractionRecordConditionInput | null,
};

export type UpdatePortalInteractionRecordMutation = {
  updatePortalInteractionRecord?:  {
    __typename: "PortalInteractionRecord",
    id: string,
    owner?: string | null,
    requestId?: string | null,
    facilityId?: string | null,
    encampRequestId?: string | null,
    credentialId?: string | null,
    credentialUsername?: string | null,
    stateOrTerritory?: string | null,
    status?: PortalInteractionStatus | null,
    log?:  Array< {
      __typename: "PortalInteractionLog",
      status: PortalInteractionStatus,
      at: string,
      errorMessage?: string | null,
      cloudWatchLogStream?: string | null,
      screenshotKey?: string | null,
    } | null > | null,
    action?: PortalInteractionAction | null,
    forceClassicSubmissionStrategy?: boolean | null,
    evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
    stepsDone?: Array< string > | null,
    stepError?: string | null,
    reportId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
  } | null,
};

export type DeletePortalInteractionRecordMutationVariables = {
  input?: DeletePortalInteractionRecordInput,
  condition?: ModelPortalInteractionRecordConditionInput | null,
};

export type DeletePortalInteractionRecordMutation = {
  deletePortalInteractionRecord?:  {
    __typename: "PortalInteractionRecord",
    id: string,
    owner?: string | null,
    requestId?: string | null,
    facilityId?: string | null,
    encampRequestId?: string | null,
    credentialId?: string | null,
    credentialUsername?: string | null,
    stateOrTerritory?: string | null,
    status?: PortalInteractionStatus | null,
    log?:  Array< {
      __typename: "PortalInteractionLog",
      status: PortalInteractionStatus,
      at: string,
      errorMessage?: string | null,
      cloudWatchLogStream?: string | null,
      screenshotKey?: string | null,
    } | null > | null,
    action?: PortalInteractionAction | null,
    forceClassicSubmissionStrategy?: boolean | null,
    evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
    stepsDone?: Array< string > | null,
    stepError?: string | null,
    reportId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
  } | null,
};

export type CreateProgramAreaMutationVariables = {
  input?: CreateProgramAreaInput,
  condition?: ModelProgramAreaConditionInput | null,
};

export type CreateProgramAreaMutation = {
  createProgramArea?:  {
    __typename: "ProgramArea",
    id: string,
    owner?: string | null,
    name: string,
    description?: string | null,
    icon?: string | null,
    isDisabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateProgramAreaMutationVariables = {
  input?: UpdateProgramAreaInput,
  condition?: ModelProgramAreaConditionInput | null,
};

export type UpdateProgramAreaMutation = {
  updateProgramArea?:  {
    __typename: "ProgramArea",
    id: string,
    owner?: string | null,
    name: string,
    description?: string | null,
    icon?: string | null,
    isDisabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteProgramAreaMutationVariables = {
  input?: DeleteProgramAreaInput,
  condition?: ModelProgramAreaConditionInput | null,
};

export type DeleteProgramAreaMutation = {
  deleteProgramArea?:  {
    __typename: "ProgramArea",
    id: string,
    owner?: string | null,
    name: string,
    description?: string | null,
    icon?: string | null,
    isDisabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateRcraForm870012MutationVariables = {
  input?: CreateRcraForm870012Input,
  condition?: ModelRcraForm870012ConditionInput | null,
};

export type CreateRcraForm870012Mutation = {
  createRcraForm870012?:  {
    __typename: "RcraForm870012",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    submissionType?: RcraSubmissionType | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateRcraForm870012MutationVariables = {
  input?: UpdateRcraForm870012Input,
  condition?: ModelRcraForm870012ConditionInput | null,
};

export type UpdateRcraForm870012Mutation = {
  updateRcraForm870012?:  {
    __typename: "RcraForm870012",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    submissionType?: RcraSubmissionType | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteRcraForm870012MutationVariables = {
  input?: DeleteRcraForm870012Input,
  condition?: ModelRcraForm870012ConditionInput | null,
};

export type DeleteRcraForm870012Mutation = {
  deleteRcraForm870012?:  {
    __typename: "RcraForm870012",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    submissionType?: RcraSubmissionType | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateRcraForm870013MutationVariables = {
  input?: CreateRcraForm870013Input,
  condition?: ModelRcraForm870013ConditionInput | null,
};

export type CreateRcraForm870013Mutation = {
  createRcraForm870013?:  {
    __typename: "RcraForm870013",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    reportingPeriod?: string | null,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    gm?:  {
      __typename: "RcraGenerationManagementForm",
      forms?:  Array< {
        __typename: "RcraGenerationManagementWasteStream",
        id?: string | null,
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        sourceCode?: string | null,
        managementMethod?: string | null,
        countryCode?: string | null,
        formCode?: string | null,
        wasteMinimizationCode?: string | null,
        radioactiveMixed?: boolean | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        wasteWasProcessedOnSite?: boolean | null,
        onSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOnSiteProcess",
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        wasteWasShippedOffSite?: boolean | null,
        offSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOffSiteProcess",
          shippedEpaId?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        comments?: string | null,
        status?: RcraFormStatus | null,
      } > | null,
    } | null,
    oi?:  {
      __typename: "RcraOffSiteIdentificationForm",
      sites?:  Array< {
        __typename: "RcraOffSiteInstallationOrTransporter",
        epaId?: string | null,
        name?: string | null,
        handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
        installationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
      } | null > | null,
      comments?: string | null,
    } | null,
    wr?:  {
      __typename: "RcraWasteReceivedForm",
      wastes?:  Array< {
        __typename: "RcraWasteReceivedWasteStream",
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        epaIdNumber?: string | null,
        formCode?: string | null,
        managementMethod?: string | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
      } > | null,
      comments?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type UpdateRcraForm870013MutationVariables = {
  input?: UpdateRcraForm870013Input,
  condition?: ModelRcraForm870013ConditionInput | null,
};

export type UpdateRcraForm870013Mutation = {
  updateRcraForm870013?:  {
    __typename: "RcraForm870013",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    reportingPeriod?: string | null,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    gm?:  {
      __typename: "RcraGenerationManagementForm",
      forms?:  Array< {
        __typename: "RcraGenerationManagementWasteStream",
        id?: string | null,
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        sourceCode?: string | null,
        managementMethod?: string | null,
        countryCode?: string | null,
        formCode?: string | null,
        wasteMinimizationCode?: string | null,
        radioactiveMixed?: boolean | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        wasteWasProcessedOnSite?: boolean | null,
        onSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOnSiteProcess",
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        wasteWasShippedOffSite?: boolean | null,
        offSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOffSiteProcess",
          shippedEpaId?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        comments?: string | null,
        status?: RcraFormStatus | null,
      } > | null,
    } | null,
    oi?:  {
      __typename: "RcraOffSiteIdentificationForm",
      sites?:  Array< {
        __typename: "RcraOffSiteInstallationOrTransporter",
        epaId?: string | null,
        name?: string | null,
        handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
        installationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
      } | null > | null,
      comments?: string | null,
    } | null,
    wr?:  {
      __typename: "RcraWasteReceivedForm",
      wastes?:  Array< {
        __typename: "RcraWasteReceivedWasteStream",
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        epaIdNumber?: string | null,
        formCode?: string | null,
        managementMethod?: string | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
      } > | null,
      comments?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type DeleteRcraForm870013MutationVariables = {
  input?: DeleteRcraForm870013Input,
  condition?: ModelRcraForm870013ConditionInput | null,
};

export type DeleteRcraForm870013Mutation = {
  deleteRcraForm870013?:  {
    __typename: "RcraForm870013",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    reportingPeriod?: string | null,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    gm?:  {
      __typename: "RcraGenerationManagementForm",
      forms?:  Array< {
        __typename: "RcraGenerationManagementWasteStream",
        id?: string | null,
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        sourceCode?: string | null,
        managementMethod?: string | null,
        countryCode?: string | null,
        formCode?: string | null,
        wasteMinimizationCode?: string | null,
        radioactiveMixed?: boolean | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        wasteWasProcessedOnSite?: boolean | null,
        onSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOnSiteProcess",
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        wasteWasShippedOffSite?: boolean | null,
        offSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOffSiteProcess",
          shippedEpaId?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        comments?: string | null,
        status?: RcraFormStatus | null,
      } > | null,
    } | null,
    oi?:  {
      __typename: "RcraOffSiteIdentificationForm",
      sites?:  Array< {
        __typename: "RcraOffSiteInstallationOrTransporter",
        epaId?: string | null,
        name?: string | null,
        handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
        installationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
      } | null > | null,
      comments?: string | null,
    } | null,
    wr?:  {
      __typename: "RcraWasteReceivedForm",
      wastes?:  Array< {
        __typename: "RcraWasteReceivedWasteStream",
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        epaIdNumber?: string | null,
        formCode?: string | null,
        managementMethod?: string | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
      } > | null,
      comments?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type CreateRequirementMutationVariables = {
  input?: CreateRequirementInput,
  condition?: ModelRequirementConditionInput | null,
};

export type CreateRequirementMutation = {
  createRequirement?:  {
    __typename: "Requirement",
    id: string,
    owner?: string | null,
    status?: RequirementStatus | null,
    name: string,
    description?: string | null,
    frequency?:  {
      __typename: "RepeatRule",
      frequency: Frequency,
      interval: number,
      start?: string | null,
    } | null,
    agencyId: string,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    area: string,
    areaSearchable: string,
    jurisdictionalAgency?: string | null,
    intervalInWords?: string | null,
    parentStatute?: ParentStatute | null,
    jurisdictionalState?: string | null,
    jurisdictionalCounties?: Array< string | null > | null,
    title?: string | null,
    portals?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    jurisdictionalTownship?: string | null,
    jurisdictionalTribalArea?: string | null,
    jurisdictionalCity?: string | null,
    formExpires?: string | null,
    forms?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    fillableLinks?: Array< string | null > | null,
    otherLinks?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    instructionFileName?: string | null,
    instruction?: string | null,
    extraDocumentations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    department?: string | null,
    subDepartment?: string | null,
    contactEmail?: string | null,
    contactPhone?: string | null,
    contactPhoneExtension?: number | null,
    isExtensionAvailable?: boolean | null,
    extensionNotes?: string | null,
    penalty?: string | null,
    encampInstructions?: string | null,
    isAlwaysApplicable?: boolean | null,
    agencyLevelSearchable?: string | null,
    agencyStateSearchable?: string | null,
    userAgencyTitle?: string | null,
    tasks?:  Array< {
      __typename: "Task",
      isCompleted: boolean,
      text: string,
    } | null > | null,
    hyperlinks?:  Array< {
      __typename: "HyperLink",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    agency?:  {
      __typename: "Agency",
      id: string,
      owner?: string | null,
      name: string,
      level: AgencyLevel,
      link?:  {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null,
      jurisdiction: string,
      state?: StateOrTerritory | null,
      locale?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateRequirementMutationVariables = {
  input?: UpdateRequirementInput,
  condition?: ModelRequirementConditionInput | null,
};

export type UpdateRequirementMutation = {
  updateRequirement?:  {
    __typename: "Requirement",
    id: string,
    owner?: string | null,
    status?: RequirementStatus | null,
    name: string,
    description?: string | null,
    frequency?:  {
      __typename: "RepeatRule",
      frequency: Frequency,
      interval: number,
      start?: string | null,
    } | null,
    agencyId: string,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    area: string,
    areaSearchable: string,
    jurisdictionalAgency?: string | null,
    intervalInWords?: string | null,
    parentStatute?: ParentStatute | null,
    jurisdictionalState?: string | null,
    jurisdictionalCounties?: Array< string | null > | null,
    title?: string | null,
    portals?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    jurisdictionalTownship?: string | null,
    jurisdictionalTribalArea?: string | null,
    jurisdictionalCity?: string | null,
    formExpires?: string | null,
    forms?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    fillableLinks?: Array< string | null > | null,
    otherLinks?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    instructionFileName?: string | null,
    instruction?: string | null,
    extraDocumentations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    department?: string | null,
    subDepartment?: string | null,
    contactEmail?: string | null,
    contactPhone?: string | null,
    contactPhoneExtension?: number | null,
    isExtensionAvailable?: boolean | null,
    extensionNotes?: string | null,
    penalty?: string | null,
    encampInstructions?: string | null,
    isAlwaysApplicable?: boolean | null,
    agencyLevelSearchable?: string | null,
    agencyStateSearchable?: string | null,
    userAgencyTitle?: string | null,
    tasks?:  Array< {
      __typename: "Task",
      isCompleted: boolean,
      text: string,
    } | null > | null,
    hyperlinks?:  Array< {
      __typename: "HyperLink",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    agency?:  {
      __typename: "Agency",
      id: string,
      owner?: string | null,
      name: string,
      level: AgencyLevel,
      link?:  {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null,
      jurisdiction: string,
      state?: StateOrTerritory | null,
      locale?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteRequirementMutationVariables = {
  input?: DeleteRequirementInput,
  condition?: ModelRequirementConditionInput | null,
};

export type DeleteRequirementMutation = {
  deleteRequirement?:  {
    __typename: "Requirement",
    id: string,
    owner?: string | null,
    status?: RequirementStatus | null,
    name: string,
    description?: string | null,
    frequency?:  {
      __typename: "RepeatRule",
      frequency: Frequency,
      interval: number,
      start?: string | null,
    } | null,
    agencyId: string,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    area: string,
    areaSearchable: string,
    jurisdictionalAgency?: string | null,
    intervalInWords?: string | null,
    parentStatute?: ParentStatute | null,
    jurisdictionalState?: string | null,
    jurisdictionalCounties?: Array< string | null > | null,
    title?: string | null,
    portals?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    jurisdictionalTownship?: string | null,
    jurisdictionalTribalArea?: string | null,
    jurisdictionalCity?: string | null,
    formExpires?: string | null,
    forms?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    fillableLinks?: Array< string | null > | null,
    otherLinks?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    instructionFileName?: string | null,
    instruction?: string | null,
    extraDocumentations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    department?: string | null,
    subDepartment?: string | null,
    contactEmail?: string | null,
    contactPhone?: string | null,
    contactPhoneExtension?: number | null,
    isExtensionAvailable?: boolean | null,
    extensionNotes?: string | null,
    penalty?: string | null,
    encampInstructions?: string | null,
    isAlwaysApplicable?: boolean | null,
    agencyLevelSearchable?: string | null,
    agencyStateSearchable?: string | null,
    userAgencyTitle?: string | null,
    tasks?:  Array< {
      __typename: "Task",
      isCompleted: boolean,
      text: string,
    } | null > | null,
    hyperlinks?:  Array< {
      __typename: "HyperLink",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    agency?:  {
      __typename: "Agency",
      id: string,
      owner?: string | null,
      name: string,
      level: AgencyLevel,
      link?:  {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null,
      jurisdiction: string,
      state?: StateOrTerritory | null,
      locale?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateProductReviewMutationVariables = {
  input?: CreateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type CreateProductReviewMutation = {
  createProductReview?:  {
    __typename: "ProductReview",
    id: string,
    owner?: string | null,
    productId?: string | null,
    alternateId: string,
    sdsExtractionJobId?: string | null,
    isExempt?: boolean | null,
    recommendedUse?: string | null,
    reviewedAt?: string | null,
    reviewerRetoolUsername?: string | null,
    status?: ReviewStatus | null,
    needsReview?: boolean | null,
    isUpdate?: boolean | null,
    fields:  Array<( {
        __typename: "TierIIMixtureComponentsFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        newComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        acceptedComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
      } | {
        __typename: "StringFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldString?: string | null,
        newString?: string | null,
        acceptedString?: string | null,
      } | {
        __typename: "IntFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldInt?: number | null,
        newInt?: number | null,
        acceptedInt?: number | null,
      } | {
        __typename: "FloatFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldFloat?: number | null,
        newFloat?: number | null,
        acceptedFloat?: number | null,
      } | {
        __typename: "BooleanFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldBoolean?: boolean | null,
        newBoolean?: boolean | null,
        acceptedBoolean?: boolean | null,
      }
    ) > | null,
    sdsFilename?: string | null,
    sdsBoxFileId?: string | null,
    sdsBoxPath?: string | null,
    sdsS3Key?: string | null,
    extractionResultsS3Key?: string | null,
    extractionMessages?:  Array< {
      __typename: "ExtractionMessage",
      type: ExtractionMessageType,
      message: string,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sdsExtractionJob?:  {
      __typename: "SdsExtractionJob",
      id: string,
      owner?: string | null,
      boxFolderId?: string | null,
      boxFolderPath?: string | null,
      fileCount?: number | null,
      processedFileCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateProductReviewMutationVariables = {
  input?: UpdateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type UpdateProductReviewMutation = {
  updateProductReview?:  {
    __typename: "ProductReview",
    id: string,
    owner?: string | null,
    productId?: string | null,
    alternateId: string,
    sdsExtractionJobId?: string | null,
    isExempt?: boolean | null,
    recommendedUse?: string | null,
    reviewedAt?: string | null,
    reviewerRetoolUsername?: string | null,
    status?: ReviewStatus | null,
    needsReview?: boolean | null,
    isUpdate?: boolean | null,
    fields:  Array<( {
        __typename: "TierIIMixtureComponentsFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        newComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        acceptedComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
      } | {
        __typename: "StringFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldString?: string | null,
        newString?: string | null,
        acceptedString?: string | null,
      } | {
        __typename: "IntFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldInt?: number | null,
        newInt?: number | null,
        acceptedInt?: number | null,
      } | {
        __typename: "FloatFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldFloat?: number | null,
        newFloat?: number | null,
        acceptedFloat?: number | null,
      } | {
        __typename: "BooleanFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldBoolean?: boolean | null,
        newBoolean?: boolean | null,
        acceptedBoolean?: boolean | null,
      }
    ) > | null,
    sdsFilename?: string | null,
    sdsBoxFileId?: string | null,
    sdsBoxPath?: string | null,
    sdsS3Key?: string | null,
    extractionResultsS3Key?: string | null,
    extractionMessages?:  Array< {
      __typename: "ExtractionMessage",
      type: ExtractionMessageType,
      message: string,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sdsExtractionJob?:  {
      __typename: "SdsExtractionJob",
      id: string,
      owner?: string | null,
      boxFolderId?: string | null,
      boxFolderPath?: string | null,
      fileCount?: number | null,
      processedFileCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteProductReviewMutationVariables = {
  input?: DeleteProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type DeleteProductReviewMutation = {
  deleteProductReview?:  {
    __typename: "ProductReview",
    id: string,
    owner?: string | null,
    productId?: string | null,
    alternateId: string,
    sdsExtractionJobId?: string | null,
    isExempt?: boolean | null,
    recommendedUse?: string | null,
    reviewedAt?: string | null,
    reviewerRetoolUsername?: string | null,
    status?: ReviewStatus | null,
    needsReview?: boolean | null,
    isUpdate?: boolean | null,
    fields:  Array<( {
        __typename: "TierIIMixtureComponentsFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        newComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        acceptedComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
      } | {
        __typename: "StringFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldString?: string | null,
        newString?: string | null,
        acceptedString?: string | null,
      } | {
        __typename: "IntFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldInt?: number | null,
        newInt?: number | null,
        acceptedInt?: number | null,
      } | {
        __typename: "FloatFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldFloat?: number | null,
        newFloat?: number | null,
        acceptedFloat?: number | null,
      } | {
        __typename: "BooleanFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldBoolean?: boolean | null,
        newBoolean?: boolean | null,
        acceptedBoolean?: boolean | null,
      }
    ) > | null,
    sdsFilename?: string | null,
    sdsBoxFileId?: string | null,
    sdsBoxPath?: string | null,
    sdsS3Key?: string | null,
    extractionResultsS3Key?: string | null,
    extractionMessages?:  Array< {
      __typename: "ExtractionMessage",
      type: ExtractionMessageType,
      message: string,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sdsExtractionJob?:  {
      __typename: "SdsExtractionJob",
      id: string,
      owner?: string | null,
      boxFolderId?: string | null,
      boxFolderPath?: string | null,
      fileCount?: number | null,
      processedFileCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateSdsExtractionJobMutationVariables = {
  input?: CreateSdsExtractionJobInput,
  condition?: ModelSdsExtractionJobConditionInput | null,
};

export type CreateSdsExtractionJobMutation = {
  createSdsExtractionJob?:  {
    __typename: "SdsExtractionJob",
    id: string,
    owner?: string | null,
    boxFolderId?: string | null,
    boxFolderPath?: string | null,
    fileCount?: number | null,
    processedFileCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSdsExtractionJobMutationVariables = {
  input?: UpdateSdsExtractionJobInput,
  condition?: ModelSdsExtractionJobConditionInput | null,
};

export type UpdateSdsExtractionJobMutation = {
  updateSdsExtractionJob?:  {
    __typename: "SdsExtractionJob",
    id: string,
    owner?: string | null,
    boxFolderId?: string | null,
    boxFolderPath?: string | null,
    fileCount?: number | null,
    processedFileCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSdsExtractionJobMutationVariables = {
  input?: DeleteSdsExtractionJobInput,
  condition?: ModelSdsExtractionJobConditionInput | null,
};

export type DeleteSdsExtractionJobMutation = {
  deleteSdsExtractionJob?:  {
    __typename: "SdsExtractionJob",
    id: string,
    owner?: string | null,
    boxFolderId?: string | null,
    boxFolderPath?: string | null,
    fileCount?: number | null,
    processedFileCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateManagedTaskMutationVariables = {
  input?: CreateManagedTaskInput,
  condition?: ModelManagedTaskConditionInput | null,
};

export type CreateManagedTaskMutation = {
  createManagedTask?:  {
    __typename: "ManagedTask",
    id: string,
    owner?: string | null,
    watcherId?: string | null,
    startDate: string,
    endDate: string,
    programArea?: string | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    isOrganizationLevel: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityTaskConnection",
      items?:  Array< {
        __typename: "FacilityTask",
        id: string,
        owner?: string | null,
        taskId: string,
        facilityId?: string | null,
        assigneeId?: string | null,
        watcherId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        assignee?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateManagedTaskMutationVariables = {
  input?: UpdateManagedTaskInput,
  condition?: ModelManagedTaskConditionInput | null,
};

export type UpdateManagedTaskMutation = {
  updateManagedTask?:  {
    __typename: "ManagedTask",
    id: string,
    owner?: string | null,
    watcherId?: string | null,
    startDate: string,
    endDate: string,
    programArea?: string | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    isOrganizationLevel: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityTaskConnection",
      items?:  Array< {
        __typename: "FacilityTask",
        id: string,
        owner?: string | null,
        taskId: string,
        facilityId?: string | null,
        assigneeId?: string | null,
        watcherId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        assignee?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteManagedTaskMutationVariables = {
  input?: DeleteManagedTaskInput,
  condition?: ModelManagedTaskConditionInput | null,
};

export type DeleteManagedTaskMutation = {
  deleteManagedTask?:  {
    __typename: "ManagedTask",
    id: string,
    owner?: string | null,
    watcherId?: string | null,
    startDate: string,
    endDate: string,
    programArea?: string | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    isOrganizationLevel: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityTaskConnection",
      items?:  Array< {
        __typename: "FacilityTask",
        id: string,
        owner?: string | null,
        taskId: string,
        facilityId?: string | null,
        assigneeId?: string | null,
        watcherId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        assignee?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateFacilityTaskMutationVariables = {
  input?: CreateFacilityTaskInput,
  condition?: ModelFacilityTaskConditionInput | null,
};

export type CreateFacilityTaskMutation = {
  createFacilityTask?:  {
    __typename: "FacilityTask",
    id: string,
    owner?: string | null,
    taskId: string,
    facilityId?: string | null,
    assigneeId?: string | null,
    watcherId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    assignee?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateFacilityTaskMutationVariables = {
  input?: UpdateFacilityTaskInput,
  condition?: ModelFacilityTaskConditionInput | null,
};

export type UpdateFacilityTaskMutation = {
  updateFacilityTask?:  {
    __typename: "FacilityTask",
    id: string,
    owner?: string | null,
    taskId: string,
    facilityId?: string | null,
    assigneeId?: string | null,
    watcherId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    assignee?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteFacilityTaskMutationVariables = {
  input?: DeleteFacilityTaskInput,
  condition?: ModelFacilityTaskConditionInput | null,
};

export type DeleteFacilityTaskMutation = {
  deleteFacilityTask?:  {
    __typename: "FacilityTask",
    id: string,
    owner?: string | null,
    taskId: string,
    facilityId?: string | null,
    assigneeId?: string | null,
    watcherId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    assignee?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTaskDocumentMutationVariables = {
  input?: CreateTaskDocumentInput,
  condition?: ModelTaskDocumentConditionInput | null,
};

export type CreateTaskDocumentMutation = {
  createTaskDocument?:  {
    __typename: "TaskDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    taskId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTaskDocumentMutationVariables = {
  input?: UpdateTaskDocumentInput,
  condition?: ModelTaskDocumentConditionInput | null,
};

export type UpdateTaskDocumentMutation = {
  updateTaskDocument?:  {
    __typename: "TaskDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    taskId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTaskDocumentMutationVariables = {
  input?: DeleteTaskDocumentInput,
  condition?: ModelTaskDocumentConditionInput | null,
};

export type DeleteTaskDocumentMutation = {
  deleteTaskDocument?:  {
    __typename: "TaskDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    taskId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTenantMutationVariables = {
  input?: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    partnerTenantId?: string | null,
    salesforceId?: string | null,
    accountType?: AccountType | null,
    isDemo?: boolean | null,
    isPartner?: boolean | null,
    isDisabled?: boolean | null,
    externalId?: string | null,
    ssoConfig?:  {
      __typename: "SsoConfig",
      mappingName: string,
      emailDomains: Array< string >,
    } | null,
    roleIdToDefaultForNewUsers?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    users?:  {
      __typename: "ModelUserTenantConnection",
      items?:  Array< {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input?: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    partnerTenantId?: string | null,
    salesforceId?: string | null,
    accountType?: AccountType | null,
    isDemo?: boolean | null,
    isPartner?: boolean | null,
    isDisabled?: boolean | null,
    externalId?: string | null,
    ssoConfig?:  {
      __typename: "SsoConfig",
      mappingName: string,
      emailDomains: Array< string >,
    } | null,
    roleIdToDefaultForNewUsers?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    users?:  {
      __typename: "ModelUserTenantConnection",
      items?:  Array< {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input?: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    partnerTenantId?: string | null,
    salesforceId?: string | null,
    accountType?: AccountType | null,
    isDemo?: boolean | null,
    isPartner?: boolean | null,
    isDisabled?: boolean | null,
    externalId?: string | null,
    ssoConfig?:  {
      __typename: "SsoConfig",
      mappingName: string,
      emailDomains: Array< string >,
    } | null,
    roleIdToDefaultForNewUsers?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    users?:  {
      __typename: "ModelUserTenantConnection",
      items?:  Array< {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTierIiFacilityProductMutationVariables = {
  input?: CreateTierIIFacilityProductInput,
  condition?: ModelTierIIFacilityProductConditionInput | null,
};

export type CreateTierIiFacilityProductMutation = {
  createTierIIFacilityProduct?:  {
    __typename: "TierIIFacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    isOverThresholds?: boolean | null,
    isAlwaysReported?: boolean | null,
    maxAmount?: number | null,
    maxAmountCode?: number | null,
    averageAmount?: number | null,
    averageAmountCode?: number | null,
    maxAmountLargestContainer?: number | null,
    daysOnSite?: number | null,
    hasConfidentialStorageLocations?: boolean | null,
    unit?: string | null,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    aggregatedProductIds?: Array< string > | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTierIiFacilityProductMutationVariables = {
  input?: UpdateTierIIFacilityProductInput,
  condition?: ModelTierIIFacilityProductConditionInput | null,
};

export type UpdateTierIiFacilityProductMutation = {
  updateTierIIFacilityProduct?:  {
    __typename: "TierIIFacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    isOverThresholds?: boolean | null,
    isAlwaysReported?: boolean | null,
    maxAmount?: number | null,
    maxAmountCode?: number | null,
    averageAmount?: number | null,
    averageAmountCode?: number | null,
    maxAmountLargestContainer?: number | null,
    daysOnSite?: number | null,
    hasConfidentialStorageLocations?: boolean | null,
    unit?: string | null,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    aggregatedProductIds?: Array< string > | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTierIiFacilityProductMutationVariables = {
  input?: DeleteTierIIFacilityProductInput,
  condition?: ModelTierIIFacilityProductConditionInput | null,
};

export type DeleteTierIiFacilityProductMutation = {
  deleteTierIIFacilityProduct?:  {
    __typename: "TierIIFacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    isOverThresholds?: boolean | null,
    isAlwaysReported?: boolean | null,
    maxAmount?: number | null,
    maxAmountCode?: number | null,
    averageAmount?: number | null,
    averageAmountCode?: number | null,
    maxAmountLargestContainer?: number | null,
    daysOnSite?: number | null,
    hasConfidentialStorageLocations?: boolean | null,
    unit?: string | null,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    aggregatedProductIds?: Array< string > | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTierIiProductMutationVariables = {
  input?: CreateTierIIProductInput,
  condition?: ModelTierIIProductConditionInput | null,
};

export type CreateTierIiProductMutation = {
  createTierIIProduct?:  {
    __typename: "TierIIProduct",
    id: string,
    owner?: string | null,
    chemName: string,
    casNumber?: string | null,
    noCasNumber?: boolean | null,
    alternateId?: string | null,
    ehs?: boolean | null,
    pure?: boolean | null,
    mixture?: boolean | null,
    solid?: boolean | null,
    liquid?: boolean | null,
    gas?: boolean | null,
    isTradeSecret?: boolean | null,
    density?: number | null,
    densityUnits?: string | null,
    boilingPointFahr?: number | null,
    flashPointFahr?: number | null,
    vaporPressureMmhg?: number | null,
    solubility?: string | null,
    isBattery?: boolean | null,
    isFuel?: boolean | null,
    physicalHazards?: Array< string | null > | null,
    healthHazards?: Array< string | null > | null,
    oregonHazards?: Array< string | null > | null,
    ifcHazards?: Array< string | null > | null,
    noHazardsNotReporting?: boolean | null,
    components?:  Array< {
      __typename: "TierIIMixtureComponent",
      componentName?: string | null,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      ehs?: boolean | null,
      componentPercentage?: number | null,
      weightOrVolume?: string | null,
      ehsForm?: EhsForm | null,
    } | null > | null,
    sdsRecords?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    stateFields?: string | null,
    ehsForm?: EhsForm | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTierIiProductMutationVariables = {
  input?: UpdateTierIIProductInput,
  condition?: ModelTierIIProductConditionInput | null,
};

export type UpdateTierIiProductMutation = {
  updateTierIIProduct?:  {
    __typename: "TierIIProduct",
    id: string,
    owner?: string | null,
    chemName: string,
    casNumber?: string | null,
    noCasNumber?: boolean | null,
    alternateId?: string | null,
    ehs?: boolean | null,
    pure?: boolean | null,
    mixture?: boolean | null,
    solid?: boolean | null,
    liquid?: boolean | null,
    gas?: boolean | null,
    isTradeSecret?: boolean | null,
    density?: number | null,
    densityUnits?: string | null,
    boilingPointFahr?: number | null,
    flashPointFahr?: number | null,
    vaporPressureMmhg?: number | null,
    solubility?: string | null,
    isBattery?: boolean | null,
    isFuel?: boolean | null,
    physicalHazards?: Array< string | null > | null,
    healthHazards?: Array< string | null > | null,
    oregonHazards?: Array< string | null > | null,
    ifcHazards?: Array< string | null > | null,
    noHazardsNotReporting?: boolean | null,
    components?:  Array< {
      __typename: "TierIIMixtureComponent",
      componentName?: string | null,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      ehs?: boolean | null,
      componentPercentage?: number | null,
      weightOrVolume?: string | null,
      ehsForm?: EhsForm | null,
    } | null > | null,
    sdsRecords?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    stateFields?: string | null,
    ehsForm?: EhsForm | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTierIiProductMutationVariables = {
  input?: DeleteTierIIProductInput,
  condition?: ModelTierIIProductConditionInput | null,
};

export type DeleteTierIiProductMutation = {
  deleteTierIIProduct?:  {
    __typename: "TierIIProduct",
    id: string,
    owner?: string | null,
    chemName: string,
    casNumber?: string | null,
    noCasNumber?: boolean | null,
    alternateId?: string | null,
    ehs?: boolean | null,
    pure?: boolean | null,
    mixture?: boolean | null,
    solid?: boolean | null,
    liquid?: boolean | null,
    gas?: boolean | null,
    isTradeSecret?: boolean | null,
    density?: number | null,
    densityUnits?: string | null,
    boilingPointFahr?: number | null,
    flashPointFahr?: number | null,
    vaporPressureMmhg?: number | null,
    solubility?: string | null,
    isBattery?: boolean | null,
    isFuel?: boolean | null,
    physicalHazards?: Array< string | null > | null,
    healthHazards?: Array< string | null > | null,
    oregonHazards?: Array< string | null > | null,
    ifcHazards?: Array< string | null > | null,
    noHazardsNotReporting?: boolean | null,
    components?:  Array< {
      __typename: "TierIIMixtureComponent",
      componentName?: string | null,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      ehs?: boolean | null,
      componentPercentage?: number | null,
      weightOrVolume?: string | null,
      ehsForm?: EhsForm | null,
    } | null > | null,
    sdsRecords?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    stateFields?: string | null,
    ehsForm?: EhsForm | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTierIiProductGroupMutationVariables = {
  input?: CreateTierIIProductGroupInput,
  condition?: ModelTierIIProductGroupConditionInput | null,
};

export type CreateTierIiProductGroupMutation = {
  createTierIIProductGroup?:  {
    __typename: "TierIIProductGroup",
    id: string,
    owner?: string | null,
    name: string,
    groupType?: TierIIProductGroupGroupType | null,
    description: string,
    measurementUnits: string,
    records?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    childProducts?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    childProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTierIiProductGroupMutationVariables = {
  input?: UpdateTierIIProductGroupInput,
  condition?: ModelTierIIProductGroupConditionInput | null,
};

export type UpdateTierIiProductGroupMutation = {
  updateTierIIProductGroup?:  {
    __typename: "TierIIProductGroup",
    id: string,
    owner?: string | null,
    name: string,
    groupType?: TierIIProductGroupGroupType | null,
    description: string,
    measurementUnits: string,
    records?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    childProducts?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    childProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTierIiProductGroupMutationVariables = {
  input?: DeleteTierIIProductGroupInput,
  condition?: ModelTierIIProductGroupConditionInput | null,
};

export type DeleteTierIiProductGroupMutation = {
  deleteTierIIProductGroup?:  {
    __typename: "TierIIProductGroup",
    id: string,
    owner?: string | null,
    name: string,
    groupType?: TierIIProductGroupGroupType | null,
    description: string,
    measurementUnits: string,
    records?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    childProducts?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    childProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTierIiProductProductGroupMutationVariables = {
  input?: CreateTierIIProductProductGroupInput,
  condition?: ModelTierIIProductProductGroupConditionInput | null,
};

export type CreateTierIiProductProductGroupMutation = {
  createTierIIProductProductGroup?:  {
    __typename: "TierIIProductProductGroup",
    id: string,
    owner?: string | null,
    productGroupId: string,
    productId: string,
    amount?: number | null,
    units?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTierIiProductProductGroupMutationVariables = {
  input?: UpdateTierIIProductProductGroupInput,
  condition?: ModelTierIIProductProductGroupConditionInput | null,
};

export type UpdateTierIiProductProductGroupMutation = {
  updateTierIIProductProductGroup?:  {
    __typename: "TierIIProductProductGroup",
    id: string,
    owner?: string | null,
    productGroupId: string,
    productId: string,
    amount?: number | null,
    units?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTierIiProductProductGroupMutationVariables = {
  input?: DeleteTierIIProductProductGroupInput,
  condition?: ModelTierIIProductProductGroupConditionInput | null,
};

export type DeleteTierIiProductProductGroupMutation = {
  deleteTierIIProductProductGroup?:  {
    __typename: "TierIIProductProductGroup",
    id: string,
    owner?: string | null,
    productGroupId: string,
    productId: string,
    amount?: number | null,
    units?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTierIiProductGroupProductGroupMutationVariables = {
  input?: CreateTierIIProductGroupProductGroupInput,
  condition?: ModelTierIIProductGroupProductGroupConditionInput | null,
};

export type CreateTierIiProductGroupProductGroupMutation = {
  createTierIIProductGroupProductGroup?:  {
    __typename: "TierIIProductGroupProductGroup",
    id: string,
    owner?: string | null,
    parentProductGroupId: string,
    childProductGroupId: string,
    amount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    parentProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    childProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateTierIiProductGroupProductGroupMutationVariables = {
  input?: UpdateTierIIProductGroupProductGroupInput,
  condition?: ModelTierIIProductGroupProductGroupConditionInput | null,
};

export type UpdateTierIiProductGroupProductGroupMutation = {
  updateTierIIProductGroupProductGroup?:  {
    __typename: "TierIIProductGroupProductGroup",
    id: string,
    owner?: string | null,
    parentProductGroupId: string,
    childProductGroupId: string,
    amount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    parentProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    childProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteTierIiProductGroupProductGroupMutationVariables = {
  input?: DeleteTierIIProductGroupProductGroupInput,
  condition?: ModelTierIIProductGroupProductGroupConditionInput | null,
};

export type DeleteTierIiProductGroupProductGroupMutation = {
  deleteTierIIProductGroupProductGroup?:  {
    __typename: "TierIIProductGroupProductGroup",
    id: string,
    owner?: string | null,
    parentProductGroupId: string,
    childProductGroupId: string,
    amount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    parentProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    childProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateTierIiReportMutationVariables = {
  input?: CreateTierIIReportInput,
  condition?: ModelTierIIReportConditionInput | null,
};

export type CreateTierIiReportMutation = {
  createTierIIReport?:  {
    __typename: "TierIIReport",
    id: string,
    owner?: string | null,
    facilityId: string,
    reportingYear: number,
    reportKind: TierIIReportKind,
    organizationStatus: TierIIReportOrganizationStatus,
    encampStatus: TierIIReportEncampStatus,
    staffAssignee?: string | null,
    metadata?:  {
      __typename: "TierIIMetadata",
      comments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "TierIISubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      submissionRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      tags?: string | null,
      untouchedSteps?: Array< string > | null,
    } | null,
    step3Prime?: string | null,
    customerComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    assignees?:  Array< {
      __typename: "TierIIPerson",
      id: string,
      first: string,
      last: string,
      title?: string | null,
      areas?: Array< string > | null,
      reconciledAt?: string | null,
    } > | null,
    basic?:  {
      __typename: "TierIIBasicInformation",
      companyName?: string | null,
      county?: string | null,
      dateClosed?: string | null,
      dbNumber?: string | null,
      department?: string | null,
      facilityName?: string | null,
      facilityPhone?: string | null,
      facilityPhone24Hour?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      isClosed?: boolean | null,
      isColocated?: boolean | null,
      isInactive?: boolean | null,
      colocationReportingFacilityId?: string | null,
      isMailingSameAsFacility?: boolean | null,
      isUnmanned?: boolean | null,
      latitude?: number | null,
      longitude?: number | null,
      lepc?: string | null,
      lepcId?: string | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      maxNumOccupants?: number | null,
      municipality?: string | null,
      naicsCode?: number | null,
      reconciledAt?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
    } | null,
    regulatory?:  {
      __typename: "TierIIRegulatory",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    facilityProductIds?: Array< string > | null,
    notReportingFacilityProductIds?: Array< string > | null,
    products?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    notReportingProducts?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    facilityContactIds?: Array< string > | null,
    contacts?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    records?:  {
      __typename: "TierIIRecords",
      facilityRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
    } | null,
    state?:  {
      __typename: "TierIIStateInformation",
      fields?: string | null,
    } | null,
    review?:  {
      __typename: "TierIIReview",
      reviewers?:  Array< {
        __typename: "TierIIReviewer",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        isComplete?: boolean | null,
      } > | null,
      notesToRegulators?: string | null,
      isNotReporting?: boolean | null,
    } | null,
    verify?:  {
      __typename: "TierIIVerify",
      verifier?:  {
        __typename: "TierIIVerifier",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        fullName?: string | null,
      } | null,
      verifiedAt?: string | null,
      isVerified?: boolean | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    computed?:  {
      __typename: "ModelComputedConnection",
      items?:  Array< {
        __typename: "Computed",
        id: string,
        typeId: string,
        owner: string,
        kind: ComputationKind,
        typeName?: string | null,
        computed?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTierIiReportMutationVariables = {
  input?: UpdateTierIIReportInput,
  condition?: ModelTierIIReportConditionInput | null,
};

export type UpdateTierIiReportMutation = {
  updateTierIIReport?:  {
    __typename: "TierIIReport",
    id: string,
    owner?: string | null,
    facilityId: string,
    reportingYear: number,
    reportKind: TierIIReportKind,
    organizationStatus: TierIIReportOrganizationStatus,
    encampStatus: TierIIReportEncampStatus,
    staffAssignee?: string | null,
    metadata?:  {
      __typename: "TierIIMetadata",
      comments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "TierIISubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      submissionRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      tags?: string | null,
      untouchedSteps?: Array< string > | null,
    } | null,
    step3Prime?: string | null,
    customerComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    assignees?:  Array< {
      __typename: "TierIIPerson",
      id: string,
      first: string,
      last: string,
      title?: string | null,
      areas?: Array< string > | null,
      reconciledAt?: string | null,
    } > | null,
    basic?:  {
      __typename: "TierIIBasicInformation",
      companyName?: string | null,
      county?: string | null,
      dateClosed?: string | null,
      dbNumber?: string | null,
      department?: string | null,
      facilityName?: string | null,
      facilityPhone?: string | null,
      facilityPhone24Hour?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      isClosed?: boolean | null,
      isColocated?: boolean | null,
      isInactive?: boolean | null,
      colocationReportingFacilityId?: string | null,
      isMailingSameAsFacility?: boolean | null,
      isUnmanned?: boolean | null,
      latitude?: number | null,
      longitude?: number | null,
      lepc?: string | null,
      lepcId?: string | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      maxNumOccupants?: number | null,
      municipality?: string | null,
      naicsCode?: number | null,
      reconciledAt?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
    } | null,
    regulatory?:  {
      __typename: "TierIIRegulatory",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    facilityProductIds?: Array< string > | null,
    notReportingFacilityProductIds?: Array< string > | null,
    products?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    notReportingProducts?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    facilityContactIds?: Array< string > | null,
    contacts?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    records?:  {
      __typename: "TierIIRecords",
      facilityRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
    } | null,
    state?:  {
      __typename: "TierIIStateInformation",
      fields?: string | null,
    } | null,
    review?:  {
      __typename: "TierIIReview",
      reviewers?:  Array< {
        __typename: "TierIIReviewer",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        isComplete?: boolean | null,
      } > | null,
      notesToRegulators?: string | null,
      isNotReporting?: boolean | null,
    } | null,
    verify?:  {
      __typename: "TierIIVerify",
      verifier?:  {
        __typename: "TierIIVerifier",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        fullName?: string | null,
      } | null,
      verifiedAt?: string | null,
      isVerified?: boolean | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    computed?:  {
      __typename: "ModelComputedConnection",
      items?:  Array< {
        __typename: "Computed",
        id: string,
        typeId: string,
        owner: string,
        kind: ComputationKind,
        typeName?: string | null,
        computed?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTierIiReportMutationVariables = {
  input?: DeleteTierIIReportInput,
  condition?: ModelTierIIReportConditionInput | null,
};

export type DeleteTierIiReportMutation = {
  deleteTierIIReport?:  {
    __typename: "TierIIReport",
    id: string,
    owner?: string | null,
    facilityId: string,
    reportingYear: number,
    reportKind: TierIIReportKind,
    organizationStatus: TierIIReportOrganizationStatus,
    encampStatus: TierIIReportEncampStatus,
    staffAssignee?: string | null,
    metadata?:  {
      __typename: "TierIIMetadata",
      comments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "TierIISubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      submissionRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      tags?: string | null,
      untouchedSteps?: Array< string > | null,
    } | null,
    step3Prime?: string | null,
    customerComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    assignees?:  Array< {
      __typename: "TierIIPerson",
      id: string,
      first: string,
      last: string,
      title?: string | null,
      areas?: Array< string > | null,
      reconciledAt?: string | null,
    } > | null,
    basic?:  {
      __typename: "TierIIBasicInformation",
      companyName?: string | null,
      county?: string | null,
      dateClosed?: string | null,
      dbNumber?: string | null,
      department?: string | null,
      facilityName?: string | null,
      facilityPhone?: string | null,
      facilityPhone24Hour?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      isClosed?: boolean | null,
      isColocated?: boolean | null,
      isInactive?: boolean | null,
      colocationReportingFacilityId?: string | null,
      isMailingSameAsFacility?: boolean | null,
      isUnmanned?: boolean | null,
      latitude?: number | null,
      longitude?: number | null,
      lepc?: string | null,
      lepcId?: string | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      maxNumOccupants?: number | null,
      municipality?: string | null,
      naicsCode?: number | null,
      reconciledAt?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
    } | null,
    regulatory?:  {
      __typename: "TierIIRegulatory",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    facilityProductIds?: Array< string > | null,
    notReportingFacilityProductIds?: Array< string > | null,
    products?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    notReportingProducts?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    facilityContactIds?: Array< string > | null,
    contacts?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    records?:  {
      __typename: "TierIIRecords",
      facilityRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
    } | null,
    state?:  {
      __typename: "TierIIStateInformation",
      fields?: string | null,
    } | null,
    review?:  {
      __typename: "TierIIReview",
      reviewers?:  Array< {
        __typename: "TierIIReviewer",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        isComplete?: boolean | null,
      } > | null,
      notesToRegulators?: string | null,
      isNotReporting?: boolean | null,
    } | null,
    verify?:  {
      __typename: "TierIIVerify",
      verifier?:  {
        __typename: "TierIIVerifier",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        fullName?: string | null,
      } | null,
      verifiedAt?: string | null,
      isVerified?: boolean | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    computed?:  {
      __typename: "ModelComputedConnection",
      items?:  Array< {
        __typename: "Computed",
        id: string,
        typeId: string,
        owner: string,
        kind: ComputationKind,
        typeName?: string | null,
        computed?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserTenantMutationVariables = {
  input?: CreateUserTenantInput,
  condition?: ModelUserTenantConditionInput | null,
};

export type CreateUserTenantMutation = {
  createUserTenant?:  {
    __typename: "UserTenant",
    id: string,
    tenantId: string,
    email: string,
    groups?: Array< string > | null,
    invitedAt?: string | null,
    invitationExpiresAt?: string | null,
    status: UserTenantStatus,
    personId: string,
    isDisabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      partnerTenantId?: string | null,
      salesforceId?: string | null,
      accountType?: AccountType | null,
      isDemo?: boolean | null,
      isPartner?: boolean | null,
      isDisabled?: boolean | null,
      externalId?: string | null,
      ssoConfig?:  {
        __typename: "SsoConfig",
        mappingName: string,
        emailDomains: Array< string >,
      } | null,
      roleIdToDefaultForNewUsers?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      users?:  {
        __typename: "ModelUserTenantConnection",
        items?:  Array< {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    roles?:  {
      __typename: "ModelUserTenantRoleConnection",
      items?:  Array< {
        __typename: "UserTenantRole",
        id: string,
        owner: string,
        userTenantId: string,
        roleId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        role:  {
          __typename: "Role",
          id: string,
          owner: string,
          name: string,
          rolePermissions?: Array< string > | null,
          roleType: RoleTypeEnum,
          createdAt: string,
          updatedAt: string,
          updatedBy: string,
        },
        userTenant:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserTenantMutationVariables = {
  input?: UpdateUserTenantInput,
  condition?: ModelUserTenantConditionInput | null,
};

export type UpdateUserTenantMutation = {
  updateUserTenant?:  {
    __typename: "UserTenant",
    id: string,
    tenantId: string,
    email: string,
    groups?: Array< string > | null,
    invitedAt?: string | null,
    invitationExpiresAt?: string | null,
    status: UserTenantStatus,
    personId: string,
    isDisabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      partnerTenantId?: string | null,
      salesforceId?: string | null,
      accountType?: AccountType | null,
      isDemo?: boolean | null,
      isPartner?: boolean | null,
      isDisabled?: boolean | null,
      externalId?: string | null,
      ssoConfig?:  {
        __typename: "SsoConfig",
        mappingName: string,
        emailDomains: Array< string >,
      } | null,
      roleIdToDefaultForNewUsers?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      users?:  {
        __typename: "ModelUserTenantConnection",
        items?:  Array< {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    roles?:  {
      __typename: "ModelUserTenantRoleConnection",
      items?:  Array< {
        __typename: "UserTenantRole",
        id: string,
        owner: string,
        userTenantId: string,
        roleId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        role:  {
          __typename: "Role",
          id: string,
          owner: string,
          name: string,
          rolePermissions?: Array< string > | null,
          roleType: RoleTypeEnum,
          createdAt: string,
          updatedAt: string,
          updatedBy: string,
        },
        userTenant:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserTenantRoleMutationVariables = {
  input?: CreateUserTenantRoleInput,
  condition?: ModelUserTenantRoleConditionInput | null,
};

export type CreateUserTenantRoleMutation = {
  createUserTenantRole?:  {
    __typename: "UserTenantRole",
    id: string,
    owner: string,
    userTenantId: string,
    roleId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    role:  {
      __typename: "Role",
      id: string,
      owner: string,
      name: string,
      rolePermissions?: Array< string > | null,
      roleType: RoleTypeEnum,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
    },
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserTenantRoleMutationVariables = {
  input?: UpdateUserTenantRoleInput,
  condition?: ModelUserTenantRoleConditionInput | null,
};

export type UpdateUserTenantRoleMutation = {
  updateUserTenantRole?:  {
    __typename: "UserTenantRole",
    id: string,
    owner: string,
    userTenantId: string,
    roleId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    role:  {
      __typename: "Role",
      id: string,
      owner: string,
      name: string,
      rolePermissions?: Array< string > | null,
      roleType: RoleTypeEnum,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
    },
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DocumentDownloadLinkQueryVariables = {
  isAshStorage?: boolean | null,
  key?: string,
};

export type DocumentDownloadLinkQuery = {
  documentDownloadLink?:  {
    __typename: "DocumentDownloadLinkResponse",
    headResponse?: string | null,
    signedUrl?: string | null,
  } | null,
};

export type DocumentImportQueryVariables = {
  documentImportInput?: DocumentImportInput,
};

export type DocumentImportQuery = {
  documentImport?:  {
    __typename: "DocumentImportResponse",
    document?:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetIcebergQueryVariables = {
  modelName?: string,
  id?: string,
};

export type GetIcebergQuery = {
  getIceberg?: string | null,
};

export type GetTierIiReportIcebergResolvedFromUniversalDataQueryVariables = {
  id?: string,
};

export type GetTierIiReportIcebergResolvedFromUniversalDataQuery = {
  getTierIIReportIcebergResolvedFromUniversalData?: string | null,
};

export type InvokeAggregatorQueryVariables = {
  aggregationKind?: string,
  tenantId?: string | null,
};

export type InvokeAggregatorQuery = {
  invokeAggregator?: string | null,
};

export type OnboardFacilityImportQueryVariables = {
  importUrl?: string,
};

export type OnboardFacilityImportQuery = {
  onboardFacilityImport?:  {
    __typename: "OnboardFacilityImportResponse",
    facilities?:  Array< {
      __typename: "OnboardFacilityImportResponseFacility",
      id?: string | null,
      name?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      status?: string | null,
      searchableStatus?: string | null,
    } | null > | null,
    conversionErrors?: Array< string | null > | null,
    duplicateFacilities?:  Array< {
      __typename: "OnboardFacilityImportResponseFacility",
      id?: string | null,
      name?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      status?: string | null,
      searchableStatus?: string | null,
    } | null > | null,
    csvError?: string | null,
  } | null,
};

export type GetDistrictInformationQueryVariables = {
  input?: GetDistrictInformationInput | null,
};

export type GetDistrictInformationQuery = {
  getDistrictInformation?:  {
    __typename: "GetDistrictInformationResponse",
    counties?: Array< string > | null,
    lepcs?:  Array< {
      __typename: "LepcOption",
      id?: string | null,
      deactivated?: boolean | null,
      label: string,
      value: string,
      mailingAddress?: string | null,
      mailingAddressLine2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
    } > | null,
    fire?:  Array< {
      __typename: "FireDepartmentOption",
      id?: string | null,
      label: string,
      value: string,
      mailingAddress?: string | null,
      mailingAddressLine2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
    } > | null,
    municipalities?:  Array< {
      __typename: "MunicipalityOption",
      label: string,
      value: string,
      state?: string | null,
    } > | null,
  } | null,
};

export type ListEventActivityLogsQueryVariables = {
  input?: ListEventActivityLogsInput | null,
};

export type ListEventActivityLogsQuery = {
  listEventActivityLogs?:  {
    __typename: "ListEventActivityLogsOutput",
    directLogs?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    relatedLogs?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
  } | null,
};

export type ComplianceEngineQueryVariables = {
  input?: ComplianceEngineInput | null,
};

export type ComplianceEngineQuery = {
  complianceEngine?:  {
    __typename: "ComplianceEngineOutput",
    validation?: string | null,
    complianceDefinitions?:  Array< {
      __typename: "ComplianceDefinitionOutput",
      id?: string | null,
      targetType?: string | null,
      key?: string | null,
      steps?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
    isSubjectToEmergencyPlanning?: boolean | null,
  } | null,
};

export type PublicGetSsoTenantByEmailDomainQueryVariables = {
  emailDomain?: string,
};

export type PublicGetSsoTenantByEmailDomainQuery = {
  publicGetSsoTenantByEmailDomain?:  {
    __typename: "PublicTenant",
    id: string,
    ssoConfig?:  {
      __typename: "SsoConfig",
      mappingName: string,
      emailDomains: Array< string >,
    } | null,
  } | null,
};

export type GetDocumentsQueryVariables = {
  input?: GetDocumentsInput | null,
};

export type GetDocumentsQuery = {
  getDocuments?:  {
    __typename: "GetDocumentsResponse",
    documents?:  Array< {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type SearchFacilityMinimalInfoQueryVariables = {
  filter?: SearchableMinimalFacilityFilterInput | null,
  sort?: SearchableMinimalFacilitySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFacilityMinimalInfoQuery = {
  searchFacilityMinimalInfo?:  {
    __typename: "MinimalFacilityPaginator",
    items:  Array<( {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            person:  {
              __typename: "Person",
              id: string,
              owner?: string | null,
              first?: string | null,
              last?: string | null,
              email?: string | null,
              title?: string | null,
              company?: string | null,
              state?: string | null,
              contactType?: ContactType | null,
              searchableProgramAreas?: string | null,
              searchableFacilities?: string | null,
              searchableContactTypes?: string | null,
              userTenantId?: string | null,
              paymentMethodId?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              updatedBy?: string | null,
            },
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            facility:  {
              __typename: "Facility",
              id: string,
              owner?: string | null,
              name: string,
              status?: FacilityStatus | null,
              statusReason?: string | null,
              epaGeneratorStatus?: RcraGeneratorStatus | null,
              epaGeneratorStatusUpdatedAt?: string | null,
              epaGeneratorStatusUpdatedBy?: string | null,
              stateGeneratorStatus?: RcraGeneratorStatus | null,
              stateGeneratorStatusUpdatedAt?: string | null,
              stateGeneratorStatusUpdatedBy?: string | null,
              businessUnit?: string | null,
              customerFacilityId?: string | null,
              dbNumber?: string | null,
              facilityType?: string | null,
              naicsCode?: number | null,
              maxNumOccupants?: number | null,
              isUnmanned?: boolean | null,
              phone?: string | null,
              phone24Hour?: string | null,
              state?: string | null,
              county?: string | null,
              municipality?: string | null,
              latitude?: number | null,
              longitude?: number | null,
              isAutoGeocoded?: boolean | null,
              lepc?: string | null,
              lepcId?: string | null,
              fireDistrict?: string | null,
              fireDistrictId?: string | null,
              programAreas?: Array< string | null > | null,
              dateOpened?: string | null,
              dateClosed?: string | null,
              dateInactive?: string | null,
              federalFields?: string | null,
              stateFields?: string | null,
              searchableProgramAreas?: string | null,
              searchableStatus?: string | null,
              searchableCity?: string | null,
              lastProductMeasuredAt?: string | null,
              streetAddressVerifiedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              updatedBy?: string | null,
            },
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            credential:  {
              __typename: "Credential",
              id: string,
              owner?: string | null,
              name: string,
              username: string,
              password: string,
              kind: CredentialKind,
              createdAt?: string | null,
              updatedAt?: string | null,
              updatedBy?: string | null,
              verificationStatus?: CredentialVerificationStatus | null,
              lastVerificationSuccessAt?: string | null,
              lastVerificationCheckAt?: string | null,
            },
            facility:  {
              __typename: "Facility",
              id: string,
              owner?: string | null,
              name: string,
              status?: FacilityStatus | null,
              statusReason?: string | null,
              epaGeneratorStatus?: RcraGeneratorStatus | null,
              epaGeneratorStatusUpdatedAt?: string | null,
              epaGeneratorStatusUpdatedBy?: string | null,
              stateGeneratorStatus?: RcraGeneratorStatus | null,
              stateGeneratorStatusUpdatedAt?: string | null,
              stateGeneratorStatusUpdatedBy?: string | null,
              businessUnit?: string | null,
              customerFacilityId?: string | null,
              dbNumber?: string | null,
              facilityType?: string | null,
              naicsCode?: number | null,
              maxNumOccupants?: number | null,
              isUnmanned?: boolean | null,
              phone?: string | null,
              phone24Hour?: string | null,
              state?: string | null,
              county?: string | null,
              municipality?: string | null,
              latitude?: number | null,
              longitude?: number | null,
              isAutoGeocoded?: boolean | null,
              lepc?: string | null,
              lepcId?: string | null,
              fireDistrict?: string | null,
              fireDistrictId?: string | null,
              programAreas?: Array< string | null > | null,
              dateOpened?: string | null,
              dateClosed?: string | null,
              dateInactive?: string | null,
              federalFields?: string | null,
              stateFields?: string | null,
              searchableProgramAreas?: string | null,
              searchableStatus?: string | null,
              searchableCity?: string | null,
              lastProductMeasuredAt?: string | null,
              streetAddressVerifiedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              updatedBy?: string | null,
            },
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            facility:  {
              __typename: "Facility",
              id: string,
              owner?: string | null,
              name: string,
              status?: FacilityStatus | null,
              statusReason?: string | null,
              epaGeneratorStatus?: RcraGeneratorStatus | null,
              epaGeneratorStatusUpdatedAt?: string | null,
              epaGeneratorStatusUpdatedBy?: string | null,
              stateGeneratorStatus?: RcraGeneratorStatus | null,
              stateGeneratorStatusUpdatedAt?: string | null,
              stateGeneratorStatusUpdatedBy?: string | null,
              businessUnit?: string | null,
              customerFacilityId?: string | null,
              dbNumber?: string | null,
              facilityType?: string | null,
              naicsCode?: number | null,
              maxNumOccupants?: number | null,
              isUnmanned?: boolean | null,
              phone?: string | null,
              phone24Hour?: string | null,
              state?: string | null,
              county?: string | null,
              municipality?: string | null,
              latitude?: number | null,
              longitude?: number | null,
              isAutoGeocoded?: boolean | null,
              lepc?: string | null,
              lepcId?: string | null,
              fireDistrict?: string | null,
              fireDistrictId?: string | null,
              programAreas?: Array< string | null > | null,
              dateOpened?: string | null,
              dateClosed?: string | null,
              dateInactive?: string | null,
              federalFields?: string | null,
              stateFields?: string | null,
              searchableProgramAreas?: string | null,
              searchableStatus?: string | null,
              searchableCity?: string | null,
              lastProductMeasuredAt?: string | null,
              streetAddressVerifiedAt?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              updatedBy?: string | null,
            },
            requirement:  {
              __typename: "Requirement",
              id: string,
              owner?: string | null,
              status?: RequirementStatus | null,
              name: string,
              description?: string | null,
              agencyId: string,
              area: string,
              areaSearchable: string,
              jurisdictionalAgency?: string | null,
              intervalInWords?: string | null,
              parentStatute?: ParentStatute | null,
              jurisdictionalState?: string | null,
              jurisdictionalCounties?: Array< string | null > | null,
              title?: string | null,
              jurisdictionalTownship?: string | null,
              jurisdictionalTribalArea?: string | null,
              jurisdictionalCity?: string | null,
              formExpires?: string | null,
              fillableLinks?: Array< string | null > | null,
              instructionFileName?: string | null,
              instruction?: string | null,
              department?: string | null,
              subDepartment?: string | null,
              contactEmail?: string | null,
              contactPhone?: string | null,
              contactPhoneExtension?: number | null,
              isExtensionAvailable?: boolean | null,
              extensionNotes?: string | null,
              penalty?: string | null,
              encampInstructions?: string | null,
              isAlwaysApplicable?: boolean | null,
              agencyLevelSearchable?: string | null,
              agencyStateSearchable?: string | null,
              userAgencyTitle?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              updatedBy?: string | null,
            },
          } | null > | null,
          nextToken?: string | null,
        } | null,
      }
    ) | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type WasteDataQueryVariables = {
  input?: WasteDataInput | null,
};

export type WasteDataQuery = {
  wasteData?:  {
    __typename: "WasteDataOutput",
    id?: string | null,
    success: boolean,
    data?: string | null,
    count: number,
  } | null,
};

export type ListTierIiFacilityProductsByFacilityReportingYearQueryVariables = {
  input?: listTierIIFacilityProductsByFacilityReportingYearInput | null,
};

export type ListTierIiFacilityProductsByFacilityReportingYearQuery = {
  listTierIIFacilityProductsByFacilityReportingYear?:  {
    __typename: "listTierIIFacilityProductsByFacilityReportingYearOutput",
    facilityProducts:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } >,
  } | null,
};

export type ListPermittedUsersByFacilityQueryVariables = {
  facilityId?: string,
};

export type ListPermittedUsersByFacilityQuery = {
  listPermittedUsersByFacility?:  Array< {
    __typename: "Person",
    id: string,
    owner?: string | null,
    first?: string | null,
    last?: string | null,
    email?: string | null,
    title?: string | null,
    company?: string | null,
    address?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    phones:  Array< {
      __typename: "Phone",
      type: PhoneType,
      reportTypes?: Array< ReportType > | null,
      number: string,
    } >,
    contactType?: ContactType | null,
    searchableProgramAreas?: string | null,
    searchableFacilities?: string | null,
    searchableContactTypes?: string | null,
    userTenantId?: string | null,
    paymentMethodId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facilities?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    userTenant?:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null > | null,
};

export type GetOneSchemaTemplateQueryVariables = {
  input?: GetOneSchemaTemplateInput | null,
};

export type GetOneSchemaTemplateQuery = {
  getOneSchemaTemplate?: string | null,
};

export type GetNoteQueryVariables = {
  id?: string,
};

export type GetNoteQuery = {
  getNote?:  {
    __typename: "Note",
    id: string,
    parentId?: string | null,
    parent?:  {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      parent?:  {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      noteBy?: string | null,
      at: string,
      key?: string | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    noteBy?: string | null,
    at: string,
    key?: string | null,
    note?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListNotesQueryVariables = {
  id?: string | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotesQuery = {
  listNotes?:  {
    __typename: "ModelNoteConnection",
    items?:  Array< {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      parent?:  {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      noteBy?: string | null,
      at: string,
      key?: string | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDraftsBySourceQueryVariables = {
  draftSource?: string | null,
  draftProcessGroup?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDraftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDraftsBySourceQuery = {
  listDraftsBySource?:  {
    __typename: "ModelDraftConnection",
    items?:  Array< {
      __typename: "Draft",
      id: string,
      draftMetadata:  {
        __typename: "DraftMetadata",
        approved: boolean,
        approvedAt?: string | null,
        autoApproved: boolean,
        rejectedAt?: string | null,
        reviewer?: string | null,
        automationAccuracy?: number | null,
        confidenceRating?: number | null,
        relatedS3Paths?:  Array< {
          __typename: "DraftS3Path",
          path?: string | null,
          type?: string | null,
        } | null > | null,
        additionalMetadata?: string | null,
      },
      draftSource: string,
      draftProcessGroup?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetKeyNotesQueryVariables = {
  key?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetKeyNotesQuery = {
  getKeyNotes?:  {
    __typename: "ModelNoteConnection",
    items?:  Array< {
      __typename: "Note",
      id: string,
      parentId?: string | null,
      parent?:  {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      noteBy?: string | null,
      at: string,
      key?: string | null,
      note?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRequestsBySourceQueryVariables = {
  source?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequestsBySourceQuery = {
  listRequestsBySource?:  {
    __typename: "ModelRequestConnection",
    items?:  Array< {
      __typename: "Request",
      id: string,
      batchId: string,
      message: string,
      status: RequestStatus,
      source: string,
      destination: string,
      queueUrl: string,
      history?:  Array< {
        __typename: "StatusHistory",
        time: string,
        status: RequestStatus,
      } | null > | null,
      response?: string | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRequestsByDestinationQueryVariables = {
  destination?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequestsByDestinationQuery = {
  listRequestsByDestination?:  {
    __typename: "ModelRequestConnection",
    items?:  Array< {
      __typename: "Request",
      id: string,
      batchId: string,
      message: string,
      status: RequestStatus,
      source: string,
      destination: string,
      queueUrl: string,
      history?:  Array< {
        __typename: "StatusHistory",
        time: string,
        status: RequestStatus,
      } | null > | null,
      response?: string | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRequestsByStatusQueryVariables = {
  status?: RequestStatus | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequestsByStatusQuery = {
  listRequestsByStatus?:  {
    __typename: "ModelRequestConnection",
    items?:  Array< {
      __typename: "Request",
      id: string,
      batchId: string,
      message: string,
      status: RequestStatus,
      source: string,
      destination: string,
      queueUrl: string,
      history?:  Array< {
        __typename: "StatusHistory",
        time: string,
        status: RequestStatus,
      } | null > | null,
      response?: string | null,
      createdAt: string,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMetatronFacilityQueryVariables = {
  id?: string,
};

export type GetMetatronFacilityQuery = {
  getMetatronFacility?:  {
    __typename: "MetatronFacility",
    id: string,
    frsId: string,
    facName?: string | null,
    facStreet?: string | null,
    facCity?: string | null,
    facState?: string | null,
    facZip?: string | null,
    facCounty?: string | null,
    facLat?: string | null,
    facLong?: string | null,
    rcraFlag?: string | null,
    rcraIds?: string | null,
    rcraPermitTypes?: string | null,
    rcraNaics?: string | null,
    airFlag?: string | null,
    airIds?: string | null,
    caaPermitTypes?: string | null,
    caaNaics?: string | null,
    npdesFlag?: string | null,
    npdesIds?: string | null,
    triFlag?: string | null,
    triIds?: string | null,
    ghgFlag?: string | null,
    ghgIds?: string | null,
    organizations?:  Array< {
      __typename: "MetatronFacilityOrganization",
      name: string,
      pgmSysAcrnm: string,
      pgmSysId: string,
      email?: string | null,
      affiliationType?: string | null,
    } | null > | null,
    contacts?:  Array< {
      __typename: "MetatronFacilityContact",
      pgmSysAcrnm: string,
      pgmSysId: string,
      email?: string | null,
      affiliationType?: string | null,
    } | null > | null,
    combinedText: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListMetatronFacilityQueryVariables = {
  filter?: ModelMetatronFacilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMetatronFacilityQuery = {
  listMetatronFacility?:  {
    __typename: "ModelMetatronFacilityConnection",
    items?:  Array< {
      __typename: "MetatronFacility",
      id: string,
      frsId: string,
      facName?: string | null,
      facStreet?: string | null,
      facCity?: string | null,
      facState?: string | null,
      facZip?: string | null,
      facCounty?: string | null,
      facLat?: string | null,
      facLong?: string | null,
      rcraFlag?: string | null,
      rcraIds?: string | null,
      rcraPermitTypes?: string | null,
      rcraNaics?: string | null,
      airFlag?: string | null,
      airIds?: string | null,
      caaPermitTypes?: string | null,
      caaNaics?: string | null,
      npdesFlag?: string | null,
      npdesIds?: string | null,
      triFlag?: string | null,
      triIds?: string | null,
      ghgFlag?: string | null,
      ghgIds?: string | null,
      organizations?:  Array< {
        __typename: "MetatronFacilityOrganization",
        name: string,
        pgmSysAcrnm: string,
        pgmSysId: string,
        email?: string | null,
        affiliationType?: string | null,
      } | null > | null,
      contacts?:  Array< {
        __typename: "MetatronFacilityContact",
        pgmSysAcrnm: string,
        pgmSysId: string,
        email?: string | null,
        affiliationType?: string | null,
      } | null > | null,
      combinedText: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchMetatronFacilitysQueryVariables = {
  filter?: SearchableMetatronFacilityFilterInput | null,
  sort?: SearchableMetatronFacilitySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchMetatronFacilitysQuery = {
  searchMetatronFacilitys?:  {
    __typename: "SearchableMetatronFacilityConnection",
    items?:  Array< {
      __typename: "MetatronFacility",
      id: string,
      frsId: string,
      facName?: string | null,
      facStreet?: string | null,
      facCity?: string | null,
      facState?: string | null,
      facZip?: string | null,
      facCounty?: string | null,
      facLat?: string | null,
      facLong?: string | null,
      rcraFlag?: string | null,
      rcraIds?: string | null,
      rcraPermitTypes?: string | null,
      rcraNaics?: string | null,
      airFlag?: string | null,
      airIds?: string | null,
      caaPermitTypes?: string | null,
      caaNaics?: string | null,
      npdesFlag?: string | null,
      npdesIds?: string | null,
      triFlag?: string | null,
      triIds?: string | null,
      ghgFlag?: string | null,
      ghgIds?: string | null,
      organizations?:  Array< {
        __typename: "MetatronFacilityOrganization",
        name: string,
        pgmSysAcrnm: string,
        pgmSysId: string,
        email?: string | null,
        affiliationType?: string | null,
      } | null > | null,
      contacts?:  Array< {
        __typename: "MetatronFacilityContact",
        pgmSysAcrnm: string,
        pgmSysId: string,
        email?: string | null,
        affiliationType?: string | null,
      } | null > | null,
      combinedText: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetAgencyQueryVariables = {
  id?: string,
};

export type GetAgencyQuery = {
  getAgency?:  {
    __typename: "Agency",
    id: string,
    owner?: string | null,
    name: string,
    level: AgencyLevel,
    link?:  {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null,
    jurisdiction: string,
    state?: StateOrTerritory | null,
    locale?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListAgencysQueryVariables = {
  filter?: ModelAgencyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgencysQuery = {
  listAgencys?:  {
    __typename: "ModelAgencyConnection",
    items?:  Array< {
      __typename: "Agency",
      id: string,
      owner?: string | null,
      name: string,
      level: AgencyLevel,
      link?:  {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null,
      jurisdiction: string,
      state?: StateOrTerritory | null,
      locale?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListAgenciesByOwnerQueryVariables = {
  owner?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgencyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgenciesByOwnerQuery = {
  listAgenciesByOwner?:  {
    __typename: "ModelAgencyConnection",
    items?:  Array< {
      __typename: "Agency",
      id: string,
      owner?: string | null,
      name: string,
      level: AgencyLevel,
      link?:  {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null,
      jurisdiction: string,
      state?: StateOrTerritory | null,
      locale?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSystemAuditLogQueryVariables = {
  id?: string,
};

export type GetSystemAuditLogQuery = {
  getSystemAuditLog?:  {
    __typename: "SystemAuditLog",
    id: string,
    owner: string,
    typeId: string,
    typeName: string,
    mutatedAt: string,
    mutatedBy?: string | null,
    oldValue?: string | null,
    newValue?: string | null,
    summary?: string | null,
    oldArchiveId?: string | null,
    newArchiveId?: string | null,
    eventId?: string | null,
    eventSequenceId?: string | null,
    facilityId?: string | null,
    personId?: string | null,
    seriesId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListSystemAuditLogsQueryVariables = {
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsQuery = {
  listSystemAuditLogs?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByOwnerQueryVariables = {
  owner?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByOwnerQuery = {
  listSystemAuditLogsByOwner?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByTypeIdQueryVariables = {
  typeId?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByTypeIdQuery = {
  listSystemAuditLogsByTypeId?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByTypeNameQueryVariables = {
  owner?: string | null,
  typeNameMutatedAt?: ModelSystemAuditLogByTypeNameCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByTypeNameQuery = {
  listSystemAuditLogsByTypeName?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByEventIdQueryVariables = {
  eventId?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByEventIdQuery = {
  listSystemAuditLogsByEventId?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByEventSequenceIdQueryVariables = {
  eventSequenceId?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByEventSequenceIdQuery = {
  listSystemAuditLogsByEventSequenceId?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByFacilityIdQueryVariables = {
  facilityId?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByFacilityIdQuery = {
  listSystemAuditLogsByFacilityId?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByPersonIdQueryVariables = {
  personId?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByPersonIdQuery = {
  listSystemAuditLogsByPersonId?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsByMutatedByQueryVariables = {
  mutatedBy?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsByMutatedByQuery = {
  listSystemAuditLogsByMutatedBy?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemAuditLogsBySeriesIdQueryVariables = {
  seriesId?: string | null,
  mutatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSystemAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemAuditLogsBySeriesIdQuery = {
  listSystemAuditLogsBySeriesId?:  {
    __typename: "ModelSystemAuditLogConnection",
    items?:  Array< {
      __typename: "SystemAuditLog",
      id: string,
      owner: string,
      typeId: string,
      typeName: string,
      mutatedAt: string,
      mutatedBy?: string | null,
      oldValue?: string | null,
      newValue?: string | null,
      summary?: string | null,
      oldArchiveId?: string | null,
      newArchiveId?: string | null,
      eventId?: string | null,
      eventSequenceId?: string | null,
      facilityId?: string | null,
      personId?: string | null,
      seriesId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventDocumentQueryVariables = {
  id?: string,
};

export type GetEventDocumentQuery = {
  getEventDocument?:  {
    __typename: "EventDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventId: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
  } | null,
};

export type ListEventDocumentsQueryVariables = {
  id?: string | null,
  filter?: ModelEventDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventDocumentsQuery = {
  listEventDocuments?:  {
    __typename: "ModelEventDocumentConnection",
    items?:  Array< {
      __typename: "EventDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventId: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentEventsQueryVariables = {
  documentId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDocumentEventsQuery = {
  getDocumentEvents?:  {
    __typename: "ModelEventDocumentConnection",
    items?:  Array< {
      __typename: "EventDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventId: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventDocumentsBySeriesQueryVariables = {
  seriesId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventDocumentsBySeriesQuery = {
  listEventDocumentsBySeries?:  {
    __typename: "ModelEventDocumentConnection",
    items?:  Array< {
      __typename: "EventDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventId: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventDocumentsQueryVariables = {
  eventId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetEventDocumentsQuery = {
  getEventDocuments?:  {
    __typename: "ModelEventDocumentConnection",
    items?:  Array< {
      __typename: "EventDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventId: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventWatcherQueryVariables = {
  id?: string,
  deadline?: string,
};

export type GetEventWatcherQuery = {
  getEventWatcher?:  {
    __typename: "EventWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventId: string,
    deadline: string,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type ListEventWatchersQueryVariables = {
  id?: string | null,
  deadline?: ModelStringKeyConditionInput | null,
  filter?: ModelEventWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventWatchersQuery = {
  listEventWatchers?:  {
    __typename: "ModelEventWatcherConnection",
    items?:  Array< {
      __typename: "EventWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventId: string,
      deadline: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetPersonEventsQueryVariables = {
  personId?: string | null,
  deadline?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPersonEventsQuery = {
  getPersonEvents?:  {
    __typename: "ModelEventWatcherConnection",
    items?:  Array< {
      __typename: "EventWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventId: string,
      deadline: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventWatchersBySeriesQueryVariables = {
  seriesId?: string | null,
  deadline?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventWatchersBySeriesQuery = {
  listEventWatchersBySeries?:  {
    __typename: "ModelEventWatcherConnection",
    items?:  Array< {
      __typename: "EventWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventId: string,
      deadline: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventPeopleQueryVariables = {
  eventId?: string | null,
  deadline?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetEventPeopleQuery = {
  getEventPeople?:  {
    __typename: "ModelEventWatcherConnection",
    items?:  Array< {
      __typename: "EventWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventId: string,
      deadline: string,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetChargeQueryVariables = {
  id?: string,
};

export type GetChargeQuery = {
  getCharge?:  {
    __typename: "Charge",
    id: string,
    owner: string,
    facilityId?: string | null,
    module: ChargeModule,
    reportingYear: number,
    date: string,
    amount?: number | null,
    type: ChargeType,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListChargesQueryVariables = {
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChargesQuery = {
  listCharges?:  {
    __typename: "ModelChargeConnection",
    items?:  Array< {
      __typename: "Charge",
      id: string,
      owner: string,
      facilityId?: string | null,
      module: ChargeModule,
      reportingYear: number,
      date: string,
      amount?: number | null,
      type: ChargeType,
      memo?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListChargesByOwnerQueryVariables = {
  owner?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChargesByOwnerQuery = {
  listChargesByOwner?:  {
    __typename: "ModelChargeConnection",
    items?:  Array< {
      __typename: "Charge",
      id: string,
      owner: string,
      facilityId?: string | null,
      module: ChargeModule,
      reportingYear: number,
      date: string,
      amount?: number | null,
      type: ChargeType,
      memo?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListChargesByFacilityQueryVariables = {
  facilityId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChargesByFacilityQuery = {
  listChargesByFacility?:  {
    __typename: "ModelChargeConnection",
    items?:  Array< {
      __typename: "Charge",
      id: string,
      owner: string,
      facilityId?: string | null,
      module: ChargeModule,
      reportingYear: number,
      date: string,
      amount?: number | null,
      type: ChargeType,
      memo?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchChargesQueryVariables = {
  filter?: SearchableChargeFilterInput | null,
  sort?: SearchableChargeSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchChargesQuery = {
  searchCharges?:  {
    __typename: "SearchableChargeConnection",
    items?:  Array< {
      __typename: "Charge",
      id: string,
      owner: string,
      facilityId?: string | null,
      module: ChargeModule,
      reportingYear: number,
      date: string,
      amount?: number | null,
      type: ChargeType,
      memo?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetComputedQueryVariables = {
  id?: string,
  kind?: ComputationKind,
};

export type GetComputedQuery = {
  getComputed?:  {
    __typename: "Computed",
    id: string,
    typeId: string,
    owner: string,
    kind: ComputationKind,
    typeName?: string | null,
    computed?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListComputedsQueryVariables = {
  id?: string | null,
  kind?: ModelStringKeyConditionInput | null,
  filter?: ModelComputedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListComputedsQuery = {
  listComputeds?:  {
    __typename: "ModelComputedConnection",
    items?:  Array< {
      __typename: "Computed",
      id: string,
      typeId: string,
      owner: string,
      kind: ComputationKind,
      typeName?: string | null,
      computed?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListComputedByOwnerQueryVariables = {
  owner?: string | null,
  typeName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelComputedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListComputedByOwnerQuery = {
  listComputedByOwner?:  {
    __typename: "ModelComputedConnection",
    items?:  Array< {
      __typename: "Computed",
      id: string,
      typeId: string,
      owner: string,
      kind: ComputationKind,
      typeName?: string | null,
      computed?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListComputedByTypeQueryVariables = {
  typeName?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelComputedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListComputedByTypeQuery = {
  listComputedByType?:  {
    __typename: "ModelComputedConnection",
    items?:  Array< {
      __typename: "Computed",
      id: string,
      typeId: string,
      owner: string,
      kind: ComputationKind,
      typeName?: string | null,
      computed?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCredentialQueryVariables = {
  id?: string,
};

export type GetCredentialQuery = {
  getCredential?:  {
    __typename: "Credential",
    id: string,
    owner?: string | null,
    name: string,
    username: string,
    password: string,
    kind: CredentialKind,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    securityQuestions?:  Array< {
      __typename: "SecurityQuestion",
      question: string,
      answer: string,
    } > | null,
    verificationStatus?: CredentialVerificationStatus | null,
    lastVerificationSuccessAt?: string | null,
    lastVerificationCheckAt?: string | null,
    recentUsage?:  Array< {
      __typename: "CredentialUsageLogEntry",
      timestamp: string,
      verificationStatus: CredentialVerificationStatus,
      errorMessage?: string | null,
    } > | null,
    pools?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCredentialsQueryVariables = {
  filter?: ModelCredentialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCredentialsQuery = {
  listCredentials?:  {
    __typename: "ModelCredentialConnection",
    items?:  Array< {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCredentialsByOwnerQueryVariables = {
  owner?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCredentialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCredentialsByOwnerQuery = {
  listCredentialsByOwner?:  {
    __typename: "ModelCredentialConnection",
    items?:  Array< {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListCredentialsByOwnerByKindQueryVariables = {
  owner?: string | null,
  kind?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCredentialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCredentialsByOwnerByKindQuery = {
  listCredentialsByOwnerByKind?:  {
    __typename: "ModelCredentialConnection",
    items?:  Array< {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCredentialPoolQueryVariables = {
  id?: string,
};

export type GetCredentialPoolQuery = {
  getCredentialPool?:  {
    __typename: "CredentialPool",
    id: string,
    owner?: string | null,
    description?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credentials?:  {
      __typename: "ModelCredentialPoolCredentialConnection",
      items?:  Array< {
        __typename: "CredentialPoolCredential",
        id: string,
        owner: string,
        credentialId: string,
        credentialPoolId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        credentialPool:  {
          __typename: "CredentialPool",
          id: string,
          owner?: string | null,
          description?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credentials?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCredentialPoolsQueryVariables = {
  filter?: ModelCredentialPoolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCredentialPoolsQuery = {
  listCredentialPools?:  {
    __typename: "ModelCredentialPoolConnection",
    items?:  Array< {
      __typename: "CredentialPool",
      id: string,
      owner?: string | null,
      description?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      credentials?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCredentialPoolCredentialQueryVariables = {
  id?: string,
};

export type GetCredentialPoolCredentialQuery = {
  getCredentialPoolCredential?:  {
    __typename: "CredentialPoolCredential",
    id: string,
    owner: string,
    credentialId: string,
    credentialPoolId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credential:  {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    credentialPool:  {
      __typename: "CredentialPool",
      id: string,
      owner?: string | null,
      description?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      credentials?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCredentialPoolCredentialsQueryVariables = {
  filter?: ModelCredentialPoolCredentialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCredentialPoolCredentialsQuery = {
  listCredentialPoolCredentials?:  {
    __typename: "ModelCredentialPoolCredentialConnection",
    items?:  Array< {
      __typename: "CredentialPoolCredential",
      id: string,
      owner: string,
      credentialId: string,
      credentialPoolId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      credential:  {
        __typename: "Credential",
        id: string,
        owner?: string | null,
        name: string,
        username: string,
        password: string,
        kind: CredentialKind,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        securityQuestions?:  Array< {
          __typename: "SecurityQuestion",
          question: string,
          answer: string,
        } > | null,
        verificationStatus?: CredentialVerificationStatus | null,
        lastVerificationSuccessAt?: string | null,
        lastVerificationCheckAt?: string | null,
        recentUsage?:  Array< {
          __typename: "CredentialUsageLogEntry",
          timestamp: string,
          verificationStatus: CredentialVerificationStatus,
          errorMessage?: string | null,
        } > | null,
        pools?:  {
          __typename: "ModelCredentialPoolCredentialConnection",
          items?:  Array< {
            __typename: "CredentialPoolCredential",
            id: string,
            owner: string,
            credentialId: string,
            credentialPoolId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      credentialPool:  {
        __typename: "CredentialPool",
        id: string,
        owner?: string | null,
        description?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credentials?:  {
          __typename: "ModelCredentialPoolCredentialConnection",
          items?:  Array< {
            __typename: "CredentialPoolCredential",
            id: string,
            owner: string,
            credentialId: string,
            credentialPoolId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDataCollectionTaskQueryVariables = {
  id?: string,
};

export type GetDataCollectionTaskQuery = {
  getDataCollectionTask?:  {
    __typename: "DataCollectionTask",
    id: string,
    owner: string,
    dataCollectionTaskTemplateId: string,
    userTenantId: string,
    createdByUserTenantId: string,
    facilityId: string,
    title: string,
    savedState?:  {
      __typename: "DataCollectionTaskSavedState",
      productGroups?:  Array< {
        __typename: "DataCollectionTaskProductGroupState",
        id: string,
        inputValue: number,
      } | null > | null,
    } | null,
    status: DataCollectionTaskStatusEnum,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    template:  {
      __typename: "DataCollectionTaskTemplate",
      id: string,
      owner: string,
      title: string,
      description?: string | null,
      productGroupIds: Array< string >,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    },
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    createdByUserTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListDataCollectionTaskQueryVariables = {
  filter?: ModelDataCollectionTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataCollectionTaskQuery = {
  listDataCollectionTask?:  {
    __typename: "ModelDataCollectionTaskConnection",
    items?:  Array< {
      __typename: "DataCollectionTask",
      id: string,
      owner: string,
      dataCollectionTaskTemplateId: string,
      userTenantId: string,
      createdByUserTenantId: string,
      facilityId: string,
      title: string,
      savedState?:  {
        __typename: "DataCollectionTaskSavedState",
        productGroups?:  Array< {
          __typename: "DataCollectionTaskProductGroupState",
          id: string,
          inputValue: number,
        } | null > | null,
      } | null,
      status: DataCollectionTaskStatusEnum,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      template:  {
        __typename: "DataCollectionTaskTemplate",
        id: string,
        owner: string,
        title: string,
        description?: string | null,
        productGroupIds: Array< string >,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      },
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      createdByUserTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDataCollectionTaskByTenantQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCollectionTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataCollectionTaskByTenantQuery = {
  listDataCollectionTaskByTenant?:  {
    __typename: "ModelDataCollectionTaskConnection",
    items?:  Array< {
      __typename: "DataCollectionTask",
      id: string,
      owner: string,
      dataCollectionTaskTemplateId: string,
      userTenantId: string,
      createdByUserTenantId: string,
      facilityId: string,
      title: string,
      savedState?:  {
        __typename: "DataCollectionTaskSavedState",
        productGroups?:  Array< {
          __typename: "DataCollectionTaskProductGroupState",
          id: string,
          inputValue: number,
        } | null > | null,
      } | null,
      status: DataCollectionTaskStatusEnum,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      template:  {
        __typename: "DataCollectionTaskTemplate",
        id: string,
        owner: string,
        title: string,
        description?: string | null,
        productGroupIds: Array< string >,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      },
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      createdByUserTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDataCollectionTaskByUserTenantQueryVariables = {
  userTenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCollectionTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataCollectionTaskByUserTenantQuery = {
  listDataCollectionTaskByUserTenant?:  {
    __typename: "ModelDataCollectionTaskConnection",
    items?:  Array< {
      __typename: "DataCollectionTask",
      id: string,
      owner: string,
      dataCollectionTaskTemplateId: string,
      userTenantId: string,
      createdByUserTenantId: string,
      facilityId: string,
      title: string,
      savedState?:  {
        __typename: "DataCollectionTaskSavedState",
        productGroups?:  Array< {
          __typename: "DataCollectionTaskProductGroupState",
          id: string,
          inputValue: number,
        } | null > | null,
      } | null,
      status: DataCollectionTaskStatusEnum,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      template:  {
        __typename: "DataCollectionTaskTemplate",
        id: string,
        owner: string,
        title: string,
        description?: string | null,
        productGroupIds: Array< string >,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      },
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      createdByUserTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDataCollectionTaskByCreatorQueryVariables = {
  createdByUserTenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCollectionTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataCollectionTaskByCreatorQuery = {
  listDataCollectionTaskByCreator?:  {
    __typename: "ModelDataCollectionTaskConnection",
    items?:  Array< {
      __typename: "DataCollectionTask",
      id: string,
      owner: string,
      dataCollectionTaskTemplateId: string,
      userTenantId: string,
      createdByUserTenantId: string,
      facilityId: string,
      title: string,
      savedState?:  {
        __typename: "DataCollectionTaskSavedState",
        productGroups?:  Array< {
          __typename: "DataCollectionTaskProductGroupState",
          id: string,
          inputValue: number,
        } | null > | null,
      } | null,
      status: DataCollectionTaskStatusEnum,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      template:  {
        __typename: "DataCollectionTaskTemplate",
        id: string,
        owner: string,
        title: string,
        description?: string | null,
        productGroupIds: Array< string >,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      },
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      createdByUserTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTaskByFacilityQueryVariables = {
  facilityId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCollectionTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskByFacilityQuery = {
  listTaskByFacility?:  {
    __typename: "ModelDataCollectionTaskConnection",
    items?:  Array< {
      __typename: "DataCollectionTask",
      id: string,
      owner: string,
      dataCollectionTaskTemplateId: string,
      userTenantId: string,
      createdByUserTenantId: string,
      facilityId: string,
      title: string,
      savedState?:  {
        __typename: "DataCollectionTaskSavedState",
        productGroups?:  Array< {
          __typename: "DataCollectionTaskProductGroupState",
          id: string,
          inputValue: number,
        } | null > | null,
      } | null,
      status: DataCollectionTaskStatusEnum,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      template:  {
        __typename: "DataCollectionTaskTemplate",
        id: string,
        owner: string,
        title: string,
        description?: string | null,
        productGroupIds: Array< string >,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      },
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      createdByUserTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDataCollectionTaskTemplateQueryVariables = {
  id?: string,
};

export type GetDataCollectionTaskTemplateQuery = {
  getDataCollectionTaskTemplate?:  {
    __typename: "DataCollectionTaskTemplate",
    id: string,
    owner: string,
    title: string,
    description?: string | null,
    productGroupIds: Array< string >,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListDataCollectionTaskTemplateQueryVariables = {
  filter?: ModelDataCollectionTaskTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataCollectionTaskTemplateQuery = {
  listDataCollectionTaskTemplate?:  {
    __typename: "ModelDataCollectionTaskTemplateConnection",
    items?:  Array< {
      __typename: "DataCollectionTaskTemplate",
      id: string,
      owner: string,
      title: string,
      description?: string | null,
      productGroupIds: Array< string >,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDataCollectionTaskTemplateByTenantQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataCollectionTaskTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataCollectionTaskTemplateByTenantQuery = {
  listDataCollectionTaskTemplateByTenant?:  {
    __typename: "ModelDataCollectionTaskTemplateConnection",
    items?:  Array< {
      __typename: "DataCollectionTaskTemplate",
      id: string,
      owner: string,
      title: string,
      description?: string | null,
      productGroupIds: Array< string >,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentRequirementQueryVariables = {
  id?: string,
};

export type GetDocumentRequirementQuery = {
  getDocumentRequirement?:  {
    __typename: "DocumentRequirement",
    id: string,
    owner?: string | null,
    documentId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    events?:  {
      __typename: "ModelEventConnection",
      items?:  Array< {
        __typename: "Event",
        id: string,
        seriesId: string,
        requirementId: string,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId: string,
        eventResponsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventStatus,
        statusSearchable: string,
        owner?: string | null,
        deadline: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventDocumentConnection",
          items?:  Array< {
            __typename: "EventDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventId: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventWatcherConnection",
          items?:  Array< {
            __typename: "EventWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventId: string,
            deadline: string,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListDocumentRequirementsQueryVariables = {
  filter?: ModelDocumentRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentRequirementsQuery = {
  listDocumentRequirements?:  {
    __typename: "ModelDocumentRequirementConnection",
    items?:  Array< {
      __typename: "DocumentRequirement",
      id: string,
      owner?: string | null,
      documentId: string,
      requirementId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      events?:  {
        __typename: "ModelEventConnection",
        items?:  Array< {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirement:  {
        __typename: "Requirement",
        id: string,
        owner?: string | null,
        status?: RequirementStatus | null,
        name: string,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId: string,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area: string,
        areaSearchable: string,
        jurisdictionalAgency?: string | null,
        intervalInWords?: string | null,
        parentStatute?: ParentStatute | null,
        jurisdictionalState?: string | null,
        jurisdictionalCounties?: Array< string | null > | null,
        title?: string | null,
        portals?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        jurisdictionalTownship?: string | null,
        jurisdictionalTribalArea?: string | null,
        jurisdictionalCity?: string | null,
        formExpires?: string | null,
        forms?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        fillableLinks?: Array< string | null > | null,
        otherLinks?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        instructionFileName?: string | null,
        instruction?: string | null,
        extraDocumentations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        department?: string | null,
        subDepartment?: string | null,
        contactEmail?: string | null,
        contactPhone?: string | null,
        contactPhoneExtension?: number | null,
        isExtensionAvailable?: boolean | null,
        extensionNotes?: string | null,
        penalty?: string | null,
        encampInstructions?: string | null,
        isAlwaysApplicable?: boolean | null,
        agencyLevelSearchable?: string | null,
        agencyStateSearchable?: string | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        agency?:  {
          __typename: "Agency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        documents?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentQueryVariables = {
  id?: string,
};

export type GetDocumentQuery = {
  getDocument?:  {
    __typename: "Document",
    id: string,
    owner?: string | null,
    title: string,
    description?: string | null,
    fileExtension: string,
    state?: string | null,
    area?: string | null,
    programAreas?: Array< string | null > | null,
    documentType: string,
    sourceLink?: string | null,
    storageLink: string,
    agencyId?: string | null,
    facilityId?: string | null,
    lobId?: string | null,
    createdBy?: string | null,
    stateSearchable?: string | null,
    areaSearchable?: string | null,
    searchableProgramAreas?: string | null,
    relatedEntityIds?:  Array< {
      __typename: "DocumentRelatedEntityId",
      kind: DocumentRelatedEntityIdKind,
      id: string,
    } > | null,
    tags?: string | null,
    isArchived?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    requirements?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListDocumentsQueryVariables = {
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentConnection",
    items?:  Array< {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDocumentsByOwnerQueryVariables = {
  owner?: string | null,
  title?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentsByOwnerQuery = {
  listDocumentsByOwner?:  {
    __typename: "ModelDocumentConnection",
    items?:  Array< {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchDocumentsQueryVariables = {
  filter?: SearchableDocumentFilterInput | null,
  sort?: SearchableDocumentSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchDocumentsQuery = {
  searchDocuments?:  {
    __typename: "SearchableDocumentConnection",
    items?:  Array< {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetEpcra311TriggerQueryVariables = {
  id?: string,
};

export type GetEpcra311TriggerQuery = {
  getEpcra311Trigger?:  {
    __typename: "Epcra311Trigger",
    id: string,
    owner?: string | null,
    description?: string | null,
    triggeredAt: string,
    deadlineAt: string,
    isReported: boolean,
    isRequestingInformation?: boolean | null,
    facilityId: string,
    productId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListEpcra311TriggersQueryVariables = {
  id?: string | null,
  filter?: ModelEpcra311TriggerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEpcra311TriggersQuery = {
  listEpcra311Triggers?:  {
    __typename: "ModelEpcra311TriggerConnection",
    items?:  Array< {
      __typename: "Epcra311Trigger",
      id: string,
      owner?: string | null,
      description?: string | null,
      triggeredAt: string,
      deadlineAt: string,
      isReported: boolean,
      isRequestingInformation?: boolean | null,
      facilityId: string,
      productId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEpcra311TriggersByFacilityQueryVariables = {
  facilityId?: string | null,
  productId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEpcra311TriggerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEpcra311TriggersByFacilityQuery = {
  listEpcra311TriggersByFacility?:  {
    __typename: "ModelEpcra311TriggerConnection",
    items?:  Array< {
      __typename: "Epcra311Trigger",
      id: string,
      owner?: string | null,
      description?: string | null,
      triggeredAt: string,
      deadlineAt: string,
      isReported: boolean,
      isRequestingInformation?: boolean | null,
      facilityId: string,
      productId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEpcra311TriggersByOwnerQueryVariables = {
  owner?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEpcra311TriggerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEpcra311TriggersByOwnerQuery = {
  listEpcra311TriggersByOwner?:  {
    __typename: "ModelEpcra311TriggerConnection",
    items?:  Array< {
      __typename: "Epcra311Trigger",
      id: string,
      owner?: string | null,
      description?: string | null,
      triggeredAt: string,
      deadlineAt: string,
      isReported: boolean,
      isRequestingInformation?: boolean | null,
      facilityId: string,
      productId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEpcra311TriggersByProductQueryVariables = {
  productId?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEpcra311TriggerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEpcra311TriggersByProductQuery = {
  listEpcra311TriggersByProduct?:  {
    __typename: "ModelEpcra311TriggerConnection",
    items?:  Array< {
      __typename: "Epcra311Trigger",
      id: string,
      owner?: string | null,
      description?: string | null,
      triggeredAt: string,
      deadlineAt: string,
      isReported: boolean,
      isRequestingInformation?: boolean | null,
      facilityId: string,
      productId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id?: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    seriesId: string,
    requirementId: string,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId: string,
    eventResponsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventStatus,
    statusSearchable: string,
    owner?: string | null,
    deadline: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventDocumentConnection",
      items?:  Array< {
        __typename: "EventDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventId: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventWatcherConnection",
      items?:  Array< {
        __typename: "EventWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventId: string,
        deadline: string,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          seriesId: string,
          requirementId: string,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId: string,
          eventResponsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventStatus,
          statusSearchable: string,
          owner?: string | null,
          deadline: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListEventsQueryVariables = {
  id?: string | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByDeadlineQueryVariables = {
  owner?: string | null,
  deadline?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByDeadlineQuery = {
  listEventsByDeadline?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByFacilityIdQueryVariables = {
  facilityId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByFacilityIdQuery = {
  listEventsByFacilityId?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByCompletionQueryVariables = {
  owner?: string | null,
  completedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByCompletionQuery = {
  listEventsByCompletion?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByStatusQueryVariables = {
  owner?: string | null,
  statusDeadline?: ModelEventByStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByStatusQuery = {
  listEventsByStatus?:  {
    __typename: "ModelEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchEventsQueryVariables = {
  filter?: SearchableEventFilterInput | null,
  sort?: SearchableEventSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchEventsQuery = {
  searchEvents?:  {
    __typename: "SearchableEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      seriesId: string,
      requirementId: string,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId: string,
      eventResponsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventStatus,
      statusSearchable: string,
      owner?: string | null,
      deadline: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventDocumentConnection",
        items?:  Array< {
          __typename: "EventDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventId: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventWatcherConnection",
        items?:  Array< {
          __typename: "EventWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventId: string,
          deadline: string,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          event:  {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetEventSequenceQueryVariables = {
  id?: string,
};

export type GetEventSequenceQuery = {
  getEventSequence?:  {
    __typename: "EventSequence",
    id: string,
    parentId?: string | null,
    owner: string,
    seriesId: string,
    taskId?: string | null,
    type: EventSequenceType,
    typeSearchable: string,
    startDate: string,
    endDate: string,
    isRecurring?: boolean | null,
    isOrganizationLevel?: boolean | null,
    exceptions?: Array< string > | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    _requirementArea: string,
    facilityId?: string | null,
    state?: string | null,
    responsiblePersonId?: string | null,
    notificationLeadTimes?:  Array< {
      __typename: "Timespan",
      value: number,
      unit: TimeIncrement,
    } > | null,
    completedAt?: string | null,
    status: EventSequenceStatus,
    statusSearchable: string,
    description?: string | null,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    documents?:  {
      __typename: "ModelEventSequenceDocumentConnection",
      items?:  Array< {
        __typename: "EventSequenceDocument",
        id: string,
        owner?: string | null,
        documentId: string,
        eventSequenceId: string,
        seriesId: string,
        taskId?: string | null,
        facilityId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watchers?:  {
      __typename: "ModelEventSequenceWatcherConnection",
      items?:  Array< {
        __typename: "EventSequenceWatcher",
        id: string,
        owner?: string | null,
        personId: string,
        eventSequenceId: string,
        taskId?: string | null,
        seriesId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        eventSequence:  {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    responsiblePerson?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task?:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListEventSequencesQueryVariables = {
  id?: string | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventSequencesQuery = {
  listEventSequences?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesByOwnerQueryVariables = {
  owner?: string | null,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesByOwnerQuery = {
  listEventSequencesByOwner?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesBySeriesIdQueryVariables = {
  seriesId?: string | null,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesBySeriesIdQuery = {
  listEventSequencesBySeriesId?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesBySeriesIdEndDateQueryVariables = {
  seriesId?: string | null,
  endDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesBySeriesIdEndDateQuery = {
  listEventSequencesBySeriesIdEndDate?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesByFacilityIdQueryVariables = {
  facilityId?: string | null,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesByFacilityIdQuery = {
  listEventSequencesByFacilityId?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesByCompletionQueryVariables = {
  owner?: string | null,
  completedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesByCompletionQuery = {
  listEventSequencesByCompletion?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesByStatusQueryVariables = {
  owner?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesByStatusQuery = {
  listEventSequencesByStatus?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesByTaskIdQueryVariables = {
  owner?: string | null,
  taskId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesByTaskIdQuery = {
  listEventSequencesByTaskId?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesByTypeQueryVariables = {
  owner?: string | null,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesByTypeQuery = {
  listEventSequencesByType?:  {
    __typename: "ModelEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchEventSequencesQueryVariables = {
  filter?: SearchableEventSequenceFilterInput | null,
  sort?: SearchableEventSequenceSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchEventSequencesQuery = {
  searchEventSequences?:  {
    __typename: "SearchableEventSequenceConnection",
    items?:  Array< {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetEventSequenceDocumentQueryVariables = {
  id?: string,
};

export type GetEventSequenceDocumentQuery = {
  getEventSequenceDocument?:  {
    __typename: "EventSequenceDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    eventSequenceId: string,
    seriesId: string,
    taskId?: string | null,
    facilityId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type ListEventSequenceDocumentsQueryVariables = {
  id?: string | null,
  filter?: ModelEventSequenceDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventSequenceDocumentsQuery = {
  listEventSequenceDocuments?:  {
    __typename: "ModelEventSequenceDocumentConnection",
    items?:  Array< {
      __typename: "EventSequenceDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventSequenceId: string,
      seriesId: string,
      taskId?: string | null,
      facilityId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequenceDocumentsBySeriesQueryVariables = {
  seriesId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceDocumentsBySeriesQuery = {
  listEventSequenceDocumentsBySeries?:  {
    __typename: "ModelEventSequenceDocumentConnection",
    items?:  Array< {
      __typename: "EventSequenceDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventSequenceId: string,
      seriesId: string,
      taskId?: string | null,
      facilityId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequenceDocumentsBySequenceQueryVariables = {
  eventSequenceId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceDocumentsBySequenceQuery = {
  listEventSequenceDocumentsBySequence?:  {
    __typename: "ModelEventSequenceDocumentConnection",
    items?:  Array< {
      __typename: "EventSequenceDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventSequenceId: string,
      seriesId: string,
      taskId?: string | null,
      facilityId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequenceDocumentsByTaskQueryVariables = {
  taskId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceDocumentsByTaskQuery = {
  listEventSequenceDocumentsByTask?:  {
    __typename: "ModelEventSequenceDocumentConnection",
    items?:  Array< {
      __typename: "EventSequenceDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventSequenceId: string,
      seriesId: string,
      taskId?: string | null,
      facilityId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequenceDocumentsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceDocumentsByOwnerQuery = {
  listEventSequenceDocumentsByOwner?:  {
    __typename: "ModelEventSequenceDocumentConnection",
    items?:  Array< {
      __typename: "EventSequenceDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      eventSequenceId: string,
      seriesId: string,
      taskId?: string | null,
      facilityId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetEventSequenceWatcherQueryVariables = {
  id?: string,
};

export type GetEventSequenceWatcherQuery = {
  getEventSequenceWatcher?:  {
    __typename: "EventSequenceWatcher",
    id: string,
    owner?: string | null,
    personId: string,
    eventSequenceId: string,
    taskId?: string | null,
    seriesId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    eventSequence:  {
      __typename: "EventSequence",
      id: string,
      parentId?: string | null,
      owner: string,
      seriesId: string,
      taskId?: string | null,
      type: EventSequenceType,
      typeSearchable: string,
      startDate: string,
      endDate: string,
      isRecurring?: boolean | null,
      isOrganizationLevel?: boolean | null,
      exceptions?: Array< string > | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      _requirementArea: string,
      facilityId?: string | null,
      state?: string | null,
      responsiblePersonId?: string | null,
      notificationLeadTimes?:  Array< {
        __typename: "Timespan",
        value: number,
        unit: TimeIncrement,
      } > | null,
      completedAt?: string | null,
      status: EventSequenceStatus,
      statusSearchable: string,
      description?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      documents?:  {
        __typename: "ModelEventSequenceDocumentConnection",
        items?:  Array< {
          __typename: "EventSequenceDocument",
          id: string,
          owner?: string | null,
          documentId: string,
          eventSequenceId: string,
          seriesId: string,
          taskId?: string | null,
          facilityId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watchers?:  {
        __typename: "ModelEventSequenceWatcherConnection",
        items?:  Array< {
          __typename: "EventSequenceWatcher",
          id: string,
          owner?: string | null,
          personId: string,
          eventSequenceId: string,
          taskId?: string | null,
          seriesId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          eventSequence:  {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      responsiblePerson?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task?:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
  } | null,
};

export type ListEventSequenceWatchersQueryVariables = {
  filter?: ModelEventSequenceWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceWatchersQuery = {
  listEventSequenceWatchers?:  {
    __typename: "ModelEventSequenceWatcherConnection",
    items?:  Array< {
      __typename: "EventSequenceWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventSequenceId: string,
      taskId?: string | null,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequencesWatchersBySeriesQueryVariables = {
  seriesId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequencesWatchersBySeriesQuery = {
  listEventSequencesWatchersBySeries?:  {
    __typename: "ModelEventSequenceWatcherConnection",
    items?:  Array< {
      __typename: "EventSequenceWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventSequenceId: string,
      taskId?: string | null,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequenceWatchersByTaskIdQueryVariables = {
  taskId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceWatchersByTaskIdQuery = {
  listEventSequenceWatchersByTaskId?:  {
    __typename: "ModelEventSequenceWatcherConnection",
    items?:  Array< {
      __typename: "EventSequenceWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventSequenceId: string,
      taskId?: string | null,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequenceWatchersBySequenceQueryVariables = {
  eventSequenceId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceWatchersBySequenceQuery = {
  listEventSequenceWatchersBySequence?:  {
    __typename: "ModelEventSequenceWatcherConnection",
    items?:  Array< {
      __typename: "EventSequenceWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventSequenceId: string,
      taskId?: string | null,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListEventSequenceWatchersByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventSequenceWatcherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventSequenceWatchersByOwnerQuery = {
  listEventSequenceWatchersByOwner?:  {
    __typename: "ModelEventSequenceWatcherConnection",
    items?:  Array< {
      __typename: "EventSequenceWatcher",
      id: string,
      owner?: string | null,
      personId: string,
      eventSequenceId: string,
      taskId?: string | null,
      seriesId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      eventSequence:  {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFacilityQueryVariables = {
  id?: string,
};

export type GetFacilityQuery = {
  getFacility?:  {
    __typename: "Facility",
    id: string,
    owner?: string | null,
    name: string,
    status?: FacilityStatus | null,
    statusReason?: string | null,
    epaGeneratorStatus?: RcraGeneratorStatus | null,
    epaGeneratorStatusUpdatedAt?: string | null,
    epaGeneratorStatusUpdatedBy?: string | null,
    stateGeneratorStatus?: RcraGeneratorStatus | null,
    stateGeneratorStatusUpdatedAt?: string | null,
    stateGeneratorStatusUpdatedBy?: string | null,
    businessUnit?: string | null,
    customerFacilityId?: string | null,
    dbNumber?: string | null,
    facilityType?: string | null,
    naicsCode?: number | null,
    maxNumOccupants?: number | null,
    isUnmanned?: boolean | null,
    phone?: string | null,
    phone24Hour?: string | null,
    streetAddress:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    },
    mailingAddress?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    county?: string | null,
    municipality?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    isAutoGeocoded?: boolean | null,
    lepc?: string | null,
    lepcId?: string | null,
    fireDistrict?: string | null,
    fireDistrictId?: string | null,
    programAreas?: Array< string | null > | null,
    alternateIds?:  Array< {
      __typename: "FacilityAlternateId",
      kind: string,
      id: string,
      expiresAt?: string | null,
      recordKeys?: Array< string | null > | null,
    } > | null,
    userPermissions?:  Array< {
      __typename: "UserPermission",
      userTenantId: string,
      level: UserPermissionLevel,
    } | null > | null,
    dateOpened?: string | null,
    dateClosed?: string | null,
    dateInactive?: string | null,
    regulatoryInformation?:  {
      __typename: "FacilityRegulatoryInformation",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    federalFields?: string | null,
    stateFields?: string | null,
    searchableProgramAreas?: string | null,
    searchableStatus?: string | null,
    searchableCity?: string | null,
    lastProductMeasuredAt?: string | null,
    customerProductComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    streetAddressVerifiedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    contacts?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    credentials?:  {
      __typename: "ModelFacilityCredentialConnection",
      items?:  Array< {
        __typename: "FacilityCredential",
        id: string,
        owner?: string | null,
        facilityId: string,
        credentialId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        credential:  {
          __typename: "Credential",
          id: string,
          owner?: string | null,
          name: string,
          username: string,
          password: string,
          kind: CredentialKind,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          securityQuestions?:  Array< {
            __typename: "SecurityQuestion",
            question: string,
            answer: string,
          } > | null,
          verificationStatus?: CredentialVerificationStatus | null,
          lastVerificationSuccessAt?: string | null,
          lastVerificationCheckAt?: string | null,
          recentUsage?:  Array< {
            __typename: "CredentialUsageLogEntry",
            timestamp: string,
            verificationStatus: CredentialVerificationStatus,
            errorMessage?: string | null,
          } > | null,
          pools?:  {
            __typename: "ModelCredentialPoolCredentialConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
        },
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListFacilitiesQueryVariables = {
  filter?: ModelFacilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilitiesQuery = {
  listFacilities?:  {
    __typename: "ModelFacilityConnection",
    items?:  Array< {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilitiesByOwnerQueryVariables = {
  owner?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilitiesByOwnerQuery = {
  listFacilitiesByOwner?:  {
    __typename: "ModelFacilityConnection",
    items?:  Array< {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilitiesByNameQueryVariables = {
  name?: string | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilitiesByNameQuery = {
  listFacilitiesByName?:  {
    __typename: "ModelFacilityConnection",
    items?:  Array< {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilitiesByStateQueryVariables = {
  state?: string | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilitiesByStateQuery = {
  listFacilitiesByState?:  {
    __typename: "ModelFacilityConnection",
    items?:  Array< {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchFacilitysQueryVariables = {
  filter?: SearchableFacilityFilterInput | null,
  sort?: SearchableFacilitySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFacilitysQuery = {
  searchFacilitys?:  {
    __typename: "SearchableFacilityConnection",
    items?:  Array< {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFacilityContactQueryVariables = {
  id?: string,
};

export type GetFacilityContactQuery = {
  getFacilityContact?:  {
    __typename: "FacilityContact",
    id: string,
    owner?: string | null,
    facilityId: string,
    personId: string,
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    areas?: Array< string > | null,
    reportingRoles?: Array< ContactReportRole > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListFacilityContactsQueryVariables = {
  filter?: ModelFacilityContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityContactsQuery = {
  listFacilityContacts?:  {
    __typename: "ModelFacilityContactConnection",
    items?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityContactsByOwnerQueryVariables = {
  owner?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityContactsByOwnerQuery = {
  listFacilityContactsByOwner?:  {
    __typename: "ModelFacilityContactConnection",
    items?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityContactsByFacilityQueryVariables = {
  facilityId?: string | null,
  personId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityContactsByFacilityQuery = {
  listFacilityContactsByFacility?:  {
    __typename: "ModelFacilityContactConnection",
    items?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityContactsByPersonQueryVariables = {
  personId?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityContactsByPersonQuery = {
  listFacilityContactsByPerson?:  {
    __typename: "ModelFacilityContactConnection",
    items?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFacilityCredentialQueryVariables = {
  id?: string,
};

export type GetFacilityCredentialQuery = {
  getFacilityCredential?:  {
    __typename: "FacilityCredential",
    id: string,
    owner?: string | null,
    facilityId: string,
    credentialId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    credential:  {
      __typename: "Credential",
      id: string,
      owner?: string | null,
      name: string,
      username: string,
      password: string,
      kind: CredentialKind,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      securityQuestions?:  Array< {
        __typename: "SecurityQuestion",
        question: string,
        answer: string,
      } > | null,
      verificationStatus?: CredentialVerificationStatus | null,
      lastVerificationSuccessAt?: string | null,
      lastVerificationCheckAt?: string | null,
      recentUsage?:  Array< {
        __typename: "CredentialUsageLogEntry",
        timestamp: string,
        verificationStatus: CredentialVerificationStatus,
        errorMessage?: string | null,
      } > | null,
      pools?:  {
        __typename: "ModelCredentialPoolCredentialConnection",
        items?:  Array< {
          __typename: "CredentialPoolCredential",
          id: string,
          owner: string,
          credentialId: string,
          credentialPoolId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          credentialPool:  {
            __typename: "CredentialPool",
            id: string,
            owner?: string | null,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListFacilityCredentialsQueryVariables = {
  filter?: ModelFacilityCredentialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityCredentialsQuery = {
  listFacilityCredentials?:  {
    __typename: "ModelFacilityCredentialConnection",
    items?:  Array< {
      __typename: "FacilityCredential",
      id: string,
      owner?: string | null,
      facilityId: string,
      credentialId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      credential:  {
        __typename: "Credential",
        id: string,
        owner?: string | null,
        name: string,
        username: string,
        password: string,
        kind: CredentialKind,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        securityQuestions?:  Array< {
          __typename: "SecurityQuestion",
          question: string,
          answer: string,
        } > | null,
        verificationStatus?: CredentialVerificationStatus | null,
        lastVerificationSuccessAt?: string | null,
        lastVerificationCheckAt?: string | null,
        recentUsage?:  Array< {
          __typename: "CredentialUsageLogEntry",
          timestamp: string,
          verificationStatus: CredentialVerificationStatus,
          errorMessage?: string | null,
        } > | null,
        pools?:  {
          __typename: "ModelCredentialPoolCredentialConnection",
          items?:  Array< {
            __typename: "CredentialPoolCredential",
            id: string,
            owner: string,
            credentialId: string,
            credentialPoolId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityCredentialByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityCredentialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityCredentialByOwnerQuery = {
  listFacilityCredentialByOwner?:  {
    __typename: "ModelFacilityCredentialConnection",
    items?:  Array< {
      __typename: "FacilityCredential",
      id: string,
      owner?: string | null,
      facilityId: string,
      credentialId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      credential:  {
        __typename: "Credential",
        id: string,
        owner?: string | null,
        name: string,
        username: string,
        password: string,
        kind: CredentialKind,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        securityQuestions?:  Array< {
          __typename: "SecurityQuestion",
          question: string,
          answer: string,
        } > | null,
        verificationStatus?: CredentialVerificationStatus | null,
        lastVerificationSuccessAt?: string | null,
        lastVerificationCheckAt?: string | null,
        recentUsage?:  Array< {
          __typename: "CredentialUsageLogEntry",
          timestamp: string,
          verificationStatus: CredentialVerificationStatus,
          errorMessage?: string | null,
        } > | null,
        pools?:  {
          __typename: "ModelCredentialPoolCredentialConnection",
          items?:  Array< {
            __typename: "CredentialPoolCredential",
            id: string,
            owner: string,
            credentialId: string,
            credentialPoolId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFacilityMeasurementQueryVariables = {
  id?: string,
};

export type GetFacilityMeasurementQuery = {
  getFacilityMeasurement?:  {
    __typename: "FacilityMeasurement",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    amount: number,
    unit: string,
    measuredAt: string,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    createdAt: string,
    updatedAt: string,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListFacilityMeasurementsQueryVariables = {
  id?: string | null,
  filter?: ModelFacilityMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFacilityMeasurementsQuery = {
  listFacilityMeasurements?:  {
    __typename: "ModelFacilityMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityMeasurement",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      amount: number,
      unit: string,
      measuredAt: string,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      createdAt: string,
      updatedAt: string,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityMeasurementsByFacilityQueryVariables = {
  facilityId?: string | null,
  productIdMeasuredAt?: ModelFacilityMeasurementByFacilityCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityMeasurementsByFacilityQuery = {
  listFacilityMeasurementsByFacility?:  {
    __typename: "ModelFacilityMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityMeasurement",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      amount: number,
      unit: string,
      measuredAt: string,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      createdAt: string,
      updatedAt: string,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityMeasurementsByFacilityProductQueryVariables = {
  facilityId?: string | null,
  productId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityMeasurementsByFacilityProductQuery = {
  listFacilityMeasurementsByFacilityProduct?:  {
    __typename: "ModelFacilityMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityMeasurement",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      amount: number,
      unit: string,
      measuredAt: string,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      createdAt: string,
      updatedAt: string,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityMeasurementsByOwnerQueryVariables = {
  owner?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityMeasurementsByOwnerQuery = {
  listFacilityMeasurementsByOwner?:  {
    __typename: "ModelFacilityMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityMeasurement",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      amount: number,
      unit: string,
      measuredAt: string,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      createdAt: string,
      updatedAt: string,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityMeasurementsByProductQueryVariables = {
  productId?: string | null,
  facilityIdMeasuredAt?: ModelFacilityMeasurementByProductCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityMeasurementsByProductQuery = {
  listFacilityMeasurementsByProduct?:  {
    __typename: "ModelFacilityMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityMeasurement",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      amount: number,
      unit: string,
      measuredAt: string,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      createdAt: string,
      updatedAt: string,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityMeasurementsByFacilityMeasuredAtQueryVariables = {
  facilityId?: string | null,
  measuredAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityMeasurementsByFacilityMeasuredAtQuery = {
  listFacilityMeasurementsByFacilityMeasuredAt?:  {
    __typename: "ModelFacilityMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityMeasurement",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      amount: number,
      unit: string,
      measuredAt: string,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      createdAt: string,
      updatedAt: string,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFacilityProductGroupMeasurementQueryVariables = {
  id?: string,
};

export type GetFacilityProductGroupMeasurementQuery = {
  getFacilityProductGroupMeasurement?:  {
    __typename: "FacilityProductGroupMeasurement",
    id: string,
    facilityId: string,
    owner: string,
    productGroupId: string,
    amount: number,
    measuredAt: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListFacilityProductGroupMeasurementsQueryVariables = {
  id?: string | null,
  filter?: ModelFacilityProductGroupMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFacilityProductGroupMeasurementsQuery = {
  listFacilityProductGroupMeasurements?:  {
    __typename: "ModelFacilityProductGroupMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityProductGroupMeasurement",
      id: string,
      facilityId: string,
      owner: string,
      productGroupId: string,
      amount: number,
      measuredAt: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      productGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityProductGroupMeasurementsByFacilityQueryVariables = {
  facilityId?: string | null,
  productGroupIdMeasuredAt?: ModelFacilityProductGroupMeasurementByFacilityCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityProductGroupMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityProductGroupMeasurementsByFacilityQuery = {
  listFacilityProductGroupMeasurementsByFacility?:  {
    __typename: "ModelFacilityProductGroupMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityProductGroupMeasurement",
      id: string,
      facilityId: string,
      owner: string,
      productGroupId: string,
      amount: number,
      measuredAt: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      productGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityProductGroupMeasurementsByOwnerQueryVariables = {
  owner?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityProductGroupMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityProductGroupMeasurementsByOwnerQuery = {
  listFacilityProductGroupMeasurementsByOwner?:  {
    __typename: "ModelFacilityProductGroupMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityProductGroupMeasurement",
      id: string,
      facilityId: string,
      owner: string,
      productGroupId: string,
      amount: number,
      measuredAt: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      productGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityProductGroupMeasurementsByProductGroupQueryVariables = {
  productGroupId?: string | null,
  facilityIdMeasuredAt?: ModelFacilityProductGroupMeasurementByProductGroupCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityProductGroupMeasurementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityProductGroupMeasurementsByProductGroupQuery = {
  listFacilityProductGroupMeasurementsByProductGroup?:  {
    __typename: "ModelFacilityProductGroupMeasurementConnection",
    items?:  Array< {
      __typename: "FacilityProductGroupMeasurement",
      id: string,
      facilityId: string,
      owner: string,
      productGroupId: string,
      amount: number,
      measuredAt: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      productGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFacilityProductQueryVariables = {
  id?: string,
};

export type GetFacilityProductQuery = {
  getFacilityProduct?:  {
    __typename: "FacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    chemName: string,
    casNumber?: string | null,
    alternateId?: string | null,
    isEHS?: boolean | null,
    unit?: string | null,
    density?: number | null,
    densityUnits?: string | null,
    lastAmount?: number | null,
    maxAmount?: number | null,
    averageAmount?: number | null,
    totalMeasurements?: number | null,
    totalLocations?: number | null,
    isAboveReportingThresholds?: boolean | null,
    lastProductMeasuredAt?: string | null,
    daysOnSite?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListFacilityProductsQueryVariables = {
  id?: string | null,
  filter?: ModelFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFacilityProductsQuery = {
  listFacilityProducts?:  {
    __typename: "ModelFacilityProductConnection",
    items?:  Array< {
      __typename: "FacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      chemName: string,
      casNumber?: string | null,
      alternateId?: string | null,
      isEHS?: boolean | null,
      unit?: string | null,
      density?: number | null,
      densityUnits?: string | null,
      lastAmount?: number | null,
      maxAmount?: number | null,
      averageAmount?: number | null,
      totalMeasurements?: number | null,
      totalLocations?: number | null,
      isAboveReportingThresholds?: boolean | null,
      lastProductMeasuredAt?: string | null,
      daysOnSite?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityProductsByFacilityQueryVariables = {
  facilityId?: string | null,
  productId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityProductsByFacilityQuery = {
  listFacilityProductsByFacility?:  {
    __typename: "ModelFacilityProductConnection",
    items?:  Array< {
      __typename: "FacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      chemName: string,
      casNumber?: string | null,
      alternateId?: string | null,
      isEHS?: boolean | null,
      unit?: string | null,
      density?: number | null,
      densityUnits?: string | null,
      lastAmount?: number | null,
      maxAmount?: number | null,
      averageAmount?: number | null,
      totalMeasurements?: number | null,
      totalLocations?: number | null,
      isAboveReportingThresholds?: boolean | null,
      lastProductMeasuredAt?: string | null,
      daysOnSite?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityProductsByOwnerQueryVariables = {
  owner?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityProductsByOwnerQuery = {
  listFacilityProductsByOwner?:  {
    __typename: "ModelFacilityProductConnection",
    items?:  Array< {
      __typename: "FacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      chemName: string,
      casNumber?: string | null,
      alternateId?: string | null,
      isEHS?: boolean | null,
      unit?: string | null,
      density?: number | null,
      densityUnits?: string | null,
      lastAmount?: number | null,
      maxAmount?: number | null,
      averageAmount?: number | null,
      totalMeasurements?: number | null,
      totalLocations?: number | null,
      isAboveReportingThresholds?: boolean | null,
      lastProductMeasuredAt?: string | null,
      daysOnSite?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityProductsByProductQueryVariables = {
  productId?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityProductsByProductQuery = {
  listFacilityProductsByProduct?:  {
    __typename: "ModelFacilityProductConnection",
    items?:  Array< {
      __typename: "FacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      chemName: string,
      casNumber?: string | null,
      alternateId?: string | null,
      isEHS?: boolean | null,
      unit?: string | null,
      density?: number | null,
      densityUnits?: string | null,
      lastAmount?: number | null,
      maxAmount?: number | null,
      averageAmount?: number | null,
      totalMeasurements?: number | null,
      totalLocations?: number | null,
      isAboveReportingThresholds?: boolean | null,
      lastProductMeasuredAt?: string | null,
      daysOnSite?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchFacilityProductsQueryVariables = {
  filter?: SearchableFacilityProductFilterInput | null,
  sort?: SearchableFacilityProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFacilityProductsQuery = {
  searchFacilityProducts?:  {
    __typename: "SearchableFacilityProductConnection",
    items?:  Array< {
      __typename: "FacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      chemName: string,
      casNumber?: string | null,
      alternateId?: string | null,
      isEHS?: boolean | null,
      unit?: string | null,
      density?: number | null,
      densityUnits?: string | null,
      lastAmount?: number | null,
      maxAmount?: number | null,
      averageAmount?: number | null,
      totalMeasurements?: number | null,
      totalLocations?: number | null,
      isAboveReportingThresholds?: boolean | null,
      lastProductMeasuredAt?: string | null,
      daysOnSite?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFacilityRequirementQueryVariables = {
  id?: string,
};

export type GetFacilityRequirementQuery = {
  getFacilityRequirement?:  {
    __typename: "FacilityRequirement",
    id: string,
    owner?: string | null,
    facilityId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListFacilityRequirementsQueryVariables = {
  filter?: ModelFacilityRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityRequirementsQuery = {
  listFacilityRequirements?:  {
    __typename: "ModelFacilityRequirementConnection",
    items?:  Array< {
      __typename: "FacilityRequirement",
      id: string,
      owner?: string | null,
      facilityId: string,
      requirementId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      requirement:  {
        __typename: "Requirement",
        id: string,
        owner?: string | null,
        status?: RequirementStatus | null,
        name: string,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId: string,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area: string,
        areaSearchable: string,
        jurisdictionalAgency?: string | null,
        intervalInWords?: string | null,
        parentStatute?: ParentStatute | null,
        jurisdictionalState?: string | null,
        jurisdictionalCounties?: Array< string | null > | null,
        title?: string | null,
        portals?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        jurisdictionalTownship?: string | null,
        jurisdictionalTribalArea?: string | null,
        jurisdictionalCity?: string | null,
        formExpires?: string | null,
        forms?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        fillableLinks?: Array< string | null > | null,
        otherLinks?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        instructionFileName?: string | null,
        instruction?: string | null,
        extraDocumentations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        department?: string | null,
        subDepartment?: string | null,
        contactEmail?: string | null,
        contactPhone?: string | null,
        contactPhoneExtension?: number | null,
        isExtensionAvailable?: boolean | null,
        extensionNotes?: string | null,
        penalty?: string | null,
        encampInstructions?: string | null,
        isAlwaysApplicable?: boolean | null,
        agencyLevelSearchable?: string | null,
        agencyStateSearchable?: string | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        agency?:  {
          __typename: "Agency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        documents?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityRequirementsByFacilityQueryVariables = {
  facilityId?: string | null,
  requirementId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityRequirementsByFacilityQuery = {
  listFacilityRequirementsByFacility?:  {
    __typename: "ModelFacilityRequirementConnection",
    items?:  Array< {
      __typename: "FacilityRequirement",
      id: string,
      owner?: string | null,
      facilityId: string,
      requirementId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      requirement:  {
        __typename: "Requirement",
        id: string,
        owner?: string | null,
        status?: RequirementStatus | null,
        name: string,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId: string,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area: string,
        areaSearchable: string,
        jurisdictionalAgency?: string | null,
        intervalInWords?: string | null,
        parentStatute?: ParentStatute | null,
        jurisdictionalState?: string | null,
        jurisdictionalCounties?: Array< string | null > | null,
        title?: string | null,
        portals?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        jurisdictionalTownship?: string | null,
        jurisdictionalTribalArea?: string | null,
        jurisdictionalCity?: string | null,
        formExpires?: string | null,
        forms?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        fillableLinks?: Array< string | null > | null,
        otherLinks?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        instructionFileName?: string | null,
        instruction?: string | null,
        extraDocumentations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        department?: string | null,
        subDepartment?: string | null,
        contactEmail?: string | null,
        contactPhone?: string | null,
        contactPhoneExtension?: number | null,
        isExtensionAvailable?: boolean | null,
        extensionNotes?: string | null,
        penalty?: string | null,
        encampInstructions?: string | null,
        isAlwaysApplicable?: boolean | null,
        agencyLevelSearchable?: string | null,
        agencyStateSearchable?: string | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        agency?:  {
          __typename: "Agency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        documents?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityRequirementsByOwnerQueryVariables = {
  owner?: string | null,
  requirementId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityRequirementsByOwnerQuery = {
  listFacilityRequirementsByOwner?:  {
    __typename: "ModelFacilityRequirementConnection",
    items?:  Array< {
      __typename: "FacilityRequirement",
      id: string,
      owner?: string | null,
      facilityId: string,
      requirementId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      requirement:  {
        __typename: "Requirement",
        id: string,
        owner?: string | null,
        status?: RequirementStatus | null,
        name: string,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId: string,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area: string,
        areaSearchable: string,
        jurisdictionalAgency?: string | null,
        intervalInWords?: string | null,
        parentStatute?: ParentStatute | null,
        jurisdictionalState?: string | null,
        jurisdictionalCounties?: Array< string | null > | null,
        title?: string | null,
        portals?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        jurisdictionalTownship?: string | null,
        jurisdictionalTribalArea?: string | null,
        jurisdictionalCity?: string | null,
        formExpires?: string | null,
        forms?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        fillableLinks?: Array< string | null > | null,
        otherLinks?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        instructionFileName?: string | null,
        instruction?: string | null,
        extraDocumentations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        department?: string | null,
        subDepartment?: string | null,
        contactEmail?: string | null,
        contactPhone?: string | null,
        contactPhoneExtension?: number | null,
        isExtensionAvailable?: boolean | null,
        extensionNotes?: string | null,
        penalty?: string | null,
        encampInstructions?: string | null,
        isAlwaysApplicable?: boolean | null,
        agencyLevelSearchable?: string | null,
        agencyStateSearchable?: string | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        agency?:  {
          __typename: "Agency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        documents?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetLockQueryVariables = {
  id?: string,
};

export type GetLockQuery = {
  getLock?:  {
    __typename: "Lock",
    id: string,
    description?: string | null,
    expiresAt: number,
    holder: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListLocksQueryVariables = {
  filter?: ModelLockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocksQuery = {
  listLocks?:  {
    __typename: "ModelLockConnection",
    items?:  Array< {
      __typename: "Lock",
      id: string,
      description?: string | null,
      expiresAt: number,
      holder: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListLocksByExpiresAtQueryVariables = {
  expiresAt?: number | null,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocksByExpiresAtQuery = {
  listLocksByExpiresAt?:  {
    __typename: "ModelLockConnection",
    items?:  Array< {
      __typename: "Lock",
      id: string,
      description?: string | null,
      expiresAt: number,
      holder: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListLocksByHolderQueryVariables = {
  holder?: string | null,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocksByHolderQuery = {
  listLocksByHolder?:  {
    __typename: "ModelLockConnection",
    items?:  Array< {
      __typename: "Lock",
      id: string,
      description?: string | null,
      expiresAt: number,
      holder: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMailTrackingQueryVariables = {
  id?: string,
};

export type GetMailTrackingQuery = {
  getMailTracking?:  {
    __typename: "MailTracking",
    id: string,
    type: MailTrackingType,
    status?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListMailTrackingQueryVariables = {
  filter?: ModelMailTrackingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMailTrackingQuery = {
  listMailTracking?:  {
    __typename: "ModelMailTrackingConnection",
    items?:  Array< {
      __typename: "MailTracking",
      id: string,
      type: MailTrackingType,
      status?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetByTypeQueryVariables = {
  type?: MailTrackingType | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMailTrackingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetByTypeQuery = {
  getByType?:  {
    __typename: "ModelMailTrackingConnection",
    items?:  Array< {
      __typename: "MailTracking",
      id: string,
      type: MailTrackingType,
      status?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMailTrackingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetByStatusQuery = {
  getByStatus?:  {
    __typename: "ModelMailTrackingConnection",
    items?:  Array< {
      __typename: "MailTracking",
      id: string,
      type: MailTrackingType,
      status?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMergeRecordQueryVariables = {
  id?: string,
};

export type GetMergeRecordQuery = {
  getMergeRecord?:  {
    __typename: "MergeRecord",
    id: string,
    owner?: string | null,
    mergedAt: string,
    mergedBy: string,
    typeName: string,
    winningId: string,
    losingId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListMergeRecordsQueryVariables = {
  filter?: ModelMergeRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMergeRecordsQuery = {
  listMergeRecords?:  {
    __typename: "ModelMergeRecordConnection",
    items?:  Array< {
      __typename: "MergeRecord",
      id: string,
      owner?: string | null,
      mergedAt: string,
      mergedBy: string,
      typeName: string,
      winningId: string,
      losingId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMergeRecordsByLosingIdQueryVariables = {
  losingId?: string | null,
  mergedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMergeRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMergeRecordsByLosingIdQuery = {
  listMergeRecordsByLosingId?:  {
    __typename: "ModelMergeRecordConnection",
    items?:  Array< {
      __typename: "MergeRecord",
      id: string,
      owner?: string | null,
      mergedAt: string,
      mergedBy: string,
      typeName: string,
      winningId: string,
      losingId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMergeRecordsByWinningIdQueryVariables = {
  winningId?: string | null,
  mergedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMergeRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMergeRecordsByWinningIdQuery = {
  listMergeRecordsByWinningId?:  {
    __typename: "ModelMergeRecordConnection",
    items?:  Array< {
      __typename: "MergeRecord",
      id: string,
      owner?: string | null,
      mergedAt: string,
      mergedBy: string,
      typeName: string,
      winningId: string,
      losingId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id?: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    parentId?: string | null,
    personId?: string | null,
    readAt?: string | null,
    lifecycleStatus: NotificationLifecycleStatus,
    scheduledFor: string,
    activeAt?: string | null,
    archivedAt?: string | null,
    name: string,
    description?: string | null,
    emailParams?:  {
      __typename: "SendEmailParams",
      from?: string | null,
      to?: string | null,
      subject?: string | null,
      replyTo?: string | null,
      text?: string | null,
      linkText?: string | null,
      hyperlink?: string | null,
    } | null,
    eventContent?:  {
      __typename: "NotificationEventContent",
      area?: string | null,
      deadline?: string | null,
      seriesId?: string | null,
      type?: EventSequenceType | null,
    } | null,
    rcraStatusChangeContent?:  {
      __typename: "RcraStatusChangeContent",
      generatorStatus?: RcraGeneratorStatus | null,
      changedFromManifest?: boolean | null,
    } | null,
    sdsExtractionContent?:  {
      __typename: "SdsExtractionContent",
      productId?: string | null,
      chemName?: string | null,
    } | null,
    facilityId?: string | null,
    notificationTypeSearchable?: string | null,
    lifecycleStatusSearchable?: string | null,
    areaSearchable?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelNotificationFacilityLinkConnection",
      items?:  Array< {
        __typename: "NotificationFacilityLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        facilityId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelNotificationRequirementLinkConnection",
      items?:  Array< {
        __typename: "NotificationRequirementLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByParentQueryVariables = {
  parentId?: string | null,
  scheduledFor?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByParentQuery = {
  listNotificationsByParent?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByStatusAndScheduleQueryVariables = {
  owner?: string | null,
  lifecycleStatusScheduledFor?: ModelNotificationByStatusAndScheduleCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByStatusAndScheduleQuery = {
  listNotificationsByStatusAndSchedule?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByPersonQueryVariables = {
  personId?: string | null,
  scheduledFor?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByPersonQuery = {
  listNotificationsByPerson?:  {
    __typename: "ModelNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchNotificationsQueryVariables = {
  filter?: SearchableNotificationFilterInput | null,
  sort?: SearchableNotificationSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchNotificationsQuery = {
  searchNotifications?:  {
    __typename: "SearchableNotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetNotificationFacilityLinkQueryVariables = {
  id?: string,
};

export type GetNotificationFacilityLinkQuery = {
  getNotificationFacilityLink?:  {
    __typename: "NotificationFacilityLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    facilityId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListNotificationFacilityLinksQueryVariables = {
  filter?: ModelNotificationFacilityLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationFacilityLinksQuery = {
  listNotificationFacilityLinks?:  {
    __typename: "ModelNotificationFacilityLinkConnection",
    items?:  Array< {
      __typename: "NotificationFacilityLink",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      notificationId: string,
      facilityId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      notification:  {
        __typename: "Notification",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        parentId?: string | null,
        personId?: string | null,
        readAt?: string | null,
        lifecycleStatus: NotificationLifecycleStatus,
        scheduledFor: string,
        activeAt?: string | null,
        archivedAt?: string | null,
        name: string,
        description?: string | null,
        emailParams?:  {
          __typename: "SendEmailParams",
          from?: string | null,
          to?: string | null,
          subject?: string | null,
          replyTo?: string | null,
          text?: string | null,
          linkText?: string | null,
          hyperlink?: string | null,
        } | null,
        eventContent?:  {
          __typename: "NotificationEventContent",
          area?: string | null,
          deadline?: string | null,
          seriesId?: string | null,
          type?: EventSequenceType | null,
        } | null,
        rcraStatusChangeContent?:  {
          __typename: "RcraStatusChangeContent",
          generatorStatus?: RcraGeneratorStatus | null,
          changedFromManifest?: boolean | null,
        } | null,
        sdsExtractionContent?:  {
          __typename: "SdsExtractionContent",
          productId?: string | null,
          chemName?: string | null,
        } | null,
        facilityId?: string | null,
        notificationTypeSearchable?: string | null,
        lifecycleStatusSearchable?: string | null,
        areaSearchable?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelNotificationFacilityLinkConnection",
          items?:  Array< {
            __typename: "NotificationFacilityLink",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            notificationId: string,
            facilityId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelNotificationRequirementLinkConnection",
          items?:  Array< {
            __typename: "NotificationRequirementLink",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            notificationId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationFacilityLinksByFacilityIdQueryVariables = {
  facilityId?: string | null,
  notificationId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFacilityLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationFacilityLinksByFacilityIdQuery = {
  listNotificationFacilityLinksByFacilityId?:  {
    __typename: "ModelNotificationFacilityLinkConnection",
    items?:  Array< {
      __typename: "NotificationFacilityLink",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      notificationId: string,
      facilityId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      notification:  {
        __typename: "Notification",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        parentId?: string | null,
        personId?: string | null,
        readAt?: string | null,
        lifecycleStatus: NotificationLifecycleStatus,
        scheduledFor: string,
        activeAt?: string | null,
        archivedAt?: string | null,
        name: string,
        description?: string | null,
        emailParams?:  {
          __typename: "SendEmailParams",
          from?: string | null,
          to?: string | null,
          subject?: string | null,
          replyTo?: string | null,
          text?: string | null,
          linkText?: string | null,
          hyperlink?: string | null,
        } | null,
        eventContent?:  {
          __typename: "NotificationEventContent",
          area?: string | null,
          deadline?: string | null,
          seriesId?: string | null,
          type?: EventSequenceType | null,
        } | null,
        rcraStatusChangeContent?:  {
          __typename: "RcraStatusChangeContent",
          generatorStatus?: RcraGeneratorStatus | null,
          changedFromManifest?: boolean | null,
        } | null,
        sdsExtractionContent?:  {
          __typename: "SdsExtractionContent",
          productId?: string | null,
          chemName?: string | null,
        } | null,
        facilityId?: string | null,
        notificationTypeSearchable?: string | null,
        lifecycleStatusSearchable?: string | null,
        areaSearchable?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelNotificationFacilityLinkConnection",
          items?:  Array< {
            __typename: "NotificationFacilityLink",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            notificationId: string,
            facilityId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelNotificationRequirementLinkConnection",
          items?:  Array< {
            __typename: "NotificationRequirementLink",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            notificationId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationRequirementLinkQueryVariables = {
  id?: string,
};

export type GetNotificationRequirementLinkQuery = {
  getNotificationRequirementLink?:  {
    __typename: "NotificationRequirementLink",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    notificationId: string,
    requirementId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    notification:  {
      __typename: "Notification",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      parentId?: string | null,
      personId?: string | null,
      readAt?: string | null,
      lifecycleStatus: NotificationLifecycleStatus,
      scheduledFor: string,
      activeAt?: string | null,
      archivedAt?: string | null,
      name: string,
      description?: string | null,
      emailParams?:  {
        __typename: "SendEmailParams",
        from?: string | null,
        to?: string | null,
        subject?: string | null,
        replyTo?: string | null,
        text?: string | null,
        linkText?: string | null,
        hyperlink?: string | null,
      } | null,
      eventContent?:  {
        __typename: "NotificationEventContent",
        area?: string | null,
        deadline?: string | null,
        seriesId?: string | null,
        type?: EventSequenceType | null,
      } | null,
      rcraStatusChangeContent?:  {
        __typename: "RcraStatusChangeContent",
        generatorStatus?: RcraGeneratorStatus | null,
        changedFromManifest?: boolean | null,
      } | null,
      sdsExtractionContent?:  {
        __typename: "SdsExtractionContent",
        productId?: string | null,
        chemName?: string | null,
      } | null,
      facilityId?: string | null,
      notificationTypeSearchable?: string | null,
      lifecycleStatusSearchable?: string | null,
      areaSearchable?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelNotificationFacilityLinkConnection",
        items?:  Array< {
          __typename: "NotificationFacilityLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          facilityId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelNotificationRequirementLinkConnection",
        items?:  Array< {
          __typename: "NotificationRequirementLink",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          notificationId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          notification:  {
            __typename: "Notification",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            parentId?: string | null,
            personId?: string | null,
            readAt?: string | null,
            lifecycleStatus: NotificationLifecycleStatus,
            scheduledFor: string,
            activeAt?: string | null,
            archivedAt?: string | null,
            name: string,
            description?: string | null,
            facilityId?: string | null,
            notificationTypeSearchable?: string | null,
            lifecycleStatusSearchable?: string | null,
            areaSearchable?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    requirement:  {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListNotificationRequirementLinksQueryVariables = {
  filter?: ModelNotificationRequirementLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationRequirementLinksQuery = {
  listNotificationRequirementLinks?:  {
    __typename: "ModelNotificationRequirementLinkConnection",
    items?:  Array< {
      __typename: "NotificationRequirementLink",
      id: string,
      owner?: string | null,
      notificationType: NotificationType,
      notificationId: string,
      requirementId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      notification:  {
        __typename: "Notification",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        parentId?: string | null,
        personId?: string | null,
        readAt?: string | null,
        lifecycleStatus: NotificationLifecycleStatus,
        scheduledFor: string,
        activeAt?: string | null,
        archivedAt?: string | null,
        name: string,
        description?: string | null,
        emailParams?:  {
          __typename: "SendEmailParams",
          from?: string | null,
          to?: string | null,
          subject?: string | null,
          replyTo?: string | null,
          text?: string | null,
          linkText?: string | null,
          hyperlink?: string | null,
        } | null,
        eventContent?:  {
          __typename: "NotificationEventContent",
          area?: string | null,
          deadline?: string | null,
          seriesId?: string | null,
          type?: EventSequenceType | null,
        } | null,
        rcraStatusChangeContent?:  {
          __typename: "RcraStatusChangeContent",
          generatorStatus?: RcraGeneratorStatus | null,
          changedFromManifest?: boolean | null,
        } | null,
        sdsExtractionContent?:  {
          __typename: "SdsExtractionContent",
          productId?: string | null,
          chemName?: string | null,
        } | null,
        facilityId?: string | null,
        notificationTypeSearchable?: string | null,
        lifecycleStatusSearchable?: string | null,
        areaSearchable?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelNotificationFacilityLinkConnection",
          items?:  Array< {
            __typename: "NotificationFacilityLink",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            notificationId: string,
            facilityId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelNotificationRequirementLinkConnection",
          items?:  Array< {
            __typename: "NotificationRequirementLink",
            id: string,
            owner?: string | null,
            notificationType: NotificationType,
            notificationId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      requirement:  {
        __typename: "Requirement",
        id: string,
        owner?: string | null,
        status?: RequirementStatus | null,
        name: string,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId: string,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area: string,
        areaSearchable: string,
        jurisdictionalAgency?: string | null,
        intervalInWords?: string | null,
        parentStatute?: ParentStatute | null,
        jurisdictionalState?: string | null,
        jurisdictionalCounties?: Array< string | null > | null,
        title?: string | null,
        portals?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        jurisdictionalTownship?: string | null,
        jurisdictionalTribalArea?: string | null,
        jurisdictionalCity?: string | null,
        formExpires?: string | null,
        forms?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        fillableLinks?: Array< string | null > | null,
        otherLinks?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        instructionFileName?: string | null,
        instruction?: string | null,
        extraDocumentations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        department?: string | null,
        subDepartment?: string | null,
        contactEmail?: string | null,
        contactPhone?: string | null,
        contactPhoneExtension?: number | null,
        isExtensionAvailable?: boolean | null,
        extensionNotes?: string | null,
        penalty?: string | null,
        encampInstructions?: string | null,
        isAlwaysApplicable?: boolean | null,
        agencyLevelSearchable?: string | null,
        agencyStateSearchable?: string | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        agency?:  {
          __typename: "Agency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        documents?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentQueryVariables = {
  id?: string,
};

export type GetPaymentQuery = {
  getPayment?:  {
    __typename: "Payment",
    id: string,
    owner: string,
    paymentFacilityIds?: Array< string > | null,
    paymentReferences:  Array<( {
        __typename: "TierIIReportPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
        reportingYear: number,
      } | {
        __typename: "FacilityPaymentReference",
        ReferenceTypename: string,
        id: string,
        amount?: number | null,
      }
    ) > | null,
    status: PaymentStatus,
    invoices?:  Array< {
      __typename: "PaymentInvoice",
      id?: string | null,
      payee?: string | null,
      invoiceNumber?: string | null,
      paymentInvoiceDocumentId?: string | null,
      dateDue?: string | null,
      amount?: number | null,
      description?: string | null,
    } > | null,
    paymentMethod?: PaymentMethod | null,
    amount?: number | null,
    description?: string | null,
    memo?: string | null,
    payee?: string | null,
    paidAt?: string | null,
    recordedBy?: string | null,
    paymentDocumentID?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    activityId?: string | null,
    payeeType?: string | null,
    company?: string | null,
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
  } | null,
};

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsQuery = {
  listPayments?:  {
    __typename: "ModelPaymentConnection",
    items?:  Array< {
      __typename: "Payment",
      id: string,
      owner: string,
      paymentFacilityIds?: Array< string > | null,
      paymentReferences:  Array<( {
          __typename: "TierIIReportPaymentReference",
          ReferenceTypename: string,
          id: string,
          amount?: number | null,
          reportingYear: number,
        } | {
          __typename: "FacilityPaymentReference",
          ReferenceTypename: string,
          id: string,
          amount?: number | null,
        }
      ) > | null,
      status: PaymentStatus,
      invoices?:  Array< {
        __typename: "PaymentInvoice",
        id?: string | null,
        payee?: string | null,
        invoiceNumber?: string | null,
        paymentInvoiceDocumentId?: string | null,
        dateDue?: string | null,
        amount?: number | null,
        description?: string | null,
      } > | null,
      paymentMethod?: PaymentMethod | null,
      amount?: number | null,
      description?: string | null,
      memo?: string | null,
      payee?: string | null,
      paidAt?: string | null,
      recordedBy?: string | null,
      paymentDocumentID?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      activityId?: string | null,
      payeeType?: string | null,
      company?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPaymentsByOwnerQueryVariables = {
  owner?: string | null,
  paidAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsByOwnerQuery = {
  listPaymentsByOwner?:  {
    __typename: "ModelPaymentConnection",
    items?:  Array< {
      __typename: "Payment",
      id: string,
      owner: string,
      paymentFacilityIds?: Array< string > | null,
      paymentReferences:  Array<( {
          __typename: "TierIIReportPaymentReference",
          ReferenceTypename: string,
          id: string,
          amount?: number | null,
          reportingYear: number,
        } | {
          __typename: "FacilityPaymentReference",
          ReferenceTypename: string,
          id: string,
          amount?: number | null,
        }
      ) > | null,
      status: PaymentStatus,
      invoices?:  Array< {
        __typename: "PaymentInvoice",
        id?: string | null,
        payee?: string | null,
        invoiceNumber?: string | null,
        paymentInvoiceDocumentId?: string | null,
        dateDue?: string | null,
        amount?: number | null,
        description?: string | null,
      } > | null,
      paymentMethod?: PaymentMethod | null,
      amount?: number | null,
      description?: string | null,
      memo?: string | null,
      payee?: string | null,
      paidAt?: string | null,
      recordedBy?: string | null,
      paymentDocumentID?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      activityId?: string | null,
      payeeType?: string | null,
      company?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchPaymentsQueryVariables = {
  filter?: SearchablePaymentFilterInput | null,
  sort?: SearchablePaymentSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPaymentsQuery = {
  searchPayments?:  {
    __typename: "SearchablePaymentConnection",
    items?:  Array< {
      __typename: "Payment",
      id: string,
      owner: string,
      paymentFacilityIds?: Array< string > | null,
      paymentReferences:  Array<( {
          __typename: "TierIIReportPaymentReference",
          ReferenceTypename: string,
          id: string,
          amount?: number | null,
          reportingYear: number,
        } | {
          __typename: "FacilityPaymentReference",
          ReferenceTypename: string,
          id: string,
          amount?: number | null,
        }
      ) > | null,
      status: PaymentStatus,
      invoices?:  Array< {
        __typename: "PaymentInvoice",
        id?: string | null,
        payee?: string | null,
        invoiceNumber?: string | null,
        paymentInvoiceDocumentId?: string | null,
        dateDue?: string | null,
        amount?: number | null,
        description?: string | null,
      } > | null,
      paymentMethod?: PaymentMethod | null,
      amount?: number | null,
      description?: string | null,
      memo?: string | null,
      payee?: string | null,
      paidAt?: string | null,
      recordedBy?: string | null,
      paymentDocumentID?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      activityId?: string | null,
      payeeType?: string | null,
      company?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPersonQueryVariables = {
  id?: string,
};

export type GetPersonQuery = {
  getPerson?:  {
    __typename: "Person",
    id: string,
    owner?: string | null,
    first?: string | null,
    last?: string | null,
    email?: string | null,
    title?: string | null,
    company?: string | null,
    address?:  {
      __typename: "Address",
      streetAddress?: string | null,
      streetAddressLine2?: string | null,
      city?: string | null,
      zip?: string | null,
      state?: string | null,
      country?: string | null,
    } | null,
    state?: string | null,
    phones:  Array< {
      __typename: "Phone",
      type: PhoneType,
      reportTypes?: Array< ReportType > | null,
      number: string,
    } >,
    contactType?: ContactType | null,
    searchableProgramAreas?: string | null,
    searchableFacilities?: string | null,
    searchableContactTypes?: string | null,
    userTenantId?: string | null,
    paymentMethodId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facilities?:  {
      __typename: "ModelFacilityContactConnection",
      items?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    userTenant?:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListPersonsQueryVariables = {
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPersonsQuery = {
  listPersons?:  {
    __typename: "ModelPersonConnection",
    items?:  Array< {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPeopleByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeopleByOwnerQuery = {
  listPeopleByOwner?:  {
    __typename: "ModelPersonConnection",
    items?:  Array< {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchPersonsQueryVariables = {
  filter?: SearchablePersonFilterInput | null,
  sort?: SearchablePersonSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPersonsQuery = {
  searchPersons?:  {
    __typename: "SearchablePersonConnection",
    items?:  Array< {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPortalInteractionRecordQueryVariables = {
  id?: string,
};

export type GetPortalInteractionRecordQuery = {
  getPortalInteractionRecord?:  {
    __typename: "PortalInteractionRecord",
    id: string,
    owner?: string | null,
    requestId?: string | null,
    facilityId?: string | null,
    encampRequestId?: string | null,
    credentialId?: string | null,
    credentialUsername?: string | null,
    stateOrTerritory?: string | null,
    status?: PortalInteractionStatus | null,
    log?:  Array< {
      __typename: "PortalInteractionLog",
      status: PortalInteractionStatus,
      at: string,
      errorMessage?: string | null,
      cloudWatchLogStream?: string | null,
      screenshotKey?: string | null,
    } | null > | null,
    action?: PortalInteractionAction | null,
    forceClassicSubmissionStrategy?: boolean | null,
    evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
    stepsDone?: Array< string > | null,
    stepError?: string | null,
    reportId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
  } | null,
};

export type ListPortalInteractionRecordsQueryVariables = {
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionRecordsQuery = {
  listPortalInteractionRecords?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByStatusQueryVariables = {
  status?: PortalInteractionStatus | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByStatusQuery = {
  listPortalInteractionsByStatus?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByRequestQueryVariables = {
  requestId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByRequestQuery = {
  listPortalInteractionsByRequest?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByFacilityQueryVariables = {
  facilityId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByFacilityQuery = {
  listPortalInteractionsByFacility?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByReportQueryVariables = {
  reportId?: string | null,
  action?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByReportQuery = {
  listPortalInteractionsByReport?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByUsernameQueryVariables = {
  stateOrTerritory?: string | null,
  credentialUsername?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByUsernameQuery = {
  listPortalInteractionsByUsername?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByOwnerQueryVariables = {
  owner?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByOwnerQuery = {
  listPortalInteractionsByOwner?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByActionQueryVariables = {
  action?: PortalInteractionAction | null,
  reportId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByActionQuery = {
  listPortalInteractionsByAction?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPortalInteractionsByEncampRequestIdQueryVariables = {
  encampRequestId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPortalInteractionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortalInteractionsByEncampRequestIdQuery = {
  listPortalInteractionsByEncampRequestId?:  {
    __typename: "ModelPortalInteractionRecordConnection",
    items?:  Array< {
      __typename: "PortalInteractionRecord",
      id: string,
      owner?: string | null,
      requestId?: string | null,
      facilityId?: string | null,
      encampRequestId?: string | null,
      credentialId?: string | null,
      credentialUsername?: string | null,
      stateOrTerritory?: string | null,
      status?: PortalInteractionStatus | null,
      log?:  Array< {
        __typename: "PortalInteractionLog",
        status: PortalInteractionStatus,
        at: string,
        errorMessage?: string | null,
        cloudWatchLogStream?: string | null,
        screenshotKey?: string | null,
      } | null > | null,
      action?: PortalInteractionAction | null,
      forceClassicSubmissionStrategy?: boolean | null,
      evidenceOfCompletionActions?: Array< CollectEvidenceOfCompletionActions | null > | null,
      stepsDone?: Array< string > | null,
      stepError?: string | null,
      reportId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetProgramAreaQueryVariables = {
  id?: string,
};

export type GetProgramAreaQuery = {
  getProgramArea?:  {
    __typename: "ProgramArea",
    id: string,
    owner?: string | null,
    name: string,
    description?: string | null,
    icon?: string | null,
    isDisabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListProgramAreasQueryVariables = {
  filter?: ModelProgramAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProgramAreasQuery = {
  listProgramAreas?:  {
    __typename: "ModelProgramAreaConnection",
    items?:  Array< {
      __typename: "ProgramArea",
      id: string,
      owner?: string | null,
      name: string,
      description?: string | null,
      icon?: string | null,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListProgramAreaByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProgramAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProgramAreaByOwnerQuery = {
  listProgramAreaByOwner?:  {
    __typename: "ModelProgramAreaConnection",
    items?:  Array< {
      __typename: "ProgramArea",
      id: string,
      owner?: string | null,
      name: string,
      description?: string | null,
      icon?: string | null,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchProgramAreasQueryVariables = {
  filter?: SearchableProgramAreaFilterInput | null,
  sort?: SearchableProgramAreaSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProgramAreasQuery = {
  searchProgramAreas?:  {
    __typename: "SearchableProgramAreaConnection",
    items?:  Array< {
      __typename: "ProgramArea",
      id: string,
      owner?: string | null,
      name: string,
      description?: string | null,
      icon?: string | null,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetRcraForm870012QueryVariables = {
  id?: string,
};

export type GetRcraForm870012Query = {
  getRcraForm870012?:  {
    __typename: "RcraForm870012",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    submissionType?: RcraSubmissionType | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListRcraForm870012sQueryVariables = {
  filter?: ModelRcraForm870012FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870012sQuery = {
  listRcraForm870012s?:  {
    __typename: "ModelRcraForm870012Connection",
    items?:  Array< {
      __typename: "RcraForm870012",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      submissionType?: RcraSubmissionType | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870012ByOwnerQueryVariables = {
  owner?: string | null,
  submittedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870012FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870012ByOwnerQuery = {
  listRcraForm870012ByOwner?:  {
    __typename: "ModelRcraForm870012Connection",
    items?:  Array< {
      __typename: "RcraForm870012",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      submissionType?: RcraSubmissionType | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870012ByFacilityQueryVariables = {
  facilityId?: string | null,
  submittedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870012FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870012ByFacilityQuery = {
  listRcraForm870012ByFacility?:  {
    __typename: "ModelRcraForm870012Connection",
    items?:  Array< {
      __typename: "RcraForm870012",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      submissionType?: RcraSubmissionType | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870012ByStatusQueryVariables = {
  status?: RcraFormStatus | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870012FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870012ByStatusQuery = {
  listRcraForm870012ByStatus?:  {
    __typename: "ModelRcraForm870012Connection",
    items?:  Array< {
      __typename: "RcraForm870012",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      submissionType?: RcraSubmissionType | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870012ByStaffAssigneeQueryVariables = {
  staffAssignee?: string | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870012FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870012ByStaffAssigneeQuery = {
  listRcraForm870012ByStaffAssignee?:  {
    __typename: "ModelRcraForm870012Connection",
    items?:  Array< {
      __typename: "RcraForm870012",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      submissionType?: RcraSubmissionType | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetRcraForm870013QueryVariables = {
  id?: string,
};

export type GetRcraForm870013Query = {
  getRcraForm870013?:  {
    __typename: "RcraForm870013",
    id: string,
    owner?: string | null,
    facilityId: string,
    status: RcraFormStatus,
    reportingPeriod?: string | null,
    submittedAt?: string | null,
    metadata?:  {
      __typename: "RcraFormMetadata",
      staffComments?:  Array< {
        __typename: "RcraFormStaffComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "RcraFormSubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "RcraFormRecord",
          at: string,
          key: string,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      tags?: string | null,
    } | null,
    staffAssignee?: string | null,
    si?:  {
      __typename: "RcraSiteIdentificationForm",
      reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
      epaId?: string | null,
      siteName?: string | null,
      locationAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      county?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      landType?: RcraSiteIdentificationFormLandType | null,
      naicsCodes?: Array< number > | null,
      siteContact?:  {
        __typename: "RcraSiteIdentificationFormSiteContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        title?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
      } | null,
      siteOwners?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      siteOperators?:  Array< {
        __typename: "RcraSiteIdentificationFormOwnerOperator",
        fullName?: string | null,
        title?: string | null,
        startedAt?: string | null,
        type?: RcraSiteIdentificationFormLandType | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        email?: string | null,
        workPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        faxPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        comments?: string | null,
      } > | null,
      federalGeneratorStatus?: RcraGeneratorStatus | null,
      isShortTermGenerator?: boolean | null,
      shortTermGeneratorComments?: string | null,
      isTreaterStorerDisposer?: boolean | null,
      isOffSiteReceiver?: boolean | null,
      isRecycler?: boolean | null,
      isRecyclerStorer?: boolean | null,
      isExemptBurner?: boolean | null,
      isExemptFurnace?: boolean | null,
      federalWasteCodes?: Array< string > | null,
      stateWasteCodes?: Array< string > | null,
      isWasteTransporter?: boolean | null,
      wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUndergroundInjectionControl?: boolean | null,
      isUSImporter?: boolean | null,
      isRecognizedTrader?: boolean | null,
      recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isSpentLeadAcidBatteryTrader?: boolean | null,
      spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
      isLargeQuantityHandlerUniversalWaste?: boolean | null,
      largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
      isUniversalWasteDestination?: boolean | null,
      isUsedOilTransporter?: boolean | null,
      usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
      isUsedOilProcessor?: boolean | null,
      usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
      isOffSpecificationUsedOilBurner?: boolean | null,
      isUsedOilFuelMarketer?: boolean | null,
      usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
      hasPharmaceuticalActivity?: boolean | null,
      pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
      isPharmaceuticalWithdrawing?: boolean | null,
      isAcademicLab?: boolean | null,
      academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
      isWithdrawingFromAcademicLab?: boolean | null,
      isEpisodicGeneration?: boolean | null,
      isLQGConsolidator?: boolean | null,
      episodicEvent?:  {
        __typename: "RcraSiteIdentificationFormEpisodicEvent",
        isPlannedEpisodicEvent?: boolean | null,
        projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
        beginningDate?: string | null,
        endDate?: string | null,
        otherDescription?: string | null,
        waste?:  Array< {
          __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
          wasteDescription?: string | null,
          estimatedQuantity?: number | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
        } | null > | null,
      } | null,
      episodicEventEmergencyContact?:  {
        __typename: "RcraSiteIdentificationFormEmergencyContact",
        first?: string | null,
        middle?: string | null,
        last?: string | null,
        phones?:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } > | null,
        email?: string | null,
      } | null,
      lqgConsolidatorSites?:  Array< {
        __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
        epaIdNumber?: string | null,
        siteName?: string | null,
        address:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        contactFirst?: string | null,
        contactMiddle?: string | null,
        contactLast?: string | null,
        contactPhone?:  {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } | null,
        contactEmail?: string | null,
      } > | null,
      lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
      expectedClosureDate?: string | null,
      requestingNewClosureDate?: string | null,
      actualClosureDate?: string | null,
      isClosedInCompliance?: boolean | null,
      isNotifyingHazardousSecondaryMaterial?: boolean | null,
      isManagingHazardousSecondarymaterial?: boolean | null,
      reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
      effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
      hsmActivities?:  Array< {
        __typename: "RcraSiteIdentificationFormHSMActivity",
        facilityCode?: string | null,
        wasteCodes?: Array< string > | null,
        estimatedShortTons?: number | null,
        landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
      } > | null,
      isElectronicManifestBroker?: boolean | null,
      comments?: string | null,
      certifiers?:  Array< {
        __typename: "RcraSiteIdentificationFormCertifier",
        certifiedAt?: string | null,
        fullName?: string | null,
        title?: string | null,
        email?: string | null,
      } > | null,
      companyName?: string | null,
      companyMailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      companyMailingAttention?: string | null,
    } | null,
    gm?:  {
      __typename: "RcraGenerationManagementForm",
      forms?:  Array< {
        __typename: "RcraGenerationManagementWasteStream",
        id?: string | null,
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        sourceCode?: string | null,
        managementMethod?: string | null,
        countryCode?: string | null,
        formCode?: string | null,
        wasteMinimizationCode?: string | null,
        radioactiveMixed?: boolean | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        wasteWasProcessedOnSite?: boolean | null,
        onSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOnSiteProcess",
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        wasteWasShippedOffSite?: boolean | null,
        offSiteProcesses?:  Array< {
          __typename: "RcraGenerationManagementOffSiteProcess",
          shippedEpaId?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
        } > | null,
        comments?: string | null,
        status?: RcraFormStatus | null,
      } > | null,
    } | null,
    oi?:  {
      __typename: "RcraOffSiteIdentificationForm",
      sites?:  Array< {
        __typename: "RcraOffSiteInstallationOrTransporter",
        epaId?: string | null,
        name?: string | null,
        handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
        installationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
      } | null > | null,
      comments?: string | null,
    } | null,
    wr?:  {
      __typename: "RcraWasteReceivedForm",
      wastes?:  Array< {
        __typename: "RcraWasteReceivedWasteStream",
        description?: string | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        epaIdNumber?: string | null,
        formCode?: string | null,
        managementMethod?: string | null,
        quantity?: number | null,
        quantityUnitOfMeasure?: string | null,
        density?: number | null,
        densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
      } > | null,
      comments?: string | null,
    } | null,
    reviewers?:  Array< {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } > | null,
    verifier?:  {
      __typename: "SubmissionAssignee",
      id: string,
      first: string,
      last: string,
      fullName?: string | null,
      title?: string | null,
      isComplete?: boolean | null,
      completedAt?: string | null,
    } | null,
    noteKey?: string | null,
    notes?:  {
      __typename: "ModelNoteConnection",
      items?:  Array< {
        __typename: "Note",
        id: string,
        parentId?: string | null,
        parent?:  {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null,
        noteBy?: string | null,
        at: string,
        key?: string | null,
        note?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
  } | null,
};

export type ListRcraForm870013sQueryVariables = {
  filter?: ModelRcraForm870013FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870013sQuery = {
  listRcraForm870013s?:  {
    __typename: "ModelRcraForm870013Connection",
    items?:  Array< {
      __typename: "RcraForm870013",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      reportingPeriod?: string | null,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      gm?:  {
        __typename: "RcraGenerationManagementForm",
        forms?:  Array< {
          __typename: "RcraGenerationManagementWasteStream",
          id?: string | null,
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          sourceCode?: string | null,
          managementMethod?: string | null,
          countryCode?: string | null,
          formCode?: string | null,
          wasteMinimizationCode?: string | null,
          radioactiveMixed?: boolean | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
          wasteWasProcessedOnSite?: boolean | null,
          onSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOnSiteProcess",
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          wasteWasShippedOffSite?: boolean | null,
          offSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOffSiteProcess",
            shippedEpaId?: string | null,
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          comments?: string | null,
          status?: RcraFormStatus | null,
        } > | null,
      } | null,
      oi?:  {
        __typename: "RcraOffSiteIdentificationForm",
        sites?:  Array< {
          __typename: "RcraOffSiteInstallationOrTransporter",
          epaId?: string | null,
          name?: string | null,
          handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
          installationAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          county?: string | null,
        } | null > | null,
        comments?: string | null,
      } | null,
      wr?:  {
        __typename: "RcraWasteReceivedForm",
        wastes?:  Array< {
          __typename: "RcraWasteReceivedWasteStream",
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          epaIdNumber?: string | null,
          formCode?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        } > | null,
        comments?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870013ByOwnerQueryVariables = {
  owner?: string | null,
  reportingPeriod?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870013FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870013ByOwnerQuery = {
  listRcraForm870013ByOwner?:  {
    __typename: "ModelRcraForm870013Connection",
    items?:  Array< {
      __typename: "RcraForm870013",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      reportingPeriod?: string | null,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      gm?:  {
        __typename: "RcraGenerationManagementForm",
        forms?:  Array< {
          __typename: "RcraGenerationManagementWasteStream",
          id?: string | null,
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          sourceCode?: string | null,
          managementMethod?: string | null,
          countryCode?: string | null,
          formCode?: string | null,
          wasteMinimizationCode?: string | null,
          radioactiveMixed?: boolean | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
          wasteWasProcessedOnSite?: boolean | null,
          onSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOnSiteProcess",
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          wasteWasShippedOffSite?: boolean | null,
          offSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOffSiteProcess",
            shippedEpaId?: string | null,
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          comments?: string | null,
          status?: RcraFormStatus | null,
        } > | null,
      } | null,
      oi?:  {
        __typename: "RcraOffSiteIdentificationForm",
        sites?:  Array< {
          __typename: "RcraOffSiteInstallationOrTransporter",
          epaId?: string | null,
          name?: string | null,
          handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
          installationAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          county?: string | null,
        } | null > | null,
        comments?: string | null,
      } | null,
      wr?:  {
        __typename: "RcraWasteReceivedForm",
        wastes?:  Array< {
          __typename: "RcraWasteReceivedWasteStream",
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          epaIdNumber?: string | null,
          formCode?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        } > | null,
        comments?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870013ByFacilityQueryVariables = {
  facilityId?: string | null,
  reportingPeriod?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870013FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870013ByFacilityQuery = {
  listRcraForm870013ByFacility?:  {
    __typename: "ModelRcraForm870013Connection",
    items?:  Array< {
      __typename: "RcraForm870013",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      reportingPeriod?: string | null,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      gm?:  {
        __typename: "RcraGenerationManagementForm",
        forms?:  Array< {
          __typename: "RcraGenerationManagementWasteStream",
          id?: string | null,
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          sourceCode?: string | null,
          managementMethod?: string | null,
          countryCode?: string | null,
          formCode?: string | null,
          wasteMinimizationCode?: string | null,
          radioactiveMixed?: boolean | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
          wasteWasProcessedOnSite?: boolean | null,
          onSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOnSiteProcess",
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          wasteWasShippedOffSite?: boolean | null,
          offSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOffSiteProcess",
            shippedEpaId?: string | null,
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          comments?: string | null,
          status?: RcraFormStatus | null,
        } > | null,
      } | null,
      oi?:  {
        __typename: "RcraOffSiteIdentificationForm",
        sites?:  Array< {
          __typename: "RcraOffSiteInstallationOrTransporter",
          epaId?: string | null,
          name?: string | null,
          handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
          installationAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          county?: string | null,
        } | null > | null,
        comments?: string | null,
      } | null,
      wr?:  {
        __typename: "RcraWasteReceivedForm",
        wastes?:  Array< {
          __typename: "RcraWasteReceivedWasteStream",
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          epaIdNumber?: string | null,
          formCode?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        } > | null,
        comments?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870013ByStatusQueryVariables = {
  status?: RcraFormStatus | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870013FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870013ByStatusQuery = {
  listRcraForm870013ByStatus?:  {
    __typename: "ModelRcraForm870013Connection",
    items?:  Array< {
      __typename: "RcraForm870013",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      reportingPeriod?: string | null,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      gm?:  {
        __typename: "RcraGenerationManagementForm",
        forms?:  Array< {
          __typename: "RcraGenerationManagementWasteStream",
          id?: string | null,
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          sourceCode?: string | null,
          managementMethod?: string | null,
          countryCode?: string | null,
          formCode?: string | null,
          wasteMinimizationCode?: string | null,
          radioactiveMixed?: boolean | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
          wasteWasProcessedOnSite?: boolean | null,
          onSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOnSiteProcess",
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          wasteWasShippedOffSite?: boolean | null,
          offSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOffSiteProcess",
            shippedEpaId?: string | null,
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          comments?: string | null,
          status?: RcraFormStatus | null,
        } > | null,
      } | null,
      oi?:  {
        __typename: "RcraOffSiteIdentificationForm",
        sites?:  Array< {
          __typename: "RcraOffSiteInstallationOrTransporter",
          epaId?: string | null,
          name?: string | null,
          handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
          installationAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          county?: string | null,
        } | null > | null,
        comments?: string | null,
      } | null,
      wr?:  {
        __typename: "RcraWasteReceivedForm",
        wastes?:  Array< {
          __typename: "RcraWasteReceivedWasteStream",
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          epaIdNumber?: string | null,
          formCode?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        } > | null,
        comments?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRcraForm870013ByStaffAssigneeQueryVariables = {
  staffAssignee?: string | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRcraForm870013FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRcraForm870013ByStaffAssigneeQuery = {
  listRcraForm870013ByStaffAssignee?:  {
    __typename: "ModelRcraForm870013Connection",
    items?:  Array< {
      __typename: "RcraForm870013",
      id: string,
      owner?: string | null,
      facilityId: string,
      status: RcraFormStatus,
      reportingPeriod?: string | null,
      submittedAt?: string | null,
      metadata?:  {
        __typename: "RcraFormMetadata",
        staffComments?:  Array< {
          __typename: "RcraFormStaffComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "RcraFormSubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "RcraFormRecord",
            at: string,
            key: string,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        tags?: string | null,
      } | null,
      staffAssignee?: string | null,
      si?:  {
        __typename: "RcraSiteIdentificationForm",
        reasonForSubmittal?: RcraSiteIdentificationFormReasonForSubmittal | null,
        epaId?: string | null,
        siteName?: string | null,
        locationAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        county?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        landType?: RcraSiteIdentificationFormLandType | null,
        naicsCodes?: Array< number > | null,
        siteContact?:  {
          __typename: "RcraSiteIdentificationFormSiteContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          title?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
        } | null,
        siteOwners?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        siteOperators?:  Array< {
          __typename: "RcraSiteIdentificationFormOwnerOperator",
          fullName?: string | null,
          title?: string | null,
          startedAt?: string | null,
          type?: RcraSiteIdentificationFormLandType | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          email?: string | null,
          workPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          faxPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          comments?: string | null,
        } > | null,
        federalGeneratorStatus?: RcraGeneratorStatus | null,
        isShortTermGenerator?: boolean | null,
        shortTermGeneratorComments?: string | null,
        isTreaterStorerDisposer?: boolean | null,
        isOffSiteReceiver?: boolean | null,
        isRecycler?: boolean | null,
        isRecyclerStorer?: boolean | null,
        isExemptBurner?: boolean | null,
        isExemptFurnace?: boolean | null,
        federalWasteCodes?: Array< string > | null,
        stateWasteCodes?: Array< string > | null,
        isWasteTransporter?: boolean | null,
        wasteTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUndergroundInjectionControl?: boolean | null,
        isUSImporter?: boolean | null,
        isRecognizedTrader?: boolean | null,
        recognizedTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isSpentLeadAcidBatteryTrader?: boolean | null,
        spentLeadAcidBatteryTraderTypes?: Array< RcraSiteIdentificationFormTraderType > | null,
        isLargeQuantityHandlerUniversalWaste?: boolean | null,
        largeQuantityHandlerUniversalWasteActivities?: Array< string > | null,
        isUniversalWasteDestination?: boolean | null,
        isUsedOilTransporter?: boolean | null,
        usedOilTransporterTypes?: Array< RcraSiteIdentificationFormTransporterType > | null,
        isUsedOilProcessor?: boolean | null,
        usedOilProcessorTypes?: Array< RcraSiteIdentificationFormUsedOilProcessorType > | null,
        isOffSpecificationUsedOilBurner?: boolean | null,
        isUsedOilFuelMarketer?: boolean | null,
        usedOilFuelMarketerTypes?: Array< RcraSiteIdentificationFormUsedOilMarketerType > | null,
        hasPharmaceuticalActivity?: boolean | null,
        pharmaceuticalActivity?: RcraSiteIdentificationFormPharmaceuticalActivity | null,
        isPharmaceuticalWithdrawing?: boolean | null,
        isAcademicLab?: boolean | null,
        academicLabTypes?: Array< RcraSiteIdentificationFormAcademicLabType > | null,
        isWithdrawingFromAcademicLab?: boolean | null,
        isEpisodicGeneration?: boolean | null,
        isLQGConsolidator?: boolean | null,
        episodicEvent?:  {
          __typename: "RcraSiteIdentificationFormEpisodicEvent",
          isPlannedEpisodicEvent?: boolean | null,
          projectTypes?: Array< RcraSiteIdentificationFormEpisodicEventProjectType | null > | null,
          beginningDate?: string | null,
          endDate?: string | null,
          otherDescription?: string | null,
          waste?:  Array< {
            __typename: "RcraSiteIdentificationFormEpisodicGeneratorWaste",
            wasteDescription?: string | null,
            estimatedQuantity?: number | null,
            federalWasteCodes?: Array< string > | null,
            stateWasteCodes?: Array< string > | null,
          } | null > | null,
        } | null,
        episodicEventEmergencyContact?:  {
          __typename: "RcraSiteIdentificationFormEmergencyContact",
          first?: string | null,
          middle?: string | null,
          last?: string | null,
          phones?:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } > | null,
          email?: string | null,
        } | null,
        lqgConsolidatorSites?:  Array< {
          __typename: "RcraSiteIdentificationFormLQGConsolidatorVSQGSite",
          epaIdNumber?: string | null,
          siteName?: string | null,
          address:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          contactFirst?: string | null,
          contactMiddle?: string | null,
          contactLast?: string | null,
          contactPhone?:  {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } | null,
          contactEmail?: string | null,
        } > | null,
        lqgClosureType?: RcraSiteIdentificationFormLQGClosureType | null,
        expectedClosureDate?: string | null,
        requestingNewClosureDate?: string | null,
        actualClosureDate?: string | null,
        isClosedInCompliance?: boolean | null,
        isNotifyingHazardousSecondaryMaterial?: boolean | null,
        isManagingHazardousSecondarymaterial?: boolean | null,
        reasonForHazardousSecondaryMaterialNotification?: RcraSiteIdentificationFormHSMNotificationReason | null,
        effectiveDateOfHazardousSecondaryMaterialNotification?: string | null,
        hsmActivities?:  Array< {
          __typename: "RcraSiteIdentificationFormHSMActivity",
          facilityCode?: string | null,
          wasteCodes?: Array< string > | null,
          estimatedShortTons?: number | null,
          landBasedUnit?: RcraSiteIdentificationFormHSMLandBasedUnitType | null,
        } > | null,
        isElectronicManifestBroker?: boolean | null,
        comments?: string | null,
        certifiers?:  Array< {
          __typename: "RcraSiteIdentificationFormCertifier",
          certifiedAt?: string | null,
          fullName?: string | null,
          title?: string | null,
          email?: string | null,
        } > | null,
        companyName?: string | null,
        companyMailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        companyMailingAttention?: string | null,
      } | null,
      gm?:  {
        __typename: "RcraGenerationManagementForm",
        forms?:  Array< {
          __typename: "RcraGenerationManagementWasteStream",
          id?: string | null,
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          sourceCode?: string | null,
          managementMethod?: string | null,
          countryCode?: string | null,
          formCode?: string | null,
          wasteMinimizationCode?: string | null,
          radioactiveMixed?: boolean | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
          wasteWasProcessedOnSite?: boolean | null,
          onSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOnSiteProcess",
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          wasteWasShippedOffSite?: boolean | null,
          offSiteProcesses?:  Array< {
            __typename: "RcraGenerationManagementOffSiteProcess",
            shippedEpaId?: string | null,
            managementMethod?: string | null,
            quantity?: number | null,
            quantityUnitOfMeasure?: string | null,
          } > | null,
          comments?: string | null,
          status?: RcraFormStatus | null,
        } > | null,
      } | null,
      oi?:  {
        __typename: "RcraOffSiteIdentificationForm",
        sites?:  Array< {
          __typename: "RcraOffSiteInstallationOrTransporter",
          epaId?: string | null,
          name?: string | null,
          handlerType?: Array< RcraOffSiteIdentificationHandlerType > | null,
          installationAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          county?: string | null,
        } | null > | null,
        comments?: string | null,
      } | null,
      wr?:  {
        __typename: "RcraWasteReceivedForm",
        wastes?:  Array< {
          __typename: "RcraWasteReceivedWasteStream",
          description?: string | null,
          federalWasteCodes?: Array< string > | null,
          stateWasteCodes?: Array< string > | null,
          epaIdNumber?: string | null,
          formCode?: string | null,
          managementMethod?: string | null,
          quantity?: number | null,
          quantityUnitOfMeasure?: string | null,
          density?: number | null,
          densityUnitOfMeasure?: RcraDensityUnitOfMeasure | null,
        } > | null,
        comments?: string | null,
      } | null,
      reviewers?:  Array< {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } > | null,
      verifier?:  {
        __typename: "SubmissionAssignee",
        id: string,
        first: string,
        last: string,
        fullName?: string | null,
        title?: string | null,
        isComplete?: boolean | null,
        completedAt?: string | null,
      } | null,
      noteKey?: string | null,
      notes?:  {
        __typename: "ModelNoteConnection",
        items?:  Array< {
          __typename: "Note",
          id: string,
          parentId?: string | null,
          parent?:  {
            __typename: "Note",
            id: string,
            parentId?: string | null,
            noteBy?: string | null,
            at: string,
            key?: string | null,
            note?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          noteBy?: string | null,
          at: string,
          key?: string | null,
          note?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetRequirementQueryVariables = {
  id?: string,
};

export type GetRequirementQuery = {
  getRequirement?:  {
    __typename: "Requirement",
    id: string,
    owner?: string | null,
    status?: RequirementStatus | null,
    name: string,
    description?: string | null,
    frequency?:  {
      __typename: "RepeatRule",
      frequency: Frequency,
      interval: number,
      start?: string | null,
    } | null,
    agencyId: string,
    citations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    area: string,
    areaSearchable: string,
    jurisdictionalAgency?: string | null,
    intervalInWords?: string | null,
    parentStatute?: ParentStatute | null,
    jurisdictionalState?: string | null,
    jurisdictionalCounties?: Array< string | null > | null,
    title?: string | null,
    portals?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    jurisdictionalTownship?: string | null,
    jurisdictionalTribalArea?: string | null,
    jurisdictionalCity?: string | null,
    formExpires?: string | null,
    forms?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    fillableLinks?: Array< string | null > | null,
    otherLinks?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    instructionFileName?: string | null,
    instruction?: string | null,
    extraDocumentations?:  Array< {
      __typename: "Link",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    department?: string | null,
    subDepartment?: string | null,
    contactEmail?: string | null,
    contactPhone?: string | null,
    contactPhoneExtension?: number | null,
    isExtensionAvailable?: boolean | null,
    extensionNotes?: string | null,
    penalty?: string | null,
    encampInstructions?: string | null,
    isAlwaysApplicable?: boolean | null,
    agencyLevelSearchable?: string | null,
    agencyStateSearchable?: string | null,
    userAgencyTitle?: string | null,
    tasks?:  Array< {
      __typename: "Task",
      isCompleted: boolean,
      text: string,
    } | null > | null,
    hyperlinks?:  Array< {
      __typename: "HyperLink",
      name?: string | null,
      link?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    agency?:  {
      __typename: "Agency",
      id: string,
      owner?: string | null,
      name: string,
      level: AgencyLevel,
      link?:  {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null,
      jurisdiction: string,
      state?: StateOrTerritory | null,
      locale?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentRequirementConnection",
      items?:  Array< {
        __typename: "DocumentRequirement",
        id: string,
        owner?: string | null,
        documentId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        document:  {
          __typename: "Document",
          id: string,
          owner?: string | null,
          title: string,
          description?: string | null,
          fileExtension: string,
          state?: string | null,
          area?: string | null,
          programAreas?: Array< string | null > | null,
          documentType: string,
          sourceLink?: string | null,
          storageLink: string,
          agencyId?: string | null,
          facilityId?: string | null,
          lobId?: string | null,
          createdBy?: string | null,
          stateSearchable?: string | null,
          areaSearchable?: string | null,
          searchableProgramAreas?: string | null,
          relatedEntityIds?:  Array< {
            __typename: "DocumentRelatedEntityId",
            kind: DocumentRelatedEntityIdKind,
            id: string,
          } > | null,
          tags?: string | null,
          isArchived?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          requirements?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        events?:  {
          __typename: "ModelEventConnection",
          items?:  Array< {
            __typename: "Event",
            id: string,
            seriesId: string,
            requirementId: string,
            _requirementName: string,
            _requirementArea: string,
            facilityId: string,
            eventResponsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventStatus,
            statusSearchable: string,
            owner?: string | null,
            deadline: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityRequirementConnection",
      items?:  Array< {
        __typename: "FacilityRequirement",
        id: string,
        owner?: string | null,
        facilityId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListRequirementsQueryVariables = {
  filter?: ModelRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequirementsQuery = {
  listRequirements?:  {
    __typename: "ModelRequirementConnection",
    items?:  Array< {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRequirementsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequirementsByOwnerQuery = {
  listRequirementsByOwner?:  {
    __typename: "ModelRequirementConnection",
    items?:  Array< {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRequirementsByOwnerStatusQueryVariables = {
  owner?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequirementsByOwnerStatusQuery = {
  listRequirementsByOwnerStatus?:  {
    __typename: "ModelRequirementConnection",
    items?:  Array< {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchRequirementsQueryVariables = {
  filter?: SearchableRequirementFilterInput | null,
  sort?: SearchableRequirementSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchRequirementsQuery = {
  searchRequirements?:  {
    __typename: "SearchableRequirementConnection",
    items?:  Array< {
      __typename: "Requirement",
      id: string,
      owner?: string | null,
      status?: RequirementStatus | null,
      name: string,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId: string,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area: string,
      areaSearchable: string,
      jurisdictionalAgency?: string | null,
      intervalInWords?: string | null,
      parentStatute?: ParentStatute | null,
      jurisdictionalState?: string | null,
      jurisdictionalCounties?: Array< string | null > | null,
      title?: string | null,
      portals?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      jurisdictionalTownship?: string | null,
      jurisdictionalTribalArea?: string | null,
      jurisdictionalCity?: string | null,
      formExpires?: string | null,
      forms?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      fillableLinks?: Array< string | null > | null,
      otherLinks?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      instructionFileName?: string | null,
      instruction?: string | null,
      extraDocumentations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      department?: string | null,
      subDepartment?: string | null,
      contactEmail?: string | null,
      contactPhone?: string | null,
      contactPhoneExtension?: number | null,
      isExtensionAvailable?: boolean | null,
      extensionNotes?: string | null,
      penalty?: string | null,
      encampInstructions?: string | null,
      isAlwaysApplicable?: boolean | null,
      agencyLevelSearchable?: string | null,
      agencyStateSearchable?: string | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      agency?:  {
        __typename: "Agency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetRoleQueryVariables = {
  id?: string,
};

export type GetRoleQuery = {
  getRole?:  {
    __typename: "Role",
    id: string,
    owner: string,
    name: string,
    rolePermissions?: Array< string > | null,
    roleType: RoleTypeEnum,
    createdAt: string,
    updatedAt: string,
    updatedBy: string,
  } | null,
};

export type ListRolesQueryVariables = {
  filter?: ModelRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRolesQuery = {
  listRoles?:  {
    __typename: "ModelRoleConnection",
    items?:  Array< {
      __typename: "Role",
      id: string,
      owner: string,
      name: string,
      rolePermissions?: Array< string > | null,
      roleType: RoleTypeEnum,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListRolesByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRolesByOwnerQuery = {
  listRolesByOwner?:  {
    __typename: "ModelRoleConnection",
    items?:  Array< {
      __typename: "Role",
      id: string,
      owner: string,
      name: string,
      rolePermissions?: Array< string > | null,
      roleType: RoleTypeEnum,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetProductReviewQueryVariables = {
  id?: string,
};

export type GetProductReviewQuery = {
  getProductReview?:  {
    __typename: "ProductReview",
    id: string,
    owner?: string | null,
    productId?: string | null,
    alternateId: string,
    sdsExtractionJobId?: string | null,
    isExempt?: boolean | null,
    recommendedUse?: string | null,
    reviewedAt?: string | null,
    reviewerRetoolUsername?: string | null,
    status?: ReviewStatus | null,
    needsReview?: boolean | null,
    isUpdate?: boolean | null,
    fields:  Array<( {
        __typename: "TierIIMixtureComponentsFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        newComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
        acceptedComponents?:  Array< {
          __typename: string,
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } > | null,
      } | {
        __typename: "StringFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldString?: string | null,
        newString?: string | null,
        acceptedString?: string | null,
      } | {
        __typename: "IntFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldInt?: number | null,
        newInt?: number | null,
        acceptedInt?: number | null,
      } | {
        __typename: "FloatFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldFloat?: number | null,
        newFloat?: number | null,
        acceptedFloat?: number | null,
      } | {
        __typename: "BooleanFieldProposal",
        type: FieldType,
        name: string,
        doNotUpdate?: boolean | null,
        messages?: Array< string > | null,
        oldBoolean?: boolean | null,
        newBoolean?: boolean | null,
        acceptedBoolean?: boolean | null,
      }
    ) > | null,
    sdsFilename?: string | null,
    sdsBoxFileId?: string | null,
    sdsBoxPath?: string | null,
    sdsS3Key?: string | null,
    extractionResultsS3Key?: string | null,
    extractionMessages?:  Array< {
      __typename: "ExtractionMessage",
      type: ExtractionMessageType,
      message: string,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sdsExtractionJob?:  {
      __typename: "SdsExtractionJob",
      id: string,
      owner?: string | null,
      boxFolderId?: string | null,
      boxFolderPath?: string | null,
      fileCount?: number | null,
      processedFileCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListProductReviewsQueryVariables = {
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReviewsQuery = {
  listProductReviews?:  {
    __typename: "ModelProductReviewConnection",
    items?:  Array< {
      __typename: "ProductReview",
      id: string,
      owner?: string | null,
      productId?: string | null,
      alternateId: string,
      sdsExtractionJobId?: string | null,
      isExempt?: boolean | null,
      recommendedUse?: string | null,
      reviewedAt?: string | null,
      reviewerRetoolUsername?: string | null,
      status?: ReviewStatus | null,
      needsReview?: boolean | null,
      isUpdate?: boolean | null,
      fields:  Array<( {
          __typename: "TierIIMixtureComponentsFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          newComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          acceptedComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
        } | {
          __typename: "StringFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldString?: string | null,
          newString?: string | null,
          acceptedString?: string | null,
        } | {
          __typename: "IntFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldInt?: number | null,
          newInt?: number | null,
          acceptedInt?: number | null,
        } | {
          __typename: "FloatFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldFloat?: number | null,
          newFloat?: number | null,
          acceptedFloat?: number | null,
        } | {
          __typename: "BooleanFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldBoolean?: boolean | null,
          newBoolean?: boolean | null,
          acceptedBoolean?: boolean | null,
        }
      ) > | null,
      sdsFilename?: string | null,
      sdsBoxFileId?: string | null,
      sdsBoxPath?: string | null,
      sdsS3Key?: string | null,
      extractionResultsS3Key?: string | null,
      extractionMessages?:  Array< {
        __typename: "ExtractionMessage",
        type: ExtractionMessageType,
        message: string,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sdsExtractionJob?:  {
        __typename: "SdsExtractionJob",
        id: string,
        owner?: string | null,
        boxFolderId?: string | null,
        boxFolderPath?: string | null,
        fileCount?: number | null,
        processedFileCount?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListProductReviewsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReviewsByOwnerQuery = {
  listProductReviewsByOwner?:  {
    __typename: "ModelProductReviewConnection",
    items?:  Array< {
      __typename: "ProductReview",
      id: string,
      owner?: string | null,
      productId?: string | null,
      alternateId: string,
      sdsExtractionJobId?: string | null,
      isExempt?: boolean | null,
      recommendedUse?: string | null,
      reviewedAt?: string | null,
      reviewerRetoolUsername?: string | null,
      status?: ReviewStatus | null,
      needsReview?: boolean | null,
      isUpdate?: boolean | null,
      fields:  Array<( {
          __typename: "TierIIMixtureComponentsFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          newComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          acceptedComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
        } | {
          __typename: "StringFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldString?: string | null,
          newString?: string | null,
          acceptedString?: string | null,
        } | {
          __typename: "IntFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldInt?: number | null,
          newInt?: number | null,
          acceptedInt?: number | null,
        } | {
          __typename: "FloatFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldFloat?: number | null,
          newFloat?: number | null,
          acceptedFloat?: number | null,
        } | {
          __typename: "BooleanFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldBoolean?: boolean | null,
          newBoolean?: boolean | null,
          acceptedBoolean?: boolean | null,
        }
      ) > | null,
      sdsFilename?: string | null,
      sdsBoxFileId?: string | null,
      sdsBoxPath?: string | null,
      sdsS3Key?: string | null,
      extractionResultsS3Key?: string | null,
      extractionMessages?:  Array< {
        __typename: "ExtractionMessage",
        type: ExtractionMessageType,
        message: string,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sdsExtractionJob?:  {
        __typename: "SdsExtractionJob",
        id: string,
        owner?: string | null,
        boxFolderId?: string | null,
        boxFolderPath?: string | null,
        fileCount?: number | null,
        processedFileCount?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListProductReviewsByAlternateIdQueryVariables = {
  alternateId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReviewsByAlternateIdQuery = {
  listProductReviewsByAlternateId?:  {
    __typename: "ModelProductReviewConnection",
    items?:  Array< {
      __typename: "ProductReview",
      id: string,
      owner?: string | null,
      productId?: string | null,
      alternateId: string,
      sdsExtractionJobId?: string | null,
      isExempt?: boolean | null,
      recommendedUse?: string | null,
      reviewedAt?: string | null,
      reviewerRetoolUsername?: string | null,
      status?: ReviewStatus | null,
      needsReview?: boolean | null,
      isUpdate?: boolean | null,
      fields:  Array<( {
          __typename: "TierIIMixtureComponentsFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          newComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          acceptedComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
        } | {
          __typename: "StringFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldString?: string | null,
          newString?: string | null,
          acceptedString?: string | null,
        } | {
          __typename: "IntFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldInt?: number | null,
          newInt?: number | null,
          acceptedInt?: number | null,
        } | {
          __typename: "FloatFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldFloat?: number | null,
          newFloat?: number | null,
          acceptedFloat?: number | null,
        } | {
          __typename: "BooleanFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldBoolean?: boolean | null,
          newBoolean?: boolean | null,
          acceptedBoolean?: boolean | null,
        }
      ) > | null,
      sdsFilename?: string | null,
      sdsBoxFileId?: string | null,
      sdsBoxPath?: string | null,
      sdsS3Key?: string | null,
      extractionResultsS3Key?: string | null,
      extractionMessages?:  Array< {
        __typename: "ExtractionMessage",
        type: ExtractionMessageType,
        message: string,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sdsExtractionJob?:  {
        __typename: "SdsExtractionJob",
        id: string,
        owner?: string | null,
        boxFolderId?: string | null,
        boxFolderPath?: string | null,
        fileCount?: number | null,
        processedFileCount?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListProductReviewsBySdsExtractionJobIdQueryVariables = {
  sdsExtractionJobId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReviewsBySdsExtractionJobIdQuery = {
  listProductReviewsBySdsExtractionJobId?:  {
    __typename: "ModelProductReviewConnection",
    items?:  Array< {
      __typename: "ProductReview",
      id: string,
      owner?: string | null,
      productId?: string | null,
      alternateId: string,
      sdsExtractionJobId?: string | null,
      isExempt?: boolean | null,
      recommendedUse?: string | null,
      reviewedAt?: string | null,
      reviewerRetoolUsername?: string | null,
      status?: ReviewStatus | null,
      needsReview?: boolean | null,
      isUpdate?: boolean | null,
      fields:  Array<( {
          __typename: "TierIIMixtureComponentsFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          newComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
          acceptedComponents?:  Array< {
            __typename: string,
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } > | null,
        } | {
          __typename: "StringFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldString?: string | null,
          newString?: string | null,
          acceptedString?: string | null,
        } | {
          __typename: "IntFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldInt?: number | null,
          newInt?: number | null,
          acceptedInt?: number | null,
        } | {
          __typename: "FloatFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldFloat?: number | null,
          newFloat?: number | null,
          acceptedFloat?: number | null,
        } | {
          __typename: "BooleanFieldProposal",
          type: FieldType,
          name: string,
          doNotUpdate?: boolean | null,
          messages?: Array< string > | null,
          oldBoolean?: boolean | null,
          newBoolean?: boolean | null,
          acceptedBoolean?: boolean | null,
        }
      ) > | null,
      sdsFilename?: string | null,
      sdsBoxFileId?: string | null,
      sdsBoxPath?: string | null,
      sdsS3Key?: string | null,
      extractionResultsS3Key?: string | null,
      extractionMessages?:  Array< {
        __typename: "ExtractionMessage",
        type: ExtractionMessageType,
        message: string,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sdsExtractionJob?:  {
        __typename: "SdsExtractionJob",
        id: string,
        owner?: string | null,
        boxFolderId?: string | null,
        boxFolderPath?: string | null,
        fileCount?: number | null,
        processedFileCount?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSdsExtractionJobQueryVariables = {
  id?: string,
};

export type GetSdsExtractionJobQuery = {
  getSdsExtractionJob?:  {
    __typename: "SdsExtractionJob",
    id: string,
    owner?: string | null,
    boxFolderId?: string | null,
    boxFolderPath?: string | null,
    fileCount?: number | null,
    processedFileCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSdsExtractionJobsQueryVariables = {
  filter?: ModelSdsExtractionJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSdsExtractionJobsQuery = {
  listSdsExtractionJobs?:  {
    __typename: "ModelSdsExtractionJobConnection",
    items?:  Array< {
      __typename: "SdsExtractionJob",
      id: string,
      owner?: string | null,
      boxFolderId?: string | null,
      boxFolderPath?: string | null,
      fileCount?: number | null,
      processedFileCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSdsExtractionJobsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSdsExtractionJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSdsExtractionJobsByOwnerQuery = {
  listSdsExtractionJobsByOwner?:  {
    __typename: "ModelSdsExtractionJobConnection",
    items?:  Array< {
      __typename: "SdsExtractionJob",
      id: string,
      owner?: string | null,
      boxFolderId?: string | null,
      boxFolderPath?: string | null,
      fileCount?: number | null,
      processedFileCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetManagedTaskQueryVariables = {
  id?: string,
};

export type GetManagedTaskQuery = {
  getManagedTask?:  {
    __typename: "ManagedTask",
    id: string,
    owner?: string | null,
    watcherId?: string | null,
    startDate: string,
    endDate: string,
    programArea?: string | null,
    requirement:  {
      __typename: "NestedRequirement",
      id?: string | null,
      status?: RequirementStatus | null,
      name?: string | null,
      title?: string | null,
      description?: string | null,
      frequency?:  {
        __typename: "RepeatRule",
        frequency: Frequency,
        interval: number,
        start?: string | null,
      } | null,
      agencyId?: string | null,
      citations?:  Array< {
        __typename: "Link",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      area?: string | null,
      agency?:  {
        __typename: "NestedAgency",
        id: string,
        owner?: string | null,
        name: string,
        level: AgencyLevel,
        link?:  {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null,
        jurisdiction: string,
        state?: StateOrTerritory | null,
        locale?: string | null,
      } | null,
      userAgencyTitle?: string | null,
      tasks?:  Array< {
        __typename: "Task",
        isCompleted: boolean,
        text: string,
      } | null > | null,
      hyperlinks?:  Array< {
        __typename: "HyperLink",
        name?: string | null,
        link?: string | null,
      } | null > | null,
      documents?:  Array< {
        __typename: "NestedDocument",
        id: string,
        owner?: string | null,
        title: string,
        storageLink: string,
      } | null > | null,
    },
    _requirementName: string,
    isOrganizationLevel: boolean,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelFacilityTaskConnection",
      items?:  Array< {
        __typename: "FacilityTask",
        id: string,
        owner?: string | null,
        taskId: string,
        facilityId?: string | null,
        assigneeId?: string | null,
        watcherId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        assignee?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListManagedTasksQueryVariables = {
  id?: string | null,
  filter?: ModelManagedTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListManagedTasksQuery = {
  listManagedTasks?:  {
    __typename: "ModelManagedTaskConnection",
    items?:  Array< {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListManagedTasksByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelManagedTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManagedTasksByOwnerQuery = {
  listManagedTasksByOwner?:  {
    __typename: "ModelManagedTaskConnection",
    items?:  Array< {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchManagedTasksQueryVariables = {
  filter?: SearchableManagedTaskFilterInput | null,
  sort?: SearchableManagedTaskSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchManagedTasksQuery = {
  searchManagedTasks?:  {
    __typename: "SearchableManagedTaskConnection",
    items?:  Array< {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFacilityTaskQueryVariables = {
  id?: string,
};

export type GetFacilityTaskQuery = {
  getFacilityTask?:  {
    __typename: "FacilityTask",
    id: string,
    owner?: string | null,
    taskId: string,
    facilityId?: string | null,
    assigneeId?: string | null,
    watcherId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    sequences?:  {
      __typename: "ModelEventSequenceConnection",
      items?:  Array< {
        __typename: "EventSequence",
        id: string,
        parentId?: string | null,
        owner: string,
        seriesId: string,
        taskId?: string | null,
        type: EventSequenceType,
        typeSearchable: string,
        startDate: string,
        endDate: string,
        isRecurring?: boolean | null,
        isOrganizationLevel?: boolean | null,
        exceptions?: Array< string > | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        _requirementArea: string,
        facilityId?: string | null,
        state?: string | null,
        responsiblePersonId?: string | null,
        notificationLeadTimes?:  Array< {
          __typename: "Timespan",
          value: number,
          unit: TimeIncrement,
        } > | null,
        completedAt?: string | null,
        status: EventSequenceStatus,
        statusSearchable: string,
        description?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        documents?:  {
          __typename: "ModelEventSequenceDocumentConnection",
          items?:  Array< {
            __typename: "EventSequenceDocument",
            id: string,
            owner?: string | null,
            documentId: string,
            eventSequenceId: string,
            seriesId: string,
            taskId?: string | null,
            facilityId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watchers?:  {
          __typename: "ModelEventSequenceWatcherConnection",
          items?:  Array< {
            __typename: "EventSequenceWatcher",
            id: string,
            owner?: string | null,
            personId: string,
            eventSequenceId: string,
            taskId?: string | null,
            seriesId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        facility?:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        responsiblePerson?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        task?:  {
          __typename: "ManagedTask",
          id: string,
          owner?: string | null,
          watcherId?: string | null,
          startDate: string,
          endDate: string,
          programArea?: string | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          isOrganizationLevel: boolean,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityTaskConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    watcher?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    assignee?:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListFacilityTasksQueryVariables = {
  id?: string | null,
  filter?: ModelFacilityTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFacilityTasksQuery = {
  listFacilityTasks?:  {
    __typename: "ModelFacilityTaskConnection",
    items?:  Array< {
      __typename: "FacilityTask",
      id: string,
      owner?: string | null,
      taskId: string,
      facilityId?: string | null,
      assigneeId?: string | null,
      watcherId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      assignee?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityTasksByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityTasksByOwnerQuery = {
  listFacilityTasksByOwner?:  {
    __typename: "ModelFacilityTaskConnection",
    items?:  Array< {
      __typename: "FacilityTask",
      id: string,
      owner?: string | null,
      taskId: string,
      facilityId?: string | null,
      assigneeId?: string | null,
      watcherId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      assignee?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityTasksByTaskQueryVariables = {
  taskId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityTasksByTaskQuery = {
  listFacilityTasksByTask?:  {
    __typename: "ModelFacilityTaskConnection",
    items?:  Array< {
      __typename: "FacilityTask",
      id: string,
      owner?: string | null,
      taskId: string,
      facilityId?: string | null,
      assigneeId?: string | null,
      watcherId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      assignee?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFacilityTasksByFacilityQueryVariables = {
  facilityId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFacilityTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFacilityTasksByFacilityQuery = {
  listFacilityTasksByFacility?:  {
    __typename: "ModelFacilityTaskConnection",
    items?:  Array< {
      __typename: "FacilityTask",
      id: string,
      owner?: string | null,
      taskId: string,
      facilityId?: string | null,
      assigneeId?: string | null,
      watcherId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      assignee?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchFacilityTasksQueryVariables = {
  filter?: SearchableFacilityTaskFilterInput | null,
  sort?: SearchableFacilityTaskSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFacilityTasksQuery = {
  searchFacilityTasks?:  {
    __typename: "SearchableFacilityTaskConnection",
    items?:  Array< {
      __typename: "FacilityTask",
      id: string,
      owner?: string | null,
      taskId: string,
      facilityId?: string | null,
      assigneeId?: string | null,
      watcherId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      facility?:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      assignee?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      task:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetTaskDocumentQueryVariables = {
  id?: string,
};

export type GetTaskDocumentQuery = {
  getTaskDocument?:  {
    __typename: "TaskDocument",
    id: string,
    owner?: string | null,
    documentId: string,
    taskId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    document:  {
      __typename: "Document",
      id: string,
      owner?: string | null,
      title: string,
      description?: string | null,
      fileExtension: string,
      state?: string | null,
      area?: string | null,
      programAreas?: Array< string | null > | null,
      documentType: string,
      sourceLink?: string | null,
      storageLink: string,
      agencyId?: string | null,
      facilityId?: string | null,
      lobId?: string | null,
      createdBy?: string | null,
      stateSearchable?: string | null,
      areaSearchable?: string | null,
      searchableProgramAreas?: string | null,
      relatedEntityIds?:  Array< {
        __typename: "DocumentRelatedEntityId",
        kind: DocumentRelatedEntityIdKind,
        id: string,
      } > | null,
      tags?: string | null,
      isArchived?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      requirements?:  {
        __typename: "ModelDocumentRequirementConnection",
        items?:  Array< {
          __typename: "DocumentRequirement",
          id: string,
          owner?: string | null,
          documentId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          document:  {
            __typename: "Document",
            id: string,
            owner?: string | null,
            title: string,
            description?: string | null,
            fileExtension: string,
            state?: string | null,
            area?: string | null,
            programAreas?: Array< string | null > | null,
            documentType: string,
            sourceLink?: string | null,
            storageLink: string,
            agencyId?: string | null,
            facilityId?: string | null,
            lobId?: string | null,
            createdBy?: string | null,
            stateSearchable?: string | null,
            areaSearchable?: string | null,
            searchableProgramAreas?: string | null,
            tags?: string | null,
            isArchived?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          events?:  {
            __typename: "ModelEventConnection",
            nextToken?: string | null,
          } | null,
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    task:  {
      __typename: "ManagedTask",
      id: string,
      owner?: string | null,
      watcherId?: string | null,
      startDate: string,
      endDate: string,
      programArea?: string | null,
      requirement:  {
        __typename: "NestedRequirement",
        id?: string | null,
        status?: RequirementStatus | null,
        name?: string | null,
        title?: string | null,
        description?: string | null,
        frequency?:  {
          __typename: "RepeatRule",
          frequency: Frequency,
          interval: number,
          start?: string | null,
        } | null,
        agencyId?: string | null,
        citations?:  Array< {
          __typename: "Link",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        area?: string | null,
        agency?:  {
          __typename: "NestedAgency",
          id: string,
          owner?: string | null,
          name: string,
          level: AgencyLevel,
          link?:  {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null,
          jurisdiction: string,
          state?: StateOrTerritory | null,
          locale?: string | null,
        } | null,
        userAgencyTitle?: string | null,
        tasks?:  Array< {
          __typename: "Task",
          isCompleted: boolean,
          text: string,
        } | null > | null,
        hyperlinks?:  Array< {
          __typename: "HyperLink",
          name?: string | null,
          link?: string | null,
        } | null > | null,
        documents?:  Array< {
          __typename: "NestedDocument",
          id: string,
          owner?: string | null,
          title: string,
          storageLink: string,
        } | null > | null,
      },
      _requirementName: string,
      isOrganizationLevel: boolean,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      sequences?:  {
        __typename: "ModelEventSequenceConnection",
        items?:  Array< {
          __typename: "EventSequence",
          id: string,
          parentId?: string | null,
          owner: string,
          seriesId: string,
          taskId?: string | null,
          type: EventSequenceType,
          typeSearchable: string,
          startDate: string,
          endDate: string,
          isRecurring?: boolean | null,
          isOrganizationLevel?: boolean | null,
          exceptions?: Array< string > | null,
          requirement:  {
            __typename: "NestedRequirement",
            id?: string | null,
            status?: RequirementStatus | null,
            name?: string | null,
            title?: string | null,
            description?: string | null,
            agencyId?: string | null,
            area?: string | null,
            userAgencyTitle?: string | null,
          },
          _requirementName: string,
          _requirementArea: string,
          facilityId?: string | null,
          state?: string | null,
          responsiblePersonId?: string | null,
          notificationLeadTimes?:  Array< {
            __typename: "Timespan",
            value: number,
            unit: TimeIncrement,
          } > | null,
          completedAt?: string | null,
          status: EventSequenceStatus,
          statusSearchable: string,
          description?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          documents?:  {
            __typename: "ModelEventSequenceDocumentConnection",
            nextToken?: string | null,
          } | null,
          watchers?:  {
            __typename: "ModelEventSequenceWatcherConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          responsiblePerson?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task?:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      watcher?:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      } | null,
      facilities?:  {
        __typename: "ModelFacilityTaskConnection",
        items?:  Array< {
          __typename: "FacilityTask",
          id: string,
          owner?: string | null,
          taskId: string,
          facilityId?: string | null,
          assigneeId?: string | null,
          watcherId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          sequences?:  {
            __typename: "ModelEventSequenceConnection",
            nextToken?: string | null,
          } | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          watcher?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          assignee?:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          task:  {
            __typename: "ManagedTask",
            id: string,
            owner?: string | null,
            watcherId?: string | null,
            startDate: string,
            endDate: string,
            programArea?: string | null,
            _requirementName: string,
            isOrganizationLevel: boolean,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListTaskDocumentsQueryVariables = {
  id?: string | null,
  filter?: ModelTaskDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTaskDocumentsQuery = {
  listTaskDocuments?:  {
    __typename: "ModelTaskDocumentConnection",
    items?:  Array< {
      __typename: "TaskDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      taskId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      task:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTaskDocumentsByTaskIdQueryVariables = {
  taskId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskDocumentsByTaskIdQuery = {
  listTaskDocumentsByTaskId?:  {
    __typename: "ModelTaskDocumentConnection",
    items?:  Array< {
      __typename: "TaskDocument",
      id: string,
      owner?: string | null,
      documentId: string,
      taskId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      document:  {
        __typename: "Document",
        id: string,
        owner?: string | null,
        title: string,
        description?: string | null,
        fileExtension: string,
        state?: string | null,
        area?: string | null,
        programAreas?: Array< string | null > | null,
        documentType: string,
        sourceLink?: string | null,
        storageLink: string,
        agencyId?: string | null,
        facilityId?: string | null,
        lobId?: string | null,
        createdBy?: string | null,
        stateSearchable?: string | null,
        areaSearchable?: string | null,
        searchableProgramAreas?: string | null,
        relatedEntityIds?:  Array< {
          __typename: "DocumentRelatedEntityId",
          kind: DocumentRelatedEntityIdKind,
          id: string,
        } > | null,
        tags?: string | null,
        isArchived?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        requirements?:  {
          __typename: "ModelDocumentRequirementConnection",
          items?:  Array< {
            __typename: "DocumentRequirement",
            id: string,
            owner?: string | null,
            documentId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      task:  {
        __typename: "ManagedTask",
        id: string,
        owner?: string | null,
        watcherId?: string | null,
        startDate: string,
        endDate: string,
        programArea?: string | null,
        requirement:  {
          __typename: "NestedRequirement",
          id?: string | null,
          status?: RequirementStatus | null,
          name?: string | null,
          title?: string | null,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId?: string | null,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area?: string | null,
          agency?:  {
            __typename: "NestedAgency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
          } | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          documents?:  Array< {
            __typename: "NestedDocument",
            id: string,
            owner?: string | null,
            title: string,
            storageLink: string,
          } | null > | null,
        },
        _requirementName: string,
        isOrganizationLevel: boolean,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        sequences?:  {
          __typename: "ModelEventSequenceConnection",
          items?:  Array< {
            __typename: "EventSequence",
            id: string,
            parentId?: string | null,
            owner: string,
            seriesId: string,
            taskId?: string | null,
            type: EventSequenceType,
            typeSearchable: string,
            startDate: string,
            endDate: string,
            isRecurring?: boolean | null,
            isOrganizationLevel?: boolean | null,
            exceptions?: Array< string > | null,
            _requirementName: string,
            _requirementArea: string,
            facilityId?: string | null,
            state?: string | null,
            responsiblePersonId?: string | null,
            completedAt?: string | null,
            status: EventSequenceStatus,
            statusSearchable: string,
            description?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        watcher?:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        } | null,
        facilities?:  {
          __typename: "ModelFacilityTaskConnection",
          items?:  Array< {
            __typename: "FacilityTask",
            id: string,
            owner?: string | null,
            taskId: string,
            facilityId?: string | null,
            assigneeId?: string | null,
            watcherId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id?: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    partnerTenantId?: string | null,
    salesforceId?: string | null,
    accountType?: AccountType | null,
    isDemo?: boolean | null,
    isPartner?: boolean | null,
    isDisabled?: boolean | null,
    externalId?: string | null,
    ssoConfig?:  {
      __typename: "SsoConfig",
      mappingName: string,
      emailDomains: Array< string >,
    } | null,
    roleIdToDefaultForNewUsers?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    users?:  {
      __typename: "ModelUserTenantConnection",
      items?:  Array< {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items?:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      partnerTenantId?: string | null,
      salesforceId?: string | null,
      accountType?: AccountType | null,
      isDemo?: boolean | null,
      isPartner?: boolean | null,
      isDisabled?: boolean | null,
      externalId?: string | null,
      ssoConfig?:  {
        __typename: "SsoConfig",
        mappingName: string,
        emailDomains: Array< string >,
      } | null,
      roleIdToDefaultForNewUsers?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      users?:  {
        __typename: "ModelUserTenantConnection",
        items?:  Array< {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTenantsByPartnerQueryVariables = {
  partnerTenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsByPartnerQuery = {
  listTenantsByPartner?:  {
    __typename: "ModelTenantConnection",
    items?:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      partnerTenantId?: string | null,
      salesforceId?: string | null,
      accountType?: AccountType | null,
      isDemo?: boolean | null,
      isPartner?: boolean | null,
      isDisabled?: boolean | null,
      externalId?: string | null,
      ssoConfig?:  {
        __typename: "SsoConfig",
        mappingName: string,
        emailDomains: Array< string >,
      } | null,
      roleIdToDefaultForNewUsers?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      users?:  {
        __typename: "ModelUserTenantConnection",
        items?:  Array< {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTierIiFacilityProductQueryVariables = {
  id?: string,
};

export type GetTierIiFacilityProductQuery = {
  getTierIIFacilityProduct?:  {
    __typename: "TierIIFacilityProduct",
    id: string,
    facilityId: string,
    owner?: string | null,
    productId: string,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    isOverThresholds?: boolean | null,
    isAlwaysReported?: boolean | null,
    maxAmount?: number | null,
    maxAmountCode?: number | null,
    averageAmount?: number | null,
    averageAmountCode?: number | null,
    maxAmountLargestContainer?: number | null,
    daysOnSite?: number | null,
    hasConfidentialStorageLocations?: boolean | null,
    unit?: string | null,
    locations?:  Array< {
      __typename: "TierIIStorageLocation",
      id: string,
      description?: string | null,
      storageType?: string | null,
      pressure?: string | null,
      temperature?: string | null,
      amount?: number | null,
      unit?: string | null,
      mapNumber?: string | null,
      gridNumber?: string | null,
      insideOutside?: string | null,
      storageBuilding?: string | null,
      storageFloor?: string | null,
      storageArea?: string | null,
      storageRoom?: string | null,
      storageQuadrant?: string | null,
    } | null > | null,
    stateFields?: string | null,
    aggregatedProductIds?: Array< string > | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    facility:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListTierIiFacilityProductsQueryVariables = {
  id?: string | null,
  filter?: ModelTierIIFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTierIiFacilityProductsQuery = {
  listTierIIFacilityProducts?:  {
    __typename: "ModelTierIIFacilityProductConnection",
    items?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiFacilityProductsByFacilityQueryVariables = {
  facilityId?: string | null,
  productId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiFacilityProductsByFacilityQuery = {
  listTierIIFacilityProductsByFacility?:  {
    __typename: "ModelTierIIFacilityProductConnection",
    items?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiFacilityProductsByOwnerQueryVariables = {
  owner?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiFacilityProductsByOwnerQuery = {
  listTierIIFacilityProductsByOwner?:  {
    __typename: "ModelTierIIFacilityProductConnection",
    items?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiFacilityProductsByProductQueryVariables = {
  productId?: string | null,
  facilityId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIFacilityProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiFacilityProductsByProductQuery = {
  listTierIIFacilityProductsByProduct?:  {
    __typename: "ModelTierIIFacilityProductConnection",
    items?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTierIiProductQueryVariables = {
  id?: string,
};

export type GetTierIiProductQuery = {
  getTierIIProduct?:  {
    __typename: "TierIIProduct",
    id: string,
    owner?: string | null,
    chemName: string,
    casNumber?: string | null,
    noCasNumber?: boolean | null,
    alternateId?: string | null,
    ehs?: boolean | null,
    pure?: boolean | null,
    mixture?: boolean | null,
    solid?: boolean | null,
    liquid?: boolean | null,
    gas?: boolean | null,
    isTradeSecret?: boolean | null,
    density?: number | null,
    densityUnits?: string | null,
    boilingPointFahr?: number | null,
    flashPointFahr?: number | null,
    vaporPressureMmhg?: number | null,
    solubility?: string | null,
    isBattery?: boolean | null,
    isFuel?: boolean | null,
    physicalHazards?: Array< string | null > | null,
    healthHazards?: Array< string | null > | null,
    oregonHazards?: Array< string | null > | null,
    ifcHazards?: Array< string | null > | null,
    noHazardsNotReporting?: boolean | null,
    components?:  Array< {
      __typename: "TierIIMixtureComponent",
      componentName?: string | null,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      ehs?: boolean | null,
      componentPercentage?: number | null,
      weightOrVolume?: string | null,
      ehsForm?: EhsForm | null,
    } | null > | null,
    sdsRecords?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    stateFields?: string | null,
    ehsForm?: EhsForm | null,
    createdAt?: string | null,
    updatedAt: string,
    updatedBy?: string | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTierIiProductsQueryVariables = {
  filter?: ModelTierIIProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductsQuery = {
  listTierIIProducts?:  {
    __typename: "ModelTierIIProductConnection",
    items?:  Array< {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiProductsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductsByOwnerQuery = {
  listTierIIProductsByOwner?:  {
    __typename: "ModelTierIIProductConnection",
    items?:  Array< {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiProductsByChemNameQueryVariables = {
  chemName?: string | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductsByChemNameQuery = {
  listTierIIProductsByChemName?:  {
    __typename: "ModelTierIIProductConnection",
    items?:  Array< {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchTierIiProductsQueryVariables = {
  filter?: SearchableTierIIProductFilterInput | null,
  sort?: SearchableTierIIProductSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchTierIiProductsQuery = {
  searchTierIIProducts?:  {
    __typename: "SearchableTierIIProductConnection",
    items?:  Array< {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetTierIiProductGroupQueryVariables = {
  id?: string,
};

export type GetTierIiProductGroupQuery = {
  getTierIIProductGroup?:  {
    __typename: "TierIIProductGroup",
    id: string,
    owner?: string | null,
    name: string,
    groupType?: TierIIProductGroupGroupType | null,
    description: string,
    measurementUnits: string,
    records?:  Array< {
      __typename: "TierIIRecord",
      at: string,
      key: string,
      kind: TierIIRecordKind,
      name: string,
      associatedDocumentId?: string | null,
    } > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    childProducts?:  {
      __typename: "ModelTierIIProductProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductProductGroup",
        id: string,
        owner?: string | null,
        productGroupId: string,
        productId: string,
        amount?: number | null,
        units?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        productGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    childProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    parentProductGroups?:  {
      __typename: "ModelTierIIProductGroupProductGroupConnection",
      items?:  Array< {
        __typename: "TierIIProductGroupProductGroup",
        id: string,
        owner?: string | null,
        parentProductGroupId: string,
        childProductGroupId: string,
        amount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        parentProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        childProductGroup:  {
          __typename: "TierIIProductGroup",
          id: string,
          owner?: string | null,
          name: string,
          groupType?: TierIIProductGroupGroupType | null,
          description: string,
          measurementUnits: string,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          childProducts?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
          childProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductGroupProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTierIiProductGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelTierIIProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTierIiProductGroupsQuery = {
  listTierIIProductGroups?:  {
    __typename: "ModelTierIIProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiProductGroupsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductGroupsByOwnerQuery = {
  listTierIiProductGroupsByOwner?:  {
    __typename: "ModelTierIIProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTierIiProductProductGroupQueryVariables = {
  id?: string,
};

export type GetTierIiProductProductGroupQuery = {
  getTierIIProductProductGroup?:  {
    __typename: "TierIIProductProductGroup",
    id: string,
    owner?: string | null,
    productGroupId: string,
    productId: string,
    amount?: number | null,
    units?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    product:  {
      __typename: "TierIIProduct",
      id: string,
      owner?: string | null,
      chemName: string,
      casNumber?: string | null,
      noCasNumber?: boolean | null,
      alternateId?: string | null,
      ehs?: boolean | null,
      pure?: boolean | null,
      mixture?: boolean | null,
      solid?: boolean | null,
      liquid?: boolean | null,
      gas?: boolean | null,
      isTradeSecret?: boolean | null,
      density?: number | null,
      densityUnits?: string | null,
      boilingPointFahr?: number | null,
      flashPointFahr?: number | null,
      vaporPressureMmhg?: number | null,
      solubility?: string | null,
      isBattery?: boolean | null,
      isFuel?: boolean | null,
      physicalHazards?: Array< string | null > | null,
      healthHazards?: Array< string | null > | null,
      oregonHazards?: Array< string | null > | null,
      ifcHazards?: Array< string | null > | null,
      noHazardsNotReporting?: boolean | null,
      components?:  Array< {
        __typename: "TierIIMixtureComponent",
        componentName?: string | null,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        ehs?: boolean | null,
        componentPercentage?: number | null,
        weightOrVolume?: string | null,
        ehsForm?: EhsForm | null,
      } | null > | null,
      sdsRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      stateFields?: string | null,
      ehsForm?: EhsForm | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    productGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListTierIiProductProductGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelTierIIProductProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTierIiProductProductGroupsQuery = {
  listTierIIProductProductGroups?:  {
    __typename: "ModelTierIIProductProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductProductGroup",
      id: string,
      owner?: string | null,
      productGroupId: string,
      productId: string,
      amount?: number | null,
      units?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      productGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiProductProductGroupsByProductGroupQueryVariables = {
  productGroupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIProductProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductProductGroupsByProductGroupQuery = {
  listTierIiProductProductGroupsByProductGroup?:  {
    __typename: "ModelTierIIProductProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductProductGroup",
      id: string,
      owner?: string | null,
      productGroupId: string,
      productId: string,
      amount?: number | null,
      units?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      productGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiProductProductGroupsByProductQueryVariables = {
  productId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIProductProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductProductGroupsByProductQuery = {
  listTierIiProductProductGroupsByProduct?:  {
    __typename: "ModelTierIIProductProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductProductGroup",
      id: string,
      owner?: string | null,
      productGroupId: string,
      productId: string,
      amount?: number | null,
      units?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      productGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTierIiProductGroupProductGroupQueryVariables = {
  id?: string,
};

export type GetTierIiProductGroupProductGroupQuery = {
  getTierIIProductGroupProductGroup?:  {
    __typename: "TierIIProductGroupProductGroup",
    id: string,
    owner?: string | null,
    parentProductGroupId: string,
    childProductGroupId: string,
    amount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    parentProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    childProductGroup:  {
      __typename: "TierIIProductGroup",
      id: string,
      owner?: string | null,
      name: string,
      groupType?: TierIIProductGroupGroupType | null,
      description: string,
      measurementUnits: string,
      records?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      childProducts?:  {
        __typename: "ModelTierIIProductProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductProductGroup",
          id: string,
          owner?: string | null,
          productGroupId: string,
          productId: string,
          amount?: number | null,
          units?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          product:  {
            __typename: "TierIIProduct",
            id: string,
            owner?: string | null,
            chemName: string,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            alternateId?: string | null,
            ehs?: boolean | null,
            pure?: boolean | null,
            mixture?: boolean | null,
            solid?: boolean | null,
            liquid?: boolean | null,
            gas?: boolean | null,
            isTradeSecret?: boolean | null,
            density?: number | null,
            densityUnits?: string | null,
            boilingPointFahr?: number | null,
            flashPointFahr?: number | null,
            vaporPressureMmhg?: number | null,
            solubility?: string | null,
            isBattery?: boolean | null,
            isFuel?: boolean | null,
            physicalHazards?: Array< string | null > | null,
            healthHazards?: Array< string | null > | null,
            oregonHazards?: Array< string | null > | null,
            ifcHazards?: Array< string | null > | null,
            noHazardsNotReporting?: boolean | null,
            stateFields?: string | null,
            ehsForm?: EhsForm | null,
            createdAt?: string | null,
            updatedAt: string,
            updatedBy?: string | null,
          },
          productGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      childProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      parentProductGroups?:  {
        __typename: "ModelTierIIProductGroupProductGroupConnection",
        items?:  Array< {
          __typename: "TierIIProductGroupProductGroup",
          id: string,
          owner?: string | null,
          parentProductGroupId: string,
          childProductGroupId: string,
          amount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          parentProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          childProductGroup:  {
            __typename: "TierIIProductGroup",
            id: string,
            owner?: string | null,
            name: string,
            groupType?: TierIIProductGroupGroupType | null,
            description: string,
            measurementUnits: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListTierIiProductGroupProductGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelTierIIProductGroupProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTierIiProductGroupProductGroupsQuery = {
  listTierIIProductGroupProductGroups?:  {
    __typename: "ModelTierIIProductGroupProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductGroupProductGroup",
      id: string,
      owner?: string | null,
      parentProductGroupId: string,
      childProductGroupId: string,
      amount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      parentProductGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      childProductGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiProductGroupProductGroupsByParentQueryVariables = {
  parentProductGroupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIProductGroupProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductGroupProductGroupsByParentQuery = {
  listTierIiProductGroupProductGroupsByParent?:  {
    __typename: "ModelTierIIProductGroupProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductGroupProductGroup",
      id: string,
      owner?: string | null,
      parentProductGroupId: string,
      childProductGroupId: string,
      amount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      parentProductGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      childProductGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiProductGroupProductGroupsByChildQueryVariables = {
  childProductGroupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIProductGroupProductGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiProductGroupProductGroupsByChildQuery = {
  listTierIiProductGroupProductGroupsByChild?:  {
    __typename: "ModelTierIIProductGroupProductGroupConnection",
    items?:  Array< {
      __typename: "TierIIProductGroupProductGroup",
      id: string,
      owner?: string | null,
      parentProductGroupId: string,
      childProductGroupId: string,
      amount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      parentProductGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      childProductGroup:  {
        __typename: "TierIIProductGroup",
        id: string,
        owner?: string | null,
        name: string,
        groupType?: TierIIProductGroupGroupType | null,
        description: string,
        measurementUnits: string,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        childProducts?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        childProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductGroupProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductGroupProductGroup",
            id: string,
            owner?: string | null,
            parentProductGroupId: string,
            childProductGroupId: string,
            amount?: number | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTierIiReportQueryVariables = {
  id?: string,
};

export type GetTierIiReportQuery = {
  getTierIIReport?:  {
    __typename: "TierIIReport",
    id: string,
    owner?: string | null,
    facilityId: string,
    reportingYear: number,
    reportKind: TierIIReportKind,
    organizationStatus: TierIIReportOrganizationStatus,
    encampStatus: TierIIReportEncampStatus,
    staffAssignee?: string | null,
    metadata?:  {
      __typename: "TierIIMetadata",
      comments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      submissionMeasures?:  Array< {
        __typename: "TierIISubmissionMeasure",
        name: string,
        description?: string | null,
        startedAt?: string | null,
        completedAt?: string | null,
        records?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        notes?: string | null,
      } > | null,
      submissionRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
      tags?: string | null,
      untouchedSteps?: Array< string > | null,
    } | null,
    step3Prime?: string | null,
    customerComments?:  Array< {
      __typename: "TierIIComment",
      at: string,
      comment: string,
      commentedBy: string,
    } > | null,
    assignees?:  Array< {
      __typename: "TierIIPerson",
      id: string,
      first: string,
      last: string,
      title?: string | null,
      areas?: Array< string > | null,
      reconciledAt?: string | null,
    } > | null,
    basic?:  {
      __typename: "TierIIBasicInformation",
      companyName?: string | null,
      county?: string | null,
      dateClosed?: string | null,
      dbNumber?: string | null,
      department?: string | null,
      facilityName?: string | null,
      facilityPhone?: string | null,
      facilityPhone24Hour?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      isClosed?: boolean | null,
      isColocated?: boolean | null,
      isInactive?: boolean | null,
      colocationReportingFacilityId?: string | null,
      isMailingSameAsFacility?: boolean | null,
      isUnmanned?: boolean | null,
      latitude?: number | null,
      longitude?: number | null,
      lepc?: string | null,
      lepcId?: string | null,
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      maxNumOccupants?: number | null,
      municipality?: string | null,
      naicsCode?: number | null,
      reconciledAt?: string | null,
      streetAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
    } | null,
    regulatory?:  {
      __typename: "TierIIRegulatory",
      isSubjectToChemicalAccidentPrevention?: boolean | null,
      isSubjectToEmergencyPlanning?: boolean | null,
      isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
      reconciledAt?: string | null,
      rmpId?: string | null,
      triId?: string | null,
    } | null,
    facilityProductIds?: Array< string > | null,
    notReportingFacilityProductIds?: Array< string > | null,
    products?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    notReportingProducts?:  Array< {
      __typename: "TierIIFacilityProduct",
      id: string,
      facilityId: string,
      owner?: string | null,
      productId: string,
      product:  {
        __typename: "TierIIProduct",
        id: string,
        owner?: string | null,
        chemName: string,
        casNumber?: string | null,
        noCasNumber?: boolean | null,
        alternateId?: string | null,
        ehs?: boolean | null,
        pure?: boolean | null,
        mixture?: boolean | null,
        solid?: boolean | null,
        liquid?: boolean | null,
        gas?: boolean | null,
        isTradeSecret?: boolean | null,
        density?: number | null,
        densityUnits?: string | null,
        boilingPointFahr?: number | null,
        flashPointFahr?: number | null,
        vaporPressureMmhg?: number | null,
        solubility?: string | null,
        isBattery?: boolean | null,
        isFuel?: boolean | null,
        physicalHazards?: Array< string | null > | null,
        healthHazards?: Array< string | null > | null,
        oregonHazards?: Array< string | null > | null,
        ifcHazards?: Array< string | null > | null,
        noHazardsNotReporting?: boolean | null,
        components?:  Array< {
          __typename: "TierIIMixtureComponent",
          componentName?: string | null,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          ehs?: boolean | null,
          componentPercentage?: number | null,
          weightOrVolume?: string | null,
          ehsForm?: EhsForm | null,
        } | null > | null,
        sdsRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        stateFields?: string | null,
        ehsForm?: EhsForm | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        parentProductGroups?:  {
          __typename: "ModelTierIIProductProductGroupConnection",
          items?:  Array< {
            __typename: "TierIIProductProductGroup",
            id: string,
            owner?: string | null,
            productGroupId: string,
            productId: string,
            amount?: number | null,
            units?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      isOverThresholds?: boolean | null,
      isAlwaysReported?: boolean | null,
      maxAmount?: number | null,
      maxAmountCode?: number | null,
      averageAmount?: number | null,
      averageAmountCode?: number | null,
      maxAmountLargestContainer?: number | null,
      daysOnSite?: number | null,
      hasConfidentialStorageLocations?: boolean | null,
      unit?: string | null,
      locations?:  Array< {
        __typename: "TierIIStorageLocation",
        id: string,
        description?: string | null,
        storageType?: string | null,
        pressure?: string | null,
        temperature?: string | null,
        amount?: number | null,
        unit?: string | null,
        mapNumber?: string | null,
        gridNumber?: string | null,
        insideOutside?: string | null,
        storageBuilding?: string | null,
        storageFloor?: string | null,
        storageArea?: string | null,
        storageRoom?: string | null,
        storageQuadrant?: string | null,
      } | null > | null,
      stateFields?: string | null,
      aggregatedProductIds?: Array< string > | null,
      createdAt?: string | null,
      updatedAt: string,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    facilityContactIds?: Array< string > | null,
    contacts?:  Array< {
      __typename: "FacilityContact",
      id: string,
      owner?: string | null,
      facilityId: string,
      personId: string,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      areas?: Array< string > | null,
      reportingRoles?: Array< ContactReportRole > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facility:  {
        __typename: "Facility",
        id: string,
        owner?: string | null,
        name: string,
        status?: FacilityStatus | null,
        statusReason?: string | null,
        epaGeneratorStatus?: RcraGeneratorStatus | null,
        epaGeneratorStatusUpdatedAt?: string | null,
        epaGeneratorStatusUpdatedBy?: string | null,
        stateGeneratorStatus?: RcraGeneratorStatus | null,
        stateGeneratorStatusUpdatedAt?: string | null,
        stateGeneratorStatusUpdatedBy?: string | null,
        businessUnit?: string | null,
        customerFacilityId?: string | null,
        dbNumber?: string | null,
        facilityType?: string | null,
        naicsCode?: number | null,
        maxNumOccupants?: number | null,
        isUnmanned?: boolean | null,
        phone?: string | null,
        phone24Hour?: string | null,
        streetAddress:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        },
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        county?: string | null,
        municipality?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        isAutoGeocoded?: boolean | null,
        lepc?: string | null,
        lepcId?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        programAreas?: Array< string | null > | null,
        alternateIds?:  Array< {
          __typename: "FacilityAlternateId",
          kind: string,
          id: string,
          expiresAt?: string | null,
          recordKeys?: Array< string | null > | null,
        } > | null,
        userPermissions?:  Array< {
          __typename: "UserPermission",
          userTenantId: string,
          level: UserPermissionLevel,
        } | null > | null,
        dateOpened?: string | null,
        dateClosed?: string | null,
        dateInactive?: string | null,
        regulatoryInformation?:  {
          __typename: "FacilityRegulatoryInformation",
          isSubjectToChemicalAccidentPrevention?: boolean | null,
          isSubjectToEmergencyPlanning?: boolean | null,
          isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
          reconciledAt?: string | null,
          rmpId?: string | null,
          triId?: string | null,
        } | null,
        federalFields?: string | null,
        stateFields?: string | null,
        searchableProgramAreas?: string | null,
        searchableStatus?: string | null,
        searchableCity?: string | null,
        lastProductMeasuredAt?: string | null,
        customerProductComments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        streetAddressVerifiedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        contacts?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        credentials?:  {
          __typename: "ModelFacilityCredentialConnection",
          items?:  Array< {
            __typename: "FacilityCredential",
            id: string,
            owner?: string | null,
            facilityId: string,
            credentialId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        requirements?:  {
          __typename: "ModelFacilityRequirementConnection",
          items?:  Array< {
            __typename: "FacilityRequirement",
            id: string,
            owner?: string | null,
            facilityId: string,
            requirementId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } > | null,
    records?:  {
      __typename: "TierIIRecords",
      facilityRecords?:  Array< {
        __typename: "TierIIRecord",
        at: string,
        key: string,
        kind: TierIIRecordKind,
        name: string,
        associatedDocumentId?: string | null,
      } > | null,
    } | null,
    state?:  {
      __typename: "TierIIStateInformation",
      fields?: string | null,
    } | null,
    review?:  {
      __typename: "TierIIReview",
      reviewers?:  Array< {
        __typename: "TierIIReviewer",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        isComplete?: boolean | null,
      } > | null,
      notesToRegulators?: string | null,
      isNotReporting?: boolean | null,
    } | null,
    verify?:  {
      __typename: "TierIIVerify",
      verifier?:  {
        __typename: "TierIIVerifier",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
        fullName?: string | null,
      } | null,
      verifiedAt?: string | null,
      isVerified?: boolean | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    computed?:  {
      __typename: "ModelComputedConnection",
      items?:  Array< {
        __typename: "Computed",
        id: string,
        typeId: string,
        owner: string,
        kind: ComputationKind,
        typeName?: string | null,
        computed?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTierIiReportsQueryVariables = {
  filter?: ModelTierIIReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiReportsQuery = {
  listTierIIReports?:  {
    __typename: "ModelTierIIReportConnection",
    items?:  Array< {
      __typename: "TierIIReport",
      id: string,
      owner?: string | null,
      facilityId: string,
      reportingYear: number,
      reportKind: TierIIReportKind,
      organizationStatus: TierIIReportOrganizationStatus,
      encampStatus: TierIIReportEncampStatus,
      staffAssignee?: string | null,
      metadata?:  {
        __typename: "TierIIMetadata",
        comments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "TierIISubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        submissionRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        tags?: string | null,
        untouchedSteps?: Array< string > | null,
      } | null,
      step3Prime?: string | null,
      customerComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      assignees?:  Array< {
        __typename: "TierIIPerson",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
      } > | null,
      basic?:  {
        __typename: "TierIIBasicInformation",
        companyName?: string | null,
        county?: string | null,
        dateClosed?: string | null,
        dbNumber?: string | null,
        department?: string | null,
        facilityName?: string | null,
        facilityPhone?: string | null,
        facilityPhone24Hour?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        isClosed?: boolean | null,
        isColocated?: boolean | null,
        isInactive?: boolean | null,
        colocationReportingFacilityId?: string | null,
        isMailingSameAsFacility?: boolean | null,
        isUnmanned?: boolean | null,
        latitude?: number | null,
        longitude?: number | null,
        lepc?: string | null,
        lepcId?: string | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        maxNumOccupants?: number | null,
        municipality?: string | null,
        naicsCode?: number | null,
        reconciledAt?: string | null,
        streetAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
      } | null,
      regulatory?:  {
        __typename: "TierIIRegulatory",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      facilityProductIds?: Array< string > | null,
      notReportingFacilityProductIds?: Array< string > | null,
      products?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      notReportingProducts?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      facilityContactIds?: Array< string > | null,
      contacts?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      records?:  {
        __typename: "TierIIRecords",
        facilityRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
      } | null,
      state?:  {
        __typename: "TierIIStateInformation",
        fields?: string | null,
      } | null,
      review?:  {
        __typename: "TierIIReview",
        reviewers?:  Array< {
          __typename: "TierIIReviewer",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          isComplete?: boolean | null,
        } > | null,
        notesToRegulators?: string | null,
        isNotReporting?: boolean | null,
      } | null,
      verify?:  {
        __typename: "TierIIVerify",
        verifier?:  {
          __typename: "TierIIVerifier",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          fullName?: string | null,
        } | null,
        verifiedAt?: string | null,
        isVerified?: boolean | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      computed?:  {
        __typename: "ModelComputedConnection",
        items?:  Array< {
          __typename: "Computed",
          id: string,
          typeId: string,
          owner: string,
          kind: ComputationKind,
          typeName?: string | null,
          computed?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiReportsByFacilityQueryVariables = {
  owner?: string | null,
  facilityIdReportingYear?: ModelTierIIReportByFacilityCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiReportsByFacilityQuery = {
  listTierIIReportsByFacility?:  {
    __typename: "ModelTierIIReportConnection",
    items?:  Array< {
      __typename: "TierIIReport",
      id: string,
      owner?: string | null,
      facilityId: string,
      reportingYear: number,
      reportKind: TierIIReportKind,
      organizationStatus: TierIIReportOrganizationStatus,
      encampStatus: TierIIReportEncampStatus,
      staffAssignee?: string | null,
      metadata?:  {
        __typename: "TierIIMetadata",
        comments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "TierIISubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        submissionRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        tags?: string | null,
        untouchedSteps?: Array< string > | null,
      } | null,
      step3Prime?: string | null,
      customerComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      assignees?:  Array< {
        __typename: "TierIIPerson",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
      } > | null,
      basic?:  {
        __typename: "TierIIBasicInformation",
        companyName?: string | null,
        county?: string | null,
        dateClosed?: string | null,
        dbNumber?: string | null,
        department?: string | null,
        facilityName?: string | null,
        facilityPhone?: string | null,
        facilityPhone24Hour?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        isClosed?: boolean | null,
        isColocated?: boolean | null,
        isInactive?: boolean | null,
        colocationReportingFacilityId?: string | null,
        isMailingSameAsFacility?: boolean | null,
        isUnmanned?: boolean | null,
        latitude?: number | null,
        longitude?: number | null,
        lepc?: string | null,
        lepcId?: string | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        maxNumOccupants?: number | null,
        municipality?: string | null,
        naicsCode?: number | null,
        reconciledAt?: string | null,
        streetAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
      } | null,
      regulatory?:  {
        __typename: "TierIIRegulatory",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      facilityProductIds?: Array< string > | null,
      notReportingFacilityProductIds?: Array< string > | null,
      products?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      notReportingProducts?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      facilityContactIds?: Array< string > | null,
      contacts?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      records?:  {
        __typename: "TierIIRecords",
        facilityRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
      } | null,
      state?:  {
        __typename: "TierIIStateInformation",
        fields?: string | null,
      } | null,
      review?:  {
        __typename: "TierIIReview",
        reviewers?:  Array< {
          __typename: "TierIIReviewer",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          isComplete?: boolean | null,
        } > | null,
        notesToRegulators?: string | null,
        isNotReporting?: boolean | null,
      } | null,
      verify?:  {
        __typename: "TierIIVerify",
        verifier?:  {
          __typename: "TierIIVerifier",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          fullName?: string | null,
        } | null,
        verifiedAt?: string | null,
        isVerified?: boolean | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      computed?:  {
        __typename: "ModelComputedConnection",
        items?:  Array< {
          __typename: "Computed",
          id: string,
          typeId: string,
          owner: string,
          kind: ComputationKind,
          typeName?: string | null,
          computed?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiReportsByFacilityIdQueryVariables = {
  facilityId?: string | null,
  reportingYear?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiReportsByFacilityIdQuery = {
  listTierIIReportsByFacilityId?:  {
    __typename: "ModelTierIIReportConnection",
    items?:  Array< {
      __typename: "TierIIReport",
      id: string,
      owner?: string | null,
      facilityId: string,
      reportingYear: number,
      reportKind: TierIIReportKind,
      organizationStatus: TierIIReportOrganizationStatus,
      encampStatus: TierIIReportEncampStatus,
      staffAssignee?: string | null,
      metadata?:  {
        __typename: "TierIIMetadata",
        comments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "TierIISubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        submissionRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        tags?: string | null,
        untouchedSteps?: Array< string > | null,
      } | null,
      step3Prime?: string | null,
      customerComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      assignees?:  Array< {
        __typename: "TierIIPerson",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
      } > | null,
      basic?:  {
        __typename: "TierIIBasicInformation",
        companyName?: string | null,
        county?: string | null,
        dateClosed?: string | null,
        dbNumber?: string | null,
        department?: string | null,
        facilityName?: string | null,
        facilityPhone?: string | null,
        facilityPhone24Hour?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        isClosed?: boolean | null,
        isColocated?: boolean | null,
        isInactive?: boolean | null,
        colocationReportingFacilityId?: string | null,
        isMailingSameAsFacility?: boolean | null,
        isUnmanned?: boolean | null,
        latitude?: number | null,
        longitude?: number | null,
        lepc?: string | null,
        lepcId?: string | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        maxNumOccupants?: number | null,
        municipality?: string | null,
        naicsCode?: number | null,
        reconciledAt?: string | null,
        streetAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
      } | null,
      regulatory?:  {
        __typename: "TierIIRegulatory",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      facilityProductIds?: Array< string > | null,
      notReportingFacilityProductIds?: Array< string > | null,
      products?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      notReportingProducts?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      facilityContactIds?: Array< string > | null,
      contacts?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      records?:  {
        __typename: "TierIIRecords",
        facilityRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
      } | null,
      state?:  {
        __typename: "TierIIStateInformation",
        fields?: string | null,
      } | null,
      review?:  {
        __typename: "TierIIReview",
        reviewers?:  Array< {
          __typename: "TierIIReviewer",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          isComplete?: boolean | null,
        } > | null,
        notesToRegulators?: string | null,
        isNotReporting?: boolean | null,
      } | null,
      verify?:  {
        __typename: "TierIIVerify",
        verifier?:  {
          __typename: "TierIIVerifier",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          fullName?: string | null,
        } | null,
        verifiedAt?: string | null,
        isVerified?: boolean | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      computed?:  {
        __typename: "ModelComputedConnection",
        items?:  Array< {
          __typename: "Computed",
          id: string,
          typeId: string,
          owner: string,
          kind: ComputationKind,
          typeName?: string | null,
          computed?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiReportsByYearQueryVariables = {
  owner?: string | null,
  reportingYearFacilityId?: ModelTierIIReportByYearCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiReportsByYearQuery = {
  listTierIIReportsByYear?:  {
    __typename: "ModelTierIIReportConnection",
    items?:  Array< {
      __typename: "TierIIReport",
      id: string,
      owner?: string | null,
      facilityId: string,
      reportingYear: number,
      reportKind: TierIIReportKind,
      organizationStatus: TierIIReportOrganizationStatus,
      encampStatus: TierIIReportEncampStatus,
      staffAssignee?: string | null,
      metadata?:  {
        __typename: "TierIIMetadata",
        comments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "TierIISubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        submissionRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        tags?: string | null,
        untouchedSteps?: Array< string > | null,
      } | null,
      step3Prime?: string | null,
      customerComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      assignees?:  Array< {
        __typename: "TierIIPerson",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
      } > | null,
      basic?:  {
        __typename: "TierIIBasicInformation",
        companyName?: string | null,
        county?: string | null,
        dateClosed?: string | null,
        dbNumber?: string | null,
        department?: string | null,
        facilityName?: string | null,
        facilityPhone?: string | null,
        facilityPhone24Hour?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        isClosed?: boolean | null,
        isColocated?: boolean | null,
        isInactive?: boolean | null,
        colocationReportingFacilityId?: string | null,
        isMailingSameAsFacility?: boolean | null,
        isUnmanned?: boolean | null,
        latitude?: number | null,
        longitude?: number | null,
        lepc?: string | null,
        lepcId?: string | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        maxNumOccupants?: number | null,
        municipality?: string | null,
        naicsCode?: number | null,
        reconciledAt?: string | null,
        streetAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
      } | null,
      regulatory?:  {
        __typename: "TierIIRegulatory",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      facilityProductIds?: Array< string > | null,
      notReportingFacilityProductIds?: Array< string > | null,
      products?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      notReportingProducts?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      facilityContactIds?: Array< string > | null,
      contacts?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      records?:  {
        __typename: "TierIIRecords",
        facilityRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
      } | null,
      state?:  {
        __typename: "TierIIStateInformation",
        fields?: string | null,
      } | null,
      review?:  {
        __typename: "TierIIReview",
        reviewers?:  Array< {
          __typename: "TierIIReviewer",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          isComplete?: boolean | null,
        } > | null,
        notesToRegulators?: string | null,
        isNotReporting?: boolean | null,
      } | null,
      verify?:  {
        __typename: "TierIIVerify",
        verifier?:  {
          __typename: "TierIIVerifier",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          fullName?: string | null,
        } | null,
        verifiedAt?: string | null,
        isVerified?: boolean | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      computed?:  {
        __typename: "ModelComputedConnection",
        items?:  Array< {
          __typename: "Computed",
          id: string,
          typeId: string,
          owner: string,
          kind: ComputationKind,
          typeName?: string | null,
          computed?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiReportsByYearAndEncampStatusQueryVariables = {
  reportingYear?: number | null,
  encampStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiReportsByYearAndEncampStatusQuery = {
  listTierIIReportsByYearAndEncampStatus?:  {
    __typename: "ModelTierIIReportConnection",
    items?:  Array< {
      __typename: "TierIIReport",
      id: string,
      owner?: string | null,
      facilityId: string,
      reportingYear: number,
      reportKind: TierIIReportKind,
      organizationStatus: TierIIReportOrganizationStatus,
      encampStatus: TierIIReportEncampStatus,
      staffAssignee?: string | null,
      metadata?:  {
        __typename: "TierIIMetadata",
        comments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "TierIISubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        submissionRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        tags?: string | null,
        untouchedSteps?: Array< string > | null,
      } | null,
      step3Prime?: string | null,
      customerComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      assignees?:  Array< {
        __typename: "TierIIPerson",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
      } > | null,
      basic?:  {
        __typename: "TierIIBasicInformation",
        companyName?: string | null,
        county?: string | null,
        dateClosed?: string | null,
        dbNumber?: string | null,
        department?: string | null,
        facilityName?: string | null,
        facilityPhone?: string | null,
        facilityPhone24Hour?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        isClosed?: boolean | null,
        isColocated?: boolean | null,
        isInactive?: boolean | null,
        colocationReportingFacilityId?: string | null,
        isMailingSameAsFacility?: boolean | null,
        isUnmanned?: boolean | null,
        latitude?: number | null,
        longitude?: number | null,
        lepc?: string | null,
        lepcId?: string | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        maxNumOccupants?: number | null,
        municipality?: string | null,
        naicsCode?: number | null,
        reconciledAt?: string | null,
        streetAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
      } | null,
      regulatory?:  {
        __typename: "TierIIRegulatory",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      facilityProductIds?: Array< string > | null,
      notReportingFacilityProductIds?: Array< string > | null,
      products?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      notReportingProducts?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      facilityContactIds?: Array< string > | null,
      contacts?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      records?:  {
        __typename: "TierIIRecords",
        facilityRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
      } | null,
      state?:  {
        __typename: "TierIIStateInformation",
        fields?: string | null,
      } | null,
      review?:  {
        __typename: "TierIIReview",
        reviewers?:  Array< {
          __typename: "TierIIReviewer",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          isComplete?: boolean | null,
        } > | null,
        notesToRegulators?: string | null,
        isNotReporting?: boolean | null,
      } | null,
      verify?:  {
        __typename: "TierIIVerify",
        verifier?:  {
          __typename: "TierIIVerifier",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          fullName?: string | null,
        } | null,
        verifiedAt?: string | null,
        isVerified?: boolean | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      computed?:  {
        __typename: "ModelComputedConnection",
        items?:  Array< {
          __typename: "Computed",
          id: string,
          typeId: string,
          owner: string,
          kind: ComputationKind,
          typeName?: string | null,
          computed?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTierIiReportsByStaffAssigneeQueryVariables = {
  staffAssignee?: string | null,
  owner?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTierIIReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTierIiReportsByStaffAssigneeQuery = {
  listTierIIReportsByStaffAssignee?:  {
    __typename: "ModelTierIIReportConnection",
    items?:  Array< {
      __typename: "TierIIReport",
      id: string,
      owner?: string | null,
      facilityId: string,
      reportingYear: number,
      reportKind: TierIIReportKind,
      organizationStatus: TierIIReportOrganizationStatus,
      encampStatus: TierIIReportEncampStatus,
      staffAssignee?: string | null,
      metadata?:  {
        __typename: "TierIIMetadata",
        comments?:  Array< {
          __typename: "TierIIComment",
          at: string,
          comment: string,
          commentedBy: string,
        } > | null,
        submissionMeasures?:  Array< {
          __typename: "TierIISubmissionMeasure",
          name: string,
          description?: string | null,
          startedAt?: string | null,
          completedAt?: string | null,
          records?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          notes?: string | null,
        } > | null,
        submissionRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
        tags?: string | null,
        untouchedSteps?: Array< string > | null,
      } | null,
      step3Prime?: string | null,
      customerComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      assignees?:  Array< {
        __typename: "TierIIPerson",
        id: string,
        first: string,
        last: string,
        title?: string | null,
        areas?: Array< string > | null,
        reconciledAt?: string | null,
      } > | null,
      basic?:  {
        __typename: "TierIIBasicInformation",
        companyName?: string | null,
        county?: string | null,
        dateClosed?: string | null,
        dbNumber?: string | null,
        department?: string | null,
        facilityName?: string | null,
        facilityPhone?: string | null,
        facilityPhone24Hour?: string | null,
        fireDistrict?: string | null,
        fireDistrictId?: string | null,
        isClosed?: boolean | null,
        isColocated?: boolean | null,
        isInactive?: boolean | null,
        colocationReportingFacilityId?: string | null,
        isMailingSameAsFacility?: boolean | null,
        isUnmanned?: boolean | null,
        latitude?: number | null,
        longitude?: number | null,
        lepc?: string | null,
        lepcId?: string | null,
        mailingAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        maxNumOccupants?: number | null,
        municipality?: string | null,
        naicsCode?: number | null,
        reconciledAt?: string | null,
        streetAddress?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
      } | null,
      regulatory?:  {
        __typename: "TierIIRegulatory",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      facilityProductIds?: Array< string > | null,
      notReportingFacilityProductIds?: Array< string > | null,
      products?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      notReportingProducts?:  Array< {
        __typename: "TierIIFacilityProduct",
        id: string,
        facilityId: string,
        owner?: string | null,
        productId: string,
        product:  {
          __typename: "TierIIProduct",
          id: string,
          owner?: string | null,
          chemName: string,
          casNumber?: string | null,
          noCasNumber?: boolean | null,
          alternateId?: string | null,
          ehs?: boolean | null,
          pure?: boolean | null,
          mixture?: boolean | null,
          solid?: boolean | null,
          liquid?: boolean | null,
          gas?: boolean | null,
          isTradeSecret?: boolean | null,
          density?: number | null,
          densityUnits?: string | null,
          boilingPointFahr?: number | null,
          flashPointFahr?: number | null,
          vaporPressureMmhg?: number | null,
          solubility?: string | null,
          isBattery?: boolean | null,
          isFuel?: boolean | null,
          physicalHazards?: Array< string | null > | null,
          healthHazards?: Array< string | null > | null,
          oregonHazards?: Array< string | null > | null,
          ifcHazards?: Array< string | null > | null,
          noHazardsNotReporting?: boolean | null,
          components?:  Array< {
            __typename: "TierIIMixtureComponent",
            componentName?: string | null,
            casNumber?: string | null,
            noCasNumber?: boolean | null,
            ehs?: boolean | null,
            componentPercentage?: number | null,
            weightOrVolume?: string | null,
            ehsForm?: EhsForm | null,
          } | null > | null,
          sdsRecords?:  Array< {
            __typename: "TierIIRecord",
            at: string,
            key: string,
            kind: TierIIRecordKind,
            name: string,
            associatedDocumentId?: string | null,
          } > | null,
          stateFields?: string | null,
          ehsForm?: EhsForm | null,
          createdAt?: string | null,
          updatedAt: string,
          updatedBy?: string | null,
          parentProductGroups?:  {
            __typename: "ModelTierIIProductProductGroupConnection",
            nextToken?: string | null,
          } | null,
        },
        isOverThresholds?: boolean | null,
        isAlwaysReported?: boolean | null,
        maxAmount?: number | null,
        maxAmountCode?: number | null,
        averageAmount?: number | null,
        averageAmountCode?: number | null,
        maxAmountLargestContainer?: number | null,
        daysOnSite?: number | null,
        hasConfidentialStorageLocations?: boolean | null,
        unit?: string | null,
        locations?:  Array< {
          __typename: "TierIIStorageLocation",
          id: string,
          description?: string | null,
          storageType?: string | null,
          pressure?: string | null,
          temperature?: string | null,
          amount?: number | null,
          unit?: string | null,
          mapNumber?: string | null,
          gridNumber?: string | null,
          insideOutside?: string | null,
          storageBuilding?: string | null,
          storageFloor?: string | null,
          storageArea?: string | null,
          storageRoom?: string | null,
          storageQuadrant?: string | null,
        } | null > | null,
        stateFields?: string | null,
        aggregatedProductIds?: Array< string > | null,
        createdAt?: string | null,
        updatedAt: string,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      facilityContactIds?: Array< string > | null,
      contacts?:  Array< {
        __typename: "FacilityContact",
        id: string,
        owner?: string | null,
        facilityId: string,
        personId: string,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        areas?: Array< string > | null,
        reportingRoles?: Array< ContactReportRole > | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } > | null,
      records?:  {
        __typename: "TierIIRecords",
        facilityRecords?:  Array< {
          __typename: "TierIIRecord",
          at: string,
          key: string,
          kind: TierIIRecordKind,
          name: string,
          associatedDocumentId?: string | null,
        } > | null,
      } | null,
      state?:  {
        __typename: "TierIIStateInformation",
        fields?: string | null,
      } | null,
      review?:  {
        __typename: "TierIIReview",
        reviewers?:  Array< {
          __typename: "TierIIReviewer",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          isComplete?: boolean | null,
        } > | null,
        notesToRegulators?: string | null,
        isNotReporting?: boolean | null,
      } | null,
      verify?:  {
        __typename: "TierIIVerify",
        verifier?:  {
          __typename: "TierIIVerifier",
          id: string,
          first: string,
          last: string,
          title?: string | null,
          areas?: Array< string > | null,
          reconciledAt?: string | null,
          fullName?: string | null,
        } | null,
        verifiedAt?: string | null,
        isVerified?: boolean | null,
      } | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      computed?:  {
        __typename: "ModelComputedConnection",
        items?:  Array< {
          __typename: "Computed",
          id: string,
          typeId: string,
          owner: string,
          kind: ComputationKind,
          typeName?: string | null,
          computed?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserActivityLogQueryVariables = {
  id?: string,
};

export type GetUserActivityLogQuery = {
  getUserActivityLog?:  {
    __typename: "UserActivityLog",
    id: string,
    owner: string,
    cognitoUserId: string,
    userTenantId: string,
    type: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    updatedBy: string,
  } | null,
};

export type ListUserActivityLogsQueryVariables = {
  filter?: ModelUserActivityLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActivityLogsQuery = {
  listUserActivityLogs?:  {
    __typename: "ModelUserActivityLogConnection",
    items?:  Array< {
      __typename: "UserActivityLog",
      id: string,
      owner: string,
      cognitoUserId: string,
      userTenantId: string,
      type: string,
      name: string,
      description: string,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUserActivityLogByUserTenantQueryVariables = {
  userTenantId?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActivityLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActivityLogByUserTenantQuery = {
  listUserActivityLogByUserTenant?:  {
    __typename: "ModelUserActivityLogConnection",
    items?:  Array< {
      __typename: "UserActivityLog",
      id: string,
      owner: string,
      cognitoUserId: string,
      userTenantId: string,
      type: string,
      name: string,
      description: string,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserAuthQueryVariables = {
  id?: string,
};

export type GetUserAuthQuery = {
  getUserAuth?:  {
    __typename: "UserAuth",
    id: string,
    owner: string,
    userId: string,
    userTenantId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListUserAuthsQueryVariables = {
  filter?: ModelUserAuthFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserAuthsQuery = {
  listUserAuths?:  {
    __typename: "ModelUserAuthConnection",
    items?:  Array< {
      __typename: "UserAuth",
      id: string,
      owner: string,
      userId: string,
      userTenantId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUserAuthByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAuthFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserAuthByUserIdQuery = {
  listUserAuthByUserId?:  {
    __typename: "ModelUserAuthConnection",
    items?:  Array< {
      __typename: "UserAuth",
      id: string,
      owner: string,
      userId: string,
      userTenantId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUserAuthByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAuthFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserAuthByOwnerQuery = {
  listUserAuthByOwner?:  {
    __typename: "ModelUserAuthConnection",
    items?:  Array< {
      __typename: "UserAuth",
      id: string,
      owner: string,
      userId: string,
      userTenantId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUserAuthByUserTenantIdQueryVariables = {
  userTenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAuthFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserAuthByUserTenantIdQuery = {
  listUserAuthByUserTenantId?:  {
    __typename: "ModelUserAuthConnection",
    items?:  Array< {
      __typename: "UserAuth",
      id: string,
      owner: string,
      userId: string,
      userTenantId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserTenantQueryVariables = {
  id?: string,
};

export type GetUserTenantQuery = {
  getUserTenant?:  {
    __typename: "UserTenant",
    id: string,
    tenantId: string,
    email: string,
    groups?: Array< string > | null,
    invitedAt?: string | null,
    invitationExpiresAt?: string | null,
    status: UserTenantStatus,
    personId: string,
    isDisabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    person:  {
      __typename: "Person",
      id: string,
      owner?: string | null,
      first?: string | null,
      last?: string | null,
      email?: string | null,
      title?: string | null,
      company?: string | null,
      address?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      phones:  Array< {
        __typename: "Phone",
        type: PhoneType,
        reportTypes?: Array< ReportType > | null,
        number: string,
      } >,
      contactType?: ContactType | null,
      searchableProgramAreas?: string | null,
      searchableFacilities?: string | null,
      searchableContactTypes?: string | null,
      userTenantId?: string | null,
      paymentMethodId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      facilities?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      userTenant?:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    },
    tenant:  {
      __typename: "Tenant",
      id: string,
      name: string,
      partnerTenantId?: string | null,
      salesforceId?: string | null,
      accountType?: AccountType | null,
      isDemo?: boolean | null,
      isPartner?: boolean | null,
      isDisabled?: boolean | null,
      externalId?: string | null,
      ssoConfig?:  {
        __typename: "SsoConfig",
        mappingName: string,
        emailDomains: Array< string >,
      } | null,
      roleIdToDefaultForNewUsers?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      users?:  {
        __typename: "ModelUserTenantConnection",
        items?:  Array< {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
    roles?:  {
      __typename: "ModelUserTenantRoleConnection",
      items?:  Array< {
        __typename: "UserTenantRole",
        id: string,
        owner: string,
        userTenantId: string,
        roleId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        role:  {
          __typename: "Role",
          id: string,
          owner: string,
          name: string,
          rolePermissions?: Array< string > | null,
          roleType: RoleTypeEnum,
          createdAt: string,
          updatedAt: string,
          updatedBy: string,
        },
        userTenant:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUserTenantsQueryVariables = {
  filter?: ModelUserTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserTenantsQuery = {
  listUserTenants?:  {
    __typename: "ModelUserTenantConnection",
    items?:  Array< {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByTenantQueryVariables = {
  tenantId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByTenantQuery = {
  listUsersByTenant?:  {
    __typename: "ModelUserTenantConnection",
    items?:  Array< {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUserTenantsByPersonIdQueryVariables = {
  personId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserTenantsByPersonIdQuery = {
  listUserTenantsByPersonId?:  {
    __typename: "ModelUserTenantConnection",
    items?:  Array< {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserTenantRoleQueryVariables = {
  id?: string,
};

export type GetUserTenantRoleQuery = {
  getUserTenantRole?:  {
    __typename: "UserTenantRole",
    id: string,
    owner: string,
    userTenantId: string,
    roleId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    role:  {
      __typename: "Role",
      id: string,
      owner: string,
      name: string,
      rolePermissions?: Array< string > | null,
      roleType: RoleTypeEnum,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
    },
    userTenant:  {
      __typename: "UserTenant",
      id: string,
      tenantId: string,
      email: string,
      groups?: Array< string > | null,
      invitedAt?: string | null,
      invitationExpiresAt?: string | null,
      status: UserTenantStatus,
      personId: string,
      isDisabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      person:  {
        __typename: "Person",
        id: string,
        owner?: string | null,
        first?: string | null,
        last?: string | null,
        email?: string | null,
        title?: string | null,
        company?: string | null,
        address?:  {
          __typename: "Address",
          streetAddress?: string | null,
          streetAddressLine2?: string | null,
          city?: string | null,
          zip?: string | null,
          state?: string | null,
          country?: string | null,
        } | null,
        state?: string | null,
        phones:  Array< {
          __typename: "Phone",
          type: PhoneType,
          reportTypes?: Array< ReportType > | null,
          number: string,
        } >,
        contactType?: ContactType | null,
        searchableProgramAreas?: string | null,
        searchableFacilities?: string | null,
        searchableContactTypes?: string | null,
        userTenantId?: string | null,
        paymentMethodId?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facilities?:  {
          __typename: "ModelFacilityContactConnection",
          items?:  Array< {
            __typename: "FacilityContact",
            id: string,
            owner?: string | null,
            facilityId: string,
            personId: string,
            areas?: Array< string > | null,
            reportingRoles?: Array< ContactReportRole > | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        userTenant?:  {
          __typename: "UserTenant",
          id: string,
          tenantId: string,
          email: string,
          groups?: Array< string > | null,
          invitedAt?: string | null,
          invitationExpiresAt?: string | null,
          status: UserTenantStatus,
          personId: string,
          isDisabled?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          tenant:  {
            __typename: "Tenant",
            id: string,
            name: string,
            partnerTenantId?: string | null,
            salesforceId?: string | null,
            accountType?: AccountType | null,
            isDemo?: boolean | null,
            isPartner?: boolean | null,
            isDisabled?: boolean | null,
            externalId?: string | null,
            roleIdToDefaultForNewUsers?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          roles?:  {
            __typename: "ModelUserTenantRoleConnection",
            nextToken?: string | null,
          } | null,
        } | null,
      },
      tenant:  {
        __typename: "Tenant",
        id: string,
        name: string,
        partnerTenantId?: string | null,
        salesforceId?: string | null,
        accountType?: AccountType | null,
        isDemo?: boolean | null,
        isPartner?: boolean | null,
        isDisabled?: boolean | null,
        externalId?: string | null,
        ssoConfig?:  {
          __typename: "SsoConfig",
          mappingName: string,
          emailDomains: Array< string >,
        } | null,
        roleIdToDefaultForNewUsers?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        users?:  {
          __typename: "ModelUserTenantConnection",
          items?:  Array< {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
      roles?:  {
        __typename: "ModelUserTenantRoleConnection",
        items?:  Array< {
          __typename: "UserTenantRole",
          id: string,
          owner: string,
          userTenantId: string,
          roleId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          role:  {
            __typename: "Role",
            id: string,
            owner: string,
            name: string,
            rolePermissions?: Array< string > | null,
            roleType: RoleTypeEnum,
            createdAt: string,
            updatedAt: string,
            updatedBy: string,
          },
          userTenant:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListUserTenantRolesQueryVariables = {
  filter?: ModelUserTenantRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserTenantRolesQuery = {
  listUserTenantRoles?:  {
    __typename: "ModelUserTenantRoleConnection",
    items?:  Array< {
      __typename: "UserTenantRole",
      id: string,
      owner: string,
      userTenantId: string,
      roleId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      role:  {
        __typename: "Role",
        id: string,
        owner: string,
        name: string,
        rolePermissions?: Array< string > | null,
        roleType: RoleTypeEnum,
        createdAt: string,
        updatedAt: string,
        updatedBy: string,
      },
      userTenant:  {
        __typename: "UserTenant",
        id: string,
        tenantId: string,
        email: string,
        groups?: Array< string > | null,
        invitedAt?: string | null,
        invitationExpiresAt?: string | null,
        status: UserTenantStatus,
        personId: string,
        isDisabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        person:  {
          __typename: "Person",
          id: string,
          owner?: string | null,
          first?: string | null,
          last?: string | null,
          email?: string | null,
          title?: string | null,
          company?: string | null,
          address?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          phones:  Array< {
            __typename: "Phone",
            type: PhoneType,
            reportTypes?: Array< ReportType > | null,
            number: string,
          } >,
          contactType?: ContactType | null,
          searchableProgramAreas?: string | null,
          searchableFacilities?: string | null,
          searchableContactTypes?: string | null,
          userTenantId?: string | null,
          paymentMethodId?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facilities?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          userTenant?:  {
            __typename: "UserTenant",
            id: string,
            tenantId: string,
            email: string,
            groups?: Array< string > | null,
            invitedAt?: string | null,
            invitationExpiresAt?: string | null,
            status: UserTenantStatus,
            personId: string,
            isDisabled?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
        },
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          partnerTenantId?: string | null,
          salesforceId?: string | null,
          accountType?: AccountType | null,
          isDemo?: boolean | null,
          isPartner?: boolean | null,
          isDisabled?: boolean | null,
          externalId?: string | null,
          ssoConfig?:  {
            __typename: "SsoConfig",
            mappingName: string,
            emailDomains: Array< string >,
          } | null,
          roleIdToDefaultForNewUsers?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          users?:  {
            __typename: "ModelUserTenantConnection",
            nextToken?: string | null,
          } | null,
        },
        roles?:  {
          __typename: "ModelUserTenantRoleConnection",
          items?:  Array< {
            __typename: "UserTenantRole",
            id: string,
            owner: string,
            userTenantId: string,
            roleId: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      },
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnPublishDocumentSubscriptionVariables = {
  processId?: string,
};

export type OnPublishDocumentSubscription = {
  onPublishDocument?:  {
    __typename: "PublishedDocument",
    processId: string,
    url?: string | null,
    errorMsg?: string | null,
  } | null,
};

export type OnPublishExceptionReportSubscriptionVariables = {
  processId?: string,
};

export type OnPublishExceptionReportSubscription = {
  onPublishExceptionReport?:  {
    __typename: "PublishedExceptionReport",
    processId: string,
    key?: string | null,
    errorMsg?: string | null,
  } | null,
};

export type OnPushNotificationSubscriptionVariables = {
  owner?: string | null,
  facilityId?: string | null,
  personId?: string | null,
};

export type OnPushNotificationSubscription = {
  onPushNotification?:  {
    __typename: "Notification",
    id: string,
    owner?: string | null,
    notificationType: NotificationType,
    parentId?: string | null,
    personId?: string | null,
    readAt?: string | null,
    lifecycleStatus: NotificationLifecycleStatus,
    scheduledFor: string,
    activeAt?: string | null,
    archivedAt?: string | null,
    name: string,
    description?: string | null,
    emailParams?:  {
      __typename: "SendEmailParams",
      from?: string | null,
      to?: string | null,
      subject?: string | null,
      replyTo?: string | null,
      text?: string | null,
      linkText?: string | null,
      hyperlink?: string | null,
    } | null,
    eventContent?:  {
      __typename: "NotificationEventContent",
      area?: string | null,
      deadline?: string | null,
      seriesId?: string | null,
      type?: EventSequenceType | null,
    } | null,
    rcraStatusChangeContent?:  {
      __typename: "RcraStatusChangeContent",
      generatorStatus?: RcraGeneratorStatus | null,
      changedFromManifest?: boolean | null,
    } | null,
    sdsExtractionContent?:  {
      __typename: "SdsExtractionContent",
      productId?: string | null,
      chemName?: string | null,
    } | null,
    facilityId?: string | null,
    notificationTypeSearchable?: string | null,
    lifecycleStatusSearchable?: string | null,
    areaSearchable?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    facility?:  {
      __typename: "Facility",
      id: string,
      owner?: string | null,
      name: string,
      status?: FacilityStatus | null,
      statusReason?: string | null,
      epaGeneratorStatus?: RcraGeneratorStatus | null,
      epaGeneratorStatusUpdatedAt?: string | null,
      epaGeneratorStatusUpdatedBy?: string | null,
      stateGeneratorStatus?: RcraGeneratorStatus | null,
      stateGeneratorStatusUpdatedAt?: string | null,
      stateGeneratorStatusUpdatedBy?: string | null,
      businessUnit?: string | null,
      customerFacilityId?: string | null,
      dbNumber?: string | null,
      facilityType?: string | null,
      naicsCode?: number | null,
      maxNumOccupants?: number | null,
      isUnmanned?: boolean | null,
      phone?: string | null,
      phone24Hour?: string | null,
      streetAddress:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      },
      mailingAddress?:  {
        __typename: "Address",
        streetAddress?: string | null,
        streetAddressLine2?: string | null,
        city?: string | null,
        zip?: string | null,
        state?: string | null,
        country?: string | null,
      } | null,
      state?: string | null,
      county?: string | null,
      municipality?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      isAutoGeocoded?: boolean | null,
      lepc?: string | null,
      lepcId?: string | null,
      fireDistrict?: string | null,
      fireDistrictId?: string | null,
      programAreas?: Array< string | null > | null,
      alternateIds?:  Array< {
        __typename: "FacilityAlternateId",
        kind: string,
        id: string,
        expiresAt?: string | null,
        recordKeys?: Array< string | null > | null,
      } > | null,
      userPermissions?:  Array< {
        __typename: "UserPermission",
        userTenantId: string,
        level: UserPermissionLevel,
      } | null > | null,
      dateOpened?: string | null,
      dateClosed?: string | null,
      dateInactive?: string | null,
      regulatoryInformation?:  {
        __typename: "FacilityRegulatoryInformation",
        isSubjectToChemicalAccidentPrevention?: boolean | null,
        isSubjectToEmergencyPlanning?: boolean | null,
        isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
        reconciledAt?: string | null,
        rmpId?: string | null,
        triId?: string | null,
      } | null,
      federalFields?: string | null,
      stateFields?: string | null,
      searchableProgramAreas?: string | null,
      searchableStatus?: string | null,
      searchableCity?: string | null,
      lastProductMeasuredAt?: string | null,
      customerProductComments?:  Array< {
        __typename: "TierIIComment",
        at: string,
        comment: string,
        commentedBy: string,
      } > | null,
      streetAddressVerifiedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      contacts?:  {
        __typename: "ModelFacilityContactConnection",
        items?:  Array< {
          __typename: "FacilityContact",
          id: string,
          owner?: string | null,
          facilityId: string,
          personId: string,
          person:  {
            __typename: "Person",
            id: string,
            owner?: string | null,
            first?: string | null,
            last?: string | null,
            email?: string | null,
            title?: string | null,
            company?: string | null,
            state?: string | null,
            contactType?: ContactType | null,
            searchableProgramAreas?: string | null,
            searchableFacilities?: string | null,
            searchableContactTypes?: string | null,
            userTenantId?: string | null,
            paymentMethodId?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          areas?: Array< string > | null,
          reportingRoles?: Array< ContactReportRole > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      credentials?:  {
        __typename: "ModelFacilityCredentialConnection",
        items?:  Array< {
          __typename: "FacilityCredential",
          id: string,
          owner?: string | null,
          facilityId: string,
          credentialId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          credential:  {
            __typename: "Credential",
            id: string,
            owner?: string | null,
            name: string,
            username: string,
            password: string,
            kind: CredentialKind,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
            verificationStatus?: CredentialVerificationStatus | null,
            lastVerificationSuccessAt?: string | null,
            lastVerificationCheckAt?: string | null,
          },
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
      requirements?:  {
        __typename: "ModelFacilityRequirementConnection",
        items?:  Array< {
          __typename: "FacilityRequirement",
          id: string,
          owner?: string | null,
          facilityId: string,
          requirementId: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
          requirement:  {
            __typename: "Requirement",
            id: string,
            owner?: string | null,
            status?: RequirementStatus | null,
            name: string,
            description?: string | null,
            agencyId: string,
            area: string,
            areaSearchable: string,
            jurisdictionalAgency?: string | null,
            intervalInWords?: string | null,
            parentStatute?: ParentStatute | null,
            jurisdictionalState?: string | null,
            jurisdictionalCounties?: Array< string | null > | null,
            title?: string | null,
            jurisdictionalTownship?: string | null,
            jurisdictionalTribalArea?: string | null,
            jurisdictionalCity?: string | null,
            formExpires?: string | null,
            fillableLinks?: Array< string | null > | null,
            instructionFileName?: string | null,
            instruction?: string | null,
            department?: string | null,
            subDepartment?: string | null,
            contactEmail?: string | null,
            contactPhone?: string | null,
            contactPhoneExtension?: number | null,
            isExtensionAvailable?: boolean | null,
            extensionNotes?: string | null,
            penalty?: string | null,
            encampInstructions?: string | null,
            isAlwaysApplicable?: boolean | null,
            agencyLevelSearchable?: string | null,
            agencyStateSearchable?: string | null,
            userAgencyTitle?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          },
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
    facilities?:  {
      __typename: "ModelNotificationFacilityLinkConnection",
      items?:  Array< {
        __typename: "NotificationFacilityLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        facilityId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        facility:  {
          __typename: "Facility",
          id: string,
          owner?: string | null,
          name: string,
          status?: FacilityStatus | null,
          statusReason?: string | null,
          epaGeneratorStatus?: RcraGeneratorStatus | null,
          epaGeneratorStatusUpdatedAt?: string | null,
          epaGeneratorStatusUpdatedBy?: string | null,
          stateGeneratorStatus?: RcraGeneratorStatus | null,
          stateGeneratorStatusUpdatedAt?: string | null,
          stateGeneratorStatusUpdatedBy?: string | null,
          businessUnit?: string | null,
          customerFacilityId?: string | null,
          dbNumber?: string | null,
          facilityType?: string | null,
          naicsCode?: number | null,
          maxNumOccupants?: number | null,
          isUnmanned?: boolean | null,
          phone?: string | null,
          phone24Hour?: string | null,
          streetAddress:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          },
          mailingAddress?:  {
            __typename: "Address",
            streetAddress?: string | null,
            streetAddressLine2?: string | null,
            city?: string | null,
            zip?: string | null,
            state?: string | null,
            country?: string | null,
          } | null,
          state?: string | null,
          county?: string | null,
          municipality?: string | null,
          latitude?: number | null,
          longitude?: number | null,
          isAutoGeocoded?: boolean | null,
          lepc?: string | null,
          lepcId?: string | null,
          fireDistrict?: string | null,
          fireDistrictId?: string | null,
          programAreas?: Array< string | null > | null,
          alternateIds?:  Array< {
            __typename: "FacilityAlternateId",
            kind: string,
            id: string,
            expiresAt?: string | null,
            recordKeys?: Array< string | null > | null,
          } > | null,
          userPermissions?:  Array< {
            __typename: "UserPermission",
            userTenantId: string,
            level: UserPermissionLevel,
          } | null > | null,
          dateOpened?: string | null,
          dateClosed?: string | null,
          dateInactive?: string | null,
          regulatoryInformation?:  {
            __typename: "FacilityRegulatoryInformation",
            isSubjectToChemicalAccidentPrevention?: boolean | null,
            isSubjectToEmergencyPlanning?: boolean | null,
            isSubjectToToxicsReleaseInventoryReporting?: boolean | null,
            reconciledAt?: string | null,
            rmpId?: string | null,
            triId?: string | null,
          } | null,
          federalFields?: string | null,
          stateFields?: string | null,
          searchableProgramAreas?: string | null,
          searchableStatus?: string | null,
          searchableCity?: string | null,
          lastProductMeasuredAt?: string | null,
          customerProductComments?:  Array< {
            __typename: "TierIIComment",
            at: string,
            comment: string,
            commentedBy: string,
          } > | null,
          streetAddressVerifiedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          contacts?:  {
            __typename: "ModelFacilityContactConnection",
            nextToken?: string | null,
          } | null,
          credentials?:  {
            __typename: "ModelFacilityCredentialConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
    requirements?:  {
      __typename: "ModelNotificationRequirementLinkConnection",
      items?:  Array< {
        __typename: "NotificationRequirementLink",
        id: string,
        owner?: string | null,
        notificationType: NotificationType,
        notificationId: string,
        requirementId: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
        notification:  {
          __typename: "Notification",
          id: string,
          owner?: string | null,
          notificationType: NotificationType,
          parentId?: string | null,
          personId?: string | null,
          readAt?: string | null,
          lifecycleStatus: NotificationLifecycleStatus,
          scheduledFor: string,
          activeAt?: string | null,
          archivedAt?: string | null,
          name: string,
          description?: string | null,
          emailParams?:  {
            __typename: "SendEmailParams",
            from?: string | null,
            to?: string | null,
            subject?: string | null,
            replyTo?: string | null,
            text?: string | null,
            linkText?: string | null,
            hyperlink?: string | null,
          } | null,
          eventContent?:  {
            __typename: "NotificationEventContent",
            area?: string | null,
            deadline?: string | null,
            seriesId?: string | null,
            type?: EventSequenceType | null,
          } | null,
          rcraStatusChangeContent?:  {
            __typename: "RcraStatusChangeContent",
            generatorStatus?: RcraGeneratorStatus | null,
            changedFromManifest?: boolean | null,
          } | null,
          sdsExtractionContent?:  {
            __typename: "SdsExtractionContent",
            productId?: string | null,
            chemName?: string | null,
          } | null,
          facilityId?: string | null,
          notificationTypeSearchable?: string | null,
          lifecycleStatusSearchable?: string | null,
          areaSearchable?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          facility?:  {
            __typename: "Facility",
            id: string,
            owner?: string | null,
            name: string,
            status?: FacilityStatus | null,
            statusReason?: string | null,
            epaGeneratorStatus?: RcraGeneratorStatus | null,
            epaGeneratorStatusUpdatedAt?: string | null,
            epaGeneratorStatusUpdatedBy?: string | null,
            stateGeneratorStatus?: RcraGeneratorStatus | null,
            stateGeneratorStatusUpdatedAt?: string | null,
            stateGeneratorStatusUpdatedBy?: string | null,
            businessUnit?: string | null,
            customerFacilityId?: string | null,
            dbNumber?: string | null,
            facilityType?: string | null,
            naicsCode?: number | null,
            maxNumOccupants?: number | null,
            isUnmanned?: boolean | null,
            phone?: string | null,
            phone24Hour?: string | null,
            state?: string | null,
            county?: string | null,
            municipality?: string | null,
            latitude?: number | null,
            longitude?: number | null,
            isAutoGeocoded?: boolean | null,
            lepc?: string | null,
            lepcId?: string | null,
            fireDistrict?: string | null,
            fireDistrictId?: string | null,
            programAreas?: Array< string | null > | null,
            dateOpened?: string | null,
            dateClosed?: string | null,
            dateInactive?: string | null,
            federalFields?: string | null,
            stateFields?: string | null,
            searchableProgramAreas?: string | null,
            searchableStatus?: string | null,
            searchableCity?: string | null,
            lastProductMeasuredAt?: string | null,
            streetAddressVerifiedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelNotificationFacilityLinkConnection",
            nextToken?: string | null,
          } | null,
          requirements?:  {
            __typename: "ModelNotificationRequirementLinkConnection",
            nextToken?: string | null,
          } | null,
        },
        requirement:  {
          __typename: "Requirement",
          id: string,
          owner?: string | null,
          status?: RequirementStatus | null,
          name: string,
          description?: string | null,
          frequency?:  {
            __typename: "RepeatRule",
            frequency: Frequency,
            interval: number,
            start?: string | null,
          } | null,
          agencyId: string,
          citations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          area: string,
          areaSearchable: string,
          jurisdictionalAgency?: string | null,
          intervalInWords?: string | null,
          parentStatute?: ParentStatute | null,
          jurisdictionalState?: string | null,
          jurisdictionalCounties?: Array< string | null > | null,
          title?: string | null,
          portals?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          jurisdictionalTownship?: string | null,
          jurisdictionalTribalArea?: string | null,
          jurisdictionalCity?: string | null,
          formExpires?: string | null,
          forms?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          fillableLinks?: Array< string | null > | null,
          otherLinks?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          instructionFileName?: string | null,
          instruction?: string | null,
          extraDocumentations?:  Array< {
            __typename: "Link",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          department?: string | null,
          subDepartment?: string | null,
          contactEmail?: string | null,
          contactPhone?: string | null,
          contactPhoneExtension?: number | null,
          isExtensionAvailable?: boolean | null,
          extensionNotes?: string | null,
          penalty?: string | null,
          encampInstructions?: string | null,
          isAlwaysApplicable?: boolean | null,
          agencyLevelSearchable?: string | null,
          agencyStateSearchable?: string | null,
          userAgencyTitle?: string | null,
          tasks?:  Array< {
            __typename: "Task",
            isCompleted: boolean,
            text: string,
          } | null > | null,
          hyperlinks?:  Array< {
            __typename: "HyperLink",
            name?: string | null,
            link?: string | null,
          } | null > | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
          agency?:  {
            __typename: "Agency",
            id: string,
            owner?: string | null,
            name: string,
            level: AgencyLevel,
            jurisdiction: string,
            state?: StateOrTerritory | null,
            locale?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            updatedBy?: string | null,
          } | null,
          documents?:  {
            __typename: "ModelDocumentRequirementConnection",
            nextToken?: string | null,
          } | null,
          facilities?:  {
            __typename: "ModelFacilityRequirementConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnPublishCreateMetatronFacilitySubscriptionVariables = {
  processId?: string,
};

export type OnPublishCreateMetatronFacilitySubscription = {
  onPublishCreateMetatronFacility?:  {
    __typename: "PublishedCreateMetatronFacility",
    processId: string,
    isError?: boolean | null,
  } | null,
};

export type OnPublishMergeContactsSubscriptionVariables = {
  processId?: string,
};

export type OnPublishMergeContactsSubscription = {
  onPublishMergeContacts?:  {
    __typename: "PublishedMergeContactsResponse",
    processId: string,
    errorMsg?: string | null,
  } | null,
};

export type OnPublishOneSchemaImportSubscriptionVariables = {
  processId?: string,
};

export type OnPublishOneSchemaImportSubscription = {
  onPublishOneSchemaImport?:  {
    __typename: "PublishedOneSchemaImportResponse",
    processId: string,
    errorMsg?: string | null,
  } | null,
};
