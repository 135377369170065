import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  Chemical,
  ChemicalComponent,
  Maybe,
  PureOrMixture,
} from "generated-graphql/graphql";

type Component = Pick<
  ChemicalComponent,
  "name" | "componentPercentage" | "casNumber" | "isEhs"
>;

export const formatChemicalName = (
  chemical: Pick<Chemical, "name" | "casNumber" | "pureOrMixture"> & {
    components?: Maybe<Array<Component>>;
  }
) => {
  const mixtureDetails =
    chemical.pureOrMixture === PureOrMixture.Mixture
      ? chemical.components
          // NOTE: We are supposed to chose the largest EHS component:
          // I have *chosen* to drive via the db's `component.isEhs`
          // if that flag is wrong, we will manifest it to the user elsewhere
          ?.filter((c) => c.isEhs)
          .reduce((acc: Component | null, component) => {
            return (component.componentPercentage ?? 0) >
              (acc?.componentPercentage ?? 0)
              ? component
              : acc;
          }, null)
      : null;

  const mixtureDisplay = mixtureDetails
    ? `${mixtureDetails.componentPercentage}% ${mixtureDetails.name}`
    : "";
  const effectiveCas = !!chemical.casNumber ? chemical.casNumber : "N/A";

  return `${chemical.name} (${effectiveCas}, ${prettyPrintEnumValue(
    chemical.pureOrMixture
  )}${mixtureDisplay ? `, ${mixtureDisplay}` : ""})`;
};
