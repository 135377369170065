import { Outlet, useMatch } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/useBreadcrumbs";
import { OrganizationTable } from "./OrganizationTable";

export function Implementation() {
  useBreadcrumb({
    label: "Organizations",
    to: "/staff/implementation",
  });

  const match = useMatch("/staff/implementation");

  if (!match) {
    return <Outlet />;
  }

  return <OrganizationTable />;
}
