import { gql } from "generated-graphql";

export const FACILITY_PRODUCT_FORM_GET_FACILITY_PRODUCT = gql(`
  query GetFacilityProduct($id: ID!) {
    facilityProduct(id: $id) {
      id
      reportingYear
      maxQuantity
      averageQuantity
      daysOnSite
      hasConfidentialStorageLocations
      facilityId
      productId
      product {
        id
        name
        productChemicals {
          id
          chemical {
            id
            name
          }
          amount
          unit
          storageType
          storageTypeDescription
          pressure
          temperature
        }
      }
      storageLocations {
        id
        maxQuantity
        storageLocation {
          ...StorageLocationPicker
        }
      }
      issues {
        ...issue
      }
    }
  }
`);

export const FACILITY_PRODUCT_PREVIOUS_YEAR_VALUES = gql(`
  query GetFacilityProductPreviousYearValues($facilityId: ID!, $productId: ID!, $reportingYear: Int!) {
    facilityProductByYear(facilityId: $facilityId, productId: $productId, reportingYear: $reportingYear) {
      id
      reportingYear
      maxQuantity
      averageQuantity
      daysOnSite
    }
  }
`);

export const FACILITY_PRODUCT_FORM_SAVE_FACILITY_PRODUCT = gql(`
  mutation SaveFacilityProduct($id: ID $input: FacilityProductInput!) {
    upsertFacilityProduct(id: $id, input: $input) {
      id
    }
  }
`);

export const FACILITY_CHEMICAL_MEASUREMENTS_DETAILS = gql(`
  query FacilityChemicalMeasurementsDetails($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilityChemicalMeasurements(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        amount
        unit
        pressure
        otherPressureValue
        temperature
        measuredAtUtc
        storageType
        storageTypeDescription
        chemicalId
        chemical {
        ...ChemicalPicker
        alternateId
        }
        storageLocationId
        storageLocation {
            ...StorageLocationPicker
        }
        issueCount
      }
      count
    }
  }
`);

export const FACILITY_PRODUCT_MEASUREMENT = gql(/** GraphQL */ `
  query FacilityProductMeasurements ($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilityProductMeasurements(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        measuredAtUtc
        quantity
        storageLocationId
        storageLocation {
          ...StorageLocationPicker
        }
        issues {
          ...issue
        }
      }
      count
    }
  }
`);
