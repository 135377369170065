import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGridPremiumProps,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { Dialog } from "components/Dialog";
import { SaveButton } from "components/SaveButton";
import { Facility, GetPersonFacilitiesQuery } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import pluralize from "pluralize";
import { useState } from "react";

type Row = Pick<Facility, "id" | "name" | "streetAddress1">;

export const ConfirmPersonChangesModal = ({
  personFullName,
  facilities,
  facilityCount,
  saving,
  onClose,
  onConfirm,
  ...props
}: {
  personFullName: string;
  facilities: Row[];
  facilityCount?: number;
  saving?: boolean;
  onClose: () => void;
  onConfirm: () => void;
} & Omit<DataGridPremiumProps<Row>, "columns" | "rows">) => {
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = usePaginationModel(5);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  const totalFacilityCount = facilityCount ?? facilities.length;

  const columns: GridColDef<
    NonNullable<GetPersonFacilitiesQuery["personFacilities"]["items"]>[0]
  >[] = [
    {
      field: "name",
      headerName: "Facility",
      valueGetter: ({ row }) => row.name,
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      valueGetter: ({ row }) => `${row.city}, ${row.state}`,
      flex: 0.75,
    },
  ];

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Confirm Changes</DialogTitle>
      <DialogContent>
        <Stack spacing={theme.spacing(1)}>
          <Typography
            sx={{ mb: theme.spacing(1) }}
            fontWeight={theme.typography.fontWeightMedium}
          >
            '{personFullName}' is currently associated with {totalFacilityCount}{" "}
            {pluralize("facility", totalFacilityCount)}. This contact's
            information will be changed for all current and future reports.
            Previously submitted reports{" "}
            <Typography
              fontWeight={theme.typography.fontWeightMedium}
              component="span"
              sx={{ fontStyle: "italic" }}
            >
              will not be affected.
            </Typography>
          </Typography>
          {facilities.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.text.secondary }}
              >
                The following {totalFacilityCount}{" "}
                {pluralize("facility", totalFacilityCount)} will be affected:
              </Typography>
              <DataGrid
                rowCount={totalFacilityCount}
                pagination
                paginationMode="client"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sortingMode="client"
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                isRowSelectable={() => false}
                pageSizeOptions={[5, 10]}
                sx={{
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "190px",
                  },
                }}
                {...props}
                columns={columns}
                rows={facilities}
              />
            </>
          )}
        </Stack>
        <Typography
          sx={{ mt: theme.spacing(2) }}
          fontWeight={theme.typography.fontWeightMedium}
        >
          Are you sure you want to make changes to this contact?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <SaveButton
          saveText="Yes I'm Sure"
          loading={!!saving}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
