import { Grid, useTheme } from "@mui/material";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  FacilityChemicalStorageLocationInput,
  InsideOutside,
  StorageQuadrant,
} from "generated-graphql/graphql";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useReport } from "../../Report/useReport";
import { useFacility } from "../../../../Customer/Facility/useFacility";
import { FormSelect } from "../../../../../components/Forms/FormSelect";
import { FormTextField } from "../../../../../components/Forms/FormTextField";
import { StorageLocationPicker } from "../../../../../components/StorageLocationPicker";

gql(`
  fragment ReportingFacilityChemicalStorageLocationForm on ReportingFacilityChemicalStorageLocation {
    id
    maxAmount
    storageLocationId
    pressure
    otherPressureValue
    storageType
    storageTypeDescription
    temperature
    unit
    storageLocation {
      ...StorageLocationPicker
    }
  }
`);

gql(`
  fragment FacilityChemicalStorageLocationForm on FacilityChemicalStorageLocation {
    id
    maxAmount
    storageLocationId
    pressure
    otherPressureValue
    storageType
    storageTypeDescription
    temperature
    unit
    storageLocation {
      ...StorageLocationPicker
    }
  }
`);

type StorageLocationFormProps = {
  facilityId: string;
  disabled?: boolean;
};

export const StorageLocationForm: React.FC<StorageLocationFormProps> = ({
  facilityId,
  disabled,
}) => {
  const theme = useTheme();
  const { data: reportData } = useReport();
  const { data: facilityData } = useFacility();
  const facilityState =
    facilityData?.facility?.state ?? reportData?.tierIIReport.facility.state;

  const { control, setValue } =
    useFormContext<FacilityChemicalStorageLocationInput>();

  const isOregonFacility = facilityState === "OR";
  const isCaliforniaFacility = facilityState === "CA";

  return (
    <Grid
      container
      spacing={theme.spacing(2)}
      sx={{ marginTop: theme.spacing(1) }}
    >
      <Grid item xs={12}>
        <Controller
          control={control}
          name="storageLocation.description"
          render={({ field, fieldState }) => (
            <>
              <StorageLocationPicker
                error={fieldState.error}
                facilityId={facilityId}
                disabled={disabled}
                description={field.value}
                required
                onSelectLocation={(location) => {
                  setValue(
                    "storageLocation",
                    { ...location, description: location.description ?? "" },
                    {
                      shouldValidate: true,
                    }
                  );
                }}
              />
            </>
          )}
        />
        {isOregonFacility && (
          <>
            <Grid item xs={12}>
              <FormSelect
                name="storageLocation.OR_insideOutside"
                label="Inside/Outside *"
                selectItems={Object.values(InsideOutside).map(
                  (insideOutside) => ({
                    display: prettyPrintEnumValue(insideOutside),
                    value: insideOutside,
                  })
                )}
                control={control}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Storage Building *"
                name="storageLocation.OR_storageBuilding"
                control={control}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Storage Floor"
                name="storageLocation.OR_storageFloor"
                control={control}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                name="storageLocation.OR_storageQuadrant"
                label="Storage Quadrant *"
                selectItems={Object.values(StorageQuadrant).map(
                  (storageQuadrant) => ({
                    display: prettyPrintEnumValue(storageQuadrant),
                    value: storageQuadrant,
                  })
                )}
                control={control}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Storage Room"
                name="storageLocation.OR_storageRoom"
                control={control}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Storage Area *"
                name="storageLocation.OR_storageArea"
                control={control}
                disabled={disabled}
              />
            </Grid>
          </>
        )}
        {isCaliforniaFacility && (
          <>
            <Grid item xs={12}>
              <FormTextField
                label="Grid Number"
                name="storageLocation.CA_gridNumber"
                control={control}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Map Number"
                name="storageLocation.CA_mapNumber"
                control={control}
                disabled={disabled}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
