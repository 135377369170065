import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useTenant } from "hooks/useTenant";
import { Suspense } from "react";
import { FacilityDetailsTile } from "./FacilityDetailsTile";
import { GeneratorStatusTile } from "./GeneratorStatusTile";
import { WasteFacilitySkeleton } from "./WasteFacilitySkeleton";
import { WasteGenerationTile } from "./WasteGeneration/WasteGenerationTile";
import { WasteStreamsTile } from "./WasteStreamsTile";

export const WasteFacility = () => {
  const { tenantId } = useTenant();
  useBreadcrumb([
    {
      label: "Waste",
      to: tenantId ? `/o/${tenantId}/waste` : undefined,
    },
    {
      label: "Facilities",
      to: tenantId ? `/o/${tenantId}/waste/facilities` : undefined,
    },
  ]);

  return (
    <DashboardLayout>
      <Suspense fallback={<WasteFacilitySkeleton />}>
        <FacilityDetailsTile />
        <GeneratorStatusTile />
        <WasteGenerationTile />
        <WasteStreamsTile />
      </Suspense>
    </DashboardLayout>
  );
};
