import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { FacilityProductStorageLocationForm } from "./FacilityProductStorageLocationForm";
import { IssueListButton } from "components/Forms/IssueListButton";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { CatalogLink } from "components/CatalogLink";
import { OverallProductStorageForm } from "components/OverallStorage/OverallProductStorageForm";
import { ProductPicker } from "components/ProductPicker";
import { SaveButton } from "components/SaveButton";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";
import {
  FacilityProductInput,
  FacilityProductStorageLocationInput,
} from "generated-graphql/graphql";
import { useTenant } from "hooks/useTenant";
import { useValidatingForm } from "hooks/useValidatingForm";
import { omit } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import invariant from "tiny-invariant";
import { hasCriticalIssues } from "util/forms";
import { v4 as uuid } from "uuid";
import { useFacilityProductInputValidation } from "../../Report/validationHooks/useFacilityProductInputValidation";
import { FacilityProductStorageLocations } from "./FacilityProductStorageLocations";
import {
  FACILITY_PRODUCT_FORM_GET_FACILITY_PRODUCT,
  FACILITY_PRODUCT_FORM_SAVE_FACILITY_PRODUCT,
} from "./schema";

type FormState = FacilityProductInput & {
  id?: string;
};

export const FacilityProductForm = ({
  facilityProductId,
  facilityId,
  reportingYear,
  onClose,
}: {
  facilityProductId: string;
  facilityId: string;
  reportingYear: number;
  onClose: () => void;
}) => {
  invariant(facilityProductId);
  invariant(facilityId);

  const { tenant, loading: tenantLoading } = useTenant();
  const theme = useTheme();

  const isNewProduct = facilityProductId === "new";

  const [dialog, setDialog] = useState<"storage" | "delete" | null>(null);
  const [selectedStorageLocation, setSelectedStorageLocation] =
    useState<FacilityProductStorageLocationInput | null>(null);

  const handleEditStorageLocation = useCallback(
    (storageLocation: FacilityProductStorageLocationInput) => {
      setSelectedStorageLocation(storageLocation);
      setDialog("storage");
    },
    []
  );

  const handleStorageLocationDialogClose = useCallback(() => {
    setSelectedStorageLocation(null);
    setDialog(null);
  }, []);

  const showNewStorageLocationDialog = useCallback(() => {
    setSelectedStorageLocation(null);
    setDialog("storage");
  }, []);

  const alerts = useAlerts();
  const { data, loading, error } = useQuery(
    FACILITY_PRODUCT_FORM_GET_FACILITY_PRODUCT,
    {
      skip: isNewProduct,
      variables: {
        id: facilityProductId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const defaultValues: FormState = useMemo(() => {
    const facilityProduct = data?.facilityProduct;

    return {
      id: data?.facilityProduct?.id ?? undefined,
      facilityId,
      productId: facilityProduct?.productId ?? null,
      maxQuantity: facilityProduct?.maxQuantity ?? 0,
      averageQuantity: facilityProduct?.averageQuantity ?? 0,
      daysOnSite: facilityProduct?.daysOnSite ?? 0,
      hasConfidentialStorageLocations:
        facilityProduct?.hasConfidentialStorageLocations ?? false,
      reportingYear: facilityProduct?.reportingYear ?? reportingYear,
      storageLocations:
        (facilityProduct?.storageLocations &&
          facilityProduct.storageLocations.map((storageLocation) => {
            return {
              ...storageLocation,
              storageLocation: {
                ...storageLocation.storageLocation,
                id: storageLocation.storageLocation?.id ?? "",
                description: storageLocation.storageLocation?.description ?? "",
                facilityId: storageLocation.storageLocation?.facilityId ?? "",
              },
            };
          })) ??
        [],
    };
  }, [data?.facilityProduct, facilityId, reportingYear]);

  const { reset, formState, control, handleSubmit, issues, ...validatingForm } =
    useValidatingForm<FormState>(
      loading ? undefined : defaultValues,
      data?.facilityProduct?.issues ?? [],
      useFacilityProductInputValidation()
    );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      onClose();
    }
  }, [formState.isSubmitSuccessful, onClose]);

  const {
    fields: storageLocations,
    append,
    update,
    remove,
  } = useFieldArray<FormState>({
    control,
    name: "storageLocations",
  });

  const storageLocationsWithIssues = storageLocations.map((storageLocation) => {
    const matchingIssues = issues.filter((issue) => {
      if (issue.modelId === storageLocation.storageLocation.id) {
        return true;
      }
      return false;
    });

    return {
      ...storageLocation,
      issues: [matchingIssues],
      issueCount: matchingIssues.length,
    };
  });

  const selectedStorageLocationIndex = useMemo(
    () =>
      storageLocations.findIndex(
        (storageLocation) => storageLocation.id === selectedStorageLocation?.id
      ),
    [storageLocations, selectedStorageLocation]
  );

  const handleStorageLocationSave = useCallback(
    (storageLocation: FacilityProductStorageLocationInput) => {
      if (
        selectedStorageLocationIndex == null ||
        selectedStorageLocationIndex === -1
      ) {
        append({
          ...storageLocation,
          id: uuid(),
        });
      } else {
        update(selectedStorageLocationIndex, storageLocation);
      }
      handleStorageLocationDialogClose();
    },
    [
      append,
      update,
      selectedStorageLocationIndex,
      handleStorageLocationDialogClose,
    ]
  );

  const [saveFacilityProduct, { loading: saving }] = useMutation(
    FACILITY_PRODUCT_FORM_SAVE_FACILITY_PRODUCT,
    {
      refetchQueries: [
        "InventoryFacilityProducts",
        "InventoryOverview",
        "ReportingFacilityChemicals",
        "GetKivaInventory",
        "FacilityProducts",
        "GetReportDetails",
      ],
    }
  );

  const onSubmit = useCallback(
    async (input: FormState) => {
      try {
        await saveFacilityProduct({
          variables: {
            id: data?.facilityProduct?.id,
            input: omit(input, "id"),
          },
        });
        alerts.success("Successfully saved facility product.");
        onClose();
      } catch (err) {
        alerts.error(`Error saving facility product ${facilityProductId}`, err);
      }
    },
    [
      alerts,
      saveFacilityProduct,
      onClose,
      facilityProductId,
      data?.facilityProduct?.id,
    ]
  );

  const handleDeleteStorageLocationClose = useCallback(() => {
    setSelectedStorageLocation(null);
    setDialog(null);
  }, []);

  const handleRemoveStorageLocation = useCallback(
    (row: any) => {
      setSelectedStorageLocation(
        storageLocations.find(
          (storageLocation) => storageLocation.id === row.id
        ) ?? null
      );
      setDialog("delete");
    },
    [storageLocations]
  );

  const handleRemoveStorageLocationConfirm = useCallback(() => {
    remove(selectedStorageLocationIndex);
    setDialog(null);
  }, [selectedStorageLocationIndex, remove]);

  const hasPendingChanges = formState.isDirty && !formState.isSubmitSuccessful;

  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const onCancel = useCallback(() => {
    // If there are pending changes, verify the user wants to close the dialog
    if (hasPendingChanges) {
      setShowConfirmClose(true);
      return;
    }

    onClose();
  }, [onClose, hasPendingChanges]);

  const isLoading = loading || tenantLoading;

  if (error) return null;

  return (
    <>
      <Dialog
        open={!!facilityProductId}
        onClose={onCancel}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider
          reset={reset}
          control={control}
          formState={formState}
          handleSubmit={handleSubmit}
          {...validatingForm}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
              {facilityProductId === "new" ? "Add" : "Edit"} Product Inventory
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={theme.spacing(2)}>
                <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
                  {isLoading ? (
                    <Skeleton variant="rounded" height="5rem" />
                  ) : (
                    <Controller
                      name="productId"
                      control={control}
                      render={({ field, fieldState }) => (
                        <ProductPicker
                          {...field}
                          {...fieldState}
                          value={data?.facilityProduct?.product}
                          defaultSearchTerm={`tenantId:${tenant?.id}`}
                          onChange={(product) =>
                            validatingForm.setValue("productId", product?.id, {
                              shouldValidate: true,
                            })
                          }
                          required
                          facilityId={facilityId}
                          reportingYear={reportingYear}
                          isOptionDisabled={(product) =>
                            !!product.facilityProducts?.length
                          }
                        />
                      )}
                    />
                  )}
                </Grid>
                {loading ? (
                  <Grid item xs={6}>
                    <Stack spacing={theme.spacing(2)}>
                      <Skeleton variant="text" height="80px" />
                      <Skeleton variant="text" height="80px" />
                      <Skeleton variant="text" height="80px" />
                    </Stack>
                  </Grid>
                ) : (
                  // Max/min quantities and days on site
                  <OverallProductStorageForm
                    facilityId={facilityId}
                    reportingYear={reportingYear}
                  />
                )}
                <Grid item xs={6} spacing={theme.spacing(4)}>
                  {isLoading ? (
                    <>
                      <Skeleton variant="rounded" height="346px" />
                      <Skeleton variant="text" height="65px" sx={{ mt: 1 }} />
                    </>
                  ) : (
                    <>
                      <FacilityProductStorageLocations
                        storageLocations={storageLocationsWithIssues}
                        showDialog={showNewStorageLocationDialog}
                        handleEditStorageLocation={handleEditStorageLocation}
                        handleDeleteStorageLocation={
                          handleRemoveStorageLocation
                        }
                      />
                      <RadioGroupField
                        sx={{ mt: theme.spacing(2) }}
                        label="Has confidential storage locations?"
                        radioOptions={[
                          { value: true, label: "Yes" },
                          { value: false, label: "No" },
                        ]}
                        name="hasConfidentialStorageLocations"
                        control={control}
                      />
                    </>
                  )}
                </Grid>
                <Grid xs={12} sx={{ paddingLeft: theme.spacing(2) }}>
                  {isLoading ? (
                    <Skeleton variant="rounded" height="5rem" />
                  ) : (
                    <>
                      {storageLocations.length === 0 && (
                        <Typography color={"rgb(211, 47, 47)"}>
                          * At least one Storage Location is required
                        </Typography>
                      )}
                      <CatalogLink
                        id={data?.facilityProduct?.product?.id ?? ""}
                        issues={issues}
                        type="Product"
                        name={data?.facilityProduct?.product?.name ?? ""}
                      />
                      {(
                        data?.facilityProduct?.product?.productChemicals ?? []
                      ).map((pc, idx) => {
                        return (
                          <CatalogLink
                            key={idx}
                            id={pc.chemical.id}
                            type="Chemical"
                            name={pc.chemical.name}
                            issues={issues}
                          />
                        );
                      })}
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <IssueListButton issues={issues} />
              <FormControl>
                <Button
                  variant="outlined"
                  disabled={loading}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </FormControl>
              <FormControl>
                <SaveButton
                  loading={loading || saving}
                  disabled={hasCriticalIssues(issues)}
                />
              </FormControl>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      <Dialog
        open={dialog === "storage"}
        onClose={handleStorageLocationDialogClose}
        fullWidth
      >
        <DialogTitle>
          {selectedStorageLocation == null && "Add Storage Location"}
          {selectedStorageLocation != null && "Edit Storage Location"}
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <FacilityProductStorageLocationForm
            onSave={handleStorageLocationSave}
            storageLocation={selectedStorageLocation}
            handleClose={handleStorageLocationDialogClose}
            facilityId={facilityId}
            isEditMode={selectedStorageLocation !== null}
          />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={dialog === "delete"}
        onClose={handleDeleteStorageLocationClose}
        onConfirm={handleRemoveStorageLocationConfirm}
        msg={`Are you sure you want to remove storage location ${selectedStorageLocation?.storageLocation?.description} from this facility product?`}
      />
      <UnfinishedChangesPrompt when={hasPendingChanges} />
      <ConfirmDialog
        open={showConfirmClose}
        onConfirm={onClose}
        onClose={() => setShowConfirmClose(false)}
        title="Are you sure you want to leave?"
        msg={"You have unsaved changes."}
      />
    </>
  );
};
