import { Box, Stack } from "@mui/material";
import {
  ActivityStatus,
  ActivityType,
  ExecutionPlanType,
  GetTierIiReportQuery,
} from "generated-graphql/graphql";
import { SendToErrorTriage } from "./SendToErrorTriage";
import { CompleteActivity } from "./CompleteActivity";
import { SetToManual } from "./SetToManual";
import { Requeue } from "./Requeue";

type ActivityBottomToolbarProps = {
  activity: NonNullable<
    GetTierIiReportQuery["tierIIReport"]["currentWorkflow"]
  >["activities"][0];
};

export function ActivityBottomToolbar({
  activity,
}: ActivityBottomToolbarProps) {
  // At this point, we are enabling completion whenever an
  // activity is considered "current", that is, it is not
  // in these statuses
  const isCurrent = ![
    ActivityStatus.NotStarted,
    ActivityStatus.StaffCompleted,
    ActivityStatus.Completed,
  ].includes(activity?.status);

  const canRequeue = activity?.status === ActivityStatus.Error;
  const skipResumeDialog = activity?.executionPlan === ExecutionPlanType.Human;

  return (
    <Stack flexDirection="row" justifyContent="space-between">
      <Stack columnGap={1} flexDirection="row">
        <SetToManual activity={activity} />
        {canRequeue && (
          <Requeue activity={activity} showWarningPanel={!skipResumeDialog} />
        )}
        <SendToErrorTriage activity={activity} />
      </Stack>
      <Box>
        {isCurrent && (
          <CompleteActivity
            activity={activity}
            showWarningPanel={
              activity?.status === ActivityStatus.Error ||
              activity.executionPlan === ExecutionPlanType.Automation
            }
          />
        )}
      </Box>
    </Stack>
  );
}

export type ActivityButtonProps = {
  activity: ActivityBottomToolbarProps["activity"];
};
