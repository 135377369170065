import {
  Box,
  Button,
  FormControl,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Issue } from "generated-graphql/graphql";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { IssueListButton } from "./Forms/IssueListButton";
import { SaveButton } from "./SaveButton";

export function FloatingSaveBar({
  saving = false,
  shadow = true,
  notification,
  sx = {},
  onSaveClick = () => {},
  onCancel,
  saveText,
  saveStartIcon,
  saveDisabled,
  hideCancel,
  hideSave,
  leftChildren,
  issues,
  saveTooltipTitle,
  formId,
}: {
  saving?: boolean;
  shadow?: boolean;
  notification?: string;
  sx?: SxProps;
  onSaveClick?: () => void;
  onCancel?: () => void;
  saveText?: string;
  saveStartIcon?: ReactNode;
  saveDisabled?: boolean;
  hideCancel?: boolean;
  hideSave?: boolean;
  leftChildren?: React.ReactNode;
  issues?: Issue[];
  saveTooltipTitle?: string;
  formId?: string;
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        gap: 1,
        position: "sticky",
        bottom: 0,
        paddingY: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(3),
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(-3),
        marginLeft: theme.spacing(-3),
        zIndex: 999,
        backgroundColor: theme.palette.background.paper,
        boxShadow: shadow ? "0 0px 2px rgba(0,0,0,0.05)" : "unset",
        display: "flex",
        justifyContent: leftChildren ? "space-between" : "flex-end",
        transition: "box-shadow 0.3s ease-in-out",
        borderRadius: "0 0 4px 4px",
        ...sx,
      }}
    >
      {leftChildren}
      <Box sx={{ display: "flex", gap: 1 }}>
        {notification && (
          <Typography paddingRight={theme.spacing(1)} alignSelf="center">
            {notification}
          </Typography>
        )}
        {issues && <IssueListButton issues={issues} />}
        {!hideCancel && (
          <Button
            variant="outlined"
            onClick={() =>
              onCancel
                ? onCancel()
                : window.history.length === 1
                ? navigate("..")
                : navigate(-1)
            }
          >
            Cancel
          </Button>
        )}
        <FormControl sx={{ marginLeft: theme.spacing(1) }}>
          {!hideSave && (
            <Tooltip title={saveTooltipTitle}>
              <span>
                <SaveButton
                  loading={saving}
                  onClick={onSaveClick}
                  saveText={saveText}
                  startIcon={saveStartIcon}
                  disabled={saveDisabled}
                  formId={formId}
                />
              </span>
            </Tooltip>
          )}
        </FormControl>
      </Box>
    </Box>
  );
}
