import ComputerOutlined from "@mui/icons-material/ComputerOutlined";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { ExecutionPlanType } from "generated-graphql/graphql";
import { useUpdateActivities } from "hooks/activities";
import { useCallback, useMemo } from "react";
import { useBulkActionContext } from ".";

export function SetToAuto() {
  const { selectedRows, onClose } = useBulkActionContext();
  const alerts = useAlerts();
  const [updateActivities] = useUpdateActivities();

  const shouldDisable = useMemo(() => {
    return selectedRows.some((row) => !row.activityId);
  }, [selectedRows]);

  const setPortalToAuto = useCallback(async () => {
    try {
      await updateActivities({
        variables: {
          ids: selectedRows.map((row) => row.activityId).filter(hasValue),
          input: {
            executionPlan: ExecutionPlanType.Automation,
          },
        },
      });
      alerts.success(`Set facilities to auto mode`);
    } catch (error) {
      alerts.error(`Error: unable to set facilities to auto `);
    } finally {
      onClose();
    }
  }, [updateActivities, selectedRows, alerts, onClose]);

  return (
    <MenuItem disabled={shouldDisable} onClick={setPortalToAuto}>
      <ListItemIcon>
        <ComputerOutlined />
      </ListItemIcon>
      <ListItemText>Set Portal to Auto</ListItemText>
    </MenuItem>
  );
}
