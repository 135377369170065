import { useMutation } from "@apollo/client";
import Label from "@mui/icons-material/Label";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import {
  ManageTagsFormDialog,
  ManageTagsFormState,
} from "components/ManageTagsFormDialog";
import { gql } from "generated-graphql";
import { ManageTagsAction } from "generated-graphql/graphql";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import { useBulkActionContext } from ".";

export function ManageTags() {
  const { setDialogState, selectedRows } = useBulkActionContext();

  return (
    <MenuItem
      onClick={() => setDialogState("manageTags")}
      disabled={
        compact(selectedRows.map((row) => row.tierIIReportId ?? "")).length ===
        0
      }
    >
      <ListItemIcon>
        <Label />
      </ListItemIcon>
      <ListItemText>Manage Tags</ListItemText>
    </MenuItem>
  );
}

export const MANAGE_TAGS_MUTATION = gql(`
  mutation ManageTags($tierIIReportIds: [ID!]!, $action: ManageTagsAction!, $tags: [String!]!) {
    manageTierIIReportsTags(tierIIReportIds: $tierIIReportIds, action: $action, tags: $tags) {
      id
      tags
    }
  }
`);

export function ManageTagsDialog() {
  const alerts = useAlerts();
  const { dialogState, onClose, selectedRows } = useBulkActionContext();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const tierIIReportIds = compact(
    selectedRows.map((row) => row.tierIIReportId ?? "")
  );

  const [mutate, { loading }] = useMutation(MANAGE_TAGS_MUTATION, {
    refetchQueries: ["TierIIReportActivities"],
  });

  const close = useCallback(() => {
    setShowConfirmation(false);
    onClose();
  }, [onClose]);

  const confirm = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const submit = useCallback(
    async (action: ManageTagsAction, formState: ManageTagsFormState) => {
      setShowConfirmation(false);
      try {
        await mutate({
          variables: {
            tierIIReportIds,
            action,
            tags: formState.tagsToAdd,
          },
        });
        alerts.success(
          `Successfully ${
            action === ManageTagsAction.Add ? "added" : "removed"
          } the tags`
        );
      } catch (err) {
        alerts.error("An error occurred while managing the report tags", err);
      }
      if (action === ManageTagsAction.Add) {
        close();
      }
    },
    [alerts, close, mutate, tierIIReportIds]
  );

  return (
    <ManageTagsFormDialog
      tierIIReportIds={tierIIReportIds}
      onClose={close}
      onCloseConfirmation={() => setShowConfirmation(false)}
      confirm={confirm}
      open={dialogState === "manageTags"}
      onSubmit={submit}
      loading={loading}
      showConfirmation={showConfirmation}
      withConfirmation
    />
  );
}
