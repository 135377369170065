import { useExpandableRow } from "./useExpandableRow";
import { Link } from "@mui/material";
import { useCallback } from "react";
/**
 * Usage example:
 * ```
  <ExpandableCell items={items} collapsedLines={5} onClick={() => {loadMoreFunction}} render={(lines) => 
    {lines?.map((line, idx) => (
        <TruncateTypography key={idx} showTooltip variant="body">
          {line}
        </TruncateTypography>
      ))}  
    }>
  </ExpandableCell>
 * ```
 */

interface ExpandableCellProps<T> {
  items: T[];
  rowId: string | number;
  collapsedLines?: number;
  totalCount?: number;
  clickDisabled?: boolean;
  onClick?: () => void;
  render: (lines: T[]) => React.ReactNode;
}

// Requires use within a omnisearch expandable grid
export function ExpandableCell<T>({
  items,
  rowId,
  collapsedLines = 5,
  totalCount = undefined,
  onClick = () => {},
  clickDisabled = false,
  render,
}: ExpandableCellProps<T>) {
  const { isExpanded, toggleRow } = useExpandableRow(rowId);

  const toggleExpand = useCallback(() => {
    toggleRow();
    onClick();
  }, [onClick, toggleRow]);

  const lines = isExpanded ? items : items?.slice(0, collapsedLines);

  // if totalCount is not provided, use the length of items, to support legacy usage
  const showLink = (totalCount ?? items.length) > collapsedLines;

  return (
    <div>
      {render(lines)}
      {showLink && (
        <Link
          type="button"
          component="button"
          sx={{ fontSize: "inherit" }}
          onClick={toggleExpand}
          disabled={clickDisabled}
        >
          {isExpanded ? "View less" : "View more"}
        </Link>
      )}
    </div>
  );
}
