import { Box, Grid, Typography, useTheme } from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import CopyableTypography from "components/Typography/CopyableTypography";
import { JobLog, JobStatus } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { prettyPrintDateTimeWithSeconds } from "util/dates";
import { STAFF_JOB_LOGS_TABLE_QUERY } from "./api";

export function JobLogTable(props: { jobId: string }) {
  const theme = useTheme();
  const { jobId } = props;
  const logColumns: OmnisearchGridColDef<JobLog>[] = [
    {
      field: "status",
      headerName: "Status",
      width: 200,
      enumValues: Object.values(JobStatus),
      filterKeyType: "enum",
    },
    {
      field: "createdAt",
      headerName: "Logged At",
      width: 150,
      renderCell: ({ row }) => (
        <span>{prettyPrintDateTimeWithSeconds(row.createdAt)}</span>
      ),
    },
    {
      field: "errorMessage",
      headerName: "Message",
      flex: 0.5,
      renderCell: ({ row }) => (
        <CopyableTypography variant="body2" text={row.errorMessage} />
      ),
    },
  ];

  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" fontWeight="medium">
        Status Logs:
      </Typography>
      <Box marginTop={theme.spacing(1)}>
        <OmnisearchDataGrid
          isURLDriven={false}
          initialSortModel={[{ field: "createdAt", sort: "desc" }]}
          columns={logColumns}
          dataQuery={STAFF_JOB_LOGS_TABLE_QUERY}
          defaultSearch={`jobId:${jobId}`}
          getItems={(data) => data.jobLogs.items}
          getCount={(data) => data.jobLogs.count}
          withPadding={false}
          initialPageSize={5}
        />
      </Box>
    </Grid>
  );
}
