import { Typography, Box, Card, CardContent } from "@mui/material";

export const HasNoInsightsId = ({ type }: { type: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: (theme) => theme.spacing(3),
      }}
    >
      <Card sx={{ maxWidth: 400, textAlign: "center", p: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            No {type}
          </Typography>
          <Typography variant="h6" gutterBottom>
            This facility's profile states it does not have an {type} which
            means that there is no linked data from the federal database to
            display.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
