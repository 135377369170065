import AdjustOutlined from "@mui/icons-material/AdjustOutlined";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CheckCircleOutlineRounded from "@mui/icons-material/CheckCircleOutlineRounded";
import EmojiEvents from "@mui/icons-material/EmojiEvents";
import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";
import PendingOutlined from "@mui/icons-material/PendingOutlined";
import Schedule from "@mui/icons-material/Schedule";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import { ActivityStatus, ActivityType } from "generated-graphql/graphql";
import { NavLink } from "react-router-dom";
import { getActivityTitle } from "../../../../../util/activity";
import { useReportDetails } from "../useReportDetails";

export function ActivityStepper() {
  const theme = useTheme();
  const { data } = useReportDetails();
  const activities = data?.tierIIReport.currentWorkflow?.activities ?? [];
  const currentActivityId =
    data?.tierIIReport.currentWorkflow?.currentActivityId;

  return (
    <List
      disablePadding
      sx={{
        maxHeight: "500px",
        overflowY: "auto",
      }}
    >
      {activities.map((activity) => (
        <NavLink
          style={{ textDecoration: "none", color: "inherit" }}
          key={activity.id}
          to={activity.id}
          replace
        >
          {({ isActive }) => (
            <Tooltip
              key={activity.id}
              title={getActivityTitle(activity)}
              placement="right"
              enterDelay={1000}
            >
              <ListItem disablePadding sx={{ overflowX: "hidden" }}>
                <ListItemButton
                  sx={{
                    background: isActive ? theme.palette.grey[100] : "inherit",
                    alignItems: "flex-start",
                    paddingLeft: theme.spacing(2),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: theme.spacing(1),
                      mt: theme.spacing(0.5),
                    }}
                  >
                    {getActivityStatusIcon(activity.status)}
                  </ListItemIcon>
                  {activity.id === currentActivityId && (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mt: theme.spacing(0.5),
                        mr: theme.spacing(1),
                      }}
                    >
                      <ArrowForward
                        color={getActivityStatusColor(activity.status)}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={getActivityTitle(activity)} />
                  {[
                    ActivityType.WaitForAllStateReports,
                    ActivityType.WaitForAllCnReports,
                    ActivityType.WaitForAllAltIdReports,
                    ActivityType.WaitForAllCredentialReports,
                    ActivityType.WaitForAllLepc,
                    ActivityType.WaitForAllFd,
                  ].includes(activity.type) &&
                    activity.championId === data?.tierIIReport.id && (
                      <ListItemIcon
                        sx={{
                          mt: theme.spacing(0.5),
                        }}
                      >
                        <EmojiEvents />
                      </ListItemIcon>
                    )}
                </ListItemButton>
              </ListItem>
            </Tooltip>
          )}
        </NavLink>
      ))}
    </List>
  );
}

function getActivityStatusIcon(status: ActivityStatus) {
  const color = getActivityStatusColor(status);
  switch (status) {
    case ActivityStatus.StaffCompleted:
      return <Schedule color={color} />;
    case ActivityStatus.Completed:
      return <CheckCircleOutlineRounded color={color} />;
    case ActivityStatus.Error:
      return <ErrorOutlineRounded color={color} />;
    case ActivityStatus.InProgress:
      return <PendingOutlined color={color} />;
    default:
      return <AdjustOutlined color={color} />;
  }
}

function getActivityStatusColor(status: ActivityStatus) {
  switch (status) {
    case ActivityStatus.StaffCompleted:
      return "success";
    case ActivityStatus.Completed:
      return "success";
    case ActivityStatus.Error:
      return "error";
    case ActivityStatus.InProgress:
      return "primary";
    default:
      return "action";
  }
}
