import { useAuthenticator } from "@aws-amplify/ui-react";
import { SpinnerOverlay } from "components/SpinnerOverlay";
import { Permission } from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useCallback, useEffect } from "react";
import {
  Outlet,
  ScrollRestoration,
  useMatch,
  useNavigate,
} from "react-router-dom";

export function PrivateRoute() {
  const { authStatus, toSignIn } = useAuthenticator();
  const navigate = useNavigate();
  const { isStaff, isCustomer, user } = useCurrentUser();
  const match = useMatch("/");
  const tenantIdMatch = useMatch("/o/:tenantId/*");

  const navigateToOrganizationHome = useCallback(() => {
    user?.UserTenant[0]?.role?.permissions?.includes(Permission.RouteFacilities)
      ? navigate(`/o/${user.UserTenant[0]?.tenantId}/facilities`)
      : navigate(`/o/${user?.UserTenant[0]?.tenantId}`);
  }, [user?.UserTenant, navigate]);

  useEffect(() => {
    if (authStatus === "authenticated" && isCustomer && !tenantIdMatch) {
      navigateToOrganizationHome();
    }
  }, [authStatus, isCustomer, tenantIdMatch, navigateToOrganizationHome]);

  useEffect(() => {
    if (!match) return;
    if (!(isStaff || isCustomer)) return;

    if (authStatus !== "authenticated" && authStatus !== "configuring") {
      if (!localStorage.getItem("logout")) {
        toSignIn({ scope: "email openid phone" });
      } else {
        localStorage.removeItem("logout");
      }
    }

    if (authStatus === "authenticated") {
      const preLoginPath = localStorage.getItem("preLoginPath");
      localStorage.removeItem("preLoginPath");
      if (preLoginPath) {
        if (isCustomer && !tenantIdMatch) {
          navigateToOrganizationHome();
        } else {
          navigate(preLoginPath);
        }
      } else {
        if (isStaff) navigate("/staff");
        else if (isCustomer && user?.UserTenant[0]?.tenantId) {
          navigateToOrganizationHome();
        }
      }
    }
  }, [
    authStatus,
    toSignIn,
    navigate,
    isStaff,
    isCustomer,
    user?.UserTenant,
    tenantIdMatch,
    match,
    navigateToOrganizationHome,
  ]);

  if (authStatus === "configuring") {
    return <div>Signing you in/out</div>;
  }

  return authStatus !== "authenticated" ||
    (authStatus === "authenticated" && !isStaff && !isCustomer) ? (
    <SpinnerOverlay loading />
  ) : (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  );
}
