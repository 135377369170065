import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { ReactNode } from "react";

export const DataGridCell = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        paddingY: theme.spacing(0.5),
        alignItems: "start",
        height: "100%",
      }}
    >
      {children}
    </Box>
  );
};
