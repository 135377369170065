import { useMutation, useQuery } from "@apollo/client";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import Description from "@mui/icons-material/Description";
import EventIcon from "@mui/icons-material/Event";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReplayIcon from "@mui/icons-material/Replay";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { SubNavAppBar } from "components/AppBar";
import { Dialog } from "components/Dialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import CopyableTypography from "components/Typography/CopyableTypography";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { DateTime } from "luxon";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { prettyPrintDateTime } from "util/dates";
import { useAlerts } from "../../../../../components/Alerts/AlertProvider";
import { Filter } from "../../../../../components/Omnisearch/Omnisearch";
import { SpinnerOverlay } from "../../../../../components/SpinnerOverlay";
import { gql } from "../../../../../generated-graphql";
import {
  ExtractionsWebAutomationJobsQuery,
  JobStatus,
} from "../../../../../generated-graphql/graphql";
import { useCurrentUser } from "../../../../../hooks/useCurrentUser";
import { useQueryParams } from "../../../../../hooks/useQueryParams";
import { JobLogs } from "./JobLogs";
import { T2sImportDialog } from "./T2sImportDialog";

type Row = ExtractionsWebAutomationJobsQuery["webAutomationJobs"]["items"][0];

const JOBS_QUERY = gql(`
  query ExtractionsWebAutomationJobs($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    webAutomationJobs(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        action,
        reportingYear
        status,
        stateOrTerritory
        tenantId,
        facilityId
        reportId
        facility {
          name
          customerFacilityId
        }
        tenant {
          name
        }
        createdAt
        updatedAt
        promotedAt
        promotionJobId
        promotionJobStatus
        promotionJobUpdatedAt
      }
      count
    }
  }
`);

const JOB_WITH_EXTRACTION_QUERY = gql(`
  query ExtractionsJob($id: ID!) {
    webAutomationJob(id: $id) {
      id
      action,
      reportingYear
      status,
      stateOrTerritory
      tenantId,
      facilityId
      reportId
      extractionKey
      extraction
      facility {
        name
        customerFacilityId
      }
      tenant {
        name
      }
      createdAt
      updatedAt
      promotedAt
      promotionJobId
      promotionJobStatus
      promotionJobUpdatedAt
    }
  }
`);

const UPDATE_JOB_MUTATION = gql(`
  mutation UpdateWebAutomationJobs(
    $ids: [ID!]!
    $input: UpdateWebAutomationJobInput!
  ) {
    updateWebAutomationJobs(ids: $ids, input: $input) {
      count
    }
  }
`);

const PROMOTE_DRAFT_MUTATION = gql(`
  mutation PromoteDraftReport($webAutomationJobId: ID!) {
    promoteDraftReport(webAutomationJobId: $webAutomationJobId)
  }
`);

export function ExtractionsTabPanel({ tenantId }: { tenantId: string }) {
  const currentUser = useCurrentUser();
  const alerts = useAlerts();

  const webAutomationAction = "extractData";

  const currentUserEmail = currentUser.user?.email;

  const { filters } = useQueryParams<Filter>();

  const [logsJobId, setLogsJobId] = useState<string | null>(null);

  // scroll the data grid into view when the omnisearch filters change
  const dataGridRef = useRef<HTMLDivElement>(null);
  const [knownFilter, setKnownFilter] = useState<Partial<Filter>>({});

  const [isCancelExtractionsModalOpen, setIsCancelExtractionsModalOpen] =
    useState(false);
  const [isCancellingExtractions, setIsCancellingExtractions] = useState(false);

  const handleOpenCancelExtractionsModal = () => {
    setIsCancelExtractionsModalOpen(true);
  };

  const handleCloseCancelExtractionsModal = () => {
    setIsCancelExtractionsModalOpen(false);
  };

  const [t2sModalOpen, setT2sModalOpen] = useState(false);
  const [isRequeueExtractionsModalOpen, setIsRequeueExtractionsModalOpen] =
    useState(false);
  const [isRequeueingExtractions, setIsRequeueingExtractions] = useState(false);

  const handleOpenLoadFromT2SModal = () => {
    setT2sModalOpen(true);
  };

  const handleOpenRequeueExtractionsModal = () => {
    setIsRequeueExtractionsModalOpen(true);
  };

  const handleCloseRequeueExtractionsModal = () => {
    setIsRequeueExtractionsModalOpen(false);
  };

  useEffect(() => {
    // known filter prevents bouncing if you scroll after clicking, since useQueryParams
    // changes a few times, it seems like
    if (filters.omnisearch !== knownFilter.omnisearch) {
      setKnownFilter(filters);
      if (dataGridRef.current && filters.omnisearch) {
        dataGridRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [filters, setKnownFilter, knownFilter]);

  const [updateWebAutomationJob] = useMutation(UPDATE_JOB_MUTATION, {
    refetchQueries: ["ExtractionsWebAutomationJobs"],
    onCompleted: async () => {},
    onError: () => {
      alerts.error("Error Updating Extraction Statuses!");
    },
  });

  const handleCancelExtractions = async () => {
    setIsCancellingExtractions(true);
    await updateWebAutomationJob({
      variables: {
        ids: selectedCancellableRows.map((row) => row.id),
        input: {
          status: JobStatus.ShouldCancel,
        },
      },
    });
    await new Promise((res) => setTimeout(res, 1000));
    setIsCancellingExtractions(false);
    handleCloseCancelExtractionsModal();
    alerts.success("Successfully Cancelled Extractions!");
  };

  const handleRequeueExtractions = async () => {
    setIsRequeueingExtractions(true);
    await updateWebAutomationJob({
      variables: {
        ids: selectedRequeueableRows.map((row) => row.id),
        input: {
          status: JobStatus.Queued,
        },
      },
    });
    await new Promise((res) => setTimeout(res, 1000));
    setIsRequeueingExtractions(false);
    handleCloseRequeueExtractionsModal();
    alerts.success("Successfully Requeued Extractions!");
  };

  const [selectedExtractionId, setSelectedExtractionId] = useState<
    string | null
  >(null);

  const viewExtraction = useCallback((jobId: string) => {
    setSelectedExtractionId(jobId);
  }, []);

  const [selectedRows, setSelectedRows] = useState<Row[]>([]);

  // Doing this so the table in the cancel modal doesn't disappear
  // until the mutation is complete
  const selectedCancellableRowsPreviousValue = useRef<Row[]>([]);
  const selectedCancellableRows = useMemo(() => {
    if (isCancellingExtractions) {
      return selectedCancellableRowsPreviousValue.current;
    }

    const newValue = selectedRows.filter(
      (row) =>
        row.status === JobStatus.Running || row.status === JobStatus.Queued
    );

    selectedCancellableRowsPreviousValue.current = newValue;
    return newValue;
  }, [selectedRows, isCancellingExtractions]);

  const selectedRequeueableRowsPreviousValue = useRef<Row[]>([]);
  const selectedRequeueableRows = useMemo(() => {
    if (isRequeueingExtractions) {
      return selectedRequeueableRowsPreviousValue.current;
    }

    const newValue = selectedRows.filter(
      (row) =>
        row.status === JobStatus.Cancelled ||
        row.status === JobStatus.FailedQualityControlCheck ||
        row.status === JobStatus.FailedProvisioningEcsTask ||
        row.status === JobStatus.FailedNeedsHuman ||
        // Not sure if this should be requeueable
        row.status === JobStatus.FailedMayAutoRetry
    );

    selectedRequeueableRowsPreviousValue.current = newValue;
    return newValue;
  }, [selectedRows, isRequeueingExtractions]);

  if (!currentUserEmail) return <></>;

  const columns: OmnisearchGridColDef<Row>[] = [
    {
      field: "tenant.name",
      headerName: "Organization",
      flex: 0.15,
      valueGetter: (params) => {
        return params.row.tenant?.name ?? "";
      },
    },
    {
      field: "facility",
      headerName: "Facility",
      filterKeyType: "facility",
      flex: 0.25,
      valueGetter: (params) => {
        return params.row.facility?.name ?? "";
      },
    },
    {
      field: "customerFacilityId",
      headerName: "Customer Facility ID",
      flex: 0.25,
      valueGetter: (params) => {
        return params.row.facility?.customerFacilityId ?? "";
      },
    },
    {
      field: "stateOrTerritory",
      headerName: "State",
      flex: 0.1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.25,
      enumValues: Object.values(JobStatus),
      filterKeyType: "enum",
    },
    {
      field: "reportingYear",
      headerName: "Reporting Year",
      flex: 0.15,
      filterKeyType: "number",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.2,
      valueGetter: (params) => {
        return prettyPrintDateTime(params.row.createdAt);
      },
      filterKeyType: "time",
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.2,
      valueGetter: (params) => {
        return prettyPrintDateTime(params.row.createdAt);
      },
      filterKeyType: "time",
    },
    {
      field: "promotedAt",
      headerName: "Promoted At",
      flex: 0.2,
      valueGetter: (params) => {
        return params.row.promotedAt ?? params.row.promotionJobUpdatedAt;
      },
      valueFormatter: ({ value }) => {
        if (!value) {
          return null;
        }
        return DateTime.fromISO(value).toFormat("MM/dd/yyyy hh:mm a");
      },
      filterKeyType: "time",
    },
    {
      field: "view",
      type: "actions",
      getActions: (params) => [
        <Tooltip title="View Logs" key={0}>
          <GridActionsCellItem
            onClick={() => setLogsJobId(params.row.id)}
            label="view logs"
            icon={<Description />}
          />
        </Tooltip>,
        <Tooltip title="View details" key={1}>
          <GridActionsCellItem
            disabled={
              !(
                params.row.status === "SUCCEEDED" ||
                params.row.status === "AWAITING_MANUAL_INTERVENTION" ||
                // deprecated
                params.row.status === "PROMOTED"
              )
            }
            onClick={() => viewExtraction(params.row.id)}
            label="view extraction details"
            icon={<ArrowCircleRightIcon />}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <OmnisearchDataGrid
        withPadding={false}
        showFavorites={false}
        dataQuery={JOBS_QUERY}
        getItems={(data) => data.webAutomationJobs.items}
        getCount={(data) => data.webAutomationJobs.count}
        defaultSearch={`tenantId:${tenantId} action:${webAutomationAction}`}
        isRowSelectable={(params) => params.row.status !== "SUCCEEDED"}
        onSelectedRowsChanged={setSelectedRows}
        columns={columns}
        initialSortModel={[{ field: "updatedAt", sort: "desc" }]}
        initialState={{
          pinnedColumns: {
            right: ["actions"],
          },
        }}
        commandButtons={[
          <Button
            key="t2s"
            variant="contained"
            color="primary"
            onClick={handleOpenLoadFromT2SModal}
          >
            Load data from T2S
          </Button>,
          <Button
            key="requeue"
            variant="contained"
            color="info"
            onClick={handleOpenRequeueExtractionsModal}
          >
            Requeue Extractions
          </Button>,
          <Button
            key="cancel"
            variant="contained"
            color="warning"
            onClick={handleOpenCancelExtractionsModal}
          >
            Cancel Extractions
          </Button>,
        ]}
      />
      {t2sModalOpen && (
        <T2sImportDialog
          open={t2sModalOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setT2sModalOpen(false);
            }
          }}
        />
      )}
      <Modal
        open={isRequeueExtractionsModalOpen}
        onClose={handleCloseRequeueExtractionsModal}
        aria-labelledby="all-comments-modal"
        aria-describedby="modal-to-view-all-comments"
      >
        <Box
          sx={{
            padding: 6,
            bgcolor: "background.paper",
            margin: "auto",
            maxWidth: "60%",
            maxHeight: "80%",
            overflow: "auto",
            mt: "10%",
            outline: "none",
          }}
        >
          {isRequeueingExtractions || selectedRequeueableRows.length ? (
            <>
              <Typography variant="h5">
                The Following Extractions Will Be Requeued
              </Typography>
              <Typography>
                Note: Only extractions with a FAILED (various types) or
                CANCELLED status can be Requeued
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  marginTop: "2rem",
                }}
              >
                <Box
                  style={{
                    maxHeight: "500px",
                    overflowY: "scroll",
                  }}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Organization</TableCell>
                        <TableCell>Facility</TableCell>
                        <TableCell>Customer Facility ID</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Reporting Year</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedRequeueableRows.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{row.tenant?.name}</TableCell>
                          <TableCell>{row.facility?.name}</TableCell>
                          <TableCell>
                            {row.facility?.customerFacilityId}
                          </TableCell>
                          <TableCell>{row.stateOrTerritory}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.reportingYear}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </TableContainer>
            </>
          ) : (
            <>
              <Typography variant="h5">
                No Requeueable Extractions Selected
              </Typography>
              <Typography>
                Note: Only extractions with a FAILED (various types) or
                CANCELLED status can be Requeued
              </Typography>
            </>
          )}
          {(isRequeueingExtractions || selectedRequeueableRows.length > 0) && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: "2rem",
              }}
            >
              <Box flexGrow={1} />
              <LoadingButton
                loading={isRequeueingExtractions}
                loadingPosition="start"
                startIcon={<ReplayIcon />}
                variant="contained"
                color="warning"
                onClick={handleRequeueExtractions}
              >
                Confirm
              </LoadingButton>
            </Stack>
          )}
        </Box>
      </Modal>
      <Modal
        open={isCancelExtractionsModalOpen}
        onClose={handleCloseCancelExtractionsModal}
        aria-labelledby="all-comments-modal"
        aria-describedby="modal-to-view-all-comments"
      >
        <Box
          sx={{
            padding: 6,
            bgcolor: "background.paper",
            margin: "auto",
            maxWidth: "60%",
            maxHeight: "80%",
            overflow: "auto",
            mt: "10%",
            outline: "none",
          }}
        >
          {isCancellingExtractions || selectedCancellableRows.length ? (
            <>
              <Typography variant="h5">
                The Following Extractions Will Be Cancelled
              </Typography>
              <Typography>
                Note: Only extractions with a status of RUNNING or QUEUED can be
                cancelled
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  marginTop: "2rem",
                }}
              >
                <Box
                  style={{
                    maxHeight: "500px",
                    overflowY: "scroll",
                  }}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Organization</TableCell>
                        <TableCell>Facility</TableCell>
                        <TableCell>Customer Facility ID</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Reporting Year</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedCancellableRows.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{row.tenant?.name}</TableCell>
                          <TableCell>{row.facility?.name}</TableCell>
                          <TableCell>
                            {row.facility?.customerFacilityId}
                          </TableCell>
                          <TableCell>{row.stateOrTerritory}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.reportingYear}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </TableContainer>
            </>
          ) : (
            <>
              <Typography variant="h5">
                No Cancellable Extractions Selected
              </Typography>
              <Typography>
                Note: Only extractions with a status of RUNNING or QUEUED can be
                cancelled
              </Typography>
            </>
          )}
          {(isCancellingExtractions || selectedCancellableRows.length > 0) && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: "2rem",
              }}
            >
              <Box flexGrow={1} />
              <LoadingButton
                loading={isCancellingExtractions}
                loadingPosition="start"
                startIcon={<CancelIcon />}
                variant="contained"
                color="warning"
                onClick={handleCancelExtractions}
              >
                Confirm
              </LoadingButton>
            </Stack>
          )}
        </Box>
      </Modal>
      {selectedExtractionId && (
        <ExtractionDetail jobId={selectedExtractionId} />
      )}
      {logsJobId && (
        <Dialog
          open={!!logsJobId}
          onClose={() => setLogsJobId(null)}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle>Extraction Logs</DialogTitle>
          <DialogContent>
            <JobLogs id={logsJobId} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

function ExtractionDetail({ jobId }: { jobId: string }) {
  const alerts = useAlerts();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tab, setTab] = useState("report");
  const [isPromotingExtraction, setIsPromotingExtraction] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTabChange = useCallback(
    (_: SyntheticEvent, selectedTab: string) => setTab(selectedTab),
    [setTab]
  );

  const [promoteDraft] = useMutation(PROMOTE_DRAFT_MUTATION, {
    refetchQueries: ["ExtractionsWebAutomationJobs"],
    onCompleted: async () => {},
    onError: () => {
      alerts.error("Error promoting draft!");
    },
  });

  const handlePromoteExtraction = async (webAutomationJobId: string) => {
    setIsPromotingExtraction(true);
    const result = await promoteDraft({
      variables: {
        webAutomationJobId,
      },
    });
    setIsPromotingExtraction(false);
    setIsModalOpen(false);

    if (!result.errors) {
      alerts.success("Successfully Promoted Extraction!");
    } else {
      alerts.error("Error promoting extraction!");
    }
  };

  const { data, loading } = useQuery(JOB_WITH_EXTRACTION_QUERY, {
    variables: {
      id: jobId,
    },
  });

  return (
    <Box
      style={{
        marginTop: "2rem",
      }}
    >
      <SpinnerOverlay loading={loading} />
      <Stack direction={"column"}>
        <Stack direction="row">
          <Typography variant="h6">
            {`${data?.webAutomationJob?.tenant?.name ?? ""} - ${
              data?.webAutomationJob?.facility?.name ?? ""
            } ${data?.webAutomationJob?.facility?.customerFacilityId ?? ""} - ${
              data?.webAutomationJob?.stateOrTerritory ?? ""
            }`}
          </Typography>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            color="info"
            onClick={handleOpenModal}
            disabled={
              data?.webAutomationJob?.status !== "SUCCEEDED" ||
              !!data?.webAutomationJob?.promotionJobId
            }
          >
            Promote
          </Button>
        </Stack>
        <Stack direction={"row"}>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText
                primary="Reporting Year"
                secondary={data?.webAutomationJob?.reportingYear}
              />
            </ListItem>
          </List>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                primary="Started At"
                secondary={DateTime.fromISO(
                  data?.webAutomationJob?.createdAt
                ).toFormat("MM/dd/yy hh:mm a")}
              />
            </ListItem>
          </List>
          <List dense={true}>
            <ListItem sx={{ display: "block" }}>
              <ListItemText>Job Id</ListItemText>
              <CopyableTypography text={jobId} />
            </ListItem>
          </List>
        </Stack>
      </Stack>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="all-comments-modal"
        aria-describedby="modal-to-view-all-comments"
      >
        <Box
          sx={{
            padding: 6,
            bgcolor: "background.paper",
            margin: "auto",
            maxWidth: "40%",
            maxHeight: "80%",
            overflow: "auto",
            mt: "10%",
            outline: "none",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              alignSelf: "center",
            }}
          >
            Are You Sure You Want to Promote This Extraction?
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: "2rem",
            }}
          >
            <Box flexGrow={1} />
            <LoadingButton
              loading={isPromotingExtraction}
              loadingPosition="start"
              startIcon={<MoveUpIcon />}
              variant="contained"
              color="success"
              onClick={() => handlePromoteExtraction(jobId)}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <TabContext value={tab}>
        <SubNavAppBar>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Report" value="report"></Tab>
            <Tab label="Facility" value="facility"></Tab>
            <Tab label="Contacts" value="contacts"></Tab>
            <Tab label="Products" value="products"></Tab>
            <Tab label="Documents" value="documents"></Tab>
          </Tabs>
        </SubNavAppBar>

        <TabPanel value="report">
          <Box
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
            }}
          >
            <pre>
              {
                // todo: jason: what do we want to do here? This isn't part of the table...
                JSON.stringify(data?.webAutomationJob.extraction, null, 2)
              }
            </pre>
          </Box>
        </TabPanel>
        <TabPanel value="facility">
          <Box
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
            }}
          >
            <pre>
              {JSON.stringify(data?.webAutomationJob.extraction.basic, null, 2)}
            </pre>
          </Box>
        </TabPanel>
        <TabPanel value="contacts">
          <Box
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
            }}
          >
            <pre>
              {JSON.stringify(
                data?.webAutomationJob.extraction.contacts,
                null,
                2
              )}
            </pre>
          </Box>
        </TabPanel>
        <TabPanel value="products">
          <Box
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
            }}
          >
            <pre>
              {JSON.stringify(
                data?.webAutomationJob.extraction.products,
                null,
                2
              )}
            </pre>
          </Box>
        </TabPanel>
        <TabPanel value="documents">
          <Box
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
            }}
          >
            <pre>
              {JSON.stringify(
                data?.webAutomationJob.extraction.records,
                null,
                2
              )}
            </pre>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
