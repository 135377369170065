import { Issue, IssueLevel } from "../generated-graphql/graphql";

/**
 * Designed to be used with validated forms to check if the form has any critical issues.
 * @param issues the list of current issues i.e. the issues
 *  returned from the validation or the initial query
 * @param model if no model is specified then all issues are checked
 * @returns whether the given model has any critical issues
 */
export const hasCriticalIssues = (issues: Issue[], model?: string) => {
  return issues?.some(
    (i) =>
      i.level === IssueLevel.Critical && (model ? i.modelName === model : true)
  );
};
