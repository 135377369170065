import { Permission } from "generated-graphql/graphql";
import { useCallback, useMemo } from "react";
import { useCurrentUser } from "./useCurrentUser";
import { useTenant } from "./useTenant";
import { FacilityPermissionsRequiringWrite } from "encamp-shared/src/constants/permissionsTypes";

export const useAuthorization = () => {
  const { tenantId, loading: loadingTenant } = useTenant();
  const { user, isStaff, loading: loadingUser } = useCurrentUser();

  // The union of the user's permissions from their tenant and the partner tenant
  const userPermissions = useMemo(() => {
    const naiveUserTenant = user?.UserTenant.find(
      (ut) => ut?.tenantId === tenantId
    );
    // If the user is associated via partner, we want to look at the role from the parent tenant
    const parentUserTenant = user?.UserTenant.find((ut) =>
      ut.tenant?.partnersTenants.some((pt) => pt.id === tenantId)
    );
    return Array.from(
      new Set([
        ...(naiveUserTenant?.role?.permissions ?? []),
        ...(parentUserTenant?.role?.permissions ?? []),
      ])
    );
  }, [user, tenantId]);

  const hasPermissions = useCallback(
    (permissions: Permission[]) => {
      if (isStaff) {
        return true;
      }

      return permissions.every((p) => userPermissions.includes(p));
    },
    [userPermissions, isStaff]
  );

  /**
   * @returns a tuple of [boolean, string[]] where the boolean indicates
   * whether the current user has access to the facility and the string[]
   * is a list of their permissions for the facility.
   */
  const permissionsForFacility = useCallback(
    (facilityId: string): [boolean, Permission[]] => {
      if (isStaff) {
        return [true, []];
      }
      // If the user has write permissions to all facilities, they can access any facility with all of their permissions
      if (userPermissions.includes(Permission.WriteAllFacility)) {
        return [true, userPermissions];
      }

      // If the user is directly associated with the facility, they have access with all of their permissions
      if (user?.facilities.find((f) => f.id === facilityId)) {
        return [true, userPermissions];
      }

      // If the user has read permissions to all facilities, they can access any facility with all of their non-write permissions
      if (userPermissions.includes(Permission.ReadAllFacility)) {
        return [
          true,
          userPermissions.filter(
            (p) => !(FacilityPermissionsRequiringWrite as string[]).includes(p)
          ),
        ];
      }

      return [false, []];
    },
    [user, userPermissions, isStaff]
  );

  const hasPermissionForFacility = useCallback(
    (facilityId: string, permissions: Permission[]): boolean => {
      if (isStaff) {
        return true;
      }
      const [hasAccess, facilityPermissions] =
        permissionsForFacility(facilityId);
      return (
        hasAccess && permissions.every((p) => facilityPermissions.includes(p))
      );
    },
    [isStaff, permissionsForFacility]
  );

  const hasWritePermissionForFacility = useCallback(
    (facilityId: string) => {
      if (hasPermissions([Permission.WriteAllFacility])) return true;
      if (user?.facilities.find((f) => f.id === facilityId)) return true;
      return false;
    },
    [hasPermissions, user?.facilities]
  );

  const hasTaskWritePermissionForFacilities = useCallback(
    (facilityIds: string[]) => {
      if (hasPermissions([Permission.WriteAllFacility])) return true;
      if (
        facilityIds.length > 0 &&
        facilityIds.every((fid) => user?.facilities.find((f) => f.id === fid))
      )
        return true;
      return false;
    },
    [hasPermissions, user?.facilities]
  );

  return useMemo(
    () => ({
      hasPermissions,
      hasPermissionForFacility,
      hasWritePermissionForFacility,
      hasTaskWritePermissionForFacilities,
      loading: loadingTenant || loadingUser,
    }),
    [
      hasPermissions,
      hasPermissionForFacility,
      hasWritePermissionForFacility,
      hasTaskWritePermissionForFacilities,
      loadingTenant,
      loadingUser,
    ]
  );
};
