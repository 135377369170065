import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import pluralize from "pluralize";

type HowToMergeDialogProps = {
  open: boolean;
  kind: string;
  onClose: () => void;
};

export function HowToMergeDialog(props: HowToMergeDialogProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>How to use Merge</DialogTitle>
      <DialogContent>
        <Typography>
          This merge tool allows you to "build" a new {props.kind} by selecting
          individual fields from each of the records you selected.
        </Typography>
        <br />
        <ul>
          <li>
            <Typography>Each column represents one {props.kind}.</Typography>
          </li>
          <li>
            <Typography>
              For most rows, one and only one cell must be selected.
            </Typography>
          </li>
          <li>
            <Typography>
              Rows marked with an asterisk (*) are multi-select rows. You may
              select any, all, or no cells in this row. Multiple selections will
              be comma delimited in the merged record.
            </Typography>
          </li>
          <li>
            <Typography>
              The "Select All" button selects every cell in a column and
              deselects all other cells (including multi-select cells).
            </Typography>
          </li>
          <li>
            <Typography>
              You can remove a {props.kind} from the merge candidates by
              clicking the "X" button in the column header.
            </Typography>
          </li>
        </ul>
        <br />
        <Typography>
          <b>WARNING:</b> Merging is a destructive action. All of the original{" "}
          {pluralize(props.kind)} will be deleted and replaced with the new,
          merged {props.kind}.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
