import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Dialog } from "components/Dialog";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { gql } from "generated-graphql";
import { CollectionMode } from "generated-graphql/graphql";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useBulkActionContext } from "../Facilities/FacilitiesBulkActionMenu";

type FormState = {
  facilityIds: string[];
  collectionMode: CollectionMode | "";
};

const SET_COLLECTION_MODES = gql(`
    mutation SetCollectionModes($facilityIds: [ID!]!, $collectionMode: CollectionMode) {
        setCollectionMode(facilityIds: $facilityIds, collectionMode: $collectionMode) {
            succeeded
        }
    }
`);

export function SetCollectionModeModal() {
  const {
    selectedRows: selectedFacilities,
    dialogState,
    onClose: handleClose,
  } = useBulkActionContext();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { handleSubmit, control, reset } = useForm<FormState>({
    defaultValues: {
      facilityIds: [],
      collectionMode: "",
    },
  });

  const alerts = useAlerts();
  const theme = useTheme();

  const closeDialog = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  useEffect(() => {
    reset({
      facilityIds: selectedFacilities.map((f) => f.id),
      collectionMode: "",
    });
  }, [selectedFacilities, reset]);

  const [setCollectionModes, { loading }] = useMutation(SET_COLLECTION_MODES, {
    refetchQueries: ["ImplementationFacilities"],
  });

  const onSubmit = useCallback(
    async (data: FormState) => {
      const collectionMode =
        data.collectionMode === "" ? null : data.collectionMode;
      try {
        const result = await setCollectionModes({
          variables: {
            facilityIds: data.facilityIds,
            collectionMode,
          },
        });

        if (result.data?.setCollectionMode?.succeeded) {
          alerts.success("Successfully saved facilities.");
          setShowConfirmDialog(false);
          handleClose();
          return;
        }

        alerts.error(
          "Error saving facilities, do all chemicals and products have amount and unit information?"
        );
      } catch (err) {
        alerts.error(`Error saving facilities:`, err);
      }
    },
    [alerts, setCollectionModes, handleClose]
  );

  return (
    <Dialog
      open={dialogState?.action === "setCollectionMode"}
      onClose={closeDialog}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <DialogTitle>Set Collection Mode</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paddingBottom={theme.spacing(1)}>
              Changing the measurement collection mode will not transfer any
              existing measurements/aggregates for the facilities.
            </Typography>
            <Typography variant="body1" paddingBottom={theme.spacing(2)}>
              Make sure no products or chemicals at each facility have missing
              information before switching collection modes. This information is
              needed to be able to aggregate products and chemicals at a
              facility into a ReportingFacilityChemical (RFC), which is the
              total quantity and full list of storage locations of a chemical at
              a given site. The aggregation process will fail if a product or
              chemical is missing quantity, unit, or storage location
              information.
            </Typography>
            <Typography variant="body2" paddingBottom={theme.spacing(1)}>
              Setting Collection mode for {selectedFacilities.length}
              &nbsp;
              {pluralize("facility", selectedFacilities.length)}
            </Typography>
            <RadioGroupField
              label=""
              radioOptions={[
                { value: CollectionMode.Aggregate, label: "Aggregate" },
                { value: CollectionMode.Measurement, label: "Measurement" },
                { value: "", label: "Tenant Default" },
              ]}
              name="collectionMode"
              control={control}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() => setShowConfirmDialog(true)}
            >
              Save
            </Button>
          </DialogActions>
        </Box>
        <ConfirmDialog
          loading={loading}
          open={showConfirmDialog}
          msg="
          This can take awhile, and will only
          work if all chemicals and products at
          each facility have no missing information.
        "
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={handleSubmit(onSubmit)}
        />
      </form>
    </Dialog>
  );
}
