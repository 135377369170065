import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { transform } from "hooks/transform/transformFacilityChemical";
import { useCallback } from "react";
import { FacilityChemicalDetailFormType } from "../../Inventory/Facility/FacilityChemicalAddEditDialog";

const VALIDATE_FACILITY_CHEMICAL_INPUT = gql(`
  query ValidateFacilityChemicalInput($input: FacilityChemicalInput!) {
    facilityChemicalInputValidator(input: $input) {
      ...issue
    }
  }
`);

export function useFacilityChemicalInputValidation() {
  const [query] = useLazyQuery(VALIDATE_FACILITY_CHEMICAL_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: FacilityChemicalDetailFormType) => {
      const transformedInput = transform(input);

      const { data: result } = await query({
        variables: {
          input: transformedInput,
        },
      });
      return result?.facilityChemicalInputValidator ?? [];
    },
    [query]
  );
}
