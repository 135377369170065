import { Skeleton, useTheme } from "@mui/material";
import { DashboardTile } from "components/Dashboard/DashboardTile";

export function WasteFacilitySkeleton() {
  const theme = useTheme();
  return (
    <>
      <DashboardTile xs={7}>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea xs={4}>
          <Skeleton variant="rectangular" height={theme.spacing(25)} />
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea xs={8}>
          <Skeleton variant="rectangular" height={theme.spacing(25)} />
        </DashboardTile.ContentArea>
      </DashboardTile>
      <DashboardTile xs={5}>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(8)} />
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(6)} />
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(6)} />
        </DashboardTile.ContentArea>
      </DashboardTile>

      <DashboardTile>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(38)} />
        </DashboardTile.ContentArea>
      </DashboardTile>

      <DashboardTile>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(4)} />
        </DashboardTile.ContentArea>
        <DashboardTile.ContentArea xs={12}>
          <Skeleton variant="rectangular" height={theme.spacing(38)} />
        </DashboardTile.ContentArea>
      </DashboardTile>
    </>
  );
}
