import { Paper } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { TierIIReportEncampStatus } from "encamp-shared/src/generated-graphql";
import React from "react";
import { ClickableGridCell } from "../../../../../components/ClickableGridCell";
import { DataGrid } from "../../../../../components/DataGrid";
import { ReportingSeasonSummaryQuery } from "../../../../../generated-graphql/graphql";
import { AssignmentManagementTableFilterKeys } from "../../AssignmentManagerTable/useAssignmentManagerOmnisearch";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";

interface StaffTableProps {
  data?: ReportingSeasonSummaryQuery["reportingSeasonSummary"]["staffSummaries"];
  loading: boolean;
}

export const StaffTable: React.FC<StaffTableProps> = ({
  data = [],
  loading,
}) => {
  const { createUrlFromOmnisearchKeyValues } = useOmnisearchDatagrid();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Staff Assignee", flex: 1 },
    {
      field: "reportCount",
      headerName: "Assigned Reports",
      flex: 1,
      type: "number",
      sortable: true,
      renderCell: (params) => {
        const filters: [AssignmentManagementTableFilterKeys, string][] = [
          [
            AssignmentManagementTableFilterKeys.Assignee,
            params.row.name === "Unassigned" ? "null" : params.row.name,
          ],
          [
            AssignmentManagementTableFilterKeys.EncampStatus,
            TierIIReportEncampStatus.PROCESSING,
          ],
        ];

        return (
          <ClickableGridCell
            href={createUrlFromOmnisearchKeyValues(filters)}
           
          >
            {params.value}
          </ClickableGridCell>
        );
      },
    },
    {
      field: "completedCount",
      headerName: "Completed Activities",
      flex: 1,
      type: "number",
      sortable: true,
    },
  ];

  return (
    <Paper sx={{ height: "100%", width: "100%", overflowY: "auto" }}>
      <DataGrid
        loading={loading}
        rows={data}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "reportCount",
                sort: "desc",
              },
            ],
          },
        }}
        autoPageSize
        pagination
      />
    </Paper>
  );
};
