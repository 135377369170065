import { ExportButton, ExportFileType } from "components/ExportButton";
import { useMemo } from "react";
import { ContactGrid } from "components/Contacts/ContactGrid";

export function OnboardingContacts({ tenantId }: { tenantId: string }) {
  // an isStaff check here may be a little overkill since we are nested under
  // a "staff" route... but the component doesn't know that, and it seems like
  // a decent candidate for getting refactored into a general-purpose contact
  // table
  const importExportButtons = useMemo(() => {
    const buttons = [
      <ExportButton
        key="export"
        disabled={!tenantId}
        fileType={ExportFileType.EXCEL}
        endpoint={`/download/contact?tenantId=${tenantId}`}
      />,
    ];

    return buttons;
  }, [tenantId]);

  return (
    <ContactGrid tenantId={tenantId} commandButtons={importExportButtons} />
  );
}
