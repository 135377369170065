import React, { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import copy from "clipboard-copy";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

interface CopyableTypographyProps {
  text?: string | null;
  variant?: "body1" | "body2" | "caption";
  stopPropagation?: boolean;
  sx?: SxProps<Theme>;
}

function CopyableTypography({
  text,
  variant = "body1",
  stopPropagation = true,
  sx,
}: CopyableTypographyProps) {
  const [copied, setCopied] = useState(false);

  const handleMouseLeave = useCallback(() => {
    setCopied(false);
  }, []);

  const handleCopy = useCallback(
    async (event: React.MouseEvent) => {
      if (stopPropagation) {
        event.stopPropagation();
      }
      await copy(text ?? "");
      setCopied(true);
    },
    [stopPropagation, text]
  );

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Tooltip
        placement="bottom-start"
        title={copied ? "Copied!" : "Click to copy"}
      >
        <Typography
          variant={variant}
          onClick={handleCopy}
          style={{ cursor: "pointer" }}
          sx={sx}
        >
          {text}
        </Typography>
      </Tooltip>
    </div>
  );
}

export default CopyableTypography;
