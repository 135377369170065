import { useQuery } from "@apollo/client";
import { CircularProgress, TextField, TextFieldProps } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { gql } from "generated-graphql";
import { FacilityPickerFragment } from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import React, { forwardRef, useState } from "react";
import { ControllerFieldState } from "react-hook-form";
import { ErrorDisplay } from "./Forms/ErrorDisplay";

gql(`
  fragment FacilityPicker on Facility {
    id
    name
  }
`);

const FACILITIES_QUERY = gql(`
  query FacilitiesPicker($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...FacilityPicker
      }
      count
    }
  }
`);

interface FacilityPickerProps extends ControllerFieldState {
  onChange: (facility: FacilityPickerFragment | null) => void;
  defaultSearchTerm?: string;
  disabledFacilitiesById?: string[];
  disabledOptionLabel?: string;
  label?: string;
  value?: FacilityPickerFragment | null;
  lazyLoad?: boolean;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  showErrors?: boolean;
}

export const FacilityPicker: React.FC<FacilityPickerProps> = forwardRef(
  function FacilityPicker(
    {
      onChange,
      defaultSearchTerm = "",
      disabledFacilitiesById = [],
      disabledOptionLabel = "",
      label,
      value,
      error,
      lazyLoad = true,
      disabled,
      textFieldProps,
      showErrors = true,
    },
    ref
  ) {
    const [search, setSearch] = useState(value?.name ?? "");
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [debouncedSearch] = useDebounce(
      `${defaultSearchTerm} ${search}`,
      150
    );

    const { data, loading, previousData } = useQuery(FACILITIES_QUERY, {
      variables: {
        search: debouncedSearch,
        page: 0,
        pageSize: 5,
      },
      skip: lazyLoad && !searchIsOpen,
      fetchPolicy: "cache-and-network",
    });

    return (
      <>
        <Autocomplete
          ref={ref}
          disabled={disabled}
          options={
            data?.facilities.items ?? previousData?.facilities.items ?? []
          }
          isOptionEqualToValue={(o, v) => o.id === v.id}
          noOptionsText="Search for a facility"
          onOpen={() => setSearchIsOpen(true)}
          onClose={() => setSearchIsOpen(false)}
          inputValue={search}
          value={value ?? null}
          loading={loading}
          onInputChange={(_, val) => {
            setSearch(val);
          }}
          onChange={(_, val) => {
            onChange(val);
          }}
          getOptionLabel={(option: FacilityPickerFragment) => option.name ?? ""}
          getOptionDisabled={(option: FacilityPickerFragment) =>
            disabledFacilitiesById.includes(option?.id ?? "")
          }
          renderOption={(props, option) => (
            <li {...props}>
              {option.name}
              {disabledOptionLabel &&
                disabledFacilitiesById.includes(option?.id ?? "") && (
                  <span>&nbsp;({disabledOptionLabel})</span>
                )}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              label={label ?? "Facility"}
              variant="outlined"
              {...textFieldProps}
              InputProps={{
                ...params.InputProps,
                ...textFieldProps?.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {showErrors && <ErrorDisplay error={error} />}
      </>
    );
  }
);
