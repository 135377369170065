import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import BasicMap from "components/BasicMap";
import { DashboardTile } from "components/Dashboard/DashboardTile";
import { FacilityAlternateIdKnownKind } from "encamp-shared/src/facility/alternateId";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { compact } from "lodash";
import { useParams } from "react-router-dom";
import { useWasteFacility } from "./useWasteFacility";

export const FacilityDetailsTile = () => {
  const theme = useTheme();
  const { facilityId } = useParams<{ facilityId: string }>();

  const { data } = useWasteFacility(facilityId);
  const {
    latitude,
    longitude,
    name,
    facilityAlternateIds,
    streetAddress1,
    streetAddress2,
    city,
    state,
    zip,
    county,
  } = data.facility || {};

  const epaId = findAlternateId(facilityAlternateIds ?? [], [
    FacilityAlternateIdKnownKind.EPA,
  ])?.value;

  const hasLatAndLong =
    typeof latitude === "number" && typeof longitude === "number";

  useBreadcrumb({ label: name ?? "" });

  return (
    <DashboardTile xs={7}>
      <DashboardTile.Header
        title="Facility Details"
        infoTooltip="See basic information about your facility. If any of this information is incorrect or needs to be updated, please reach out to Encamp."
      />
      <DashboardTile.ContentArea xs={12} container spacing={5}>
        {hasLatAndLong && (
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Box height={"262px"}>
                <BasicMap latitude={latitude} longitude={longitude} />
              </Box>
              <Typography variant="caption">
                {[latitude, longitude].join(", ")}
              </Typography>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12} sm={hasLatAndLong ? 6 : 12}>
          <Stack spacing={2}>
            {epaId && (
              <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                component="a"
                href={`https://enviro.epa.gov/envirofacts/rcrainfo/facility?handlerId=${epaId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {epaId}
                <Tooltip title="View EPA Detail Page in new tab">
                  <IconButton>
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            )}

            <Typography variant="h4">{name}</Typography>
            <Typography variant="body1">
              {compact([
                compact([streetAddress1, streetAddress2, city]).join(" "),
                compact([state, zip]).join(" "),
              ]).join(", ")}
            </Typography>
            {county && (
              <Typography variant="body1">
                {county.endsWith(" County") ? county : `${county} County`}
              </Typography>
            )}
          </Stack>
        </Grid>
      </DashboardTile.ContentArea>
    </DashboardTile>
  );
};
