import {
  HealthHazard,
  InternationalFireCodeHazard,
  OregonHazard,
  PhysicalHazard,
} from "generated-graphql/graphql";

export const PhysicalHazardLabels: Record<PhysicalHazard, string> = {
  EXPLOSIVE: "Explosive",
  FLAMMABLE: "Flammable",
  OXIDIZER: "Oxidizer",
  SELF_REACTIVE: "Self-reactive",
  PYROPHORIC_SOLID_OR_LIQUID: "Pyrophoric (liquid or solid)",
  PYROPHORIC_GAS: "Pyrophoric Gas",
  SELF_HEATING: "Self-heating",
  ORGANIC_PEROXIDE: "Organic peroxide",
  CORROSIVE_TO_METAL: "Corrosive to metal",
  COMPRESSED_GAS: "Gas under pressure (compressed gas)",
  FLAMMABLE_WITH_WATER: "In contact with water emits flammable gas",
  COMBUSTIBLE_DUST: "Combustible Dust",
  UNCLASSIFIED_PHYSICAL_HAZARD: "Hazard not otherwise classified",
};

export const HealthHazardLabels: Record<HealthHazard, string> = {
  ACUTE_TOXICITY: "Acute toxicity (any route of exposure)",
  SKIN_IRRITANT: "Skin corrosion or irritation",
  EYE_IRRITANT: "Serious eye damage or eye irritation",
  CAUSES_RESPIRATORY_OR_SKIN_SENSITIZATION: "Respiratory or skin sensitization",
  GERM_CELL_MUTAGENIC: "Germ cell mutagenicity",
  CARCINOGENIC: "Carcinogenicity",
  REPRODUCTIVE_TOXICITY: "Reproductive toxicity",
  SPECIFIC_TARGET_ORGAN_TOXICITY:
    "Specific target organ toxicity (single or repeated exposure)",
  ASPIRATION_HAZARD: "Aspiration hazard",
  SIMPLE_ASPHYXIANT: "Simple Asphyxiant",
  HAZARD_NOT_OTHERWISE_CLASSIFIED_HEALTH: "Hazard not otherwise classified",
};

export const OregonHazardLabels: Record<OregonHazard, string> = {
  RADIOACTIVE: "Radioactive",
  POISONOUS_GAS: "Poisonous Gas",
  POISONOUS_MATERIAL: "Poisonous Material",
  INFECTIOUS_OR_ETIOLOGIC: "Infectious or etiologic (biological hazard)",
};

export const NevadaIFCHazardLabels: Record<
  InternationalFireCodeHazard,
  string
> = {
  COMBUSTIBLE: "Combustible",
  CORROSIVE: "Corrosives",
  FLAMMABLE: "Flammable",
  INERT: "Inert Material/Gas",
  OXIDIZING: "Oxidizing Materials",
  RADIOACTIVE: "Radioactive",
  UNSTABLE: "Unstable (Reactive) Materials",
  EXPLOSIVE: "Explosive Materials",
  HIGHLY_TOXIC: "Highly Toxic Materials",
  ORGANIC_PEROXIDES: "Organic Peroxides",
  PYROPHORIC: "Pyrophoric Materials",
  TOXIC: "Toxic Materials",
  WATER_REACTIVE: "Water-reactive Materials",
};

export const PhysicalHazardOrder: PhysicalHazard[] = [
  PhysicalHazard.Explosive,
  PhysicalHazard.Flammable,
  PhysicalHazard.Oxidizer,
  PhysicalHazard.SelfReactive,
  PhysicalHazard.PyrophoricSolidOrLiquid,
  PhysicalHazard.PyrophoricGas,
  PhysicalHazard.SelfHeating,
  PhysicalHazard.OrganicPeroxide,
  PhysicalHazard.CorrosiveToMetal,
  PhysicalHazard.CompressedGas,
  PhysicalHazard.FlammableWithWater,
  PhysicalHazard.CombustibleDust,
  PhysicalHazard.UnclassifiedPhysicalHazard,
];

export const HealthHazardOrder: HealthHazard[] = [
  HealthHazard.AcuteToxicity,
  HealthHazard.SkinIrritant,
  HealthHazard.EyeIrritant,
  HealthHazard.CausesRespiratoryOrSkinSensitization,
  HealthHazard.GermCellMutagenic,
  HealthHazard.Carcinogenic,
  HealthHazard.ReproductiveToxicity,
  HealthHazard.SpecificTargetOrganToxicity,
  HealthHazard.AspirationHazard,
  HealthHazard.SimpleAsphyxiant,
  HealthHazard.HazardNotOtherwiseClassifiedHealth,
];

export const OregonHazardOrder: OregonHazard[] = [
  OregonHazard.Radioactive,
  OregonHazard.PoisonousGas,
  OregonHazard.PoisonousMaterial,
  OregonHazard.InfectiousOrEtiologic,
];

export const NevadaIFCHazardOrder: InternationalFireCodeHazard[] = [
  InternationalFireCodeHazard.Combustible,
  InternationalFireCodeHazard.Corrosive,
  InternationalFireCodeHazard.Flammable,
  InternationalFireCodeHazard.Inert,
  InternationalFireCodeHazard.Oxidizing,
  InternationalFireCodeHazard.Radioactive,
  InternationalFireCodeHazard.Unstable,
  InternationalFireCodeHazard.Explosive,
  InternationalFireCodeHazard.HighlyToxic,
  InternationalFireCodeHazard.OrganicPeroxides,
  InternationalFireCodeHazard.Pyrophoric,
  InternationalFireCodeHazard.Toxic,
  InternationalFireCodeHazard.WaterReactive,
];
