import { useMutation } from "@apollo/client";
import pluralize from "pluralize";
import { useAlerts } from "../../components/Alerts/AlertProvider";
import { gql } from "../../generated-graphql";
import { UpdateActivityMutation } from "../../generated-graphql/graphql";

const ACTIVITY_FRAGMENT = gql(`
  fragment ActivityFragment on Activity {
    id
    type
    status
    executionPlan
    description
    assigneeId
    assignee {
      id
      email
      person {
        id
        first
        last
      }
    }
    workflow {
      id
      tierIIReportId
    }
  }
`);

const UPDATE_ACTIVITY = gql(`
  mutation UpdateActivity($id: ID!, $input: ActivityInput!) {
    updateActivity(id: $id, input: $input) {
      ... ActivityFragment
    }
  }
`);

export const useUpdateActivity = () => {
  const alerts = useAlerts();
  const mutation = useMutation<UpdateActivityMutation>(UPDATE_ACTIVITY, {
    refetchQueries: ["TierIIReportActivities"],
    onCompleted(data) {
      alerts.success(`Successfully updated Activity ${data.updateActivity.id}`);
    },
    onError(error) {
      console.error(error);
      alerts.error(`An error occurred while updated the Activity`, error);
    },
    optimisticResponse: (variables) => ({
      __typename: "Mutation",
      updateActivity: {
        __typename: "Activity",
        id: variables.id,
        ...variables.input,
      },
    }),
  });

  return mutation;
};

const UPDATE_ACTIVITIES = gql(`
  mutation UpdateActivities($ids: [ID!]!, $input: ActivityInput!) {
    updateActivities(ids: $ids, input: $input) {
      ... ActivityFragment
    }
  }
`);

export const useUpdateActivities = () => {
  const alerts = useAlerts();
  const mutation = useMutation(UPDATE_ACTIVITIES, {
    refetchQueries: ["TierIIReportActivities", "TierIIReportActivitiesInError"],
    onCompleted(data) {
      alerts.success(
        `Successfully updated ${data.updateActivities.length} ${pluralize(
          "activity",
          data.updateActivities.length
        )}`
      );
    },
    onError(error) {
      console.error(error);
      alerts.error(`An error occurred while updating activities`, error);
    },
  });

  return mutation;
};
