import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EventMutationScope } from "generated-graphql/graphql";

interface RecurrenceScopeRadioDialogProps {
  action: "Delete" | "Edit";
  hideOptions?: EventMutationScope[];
  onSubmit: (scope: EventMutationScope) => void;
  onClose: () => void;
  open: boolean;
  loading?: boolean;
}

const prettyPrintScope = (scope: EventMutationScope) => {
  switch (scope) {
    case EventMutationScope.This:
      return "This event";
    case EventMutationScope.ThisAndFollowing:
      return "This and following events";
    case EventMutationScope.All:
      return "All events";
  }
};

// This is the order of the options in the UI
const scopeOptions: EventMutationScope[] = [
  EventMutationScope.This,
  EventMutationScope.ThisAndFollowing,
  EventMutationScope.All,
];

const RecurrenceScopeRadioDialog: React.FC<RecurrenceScopeRadioDialogProps> = ({
  action,
  hideOptions = [],
  onSubmit,
  onClose,
  open,
  loading = false,
}) => {
  const [visibleScopeOptions, setVisibleScopeOptions] = useState<
    EventMutationScope[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<EventMutationScope>(
    EventMutationScope.This
  );

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value as EventMutationScope);
  };

  const handleSubmit = useCallback(() => {
    onSubmit(selectedOption);
  }, [onSubmit, selectedOption]);

  // shouldn't need this but there is a race condition
  // causing the dialog to flash all options before closing
  useEffect(() => {
    if (open) {
      setVisibleScopeOptions(
        scopeOptions.filter((option) => !hideOptions.includes(option))
      );
    }
  }, [hideOptions, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${action} recurring event`}</DialogTitle>
      <DialogContent>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          {visibleScopeOptions.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={
                <Typography variant="body1">
                  {prettyPrintScope(option)}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          loading={loading}
          loadingPosition="start"
        >
          {action === "Edit" ? "Save" : action}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RecurrenceScopeRadioDialog;
