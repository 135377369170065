import invariant from "tiny-invariant";

export function calculateAmountPerAssociation(
  totalAmount: number | null | undefined,
  associationsLength: number
): number[] {
  invariant(
    associationsLength > 0,
    "associationsLength must be greater than 0"
  );
  const factor = Math.pow(10, 2);
  const safeAmount: number = totalAmount ?? 0;
  const intTotalAmount = Math.round(safeAmount * factor);
  const intBaseAssociationAmount: number = Math.round(
    intTotalAmount / associationsLength
  );
  const intRemainder: number =
    intTotalAmount - intBaseAssociationAmount * associationsLength;
  const intAmountPerAssociation = [];
  for (let i = 0; i < associationsLength; i++) {
    if (i === 0) {
      intAmountPerAssociation.push(intBaseAssociationAmount + intRemainder);
    } else {
      intAmountPerAssociation.push(intBaseAssociationAmount);
    }
  }

  return intAmountPerAssociation.map((amount) => amount / factor);
}
