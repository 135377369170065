import {
  Autocomplete,
  TextField,
  FilterOptionsState,
  createFilterOptions,
} from "@mui/material";
import { FieldValues, Control, Controller, FieldPath } from "react-hook-form";

type SelectWithFreeSoloProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  options: Record<string, string>;
  disabled?: boolean;
};

export const SelectWithFreeSolo = <T extends FieldValues>({
  name,
  control,
  options,
  disabled,
}: SelectWithFreeSoloProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          freeSolo
          clearOnBlur
          options={Object.keys(options)}
          filterOptions={filterOptions}
          renderInput={(params) => <TextField {...params} label="Name" />}
          getOptionLabel={(option) => getOptionLabel(option, options)}
          onChange={(_, data) => {
            field.onChange(data);
          }}
          onBlur={() => field.onBlur()}
          disabled={disabled}
        />
      )}
    />
  );
};

function getOptionLabel(option: string, options: Record<string, string>) {
  if (Object.keys(options).includes(option)) {
    return options[option];
  }

  return option;
}

function filterOptions(
  options: string[],
  params: FilterOptionsState<string>
): string[] {
  const filterFunction = createFilterOptions<string>();
  const filteredOptions = filterFunction(options, params);
  const { inputValue } = params;
  // Suggest the creation of a new value
  const isExisting = options.some((option) => inputValue === option);
  if (inputValue !== "" && !isExisting) {
    filteredOptions.push(inputValue);
  }

  return filteredOptions;
}
