import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

type TimeFilterProps = {
  value: string;
  header: string;
  id: string;
  onChange: (value: string) => void;
  handleApply: () => void;
};

export const TimeFilter: React.FC<TimeFilterProps> = ({
  value,
  id,
  header,
  onChange,
  handleApply,
}) => {
  const [initialDirection, initialAmount, initialUnit] = value.split(" ");

  const [direction, setDirection] = useState<string>(initialDirection || ">");
  const [amount, setAmount] = useState<string>(initialAmount || "");
  const [unit, setUnit] = useState<string>(initialUnit || "days");

  useEffect(() => {
    setDirection(initialDirection || ">");
    setAmount(initialAmount || "");
    setUnit(initialUnit || "days");
  }, [initialDirection, initialAmount, initialUnit]);

  const handleChange = useCallback(
    (newDirection: string, newAmount: string, newUnit: string) => {
      let newValue = "";
      if (newDirection && newAmount && newUnit) {
        newValue = `${newDirection} ${newAmount} ${newUnit}`;
      }
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Box
      sx={{ display: "inline-flex" }}
      flexDirection="row"
      justifyContent="left"
    >
      <FormControl>
        <InputLabel id="direction-label" size="small" sx={{ mt: 2 }}>
          {header}
        </InputLabel>
        <Select
          labelId="direction-label"
          label={header}
          value={direction}
          key={`${id}-direction`}
          size="small"
          sx={{ mt: 2, mr: 1 }}
          onChange={(e) => {
            const newDirection = e.target.value as string;
            setDirection(newDirection);
            handleChange(newDirection, amount, unit);
          }}
        >
          <MenuItem value=">">Older than</MenuItem>
          <MenuItem value="<">Newer than</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Amount"
        value={amount}
        key={`${id}-amount`}
        size="small"
        sx={{ mt: 2, mr: 1 }}
        onChange={(e) => {
          const newAmount = e.target.value;
          setAmount(newAmount);
          handleChange(direction, newAmount, unit);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleApply();
          }
        }}
      />
      <FormControl>
        <InputLabel id="unit-label" size="small" sx={{ mt: 2 }}>
          Unit
        </InputLabel>
        <Select
          labelId="unit-label"
          label="Unit"
          value={unit}
          key={`${id}-unit`}
          size="small"
          sx={{ mt: 2 }}
          onChange={(e) => {
            const newUnit = e.target.value as string;
            setUnit(newUnit);
            handleChange(direction, amount, newUnit);
          }}
        >
          <MenuItem value="minutes">Minutes</MenuItem>
          <MenuItem value="hours">Hours</MenuItem>
          <MenuItem value="days">Days</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
