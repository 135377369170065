import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { FormTextField } from "components/Forms/FormTextField";
import { CheckboxField } from "components/Forms/CheckboxField";
import { FormSelect } from "components/Forms/FormSelect";
import { SaveButton } from "components/SaveButton";
import { LepcInput } from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import { GET_LEPC, UPSERT_LEPC, LEPC_SEARCH, VALIDATE_LEPC_INPUT } from "./api";
import { hasCriticalIssues } from "util/forms";
import { useCallback } from "react";
import { STATES } from "encamp-shared/src/constants/states";
import { transform } from "hooks/transform/transformLepc";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";

export function useLepcInputValidation(isCreate: boolean) {
  const [query] = useLazyQuery(VALIDATE_LEPC_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: LepcInput) => {
      const { data } = await query({
        variables: {
          data: transform(input),
          id: !isCreate ? input.id : undefined,
        },
      });

      return data?.lepcInputValidator ?? [];
    },
    [query, isCreate]
  );
}

type LepcDialogProps = {
  onClose: () => void;
  mode?: "add" | "edit";
  lepcId?: string;
};

export const CreateEditLepcDialog: React.FC<LepcDialogProps> = ({
  onClose,
  mode,
  lepcId,
}) => {
  const theme = useTheme();
  const alerts = useAlerts();
  const [additionalFieldsOpen, setAdditionalFieldsOpen] = useState(false);

  const { data: agency, loading: isLoadingAgency } = useQuery(GET_LEPC, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: lepcId ?? "",
    },
    skip: !lepcId,
  });

  const [upsertLepc, { loading: isUpserting }] = useMutation(UPSERT_LEPC, {
    refetchQueries: ["Lepc", LEPC_SEARCH],
  });

  const defaultValues: LepcInput = useMemo(
    () => ({
      id: agency?.lepc.id ?? undefined,
      name: agency?.lepc?.name ?? "",
      state: agency?.lepc?.state ?? "",
      city: agency?.lepc?.city ?? "",
      county: agency?.lepc?.county ?? "",
      zip: agency?.lepc?.zip ?? "",
      mailingAddress: agency?.lepc?.mailingAddress ?? "",
      mailingAddressLine2: agency?.lepc?.mailingAddressLine2 ?? "",
      contactName: agency?.lepc?.contactName ?? "",
      email: agency?.lepc?.email ?? "",
      phone: agency?.lepc?.phone ?? "",
      feeStructure: agency?.lepc?.feeStructure ?? "",
      latitude: agency?.lepc?.latitude ?? null,
      longitude: agency?.lepc?.longitude ?? null,
      leadAgency: agency?.lepc?.leadAgency ?? null,
      paymentMethod: agency?.lepc?.paymentMethod ?? null,
      t2sValue: agency?.lepc?.t2sValue ?? null,
      submissionNotes: agency?.lepc?.submissionNotes ?? null,
      requirementDetails: agency?.lepc?.requirementDetails ?? null,
      additionalComments: agency?.lepc?.additionalComments ?? "",
      additionaRequirements: agency?.lepc?.additionaRequirements ?? "",
      additionalFees: agency?.lepc?.additionalFees ?? "",
      isEmailed: agency?.lepc?.isEmailed ?? false,
      isCurrent1AsOf2021: agency?.lepc?.isCurrent1AsOf2021 ?? false,
      isMailed: agency?.lepc?.isMailed ?? false,
      deactivated: agency?.lepc?.deactivated ?? false,
      isStateSubmits: agency?.lepc?.isStateSubmits ?? false,
      needsT2sFile: agency?.lepc?.needsT2sFile ?? false,
      isSubmittedViaPortal: agency?.lepc?.isSubmittedViaPortal ?? false,
      needsXmlZipFile: agency?.lepc?.needsXmlZipFile ?? false,
      portalId: agency?.lepc?.portalId ?? "",
      portalName: agency?.lepc?.portalName ?? "",
    }),
    [agency]
  );

  const form = useValidatingForm<LepcInput>(
    defaultValues,
    agency?.lepc.issues ?? [],
    useLepcInputValidation(mode === "add")
  );

  const {
    control,
    getValues,
    issues,

    formState: { errors },
  } = form;

  const onSubmit = async () => {
    const input = {
      ...getValues(),
    };

    await upsertLepc({
      variables: {
        id: lepcId,
        input: transform(input),
      },
      onCompleted: (data) => {
        onClose();
        alerts.success("LEPC saved successfully");
      },
      onError: (error) => {
        alerts.error(
          error.message ?? "An error occurred while saving the LEPC"
        );
      },
    });
  };

  const savingOrLoading = isLoadingAgency || isUpserting;
  return (
    <>
      <Dialog open onClose={onClose} maxWidth="lg">
        <DialogTitle>
          {mode === "add" ? "Add" : "Edit"} {"LEPC"}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 500 }}>
          <FormProvider {...form}>
            <Box
              component="form"
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                width: 500,
              }}
            >
              <Grid container spacing={4} justifyContent="center" pt={1}>
                <Grid item xs={12}>
                  <FormTextField
                    name="name"
                    label="Name"
                    control={control}
                    textFieldProps={{ required: true }}
                  />
                  <FormTextField
                    name="mailingAddress"
                    label="Mailing Address"
                    control={control}
                  />
                  <FormTextField
                    name="mailingAddressLine2"
                    label="Mailing Address Line 2"
                    control={control}
                  />
                  <FormTextField name="city" label="City" control={control} />
                  <FormTextField
                    name="county"
                    label="County"
                    control={control}
                  />
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={6}>
                      <FormSelect
                        name="state"
                        label="State"
                        control={control}
                        selectItems={STATES.map((state) => ({
                          value: state,
                          display: state,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextField name="zip" label="Zip" control={control} />
                    </Grid>
                  </Grid>
                  <FormTextField name="email" label="Email" control={control} />
                  <FormTextField
                    name="additionalComments"
                    label="Additional Comments"
                    control={control}
                    textFieldProps={{ multiline: true }}
                  />

                  <Grid item xs={12}>
                    <ErrorDisplay
                      errors={errors}
                      fields={[
                        "isEmailed",
                        // These are commented out because they all share
                        // essentially the same error message, and it's more
                        // clear if the message is only listed once
                        // -  "isMailed",
                        // -  "isSubmittedViaPortal",
                        // -  "isStateSubmits",
                      ]}
                    />
                  </Grid>

                  <CheckboxField
                    name="isEmailed"
                    label="Is Emailed"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="isMailed"
                    label="Is Mailed"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="isSubmittedViaPortal"
                    label="Is submitted via portal"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="isStateSubmits"
                    label="Is submitted by the state"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="deactivated"
                    label="Deactivated"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="needsT2sFile"
                    label="Needs T2s File"
                    control={control}
                    noErrorDisplay
                  />
                  <CheckboxField
                    name="needsXmlZipFile"
                    label="Needs XML Zip File"
                    control={control}
                    noErrorDisplay
                  />
                  <Typography
                    onClick={() => setAdditionalFieldsOpen((prev) => !prev)}
                    sx={{ cursor: "pointer" }}
                    my={3}
                    color="primary"
                    gutterBottom
                  >
                    Show Additional Fields
                  </Typography>
                  <Collapse
                    in={additionalFieldsOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <FormTextField
                      name="contactName"
                      label="Contact Name"
                      control={control}
                    />
                    <FormTextField
                      name="phone"
                      label="Phone"
                      control={control}
                    />
                    <FormTextField
                      name="feeStructure"
                      label="Fee Structure"
                      control={control}
                    />
                    <FormTextField
                      name="latitude"
                      label="Latitude"
                      control={control}
                    />
                    <FormTextField
                      name="longitude"
                      label="Longitude"
                      control={control}
                    />
                    <FormTextField
                      name="leadAgency"
                      label="Lead Agency"
                      control={control}
                    />
                    <FormTextField
                      name="paymentMethod"
                      label="Payment Method"
                      control={control}
                    />
                    <FormTextField
                      name="t2sValue"
                      label="T2s Value"
                      control={control}
                    />
                    <FormTextField
                      name="submissionNotes"
                      label="Submission Notes"
                      control={control}
                      textFieldProps={{ multiline: true }}
                    />
                    <FormTextField
                      name="requirementDetails"
                      label="Requirement Details"
                      control={control}
                    />
                    <FormTextField
                      name="additionaRequirements"
                      label="Additional Requirements"
                      control={control}
                    />
                    <FormTextField
                      name="additionalFees"
                      label="Additional Fees"
                      control={control}
                    />
                    <CheckboxField
                      name="isCurrent1AsOf2021"
                      label="Is Current 1 as of 2021"
                      control={control}
                      noErrorDisplay
                    />
                    <FormTextField
                      name="portalId"
                      label="Portal Id"
                      control={control}
                    />
                    <FormTextField
                      name="portalName"
                      label="Portal Name"
                      control={control}
                    />
                  </Collapse>
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
          <Button onClick={onClose}>Cancel</Button>
          <SaveButton
            loading={savingOrLoading}
            saveText="Save"
            onClick={onSubmit}
            disabled={
              hasCriticalIssues(issues) ||
              (mode === "add" && !form.formState.isDirty)
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
