import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
} from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";

export type AutocompleteItem = {
  value: any;
  label: string;
};

export const FormAutocomplete = <T extends FieldValues>({
  name,
  label,
  tooltip,
  disabled,
  control,
  autocompleteProps,
  autocompleteItems,
  helperText,
  rules,
  variant = "outlined",
  sx,
}: {
  label: string;
  tooltip?: string;
  disabled?: boolean;
  autocompleteProps?: AutocompleteProps<AutocompleteItem, false, false, false>;
  autocompleteItems: AutocompleteItem[];
  helperText?: string;
  rules?: RegisterOptions;
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps<Theme>;
} & UseControllerProps<T>) => {
  const theme = useTheme();
  const inputLabelId = useMemo(() => `${name}-label`, [name]);
  const labelWithTooltip = useMemo(
    () =>
      tooltip ? <TextWithTooltip text={label} tooltip={tooltip} /> : label,
    [label, tooltip]
  );

  return (
    <FormControl fullWidth sx={sx}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <Autocomplete
              getOptionLabel={(option: AutocompleteItem) => option.label}
              isOptionEqualToValue={(option: AutocompleteItem, selected) =>
                option?.value === selected?.value
              }
              {...autocompleteProps}
              options={autocompleteItems}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={labelWithTooltip}
                  variant={variant}
                  error={!!error}
                  InputLabelProps={{
                    id: inputLabelId,
                    style: {
                      color: disabled
                        ? theme.palette.action.disabled
                        : error
                        ? theme.palette.error.main
                        : theme.palette.text.primary,
                    },
                  }}
                />
              )}
              {...field}
              value={
                autocompleteItems.find((item) => item.value === field.value) ??
                null
              }
              onChange={(_, data) => field.onChange(data?.value ?? null)}
            />
            <ErrorDisplay error={error} helperText={helperText} />
          </>
        )}
      />
    </FormControl>
  );
};
