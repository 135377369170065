import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { LicenseInfo } from "@mui/x-data-grid-premium";

export default function configureMuiInstallation() {
  // This key is intended to be public, so it's okay to commit it to the repo.
  // Please see https://mui.com/x/introduction/licensing/#security for more
  // details on licensing and installation of the premium package. To stay
  // compliant with the End User License Agreement
  // (https://mui.com/legal/mui-x-eula/#license-key), Encamp must purchase
  // licenses for every developer working in the front-end of the application and
  // interacting with these components. However, only one key needs to be deployed
  // in production. This key has a perpetual license for the use in deployed,
  // production software.
  //    - ben@encamp.com 2023-04-26 18:34:54
  LicenseInfo.setLicenseKey(
    "4dd4e55ae14e05e05b37848c607ec27dTz04NzYxNSxFPTE3NDM2MjE0MTkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
  );
}
