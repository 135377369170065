import { Paper } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import React from "react";
import { ClickableGridCell } from "../../../../../components/ClickableGridCell";
import { DataGrid } from "../../../../../components/DataGrid";
import { ReportingSeasonSummaryQuery } from "../../../../../generated-graphql/graphql";
import { AssignmentManagementTableFilterKeys } from "../../AssignmentManagerTable/useAssignmentManagerOmnisearch";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";

interface UnverifiedTableProps {
  type: "Organization" | "State";
  data?: ReportingSeasonSummaryQuery["reportingSeasonSummary"]["organizationSummaries"];
  loading: boolean;
}

export const UnverifiedTable: React.FC<UnverifiedTableProps> = ({
  type = "Organization",
  data = [],
  loading,
}) => {
  const { createUrlFromOmnisearchKeyValues } = useOmnisearchDatagrid();

  const columns: GridColDef[] = [
    { field: "name", headerName: type, flex: 1 },
    {
      field: "unverifiedCount",
      headerName: "Unverified",
      flex: 1,
      type: "number",
      sortable: true,
      renderCell: (params) => {
        return (
          <ClickableGridCell
              href={createUrlFromOmnisearchKeyValues([
                [AssignmentManagementTableFilterKeys.Unverified, "true"],
                [
                  AssignmentManagementTableFilterKeys[type],
                  params.row.name.includes(" ")
                    ? `"${params.row.name}"`
                    : params.row.name,
                ],
              ])
            }
          >
            {params.value}
          </ClickableGridCell>
        );
      },
    },
    {
      field: "reportCount",
      headerName: "# of Reporting Facilities",
      flex: 1,
      type: "number",
      sortable: true,
      renderCell: (params) => {
        return (
          <ClickableGridCell
            href={
              createUrlFromOmnisearchKeyValues([
                [
                  AssignmentManagementTableFilterKeys[type],
                  params.row.name.includes(" ")
                    ? `"${params.row.name}"`
                    : params.row.name,
                ],
              ])
            }
          >
            {params.value}
          </ClickableGridCell>
        );
      },
    },
  ];

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "unverifiedCount",
                sort: "desc",
              },
            ],
          },
        }}
        autoPageSize
        pagination
      />
    </Paper>
  );
};
