import { useMutation } from "@apollo/client";
import { TierIiReportStep } from "generated-graphql/graphql";
import { useCallback } from "react";
import { useNavigateReportSaveCancel } from "./useNavigateReportSaveCancel";
import { GET_REPORT } from "./useReport";
import { gql } from "generated-graphql";

const TOUCH_TIER_II_REPORT_STEP = gql(`
  mutation TouchTierIIReportStep($reportId: ID!, $step: TierIIReportStep!) {
    touchTierIIReportStep(reportId: $reportId, step: $step)
  }
`);

export function useTouchReportMutation(
  reportId: string,
  touchedSteps: TierIiReportStep[],
  step: TierIiReportStep
) {
  const navigateToOverview = useNavigateReportSaveCancel();

  const [touchTierIIReportStep, { loading }] = useMutation(
    TOUCH_TIER_II_REPORT_STEP,
    {
      refetchQueries: [GET_REPORT],
      update: (cache) => {
        cache.modify({
          id: cache.identify({
            __typename: "TierIIReport",
            id: reportId,
          }),
          fields: {
            touchedSteps: () => Array.from(new Set([...touchedSteps, step])),
          },
          optimistic: true,
          broadcast: true,
        });
      },
    }
  );
  const handleSave = useCallback(async () => {
    if (!touchedSteps.includes(step)) {
      await touchTierIIReportStep({
        variables: {
          reportId,
          step,
        },
      });
    }
    navigateToOverview();
  }, [reportId, step, navigateToOverview, touchTierIIReportStep, touchedSteps]);

  return { handleSave, loading };
}
