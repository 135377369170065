import AccountBox from "@mui/icons-material/AccountBox";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { useUpdateFacilities } from "./useUpdateFacilities";
import { useCallback } from "react";
import { useBulkActionContext } from ".";

export function SetDefaultExecutionPlanManual() {
  const { selectedRows, onClose } = useBulkActionContext();
  const alerts = useAlerts();
  const theme = useTheme();
  const [update] = useUpdateFacilities();

  const setDefaultExecutionPlanToManual = useCallback(async () => {
    try {
      await update({
        variables: {
          ids: selectedRows.map((row) => row.facilityId).filter(hasValue),
          facility: {
            reportsManually: true,
          },
        },
      });
      alerts.success(`Set facilities to default manual mode`);
    } catch (error) {
      alerts.error(`Error: unable to set facilities to default manual `);
    } finally {
      onClose();
    }
  }, [update, selectedRows, alerts, onClose]);

  return (
    <MenuItem onClick={setDefaultExecutionPlanToManual}>
      <ListItemIcon>
        <AccountBox />
      </ListItemIcon>
      <Tooltip title="New workflows for this facility will set geppetto to manual">
        <ListItemText sx={{ pr: theme.spacing(1) }}>
          Set Default Execution Plan for Geppetto: Manual
        </ListItemText>
      </Tooltip>
    </MenuItem>
  );
}
