import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { DateField } from "components/Forms/DateField";
import { FormTextField } from "components/Forms/FormTextField";
import { IssueListButton } from "components/Forms/IssueListButton";
import { ProductPicker } from "components/ProductPicker";
import { SaveButton } from "components/SaveButton";
import { SkeletonFormGroup } from "components/Skeleton";
import { StorageLocationPicker } from "components/StorageLocationPicker";
import { gql } from "generated-graphql";
import {
  FacilityProductMeasurementInput,
  InsideOutside,
  ProductPickerFragment,
  StorageLocationPickerFragment,
  StorageQuadrant,
} from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { hasCriticalIssues } from "util/forms";
import { v4 as uuid } from "uuid";
import { FACILITY_PRODUCT_MEASUREMENT } from "./Inventory/Facility/schema";
import { PRODUCTS_WITH_MEASUREMENTS } from "./ProductsWithMeasurementsTable";
import { useFacilityProductMeasurementInputValidation } from "./Report/validationHooks/useFacilityProductMeasurementInputValidation";
import { CatalogLink } from "components/CatalogLink";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { FormSelect } from "components/Forms/FormSelect";
import { useReport } from "./Report/useReport";
import { useFacility } from "../Facility/useFacility";

const STORAGE_LOCATION_MEASUREMENT_TOOLTIP = (
  <div>
    <p>
      Storage Locations must be unique and are reported individually per
      chemical in your product.
    </p>
    <p>
      If you wish to record a measurement for a specific vessel at the same
      general location at your facility, include the container ID in the Storage
      Location. For example: {'"'}
      West building (Tank A).{'"'}
    </p>
    <p>
      Recording a second measurement for the same product, at the same location,
      on the same day will cause the second measurement to supersede the first.
    </p>
  </div>
);

type FormType = {
  id: string | null;
  product: ProductPickerFragment | null;
  storageLocation: StorageLocationPickerFragment | null;
  measuredAtUtc: string;
  quantity: number | null;
};

const mapFormTypeToFacilityProductMeasurementInput = (
  form: FormType,
  facilityId: string
): FacilityProductMeasurementInput & { id: string } => {
  return {
    id: form.id ?? "",
    facilityId: facilityId,
    productId: form?.product?.id ?? "",
    storageLocation: {
      ...form.storageLocation,
      description: form.storageLocation?.description ?? "",
      facilityId: form.storageLocation?.facilityId ?? "",
      id: form.storageLocation?.id ?? "",
      OR_insideOutside: form?.storageLocation?.OR_insideOutside ?? null,
    },
    storageLocationId: form?.storageLocation?.id ?? "",
    measuredAtUtc: form.measuredAtUtc,
    quantity: form.quantity,
  };
};

const UPSERT_FACILITY_PRODUCT_MEASUREMENT_MUTATION = gql(/* GraphQL */ `
  mutation UpsertFacilityProductMeasurement(
    $id: ID!
    $input: FacilityProductMeasurementInput!
  ) {
    upsertFacilityProductMeasurement(id: $id, input: $input) {
      id
    }
  }
`);

const FACILITY_PRODUCT_MEASUREMENT_QUERY = gql(/* GraphQL */ `
  query FacilityProductMeasurement($id: ID!) {
    facilityProductMeasurement(id: $id) {
      id
      measuredAtUtc
      quantity
      product {
        ...ProductPicker
      }
      storageLocationId
      storageLocation {
        ...StorageLocationPicker
      }
      issues {
        ...issue
      }
    }
  }
`);

type Props = {
  measurementId?: string;
  facilityId: string;
  onClose: () => void;
  open: boolean;
  product?: Pick<ProductPickerFragment, "id" | "name">;
  /**
   * id of measurement to prefill from
   */
  measurementSeed?: string;
};

export function FacilityProductMeasurementForm(props: Props) {
  const theme = useTheme();
  const alerts = useAlerts();
  const { tenantId } = useParams<{
    tenantId: string;
  }>();

  const {
    onClose,
    measurementId,
    facilityId,
    product: inputProduct,
    measurementSeed,
  } = props;

  const { data: reportData } = useReport();
  const { data: facilityData, loading } = useFacility();

  const facilityState =
    facilityData?.facility?.state ?? reportData?.tierIIReport.facility.state;

  const isOregonFacility = facilityState === "OR";
  const isCaliforniaFacility = facilityState === "CA";

  const { data, loading: measurementLoading } = useQuery(
    FACILITY_PRODUCT_MEASUREMENT_QUERY,
    {
      variables: { id: measurementId ?? measurementSeed ?? "" },
      skip: !measurementId && !measurementSeed,
    }
  );
  const measurement = data?.facilityProductMeasurement;

  const defaultValues: FormType = useMemo(() => {
    return {
      id: measurementSeed ? null : measurement?.id ?? null, // if using a seed, we don't want to pre-fill the id
      facilityId,
      product: inputProduct ?? measurement?.product ?? null,
      storageLocation: {
        ...measurement?.storageLocation,
        description: measurement?.storageLocation?.description ?? "",
        facilityId: measurement?.storageLocation?.facilityId ?? "",
        id: measurement?.storageLocation?.id ?? "",
      },
      measuredAtUtc:
        measurementSeed || !measurement
          ? DateTime.fromJSDate(new Date()).toISODate()
          : measurement?.measuredAtUtc,
      quantity: measurement?.quantity ?? null,
    };
  }, [measurement, measurementSeed, inputProduct, facilityId]);

  const validator = useFacilityProductMeasurementInputValidation();
  const { handleSubmit, control, issues, watch, setValue, trigger } =
    useValidatingForm<FormType>(
      defaultValues,
      measurementSeed ? [] : data?.facilityProductMeasurement?.issues,
      async (input: FormType) => {
        const mapped = mapFormTypeToFacilityProductMeasurementInput(
          input,
          facilityId
        );
        return await validator(mapped);
      }
    );

  const [mutate, { loading: saving }] = useMutation(
    UPSERT_FACILITY_PRODUCT_MEASUREMENT_MUTATION,
    {
      refetchQueries: [
        PRODUCTS_WITH_MEASUREMENTS,
        FACILITY_PRODUCT_MEASUREMENT,
      ],
    }
  );

  const onSubmit = useCallback(
    async (data: FormType) => {
      const input = mapFormTypeToFacilityProductMeasurementInput(
        data,
        facilityId
      );
      try {
        await mutate({
          variables: {
            id: input.id && input.id !== "" ? input.id : uuid(),
            input: {
              facilityId: input.facilityId,
              quantity: Number(input.quantity),
              productId: input.productId,
              measuredAtUtc: input.measuredAtUtc,
              storageLocation: input.storageLocation,
              storageLocationId: input.storageLocationId,
            },
          },
        });

        alerts.success("Successfully saved measurement data");
        onClose();
      } catch (err) {
        alerts.error("An error occurred while saving measurement data", err);
      }
    },
    [mutate, alerts, onClose, facilityId]
  );
  const product = watch("product");

  useEffect(() => {
    if (measurement) {
      trigger();
    }
  }, [trigger, measurement]);

  return (
    <Dialog open={props.open} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {measurementId ? "Edit" : "Add"} Product Measurement
        </DialogTitle>
        <DialogContent>
          {measurementLoading || loading ? (
            <SkeletonFormGroup count={8} />
          ) : (
            <Grid container spacing={theme.spacing(1)}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Product measurements remain relevant until another measurement
                  is made for the same product at the same Storage Location. If
                  a product is no longer present at a storage location, enter
                  '0' for quantity on hand on the date it was removed.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
                <Controller
                  name="product"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ProductPicker
                      {...field}
                      {...fieldState}
                      defaultSearchTerm={`tenantId:${tenantId}`}
                      required
                      readOnly={!!inputProduct || !!measurementId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <FormTextField
                  name="quantity"
                  helperText="Must be a whole number"
                  label="Quantity on Hand"
                  control={control}
                  intOnly
                  textFieldProps={{ required: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <DateField
                  label="Measurement Date"
                  control={control}
                  name="measuredAtUtc"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="storageLocation.description"
                  control={control}
                  render={({ field, fieldState }) => (
                    <StorageLocationPicker
                      disableClearable
                      facilityId={facilityId}
                      error={fieldState.error}
                      description={field.value}
                      onSelectLocation={(location) => {
                        setValue("storageLocation", location, {
                          shouldValidate: true,
                        });
                      }}
                      required
                      tooltip={STORAGE_LOCATION_MEASUREMENT_TOOLTIP}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {isOregonFacility && (
                  <>
                    <Grid item xs={12}>
                      <FormSelect
                        name="storageLocation.OR_insideOutside"
                        label="Inside/Outside *"
                        selectItems={Object.values(InsideOutside).map(
                          (insideOutside) => ({
                            display: prettyPrintEnumValue(insideOutside),
                            value: insideOutside,
                          })
                        )}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Storage Building *"
                        name="storageLocation.OR_storageBuilding"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Storage Floor"
                        name="storageLocation.OR_storageFloor"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="storageLocation.OR_storageQuadrant"
                        label="Storage Quadrant *"
                        selectItems={Object.values(StorageQuadrant).map(
                          (storageQuadrant) => ({
                            display: prettyPrintEnumValue(storageQuadrant),
                            value: storageQuadrant,
                          })
                        )}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Storage Room"
                        name="storageLocation.OR_storageRoom"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Storage Area *"
                        name="storageLocation.OR_storageArea"
                        control={control}
                      />
                    </Grid>
                  </>
                )}
                {isCaliforniaFacility && (
                  <>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Grid Number"
                        name="storageLocation.CA_gridNumber"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Map Number"
                        name="storageLocation.CA_mapNumber"
                        control={control}
                      />
                    </Grid>
                  </>
                )}
                <CatalogLink
                  id={product?.id ?? ""}
                  type="Product"
                  name={product?.name ?? ""}
                  issues={issues ?? []}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <IssueListButton issues={issues} />
          <FormControl>
            <Button variant="outlined" onClick={() => onClose()}>
              Cancel
            </Button>
          </FormControl>
          <FormControl>
            <SaveButton
              loading={saving || measurementLoading}
              disabled={hasCriticalIssues(issues)}
            />
          </FormControl>
        </DialogActions>
      </form>
    </Dialog>
  );
}
