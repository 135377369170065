import BadgeTwoTone from "@mui/icons-material/BadgeTwoTone";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useVerifyCredentials } from "hooks/useVerifyCredentials";
import { useBulkActionContext } from ".";

export function VerifyCredentialsMenuItem() {
  const { selectedRows, onClose } = useBulkActionContext();
  const alerts = useAlerts();
  const [verifyCredentials] = useVerifyCredentials();
  const onClick = async () => {
    const facilityIdObjects = selectedRows.map((row) => ({
      facilityId: row.facilityId,
    }));
    const res = await verifyCredentials({
      variables: {
        input: facilityIdObjects,
      },
    });
    alerts.info(
      `Attempting to verify Credentials for ${res.data?.verifyCredentials.count} facilities -- check back later!`
    );
    onClose();
  };

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <BadgeTwoTone />
      </ListItemIcon>
      <ListItemText>Verify Credentials</ListItemText>
    </MenuItem>
  );
}
