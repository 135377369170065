import { DashboardTile } from "components/Dashboard/DashboardTile";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import {
  FacilityWasteStreamsQuery,
  FacilityWasteStreamsQueryVariables,
} from "generated-graphql/graphql";
import { useParams } from "react-router-dom";

const FACILITY_WASTE_STREAMS = gql(`
  query FacilityWasteStreams(
    $facilityId: ID!,
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    facility(id: $facilityId) {
      wasteStreams(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
        items {
          id
          dotDescription
          federalWasteCodes
          stateWasteCodes
        }
        count
      }
    }
  }
`);

export const WasteStreamsTile = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  return (
    <DashboardTile>
      <DashboardTile.Header title="Waste Streams" />
      <DashboardTile.ContentArea xs={12}>
        <OmnisearchDataGrid<
          FacilityWasteStreamsQuery,
          FacilityWasteStreamsQueryVariables,
          NonNullable<
            NonNullable<FacilityWasteStreamsQuery["facility"]>["wasteStreams"]
          >["items"][number]
        >
          columns={[
            {
              field: "dotDescription",
              headerName: "DOT Description",
              flex: 1,
              minWidth: 100,
            },
            {
              field: "classification",
              headerName: "Classification",
              sortable: false,
              flex: 0.5,
              maxWidth: 150,
            },
            {
              field: "wasteCodes",
              headerName: "Waste Codes",
              sortable: false,
              flex: 0.5,
              minWidth: 100,
              maxWidth: 200,
              valueGetter({ row }) {
                const stateWasteCodes = row.stateWasteCodes || [];
                const federalWasteCodes = row.federalWasteCodes || [];
                return [...stateWasteCodes, ...federalWasteCodes].join(", ");
              },
            },
            {
              field: "profileNumber",
              headerName: "Profile #",
              sortable: false,
              flex: 0.5,
              maxWidth: 150,
            },
            {
              field: "vendor",
              headerName: "Vendor",
              sortable: false,
              flex: 0.5,
              maxWidth: 150,
            },
            {
              field: "generated",
              headerName: "Generated",
              sortable: false,
              flex: 0.5,
              maxWidth: 150,
            },
          ]}
          dataQuery={FACILITY_WASTE_STREAMS}
          getItems={(data) => data.facility?.wasteStreams?.items ?? []}
          getCount={(data) => data.facility?.wasteStreams?.count ?? 0}
          dataQueryVariables={{ facilityId: facilityId ?? "" }}
          initialPageSize={10}
          showFavorites={true}
          withPadding={false}
          noDataMessage={"No Waste Streams found for this facility."}
          initialSortModel={[{ field: "dotDescription", sort: "asc" }]}
          excludeFilterColumns={[
            "classification",
            "wasteCodes",
            "profileNumber",
            "vendor",
            "generated",
          ]}
        />
      </DashboardTile.ContentArea>
    </DashboardTile>
  );
};
