import { useMeasurements } from "hooks/useMeasurements";
import { FacilityProductsTable } from "./FacilityProductsTable";
import { ProductsWithMeasurementsTable } from "./ProductsWithMeasurementsTable";
import { reportStepMetadata } from "util/constants";
import { StepperPageHeader } from "./Report/StepperPageHeader";

export type ProductsTableProps = {
  facilityId: string;
  reportId?: string;
};

export function ProductsTable(props: ProductsTableProps) {
  const { hasMeasurements } = useMeasurements(props.facilityId);

  let table;
  if (hasMeasurements) {
    table = <ProductsWithMeasurementsTable {...props} />;
  } else {
    table = <FacilityProductsTable {...props} />;
  }

  return (
    <>
      <StepperPageHeader
        header={reportStepMetadata.PRODUCTS.title}
        description={reportStepMetadata.PRODUCTS.overviewDescription}
      />
      {table}
    </>
  );
}
