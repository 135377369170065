import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";

const GENERATE_TIER_II_REPORT_PREVIEW = gql(`
mutation GenerateTierIIReportPreview($tierIIReportId: ID!) {
  generateTierIIPdfPreview(tierIIReportId: $tierIIReportId, attachSitePlan: true)
}
`);

export const useDownloadReportPreview = () => {
  const [generateReportPreview, { loading: loadingPreview }] = useMutation(
    GENERATE_TIER_II_REPORT_PREVIEW
  );

  const alerts = useAlerts();

  const downloadReportPreview = useCallback(
    async (reportId: string | null) => {
      if (!reportId) {
        alerts.error("Tier II Report ID not found");
        return;
      }

      alerts.info("Initiated PDF Preview. This may take awhile.");
      try {
        const { data } = await generateReportPreview({
          variables: { tierIIReportId: reportId },
        });

        if (data?.generateTierIIPdfPreview) {
          window.open(data.generateTierIIPdfPreview, "_blank");
          return;
        }

        alerts.error("Error generating report");
      } catch (err) {
        alerts.error("Error generating report", err);
        console.error(err);
      }
    },
    [generateReportPreview, alerts]
  );

  return { downloadReportPreview, loadingPreview };
};
