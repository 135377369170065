import { ChemicalInput } from "generated-graphql/graphql";
import { ChemicalDetailInputs } from "../../routes/Customer/Chemicals/Catalog/Chemical";
import { omit } from "lodash";
import { transform as transformStateField } from "./transformStateField";
import { transform as transformDocument } from "./transformDocument";
import { v4 } from "uuid";

export function transform(input: ChemicalDetailInputs): ChemicalInput {
  return {
    ...omit(input, "__typename", "id", "issues", "verifier"),
    components: input.components?.map((c) => ({
      ...omit(c, "__typename"),
      componentPercentage: parseFloat(String(c.componentPercentage)),
      id: c.id ?? v4(),
    })),
    documents: input.documents?.map((cd) => ({
      ...omit(cd, "chemical"),
      key: cd.key,
      name: cd.name,
      chemicalId: input.id,
      documentId: cd.documentId,
      document: transformDocument(cd.document),
    })),
    density: parseFloat(String(input.density)),
    stateFields: input.stateFields?.map(transformStateField),
    verifiedAt: null,
    verifiedBy: null,
  };
}
