import { STATE_NAMES } from "../constants/states";

export function convertAbbreviationToStateName(
  abbreviation: string
): string | undefined {
  const standardAbbreviation = abbreviation.trim().toUpperCase();

  return getStateName(standardAbbreviation);
}

export function convertStateNameToAbbreviation(
  stateName: string
): string | undefined {
  const standardStateName = stateName.trim().toUpperCase();

  return getAbbreviation(standardStateName);
}

export function convertToAbbreviation(input: string): string | undefined {
  const standardInput = input.trim().toUpperCase();

  // Check if the input is an abbreviation.
  if (getStateName(standardInput)) {
    return standardInput;
  }

  // Check if the input is a state name.
  return convertStateNameToAbbreviation(standardInput);
}

export function convertToStateName(input: string): string | undefined {
  const standardInput = input.trim().toUpperCase();

  // Check if the input is a state name.
  const abbreviation = getAbbreviation(standardInput);
  if (abbreviation) {
    return getStateName(abbreviation);
  }

  // Check if the input is an abbreviation.
  return convertAbbreviationToStateName(standardInput);
}

function getAbbreviation(stateName: string): string | undefined {
  return Object.keys(STATE_NAMES).find(
    (key) => getStateName(key)?.toUpperCase() === stateName
  );
}

function getStateName(abbreviation: string): string | undefined {
  return STATE_NAMES[abbreviation as keyof typeof STATE_NAMES];
}
