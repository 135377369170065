import { useMutation } from "@apollo/client";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { gql } from "generated-graphql";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Reviewer } from "./ReviewForm";

export const SUBMIT_REVIEW = gql(`
  mutation SetReviewComplete($reportId: ID!, $reviewComplete: Boolean!) {
    setReviewComplete(id: $reportId, reviewComplete: $reviewComplete) {
      id
      organizationStatus
      notesToRegulators
      touchedSteps
      reviewers {
        id
        assignmentType
        isComplete
        person {
          id
          first
          last
          phones {
            number
            type
          }
        }
      }
    }
  }
`);

export function SubmitReviewButtons({ reviewer }: { reviewer?: Reviewer }) {
  const theme = useTheme();
  const alerts = useAlerts();
  const { reportId = "" } = useParams<{ reportId: string }>();
  const [showReviewReport, setShowReviewReport] = useState(false);
  const [showRemoveReview, setShowRemoveReview] = useState(false);

  const [setReviewComplete, { loading: submittingReview }] = useMutation(
    SUBMIT_REVIEW,
    {
      update(cache, { data }) {
        const reportData = data?.setReviewComplete;
        if (!reportData) {
          return;
        }
        const updatedReviewer = reportData.reviewers.find(
          (r) => r.person.id === reviewer?.id
        );

        if (updatedReviewer) {
          cache.modify({
            id: cache.identify({
              __typename: "TierIIReport",
              id: reportData.id,
            }),
            fields: {
              reviewers: (existingRefs = [], { readField }) => {
                return existingRefs?.map((ref: any) => {
                  if (readField("id", ref) === updatedReviewer?.id) {
                    return {
                      ...ref,
                      isComplete: updatedReviewer.isComplete,
                    };
                  }
                  return ref;
                });
              },
            },
          });
        }
      },
    }
  );

  const handleSubmitReview = useCallback(
    async (reviewComplete: boolean) => {
      try {
        await setReviewComplete({
          variables: {
            reportId,
            reviewComplete,
          },
        });
        alerts.success(
          reviewComplete ? "Review submitted successfully" : "Review removed"
        );
      } catch (error) {
        alerts.error("There was a problem submitting your review");
      }
      setShowReviewReport(false);
      setShowRemoveReview(false);
    },
    [reportId, alerts, setReviewComplete]
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="large"
            onClick={() => setShowReviewReport(true)}
            disabled={reviewer?.isComplete ?? false}
            sx={{ width: "auto" }}
          >
            <>
              <CheckCircleOutline sx={{ marginRight: theme.spacing(1) }} />I
              have reviewed this report
            </>
          </Button>
        </Grid>
        {reviewer?.isComplete && (
          <Grid item xs={12}>
            <Button
              variant="text"
              onClick={() => setShowRemoveReview(true)}
              sx={{ width: "auto" }}
            >
              I still need to review this report
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        open={showReviewReport}
        onClose={() => setShowReviewReport(false)}
        title="Review Confirmation"
        confirmText="Yes, I have reviewed this report"
        loading={submittingReview}
        onConfirm={() => handleSubmitReview(true)}
        msg={
          <>
            <Typography sx={{ marginBottom: theme.spacing(1) }}>
              I,{" "}
              {reviewer?.person
                ? `${reviewer.person.first} ${reviewer.person.last}`
                : ""}
              , confirm that I have reviewed this report on{" "}
              {new Date().toLocaleDateString()} for correctness and completeness
              and that it is ready for verification and submission to Encamp.
            </Typography>
            <Typography>
              *The report assignee will be notified of this review via email.
            </Typography>
          </>
        }
      />
      <ConfirmDialog
        open={showRemoveReview}
        onClose={() => setShowRemoveReview(false)}
        title="Remove Review"
        loading={submittingReview}
        onConfirm={() => handleSubmitReview(false)}
        msg={
          <>
            <Typography>
              This will remove your review of this report.
            </Typography>
          </>
        }
      />
    </>
  );
}
