import { HasFeature } from "components/HasFeature";
import { useParams } from "react-router-dom";
import { ProductsTable } from "../../ProductsTable";

export function FacilityProduct() {
  const { facilityId = "" } = useParams();

  return (
    <HasFeature products fallback="../chemicals">
      <ProductsTable facilityId={facilityId} />
    </HasFeature>
  );
}
