import Dvr from "@mui/icons-material/Dvr";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { useUpdateFacilities } from "./useUpdateFacilities";
import { useCallback } from "react";
import { useBulkActionContext } from ".";

export function SetDefaultExecutionPlanAuto() {
  const { selectedRows, onClose } = useBulkActionContext();
  const alerts = useAlerts();
  const theme = useTheme();
  const [update] = useUpdateFacilities();

  const setDefaultExecutionPlanToAuto = useCallback(async () => {
    try {
      await update({
        variables: {
          ids: selectedRows.map((row) => row.facilityId).filter(hasValue),
          facility: {
            reportsManually: false,
          },
        },
      });
      alerts.success(`Set facilities to default auto mode`);
    } catch (error) {
      alerts.error(`Error: unable to set facilities to default auto `);
    } finally {
      onClose();
    }
  }, [update, selectedRows, alerts, onClose]);

  return (
    <MenuItem onClick={setDefaultExecutionPlanToAuto}>
      <ListItemIcon>
        <Dvr />
      </ListItemIcon>
      <Tooltip title="New workflows for this facility will set geppetto to auto. This is the default behavior">
        <ListItemText sx={{ pr: theme.spacing(1) }}>
          Set Default Execution Plan for Geppetto: Auto
        </ListItemText>
      </Tooltip>
    </MenuItem>
  );
}
