import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { TaskFormType } from "./TaskDialog";
import { TagPicker } from "components/TagPicker";
import { TagType } from "encamp-shared/src/prisma/generated/schema";

type TagsControl = {
  control: Control<TaskFormType>;
};

export const TagsControl: React.FC<TagsControl> = ({ control }) => {
  const theme = useTheme();
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={theme.spacing(1)}
      >
        <Typography variant="h6">Tags</Typography>
      </Stack>
      <Stack direction="column" gap={theme.spacing(0.5)}>
        <Controller
          name="tags"
          control={control}
          render={({ field, fieldState }) => (
            <TagPicker
              {...field}
              {...fieldState}
              label="Task Tags"
              tagType={TagType.Task}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};
