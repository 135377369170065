import { FetchResult } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import MergeIcon from "@mui/icons-material/Merge";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from "@mui/material";
import { IssueListButton } from "components/Forms/IssueListButton";
import { IssueCount } from "components/IssueCount";
import { IssueListTooltip } from "components/IssueListTooltip";
import { Issue } from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import invariant from "invariant";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldValues } from "react-hook-form";
import { ConfirmDialog } from "../ConfirmDialog";
import { Dialog } from "../Dialog";
import { HowToMergeDialog } from "./HowToMergeDialog";
import {
  CandidateDataCell,
  CellContent,
  EmptyCell,
  FacilityCountCell,
  FieldLabelCell,
  MergedDataCell,
  MergedHeaderCell,
  SelectAllCell,
  SelectableCellContent,
  StickyFacilityCountCell,
  StyledTableRow,
} from "./styles";

interface BaseFieldConfig<TData extends FieldValues> {
  name: string;
  label?: string;
  primary?: boolean;
  hidden?: boolean;
  render?: (data: TData) => string;
}

interface ExclusiveFieldConfig<TData extends FieldValues>
  extends BaseFieldConfig<TData> {
  label: string;
  strategy: "exclusive";
  render: (data: TData) => string;
  merge: (selection: TData, mergedRecord: TData) => TData;
}

interface InclusiveFieldConfig<TData extends FieldValues>
  extends BaseFieldConfig<TData> {
  label: string;
  strategy: "inclusive";
  render: (data: TData) => string;
  merge: (selections: TData[], mergedRecord: TData) => TData;
}

interface AllFieldConfig<TData extends FieldValues>
  extends BaseFieldConfig<TData> {
  label?: undefined;
  strategy: "all";
  merge: (selections: TData[], mergedRecord: TData) => TData;
}

export type FieldConfig<TData extends FieldValues> =
  | ExclusiveFieldConfig<TData>
  | InclusiveFieldConfig<TData>
  | AllFieldConfig<TData>;

export type Candidate<TData> = {
  id: string;
  name: string;
  associatedFacilityIds: string[];
  issues: Issue[];
  data: TData;
};

type MergePickerProps<TData extends FieldValues, TResult> = {
  open: boolean;
  onClose: () => void;
  kind: "chemical" | "contact";
  fields: FieldConfig<TData>[];
  candidates: Candidate<TData>[];
  onMerge: (
    result: TData,
    mergedIds: string[]
  ) => Promise<void> | Promise<FetchResult<TResult>>;
  validator: (result: TData) => Promise<Issue[]>;
  loadingCandidates: boolean;
  isMerging: boolean;
};

export function MergePicker<TData extends FieldValues, TResult>(
  props: MergePickerProps<TData, TResult>
) {
  const theme = useTheme();
  const [howToOpen, setHowToOpen] = useState(false);

  const dialogContent = props.loadingCandidates ? (
    <Box sx={{ padding: theme.spacing(4) }}>
      <Skeleton sx={{ padding: theme.spacing(4) }}></Skeleton>
      <Skeleton sx={{ padding: theme.spacing(4) }}></Skeleton>
      <Skeleton sx={{ padding: theme.spacing(4) }}></Skeleton>
      <Skeleton sx={{ padding: theme.spacing(4) }}></Skeleton>
      <Skeleton sx={{ padding: theme.spacing(4) }}></Skeleton>
      <Skeleton sx={{ padding: theme.spacing(4) }}></Skeleton>
    </Box>
  ) : (
    <MergePickerContent<TData, TResult> {...props} />
  );

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
      <DialogTitle sx={{ position: "relative" }}>
        Merge {props.kind.charAt(0).toUpperCase() + props.kind.substring(1)}
        <Typography>
          Select a value for each field of your selected {pluralize(props.kind)}{" "}
          to build a new, merged {props.kind}.
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: theme.spacing(2),
            cursor: "pointer",
          }}
          onClick={() => setHowToOpen(true)}
        >
          <InfoIcon sx={{ color: theme.palette.primary.main }} />
        </IconButton>
        <HowToMergeDialog
          open={howToOpen}
          onClose={() => setHowToOpen(false)}
          kind={props.kind}
        />
      </DialogTitle>
      {dialogContent}
    </Dialog>
  );
}

export function MergePickerContent<TData extends FieldValues, TResult>(
  props: MergePickerProps<TData, TResult>
) {
  invariant(
    props.candidates.length > 1,
    "MergePicker must have at least one candidate"
  );

  const theme = useTheme();
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number | undefined>();

  const [candidateToRemove, setCandidateToRemove] = useState<
    Candidate<TData> | undefined
  >();
  const [showMergeConfirm, setShowMergeConfirm] = useState(false);

  const [issueTooltipIndex, setIssueTooltipIndex] = useState<
    number | undefined
  >();
  const [mergedIssueTooltipOpen, setMergedIssueTooltipOpen] = useState<
    boolean | undefined
  >();

  const [candidates, setCandidates] = useState<Candidate<TData>[]>(
    props.candidates
  );

  const [selectedCells, setSelectedCells] = useState<Record<string, number[]>>(
    () => {
      const initial: Record<string, number[]> = {};
      props.fields.forEach((field) => {
        initial[field.name] = [0];
      });
      return initial;
    }
  );

  const primaryField = useMemo(
    () => props.fields.find((f) => f.primary),
    [props.fields]
  );

  invariant(primaryField, "Must have exactly one primary field.");

  const secondaryFields = useMemo(
    () => props.fields.filter((f) => !f.primary && !f.hidden),
    [props.fields]
  );

  const mergedRecord = useMemo<TData>(() => {
    return props.fields.reduce<TData>((acc, field) => {
      if (field.strategy === "exclusive") {
        const selectedIndex = selectedCells[field.name][0];
        const selectedCandidate = candidates[selectedIndex].data;
        return field.merge(selectedCandidate, acc);
      } else if (field.strategy === "inclusive") {
        const selectedCandidates = selectedCells[field.name].map(
          (index) => candidates[index].data
        );
        return field.merge(selectedCandidates, acc);
      } else {
        // fields with strategy "all" will merge all columns automatically, regardless of cell selection
        const candidateData = candidates.map((c) => c.data);
        return field.merge(candidateData, acc);
      }
    }, {} as TData);
  }, [props.fields, selectedCells, candidates]);

  const { issues: mergedRecordIssues, trigger } = useValidatingForm(
    mergedRecord,
    [], // We don't need to initialize any persisted issues, since we're creating a new record.
    props.validator
  );

  useEffect(() => {
    // Since we're not in the context of a real react-hook-form, we need to manually trigger the validation when the form opens
    trigger();
  }, [mergedRecord, trigger]);

  const mergedFacilityCount = useMemo(
    () => new Set(candidates.flatMap((c) => c.associatedFacilityIds)).size,
    [candidates]
  );

  const calculateTableHeight = useCallback(() => {
    if (dialogContentRef.current) {
      const dialogHeight = dialogContentRef.current.clientHeight;
      const paddingInPixels = theme.spacing(1);
      setTableHeight(dialogHeight - parseFloat(paddingInPixels));
    }
  }, [theme]);

  // Calculates and sets the table height on window resize
  useEffect(() => {
    // Initial calculation with a slight delay. This is so we don't calculate the height before it renders.
    const timer = setTimeout(() => {
      calculateTableHeight();
    }, 100);

    const handleResize = () => {
      calculateTableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [calculateTableHeight]);

  const handleCellClick = (
    field: FieldConfig<TData>,
    candidateIndex: number
  ) => {
    setSelectedCells((prev) => {
      const newSelected = { ...prev };
      if (field.strategy === "exclusive") {
        if (newSelected[field.name][0] === candidateIndex) {
          return prev; // No change if clicking the same cell
        }
        newSelected[field.name] = [candidateIndex];
      } else if (field.strategy === "inclusive") {
        if (newSelected[field.name].includes(candidateIndex)) {
          newSelected[field.name] = newSelected[field.name].filter(
            (i) => i !== candidateIndex
          );
        } else {
          newSelected[field.name] = [
            ...newSelected[field.name],
            candidateIndex,
          ];
        }
      }
      return newSelected;
    });
  };

  const handleSelectAll = (candidateIndex: number) => {
    setSelectedCells((prev) => {
      const newSelected = { ...prev };
      props.fields.forEach((field) => {
        newSelected[field.name] = [candidateIndex];
      });
      return newSelected;
    });
  };

  const handleRemoveCandidate = (candidateId: string) => {
    const candidateIndex = candidates.findIndex((c) => c.id === candidateId);
    if (candidateIndex < 0) return;

    setCandidates((prev) =>
      prev.filter((_, index) => index !== candidateIndex)
    );

    setSelectedCells((prev) => {
      const newSelected = { ...prev };
      props.fields.forEach((field) => {
        newSelected[field.name] = newSelected[field.name]
          .filter((index) => index !== candidateIndex)
          .map((index) => (index > candidateIndex ? index - 1 : index));

        if (newSelected[field.name].length === 0 && candidates.length > 1) {
          newSelected[field.name] = [0];
        }
      });
      return newSelected;
    });
  };

  async function handleMerge() {
    const mergedIds = candidates.map((c) => c.id);
    const result = await props.onMerge(mergedRecord, mergedIds);
    if (result && !result.errors) {
      props.onClose();
    }
  }

  return (
    <>
      <DialogContent
        ref={dialogContentRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          paddingY: 0,
          paddingLeft: 0,
          paddingRight: 2,
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          <TableContainer
            sx={{
              height: tableHeight,
              overflow: "auto",
              position: "relative",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <EmptyCell />
                  {candidates.map((candidate, index) => (
                    <FacilityCountCell
                      key={index}
                      data-testid={`candidate-facility-count-cell-${index}`}
                    >
                      <CellContent isCentered>
                        {`${candidate.associatedFacilityIds.length} ${pluralize(
                          "facility",
                          candidate.associatedFacilityIds.length
                        )}`}
                      </CellContent>
                    </FacilityCountCell>
                  ))}
                  <StickyFacilityCountCell data-testid="merged-facility-count-cell">
                    <CellContent isCentered>
                      {`${mergedFacilityCount} ${pluralize(
                        "facility",
                        mergedFacilityCount
                      )}`}
                    </CellContent>
                  </StickyFacilityCountCell>
                </StyledTableRow>
                <StyledTableRow>
                  <EmptyCell />
                  {candidates.map((candidate, index) => (
                    <SelectAllCell key={index} isFirst={index === 0}>
                      <CellContent>
                        <Button
                          onClick={() => handleSelectAll(index)}
                          sx={{
                            minWidth: "auto",
                            padding: "2px 8px",
                            fontSize: "0.75rem",
                            whiteSpace: "nowrap",
                          }}
                          data-testid={`select-all-${index}`}
                        >
                          SELECT ALL
                        </Button>
                        {candidates.length > 2 && (
                          <IconButton
                            onClick={() => setCandidateToRemove(candidate)}
                            size="small"
                            aria-label={`Remove ${props.kind}`}
                            sx={{
                              padding: "2px",
                              position: "absolute",
                              right: "8px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                            data-testid={`remove-candidate-${index}`}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                      </CellContent>
                    </SelectAllCell>
                  ))}
                  <MergedHeaderCell>
                    <CellContent>MERGED {props.kind.toUpperCase()}</CellContent>
                  </MergedHeaderCell>
                </StyledTableRow>
                {/* Primary Field Row */}
                <StyledTableRow key={primaryField.name}>
                  <FieldLabelCell>{`${primaryField.label}${
                    primaryField.strategy === "inclusive" ? " *" : ""
                  }`}</FieldLabelCell>
                  {candidates.map((candidate, index) => (
                    <CandidateDataCell
                      key={index}
                      onClick={() => handleCellClick(primaryField, index)}
                      isSelected={selectedCells[primaryField.name].includes(
                        index
                      )}
                      isBold
                      isTopMost
                      isLeftMost={index === 0}
                      data-testid={`candidate-cell-${primaryField.name}-${index}`}
                    >
                      <SelectableCellContent
                        isSelected={selectedCells[primaryField.name].includes(
                          index
                        )}
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        {primaryField.render?.(candidate.data)}
                        <IssueListTooltip
                          open={issueTooltipIndex === index}
                          onClose={() => {
                            setIssueTooltipIndex(undefined);
                          }}
                          issues={candidate.issues}
                        >
                          <IssueCount
                            issueCount={candidate.issues.length}
                            onClick={() => setIssueTooltipIndex(index)}
                          />
                        </IssueListTooltip>
                      </SelectableCellContent>
                    </CandidateDataCell>
                  ))}
                  <MergedDataCell
                    isBold
                    isTopMost
                    data-testid={`merged-cell-${primaryField.name}`}
                  >
                    <CellContent
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      {primaryField.render?.(mergedRecord)}
                      <IssueListTooltip
                        open={!!mergedIssueTooltipOpen}
                        onClose={() => {
                          setMergedIssueTooltipOpen(undefined);
                        }}
                        issues={mergedRecordIssues}
                      >
                        <IssueCount
                          issueCount={mergedRecordIssues.length}
                          onClick={() => setMergedIssueTooltipOpen(true)}
                        />
                      </IssueListTooltip>
                    </CellContent>
                  </MergedDataCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {/* Secondary Field Rows */}
                {secondaryFields.map((field, fieldIndex) => (
                  <StyledTableRow key={field.name}>
                    <FieldLabelCell>{`${field.label}${
                      field.strategy === "inclusive" ? " *" : ""
                    }`}</FieldLabelCell>
                    {candidates.map((candidate, index) => (
                      <CandidateDataCell
                        key={index}
                        onClick={() => handleCellClick(field, index)}
                        isSelected={selectedCells[field.name].includes(index)}
                        isLeftMost={index === 0}
                        isTopMost={fieldIndex === 0}
                        isBottomMost={fieldIndex === secondaryFields.length - 1}
                        data-testid={`candidate-cell-${field.name}-${index}`}
                      >
                        <SelectableCellContent
                          isSelected={selectedCells[field.name].includes(index)}
                        >
                          <span>{field.render?.(candidate.data)}</span>
                        </SelectableCellContent>
                      </CandidateDataCell>
                    ))}
                    <MergedDataCell data-testid={`merged-cell-${field.name}`}>
                      <CellContent>{field.render?.(mergedRecord)}</CellContent>
                    </MergedDataCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <ConfirmDialog
          open={!!candidateToRemove}
          title="Confirm Remove"
          msg={`Are you sure you want to remove '${
            candidateToRemove?.name
          }' from the list of ${pluralize(props.kind)} to be merged?`}
          confirmText="Remove"
          onClose={() => setCandidateToRemove(undefined)}
          onConfirm={() => {
            if (!candidateToRemove) return;
            handleRemoveCandidate(candidateToRemove.id);
            setCandidateToRemove(undefined);
          }}
        />
        <ConfirmDialog
          open={!!showMergeConfirm}
          title="Confirm Merge"
          msg={
            <>
              <Typography component="p">
                You are about to create a new merged {props.kind} for{" "}
                <i>{primaryField?.render?.(mergedRecord)}</i>.
              </Typography>
              <br />
              <Typography component="p">
                This action will merge the selected {pluralize(props.kind)} into
                a single merged {props.kind} in your {props.kind} catalog and
                update all references to those {pluralize(props.kind)} in your
                facility inventory.{" "}
                <b>
                  {mergedFacilityCount}{" "}
                  {pluralize("facility", mergedFacilityCount)} will be affected.
                </b>
              </Typography>
              <br />
              <Typography component="p">
                This action will <b>not</b> affect previously submitted reports.
              </Typography>
              <br />
              <Typography component="p">
                Are you sure you want to merge these {pluralize(props.kind)}?
              </Typography>
            </>
          }
          confirmText={
            <>
              <MergeIcon /> MERGE
            </>
          }
          onClose={() => {
            setShowMergeConfirm(false);
          }}
          onConfirm={handleMerge}
          loading={props.isMerging}
        />
      </DialogContent>
      <DialogActions>
        <IssueListButton issues={mergedRecordIssues} />
        <Button
          onClick={props.onClose}
          variant="outlined"
          sx={{ marginLeft: theme.spacing(1) }}
        >
          CANCEL
        </Button>
        <LoadingButton
          onClick={() => setShowMergeConfirm(true)}
          variant="contained"
          loading={props.isMerging}
        >
          <MergeIcon /> MERGE
        </LoadingButton>
      </DialogActions>
    </>
  );
}
