import { Stack } from "@mui/material";
import { purple } from "@mui/material/colors";
import { BarChart } from "components/Dashboard/BarChart";
import ChartLegend from "components/Dashboard/ChartLegend";
import { InsightsViolations } from "generated-graphql/graphql";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  PriorityChartColors,
  ProgramAreaColors,
  generateQuarters,
  getPriorityChartColorByIndex,
} from "util/insights";
import { useOverviewState } from "../OverviewContext";

export const allowedViolationsBarChartTypes = [
  "byFacility",
  "byProgramArea",
  "byQuarter",
  "byQuarterProgramArea",
] as const;

export type ViolationsBarChartType =
  (typeof allowedViolationsBarChartTypes)[number];

export type ViolationsBarChartProps = {
  data: InsightsViolations | undefined;
  graphType: ViolationsBarChartType;
};

export const ViolationsBarChart = ({
  data,
  graphType,
}: ViolationsBarChartProps) => {
  const navigate = useNavigate();
  const {
    overviewState: { programArea },
  } = useOverviewState();

  const onBarChartItemClick = useCallback(
    (item: any, source: string) => {
      const omnisearchParams: string[] = [];

      if (item) {
        switch (source) {
          case "byFacility":
            omnisearchParams.push(
              `registryId:${encodeURIComponent(item.frsId)}`
            );
            break;
          case "programArea":
            omnisearchParams.push(
              `programAreaViolation:${encodeURIComponent(item.name)}`
            );
            break;
          case "byQuarterByProgramArea": {
            if (item.programArea) {
              omnisearchParams.push(
                `programAreaViolation:${encodeURIComponent(item.programArea)}`
              );
            }
            break;
          }
          case "byQuarter": {
            omnisearchParams.push(`hasViolations:true`);
            break;
          }
        }
      }

      if (source !== "byFacility" && programArea) {
        omnisearchParams.push(`programArea:${encodeURIComponent(programArea)}`);
      }

      const omnisearchValue = omnisearchParams.join(" ");
      const newSearchParams = new URLSearchParams();
      newSearchParams.set("omnisearch", omnisearchValue);

      navigate(`../echo?${newSearchParams.toString()}`);
    },
    [navigate, programArea]
  );

  if (!data) return null;

  switch (graphType) {
    case "byFacility":
      return (
        <ByFacility data={data} onBarChartItemClick={onBarChartItemClick} />
      );
    case "byProgramArea":
      return (
        <ByProgramArea data={data} onBarChartItemClick={onBarChartItemClick} />
      );
    case "byQuarter":
      return (
        <ByQuarter data={data} onBarChartItemClick={onBarChartItemClick} />
      );
    case "byQuarterProgramArea":
      return (
        <ByQuarterProgramArea
          data={data}
          onBarChartItemClick={onBarChartItemClick}
        />
      );
    default:
      return null;
  }
};

const ByFacility = ({
  data,
  limit = 5,
  onBarChartItemClick,
}: {
  data: InsightsViolations;
  limit?: number;
  onBarChartItemClick: (item: any, source: string) => void;
}) => {
  const facilitiesWithMostViolations = useMemo(
    () =>
      data.violationsByFacility
        .filter((f) => f.totalViolations > 0) // Do not show facilities with 0 violations
        .slice() // Need to make a copy since this array is not mutable
        .sort((a, b) => {
          const violationDiff = b.totalViolations - a.totalViolations;
          if (violationDiff !== 0) return violationDiff;
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        .slice(0, limit),
    [data.violationsByFacility, limit]
  );

  return (
    <Stack display="flex" direction="row" height="100%">
      <BarChart
        dataset={
          facilitiesWithMostViolations.length
            ? facilitiesWithMostViolations
            : [{ totalViolations: 0, name: "No data" }]
        }
        series={[
          {
            type: "bar",
            dataKey: "totalViolations",
            valueFormatter: (value) => `Violations (${value})`,
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "name",
            categoryGapRatio: 0.4,
            colorMap: {
              type: "ordinal",
              colors: PriorityChartColors,
            },
          },
        ]}
        yAxis={[{ scaleType: "linear" }]}
        barLabel="value"
        barLabelColor="white"
        indexAxisLabelProperty={
          facilitiesWithMostViolations.length ? "name" : undefined
        }
        sx={{ flex: 1 }}
        onItemClick={(event, item) => {
          onBarChartItemClick(
            facilitiesWithMostViolations[item.dataIndex],
            "byFacility"
          );
        }}
      />
      <ChartLegend
        items={facilitiesWithMostViolations.map((d, index) => ({
          color: getPriorityChartColorByIndex(index),
          value: `${index + 1}. ${d.name}`,
        }))}
        sx={{ flex: 1, maxWidth: "35%" }}
      />
    </Stack>
  );
};

const ByProgramArea = ({
  data,
  onBarChartItemClick,
}: {
  data: InsightsViolations;
  onBarChartItemClick: (item: any, source: string) => void;
}) => {
  const violationsByProgramArea = data.violationsByProgramArea;

  return (
    <BarChart
      dataset={
        violationsByProgramArea.length
          ? violationsByProgramArea
          : [{ name: "No data", totalViolations: 0 }]
      }
      series={[
        {
          type: "bar",
          dataKey: "totalViolations",
          valueFormatter: (value) => `Violations (${value})`,
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "name",
          colorMap: {
            type: "ordinal",
            values: Object.keys(ProgramAreaColors),
            colors: Object.values(ProgramAreaColors),
          },
          categoryGapRatio: 0.65,
        },
      ]}
      yAxis={[{ scaleType: "linear" }]}
      barLabel="value"
      barLabelColor="white"
      onItemClick={(event, item) => {
        onBarChartItemClick(
          violationsByProgramArea[item.dataIndex],
          "programArea"
        );
      }}
    />
  );
};

const ByQuarter = ({
  data,
  onBarChartItemClick,
}: {
  data: InsightsViolations;
  onBarChartItemClick: (item: any, source: string) => void;
}) => {
  const quarters = generateQuarters().map((quarter) => {
    const total = (
      data.violationsByQuarter
        .find((q) => q.quarter === quarter)
        ?.violationsByProgramArea.map((pa) => pa.totalViolations) ?? []
    ).reduce((partialSum, a) => partialSum + a, 0);

    return {
      total,
      quarter,
    };
  });

  return (
    <BarChart
      dataset={quarters}
      series={[
        {
          type: "bar",
          dataKey: "total",
          color: purple[200],
          valueFormatter: (value) => `Violations (${value})`,
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "quarter",
          categoryGapRatio: 0.2,
          valueFormatter(value, context) {
            if (context.location === "tooltip") return value;
            if (context.location === "tick") {
              if (!value.includes("Q1")) {
                return value.split(" ")[0];
              }
            }
            return value.split(" ").join("\n");
          },
        },
      ]}
      yAxis={[{ scaleType: "linear" }]}
      onItemClick={(event, item) => {
        onBarChartItemClick(quarters[item.dataIndex], "byQuarter");
      }}
    />
  );
};

const ByQuarterProgramArea = ({
  data,
  onBarChartItemClick,
}: {
  data: InsightsViolations;
  onBarChartItemClick: (item: any, source: string) => void;
}) => {
  const violationsByQuarter = data.violationsByQuarter;
  const programAreas = data.programAreas;

  const allQuarters = generateQuarters();

  const quarters = allQuarters.map((quarter) => {
    const quarterData = violationsByQuarter.find(
      (q) => q.quarter === quarter
    ) || { violationsByProgramArea: [] };

    const violations = Object.keys(ProgramAreaColors).reduce(
      (acc, programArea) => {
        const violation = quarterData.violationsByProgramArea.find(
          (v) => v.name === programArea
        );
        acc[programArea.toLowerCase()] = violation
          ? violation.totalViolations
          : 0;
        return acc;
      },
      {} as Record<string, number>
    );

    return {
      ...violations,
      quarter,
    };
  });

  return (
    <>
      <ChartLegend
        config={{ direction: "row", position: "above" }}
        items={programAreas
          .slice()
          .sort()
          .map((pa) => ({
            color: ProgramAreaColors[pa],
            value: pa,
          }))}
        sx={{ paddingLeft: 6, position: "absolute" }}
      />
      <BarChart
        onItemClick={(event, barItemIdentifier) => {
          const clickedQuarter = quarters[barItemIdentifier.dataIndex];
          if (clickedQuarter) {
            onBarChartItemClick(
              { ...clickedQuarter, programArea: barItemIdentifier.seriesId },
              "byQuarterByProgramArea"
            );
          }
        }}
        dataset={quarters}
        series={Object.keys(ProgramAreaColors).map((programArea) => ({
          type: "bar",
          stack: "total",
          id: programArea,
          dataKey: programArea.toLowerCase(),
          color: ProgramAreaColors[programArea],
          valueFormatter: (value) =>
            programArea === "Multiple Programs"
              ? `Multi-program facilities (${value})`
              : `${programArea} facilities (${value})`,
        }))}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "quarter",
            categoryGapRatio: 0.2,
            valueFormatter(value, context) {
              if (context.location === "tooltip") return value;
              if (context.location === "tick") {
                if (!value.includes("Q1")) {
                  return value.split(" ")[0];
                }
              }
              return value.split(" ").join("\n");
            },
          },
        ]}
        yAxis={[{ scaleType: "linear" }]}
        stackedTooltipTotalFormatter={(total) => `Total violations: ${total}`}
      />
    </>
  );
};
