import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ConfirmDialog } from "components/ConfirmDialog";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { CheckboxField } from "components/Forms/CheckboxField";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { StateFieldsForm } from "components/Forms/StateFieldsForm";
import { ValidationSummary } from "components/Forms/ValidationSummary";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";
import {
  isNonReactiveSolid,
  normalizeCas,
} from "encamp-shared/src/utils/casAndEhs";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  ChemicalComponentInput,
  ChemicalDocumentInput,
  ChemicalInput,
  DensityUnits,
  DynamicField,
  DynamicFieldInput,
  DynamicFieldType,
  HealthHazard,
  InternationalFireCodeHazard,
  OregonHazard,
  PhysicalHazard,
  ProgramArea,
  StateOfMatter,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformChemical";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useValidatingForm } from "hooks/useValidatingForm";
import { isNil, isUndefined, omit } from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  SubmitHandler,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import {
  getChemicalsBreadcrumb,
  getChemicalsCatalogBreadcrumb,
} from "util/breadcrumb";
import { EhsFormLabels, EhsFormOrder } from "util/ehsForm";
import { hasCriticalIssues } from "util/forms";
import {
  HealthHazardLabels,
  HealthHazardOrder,
  NevadaIFCHazardLabels,
  NevadaIFCHazardOrder,
  OregonHazardLabels,
  OregonHazardOrder,
  PhysicalHazardLabels,
  PhysicalHazardOrder,
} from "util/hazards";
import { v4 } from "uuid";
import { useAlerts } from "../../../../../components/Alerts/AlertProvider";
import { useChemicalInputValidation } from "../../Report/validationHooks/useChemicalInputValidation";
import { ChemicalComponentTable } from "./ChemicalComponentTable";
import { ChemicalFormSkeleton } from "./ChemicalFormSkeleton";
import { EhsFormTooltip } from "./EhsFormTooltip";
import { FacilityUsage } from "./FacilityUsage";
import { SDSTable } from "./SDSTable";
import {
  CHEMICAL_DETAIL_CHEMICAL_QUERY,
  CHEMICAL_DETAIL_SAVE_CHEMICAL_MUTATION,
  UNVERIFY_CHEMICAL_MUTATION,
  VERIFY_CHEMICAL_MUTATION,
} from "./schema";
import { useFacilityUsage } from "./useFacilityUsage";
import { DocumentPreview } from "components/DocumentPreview";
import { DateTime } from "luxon";

export type ComponentInputType = Omit<
  ChemicalComponentInput,
  "componentPercentage"
> & {
  componentPercentage: string | null; // override float type to a string for data input
};

export type ChemicalDetailInputs = Omit<
  ChemicalInput,
  "density" | "components" | "stateFields"
> & {
  id: string;
  // Make the hazards required for custom handling
  physicalHazards: PhysicalHazard[];
  healthHazards: HealthHazard[];
  oregonHazards: OregonHazard[];
  ifcHazards: InternationalFireCodeHazard[];
  // Use a UI specific ChemicalComponent type
  stateFields: (DynamicField & { type: ProgramArea })[];
  components: ComponentInputType[];
  documents: ChemicalDocumentInput[];
  density: string | null; // override default density float type
};

export const ChemicalDetail = () => {
  const { chemicalId: pathParamChemicalId } = useParams();
  const { tenantId } = useParams<{ tenantId: string }>();
  invariant(tenantId);

  const creatingNewChemical = pathParamChemicalId === "new";

  const alerts = useAlerts();
  const theme = useTheme();
  const navigate = useNavigate();
  // This determines whether the confirmation dialog shows
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  // This state is used to determine if the user has successfully saved the chemical
  // so that the UnfinishedChangesPrompt can be hidden
  const [isSubmitAnywaySuccessful, setSubmitAnywaySuccessful] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [showChemicalVerification, setShowConfirmChemicalVerification] =
    useState<"verify" | "unverify" | undefined>(undefined);

  const client = useApolloClient();

  const isPreviewVisible = fileUrl != null;

  const { error, data, loading } = useQuery(CHEMICAL_DETAIL_CHEMICAL_QUERY, {
    variables: { chemicalId: pathParamChemicalId ?? "" },
    fetchPolicy: "cache-and-network",
    skip: !pathParamChemicalId?.length || creatingNewChemical,
  });

  const jurisdictions = useMemo(
    () => data?.chemicalWithAssociatedData.jurisdictions ?? [],
    [data?.chemicalWithAssociatedData.jurisdictions]
  );

  if (error) {
    throw error;
  }

  const chemical = useMemo<ChemicalDetailInputs | undefined>(() => {
    const chemical = data?.chemicalWithAssociatedData.chemical;
    if (loading) return undefined;
    return {
      ...chemical,
      id: chemical?.id ?? v4(),
      name: chemical?.name ?? "",
      ehsForm: chemical?.ehsForm ?? null,
      components:
        chemical?.components?.map((c) => ({
          ...c,
          componentPercentage: c.componentPercentage?.toString() ?? null,
        })) ?? [],
      pureOrMixture: chemical?.pureOrMixture ?? null,
      stateOfMatter: chemical?.stateOfMatter ?? [],
      density: chemical?.density?.toString() ?? null,
      noCasNumber: chemical?.noCasNumber ?? false,
      noHazardsNotReporting: chemical?.noHazardsNotReporting ?? false,
      ifcHazards: chemical?.ifcHazards ?? [],
      oregonHazards: chemical?.oregonHazards ?? [],
      healthHazards: chemical?.healthHazards ?? [],
      physicalHazards: chemical?.physicalHazards ?? [],
      stateFields:
        chemical?.stateFields?.map((sf) => ({
          ...omit(sf, "selectOptions", "tooltip"),
          value: sf.value ?? null,
          type: ProgramArea.Epcra,
        })) ?? [],
      documents: (
        data?.chemicalWithAssociatedData.chemical.documents ?? []
      ).map((d) => ({
        ...d,
        key: d.document.storageLink,
        name: d.document.title,
      })),
    };
  }, [data?.chemicalWithAssociatedData.chemical, loading]);

  // This is just a helper so you don't need to type out the full path
  const dynamicFields = useMemo(
    () => data?.chemicalWithAssociatedData.chemical?.stateFields ?? [],
    [data?.chemicalWithAssociatedData.chemical?.stateFields]
  );

  const { data: facilityUsageData } = useFacilityUsage({
    chemicalId: chemical?.id ?? "",
  });

  const facilityUsage = useMemo(
    () => ({
      facilityCount: facilityUsageData?.facilityUsage.facilityCount ?? 0,
      tenantCount: facilityUsageData?.facilityUsage.tenantCount ?? 0,
    }),
    [facilityUsageData?.facilityUsage]
  );

  const facilityCount = facilityUsage.facilityCount;
  const facilityUsageMessage =
    facilityCount > 0
      ? `You are about to update a chemical that is stored at ${facilityCount} ${pluralize(
          "facility",
          facilityCount
        )}.`
      : "";
  const chemicalId = creatingNewChemical ? chemical?.id : pathParamChemicalId;

  useBreadcrumb([
    {
      label: "Chemicals",
      to: getChemicalsBreadcrumb(tenantId),
    },
    {
      label: "Chemical Catalog",
      to: getChemicalsCatalogBreadcrumb(tenantId),
    },
    {
      label: creatingNewChemical ? "Create new chemical" : chemical?.name ?? "",
      to: chemical?.id,
    },
  ]);

  const form = useValidatingForm(
    chemical,
    data?.chemicalWithAssociatedData.chemical?.issues ?? [],
    useChemicalInputValidation(creatingNewChemical)
  );

  const {
    handleSubmit,
    getValues,
    control,
    resetField,
    issues,
    watch,
    formState: { isDirty, isSubmitSuccessful, errors },
  } = form;

  const watchCasNumber = watch("casNumber");
  const watchIsEhs = watch("isEhs");

  const saveDisabled = useMemo(() => {
    return loading || hasCriticalIssues(issues);
  }, [issues, loading]);

  const isEhsFormDisabled = useMemo(() => {
    if (!watchCasNumber) return true;
    if (!watchIsEhs) return true;
    return !isNonReactiveSolid(normalizeCas(watchCasNumber));
  }, [watchCasNumber, watchIsEhs]);

  useEffect(() => {
    if (isEhsFormDisabled) {
      resetField("ehsForm");
    }
  }, [isEhsFormDisabled, resetField]);

  // This method handles checkbox onChange events for chemical hazard classes.
  // Instead of representing the state of checkboxes by boolean values, they are
  // instead represented by an array of enumeration values containing the list
  // of checked elements.
  const handleHazardChange = useCallback(
    <
      F extends keyof Pick<
        ChemicalDetailInputs,
        "physicalHazards" | "healthHazards" | "ifcHazards" | "oregonHazards"
      >
    >(
      e: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
      field: ControllerRenderProps<ChemicalDetailInputs, F>
    ) => {
      if (checked) field.onChange([...field.value, e.target.value]);
      else {
        // i was hoping the type parameter would work here but i don't believe
        // it will due to an ambiguity in the type system :(
        // https://github.com/microsoft/TypeScript/issues/33591#issuecomment-535089398
        field.onChange(
          (field.value as any[]).filter((v) => v !== e.target.value)
        );
      }
    },
    []
  );

  const [saveChemical, { loading: saving, data: savedChemical }] = useMutation(
    CHEMICAL_DETAIL_SAVE_CHEMICAL_MUTATION,
    {
      onCompleted() {
        alerts.success("Saved chemical successfully!");
      },
      onError(ex) {
        console.error(ex);
        alerts.error("Error saving chemical.", ex);
      },
    }
  );

  useEffect(() => {
    // if we have saved a new chemical in the catalog
    // we should replace the current route with that
    // new chemical so that going back in history
    // doesn't take us back to a new form
    if (
      savedChemical?.upsertChemical?.id &&
      creatingNewChemical &&
      (isSubmitSuccessful || isSubmitAnywaySuccessful)
    ) {
      navigate(`../chemicals/${savedChemical.upsertChemical.id}`, {
        replace: true,
      });
    }
  }, [
    savedChemical,
    isSubmitAnywaySuccessful,
    isSubmitSuccessful,
    creatingNewChemical,
    navigate,
  ]);

  const [verifyChemical, { loading: verifyingChemical }] = useMutation(
    VERIFY_CHEMICAL_MUTATION
  );

  const [unverifyChemical, { loading: unverifyingChemical }] = useMutation(
    UNVERIFY_CHEMICAL_MUTATION
  );

  const onSubmit: SubmitHandler<ChemicalDetailInputs> = useCallback(
    (saveData) => {
      if (isUndefined(chemicalId)) {
        alerts.error("Chemical ID is undefined");
        return;
      }

      const input = transform({
        ...saveData,
        id: chemicalId,
        documents: saveData.documents,
        tenantIds: creatingNewChemical ? [tenantId] : chemical?.tenantIds,
        needsReview: false,
      });

      saveChemical({
        variables: {
          id: chemicalId,
          input,
        },
      });
    },
    [
      chemicalId,
      creatingNewChemical,
      tenantId,
      chemical?.tenantIds,
      alerts,
      saveChemical,
    ]
  );

  const handlePreSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const needsConfirmation =
        issues.length > 0 || facilityUsage.facilityCount > 0;
      if (needsConfirmation) {
        setShowConfirmUpdate(true);
      } else {
        handleSubmit(onSubmit)();
      }
    },
    [facilityUsage, handleSubmit, issues, onSubmit]
  );

  /**
   * Callback to submit data even though there are validation errors.
   * Triggered by user clicking "Save with errors" in the error dialog.
   */
  const submitAnyway = useCallback(async () => {
    const valuesToSubmit = omit(
      getValues(),
      "id",
      "issues"
    ) as ChemicalDetailInputs;
    await onSubmit(valuesToSubmit);
    setSubmitAnywaySuccessful(true);
    handleCloseErrorDialog();
  }, [getValues, onSubmit]);

  const handleCloseErrorDialog = () => {
    setShowConfirmUpdate(false);
  };

  const verifierName = useMemo(() => {
    const email = data?.chemicalWithAssociatedData?.chemical?.verifier?.email;
    const personName = `${
      data?.chemicalWithAssociatedData?.chemical?.verifier?.person?.first ?? ""
    } ${
      data?.chemicalWithAssociatedData?.chemical?.verifier?.person?.last ?? ""
    }`.trim();

    if (personName.length === 0) return email;

    return `${personName} (${email})`;
  }, [
    data?.chemicalWithAssociatedData?.chemical?.verifier?.person?.first,
    data?.chemicalWithAssociatedData?.chemical?.verifier?.person?.last,
    data?.chemicalWithAssociatedData?.chemical?.verifier?.email,
  ]);

  if (loading || (!creatingNewChemical && isNil(chemical))) {
    return <ChemicalFormSkeleton />;
  }

  return (
    <Paper sx={{ paddingTop: theme.spacing(2), paddingX: theme.spacing(4) }}>
      <Grid container>
        <Grid item md={isPreviewVisible ? 7 : 12}>
          <form id="chemical-detail-form" onSubmit={handlePreSubmit}>
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">
                  {creatingNewChemical ? "Add Chemical" : "Edit Chemical"}
                </Typography>
                {creatingNewChemical ? (
                  <></>
                ) : data?.chemicalWithAssociatedData?.chemical?.verifiedBy ==
                  null ? (
                  <Tooltip
                    title={
                      isDirty
                        ? "You must save your changes before you can verify this chemical."
                        : ""
                    }
                    disableFocusListener={!isDirty}
                  >
                    <span>
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        disabled={isDirty}
                        onClick={() =>
                          setShowConfirmChemicalVerification("verify")
                        }
                      >
                        Verify Chemical
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Box>
                    <Tooltip
                      title={
                        <>
                          <Typography variant="caption">
                            This chemical has been verified to match it's SDS.
                            Any changes made to it will require it to be
                            reverified.
                          </Typography>{" "}
                          <Typography
                            variant="caption"
                            onClick={() =>
                              setShowConfirmChemicalVerification("unverify")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <b>
                              <span style={{ textDecoration: "underline" }}>
                                Unverify
                              </span>
                            </b>
                          </Typography>
                        </>
                      }
                    >
                      <Chip
                        label={`Verified by ${verifierName} on
                    ${DateTime.fromISO(
                      data?.chemicalWithAssociatedData?.chemical?.verifiedAt
                    ).toLocaleString(DateTime.DATE_MED)}`}
                        color="success"
                        variant="outlined"
                      ></Chip>
                    </Tooltip>
                  </Box>
                )}
              </Stack>
              {!creatingNewChemical && (
                <Box sx={{ marginTop: theme.spacing(2) }}>
                  <FacilityUsage
                    chemicalId={chemical?.id ?? chemicalId ?? v4()}
                    tenantCount={facilityUsage.tenantCount}
                    facilityCount={facilityUsage.facilityCount}
                  />
                </Box>
              )}

              <Grid
                container
                sx={{ marginTop: theme.spacing(1) }}
                columnSpacing={theme.spacing(2)}
                rowSpacing={theme.spacing(1)}
              >
                {/* Name */}
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="name"
                    label="Name"
                    control={control}
                    textFieldProps={{ required: true, autoComplete: "off" }}
                  />
                </Grid>

                {/* Alternate ID */}
                <Grid item sm={6} xs={12}>
                  <FormTextField
                    name="alternateId"
                    label="Alternate ID"
                    control={control}
                  />
                </Grid>

                {/* CAS Number */}
                <Grid item sm={6} xs={12}>
                  <FormTextField
                    name="casNumber"
                    label="CAS Number"
                    control={control}
                  />
                </Grid>

                {/* EHS Form */}
                <Grid item xs={12} sm={6}>
                  <Stack flexDirection={"row"}>
                    <FormSelect
                      name="ehsForm"
                      label="EHS Form"
                      control={control}
                      disabled={isEhsFormDisabled}
                      selectItems={EhsFormOrder.map((ehsForm) => ({
                        value: ehsForm,
                        display: EhsFormLabels[ehsForm],
                      }))}
                      helperText={
                        isEhsFormDisabled
                          ? "EHS Form is only required for certain CAS Numbers"
                          : ""
                      }
                    />
                    {!isEhsFormDisabled && <EhsFormTooltip />}
                  </Stack>
                </Grid>

                {/* State of Matter */}
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="stateOfMatter"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl>
                        <FormLabel>State of Matter</FormLabel>
                        <FormGroup>
                          <Stack
                            flexDirection="row"
                            justifyContent="flex-start"
                          >
                            {Object.values(StateOfMatter).map((option) => (
                              <FormControlLabel
                                key={option}
                                label={prettyPrintEnumValue(option)}
                                control={
                                  <Checkbox
                                    checked={!!field.value?.includes(option)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        field.onChange([
                                          ...(field.value ?? []),
                                          option,
                                        ]);
                                      } else {
                                        field.onChange(
                                          field.value?.filter(
                                            (v) => v !== option
                                          )
                                        );
                                      }
                                    }}
                                  />
                                }
                              />
                            ))}
                          </Stack>
                        </FormGroup>
                        <ErrorDisplay error={error} />
                      </FormControl>
                    )}
                  />
                </Grid>

                {/* Density/Density Units */}
                <Grid item sm={6} xs={12}>
                  <Grid
                    container
                    columnSpacing={theme.spacing(2)}
                    rowSpacing={theme.spacing(1)}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        marginTop: {
                          xs: theme.spacing(2),
                          sm: 0,
                        },
                      }}
                    >
                      <FormTextField
                        name="density"
                        label="Density"
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormSelect
                        name="densityUnits"
                        label="Density Units"
                        control={control}
                        selectItems={[
                          {
                            value: null,
                            display: "None",
                          },
                          ...Object.values(DensityUnits).map((densityUnit) => ({
                            value: densityUnit,
                            display: prettyPrintEnumValue(densityUnit),
                          })),
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={theme.spacing(3)}>
                    {/* Basic Information */}
                    <Grid item xs={12} sm={12}>
                      <FormLabel>Basic Information</FormLabel>
                      <FormGroup>
                        {[
                          {
                            name: "isEhs" as keyof ChemicalDetailInputs,
                            label: "Extremely hazardous substance?",
                          },
                          {
                            name: "noHazardsNotReporting" as keyof ChemicalDetailInputs,
                            label: "Exclude from Tier II reporting?",
                          },
                          {
                            name: "isTradeSecret" as keyof ChemicalDetailInputs,
                            label: "Trade secret?",
                          },
                        ].map(({ name, label }) => (
                          <CheckboxField
                            key={name}
                            name={name}
                            control={control}
                            label={label}
                            staticErrorSpacing={false}
                          />
                        ))}
                      </FormGroup>
                    </Grid>

                    {/* Pure or Mixture */}
                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="pureOrMixture"
                        control={control}
                        defaultValue={chemical?.pureOrMixture}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl>
                            <FormLabel>Pure or Mixture</FormLabel>
                            <FormGroup row>
                              <RadioGroup row {...field}>
                                <FormControlLabel
                                  value="PURE"
                                  control={<Radio />}
                                  label="Pure"
                                />
                                <FormControlLabel
                                  value="MIXTURE"
                                  control={<Radio />}
                                  label="Mixture"
                                />
                              </RadioGroup>
                            </FormGroup>
                            <ErrorDisplay error={error} />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* SDS Upload */}
                <Grid item xs={12} sm={6}>
                  <SDSTable
                    chemicalId={chemical?.id ?? ""}
                    control={control}
                    onDocumentReview={(fileUrl) => {
                      setFileUrl(fileUrl);
                    }}
                    parentFile={fileUrl}
                  />
                </Grid>

                {/* Mixture Components */}
                <ChemicalComponentTable
                  getValues={getValues}
                  errors={errors}
                  control={control}
                />
              </Grid>

              {/* Physical Hazards */}
              <Grid sx={{ marginTop: theme.spacing(4) }}>
                <FormControl>
                  <FormLabel>Physical Hazards</FormLabel>
                  <FormGroup>
                    <Grid container>
                      <Controller
                        name="physicalHazards"
                        control={control}
                        defaultValue={chemical?.physicalHazards}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            {PhysicalHazardOrder.map((v) => (
                              <Grid key={v} item xs={12} sm={6}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      value={v}
                                      checked={field.value?.includes(v)}
                                      onChange={(e, checked) =>
                                        handleHazardChange(e, checked, field)
                                      }
                                    />
                                  }
                                  label={PhysicalHazardLabels[v]}
                                />
                              </Grid>
                            ))}
                            <ErrorDisplay error={error} />
                          </>
                        )}
                      />
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>

              {/* Health Hazards */}
              <Grid>
                <FormControl>
                  <Stack
                    sx={{ marginTop: theme.spacing(2) }}
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "baseline" }}
                  >
                    <FormLabel sx={{ marginTop: theme.spacing(4) }}>
                      Health Hazards
                    </FormLabel>
                  </Stack>
                  <FormGroup>
                    <Grid container>
                      <Controller
                        name="healthHazards"
                        control={control}
                        defaultValue={chemical?.healthHazards}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            {HealthHazardOrder.map((v) => (
                              <Grid key={v} item xs={12} sm={6}>
                                <FormControlLabel
                                  key={v}
                                  control={
                                    <Checkbox
                                      {...field}
                                      value={v}
                                      checked={field.value?.includes(v)}
                                      onChange={(e, checked) =>
                                        handleHazardChange(e, checked, field)
                                      }
                                    />
                                  }
                                  label={HealthHazardLabels[v]}
                                />
                              </Grid>
                            ))}
                            <ErrorDisplay error={error} />
                          </>
                        )}
                      />
                    </Grid>
                  </FormGroup>
                </FormControl>

                {jurisdictions.includes("OR") && (
                  <Grid>
                    <FormControl>
                      <Stack
                        sx={{ marginTop: theme.spacing(4) }}
                        direction={{ xs: "column", sm: "row" }}
                        alignItems={{ xs: "flex-start", sm: "baseline" }}
                      >
                        <FormLabel>Hazards</FormLabel>
                      </Stack>
                      <FormGroup>
                        <Grid container>
                          <Controller
                            name={"oregonHazards"}
                            control={control}
                            defaultValue={chemical?.oregonHazards}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                {OregonHazardOrder.map((v) => (
                                  <Grid key={v} item xs={12} sm={6}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          {...field}
                                          value={v}
                                          checked={field.value?.includes(v)}
                                          onChange={(e, checked) => {
                                            handleHazardChange(
                                              e,
                                              checked,
                                              field
                                            );
                                          }}
                                        />
                                      }
                                      label={OregonHazardLabels[v]}
                                    />
                                  </Grid>
                                ))}
                                <ErrorDisplay error={error} />
                              </>
                            )}
                          />
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}

                {jurisdictions.includes("NV") && (
                  <Grid>
                    <FormControl>
                      <Stack
                        sx={{ marginTop: theme.spacing(4) }}
                        direction={{ xs: "column", sm: "row" }}
                        alignItems={{ xs: "flex-start", sm: "baseline" }}
                      >
                        <FormLabel>IFC Hazards</FormLabel>
                      </Stack>
                      <FormGroup>
                        <Grid container>
                          <Controller
                            name={"ifcHazards"}
                            control={control}
                            defaultValue={chemical?.ifcHazards}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                {NevadaIFCHazardOrder.map((v) => (
                                  <Grid key={v} item xs={12} sm={6}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          {...field}
                                          value={v}
                                          checked={field.value?.includes(v)}
                                          onChange={(e, checked) => {
                                            handleHazardChange(
                                              e,
                                              checked,
                                              field
                                            );
                                          }}
                                        />
                                      }
                                      label={NevadaIFCHazardLabels[v]}
                                    />
                                  </Grid>
                                ))}
                                <ErrorDisplay error={error} />
                              </>
                            )}
                          />
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              {/* Other (less important) Fields */}
              <Grid
                container
                sx={{ marginTop: theme.spacing(4) }}
                columnSpacing={theme.spacing(2)}
                rowSpacing={theme.spacing(1)}
              >
                {/* Manufacturer/Supplier */}
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="manufacturerSupplier"
                    label="Manufacturer/Supplier"
                    control={control}
                    textFieldProps={{ autoComplete: "off" }}
                  />
                </Grid>

                {/* Recommended Use */}
                <Grid item sm={6} xs={12}>
                  <FormTextField
                    name="recommendedUse"
                    label="Recommended Use"
                    control={control}
                  />
                </Grid>

                {/* Version */}
                <Grid item sm={6} xs={12}>
                  <FormTextField
                    name="version"
                    label="Version"
                    control={control}
                  />
                </Grid>

                {/* Revision Date */}
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="revisionDate"
                    label="Revision Date"
                    control={control}
                  />
                </Grid>
              </Grid>
              <FormProvider {...form}>
                <Box sx={{ marginTop: theme.spacing(2) }}>
                  <Stack direction="row"></Stack>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: theme.spacing(2) }}
                  >
                    State Fields
                  </Typography>
                  {dynamicFields.length === 0 ? (
                    <Typography variant="body1">
                      There are no additional state fields for this chemical
                    </Typography>
                  ) : (
                    <StateFieldsForm
                      namePrefix="stateFields"
                      openJurisdictions={jurisdictions}
                      fields={dynamicFields}
                      sx={{ p: theme.spacing(3) }}
                    />
                  )}
                </Box>
              </FormProvider>
            </Stack>
          </form>
        </Grid>
        {isPreviewVisible && (
          <Grid item md={5} paddingLeft={theme.spacing(2)}>
            <div
              style={{
                position: "sticky",
                top: "125px", // account for top nav bar and floating save bar
                height: "69vh", // account for top nav bar and floating save bar
              }}
            >
              <DocumentPreview fileUrl={fileUrl} />
            </div>
          </Grid>
        )}
      </Grid>
      <FloatingSaveBar
        saving={saving}
        saveDisabled={saveDisabled}
        issues={issues}
        formId="chemical-detail-form"
        {...(isPreviewVisible
          ? {
              leftChildren: (
                <Button
                  onClick={() => setFileUrl(undefined)}
                  variant="contained"
                >
                  Close Preview
                </Button>
              ),
            }
          : {})}
      />
      <ConfirmDialog
        loading={saving}
        open={showConfirmUpdate}
        msg={
          <Box>
            {facilityUsageMessage}
            <ValidationSummary issues={issues} />
          </Box>
        }
        onClose={() => setShowConfirmUpdate(false)}
        onConfirm={submitAnyway}
      />
      {chemical && (
        <ConfirmDialog
          loading={verifyingChemical || unverifyingChemical}
          open={!!showChemicalVerification}
          msg={
            <Box>
              Are you sure you want to {showChemicalVerification}{" "}
              <b>"{chemical?.name}"</b>? This will indicate that the fields in
              the form{" "}
              <b>{showChemicalVerification === "unverify" && `do not`}</b> match
              those on the SDS document.
            </Box>
          }
          onClose={() => setShowConfirmChemicalVerification(undefined)}
          onConfirm={async () => {
            if (showChemicalVerification === "unverify") {
              const { data } = await unverifyChemical({
                variables: { id: chemical.id },
              });

              if (!data?.unverifyChemical) {
                alerts.error("There was an error unverifying this chemical.");
                return;
              }

              alerts.success("Successfully unverified chemical.");

              client.cache.modify({
                id: client.cache.identify({
                  __typename: "Chemical",
                  id: chemical.id,
                }),
                fields: {
                  verifiedAt: () => null,
                  verifier: () => null,
                },
              });
            } else {
              const { data } = await verifyChemical({
                variables: { id: chemical.id },
              });

              if (data?.verifyChemical?.verifiedBy == null) {
                alerts.error(`There was an error verifying this chemical.`);
                return;
              }

              alerts.success("Successfully verified chemical.");
              client.cache.modify({
                id: client.cache.identify({
                  __typename: "Chemical",
                  id: chemical.id,
                }),
                fields: {
                  verifiedAt: () => data?.verifyChemical?.verifiedAt,
                  verifier: () => ({
                    email: data?.verifyChemical?.verifier?.email,
                    person: {
                      first: data?.verifyChemical?.verifier?.person?.first,
                      last: data?.verifyChemical?.verifier?.person?.last,
                    },
                  }),
                },
              });
            }

            setShowConfirmChemicalVerification(undefined);
          }}
        />
      )}
      <UnfinishedChangesPrompt
        when={isSubmitSuccessful || isSubmitAnywaySuccessful ? false : isDirty}
      />
    </Paper>
  );
};
