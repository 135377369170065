import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  PropsWithChildren,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Array of items that must have an id field to use for navigation
type ItemsType = Array<{
  id: string;
  [key: string]: any;
}>;

type PrevNextNavigationState = {
  items: ItemsType;
  total: number;
  currentPage: number;
  pageSize: number;
  /**
   * Given your current path, finds the id of the item which represents our place in navigation state.
   * Defaults to the last segment of the path.
   */
  getIdFromPath: (path: string) => string;
  /**
   * Return a string representing the path to the current item with the given id.
   */
  setPathFromId: (id: string) => string;
};

/**
 * Fallback implementation to get id as last segment of path
 */
function defaultGetIdFromPath(path: string) {
  const pathSegments = path.split("/");
  return pathSegments[pathSegments.length - 1];
}

function defaultSetPathFromId(basePath: string, id: string) {
  return `${basePath}/${id}`;
}

type NavigationConfig = Partial<PrevNextNavigationState> & {
  queryPage?: (page: number) => Promise<ItemsType>;
};

type PrevNextNavigationContextType = {
  states: Record<string, PrevNextNavigationState>;
  setNavigationState: (key: string, config: NavigationConfig) => void;
  /**
   * By Convention, should be the prefix of the path to your items, for example: "staff/fulfillment", or "staff/submission-errors",
   * Defaults to the url path of the current page minus the last segment
   */
  navigationKey: string;
  /**
   *  Override of navigation key, should be called in a useEffect.
   */
  setNavigationKey: (key: string) => void;
  navigateToItemFromId: (
    key: string,
    currentItemId: string,
    offset: number
  ) => void;
  hasPreviousItem: (key: string, currentItemId: string) => boolean;
  hasNextItem: (key: string, currentItemId: string) => boolean;
};

const PrevNextNavigationContext = createContext<PrevNextNavigationContextType>({
  states: {},
  navigationKey: location.pathname,
  setNavigationKey: () => {},
  setNavigationState: () => {},
  navigateToItemFromId: () => {},
  hasPreviousItem: () => false,
  hasNextItem: () => false,
});

export const usePrevNextNavigationContext = () =>
  useContext(PrevNextNavigationContext);

export function PrevNextNavigationProvider(props: PropsWithChildren<{}>) {
  const [states, setStates] = useState<Record<string, PrevNextNavigationState>>(
    {}
  );
  const [overrideNavigationKey, setNavigationKey] = useState<string>("");
  let navigationKey = "";
  if (overrideNavigationKey && overrideNavigationKey.trim() !== "") {
    navigationKey = overrideNavigationKey;
  } else {
    navigationKey = window.location.pathname.split("/").slice(0, -1).join("/");
  }
  const queryPageRefs = useRef<
    Record<string, (page: number) => Promise<ItemsType>>
  >({});

  const navigate = useNavigate();

  const setNavigationState = useCallback(
    (key: string, config: NavigationConfig) => {
      const { queryPage, getIdFromPath, setPathFromId, ...rest } = config;
      if (queryPage) {
        queryPageRefs.current[key] = queryPage;
      }
      setStates((prev) => ({
        ...prev,
        [key]: {
          ...(prev[key] || {}),
          getIdFromPath:
            getIdFromPath || prev[key]?.getIdFromPath || defaultGetIdFromPath,
          setPathFromId:
            setPathFromId ||
            prev[key]?.setPathFromId ||
            ((id: string) => defaultSetPathFromId(key, id)),
          ...rest,
        },
      }));
    },
    []
  );

  const navigateToItemFromId = useCallback(
    async (key: string, currentItemId: string, offset: number) => {
      const state = states[key] || {};
      const items = state.items || [];
      const currentPage = state.currentPage || 0;
      const pageSize = state.pageSize || items.length;
      const queryPage =
        queryPageRefs.current[key] || (() => Promise.resolve([]));

      let currentIndex = items.findIndex((item) => item.id === currentItemId);

      if (currentIndex === -1) {
        return; // Current item not found
      }

      let newIndex = currentIndex + offset;
      let newPage = currentPage;

      let newItemId;
      if (newIndex >= pageSize) {
        // Need to fetch next page
        newPage = currentPage + 1;
        const newItems = await queryPage(newPage);
        if (newItems.length === 0) {
          return; // No more items to fetch
        }
        setNavigationState(key, {
          items: newItems,
          currentPage: newPage,
        });
        newItemId = newItems[0].id; // First item of the new page
      } else if (newIndex < 0) {
        // Need to fetch previous page
        newPage = currentPage - 1;
        if (newPage < 0) {
          return; // No more items to fetch
        }
        const newItems = await queryPage(newPage);
        setNavigationState(key, {
          items: newItems,
          currentPage: newPage,
        });
        newItemId = newItems[newItems.length - 1].id; // Last item of the new page
      } else {
        newItemId = items[newIndex].id;
      }

      if (newItemId) {
        const newPath = state.setPathFromId(newItemId);
        navigate(newPath);
      }
    },
    [states, navigate]
  );

  const hasPreviousItem = useCallback(
    (key: string, currentItemId: string) => {
      const state = states[key] || {};
      const items = state.items || [];
      const currentPage = state.currentPage || 0;

      const currentIndex = items.findIndex((item) => item.id === currentItemId);

      return currentIndex !== -1 && (currentIndex > 0 || currentPage >= 1);
    },
    [states]
  );

  const hasNextItem = useCallback(
    (key: string, currentItemId: string) => {
      const state = states[key] || {};
      const items = state.items || [];
      const total = state.total || 0;
      const currentPage = state.currentPage || 0;
      const pageSize = state.pageSize || items.length;

      const currentIndex = items.findIndex((item) => item.id === currentItemId);
      const isLastPage = (currentPage + 1) * pageSize >= total;

      return (
        currentIndex !== -1 && (currentIndex < items.length - 1 || !isLastPage)
      );
    },
    [states]
  );

  return (
    <PrevNextNavigationContext.Provider
      value={{
        states,
        setNavigationState,
        navigateToItemFromId,
        hasPreviousItem,
        hasNextItem,
        navigationKey,
        setNavigationKey,
      }}
    >
      {props.children}
    </PrevNextNavigationContext.Provider>
  );
}
