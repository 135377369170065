import { HasFeature } from "components/HasFeature";
import { useParams } from "react-router-dom";
import { ChemicalSummaryTable } from "../../ChemicalSummaryTable";

export function ChemicalSummary() {
  const { facilityId = "" } = useParams();

  return (
    <HasFeature
      products
      or
      measurements
      facilityId={facilityId}
      fallback="../chemicals"
    >
      <ChemicalSummaryTable facilityId={facilityId} />
    </HasFeature>
  );
}
