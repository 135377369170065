import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { useLocalStorageState } from "hooks/useLocalStorageState";

type ThemeMode = "light" | "dark";

type ThemeModeContext = {
  themeMode: ThemeMode;
  setThemeMode: Dispatch<SetStateAction<ThemeMode>>;
  toggleThemeMode: () => void;
};

export const themeModeContext = createContext<ThemeModeContext>({
  themeMode: "light",
  setThemeMode: () => {},
  toggleThemeMode: () => {},
});

export function ThemeModeProvider(props: PropsWithChildren<unknown>) {
  const [themeMode, setThemeMode] = useLocalStorageState<"light" | "dark">(
    "themeMode",
    "light"
  );

  const toggleThemeMode = useCallback(() => {
    setThemeMode(themeMode === "dark" ? "light" : "dark");
  }, [themeMode, setThemeMode]);

  const value: ThemeModeContext = useMemo(
    () => ({
      themeMode,
      setThemeMode,
      toggleThemeMode,
    }),
    [setThemeMode, themeMode, toggleThemeMode]
  );

  return (
    <themeModeContext.Provider value={value}>
      {props.children}
    </themeModeContext.Provider>
  );
}
