import { ChemicalsTable } from "../../ChemicalsTable";
import { ReportFloatingBar } from "../ReportFloatingBar";
import { useChemicalIssues, useReport } from "../useReport";
import { Skeleton } from "@mui/material";

import { TierIiReportStep } from "generated-graphql/graphql";
import { useProducts } from "hooks/useProducts";
import { useTouchReportMutation } from "../useTouchReportMutation";

export function ChemicalsReportStep() {
  const { data, loading } = useReport();
  const issues = useChemicalIssues();
  const facilityId = data?.tierIIReport.facility.id ?? "";
  const reportId = data?.tierIIReport.id ?? "";
  const { hasProducts: isOtherChemicals } = useProducts();
  const step = isOtherChemicals
    ? TierIiReportStep.OtherChemicals
    : TierIiReportStep.Chemicals;
  const { handleSave, loading: touchLoading } = useTouchReportMutation(
    reportId,
    data?.tierIIReport.touchedSteps ?? [],
    step
  );

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <>
      <ChemicalsTable facilityId={facilityId} reportId={reportId} />
      <ReportFloatingBar
        loading={touchLoading}
        onSaveClick={handleSave}
        issues={issues}
        disabledWithAnyIssues
        saveText="Mark as Complete"
      />
    </>
  );
}
