import { yupResolver } from "@hookform/resolvers/yup";
import { parseVirtualEventId } from "encamp-shared/src/tasks/expandSeries";
import { v4 } from "uuid";
import { Frequency, EventMutationScope } from "generated-graphql/graphql";
import { gql } from "generated-graphql";
import { useQuery } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useDeleteDocumentMutation } from "hooks/documents";
import { useTenant } from "hooks/useTenant";
import invariant from "invariant";
import { useCallback, useMemo, useState } from "react";
import { useTaskSchema } from "./createTaskSchema";
import { useUpdateTaskMutation } from "./useUpdateTask";
import { TaskDialog, TaskFormType } from "./TaskDialog";
import RecurrenceScopeRadioDialog from "./RecurrenceScopeRadioDialog";
import { Dialog } from "components/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const GET_VIRTUAL_EVENT = gql(`
  query getVirtualEvent($taskId: ID!, $deadline: DateTime!) {
      virtualEvent(taskId: $taskId, deadline: $deadline) {
        id
        taskId
        seriesId
        title
        description
        deadline
        completedAt
        shouldNotify
        frequency
        interval
        assignee {
          id
          email
          person {
            first
            last
          }
        }
        subtasks {
          id
          title
          completedAt
        }
        facilities {
          id
        }
        watchers {
          id
        }
        documents {
          id
          fileExtension
          title
          documentType
        }
        tags {
          id
          name
        }
        links
      }
  }
`);

interface EditTaskDialogProps {
  virtualEventId: string;
  onClose: () => void;
}

export const EditTaskDialog: React.FC<EditTaskDialogProps> = ({
  virtualEventId,
  onClose,
}) => {
  const alerts = useAlerts();
  const { tenantId, loading: isTenantLoading } = useTenant();
  const [mutationScopeDialogState, setOpenMutationScopeDialogState] = useState({
    open: false,
    onSubmit: (scope: EventMutationScope) => {},
    hideOptions: [] as EventMutationScope[],
  });
  const schema = useTaskSchema();

  const { taskId, deadline } = parseVirtualEventId(virtualEventId);
  const { data: virtualEventQuery, loading: isVirtualEventLoading } = useQuery(
    GET_VIRTUAL_EVENT,
    {
      variables: {
        taskId,
        deadline,
      },
      skip: !taskId || !deadline || (deadline && isNaN(deadline.getTime())),
      fetchPolicy: "network-only",
    }
  );

  const [updateTask, { loading: isUpdateTaskLoading }] = useUpdateTaskMutation(
    virtualEventQuery?.virtualEvent ?? null,
    tenantId ?? ""
  );

  const [deleteDocument] = useDeleteDocumentMutation({
    onError: (error) => {
      alerts.error("Failed to delete document");
    },
  });

  const handleClose = useCallback(
    (values: TaskFormType) => {
      // delete any documents that we may have created, as they were not meant to be persisted
      values.documents
        ?.filter(
          (document) =>
            !virtualEventQuery?.virtualEvent?.documents
              ?.map((x) => x.id)
              .includes(document.id)
        )
        .forEach((document) => {
          if (document.id) {
            deleteDocument({ variables: { id: document.id } });
          }
        });
      onClose();
    },
    [deleteDocument, onClose, virtualEventQuery?.virtualEvent?.documents]
  );

  const handleSave = useCallback(
    (data: TaskFormType, eventMutationScope: EventMutationScope) => {
      invariant(tenantId, "tenantId is required");
      invariant(data.assignee, "assignee is required");
      updateTask({
        variables: {
          taskData: data,
          scope: eventMutationScope,
        },
        onCompleted: () => {
          alerts.success("Task updated");
          onClose();
        },
        onError: (error) => {
          alerts.error("Failed to updated task");
          onClose();
        },
      });
    },
    [alerts, onClose, tenantId, updateTask]
  );

  const initialValues: TaskFormType = useMemo(() => {
    const virtualEvent = virtualEventQuery?.virtualEvent;
    return {
      title: virtualEvent?.title ?? "",
      assignee: virtualEvent?.assignee ?? null,
      description: virtualEvent?.description ?? "",
      frequency: virtualEvent?.frequency ?? Frequency.Once,
      interval: virtualEvent?.interval ?? 1,
      shouldNotify: virtualEvent?.shouldNotify ?? false,
      deadline: virtualEvent?.deadline,
      tags: virtualEvent?.tags ?? [],
      links:
        virtualEvent?.links?.map((link) => ({
          url: link,
          id: v4(),
        })) ?? [],
      subtasks:
        virtualEvent?.subtasks?.map((subtask) => ({
          title: subtask.title,
          completed: subtask.completedAt !== null,
          id: subtask.id,
        })) ?? [],
      facilityIds:
        virtualEvent?.facilities?.map((facility) => facility.id) ?? [],
      watchers: virtualEvent?.watchers?.map((watcher) => watcher.id) ?? [],
      documents: virtualEvent?.documents ?? [],
      isCompleted: virtualEvent?.completedAt !== null,
      completedOn: virtualEvent?.completedAt,
    };
  }, [virtualEventQuery?.virtualEvent]);

  const onDialogSubmitted = useCallback(
    (
      data: TaskFormType,
      isCompletionOnly: boolean,
      isFrequencyChanged: boolean
    ) => {
      if (
        virtualEventQuery?.virtualEvent?.frequency === Frequency.Once ||
        isCompletionOnly
      ) {
        handleSave(data, EventMutationScope.This);
      } else {
        setOpenMutationScopeDialogState({
          open: true,
          onSubmit: (scope) => handleSave(data, scope),
          hideOptions: isFrequencyChanged ? [EventMutationScope.This] : [],
        });
      }
    },
    [
      virtualEventQuery?.virtualEvent?.frequency,
      handleSave,
      setOpenMutationScopeDialogState,
    ]
  );
  if (!isVirtualEventLoading && !virtualEventQuery?.virtualEvent) {
    return (
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Event not found</DialogTitle>
        <DialogContent>
          <Typography>
            The event you are looking for may have been moved or deleted. You
            can close this message and return to the task list.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <TaskDialog
        validationResolver={yupResolver(schema) as any}
        initialValues={initialValues}
        onSubmit={onDialogSubmitted}
        onClose={handleClose}
        isSubmitting={isUpdateTaskLoading}
        isLoading={isVirtualEventLoading || isTenantLoading}
        open={!!virtualEventId}
        title="Edit Task"
      />
      <RecurrenceScopeRadioDialog
        {...mutationScopeDialogState}
        action="Edit"
        onClose={() =>
          setOpenMutationScopeDialogState({
            open: false,
            onSubmit: () => {},
            hideOptions: [],
          })
        }
      />
    </>
  );
};
