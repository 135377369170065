import { Fade, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { Issue } from "generated-graphql/graphql";
import { groupBy } from "lodash";
import { PropsWithChildren, useEffect, useRef } from "react";

type IssueListTooltipProps = PropsWithChildren<{
  issues: Issue[];
  open: boolean;
  onClose: () => void;
}>;

export const IssueListTooltip = ({
  issues,
  open,
  onClose,
  children,
}: IssueListTooltipProps) => {
  const theme = useTheme();

  const popperRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node) &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const issuesByModel = groupBy(issues, (i) => i.modelName);

  return (
    <div ref={tooltipRef}>
      <Tooltip
        arrow
        TransitionComponent={Fade}
        open={open}
        onKeyDown={onClose}
        placement="top"
        slotProps={{
          tooltip: {
            sx: { maxWidth: theme.spacing(49) },
          },
        }}
        PopperProps={{
          ref: popperRef,
        }}
        title={
          <Stack
            sx={{
              p: theme.spacing(2),
              maxHeight: theme.spacing(75),
              overflowY: "auto",
            }}
          >
            {Object.keys(issuesByModel).map((key, i) => {
              const modelIssues = issuesByModel[key];
              return (
                <div key={i}>
                  {modelIssues.map((issue, j) => (
                    <Typography sx={{ marginBottom: theme.spacing(2) }} key={j}>
                      {issue.message}
                    </Typography>
                  ))}
                </div>
              );
            })}
          </Stack>
        }
      >
        <div>{children}</div>
      </Tooltip>
    </div>
  );
};
