import { Chip, SxProps, useTheme } from "@mui/material";

type ThresholdChipProps = {
  isOverThreshold: boolean;
  sx?: SxProps;
};

export function ThresholdChip({ isOverThreshold, sx }: ThresholdChipProps) {
  const theme = useTheme();
  const backgroundColor = isOverThreshold
    ? `${theme.palette.error.light}20`
    : `${theme.palette.success.light}20`;

  const color = isOverThreshold
    ? theme.palette.error.main
    : theme.palette.success.main;

  return (
    <Chip
      label={`${isOverThreshold ? "Above" : "Below"} Threshold`}
      size="small"
      variant="filled"
      sx={{
        backgroundColor,
        color,
        ...sx,
      }}
    />
  );
}
