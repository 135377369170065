import Save from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CellWithSecondaryText } from "components/DataGrid";
import { Dialog } from "components/Dialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  AssignmentType,
  GetReportDetailsQuery,
  PersonSelectListQuery,
  PersonSelectListQueryVariables,
  UserStatus,
} from "generated-graphql/graphql";
import {
  useAssigneeRemove,
  useAssigneeUpdate,
  useReviewerRemove,
  useReviewerUpdate,
} from "hooks/useAssignees";
import React, { useMemo, useState } from "react";
import { ReportRow } from ".";
import { gql } from "../../../../generated-graphql";

export enum AssignmentAction {
  Assign,
  Remove,
}

interface AssigneesEditorDialogProps {
  assignmentType: AssignmentType;
  assignmentAction: AssignmentAction;
  tierIIReports:
    | ReportRow[]
    | NonNullable<GetReportDetailsQuery["tierIIReport"]>[];
  tenantId: string;
  open: boolean;
  refetchQueries?: string[];
  clearAssigneesOnClose?: boolean; // Whether to clear the selected assignees when the dialog is closed, set to false if the assignees are being saved to the parent component
  onClose: () => void;
}

const PERSON_SELECT_LIST = gql(/* GraphQL */ `
  query PersonSelectList(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    people(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      items {
        id
        first
        last
        title
        phones {
          type
          number
        }
        user {
          status
          email
        }
      }
    }
  }
`);

const getExistingAssigneesForType = (
  reports: ReportRow[] | NonNullable<GetReportDetailsQuery["tierIIReport"]>[],
  type: AssignmentType
) => {
  return reports.flatMap((r) =>
    type === AssignmentType.Reviewer ? r.reviewers : r.assignees
  );
};

export const AssigneesEditorDialog: React.FC<AssigneesEditorDialogProps> = ({
  assignmentType,
  assignmentAction,
  tierIIReports,
  tenantId,
  open,
  refetchQueries = ["TierIIReportAssignees", "FacilityTierIIReports"],
  clearAssigneesOnClose = true,
  onClose,
}) => {
  const theme = useTheme();
  const alerts = useAlerts();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
    assignmentAction === AssignmentAction.Remove
      ? []
      : getExistingAssigneesForType(tierIIReports, assignmentType).map(
          (a) => a.person.id
        )
  );
  const [dataGridLoading, setDataGridLoading] = useState(true);
  const removeAssignee = useAssigneeRemove();
  const updateAssignee = useAssigneeUpdate();
  const removeReviewer = useReviewerRemove();
  const updateReviewer = useReviewerUpdate();
  const removingAssignees = assignmentAction === AssignmentAction.Remove;

  const [mutate, { loading: mutationLoading }] = useMemo(() => {
    if (assignmentAction === AssignmentAction.Remove) {
      switch (assignmentType) {
        case AssignmentType.Assignee:
          return removeAssignee;
        case AssignmentType.Reviewer:
          return removeReviewer;
      }
    } else {
      switch (assignmentType) {
        case AssignmentType.Assignee:
          return updateAssignee;
        case AssignmentType.Reviewer:
          return updateReviewer;
      }
    }
  }, [
    assignmentAction,
    assignmentType,
    removeAssignee,
    removeReviewer,
    updateAssignee,
    updateReviewer,
  ]);

  const handleSave = async () => {
    await mutate({
      variables: {
        tierIIReportIds: tierIIReports
          .map(
            (r) =>
              ("tierIIReportId" in r
                ? r.tierIIReportId
                : "id" in r
                ? r.id
                : "") ?? ""
          )
          .filter((id) => id.length),
        personIds: selectedUserIds,
      },
      refetchQueries,
      onError: (error) => {
        alerts.error(`Error updating ${prettyPrintEnumValue(assignmentType)}s`);
        console.error(error);
      },
      onCompleted: () => {
        alerts.success(`${prettyPrintEnumValue(assignmentType)}s updated`);
        handleOnClose();
      },
    });
  };

  const handleOnClose = () => {
    if (clearAssigneesOnClose) {
      setSelectedUserIds([]);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="lg">
      <DialogTitle>
        {prettyPrintDialogTitle(
          assignmentType,
          getExistingAssigneesForType(tierIIReports, assignmentType).length > 0,
          assignmentAction
        )}
      </DialogTitle>
      <DialogContent sx={{ minWidth: 900 }}>
        <OmnisearchDataGrid<
          PersonSelectListQuery,
          PersonSelectListQueryVariables,
          PersonSelectListQuery["people"]["items"][0]
        >
          sx={{ height: 510 }}
          withPadding={false}
          rowHeight="auto"
          initialPageSize={10}
          isURLDriven={false}
          dataQuery={PERSON_SELECT_LIST}
          defaultSearch={`tenantId:${tenantId} user:exists`}
          onSelectedIdsChanged={(rows) =>
            setSelectedUserIds(rows.map((id) => id as string))
          }
          selectedIds={selectedUserIds}
          columns={[
            {
              field: "name",
              headerName: "Name",
              flex: 0.9,
              sortable: false,
              valueGetter: ({ row }) => {
                return [row.first, row.last].filter(Boolean).join(" ");
              },
              renderCell: ({ row: { title }, value }) => (
                <CellWithSecondaryText
                  primaryValue={value}
                  secondaryValue={title}
                />
              ),
            },
            {
              field: "phone",
              headerName: "Phone",
              sortable: false,
              flex: 0.5,
              valueGetter: ({ row }) => {
                // Just taking the first phone for now
                return row.phones?.[0]?.number;
              },
            },
            {
              field: "user.email",
              headerName: "Email",
              flex: 1,
              valueGetter: ({ row }) => row.user?.email,
            },
            {
              field: "user.status",
              headerName: "Status",
              flex: 0.5,
              valueGetter: ({ row }) => row.user?.status,
              valueFormatter: ({ value }) => {
                if (value !== UserStatus.Active) {
                  return prettyPrintEnumValue(value);
                }
                return "";
              },
              enumValues: Object.values(UserStatus),
              filterKeyType: "enum",
            },
          ]}
          additionalFilterColumns={[{ header: "Title", key: "title" }]}
          getItems={(data) => data.people.items}
          getCount={(data) => data.people.count}
          onLoadingStateChange={(loading) => setDataGridLoading(loading)}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1} width="100%">
          <Button
            onClick={() => setSelectedUserIds([])}
            disabled={!selectedUserIds.length}
            sx={{ px: theme.spacing(2) }}
          >
            Remove all
          </Button>
          <Box flex={1} />
          <Button onClick={handleOnClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            loading={dataGridLoading || mutationLoading}
            onClick={handleSave}
            startIcon={removingAssignees ? <></> : <Save />}
            variant="contained"
          >
            {removingAssignees ? "Remove" : "Save"}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

function prettyPrintDialogTitle(
  assignmentType: AssignmentType,
  isEdit: boolean,
  assignmentAction: AssignmentAction
) {
  const action =
    assignmentAction === AssignmentAction.Remove
      ? "Remove"
      : isEdit
      ? "Edit"
      : "Add";
  const assignee =
    assignmentType === AssignmentType.Assignee ? "Assignees" : "Reviewers";
  return `${action} ${assignee}`;
}
