import { useQuery } from "@apollo/client";
import { uuidv5 } from "encamp-shared/src/utils/uuid";
import { gql } from "generated-graphql";
import { TagInput, TagType } from "../generated-graphql/graphql";
import { useTenant } from "./useTenant";

const DOCUMENT_TAGS = gql(`
  query DocumentTags($search: String) {
    tags(search: $search) {
      items {
        id
        name
      }
    }
  }
`);

export function useDocumentTags() {
  const { tenantId } = useTenant();
  return useQuery(DOCUMENT_TAGS, {
    variables: {
      search: `tenantId:${tenantId} type:${TagType.Document}`,
    },
    skip: !tenantId,
  });
}

function useFindOrGenerateTag(idName: string, name: string) {
  const { data } = useDocumentTags();

  const existingTag = data?.tags.items.find((tag) => tag.name === name);

  return existingTag ?? { id: uuidv5(idName), name };
}

export function useRYTag(reportingYear: number | undefined): TagInput {
  const { tenantId } = useTenant();
  return useFindOrGenerateTag(
    `RY${reportingYear}-${tenantId}-documentTag`,
    `RY${reportingYear}`
  );
}

export function useTierIITag(): TagInput {
  const { tenantId } = useTenant();
  return useFindOrGenerateTag(`Tier II-${tenantId}-documentTag`, "Tier II");
}
