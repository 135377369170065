import { DateTime } from "luxon";

/**
 * Gets the date at the next midnight i.e. the start of the day in UTC
 * e.g. 2023-03-19T17:00:00.000-7:00 becomes 2023-03-20T00:00:00.000-7:00
 * @param date
 * @returns The datetime + the time offset from UTC
 */
export function getMidnightOfUtcDay(date: Date) {
  const newDate = new Date(date);

  // This is a workaround to the date being converted to the local timezone automatically
  // and then displaying in a datepicker as 1 day prior to the date in the database.
  // This sets the time to midnight i.e. the start of the UTC day. For example,
  // 2023-03-20 might get converted to 2023-03-19T17:00:00.000-7:00 if you're in PDT.
  // We want to set the time to 2023-03-20T00:00:00.000-7:00 so that it displays correctly.
  newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
  return newDate;
}

/**
 * Uses Luxon to get a string representation of the date only (without the timestamp) in the UTC timezone
 * @param date
 * @param type either "short" to get the format MM/dd/yyyy or "full" to get the format MMM d, yyyy. Defaults to "short".
 * @returns formatted string of the UTC date with no timestamp
 */
export function getUtcDisplayDate(
  date: Date | string,
  type: "short" | "med" | "full" = "short"
): string {
  const inputDate = typeof date === "string" ? new Date(date) : date;

  const formatTypes = {
    short: DateTime.DATE_SHORT,
    med: DateTime.DATE_MED,
    full: DateTime.DATE_FULL,
  };
  return DateTime.fromJSDate(inputDate, {
    zone: "utc",
  }).toLocaleString(formatTypes[type]);
}

/**
 * Gets midnight of the input date
 * @param date
 * @returns date where the timestamp is midnight of the input date
 */
export function getMidnightOfDay(date: Date | string): Date {
  const dt =
    typeof date === "string"
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date);

  if (!dt.isValid) {
    // This error might be thrown if a string is passed in that
    // is not an ISO date
    throw new Error(`Invalid date: ${date}`);
  }

  const midnight = dt.minus({
    hours: dt.hour,
    minutes: dt.minute,
    seconds: dt.second,
    milliseconds: dt.millisecond,
  });

  return midnight.toJSDate();
}
